<div>
    <span class="badge badge-pink">Material tag: COVID Vaccine</span>
    <!-- <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span>
    <span class="badge badge-pink">State: Andaman and Nicobar Islands</span> -->
</div>
<div class="card shadow-none p-0 mb-0 rounded-0 mt-3">
    <div class="card-header shadow-sm p-3 pt-2 pb-2 rounded-0">
        <div class="row">
            <div class="col-xl-6 mt-1" data-toggle="collapse" href="#multiCollapseExample2" role="button"
                aria-expanded="false" aria-controls="multiCollapseExample2" id="prdCtyClpse">
                <span class="me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Filter">
                    <i class="fa fa-filter" aria-hidden="true"></i></span>
                <span class="badge badge-pink">Periodicity: {{prdctyType}}</span>
            </div>
            <div class="col-xl-6">
                <div class="row">
                    <div class="col-xl-4 small">
                        <div class="input-group" *ngIf="prdctyType=='Monthly' && tab=='o'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpSD="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="Start Date" [maxDate]="crntDate"
                                (bsValueChange)="onChngDT($event, 'sd')" bsDatepicker readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                    [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group" *ngIf="prdctyType=='Weekly' && tab=='o'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpSD="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="Start Date"
                                [daysDisabled]="[0,2,3,4,5,6]" [maxDate]="crntDate"
                                (bsValueChange)="onChngDT($event, 'sd')" bsDatepicker readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                    [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group" *ngIf="prdctyType=='Daily' && tab=='o'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpSD="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY', displayOneMonthRange: true,
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="Start Date" [maxDate]="crntDate"
                                (bsValueChange)="onChngDT($event, 'sd')" bsDatepicker readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                    [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-1 text-center mt-1 p-0"><label *ngIf="tab=='o'">-</label></div>
                    <div class="col-xl-4 small">
                        <div class="input-group" *ngIf="prdctyType=='Monthly'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="End Date" [maxDate]="crntDate"
                                (bsValueChange)="onChngDT($event, 'ed')" bsDatepicker readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                    [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group" *ngIf="prdctyType=='Weekly'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="End Date"
                                [daysDisabled]="[0,2,3,4,5,6]" [maxDate]="maxToDate" readonly
                                (bsValueChange)="onChngDT($event, 'ed')" bsDatepicker>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                    [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                        <div class="input-group" *ngIf="prdctyType=='Daily'">
                            <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY', displayOneMonthRange: true,
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' placeholder="End Date" [maxDate]="maxToDate"
                                (bsValueChange)="onChngDT($event, 'ed')" bsDatepicker readonly>
                            <div class="input-group-append">
                                <button class="btn btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                    [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 small select2-drpdwn">
                        <select class="form-select form-select-sm" (change)="onChngPCT($event)">
                            <option *ngFor="let pc of lstPrdCty" [value]="pc.val">{{pc.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-3 p-0 border" class="collapse multi-collapse" id="multiCollapseExample2">
        <div class="row ps-2 pe-2 mt-1">
            <div class="col-xl-6 mt-3" *ngIf="path == 'demand' || path == 'discrepancies' || path == 'ord-res-time' || path == 'order-status' ">
                <div class="card p-0 m-0">
                    <div class="card-header p-2">Indent</div>
                    <div class="card-body p-2">
                        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist" *ngIf="path != 'demand'">
                            <li class="nav-item"><a class="nav-link f-14 p-2 active" id="Overview-tab"
                                data-bs-toggle="tab" href="#Overview" role="tab" aria-controls="Overview"
                                aria-selected="true"><span class="f-10">By type</span></a></li>
                        </ul>
                        <div class="tab-content" id="top-tabContent" *ngIf="path != 'demand'">
                            <div class="tab-pane fade show active" id="Overview" role="tabpanel"
                                aria-labelledby="Overview-tab">
                                <div class="m-2">
                                    <select id="inputState" class="form-select">
                                        <option value="">Select Indent Type</option>
                                        <option [value]="ordTY.id" *ngFor="let ordTY of lstOrdTyps">{{ordTY.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                            <li class="nav-item"><a class="nav-link f-14 p-2 active" id="Overview-1-tab"
                                data-bs-toggle="tab" href="#Overview-1" role="tab" aria-controls="Overview-1"
                                aria-selected="true"><span class="f-10">By tag</span></a></li>
                        </ul>
                        <div class="tab-content" id="top-tabContent">
                            <div class="tab-pane fade show active" id="Overview-1" role="tabpanel"
                                aria-labelledby="Overview-1-tab">
                                <div class="m-2">
                                    <select id="inputState" class="form-select">
                                        <option value="">Select Indent Tag</option>
                                        <option *ngFor="let ot of orderBadges" [value]="ot.name">{{ot.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <form [formGroup]="rprtFltrForm"> -->
                <div class="col-xl-6 mt-3" *ngIf="path == 'rep-asset-capacity' || path == 'rep-asset-status' || path == 'power-availability' 
                    || path == 'rspns-tm-rpar' || path == 'sickness-rate' || path == 'temp-excursion' || path == 'up-time'">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">TYPE <span class="text-danger" style="display: inline;">*</span></div>
                        <div class="card-body p-2">
                            <div>
                                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                    <li class="nav-item"><a class="nav-link f-14 p-2 active" id="TYPE-tab"
                                        data-bs-toggle="tab" href="#TYPE" role="tab" aria-controls="TYPE"
                                        aria-selected="true"><span class="f-10">By monitoring type</span></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link f-14 p-2" id="TYPE-1-tab" data-bs-toggle="tab"
                                        href="#TYPE-1" role="tab" aria-controls="TYPE-1" aria-selected="false"><span
                                        class="f-10">By asset type</span></a></li>
                                </ul>
                                <div class="tab-content" id="top-tabContent">
                                    <div class="tab-pane fade show active" id="TYPE" role="tabpanel"
                                        aria-labelledby="TYPE-tab">
                                        <div class="m-2">
                                            <select id="inputState" class="form-select">
                                                <option selected>All</option>
                                                <option>Monitoring</option>
                                                <option>Monitored</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade pb-3" id="TYPE-1" role="tabpanel"
                                        aria-labelledby="TYPE-1-tab">
                                        <div class="m-2">
                                            <select id="inputState" class="form-select">
                                                <option value="AL">ILR</option>
                                                <option value="WY">Deep freezer</option>
                                                <option value="WY">Walk-in cooler</option>
                                                <option value="WY">Walk-in freezer</option>
                                                <option value="WY">Radient warmer</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                    <li class="nav-item"><a class="nav-link f-14 p-2 active" id="TYPE-2-tab"
                                        data-bs-toggle="tab" href="#TYPE-2" role="tab" aria-controls="TYPE-2"
                                        aria-selected="true"><span class="f-10">By manufacturer</span></a></li>
                                </ul>
                                <div class="tab-content" id="top-tabContent">
                                    <div class="tab-pane fade pb-3 show active" id="TYPE-2" role="tabpanel"
                                        aria-labelledby="TYPE-2-tab">
                                        <div class="m-2">
                                            <select id="inputState" class="form-select">
                                                <option>All</option>
                                                <option>2021</option>
                                                <option>2020</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-3" *ngIf="path == 'demand' || path == 'discrepancies' || path == 'utilization' || path == 'discards' 
                    || path == 'ord-res-time' || path == 'order-status' || path == 'abnormal-stock' || path == 'consumption' || path == 'supply'
                    || path == 'rplnsh-rspn-time' || path == 'stock-availability' || path == 'stock-trends' || path == 'trans-counts'">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">MATERIAL</div>
                        <div class="card-body p-2">
                            <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link f-14 p-2 active" id="Overview-tab"
                                    data-bs-toggle="tab" href="#Overview" role="tab" aria-controls="Overview"
                                    aria-selected="true"><span class="f-10">By tag</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="Overview-1-tab" data-bs-toggle="tab"
                                    href="#Overview-1" role="tab" aria-controls="Overview-1" aria-selected="false"><span
                                    class="f-10"> By name</span></a></li>
                            </ul>
                            <div class="tab-content" id="top-tabContent">
                                <div class="tab-pane fade show active" id="Overview" role="tabpanel"
                                    aria-labelledby="Overview-tab">
                                    <div class="m-2">
                                        <select id="inputState" class="form-select">
                                            <option value="">Select Material Tag</option>
                                            <option [value]="it.name" *ngFor="let it of lstMatTags">{{it.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="Overview-1" role="tabpanel"
                                    aria-labelledby="Overview-1-tab">
                                    <div class="m-2">
                                        <ng-template #cstmProduct let-model="item">
                                            <span class="font-weight-bold">{{model.productName}}</span><br />
                                        </ng-template>
                                        <input name="material" type="text" placeholder="Select Material" id="material"
                                            [(ngModel)]="material" [typeahead]="lstAllMats" autocomplete="new"
                                            [typeaheadItemTemplate]="cstmProduct" typeaheadOptionField="productName"
                                            [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                            (typeaheadOnSelect)="onChngTAH('product', $event)" 
                                            [typeaheadOptionsLimit]="500" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-3" *ngIf="path == 'demand' || path == 'discrepancies' || path == 'ord-res-time' || path == 'order-status'
                    || path == 'abnormal-stock' || path == 'consumption' || path == 'discards' || path == 'rplnsh-rspn-time' || path == 'stock-availability'
                    || path == 'stock-trends' || path == 'supply' || path == 'utilization' || path == 'rep-asset-capacity' || path == 'rep-asset-status' 
                    || path == 'power-availability' || path == 'rspns-tm-rpar' || path == 'sickness-rate' || path == 'temp-excursion' 
                    || path == 'up-time' || path == 'trans-counts'">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">
                            Facility<span class="text-danger" style="display: inline-block;" *ngIf="path=='consumption'">*</span>
                        </div>
                        <div class="card-body p-2">
                            <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link f-14 p-2 active" data-bs-toggle="tab" role="tab" id="STORE-tab"
                                    href="#STORE" aria-controls="STORE" aria-selected="true" (click)="onClkTbs('storeId', null, 'strName')">
                                    <span class="f-10">By tag</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="STORE-1-tab" data-bs-toggle="tab" role="tab"
                                    href="#STORE-1" aria-controls="STORE-1" aria-selected="false" (click)="onClkTbs('storeBadge', '', 'strTag')">
                                    <span class="f-10"> By name</span></a></li>
                            </ul>
                            <div class="tab-content" id="top-tabContent">
                                <div class="tab-pane fade show active" id="STORE" role="tabpanel" aria-labelledby="Overview-tab">
                                    <div class="m-2">
                                        <select id="strTag" class="form-select" (change)="onChngDD($event, 'storeBadge')"> <!-- formControlName="storeBadge" -->
                                            <option value="">Select store tag</option>
                                            <option [value]="it.name" *ngFor="let it of lstFacTags">{{it.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="tab-pane fade " id="STORE-1" role="tabpanel" aria-labelledby="Overview-1-tab">
                                    <div class="m-2">
                                        <ng-template #cstmStore let-model="item">
                                            <span class="font-weight-bold">{{model.storeName}}</span><br />
                                            <span class="fs-12">{{model.location}}</span>
                                        </ng-template>
                                        <input name="store" type="text" placeholder="Select Facility" id="strName"
                                            [(ngModel)]="facility" [typeahead]="lstFcltys" autocomplete="new"
                                            [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                            [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                            (typeaheadOnSelect)="onChngTAH('store', $event)" (keyup)="onChngTAH('store', $event)" 
                                            [typeaheadOptionsLimit]="500" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-3" *ngIf="path == 'rep-asset-capacity' || path == 'rep-asset-status' || path == 'rspns-tm-rpar'
                    || path == 'sickness-rate' || path == 'temp-excursion' || path == 'up-time'">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">MANUFACTURER</div>
                        <div class="card-body p-2">
                            <div>
                                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                    <li class="nav-item"><a class="nav-link f-14 p-2 active" id="MANUFACTURER-tab"
                                        data-bs-toggle="tab" href="#MANUFACTURER" role="tab"
                                        aria-controls="MANUFACTURER" aria-selected="true"><span class="f-10">
                                        By manufacturer</span></a></li>
                                    <li class="nav-item"><a class="nav-link f-14 p-2" id="MANUFACTURER-1-tab"
                                        data-bs-toggle="tab" href="#MANUFACTURER-1" role="tab"
                                        aria-controls="MANUFACTURER-1" aria-selected="false"><span class="f-10">
                                        By model</span></a></li>
                                </ul>
                                <div class="tab-content" id="top-tabContent">
                                    <div class="tab-pane fade show active" id="MANUFACTURER" role="tabpanel"
                                        aria-labelledby="MANUFACTURER-tab">
                                        <div class="m-2">
                                            <select id="inputState" class="form-select">
                                                <option value="">Select manufacturer tag</option>
                                                <option *ngFor="let item of producerBadges" [value]="item.name">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade pb-3" id="MANUFACTURER-1" role="tabpanel"
                                        aria-labelledby="MANUFACTURER-1-tab">
                                        <div class="m-2">
                                            <input type="text" class="form-control" placeholder="Select model name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-3" *ngIf="path == 'demand' || path == 'discrepancies' || path == 'ord-res-time' || path == 'order-status' 
                    || path == 'abnormal-stock' || path == 'consumption' || path == 'discards' || path == 'rplnsh-rspn-time' || path == 'up-time'
                    || path == 'stock-availability' || path == 'stock-trends' || path == 'supply' || path == 'utilization' || path == 'trans-counts'
                    || path == 'rep-asset-capacity' || path == 'rep-asset-status' || path == 'power-availability' || path == 'rspns-tm-rpar'
                    || path == 'sickness-rate' || path == 'temp-excursion' ">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">LOCATION</div>
                        <div class="card-body p-2">
                            <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link f-14 p-2 active" id="LOCATION-tab"
                                    data-bs-toggle="tab" href="#LOCATION" role="tab" aria-controls="LOCATION"
                                    aria-selected="true"><span class="f-10">By state</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="LOCATION-1-tab" data-bs-toggle="tab"
                                    href="#LOCATION-1" role="tab" aria-controls="LOCATION-1" aria-selected="false"><span
                                    class="f-10"> By
                                    District</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="LOCATION-2-tab" data-bs-toggle="tab"
                                    href="#LOCATION-2" role="tab" aria-controls="LOCATION-2" aria-selected="false"><span
                                    class="f-10"> By
                                    taluk/block</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="LOCATION-3-tab" data-bs-toggle="tab"
                                    href="#LOCATION-3" role="tab" aria-controls="LOCATION-3" aria-selected="false"><span
                                    class="f-10">By
                                    city/village</span></a></li>
                            </ul>
                            <div class="tab-content" id="top-tabContent">
                                <div class="tab-pane  fade show active" id="LOCATION" role="tabpanel"
                                    aria-labelledby="LOCATION-tab">
                                    <div class="m-2">
                                        <ng-template #cstmState let-model="item">
                                            <span class="font-weight-bold">{{model.name}}</span> <br />
                                        </ng-template>
                                        <input name="state" type="text" placeholder="Select state" autocomplete="new"
                                            [(ngModel)]="state" [typeahead]="stsLst" [typeaheadScrollable]="true"
                                            [typeaheadItemTemplate]="cstmState" typeaheadOptionField="name"
                                            [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                            (typeaheadOnSelect)="onChngTAH('state', $event)" (keyup)="onChngState($event)" 
                                            [typeaheadOptionsLimit]="500" />
                                    </div>
                                    <div class="m-2" *ngIf="shwStDrpdwn">
                                        <select class="form-select form-select-sm">
                                            <option>Show by District</option>
                                            <option>Show by Taluk/Block</option>
                                            <option>Show by Village/City</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="tab-pane  fade " id="LOCATION-1" role="tabpanel"
                                    aria-labelledby="LOCATION-1-tab">
                                    <div class="m-2">
                                        <ng-template #cstmDistrcit let-model="item">
                                            <span class="font-weight-bold">{{model.name}}</span> <br />
                                        </ng-template>
                                        <input name="distrcit" type="text" placeholder="Select District"
                                            id="distrcit" [(ngModel)]="distrcit" [typeahead]="dstLst"
                                            [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmDistrcit"
                                            typeaheadOptionField="name" [adaptivePosition]="true" class="form-control"
                                            [typeaheadScrollable]="true" autocomplete="new" [typeaheadOptionsLimit]="500"
                                            (typeaheadOnSelect)="onChngTAH('district', $event)" />
                                    </div>
                                </div>
                                <div class="tab-pane  fade " id="LOCATION-2" role="tabpanel"
                                    aria-labelledby="LOCATION-2-tab">
                                    <div class="m-2">
                                        <ng-template #cstmBlock let-model="item">
                                            <span class="font-weight-bold">{{model.name}}</span> <br />
                                        </ng-template>
                                        <input name="block" type="text" placeholder="Select taluk/block" id="block"
                                            [(ngModel)]="block" [typeahead]="blkLst" [typeaheadScrollable]="true"
                                            [typeaheadItemTemplate]="cstmBlock" typeaheadOptionField="name"
                                            [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                            (typeaheadOnSelect)="onChngTAH('block', $event)" autocomplete="new" 
                                            [typeaheadOptionsLimit]="500"/>
                                    </div>
                                </div>
                                <div class="tab-pane  fade " id="LOCATION-3" role="tabpanel"
                                    aria-labelledby="LOCATION-3-tab">
                                    <div class="m-2">
                                        <input type="text" class="form-control" placeholder="Select city/village">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-3" *ngIf="path == 'user-activity'">
                    <div class="card p-0 m-0">
                        <div class="card-header p-2">USER</div>
                        <div class="card-body p-2">
                            <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link f-14 p-2 active" id="USER-tab" data-bs-toggle="tab"
                                    href="#USER" role="tab" aria-controls="USER" aria-selected="true"><span
                                    class="f-10">By tag</span></a></li>
                                <li class="nav-item"><a class="nav-link f-14 p-2" id="USER-1-tab" data-bs-toggle="tab"
                                    href="#USER-1" role="tab" aria-controls="USER-1" aria-selected="false"><span
                                    class="f-10">By name</span></a></li>
                            </ul>
                            <div class="tab-content" id="top-tabContent">
                                <div class="tab-pane  fade show active" id="USER" role="tabpanel"
                                    aria-labelledby="USER-tab">
                                    <div class="m-2">
                                        <select id="inputState" class="form-select">
                                            <option value="">Select user tag</option>
                                            <option *ngFor="let item of userBadges" [value]="item.name">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="tab-pane  fade " id="USER-1" role="tabpanel" aria-labelledby="USER-1-tab">
                                    <div class="m-2">
                                        <input type="text" class="form-control" placeholder="Select User">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </form> -->
        </div>
        <div class="p-3">
            <div class="float-xl-start mb-3">
                <button class="btn btn-sm btn-light me-2" data-toggle="collapse" aria-controls="multiCollapseExample2"
                    href="#multiCollapseExample2" aria-expanded="false" type="submit">Reset</button>
            </div>
            <div class="float-xl-end mb-3">
                <button class="btn btn-sm btn-light me-2" data-toggle="collapse" aria-controls="multiCollapseExample2"
                    href="#multiCollapseExample2" aria-expanded="false" type="submit">Cancel</button>
                <button class="btn btn-sm btn-primary" (click)="onGenRprt()">Get report</button>
            </div>
        </div>
    </div>
</div>