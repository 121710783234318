<div class="card p-0 mb-0">
    <div class="card-header p-2">
        Forms Configuration
    </div>
    <div class="card p-0 border-0 mb-0">
        <div class="card-header border-0 p-2">
            <h6 class="mb-0">Schemas</h6>
        </div>
        <div class="card-body p-2">
            <div class="form-group">
                <textarea class="form-control" rows="10" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
              </div>
        </div>
    </div>
    <div class="m-2">
        <div class="float-xl-end">
         <button class="btn btn-sm btn-primary ">Save</button>
        </div>
    </div>
</div>
