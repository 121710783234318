import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'evin-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent extends BaseComponent implements OnInit {
  bulkUploadForm: FormGroup; uploadStatus= ''; successCount = ''; errorCount = ''; downloadLink = ''; uploadResult = false;
  uploadStatusResult = false; uploadName = ''; smplDwnldFle = '';


  constructor(public glblSvc: GlobalService, private formBuilder: FormBuilder, private router: Router) {
    super(glblSvc);
    this.bulkUploadForm = this.formBuilder.group({uploadedFile: ['', Validators.required], fileName: ''})
   }

  async ngOnInit(): Promise<void> {
    this.uploadName = ((this.router.url.includes('home/setup-fclty')) ? 'Facility' :
    (this.router.url.includes('home/setup-matrls')) ? 
      'Material(s)': (this.router.url.includes('setup-assets')) ? 'Asset(s)' : 'Transaction(s)');

    if (this.router.url.includes('home/setup-fclty'))
      this.smplDwnldFle = environment.webURL + environment.upldFileTmplts.facility; // "../../../../assets/excel/Facility_Bulk_Upload.csv";
    else if (this.router.url.includes('home/setup-matrls'))
    this.smplDwnldFle = environment.webURL + environment.upldFileTmplts.material; // "../../../../assets/excel/Materials_Bulk_Upload.csv";
    else if(this.router.url.includes('home/setup-assets'))
    this.smplDwnldFle = environment.webURL + environment.upldFileTmplts.asset; // "../../../../assets/excel/bulkUploadFormat.csv";
    else
      this.smplDwnldFle = '';
    
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.bulkUploadForm.patchValue({ fileName: file });      
    }
  }

  hasExtension(exts: any) {
    var fileName = this.bulkUploadForm.value.uploadedFile;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  uploadFile() {
    if (this.bulkUploadForm.get('fileName')?.value == '')
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
     else if(this.hasExtension(['.csv'])){
      this.uploadStatusResult = true,  this.uploadStatus = 'In Progress';
      const formData = new FormData();
      formData.append('file', this.bulkUploadForm.get('fileName')?.value);     
      if (this.router.url.includes('setup-assets')) {
        this.onFormData(environment.apiPaths.asset, environment.apiMthds.bulkUpload, formData, 2, true, 3).then(() => {
          if (this.result) {
            if (this.result.returnCode === 1)
              this.onSucuessRes();
            else
              this.onFailRes();
          }
        })
      }
      else if (this.router.url.includes('home/setup-fclty')) {
        this.onFormData(environment.apiPaths.store, environment.apiMthds.bulkSveFaclty, formData, 7).then(() => {
          if (this.result) {
            if (this.result.returnCode === 1)
              this.onSucuessRes();
            else
              this.onFailRes();
          }
        })
      }
      else if (this.router.url.includes('home/setup-matrls')) {
        this.onFormData(environment.apiPaths.product, environment.apiMthds.bulkSveMtrl, formData, 7).then(() => {
          if (this.result) {
            if (this.result.returnCode === 1)
              this.onSucuessRes();
            else
              this.onFailRes();
          }
        })
      }
    } else{
      this.glblSvc.onTstr('w', 'Upload only CSV files'), this.bulkUploadForm.reset();
    }   
  }
  onDwnld() {
    if (this.downloadLink)
      this.OpenFileInNewTab(this.downloadLink);
  }

  onSucuessRes() {
    this.uploadResult = true, this.uploadStatus = 'Done', this.successCount = this.result.data.successCount + ' success', this.errorCount = this.result.data.errorCount + ' error(s)',
     this.downloadLink = this.result.data.downloadUrl, this.glblSvc.onTstr('s', this.result.message), this.bulkUploadForm.reset();
  }

  onFailRes() {
    this.uploadStatusResult = false, this.uploadResult = false, this.uploadStatus = 'Failed', this.successCount = '', this.errorCount = '',
    this.glblSvc.onTstr('e', this.result.message), this.bulkUploadForm.reset();
  }

  onReset() {
    this.bulkUploadForm.reset();
  }

}
