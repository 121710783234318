import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-sent-notifications',
  templateUrl: './cnfg-sent-notifications.component.html',
  styleUrls: ['./cnfg-sent-notifications.component.css']
})

export class CnfgSentNotificationsComponent extends BaseComponent implements OnInit {

  dt = new Date(); toMin = new Date(); lstGrid: any[] = []; noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; totalItems: any;
  pageSize = environment.tblPageSize; sntNtfcnForm = this.fb.group({ fromDate: ['', Validators.required], tooDate: ['', Validators.required] });
  usrPrmsn: any = null; cnfgntfnPrmsn = true; ctDte = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)); defToDate: any = '';

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.LoadGrid();
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_nf_ac') ? (this.cnfgntfnPrmsn = e.action) : '') });
    }
  }

  onChngDt(val: any, type: any) {
    if (val != null) {
    if (type=='fd')
      this.sntNtfcnForm.get('fromDate')?.setValue(val), this.sntNtfcnForm.get('tooDate')?.setValue(''), this.toMin = val;
    }
  }

  LoadGrid() {
    let payLoad = this.sntNtfcnForm.getRawValue(), frmDt = '', toDt = '';

    if (payLoad.fromDate != '' && payLoad.fromDate != null && payLoad.fromDate != undefined)
      frmDt = this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd);
    else 
      frmDt = this.glblSvc.setDtFrmt(this.ctDte, this.dtF.ymd);
    if (payLoad.tooDate != '' && payLoad.tooDate != null && payLoad.tooDate != undefined)
      toDt = this.glblSvc.setDtFrmt(payLoad.tooDate, this.dtF.ymd);
    else 
      toDt = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd), this.defToDate = toDt;
    this.lstGrid = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getNtfcnLog.
        replace('fff', frmDt).replace('ttt', toDt).replace('pageNo', `${this.crntPage - 1}`).
        replace('pageSize', `${this.pageSize}`), null, 2).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstGrid = this.result.data?.userNotificationLogs != null ? this.result.data?.userNotificationLogs : [],
              this.totalItems = this.result.data?.totalCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        }
      }
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadGrid();
  }

  clearBSDate(ctrl: string) {
    this.sntNtfcnForm.get(ctrl)?.setValue('');
  }

  OnCncl() {
    this.sntNtfcnForm.reset(), this.ctDte = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)), 
    setTimeout(() => { this.LoadGrid() }, 1000); 
  }
}