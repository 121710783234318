import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { PrchsIndntFulfillMdlComponent } from 'src/app/Components/evin/Orders/prchs-indent/prchs-indnt-fulfill-mdl/prchs-indnt-fulfill-mdl.component';
import { ParMdlComponent } from 'src/app/Components/evin/Orders/prchs-indent/par-mdl/par-mdl.component';

@Component({
  selector: 'evin-prchs-indnt-fulfull',
  templateUrl: './prchs-indnt-fulfull.component.html',
  styleUrls: ['./prchs-indnt-fulfull.component.css']
})
export class PrchsIndntFulfullComponent extends BaseComponent implements OnInit {

  orderStatus: any; lstMats: any[] = []; arDate: any = new Date(); fulfillFrm: FormGroup = new FormGroup({}); fileNme: any = '';
  orderId: any; ordrSts = ''; rcvdRsn = ''; isShowRsnOthrs = false; rsnsLst: any[] = []; dt2Day = new Date(); isFleUpld: boolean =false;
  rsnMand = false; isFulfill = false; paramTabName: any; isReqLL = '0'; isSbmtd = false; isUplodSucss: boolean = false;
  flseBtchQntuty: any = ''; isNBtchRsn: any = ''; isNbtchOthrRsn: any = ''; btchLst: any[] = []; 

  constructor(public glblSvc: GlobalService,
    private ngbMdl: NgbModal
    , private router: Router
    , public dtPipe: DatePipe
    , private formBuilder: FormBuilder, private route: ActivatedRoute) {
    super(glblSvc);

    this.glblSvc.sbjctodrStst.subscribe((s: any) => { this.btchLst = s[0], this.orderStatus = s[1], this.orderId = s[1]?.conisgneeBookingId }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.rsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.fulfill_indent);
    });
    this.rsnMand = this.rsnsLst?.filter(a => a.isReasonMandatory == true).length > 0;

    this.btchLst?.forEach((e: any) => { Object.assign(e, { isRsn: false, reasonId: null, reasonName: '', RCquantity: e.batchQty }); }),

      this.flseBtchQntuty = this.orderStatus?.shippedQuantity;
    this.fulfillFrm = this.formBuilder.group({ arDate: [this.arDate], comment: [''], consigneeBookingId: [this.orderStatus?.consigneeBookingId], 
    fileName: [''], fileFormat: [''] });
  }


  ngOnInit(): void {

  }

  openModal(c:string) {
    // localStorage.setItem('sbjctOrdShipDtls', JSON.stringify(this.orderStatus)), this.glblSvc.sbjctOrdSmry.next(this.orderStatus);
    // const modalRef = this.ngbMdl.open(PrchsIndntFulfillMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    if (c == 'v') {
      this.ngbMdl.open(PrchsIndntFulfillMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    } else {
      this.ngbMdl.open(ParMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    }
    // modalRef.componentInstance.orderStatusData = this.orderStatus;
    // modalRef.result.then((result: any) => {
    //   if (result == 'done')
    //     this.routingToLocation(); // setTimeout(() => { window.location.reload(); }, 1000);
    // }, (r: any) => { console.log(r) });
  }

  clearBSDate(ctrl: string) {
    this.fulfillFrm.get(ctrl)?.setValue('');
  }

  onChngRsn(evnt: any, indx: any) {
    this.btchLst[indx]['reasonId'] = evnt?.target?.value
  }
  onChngRsnBath(evnt: any, bIndex: any) {
    this.btchLst[bIndex]['reasonName'] = evnt?.target?.value;
  }

  onChngRcvd(evnt: any, bIndex: any) {
    this.btchLst[bIndex]['RCquantity'] = evnt?.target?.value, this.btchLst[bIndex]['isRsn'] = this.btchLst[bIndex]['batchQty'] != evnt?.target.value;
  }

  onChkLL() {
    // if (this.fulfillFrm.get('fileName')?.value != '') 
    this.fulfillFrm.get('fileName')?.value != '' ? this.uploadFile() : '';
    // if (this.isUplodSucss) {
      if (this.isReqLL == '1') {
        this.GetLatLon(), setTimeout(() => {
          if (this.usrLat != undefined && this.usrLon != undefined)
            this.fulFilShip();
          else {
            setTimeout(() => {
              if (this.usrLat == undefined || this.usrLon == undefined)
                this.glblSvc.onTstr('i', 'Kindly provide Location access to Fullfill Indent..!');
            }, 2000);
          }
        }, 500);
      }
      else
        this.fulFilShip();
    // }
  }

  fulFilShip() {
    let batches: any[] = [], mats: any[] = [], arDate = this.dtPipe.transform((this.fulfillFrm.get('arDate')?.value), 'yyyy-MM-dd HH:mm:ss'), btchVldn: boolean = false,
    payLoad: any = {
      fulfillDate: arDate, comments: this.fulfillFrm.get('comment')?.value,
      fulfillProduct: [], storeId: this.orderStatus?.storeId, consgineeBookingId: this.orderStatus?.conisgneeBookingId,
      dateOfActualReciet: arDate, pranthId: this.chsnDmnId, sourceType: '2', userId: this.usrLgnDtls.userId, fulfillProductBatch: [],
      latitude: (this.usrLat != '' && this.usrLat != null && this.usrLat != undefined) ? +this.usrLat : null,
      longitude: (this.usrLon != '' && this.usrLon != null && this.usrLon != undefined) ? +this.usrLon : null
    }

    if (this.orderStatus?.batchEnabled == true) {
      this.btchLst.filter(a => {
        if (a.isRsn == true && a.reasonId == null)
          btchVldn = true;
        else if (a.reasonId == 16 && a.reasonName.length == 0)
          btchVldn = true;
        else
          btchVldn = false;

        batches.push({
          batchNo: a.batchNo, expiryDate: a.expDate, fulfillQuantity: a.RCquantity, manufacturedDate: a.mfgDate,
          producerId: this.orderStatus?.producerId, productId: this.orderStatus?.productId, quantity: a.batchQty, reasonId: a.reasonId,
          reasonName: a.reasonName, shippedQuantity: a.batchQty
        })
      })

      payLoad.fulfillProductBatch = batches.length == 0 ? [] : batches, payLoad.fulfillProduct = [];
    }
    else {
      if (this.flseBtchQntuty != this.orderStatus?.shippedQuantity && this.isNBtchRsn == '')
        btchVldn = true;
      else if (this.isNBtchRsn == '16' && this.isNbtchOthrRsn?.length == 0)
        btchVldn = true;
      else
        btchVldn = false;
      
        payLoad.fulfillProductBatch = [], payLoad.fulfillProduct = mats;
    }

    mats.push({
      productId: this.orderStatus?.productId, quantity: this.orderStatus?.orderedQuantity != null ? +this.orderStatus?.orderedQuantity : 0,
      reasonId: this.isNBtchRsn != '' ? this.isNBtchRsn : null, reasonName: +this.isNBtchRsn == 16 ? this.isNbtchOthrRsn : null,
      fulfillQuantity: this.flseBtchQntuty != '' ? this.flseBtchQntuty : 0, shippedQuantity: this.orderStatus?.orderedQuantity != null ?
        this.orderStatus?.orderedQuantity : 0
    });
   
    if (!btchVldn) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.prchsShip, environment.apiMthds.fulfilBooking, payLoad, 12).then(() => {
        if (this.result) {
          console.log(this.result);
          if (this.result.status == 'OK' && this.result.returnCode == 1)
            this.glblSvc.onTstr('s', this.result.message), this.router.navigate(['/home/prchs-ord-sts']);          
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
    else
      this.glblSvc.onTstr('w', 'Enter all the mandatory fileds');
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];      
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        this.fulfillFrm.patchValue({ fileName: '' })
      }
      else {
        this.fulfillFrm.patchValue({ fileFormat: file });
      }
    }
  }

  hasExtension(exts: any) {
    var fileFormat = this.fulfillFrm.value.fileName;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileFormat);
  }

  uploadFile() {
    if (this.fulfillFrm.get('fileName')?.value == '')
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
    else if(this.hasExtension(['.pdf'])) {
      // this.uploadStatusResult = true,  this.uploadStatus = 'In Progress';
      const formData = new FormData();
      formData.append('file', this.fulfillFrm.get('fileFormat')?.value);     
      this.onFormData(environment.apiPaths.prchsShip, environment.apiMthds.fulFillUpld.replace('iii', this.orderId ?? null).
        replace('fff', this.orderStatus?.batchEnabled ? '64' : '80'),//this.orderStatus?.consigneeBookingId
      formData, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message);
            // Full fill API
            // if (this.isReqLL == '1') {
            //   this.GetLatLon(), setTimeout(() => {
            //     if (this.usrLat != undefined && this.usrLon != undefined)
            //       this.fulFilShip();
            //     else {
            //       setTimeout(() => {
            //         if (this.usrLat == undefined || this.usrLon == undefined)
            //           this.glblSvc.onTstr('i', 'Kindly provide Location access to Fullfill Indent..!');
            //       }, 2000);
            //     }
            //   }, 500);
            // }
            // else
            //   this.fulFilShip();
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
    } else {
      this.glblSvc.onTstr('w', 'Upload only PDF files'), this.fulfillFrm.reset();
    }   
  }

  cancel() {
    this.router.navigate(['/home/prchs-ord-sts']);  
  }

  isNoBtchRsnChng(evnt: any) {
    this.isNBtchRsn = evnt.target.value;
  }
}