<form [formGroup]="orderlstfrm" autocomplete="off" class="p-2">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="form-row row mb-2">
                        <div class="form-group col-xl-3" *ngIf="dfltPrdcrID == null">
                            <label for="textBox">Manufacturer</label>
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.producerName}}</span> <br />
                                <!-- <span class="fs-12">{{model.producerId}}</span> -->
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Manufacturer" class="form-control form-control-sm"
                                formControlName="producerId" [typeahead]="lstManfacturs" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="producerName" id="Facility"
                                (typeaheadOnSelect)="onChngFclty($event, 'slct')" (change)="onChngFclty($event, 'chng')"
                                [adaptivePosition]="true" [typeaheadOptionsLimit]="500" />
                        </div>
                        <div class="form-group col-xl-3" *ngIf="dfltPrdcrID != null">
                            <label for="textBox">Manufacturer</label>
                            <input type="text" [attr.disabled]="true" formControlName="manfName" class="form-control form-control-sm">
                        </div>
                        <div class="form-group col-xl-3" *ngIf="false">
                            <label for="textBox">Status</label>
                            <select id="inputState" class="form-select form-select-sm" formControlName="status">
                                <option value="">All</option>
                                <option *ngFor="let os of orderStatusList" [value]="os.name">{{os.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">From</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)" 
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="fromDate"
                                    [(ngModel)]="frmDate" placeholder="From" [maxDate]="dt2day" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('fromDate')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox">To</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                    [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)" (bsValue)="ctDte"
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="toDate" [(ngModel)]="toDateF"
                                    bsDatepicker [minDate]="frmDate" [maxDate]="dt2day" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                        [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpED.hide();clearBSDate('toDate')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label class="textBox">Material By</label>&nbsp;
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="badge"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('materialBadge')"
                                    formControlName="isStkMatBdge" [checked]="isStkMatBdge=='badge'">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isStkMatBdge" value="name"
                                    [(ngModel)]="isStkMatBdge" (click)="onClkRdb('material')"
                                    [checked]="isStkMatBdge=='name'" formControlName="isStkMatBdge">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div *ngIf="isStkMatBdge=='badge'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdStkMat" placeholder=""
                                    (onSelect)="onMultiSlct('s', 'n', $event)" [data]="lstMatTags1"
                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)" formControlName="stkMtrlBdge">
                                </ng-multiselect-dropdown>
                            </div>
                            <div *ngIf="isStkMatBdge=='name'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats" placeholder=""
                                    (onSelect)="onMultiSlct('s', 'f', $event)" 
                                    (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'f', $event)" formControlName="stkMtrlNme">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>	
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-12">
                    <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
                        aria-controls="box1">Advance Search</button>
                </div>
            </div>
            <div class="clearfix"></div>
            <div id="box1" class="collapse">
                <div class="form-row row mt-2">
                    <div class="form-group col-xl-3">
                        <label for="textBox">eVIN Indent Id</label>
                        <input type="text" placeholder="eVIN Indent Id" class="form-control form-control-sm" id="bookingID"
                            formControlName="bookingId">
                    </div>
                    <!-- <div class="form-group col-xl-3">
                        <label class="textBox">Facility Category:</label>
                            <select class="form-select form-select-sm" formControlName="storeBadgeId">
                                <option value="null">-Select-</option>
                                <option *ngFor="let sb of lstFacTags" [value]="sb.id">{{sb.name}}</option>
                            </select>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;LoadOrders()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <!-- <div class="row" *ngIf="ordersList?.length!=0">
                <div class="col-xl-3 mb-2">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>-->
            <div class="row mb-2" *ngIf="ordersList?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-striped table-sm table-bordered" id="table_detail">
                    <thead>
                        <tr>
                            <th></th>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">eVIN Indent Id</th>
                            <th class="text-center">Manufacturer Name</th>                           
                            <th>Product Name</th>
                            <th>Order Quantity</th>
                            <th>Supply Period</th>
                            <th>Created On</th>                            
                            <!-- <th></th> -->
                        </tr>
                    </thead>
                    <tbody class="table_data">
                        <tr *ngFor="let item of ordersList | search:searchText:filterMetadata |
                            paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                            let i=index" (click)="onOrdIdClk(item.produerBookingId)" style="cursor:pointer">
                            <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                            <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                            <td class="text-center">{{item?.produerBookingId}}</td>
                            <td class="text-center">{{item?.producerName}}</td>                            
                            <td><div class="small"><a>{{item?.productName}}</a></div></td>
                            <td class="text-center">{{item?.quantity}}</td>
                            <td>{{item?.supplyPeriodFrom | date: 'MM/yyyy'}} - {{item?.supplyPeriodEnd | date: 'MM/yyyy'}}</td>
                            <td>
                                <a>{{item.createdBy}}</a>
                                <div class="small">{{item?.userId}} <br> {{item?.createdOn | date: "dd/MM/yyyy hh:mm aa"}}</div>
                            </td>                           
                            <!-- <td class="text-center" style="width: 7%;">
                                <span class="f-14">
                                    <i class="fa fa-mobile" aria-hidden="true" *ngIf="item?.sourceType==1"></i>
                                    <i class="fa fa-desktop" aria-hidden="true" *ngIf="item?.sourceType==2"></i>
                                </span>
                            </td> -->
                        </tr>
                    </tbody>
                    <tbody *ngIf="ordersList?.length==0 || count==0">
                        <tr>
                            <td colspan="9" class="text-center">
                                {{noRcrdsTxt}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="ordersList?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>