import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-rplnsh-rspn-time-locations',
  templateUrl: './rplnsh-rspn-time-locations.component.html',
  styleUrls: ['./rplnsh-rspn-time-locations.component.css']
})
export class RplnshRspnTimeLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
