import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-cnfg-add-notifications',
  templateUrl: './cnfg-add-notifications.component.html',
  styleUrls: ['./cnfg-add-notifications.component.css']
})
export class CnfgAddNotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
