import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-shipments-fulfill',
  templateUrl: './shipments-fulfill.component.html',
  styleUrls: ['./shipments-fulfill.component.css']
})
export class ShipmentsFulfillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
