import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-transfers-create',
  templateUrl: './transfers-create.component.html',
  styleUrls: ['./transfers-create.component.css']
})

export class TransfersCreateComponent extends BaseComponent implements OnInit {

  lstStrs: any[] = []; lstLinkedStrs: any[] = []; issFacId: any; recFacId = ''; str = ''; lstAllMats: any[] = []; lstMats: any[] = [];
  trnfCrtFrm: FormGroup; ordrType = '1'; isSbmtd = false; noRcrdsTxt = environment.TblNoRcrds; orderType = '2'; lstTags: any[] = [];
  lstOrdTyps: any[] = []; rsnsLst: any[] = []; facSlctTxt = environment.OrdrFacSlct; dt2Day: Date = new Date(); isRsnMndtry = false;
  issPranth:any; 

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    public dtPipe: DatePipe,
    private router: Router) {
    super(glblSvc);
    this.trnfCrtFrm = this.fb.group({ badgeId: [null], orderTypeId: [4], srchMtrl: [''], transactionType: [1], srcType: [2],
      kioskid: [null, Validators.required], vkioskid: [null, Validators.required], comments: [null], arrivalDate: [],
      orderReferenceNo: [''], updatedOn: [], receiptDate: [], productBookings: this.fb.array([]), bookingBadge: [''],
      confirmEndDate: [], confirmStartDate: [], createdOn: [], isDeleted: [false], itemsCount: [100], transferReferenceNo: [null] }),
      this.glblSvc.sbjctStrList.subscribe(s => { this.lstStrs = s, this.usrStrLen = s.length; if (this.usrStrLen == 1)
        this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1'); }), this.glblSvc.sbjctMstrData.subscribe(s => {
        this.lstOrdTyps = s.ordTypeLst, this.rsnsLst = s.rsnsLst.filter((f: any) => f.reasonType == "New Booking") }),
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstTags = s.filter(f => f.badgeTypeId == environment.tagTypes.order) }),
      this.isRsnMndtry = this.rsnsLst.filter(a => a.isReasonMandatory == true).length > 0;
  }

  ngOnInit(): void {
    
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  clearBSDate(ctrl: string) {
    this.trnfCrtFrm.get(ctrl)?.setValue('');
  }

  change($event: any) {
    this.ordrType = $event.target.value;
  }

  onEditQty(matItem: any) {
    if (matItem?.get('recommendedQuantity')?.value != matItem?.get('quantity')?.value && this.isRsnMndtry)
      matItem.get('reason')?.setValidators([Validators.required]);
    else
      matItem.get('reason')?.setValidators(null);
    matItem.get('reason')?.updateValueAndValidity();
  }

  changeFn(event: any, ordrType: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == '' || evnt == undefined)
      [this.str, this.issFacId] = [event.value, ''];
    else {
      if (strType == 'main') {
        if (this.lstStrs.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstStrs.length; s++) {
            if (this.lstStrs[s].storeName == evnt) {
              this.issFacId = this.lstStrs[s].storeId;
              if (evntType === 'str1')
                this.trnfCrtFrm.get('kioskid')?.setValue(evnt);
            }
          }
        else
          this.issFacId = '', this.recFacId = '', this.trnfCrtFrm.get('kioskid')?.setValue(null);
      }
      else {
        if (this.lstLinkedStrs.filter(a => a.mapped_store_name == evnt).length != 0) {
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].mapped_store_name == event.value)
              this.recFacId = this.lstLinkedStrs[s].mapped_store_id;
          }
        }
        else
          this.recFacId = '';
      }
    }
    this.trnfCrtFrm.get('vkioskid')?.setValue(null), this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0,
      this.linkedPranths(this.issFacId);
  }

  onChngRecFclty(event: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if (evnt == '' || evnt == undefined)
      this.recFacId = '';
    else {
      if (strType == 'main') {
        if (this.lstStrs.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstStrs.length; s++) {
            if (this.lstStrs[s].storeName == event.value)
              this.issFacId = this.lstStrs[s].storeId;
          }
        else
          this.issFacId = '', this.recFacId = '', this.trnfCrtFrm.get('vkioskid')?.setValue(null);
      }
      else {
        if (this.lstLinkedStrs.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].storeName == evnt)
              this.recFacId = this.lstLinkedStrs[s].storeId;
          }
          else
            this.recFacId = '', this.trnfCrtFrm.get('vkioskid')?.setValue(null);
      }
    }
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0;
    if (this.recFacId != '')
      this.LoadMatsbyStoreId(this.issFacId, this.recFacId);
  }

  linkedPranths(kioskId: any) {
    if (kioskId)
      this.lstLinkedStrs = [...this.lstStrs], this.lstLinkedStrs = this.lstLinkedStrs.filter(e => e.storeId !== kioskId);
  }

  LoadMatsbyStoreId(issFacId: number, recFacId: any) {
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0, this.FetchData(environment.apiCalls.get,
        environment.apiPaths.store, environment.apiMthds.invMats.replace('sss', `${issFacId}`).replace('rrr', `${recFacId}`).
        replace(environment.noPgLen, ''), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats,
            this.issPranth = this.lstAllMats[0]?.pranthId;
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  slctdMat($event: any) {
    let matName = $event.value;
    if (matName != '') {
      if (this.lstAllMats.filter(f => f.productName == matName).length > 0) {
        let avlbStk = this.lstAllMats.filter(f => f.productName == matName)[0].availableStock,
          stockIndicator = this.lstAllMats.filter(f => f.productName == matName)[0].stockIndicator;
        this.GetMatRcmnd(this.recFacId, this.lstAllMats.filter(f => f.productName == matName)[0].productId, matName, avlbStk, stockIndicator); // this.issFacId
      }
    }
  }

  onDelMtrls(tabIndex: any, matName: any) {
    this.GetMatrls().removeAt(tabIndex), this.lstMats.push(this.lstAllMats.filter(f => f.productName == matName)[0]),
      this.lstMats.sort((a, b) => a.productId - b.productId);
  }

  GetMatRcmnd(strId: any, matId: any, matName: any, avlStock: any, stockIndicator: any) {
    if (strId && matId && matName) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getRecmndMats.
          replace('sss', strId).replace('mmm', matId), null).then(() => {
        if (this.result) {
          this.lstMats = this.lstMats.filter(f => f.productName != matName),
            this.GetMatrls().push(this.onAddFormCtrls(matName, matId, avlStock, this.result.data.recmndQuty, stockIndicator)),
            this.trnfCrtFrm.get('srchMtrl')?.setValue(''), this.lstMats = this.lstMats.filter(f => f.productName != matName);
        }
      });
    }
  }

  GetMatrls(): FormArray {
    return this.trnfCrtFrm.get('productBookings') as FormArray;
  }

  onAddFormCtrls(matName: string, mId: any, avlStock: any, recVal: any, stockIndicator: any, indx: number = 0) {
    let matData = this.lstAllMats.filter(f => f.productId === mId)[0];
    return this.fb.group({ productId: [mId, [Validators.required]], name: [matName, [Validators.required]], ts: avlStock, others: [''],
      quantity: [recVal, [Validators.required, Validators.minLength(1), Validators.min(1), Validators.maxLength(10),
        Validators.pattern(environment.patterns.numericsby10)]], reason: [''], recommendedQuantity: recVal, modifiedReason: [null],
      intransitStock: [matData.intransitStock], invMin: [matData.invMin], invMax: [matData.invMax], stockIndicator: stockIndicator });
  }

  onClrData() {
    $('#Store').val(''), this.str = '', this.lstLinkedStrs = [], this.issFacId = '', this.lstMats = [], this.isSbmtd = false, this.onClrMatData();
  }

  onClrMatData() {
    this.lstMats = [], (<FormArray>this.trnfCrtFrm.get('productBookings')).controls = [], this.trnfCrtFrm.controls.srchMtrl.setValue(''),
      this.trnfCrtFrm.controls.vkioskid.setValue(''); this.trnfCrtFrm.controls.kioskid.setValue('');
    if (this.lstStrs.length == 1) this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1');
  }

  onCreateRlsOrdr(ordrType: any) {
    this.checkFormValidations(this.trnfCrtFrm), this.checkFormArrayValidations(this.trnfCrtFrm.controls['productBookings']);
    if (this.trnfCrtFrm.invalid || this.trnfCrtFrm.controls['productBookings'].invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else {
      let payLoad = this.trnfCrtFrm.getRawValue(), arDate = this.trnfCrtFrm.get('arrivalDate')?.value != null ?
        this.dtPipe.transform((this.trnfCrtFrm.get('arrivalDate')?.value), 'yyyy-MM-dd') + ' 00:00:00' : null, notRcmndCnt = 0;
      payLoad.receivingStoreId = ordrType == 1 ? (this.recFacId != '' ? +this.recFacId :null) : (this.issFacId != '' ? +this.issFacId : null), 
        // payLoad.receivingStoreId = this.lstLinkedStrs.filter(f => f.mapped_store_name == this.trnfCrtFrm.controls.vkioskid.value)[0].mapped_store_id,
        payLoad.issuingStoreId = ordrType == 1 ? (this.issFacId != '' ? +this.issFacId :null) : (this.recFacId != '' ? +this.recFacId : null), 
        payLoad.arrivalDate = arDate, payLoad.confirmEndDate = arDate, payLoad.confirmStartDate = arDate, payLoad.createdOn = arDate,
        payLoad.updatedOn = arDate, payLoad.receiptDate = arDate, payLoad.productBookings.forEach((e: any) => { delete e.name, delete e.ts,
          delete e.others, delete e.stockIndicator, e.quantity = (e.quantity != null && e.quantity != '') ? +e.quantity : 0, 
          e.recommendedQuantity = (e.recommendedQuantity != null && e.recommendedQuantity != '') ? +e.recommendedQuantity : 0;
          if (e.quantity != e.recommendedQuantity)
            notRcmndCnt++;
        }), delete payLoad.srchMtrl, delete payLoad.kioskid, delete payLoad.vkioskid,
        payLoad.pranthId = this.issPranth, payLoad.sourceType = '2',
        payLoad.updatedBy = this.usrLgnDtls.userId, 
        payLoad.bookingBadge = (payLoad.bookingBadge != null && payLoad.bookingBadge != '')? +payLoad.bookingBadge : null, 
        payLoad.badgeId = (payLoad.badgeId != null && payLoad.badgeId != '')? +payLoad.badgeId : null,
        payLoad.createdBy = payLoad.updatedBy;
      if (notRcmndCnt > 0) {
        if (confirm(`The entered quantity of ${notRcmndCnt} material(s) do not match the recommended quantity. Do you wish to continue?`))
          this.SaveRlsIndnt(payLoad);
      }
      else
        this.SaveRlsIndnt(payLoad);
    }
  }

  SaveRlsIndnt(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.trnsfrSave, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.data != null)
          localStorage.removeItem("isTrnsfrAPICald"), this.glblSvc.onTstr('s', this.result.message), this.onReset(),
          localStorage.setItem('ordrCreatePL', JSON.stringify(payLoad)), this.glblSvc.sbjctTabsPage.next('trsf-list'),
          localStorage.removeItem('isTrnsfrAPICald'), this.issPranth = '',
          this.router.navigate(['home/ord-transfers'], { queryParams: { tab: 'list', type: 't' }});
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onReset() {
    this.trnfCrtFrm.reset(), this.trnfCrtFrm.get('orderTypeId')?.setValue(4), this.glblSvc.sbjctOrdId.next(0), this.GetMatrls().controls.length = 0;
    if (this.lstStrs.length == 1)
      this.changeFn(this.lstStrs[0].storeName, '1', 'main', 'str1');
  }

}