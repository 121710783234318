import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

declare let $: any;

@Component({
  selector: 'evin-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.css']
})

export class FacilityDetailsComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {

  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; asetType: any[] = []; lstMatTags: any[] = []; lstMatTagsStk: any[] = []; lstMats1: any[] = [];
  lstSlctdMatTags: any[] = []; lstAllMats1: any[] = []; asstsList: any; lstPrnts: any[] = []; astTypLst: any[] = []; astTypeMntrg: any[] = [];
  astTypeMntrd: any[] = []; slctdFcltyId: any; matsLST: any[] = []; pageEntrs = ''; stkPageEntrs = ''; astPgeEntrs = ''; mtrlPageEntrs = '';
  crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; astStatusList: any[] = []; isShowAstdtls = true; lstFreeMtrls: any[] = [];
  strDetails: any; strMappingsLst: any[] = []; IssuFaciLst: any[] = []; RecFaciLst: any[] = []; noRcrdsTxt = environment.TblNoRcrds;
  addStatus = true; lstAllFacilities: any[] = []; mappingType: any = 'v'; strIds: any[] = []; lstFacTags: any[] = []; slctdMat: any; strId: any;
  facltyDtlsFrm: FormGroup = this.fb.group({}); isSbmtd = false; isShowMat = true; routerTabTyoe: any; strPrfle: any; mtrlList: any[] = [];
  lstSlctdStkMatTags: any[] = []; totalStkItems: any; crntStkPage = 1; lstMat: any[] = []; slctdMatId: any; isEdtAd = false; iCatlog: any;
  
  @ViewChild('stk') stk: any; @ViewChild('ind') ind: any; @ViewChild('txn') txn: any; @ViewChild('mtrl') mtrl: any;
  @ViewChild('rlnshp') rlnshp: any; @ViewChild('stbrd') stbrd: any; @ViewChild('asts') asts: any; @ViewChild('prfle') prfle: any;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder, private ngbMdl: NgbModal,
    private router: Router, public route: ActivatedRoute, private cdRef: ChangeDetectorRef) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
        this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); this.lstMatTagsStk = this.lstMatTags; });
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstPrnts = s; }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astStatusList = s.stsLst.filter((f: any) => f.statusType === 'Asset') }), 
      this.glblSvc.sbjctMstrData.subscribe(s => { this.astTypeMntrd = this.astTypLst.filter(f => f.deviceTypeId == 2),
      this.astTypLst = s.astTypLst, this.lstMat = s.allMats, this.astTypeMntrg = this.astTypLst.filter(f => f.deviceTypeId == 1);
    });

  }

  ngOnInit(): void {
    this.facltyDtlsFrm = this.fb.group({ asetTypeId: [''], alarms: ['0'], relationships: [''], serialNumber: [''], approvalStatus: [''],
    statusId: [''], assetId: [''], assetTypes: [''], includeProductBadge: [''], productId: [null, Validators.required], 
    minStock: ['', Validators.required], maxStock: ['', Validators.required] });
    this.route.queryParams.subscribe(params => { this.routerTabTyoe = params.tab; });
    this.glblSvc.sbjctStrId.subscribe((s: any) => {
      this.strId = s;
      if (s > 0)
        this.loadStoreData(this.strId), this.LoadMatsbyStoreId1(this.strId), this.LoadAssets(), this.getStrPrfle(), this.getAllMtrlLst(),
          this.LoadstrMappings('v', this.strId), this.LoadstrMappings('c', this.strId), this.loadFacilities();
      else
        this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'list' } })
    });
  }

get fc() { return this.facltyDtlsFrm.controls; }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    // let el: HTMLElement = this.asts.nativeElement;
    //   el.click();
    let actvntrl = this.routerTabTyoe;
    if (actvntrl == 's') {
      let el: HTMLElement = this.stk.nativeElement;
      el.click();
    }
    else if (actvntrl == 'i') {
      let el: HTMLElement = this.stk.nativeElement;
      el.click();
    }
    else if (actvntrl == 't') {
      let el: HTMLElement = this.txn.nativeElement;
      el.click();
    }
    else if (actvntrl == 'm') {
      let el: HTMLElement = this.mtrl.nativeElement;
      el.click();
    }
    else if (actvntrl == 'r') {
      let el: HTMLElement = this.rlnshp.nativeElement;
      el.click();
    }
    else if (actvntrl == 'b') {
      let el: HTMLElement = this.stbrd.nativeElement;
      el.click();
    }
    else if (actvntrl == 'a') {
      let el: HTMLElement = this.asts.nativeElement;
      el.click();
    }
    else {
      let el: HTMLElement = this.prfle.nativeElement;
      el.click();
    }
  }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'asetTypeId') {
      if (evntType == 'os')
        this.asetType.push(item.id);
      else if (evntType == 'osa')
        this.asetType = [], this.asetType = item.id;
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id);
      else
        this.asetType = [];
    }
  }

  onTabClck(cntrl: any) {
    if (cntrl == 's')
      this.routerTabTyoe = 's', this.router.navigate([], { queryParams: { tab: 's', type: 'list' } });
    else if (cntrl == 'i')
      this.routerTabTyoe = 'i', this.router.navigate([], { queryParams: { tab: 'i', type: 'list' } });
    else if (cntrl == 't')
      this.routerTabTyoe = 't', this.router.navigate([], { queryParams: { tab: 't', type: 'list' } });
    else if (cntrl == 'm')
      this.routerTabTyoe = 'm', this.router.navigate([], { queryParams: { tab: 'm', type: 'list' } });
    else if (cntrl == 'r')
      this.routerTabTyoe = 'r', this.router.navigate([], { queryParams: { tab: 'r', type: 'list' } });
    else if (cntrl == 'b')
      this.routerTabTyoe = 'b', this.router.navigate([], { queryParams: { tab: 'b', type: 'list' } });
    else if (cntrl == 'a')
      this.routerTabTyoe = 'a', this.router.navigate([], { queryParams: { tab: 'a', type: 'list' } });
    else
      this.routerTabTyoe = 'p', this.router.navigate([], { queryParams: { tab: 'p', type: 'list' } });
  }

  onMultiSlct(type: string, ctrl: any, item: any) {
    if (ctrl == 'stk') {
      if (type == 's') {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
      else if (type == 'r') {
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
      }
      else if (type == 'a') {
        this.lstSlctdMatTags = this.lstMatTagsStk;
      }
      else {
        this.lstSlctdMatTags = [];
      }
    } 
    else {
      if (type == 's') {
        if (this.lstSlctdStkMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdStkMatTags.push(item);
      }
      else if (type == 'r') {
        this.lstSlctdStkMatTags = this.lstSlctdStkMatTags.filter(f => f.id != item.id);
      }
      else if (type == 'a') {
        this.lstSlctdStkMatTags = this.lstMatTags;
      }
      else {
        this.lstSlctdStkMatTags = [];
      }

      this.getAllMtrlLst();
    }
  }

  LoadMatsbyStoreId1(sId: number) {
    if (sId) {
      let crntPgIndx = this.crntStkPage - 1;
      this.lstMats1 = [], this.lstAllMats1 = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
        environment.apiMthds.invMats.replace('sss', `${sId}`).replace('rrr', '').replace('pageNo', `${crntPgIndx}`).
          replace('pageSize', `${this.pageSize}`), 0).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                if (this.result.data)
                  this.totalStkItems = this.result.data?.totalRecordCount ?? 0,
                    this.stkPageEntrs = this.GetPageSizeTxt(this.crntStkPage, this.pageSize, this.totalStkItems), this.result.data?.productsDTOs.forEach((e: any) => {
                      Object.assign(e, { isB: false, isM: false, isH: false, isAS: false, isIT: false, lstB: [], lstM: [], lstH: [] });
                    }),
                    this.lstAllMats1 = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [],
                    this.lstMats1 = this.lstAllMats1, this.matsLST = this.lstMats1;
                else
                  this.glblSvc.onTstr('e', this.result.message);
              }
            }
          });
    }
  }

  setStkPage(pagNo: number, type: any): void {
    if (type == 'mat')
      this.crntPage = pagNo, this.LoadMatsbyStoreId1(this.strId);
  }

  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => { /* if (result == 'done') //   return; // your code */ }, (r) => { console.log(r) });
  }

  LoadAssets() {
    let payload = this.facltyDtlsFrm.getRawValue();
    payload.asetTypeId = payload.asetTypeId != '' ? +payload.asetTypeId : null,
      payload.storeId = this.strId, payload.monitoringPoint = null,
      payload.statusId = payload.approvalStatus != '' ? payload.approvalStatus : null, payload.assetId = null, payload.assetTypes = this.asetType,
      this.asstsList = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.getassetdetailsbyfilter.
        replace('pageNo', (this.crntPage - 1).toString()).replace('pageSize', this.pageSize.toString()), payload, 2, true, 3).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (this.result.status == 'OK') {
                this.asstsList = this.result?.data?.assetsList != null ? this.result?.data?.assetsList : [],
                  this.totalItems = this.result.data?.totalCount ?? 0,
                  this.astPgeEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
              }
            }
          }
        })
  }

  onReset() {
    this.facltyDtlsFrm.get('asetTypeId')?.setValue(''), this.facltyDtlsFrm.get('relationships')?.setValue(''),
      this.facltyDtlsFrm.get('approvalStatus')?.setValue(''), this.facltyDtlsFrm.get('alarms')?.setValue('0'), this.LoadAssets();
  }

  onClick(assetId: any) {
    if (assetId > 0)
      this.glblSvc.sbjctAssetId.next(assetId), this.router.navigate(['home/asst-ast-dtls']);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadAssets(); this.getAllMtrlLst(); this.LoadMatsbyStoreId1(this.strId);
  }

  loadStoreData(strId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getStrById.replace('sss', strId), null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.strDetails = this.result.data;
      }
    });
  }

  loadFacilities() {
    let crntPgIndx = this.crntPage - 1,  storeId : any= 0, storeBadgeId : any = 0;
    this.lstAllFacilities = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getAllStrByFilter.
        replace('ppp', `${this.chsnDmnId}`).replace('sss', storeId).
        replace('bbb', storeBadgeId).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.result.data?.storeFiltersDTOs.forEach((e: any) => { Object.assign(e, { flag: false }) }),
              this.lstAllFacilities = this.result.data?.storeFiltersDTOs != null ? this.result.data?.storeFiltersDTOs : [],
              this.totalItems = this.result.data?.totalRecordCount ?? 0,
              this.pageEntrs = `${((this.crntPage * this.pageSize) - (this.pageSize - 1))}-${((this.crntPage * this.pageSize) < (this.totalItems)) ? (this.crntPage * this.pageSize) : (this.totalItems)} of ${this.totalItems}`;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadstrMappings(type: any, strId: any) {
    this.strMappingsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.strMappings, environment.apiMthds.getLinkedStores.
        replace('pranthId', strId).replace('ordType', type), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (type == 'c')
            this.RecFaciLst = this.result.data != null ? this.result.data : [];
          else
            this.IssuFaciLst = this.result.data != null ? this.result.data : [];
        }
      }
    });
  }

  onChange($event: any) {
    this.mappingType = $event.target.value;
  }

  onSlct($event: any, item: any) {
    if ($event.target.checked)
      item.flag = true, this.strIds.push(item.storeId);
    else
      item.flag = false, this.strIds.filter((a: any, index: any) => { if (a == item.storeId) this.strIds.splice(index, 1); })
  }

  onSlctAll($event: any) {
    this.strIds = [];
    if ($event.target.checked)
      this.lstAllFacilities.filter(a => { a.flag = true, this.strIds.push(a.storeId); })
    else
      this.lstAllFacilities.filter(a => { a.flag = false });
  }

  createStrRelation() {
    let rltnType = $('#rltnType').val(), payLoad = {
      createdBy: this.usrLgnDtls.userId,
      mapped_store_ids: this.strIds, updatedBy: this.usrLgnDtls.userId, mappingType: rltnType,
      storeId: this.strId
    };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.strMappings, environment.apiMthds.creatStrRelation, payLoad, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message), this.mappingType = 'v', this.strIds = [], this.addStatus = true,
            this.lstAllFacilities.filter(a => { a.flag = false }), this.LoadstrMappings('v', this.strId), this.LoadstrMappings('c', this.strId);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  getStrPrfle() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.stpFcGetPrfle.replace('sss', this.strId),
      null, 7, true, 2).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.strPrfle = this.result.data != null ? this.result.data : [];
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  getAllMtrlLst() {
    let matIds: any = [];
    this.lstSlctdStkMatTags.filter(e => { matIds.push(e.id) }), this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
      environment.apiMthds.stpFcGetAlprdctsBystrId.replace('sss', matIds).replace('ttt', this.strId), null, 7, true).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.totalItems = this.result.data?.totalRecordCount ?? 0,
            this.mtrlPageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems),
            this.mtrlList = this.result?.data?.productDetails != null ? this.result?.data?.productDetails : [];
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onChngTAH(event: any) {
    if (this.lstFreeMtrls.filter(f => f.name === event.value).length > 0)
      this.slctdMatId = this.lstFreeMtrls.filter(f => f.name === event.value)[0].id;
    else
      this.facltyDtlsFrm.get('productId')?.setValue(null);
  }

  saveMat() {
    this.isSbmtd = true; this.checkFormValidations(this.facltyDtlsFrm);
    if (this.facltyDtlsFrm.invalid)
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
    else {
      let frmCtrls = this.facltyDtlsFrm.getRawValue(), payLoad: any;
      if (this.isEdtAd) {
        payLoad = { productId: this.lstMat.filter(f => f.name === frmCtrls.productId)[0]?.id ?? 0, currentStock: 0, daysOfStock: 0,
           minStock: frmCtrls.minStock == 'null' ? 0 : +frmCtrls.minStock, maxStock: frmCtrls.maxStock == 'null' ? 0 : +frmCtrls.maxStock,
          storeId: this.strId, id: this.iCatlog, pranthId: this.chsnDmnId, totalStock: 0,
          inTransitStock: 0 }
      }
      else {
        payLoad = { productId: frmCtrls.productId == 'null' ? 0 : +this.slctdMatId, totalStock: 0, storeId: this.strId,
        minStock: frmCtrls.minStock == 'null' ? 0 : +frmCtrls.minStock, maxStock: frmCtrls.maxStock == 'null' ? 0 : +frmCtrls.maxStock,
        currentStock: 0, daysOfStock: 0, inTransitStock: 0, pranthId: this.chsnDmnId };
      }
      this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.saveRupdate, payLoad, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.getAllMtrlLst(), this.isShowMat = true, this.facltyDtlsFrm.get('productId')?.setValue(''),
              this.facltyDtlsFrm.get('minStock')?.setValue(''), this.facltyDtlsFrm.get('maxStock')?.setValue('');
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onMatEdit(item: any) {
    this.lstFreeMtrls = [];
    if (this.mtrlList.length > 0)
      this.lstMat.forEach(e => { if (this.mtrlList.filter(f => f.productid === e.id).length == 0) this.lstFreeMtrls.push(e); });
    else
      this.lstFreeMtrls = this.lstMat;
    this.isEdtAd = true, this.iCatlog = item.icatalogue_id, this.facltyDtlsFrm.patchValue({ productId: item.productname, minStock: item.minstock,
      maxStock: item.maxstock });
  }

  onAddMat() {
    this.lstFreeMtrls = [];
    if (this.mtrlList.length > 0)
      this.lstMat.forEach(e => { if (this.mtrlList.filter(f => f.productid === e.id).length == 0) this.lstFreeMtrls.push(e); });
    else
      this.lstFreeMtrls = this.lstMat;
  }

 onMatDel(id: any) {
    if (confirm("Are you sure want to delete  ?")) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.invntry, environment.apiMthds.delByMatId.replace('mmm', id), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Records deleted")
              this.glblSvc.onTstr('s', this.result.message), this.getAllMtrlLst();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

}