<form [formGroup]="stkMangmntFrm" autocomplete="off">
    <div class="row">
        <div class="form-group col-xl-3">
            <label for="BadgeName">Material Category:</label>
            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags" placeholder=""
                [(ngModel)]="slctMatTags" formControlName="materialBadge"
                (onSelect)="onMultiSlct('s', 'm', $event)" (onSelectAll)="onMultiSlct('a', 'm', $event)"
                (onDeSelect)="onMultiSlct('r', 'm', $event)" (onDeSelectAll)="onMultiSlct('d', 'm', $event)">
            </ng-multiselect-dropdown>
        </div>
        <div class="form-group col-xl-3">
            <label for="tagName">Facility Category:</label>
            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
            formControlName="storeBadges" (onSelect)="onMultiSlct('s', 'f', $event)"
            (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
            (onDeSelectAll)="onMultiSlct('d', 'f', $event)" [(ngModel)]="slctdFac">
            </ng-multiselect-dropdown>
        </div>
        <div class="form-group col-xl-3">
            <label for="inputState-57" class="form-label">Status</label>
            <select id="inputState-57 " class="form-select" formControlName="status" [(ngModel)]="stats">
                <option value="">Normal</option>
                <option value="200">Zero Stock</option>
                <option value="201">&lt; Min</option>
                <option value="202">&gt; Max</option>
            </select>
        </div>
        <div class="form-group col-xl-3 text-end mt-4">
            <button class="btn btn-sm btn-light mt-2" (click)=" crntPage=1;locId='';onReset()">Reset</button> &nbsp;
            <button class="btn btn-primary btn-sm mt-2" (click)="crntPage=1;isBredCrumNatnl='';isBredCrumState='';
                isBredCrumDist='';locId='';isCcpLvl = false;isDstLvl = false;isBackToState =true;isBackToCountry =true;loadStkMangmnt('a')">Filter</button>
        </div>
    </div>
    <div class="card mt-3 mb-0" *ngIf="lstGrid.length!=0">
        <div class="card-header p-3">
            <div class="float-lg-start">
                <h6 class="mb-0">{{locName}}</h6>
            </div>
            <!-- <div class="float-lg-end">
                <span class="small me-2">&nbsp;&nbsp;As of {{toDayDate | date:"dd/MM/yyyy hh:mm aa"}}</span>
                <span class="btn btn-sm p-0"><a [routerLink]="" class="rounded-circle"><i
                            class="fa fa-refresh"></i></a></span>
            </div> -->
        </div>
        <div class="card-body p-3">
            <div class="text-center">
                <h5 class="mb-0 mt-1">Stock Management <i class="fa fa-info-circle" aria-hidden="false"
                        data-bs-toggle="tooltip" data-bs-placement="top"
                        title="% of inventory items that are normal or abnormal (zero stock, &lt; min. or > max) across all kiosks in this jurisdiction. An inventory item is a material at a kiosk."></i>
                </h5>
                <div *ngIf="isSbmtd==true">
                    <div><strong>Material Category:&nbsp;</strong>{{matCatgry}}</div>
                    <div><strong>Facility Category:&nbsp;</strong>{{facCatgry}} <strong>&nbsp;Status:&nbsp;</strong>{{statusType}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0" *ngIf="lstGrid.length!=0">
                <!-- *ngIf="lstGrid.length!=0" -->
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-12 mb-1">
                            <div class="row">
                                <div class="col-xl-4">
                                    <button class="btn btn-sm btn-success" (click)="isExprt=false;onEE()">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                                <div class="col-xl-8">
                                    <nav aria-label="breadcrumb" class="float-end">
                                        <ol class="breadcrumb">
                                            <li class="breadcrumb-item" *ngIf="isBredCrumNatnl !='' && slctDmnDetails?.districtId == null"
                                                (click)="crntPage=1;isBredCrumState=''; isBredCrumDist='';locId='';isCcpLvl = false;
                                                isDstLvl = false;this.isBackToState =true;loadStkMangmnt('a')"><a>{{isBredCrumNatnl}}</a></li>
                                            <li id="isAct" class="breadcrumb-item" [ngClass]="[ isBredCrumDist =='' ? 'active' : '']"
                                                *ngIf="isBredCrumState !='' && (slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null)"
                                                (click)="crntPage=1;isBredCrumDist='';isCcpLvl=false;loadStkMangmnt('s');">
                                                <a>{{isBredCrumState}}</a></li>
                                            <li class="breadcrumb-item active" aria-current="page"
                                                *ngIf="isBredCrumDist !=''">{{isBredCrumDist}}
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 text-start mt-3">
                            <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }"
                                class="clrCde p-1 me-2">Normal</span>
                            <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                class="clrCde p-1 me-2">Zero Stock</span>
                            <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                class="clrCde p-1 me-2">Min</span>
                            <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                class="clrCde p-1 me-2">Max</span>
                        </div>
                        <div class="col-xl-6 float-end mb-2">
                            <div class="text-end mb-2 mt-2">
                                <span class="box_indicators border mt-3 mb-3 p-2 f-10">
                                    <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange0 }"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>0-20%</span></span>
                                    <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange1 }"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>20-40%</span></span>
                                    <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange2 }"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>40-60%</span></span>
                                    <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange3 }"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>60-80%</span></span>
                                    <span class="f-12"><span [ngStyle]="{ 'color': clrrange4 }"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>80-100%</span></span>
                                </span>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="table-multi-columns table-fill mt-2 mb-2 horizontal-scroll scroll-demo p-0 border-0 table_spacing"
                            style="height: auto !important;" *ngIf="lstGrid.length==0">
                            <table class="table table-sm text-center table-small-font table-striped table-bordered align-middle"
                                cellspacing="0">
                                <thead>
                                    <tr>
                                        <th style="width: 20%">Item No.</th>
                                        <th style="width: 60%" class="text-center">Location</th>
                                        <th style="width: 20%" class="text-center">Material name</th>
                                        <th *ngIf="false" style="width: 10%" title="Temperature" class="toggle-data">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                            </svg>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="text-center">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-wrap" *ngIf="lstGrid.length!=0">
                            <table class="text-center table-small-font main-table table-bordered">
                                <thead>
                                    <tr>
                                        <th colspan="3" class="freeze tbl-hdr-clr">
                                            <table class="w-450p break-word-td">
                                                <tr>
                                                    <th style="width: 15%">Item No.</th>
                                                    <th style="width: 80%" class="text-center" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId ==''">State</th>
                                                    <th style="width: 80%" class="text-center" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId !='' && isCcpLvl== true && isDstLvl == false">District</th>
                                                    <th style="width: 80%" class="text-center" *ngIf="(slctDmnDetails?.stateId == null && slctDmnDetails?.districtId == null) && locId !='' && isCcpLvl== true && isDstLvl == true">Facility</th>
                                                    <th style="width: 80%" class="text-center" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId == null) && locId ==''">District</th>
                                                    <th style="width: 50%" class="text-center" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId == null) && locId !=''">Facility</th>
                                                    <th style="width: 50%" class="text-center" *ngIf="(slctDmnDetails?.stateId != null && slctDmnDetails?.districtId != null)">Facility</th>
                                                    <th style="width: 35%" class="text-center" *ngIf="isBredCrumDist != ''">Facility Category</th>
                                                    <th *ngIf="false" style="width: 15%" title="Temperature" class="toggle-data text-center">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                            <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                            <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                        </svg>
                                                    </th>
                                                </tr>
                                            </table>
                                        </th>
                                        <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-2">{{hdr}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGrid.length!=0">
                                    <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms }; let i=index">
                                        <td colspan="3" class="freeze">
                                            <table class="w-300p break-word-td">
                                                <tr>
                                                    <td style="width: 15%" class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                    <td style="width: 50%" class="text-center f-14"><a (click)="onLocDetails(item.kName, item.kId, $event)">{{item.kName}}</a></td>
                                                    <td style="width: 35%" *ngIf="isBredCrumDist != ''" class="text-center">{{item.kBadge}}</td>
                                                    <td *ngIf="false" class="text-center" style="width: 15%"></td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td [ngStyle]="{ 'backgroundColor': col.color != null ? col.color : null,
                                            'color': col.color != null ? '#000000' : null }" *ngFor="let col of item.matData"
                                            title="{{col.numberByTotal}}">{{col.percentage == null ? 'NA' : (col.percentage + '%')}}
                                    </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pb-3 pt-2">
                            <div class="row" *ngIf="lstGrid?.length!=0">
                                <div class="col-xl-6 text-start">
                                    {{pageEntrs}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                    </div>
                                </div>
                                <div class="col-xl-6 text-start">
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }"
                                        class="clrCde p-1 me-2">Normal</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                        class="clrCde p-1 me-2">Zero Stock</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                        class="clrCde p-1 me-2">Min</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                        class="clrCde p-1 me-2">Max</span>
                                </div>
                                <div class="col-xl-6 float-end">
                                    <div class="text-end mb-2 mt-1">
                                        <span class="box_indicators border mt-3 mb-3 p-2 f-10">
                                            <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange0 }"><i
                                                        class="fa fa-square me-2" aria-hidden="true"></i></span>
                                                <span>0-20%</span></span>
                                            <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange1 }"><i
                                                        class="fa fa-square me-2" aria-hidden="true"></i></span>
                                                <span>20-40%</span></span>
                                            <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange2 }"><i
                                                        class="fa fa-square me-2" aria-hidden="true"></i></span>
                                                <span>40-60%</span></span>
                                            <span class="me-2 f-12"><span [ngStyle]="{ 'color': clrrange3 }"><i
                                                        class="fa fa-square me-2" aria-hidden="true"></i></span>
                                                <span>60-80%</span></span>
                                            <span class="f-12"><span [ngStyle]="{ 'color': clrrange4 }"><i
                                                        class="fa fa-square me-2" aria-hidden="true"></i></span>
                                                <span>80-100%</span></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>