import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { icon, latLng, Layer, marker, tileLayer } from 'leaflet';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-osm-leaflet',
  templateUrl: './osm-leaflet.component.html',
  styleUrls: ['./osm-leaflet.component.css']
})

export class OsmLeafletComponent extends BaseComponent implements OnInit {

  mapPL = JSON.parse(<string>localStorage.getItem('osmPL')); llTtl = <string>localStorage.getItem('slctdDmnName'); isShwMapBtn = false;
  osmLyr = tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: 'OSM' }); llMrkrs: Layer[] = [];
  llOpts = { layers: [this.osmLyr], zoom: 10, center: latLng(<any>localStorage.getItem('osmLat'), <any>localStorage.getItem('osmLng')) };

  constructor(public glblSvc: GlobalService,
    public actMdl: NgbActiveModal) {
    super(glblSvc);
	}

  ngOnInit(): void {
    this.MapData();
  }

  MapData() {
    let dtMap: any[] = JSON.parse(<any>localStorage.getItem('osmData'));
    dtMap.filter((f: any) => f.latitude != null && f != f.longitude != null).forEach((e: any) => {
      let mrkrIcon = e.stockIndicator != null ? (`${e?.stockIndicator?.color}`.toLowerCase() == '#c2e4f3' ? 'mi-blue' :
        (`${e?.stockIndicator?.color}`.toLowerCase() == '#e44949' ? 'mi-red' : (`${e?.stockIndicator?.color}`.toLowerCase() ==
        '#ead03e' ? 'mi-yellow' : 'mi-green'))) : 'mi-black';
      const newMarker = marker([e?.latitude, e.longitude], { icon: icon({ iconSize: [25, 41], iconAnchor: [13, 41],
        iconUrl: `assets/osm-icons/${mrkrIcon}.png` }) });
      newMarker.bindPopup("<b>Store Name: </b>" + e?.storeName + "<br /><b>Location: </b>" + e?.location + "<br /><b>Total Stock: </b>" +
        e?.totalStock + "<br /><b>Min: </b>" + e?.minStock + " <b>& Max: </b>" + e?.maxStock, { closeButton: false }).openPopup();
      this.llMrkrs.push(newMarker);
    }), ['osmLat', 'osmLng', 'osmData'].forEach(e => { localStorage.removeItem(e); });
  }

}