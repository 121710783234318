import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-create-prchs-indent',
  templateUrl: './create-prchs-indent.component.html',
  styleUrls: ['./create-prchs-indent.component.css']
})
export class CreatePrchsIndentComponent extends BaseComponent implements OnInit {

 ordrCreateForm: FormGroup; ordrType = '1'; noRcrdsTxt = environment.TblNoRcrds; lstAllMats: any[] = []; matName: any;
 isRsnMndtry = false; str2 = ''; paramsTab = ''; frmDate: any = ''; dt2day = new Date(); toDateF: any = ''; isSbmtd: boolean = false;
 ctDte: any = new Date(); lstPrducr: any[] = []; slctPrdId: any; slctdPrducrId: any; rcmdQntity = 0;

  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder,
    public dtPipe: DatePipe,
    private router: Router, private route: ActivatedRoute) {
    super(glblSvc);
    // this.glblSvc.sbjctMstrData.subscribe(s => {  this.lstAllMats = s.allMats }),
    this.ordrCreateForm = this.formBuilder.group({ 
      producerId: ['', Validators.required],  productId: ['', Validators.required], comments: [null], orderReferenceNo: [''], 
      supplyPeriodFrom: ['', Validators.required], supplyPeriodEnd: ['', Validators.required], quantity: ['', Validators.required]
     })
  }

  ngOnInit(): void { 
    this.route.queryParams.subscribe(params => { this.paramsTab = params.tab; }), this.LoadManufacturers();
  }

  get fc() { return this.ordrCreateForm.controls; }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef, this.ordrCreateForm.get('supplyPeriodEnd')?.setValue('');
  }

  clearBSDate(ctrl: string) {
    this.ordrCreateForm.get(ctrl)?.setValue('');
  }

  onChngDtT(dtVal2: any){
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
    if (this.ordrCreateForm.get('supplyPeriodEnd')?.value != '')
      this.LoadCnsmptnReport();
  }

  LoadCnsmptnReport() {
    let frmValues = this.ordrCreateForm.getRawValue(), payLoad: any = {};
    this.GetDtesMinMax(frmValues.supplyPeriodFrom, frmValues.supplyPeriodEnd), payLoad.productIds = this.slctPrdId != null && this.slctPrdId != 
      undefined && this.slctPrdId != '' ? [+this.slctPrdId] : null, payLoad.fromDate = frmValues.supplyPeriodFrom ? this.maxFrmDate + 
      this.tmF.zeroTM : null, payLoad.toDate = frmValues.supplyPeriodEnd ? this.maxToDate + this.tmF.zeroTM : null, payLoad.storeId = null;
      payLoad.days = this.CheckDaysDiff(new Date(this.maxFrmDate), new Date(this.maxToDate)) ?? 0 ;
      
    if (payLoad.productIds != null && payLoad.fromDate != null) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.cnsgnRcmndQntity, payLoad, 4).then(() => {
        let res = this.result;
        if (this.result) {
          if (this.result?.data) {
            this.rcmdQntity = this.result?.data;
          }            
          // else
          //   this.glblSvc.onTstr('i', res.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }


  LoadManufacturers() {
    this.lstPrducr = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.producer, environment.apiMthds.getAllProducers, 
        null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.lstPrducr = this.result.data != null ? this.result.data : [];
        }
      })
  }

  LoadPrducts() {
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.noPath, environment.apiMthds.getPrdtsByMnf.
      replace('ppp', this.slctdPrducrId), null, 12).then(() => {
        if (this.result) {
          if (this.result.data)
            this.lstAllMats = this.result.data != null ? this.result.data : [];
        }
      })
  }

  chngProduct(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == '' || evnt == undefined)
      this.slctPrdId = '', this.ordrCreateForm.get('recevingStoreName')?.setValue('');
    else {
      if (this.lstAllMats.filter(a => a.productName == evnt).length != 0)
        for (let s = 0; s < this.lstAllMats.length; s++) {
          if (this.lstAllMats[s].productName == event.value)
            this.slctPrdId = this.lstAllMats[s]?.productId;
        }
      else
        this.slctPrdId = null; //, this.ordrCreateForm.get('id')?.setValue(null);
    }
  }

  onChngPrducr(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if  (evnt == '' || evnt == undefined)
      this.slctdPrducrId = '';
    else {
      if (this.lstPrducr?.filter(a => a.producerName == evnt).length != 0) {
        for (let s = 0; s < this.lstPrducr.length; s++) {
          if (this.lstPrducr[s].producerName == evnt) {
            this.slctdPrducrId = this.lstPrducr[s].producerId, this.ordrCreateForm.get('recevingStoreName')?.setValue(evnt);
            if (evntType === 'str1')
              this.ordrCreateForm.get('storeId')?.setValue(evnt);
          }
        }
      }
      else
        this.slctdPrducrId = '';
    }
    setTimeout(() => { this.LoadPrducts();}, 500);
  }

  onSave() {
    //this.checkFormValidations(this.ordrCreateForm); //, this.checkFormArrayValidations(this.ordrCreateForm.controls['productBookings']);
    // if (this.ordrCreateForm.invalid)
    if ( this.fc.producerId?.value == '' || this.fc.productId.value == '' || this.fc.productId.value == undefined || this.fc.quantity.value =='' ||
        this.fc.supplyPeriodFrom.value == '' || this.fc.supplyPeriodFrom.value == null || this.fc.supplyPeriodEnd.value =='' || 
        this.fc.supplyPeriodEnd.value == null) {
        this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    } else {
      let payLoad = this.ordrCreateForm.getRawValue();
        this.GetDtesMinMax(payLoad.supplyPeriodFrom, payLoad.supplyPeriodEnd),
      payLoad.statusId = 5, payLoad.isDeleted = false, payLoad.createdBy = this.usrLgnDtls?.userId,
        payLoad.producerId = this.slctdPrducrId != null && this.slctdPrducrId != undefined && this.slctdPrducrId != '' ? +this.slctdPrducrId : null,
        payLoad.productId = this.slctPrdId != null && this.slctPrdId != undefined && this.slctPrdId != '' ? +this.slctPrdId : null,
        payLoad.supplyPeriodFrom = payLoad.supplyPeriodFrom ? this.maxFrmDate + this.tmF.zeroTM : null,
        payLoad.supplyPeriodEnd = payLoad.supplyPeriodEnd ? this.maxToDate + this.tmF.zeroTM : null,
        this.FetchData(environment.apiCalls.post, environment.apiPaths.prdcrBking, environment.apiMthds.saveRupdate, payLoad, 12).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              localStorage.removeItem("isIndntAPICald"),
                this.glblSvc.onTstr('s', this.result.message), this.onReset();
              this.router.navigate(['home/prchs-ord'], { queryParams: { tab: 'list', type: 'i' } }), setTimeout(() => { window.location.reload(); }, 500);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    }
  }

  onReset() {
    this.ordrCreateForm.reset(), this.slctPrdId = '', this.slctdPrducrId ='', this.rcmdQntity = 0,
    this.ordrCreateForm.get('producerId')?.setValue(''), this.ordrCreateForm.get('productId')?.setValue(''),
    this.ordrCreateForm.get('supplyPeriodFrom')?.setValue(''), this.ordrCreateForm.get('supplyPeriodEnd')?.setValue('');
  }

}
