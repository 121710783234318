import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.css']
})

export class AssetsComponent extends BaseComponent implements OnInit {

  prmsns: any = null; cceMngmntLst: boolean = true;

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void { 
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.CCE_Management : null;
    this.prmsns.find((e: any) =>
    {
      if(e?.menuCode =='mm_cm_li')
      this.cceMngmntLst = e?.action;
    });
  }
}