<div class="card p-0" [formGroup]="cnfgOptFrm">
    <div class="card-header shadow-none p-2 ">
        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="tab_option-tab" data-bs-toggle="tab" href="#tab_option"
                    role="tab" aria-controls="custom-dashboard" aria-selected="true">Push Configuration</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="tab_option-tab2" data-bs-toggle="tab" href="#tab_option2"
                    role="tab" aria-controls="custom-dashboard" aria-selected="true">Push Material</a>
            </li>
        </ul>
    </div>
    <div class="card-body p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="tab_option" role="tabpanel" aria-labelledby="tab_option-tab">
                <div class="card border-0 p-0 pb-0 mt-2 mb-0 pt-2">
                    <div class="card-header shadow-sm p-2">
                        <h6 class="mb-0">Push configuration to child Domain</h6>
                    </div>
                    <div class="card-body p-2 mt-0 mb-0">
                        <div class="row">
                            <div class="col xl-3 text-start pb-3">
                                <label><b>Type</b><sup class="mndt">*</sup></label>
                                <!-- <select class="form-select" formControlName="typeId" (change)="noCTs=[];onChngType($event, 'mt');"
                                    [ngClass]="{ 'is-invalid': isPush && (FC.typeId == '' || FC.typeId == null) }">
                                    <option value="">Select Type</option>
                                    <option [value]="mt.id" *ngFor="let mt of lstTyps">{{mt.type | titlecase}}</option>
                                </select>
                                <div *ngIf="isPush && (FC.typeId == '' || FC.typeId == null)" class="invalid-feedback">
                                    Please choose Type
                                </div> -->

                                <div>
                                    <!-- <label *ngIf="lblChldType!=''"><b>{{lblChldType | titlecase}} Type</b><sup class="mndt">*</sup></label>
                                    <label *ngIf="lblChldType==''">&nbsp;</label> -->
                                    <ng-multiselect-dropdown [data]="lstTyps"
                                        [ngClass]="{ 'is-invalid': isPush && (FC.typeId == '' || FC.typeId == null) }"
                                        (onSelect)="onMultiDDL($event, 'type', 's')" (onSelectAll)="onMultiDDL($event, 'type', 'a')"
                                        (onDeSelect)="onMultiDDL($event, 'type', 'd')" (onDeSelectAll)="onMultiDDL($event, 'type', 'r')"
                                        [settings]="msdTypestngs" [(ngModel)]="type" formControlName="typeId">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="isPush && (FC.typeId == '' || FC.typeId == null)" class="invalid-feedback">
                                        Please choose Type
                                    </div>
                                </div>
                            </div>
                            <div class="col xl-3 text-start pb-3">
                                <label *ngIf="lblSubType!=''"><b>{{lblSubType | titlecase}} Type</b>
                                    <sup class="mndt" *ngIf="lstSubTyps.length != 0">*</sup></label>
                                <label *ngIf="lblSubType==''">&nbsp;</label>
                                <!-- <select class="form-select" (change)="noCTs=[];onChngType($event, 'st');" formControlName="sub"
                                    [ngClass]="{ 'is-invalid': isPush && (FC.sub == '' || FC.sub == null) && lblSubType != '' &&
                                        lstSubTyps.length != 0 }" [attr.disabled]="lblSubType == '' ? true : null">
                                    <option *ngIf="lblSubType!=''" value="">Select {{lblSubType | titlecase}} Type</option>
                                    <option *ngIf="lblSubType==''" value="">Select</option>
                                    <option [value]="st.subId" *ngFor="let st of lstSubTyps">{{st.subType | titlecase}}</option>
                                </select>
                                <div *ngIf="isPush && (FC.sub == '' || FC.sub == null) && lblSubType !='' && lstSubTyps.length != 0"
                                    class="invalid-feedback"> Please choose {{lblSubType | titlecase}} Type
                                </div> -->


                                <div>
                                    <!-- <label *ngIf="lblChldType!=''"><b>{{lblChldType | titlecase}} Type</b><sup class="mndt">*</sup></label>
                                    <label *ngIf="lblChldType==''">&nbsp;</label> -->
                                    <ng-multiselect-dropdown [data]="lstSubTyps"
                                        [ngClass]="{ 'is-invalid': isPush && (FC.sub == '' || FC.sub == null) && lblSubType != '' &&
                                        lstSubTyps.length != 0  }" [disabled]="lblSubType == '' ? true : false"
                                        (onSelect)="onMultiDDL($event, 'subType', 's')" (onSelectAll)="onMultiDDL($event, 'subType', 'a')"
                                        (onDeSelect)="onMultiDDL($event, 'subType', 'd')" (onDeSelectAll)="onMultiDDL($event, 'subType', 'r')"
                                        [settings]="msdSubTypeStng" [(ngModel)]="subTypeValue" formControlName="sub" >
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="isPush && (FC.sub == '' || FC.sub == null) && lblSubType !='' && lstSubTyps.length != 0" class="invalid-feedback">
                                        Please choose {{lblSubType | titlecase}} Type
                                    </div>
                                </div>
                            </div>
                            <div class="col xl-3 text-start pb-3">
                                <div>
                                    <label *ngIf="lblChldType!=''"><b>{{lblChldType | titlecase}} Type</b><sup class="mndt">*</sup></label>
                                    <label *ngIf="lblChldType==''">&nbsp;</label>
                                    <ng-multiselect-dropdown [data]="lstChldTyps" [placeholder]="phCT" [disabled]="lblChldType=='' ? true : false || chsnSubType.length> 1"
                                        [ngClass]="{ 'is-invalid': isPush && lstChldTyps.length > 0 && chsnCTs.length == 0 }"
                                        (onSelect)="onMultiDDL($event, 't', 's')" (onSelectAll)="onMultiDDL($event, 't', 'a')"
                                        (onDeSelect)="onMultiDDL($event, 't', 'd')" (onDeSelectAll)="onMultiDDL($event, 't', 'r')"
                                        [settings]="mdlStngs" [(ngModel)]="noCTs" formControlName="eg">
                                    </ng-multiselect-dropdown>
                                    <div *ngIf="isPush && lstChldTyps.length > 0 && chsnCTs.length == 0" class="invalid-feedback">
                                        Please choose {{lblChldType | titlecase}} Type
                                    </div>
                                </div>
                            </div>
                            <div class="col xl-3 text-start pb-3">
                                <label><b>Target Domains</b><sup class="mndt">*</sup></label>
                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstDmns" [(ngModel)]="noDmns"
                                    (onSelect)="onMultiDDL($event, 'd', 's')" (onSelectAll)="onMultiDDL($event, 'd', 'a')"
                                    (onDeSelect)="onMultiDDL($event, 'd', 'd')" (onDeSelectAll)="onMultiDDL($event, 'd', 'r')"
                                    [ngClass]="{ 'is-invalid': isPush && chsnDmns.length == 0 }" formControlName="eg">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isPush && chsnDmns.length == 0" class="invalid-feedback">
                                    Please choose Target Domains
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col xl-3 text-start pb-3">
                                <div class="form-check form-check-inline mt-2 p-l-25">
                                    <input class="form-check-input" type="radio" id="rbMat1" name="pushToAllChilds"
                                        [(ngModel)]="pushToAllChilds" [checked]="pushToAllChilds=='0'" value="0"
                                        formControlName="pushToAllChilds">&nbsp;
                                    <label class="form-check-label">Push to selected Domains</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="rbMat2" name="pushToAllChilds"
                                        [(ngModel)]="pushToAllChilds" [checked]="pushToAllChilds=='1'" value="1"
                                        formControlName="pushToAllChilds">&nbsp;
                                    <label class="form-check-label">Push to All Childs</label>
                                </div>
                            </div>
                            <div class="col xl-3 text-start pb-3">
                                <button type="button" class="btn btn-sm btn-light" (click)="isPush=false;onRst()">Reset</button>&nbsp;&nbsp;
                                <button type="button" class="btn btn-sm btn-primary" (click)="isPush=true;onPush()">Push</button>
                            </div>
                            <br />
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <div>Push the configuration of this domain to all the child domains in this sub-tree.</div>
                                <div class="card p-0 mt-2 mb-0 border-0 shadow-sm">
                                    <div class="card-body p-3 box-outline-warning">
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                                        WARNING: {{pushToAllChilds == '0' ? 'The configuration in selected domains will be overwritten by the configuration of this domain.':
                                            'The configuration in each child domain will be overwritten by the configuration of this domain.'}}
                                    </div>
                                </div>
                                <div class="mt-2">
                                    Once you push the configuration, please review and update the configuration in the child domains.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane" id="tab_option2" role="tabpanel" aria-labelledby="tab_option-tab2">
                <div class="card border-0 p-0 pb-0 mt-2 mb-0 pt-2">
                    <div class="card-header shadow-sm p-2">
                        <h6 class="mb-0">Push Material to child Domain</h6>
                    </div>
                    <div class="card-body p-2 mt-0 mb-0">
                        <div class="row">
                            <div class="col-xl-4 text-start pb-3">
                                <label><b>Material Name</b><sup class="mndt">*</sup></label>
                                <ng-multiselect-dropdown [settings]="msdMatStngs" [data]="lstMats" [(ngModel)]="matMdl"
                                    (onSelect)="onMultiMat($event, 'm', 'os')" (onSelectAll)="onMultiMat($event, 'm', 'osa')"
                                    (onDeSelect)="onMultiMat($event, 'm', 'ds')" (onDeSelectAll)="onMultiMat($event, 'm', 'dsa')"
                                    [ngClass]="{ 'is-invalid': isMatPush && slctdMats.length == 0 }" formControlName = 'matMul'>
                                </ng-multiselect-dropdown>
                                <div *ngIf="isMatPush && slctdMats.length == 0" class="invalid-feedback">
                                    Please choose Materials
                                </div>
                            </div>
                           
                            <div class="col-xl-4 text-start pb-3">
                                <label><b>Target Domains</b><sup class="mndt">*</sup></label>
                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstDmns" [(ngModel)]="noDmns"
                                    (onSelect)="onMultiMat($event, 'd', 'os')" (onSelectAll)="onMultiMat($event, 'd', 'osa')"
                                    (onDeSelect)="onMultiMat($event, 'd', 'ds')" (onDeSelectAll)="onMultiMat($event, 'd', 'dsa')"
                                    [ngClass]="{ 'is-invalid': isMatPush && slctdDmns.length == 0 }" formControlName = 'matDmn'>
                                </ng-multiselect-dropdown>
                                <div *ngIf="isMatPush && slctdDmns.length == 0" class="invalid-feedback">
                                    Please choose Target Domains
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-5 text-start pb-3">
                                <div class="form-check form-check-inline mt-2 p-l-25">
                                    <input class="form-check-input" type="radio" name="pshMat" (change)="matPshChng($event, 's')" checked>&nbsp;
                                    <label class="form-check-label">Push to selected Domains</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="pshMat" (change)="matPshChng($event, 'a')">&nbsp;
                                    <label class="form-check-label">Push to All Childs</label>
                                </div>
                            </div>
                            <div class="col-xl-3 text-start pb-3 pt-2">
                                <input type="checkbox" id="str" [(ngModel)]='strPush' (change)="matPshChng($event, 'str')" formControlName="pshStr">&nbsp;  <label for="str">Push to Stores</label>
                            </div>
                            <div class="col-xl-4 text-start pb-3">
                                <button type="button" class="btn btn-sm btn-light" (click)="isMatPush=false;onRstMat()">Reset</button>&nbsp;&nbsp;
                                <button type="button" class="btn btn-sm btn-primary" (click)="isMatPush=true;onPushMat()">Push</button>
                            </div>
                            <br />
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <div>Push the selected material of this domain to all the child domains in this sub-tree.</div>
                                <div class="card p-0 mt-2 mb-0 border-0 shadow-sm" *ngIf="false">
                                    <div class="card-body p-3 box-outline-warning">
                                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;
                                        WARNING: {{matPushAllChilds == '0' ? 'The configuration in selected domains will be overwritten by the configuration of this domain.':
                                            'The configuration in each child domain will be overwritten by the configuration of this domain.'}}
                                    </div>
                                </div>
                                <div class="mt-2">
                                    Once you push the material, please review and update the material in the child domains.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>