import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
declare let LoadSetupDomains: any;

@Component({
  selector: 'evin-users-details',
  templateUrl: './users-details.component.html',
  styleUrls: ['./users-details.component.css']
})

export class UsersDetailsComponent  extends BaseComponent implements OnInit {

  usrDetails: any[] = []; usrId: any; prnthid: any; strsLst: any[] = []; noRcrdsTxt = environment.TblNoRcrds; isDmntabShw = false;
  usrDtls: any = JSON.parse(<string>localStorage.getItem('usrDtls')); lstDmns: any[] = []; lstPrnths: any[] = [];  usrDtlsfrm = this.fb.group({
    dmnID: [''] }); slctdDmnPrnthId: any = '';
  
  constructor(public glblSvc: GlobalService, private router: Router, public fb: FormBuilder) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.prnthid = this.usrDtls.pranth_id;
    this.glblSvc.sbjctUsrId.subscribe(s => {
      this.usrId = s;
      //console.log(this.usrId);
      if (s > 0)
        this.getUserById(this.usrId)
      else
        this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } })
    })
    this.getStores(this.usrId, this.prnthid);

    if (this.slctDmnDetails.pranthLevel == 'Supervisory')
      this.isDmntabShw = true, this.loadDmns(), this.loadUsrPrnth();     
  }

  getUserById(usrId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrbyId.
      replace('uuu', usrId), null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.usrDetails = this.result.data != null ? this.result.data : [];
        }
      }
    });
  }

  getStores(usrId: any, pranthId: any){
    this.strsLst = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getStrsbyUserId.
      replace('uuu', usrId).replace('ppp', pranthId), null).then(()=> {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.strsLst = this.result.data;          
        }
      }
    });
  }

  loadDmns() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.allPrnths.
      replace('pageNo', '').replace('pageSize', '').replace('nnn', ''), null, 7).then(() => {
        let res = this.result;
        if (this.result)
           this.lstPrnths = this.result?.data?.pranthDetail ?? [];
        else
          this.glblSvc.onTstr('e', res.message);
      });
  }

  onChngDmn(evnt: any, type: any) {
    if (type == 's')
      this.slctdDmnPrnthId = evnt.item?.pranthId;
    else
      this.slctdDmnPrnthId = '';
  }

  onAddDmn(type: any, slctdPrnth: any) {
    let payLoad: any = {}, pranthDtls: any[] = [];

    if (type == 'm') {
      if (this.usrDtlsfrm.value.dmnID != '' && this.slctdDmnPrnthId != '') {
        pranthDtls.push(this.slctdDmnPrnthId);
        payLoad.userId = this.usrId, payLoad.pranthId = pranthDtls;
      }
      else 
        this.glblSvc.onTstr('w', 'Select domain(s) to add'), this.slctdDmnPrnthId = '';
    } 
    else 
      pranthDtls.push(slctdPrnth), payLoad.userId = this.usrId, payLoad.pranthId = pranthDtls;

    if (pranthDtls.length != 0) {
      if (confirm('Are you sure want to add the domain ?')) {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.saveUsrPrnth, payLoad, 7).then(() => {
          let res = this.result;
          if (this.result)
            this.glblSvc.onTstr('s', res.message), this.usrDtlsfrm.reset(), this.loadUsrPrnth();
          else
            this.glblSvc.onTstr('e', res.message), this.usrDtlsfrm.reset(), this.loadUsrPrnth();
        });
      }
    }
  }


  loadUsrPrnth() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrPrnth + this.usrId, null, 7).then(() => {
      let res = this.result;
      if (this.result)
        this.lstDmns = this.result?.data ?? [], LoadSetupDomains();
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  onDlnkDmn(prnth: any) {
    if (confirm('Are you sure want to delete the domain ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.users, environment.apiMthds.dltUsrPrnth.replace('ttt', this.usrId)
      .replace('kkk', prnth), null, 7).then(() => {
        let res = this.result;
        if (this.result)
          this.glblSvc.onTstr('e', res.message), this.loadUsrPrnth();
        else
          this.glblSvc.onTstr('e', res.message), this.loadUsrPrnth();
      });
    }
  }
}