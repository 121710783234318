<form [formGroup]="ordrStsForm" autocomplete="off">
    <div class="card">
        <div class="card-body pb-0">
            <h4>Supply Indent</h4>
            <br>
            <div class="row p-0 mt-2">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="p-2 pt-0 row">
                        <div class="col-md-4">
                            <div class="card" style="background-color: #d5ddee;">
                                <div class="card-body">
                                    <span>Consignee Schedule Order Id: <b>{{indntDtls?.supplyOrderNo}}</b></span>&nbsp;
                                    <div class="small mt-3">                                
                                        <div>Consignment Quantity: {{indntDtls?.supplyQuantity}}</div>
                                        <div>Supply Period: {{indntDtls?.consignePeriodFrom | date: 'MM-yyyy' }} to
                                            {{indntDtls?.consignePeriodEnd | date: 'MM-yyyy' }}</div>
                                    </div>
                                    <div class="small">Created on {{indntDtls?.supplyCreatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.supplyCreatedBy}} - {{indntDtls?.supplyCreatedUserId}}</a>
                                    </div>
                                    <div class="small">Last updated on {{indntDtls?.supplyUpdatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.supplyUpdatedBy}} - {{indntDtls?.supplyUpdatedUserId}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <span>eVIN Consignee No. :<b>{{indntDtls?.purchaseNo}}</b></span>&nbsp;
                                    <div class="small mt-3">
                                        <div>Product Name: {{indntDtls?.productname}}</div>
                                        <div>Consignee Name: {{indntDtls?.storename}}</div>
                                        <div>Consignment Quantity: {{indntDtls?.consigneQuantity}}</div>
                                        <div>Supply Period: {{indntDtls?.supplyPeriodFrom | date: 'MM-yyyy' }} to
                                            {{indntDtls?.supplyPeriodEnd | date: 'MM-yyyy' }}</div>
                                    </div>
                                    <div class="small">Created on {{indntDtls?.consigneeCreatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.consigneeCreatedBy}} - {{indntDtls?.consigneeCreatedUserId}}</a>
                                    </div>
                                    <div class="small">Last updated on {{indntDtls?.consigneeUpdatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.consigneeUpdatedBy}} - {{indntDtls?.consigneeUpdatedUserId}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <span>eVIN Indent Id: <b>{{indntDtls?.purchaseId}}</b></span>&nbsp;
                                    <div class="small mt-3">                                
                                        <div>Manufacturer Name: {{indntDtls?.producerName}}</div>
                                        <div>Purchase Quantity: {{indntDtls?.orderedQuantity}}</div>                                
                                        <div>Supply Period: {{indntDtls?.supplyPeriodFrom | date: 'MM-yyyy' }} to
                                            {{indntDtls?.supplyPeriodEnd | date: 'MM-yyyy' }}</div>
                                        <div class="mt-0">Supply Order No #: {{indntDtls?.reference}}</div>
                                    </div>
                                    <div class="small">Created on {{indntDtls?.purchaseCreatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.purchaseCreatedBy}}- {{indntDtls?.purchaseCreatedUserId}}</a>
                                    </div>
                                    <div class="small">Last updated on {{indntDtls?.purchaseUpdatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls?.purchaseUpdatedBy}} - {{indntDtls?.purchaseUpdatedUserId}}</a>
                                    </div>
                                </div>
                            </div>
                         </div>                       
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4>Supply Quantity: </h4>
                <div class="col-md-12" *ngIf="isCnsgnStr != null">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>Enter Quantity</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Enter Quantity"
                                    maxlength="16" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    formControlName="initiatedQty"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.initiatedQty.errors}">

                                <div *ngIf="isSbmtd && fc.initiatedQty.errors" class="invalid-feedback">
                                    <div *ngIf="fc.initiatedQty.errors?.required">Supply Quantity is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label>Estimated Arrival</label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)" 
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', 
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="arrival_date"
                                    [(ngModel)]="frmDate" placeholder="From" [minDate]="dt2day" [maxDate]="dt2mx" readonly 
                                    [ngClass]="{'is-invalid': isSbmtd && fc.arrival_date.errors}">
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('arrival_date')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>

                                <div *ngIf="isSbmtd && fc.arrival_date.errors" class="invalid-feedback">
                                    <div *ngIf="fc.arrival_date.errors?.required">Estimated date is required</div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group mt-1">
                                <button class="btn btn-light mt-4" *ngIf="isEdit" (click)="onCancelEdit()">Cancel Edit</button>&nbsp;
                                <button class="btn btn-sm mt-4 btn-primary"
                                    (click)="isSbmtd=true; onSubmit()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 mt-3">
                    <p><b>Supply Quantity List : </b></p>
                    <div class="table_spacing table-responsive table-row-spacing">
                        <table class="table table-striped table-sm table-bordered" id="table_detail">
                            <thead>
                                <tr>
                                    <th class="text-center">S.No</th>
                                    <th class="text-center">Consignee Schedule Order Id</th>
                                    <th class="text-center">Quantity</th>
                                    <th>Status</th>
                                    <th>Created On</th>
                                    <th>Estimated Arrival</th>
                                    <th>Shipped On</th>                                    
                                    <th>Fulfilled On</th>
                                    <th>Created By</th>
                                    <th class="text-center" *ngIf="isCnsgnStr != null">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="qntutyLst.length != 0">
                                <!--(click)="openModal(x)"-->
                                <tr *ngFor="let x of qntutyLst; let i=index">
                                    <td class="text-center">{{i+1}}</td>
                                    <td class="text-center" (click)="onOrdDtls(x)" style="cursor: pointer;">{{x?.consignee_no}}</td>
                                    <td class="text-center">{{x?.quantity}}</td>
                                    <td>{{x?.statusname}}</td>
                                    <td>{{x?.created_on | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                                    <td>{{x?.arrival_date | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{x?.shipped_on | date: 'dd-MM-yyyy hh:mm:ss'}}</td>                                    
                                    <td>{{x?.fulfilled_on | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                                    <td>{{x?.createdby}} <br> {{x?.createduserid}}</td>
                                    <td class="text-center" *ngIf="isCnsgnStr != null">
                                        <a (click)="onQuanEdt(x);" *ngIf="x?.statusname !='Shipped'"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                        <a (click)="onQuantityDel(x?.indentid)"><i
                                                class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center"><b>Total Quantity</b></td>
                                    <td class="text-center"><b>{{ttlQty}}</b></td>
                                    <td colspan="6"></td>
                                    <td *ngIf="isCnsgnStr != null"></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="qntutyLst.length == 0">
                                <tr>
                                    <td colspan="10">{{noRcrdsTxt}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>