<div class="card p-0 border-0" [formGroup]="txnExprtFrm">
    <div class="row mt-2 mb-0 p-3">
        <div class="col-xl-2">
            <label>Batch Type</label>
            <select class="form-select form-select-sm" formControlName="reportType">
                <option value="">All</option>
                <option value="TransactionWithBatch">With Batch</option>
                <option value="TransactionWithoutBatch">Without Batch</option>
            </select>
        </div>
        <div class="col-xl-4">
            <div class="row">
                <div class="col-xl-6">
                    <label>From<sup class="mndt">*</sup></label>
                    <div class="input-group">
                        <input type="text" autocomplete="off" onpaste="return false" #dpSD="bsDatepicker"
                            readonly class="form-control form-control-sm optinbg bsDP-bg"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                            formControlName="monthFrom" onkeypress='return event.charCode == 8' placeholder="From"
                            (bsValueChange)="dt2=null" [(bsValue)]="dt1" [maxDate]="dt2Day" bsDatepicker>
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-bsdp" (click)="dpSD.toggle()"
                                [attr.aria-expanded]="dpSD.isOpen" type="button">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <label>To<sup class="mndt">*</sup></label>
                    <div class="input-group">
                        <input type="text" autocomplete="off" onpaste="return false" formControlName="monthTo"
                            #dpED="bsDatepicker" [(bsValue)]="dt2" class="form-control form-control-sm optinbg bsDP-bg"
                            readonly bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                            onkeypress='return event.charCode == 8' placeholder="To" [maxDate]="dt2Day" [minDate]="dt1">
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 float-xl-end text-left"><strong>Max 12 months will be allowed</strong></div>
            </div>
        </div>
        <div class="col-xl-2 mt-4">
            <button class="btn btn-sm btn-light" (click)="lstGrid=[];onRst()">Reset</button>&nbsp;&nbsp;
            <button class="btn btn-sm btn-primary" (click)="onFltr()" [attr.disabled]="(dt1==''||dt1==null||dt2==''||dt2==null)?true:null">Filter</button>
        </div>
    </div>
    <div class="row p-3" *ngIf="lstGrid?.length!=0">
        <div class="col-xl-6 text-start">
            {{pageEntrs}}
        </div>
        <div class="col-xl-6">
            <div class="float-end">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
            </div>
        </div>
    </div>
    <div class="table_spacing table-responsive table-row-spacing p-3">
        <table class="table table-striped table-sm table-bordered">
            <thead class="text-center">
                <tr>
                    <th>S.No.</th>
                    <th>Type</th>
                    <th>Month</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody class="table_data">
                <tr *ngFor="let d of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms }; let i=index">
                    <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                    <td>{{d.reportType}}</td>
                    <td>{{d.monthName}}</td>
                    <td>Completed <br>
                        <span>{{d.createdOn | date: "dd-MM-yyyy"}}</span>
                    </td>
                    <td class="text-center">
                        <a class="btn btn-link" (click)="onDwnld(d)">Download</a>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="lstGrid?.length==0">
                <tr>
                    <td colspan="6" class="text-center">
                        {{noRcrdsTxt}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row p-3" *ngIf="lstGrid?.length!=0">
        <div class="col-xl-6 text-start">
            {{pageEntrs}}
        </div>
        <div class="col-xl-6">
            <div class="float-end">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>