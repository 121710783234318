<form [formGroup]="mnlDomFrm" autocomplete="off">
    <div class="card p-3 mb-0">
        <div class="card-header p-0 pb-2">
            <h6 class="mb-0">Add new domain</h6>
        </div>
        <div class="card-body p-0">
            <div class="row ">
                <div class="col-xl-12">
                    <span class="small m-0">Please specify domain information and level</span>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Domain name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Domain name" class="form-control form-control-sm" id="" formControlName="name"
                            [ngClass]="{'is-invalid': isSbmtd && fc.name.errors}">
                            <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                                <div *ngIf="fc.name.errors.required">Domain name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Owner <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Owner Name" [value]="lgnUsrName"  class="form-control form-control-sm" id="" formControlName="owner"
                            [ngClass]="{'is-invalid': isSbmtd && fc.owner.errors}" [attr.disabled]=true>    
                            <div *ngIf="isSbmtd && fc.owner.errors" class="invalid-feedback">
                                <div *ngIf="fc.owner.errors.required">Owner name is required</div>
                            </div>                        
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Description</label>
                            <textarea class="form-control h-80" id="exampleFormControlTextarea1" rows="1" formControlName="desc"></textarea>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Domain Level <span class="text-danger">*</span></label>
                            <select class="form-select form-select-sm" formControlName="prnthLvl" [ngClass]="{'is-invalid': isSbmtd && fc.prnthLvl.errors}">
                                <option value="">--Select--</option>
                                <option value="State">State level</option>
                                <option value="Region">Region level</option>
                                <option value="Division">Division level</option>
                                <option value="District">District level</option>
                                <option value="Block">Block level</option>
                                <option value="Supervisory">Supervisory</option>                                
                            </select>                              
                            <div *ngIf="isSbmtd && fc.prnthLvl.errors" class="invalid-feedback">
                                <div *ngIf="fc.prnthLvl.errors.required">Domain level is required</div>
                            </div>                        
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row mb-2 mt-3">
                                        <div class="form-group col-xl-3 mt-3">
                                            <label for="textBox">Country <span class="text-danger">*</span></label>
                                            <select id="country_id" class="form-select form-select-sm"
                                                formControlName="country_id" (change)="onLocChng($event, 'country')" [attr.disabled]=true>
                                                <option value="null">-Select-</option>
                                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3 mt-3">
                                            <label for="textBox">State</label>
                                            <select id="state" class="form-select form-select-sm"
                                                formControlName="state" (change)="onLocChng($event, 'state')">
                                                <option value="null">-Select-</option>
                                                <option *ngFor="let s of stsLst" [value]="s.id">{{s.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3 mt-3">
                                            <label for="textBox">District</label>
                                            <select id="disrict_id" class="form-select form-select-sm"
                                                formControlName="district" >
                                                <option value="null">-Select-</option>
                                                <option *ngFor="let s of dstrtLst" [value]="s.districtid">
                                                    {{s.districtname}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3 mt-3">
                                            <label for="textBox">Timezone <span class="text-danger">*</span></label>
                                            <select id="time_zone_id" class="form-select form-select-sm"
                                                formControlName="time_zone_id" [attr.disabled]=true>
                                                <option value="null">Select Timezone</option>
                                                <option *ngFor="let tz of tmZoneLst" value="{{tz.id}}">{{tz.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary me-2 mb-2" (click)="isSbmtd=true;onSave()">Save</button>
                        <button class="btn btn-sm btn-light mb-2" (click)="onCncl()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>