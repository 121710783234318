import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-cnfg-custom-dashboard-view',
  templateUrl: './cnfg-custom-dashboard-view.component.html',
  styleUrls: ['./cnfg-custom-dashboard-view.component.css']
})
export class CnfgCustomDashboardViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
