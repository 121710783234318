<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="mmTxnLst && !isTxnLstHdn">
                <a class="nav-link" id="top-trans-tab" data-bs-toggle="tab" href="#top-trans" role="tab" aria-controls="top-trans"
                    aria-selected="true" (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">Transactions
                </a>
            </li>
            <li class="nav-item" *ngIf="mmCrtTxn && !isTxnCrtHdn">
                <a class="nav-link" id="create-trans-top-tab" data-bs-toggle="tab" href="#top-create-trans" role="tab"
                    aria-controls="top-profile" aria-selected="false" (click)="onTabSelection('new')"
                    [ngClass]="paramTab === 'new' ? 'active' : ''" >Create transactions
                </a>
            </li>
            <li class="nav-item" *ngIf="false">
                <a class="nav-link" id="bulkup-top-tab" data-bs-toggle="tab" href="#top-bulkup" role="tab" aria-controls="top-bulkup"
                    aria-selected="false" (click)="onTabSelection('upld')" [ngClass]="paramTab === 'upld' ? 'active' : ''"
                    [hidden]="!txnUploadFlag">Bulk upload
                </a>
            </li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="top-trans" role="tabpanel" aria-labelledby="top-1trans-tab" *ngIf="mmTxnLst && !isTxnLstHdn"
                [ngClass]="paramTab === 'list' ? 'show active' : ''">
               <evin-transactions-list></evin-transactions-list>
            </div>
            <div class="tab-pane fade" id="top-create-trans" role="tabpanel" aria-labelledby="create-trans-top-tab" *ngIf="mmCrtTxn && !isTxnCrtHdn"
                [ngClass]="paramTab === 'new' ? 'show active' : ''">
                <evin-create-transactions></evin-create-transactions>
            </div>
            <div class="tab-pane fade" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab" *ngIf="false"
                [ngClass]="paramTab === 'upld' ? 'show active' : ''" [hidden]="!txnUploadFlag">
                <evin-bulk-upload></evin-bulk-upload>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link f-14 active" id="top-batchtab-tab" data-bs-toggle="tab" href="#top-batchtab" role="tab"
                            aria-controls="top-batchtab" aria-selected="true">
                            <div class="modal-title">Batches of BCG diluent (dose)</div>
                        </a>
                    </li>
                </ul>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade show active" id="top-batchtab" role="tabpanel"
                        aria-labelledby="top-batchtab-tab">
                        <div class="card mb-2 p-0 border-0 rounded-0">
                            <div class="card-body p-2">
                                <div class="table-responsive mt-2">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Batch ID</th>
                                                <th>Manufactured date (dd/mm/yyyy)</th>
                                                <th>Manufacturer</th>
                                                <th>Expiry date (dd/mm/yyyy)</th>
                                                <th>Opening stock</th>
                                                <th>Closing stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>XYAD12</td>
                                                <td></td>
                                                <td>Serum Institute of India</td>
                                                <td>24/1/21 4:15 PM</td>
                                                <td>0</td>
                                                <td>2,200</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop2">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h6 class="modal-title">Recent zero stock incidents for RVV dropper (piece)</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="table-responsive mt-2">
                            <table class="table table-striped table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th>Batch ID</th>
                                        <th>Manufactured date (dd/mm/yyyy)</th>
                                        <th>Manufacturer</th>
                                        <th>Expiry date (dd/mm/yyyy)</th>
                                        <th>Opening stock</th>
                                        <th>Closing stock</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>XYAD12</td>
                                        <td>01/02/21/td>
                                        <td>Serum Institute of India</td>
                                        <td>24/1/21 4:15 PM</td>
                                        <td>74, 400</td>
                                        <td>74,200</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop3">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h6 class="modal-title">Recent zero stock incidents for RVV dropper (piece)</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="table-responsive mt-2">
                            <table class="table table-striped table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th>Batch ID</th>
                                        <th>Manufactured date (dd/mm/yyyy)</th>
                                        <th>Manufacturer</th>
                                        <th>Expiry date (dd/mm/yyyy)</th>
                                        <th>Opening stock</th>
                                        <th>Closing stock</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>XYAD12</td>
                                        <td>05/03/2021</td>
                                        <td>Serum Institute of India</td>
                                        <td>24/1/21 4:15 PM</td>
                                        <td>1000</td>
                                        <td>980</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>