<div class="card p-0">
    <div class="card-header shadow-none p-2">
        <div class="h6 mb-0">Accounting Configuration</div>
    </div>
    <div class="card-body p-2 mt-3 mb-0">
        <form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card p-0 mt-0 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Accounting</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Please specify categories that can be applied to the materials in the
                                system
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <label class="form-check-label me-2 f-w-500" for="flexCheckChecked">
                                        Enable
                                    </label><br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                            checked>
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Enable accounting
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Credit limit</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Specify default credit limit and enforcement constraints.</div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <div class="form-group col-xl-12 mb-2">
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Credit limit (default)
                                        </label>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <input type="text" placeholder="" value="" class="form-control" id="">
                                            </div>
                                            <div class="col-sm-3">
                                                INR
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-12 mb-2">
                                        <div class="f-w-500 mt-3">Enforce limits</div>
                                        <label class="form-check-label mt-2" for="flexCheckChecked">
                                            Enforce credit limit when Indent status is changed to: &nbsp;
                                        </label><br />
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                id="exampleRadios1" value="option1" checked>
                                            <label class="form-check-label" for="exampleRadios1">
                                                None
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                id="exampleRadios2" value="option2">
                                            <label class="form-check-label" for="exampleRadios2">
                                                confirmed
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                id="exampleRadios3" value="option3">
                                            <label class="form-check-label" for="exampleRadios3">
                                                shipped
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end mt-3 pb-3">
                        <button type="button" class="btn btn-sm btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>