import { AfterViewChecked, Component, DoCheck, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-assets-create',
  templateUrl: './assets-create.component.html',
  styleUrls: ['./assets-create.component.css']
})

export class AssetsCreateComponent extends BaseComponent implements OnInit, OnDestroy {

  show: any = false; astTypLst: any[] = []; slctdAsetId: any; manufacturersLst: any[] = []; slctdVendId: any; isDup = false; showOther = true;
  editableAssetInfo: any; selectedMaintainers: any; selectedOwners: any; isStoreID = false; enableNotifcation = false; isDupTxt: string = '';
  modelsLst: any[] = []; strsLst: any[] = []; userLst: any[] = []; pranthid: any; tmZoneLst: any[] = []; cntriesLst: any[] = []; astStatsLst: any[] = [];
  LngLst: any[] = []; useDefaultConfiguration: any; bnData: any; btnFlag = true; defaultAsetCnfgData: any; editFlag = false; showPshBtn = false;
  dacd: any; sdcl: any; comuChnlLst: any[] = []; showData = false; owners: any[] = []; dropdownSettings = {}; isEditDFT = false; pushSmsConfig = false;
  slctdStrId: any; maintainers: any[] = []; isSbmtd = false; tab = 'A'; lstPrnts: any[] = []; slctdFcltyId: any = null; asstId: any;
  maxSnoLength: any; cYr: any = (new Date()).getFullYear(); stus: any = ''; snsrdscnct: boolean = false;
  setupAssetsCreateForm = this.formBuilder.group({
    manufacturer: ['', [Validators.required]], asetTypeId: ['', [Validators.required]], statusId: ['', [Validators.required]],
    alternateMobile: ['', [Validators.minLength(10), Validators.maxLength(13)]], alternateSimNumber: ['', Validators.min(5)], alternateSimVendor: [''],
    assetImeiNumber: [''], assetModelId: ['', [Validators.required]], enablePushConfiguration: ['false'], firmwareVersion: [''],
    gsmModuleVersion: [''], isDeleted: [false], id: [null], maintainers: [[], Validators.required], mobileNumber: ['', [Validators.minLength(10), Validators.maxLength(13)]],
    owners: [[], Validators.required], serialNumber: ['', [Validators.required, Validators.minLength(5)]], setDefaultConfiguration: [true], simNumber: ['', Validators.min(5)],
    simVendor: [''], storeId: ['', Validators.required], yearOfManufacture: ['', [Validators.required, Validators.min(environment.astsMinY), Validators.max(this.cYr)]],
    assetConfiguration: this.formBuilder.group({
      alarmFrequencyNotificationDuration: [''], alarmFrequencyNotificationNumber: [''],
      alarmPushUrl: [''], assetSensorConfigurationList: this.formBuilder.array([]), communicationChannelId: [''], configurationPullUrl: [''],
      countryId: [''], deviceAlarmPushNotification: [false], deviceReadyStatusPushUrl: [''], highAlarmTemperature: [''],
      highAlarmTemperatureDuration: [''], highWarningTemperature: [''], highWarningTemperatureDuration: [''], id: [null], languageId: [''],
      lowAlarmTemperature: [''], lowAlarmTemperatureDuration: [''], lowBatteryAlarmThresholdLimit: [''], lowWarningTemperature: [''],
      lowWarningTemperatureDuration: [''], nameSpace: [''], phoneNumbersToSendSmsNotification: [''], powerOutageAlarmDuration: [''],
      pushInterval: [''], snsConFailInt: [''], samplingInterval: [''], smsGatewayKeyword: [''], smsGatewayPhoneNumber: [''], smsGatewaySenderId: [''],
      statsPushNotification: [false], statsPushUrl: [''], temperatureAlarmPushNotifications: [false], temperaturePushUrl: [''],
      temperatureIncursionExcursionPushNotification: [false], temperaturePushNotification: [false], timeZoneId: [1], wifiPassword: [''],
      wifiSecurity: [''], wifiSsid: [''], smsAlarmSent: [false], buzz: [false]
    }), sensorEMAC: [''], sensorDMAC: [''], sensorCMAC: [''],
    sensorBMAC: ['', [Validators.required, Validators.minLength(10)]], sensorAMAC: [''],
  }); dupMsgA = ''; dupMsgB = ''; dupMsgC = ''; dupMsgD = ''; dupMsgE = '';
  slctAstMdl: any = ''; snsrInfo: boolean = false;

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astTypLst = s.astTypLst, this.tmZoneLst = s.tmZoneLst, this.cntriesLst = s.cntriesLst,
        this.LngLst = s.LngLst, this.comuChnlLst = s.comuChnlLst,
        this.astStatsLst = s.stsLst.filter((f: any) => f.statusType === 'Asset')
    }), this.glblSvc.sbjctStrList.subscribe(s => { let prntLst: any[] = s; prntLst.filter(e => { this.lstPrnts.push(Object.assign(e, { strDtls: e.storeName + ' $$$ ' + e.storeId })) }) }),
      this.pranthid = this.chsnDmnId;
    this.glblSvc.editAssetInfo.subscribe(s => {
      this.dupMsgA = '', this.dupMsgB = '', this.dupMsgC = '', this.dupMsgD = '', this.dupMsgE = '', this.snsrInfo = false;
      if (Object.keys(s).length != 0) {
        this.GetDefaultConfgs().clear(), this.setupAssetsCreateForm.reset();
        this.setupAssetsCreateForm.patchValue({
          asetTypeId: [s.assetTypeId],
          manufacturer: [s.assetVendorId],
          assetModelId: [s.assetModelId],
          serialNumber: s.serialNumber,
          id: s.assetId,
          isDeleted: false
        });
        this.setupAssetsCreateForm.get('serialNumber')?.disable();
        this.setupAssetsCreateForm.get('asetTypeId')?.disable();
        this.asstId = s.assetId; // this.getSerialNoData(s.serialNumber);
        this.LoadAssetDetails(this.asstId);
        let event = {
          target: {
            value: s.assetTypeId
          }
        }
        let modalEvent = {
          target: {
            value: s.assetVendorId
          }
        }
        this.onNestedSlct(event, 'asetType');
        this.onNestedSlct(modalEvent, 'manufacturer');
        setTimeout(() => {
          this.setupAssetsCreateForm.get('manufacturer')?.setValue(s.assetVendorId);
          this.setupAssetsCreateForm.get('assetModelId')?.setValue(s.assetModelId);
        }, 500);
        this.btnFlag = true;
      } else {
        this.cancel(), this.editFlag = false, this.owners = [], this.maintainers = [], this.setupAssetsCreateForm.get('serialNumber')?.enable(),
          this.setupAssetsCreateForm.get('asetTypeId')?.enable(); this.isEditDFT = false;
      }
    })
  }

  ngOnInit(): void {
    this.dropdowns(); this.LoadUsers();  //this.LoadStores();
  }

  onChngFclty(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value; console.log(evnt);
    if (evnt == '' || evnt == undefined)
      this.slctdFcltyId = '', this.setupAssetsCreateForm.get('storeId')?.setValue(null);
    else
      if (this.lstPrnts.filter(a => a.storeId == evnt.split('$$$')[1]).length != 0)
        this.slctdFcltyId = this.lstPrnts.filter(a => a.storeId == evnt.split('$$$')[1])[0].storeId, this.setupAssetsCreateForm.get('storeId')?.setValue(this.lstPrnts.filter(a => a.storeId == evnt.split('$$$')[1])[0].storeName);
      else
        this.slctdFcltyId = '', this.setupAssetsCreateForm.get('storeId')?.setValue(null);
    // for (let s = 0; s < this.lstPrnts.length; s++) {
    //   if (this.lstPrnts[s].storeName == event.value)
    //     this.slctdFcltyId = this.lstPrnts[s].storeId;
    // }
  }

  tglBtn() {
    this.bnData = this.btnFlag ? 'Edit device Configuration' : 'Reset to default configuration';
  }

  optChange($event: any) {
    let data = this.getDefault().controls;
    this.enableNotifcation = $event.target.value;
    if (this.slctdAsetId == 1) {
      if ($event.target.value == true || $event.target.value == 'true') {
        this.show = true, this.bnData = 'Edit device Configuration';
        if (this.editFlag && this.isEditDFT)
          this.isEditDFT = true, this.LoadAssetDetails(this.asstId, false);
        else
          this.GetDefaultConfgs().clear(), this.LoadConfigAssDefData();
      }
      else {
        this.show = false; this.snsrdscnct = false;
        if (this.isEditDFT) // if ((this.editFlag && this.isEditDFT) || (!this.editFlag))
          alert('All the configurations will be deleted, if exists. Are you sure want to proceed ?');
        data.alarmPushUrl.clearValidators(), data.communicationChannelId.clearValidators(), data.highAlarmTemperature.clearValidators(), data.snsConFailInt.clearValidators(),
          data.highAlarmTemperatureDuration.clearValidators(), data.lowAlarmTemperature.clearValidators(), data.lowAlarmTemperatureDuration.clearValidators(),
          data.pushInterval.clearValidators(), data.samplingInterval.clearValidators(), data.smsGatewayPhoneNumber.clearValidators(),
          data.temperaturePushUrl.clearValidators(), data.alarmPushUrl.updateValueAndValidity(), data.communicationChannelId.updateValueAndValidity(), data.highAlarmTemperature.updateValueAndValidity(),
          data.highAlarmTemperatureDuration.updateValueAndValidity(), data.lowAlarmTemperature.updateValueAndValidity(), data.lowAlarmTemperatureDuration.updateValueAndValidity(),
          data.pushInterval.updateValueAndValidity(), data.samplingInterval.updateValueAndValidity(), data.smsGatewayPhoneNumber.updateValueAndValidity(), data.snsConFailInt.updateValueAndValidity(),
          data.temperaturePushUrl.updateValueAndValidity();
      }
    }
  }

  // LoadStores() {
  //   this.strsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.str, environment.apiMthds.getStrsByPrnth.
  //     replace('pranthid', `${this.pranthid}`), null).then(() => {
  //       if (this.result) {
  //         if (this.result.data)
  //           this.strsLst = this.result.data != null ? this.result.data : [];
  //       }
  //     })
  // }

  // onChngFacility($event: any) {
  //   this.slctdStrId = this.strsLst.filter(a => a.storeName == $event.value)[0].storeId;
  // }

  LoadUsers() {
    let myuserData: any[] = [];
    this.userLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.str, environment.apiMthds.getUsrsByPrnth.
      replace('pranthid', `${this.pranthid}`), null).then(() => {
        if (this.result) {
          if (this.result.data)
            myuserData = this.result.data != null ? this.result.data : [],
              this.userLst = myuserData.map(e => ({ ...e, userData: e.userName + " - " + e.userId }));
        }
      })
  }

  onNestedSlct($event: any, type: any) {
    this.snsrInfo = false;
    this.setupAssetsCreateForm.get('sensorDMAC')?.clearValidators(),
      this.setupAssetsCreateForm.get('sensorDMAC')?.updateValueAndValidity(),
      this.setupAssetsCreateForm.get('sensorBMAC')?.clearValidators(),
      this.setupAssetsCreateForm.get('sensorBMAC')?.updateValueAndValidity();
    let radioCheck = { target: { value: false } }
    this.optChange(radioCheck);
    this.setupAssetsCreateForm.patchValue({ enablePushConfiguration: 'false' })
    if (type == 'asetType') {
      this.slctdAsetId = $event.target.value, this.show = false;
      this.setupAssetsCreateForm.get('serialNumber')?.setValue('');
      this.setupAssetsCreateForm.get('sensorDMAC')?.setValue('');
      this.setupAssetsCreateForm.get('assetImeiNumber')?.setValue('');

      if (this.slctdAsetId != '')
        this.LoadManufacturers();
      else
        this.setupAssetsCreateForm.get('manufacturer')?.setValue(''), this.setupAssetsCreateForm.get('assetModelId')?.setValue('');

      if (this.slctdAsetId == 1)
        this.showData = true, this.showOther = false, this.maxSnoLength = 18, this.showPshBtn = true, this.setupAssetsCreateForm.get('owners')?.clearValidators(),
          this.setupAssetsCreateForm.get('owners')?.updateValueAndValidity(),
          this.setupAssetsCreateForm.get('maintainers')?.clearValidators(), this.setupAssetsCreateForm.get('maintainers')?.updateValueAndValidity();
      else
        this.showData = false, this.showOther = true, this.showPshBtn = false, this.maxSnoLength = 30,
          this.setupAssetsCreateForm.get('owners')?.setValidators([Validators.required]),
          this.setupAssetsCreateForm.get('owners')?.updateValueAndValidity(),
          this.setupAssetsCreateForm.get('maintainers')?.setValidators([Validators.required]),
          this.setupAssetsCreateForm.get('maintainers')?.updateValueAndValidity();
    }
    if (type == 'manufacturer') {
      this.setupAssetsCreateForm.get('assetModelId')?.setValue('')
      this.slctdVendId = $event.target.value;
      if (this.slctdVendId != '')
        this.LoadModels();
      else
        this.setupAssetsCreateForm.get('assetModelId')?.setValue('')
    }
    if (type == 'astModel') {
      this.slctAstMdl = $event.target.value;
      if (this.slctdAsetId == 1 && this.slctdVendId == 58 && this.slctAstMdl == 459) {
        let radioCheck = {
          target: {
            value: true
          }
        }
        this.snsrInfo = true; this.optChange(radioCheck);
        this.setupAssetsCreateForm.patchValue({ enablePushConfiguration: 'true' })
        // this.setupAssetsCreateForm.get('sensorDMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
        //   this.setupAssetsCreateForm.get('sensorDMAC')?.updateValueAndValidity(),
          this.setupAssetsCreateForm.get('sensorBMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
          this.setupAssetsCreateForm.get('sensorBMAC')?.updateValueAndValidity();
      } else {
        this.snsrInfo = false;
      }
    }
  }

  LoadManufacturers() {
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getByasetTypId.
      replace('asetId', this.slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manufacturersLst = this.result.data != null ? this.result.data : [];
          if (this.manufacturersLst.length == 1)
            this.setupAssetsCreateForm.get('manufacturer')?.setValue(this.manufacturersLst[0].id),
              this.slctdVendId = this.manufacturersLst[0].id, this.LoadModels();
          else {
            if (!this.editFlag)
              this.setupAssetsCreateForm.get('manufacturer')?.setValue(''), this.setupAssetsCreateForm.get('assetModelId')?.setValue('');
          }
        }
      })
  }

  LoadConfigAssDefData() {
    let data = this.getDefault().controls;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asetDefCnfg, environment.apiMthds.getOne, null, 2, false, 3).then(() => {
      if (this.result) {
        this.defaultAsetCnfgData = this.result.data;
      

        if (this.defaultAsetCnfgData) {
          this.defaultAsetCnfgData.alarmFrequencyNotificationNumber =24;
          this.defaultAsetCnfgData.highAlarmTemperature =43;
          this.defaultAsetCnfgData.phoneNumbersToSendSmsNotification =this.defaultAsetCnfgData.phoneNumbersToSendSmsNotification =="[]" ? null: this.defaultAsetCnfgData.phoneNumbersToSendSmsNotification ;
          this.defaultAsetCnfgData.alarmFrequencyNotificationDuration =60;
          this.defaultAsetCnfgData.snsConFailInt = 60;
          this.dacd = this.defaultAsetCnfgData, this.sdcl = this.defaultAsetCnfgData?.sensorDefaultConfigurationList[0],
            this.updateForm(this.dacd);
          if (this.defaultAsetCnfgData?.sensorDefaultConfigurationList.length != this.GetDefaultConfgs().controls.length) {
            this.sortAryLst(this.defaultAsetCnfgData?.sensorDefaultConfigurationList, 'sensorId'),
              this.defaultAsetCnfgData?.sensorDefaultConfigurationList.forEach((data: any) => {
                if (this.slctdAsetId == 1)
                  this.GetDefaultConfgs().push(this.onAddFormCtrls(data)), this.tab = this.GetDefaultConfgs()?.at(0).get('sensorId')?.value;
              });
            if (this.defaultAsetCnfgData?.sensorDefaultConfigurationList.length == this.GetDefaultConfgs().controls.length)
              data.alarmPushUrl.setValidators([Validators.required]), data.communicationChannelId.setValidators([Validators.required]),
                data.highAlarmTemperature.setValidators([Validators.required]), data.highAlarmTemperatureDuration.setValidators([Validators.required]),
                data.lowAlarmTemperature.setValidators([Validators.required]), data.lowAlarmTemperatureDuration.setValidators([Validators.required]),
                data.pushInterval.setValidators([Validators.required]), data.snsConFailInt.setValidators([Validators.required]), data.samplingInterval.setValidators([Validators.required]),
                data.smsGatewayPhoneNumber.setValidators([Validators.required]), data.temperaturePushUrl.setValidators([Validators.required]);
          }
        }
      }
    });
  }

  GetDefaultConfgs(): FormArray {
    return this.setupAssetsCreateForm.get('assetConfiguration')?.get('assetSensorConfigurationList') as FormArray;
  }

  getDefault(): FormGroup {
    return this.setupAssetsCreateForm.get('assetConfiguration') as FormGroup;
  }

  dropdowns() {
    this.dropdownSettings = {
      singleSelection: false, idField: 'id', textField: 'userData', selectAllText: 'ALL',
      unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'owner') {
      if (evntType == 'os')
        this.owners.push(item)
      else if (evntType == 'osa')
        this.owners = [], this.owners = item
      else if (evntType == 'ds')
        this.owners = this.owners.filter(s => s.id != item.id)
      else
        this.owners = [];
    }
    if (type == 'maintainers') {
      if (evntType == 'os')
        this.maintainers.push(item)
      else if (evntType == 'osa')
        this.maintainers = [], this.maintainers = item
      else if (evntType == 'ds')
        this.maintainers = this.maintainers.filter(s => s.id != item.id)
      else
        this.maintainers = [];
    }
  }

  stripText(event: any) {
    let result = event.key;
    if (this.slctdAsetId == 1) {
      const seperator = '^([0-9])';
      const maskSeperator = new RegExp(seperator, 'g');
      result = maskSeperator.test(event.key);
    }
    return result;
  }

  onAddFormCtrls(a: any) {
    return this.formBuilder.group({
      alarmFrequencyNotificationDuration: a.alarmFrequencyNotificationDuration,
      alarmFrequencyNotificationNumber: a.alarmFrequencyNotificationNumber,
      createdBy: a.createdBy,
      createdOn: a.createdOn,
      deviceAlarmPushNotification: a.deviceAlarmPushNotification,
      highAlarmTemperature: a.highAlarmTemperature,
      highAlarmTemperatureDuration: a.highAlarmTemperatureDuration,
      highWarningTemperature: a.highWarningTemperature,
      highWarningTemperatureDuration: a.highWarningTemperatureDuration,
      lowAlarmTemperature: a.lowAlarmTemperature,
      lowAlarmTemperatureDuration: a.lowAlarmTemperatureDuration,
      lowWarningTemperature: a.lowWarningTemperature,
      lowWarningTemperatureDuration: a.lowWarningTemperatureDuration,
      pushInterval: a.pushInterval,
      snsConFailInt: +a.snsConFailInt,
      samplingInterval: a.samplingInterval,
      sensorId: a.sensorId,
      statsPushNotification: a.statsPushNotification,
      buzz: a.buzz,
      smsAlarmSent: a.smsAlarmSent,
      temperatureAlarmPushNotifications: a.temperatureAlarmPushNotifications,
      temperatureIncursionExcursionPushNotification: a.temperatureIncursionExcursionPushNotification,
      temperaturePushNotification: a.temperaturePushNotification,
      useDefaultConfiguration: this.editFlag ? true : a.useDefaultConfiguration
    });
  }

  updateForm(d: any) {
    this.setupAssetsCreateForm.patchValue({
      assetConfiguration: {
        configurationPullUrl: d?.configurationPullUrl, communicationChannelId: d?.communicationChannelId,
        alarmPushUrl: d?.alarmPushUrl, alarmFrequencyNotificationNumber: d?.alarmFrequencyNotificationNumber,
        smsGatewayKeyword: d?.smsGatewayKeyword, smsGatewayPhoneNumber: d?.smsGatewayPhoneNumber,
        smsGatewaySenderId: d?.smsGatewaySenderId, lowBatteryAlarmThresholdLimit: d?.lowBatteryAlarmThresholdLimit,
        lowAlarmTemperatureDuration: d?.lowAlarmTemperatureDuration, lowAlarmTemperature: d?.lowAlarmTemperature,
        countryId: d?.countryId != null ? d?.countryId : '', languageId: d?.languageId != null ? d?.languageId : '',
        highWarningTemperatureDuration: d?.highWarningTemperatureDuration, highWarningTemperature: d?.highWarningTemperature,
        highAlarmTemperatureDuration: d?.highAlarmTemperatureDuration, highAlarmTemperature: d?.highAlarmTemperature,
        deviceReadyStatusPushUrl: d?.deviceReadyStatusPushUrl, deviceAlarmPushNotification: d?.deviceAlarmPushNotification,
        lowWarningTemperature: d?.lowWarningTemperature, lowWarningTemperatureDuration: d?.lowWarningTemperatureDuration, nameSpace: d?.nameSpace,
        phoneNumbersToSendSmsNotification: d?.phoneNumbersToSendSmsNotification == "[]" ? null :d?.phoneNumbersToSendSmsNotification, powerOutageAlarmDuration: d?.powerOutageAlarmDuration,
        pushInterval: d?.pushInterval, samplingInterval: d?.samplingInterval, wifiPassword: d?.wifiPassword, wifiSecurity: d?.wifiSecurity,
        wifiSsid: d?.wifiSsid, alarmFrequencyNotificationDuration: d?.alarmFrequencyNotificationDuration, snsConFailInt: +(d?.snsConFailInt),
        temperaturePushUrl: d?.temperaturePushUrl, statsPushUrl: d?.statsPushUrl, statsPushNotification: d?.statsPushNotification,
        temperatureIncursionExcursionPushNotification: d?.temperatureIncursionExcursionPushNotification, smsAlarmSent: d?.smsAlarmSent,
        temperaturePushNotification: d?.temperaturePushNotification, timeZoneId: d?.timeZoneId != null ? d?.timeZoneId : '1',
        setDefaultConfiguration: d?.setDefaultConfiguration, statusId: d?.statusId, buzz: d?.buzz, temperatureAlarmPushNotifications: d?.temperatureAlarmPushNotifications
      }
    });
    if (this.slctdAsetId == 1 && this.slctdVendId == 58 && this.slctAstMdl == 459) {
      this.snsrdscnct = true;
      this.setupAssetsCreateForm.patchValue({
        assetConfiguration: { temperatureAlarmPushNotifications: true, smsAlarmSent: true }
      })
    }
    else {
      this.snsrdscnct = false;
    }
  }

  LoadModels() {
    this.modelsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getByVendorId.
      replace('vendId', this.slctdVendId).replace('rrrr', this.slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.modelsLst = this.result.data != null ? this.result.data : [];
          if (this.modelsLst.length == 1)
            this.setupAssetsCreateForm.get('assetModelId')?.setValue(this.modelsLst[0].id);
        }
      })
  }

  get fc() { return this.setupAssetsCreateForm.controls; }

  get fc1() { return this.setupAssetsCreateForm.get('assetConfiguration') as FormGroup; }

  onSaveSetupAssets() {
    this.checkFormValidations(this.setupAssetsCreateForm);
    if (this.setupAssetsCreateForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else if (this.setupAssetsCreateForm.get('serialNumber')?.value == '')
      this.glblSvc.onTstr('i', 'Serial number cant be empty');
    else if (this.isDupTxt.includes('already exists'))
      this.glblSvc.onTstr('i', 'Entered Serial number already exists');
    else if (this.isDupTxt.includes('all zeros'))
      this.glblSvc.onTstr('i', 'Serial number does not contain all zeros');
    else if (this.slctdAsetId == 1 && this.onChkTBFrmt('n', this.setupAssetsCreateForm.get('serialNumber')?.value))
      this.glblSvc.onTstr('i', 'Serial number should contain only numeric values');
    else if (this.slctdAsetId != 1 && this.onChkTBFrmt('an', this.setupAssetsCreateForm.get('serialNumber')?.value))
      this.glblSvc.onTstr('i', 'Serial number should contain only alphanumeric values');
    else if (this.slctdAsetId == 1 && this.onChkTBFrmt('n', this.setupAssetsCreateForm.get('mobileNumber')?.value))
      this.glblSvc.onTstr('i', 'Mobile number should contain only numeric values');
    else if (this.slctdAsetId == 1 && this.onChkTBFrmt('n', this.setupAssetsCreateForm.get('simNumber')?.value))
      this.glblSvc.onTstr('i', 'Sim ID should contain only numeric values');
    else if (this.slctdAsetId == 1 && this.onChkTBFrmt('n', this.setupAssetsCreateForm.get('alternateMobile')?.value))
      this.glblSvc.onTstr('i', 'Alternate mobile number should contain only numeric values');
    else if (this.slctdAsetId == 1 && this.onChkTBFrmt('n', this.setupAssetsCreateForm.get('alternateSimNumber')?.value))
      this.glblSvc.onTstr('i', 'Alternate sim ID should contain only numeric values');
    else if (this.slctdAsetId == 1 && this.onChkMinMaxTB(this.setupAssetsCreateForm.get('mobileNumber')?.value, 10, 10))
      this.glblSvc.onTstr('i', 'Mobile number should contain 10 digits');
    else if (this.slctdAsetId == 1 && this.onChkMinMaxTB(this.setupAssetsCreateForm.get('simNumber')?.value, 5, 25))
      this.glblSvc.onTstr('i', 'Sim ID should contain min. 5 digits');
    else if (this.slctdAsetId == 1 && this.onChkMinMaxTB(this.setupAssetsCreateForm.get('alternateMobile')?.value, 10, 10))
      this.glblSvc.onTstr('i', 'Alternate mobile number should contain 10 digits');
    else if (this.slctdAsetId == 1 && this.onChkMinMaxTB(this.setupAssetsCreateForm.get('alternateSimNumber')?.value, 5, 25))
      this.glblSvc.onTstr('i', 'Alternate sim ID should contain min. 5 digits');
    else if (this.slctdAsetId == 1 && Boolean(this.show) && this.setupAssetsCreateForm.getRawValue().assetConfiguration?.timeZoneId == '')
      this.glblSvc.onTstr('i', 'Timezone is required');
    else {
    let b =  this.setupAssetsCreateForm?.controls?.assetConfiguration.get('phoneNumbersToSendSmsNotification')?.value == null || 
    this.setupAssetsCreateForm?.controls?.assetConfiguration.get('phoneNumbersToSendSmsNotification')?.value == '' ? "[]" : this.setupAssetsCreateForm?.controls?.assetConfiguration.get('phoneNumbersToSendSmsNotification')?.value

      this.setupAssetsCreateForm.patchValue({
        assetConfiguration: { phoneNumbersToSendSmsNotification: b }
      })
      let payLoad = this.setupAssetsCreateForm.getRawValue();
      payLoad.serialNumber = payLoad.serialNumber.trim(), payLoad.enablePushConfiguration = this.enableNotifcation,
        payLoad.updatedBy = this.usrLgnDtls.userId, //payLoad.controls.assetConfiguration.phoneNumbersToSendSmsNotification = payLoad.assetConfiguration.phoneNumbersToSendSmsNotification == null ? "[]": payLoad?.assetConfiguration?.phoneNumbersToSendSmsNotification,
        payLoad.assetModelId = payLoad.assetModelId != null ? +payLoad.assetModelId : null, payLoad.maintainers = this.maintainers,
        payLoad.owners = this.owners, payLoad.storeId = this.slctdFcltyId != null ? + this.slctdFcltyId : null, delete payLoad.manufacturer,
        payLoad.yearOfManufacture = (payLoad.yearOfManufacture != null && payLoad.yearOfManufacture != '') ? +payLoad.yearOfManufacture : null,
        payLoad.pushSmsConfig = this.pushSmsConfig, payLoad.sensorAMAC = (payLoad.sensorAMAC !='' || payLoad.sensorAMAC != null) ?  payLoad.sensorAMAC?.toUpperCase() : null,
        payLoad.sensorBMAC = (payLoad.sensorBMAC != '' || payLoad.sensorBMAC != null) ? payLoad.sensorBMAC?.toUpperCase(): null, payLoad.sensorCMAC = (payLoad.sensorCMAC != '' || payLoad.sensorCMAC != null) ? payLoad.sensorCMAC?.toUpperCase(): null, 
        payLoad.sensorDMAC = (payLoad.sensorDMAC !='' || payLoad.sensorDMAC !=null) ? payLoad.sensorDMAC?.toUpperCase(): null, payLoad.sensorEMAC = (payLoad.sensorEMAC!='' || payLoad.sensorEMAC !=null) ?  payLoad.sensorEMAC?.toUpperCase(): null;
      if (this.slctdAsetId != 1)
        payLoad.assetImeiNumber = "";
      if (!Boolean(this.show))
        payLoad.assetConfiguration = {};
      delete payLoad.asetTypeId;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.saveRupdate, payLoad, 2, true, 3).then(() => {
        let a = {};
        if (this.result) {
          if (this.result.status == 'OK' && this.result.message == 'Record saved successfully')
            this.glblSvc.onTstr('s', this.result.message), this.router.navigate(['home/setup-assets'], { queryParams: { tab: 'list' } }),
              localStorage.setItem('asstsCreatePL', JSON.stringify(payLoad)), setTimeout(() => { window.location.reload(); }, 1000);
          else if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
            this.glblSvc.onTstr('s', this.result.message), this.cancel(), this.cnclEdtInfo(), setTimeout(() => { window.location.reload() }, 200);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  cancel() {
    this.setupAssetsCreateForm.reset(), this.setupAssetsCreateForm.get('asetTypeId')?.setValue(''), this.stus = '';
    this.setupAssetsCreateForm.get('manufacturer')?.setValue(''), this.setupAssetsCreateForm.get('assetModelId')?.setValue(''),
      this.setupAssetsCreateForm.get('yearOfManufacture')?.setValue(''), this.setupAssetsCreateForm.get('buzz')?.setValue('false'),
      this.setupAssetsCreateForm.get('enablePushConfiguration')?.setValue('false'), this.showData = false, this.isSbmtd = false,
      this.slctdFcltyId = null, this.router.navigate(['home/setup-assets'], { queryParams: { tab: 'list' } });
  }

  cnclEdtInfo() {
    this.glblSvc.editAssetInfo.next('');
  }

  onChngSN() {
    let sVal = this.setupAssetsCreateForm.get('serialNumber')?.value;
    let snVal = sVal.replace(/\s/g, "")
    this.setupAssetsCreateForm.get('serialNumber')?.setValue(`${snVal}`.trim());
    if (this.slctdAsetId == 1) {
      const seperator = '^([0-9])';
      const maskSeperator = new RegExp(seperator, 'g');
      if (maskSeperator.test(snVal))
        this.setupAssetsCreateForm.get('serialNumber')?.setValue(`${snVal}`.trim());
      else
        this.setupAssetsCreateForm.get('serialNumber')?.setValue('');
    }
    this.setupAssetsCreateForm.get('sensorDMAC')?.setValue(`${snVal}`.trim());
    this.setupAssetsCreateForm.get('assetImeiNumber')?.setValue(`${snVal}`.trim());

    if (snVal.length == (snVal.split("0").length - 1))
      this.isDupTxt = 'Serial number does not contain all zeros', this.isDup = this.result.message.includes('all zeros');
    else
      this.isDupTxt = '', this.isDup = false;

    if (snVal) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.chkDupAsstSlNo + `${snVal}`.trim(), null, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status === 'OK') {
            if (this.result.message.includes('already exists'))
              this.isDup = this.result.message.includes('already exists'), this.isDupTxt = 'Serial number already exists';
          }
          else
            this.glblSvc.onTstr('e', this.result.message)
        }
      });
    }
  }

  //on Destroy component
  ngOnDestroy() {
    let a = {};
    this.glblSvc.editAssetInfo.next(a);
    localStorage.removeItem('editAssetInfo');
  }

  //Edit Asset 
  LoadAssetDetails(assetId: any, isReq: boolean = true) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.getAsetDetails.replace('assetId', assetId), null, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.message == 'Records fetched successfully') {
            let maintainerData = [];
            let ownersData = [];
            let a = this.result.data[0]?.assetConfiguration;
            this.slctdFcltyId = this.result.data[0]?.storeId;
            if (this.result.data[0]?.assetModelId == 459)
              this.snsrInfo = true, this.snsrdscnct = true, //this.setupAssetsCreateForm.get('sensorDMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
               // this.setupAssetsCreateForm.get('sensorDMAC')?.updateValueAndValidity(),
                 this.setupAssetsCreateForm.get('sensorBMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
                this.setupAssetsCreateForm.get('sensorBMAC')?.updateValueAndValidity();
            else
              this.snsrInfo = false, this.snsrdscnct = false,
                setTimeout(() => {
                  // this.setupAssetsCreateForm.get('sensorDMAC')?.clearValidators(),
                  //   this.setupAssetsCreateForm.get('sensorDMAC')?.updateValueAndValidity(),
                    this.setupAssetsCreateForm.get('sensorBMAC')?.clearValidators(),
                    this.setupAssetsCreateForm.get('sensorBMAC')?.updateValueAndValidity();
                }, 1000);

            if (this.result.data[0]?.userDetailsList != null) {
              for (let i = 0; i < this.result.data[0]?.userDetailsList.length; i++) {
                if (this.result.data[0]?.userDetailsList[i]?.user_role == "Maintainer") {
                  for (let j = 0; j < this.result.data[0]?.userDetailsList[i]?.user_list.length; j++) {
                    maintainerData.push({
                      userName: this.result.data[0]?.userDetailsList[i]?.user_list[j]?.name,
                      userData: this.result.data[0]?.userDetailsList[i]?.user_list[j]?.name + " - " + parseInt(this.result.data[0]?.userDetailsList[i]?.user_list[j]?.userId),
                      id: parseInt(this.result.data[0]?.userDetailsList[i]?.user_list[j]?.userId)
                    })
                  }
                } else if (this.result.data[0]?.userDetailsList[i]?.user_role == "Owner") {
                  for (let j = 0; j < this.result.data[0]?.userDetailsList[i]?.user_list.length; j++) {
                    ownersData.push({
                      id: parseInt(this.result.data[0]?.userDetailsList[i]?.user_list[j]?.userId),
                      userData: this.result.data[0]?.userDetailsList[i]?.user_list[j]?.name + " - " + parseInt(this.result.data[0]?.userDetailsList[i]?.user_list[j]?.userId),
                      userName: this.result.data[0]?.userDetailsList[i]?.user_list[j]?.name
                    })
                  }
                } else {
                  maintainerData = [];
                  ownersData = [];
                }
              }
              this.selectedOwners = [...ownersData], this.owners = [...ownersData];
              this.selectedMaintainers = maintainerData;
              this.maintainers = maintainerData;
            }
            this.setupAssetsCreateForm.patchValue({
              statusId: this.result.data[0]?.statusId,
              storeId: this.result.data[0]?.storeName,
              yearOfManufacture: this.result.data[0]?.yearOfManufacture,
              alternateMobile: this.result.data[0]?.alternateMobile,
              alternateSimNumber: this.result.data[0]?.alternateSimNumber,
              alternateSimVendor: this.result.data[0]?.alternateSimVendor,
              assetImeiNumber: this.result.data[0]?.assetImeiNumber,
              enablePushConfiguration: this.result.data[0]?.enablePushConfiguration != null ? this.result.data[0]?.enablePushConfiguration.toString() : '',
              firmwareVersion: this.result.data[0]?.firmwareVersion,
              gsmModuleVersion: this.result.data[0]?.gsmModuleVersion,
              isDeleted: this.result.data[0]?.isDeleted,
              mobileNumber: this.result.data[0]?.mobileNumber,
              serialNumber: this.result.data[0]?.serialNumber,
              setDefaultConfiguration: this.result.data[0]?.setDefaultConfiguration,
              simNumber: this.result.data[0]?.simNumber,
              simVendor: this.result.data[0]?.simVendor,
              sensorDMAC: this.result.data[0]?.sensorDMAC,
              sensorBMAC: this.result.data[0]?.sensorBMAC,
              sensorAMAC: this.result.data[0]?.sensorAMAC,
              sensorCMAC: this.result.data[0]?.sensorCMAC,
              sensorEMAC: this.result.data[0]?.sensorEMAC
            })

            let radioCheck = {
              target: {
                value: this.result.data[0]?.enablePushConfiguration
              }
            }
            this.isEditDFT = this.result.data[0]?.enablePushConfiguration, this.editFlag = true, this.updateForm(a);
            if (isReq)
              this.optChange(radioCheck);
            if (a != null) {
              if (this.slctdAsetId == 1 && a.assetSensorConfigurationList != null) {
                if (this.editFlag)
                  this.GetDefaultConfgs().controls.length = 0;
                a.assetSensorConfigurationList.forEach((data: any) => { this.GetDefaultConfgs().push(this.onAddFormCtrls(data)); }),
                  this.tab = this.GetDefaultConfgs()?.at(0).get('sensorId')?.value;
              }
            }
            this.setupAssetsCreateForm.get('alternateSimNumber')?.setValidators([Validators.min(5)]),
              this.setupAssetsCreateForm.get('alternateSimNumber')?.updateValueAndValidity(),
              this.setupAssetsCreateForm.get('alternateMobile')?.setValidators([Validators.min(10)]),
              this.setupAssetsCreateForm.get('alternateMobile')?.updateValueAndValidity(),
              this.setupAssetsCreateForm.get('simNumber')?.setValidators([Validators.min(5)]),
              this.setupAssetsCreateForm.get('simNumber')?.updateValueAndValidity(),
              this.setupAssetsCreateForm.get('mobileNumber')?.setValidators([Validators.min(10)]),
              this.setupAssetsCreateForm.get('mobileNumber')?.updateValueAndValidity();
            // this.setupAssetsCreateForm.get('sensorDMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
            //   this.setupAssetsCreateForm.get('sensorDMAC')?.updateValueAndValidity(),
              this.setupAssetsCreateForm.get('sensorBMAC')?.setValidators([Validators.required, Validators.minLength(10)]),
              this.setupAssetsCreateForm.get('sensorBMAC')?.updateValueAndValidity();
          }
        }
      }
    });
  }

  onChngFN(evnt: any, snsr: any) {
    let macName: string = evnt?.target?.value ?? '';
    if (macName != '') {
      let payLoad = { mac_address: macName };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.chckMac, payLoad, 3, true, 3).then(() => {
        if (this.result) {
          if (this.result.data != null) {
            this.dupMsgA = '', this.dupMsgB = '', this.dupMsgC = '', this.dupMsgD = '', this.dupMsgE = '';
            if (!this.result?.data) {
              if (snsr == 'A')
                this.dupMsgA = `${this.result?.message} - ${macName}`, this.setupAssetsCreateForm.get('sensorAMAC')?.setValue('');
              if (snsr == 'B')
                this.dupMsgB = `${this.result?.message} - ${macName}`, this.setupAssetsCreateForm.get('sensorBMAC')?.setValue('');
              if (snsr == 'C')
                this.dupMsgC = `${this.result?.message} - ${macName}`, this.setupAssetsCreateForm.get('sensorCMAC')?.setValue('');
              if (snsr == 'D')
                this.dupMsgD = `${this.result?.message} - ${macName}`, this.setupAssetsCreateForm.get('sensorDMAC')?.setValue('');
              if (snsr == 'E')
                this.dupMsgE = `${this.result?.message} - ${macName}`, this.setupAssetsCreateForm.get('sensorEMAC')?.setValue('');
            }
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      })
    }
  }

  asetConfg() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asetTypeConfg, environment.apiMthds.getAsetTyp.replace('id', '1'), null, 2, true, 3).then(() => {
      if (this.result) {

      }
    });
  }

}