import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-supply-materials',
  templateUrl: './supply-materials.component.html',
  styleUrls: ['./supply-materials.component.css']
})
export class SupplyMaterialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
