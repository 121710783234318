<form>
    <div class="card p-3">
        <div class="card-header p-0 pb-2">
            <h6 class="mb-0">Simulate entity data</h6>
        </div>
        <div class="card-body p-0">
            <div class="row ">
                <div class="col-xl-12">
                    <span class="small m-0">Please specify user's account information and role</span>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Entity <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Select entity" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Start date <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Start date" class="form-control form-control-sm" id="" disabled>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Duration <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Duration" value="90" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Stock on hand <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Stock on hand" value="0" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Periodicity <span class="text-danger">*</span></label>
                            <select id="inputState" class="form-select form-select-sm">
                                <option value="">Daily</option>
                                <option value="WY">Weekly</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Issues mean <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Issues mean" value="50" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Issues Std dev <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Issues Std dev" value="25" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Receipt Mean <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Receipt Mean" value="150" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Receipt Mean <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Receipt Mean" value="150" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Receipt Std dev <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Receipt Std dev" value="50" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Zero stock days min <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Zero stock days min" value="1" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Zero stock days max <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Zero stock days max" value="2" class="form-control form-control-sm" id="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary">Request simulation</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>