import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-coverage-report',
  templateUrl: './coverage-report.component.html',
  styleUrls: ['./coverage-report.component.css']
})
export class CoverageReportComponent extends BaseComponent implements OnInit {

  usrLvl = ''; pageEntrs = ''; crntPage = 1; matName: any; ttlItms: any;
  lstGrid: any[] = []; lstMats: any[] = []; lstMatTags: any[] = []; stsLst: any[] = []; dstLst: any[] = []; blkLst: any[] = []; isExprt = false;
  lstFacTags: any[] = []; dt2day = new Date(); frmDate = this.wkBkDt; isPgLds = true; isorderBy = false;
  isMatBdge = 'badge'; cnsmptnForm: FormGroup = this.fb.group({}); lstSlctdMetric: any = []; slctdMetric: any[] = []; lstShowMetric: any[] = [];
  pageSize = environment.tblPageSize; noRcrdsTxt = environment.TblNoRcrds; slctdMatName: any[] = []; isCcp = false;
  metricList: any[] = []; metLst: any[] = []; lstAllMats: any[] = []; slctdPrdId: any[] = []; lstSlctdMatName: any = []; slctdFac: any[] = [];
  lstSlctdFacTags: any[] = []; lstShowFacTags: any[] = []; lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; slctdMat: any[] = [];
  lstCcpAll: any[] = []; isSbmtd = false; isPgLd = false; ddlStngs = { singleSelection: false, idField: 'storeid', textField: 'storename', 
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true}; slctdccp: any[] = []; lstSlctdStores: any[] = [];
  lstStrs: any[] = []; dd2Stngs = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', 
  itemsShowLimit: 3, allowSearchFilter: true }; storeT: any[] = []; storeTagName = ''; strHdrName = ''; ordBy: boolean = false; dropdownSettings = {
    singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
     allowSearchFilter: true }; isCCpEnble : boolean = false;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility)}), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.lstAllMats = s.allMats; });
  }

  ngOnInit(): void {
    this.glblSvc.sbjctMstrData.subscribe(s => { (this.metricList = s.txnTypeLst) }), this.metLst = this.metricList.filter(f => f.id == 1); 
    this.cnsmptnForm = this.fb.group({ state: [null], district: [null], blockId: [null], productIds: [[], Validators.required], 
    productTags: [[], Validators.required], isMatBdge: ['badge'], fromDate: [null], toDate: [this.dt2day], storeTags: [[], 
    Validators.required], txnTypeId: [this.metLst[0].id], storeIds: [], isDistrict: [] });
    this.glblSvc.invTable.subscribe(s => this.lstGrid = s), this.lstGrid = [], this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst }),
    this.onClkRdb('productTags');
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.cnsmptnForm.get('country')?.setValue(cntryId); this.isCcp = (this.usrLvl == 'State' || this.usrLvl == 'District');
    }
    this.dmnLocation();
    setTimeout(() => { $("#ccpContainer").addClass("disabledbutton"), this.LoadCCPs(), this.onAS(), this.isPgLds = false; }, 1000);
  }

  get fc() { return this.cnsmptnForm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.cnsmptnForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.cnsmptnForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.cnsmptnForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.cnsmptnForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.cnsmptnForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.cnsmptnForm.get('blockId')?.setValue(blockID ?? null),
              setTimeout(() => { this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.cnsmptnForm.get('state')?.setValue('null'), this.cnsmptnForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onClkRdb(ctrl: string) {
    this.fc?.productTags.setValue([]), this.fc?.productIds.setValue([]); this.lstSlctdMatName = []; this.lstSlctdMatTags = [];
    if (ctrl == 'productTags')
      this.fc?.productIds?.clearValidators(), this.fc?.productTags?.setValidators([Validators.required]), this.lstSlctdMatName = [];
    else
      this.fc?.productTags?.clearValidators(), this.fc?.productIds?.setValidators([Validators.required]);
    this.fc?.productTags?.updateValueAndValidity(), this.fc?.productIds?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  clearBSDate(ctrl: string) {
    this.cnsmptnForm.get(ctrl)?.setValue('');
  }

  onLocChng1(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.cnsmptnForm.get('district')?.setValue(null), this.cnsmptnForm.get('blockId')?.setValue(null);
      else if(type == 'district')
        did = slctdId, this.blkLst = [], this.cnsmptnForm.get('blockId')?.setValue(null);

        this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.cnsmptnForm.get('district')?.enable(),
              this.cnsmptnForm.get('blockId')?.disable();
            else if(type == 'district')
              this.blkLst = this.result.data != null ? this.result.data : [], this.cnsmptnForm.get('blockId')?.enable();
              else
              this.loadCcpAll();
          }
          if (type == 'district')
            this.slctdccp = [...[]], this.lstStrs = [...[]], this.loadCcpAll();
        }
      });
    }
    else {
      if (type == 'state')
        this.cnsmptnForm.get('district')?.setValue('null'), this.cnsmptnForm.get('district')?.disable();
      else
      if (type == 'district')
        this.slctdccp = [...[]], this.lstStrs = [...[]], this.loadCcpAll();
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    type == 'state' ? this.cnsmptnForm.get('isDistrict')?.setValue(false) : '';
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.cnsmptnForm.get('district')?.setValue(null),
          this.cnsmptnForm.get('blockId')?.setValue('null');
      else
        did = slctdId, this.blkLst = [],this.cnsmptnForm.get('blockId')?.setValue('null');
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.cnsmptnForm.get('district')?.enable(),
                this.cnsmptnForm.get('blockId')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.cnsmptnForm.get('blockId')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.cnsmptnForm.get('district')?.setValue('null'), this.cnsmptnForm.get('blockId')?.setValue('null'),
          this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('blockId')?.disable();
      else
        this.cnsmptnForm.get('blockId')?.setValue('null'), this.cnsmptnForm.get('blockId')?.disable();
    }
    // if (!this.isPgLd)
      this.LoadCCPs();
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.cnsmptnForm.get('state')?.enable(), this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.cnsmptnForm.get('state')?.disable(), this.cnsmptnForm.get('district')?.enable(), this.cnsmptnForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.cnsmptnForm.get('state')?.disable(), this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.cnsmptnForm.get('state')?.disable(), this.cnsmptnForm.get('district')?.disable(), this.cnsmptnForm.get('blockId')?.disable();
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadCnsmptnReport();
  }

  LoadCnsmptnReport() {
    let fT: any[] = [], mT: any[] = [], mN: any[] = [], sI: any[] = [], crntPgIndx = this.crntPage - 1, payLoad = this.cnsmptnForm.getRawValue();
    if ((this.fc.productIds.value != '' || this.fc.productTags.value != '') && this.fc.storeTags.value != '') {
      payLoad.txnTypeId = payLoad.txnTypeId != '' && payLoad.txnTypeId != null ? +payLoad.txnTypeId : 0, payLoad.storeIds = payLoad.storeIds?.length != 0 &&
      payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sI.push(e.storeid) }) : [], payLoad.storeIds = sI;
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.storeTags = payLoad.storeTags?.length != 0 && payLoad.storeTags != null ?
        this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.productTags = payLoad.productTags.length != 0 &&
      payLoad.productTags != null ? this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT;
      payLoad.productIds = payLoad.productIds.length != 0 && payLoad.productIds != null ? this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [],
      payLoad.productIds = mN; payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate,
        this.dtF.ymd) + this.tmF.zeroTM : '', payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district, payLoad.blockId = payLoad.blockId == 'null' ? 0 : +payLoad.blockId,
      payLoad.userName = this.usrLgnDtls.userName, payLoad.email = this.usrLgnDtls.email, payLoad.isDistrict = payLoad.isDistrict != '' && 
      payLoad.isDistrict != null ? payLoad.isDistrict : false; delete payLoad.state, delete payLoad.district, delete payLoad.isMatBdge
      if (this.isPgLd)
        payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
      else
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.rprtsCnsmptn.replace('pageNo',
        `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 4).then(() => { this.isPgLd = false, this.strHdrName = <string>localStorage.getItem('slctdStoreCR');
          if (this.result) {
            if (this.result.status == 'OK') {
              this.cnsmptnForm.getRawValue()?.isDistrict == 'true'? this.isorderBy = true:this.isorderBy = false;
              let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
              if (data) {
                this.ttlItms = this.result.data?.count ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                  this.ttlItms), Object.keys(data.consumptionResponseModel).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0], adrs = `${s}`.split('||')[1], ccp = this.usrLvl == 'National' || this.usrLvl == 'State' || this.usrLvl == 'District' ? `${s}`.split('||')[2] : '',
                    storeBadge = `${s}`.split('||')[3];
                    if (i == 0)
                      data.consumptionResponseModel[s].forEach((m: any) => { hdrs.push(m.productName); });
                    result.push({ kId: null, kName: sName, kAdrs: adrs, ccp: ccp,storeBadge: storeBadge, matData: data.consumptionResponseModel[s], hdrs: hdrs, total: ttl });
                  });
              }
              else if (data == null)
                this.glblSvc.onTstr('i', this.result.message);
              else
                this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid), this.glblSvc.sbjctTblDataCnt.next(data?.count ?? 0);

              if (result.length == 0)
                this.glblSvc.onTstr('i', 'No data found');
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    }
    else
        this.glblSvc.onTstr('w', 'Please choose Mandatory fields');
  }

  onReset() {
    this.storeTagName = '', this.isSbmtd = false, this.lstSlctdMatName = [], this.lstSlctdMatTags = [], this.lstSlctdFacTags = [], this.ordBy = false, 
      this.cnsmptnForm.patchValue({ productIds: null }), this.cnsmptnForm.get('txnTypeId')?.setValue(this.metLst[0].id), this.onClkRdb('productTags'),
      this.cnsmptnForm.get('isMatBdge')?.setValue('badge'), this.cnsmptnForm.get('storeTags')?.setValue([]), this.frmDate, this.LoadCCPs(),
      this.cnsmptnForm.get('fromDate')?.setValue(this.wkBkDt), this.cnsmptnForm.get('toDate')?.setValue(this.dt2day),
      this.cnsmptnForm.get('isDistrict')?.setValue(false), $("#ccpContainer").addClass("disabledbutton"),
      this.lstSlctdStores = [], this.lstStrs = [], this.slctdccp = [], this.lstCcpAll = [...[]],
      this.cnsmptnForm.get('storeIds')?.setValue([]), this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);

  }

  onEE() {
    let payLoad = this.cnsmptnForm.getRawValue(); let fT: any[] = [], mT: any[] = [], mN: any[] = [], sI: any[] = [];
    payLoad.txnTypeId = payLoad.txnTypeId != '' && payLoad.txnTypeId != null ? +payLoad.txnTypeId : 0, payLoad.storeIds = payLoad.storeIds.length != 0 &&
      payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sI.push(e.storeid) }) : [], payLoad.storeIds = sI;
    payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.storeTags = payLoad.storeTags.length != 0 && payLoad.storeTags != null ?
      this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.productTags = payLoad.productTags.length != 0 &&
        payLoad.productTags != null ? this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT;
    payLoad.productIds = payLoad.productIds.length != 0 && payLoad.productIds != null ? this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [],
      payLoad.productIds = mN; payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate,
        this.dtF.ymd) + this.tmF.zeroTM : '', payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.userName = this.usrLgnDtls.userName, payLoad.email = this.usrLgnDtls.email, payLoad.blockId = payLoad.blockId == 'null' ? 0 : +payLoad.blockId,
        delete payLoad.state, delete payLoad.district,  delete payLoad.isMatBdge
    if (this.isPgLd)
      payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.rprtsCnsmptnExprt +
        this.usrLgnDtls.email, payLoad, 4).then(() => { this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (ctrl == 'c')
      this.lstGrid = [];
    if (type == 's') {
      if (ctrl == 'n') {
        if (this.lstSlctdMatName.filter((f: any) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
      }
      else if (ctrl == 'm') {
        (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
      } else if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
        let obj = this.lstSlctdFacTags.find((f: { id: any; }) => f.id == 53);
        obj?.id == 53 ? this.isCCpEnble = true : this.isCCpEnble = false;
        if (this.isCCpEnble) {
          $("#ccpContainer").removeClass("disabledbutton");
        }
      } else
        if (this.lstSlctdStores.filter(f => f.storeid === item.storeid).length == 0)
          this.lstSlctdStores.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'n')
        this.lstSlctdMatName = this.lstSlctdMatName.filter((f: any) => f.id != item.id);
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
      else if (ctrl == 'f') {
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
        if(item?.id==53){
          this.lstSlctdStores=[...[]], this.cnsmptnForm.get('storeIds')?.setValue([]),
          this.isCCpEnble = false, $("#ccpContainer").addClass("disabledbutton");
        }
      }
      else
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeid != item.storeid);
    }
    else if (type == 'a') {
      if (ctrl == 'n')
        this.lstSlctdMatName = this.lstAllMats;
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstMatTags;
      else if (ctrl == 'f') {
        this.lstSlctdFacTags = this.lstFacTags, this.isCCpEnble = true;
        if (this.isCCpEnble) {
          $("#ccpContainer").removeClass("disabledbutton");
        }
      }
      else
        this.lstSlctdStores = this.lstCcpAll;
    }
    else {
      if (ctrl == 'n')
        this.lstSlctdMatName = [];
      else if (ctrl == 'm')
        this.lstSlctdMatTags = [];
      else if (ctrl == 'f') {
        this.lstSlctdFacTags = [];
          this.lstSlctdStores=[...[]], this.cnsmptnForm.get('storeIds')?.setValue([]),
          this.isCCpEnble = false, $("#ccpContainer").addClass("disabledbutton");
      }
      else
        this.lstSlctdStores = [];
    }
  }

  loadCcpAll() {
    if (this.isCcp) {
      let frmCtrls = this.cnsmptnForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state,
        districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district };
      this.lstSlctdStores = [...[]], this.lstCcpAll = [...[]], this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp,
          environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.lstCcpAll = this.result.data != null ? this.result.data : [];
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    } 
  }

  LoadCCPs() {
    if (this.usrLvl !='National') {
      let frmCtrls = this.cnsmptnForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, 
      districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district, blockId: frmCtrls.blockId == 'null' ? 0 : +frmCtrls.blockId };
      this.slctdccp = [], this.lstCcpAll = [...[]], this.lstSlctdStores = [], this.FetchData(environment.apiCalls.post,
          environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstCcpAll = this.result.data != null ? this.result.data : [];
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
  }

}
