import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-materials-view',
  templateUrl: './materials-view.component.html',
  styleUrls: ['./materials-view.component.css']
})

export class MaterialsViewComponent extends BaseComponent implements OnInit {

  lstAllMats: any[] = []; lstMatTags: any[] = []; slctdIncldFac: any[] = []; noRcrdsTxt = environment.TblNoRcrds;
  setupMatsViewForm = this.formBuilder.group({ BadgeName: [''], productName: [''] }); pageEntrs = ''; crntPage = 1;
  pageSize = environment.tblPageSize; totalItems: any;
  usrLvlDtls: any = null; isUserLvl: any = null; isBetaURL = environment.isBetaURL;
  prmsns: any = null; mtrlDtl : boolean = true; mtrlEdt: boolean = true; mtrlDlt: boolean = true; mtrlLst: boolean = true;


  constructor(public glblSvc: GlobalService
    , private router: Router
    , private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); });
  }

  ngOnInit(): void {
    if (this.usrLgnDtls) {
      this.usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.isUserLvl = this.usrLvlDtls?.userLevel;
    }
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
      if(e?.menuCode =='mm_st_ma_li')
     this.mtrlLst = e?.action;
    if(e?.menuCode =='mm_st_ma_md')
     this.mtrlDtl = e?.action;
     if(e?.menuCode =='mm_st_ma_ed')
     this.mtrlEdt = e?.action;
     if(e?.menuCode =='mm_st_ma_dt')
     this.mtrlDlt = e?.action;
  });
  if(this.mtrlLst)
  this.loadMats();
  }

  onMatEdit(id: any) {
      this.glblSvc.sbjctUsrId.next(id), this.router.navigate(['home/setup-matrls-edit']);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.loadMats();
  }

  loadMats() {
    let crntPgIndx = this.crntPage - 1, productName = this.setupMatsViewForm.get('productName')?.value != null ? this.setupMatsViewForm.get('productName')?.value :
      '', badgeName = this.setupMatsViewForm.get('BadgeName')?.value != null ? this.setupMatsViewForm.get('BadgeName')?.value : '';
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getMatsByFilter.
      replace('ppp', `${this.chsnDmnId}`).replace('prdName', productName).
      replace('bdgeName', badgeName).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), null, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAllMats = this.result.data?.productFilterModel != null ? this.result.data?.productFilterModel : [],
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  onReset() {
    this.setupMatsViewForm.get('BadgeName')?.setValue(''), this.setupMatsViewForm.get('productName')?.setValue(''), this.loadMats();
  }

  onMatDetails(id: any) {
      this.glblSvc.sbjctUsrId.next(id), this.router.navigate(['home/setup-matrls-details']);
  }

  onMatDelete(id: any) {
      if (confirm("Are you sure want to delete  ?")) {
        this.FetchData(environment.apiCalls.del, environment.apiPaths.product, environment.apiMthds.delByMatId.replace('mmm', id).replace('null', '0'),
          null, 7).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                if (this.result.message === "Records deleted")
                  this.glblSvc.onTstr('s', this.result.message), this.loadMats();
                else
                  this.glblSvc.onTstr('w', this.result.message);

              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
      }
  }

  onEE() {
    let productName = this.setupMatsViewForm.get('productName')?.value != null ? this.setupMatsViewForm.get('productName')?.value :
      '', badgeName = this.setupMatsViewForm.get('BadgeName')?.value != null ? this.setupMatsViewForm.get('BadgeName')?.value : '';
    this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.expProducts.replace('ppp', `${this.chsnDmnId}`).
        replace('prdName', productName).replace('bdgeName', badgeName), null, 11).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        }
      }
    });
  }

}