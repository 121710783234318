import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-ts-locations',
  templateUrl: './ts-locations.component.html',
  styleUrls: ['./ts-locations.component.css']
})

export class TsLocationsComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
  }

}
