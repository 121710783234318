import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
declare let $: any;
declare let LoadSetupDomains: any;

@Component({
  selector: 'evin-domain-details',
  templateUrl: './domain-details.component.html',
  styleUrls: ['./domain-details.component.css']
})

export class DomainDetailsComponent extends BaseComponent implements OnInit {
  chsnDmnId: any = ''; dmnDtls: any; isShwEdt = false; btnFlag = false; isUpdateBtn = false; isEdtPrmsnBtn = true; allPrmsnBoxes: any[] = [];
  chldDmns: any = ''; allChldPrmsnBox: any[] = []; lstDmns: any[] = []; lstMappedPrnths: any[] = []; msdStngs = { idField: 'pranthid', 
  textField: 'pranthname', itemsShowLimit: 3, allowSearchFilter: true }; slctdChldPrnths: any[] = []; mtrlsFprnt = false; cnfgFprnt = false; prntFprnt = false;
  isLclDev = environment.isLclDev; dmnNametoEdt: any = ''; unMappedPrnthsLst: any[] = []; noRcrdTxt = environment.TblNoRcrds; slctChckd = false; 
  slctdDmnNme = ''; dmnFrm = this.fb.group({ mppdPrnth: [''] });

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctslctdDmntPrnth.subscribe(e => { this.chsnDmnId = e.pranthId, this.slctdDmnNme = e.pranthName });
  }

  ngOnInit(): void {
    this.select2ShowHide();
    this.LoadSlctdDmnDtls(), this.LoadChldHierarchy();
  }

  LoadSlctdDmnDtls() {
    let allBoxex: any[] = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.exprt, environment.apiMthds.getPrnthDesc.replace('slctdDmnID',
      `${this.chsnDmnId}`), null, 8).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.dmnDtls = this.result.data, allBoxex = this.dmnDtls.permissions;
              for (let i = 0; i < allBoxex.length; i++) {
                for (let j = 0; j < allBoxex[i].permissionPranth.length; j++) {
                  for (let k = 0; k < allBoxex[i].permissionPranth[j].permissionCodes.length; k++) {
                    this.allPrmsnBoxes.push({ domainPermissionId: allBoxex[i].permissionPranth[j].permissionCodes[k].id, isChecked: allBoxex[i].permissionPranth[j].permissionCodes[k].isChecked })
                  }
                }
              }
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  LoadChldDmnDtls() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.exprt, environment.apiMthds.loadChldDmns, null, 8).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.chldDmns = this.result.data;
            for (let i = 0; i < this.chldDmns.length; i++) {
              for (let j = 0; j < this.chldDmns[i].permissionPranth.length; j++) {
                for (let k = 0; k < this.chldDmns[i].permissionPranth[j].permissionCodes.length; k++) {
                  this.allChldPrmsnBox.push({ domainPermissionId: this.chldDmns[i].permissionPranth[j].permissionCodes[k].id, isChecked: this.chldDmns[i].permissionPranth[j].permissionCodes[k].isChecked })
                }
              }
            }
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadChldHierarchy() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getHrchryDtls + `${this.chsnDmnId}`, null)
      .then(() => {
        let res = this.result;
        if (this.result) {
          this.lstDmns = this.result?.data[0]?.Pranth ?? [];
          if (this.lstDmns.length != 0)
            this.LoadChldDmnDtls(), this.LoadMappedPranths({pranthId: this.chsnDmnId, pranthName: this.slctdDmnNme}), LoadSetupDomains();
        }
        else
          this.glblSvc.onTstr('e', res.message)
      });
  }

  LoadMappedPranths(prnth: any) {
    this.dmnFrm.reset();
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getChldPrnth + prnth.pranthId, null, 7).then(() => {
        let res = this.result;
        if (this.result)
          this.lstMappedPrnths = this.result?.data ?? [], this.slctdDmnNme = prnth.pranthName;
        else
          this.glblSvc.onTstr('e', res.message);
      });
  }

  onShwEdt() {
    this.isShwEdt = true, this.dmnNametoEdt = this.dmnDtls?.pranthName;
  }

  onCnclNme() {
    this.isShwEdt = false;
  }

  editPrmns() {
    this.btnFlag = true, this.isEdtPrmsnBtn = false, this.isUpdateBtn = true;
  }

  onChngChld(type: any, evnt: any) {
    if (type == 'mtrls')
      this.mtrlsFprnt = evnt.target.checked;
    else if (type == 'prnt')
      this.prntFprnt = evnt.target.checked;
    else
      this.cnfgFprnt = evnt.target.checked;
  }

  onChng(item: any, evntType: any) {
    if (evntType == 'os')
      this.slctdChldPrnths.push(item.pranthid)
    else if (evntType == 'osa')
      this.slctdChldPrnths = [], this.lstMappedPrnths.filter(e => this.slctdChldPrnths.push(e.pranthid))
    else if (evntType == 'ds')
      this.slctdChldPrnths = this.slctdChldPrnths.filter(s => s != item.pranthid)
    else
      this.slctdChldPrnths = [];
  }

  onChngePrmsn(type: any, evnt: any, id: any) {
    if (type == "main")
      this.allPrmsnBoxes.filter(e => { if (e.domainPermissionId == id) { e.isChecked = evnt.target.checked } });
    else
      this.allChldPrmsnBox.filter(e => { if (e.domainPermissionId == id) { e.isChecked = evnt.target.checked } })
  }

  onCncl() {
    this.btnFlag = false, this.isEdtPrmsnBtn = true, this.isUpdateBtn = false;
  }

  onUpdate(type: any) {
    this.btnFlag = false, this.isEdtPrmsnBtn = true, this.isUpdateBtn = false;
    let payLoad: any = {};
    if (type == 'main')
      payLoad.copyConfig = false, payLoad.copyMaterial = false, payLoad.pranthId = [this.dmnDtls.pranthId], payLoad.updatePermission = this.allPrmsnBoxes, payLoad.copyToChilds = false;
    else if (type == 'subChld')
      payLoad.copyConfig = this.cnfgFprnt, payLoad.copyMaterial = this.mtrlsFprnt, payLoad.pranthId = this.slctdChldPrnths, 
      payLoad.updatePermission = this.allChldPrmsnBox, payLoad.copyToChilds = true, payLoad.parentPranthId = this.chsnDmnId;
    else 
      payLoad.copyConfig = this.cnfgFprnt, payLoad.copyMaterial = this.mtrlsFprnt, payLoad.pranthId = this.slctdChldPrnths, 
      payLoad.updatePermission = this.allChldPrmsnBox, payLoad.copyToChilds = false, payLoad.parentPranthId = this.chsnDmnId;

    if (payLoad.pranthId.length != 0) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.updatePrmsn, payLoad, 8).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.glblSvc.onTstr('s', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
    else
      this.glblSvc.onTstr('w', 'Kindly select the domain(s)');
  }


  updtePrnthNme() {
    let chngdDmnNme = $("#dmnName").val();
    if (chngdDmnNme != '') {
      let payLoad: any = {}; payLoad.id = this.chsnDmnId, payLoad.name = chngdDmnNme;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.pranth, environment.apiMthds.updatePrnth, payLoad, 7)
        .then(() => {
          let res = this.result;
          if (this.result)
            this.isShwEdt = false, this.LoadSlctdDmnDtls(), this.glblSvc.onTstr('s', res.message);
          else
            this.glblSvc.onTstr('e', res.message)
        });
    }
  }

  getUnmpdPrnths() {
    this.unMappedPrnthsLst = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getUnmapedPrnths + `${this.chsnDmnId}`, null, 7).then(() => {  
      let resMsg = this.result;    
      if (this.result) {
        this.result.data.forEach((e: any) => { Object.assign(e, { isChcked: false}); });
        this.unMappedPrnthsLst = this.result?.data ?? [];
      }      
      else
        this.glblSvc.onTstr('e', resMsg.message)
    });
  }

  slctPrnth(prnthId: any, evnt: any) {
    this.unMappedPrnthsLst.filter(e => {if (+prnthId === +e.pranthId) { e.isChcked = evnt.target.checked } });
  }

  // slctAll(evnt: any) {
  //   this.unMappedPrnthsLst.filter(e => {e.isChcked = evnt.target.checked});
  // }

  onLnkPrnths() {
    let slctdPrnths: any[] = [], payLoad: any = {};
    this.unMappedPrnthsLst.forEach(e => {
      if (e.isChcked == true) {
        slctdPrnths.push({mappedPranthId: e.pranthId, mappingType: e.pranthLevel }) 
       }
    });
    if (slctdPrnths.length != 0) {
      payLoad.mappedPranths = slctdPrnths, payLoad.createdBy = this.usrLgnDtls.userId, payLoad.pranthId = this.chsnDmnId;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.prnthHierrchy, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {  
        let resMsg = this.result;    
        if (this.result) {
          this.glblSvc.onTstr('s', resMsg.message), window.location.reload();
        }      
        else
          this.glblSvc.onTstr('e', resMsg.message)
      });

    } else {
      this.glblSvc.onTstr('w', 'Kindly select domain to link')
    }
  }
}