import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-order-response-time',
  templateUrl: './order-response-time.component.html',
  styleUrls: ['./order-response-time.component.css']
})

export class OrderResponseTimeComponent extends BaseComponent implements OnInit {

  isFacBdge = 'badge'; lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllMats: any[] = []; lstFcltys: any[] = []; lstStrs: any[] = [];
  isSbmtd = false; lstSlctdFacTags: any[] = []; lstSlctdStores: any[] = []; lstFacIds: any[]= []; slctdFac: any[] = []; slctdFacId: any[] = [];
  pstT = 'Monthly'; isLocOptn = 'state'; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; lstTxnTyps: any[] = []; drpVal = '2';
  shwStDrpdwn = false; slctdStateId: any; slctdDistrictId: any; slctdBlockId: any; lstMAT: any[] = []; lstFAC: any[] = []; isDtCng:string= '';
  dpSDVal: any = new Date(); dpEDVal: any = new Date(); pLSD = '';  crntPage = 1; pageEntrs = ''; pageSize = environment.tblPageSize;
  plED = ''; isMnthChng = false; isDayChng = false; txtFD = ''; txtED = ''; pstType = 'monthly'; tab = 'o'; maxToDate: Date = new Date(); 
  crntDate: Date = new Date(); fD2 = ''; eD2 = ''; lstDataDDL: any[] = []; lstX: any[] = []; lstY: any[] = []; dt2days = new Date();
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', 
  itemsShowLimit: 3, allowSearchFilter: true }; ddlStngs = { singleSelection: false, idField: 'storeId', textField: 'storeName',
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; noRcrdTxt = environment.TblNoRcrds;
  respnseTimeForm: FormGroup = this.fb.group({}); usrLvl = ''; txnType: any; frmDate: any = ''; tooDate: any = ''; lstGrid: any[] = [];
  frmDates: any = ''; tooDates: any = ''; slctTxnType: any =''; slctTxnTypes: any =''; lstOrdTyps: any[] = []; lstTags: any[] = [];
  orderStatusList: any[] = []; ordLst: any[] = []; ttlCount = 0; lstFacsTag: any; lstFacsName: any;  totalItems: any;
  yBDate : any; mBDate : any; monSplit: any; lstDX : any[] = []; lstDY : any[] = []; lstGridDate : any[] =[]; mtrcVal: any = 1;
  metricType: any;  rsnsLst: any[] = []; initFacTag: any[] = []; initMatTag: any[] = []; initFacName: any[] = []; initMatName: any[] = []; 
   slctrsnTYpe: any ='';  dscrpcyType:any = ''; slctResnType: any; yrMin : any; ttlItms: any; isShow: boolean = false; expDrpnDly: any = '1';
   lblTextOfDate:any = 'Max 12 months will be accepted'; ttlDayItms: any; crntDPage = 1; pageEntrsD = ''; expDrpn: any = '1';
   isRprtsHlpShw = environment.isRprtsHlpShw; steRes: string = ''; dstRes: string = ''; steResDly: string = ''; dstResDly: string = '';
   isWekChng = false; ttlCnt: any[] = []; ttlCntDly: any[] = [];showExportOption:boolean=true;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc); this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product,
      this.lstTags = s?.filter(f => f.badgeTypeId == environment.tagTypes.order)), this.lstFacTags = s.filter(f => f.badgeTypeId === 
        environment.tagTypes.facility)}), this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }),
        this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst,
        this.lstTxnTyps = s.txnTypeLst, this.dstLst = s.dstLst, this.lstAllMats = s.allMats, this.lstOrdTyps = s.ordTypeLst;
        this.lstOrdTyps = s.ordTypeLst;
        const releases =  {
          "id": 3,
          "name": "Releases",
          "description": "Releases",
          "updatedOn": null,
          "createdOn": null,
          "updatedBy": null,
          "createdBy": null,
          "isDeleted": false
        };
        this.lstOrdTyps.push(releases)
        this.rsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.new_indent) });
  }

  ngOnInit(): void {
    this.respnseTimeForm = this.fb.group({ isFacBdge: ['badge'], storeTags: [[], Validators.required], storeIds: [[], Validators.required],
    isLocOptn: ['state'], pstT: ['Monthly'], block: ['null'], district: [null], state: [null], dpSDVal: ['', Validators.required],
    dpEDVal: ['', Validators.required], fromDate: [''], toDate: [''], metricsId: ['2'], orderTypeId: [''], orderTag: ['']});
    localStorage.setItem('isPgLd', '0'); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
        if (this.usrLgnDtls) {
          let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
            usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
          this.usrLvl = usrLvlDtls?.userLevel ?? '', this.respnseTimeForm.get('country')?.setValue(cntryId);
        }
        this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
    // this.lstMatTags.forEach(e => {this.initMatTag.push(e.id)}); this.lstAllMats.forEach(e => {this.initMatName.push(e.id)});
    // this.lstFacTags.forEach(e => {this.initFacTag.push(e.id)}); this.lstFacIds.forEach(e => {this.initFacName.push(e.storeid)});
  }

  get fc(){ return this.respnseTimeForm.controls };
  get fgrv() { return this.respnseTimeForm.getRawValue(); };

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.respnseTimeForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.respnseTimeForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.respnseTimeForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.respnseTimeForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.respnseTimeForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.respnseTimeForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.respnseTimeForm.get('state')?.setValue('null'), this.respnseTimeForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.respnseTimeForm.get('fromDate')?.setValue(''), this.respnseTimeForm.get('toDate')?.setValue(''), this.respnseTimeForm.get('dpSDVal')?.setValue(''),
          this.respnseTimeForm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.respnseTimeForm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.respnseTimeForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.respnseTimeForm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.respnseTimeForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.respnseTimeForm.get('fromDate')?.setValue(''), this.respnseTimeForm.get('toDate')?.setValue(''), this.respnseTimeForm.get('dpSDVal')?.setValue(''),
          this.respnseTimeForm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.respnseTimeForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.respnseTimeForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.respnseTimeForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.respnseTimeForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.respnseTimeForm.get('fromDate')?.setValue(''), this.respnseTimeForm.get('toDate')?.setValue(''), this.respnseTimeForm.get('dpSDVal')?.setValue(''),
          this.respnseTimeForm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.respnseTimeForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.respnseTimeForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.respnseTimeForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.respnseTimeForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onTabClk(tabType: string) {
    this.tab = tabType, this.lstGrid = [], this.crntPage=1, this.crntDPage = 1, this.GenRprt(); this.isSbmtd = true;
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter((a: { name: any; }) => a.name == evnt.target.value).length > 0;
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if(slctdId=="null"){
      this.showExportOption= true
    }
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.showExportOption= true, this.dstLst = [], this.blkLst = [], this.respnseTimeForm.get('district')?.setValue(null),
          this.respnseTimeForm.get('block')?.setValue(null);
      else
        did = slctdId, this.showExportOption= slctdId=="null"?true:false, this.blkLst = [],this.respnseTimeForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.respnseTimeForm.get('district')?.enable(),
                this.respnseTimeForm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.respnseTimeForm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.respnseTimeForm.get('district')?.setValue('null'), this.respnseTimeForm.get('block')?.setValue('null'),
          this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('block')?.disable();
      else
        this.respnseTimeForm.get('block')?.setValue('null'), this.respnseTimeForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.respnseTimeForm.get('state')?.enable(), this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.respnseTimeForm.get('state')?.disable(), this.respnseTimeForm.get('district')?.enable(), this.respnseTimeForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.respnseTimeForm.get('state')?.disable(), this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.respnseTimeForm.get('state')?.disable(), this.respnseTimeForm.get('district')?.disable(), this.respnseTimeForm.get('block')?.disable();
  }

  onChngPstT() {
    this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' : (this.pstT=='Daily' ?
     'Max 31 days will be accepted':'Max 42 days will be accepted');
    this.respnseTimeForm.get('dpSDVal')?.setValue(''); this.pstType = this.pstT.toLowerCase(), this.isMnthChng = this.pstT == 'Monthly', 
    this.isWekChng = this.pstT == 'Weekly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='', this.dpEDVal='';
  }

  GenRprt(type: string = 'g') {
    this.slctResnType = (this.respnseTimeForm.controls.metricsId.value == 1 ? 'Average lead time (days)' :
      (this.respnseTimeForm.controls.metricsId.value == 2 ? 'Average processing time (days)' :
        (this.respnseTimeForm.controls.metricsId.value == 3 ? 'Average delivery lead time (days)' : '')));
    let formCtrls = this.respnseTimeForm.getRawValue(); this.isSbmtd = true;
    if ((this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != '') {
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.respnseTimeForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.respnseTimeForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
        this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal && this.pstT == 'Monthly') {
          if (this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)) {
            this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
          }
          else {
            let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
            let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]), dtdts = dts.split('...');
            this.respnseTimeForm.get('toDate')?.setValue(dtdts[1]);
          }
        } else {
          this.respnseTimeForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        }
      }
      let payLoad: any = this.respnseTimeForm.getRawValue(), fT: any[] = [], sN: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = []; 
      this.isDtCng = this.pstT; this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) : 
        (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.tooDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
        (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
      this.lstSlctdFacTags?.forEach((e: any) => {lstFacsTags.push(e.name) }),this.lstFacsTag = lstFacsTags;
      this.lstSlctdStores?.forEach((e: any) => {lstFacsNames.push(e.storeName) }),this.lstFacsName = lstFacsNames;
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.discrepancyTypeId = [], payLoad.reason = null,
      payLoad.email = this.usrLgnDtls.email, payLoad.userName = this.usrLgnDtls.userName, payLoad.storeTags = payLoad.storeTags != null ? 
        this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds != null ?
        this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN; payLoad.metricsId = 
        (payLoad.metricsId != '' && payLoad.metricsId != null && payLoad.metricsId != undefined) ? +payLoad.metricsId : 2,
      payLoad.orderTypeId = payLoad.orderTypeId != '' ? +payLoad.orderTypeId : null, payLoad.orderTag = payLoad.orderTag != '' ? +payLoad.orderTag : null,
      ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isFacBdge', 'isLocOptn'].forEach(e => {delete payLoad[e]});
      if (type == 'g')
        this.isShow = false, this.onGen(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '') : '', this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }
  
  onGen(payLoad: any) {
    let crntPgIndx = this.crntPage - 1; this.lstGrid = [], this.lstGridDate = [], this.lstX = [], this.lstY = [], this.lstMAT = [],
      this.lstFAC = [], this.ttlCount = 0, this.ttlCnt = [], this.ttlCntDly = []; let tc: any[] = [];
    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.respnseTime.replace('pageNo', `${crntPgIndx}`).
        replace('pageSize', `${this.pageSize}`).replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ?
        '1' : (this.tab == 'f' ? '2' : '3')}`), payLoad, 4).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data) {
            if (this.tab == 'o') {
              this.lstGrid = this.result?.data?.ovrBookingRepncTimeDTO ?? [];
            } else {
              let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
              if (data) {
                this.ttlItms = this.result.data?.totalListSize ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms), 
                Object.keys(data.groupingByName).forEach((s: any, i: number) => {
                  let sName = `${s}`.split('||')[0];
                  if (i == 0)
                    data.groupingByName[s].forEach((m: any) => { hdrs.push(m.rdate) });
                  result.push({ kId: null, kName: sName, matData: data.groupingByName[s], hdrs: hdrs, total: ttl });
                });
                data.listOfDateCount?.forEach((s: any) => this.ttlCnt.push(s.count));
                this.lstGrid = result, this.steRes = this.lstGrid[0]?.matData[0]?.stateName, this.dstRes = this.lstGrid[0]?.matData[0].districtName;
              }
              else if (data == null)
                this.glblSvc.onTstr('i', this.result.message);
            }
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
  }

  onCncl() {
    this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.slctdFac = [], this.slctdFacId = [], this.drpVal = '2',
    this.mtrcVal = 1, this.dscrpcyType ='', this.respnseTimeForm.get('isFacBdge')?.setValue('badge'), this.steRes = '', this.dstRes = '', 
    this.dpEDVal ='', this.dpSDVal='', this.lblTextOfDate = 'Max 12 months will be accepted',this.steResDly = '', this.dstResDly = '',
    this.respnseTimeForm.get('dpSDVal')?.setValue(''), this.respnseTimeForm.get('dpEDVal')?.setValue(''), this.expDrpnDly = '1',
    this.respnseTimeForm.get('fromDate')?.setValue(''), this.respnseTimeForm.get('toDate')?.setValue(''), this.expDrpn = '1', 
    this.respnseTimeForm.get('storeTags')?.setValue([]), this.respnseTimeForm.get('storeIds')?.setValue([]), this.ttlCnt = [],
    this.respnseTimeForm.get('pstT')?.setValue('Monthly'), this.respnseTimeForm.get('metricsId')?.setValue('2'), this.ttlCntDly = [],
    this.respnseTimeForm.get('orderTypeId')?.setValue(''), this.respnseTimeForm.get('orderTag')?.setValue('');
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
  }

  onClkRdb(ctrl: string) {
  if(ctrl == 'storeTags')
    this.fc?.storeIds.setValue([]), this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]),
    this.lstSlctdStores = [];
    else 
    this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), this.fc?.storeIds?.setValidators([Validators.required]),
    this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = []; 
  }
  
  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
        this.lstSlctdFacTags.push(item);
      }
     else {
      if (this.lstSlctdStores.filter((f: { storeId: any; }) => f.storeId === item.storeId).length == 0)
      this.lstSlctdStores.push(item);
     }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  // LoadStrName() {
  //     let frmCtrls = this.respnseTimeForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, districtId: 
  //     frmCtrls.district == 'null' ? 0 : +frmCtrls.district }; this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]],
  //     this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data)
  //             this.lstFacIds = this.result.data != null ? this.result.data : [];
  //           else
  //             this.glblSvc.onTstr('W', this.result.message);
  //         }
  //       }
  //     });
  // }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.respnseTimeExprt.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' :
        (this.tab == 'f' ? '2' :'3')}`), payLoad, 4).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  GetDailyData(dtt: any, type: string = 'g') {
    if (this.pstType == 'monthly' || this.pstType == 'weekly') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt != '') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if (this.pstType == 'monthly') {
        dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd); let mn = sm.split("-");
        let mnth = this.mnths.filter(m => m.name == mn[0]), v = this.lstMnths.find(month => month.val == mnth[0].val);
        let fromDateDay = mn[1] + '-' + v?.val + '-01'; let toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if (dtEND < toDateDay) {
          maxDate = dtEND;
        } else {
          maxDate = toDateDay;
        }
      } else
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length-1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.respnseTimeForm.getRawValue(), fT: any[] = [], sN: any[] = [];
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.tooDates = this.pstType == 'weekly' ?
      maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.fromDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(frmDte, 
        this.dtF.ymd): frmDte , payLoad.toDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate, payLoad.stateId =
      payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district, payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
      payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN;
      payLoad.orderTypeId = payLoad.orderTypeId != '' ? +payLoad.orderTypeId : null, payLoad.orderTag = payLoad.orderTag != '' ? +payLoad.orderTag : null,
      payLoad.userName = this.usrLgnDtls.userName, payLoad.metricsId = (payLoad.metricsId != '' && payLoad.metricsId != null && 
      payLoad.metricsId != undefined) ? +payLoad.metricsId : 2, payLoad.email = this.usrLgnDtls.email, payLoad.discrepancyTypeId = [], 
      payLoad.reason = null, ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isFacBdge', 'isLocOptn'].forEach(e => { delete payLoad[e] });
      if (type == 'g')
        this.isShow = true, this.onGenDaysData(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '') : '', this.onDaysEE(payLoad);
    }
  }

  onGenDaysData(payLoad: any) {
    let crntPgIndx = this.crntDPage - 1; this.lstGridDate = [], this.ttlCntDly = [],this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt,
      environment.apiMthds.respnseTime.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).
      replace('ddd', 'D').replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`), payLoad, 4).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              if (this.tab == 'o') {
                this.lstGridDate = this.result?.data?.ovrBookingRepncTimeDTO ?? [];
              } else {
                let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.ttlDayItms = this.result.data?.totalListSize ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms), 
                  Object.keys(data.groupingByName).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0];
                    if (i == 0)
                      data.groupingByName[s].forEach((m: any) => { hdrs.push(m.rdate) });
                    result.push({ kId: null, kName: sName, matData: data.groupingByName[s], hdrs: hdrs, total: ttl });
                  });
                  data.listOfDateCount?.forEach((s: any) => this.ttlCntDly.push(s.count));
                  this.lstGridDate = result, this.steResDly = this.lstGridDate[0]?.matData[0]?.stateName, this.dstResDly = this.lstGridDate[0]?.matData[0].districtName;
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
                else
                  this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGridDate), this.glblSvc.sbjctTblDataCnt.next(data?.count ?? 0);
              }
            }
            else
              this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  onDaysEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.respnseTimeExprt.
      replace('ddd', `${'d'}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`), payLoad, 4).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
  }

}