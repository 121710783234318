<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="mmIdLst && !isOrdLstHdn">
                <a class="nav-link" id="orders-tab" data-bs-toggle="tab" href="#orders" role="tab"
                    aria-controls="orders" aria-selected="true" (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' || paramTab === 'sts' ? 'active' : ''">Indents
                </a>
            </li>
            <li class="nav-item" *ngIf="mmCrtId && !isOrdCrtHdn">
                <a class="nav-link" id="creat-order-tab" data-bs-toggle="tab" href="#creat-order" role="tab"
                    aria-controls="creat-order" aria-selected="false" (click)="onTabSelection('new')"
                    [ngClass]="paramTab === 'new' ? 'active' : ''">Create Indent
                </a>
            </li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="orders-list" role="tabpanel" aria-labelledby="orders-tab" *ngIf="mmIdLst && !isOrdLstHdn"
                [ngClass]="paramTab === 'list' ? 'show active' : ''">
                <evin-orders-list></evin-orders-list>
            </div>
            <div class="tab-pane fade" id="order-status" role="tabpanel" aria-labelledby="orders-tab2"
                [ngClass]="paramTab === 'sts' ? 'show active' : ''">
                <evin-orders-status></evin-orders-status>
            </div>
            <div class="tab-pane fade" id="order-fulfill" role="tabpanel" aria-labelledby="orders-tab3"
                [ngClass]="paramTab === 'fulfill' ? 'show active' : ''">
                <evin-order-fulfill></evin-order-fulfill>
            </div>
            <div class="tab-pane fade" id="creat-order" role="tabpanel" aria-labelledby="creat-order-tab" *ngIf="mmCrtId && !isOrdCrtHdn"
                [ngClass]="paramTab === 'new' ? 'show active' : ''">
               <evin-orders-create></evin-orders-create>
            </div>
        </div>
    </div>
</div>