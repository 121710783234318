<div>
    <div class="row mt-1">
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Stock Management </h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span>
                                        </a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Donut';LoadDnClkFC()">
                                                <i class="fa fa-circle-thin"></i>Doughnut Charts
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Pie';LoadNewFCs('pie2d', 'fc-pieI')">
                                                <i class="fa fa-pie-chart"></i>Pie chart
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Bar';LoadNewFCs('column2d', 'fc-VbarI')">
                                                <i class="fa fa-bar-chart"></i>Bar chart
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Line';LoadNewFCs('line', 'fc-lineI')">
                                                <i class="fa fa-line-chart"></i>Line Charts
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample12"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Material tag(s):</span> RI Vaccines,
                                <span class="font-weight-bold">Period:</span> 0 day(s)
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample12">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <div class="">
                                                        <ng-multiselect-dropdown [settings]="msdStngs"
                                                            [data]="lstMatTags"></ng-multiselect-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block ">
                    <div [hidden]="invChart!='Donut'" id="fc-dnI"></div>
                    <div [hidden]="invChart!='Bar'" id="fc-VbarI"></div>
                    <div [hidden]="invChart!='Line'" id="fc-lineI"></div>
                    <div [hidden]="invChart!='Pie'" id="fc-pieI"></div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Temperature</h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Donut';LoadNewFCs('doughnut2d', 'fc-dnT')">
                                                <i class="fa fa-circle-thin"></i>Doughnut Charts
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Pie';LoadNewFCs('pie2d', 'fc-pieT')">
                                                <i class="fa fa-pie-chart"></i>Pie chart
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Bar';LoadNewFCs('column2d', 'fc-VbarT')">
                                                <i class="fa fa-bar-chart"></i>Bar chart
                                            </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Line';LoadNewFCs('line', 'fc-lineT')">
                                                <i class="fa fa-line-chart"></i>Line Charts
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample13"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Exclude Temparature status:</span>Unknown
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample13">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <div class="">
                                                        <ng-multiselect-dropdown [settings]="msdStngs"
                                                            [data]="lstMatTags"></ng-multiselect-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block"> <!-- apex-chart -->
                    <div [hidden]="tmpChart!='Donut'" id="fc-dnT"></div>
                    <div [hidden]="tmpChart!='Bar'" id="fc-VbarT"></div>
                    <div [hidden]="tmpChart!='Line'" id="fc-lineT"></div>
                    <div [hidden]="tmpChart!='Pie'" id="fc-pieT"></div>
                    <div id="fcdnTmp" class="text-center" hidden></div>
                    <div id="fcpieTmp" class="text-center" hidden></div>
                    <div id="fcbarTmp" class="text-center" hidden></div>
                    <div id="fclineTmp" class="text-center" hidden></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 pb-3">
                <div class="card-header pb-2 pt-2 pl-4 pr-4">
                    <h5 class="mb-0">Stock Management </h5>
                    <p class="mb-0">Stock: Normal, Material category(s): RI Vaccines, Period: 0 day(s)</p>
                </div>
                <div class="card-body p-3 mt-3 border-0">
                    <div class="row">
                        <div class="col-xl-7 col-sm-12">
                            <div class="float-end">
                                <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                    <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab"
                                            data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                            aria-selected="true"><i class="fa fa-desktop me-0"
                                                aria-hidden="true"></i></a>
                                    </li>
                                    <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                                            data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                                            aria-selected="false"><i class="fa fa-bar-chart me-0"
                                                aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                            <h5 class="mb-4 text-center">By location </h5>
                            <div class="tab-content " id="top-tabContent">
                                <div class="tab-pane fade show active" id="Map" role="tabpanel"
                                    aria-labelledby="Map-tab">
                                    <div class="row centered-div">
                                        <div [hidden]="isHideIND">
                                            <h3><label style="text-align: center;">INDIA</label></h3>
                                            <div id="fc-map-ind" style="padding-left: 2% !important;"></div>
                                        </div>
                                        <button id="btnState" (click)="isHideIND=true;onClkState()" hidden></button>
                                        <div [hidden]="!isHideIND">
                                            <h3><label id="lblState" style="text-align: center;"></label></h3>
                                            <div id="fc-map-state-ind"></div><br>
                                            <button class="btn btn-sm btn-light" (click)="isHideIND=false">Back</button>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <!-- default -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(115 102 255 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(115 102 255 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(115 102 255 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- green -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(26 175 93 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(26 175 93 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(26 175 93 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- red -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(217 83 79 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(217 83 79 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(217 83 79 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- yellow -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(250 212 46 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(250 212 46 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(250 212 46 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- blue -->
                                        <div class="col-xl-12 mb-2 mt-2" hidden>
                                            <div class="text-center">
                                                <span class="box_indicators border mt-3 p-2">
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.5) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>0-10%</span></span>
                                                    <span class="me-3">
                                                        <span style="color:rgb(0 192 239 / 0.6) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>10-25%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.7) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>25-50%</span></span>
                                                    <span class="me-3">
                                                        <span style="color: rgb(0 192 239 / 0.8) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>50-90%</span></span>
                                                    <span>
                                                        <span style="color: rgb(0 192 239 / 0.9) !important;">
                                                            <i class="fa fa-square me-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span>90-100%</span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                                    <div class="row centered-div">
                                        INDIA Map table
                                        <!-- <img class="img-fluid" src="./assets/img/Screenshot 2021-04-27-103959.jpg" alt=""> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-5 col-sm-12">
                            <div>
                                <h5 class="mb-4 text-center">By material</h5>
                                <div class="float-xl-12"></div>
                                <div id="fc-bar-horz"></div> <!-- <div id="fc-bar" class="text-center mt-4"></div> -->
                                <!-- <div id="multi-bar" style="height: 500px;"></div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="tooltip" style="position: absolute; z-index: 9999"></div>