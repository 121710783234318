<div class="card mb-0 p-0 border-0">
    <div class="card-header p-2">Last <strong>30</strong> custom reports</div>
    <div class="card-body  p-2">
        <div class="table_spacing table-responsive mt-3 mb-3">
            <table class="table table-striped table-bordered table-sm ">
                <thead>
                    <tr>
                        <th scope="col">Item No.</th>
                        <th scope="col">Job Id</th>
                        <th scope="col">Name</th>
                        <th scope="col">Exported on</th>
                        <th scope="col">Status</th>
                        <th scope="col">Download</th>
                        <th scope="col">Recipients</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="7">
                            <div class="text-center">
                                No custom reports
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>