<form  [formGroup]="dmnFrm" autocomplete="off" >
<div class="card p-0 border-0">
    <div class="card-header border-0 p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="linked-domains-tab" data-bs-toggle="tab" href="#linked-domains"
                    role="tab" aria-controls="Order" aria-selected="true">Linked domains
                </a>
            </li>
        </ul>
    </div>
    <div class="card-body border-0 p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="linked-domains" role="tabpanel"
                aria-labelledby="linked-domains-tab">
                <div>
                    Other domains can be nested with this domain as children. 'Remove' can be used to disassociate the child domains (the domains will not be deleted,
                    only relationship is removed).
                </div>
                <div class="row">
                    <div class="col-xl-6 col-sm-12">
                        <div class="card p-0 border-0 mt-3" *ngIf="lstDmns?.length!=0">
                            <div class="card-header p-2">
                                <h6 *ngIf="slctDmnNme != ''" class="mb-0">Domains of <b>{{slctDmnNme | uppercase}}</b></h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="col-xl-12 col-sm-12 mt-4">
                                    <div id="accordian" class="menu-su-domain-container">
                                        <ul class="menu">
                                            <li class="menu-item-has-children" *ngFor="let dl1 of lstDmns">
                                                <a class="span_div">
                                                    <span class="span_div1" (click)="LoadMappedPranths(dl1)">{{dl1.pranthName}}</span>
                                                    <span class="icon_div">
                                                        <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl1)"></i>&nbsp;&nbsp;
                                                        
                                                        <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl1.pranthId)"></i>
                                                    </span>
                                                </a>
                                                <ul *ngIf="dl1?.Pranth?.length!=0">
                                                    <li *ngFor="let dl2 of dl1?.Pranth">
                                                        <a class="span_div">
                                                            <span class="span_div1" (click)="LoadMappedPranths(dl2)">{{dl2.pranthName}}</span>
                                                            <span class="icon_div">
                                                                <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl2)"></i>&nbsp;&nbsp;
                                                                
                                                                <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl2.pranthId)"></i>
                                                            </span>
                                                        </a>
                                                        <ul *ngIf="dl2?.Pranth?.length!=0">
                                                            <li *ngFor="let dl3 of dl2?.Pranth">
                                                                <a class="span_div">
                                                                    <span class="span_div1" (click)="LoadMappedPranths(dl3)">{{dl3.pranthName}}</span>
                                                                    <span class="icon_div">
                                                                        <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl3)"></i>&nbsp;&nbsp;
                                                                        
                                                                        <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl3.pranthId)"></i>
                                                                    </span>
                                                                </a>
                                                                <ul *ngIf="dl3?.Pranth?.length!=0">
                                                                    <li *ngFor="let dl4 of dl3?.Pranth">
                                                                        <a class="span_div">
                                                                            <span class="span_div1" (click)="LoadMappedPranths(dl4)">{{dl4.pranthName}}</span>
                                                                            <span class="icon_div">
                                                                                <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl4)"></i>&nbsp;&nbsp;
                                                                                
                                                                                <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl4.pranthId)"></i>
                                                                            </span>
                                                                        </a>
                                                                        <ul *ngIf="dl4?.Pranth?.length!=0">
                                                                            <li *ngFor="let dl5 of dl4?.Pranth">
                                                                                <a class="span_div">
                                                                                    <span class="span_div1" (click)="LoadMappedPranths(dl5)">{{dl5.pranthName}}</span>
                                                                                    <span class="icon_div">
                                                                                        <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl5)"></i>&nbsp;&nbsp;
                                                                                        
                                                                                        <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl5.pranthId)"></i>
                                                                                    </span>
                                                                                </a>
                                                                                <ul *ngIf="dl5?.Pranth?.length!=0">
                                                                                    <li *ngFor="let dl6 of dl5?.Pranth">
                                                                                        <a class="span_div">
                                                                                            <span class="span_div1" (click)="LoadMappedPranths(dl6)">{{dl6.pranthName}}</span>
                                                                                            <span class="icon_div">
                                                                                                <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl6)"></i>&nbsp;&nbsp;
                                                                                                
                                                                                                <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl6.pranthId)"></i>
                                                                                            </span>
                                                                                        </a>
                                                                                        <ul *ngIf="dl6?.Pranth?.length!=0">
                                                                                            <li *ngFor="let dl7 of dl6?.Pranth">
                                                                                                <a class="span_div">
                                                                                                    <span class="span_div1" (click)="LoadMappedPranths(dl7)">{{dl7.pranthName}}</span>
                                                                                                    <span class="icon_div">
                                                                                                        <i class="fa fa-exchange" aria-hidden="true" (click)="onTreDmnClk(dl7)"></i>&nbsp;&nbsp;
                                                                                                        
                                                                                                        <i class="fa fa-trash" aria-hidden="true" (click)="onDlnkDmn(dl7.pranthId)"></i>
                                                                                                    </span>
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul>
                                                                            </li>
                                                                        </ul>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-sm-12" *ngIf="isChldShw && dmnActns">
                        <div class="card p-0 border-0 mt-3">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Set permissions to Child Domains of {{chldDmnNme}}</h6>
                            </div>
                            <div class="card-body p-3" *ngIf="lstMappedPrnths.length != 0">
                                <div class="row mt-3">
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Specify child domains</label>
                                        <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstMappedPrnths" formControlName = "mppdPrnth"
                                            (onSelect)="onChng($event, 'os')" (onSelectAll)="onChng($event, 'osa')"
                                            (onDeSelect)="onChng($event, 'ds')" (onDeSelectAll)="onChng($event, 'dsa')">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Copy options</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                [(ngModel)]="mtrlsFprnt" (change)="onChngChld('mtrls', $event)">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Copy materials from {{chldDmnNme}}
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="cnfgFprnt"
                                                (change)="onChngChld('cnfg', $event)">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Copy configuration from {{chldDmnNme}}
                                            </label>
                                        </div>
                                        <!-- <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" [(ngModel)]="prntFprnt"
                                                (change)="onChngChld('prnt', $event)">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Copy configuration from parents to all sub-domains
                                            </label>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div>Permissions</div>
                                    <div class="mt-2">Master Data</div>
                                    <div class="table-responsive table_spacing">
                                        <table class="table table-striped table-bordered border-primary f-14">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="col-auto text-center">View</th>
                                                    <th class="col-auto">Add</th>
                                                    <th class="col-auto">Edit</th>
                                                    <th class="col-auto">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let prmsn of chldDmns">
                                                <tr>
                                                    <td colspan="5" class="text-center">{{prmsn.frameType}}</td>
                                                </tr>
                                                <tr *ngFor="let pp of prmsn?.permissionPranth">
                                                    <td>{{pp.permissionType}}</td>
                                                    <td class="text-center" *ngFor="let pn of pp?.permissionCodes">
                                                        <div class="form-check form-check-inline" *ngIf="pn.id != 180">
                                                            <input class="form-check-input" type="checkbox" id="{{pn.id}}"
                                                                value="{{pn.id}}"
                                                                (click)="onChngePrmsn('chld', $event, pn.id)">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-sm-12">
                                        <div class="float-end">
                                            <button type="button" class="btn btn-sm btn-warning me-2"
                                                (click)="onUpdate('chld')">Update to all childs</button>

                                            <button type="button" class="btn btn-sm btn-primary me-2"
                                                (click)="onUpdate('main')">Update</button>
                                            <button type="button" class="btn btn-sm btn-light" (click)="onCncl()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-3" *ngIf="lstMappedPrnths.length == 0">
                                <p>There are no child domains mapped with {{chldDmnNme}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</form>