<form [formGroup]="setupAssetsCreateForm" autocomplete="off">
    <div class="card p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6>Asset information</h6>
        </div>
        <div class="card-body p-4 pt-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <span class="small m-0">Please specify basic asset information</span>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Asset type <span class="text-danger">*</span></label>
                            <select id="asetTypeId" class="form-select form-select-sm" (change)="onNestedSlct($event, 'asetType')"
                                formControlName="asetTypeId" [ngClass]="{'is-invalid': isSbmtd && fc.asetTypeId.errors}">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of astTypLst" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.asetTypeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.asetTypeId.errors.required">Asset type is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Manufacturer <span class="text-danger">*</span></label>
                            <select id="manufacturer" class="form-select form-select-sm"
                                (change)="onNestedSlct($event, 'manufacturer')" formControlName="manufacturer"
                                [ngClass]="{'is-invalid': isSbmtd && fc.manufacturer.errors}"
                                [attr.disabled]="(fc.asetTypeId.value == '' || fc.asetTypeId.value == null) ? true : null">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of manufacturersLst" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.manufacturer.errors" class="invalid-feedback">
                                <div *ngIf="fc.manufacturer.errors.required">Manufacturer is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Asset model <span class="text-danger">*</span></label>
                            <select id="assetModelId" class="form-select form-select-sm" formControlName="assetModelId"
                                [ngClass]="{'is-invalid': isSbmtd && fc.assetModelId.errors}" (change)="onNestedSlct($event, 'astModel')"
                                [attr.disabled]="(fc.asetTypeId.value == '' || fc.asetTypeId.value == null ||
                                fc.manufacturer.value == '' || fc.manufacturer.value == null) ? true : null">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of modelsLst" value="{{item.id}}">{{item.modelName}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.assetModelId.errors" class="invalid-feedback">
                                <div *ngIf="fc.assetModelId.errors.required">Asset model is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Serial number <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Serial number" class="form-control form-control-sm" 
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)) ||
                                (event.charCode >= 48 && event.charCode <= 57)' formControlName="serialNumber"
                            [attr.maxlength]="maxSnoLength"  (change)="isDup=false;onChngSN()" id="serialNumber" (keypress) = "stripText($event)"
                            [ngClass]="{'is-invalid': ((isSbmtd && fc.serialNumber.errors) || (isDup))}">
                            <div *ngIf="isSbmtd && fc.serialNumber.errors" class="invalid-feedback">
                                <div *ngIf="fc.serialNumber.errors.required || fc.serialNumber.errors.minlength">Min. 5 length serial number is required</div>
                            </div>
                            <div *ngIf="isDup" class="invalid-feedback">
                                <div *ngIf="isDup">{{isDupTxt}}</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Year of manufacture <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Year of manufacture" class="form-control form-control-sm"
                                formControlName="yearOfManufacture" id="yearOfManufacture"
                                maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{'is-invalid': isSbmtd && fc.yearOfManufacture.errors}">
                            <div *ngIf="isSbmtd && fc.yearOfManufacture.errors" class="invalid-feedback">
                                <div *ngIf="fc.yearOfManufacture.errors.required">Year of manufacture is required</div>
                                <div *ngIf="fc.yearOfManufacture.errors.min || fc.yearOfManufacture.errors.max">
                                    Year of manufacture should accepts between 1970 to {{cYr}}
                                </div>
                            </div>
                           
                        </div>
                        
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Facility <span class="text-danger">*</span></label>
                            <ng-template #cstmFacility let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Facility" class="form-control form-control-sm"
                                formControlName="storeId" [typeahead]="lstPrnts" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmFacility" typeaheadOptionField="strDtls" id="Store"
                                (typeaheadOnSelect)="onChngFclty($event, 'slct')" (change)="onChngFclty($event, 'chng')"
                                 [adaptivePosition]="true" [ngClass]="{'is-invalid': isSbmtd && fc.storeId.errors}" 
                                 [typeaheadOptionsLimit]="500"/>

                            <!-- <div *ngIf = "isStoreID" class="invalid-feedback">
                                Facility is required
                            </div>    -->
                            <div *ngIf="isSbmtd && fc.storeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeId.errors?.required">Facility is required</div>
                            </div>                            
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf = "showOther">
                            <label for="textBox">Owners <span class="text-danger">*</span></label>
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userLst"
                            [(ngModel)]="selectedOwners"
                                formControlName="owners" 
                                (onSelect)="onChng('owner', $event, 'os')"
                                (onSelectAll)="onChng('owner', $event, 'osa')"
                                (onDeSelect)="onChng('owner', $event, 'ds')"
                                (onDeSelectAll)="onChng('owner', $event, 'dsa')"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.owners.errors }"
                                >
                                <!-- (onSelect)="onItemSelect('owner', $event)"
                                (onSelectAll)="onSelectAll('owner', $event)"  -->
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.owners.errors" class="invalid-feedback">
                                <div *ngIf="fc.owners.errors?.required">Owners is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf = "showOther">
                            <label for="textBox">Maintainers <span class="text-danger">*</span></label>
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userLst"
                            [(ngModel)]="selectedMaintainers"
                                formControlName="maintainers"             
                                (onSelect)="onChng('maintainers', $event, 'os')"
                                (onSelectAll)="onChng('maintainers', $event, 'osa')"
                                (onDeSelect)="onChng('maintainers', $event, 'ds')"
                                (onDeSelectAll)="onChng('maintainers', $event, 'dsa')" 
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.maintainers.errors }">
                                <!-- (onSelect)="onItemSelect('maintainers', $event)"
                                (onSelectAll)="onSelectAll('maintainers', $event)"  -->
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.maintainers.errors" class="invalid-feedback">
                                <div *ngIf="fc.maintainers.errors?.required">Maintainers is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label>Status <span class="text-danger">*</span></label>
                            <select class="form-select form-select-sm" id="statusId" formControlName="statusId" [(ngModel)]="stus"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.statusId.errors }">
                                <option value="">-Select-</option>
                                <option *ngFor="let status of astStatsLst" value="{{status.id}}">{{status.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.statusId.errors" class="invalid-feedback">
                                <div *ngIf="fc.statusId.errors?.required">Status is required</div>
                            </div>
                        </div>

                        <div class="form-group col-xl-3 mt-3" *ngIf="snsrInfo">
                            <label>Sensor A </label>
                            <input type="text" placeholder="Sensor A" class="form-control form-control-sm text-uppercase" 
                            maxlength="50" formControlName="sensorAMAC" (change)="dupMsgA='';onChngFN($event, 'A')">
                            <div class="highTemp" *ngIf="dupMsgA!=''">{{dupMsgA}}</div>
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf="snsrInfo">
                            <label>Sensor B <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Sensor B" class="form-control form-control-sm text-uppercase" 
                            maxlength="50" formControlName="sensorBMAC" (change)="dupMsgB='';onChngFN($event, 'B')" [ngClass]="{'is-invalid': (isSbmtd && fc.sensorBMAC.errors)}">
                            <div *ngIf="isSbmtd && fc.sensorBMAC.errors" class="invalid-feedback">
                                <div *ngIf="fc.sensorBMAC.errors.required || fc.sensorBMAC.errors.minlength">Min. 10 length sensor B MAC is required</div>
                            </div>
                            <div class="highTemp" *ngIf="dupMsgB!=''">{{dupMsgB}}</div>             
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf="snsrInfo">
                            <label>Sensor C </label>
                            <input type="text" placeholder="Sensor C" class="form-control form-control-sm text-uppercase"  (change)="dupMsgC='';onChngFN($event, 'C')"
                            maxlength="50" formControlName="sensorCMAC">
                            <div class="highTemp" *ngIf="dupMsgC!=''">{{dupMsgC}}</div>
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf="snsrInfo">
                            <label>Sensor D</label>
                            <input type="text" placeholder="Sensor D" class="form-control form-control-sm text-uppercase" [attr.disabled] = 'true'  (change)="dupMsgD='';onChngFN($event, 'D')"
                            formControlName="sensorDMAC" [ngClass]="{'is-invalid': (isSbmtd && fc.sensorDMAC.errors)}">
                            <!-- <div *ngIf="isSbmtd && fc.sensorDMAC.errors" class="invalid-feedback">
                                <div *ngIf="fc.sensorDMAC.errors.required || fc.sensorDMAC.errors.minlength">Min. 10 length sensor B MAC is required</div>
                            </div>
                            <div class="highTemp" *ngIf="dupMsgD!=''">{{dupMsgD}}</div> -->
                        </div>
                        <div class="form-group col-xl-3 mt-3" *ngIf="snsrInfo">
                            <label>Sensor E</label>
                            <input type="text" placeholder="Sensor E" class="form-control form-control-sm text-uppercase" (change)="dupMsgE='';onChngFN($event, 'E')"
                            maxlength="50" formControlName="sensorEMAC">
                            <div class="highTemp" *ngIf="dupMsgE!=''">{{dupMsgE}}</div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="showData">
                <div class="card-header p-2">
                    <h6>Asset GSM information</h6>
                </div>
                <div class="card-body p-2">
                    <div class="mt-2 mb-2 f-w-500">Please provide asset GSM information</div>
                    <div class="row">
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">Mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="mobileNumber" 
                                    formControlName="mobileNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    maxlength="13" minlength="10" [ngClass]="{'is-invalid': isSbmtd && fc.mobileNumber.errors}">
                                    <div *ngIf="isSbmtd && fc.mobileNumber.errors" class="invalid-feedback">                                    
                                        <div *ngIf="fc.mobileNumber.errors.minlength || fc.mobileNumber.errors.maxlength">
                                            Mobile number should be min. of 10 and max. of 13 digits only
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">SIM ID</label>
                            <input type="text" placeholder="SIM ID" class="form-control form-control-sm" id="simNumber"
                                formControlName="simNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="25"
                                [ngClass]="{'is-invalid': isSbmtd && fc.simNumber.errors}" minlength="5">
                                <div *ngIf="isSbmtd && fc.simNumber.errors" class="invalid-feedback">                                    
                                    <div *ngIf="fc.simNumber.errors.min">
                                        Min. sim number should be 5 digits
                                    </div>
                                </div>
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">SIM network provider</label>
                            <input type="text" placeholder="SIM network provider" class="form-control form-control-sm" id="simVendor"
                                formControlName="simVendor">
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">Alternate Mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Alternate Mobile number" class="form-control form-control-sm" id="alternateMobile"
                                    formControlName="alternateMobile" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    maxlength="13" [ngClass]="{'is-invalid': isSbmtd && fc.alternateMobile.errors}" minlength="10">
                                    <div *ngIf="isSbmtd && fc.alternateMobile.errors" class="invalid-feedback">                                    
                                        <div *ngIf="fc.alternateMobile.errors.minlength || fc.alternateMobile.errors.maxlength">
                                            Alternate mobile number should be min. of 10 and max. of 13 digits only
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">Alternate SIM ID</label>
                            <input type="text" placeholder="SIM ID" class="form-control form-control-sm" id="alternateSimNumber"
                                formControlName="alternateSimNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="25"
                                [ngClass]="{'is-invalid': isSbmtd && fc.alternateSimNumber.errors}" minlength="5">
                                <div *ngIf="isSbmtd && fc.alternateSimNumber.errors" class="invalid-feedback">                                    
                                    <div *ngIf="fc.alternateSimNumber.errors.min">
                                        Min. alternate sim number should be 5 digits
                                    </div>
                                </div>
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">Alternate SIM network provider</label>
                            <input type="text" placeholder="SIM network provider" class="form-control form-control-sm"
                                id="alternateSimVendor" formControlName="alternateSimVendor">
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">IMEI</label>
                            <input type="text" placeholder="Asset IMEI number" class="form-control form-control-sm" id="assetImeiNumber"
                                formControlName="assetImeiNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="30"
                                [attr.disabled] = 'true'>
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">Firmware version</label>
                            <input type="text" placeholder="Firmware version" class="form-control form-control-sm" id="firmwareVersion"
                                formControlName="firmwareVersion">
                        </div>
                        <div class="form-group col-xl-6 mt-3">
                            <label for="textBox">GSM module version</label>
                            <input type="text" placeholder="GSM module version" class="form-control form-control-sm"
                                id="gsmModuleVersion" formControlName="gsmModuleVersion">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card" *ngIf="showData">
                <div class="card-header p-2">
                    <h6>Temperature logger configuration</h6>
                </div>
                <div class="card-body p-2">
                    <div class="mt-2 mb-2 f-w-500">Please specify device configuration.</div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="enablePushConfiguration"
                            name="enablePushConfiguration" value="false" (change)="optChange($event)"
                            formControlName="enablePushConfiguration">
                        <label class="form-check-label" for="enablePushConfiguration">Don't push default configuration to device</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="enablePushConfiguration"
                            name="enablePushConfiguration" value="true" (change)="optChange($event)"
                            formControlName="enablePushConfiguration">
                        <label class="form-check-label" for="enablePushConfiguration">Push default configuration to device</label>
                    </div>
                    <div *ngIf="show">
                        <hr />
                        <button type="button" class="btn btn-sm btn-light"
                            (click)="btnFlag=!btnFlag;tglBtn()">{{bnData}}</button>
                        <div class="row" formGroupName="assetConfiguration">
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <div class="row mb-2">
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Communication channel <span
                                                class="text-danger">*</span></label>
                                        <select id="communicationChannelId" class="form-select form-select-sm"
                                            formControlName="communicationChannelId"
                                            [attr.disabled]="true"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('communicationChannelId')?.errors}">
                                            <option value="" selected>-Select-</option>
                                            <option [value]="cc.id" *ngFor="let cc of comuChnlLst">{{cc.name}}
                                            </option>
                                        </select>
                                        <!-- *ngIf="isSbmtd && fc.assetConfiguration.controls.communicationChannelId.errors" -->
                                        <div *ngIf="isSbmtd && fc1.get('communicationChannelId')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fc1.get('communicationChannelId')?.errors?.required">Communication channel
                                                is required</div>
                                        </div>
                                    </div>
                                     <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Temperature push URL <span
                                            class="text-danger">*</span></label>
                                        <input type="text" placeholder="Temperature push URL" class="form-control form-control-sm"
                                            id="temperaturePushUrl" formControlName="temperaturePushUrl"
                                            [attr.disabled]="true"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('temperaturePushUrl')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('temperaturePushUrl')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('temperaturePushUrl')?.errors?.required">Temperature push URL is
                                                required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Configuration pull URL</label>
                                        <input type="text" placeholder="Configuration pull URL" class="form-control form-control-sm"
                                            id="configurationPullUrl" formControlName="configurationPullUrl"
                                            [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Alarm push URL <span class="text-danger">*</span></label>
                                        <input type="text" placeholder="Alarm push URL" class="form-control form-control-sm"
                                            id="alarmPushUrl" formControlName="alarmPushUrl"
                                            [attr.disabled]="true"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('alarmPushUrl')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('alarmPushUrl')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('alarmPushUrl')?.errors?.required">Alarm push URL is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Stats push URL</label>
                                        <input type="text" placeholder="Stats push URL" class="form-control form-control-sm"
                                            id="statsPushUrl" formControlName="statsPushUrl"
                                            [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Device ready status push URL</label>
                                        <input type="text" placeholder="Device ready status push URL"
                                            class="form-control form-control-sm" id="deviceReadyStatusPushUrl"
                                            formControlName="deviceReadyStatusPushUrl"
                                            [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">SMS gateway phone number <span
                                                class="text-danger">*</span></label>
                                        <input type="text" placeholder="SMS gateway phone number" class="form-control form-control-sm"
                                            id="smsGatewayPhoneNumber" formControlName="smsGatewayPhoneNumber"
                                            [attr.disabled]="true"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('smsGatewayPhoneNumber')?.errors}">
                                        <p class="small pb-0 mb-0">+&lt;Country-Code&gt; &lt;Mobile phone&gt; (e.g.
                                            +91
                                            08023418743 [India], +1 5105015555 [USA])</p>
                                        <div *ngIf="isSbmtd && fc1.get('smsGatewayPhoneNumber')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fc1.get('smsGatewayPhoneNumber')?.errors?.required">SMS gateway phone
                                                number is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">SMS gateway keyword</label>
                                        <input type="text" placeholder="SMS gateway keyword" class="form-control form-control-sm"
                                            id="smsGatewayKeyword" formControlName="smsGatewayKeyword"
                                            [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Sender Id</label>
                                        <input type="text" placeholder="Sender Id" class="form-control form-control-sm"
                                            id="smsGatewaySenderId" formControlName="smsGatewaySenderId"
                                            [attr.disabled]="true">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Sampling interval (in minutes) <span
                                                class="text-danger">*</span></label>
                                        <input type="text" placeholder="Sampling interval (in minutes)"
                                            class="form-control form-control-sm" id="samplingInterval"
                                            formControlName="samplingInterval"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('samplingInterval')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('samplingInterval')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('samplingInterval')?.errors?.required">Sampling interval (in
                                                minutes) is required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Push interval (in minutes) <span class="text-danger">*</span></label>
                                        <input type="text" placeholder="Push interval (in minutes)" class="form-control form-control-sm"
                                            id="pushInterval" formControlName="pushInterval"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('pushInterval')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('pushInterval')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('pushInterval')?.errors?.required">Push interval (in minutes) is
                                                required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3" *ngIf="snsrdscnct">
                                        <label for="textBox">Sensor disconnect interval (in minutes)<span class="text-danger">*</span></label>
                                        <input type="text" placeholder="Sensor disconnect interval (in minutes)" class="form-control form-control-sm"
                                            id="snsConFailInt" formControlName="snsConFailInt"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('snsConFailInt')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('snsConFailInt')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('snsConFailInt')?.errors?.required">Sensor disconnect interval (in minutes) is
                                                required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-12 mt-3">
                                        <label for="textBox">Phones to send SMS notifications </label>
                                        <input type="text" placeholder="Phones to send SMS notifications"
                                            class="form-control form-control-sm" id="phoneNumbersToSendSmsNotification"
                                            formControlName="phoneNumbersToSendSmsNotification"
                                            [attr.disabled]="btnFlag ? true : null">
                                        <p class="small pb-0 mb-0">(Phones to send SMS notifications directly)</p>
                                        <p class="small pb-0 mb-0">(User phone numbers (comma-separated)
                                            (+&lt;Country-Code&gt;&lt;Mobile phone&gt;; +919844360948 [India],
                                            +15404569089
                                            [USA]))</p>
                                    </div>
                                    <div class="form-group col-xl-12 mt-3">
                                        <label for="textBox " class="me-2 fw-bold">Notifications</label><br />
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                id="temperaturePushNotification"
                                                formControlName="temperaturePushNotification"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable raw temperature push
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                id="temperatureIncursionExcursionPushNotification"
                                                formControlName="temperatureIncursionExcursionPushNotification"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable temperature incursion/excursion push
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox"
                                                id="temperatureAlarmPushNotifications"
                                                formControlName="temperatureAlarmPushNotifications"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable device alarm push
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="statsPushNotification"
                                                formControlName="statsPushNotification"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable stats push
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="buzz"
                                                formControlName="buzz"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable buzz alarm
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="smsAlarmSent"
                                                formControlName="smsAlarmSent"
                                                [attr.disabled]="btnFlag ? true : null">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Enable SMS alarm
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Power outage alarm duration(in minutes)</label>
                                        <input type="text" formControlName="powerOutageAlarmDuration"
                                            placeholder="Power outage alarm duration(in minutes)"
                                            class="form-control form-control-sm" id="powerOutageAlarmDuration"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Low battery alarm threshold limits</label>
                                        <input type="text" placeholder="Low battery alarm threshold limits"
                                            class="form-control form-control-sm" id="lowBatteryAlarmThresholdLimit"
                                            formControlName="lowBatteryAlarmThresholdLimit"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">High alarm configuration</div>
                                        <div class="small">High temperature alarm limits.</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Temperature (in celsius) <span
                                            class="text-danger">*</span></label>
                                        <input type="text" placeholder="Temperature (in celsius)" class="form-control form-control-sm"
                                            id="highAlarmTemperature" formControlName="highAlarmTemperature"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('highAlarmTemperature')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('highAlarmTemperature')?.errors" class="invalid-feedback">
                                            <div *ngIf="fc1.get('highAlarmTemperature')?.errors?.required">Temperature (in
                                                celsius) is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Duration (in minutes) <span
                                            class="text-danger">*</span></label>
                                        <input type="text" placeholder="Duration (in minutes)" class="form-control form-control-sm"
                                            id="highAlarmTemperatureDuration"
                                            formControlName="highAlarmTemperatureDuration"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('highAlarmTemperatureDuration')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('highAlarmTemperatureDuration')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fc1.get('highAlarmTemperatureDuration')?.errors?.required">Duration (in
                                                minutes) is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">Low alarm configuration</div>
                                        <div class="small">Low temperature alarm limits.</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Temperature (in celsius) <span
                                            class="text-danger">*</span></label>
                                        <input type="text" placeholder="Temperature (in celsius)" class="form-control form-control-sm"
                                            id="lowAlarmTemperature" formControlName="lowAlarmTemperature"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('lowAlarmTemperature')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('lowAlarmTemperature')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fc1.get('lowAlarmTemperature')?.errors?.required">Temperature (in
                                                celsius) is required
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Duration (in minutes) <span
                                                class="text-danger">*</span></label>
                                        <input type="text" placeholder="Duration (in minutes)" class="form-control form-control-sm"
                                            id="lowAlarmTemperatureDuration"
                                            formControlName="lowAlarmTemperatureDuration"
                                            [attr.disabled]="btnFlag ? true : null"
                                            [ngClass]="{'is-invalid': isSbmtd && fc1.get('lowAlarmTemperatureDuration')?.errors}">
                                        <div *ngIf="isSbmtd && fc1.get('lowAlarmTemperatureDuration')?.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="fc1.get('lowAlarmTemperatureDuration')?.errors?.required">Duration (in
                                                minutes) is required
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">High warning configuration</div>
                                        <div class="small">High temperature warning limits.</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Temperature (in celsius)</label>
                                        <input type="text" placeholder="Temperature (in celsius)" class="form-control form-control-sm"
                                            id="highWarningTemperature" formControlName="highWarningTemperature"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Duration (in minutes)</label>
                                        <input type="text" placeholder="Duration (in minutes)" class="form-control form-control-sm"
                                            id="highWarningTemperatureDuration"
                                            formControlName="highWarningTemperatureDuration"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">Low warning configuration</div>
                                        <div class="small">Low temperature warning limits.</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Temperature (in celsius)</label>
                                        <input type="text" placeholder="Temperature (in celsius)" class="form-control form-control-sm"
                                            id="lowWarningTemperature" formControlName="lowWarningTemperature"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Duration (in minutes)</label>
                                        <input type="text" placeholder="Duration (in minutes)" class="form-control form-control-sm"
                                            id="lowWarningTemperatureDuration"
                                            formControlName="lowWarningTemperatureDuration"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">Alarm frequency notification</div>
                                        <div class="small">Alarm frequency limits to send notifications.</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Duration (in minutes)</label>
                                        <input type="text" placeholder="Duration (in minutes)" class="form-control form-control-sm"
                                            id="alarmFrequencyNotificationDuration"
                                            formControlName="alarmFrequencyNotificationDuration"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Number of times</label>
                                        <input type="text" placeholder="Number of times" class="form-control form-control-sm"
                                            id="alarmFrequencyNotificationNumber"
                                            formControlName="alarmFrequencyNotificationNumber"
                                            [attr.disabled]="btnFlag ? true : null">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">Locale configuration</div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Timezone <span class="text-danger">*</span></label>
                                        <select formControlName="timeZoneId" id="timeZoneId" class="form-select form-select-sm"
                                            [attr.disabled]="btnFlag ? true : null">
                                            <option value="" selected>-Select-</option>
                                            <option *ngFor="let tz of tmZoneLst" value="{{tz.id}}">{{tz.name}}</option>
                                        </select>                                                                           
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Country</label>
                                        <select class="form-select form-select-sm" formControlName="countryId"
                                            [attr.disabled]="btnFlag ? true : null" id="countryId">
                                            <option value="" selected>-Select-</option>
                                            <option *ngFor="let cn of cntriesLst" value="{{cn.id}}">{{cn.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="form-group col-xl-6">
                                        <label for="textBox">Language</label>
                                        <select class="form-select form-select-sm" formControlName="languageId"
                                            [attr.disabled]="btnFlag ? true : null" id="languageId">
                                            <option value="" selected>-Select-</option>
                                            <option *ngFor="let ln of LngLst" value="{{ln.id}}">{{ln.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="fw-bold">Sensor specific configurations</div>
                                        <div class="small">Please specify sensor specific configurations.</div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let item of GetDefaultConfgs()?.controls;let liIndx=index;">
                                            <a [ngClass]="liIndx==0?'nav-link active':'nav-link'"
                                                id="{{item?.get('sensorId')?.value}}-tab" data-bs-toggle="tab"
                                                href="#{{item?.get('sensorId')?.value}}" role="tab" title=""
                                                [attr.aria-controls]="item?.get('sensorId')?.value" aria-selected="true"
                                                data-bs-original-title="" (click)="tab=item?.get('sensorId')?.value">{{item?.get('sensorId')?.value}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" [id]="'icon-tabContent'+tabIndx"
                                        *ngFor="let item of GetDefaultConfgs()?.controls;let tabIndx=index;"                                        
                                        formArrayName="assetSensorConfigurationList">
                                        <div *ngIf="item?.get('sensorId')?.value == tab">
                                            <div class="tab-pane fade active show" id="{{item?.get('sensorId')?.value}}"
                                                role="tabpanel" [attr.aria-labelledby]="item?.get('sensorId')?.value+'-tab'"
                                                [formGroupName]="tabIndx">
                                                <div class="p-2">
                                                    <div class="form-group col-xl-3 mt-3">
                                                        <label for="textBox">Use default configuration</label>
                                                        <input class="form-check-input" type="checkbox"
                                                            id="useDefaultConfiguration"
                                                            formControlName="useDefaultConfiguration"
                                                            [attr.disabled]="btnFlag ? true : null">
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Sampling interval (in minutes)</label>
                                                            <input type="text" placeholder="Sampling interval (in minutes)"
                                                                class="form-control form-control-sm" id="samplingInterval"
                                                                formControlName="samplingInterval"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Push interval (in minutes)</label>
                                                            <input type="text" placeholder="Sampling interval (in minutes)"
                                                                class="form-control form-control-sm" id="pushInterval"
                                                                formControlName="pushInterval"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-xl-12 mt-3">
                                                        <label for="textBox "
                                                            class="me-2 fw-bold">Notifications</label><br />
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="temperaturePushNotification"
                                                                formControlName="temperaturePushNotification"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                            <label class="form-check-label"
                                                                for="temperaturePushNotification">
                                                                Enable raw temperature push
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="temperatureIncursionExcursionPushNotification"
                                                                formControlName="temperatureIncursionExcursionPushNotification"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                            <label class="form-check-label"
                                                                for="temperatureIncursionExcursionPushNotification">
                                                                Enable temperature incursion/excursion push
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="deviceAlarmPushNotification"
                                                                formControlName="deviceAlarmPushNotification"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                Enable device alarm push
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="statsPushNotification"
                                                                formControlName="statsPushNotification"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                Enable stats push
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-xl-12">
                                                            <div class="fw-bold">High alarm configuration</div>
                                                            <div class="small">High temperature alarm limits.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Temperature (in celsius)</label>
                                                            <input type="text" placeholder="Temperature (in celsius)"
                                                                class="form-control form-control-sm" id="highAlarmTemperature"
                                                                formControlName="highAlarmTemperature"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Duration (in minutes)</label>
                                                            <input type="text" placeholder="Duration (in minutes)"
                                                                class="form-control form-control-sm" id="highAlarmTemperatureDuration"
                                                                formControlName="highAlarmTemperatureDuration"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-xl-12">
                                                            <div class="fw-bold">Low alarm configuration</div>
                                                            <div class="small">Low temperature alarm limits.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Temperature (in celsius)</label>
                                                            <input type="text" placeholder="Temperature (in celsius)"
                                                                class="form-control form-control-sm" id="lowAlarmTemperature"
                                                                formControlName="lowAlarmTemperature"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Duration (in minutes)</label>
                                                            <input type="text" placeholder="Duration (in minutes)"
                                                                class="form-control form-control-sm" id="lowAlarmTemperatureDuration"
                                                                formControlName="lowAlarmTemperatureDuration"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-xl-12">
                                                            <div class="fw-bold">High warning configuration</div>
                                                            <div class="small">High temperature warning limits.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Temperature (in celsius)</label>
                                                            <input type="text" placeholder="Temperature (in celsius)"
                                                                class="form-control form-control-sm" id="highWarningTemperature"
                                                                formControlName="highWarningTemperature"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Duration (in minutes)</label>
                                                            <input type="text" placeholder="Duration (in minutes)"
                                                                class="form-control form-control-sm" id="highWarningTemperatureDuration"
                                                                formControlName="highWarningTemperatureDuration"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-xl-12">
                                                            <div class="fw-bold">Low warning configuration</div>
                                                            <div class="small">Low temperature warning limits.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Temperature (in celsius)</label>
                                                            <input type="text" placeholder="Temperature (in celsius)"
                                                                class="form-control form-control-sm" id="lowWarningTemperature"
                                                                formControlName="lowWarningTemperature"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Duration (in minutes)</label>
                                                            <input type="text" placeholder="Duration (in minutes)"
                                                                class="form-control form-control-sm" id="lowWarningTemperatureDuration"
                                                                formControlName="lowWarningTemperatureDuration"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="col-xl-12">
                                                            <div class="fw-bold">Alarm frequency notification</div>
                                                            <div class="small">Alarm frequency limits to send notifications.</div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Duration (in minutes)</label>
                                                            <input type="text" placeholder="Duration (in minutes)"
                                                                class="form-control form-control-sm" id="alarmFrequencyNotificationDuration"
                                                                formControlName="alarmFrequencyNotificationDuration"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                        <div class="form-group col-xl-3">
                                                            <label for="textBox">Number of times</label>
                                                            <input type="text" placeholder="Number of times"
                                                                class="form-control form-control-sm" id="alarmFrequencyNotificationNumber"
                                                                formControlName="alarmFrequencyNotificationNumber"
                                                                [attr.disabled]="item?.get('useDefaultConfiguration')?.value ? true : null">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-warning me-2 mb-2" *ngIf = "showPshBtn"
                        (click)="isSbmtd=true;pushSmsConfig=true;onSaveSetupAssets()">Save & Push Config. to Device</button>
                        <button class="btn btn-sm btn-primary me-2 mb-2"
                            (click)="isSbmtd=true;pushSmsConfig=false;onSaveSetupAssets()">Save</button>
                        <button class="btn btn-sm btn-light mb-2" (click)="cancel(); cnclEdtInfo()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>