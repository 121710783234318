<div class="card mt-3 border-0">
    <div class="card-body p-0">
        <div class="row">
            <div class="col-xl-6 mb-2">
                <!-- <span style="display: inline-block;" class="me-2">
                    <label tyle="display: inline-block;">Filter</label>
                </span>
                <span style="display: inline-block;">
                    <select class="form-select form-select-sm">
                        <option>5</option>
     s                   <option>10</option>
                    </select>
                </span> -->
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-sm table_spacing table-small-font p-0 table-bordered"
                style="min-width: 1100px;">
                <thead>
                    <tr>
                        <th>Item No.</th>
                        <th>Sent to</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Sent by</th>
                        <th style="width: 35%">Message</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-center">1</td>
                        <td>Abhimanyu Saxena (+91 9718249464)</td>
                        <td>27/12/20 3:44 PM</td>
                        <td></td>
                        <td>Srilekha Chakrabarty</td>
                        <td>Your password has been reset.</td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>
                        <td>Srilekha (+91 9718249464)</td>
                        <td>31/12/20 3:44 PM</td>
                        <td></td>
                        <td>Srilekha Chakrabarty</td>
                        <td>Your password has been reset.</td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>
                        <td>Srilekha (+91 9718249464)</td>
                        <td>31/12/20 3:44 PM</td>
                        <td>Message delivered</td>
                        <td></td>
                        <td>A one time password has been sent to Srilekha's registered mobile number. Please enter the
                            one-time password received below.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class=" pb-3 pt-3">
            <div class="row">
                <div class="col-lg-6 col-xxl-6">
                </div>
                <div class="col-lg-6 col-xxl-6">
                    <nav aria-label="Page navigation example ">
                        <ul class="pagination justify-content-xl-end  pagination-primary ">
                            <li class="page-item"><a class="page-link" aria-label="Previous"
                                    data-bs-original-title="" title=""><span aria-hidden="true">«</span><span
                                        class="sr-only">Previous</span></a></li>
                            <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a>
                            </li>
                            <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a>
                            </li>
                            <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a>
                            </li>
                            <li class="page-item"><a class="page-link" aria-label="Next"
                                    data-bs-original-title="" title=""><span aria-hidden="true">»</span><span
                                        class="sr-only">Next</span></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>