<div class="row p-3 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12 p-2 pt-0">
        <div class="float-start">
            <h5 class="mb-0">Fulfil the shipment: 2226202-1 ( Release : <a>2226202</a>)</h5>
        </div>
        <div class="clearfix"></div>
        <hr class="mt-1 mb-1" />
        <div class="small">Please review and confirm the received quantities before fulfilling the Indent. If the
            received quantities are different from the shipped quantities indicated in the Indent, edit the
            corresponding received quantities before fulfilling the Indent.</div>

        <div class="row mt-3">
            <div class="col-xl-6 mb-3">
                <div class="card shadow-sm h-100">
                    <div class="card-header p-2">
                        Issuing facility
                    </div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <a>OM Test DVS01</a>
                                <div>Dehradun, Uttarakhand</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 mb-3">
                <div class="card mb-4 shadow-sm h-100">
                    <div class="card-header p-2">
                        Receiving facility
                    </div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <a>OM Test DVS01</a>
                                <div>Dehradun, Uttarakhand</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border mt-3">
            <div class="card-header p-2">
                <h6 class="modal-title">Item</h6>
            </div>
            <div class="card-body p-2">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">Item no.</th>
                                <th scope="col">Material</th>
                                <th scope="col" class="text-center">Shipped</th>
                                <th scope="col" class="text-center">Received</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">1</td>
                                <td><a>BCG (dose)</a></td>
                                <td class="text-center">20</td>
                                <td class="text-center">

                                    <div class="table-responsive table_spacing mt-3">
                                        <table
                                            class="table table-striped table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Batch</th>
                                                    <th class="text-center">Expiry date</th>
                                                    <th class="text-center">Shipped</th>
                                                    <th class="text-center">Received</th>
                                                    <th class="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center">HUY675</td>
                                                    <td class="text-center">30/11/2021</td>
                                                    <td class="text-center">100</td>
                                                    <td class="text-center">100</td>
                                                    <td><a data-bs-toggle="modal"
                                                            data-bs-target="#edit_modal_popup">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </td>

                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td><a>BCG diluent (dose)</a></td>
                                <td class="text-center">20</td>
                                <td class="text-center">

                                    <div class="table-responsive table_spacing mt-3">
                                        <table
                                            class="table table-striped table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Batch</th>
                                                    <th class="text-center">Expiry date</th>
                                                    <th class="text-center">Shipped</th>
                                                    <th class="text-center">Received</th>
                                                    <th class="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="text-center">HUY675</td>
                                                    <td class="text-center">30/11/2021</td>
                                                    <td class="text-center">100</td>
                                                    <td class="text-center">100</td>
                                                    <td><a data-bs-toggle="modal"
                                                            data-bs-target="#edit_modal_popup">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <div class="card border mt-3">
            <div class="card-body p-2">
                <div class="row">
                    <div class="col-xl-12">
                        <form>
                            <div class="form-row mb-2">
                                <div class="col-sm-3 mb-2">
                                    <label for="inputEmail4" class="col-form-label">Date of actual receipt
                                        <span class="text-danger">*</span>
                                    </label>
                                    <input type="text" class="form-control form-control-sm"
                                        placeholder="dd/mm/yyy" />
                                </div>
                                <div class="col-xl-3 mb-2">
                                    <label for="inputEmail3" class="col-form-label">Comment</label>
                                    <textarea class="form-control form-control-sm"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary me-2">Fullfill</button>
            <button type="button" class="btn btn-sm btn-light">Cancel</button>
        </div>
    </div>
</div>
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#edit_modal_popup">
    Launch static backdrop modal
</button> -->
<div class="modal fade" id="edit_modal_popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Batches of BCG (dose)</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table-responsive table_spacing mt-3">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">Batch ID <span class="text-danger">*</span></th>
                                <th class="text-center">Manufactured date (dd/mm/yyyy)</th>
                                <th class="text-center">Manufacturer <span class="text-danger">*</span></th>
                                <th class="text-center">Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">KK123</td>
                                <td class="text-center">08/02/2021</td>
                                <td class="text-center">Biological. E Ltd</td>
                                <td class="text-center">03/05/2022</td>
                                <td>
                                    <div class="form-row mb-2">
                                        <div class="col-sm-3 mb-2">
                                            <input type="text" class="form-control form-control-sm" placeholder="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <button type="button" class="btn btn-sm btn-primary">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div>