import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-ship-create-mdl',
  templateUrl: './ship-create-mdl.component.html',
  styleUrls: ['./ship-create-mdl.component.css']
})

export class ShipCreateMdlComponent extends BaseComponent implements OnInit {

  @Input() orderStatusData: any; @Input() estmdArvlDt: any; isSbmtd = false; carrierLst: any[] = []; slctTransType: any;
  shipCreateForm: FormGroup = new FormGroup({}); dtToDay = new Date(); isChkd = false; carrierAddr = ''; trnsPrtrFlg = false;
  issRefFlag = false; arrvDatFlag = false;  cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData')); isReqLL = '0';
  packageTypeId: any = ''; packageDetails: any[] = []; lstPkgeTyp: any[] = [];

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , public dtPipe: DatePipe
    , public actMdl: NgbActiveModal) {
    super(glblSvc);
    this.createShipForm(), this.LoadPkgeTyp(), this.glblSvc.sbjctMstrData.subscribe(s => { this.carrierLst = s.carrierLst});
    if (window.location.href.includes('prchs-ord-dtls'))
      this.glblSvc.sbjctOrdSmry.subscribe(s => { console.log(s), this.orderStatusData = s });
  }

  ngOnInit(): void {
    ['isIndntAPICald', 'isRelsAPICald', 'isTrnsfrAPICald'].forEach(e => { localStorage.removeItem(e); });
    this.estmdArvlDt = this.orderStatusData.estimatedDateOfArrival; this.LoadPkgeTyp();
    // if (this.estmdArvlDt) {
    //   let dtEA = this.estmdArvlDt.split('-');
    //   this.estmdArvlDt = new Date(+dtEA[2], +dtEA[1]-1, +dtEA[0]);
    // }
    this.shipCreateForm.get('arrivalDate')?.setValue(this.estmdArvlDt ? new Date(this.estmdArvlDt) : this.dtToDay);
    if (this.orderStatusData)
      this.createShipForm(); // , this.loadCarriers();
    this.glblSvc.sbjctCnfgData.subscribe(s => { this.trnsPrtrFlg = s.cnfgOdrData.general.transporter.transporter_specification_order_mandatory_before_shipping,
    this.issRefFlag = s.cnfgOdrData.mandatory_order_fields.before_shipping_an_order.make_issue_reference_num_mandatory_before_shipping,
    this.arrvDatFlag = s.cnfgOdrData.mandatory_order_fields.before_shipping_an_order.make_estimated_date_of_arrival_mandatory_before_shipping});
    if(this.trnsPrtrFlg) {
      this.shipCreateForm.get('carrierName')?.setValidators([Validators.required]);
    }
    else
      this.shipCreateForm.get('carrierName')?.setValidators(null);
    if(this.issRefFlag) {
      this.shipCreateForm.get('orderReferenceNo')?.setValidators([Validators.required]);
    }
    else
      this.shipCreateForm.get('orderReferenceNo')?.setValidators(null);
    if(this.arrvDatFlag) {
      this.shipCreateForm.get('arrivalDate')?.setValue(this.estmdArvlDt ? new Date(this.estmdArvlDt) : this.dtToDay), this.shipCreateForm.get('arrivalDate')?.setValidators([Validators.required]);
    }
    else
      this.shipCreateForm.get('arrivalDate')?.setValue(''), this.shipCreateForm.get('arrivalDate')?.setValidators(null);      
    this.shipCreateForm.get('carrierName')?.updateValueAndValidity(),this.shipCreateForm.get('orderReferenceNo')?.updateValueAndValidity(),
    this.shipCreateForm.get('arrivalDate')?.updateValueAndValidity();
  }

  clearBSDate(ctrl: string) {
    this.shipCreateForm.get(ctrl)?.setValue('');
  }

  onActn(c: string, i: number = 0) {
      if ( c == 'a') {
        if (this.packageTypeId !='') {
          if( this.packageDetails.filter( f =>f.packageTypeId == this.packageTypeId).length == 0) { //  && f.packageNo == +this.fc.packageNo.value
            this.packageDetails.push({ 
              packageName: (this.packageTypeId != '' && this.packageTypeId  !=null) ? this.lstPkgeTyp.filter(f => f.id == +this.packageTypeId)[0]?.packageName : '',
              packageTypeId: this.packageTypeId ?? '', quantity: this.fc?.quantity.value ?? 0,
            }); 
            this.packageTypeId = '', this.shipCreateForm.get('quantity')?.setValue(null);
            } else
              this.glblSvc.onTstr('w', this.fc.quantity.value == 0 ?'Number of Packages greater than Zero' : 'Record already exists');
        } else {
          this.glblSvc.onTstr('w', 'Please Select Package Type')
        }
      } else {
        this.packageDetails.splice(i, 1);
      }
  }

  LoadPkgeTyp() {
    this.lstPkgeTyp = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrPkge, environment.apiMthds.getAll,
      null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstPkgeTyp = this.result?.data ?? [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }
  
  createShipForm() {
    this.shipCreateForm = this.formBuilder.group({ arrivalDate: [], bookingId: [this.orderStatusData?.bookingId],
      cargoNo: [`${this.orderStatusData?.bookingId}` + '-1'], carrierAddress: [null], carrierName: [''], carrierType: [null], height: [],
      createdBy: [this.usrLgnDtls.userId], breadth: [], quantity: [null], packageQuantity: [], length: [],
      customerDelivery: [false], driverPhone: ['', Validators.pattern("^(\([0-9]{3}\) |[6-9]{1})[0-9]{9}$")],
      issuingStoreId: [`${this.orderStatusData?.issuingStoreId}`], orderReferenceNo: [`${this.orderStatusData?.reference}`],
      pranthId: [this.chsnDmnId], packageType: [null],
      reason: ["no reason"], remarks: [], sourceType: ['2'], statusId: [], vehicleInfo: [], wtkg: [],
      updatedBy: [this.usrLgnDtls.userId],
    }), this.shipCreateForm.get('orderReferenceNo')?.setValue(this.orderStatusData?.reference);

    if (window.location.href.includes('prchs-ord-dtls')) {
      this.shipCreateForm.patchValue({ bookingId: this.orderStatusData?.indentid, cargoNo: `${this.orderStatusData?.indentid}` + '-1'  })
    }
  }

  loadCarriers() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.carrier, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.carrierLst = this.result.data;
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
  }

  sctTransPName($event: any) {
    for (let i = 0; i < this.carrierLst.length; i++) {
      if (this.carrierLst[i].carrierName == $event.value)
        this.slctTransType = this.carrierLst[i].carrierType;
    }
  }
  
  sctTransPName1(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if(evnt == '' || evnt == undefined) 
      this.slctTransType = null, this.carrierAddr = '', this.shipCreateForm.get('carrierName')?.setValue('');
    else
      if(this.carrierLst.filter(a => a.carrierName == evnt).length != 0)
        for (let i = 0; i < this.carrierLst.length; i++) {
          if (this.carrierLst[i].carrierName == evnt)
            this.slctTransType = this.carrierLst[i].carrierType, this.carrierAddr = this.carrierLst[i].address;
        }
      else
        this.slctTransType = null, this.carrierAddr = '', this.shipCreateForm.get('carrierName')?.setValue('');
  }

  get fc() { return this.shipCreateForm.controls; }

  onChkLL() {
    if (this.packageDetails.length == 0 && this.usrLgnDtls.roleId == 7 && this.usrLgnDtls.roleName == 'Manufacturer') {
      this.glblSvc.onTstr('w', 'Please Select Package Type')
    } else {
      if (this.isReqLL == '1') {
        this.GetLatLon(), setTimeout(() => {
          if (this.usrLat != undefined && this.usrLon != undefined)
            this.createShipment();
          else {
            setTimeout(() => {
              if (this.usrLat == undefined || this.usrLon == undefined)
                this.glblSvc.onTstr('i', 'Kindly provide Location access to Create Shippment..!');  
            }, 2000);
          }
        }, 500);
      }
      else
        this.createShipment();
    }
  }


  createShipment() {
    ['isIndntAPICald', 'isRelsAPICald', 'isTrnsfrAPICald'].forEach(e => { localStorage.removeItem(e); });
    let payLoad = this.shipCreateForm.getRawValue();
    this.checkFormValidations(this.shipCreateForm)
    if (this.shipCreateForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else if (this.shipCreateForm.get('arrivalDate')?.value != '' && !this.isChkd)
      this.glblSvc.onTstr('w', 'Please click on the confirmation checkbox');
    else {
      if (this.shipCreateForm.get('arrivalDate')?.value != null)
        // payLoad.arrivalDate = this.dtPipe.transform((this.shipCreateForm.get('arrivalDate')?.value), 'yyyy-MM-dd') + ' 00:00:00',
          payLoad.arrivalDate = this.shipCreateForm.get('arrivalDate')?.value != "" ? this.glblSvc.setDtFrmt(this.shipCreateForm.get('arrivalDate')?.value, this.dtF.ymd) + this.tmF.zeroTM : null,
          payLoad.breadth = parseInt(payLoad.breadth), payLoad.height = parseInt(payLoad.height), delete payLoad.quantity,
          payLoad.issuingStoreId = parseInt(payLoad.issuingStoreId), payLoad.length = parseInt(payLoad.length),
          payLoad.packageQuantity = parseInt(payLoad.packageQuantity), payLoad.wtkg = parseInt(payLoad.wtkg),
          payLoad.statusId = parseInt(payLoad.statusId),  payLoad.latitude= (this.usrLat != '' && this.usrLat != null && this.usrLat != undefined) ? +this.usrLat : null,
          payLoad.longitude = (this.usrLon != '' && this.usrLon != null && this.usrLon != undefined) ? +this.usrLon : null,
          payLoad.packageDetails = (this.usrLgnDtls.roleId == 7 && this.usrLgnDtls.roleName == 'Manufacturer') ? this.packageDetails : [];

      if (window.location.href.includes('prchs-ord-dtls')) {
        payLoad.carrierTypeId = payLoad.carrierType, payLoad.consigneeBookingId = this.orderStatusData?.conisgneeBookingId,
          payLoad.consigneeNo = this.orderStatusData?.consgineeNo, payLoad.statusId = 7, payLoad.packageCost = '',
          delete payLoad.cargoNo, delete payLoad.carrierType, delete payLoad.pranthId; delete payLoad.quantity,
          payLoad.packageDetails = (this.usrLgnDtls.roleId == 7 && this.usrLgnDtls.roleName == 'Manufacturer') ? this.packageDetails : [];
          this.FetchData(environment.apiCalls.post, environment.apiPaths.prchsShip, environment.apiMthds.create, payLoad, 12).then(() => {
            if (this.result) {
              if (this.result.status == 'OK')
                this.glblSvc.onTstr('s', this.result.message), this.shipCreateForm.reset(), this.actMdl.close('done');
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
      }
      else {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.cargo, environment.apiMthds.create, payLoad).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              this.glblSvc.onTstr('s', this.result.message), this.shipCreateForm.reset(), this.actMdl.close('done');
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      }
    }
  }

}