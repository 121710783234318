import { Component, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-ordr-par',
  templateUrl: './ordr-par.component.html',
  styleUrls: ['./ordr-par.component.css']
})
export class OrdrParComponent extends BaseComponent implements OnInit {

  @Input() cargoId: any;
  shpngNtfcn1 = ''; shpngNtfcn2 = ''; shpngNtfcn3 = ''; shpngNtfcn4 = ''; lstVcine: any = {}; toDay: any = new Date(); boxArvl: any = '';
  coment: any = ''; coment1: any = ''; shipment1 = ''; shipment2 = ''; shipment3 = ''; shipment4 = ''; orderId: any; shipBox: any;
  colntType = ''; tempMntrPrsnt = ''; qntyRcvd = ''; vcneArvl = ''; typ = ''; qntyRcvd1 = ''; vcneArvl1 = ''; lstOthDcm: any = '';
  noOfBoxsVcn: any = ''; noOfVials: any = ''; btchNo: any = ''; noOfBoxsDrpers: any; noOfUnits: any; expDte: any; boxInspctd: any = '';
  elctrncDvc: any = ''; suprvsior: any = ''; steVcneStr: any = ''; sepio: any = ''; lstBtch: any[] = []; noColnt:  any = ''; icePack: any = '';
  dryIce: any = ''; vmm: any = ''; cldCain: any = ''; elctrcDevce: any = ''; othr: any = ''; frmDate: any = ''; dt2day = new Date();
  ordCnsnee: any = localStorage.getItem('sbjctOrdCnsgnee'); ordDtls: any = localStorage.getItem('sbjctOrdDtls'); toDate: any = ''; tme: any = '';
  refNo: any = ''; strTme: any = ''; preAdvceDte: any = ''; shpDate: any = ''; clrAgnt: any = ''; onBEHALF: any = ''; insptnDte: any = '';
  strMngrDte: any = ''; plce: any = ''; inspDte: any = ''; awbNum: any = ''; arprtDsntn: any = ''; fltNo: any = ''; dteEAPN = ''; tmeEAPN = '';
  // tmp45: any = ''; tmp30: any = ''; tmp10: any = ''; tmp5: any = ''; almDte: any = ''; almTme: any = '';
  tmeATA = ''; dteATA = ''; mnftrDte = ''; expiryDte = ''; lotNmb = ''; nmbOfBox = ''; itmQty = ''; mnfrNme = '';
  arvilDtls: any = []; shpngNtfcn5 = '';

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , public actMdl: NgbActiveModal) {
      super(glblSvc);
      this.glblSvc.sbjctOrdId.subscribe((s: any) => { this.orderId = s; });
    }

  ngOnInit(): void {  
    this.LoadVcine(), console.log(this.cargoId)
  }

  LoadVcine() {
    this.lstBtch = [];
    this.lstVcine = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.exprt, environment.apiMthds.dtlsByVargoId.
      replace('ccc', this.cargoId), null, 0).then(() => {
      if (this.result) {
        if (this.result.data)
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstVcine = this.result?.data ?? {};
            this.lstVcine?.batchDetails.forEach((e: any) => {
              this.lstBtch.push({ batchNo: e.batchNo, expDate: e.batchExpiryDate, noOfBoxsVcn: '', noOfVials: '', dilutBtchNo: '', dilutNoOfBoxs: '',
              dilutNoOfUnits: '', dilutexpDte: '', tmp45: '', tmp30: '', tmp10: '', tmp5: '', almDte: '', almTme: '', boxNo: '' })
            });
          }
          else if (this.result.data == null || this.result.returnCode == 0)
            this.glblSvc.onTstr('i', this.result.message);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    })
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onExportExcel(tblId: string){
    var x: any = document.getElementById(tblId)?.innerHTML;
    // x.style.fontFamily = "Impact,Charcoal,sans-serif";
    var link = document.createElement('a');
    link.setAttribute('download', 'par.doc');
    link.setAttribute('href', 'data:' + 'text/doc' + ';charset=utf-8,' + encodeURIComponent(x));
    link.click(); 
  }

  onActn(c: string, i: number = 0) {
    if (this.lotNmb == '' || this.nmbOfBox == '' || this.itmQty == '' || this.mnftrDte == '' || this.expiryDte == '') {
      this.glblSvc.onTstr('w', 'Please Select All the fields');
    } else {
        if ( c == 'a') {
          // if( this.arvilDtls.filter( f =>f.packageTypeId == this.packageTypeId).length == 0) { //  && f.packageNo == +this.fc.packageNo.value
            this.arvilDtls.push({ lotNmb: this.lotNmb, nmbOfBox: this.nmbOfBox, itmQty: this.itmQty, mnftrDte: this.mnftrDte,
              expiryDte: this.expiryDte
            }); 
            this.lotNmb = '', this.nmbOfBox= '', this.itmQty = '', this.mnftrDte = '', this.expiryDte = '';
            // } else
            //   this.glblSvc.onTstr('w', this.fc.quantity.value == 0 ?'Number of Packages greater than Zero' : 'Record already exists');
      } 
    }
  }
}
