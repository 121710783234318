<div class="tab-pane fade show active" id="Shipment_status" role="tabpanel" aria-labelledby="Shipment_status-tab">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="p-2 pt-0">
                <div class="float-start">
                    <h5 class="mb-0">Shipment: {{shipmentStatus?.cargoNo}}&nbsp;&nbsp;
                        <span *ngIf="shipmentStatus?.status!='Cancelled'" class="f-12 badge bg-success">{{shipmentStatus?.status}}</span>
                        <span *ngIf="shipmentStatus?.status=='Cancelled'" class="f-12 badge bg-danger">{{shipmentStatus?.status}}</span>
                    </h5>
                    <div class="mt-0">Issue reference #: {{shipmentStatus?.bookingReferenceNo}}</div>
                    <div class="small">Created on {{shipmentStatus?.createdOn}} by
                        <a>{{shipmentStatus?.createdName}}</a>
                    </div>
                    <div class="small">Last updated on {{shipmentStatus?.updatedOn}} by
                        <a>{{shipmentStatus?.updatedName}}</a>
                    </div>
                </div>
                <div class="float-end">
                    <span data-tooltip="Invoice" class="toggle-data" title="Invoice" (click)="onInvc()"
                        *ngIf="shipmentStatus?.status!='Cancelled'">
                        <i class="fa fa-print" aria-hidden="true"></i></span>&nbsp;&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="shipmentStatus?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-mobile"></i></span>&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="shipmentStatus?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-globe"></i></span>
                    <span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false"
                        aria-controls="multiCollapseExample2" *ngIf="shipmentStatus?.sourceType==2">
                        <span data-tooltip="map view" class="f-14"><i aria-hidden="true" class="fa fa-desktop"></i></span>
                    </span>
                </div>
                <div class="clearfix"></div>
                <div class="card border-0 mt-3 shadow">
                    <div class="card-header p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="float-start pt-1">
                                    <h6>Status</h6>
                                </div>
                                <div class="float-end">
                                    <span>
                                        <a class="btn btn-sm btn-primary pt-1 pb-1" data-bs-toggle="modal"
                                            data-bs-target="#history-status" title="History" (click)="LoadHstry()">
                                            <i class="fa fa-history" aria-hidden="true"></i>
                                        </a>
                                    </span>
                                    <span class="dropdown" *ngIf="shipmentStatus?.status!='Fulfilled'">
                                        <button class="btn btn-primary btn-sm dropdown-toggle ms-2" type="button" *ngIf="shipmentStatus?.status!='Fulfilled' && shipmentStatus?.status != 'Cancelled'"
                                            id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa fa-tasks" aria-hidden="true"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end p-0 btn_drop"
                                            aria-labelledby="dropdownMenuButton1">
                                            <li *ngIf="mmIdDtlsCs">
                                                <a class="dropdown-item" (click)="onCncl()">Cancel</a>
                                            </li>
                                        </ul>
                                    </span>
                                    <span *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsFs">
                                        <a [routerLink]="['/home/full-ship']" *ngIf="shipmentStatus?.status=='Shipped'"
                                            class="btn btn-sm btn-primary pt-1 pb-1 ms-2">Fulfill
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-xl-12">
                                <div class="process-wrapper">
                                    <div id="progress-bar-container">
                                        <div>
                                            <ul>
                                                <li style="animation-timing-function: ease-in;"
                                                    [ngClass]="item.label == 'Cancelled' ? 'inactive' : 'active'"
                                                    *ngFor="let item of shipmentProgress ; let i=index">
                                                    <div [ngStyle]="{ 'color': item.label == 'Cancelled' ? 'red' : '#28a745' }"
                                                        [attr.id]='i' class="step-inner">{{item.label}} </div>
                                                    <div class="status">
                                                        <div>{{item.activityDate| date:'d-M-yyyy h:mm a'}}</div>
                                                        <a style="text-decoration: none;">{{item.createdBy}}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="line">
                                            <div id="line-progress"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="float-end mb-2 mt-2">Processing time: 0.02 hour(s), Delivery lead time:
                            0 hours</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 mb-3">
                        <div class="card shadow-sm h-100">
                            <div class="card-header p-2">
                                Issuing facility
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <a>{{shipmentStatus?.issuingStoreName}}</a>
                                        <div>{{shipmentStatus?.issuingStoreLocation}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 mb-3">
                        <div class="card mb-4 shadow-sm h-100">
                            <div class="card-header p-2">
                                Receiving facility
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <a>{{shipmentStatus?.seceivingStoreName}}</a>
                                        <div>{{shipmentStatus?.issuingStoreLocation}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-3 shadow-sm">
                    <div class="card-header p-2">
                        Consignment
                    </div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-xl-6">
                                        Number of packages
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.noOfPackages}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEc">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        Total weight (kg)
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.totalWeight}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEc">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        Volume (inches)
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.volume}} &nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEc">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-xl-3">
                                        Content declaration
                                    </div>
                                    <div class="col-xl-3">
                                        None&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEc">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-3">
                                        Value
                                    </div>
                                    <div class="col-xl-3">
                                        0&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEc">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mb-4 shadow-sm">
                    <div class="card-header p-2">
                        Tracking details
                    </div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-xl-6">
                                        Tracking #
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.trackingId}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        Estimated date of arrival
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.estimatedDateOfArrival | date: "dd-MM-yyyy"}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        Actual fulfilment date
                                    </div>
                                    <div class="col-xl-6">
                                        &nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6">
                                        Vehicle
                                    </div>
                                    <div class="col-xl-6">
                                        {{shipmentStatus?.vehicle}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-xl-3">
                                        Transporter
                                    </div>
                                    <div class="col-xl-3">
                                        {{shipmentStatus?.transporter}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-3">
                                        Phone number
                                    </div>
                                    <div class="col-xl-3">
                                        {{shipmentStatus?.transporterPhone}}&nbsp;&nbsp;
                                        <a *ngIf="shipmentStatus?.status!='Cancelled' && mmIdDtlsEt">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 mt-3">
                    <div class="card-body p-2">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item"><a class="nav-link active" id="Items-tab" data-bs-toggle="tab"
                                    href="#Items" role="tab" aria-controls="Items" aria-selected="true">Items <span
                                        class="badge rounded-pill bg-secondary">{{lstMats.length}}</span></a></li>
                            <li class="nav-item"><a class="nav-link" id="Comments-tabs" data-bs-toggle="tab"
                                    href="#Comments" role="tab" aria-controls="Comments" aria-selected="false">Comments
                                    <span class="badge rounded-pill bg-secondary">{{lstAllComments.length}}</span></a></li>
                            <li class="nav-item"><a class="nav-link" id="Delivery-requests-tab" data-bs-toggle="tab"
                                    href="#Delivery-requests" role="tab" aria-controls="Delivery-requests"
                                    aria-selected="true">Delivery requests
                                    <span class="badge rounded-pill bg-secondary">0</span></a></li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Items" role="tabpanel"
                                aria-labelledby="Items-tab">
                                <button type="button" class="btn btn-sm btn-primary mt-3"
                                    *ngIf="false">Return <!-- *ngIf="shipmentStatus?.status!='Cancelled'" -->
                                </button>
                                <div class="table-responsive mt-3">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Material</th>
                                                <th scope="col" class="text-center">Ordered</th>
                                                <th scope="col" class="text-center">Shipped</th>
                                                <th scope="col" class="text-center">Fulfilled</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let mat of lstMats; let i= index">
                                                <td>{{mat.productName}}</td>
                                                <td class="text-center">{{mat.bookingQuantity}}</td>
                                                <td class="text-center">{{mat.cargoQuantity}}
                                                    <a *ngIf="mat.enableBatchManagement" (click)="LoadBatches(mat.productId, i); mat.flag=!mat.flag">
                                                        <i class="fa fa-list-alt"></i>
                                                    </a>
                                                    <div class="row" *ngIf="mat.flag">
                                                        <table class="table table-striped table-sm table-bordered">
                                                            <thead>
                                                                <th>Batch</th>
                                                                <th>Expiry date</th>
                                                                <th>Quantity</th>
                                                            </thead>
                                                            <tbody *ngIf="mat.lstB.length!=0">
                                                                <tr *ngFor="let bat of mat.lstB">
                                                                    <td>{{bat.batchNo}}</td>
                                                                    <td>{{bat.batchExpiry | date : "dd-MM-yyyy"}}</td>
                                                                    <td>{{bat.cargoQuantity}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="mat.lstB.length==0">
                                                                <tr>
                                                                    <td colspan="3">{{noRcrdsTxt}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                                <td class="text-center">{{mat.fulfiledQuantity}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Comments" role="tabpanel" aria-labelledby="Comments-tab">
                                <div class="table-responsive">
                                    <table
                                        class="table table-sm table-bordered table_spacing table-small-font p-0 mt-3">
                                        <tbody>                                           
                                            <tr *ngFor="let com of lstAllComments">
                                                <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                        aria-hidden="true"></i></td>
                                                <td><a>{{com.userId}}</a>&nbsp;&nbsp;<small>added a
                                                        message - {{com.createdOnDate | date: "yy/MM/dd hh:mm a"}}</small>
                                                    <div>{{com.userComments}}</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="lstAllComments.length == 0">
                                            <tr colspan="3" class="text-center">
                                                <td>{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-3" *ngIf="lstAllComments.length == 0 || cmntStat">
                                    <div class="col-xl-12">
                                        <label class="form-label" for="comment">Comment</label>
                                        <textarea class="form-control taRS" id="comment" 
                                        [(ngModel)]="comment" placeholder="Add comment"></textarea>
                                    </div>                                                                                
                                    <div class="float-end mt-2">
                                        <button class="btn btn-sm btn-primary"
                                            (click)="saveComments()">Save</button>&nbsp;
                                        <button class="btn btn-sm btn-light" (click)="cancelComment()">Cancel</button>
                                    </div>
                                </div>
                                <div class="mt-2 mb-2" *ngIf="!cmntStat && lstAllComments.length != 0 && mmIdDtlsAc">
                                    <button class="float-end btn btn-sm btn-primary" (click)="cmntStat = true">
                                        Add comment</button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Delivery-requests" role="tabpanel"
                                aria-labelledby="Delivery-requests-tab">
                                <div class="card">
                                    <div class="card-body p-2">
                                        There are no delivery requests for this shipment.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
</div>
<div class="modal" id="history-status">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Status history</h6>
            </div>
            <div class="modal-body">
                <span *ngIf="shipmentHistory?.length != 0">
                    <div class="card-body p-2" *ngFor="let his of shipmentHistory">
                        <i class="fa fa-check-circle"></i>
                        Indent {{his.status}} on {{his.time | date: "d/M/yy hh:mm aa"}} by
                        <a>{{his.user_id}}</a>
                    </div>
                </span>
                <span *ngIf="shipmentHistory?.length == 0">
                    <div class="card-body p-2 text-center">{{noRcrdsTxt}}</div>
                </span>
                <!-- <ul class="list-group">
                    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>
                        Return received on 22/3/21 6:07 PM by Test DVSM
                        <div class="small ms-4">100 received</div>
                    </li>
                    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>
                        Return shipped on 22/3/21 4:29 PM by Test BVSM</li>
                    <li class="list-group-item"><i class="fa fa-check" aria-hidden="true"></i>
                        Return opened on 22/3/21 4:26 PM by Test BVSM</li>
                </ul> -->
            </div>
            <div class="modal-footer card-footer" style="padding: 6px">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="Shipped-tab" data-bs-toggle="tab"
                            href="#Shipped" role="tab" aria-controls="Shipped" aria-selected="true">Shipped</a></li>
                    <li class="nav-item"><a class="nav-link" id="Fulfilled-tab" data-bs-toggle="tab" href="#Fulfilled"
                            role="tab" aria-controls="Fulfilled" aria-selected="true">Fulfilled</a></li>
                </ul>
                <button type="button" class="btn btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Shipped" role="tabpanel"
                                aria-labelledby="Shipped-tab">
                                <div class="table-responsive table_spacing mt-3">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Batch</th>
                                                <th class="text-center">Expiry date</th>
                                                <th class="text-center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">HUY675</td>
                                                <td class="text-center">30/11/2021</td>
                                                <td class="text-center">100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Fulfilled" role="tabpanel" aria-labelledby="Fulfilled-tab">
                                <div class="table_spacing table-responsive">
                                    <table class="table table-striped table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Batch</th>
                                                <th class="text-center">Expiry date</th>
                                                <th class="text-center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">HUY675</td>
                                                <td class="text-center">30/11/2021</td>
                                                <td class="text-center">100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="status_popup">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header m-0 p-3">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="Cancle-tab" data-bs-toggle="tab" href="#Cancle"
                            role="tab" aria-controls="Cancle" aria-selected="true">Cancle</a></li>
                </ul>
            </div>
            <div class="modal-body">
                <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade show active" id="Cancle" role="tabpanel" aria-labelledby="Cancle-tab">
                        <form>
                            <div class="form-row mb-2">
                                <div class="form-group col-xl-3 mb-2">
                                    <label for="textBox">Next status: </label>
                                    <span class="ms-3">Cancelled</span>
                                </div>
                                <div class="form-group col-xl-3 mb-2">
                                    <label for="textBox">Provide reason</label>
                                    <select id="inputState" class="form-select form-select-sm">
                                        <option selected>select reason</option>
                                        <option>High Processing Time</option>
                                        <option>No Longer Required</option>
                                        <option>Others</option>
                                    </select>
                                </div>
                                <div class="col-xl-3 mb-2">
                                    <label for="inputEmail3" class="col-form-label">Comment</label>
                                    <textarea class="form-control form-control-sm"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal">Submit</button>
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ship_popup">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Ship</h6>
            </div>
            <div class="modal-body">
                <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade show active" id="Cancle" role="tabpanel" aria-labelledby="Cancle-tab">
                        <form class="form-row">
                            <div class="form-group col-xl-12">
                                <label for="textBox">Next status: </label>
                                <span class="ms-3">Shipped</span>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Issue reference # <span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="Facility" /></div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1"
                                        value="option1">
                                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2"
                                        value="option2">
                                    <label class="form-check-label" for="inlineCheckbox2">No</label>
                                </div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <div class="col-xl-3">
                                    <label for="inputEmail3" class="col-form-label">Comment</label>
                                    <textarea class="form-control form-control-sm"></textarea>
                                </div>
                            </div>
                            <hr />
                            <h6>Consignment</h6>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Number of packages</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="Number of packages" /></div>

                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail3" class="col-form-label">Total weight (kg)</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="Total weight (kg)" /> </div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail3" class="col-form-label">Volume (inches)</label>
                                <div class="row">
                                    <div class="col-sm-3 me-1">
                                        <input type="text" class="form-control form-control-sm" id=""
                                            placeholder="Length">
                                    </div>
                                    <div class="col-sm-3 me-1">
                                        <input type="text" class="form-control form-control-sm" id=""
                                            placeholder="Width">
                                    </div>
                                    <div class="col-sm-3 me-1">
                                        <input type="text" class="form-control form-control-sm" id=""
                                            placeholder="Height">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail3" class="col-form-label">Value</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="" /> </div>
                            </div>
                            <hr />
                            <h6>Tracking details</h6>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Tracking #</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="" /></div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Estimated date of arrival <span
                                        class="text-danger">*</span></label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="" /></div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Transporter Name</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="Search/Enter Transporter" /></div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Phone number</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="" /></div>
                            </div>
                            <div class="col-sm-12 mb-2">
                                <label for="inputEmail4" class="col-form-label">Vehicle details</label>
                                <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                        placeholder="" /></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary me-2" data-bs-dismiss="modal">Submit</button>
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Ship popup modalpoup end -->
<!-- <div class="tab-pane fade show active" id="Shipment_status" role="tabpanel" aria-labelledby="Shipment_status-tab">
    <div class="row p-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12 p-2 pt-0">
            <div class="float-start">
                <h5 class="mb-0">Fulfil the shipment: 2226202-1 ( Release : <a>2226202</a>)</h5>
            </div>
            <div class="clearfix"></div>
            <hr class="mt-1 mb-1" />
            <div class="small">Please review and confirm the received quantities before fulfilling the order. If the
                received quantities are different from the shipped quantities indicated in the order, edit the
                corresponding received quantities before fulfilling the order.</div>

            <div class="row mt-3">
                <div class="col-xl-6 mb-3">
                    <div class="card shadow-sm h-100">
                        <div class="card-header p-2">
                            Issuing facility
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <a>OM Test DVS01</a>
                                    <div>Dehradun, Uttarakhand</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="card mb-4 shadow-sm h-100">
                        <div class="card-header p-2">
                            Receiving facility
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <a>OM Test DVS01</a>
                                    <div>Dehradun, Uttarakhand</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border mt-3">
                <div class="card-header p-2">
                    <h6 class="modal-title">Item</h6>
                </div>
                <div class="card-body p-2">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">Item no.</th>
                                    <th scope="col">Material</th>
                                    <th scope="col" class="text-center">Shipped</th>
                                    <th scope="col" class="text-center">Received</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-center">1</td>
                                    <td><a>BCG (dose)</a></td>
                                    <td class="text-center">20</td>
                                    <td class="text-center">

                                        <div class="table-responsive mt-2">
                                            <table
                                                class="table table-striped table-sm table_spacing table-small-font p-0"
                                                style="background-color: #24272f !important;;">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Batch</th>
                                                        <th class="text-center">Expiry date</th>
                                                        <th class="text-center">Shipped</th>
                                                        <th class="text-center">Received</th>
                                                        <th class="text-center"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">HUY675</td>
                                                        <td class="text-center">30/11/2021</td>
                                                        <td class="text-center">100</td>
                                                        <td class="text-center">100</td>
                                                        <td><a data-bs-toggle="modal"
                                                                data-bs-target="#edit_modal_popup">
                                                                <i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </td>

                                </tr>
                                <tr>
                                    <td class="text-center">2</td>
                                    <td><a>BCG diluent (dose)</a></td>
                                    <td class="text-center">20</td>
                                    <td class="text-center">

                                        <div class="table-responsive mt-2">
                                            <table
                                                class="table table-striped table-sm table_spacing table-small-font p-0"
                                                style="background-color: #24272f !important;;">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Batch</th>
                                                        <th class="text-center">Expiry date</th>
                                                        <th class="text-center">Shipped</th>
                                                        <th class="text-center">Received</th>
                                                        <th class="text-center"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-center">HUY675</td>
                                                        <td class="text-center">30/11/2021</td>
                                                        <td class="text-center">100</td>
                                                        <td class="text-center">100</td>
                                                        <td><a data-bs-toggle="modal"
                                                                data-bs-target="#edit_modal_popup">
                                                                <i class="fa fa-pencil" aria-hidden="true"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>

            <div class="card border mt-3">
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-row mb-2">
                                    <div class="col-sm-3 mb-2">
                                        <label for="inputEmail4" class="col-form-label">Date of actual receipt
                                            <span class="text-danger">*</span>
                                        </label>
                                        <input type="text" class="form-control form-control-sm"
                                            placeholder="dd/mm/yyy" />
                                    </div>
                                    <div class="col-xl-3 mb-2">
                                        <label for="inputEmail3" class="col-form-label">Comment</label>
                                        <textarea class="form-control form-control-sm"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="float-end">
                <button type="button" class="btn btn-sm btn-primary me-2">Fullfill</button>
                <button type="button" class="btn btn-sm btn-light">Cancel</button>
            </div>
        </div>
    </div>

</div>
<button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#edit_modal_popup">
    Launch static backdrop modal
</button>
<div class="modal fade" id="edit_modal_popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Batches of BCG (dose)</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table-responsive mt-2">
                    <table class="table table-striped table-sm table_spacing table-small-font p-0"
                        style="background-color: #24272f !important;;">
                        <thead>
                            <tr>
                                <th class="text-center">Batch ID <span class="text-danger">*</span></th>
                                <th class="text-center">Manufactured date (dd/mm/yyyy)</th>
                                <th class="text-center">Manufacturer <span class="text-danger">*</span></th>
                                <th class="text-center">Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">KK123</td>
                                <td class="text-center">08/02/2021</td>
                                <td class="text-center">Biological. E Ltd</td>
                                <td class="text-center">03/05/2022</td>
                                <td>
                                    <div class="form-row mb-2">
                                        <div class="col-sm-3 mb-2">
                                            <input type="text" class="form-control form-control-sm" placeholder="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <button type="button" class="btn btn-sm btn-primary">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-sm btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div> -->