import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'evin-domain-create',
  templateUrl: './domain-create.component.html',
  styleUrls: ['./domain-create.component.css']
})

export class DomainCreateComponent extends BaseComponent implements OnInit {

  cntryLst: any[] = []; stsLst: any[] = []; dstrtLst: any[] = []; tmZoneLst: any[] = []; lstUsers: any[] = []; isSbmtd=false;
  lgnUsrName = ''; mnlDomFrm = this.fb.group({ country_id: [431], district: [], time_zone_id: [1], state: [], name: ['', Validators.required], 
  owner: ['', Validators.required], desc: [''], prnthLvl: ['', Validators.required] });

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst, this.tmZoneLst = s.tmZoneLst, this.stsLst = s.statesLst; });
  }

  ngOnInit(): void {
    this.lgnUsrName = this.usrLgnDtls.firstName + ' ' + this.usrLgnDtls.lastName, this.mnlDomFrm.patchValue({owner: this.lgnUsrName});
    setTimeout(() => { this.dmnLocation(), this.onAS() }, 1000);
  }

  onLocChng(evntVal: any, type: string, isFtchd = false) {
    let cid = '', sid = '', did = '';
    if (type == 'country')
      cid = isFtchd ? evntVal : evntVal.target.value, this.mnlDomFrm.get('state')?.
        setValue(null), this.mnlDomFrm.get('district')?.setValue(null);
    else (type == 'state')
    sid = isFtchd ? evntVal : evntVal.target.value, this.mnlDomFrm.get('district')?.setValue(null), this.mnlDomFrm.get('district')?.enable();
    this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.getStates.
      replace('ccc', cid) : (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) : environment.apiMthds.getBlocks.
        replace('ddd', did))), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type === 'country')
                this.stsLst = this.result.data != null ? this.result.data : [];
              this.dstrtLst = this.result.data != null ? this.result.data : [];
              if (type === 'state')
                this.dstrtLst = this.result.data != null ? this.result.data : [];
            }
          }
        });
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null)
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true)]).then(() => {
          this.mnlDomFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.mnlDomFrm.get('state')?.disable(), this.mnlDomFrm.get('district')?.setValue('null');
        });

      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null) {
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)])
        .then(() => {
          this.mnlDomFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.mnlDomFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
            this.mnlDomFrm.get('district')?.disable();
        });
      }

      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null)
        this.mnlDomFrm.get('state')?.setValue('null'), this.mnlDomFrm.get('district')?.setValue('null'), this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onSave() {
    this.checkFormValidations(this.mnlDomFrm);
    if (this.mnlDomFrm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let rawVal = this.mnlDomFrm.getRawValue(), payLoad: any = {}, fnlPL: any = null;
      payLoad.countryId = rawVal.country_id, payLoad.districtId = rawVal.district, payLoad.stateId = rawVal.state, payLoad.name = rawVal.name,
        payLoad.governorNo = this.lgnUsrName, payLoad.description = rawVal.desc, payLoad.pranthLevel = rawVal.prnthLvl, payLoad.timeZone = rawVal.time_zone_id;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.pranth, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message), window.location.reload();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  get fc() { return this.mnlDomFrm.controls; }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null)
      this.mnlDomFrm.get('state')?.enable(), this.mnlDomFrm.get('district')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null)
      this.mnlDomFrm.get('state')?.disable(), this.mnlDomFrm.get('district')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null)
      this.mnlDomFrm.get('state')?.disable(), this.mnlDomFrm.get('district')?.disable();
  }

  onCncl() {
    this.mnlDomFrm.reset(), this.mnlDomFrm.get('country_id')?.setValue([431]),this.mnlDomFrm.get('time_zone_id')?.setValue([1]),
    this.mnlDomFrm.get('prnthLvl')?.setValue('') 
    this.isSbmtd = false, this.mnlDomFrm.patchValue({owner: this.lgnUsrName}), setTimeout(() => { this.dmnLocation(), this.onAS() }, 1000);;
  }


}