<div class="card p-0">
    <div class="card-header border-0 shadow-none p-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link" id="top-General-tab" data-bs-toggle="tab"
                href="#top-General" role="tab" aria-controls="top-General" aria-selected="true"
                (click)="onTabSelection('gnrl')" [ngClass]="paramTab === 'gnrl' ? 'active' : ''">General</a></li>
            <li class="nav-item"><a class="nav-link" id="Event-top-tab" data-bs-toggle="tab" href="#top-Event"
                role="tab" aria-controls="top-Event" aria-selected="false"
                (click)="onTabSelection('evnt')" [ngClass]="paramTab === 'evnt' ? 'active' : ''">Event notifcations</a></li>
            <li class="nav-item" *ngIf="false"><a class="nav-link" id="Bulletin-top-tab" data-bs-toggle="tab" href="#top-Bulletin"
                role="tab" aria-controls="top-Bulletin" aria-selected="false"
                (click)="onTabSelection('bltn')" [ngClass]="paramTab === 'bltn' ? 'active' : ''">Bulletin board (old)</a></li>
            <!-- <li class="nav-item"><a class="nav-link" id="Access-top-tab" data-bs-toggle="tab" href="#top-Access"
                role="tab" aria-controls="top-Access" aria-selected="false"
                (click)="onTabSelection('acslog')" [ngClass]="paramTab === 'acslog' ? 'active' : ''">Access log</a></li> -->
            <li class="nav-item"><a class="nav-link" id="Sent-top-tab" data-bs-toggle="tab" href="#top-Sent" role="tab"
                aria-controls="top-Sent" aria-selected="false"
                (click)="onTabSelection('sms')" [ngClass]="paramTab === 'sms' ? 'active' : ''">Sent Notifications</a></li>
        </ul>
    </div>
    <div class="card-body shadow-none p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="top-General" role="tabpanel" aria-labelledby="top-General-tab"
            [ngClass]="paramTab === 'gnrl' ? 'show active' : '' ">
                <evin-cnfg-notifications-general></evin-cnfg-notifications-general>
            </div>
            <div class="tab-pane fade" id="top-Event" role="tabpanel" aria-labelledby="Event-top-tab"
            [ngClass]="paramTab === 'evnt' ? 'show active' : '' ">
                <evin-cnfg-event-notifications></evin-cnfg-event-notifications>
            </div>
            <div class="tab-pane fade" id="top-Bulletin" role="tabpanel" aria-labelledby="Bulletin-top-tab"
            [ngClass]="paramTab === 'bltn' ? 'show active' : '' " *ngIf="false">
               <evin-cnfg-notifications-bulletin-board></evin-cnfg-notifications-bulletin-board>
            </div>
            <!-- <div class="tab-pane fade" id="top-Access" role="tabpanel" aria-labelledby="Access-top-tab"
                 [ngClass]="paramTab === 'acslog' ? 'show active' : '' ">
               <evin-notifications-access-log></evin-notifications-access-log>
            </div> -->
            <div class="tab-pane fade" id="top-Sent" role="tabpanel" aria-labelledby="Sent-top-tab"
            [ngClass]="paramTab === 'sms' ? 'show active' : '' ">
               <evin-cnfg-sent-notifications></evin-cnfg-sent-notifications>
            </div>
        </div>
    </div>
</div>