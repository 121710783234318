<div class="card p-0">
    <div class="card-header shadow-none p-2 ">
        <div class="h6 float-start">Approval configuration</div>
    </div>
    <div class="card-body p-2 ">
        <form>
            <div class="row mt-2">
                <div class="col-sm-12">
                    <div class="card shadow-sm p-0 mt-0 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Indent approvals</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Specify configuration parameters for Indent approvals, including indents
                                (receipt), indents (issue) and transfer/release Indents. Approvals can be configured by
                                groups of Facilities identified by Facility categories for indents (receipt and issue).</div>
                            <div class="row mt-3">
                                <div>
                                    <div class="nav nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                        aria-orientation="vertical">
                                        <a class="nav-link f-w-500 active" id="top-tab1-tab" data-bs-toggle="tab"
                                            href="#top-tab1" role="tab" aria-controls="top-tab1"
                                            aria-selected="true">Indents (receipt and issue)</a>
                                        <a class="nav-link f-w-500" id="tab2-top-tab" data-bs-toggle="tab"
                                            href="#top-tab2" role="tab" aria-controls="top-tab2"
                                            aria-selected="false">Transfers/Releases</a>
                                    </div>
                                </div>
                                <div>
                                    <div class="tab-content" id="top-tabContent">
                                        <div class="tab-pane fade show active" id="top-tab1" role="tabpanel"
                                            aria-labelledby="top-tab1-tab">
                                            <div class="card mb-3 p-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Indents (receipt and issue)</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">
                                                        Specify Facility categories and enable approvals for indents (receipt or
                                                        issue) for Facilities with these categories. For indents (issue),
                                                        approvals will be enabled at the time of shipping.
                                                    </div>
                                                    <div class="table-responsive mt-4">
                                                        <table class="table table-striped table-sm table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" class="text-center">Item No.</th>
                                                                    <th scope="col" class="text-center">Facility
                                                                        categories
                                                                    </th>
                                                                    <th scope="col" class="text-center">Approvals</th>
                                                                    <th scope="col" class="text-center"></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="text-center">1</td>
                                                                    <td style="width: 40%"><input type="text"
                                                                            placeholder="" class="form-control" id="">
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox" value=""
                                                                                    id="flexCheckDefault">
                                                                                <label class="form-check-label"
                                                                                    for="flexCheckDefault">
                                                                                    Enable approvals for indents
                                                                                    (receipt)
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="checkbox" value=""
                                                                                    id="flexCheckDefault">
                                                                                <label class="form-check-label"
                                                                                    for="flexCheckDefault">
                                                                                    Enable approvals for indents (issue)
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td><i class="fa fa-trash" aria-hidden="true"></i>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                            <tfoot>
                                                                <tr>
                                                                    <td colspan="4">
                                                                        <button type="button"
                                                                            class="btn btn-sm btn-primary">
                                                                            <i class="fa fa-plus"
                                                                                aria-hidden="true"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tfoot>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card p-0 mb-3">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Approval request expiry times</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="row mt-1">
                                                        <div class="col-sm-6">
                                                            <label class="textBox mb-0" for="">Expiry time for indent
                                                                (receipt) approval requests</label>
                                                            <div class="row mt-1">
                                                                <div class="col-sm-4">
                                                                    <input type="text" placeholder=""
                                                                        class="form-control" id="">
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    hours
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <label class="textBox mb-0" for="">Expiry time for indent
                                                                (issue) approval requests</label>
                                                            <div class="row mt-1">
                                                                <div class="col-sm-4">
                                                                    <input type="text" placeholder=""
                                                                        class="form-control" id="">
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    hours
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-end pb-3">
                                                <button type="button" class="btn btn-sm btn-primary">Save</button>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="top-tab2" role="tabpanel"
                                            aria-labelledby="tab2-top-tab">
                                            <div class="card mb-3 p-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Transfers/Releases</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">
                                                        Specify one or more primary or secondary approvers for
                                                        transfer/release Indents. Approval request will be sent to all
                                                        primary approvers first, and on request expiry, it will be sent
                                                        to
                                                        all the secondary approvers, if specified.
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="form-group col-md-3">
                                                            <label for="textBox">Primary approvers</label>
                                                            <input type="text" placeholder="Primary approvers"
                                                                class="form-control" id="State">
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label for="textBox">Secondary approvers</label>
                                                            <input type="text" placeholder="Secondary approvers"
                                                                class="form-control" id="State">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card mb-3 p-0">
                                                <div class="card-header p-2">
                                                    <h6 class="mb-0">Approval request expiry times</h6>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="small">
                                                        Specify one or more primary or secondary approvers for
                                                        transfer/release Indents. Approval request will be sent to all
                                                        primary approvers first, and on request expiry, it will be sent
                                                        to
                                                        all the secondary approvers, if specified.
                                                    </div>
                                                    <div class="row mt-3">
                                                        <div class="form-group col-md-12">
                                                            <label for="textBox">Expiry time for transfer/release Indent
                                                                approval requests</label>
                                                            <div class="row mt-1">
                                                                <div class="col-sm-4">
                                                                    <input type="text" placeholder="" value=""
                                                                        class="form-control" id="">
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    hours
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-end pb-3">
                                                <button type="button" class="btn btn-sm btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>