import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-demand-receiving-facility',
  templateUrl: './demand-receiving-facility.component.html',
  styleUrls: ['./demand-receiving-facility.component.css']
})
export class DemandReceivingFacilityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
