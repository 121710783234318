import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-stock-availability-overview',
  templateUrl: './stock-availability-overview.component.html',
  styleUrls: ['./stock-availability-overview.component.css']
})
export class StockAvailabilityOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
