<form [formGroup]="bulkUploadForm" autocomplete="off">
    <div class="card p-0 border-0">
        <div class="card-body p-4 pt-2 pb-2"  *ngIf="stckMngmntBlkUpld">
            <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="bulkup-top-tab" data-bs-toggle="tab"
                        href="#top-bulkup" role="tab" aria-controls="top-bulkup" aria-selected="false">Bulk upload</a>
                </li>
                <li class="nav-item" *ngIf="isBDShow">
                    <a class="nav-link" id="inv-top-tab2" data-bs-toggle="tab" aria-selected="false"
                        href="#top-inv" role="tab" aria-controls="top-inv">Update Batch Details</a>
                </li>
            </ul>
            <div class="tab-content" id="top-tabContent">
                <div class="tab-pane fade show active" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab">
                    <div class="card mt-3">
                        <div class="card-body p-4">
                            <div class="row">
                                <div class="col-xl-6">
                                    <!-- <div><span><i class="fa fa-upload"
                                            aria-hidden="true"></i></span>&nbsp;&nbsp;<strong>Last
                                        upload:</strong>&nbsp;&nbsp;<a>users_GMSD_Mgr.csv</a></div>
                                <p class="small ms-4">on 14/7/16 1:53 PM by <a>Devdutt</a></p> -->
                                </div>
                                <div class="col-xl-6">
                                    <span class="me-3"  *ngIf = "uploadStatusResult"><strong>Status:</strong>&nbsp;&nbsp;{{uploadStatus}}</span>
                                    <span class="me-3" *ngIf = "uploadResult">
                                        <a (click)="onDwnld()" style="color:#5d5757"><strong>Success:</strong>&nbsp;&nbsp; <span
                                            class="text-success">{{successCount}}</span></a></span>
                                    <span class="me-3" *ngIf = "uploadResult">
                                        <a (click)="onDwnld()" style="color:#5d5757"><strong>Errors:</strong>&nbsp;&nbsp; <span class="text-danger">{{errorCount}} </span></a>
                                        <!-- <a data-bs-toggle="modal" data-bs-target="#modal_error"></a> -->
                                    </span>
                                    <!-- <span class="me-3"><i class="fa fa-refresh" aria-hidden="true"></i></span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div>Select CSV file for Stock Management</div>
                            <div class="row">
                                <div class="btn-group mt-2 col-xl-9 p-2">
                                    <input class="form-control form-control-sm" type="file" aria-label="file example"
                                    name="uploadedFile" formControlName = "uploadedFile" accept=".csv, text/csv"
                                    (change)="onFileSelect($event)"  required="">
                                </div>
                                <div class="btn-group mt-2 col-xl-3 p-2">
                                    <button type="button" class="btn btn-sm btn-primary me-2" (click) = "uploadFile()">Upload</button>
                                    <input type="reset" class="btn btn-sm btn-light">
                                </div>
                                <hr />
                                <h6><span class="border-bottom">Tips for filling data file:</span></h6>
                                <ul>
                                    <li>Review the instructions in the data file header to ensure correctness</li>
                                    <li>Star (*) implies mandatory fields</li>
                                    <li>Values having commas (,) should be quoted (") [NOT required if entering in
                                        Excel]
                                    </li>
                                </ul>
                                <hr />
                                <h6><span class="border-bottom">Instructions</span></h6>
                                <div>Create, modify or delete items in bulk by uploading a file containing relevant data
                                    in
                                    Comma-Separated Value (CSV) format (one item per line). You can download a formatted
                                    CSV
                                    spreadsheet below, review instructions in the header, fill it up with data (one item
                                    per
                                    line), save it as a CSV file, and upload the CSV file.</div>
                                <div class="mt-2">On completion of upload, you can review the status of your upload on
                                    this
                                    page. Bulk import of data will take time, so refresh the status using the 'refresh'
                                    link
                                    after giving sufficient time. Subsequently, verify that the data has been created
                                    correctly, as required.</div>
                                <div class="row mt-3 mb-3">
                                    <a href="{{smTmpltUrl}}"  class="btn btn-sm btn-success col-xl-4 col-sm-6 mb-2 ms-3"><i
                                            class="fa fa-download" aria-hidden="true"></i>&nbsp;&nbsp;Download data file
                                        format for Stock Management</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="top-inv" role="tabpanel" aria-labelledby="inv-top-tab2" *ngIf="isBDShow">
                    <evin-setup-update-batch></evin-setup-update-batch>
                </div>
            </div>
        </div>
    </div>
</form>
<!-- <div class="modal" id="modal_error">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body">
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-sm table_spacing table-small-font p-0 table-bordered"
                        style="min-width: 1100px;">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Character offset</th>
                                <th>Operation</th>
                                <th>Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>1334</td>
                                <td>add</td>
                                <td>
                                    <div class="card p-0">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2 text-danger">
                                                    1
                                                </div>
                                                <div class="col-xl-11 col-sm-10 text-danger">
                                                    User with ID mgrgmsd1 already exists. Cannot add this user again.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2">
                                                    in
                                                </div>
                                                <div class="col-xl-11 col-sm-10">
                                                    a,mgrgmsd2,123456,123456,ROLE_sm,Krishan Kumar,Ahuja,+91
                                                    989,gmsdkarnal.store111@gil.com,IN,en,Asia/Kolkata,,35,,Haryana,,,Karnal,,,,,,,,,,,,,,,,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1463</td>
                                <td>add</td>
                                <td>
                                    <div class="card p-0">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2 text-danger">
                                                    1
                                                </div>
                                                <div class="col-xl-11 col-sm-10 text-danger">
                                                    User with ID mgrgmsd1 already exists. Cannot add this user again.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2">
                                                    in
                                                </div>
                                                <div class="col-xl-11 col-sm-10">
                                                    a,mgrgmsd2,123456,123456,ROLE_sm,Krishan Kumar,Ahuja,+91
                                                    989,gmsdkarnal.store111@gil.com,IN,en,Asia/Kolkata,,35,,Haryana,,,Karnal,,,,,,,,,,,,,,,,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div> -->