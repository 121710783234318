<div [formGroup]="manufutureDtlFrm" autocomplete="off" *ngIf="manufutureDtlFrm">
<div class="card p-3 pt-2 border-0">
    <div class="card-header shadow-sm p-3 pb-1 pt-2">
        <div class="h6 float-start">{{manFctrDetails?.producerName}}</div>
        <!-- <div class="float-end"><i class="fa fa-external-link"></i></div> -->
    </div>
    <div class="card-body p-4 pt-3">
        <div class="float-start">
            <div class="small">
                <p class="mb-0">Created on {{manFctrDetails?.createdOn | date: "dd-MM-yyyy HH:mm:ss"}} by <a>{{manFctrDetails?.createdBy}}</a>,
                    Last updated on {{manFctrDetails?.lastupdated | date: "dd-MM-yyyy HH:mm:ss"}} by <a>{{manFctrDetails?.updatedBy}}</a>
                 </p>
            </div>
        </div>
    </div>
    <div class="card mb-0 border-0">
        <div class="card-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item"><a class="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#profile"
                        role="tab" aria-controls="profile" aria-selected="true">Profile</a></li>
                <li class="nav-item"><a class="nav-link" id="material2-tab" data-bs-toggle="tab" href="#material2"
                        role="tab" aria-controls="material2" aria-selected="true">Material</a></li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="mt-3">
                        <!-- <button type="button" *ngIf="false" class="btn btn-sm btn-light">Disable</button> -->
                        <hr class="pt-0 mt-2" />
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card p-0 mb-3">
                                    <div class="card-header shadow-none p-3 pb-1 pt-2">
                                        <h6 class="mb-0">Manufacturer information</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                            <div class="col-sm-4">Reference ID:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.referenceId}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Description:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.description}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Tags:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.badgeName}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header shadow-none p-3 pb-1 pt-2">
                                        <h6 class="mb-0">Contact information</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                            <div class="col-sm-4">Contact name:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.contactName}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Phone number:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.phoneNumber}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Alternate phone number:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.alternatePhoneNumber}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Email:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.email}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Country:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.countryName}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">State:</div>
                                            <div class="col-sm-8">{{manFctrDetails?.stateName}}</div>
                                        </div>
                                        <div class="float-xl-end pt_2">
                                            <button class="btn btn-primary btn-sm mt-2 m-2" (click)="backToList()">Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="material2" role="tabpanel" aria-labelledby="material2-tab">
                    <div class="form-group col-xl-12  pt-4 mt-2">

                        <ul class="nav nav-tabs" id="myTabs" role="tablist">
                            <li class="nav-item"><a class="nav-link active" id="list-tab" data-bs-toggle="tab" href="#List"
                                    role="tab" aria-controls="list" aria-selected="true" (click)="addTbl=false;slctdDMT=[];lstSlctdMatNames=[];flag=true; listMtrlAdd=[];getList()">List</a></li>
                            <li class="nav-item"><a class="nav-link" id="add-tab" data-bs-toggle="tab" href="#add"
                                    role="tab" aria-controls="add" aria-selected="true" (click)="flag=false;addTbl=true;slctdDMT=[];lstSlctdMatNames=[];listMtrlAdd=[];getList()">Add</a></li>
                        </ul>
                        <!-- <button class="btn btn-primary btn-sm mt-2 m-2" >List</button>
                        <button class="btn btn-primary btn-sm mt-2 m-2" >Add</button> -->
                    </div>
                    <div>
                        <form class="mt-2">
                            <div class="row mb-2">
                                <div class="form-group col-xl-3 mt-2">
                                    <!-- <label for="textBox">Manufacturer tag:</label>
                                    <select id="textBox" class="form-select form-select-sm">
                                        <option value="">All</option>
                                    </select> -->
                                        <!-- <option [value]="it.name" *ngFor="let it of lstMatTags">{{it.name}}</option> -->


                                    <label for="textBox">Material category</label>
                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags"
                                        placeholder="Categories" [(ngModel)] ="slctdDMT" [ngModelOptions]="{standalone: true}"
                                        (onSelect)="onChng( $event, 's')" 
                                        (onSelectAll)="onChng( $event, 'a')"
                                        (onDeSelect)="onChng( $event, 'r')"
                                        (onDeSelectAll)="onChng( $event, 'ds')">
                                    </ng-multiselect-dropdown>
                                </div>
                                <div class="form-group col-xl-3 mt-4"><button class="btn btn-primary btn-sm mt-2 m-2" (click)="crntPage=1;getList()">Search</button></div>
                            </div>
                        </form>
                    </div>
                    <div class="pt-3">
                        <div class="row mt-3">
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <div class="row" *ngIf="listMtrlAdd?.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table
                                    class="table table-sm table_spacing table-small-font p-0 table-striped table-bordered"
                                    style="min-width: 1100px;">
                                    <thead>
                                        <tr>
                                            <th class="text-center" *ngIf="addTbl"><input class="form-check-input" type="checkbox"
                                                    id="checkboxNoLabel" (click)="onSlctAll($event)"></th>
                                            <th class="text-center">Item No.</th>
                                            <th>Material Name</th>
                                            <th>Material category</th>
                                            <th>Batch</th>
    
                                            <!-- <th *ngIf="!addTbl">
                                                <a><i class="fa fa-trash" aria-hidden="true"></i></a>
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let lst of listMtrlAdd | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems }; let i = index ">
                                            <td *ngIf="addTbl" class="text-center"><input class="form-check-input" type="checkbox"
                                                    id="checkboxNoLabel" (change)= "isSelected($event, lst)"  [checked]="lst.flag" value="" aria-label="..."></td>
                                            <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                            <td>
                                              {{lst.productName}}
                                            </td>
                                            <td>{{lst.productBadge}}</td>
                                            <td>{{lst.batchEnabled == 'Enabled' ? 'YES' : 'NO'}}</td>
                                            <!-- <th *ngIf="!addTbl">
                                                <a (click)="deleteMtrl(lst.productId)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                            </th> -->
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr *ngIf="listMtrlAdd?.length == 0">
                                            <td colspan="5" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                                <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="listMtrlAdd?.length!= 0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                                            </pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="float-end">
                        <button *ngIf="addTbl && listMtrlAdd?.length!=0" class="btn btn-primary btn-sm mt-2 m-2" (click)="crntPage=1;saveMtrls()">Add</button>

                        <button *ngIf="addTbl && listMtrlAdd?.length!=0" class="btn btn-primary btn-sm mt-2 m-2" (click)="crntPage=1;Cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>