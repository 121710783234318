import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-gnrl',
  templateUrl: './cnfg-gnrl.component.html',
  styleUrls: ['./cnfg-gnrl.component.css']
})

export class CnfgGnrlComponent extends BaseComponent implements OnInit {

  cnfgGnrlFrm: FormGroup = this.fb.group({ localeDefaults: this.fb.group({ currency_id: [null], country_id: [null], disrict_id: [null],
    language_id: [null], time_zone_id: [null], state: [null], custom_page_header: [null], domain_level: [null] }), support_contacts:
    this.fb.array([]), admin_contacts: this.fb.array([]), domain_photos: this.fb.array([]) }); isLcl = environment.lclURL; gnrlPrmsn = true;
  lstCnts: any[] = []; lstSts: any[] = []; lstDsts: any[] = []; lstTmZns: any[] = []; lstDLs: any[] = []; lstUsers: any[] = [];
  lstUsrOprt: any[] = []; lstUsrMngr: any[] = []; lstUsrAdmn: any[] = []; lstUsrAdmn1: any[] = []; lstUsrAdmn2: any[] = []; isSave = false;
  lstAdmCatgrs: any[] = [{ id: 1, admin_type: 'Primary' }, { id: 2, admin_type: 'Secondary' }]; lstRoles: any[] = []; usrPrmsn: any = null;

  get fc() { return this.cnfgGnrlFrm.controls; }

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    if (this.slctDmnDetails) {
      let prnthLvl: string = this.slctDmnDetails?.pranthLevel ?? '';
      this.lstCnts = this.mstrDta?.cntriesLst?.filter((f: any) => f.id == this.slctDmnDetails?.countryId) ?? [],
        this.lstSts = this.mstrDta?.statesLst?.filter((f: any) => f.id == this.slctDmnDetails?.stateId) ?? [],
        this.lstDsts = this.mstrDta?.dstLst?.filter((f: any) => f.id == this.slctDmnDetails?.districtId) ?? [], this.lstTmZns =
        this.mstrDta?.tmZoneLst?.length > 0 ? this.mstrDta?.tmZoneLst : [], this.lstDLs.push(prnthLvl == '' ? 'National' : prnthLvl),
        this.cnfgGnrlFrm.get('localeDefaults.country_id')?.setValue(this.lstCnts.length > 0 ? this.lstCnts[0].id : null),
        this.cnfgGnrlFrm.get('localeDefaults.state')?.setValue(this.lstSts.length > 0 ? this.lstSts[0].id : null),
        this.cnfgGnrlFrm.get('localeDefaults.disrict_id')?.setValue(this.lstDsts.length > 0 ? this.lstDsts[0].id : null),
        this.cnfgGnrlFrm.get('localeDefaults.time_zone_id')?.setValue(this.lstTmZns.length > 0 ? this.lstTmZns[0].id : null),
        this.cnfgGnrlFrm.get('localeDefaults.domain_level')?.setValue(this.lstDLs[0]);
    }
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_gn_ac') ? (this.gnrlPrmsn = e.action) : '') });
    }
  }

  ngOnInit(): void {
    this.LoadRoles();
  }

  GetCnfgGnrlData() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
        environment.cnfgTypes.general, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            if (Object.keys(this.result?.data?.configJson).length > 0) {
              this.result?.data?.configJson?.support_contacts.forEach((e: any) => {
                this.onSlctUser(e.user_id, 'sc', this.GetSprtCntcts().getRawValue().findIndex(fi => fi.role_id == e.role_id), 'b',
                (e.role_id == 1 ? this.lstUsrOprt : (e.role_id == 2 ? this.lstUsrMngr : this.lstUsrAdmn)), e); }), this.result?.data?.
                configJson?.admin_contacts.forEach((e: any, i: number) => { this.onSlctUser(e.user_id, 'ac', i, 'b', this.lstUsrAdmn); });
            }
          }
        }
      }
    });
  }

  LoadRoles() {
    this.lstRoles = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrRole, environment.apiMthds.getAll +
        environment.apiMthds.getbyModName + 'General', null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            let rleIds: any[] = [];
            this.lstRoles = this.result?.data ? this.result?.data : [], this.lstRoles.forEach(e => { rleIds.push(e.id) });
            if (this.lstRoles.length > 0)
              this.LoadUserbyRole(rleIds);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadUserbyRole(roleIds: any[]) {
    const oprtUsrs = this.glblSvc.getAPI(environment.apiPaths.users, environment.apiMthds.getUsrByPrnthRole + roleIds[0], 7);
    const mngrUsrs = this.glblSvc.getAPI(environment.apiPaths.users, environment.apiMthds.getUsrByPrnthRole + roleIds[1], 7);
    const admnUsrs = this.glblSvc.getAPI(environment.apiPaths.users, environment.apiMthds.getUsrByPrnthRole + roleIds[2], 7);
    forkJoin(oprtUsrs, mngrUsrs, admnUsrs).subscribe({
      next: (res: any) => {
        res.forEach((list: any, indx: number) => {
          if (list.status === 'OK') {
            let usrsLst: any = {
              '0': () => this.lstUsrOprt = list?.data ?? [],
              '1': () => this.lstUsrMngr = list?.data ?? [],
              '2': () => this.lstUsrAdmn = list?.data ?? [],
            }  
            usrsLst[indx]();
          }
        });
      },
      error: err => { console.error('something went wrong ', err); },
      complete: () => {
        [this.lstUsrOprt, this.lstUsrMngr, this.lstUsrAdmn].forEach(e => { this.lstUsers.push(e); }), this.SetFAData(), this.GetCnfgGnrlData();
      },
    });
  }

  SetFAData() {
    let usrAdms: any[] = this.lstUsrAdmn;
    this.lstRoles.forEach(e => { this.GetSprtCntcts().push(this.AssignData('support_contacts', e)); }), this.lstUsrAdmn1 = usrAdms,
      this.lstAdmCatgrs.forEach(e => { this.GetAdmnCntcts().push(this.AssignData('admin_contacts', e)); }), this.lstUsrAdmn2 = usrAdms;
  }

  AssignData(type: string, item: any) {
    if (type == 'support_contacts')
      return this.fb.group({ role_id: item.id, user_id: null, name: '', phone_number: '', email_id: '', roleName: item.name, userName: '' });
    else
      return this.fb.group({ admin_type: item.admin_type, user_id: null, name: '', phone_number: '', email_id: '', userName: '' });
  }

  GetSprtCntcts(): FormArray {
    return this.cnfgGnrlFrm.get('support_contacts') as FormArray;
  }

  GetAdmnCntcts(): FormArray {
    return this.cnfgGnrlFrm.get('admin_contacts') as FormArray;
  }

  onSlctUser(evntVal: any, ctrl: string, indx: number, type: string, usrLst: any[], apiData: any = {}) {
    let usrDtls: any;
    if (type != 'b')
      usrDtls = usrLst.filter(f => f.user_id === (type == 's' ? evntVal.value : evntVal.target.value));
    else
      usrDtls = usrLst.filter(f => f.id === evntVal);
    if (ctrl == 'sc') {
        this.GetSprtCntcts().at(indx)?.get('name')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.f_name + ' ' + usrDtls[0]?.l_name) : ''),
          this.GetSprtCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.mobile ?? '') : ''),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.email ?? '') : ''),
          this.GetSprtCntcts().at(indx)?.get('user_id')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.id ?? '') : null);
      if (usrDtls.length > 0)
          this.GetSprtCntcts().at(indx)?.get('name')?.disable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.disable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.disable(), this.GetSprtCntcts().at(indx)?.get('email_id')?.setValidators(
            Validators.pattern(environment.patterns.email)), this.GetSprtCntcts().at(indx)?.get('email_id')?.updateValueAndValidity();
      else
        this.GetSprtCntcts().at(indx)?.get('name')?.enable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.enable();
      if (type == 'b') {
        this.GetSprtCntcts().at(indx)?.get('userName')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.user_id ?? '') : '');
        if (evntVal == null)
          this.GetSprtCntcts().at(indx)?.get('name')?.setValue(apiData?.name ?? ''), this.GetSprtCntcts().at(indx)?.get('phone_number')?.
            setValue(apiData?.phone_number ?? ''), this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(apiData?.email_id ?? '');
      }
    }
    else {
      this.GetAdmnCntcts().at(indx)?.get('name')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.f_name + ' ' + usrDtls[0]?.l_name) : ''),
        this.GetAdmnCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.mobile ?? '') : ''),
        this.GetAdmnCntcts().at(indx)?.get('email_id')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.email ?? '') : ''),
        this.GetAdmnCntcts().at(indx)?.get('user_id')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.id ?? '') : null);
      if (usrDtls.length > 0)
        this.GetAdmnCntcts().at(indx)?.get('name')?.disable(), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.disable(),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.disable();
      else
        this.GetAdmnCntcts().at(indx)?.get('name')?.enable(), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.enable();
      if (type == 'b')
        this.GetAdmnCntcts().at(indx)?.get('userName')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.user_id ?? '') : '');
    } 
  }

  onSlctUserOld(evntVal: any, ctrl: string, indx: number, type: string, usrLst: any[], apiData: any = {}) {
    let usrDtls: any;
    if (type != 'b')
      usrDtls = usrLst.filter(f => f.user_id === (type == 's' ? evntVal.value : evntVal.target.value));
    else
      usrDtls = usrLst.filter(f => f.id === evntVal);
    if (ctrl == 'sc') {
      if (usrDtls.length > 0)
        this.GetSprtCntcts().at(indx)?.get('name')?.setValue(usrDtls[0]?.f_name + ' ' + usrDtls[0]?.l_name),
          this.GetSprtCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls[0]?.mobile),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(usrDtls[0]?.email),
          this.GetSprtCntcts().at(indx)?.get('user_id')?.setValue(usrDtls[0]?.id),
          this.GetSprtCntcts().at(indx)?.get('name')?.disable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.disable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.disable(), this.GetSprtCntcts().at(indx)?.get('email_id')?.setValidators(
            Validators.pattern(environment.patterns.email)), this.GetSprtCntcts().at(indx)?.get('email_id')?.updateValueAndValidity();
      else
        this.GetSprtCntcts().at(indx)?.get('name')?.setValue(''), this.GetSprtCntcts().at(indx)?.get('phone_number')?.setValue(''),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(''), this.GetSprtCntcts().at(indx)?.get('user_id')?.setValue(null),
          this.GetSprtCntcts().at(indx)?.get('name')?.enable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.enable();
      if (type == 'b') {
        this.GetSprtCntcts().at(indx)?.get('userName')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.user_id ?? '') : '');
        if (evntVal == null)
          this.GetSprtCntcts().at(indx)?.get('name')?.setValue(apiData?.name ?? ''), this.GetSprtCntcts().at(indx)?.get('phone_number')?.
            setValue(apiData?.phone_number ?? ''), this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(apiData?.email_id ?? '');
      }
    }
    else {
      if (usrDtls.length > 0)
        this.GetAdmnCntcts().at(indx)?.get('name')?.setValue(usrDtls[0]?.f_name + ' ' + usrDtls[0]?.l_name),
          this.GetAdmnCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls[0]?.mobile),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.setValue(usrDtls[0]?.email),
          this.GetAdmnCntcts().at(indx)?.get('user_id')?.setValue(usrDtls[0]?.id), this.GetAdmnCntcts().at(indx)?.get('name')?.disable(),
          this.GetAdmnCntcts().at(indx)?.get('phone_number')?.disable(), this.GetAdmnCntcts().at(indx)?.get('email_id')?.disable();
      else
        this.GetAdmnCntcts().at(indx)?.get('name')?.setValue(''), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.setValue(''),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.setValue(''), this.GetAdmnCntcts().at(indx)?.get('user_id')?.setValue(null),
          this.GetAdmnCntcts().at(indx)?.get('name')?.enable(), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.enable();
      if (type == 'b')
        this.GetAdmnCntcts().at(indx)?.get('userName')?.setValue(usrDtls.length > 0 ? (usrDtls[0]?.user_id ?? '') : '');
    } 
  }

  onSave() {
    let payLoad = this.cnfgGnrlFrm.getRawValue(), fnlPL: any = null;
    payLoad.localeDefaults.country_id = this.lstCnts.length > 0 ? +this.lstCnts[0].id : null, payLoad.localeDefaults.currency_id = null,
      payLoad.localeDefaults.disrict_id = this.lstDsts.length > 0 ? +this.lstDsts[0].id : null, payLoad.localeDefaults.language_id = null,
      payLoad.localeDefaults.time_zone_id = this.lstTmZns.length > 0 ? +this.lstTmZns[0].id : null, payLoad.domain_photos = [],
      payLoad.localeDefaults.state = this.lstSts.length > 0 ? +this.lstSts[0].id : null, payLoad.support_contacts.forEach((e: any) => {
        delete e.userName; delete e.roleName; }), payLoad.admin_contacts.forEach((e: any) => { delete e.userName; }), fnlPL = {
        configJson: payLoad, configTypeId: +environment.cnfgTypes.general, updatedBy: this.usrLgnDtls?.userId }, this.FetchData(
        environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, fnlPL).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

}