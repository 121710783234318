import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-system-config',
  templateUrl: './system-config.component.html',
  styleUrls: ['./system-config.component.css']
})

export class SystemConfigComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
  }

}