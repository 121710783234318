import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-cnsin-wise-cnsumton',
  templateUrl: './cnsin-wise-cnsumton.component.html',
  styleUrls: ['./cnsin-wise-cnsumton.component.css']
})
export class CnsinWiseCnsumtonComponent extends BaseComponent implements OnInit {

  pageEntrs = ''; crntPage = 1; isMatBdge = 'badge'; slctdMat: any[] = []; lstSlctdMatName: any = []; lstSlctdMatTags: any[] = [];
  slctdMatName: any[] = []; dt2day = new Date(); lstMatTags: any[] = []; lstAllMats: any[] = []; lstGrid: any[] = []; isSbmtd = false;
  frmDate: any = ''; stsLst: any[] = []; perid = '1'; pageSize = environment.tblPageSize; noRcrdTxt = environment.TblNoRcrds;
  cnsmptnForm: FormGroup = this.fb.group({}); isExprt = false; totalItems: any; 
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
    allowSearchFilter: true };

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product) }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats; });
  }

  ngOnInit(): void {
    this.cnsmptnForm = this.fb.group({
      state: [null], productIds: [[], Validators.required], productTags: [[], Validators.required], isMatBdge: ['badge'], fromDate: [''],
      toDate: [''], range: ['', Validators.required] });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst }), this.onClkRdb('productTags');
  }

  get fc() { return this.cnsmptnForm.controls; }

  onClkRdb(ctrl: string) {
    this.fc?.productTags.setValue([]), this.fc?.productIds.setValue([]); this.lstSlctdMatName = []; this.lstSlctdMatTags = [];
    if (ctrl == 'productTags')
      this.fc?.productIds?.clearValidators(), this.fc?.productTags?.setValidators([Validators.required]), this.lstSlctdMatName = [];
    else
      this.fc?.productTags?.clearValidators(), this.fc?.productIds?.setValidators([Validators.required]);
    this.fc?.productTags?.updateValueAndValidity(), this.fc?.productIds?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  clearBSDate(ctrl: string) {
    this.cnsmptnForm.get(ctrl)?.setValue('');
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadCnsmptnReport();
  }

  LoadCnsmptnReport() {
    let mT: any[] = [], mN: any[] = [], crntPgIndx = this.crntPage - 1, payLoad = this.cnsmptnForm.getRawValue();
    if (this.fc.productIds.value != '' || this.fc.productTags.value != '') {
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.productTags = payLoad.productTags != null ? 
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT; payLoad.productIds = 
      payLoad.productIds != null ? this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN,
      delete payLoad.state, delete payLoad.isMatBdge;
      if (this.perid == '5')
        payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : '', 
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
      else
        delete payLoad.fromDate, delete payLoad.toDate;
        // payLoad.fromDate = '', payLoad.toDate = '';
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.cnsinWiseCnsumton.replace('pageNo',
        `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 4).then(() => { let res = this.result; this.lstGrid = [];
          if (this.result) {
            if (this.result?.data) {
              this.lstGrid = this.result?.data?.consingeWiseCount ?? [], this.totalItems = this.result?.data?.totalCount ?? this.lstGrid.length;
              this.pageEntrs = this.result.data?.totalCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
                }
            else
              this.glblSvc.onTstr('i', res.message);
          }
          else
            this.glblSvc.onTstr('e', res.message);
        });
    }
    else
      this.glblSvc.onTstr('w', 'Please choose Mandatory fields');
  }

  onReset() {
    this.isSbmtd = false, this.lstSlctdMatName = [...[]], this.lstSlctdMatTags = [...[]], this.perid = '1', this.lstGrid = [],
    this.cnsmptnForm.patchValue({ productIds: null }), this.onClkRdb('productTags'), this.cnsmptnForm.get('isMatBdge')?.setValue('badge'),
    this.cnsmptnForm.get('fromDate')?.setValue(''), this.cnsmptnForm.get('toDate')?.setValue(''), this.cnsmptnForm.get('state')?.setValue(null);
  }

  onEE() {
    let payLoad = this.cnsmptnForm.getRawValue(); let mT: any[] = [], mN: any[] = [];
    payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.productTags = payLoad.productTags != null ? 
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT; payLoad.productIds = 
      payLoad.productIds != null ? this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN,
      delete payLoad.state, delete payLoad.isMatBdge, payLoad.email = this.usrLgnDtls.email;
      if (this.perid == '5')
        payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : '', 
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
      else
        delete payLoad.fromDate, delete payLoad.toDate;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.cnsineWseExpt, payLoad, 4).then(() => {
        this.isExprt = false;
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (ctrl == 'c')
      this.lstGrid = [];
    if (type == 's') {
      if (ctrl == 'n') {
        if (this.lstSlctdMatName.filter((f: any) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
      }
      else if (ctrl == 'm') {
        (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'n')
        this.lstSlctdMatName = this.lstSlctdMatName.filter((f: any) => f.id != item.id);
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'n')
        this.lstSlctdMatName = this.lstAllMats;
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'n')
        this.lstSlctdMatName = [];
      else if (ctrl == 'm')
        this.lstSlctdMatTags = [];
    }
  }
}
