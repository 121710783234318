import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any, filter: any, filterMetadata: any): any {
    if (!filter) {
      filterMetadata.count = items.length
      return items;
    }

    if (!Array.isArray(items))
      return items;

    if (filter && Array.isArray(items)) {
      if (items[0]) {
        let filterKeys = Object.keys(items[0]);
        let filteredItems = items.filter(item => {
          return filterKeys.some((keyName) => {
            if (typeof (item[keyName]) == 'object' && keyName != 'hdrs') {
              let keys = (item[keyName] != null || item[keyName] != undefined) ? Object.keys(item[keyName]) : [];
              if (keys.length > 0) {
                return keys.some((key) => {
                  return item[keyName][key]?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase());
                });
              }
            } else
              return item[keyName]?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase());
          });
        });
        filterMetadata.count = filteredItems.length;
        return filteredItems;
      }
    }
  }

}

// if (keyName == 'matData') {
//   if (item[keyName][0]) {
//     let fiKeys = (item[keyName][0] != null || item[keyName][0] != undefined) ? Object.keys(item[keyName][0]) : [];
//     if (fiKeys.length > 0) {
//       let p = item[keyName].filter((arrayItem: any) => {
//         return fiKeys.some((k) => {
//           return arrayItem[k]?.toString().toLocaleLowerCase().includes(filter.toLocaleLowerCase());
//         })
//       })
//       return p;
//     }
//   }
// }
// else 