import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-releases-create',
  templateUrl: './releases-create.component.html',
  styleUrls: ['./releases-create.component.css']
})

export class ReleasesCreateComponent extends BaseComponent implements OnInit {

  lstGMDSStrs: any[] = []; lstLinkedStrs: any[] = []; issFacId: any; recFacId = ''; str = ''; lstAllMats: any[] = []; lstMats: any[] = [];
  rlsCrtFrm: FormGroup; ordrType = '1'; isSbmtd = false; noRcrdsTxt = environment.TblNoRcrds; orderType = '2'; lstTags: any[] = [];
  lstOrdTyps: any[] = []; rsnsLst: any[] = []; facSlctTxt = environment.OrdrFacSlct; dt2Day: Date = new Date(); isRsnMndtry = false;
  lstStrs: any[] = []; issPranth:any; str2 = '';

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    public dtPipe: DatePipe,
    private router: Router) {
    super(glblSvc);
    this.rlsCrtFrm = fb.group({ badgeId: [null], orderTypeId: [3], srchMtrl: [''], comments: [null], arrivalDate: [], updatedOn: [],
      kioskid: [null, Validators.required], srcType: [2], vkioskid: [null, Validators.required], orderReferenceNo: [''], receiptDate: [],
      productBookings: fb.array([]), bookingBadge: [''], confirmEndDate: [], confirmStartDate: [], createdOn: [], isDeleted: [false],
      itemsCount: [100], transferReferenceNo: [null], transactionType: [1] }), glblSvc.sbjctMstrData.subscribe(s => { this.lstOrdTyps =
        s.ordTypeLst, this.rsnsLst = s.rsnsLst.filter((f: any) => f.reasonType == 'New Booking'); }), glblSvc.sbjctBdgsList.subscribe(s => {
        this.lstTags = s.filter(f => f.badgeTypeId == environment.tagTypes.order) }), this.isRsnMndtry = this.rsnsLst.filter(a =>
        a.isReasonMandatory == true).length > 0, glblSvc.sbjctStrList.subscribe(s => { this.usrStrLen = s.length;
      if (this.usrStrLen == 1)
        this.lstGMDSStrs = s, this.rlsCrtFrm.get('kioskid')?.setValue(this.lstGMDSStrs[0].storeName),
          this.changeFn(this.lstGMDSStrs[0].storeName, '1', 'main', 'str1');
      else
        glblSvc.sbjctGMSDStrList.subscribe(s => this.lstGMDSStrs = s);
    });
  }

  ngOnInit(): void { }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  clearBSDate(ctrl: string) {
    this.rlsCrtFrm.get(ctrl)?.setValue('');
  }

  change($event: any) {
    this.ordrType = $event.target.value;
  }

  onEditQty(matItem: any) {
    if (matItem?.get('recommendedQuantity')?.value != matItem?.get('quantity')?.value && this.isRsnMndtry)
      matItem.get('reason')?.setValidators([Validators.required])
    else
      matItem.get('reason')?.setValidators(null)
    matItem.get('reason')?.updateValueAndValidity()
  }

  changeFn(event: any, ordrType: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value); this.str2 = '';
    if (evnt == '' || evnt == undefined)
      [this.str, this.issFacId] = [event.value, ''];
    else {
      if (strType == 'main') {
        if (this.lstGMDSStrs.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstGMDSStrs.length; s++) {
            if (this.lstGMDSStrs[s].storeName == evnt) {
              this.issFacId = this.lstGMDSStrs[s].storeId;
              if (evntType === 'str1')
                this.rlsCrtFrm.get('kioskid')?.setValue(evnt);
            }
          }
        else
          this.issFacId = '', this.recFacId = '', this.rlsCrtFrm.get('kioskid')?.setValue(null);
      }
      else {
        if (this.lstLinkedStrs.filter(a => a.mapped_store_name == evnt).length != 0) {
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].mapped_store_name == event.value)
              this.recFacId = this.lstLinkedStrs[s].mapped_store_id;
          }
        }
        else
          this.recFacId = '';
      }
    }
    this.rlsCrtFrm.get('vkioskid')?.setValue(null), this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0,
      this.linkedPranths(this.issFacId, ordrType);
  }

  onChngRecFclty(event: any, strType: string, evntType: any) {
    let evnt = evntType == 'chng' ? event.target.value : event.value; this.str2 = '';
    if (evnt == '' || evnt == undefined)
      this.recFacId = '';
    else {
      if (strType == 'main') {
        if (this.lstGMDSStrs.filter(a => a.storeName == evnt).length != 0)
          for (let s = 0; s < this.lstGMDSStrs.length; s++) {
            if (this.lstGMDSStrs[s].storeName == event.value)
              this.issFacId = this.lstGMDSStrs[s].storeId;
          }
        else
          this.issFacId = '', this.recFacId = '', this.rlsCrtFrm.get('vkioskid')?.setValue(null);
      }
      else {
        this.str2 = evnt;
        if (this.lstLinkedStrs.filter(a => a.mapped_store_name == evnt).length != 0)
          for (let s = 0; s < this.lstLinkedStrs.length; s++) {
            if (this.lstLinkedStrs[s].mapped_store_name == evnt)
              this.recFacId = this.lstLinkedStrs[s].mapped_store_id;
          }
          else
            this.recFacId = '', this.rlsCrtFrm.get('vkioskid')?.setValue(null);
      }
    }
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0;
    if (this.recFacId != '')
      this.LoadMatsbyStoreId(this.issFacId, this.recFacId);
  }

  linkedPranths(kioskId: any, ordrType: any) {
    if (kioskId) {
      let ordType = (ordrType == 1 ? 'c' : (ordrType == 2 ? 'v' : ''));
      this.lstLinkedStrs = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
          environment.apiMthds.getLinkedStores.replace('pranthId', kioskId).replace('ordType', ordType), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.lstLinkedStrs = this.result.data != null ? this.result.data : [];
            if (this.lstLinkedStrs.length == 1 && ordType == 'c')
              this.rlsCrtFrm.get('vkioskid')?.setValue(this.lstLinkedStrs[0]?.mapped_store_name),
                this.recFacId = this.lstLinkedStrs[0]?.mapped_store_id, this.LoadMatsbyStoreId(this.issFacId, this.recFacId),
                this.str2 = this.lstLinkedStrs[0]?.mapped_store_name;
            if (this.lstLinkedStrs.length == 1 && ordType == 'v')
              this.rlsCrtFrm.get('kioskid')?.setValue(this.lstLinkedStrs[0]?.mapped_store_name), 
                this.issFacId = this.lstLinkedStrs[0]?.mapped_store_id, this.LoadMatsbyStoreId(+this.recFacId, this.issFacId);
          }
        }
      });
    }
  }

  LoadMatsbyStoreId(issFacId: number, recFacId: any) {
    this.lstMats = [], this.lstAllMats = [], this.GetMatrls().controls.length = 0, 
      this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMats.
        replace('sss', `${issFacId}`).replace('rrr', `${recFacId}`).replace(environment.noPgLen, ''), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats,
            this.issPranth = this.lstAllMats[0]?.pranthId;
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  slctdMat($event: any) {
    let matName = $event.value;
    if (matName != '') {
      if (this.lstAllMats.filter(f => f.productName == matName).length > 0) {
        let avlbStk = this.lstAllMats.filter(f => f.productName == matName)[0].availableStock,
        stockIndicator = this.lstAllMats.filter(f => f.productName == matName)[0].stockIndicator;
        this.GetMatRcmnd(this.recFacId, this.lstAllMats.filter(f => f.productName == matName)[0].productId, matName, avlbStk, stockIndicator); // this.issFacId
      }
    }
  }

  onDelMtrls(tabIndex: any, matName: any) {
    this.GetMatrls().removeAt(tabIndex), this.lstMats.push(this.lstAllMats.filter(f => f.productName == matName)[0]),
      this.lstMats.sort((a, b) => a.productId - b.productId);
  }

  GetMatRcmnd(strId: any, matId: any, matName: any, avlStock: any, stockIndicator: any) {
    if (strId && matId && matName) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getRecmndMats.
          replace('sss', strId).replace('mmm', matId), null).then(() => {
        if (this.result) {
          this.lstMats = this.lstMats.filter(f => f.productName != matName),
            this.GetMatrls().push(this.onAddFormCtrls(matName, matId, avlStock, this.result.data.recmndQuty, stockIndicator)),
            this.rlsCrtFrm.get('srchMtrl')?.setValue(''), this.lstMats = this.lstMats.filter(f => f.productName != matName);
        }
      });
    }
  }

  GetMatrls(): FormArray {
    return this.rlsCrtFrm.get('productBookings') as FormArray;
  }

  onAddFormCtrls(matName: string, mId: any, avlStock: any, recVal: any, stockIndicator: any, indx: number = 0) {
    let matData = this.lstAllMats.filter(f => f.productId === mId)[0];
    return this.fb.group({ productId: [mId, [Validators.required]], name: [matName, [Validators.required]], ts: avlStock, others: [''],
      quantity: [recVal, [Validators.required, Validators.minLength(1), Validators.min(1), Validators.maxLength(10),
        Validators.pattern(environment.patterns.numericsby10)]], reason: [''], recommendedQuantity: recVal, modifiedReason: [null],
      intransitStock: [matData.intransitStock], invMin: [matData.invMin], invMax: [matData.invMax], stockIndicator: stockIndicator,
      rcvngStoreMinStock: [matData.rcvngStoreMinStock], rcvngStoreMaxStock: [matData.rcvngStoreMaxStock], rcvngStoreAvlStock: [matData.rcvngStoreAvlStock],
      rcvngStoreIntransitStock: [matData.rcvngStoreIntransitStock],});
  }

  onClrData() {
    $('#Store').val(''), this.str = '', this.lstLinkedStrs = [], this.issFacId = '', this.lstMats = [], this.isSbmtd = false, this.onClrMatData();
  }

  onClrMatData() {
    this.lstMats = [], (<FormArray>this.rlsCrtFrm.get('productBookings')).controls = [], this.rlsCrtFrm.controls.srchMtrl.setValue(''),
      this.rlsCrtFrm.controls.vkioskid.setValue(''); this.rlsCrtFrm.controls.kioskid.setValue('');
    if (this.lstGMDSStrs.length == 1) this.changeFn(this.lstGMDSStrs[0].storeName, '1', 'main', 'str1');
  }

  onCreateRlsOrdr(ordrType: any) {
    this.checkFormValidations(this.rlsCrtFrm), this.checkFormArrayValidations(this.rlsCrtFrm.controls['productBookings']);
    if (this.rlsCrtFrm.invalid || this.rlsCrtFrm.controls['productBookings'].invalid)
      this.glblSvc.onTstr('w', 'Kindly fill all mandatory fields');
    else {
      let payLoad = this.rlsCrtFrm.getRawValue(), arDate = this.rlsCrtFrm.get('arrivalDate')?.value != null ?
        this.dtPipe.transform((this.rlsCrtFrm.get('arrivalDate')?.value), 'yyyy-MM-dd') + ' 00:00:00' : null, notRcmndCnt = 0;
      payLoad.receivingStoreId = ordrType == 1 ? (this.recFacId != '' ? +this.recFacId :null) : (this.issFacId != '' ? +this.issFacId : null), 
        // payLoad.receivingStoreId = this.lstLinkedStrs.filter(f => f.mapped_store_name == this.rlsCrtFrm.controls.vkioskid.value)[0].mapped_store_id,
        payLoad.issuingStoreId = ordrType == 1 ? (this.issFacId != '' ? +this.issFacId :null) : (this.recFacId != '' ? +this.recFacId : null), 
        payLoad.arrivalDate = arDate, payLoad.confirmEndDate = arDate,
        payLoad.confirmStartDate = arDate, payLoad.createdOn = arDate, payLoad.updatedOn = arDate, payLoad.receiptDate = arDate,
        payLoad.productBookings.forEach((e: any) => { delete e.name, delete e.ts, delete e.others, delete e.stockIndicator,
          e.quantity = (e.quantity != null && e.quantity != '') ? +e.quantity : 0, 
          e.recommendedQuantity = (e.recommendedQuantity != null && e.recommendedQuantity != '') ? +e.recommendedQuantity : 0;
          if (e.quantity != e.recommendedQuantity)
            notRcmndCnt++;
        }), delete payLoad.srchMtrl, delete payLoad.kioskid, delete payLoad.vkioskid, 
        payLoad.pranthId = this.issPranth, payLoad.sourceType = '2',
        payLoad.updatedBy = this.usrLgnDtls.userId, 
        payLoad.bookingBadge = (payLoad.bookingBadge != null && payLoad.bookingBadge != '')? +payLoad.bookingBadge : null, 
        payLoad.badgeId = (payLoad.badgeId != null && payLoad.badgeId != '')? +payLoad.badgeId : null,
        payLoad.createdBy = payLoad.updatedBy;
      if (notRcmndCnt > 0) {
        if (confirm(`The entered quantity of ${notRcmndCnt} material(s) do not match the recommended quantity. Do you wish to continue?`))
          this.SaveRlsIndnt(payLoad);
      }
      else
        this.SaveRlsIndnt(payLoad);
    }
  }

  SaveRlsIndnt(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.rlseSave, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.data != null)
        localStorage.removeItem("isRelsAPICald"),
          this.glblSvc.onTstr('s', this.result.message),
            localStorage.setItem('relsCreatePL', JSON.stringify(payLoad)), this.onReset(), localStorage.removeItem('isRelsAPICald'),
            this.glblSvc.sbjctTabsPage.next('rels-list'), this.issPranth = '',
            this.router.navigate(['home/ord-rls'], { queryParams: { tab: 'list', type: 'r' } }); // setTimeout(() => { window.location.reload(); }, 1000);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onReset() {
    this.rlsCrtFrm.reset(), this.rlsCrtFrm.get('orderTypeId')?.setValue(3), this.glblSvc.sbjctOrdId.next(0), this.GetMatrls().controls.length = 0;
    if (this.lstGMDSStrs.length == 1)
      this.changeFn(this.lstGMDSStrs[0].storeName, '1', 'main', 'str1');
  }

}