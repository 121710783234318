import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-ua-by-user',
  templateUrl: './ua-by-user.component.html',
  styleUrls: ['./ua-by-user.component.css']
})

export class UaByUserComponent extends BaseComponent implements OnInit {

  lst = [{
    "label": "Normal",
    "value": "86.94",
    "isSliced": "1"
  }, {
    "label": "Zero Stock",
    "value": "1",
  }, {
    "label": "Min",
    "value": "5",
  }, {
    "label": "Max",
    "value": "7.06"
  }]

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.glblSvc.sbjctSpnr.next(true), Promise.all([this.DoughNutChart(this.lst, 'column2d', 'fcbarInv')]).
      then(() => { this.glblSvc.sbjctSpnr.next(false); })
  }

}