import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';


@Component({
  selector: 'evin-materials-details',
  templateUrl: './materials-details.component.html',
  styleUrls: ['./materials-details.component.css']
})

export class MaterialsDetailsComponent extends BaseComponent implements OnInit {

  matId: any; matDetals: any [] = []; lstMatTags: any [] = []; stores = JSON.parse(<string>sessionStorage.getItem('lgnUsrStrs'));
  slctdStrsLst: any[] = []; StrLst: any[] = []; strName: any; slctdDmndtls: any; unMapedStrs: any[] = []; pageEntrs = '';crntPage = 1;
  noRcrdTxt = environment.TblNoRcrds; pageSize = environment.tblPageSize; totalItems: any; lstGrid: any[] = []; slctStrName ='';

  constructor(public glblSvc: GlobalService, private router: Router) { 
    super(glblSvc);
    this.glblSvc.sbjctUsrId.subscribe(s => {
      if (s)
        this.matId = s;
      else
        router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } })
    })
  }

  ngOnInit(): void {
   this.slctdDmndtls =localStorage.getItem('slctdDmnDtls');
   this.getUnMappedStores();
    // this.StrLst = this.stores;
    this.getMaterial();
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.getUnMappedStores();
  }

  getMaterial() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.editMat.replace('iiiii', this.matId), null, 7).then(()=> {
      if (this.result) {
        if (this.result.status == 'OK') {
           this.matDetals = this.result.data; 
        }
      }
    });
  }

  getUnMappedStores() {
    this.lstGrid=[]; this.slctStrName  != '' ? this.slctStrName : '';
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getUnMappedStrs.replace('rrr', this.matId).replace('sss', this.slctStrName).
    replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), null, 7).then(()=> {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.lstGrid = this.result.data?.storeDetails, this.totalItems = this.result?.data?.totalRecordCount ?? 0,
          this.pageEntrs = this.result.data?.totalRecordCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
        //  this.unMapedStrs?.filter(e => 
        //   this.StrLst.push({name: e.name, id: e.id, pranthId : e.pranthId}));
        }
      }
    });
  }

  onCancel() {
    this.router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } });
  }
  // onSlct($event: any) {
  //   if ($event.value)
  //     this.slctdStrsLst.push($event.item), this.strName = '', this.StrLst = this.StrLst.filter(f => f.id != $event.item.id);
  // }
  isSelected($event: any, str: any) {
    if (this.slctdStrsLst.filter(f => f.id == str.id).length == 0)
        this.slctdStrsLst.push({ id: str.id, name: str.name, pranthId: str.pranthId });
      else
        this.slctdStrsLst = this.slctdStrsLst.filter(f => f.id != str.id);
    if ($event.target.checked) {
      str.flag = true;
    }
    else
      str.flag = false;
  }
 
  onSlctAll($event: any) {
    this.slctdStrsLst = [];
    if ($event.target.checked)
      this.lstGrid.filter(a => { a.flag = true, this.slctdStrsLst.push({ id: a.id, name: a.name, pranthId: a.pranthId })})
    else
      this.lstGrid.filter(a => { a.flag = false });
  }

  // onRmveStr(strDtls: any) {
  //   this.slctdStrsLst = this.slctdStrsLst.filter(f => f.storeId != strDtls.storeId), this.StrLst.push(strDtls);
  // }

  onSubmit(){
   let strs:any[]=[]; this.slctdStrsLst.filter((s: any) =>
    strs.push({storeId: s.id, pranthId: s.pranthId, minStock: s.minStock ?? null, maxStock: s.maxStock ?? null, inTransitStock:0, daysOfStock: 0, currentStock: 0, totalStock: 0}));
    let payLoad = {
      productId: this.matId, store: strs
    }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.saveStrsForMtrl, payLoad, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.message === "Record saved successfully")
            this.glblSvc.onTstr('s', this.result.message), this.getUnMappedStores();
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });

  }

}
