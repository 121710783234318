<form [formGroup]="setupAssetsViewForm" autocomplete="off"> 
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Serial Number</label>
                            <input type="text" placeholder="Filter by Serial Number" class="form-control form-control-sm"
                                formControlName="serialNumber" (change)="onChngSNO()" onkeypress="return event.charCode != 32" 
                                maxlength="30">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Type</label>
                            <!-- <select id="inputState" class="form-select form-select-sm" formControlName="asetType">
                                <option value="" selected>All</option>
                                <option *ngFor="let item of astTypLst" value="{{item.id}}">{{item.name}}</option>
                            </select> -->
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"                            
                                formControlName="asetType"             
                                (onSelect)="onChng('asetType', $event, 'os')"
                                (onSelectAll)="onChng('asetType', $event, 'osa')"
                                (onDeSelect)="onChng('asetType', $event, 'ds')"
                                (onDeSelectAll)="onChng('asetType', $event, 'dsa')">
                                <!-- (onSelect)="onItemSelect('asetType', $event)" (onSelectAll)="onSelectAll('asetType', $event)"  -->
                            </ng-multiselect-dropdown>
                        </div>

                        <div class="form-group col-xl-3" *ngIf="enbleField">
                            <label for="textBox">Manufacturer</label>
                            <select id="manufacturer" class="form-select form-select-sm"
                            (change)="onChng('manufacturer', $event,  'mf')"  formControlName="manufacturer"
                                [attr.disabled]="(fc.asetType.value == '' || fc.asetType.value == null) ? true : null">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of manufacturersLst" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <!-- (change)="onNestedSlct($event, 'manufacturer')" -->
                        </div>
                        <div class="form-group col-xl-3" *ngIf="enbleField">
                            <label for="textBox">Asset model</label>
                            <select id="assetModelId" class="form-select form-select-sm" formControlName="assetModelId"
                            (change)="onChng('astModel', $event,  'ast')"
                                [attr.disabled]="(fc.asetType.value == '' || fc.asetType.value == null ||
                                fc.manufacturer.value == '' || fc.manufacturer.value == null) ? true : null">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of modelsLst" value="{{item.id}}">{{item.modelName}}</option>
                            </select>
                        </div>
                        <!-- (change)="onNestedSlct($event, 'astModel')" -->

                        <!-- <div class="form-group col-xl-3 mt-2" *ngIf="macEnabled">
                            <label for="textBox">Mac Address</label>
                            <input type="text" placeholder="Mac Address" formControlName="macAddress"
                                class="form-control form-control-sm text-uppercase" id="macAddress"
                               >
                        </div> -->
                        
                    </div>
                    
                </div>
                <div class="col-xl-6"></div>
                <div class="col-xl-6 mb-2" *ngIf="cceList">
                    <div class="float-xl-end pt_2">
                        <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary" (click)="crntPage=1;LoadAssets()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="assetsLst?.length!=0">
                <div class="col-xl-12 mb-2">
                    <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 text-start" *ngIf="assetsLst?.length!=0">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-4 text-center">
                    <!-- <a (click)="deleteAsset('', 'a')" *ngIf="isDelSa=='1' && astIds.length!=0">
                        <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
                    </a> -->
                    <button type="button" class="ms-2 btn btn-sm btn-primary" (click)="onRetrvData()" *ngIf="isDelSa=='1' && astIds.length!=0">
                        <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i> Add </button>
                </div>
                <div class="col-xl-4" *ngIf="assetsLst?.length!=0">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-striped table-sm table_spacing table-small-font p-0 horizontal-scroll scroll-demo table-bordered"
                    id="table_detail">
                    <thead>
                        <tr>
                            <th *ngIf="assetsLst?.length!=0 && isDelSa =='1'"> <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                (click)="onSlctAll($event)"></th>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Asset</th>
                            <th class="text-center">Asset type</th>
                            <th class="text-center">Facility</th>
                            <th class="text-center">Last updated</th>
                            <th class="text-center" *ngIf="(cceEdit || cceDlt) && isDelSa !='1'"></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr *ngFor="let item of assetsLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                                let i=index">
                            <td class="text-center" scope="row" *ngIf="isDelSa =='1'"><input class="form-check-input" type="checkbox" id="flexCheckDefault12"
                                value="" aria-label="..." (change)= "isSelected($event, item)" [checked]="item.flag"></td>
                            <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                            <td><a (click)="onClick(item.assetId)">{{item.serialNumber}}</a> - {{item.modelName}}
                                ({{item.assetVendorName}})</td>
                            <td><a>{{item.assetTypeName}}</a></td>
                            <td><a>{{item.storeName}}</a>
                                <div class="small">{{item.districtName}}, {{item.stateName}}, {{item.city}},
                                    {{item.country}}</div>
                            </td>
                            <td><a>{{item.lastUpdatedByName}}</a>
                                <div class="small">{{item.lastUpdatedOn | date: "dd-MM-yy"}}</div>
                            </td>
                            <td class="d-flex flext-content-between" *ngIf="isDelSa !='1'">                                
                                <a *ngIf="cceEdit" class="btn btn-link text-dark" (click) = "editAsset(item)">
                                    <i class="fa fa-pencil"></i>
                                </a> 
                                <a *ngIf="cceDlt" class="btn btn-link text-danger" (click) = "deleteAsset(item)">
                                    <i class="fa fa-trash-o"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="assetsLst.length == 0">
                        <tr>
                            <td colspan="6" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3">
                <div class="col-xl-6 text-start" *ngIf="assetsLst?.length!=0">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6" *ngIf="assetsLst?.length!=0">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>