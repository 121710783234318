<form [formGroup]="cnsmptnForm">
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-2 p-4">
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-md-3">
                        <strong class="col-form-label">Material By<span class="text-danger"><sup>*</sup></span></strong>
                        <div class="form-check form-check-inline mt-2 p-l-25">
                            <input class="form-check-input" type="radio" id="rbMat1" name="isMatBdge" value="badge"
                                [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" formControlName="isMatBdge"
                                (click)="onClkRdb('productTags')">
                            <label class="form-check-label" for="mat">Tag</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbMat2" name="isMatBdge" value="name"
                                [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" formControlName="isMatBdge"
                                (click)="onClkRdb('productIds')">
                            <label class="form-check-label" for="mat">Name</label>
                        </div>
                        <div *ngIf="isMatBdge=='badge'">
                            <ng-multiselect-dropdown
                                [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                [data]="lstMatTags" formControlName="productTags"
                                (onSelect)="onMultiSlct('s', 'm', $event)"
                                (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.productTags.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.productTags.errors" class="invalid-feedback">
                                <div *ngIf="fc.productTags.errors?.required">Material tag is required</div>
                            </div>
                        </div>
                        <div *ngIf="isMatBdge=='name'">
                             <ng-multiselect-dropdown [settings]="dropdownSettings"
                                 [data]="lstAllMats" formControlName="productIds"                                                                        
                                 (onSelect)="onMultiSlct('s', 'n', $event)"
                                 (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                 (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                 (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                 [ngClass]="{ 'is-invalid': isSbmtd && fc.productIds.errors }"
                                 [(ngModel)]="slctdMatName">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.productIds.errors" class="invalid-feedback">
                                <div *ngIf="fc.productIds.errors?.required">Material name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <strong class="col-form-label">Period</strong><span class="text-danger"><sup>*</sup></span>&nbsp;&nbsp;
                        <select formControlName="range" class="form-select form-select-sm" [(ngModel)]="perid">
                            <!-- [ngClass]="{ 'is-invalid': isSbmtd && fc.range.errors }" -->
                            <option value="1">Last 3 Months</option>
                            <option value="2">Last 6 Months</option>
                            <option value="3">Last 9 Months</option>
                            <option value="4">Last 1 Year</option>
                            <option value="5">Custom Period</option>
                        </select>
                        <!-- <div *ngIf="isSbmtd && fc.range.errors" class="invalid-feedback">
                            <div *ngIf="fc.range.errors?.required">Period is required</div>
                        </div> -->
                    </div>
                    <div class="form-group col-md-3 pt-3" *ngIf="perid == '5'">
                        <strong>From</strong>
                        <div class="input-group">
                            <!-- [dateCustomClasses]="cstmDt"  -->
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                (bsValueChange)="onChngDT($event)"
                                onkeypress='return event.charCode == 8' placeholder="From" readonly
                                formControlName="fromDate" [(ngModel)]="frmDate" [maxDate]="dt2day">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                    [attr.aria-expanded]="dpFD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpFD.hide();clearBSDate('fromDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3 pt-3" *ngIf="perid == '5'">
                        <strong>To</strong>
                        <div class="input-group">
                            <!-- [dateCustomClasses]="cstmDt"  -->
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                (bsValueChange)="onChngDtT($event)" onkeypress='return event.charCode == 8'
                                formControlName="toDate" placeholder="To" [minDate]="frmDate" [maxDate]="dt2day">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                    [attr.aria-expanded]="dpTD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpTD.hide();clearBSDate('toDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3  mt-3">
                        <strong>State</strong>
                        <select formControlName="state" class="form-select form-select-sm" #locState>
                            <option value="null">All</option>
                            <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                        </select>
                    </div>
                    <div class="form-group offset-xl-9 col-md-3 mt-2">
                        <div class="float-xl-end mb-2">
                            <button class="btn btn-sm btn-light me-2" type="button" (click)="crntPage=1;lstGrid=[];isExprt=false;onReset()">Reset</button>
                            <button class="btn btn-sm btn-primary" type="button" 
                                (click)="crntPage=1;lstGrid=[];isSbmtd=true;isExprt=false;LoadCnsmptnReport()">Get report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>    
    </div>
    <div class="row p-0">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4 mb-2">
                            <div class="row">
                                <div class="col-xl-4">
                                    <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive table_spacing">
                        <table class="table table-striped table-sm table-bordered border-primary f-14">
                            <thead>
                                <tr class="text-center">
                                    <th>Item No</th>
                                    <th>Consignee Name</th>
                                    <th>Consignee Tag</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="lstGrid?.length!=0">
                                <tr class="text-center" *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems }; let i=index">
                                    <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                    <td>{{item.storeName}}</td>
                                    <td>{{item.storeTag}}</td>
                                    <td>{{item.count}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstGrid?.length==0">
                                <tr>
                                    <td colspan="4" class="text-center">{{noRcrdTxt}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pb-3 pt-3" >
                        <div class="row" *ngIf="lstGrid?.length!=0">
                            <div class="col-xl-6 text-start">
                                {{pageEntrs}}
                            </div>
                            <div class="col-xl-6">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>