import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { clear } from 'console';
import { resolveAny } from 'dns';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-db-inv-overview',
  templateUrl: './db-inv-overview.component.html',
  styleUrls: ['./db-inv-overview.component.css']
})

export class DbInvOverviewComponent extends BaseComponent implements OnInit {

  lstFacTags: any[] = []; lstMatTags: any[] = []; BadgeName: any; tagName: any; slctMatTags: any; stats: any = ['']; pranthType: any;
  slctdFac: any[] = []; cnfgInvData: any; slctdDefauFacTag: any[] = []; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize;
  stkMangmntFrm = this.fb.group({ status: [null], materialBadge: [null], storeBadges: [null] }); lstGrid: any[] = []; chsnInvFT: any[] = [];
  toDayDate = new Date(); usrLvl = ''; pId: any; isUsrLvlClck: boolean =false;
  ttlItms: any; lstSlctdFacTags: any[] = []; lstSlctdMatTags: any[] = []; noRcrdsTxt = environment.TblNoRcrds; locId: any = ''; statusType: any;
  dropdownSettings = {singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
    allowSearchFilter: true }; cntryId: any = ''; stateId: any = ''; distId: any = ''; isLevl = false; isExprt = false; isSbmtd = false;
  isCcpLvl: any = false; isDstLvl: any = false; isCcpLvlExprt: any = false; isDstLvlExprt: any = false; locName: any = ''; matCatgry: any = [];
  facCatgry: any = []; isBredCrumNatnl: any = ''; isBredCrumState: any = ''; isBredCrumDist: any = ''; isStateId: any;
  clrrange0: any; clrrange1: any; clrrange2: any; clrrange3: any; clrrange4: any; isBackToCountry :boolean = false;
  isBackToState :boolean = true; stsLst: any[] = []; dstLst: any[] = [];

  constructor(public glblSvc: GlobalService, private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility), 
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product)
    });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.dstLst = s.dstLst });
  }

  ngOnInit(): void {
    if (this.usrLgnDtls) {
      this.cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null;      
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '';
    }
    if(this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) 
      this.isBredCrumNatnl = this.locName = this.usrLgnDtls.country, this.isBackToCountry = true;
      if (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null)
        this.stateId = this.slctDmnDetails?.stateId ?? null, this.isBredCrumNatnl = this.locName = this.stsLst.filter((e: any) =>e.id == this.slctDmnDetails?.stateId)[0]?.name, this.isBackToCountry = true;
      if (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null)
        this.distId = this.slctDmnDetails?.districtId ?? null, this.isBredCrumDist = this.locName = this.dstLst.filter((e: any) =>e.id == this.slctDmnDetails?.districtId)[0]?.name, this.isBackToCountry = true;

    this.isLevl = (this.usrLvl == 'National' || this.usrLvl == 'State');  //this.usrLvl == 'District'
    this.glblSvc.sbjctSpnr.next(true), Promise.all([this.matFacList(), setTimeout(() => { this.loadStkMangmnt('a')}, 1000)]).then(() => { setTimeout(() => { this.glblSvc.sbjctSpnr.next(true)}, 1000) });
    // this.glblSvc.sbjctSpnr.next(true), Promise.all([this.matFacList(false), this.loadStkMangmnt(false)]).
    // then(() => { setTimeout(() => { this.glblSvc.sbjctSpnr.next(false); }, 1000) });
  }

  matFacList(){
    let mtRIV = this.lstMatTags.filter(f => f.name == 'RI Vaccines')[0], lstMTR: any[] = [];
    if (mtRIV)
      lstMTR.push(mtRIV),
        this.slctMatTags = lstMTR, lstMTR.forEach(e => { this.lstSlctdMatTags.push({ id: e.id, name: e.name }) }); // , this.matCatgry.push(e.name) 
    this.lstFacTags.forEach(e => { this.lstSlctdFacTags.push({ id: e.id, name: e.name }), this.slctdFac.push(e) }); // , this.facCatgry.push(e.name) 
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }

  }

  loadStkMangmnt(type:string) {    
    if (this.isBredCrumState !=''){ $('#isAct').addClass('active') };
    if ((this.isBackToCountry == true && this.isBackToState == true) || type == 'p') {
        let crntPgIndx = this.crntPage - 1, payLoad = this.stkMangmntFrm.getRawValue(), mT: any[] = [], fT: any[] = [], lstMatsTags: any[] = [],
        lstFacsTags: any[] = []; this.isSbmtd = true; this.lstSlctdMatTags?.forEach((e: any) => {lstMatsTags.push(e.name) });
        payLoad?.status == '' ? (this.statusType = 'Normal', this.clrrange0 = environment.clrCds.green[0],this.clrrange1 = environment.clrCds.green[1],
          this.clrrange2 = environment.clrCds.green[2], this.clrrange3 = environment.clrCds.green[3], this.clrrange4 = environment.clrCds.green[4]):
        (payLoad?.status == '200' ? (this.statusType = 'Zero Stock', this.clrrange0 = environment.clrCds.red[0],this.clrrange1 = environment.clrCds.red[1],
          this.clrrange2 = environment.clrCds.red[2], this.clrrange3 = environment.clrCds.red[3], this.clrrange4 = environment.clrCds.red[4]):
        (payLoad?.status == '201' ? (this.statusType = '< Min', this.clrrange0 = environment.clrCds.yellow[0],this.clrrange1 = environment.clrCds.yellow[1],
          this.clrrange2 = environment.clrCds.yellow[2], this.clrrange3 = environment.clrCds.yellow[3], this.clrrange4 = environment.clrCds.yellow[4]):
        (payLoad?.status == '202' ? (this.statusType = '> Max', this.clrrange0 = environment.clrCds.skyBlue[0],this.clrrange1 = environment.clrCds.skyBlue[1],
          this.clrrange2 = environment.clrCds.skyBlue[2], this.clrrange3 = environment.clrCds.skyBlue[3], this.clrrange4 = environment.clrCds.skyBlue[4]):'')))
        this.matCatgry = lstMatsTags; this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.name) }); this.facCatgry = lstFacsTags;
        // this.statusType = (this.stkMangmntFrm.controls.status.value == '' ? 'Normal' : (this.stkMangmntFrm.controls.status.value == '200' ?
        // 'Zero Stock' : (this.stkMangmntFrm.controls.status.value == '201' ? '< Min' : (this.stkMangmntFrm.controls.status.value == '202' ? '> Max' : ''))));
        payLoad.materialBadge = payLoad.materialBadge != [] && payLoad.materialBadge != null ?
          this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.materialBadge = mT; payLoad.storeBadges =
            payLoad.storeBadges != [] && payLoad.storeBadges != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [],
            payLoad.storeBadges = fT; payLoad.status = (payLoad.status != '' && payLoad.status != null &&
              payLoad.status != undefined) ? +payLoad.status : null; payLoad.countryId = this.slctDmnDetails?.countryId, payLoad.stateId = 
              this.slctDmnDetails?.stateId, payLoad.districtId = this.slctDmnDetails?.districtId, payLoad.pranthHierarchy =
              this.slctDmnDetails;this.lstGrid = [];
      if (this.locId == '')
        ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
          this.slctDmnDetails?.districtId == null) ? (payLoad.locationId = this.slctDmnDetails?.countryId, payLoad.type = 'n') :
          ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) ?
            (payLoad.locationId = this.slctDmnDetails?.stateId, payLoad.type = 's') : (payLoad.locationId = this.slctDmnDetails?.districtId, payLoad.type = 'd'))),

          this.locName = (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) ?
            this.usrLgnDtls.country : this.chsnDmnName, this.isUsrLvlClck = false, this.isBackToCountry = false, this.isBackToState = true,
          (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) ?
            (this.isBredCrumNatnl = this.usrLgnDtls.country) : ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null &&
              this.slctDmnDetails?.districtId == null) ? (this.isBredCrumNatnl = this.chsnDmnName) : this.isBredCrumDist = this.chsnDmnName);
      else if ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) && this.locId != '')
        payLoad.locationId = this.locId, payLoad.type = 'd', this.isBredCrumDist = this.locName, this.isUsrLvlClck = true, this.isCcpLvl = true, this.isCcpLvl = true;
      else if (this.isCcpLvl == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) && this.locId != '')
        payLoad.locationId = this.locId, payLoad.type = 'd', this.isDstLvl = true, this.isDstLvlExprt = false, this.isCcpLvlExprt = true,
          this.isBredCrumDist = this.locName, this.isUsrLvlClck = true, this.isBackToState = true;
      else {
        if (type == 'a' || type == 'p')
          this.isStateId = payLoad.locationId = this.locId, payLoad.type = 's', this.isDstLvlExprt = true, this.isCcpLvlExprt = false, this.isDstLvl = false,
            this.isBredCrumState = this.locName, this.isUsrLvlClck = false, this.isBackToState = false, this.isBackToCountry = true, this.isCcpLvl = true;
        else
          payLoad.locationId = this.isStateId, payLoad.type = 's', this.isDstLvlExprt = true, this.isCcpLvlExprt = false, this.isBredCrumState, this.isDstLvl = false,
            this.isCcpLvl = true, this.isUsrLvlClck = false, this.isBackToState = false, this.isBackToCountry = true;
      }
        this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbStckMangmentView.
          replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
          payLoad, 5).then(() => { this.lstGrid = [];
            if (this.result) {
              if (this.result.status == 'OK') {
                let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.ttlItms = this.result.data?.totalRecordsCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                    this.ttlItms), Object.keys(data.stockReportDetails).forEach((s: any, i: number) => {
                      let sId = data.stockReportDetails[s][0].id, sName = `${s}`.split('||')[0], sBadge = `${s}`.split('||')[1];//, sId = `${s}`.split('||')[1], ccp = this.usrLvl == 'National' || this.usrLvl == 'State' || this.usrLvl == 'District' ? `${s}`.split('||')[3] : '';
                      if (i == 0)
                        data.stockReportDetails[s].forEach((m: any) => { hdrs.push(m.productName); });
                      result.push({ kId: sId, kName: sName, kBadge: sBadge, matData: data.stockReportDetails[s], hdrs: hdrs });
                    });
                  this.lstGrid = result;
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
                else
                  this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid), this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0);
                if (result.length == 0)
                  this.glblSvc.onTstr('i', 'No data found');
              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
         } else {
           if (this.isBredCrumState != '')
              this.isBredCrumDist == '' ? this.isCcpLvl = true : this.isCcpLvl = false;
           if (this.isBredCrumState == '' && this.isBredCrumDist == '')
            this.isCcpLvl = false;
         }
  } 

  onLocDetails(name: string, id: any, e: any) {
    if (this.isUsrLvlClck == false) {
      this.isBackToCountry = true, this.isBackToState = true,// this.isBredCrumState == ''? this.isBackToState = true: this.isBackToState = false ,
        this.isBredCrumDist == '' ? this.isBackToState = true : this.isBackToState = false;
      if (name != '' && this.isLevl == true) {
        this.crntPage=1, this.locName = name, this.locId = id, this.loadStkMangmnt('a'), this.isDstLvlExprt = true;
        if (this.isExprt == true)
          this.locId = id, this.onEE(), this.isExprt = false;
      }
    }
    else  
      e.preventDefault();
  }

  onReset() {
    this.locName = (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) ? this.usrLgnDtls.country : this.chsnDmnName; this.isBackToState = true; this.isBackToState = true;
    this.stkMangmntFrm.get('status')?.setValue(''); this.stats = ''; this.lstSlctdFacTags = [...[]]; this.slctdFac = []; this.slctMatTags = [];
    this.isCcpLvl = false; this.isDstLvl = false; this.isCcpLvlExprt = false; this.isDstLvlExprt =  false;
    this.lstSlctdMatTags = [...[]]; this.matFacList(); setTimeout(() => { this.loadStkMangmnt('a'); }, 1000); this.isBredCrumDist = false; this.isBredCrumState= false;
    //this.stkMangmntFrm.get('storeBadges')?.setValue(null); this.stkMangmntFrm.get('materialBadge')?.setValue(null), this.loadStkMangmnt(); 
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, (this.isBredCrumDist=='' && this.isCcpLvl == true)? this.isCcpLvl = false : this.isCcpLvl = true, this.loadStkMangmnt('p');
  }

  onEE() {
    let payLoad = this.stkMangmntFrm.getRawValue(), mT: any[] = [], fT: any[] = []; //this.isExprt = true;
    payLoad.materialBadge = payLoad.materialBadge != [] && payLoad.materialBadge != null ?
      this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.materialBadge = mT; payLoad.storeBadges =
    payLoad.storeBadges != [] && payLoad.storeBadges != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [],
    payLoad.email = this.usrLgnDtls.email; payLoad.storeBadges = fT; payLoad.status = (payLoad.status != '' && payLoad.status != null &&
    payLoad.status != undefined) ? +payLoad.status : null; payLoad.countryId = this.slctDmnDetails?.countryId, payLoad.stateId = 
      this.slctDmnDetails?.stateId, payLoad.districtId = this.slctDmnDetails?.districtId, payLoad.pranthHierarchy = this.slctDmnDetails;
    if (this.locId == '')
    ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) ? (payLoad.locationId = this.cntryId, payLoad.type = 'n') :
      ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) ?
        (payLoad.locationId = this.stateId, payLoad.type = 's') : (payLoad.locationId = this.distId, payLoad.type = 'd')));
    else if ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) && this.locId != '')
      payLoad.locationId = this.locId, payLoad.type = 'd';
    else if (this.isCcpLvlExprt == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) && this.locId != '')
      payLoad.locationId = this.locId, payLoad.type = 'd', this.isDstLvlExprt = true;
    else if (this.isDstLvlExprt == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) && this.locId != '') 
      payLoad.locationId = this.isStateId, payLoad.type = 's', this.isDstLvlExprt = true;
    else
      payLoad.locationId = this.locId, payLoad.type = 's';
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbStckMangmentExpt, payLoad, 5).then(() => {
      if (this.result) {
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      }
    })
  }
}
