<div class="card p-0">
    <div class="card-header border-0 p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
          <li class="nav-item" ><a class="nav-link active" id="custom-dashboard-tab" data-bs-toggle="tab" href="#custom-dashboard" role="tab" aria-controls="custom-dashboard" aria-selected="true">Custom dashboard</a></li>
          <li class="nav-item"><a class="nav-link" id="create-custom-dashboard-tab" data-bs-toggle="tab" href="#create-custom-dashboard" role="tab" aria-controls="create-custom-dashboard" aria-selected="false">Create Custom dashboard</a></li>
        </ul>
    </div>
    <div class="card-body p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="custom-dashboard" role="tabpanel" aria-labelledby="custom-dashboard-tab">
                <evin-cnfg-custom-dashboard-view></evin-cnfg-custom-dashboard-view>
            </div>
            <div class="tab-pane fade" id="create-custom-dashboard" role="tabpanel" aria-labelledby="create-custom-dashboard-tab">
               <evin-cnfg-create-custom-dashboard></evin-cnfg-create-custom-dashboard>
            </div>
        </div>
    </div>
</div>
