<form [formGroup]="stksRprtsFrm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-4 border-0">
                    <div class="form-row row mb-2">
                        <div class="col-xl-12">
                            <div style="float: right;" *ngIf="isRprtsHlpShw">
                                <a href="btn btn-sm btn-link" [routerLink]="['/stks-rprts/help']" target="_blank"><u>Help</u></a> 
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <strong class="col-form-label">Material By</strong><span class="text-danger"><sup>*</sup></span>
                            <div class="form-check form-check-inline mt-2 p-l-25">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="badge" (click)="onClkRdb('materialBadge')"
                                    [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" formControlName="isMatBdge">
                                <label class="form-check-label" for="mat">Tag</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="isMatBdge" value="name" (click)="onClkRdb('material')"
                                    [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" formControlName="isMatBdge">
                                <label class="form-check-label" for="mat">Name</label>
                            </div>
                            <div *ngIf="isMatBdge=='badge'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMat" [data]="lstMatTags"
                                    formControlName="materialBadge" (onSelect)="onMultiSlct('s', 'n', $event)"
                                    (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.materialBadge.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.materialBadge.errors" class="invalid-feedback">
                                    <div *ngIf="fc.materialBadge.errors?.required">Material tag is required</div>
                                </div>
                            </div>
                            <div *ngIf="isMatBdge=='name'">
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstAllMats" [(ngModel)]="slctdMatName"
                                    formControlName="material" (onSelect)="onMultiSlct('s', 'f', $event)"
                                    (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'f', $event)" [ngClass]="{ 'is-invalid': isSbmtd && fc.material.errors }">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.material.errors" class="invalid-feedback">
                                    <div *ngIf="fc.material.errors?.required">Material name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <strong class="col-form-label">Facility Tag</strong><span class="text-danger"><sup>*</sup></span>&nbsp;&nbsp;
                            <div>
                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                    [data]="lstFacTags" formControlName="storeBadge"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.storeBadge.errors }"                                                                        
                                    (onSelect)="onMultiSlct('s', 'fa', $event)"
                                    (onSelectAll)="onMultiSlct('a', 'fa', $event)"
                                    (onDeSelect)="onMultiSlct('r', 'fa', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'fa', $event)"
                                    [(ngModel)]="slctdFac">
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.storeBadge.errors" class="invalid-feedback">
                                    <div *ngIf="fc.storeBadge.errors?.required">Facility Tag is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-3 pt-3">
                            <strong for="textBox">State</strong>
                            <select formControlName="state" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];lstGrid=[];onLocChng($event, 'state')" #locState>
                                <option value="null">All</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 pt-3">
                            <strong for="textBox">District</strong>
                            <select formControlName="district" class="form-select form-select-sm"
                                (change)="blkLst=[];lstGrid=[];onLocChng($event, 'district')" #locDist>
                                <option value="null">All</option>
                                <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 pt-3">
                            <strong for="textBox">Taluk/Block</strong>
                            <select formControlName="block" class="form-select form-select-sm"
                                (change)="lstGrid=[];LoadCCPs();" #locBlck>
                                <option value="null">All</option>
                                <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 pt-3" *ngIf="usrLvl != 'National'" id="ccpContainer">
                            <strong for="textBox">CCP</strong>
                            <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstCcpAll" [(ngModel)]="slctdccp"
                                formControlName="storeId" (onSelect)="onMultiSlct('s', 'c', $event)"
                                (onSelectAll)="onMultiSlct('a', 'c', $event)" (onDeSelect)="onMultiSlct('r', 'c', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'c', $event)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="form-group col-md-3 pt-3" >
                            <strong for="textBox">Total By</strong>
                            <select class="form-select form-select-sm" formControlName="stockMetric" [(ngModel)]="totlBy">
                                <option value="1">Total without In-transit</option>
                                <option value="2">Total with In-transit</option>
                                <option value="3" >Total and In-transit</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 pt-3" *ngIf="usrLvl == 'National'&& locState.value == 'null'">
                            <strong for="textBox">Order By</strong>
                            <select class="form-select form-select-sm" formControlName="isDistrict" [(ngModel)]="ordBy">
                                <option value='false'>State-wise</option>
                                <option value="true">District-wise</option>
                            </select>
                        </div>
                        <div class="form-group col-md-3 pt-3" *ngIf="usrLvl == 'National' && locState.value != 'null'"></div>
                        <div class="form-group col-md-12 mt-4">
                            <div class="float-md-end">
                                <button (click)="isExprt=false;crntPage=1;lstGrid=[];onReset()" class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;isExprt=false;lstGrid=[];LoadStockReport()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0" >
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4 mb-2">
                            <div class="row">
                                <div class="col-xl-4">
                                    <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="lstGrid.length!=0">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table-multi-columns table-fill mt-2 mb-2 horizontal-scroll scroll-demo p-0 border-0 table_spacing"
                                style="height: auto !important;" *ngIf="lstGrid.length==0">
                                <table class="table table-sm text-center table-small-font table-striped table-bordered align-middle center"
                                    cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%">Item No.</th>
                                            <th style="width: 60%" class="text-center">Facility</th>
                                            <!-- <th style="width: 20%" title="Temperature" class="toggle-data">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                    <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                    <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                </svg>
                                            </th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="5" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap" *ngIf="lstGrid.length!=0">
                                <table class="text-center table-small-font main-table table-bordered center">
                                    <thead>
                                        <tr>
                                            <th colspan="5" class="freeze tbl-hdr-clr p-0">
                                                <table class="w-450p break-word-td">
                                                    <!-- *ngIf="stksRprtsFrm?.get('state')?.value!=null && stksRprtsFrm?.get('state')?.value!='null'
                                                            && lstGrid?.length!=0" -->
                                                    <tr>
                                                        <th style="width: 15%">Item No.</th>
                                                        <th style="width: 25%" class="text-center" *ngIf="usrLvl=='National'">State</th>
                                                        <th style="width: 25%" class="text-center" *ngIf="usrLvl=='National' && ((stksRprtsFrm?.get('state')?.value!=null && 
                                                        stksRprtsFrm?.get('state')?.value!='null' && !isorderBy) || isorderBy) && lstGrid?.length!=0">District</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='State'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='State'">District</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='District'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='District'">District</th>
                                                        <!-- <th style="width: 20%" class="text-center" *ngIf="usrLvl=='Region'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='Region'">District</th> -->
                                                        <th style="width: 20%" class="text-center" *ngIf="((stksRprtsFrm?.get('district')?.value!=null && 
                                                        stksRprtsFrm?.get('district')?.value!='null') || stksRprtsFrm?.get('storeId')?.value.length!=0) && lstGrid?.length!=0">Facility</th>
                                                        <!-- <th style="width: 10%" title="Temperature" class="toggle-data text-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                                <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                            </svg>
                                                        </th> -->
                                                    </tr>
                                                </table>
                                            </th>                                            
                                            <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-0">
                                                <table class="table table-borderless w-100">
                                                    <tr>
                                                        <th colspan="3">{{hdr}}</th>
                                                    </tr>
                                                    <tr class="bg-info">                                                        
                                                        <th style="width:30%;">Total</th>
                                                        <th style="width:30%;" *ngIf="isTotlBy">In Transit</th>
                                                        <th style="width:40%;" *ngIf="stksRprtsFrm?.get('district')?.value!=null && stksRprtsFrm?.get('district')?.value!='null'">
                                                            Updated On</th>
                                                    </tr>
                                                    <!-- <div *ngIf="isTotlBy">(Total/In-transit 
                                                        <div *ngIf="stksRprtsFrm?.get('district')?.value!=null && stksRprtsFrm?.get('district')?.value!='null'">(Total/Updated on)</div>
                                                    <span *ngIf="stksRprtsFrm?.get('district')?.value!=null && stksRprtsFrm?.get('district')?.value!='null'">(Total/Updated on</span>)</div> -->
                                                </table>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid.length!=0">
                                        <tr>
                                            <td colspan="5" class="freeze">
                                                <table class="w-450p">
                                                    <tr>
                                                        <td class="text-center"><b>Total</b></td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td *ngFor="let ttl of lstGrid[0]?.total">
                                                <b>
                                                    <table class="table table-borderless w-100">
                                                        <td>{{ttl.split('||')[0]}}</td>
                                                        <td *ngIf="isTotlBy">{{ttl.split('||')[1]}}</td>
                                                        <td style="width:40%;" *ngIf="stksRprtsFrm?.get('district')?.value!=null && stksRprtsFrm?.get('district')?.value!='null'">&nbsp;</td>
                                                    </table>
                                                </b>
                                            </td>                                            
                                        </tr>
                                        <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms }; let i=index">
                                            <td colspan="5" class="freeze p-0">
                                                <table class="w-450p break-word-td">
                                                    <tr>
                                                        <td style="width: 15%" class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                        <td style="width: 25%" class="text-center f-14" *ngIf="usrLvl=='National'">{{item.kName}}</td>
                                                        <td style="width: 25%" class="text-center f-14" *ngIf="usrLvl=='National' && ((stksRprtsFrm?.get('state')?.value!=null && 
                                                        stksRprtsFrm?.get('state')?.value!='null' && !isorderBy) || isorderBy) && lstGrid?.length!=0">{{item.kAdrs}}</td>
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='State'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='State'">{{item.kAdrs}}</td>
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='District'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='District'">{{item.kAdrs}}</td>
                                                        <!-- <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='Region'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="usrLvl=='Region'">{{item.kAdrs}}</td> -->
                                                        <td style="width: 20%" class="text-center f-14" *ngIf="((stksRprtsFrm?.get('district')?.value!=null && 
                                                        stksRprtsFrm?.get('district')?.value!='null') || stksRprtsFrm?.get('storeId')?.value.length!=0) && lstGrid?.length!=0">{{item.ccp}}</td>
                                                        <!-- <td class="text-center" style="width: 10%"></td> -->
                                                    </tr>
                                                </table>
                                            </td>
                                            <td *ngFor="let col of item.matData" class="p-0">
                                                <table class="table table-borderless w-100 tblMatDta">
                                                    <tr>                                                        
                                                        <td style="width:30%"> {{col.totalStock == null ? 'NA' : col.totalStock}}</td>
                                                        <td style="width:30%;" *ngIf="isTotlBy">{{col.inTransitStock == null ? '0' : col.inTransitStock}}</td>
                                                        <td style="width:40%;" *ngIf="stksRprtsFrm?.get('district')?.value!=null && 
                                                        stksRprtsFrm?.get('district')?.value!='null'">{{col.updatedOn == null ? '0' : col.updatedOn | date: "dd-MM-yyyy hh:mm:ss" }}</td>
                                                    </tr>                                                   
                                                </table>
                                            </td>
                                            <!-- <td *ngFor="let col of item.matData">
                                                {{col.totalStock == null ? 'NA' : col.totalStock}}
                                                 <span *ngIf="isTotlBy"> / {{col.inTransitStock == null ? '0' : col.inTransitStock}}</span>
                                                <span >/ {{col.updatedOn == null ? '0' : col.updatedOn}}</span>
                                            </td> -->
                                            <!-- {{col.currentStock == null ? 'NA' : col.currentStock}} -->
                                            <!-- <td *ngFor="let col of item.matData">{{col.totalStock}}
                                                {{col.totalStock == null ? 'NA' : col.totalStock}}
                                            </td>
                                            <td *ngFor="let col of item.matData"> {{col.inTransitStock == null ? '0' : col.inTransitStock}}
                                                {{col.currentStock == null ? 'NA' : col.currentStock}}
                                            </td>       -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid?.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>