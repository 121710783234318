import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { PrchsIndentStatusComponent } from 'src/app/Components/evin/Orders/prchs-indent/prchs-indent-status/prchs-indent-status.component';

declare let $: any;

@Component({
  selector: 'evin-prchs-indent-summary',
  templateUrl: './prchs-indent-summary.component.html',
  styleUrls: ['./prchs-indent-summary.component.css']
})
export class PrchsIndentSummaryComponent extends BaseComponent implements OnInit {

  noRcrdsTxt = environment.TblNoRcrds; orderId: any; indntDtls: any[] = []; cnsgnQnLst: any[] = []; isRsnAlw = false; cnsgnStrLst: any[] = [];
  ordrStatForm: FormGroup; isEditCnsgn: boolean = false; editCnsgnId: any = ''; edtCreatedBy: any = ''; cnsgnId = ''; dplStrLst: any[] = [];
  ttlStrLSt: any[] = []; cnsgnStr: any = this.usrLgnDtls?.consigneStoreId; isCnsgnPrmsn = this.usrLgnDtls?.userLevelDetails[0]?.userLevel;
  rcmdQntity = 0; ttlQty = 0;

  constructor(public glblSvc: GlobalService,
    private ngbMdl: NgbModal,
    public dtPipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    super(glblSvc);
    this.ordrStatForm = this.formBuilder.group({ consignStrId: [''], consignQntity: [''] });
  }

  ngOnInit(): void {
    this.cnsgnStr = this.cnsgnStr == null ? '' : this.cnsgnStr;
    this.glblSvc.sbjctOrdId.subscribe((s: any) => {
      this.orderId = s;
      if (s > 0)
        this.LoadIndentDetails(this.orderId), this.getConsigmntStr();
      else
        this.router.navigate(['home/prchs-ord'], { queryParams: { tab: 'list' } });
    });
  }

  getConsigmntStr() {
    this.cnsgnStrLst = [], this.dplStrLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, 
      environment.apiMthds.getCnsgnmntStr, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.dplStrLst = this.result.data, this.cnsgnStrLst = this.result.data, localStorage.setItem('cnsnStrLst', JSON.stringify(this.result.data)),
             this.getConsgnLst();
        }
      });
  }

  getConsgnLst() {
    let tq: any[] = [];
    this.cnsgnQnLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsgnBking, environment.apiMthds.getCnsgnPrchsById
      .replace('nnn', this.orderId).replace('sss', this.cnsgnStr), null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.cnsgnQnLst = this.result?.data;
            this.cnsgnQnLst.forEach(a => { tq.push(a.quantity) });
            this.dplStrLst?.filter(val => {this.cnsgnQnLst.filter(a => { if (a.storeid == val.storeId) { 
              this.dplStrLst?.splice(this.dplStrLst?.findIndex(b => b.storeId === val.storeId) , 1)
            }})});                      
            this.cnsgnStrLst = [...this.dplStrLst];
            if (this.cnsgnQnLst.length > 0)
              this.ttlQty = tq.reduce((x, value) => { x += value; return x });
          }
        }
      });
  }

  LoadIndentDetails(id: any) {
    this.indntDtls = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.prdcrBking, environment.apiMthds.getIndntbyID
      .replace('prcId', id), null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.indntDtls = this.result?.data;
          else
            this.glblSvc.onTstr('e', this.result.message)
        }
      });
  }

  getRcmndQnty(evnt: any) {
    this.LoadCnsmptnReport(evnt.target.value);
  }

  LoadCnsmptnReport(strId: any) {
    let payLoad: any = {};
    payLoad.productIds = this.indntDtls[0]?.productId != null ? [+this.indntDtls[0]?.productId] : null,    
      payLoad.fromDate = this.indntDtls[0]?.supplyPeriodFrom ? this.indntDtls[0]?.supplyPeriodFrom+ ' ' + this.tmF.zeroTM : null,
      payLoad.toDate = this.indntDtls[0]?.supplyPeriodEnd ? this.indntDtls[0]?.supplyPeriodEnd + ' ' + this.tmF.zeroTM : null,
      payLoad.storeId = +strId;
      let a = new Date(this.indntDtls[0]?.supplyPeriodEnd), b = new Date(this.indntDtls[0]?.supplyPeriodFrom);
      const diffTime: any = Math.abs(a.valueOf() - b.valueOf());
      const diffDays: any = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
      payLoad.days = diffDays;
      
    if (payLoad.productIds != null && payLoad.fromDate != null) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.cnsgnRcmndQntity, payLoad, 4).then(() => {
        let res = this.result;
        if (this.result) {
          if (this.result?.data) {
            this.rcmdQntity = this.result?.data;
          }            
          // else
          //   this.glblSvc.onTstr('i', res.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

  OnSaveConsgn() {
    let conStrId = this.ordrStatForm.value.consignStrId, conQnty = this.ordrStatForm.value.consignQntity;
    if (conStrId == '' || conQnty == '' || conQnty == 0)
      this.glblSvc.onTstr('w', "Kindly select the Consignment & Quantity (Quantity must be greater than Zero)");
    else {
      let payLoad: any = {};
      payLoad.producerBookingId = +this.orderId, payLoad.quantity = +conQnty, payLoad.storeId = +conStrId, payLoad.isDeleted = false;

      if (this.isEditCnsgn)
        payLoad.id = this.editCnsgnId, payLoad.createdBy = this.edtCreatedBy, payLoad.updatedBy = +this.usrLgnDtls?.userId, payLoad.purchaseNo = this.cnsgnId;
      else
        payLoad.id = 0, payLoad.createdBy = +this.usrLgnDtls?.userId, payLoad.updatedBy = null, payLoad.purchaseNo = '';

      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsgnBking, environment.apiMthds.saveRupdate, payLoad, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message), this.ordrStatForm.reset(), this.isEditCnsgn = false, 
            this.editCnsgnId = '', this.cnsgnStrLst = [], setTimeout(() => { this.ordrStatForm.get('consignStrId')?.setValue(''), 
            this.ordrStatForm.get('consignQntity')?.setValue('') }, 200), 
            this.getConsgnLst();
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  openModal(data: any) {
    const modalRef = this.ngbMdl.open(PrchsIndentStatusComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.orderStatusData = data;
    modalRef.result.then((result: any) => {
      // if (result == 'done')
      // //this.routingToLocation('list', this.paramTabName); // setTimeout(() => { window.location.reload(); }, 1000);
    }, (r: any) => { console.log(r) });
  }

  ordSts(data: any) {
    let ipData: any[] = []; ipData.push({ 'ordSmry': data, 'ordDtls': this.indntDtls, 'ordId': this.orderId}), 
    localStorage.setItem('sbjctOrdSmryDtls', JSON.stringify(ipData)), this.glblSvc.sbjctOrdSmry.next(ipData), this.router.navigate(['home/prchs-ord-cnsgnee']);
  }

  onCnsgnDel(id: any) {
    if (confirm('Are you sure want to delete the consignment ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.cnsgnBking, environment.apiMthds.delById + id, null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message), this.getConsigmntStr(), this.ordrStatForm.reset(), 
            setTimeout(() => { this.ordrStatForm.get('consignStrId')?.setValue('') }, 200); 
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onCnsgnEdt(item: any) {
    let str: any[] = JSON.parse(<string>localStorage.getItem('cnsnStrLst'));
    str.filter(a => { if (a.storeId == item.storeid) { this.cnsgnStrLst.push(a) }});
    this.isEditCnsgn = true, this.editCnsgnId = item.bookingconsignmentid, this.edtCreatedBy = item.createdid, this.cnsgnId = item.purchase_no;
    this.ordrStatForm.patchValue({ consignStrId: item.storeid, consignQntity: item.quantity });
  }

  onCancelEdit() {
    this.cnsgnStrLst = [...this.dplStrLst], 
    this.isEditCnsgn = false, this.ordrStatForm.get('consignStrId')?.setValue(''), this.ordrStatForm.get('consignQntity')?.setValue('');
  }
}
