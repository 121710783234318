<form [formGroup]="trnsprterCrtForm" autocomplete="off">
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Transporter information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row">
                <div class="col-xl-12">
                    <p class="small mt-0">Please specify the transporter information</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="carrierName">Transporter Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Transporter Name" class="form-control form-control-sm"
                                id="carrierName" formControlName="carrierName"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.carrierName.errors }">
                            <div *ngIf="isSbmtd && fc.carrierName.errors" class="invalid-feedback">
                                <div *ngIf="fc.carrierName.errors.required">Transporter name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="description">Description</label>
                            <input type="text" placeholder="Description" class="form-control form-control-sm"
                                id="description" formControlName="description">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="contactNo">Mobile number <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="contactNo"
                                    formControlName="contactNo" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    id="contactNo" formControlName="contactNo"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.contactNo.errors }" minlength="10" maxlength="13">
                                <div *ngIf="isSbmtd && fc.contactNo.errors" class="invalid-feedback">
                                    <div *ngIf="fc.contactNo.errors.required">Mobile number is required</div>
                                    <div *ngIf="fc.contactNo.errors.minlength || fc.contactNo.errors.maxlength">
                                        Mobile number should be min. of 10 and max. of 13 digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="country">Country <span class="text-danger">*</span></label>
                            <select id="country" class="form-select form-select-sm" formControlName="country"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.country.errors }"
                                (change)="onLocChng($event, 'country')">
                                <option value='null'>-Select-</option>
                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.country.errors" class="invalid-feedback">
                                <div *ngIf="fc.country.errors.required">Country is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="textBox">State <span class="text-danger">*</span></label>
                            <select formControlName="state" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.state.errors }">
                                <option value="null">All</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.state.errors" class="invalid-feedback">
                                <div *ngIf="fc.state.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="textBox">District</label>
                            <select formControlName="district" class="form-select form-select-sm"
                                (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                <option value="null">All</option>
                                <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                                <label for="textBox">Taluk/Block</label>
                                <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                    <option value="null">All</option>
                                    <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                </select>
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="city">Village/City</label>
                            <input type="text" placeholder="Village/City" class="form-control form-control-sm" id="city"
                                formControlName="city">
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="textBox">Street address</label>
                            <input type="text" placeholder="Street address" class="form-control form-control-sm"
                                id="address" formControlName="address">
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="pincode">Zip/PIN code</label>
                            <input type="text" placeholder="Zip/PIN code" class="form-control form-control-sm" id="pincode"
                                formControlName="pincode">
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="vehicleDetails">Vehicle details</label>
                            <input type="text" placeholder="Vehicle details" class="form-control form-control-sm"
                                id="vehicleDetails" formControlName="vehicleDetails">
                        </div>
                        <div class="form-group col-xl-3 mt-2">
                            <label for="textBox">Type</label>
                            <div class="clearfix"></div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="carrierType" id="inlineRadio1"
                                    value="owner" formControlName="carrierType"  [checked]="carrType=='owner'">
                                <label class="form-check-label" for="inlineRadio1">Owned</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="carrierType" id="inlineRadio2"
                                    value="Third party" formControlName="carrierType"  [checked]="carrType=='Third party'">
                                <label class="form-check-label" for="inlineRadio2">Third party</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary me-2 mb-2"
                            (click)="isSbmtd=true;onCreateTransporter()">Save</button>
                        <button class="btn btn-sm btn-light mb-2" (click)="onCancel();isSbmtd=false;">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>