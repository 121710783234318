<form [formGroup]="cnsmptnForm">
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-2 p-4">
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">Material By</strong>&nbsp;
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input" type="radio" id="rbMat1" name="isMatBdge" value="badge"
                                [(ngModel)]="isMatBdge" formControlName="isMatBdge" >
                            <label class="form-check-label" for="mat"
                                (click)="onClkRdb('productId', '', 'prdtBadge')">Tag</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbMat2" name="isMatBdge" value="name"
                                [(ngModel)]="isMatBdge" formControlName="isMatBdge" >
                            <label class="form-check-label" for="mat" (click)="onClkRdb('productBadgeId', '', 'prdtName')">
                                Name</label>
                        </div>
                        <select id="prdtBadge" class="form-select form-select-sm" *ngIf="isMatBdge=='badge'"
                            formControlName="productBadgeId" (change)="onChngDD($event, 'productBadgeId')">
                            <option value="">Select Material Tag</option>
                            <option [value]="it.id" *ngFor="let it of lstMatTags">{{it.name}}</option>
                        </select>
                        <div *ngIf="isMatBdge=='name'">
                            <ng-template #cstmProduct let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span><br />
                            </ng-template>
                            <input name="material" type="text" placeholder="Select Material Name"
                                [typeahead]="lstAllMats" autocomplete="new" [typeaheadScrollable]="true"
                                formControlName="productId" [typeaheadItemTemplate]="cstmProduct"
                                typeaheadOptionField="name" [adaptivePosition]="true"
                                class="form-control form-control-sm" id="prdtName"
                                (typeaheadOnSelect)="onChngTAH('product', $event)" [typeaheadOptionsLimit]="500" />
                        </div>
                    </div>
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">Facility by<span class="text-danger"><sup>*</sup></span></strong>&nbsp;&nbsp;
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input" type="radio" id="rbFac1" name="isFacBdge" value="badge"
                                [(ngModel)]="isFacBdge" formControlName="isFacBdge" (click)="onClkRdb('storeId', '', 'strTag')">
                            <label class="form-check-label" for="fac">Tag</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbFac2" name="isFacBdge" value="name"
                                [(ngModel)]="isFacBdge" formControlName="isFacBdge" (click)="onClkRdb('storeBadgeId', '', 'strName')">
                            <label class="form-check-label" for="fac">Name</label>
                        </div>
                        <select id="strTag" class="form-select form-select-sm" *ngIf="isFacBdge=='badge'"
                            formControlName="storeBadgeId" (change)="onChngDD($event, 'storeBadgeId')">
                            <option value="">Select Facility Tag</option>
                            <option [value]="it.id" *ngFor="let it of lstFacTags">{{it.name}}</option>
                        </select>
                        <div *ngIf="isFacBdge=='name'">
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span><br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" placeholder="Select Facility Name" id="strName"
                                formControlName="storeId" [typeahead]="lstFcltys" autocomplete="new"
                                [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                [adaptivePosition]="true" class="form-control form-control-sm"
                                [typeaheadScrollable]="true" (typeaheadOnSelect)="onChngTAH('store', $event)" 
                                [typeaheadOptionsLimit]="500"/>
                            <!--  (keyup)="onChngTAH('store', $event)" -->
                        </div>
                    </div>
                    <div class="form-group col-xl-6" style="margin-top: 2.3rem !important;">
                        <div class="row">
                            <div class="col-xl-4 small">
                                <div class="input-group" *ngIf="pct=='Monthly' && tab=='o'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpSDVal" #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }" onkeypress='return event.charCode == 8'
                                        placeholder="Start Date" [maxDate]="crntDate"
                                        (bsValueChange)="onChngDT($event, 'sd')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pct=='Weekly' && tab=='o'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                        #dpSD="bsDatepicker" [maxDate]="crntDate"
                                        class="form-control form-control-sm optinbg bsDP-bg" bsDatepicker
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }" onkeypress='return event.charCode == 8'
                                        placeholder="Start Date" readonly [daysDisabled]="[0,2,3,4,5,6]"
                                        (bsValueChange)="onChngDT($event, 'sd')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pct=='Daily' && tab=='o'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                        #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                        [maxDate]="crntDate" (bsValueChange)="onChngDT($event, 'sd')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-1 text-center mt-1 p-0"><label *ngIf="tab=='o'">-</label></div>
                            <div class="col-xl-4 small">
                                <div class="input-group" *ngIf="pct=='Monthly'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpEDVal" #dpED="bsDatepicker" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY',
                                            minMode: 'month', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="crntDate" (bsValueChange)="onChngDT($event, 'ed')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pct=='Weekly'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                        #dpED="bsDatepicker" readonly
                                        class="form-control form-control-sm optinbg bsDP-bg" bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false,
                                            adaptivePosition: true }" [daysDisabled]="[0,2,3,4,5,6]"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="maxWED" (bsValueChange)="onChngDT($event, 'ed')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pct=='Daily'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                        #dpED="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="maxToDate" (bsValueChange)="onChngDT($event, 'ed')">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 small select2-drpdwn">
                                <select class="form-select form-select-sm" [(ngModel)]="pct" formControlName="pct"
                                    (change)="dtsDDL='';onChngPct()">
                                    <option *ngFor="let pc of lstPrdCty" [value]="pc.name">{{pc.name}}</option>
                                </select>
                            </div>
                            <!-- <div class="col-xl-12">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-xl-8">
                                        <strong class="col-form-label">Please choose date range:</strong>
                                        <div class="input-group">
                                            <input type="text" autocomplete="off" onpaste="return false" readonly bsDaterangepicker
                                                class="form-control form-control-sm optinbg bsDP-bg" #drp="bsDaterangepicker"
                                                [bsConfig]="{ isAnimated: true, rangeInputFormat: 'MMM-YYYY', minMode: 'month',
                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true,
                                                    showPreviousMonth: true, maxDateRange: 365, returnFocusToInput: true }"
                                                onkeypress='return event.charCode == 8' placeholder="Date Range" [maxDate]="crntDate"
                                                (bsValueChange)="onChngDT($event, 'sd')" [(bsValue)]="drpVal">
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="drp.toggle()"
                                                    [attr.aria-expanded]="drp.isOpen"> <i class="fa fa-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 mt-2r">
                                        <select class="form-select form-select-sm" [(ngModel)]="pct">
                                            <option *ngFor="let pc of lstPrdCty" [value]="pc.name">{{pc.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="form-group col-xl-9 mt-3">
                        <strong class="col-form-label">Location By</strong>&nbsp;&nbsp;
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input" type="radio" id="rbLoc1" name="isLocOptn" value="state"
                                [(ngModel)]="isLocOptn" formControlName="isLocOptn"
                                (click)="onClkRdb('stateId', '', 'stateName')">
                            <label class="form-check-label" for="loc">State</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbLoc2" name="isLocOptn" value="dist"
                                [(ngModel)]="isLocOptn" formControlName="isLocOptn"
                                (click)="onClkRdb('districtId', '', 'distrcitName')">
                            <label class="form-check-label" for="loc">District</label>
                        </div>
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input" type="radio" id="rbLoc3" name="isLocOptn" value="block"
                                [(ngModel)]="isLocOptn" formControlName="isLocOptn"
                                (click)="onClkRdb('blockId', '', 'blockName')">
                            <label class="form-check-label" for="loc">Taluk/Block</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbLoc4" name="isLocOptn" value="city"
                                [(ngModel)]="isLocOptn" formControlName="isLocOptn"
                                (click)="onClkRdb('city', '', 'cityName')">
                            <label class="form-check-label" for="loc">City/Village</label>
                        </div>
                        <div *ngIf="isLocOptn=='state'">
                            <ng-template #cstmState let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span> <br />
                            </ng-template>
                            <input name="state" type="text" placeholder="Select State" autocomplete="new"
                                formControlName="stateId" [typeahead]="stsLst" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmState" typeaheadOptionField="name" id="stateName"
                                [adaptivePosition]="true" class="form-control form-control-sm w-31"
                                [typeaheadScrollable]="true" (typeaheadOnSelect)="onChngTAH('state', $event)"
                                [typeaheadOptionsLimit]="500" />
                            <!--  (keyup)="onChngState($event)" -->
                            <div *ngIf="shwStDrpdwn" class="mt-2">
                                <select class="form-select form-select-sm w-31">
                                    <option>Show by District</option>
                                    <option>Show by Taluk/Block</option>
                                    <option>Show by Village/City</option>
                                </select>
                            </div>
                        </div>
                        <div *ngIf="isLocOptn=='dist'">
                            <ng-template #cstmDistrcit let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span> <br />
                            </ng-template>
                            <input name="distrcit" type="text" placeholder="Select District" id="distrcitName"
                                formControlName="districtId" [typeahead]="dstLst" autocomplete="new"
                                [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmDistrcit"
                                typeaheadOptionField="name" [adaptivePosition]="true" [typeaheadScrollable]="true"
                                class="form-control form-control-sm w-31"
                                (typeaheadOnSelect)="onChngTAH('district', $event)" [typeaheadOptionsLimit]="500" />
                        </div>
                        <div *ngIf="isLocOptn=='block'">
                            <ng-template #cstmBlock let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span> <br />
                            </ng-template>
                            <input name="block" type="text" placeholder="Select Taluk/Block" id="blockName"
                                formControlName="blockId" [typeahead]="blkLst" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmBlock" typeaheadOptionField="name" autocomplete="new"
                                [adaptivePosition]="true" class="form-control form-control-sm w-31"
                                [typeaheadScrollable]="true" (typeaheadOnSelect)="onChngTAH('block', $event)" 
                                [typeaheadOptionsLimit]="500"/>
                        </div>
                        <div *ngIf="isLocOptn=='city'">
                            <input type="text" class="form-control form-control-sm w-31"
                                placeholder="Select City/Village" formControlName="city" id="cityName">
                        </div>
                    </div>
                    <div class="form-group col-xl-3 mt-5">
                        <div class="float-xl-end mb-2">
                            <!-- <button class="btn btn-sm btn-danger me-2" type="button" (click)="onReset()">Reset</button> -->
                            <button class="btn btn-sm btn-light me-2" type="button" (click)="onCncl()">Cancel</button>
                            <button class="btn btn-sm btn-primary" type="button" (click)="GenRprt()">Get report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-header border-0 p-2">
            <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                <li class="nav-item"><a class="nav-link active" id="Consumption-count-tab" data-bs-toggle="tab"
                        href="#Consumption-count" role="tab" aria-controls="Consumption-count" (click)="onTabClk('o')"
                        aria-selected="true">Overview</a></li>
                <li class="nav-item"><a class="nav-link" id="Consumption-count-2-tab" data-bs-toggle="tab"
                        (click)="onTabClk('m')" href="#Consumption-count-2" role="tab"
                        aria-controls="#Consumption-count-2" aria-selected="false">
                        By Materials</a></li>
                <li class="nav-item"><a class="nav-link" id="Consumption-count-3-tab" data-bs-toggle="tab"
                        (click)="onTabClk('f')" href="#Consumption-count-3" role="tab"
                        aria-controls="#Consumption-count-3" aria-selected="false">
                        By Facilities</a></li>
                <li class="nav-item"><a class="nav-link" id="Consumption-count-4-tab" data-bs-toggle="tab"
                        (click)="onTabClk('l')" href="#Consumption-count-4" role="tab"
                        aria-controls="#Consumption-count-4" aria-selected="false">
                        By Locations</a></li>
            </ul>
        </div>
        <div class="card-body p-2">
            <div class="tab-content" id="top-tabContent">
                <div class="tab-pane fade show active" id="Consumption-count" role="tabpanel"
                    aria-labelledby="Consumption-count-tab">
                    <div class="card border-0 p-0">
                        <div class="card-body border-0 p-2 pt-0">
                            <div class="row">
                                <!-- <div class="col-xl-12 text-end">As of 19/9/20 12:00 PM</div> -->
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-md-3">
                                            <label class="col-form-label">Metric</label>
                                            <select class="form-select form-select-sm" [(ngModel)]="mtrcVal"
                                                (change)="GenRprt()" formControlName="consumptionTypeId">
                                                <option value="">-Select-</option>
                                                <option value="All issues/net utilization">All issues/net utilization</option>
                                                <option value="Direct issues/net utilization excluding Transfer orders">
                                                    Direct issues/net utilization excluding Transfer Indents</option>
                                                <option value="Issues/Net utilization via transfer orders">Issues/Net
                                                    utilization via transfer Indents</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <label class="col-form-label">Return Reason</label>
                                            <select class="form-select form-select-sm">
                                                <option value="All">All</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-5"></div>
                                        <div class="form-group col-xl-1 mt-4">
                                            <ul class="nav nav-pills nav-primary" id="pills-tab" role="tablist">
                                                <li class="">
                                                    <a class="nav-link btn btn-sm active pt-1 pb-1"
                                                        id="pills-CR-chart-tab" data-bs-toggle="pill"
                                                        href="#pills-CR-chart" title="Chart UI"
                                                        aria-controls="pills-CR-chart" aria-selected="true" role="tab">
                                                        <i class="fa fa-line-chart me-0" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                <li class="">
                                                    <a class="nav-link btn btn-sm pt-1 pb-1" id="pills-CR-table-tab"
                                                        data-bs-toggle="pill" href="#pills-CR-table" title="Table Data"
                                                        aria-controls="pills-CR-table" aria-selected="false" role="tab">
                                                        <i class="fa fa-table me-0" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-content mt-2" id="pills-tabContent" [hidden]="lstOV.length==0">
                                <div class="tab-pane fade show active" id="pills-CR-chart" role="tabpanel"
                                    aria-labelledby="pills-CR-chart-tab">
                                    <div class="card p-0">
                                        <div class="card-header p-2">
                                            <div class="row">
                                                <div class="col-sm-12 text-center">
                                                    <h6 class="mb-0">{{mtrcVal}} (All)</h6>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="fD != '' && eD != ''">
                                                <div class="col-sm-12 text-center">From: {{fD}} To: {{eD}}</div>
                                            </div>
                                            <div class="row" *ngIf="lstOV.length!=0">
                                                <div class="col-sm-12">
                                                    <div id="fc-ov" class="text-center" style="height: 500px;"></div>
                                                </div>
                                            </div>
                                            <div class="row col-sm-12" *ngIf="dtsDDL!=''">
                                                <div id="fc-ddl-dly" class="text-center" style="height: 500px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="pills-CR-table" role="tabpanel"
                                    aria-labelledby="pills-CR-table-tab">
                                    <div class="card p-0">
                                        <div class="card-header p-2">
                                            <div class="row mt-3">
                                                <div class="col-xl-6 offset-xl-3 text-center">
                                                    <h6 class="mb-0">{{mtrcVal}} (All)</h6>
                                                    <!-- <small>From: May 2020 To: Apr 2021</small> -->
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="fD != '' && eD != ''">
                                                <div class="col-sm-12 text-center">From: {{fD}} To: {{eD}}</div>
                                            </div>
                                            <div
                                                class="col-xl-12 col-sm-12 mt-2 mb-3 table-responsive table_spacing no-hor-scrl">
                                                <div class="row form-group">
                                                    <div class="col-xl-3"></div>
                                                    <div class="col-xl-6">
                                                        <table class="table table-striped table-sm table-bordered border-primary f-14"
                                                            style="min-width: 300;">
                                                            <thead>
                                                                <tr>
                                                                    <th class="col-auto text-center">Item.No.</th>
                                                                    <th class="col-auto text-center">
                                                                        {{pcType=='monthly'?'Month':'Date'}}</th>
                                                                    <th class="col-auto text-center">Net Consumption</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody [hidden]="lstOV.length==0">
                                                                <tr *ngFor="let item of lstOV; let i=index">
                                                                    <td class="text-center">{{i+1}}</td>
                                                                    <td class="text-center">
                                                                        <div *ngIf="pcType=='monthly'">{{GetMonthYear(item.report_date)}}</div>
                                                                        <div *ngIf="pcType!='monthly'">{{item.report_date | date: "MMM dd, yyyy"}}</div>
                                                                    </td>
                                                                    <td class="text-center">{{item.consumed}}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody [hidden]="lstOV.length!=0">
                                                                <tr>
                                                                    <td class="text-center" colspan="3">{{noRcrdsTxt}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div class="centered-div mt-3 text-center" [hidden]="lstOV.length==0">
                                                            <a [routerLink]="" (click)="DownloadFileCSV(lstOV, 'consumption_overview')">Download as CSV</a>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-3"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- <evin-consumption-overview></evin-consumption-overview> -->
                </div>
                <div class="tab-pane fade" id="Consumption-count-2" role="tabpanel"
                    aria-labelledby="Consumption-count-2-tab">
                    <div class="row" *ngIf="lstMAT.length!=0">
                        <div class="col-xl-12">
                            <div class="float-end">
                                <span style="display: inline-block;">
                                    <button type="button" class="btn btn-sm btn-success ms-2" (click)="GenRprt('e')">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </span>
                                <!-- <small>As of 19/9/20 12:00 PM</small> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-3">
                            <label for="inputState-43" class="form-label">Metric</label>
                            <select id="inputState-43" class="form-select form-select-sm" [(ngModel)]="mtrcVal"
                                (change)="GenRprt()" formControlName="consumptionTypeId">
                                <option value="">-Select-</option>
                                <option value="All issues/net utilization">All issues/net utilization</option>
                                <option value="Direct issues/net utilization excluding Transfer orders">Direct Issues/net utilization excluding transfer Indents</option>
                                <option value="Issues/Net utilization via transfer orders">Issues/net utilization via transfer Indents</option>
                            </select>
                        </div>
                        <div class="col-xl-9 text-end">
                            <!-- <div class="float-end">
                                <button type="button" class="btn btn-sm btn-primary mb-2" data-bs-toggle="modal"
                                    data-bs-target="#modal_export1">Export</button>
                            </div> -->
                        </div>
                    </div>
                    <!-- <div class="row mt-4">
                        <div class="col-xl-12">
                            <div class="text-center">
                                <h6 class="mb-0">All issues/net utilization</h6>
                                <small>From: Feb 2021 To: Apr 2021 Material tag: RI Vaccines Store: Falka Bazar CD </small>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h6 class="mb-0">{{mtrcVal}} (All)</h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="fD2 != '' && eD2 != ''">
                        <div class="col-sm-12 text-center">From: {{fD2}} To: {{eD2}}</div>
                    </div>
                    <div class="row mt-2 mb-3">
                        <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
                                <thead>
                                    <tr>
                                        <th class="col-auto">Item No.</th>
                                        <th class="col-auto text-center">Material</th>
                                        <th class="col-auto text-center">{{pcType=='monthly'?'Month':'Date'}}</th>
                                        <th class="col-auto text-center">Net Consumption</th>
                                    </tr>
                                </thead>
                                <tbody [hidden]="lstMAT.length==0">
                                    <tr *ngFor="let mat of lstMAT; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{mat.product_name}}</td>
                                        <td class="text-center">
                                            <div *ngIf="pcType=='monthly'">{{GetMonthYear(mat.report_date)}}</div>
                                            <div *ngIf="pcType!='monthly'">{{mat.report_date | date: "MMM dd, yyyy"}}</div> <!-- bug -->
                                        </td>
                                        <td class="text-center">{{mat.consumed}}</td>
                                    </tr>
                                </tbody>
                                <tbody [hidden]="lstMAT.length!=0">
                                    <tr>
                                        <td class="text-center" colspan="4">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> <!-- <evin-consumption-materials></evin-consumption-materials> -->
                </div>
                <div class="tab-pane fade" id="Consumption-count-3" role="tabpanel"
                    aria-labelledby="Consumption-count-2-tab">
                    <div class="row" *ngIf="lstFAC.length!=0">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <span style="display: inline-block;">
                                    <button type="button" class="btn btn-sm btn-success ms-2" (click)="GenRprt('e')">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </span>
                                <!-- <small>As of 19/9/20 12:00 PM</small> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-3">
                            <label for="inputState-43" class="form-label">Metric</label>
                            <select id="inputState-43" class="form-select form-select-sm" [(ngModel)]="mtrcVal"
                                formControlName="consumptionTypeId" (change)="GenRprt()">
                                <option value="">-Select-</option>
                                <option value="All issues/net utilization">All Issues/net utilization</option>
                                <option value="Direct issues/net utilization excluding Transfer orders">Direct Issues/net utilization excluding transfer Indents</option>
                                <option value="Issues/Net utilization via transfer orders">Issues/net utilization via transfer Indents</option>
                            </select>
                        </div>
                        <div class="col-xl-9 text-end">
                            <!-- <div class="float-xl-end">
                                <button type="button" class="btn btn-sm btn-primary mb-2" data-bs-toggle="modal"
                                    data-bs-target="#modal_export1">Export</button>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h6 class="mb-0">{{mtrcVal}} (All)</h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="fD2 != '' && eD2 != ''">
                        <div class="col-sm-12 text-center">From: {{fD2}} To: {{eD2}}</div>
                    </div>
                    <div class="row mt-2 mb-3">
                        <div class="col-xl-8 offset-xl-2 table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered border-primary f-14">
                                <thead>
                                    <tr>
                                        <th class="col-auto">Item No.</th>
                                        <th class="col-auto text-center">Facility</th>
                                        <th class="col-auto text-center">{{pcType=='monthly'?'Month':'Date'}}</th>
                                        <th class="col-auto text-center">Net Consumption</th>
                                    </tr>
                                </thead>
                                <tbody [hidden]="lstFAC.length==0">
                                    <tr *ngFor="let fac of lstFAC; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{fac.store_name}}</td>
                                        <td class="text-center">
                                            <div *ngIf="pcType=='monthly'">{{GetMonthYear(fac.report_date)}}</div>
                                            <div *ngIf="pcType!='monthly'">{{fac.report_date | date: "MMM dd, yyyy"}}</div> <!-- bug -->
                                        </td>
                                        <td class="text-center">{{fac.consumed}}</td>
                                    </tr>
                                </tbody>
                                <tbody [hidden]="lstFAC.length!=0">
                                    <tr>
                                        <td class="text-center" colspan="4">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> <!-- <evin-consumption-facilities></evin-consumption-facilities> -->
                </div>
                <div class="tab-pane fade" id="Consumption-count-4" role="tabpanel"
                    aria-labelledby="Consumption-count-3-tab">
                    <div class="row" *ngIf="lstLOC.length!=0">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <span style="display: inline-block;">
                                    <button type="button" class="btn btn-sm btn-success ms-2" (click)="GenRprt('e')">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </span>
                                <!-- <small>As of 19/9/20 12:00 PM</small> -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-3">
                            <label for="inputState-43" class="form-label">Metric</label>
                            <select id="inputState-43" class="form-select form-select-sm"  [(ngModel)]="mtrcVal"
                                (change)="GenRprt()" formControlName="consumptionTypeId">
                                <option value="">-Select-</option>
                                <option value="All issues/net utilization">All Issues/net utilization</option>
                                <option value="Direct issues/net utilization excluding Transfer orders">Direct Issues/net utilization excluding transfer Indents</option>
                                <option value="Issues/Net utilization via transfer orders">Issues/net utilization via transfer Indents</option>
                            </select>
                        </div>
                        <div class="col-xl-9 text-end">
                            <!-- <div class="float-xl-end">
                                <button type="button" class="btn btn-sm btn-primary mb-2" data-bs-toggle="modal"
                                    data-bs-target="#modal_export1">Export</button>
                            </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h6 class="mb-0">{{mtrcVal}} (All)</h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="fD2 != '' && eD2 != ''">
                        <div class="col-sm-12 text-center">From: {{fD2}} To: {{eD2}}</div>
                    </div>
                    <div class="row mt-2 mb-3">
                        <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered border-primary f-14">
                                <thead>
                                    <tr>
                                        <th class="col-auto">Item No.</th>
                                        <th class="col-auto text-center">State</th>
                                        <th class="col-auto text-center">{{pcType=='monthly'?'Month':'Date'}}</th>
                                        <th class="col-auto text-center">Net Consumption</th>
                                    </tr>
                                </thead>
                                <tbody [hidden]="lstLOC.length==0">
                                    <tr *ngFor="let loc of lstLOC; let i=index">
                                        <td class="text-center">{{i+1}}</td>
                                        <td class="text-center">{{loc.state_name}}</td>
                                        <td class="text-center">
                                            <div *ngIf="pcType=='monthly'">{{GetMonthYear(loc.report_date)}}</div>
                                            <div *ngIf="pcType!='monthly'">{{loc.report_date | date: "MMM dd, yyyy"}}</div> <!-- bug -->
                                        </td>
                                        <td class="text-center">{{loc.consumed}}</td>
                                    </tr>
                                </tbody>
                                <tbody [hidden]="lstLOC.length!=0">
                                    <tr>
                                        <td class="text-center" colspan="4">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> <!-- <evin-consumption-locations></evin-consumption-locations> -->
                </div>
            </div>
        </div>
    </div>
</form>
<button id="btnDailyDDL" (click)="onClkDDL()" hidden></button>