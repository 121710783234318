import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { environment } from 'src/environments/environment.prod';
import { GlobalService } from 'src/app/Services/global.service';
import { BaseComponent } from 'src/app/Components/Global/base.component';

@Component({
  selector: 'evin-txn-exports',
  templateUrl: './txn-exports.component.html',
  styleUrls: ['./txn-exports.component.css']
})

export class TxnExportsComponent extends BaseComponent implements OnInit {

  txnExprtFrm: FormGroup = this.fb.group({ reportName: ['Transactions'], reportType: [''], monthFrom: [''], monthTo: [''] }); dt1: any; dt2: any;
  pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; ttlItms = 0; lstGrid: any[] = []; noRcrdsTxt = environment.TblNoRcrds;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void { }

  onFltr() {
    let payLoad: any = this.txnExprtFrm.getRawValue();
    if (this.CheckMonthDiff((new Date(payLoad.monthFrom)), new Date(payLoad.monthTo)) > 12) {
      this.glblSvc.onTstr('w', 'Max 12 months will be allowed');
      return;
    }
    if (payLoad.monthFrom != '' && payLoad.monthFrom != null)
      payLoad.monthFrom = this.glblSvc.setDtFrmt(new Date(payLoad.monthFrom), this.dtF.MY2);
    if (payLoad.monthTo != '' && payLoad.monthTo != null)
      payLoad.monthTo = this.glblSvc.setDtFrmt(new Date(payLoad.monthTo), this.dtF.MY2);
    this.lstGrid = [], this.ttlItms = 0, this.pageEntrs = '', this.FetchData(environment.apiCalls.post, environment.apiPaths.noPath,
        environment.apiMthds.getTxnExprt.replace('ppp', `${this.crntPage-1}`).replace('sss', `${this.pageSize}`), payLoad, 9, true, 2).then(() => {
        let res = this.result;
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstGrid = this.result?.data?.filesList ?? [], this.ttlItms = this.result.data?.count ?? 0,
            this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms);
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  onRst() {
    this.txnExprtFrm.patchValue({ reportName: 'Transactions', reportType: '', monthFrom: '', monthTo: '' }), this.dt1 = null, this.dt2 = null;
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.onFltr();
  }

  onDwnld(item: any) {
    if (item.filePath)
      this.OpenFileInNewTab(item.filePath);
  }

}