import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { items } from 'fusioncharts';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'evin-prchs-indnt-cnsgnee',
  templateUrl: './prchs-indnt-cnsgnee.component.html',
  styleUrls: ['./prchs-indnt-cnsgnee.component.css']
})
export class PrchsIndntCnsgneeComponent extends BaseComponent implements OnInit {

  ordData: any; ordSmrDtls: any; ordrId: any; ordrStsForm: FormGroup; isSbmtd: boolean = false; qntutyLst: any[] = []; 
  noRcrdsTxt = environment.TblNoRcrds; isEdit: boolean = false; edtIndntId: any = ''; dt2day: any = ''; quntyDtls: any[] = [];
  frmDate: any = ''; ctDte: any = new Date(); dt2mx = new Date(); toMaxDt: any = ''; isEdtDel = true; isFreze = false;
  isCnsgnStr = this.usrLgnDtls?.userLevelDetails[0]?.userLevel; rcmdQntity = 0; ttlQty = 0; isActn = true;


  constructor(public glblSvc: GlobalService, public fb: FormBuilder, public router: Router) { super(glblSvc); 
    this.glblSvc.sbjctOrdSmry.subscribe(s => { this.ordData = s[0].ordDtls, this.ordSmrDtls = s[0].ordSmry, this.ordrId = s[0].ordId });
    this.ordrStsForm = this.fb.group({ quantity: ['', Validators.required], supplyPeriodFrom: ['', Validators.required], 
    supplyPeriodEnd: ['', Validators.required] });
    let dtF: any = new Date(this.ordData[0]?.supplyPeriodFrom), dtTo: any = new Date(this.ordData[0]?.supplyPeriodEnd);
    this.ordrStsForm.get('supplyPeriodFrom')?.setValue(''); this.frmDate = dtF, this.dt2day = dtF;
    if (this.ordData[0]?.supplyPeriodFrom != null && this.ordData[0]?.supplyPeriodFrom != '')
      setTimeout(() => { this.ordrStsForm.get('supplyPeriodFrom')?.setValue(dtF); }, 100);
      this.toMaxDt = dtTo;
  }

  ngOnInit(): void {
    this.getSplyLSt();
  }

  get fc() {
    return this.ordrStsForm.controls;
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef, this.ordrStsForm.get('supplyPeriodEnd')?.setValue('');
  }

  LoadCnsmptnReport() {
    let frmValues = this.ordrStsForm.getRawValue(), payLoad: any = {}, t: any, f: any;
    this.GetDtesMinMax(frmValues.supplyPeriodFrom, frmValues.supplyPeriodEnd), payLoad.productIds = this.ordData[0].productId != null && 
    this.ordData[0].productId != '' ? [+this.ordData[0]?.productId] : null, payLoad.fromDate = frmValues.supplyPeriodFrom ? 
    this.glblSvc.setDtFrmt(new Date(this.maxFrmDate), this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.toDate = frmValues.supplyPeriodEnd ?
    this.glblSvc.setDtFrmt(new Date(this.maxToDate), this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.storeId = this.ordSmrDtls?.storeid; payLoad.days = 
    this.CheckDaysDiff(new Date(this.maxFrmDate), new Date(this.maxToDate));
    if (payLoad.productIds != null && payLoad.fromDate != null) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsmptn, environment.apiMthds.cnsgnRcmndQntity, payLoad, 4).then(() => {
        let res = this.result;
        if (this.result) {
          if (this.result?.data) {
            this.rcmdQntity =  this.result?.data;
          }
          // else
          //   this.glblSvc.onTstr('i', res.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
    // else 
    //   this.glblSvc.onTstr('w', 'Kindly select the Product');
  }


  onSubmit() {
    let payLoad = this.ordrStsForm.getRawValue();
    if (this.fc.quantity?.value =='' || this.fc.quantity?.value == 0 || this.fc.quantity?.value == null || this.fc.supplyPeriodFrom?.value =='' ||
      this.fc.supplyPeriodFrom?.value == null || this.fc.supplyPeriodEnd?.value =='' || this.fc.supplyPeriodEnd?.value == null) {
        this.glblSvc.onTstr('w', 'Kindly fill all Mandatory fields');
      } else {
        this.GetDtesMinMax(payLoad.supplyPeriodFrom, payLoad.supplyPeriodEnd),
        payLoad.producerConsigneeId = this.ordSmrDtls?.bookingconsignmentid, 
        payLoad.supplyOrderNo =  this.ordSmrDtls?.purchase_no, payLoad.supplyPeriodEnd = (this.fc.supplyPeriodEnd?.value !='' && 
          this.fc.supplyPeriodEnd?.value !=  null) ? this.glblSvc.setDtFrmt(new Date(this.maxToDate), this.dtF.ymd) + this.tmF.zeroTM : null,
          payLoad.supplyPeriodFrom = (this.fc.supplyPeriodFrom?.value !='' && this.fc.supplyPeriodFrom?.value !=  null) ? 
          this.glblSvc.setDtFrmt(new Date(this.maxFrmDate), this.dtF.ymd) +  this.tmF.zeroTM : null;
        if(this.isEdit)
          payLoad.id = this.edtIndntId;
        else 
          payLoad.id = 0;
        this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsgnSupply, environment.apiMthds.saveCnsgnSply, payLoad, 12).then(() => {
          if (this.result) {
            if (this.result.status == 'OK' && this.result.returnCode == 1) {
              this.glblSvc.onTstr('s', this.result.message), this.isSbmtd = false, this.ordrStsForm.reset(), this.isEdit = false, this.edtIndntId = '',
              this.getSplyLSt();
              if (this.ordData[0]?.supplyPeriodFrom != null && this.ordData[0]?.supplyPeriodFrom != '')
                setTimeout(() => { this.ordrStsForm.get('supplyPeriodFrom')?.setValue(new Date(this.ordData[0]?.supplyPeriodFrom)); }, 100);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        })
    }
  }

  onOrdDtls(data: any) {
    let ipData: any[] = []; ipData.push({ 'ordSmry': data, 'ordDtls': this.qntutyLst, 'ordId': this.ordrId, 'cnsgnDtls': this.ordData}), 
    localStorage.setItem('sbjctOrdCnsgnee', JSON.stringify(ipData)), this.glblSvc.sbjctOrdCnsgnee.next(ipData), this.router.navigate(['home/prchs-ord-sts']);
    //localStorage.setItem('sbjctOrdDtls', JSON.stringify(data)), this.glblSvc.sbjctOrdDtls.next(data), this.router.navigate(['home/prchs-ord-dtls'])
  }

  getSplyLSt() {
    let tq :any[] = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsgnSupply, environment.apiMthds.getCnsgnSplyLst + 
      this.ordSmrDtls?.bookingconsignmentid, null, 12).then((res) => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.qntutyLst = this.result.data;
          let validFrmDate = this.qntutyLst[this.qntutyLst.length - 1];
          this.qntutyLst.forEach(a => { tq.push(a.quantity) });
          //validFrmDate?.supply_period_end.split("-")[1] == 12 ? validFrmDate?.supply_period_end.split("-")[0] = validFrmDate?.supply_period_end.split("-")[0] + 1 : validFrmDate?.supply_period_end.split("-")[1] = validFrmDate?.supply_period_end.split("-")[0] +1 ;
          // this.frmDate = new Date(validFrmDate?.supply_period_end); // this.dt2day = new Date(validFrmDate?.supply_period_end),
          if (this.qntutyLst.length > 0) {
            this.ttlQty = tq.reduce((x, value) => { x += value; return x }), (this.qntutyLst[0]?.confirm_supply_cycle != null) || 
            (this.qntutyLst[0]?.confirm_supply_cycle != false) ? (this.isEdtDel = false, this.isFreze = true) : (this.isEdtDel = true,
            this.isFreze = false), this.isActn = this.qntutyLst[0]?.confirm_supply_cycle ?? false;
            let nxtDte = new Date(this.qntutyLst[this.qntutyLst.length-1]?.supply_period_end);
              let x = new Date(nxtDte.setDate(nxtDte.getDate() - 1));
              let y = x.setMonth( x.getMonth() + 1 );
              this.frmDate = '', this.ordrStsForm.get('supplyPeriodFrom')?.setValue('');
            setTimeout(() => {  
              this.frmDate = new Date(y), this.dt2day = new Date(y), this.ordrStsForm.get('supplyPeriodFrom')?.setValue(new Date(y));
            }, 400);
          } else {
            let dtF: any = new Date(this.ordData[0]?.supplyPeriodFrom), dtTo: any = new Date(this.ordData[0]?.supplyPeriodEnd);
              this.ordrStsForm.get('supplyPeriodFrom')?.setValue(''); this.frmDate = dtF, this.dt2day = dtF;
              if (this.ordData[0]?.supplyPeriodFrom != null && this.ordData[0]?.supplyPeriodFrom != '')
                setTimeout(() => { this.ordrStsForm.get('supplyPeriodFrom')?.setValue(dtF); }, 100);
                this.toMaxDt = dtTo;
          }
            if (this.qntutyLst[0]?.confirm_supply_cycle == null || this.qntutyLst[0]?.confirm_supply_cycle == false)
              this.ordSmrDtls?.quantity == this.ttlQty ? this.isFreze = true : this.isFreze = false;
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  onCnfmSuply() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsgnBking, environment.apiMthds.cnfmSplyCyle.
      replace('ccc', this.ordSmrDtls?.bookingconsignmentid).replace('sss', this.ordSmrDtls?.storeid), null, 12).then((res) => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.returnCode == 1)
          this.getSplyLSt(); //this.isEdtDel = false,  // , setTimeout(() => { this.qntutyLst[0]?.confirm_supply_cycle}, 200); //this.glblSvc.onTstr('s', this.result.message), 
      else
        this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  onQuanEdt(data: any) {
    let dtF: any = new Date(this.ordData[0]?.supplyPeriodFrom), dtTo: any = new Date(this.ordData[0]?.supplyPeriodEnd);
    this.isEdit = true, this.edtIndntId = data?.supplyid, this.quntyDtls = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsgnSupply, environment.apiMthds.getIndntDtlsSpl + 
        this.edtIndntId, null, 12).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.quntyDtls = this.result.data != null ? this.result.data : [], this.ordrStsForm.get('supplyPeriodFrom')?.setValue(''),
          this.ordrStsForm.get('supplyPeriodEnd')?.setValue(''), this.ordrStsForm.get('quantity')?.setValue('');this.frmDate = '',
          this.dt2day = dtF, this.toMaxDt = dtTo;
          setTimeout(() => {
            this.ordrStsForm.patchValue({ quantity: +this.quntyDtls[0]?.supplyQuantity,
              supplyPeriodFrom: new Date(this.quntyDtls[0]?.consignePeriodFrom), supplyPeriodEnd: new Date(this.quntyDtls[0]?.consignePeriodEnd),
            })
          }, 300);
        }
      }
    });
  }

  clearBSDate(ctrl: string) {
    this.ordrStsForm.get(ctrl)?.setValue('');
  }

  onChngDtT(dtVal2: any){
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
    if (this.ordrStsForm.get('supplyPeriodEnd')?.value != '')
      this.LoadCnsmptnReport();
  }

  onQuantityDel(id: any) {
    if (confirm('Are you sure want to delete the supply quantity ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.cnsgnSupply, environment.apiMthds.delQntityInd.replace('indentId=', 
        `${'supplyId='}${id}`), null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message), this.getSplyLSt();
            this.ordrStsForm.get('supplyPeriodFrom')?.setValue('');
            setTimeout(() => {  
              if (this.qntutyLst.length >0) {
                let nxtDte = new Date(this.qntutyLst[this.qntutyLst.length-1]?.supply_period_end);
              let x = new Date(nxtDte.setDate(nxtDte.getDate() - 1));
              let y = x.setMonth( x.getMonth() + 1 );
              this.frmDate = new Date(y), this.dt2day = new Date(y), this.ordrStsForm.get('supplyPeriodFrom')?.setValue(new Date(y));
              } else {
                let dtF: any = new Date(this.ordData[0]?.supplyPeriodFrom), dtTo: any = new Date(this.ordData[0]?.supplyPeriodEnd);
                this.ordrStsForm.get('supplyPeriodFrom')?.setValue(''); this.frmDate = dtF, this.dt2day = dtF;
                if (this.ordData[0]?.supplyPeriodFrom != null && this.ordData[0]?.supplyPeriodFrom != '')
                  setTimeout(() => { this.ordrStsForm.get('supplyPeriodFrom')?.setValue(dtF); }, 100);
                  this.toMaxDt = dtTo;
              }
            }, 200);
            // if (this.ordData[0]?.supplyPeriodFrom != null && this.ordData[0]?.supplyPeriodFrom != '')
            //   setTimeout(() => { this.ordrStsForm.get('supplyPeriodFrom')?.setValue(new Date(this.ordData[0]?.supplyPeriodFrom)); }, 100);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onCancelEdit() {
    this.isEdit = false, this.isSbmtd = false, this.ordrStsForm.get('supplyPeriodFrom')?.setValue(''),
    this.ordrStsForm.get('supplyPeriodEnd')?.setValue(''), this.ordrStsForm.get('quantity')?.setValue('');
  }

}
