import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-materials-edit',
  templateUrl: './materials-edit.component.html',
  styleUrls: ['./materials-edit.component.css']
})

export class MaterialsEditComponent  extends BaseComponent implements OnInit {

  matId: any; isSbmtd = false; setupMatsEditForm : FormGroup = this.fb.group({}); lstMatTags: any [] = []; badge_id: any = '';
  
  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctUsrId.subscribe(s => {
      if (s)
        this.matId = s;
      else
        router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } })
    })
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); });   
  }

  ngOnInit(): void {
    this.setupMatsEditForm = this.fb.group({ productName: ['',Validators.required], short_name: [''], badge:['', Validators.required],
    description:[''], is_batch_enabled:[false], is_seasonal:[], handling_unit:[0, [Validators.min(1), Validators.max(100)]],
    is_temperature_sensitive:[false], minimum_temperature :[0], maximum_temperature:[0],  product_id:[] }),
    this.select2ShowHide(), this.getMaterial();
  }

  get fc() { return this.setupMatsEditForm.controls; }

  getMaterial() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.editMat.replace('iiiii', this.matId), 7).then(()=> {
      if (this.result) {
        if (this.result.status == 'OK') {
          let materialDetails = this.result.data;
         this.setupMatsEditForm.patchValue({
          minimum_temperature : materialDetails[0]?.minimumTemperature,maximum_temperature : materialDetails[0]?.maximumTemperature,
            productName : materialDetails[0]?.productName, product_id : materialDetails[0]?.product_id, description : materialDetails[0]?.description,
            handling_unit : materialDetails[0]?.handlingUnit, is_batch_enabled : materialDetails[0]?.batchEnabled=='Enabled' ? true : false,
            is_seasonal : materialDetails[0]?.isSeasonal == 'Yes', is_temperature_sensitive : materialDetails[0]?.isTempSensitive == 'Yes',
            badge: materialDetails[0]?.badge !=null? materialDetails[0]?.badge : '', short_name: materialDetails[0]?.shortName
         });         
        }
      }
    });
  }
  
  updateMaterial() {
    let payLoad: any = this.setupMatsEditForm.getRawValue(), bg: any[] = [];
    if((this.fc.badge.value != '' || this.fc.badge.value != null) && (this.fc.handling_unit.value != 0 ||
    this.fc.handling_unit.value != "" || this.fc.handling_unit.value != null || this.fc.handling_unit.value != 0) &&
    (this.fc.productName.value != '' || this.fc.productName.value != null)){
     this.lstMatTags.filter(e => { if(e.name == this.badge_id ) {bg.push(e.id)}});
    payLoad.name = payLoad.productName != '' ? payLoad.productName : null, payLoad.short_name = payLoad.short_name != '' ? 
    payLoad.short_name : null, payLoad.is_temperature_sensitive = payLoad.is_temperature_sensitive, payLoad.product_badge_ids = bg ,
    payLoad.id = this.matId, payLoad.is_seasonal = payLoad.is_seasonal, payLoad.is_batch_enabled = payLoad.is_batch_enabled, 
    payLoad.description = payLoad.description != '' && payLoad.description != null ? payLoad.description : null, payLoad.handling_unit =
    payLoad.handling_unit != '' ? payLoad.handling_unit : null; payLoad.maximum_temperature = payLoad.maximum_temperature != '' ? 
    +payLoad.maximum_temperature : null, payLoad.minimum_temperature = payLoad.minimum_temperature != '' ? 
    +payLoad.minimum_temperature : null, ['badge', 'product_id', 'productName'].forEach(e =>{ delete payLoad[e] }),
      payLoad.user_id = this.usrLgnDtls.userId, this.FetchData(environment.apiCalls.post,
        environment.apiPaths.product, environment.apiMthds.creatProduct, payLoad, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message), this.router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } }),
            this.glblSvc.sbjctUsrId.next(0), setTimeout(() => { window.location.reload(); }, 1000);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }else
  this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onCancel() {
    this.setupMatsEditForm.reset(), this.isSbmtd = false, this.router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } });
  }

}