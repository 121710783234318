import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { FormBuilder } from '@angular/forms';

var $ = require('jquery'), FusionCharts = require('fusioncharts'),
Charts = require('fusioncharts/fusioncharts.charts'),
  FusionTheme = require('fusioncharts/themes/fusioncharts.theme.fusion'), jQueryFusionCharts = require('jquery-fusioncharts');
Charts(FusionCharts), FusionTheme(FusionCharts);// jQueryFusionCharts(FusionCharts);

@Component({
  selector: 'evin-db-assets',
  templateUrl: './db-assets.component.html',
  styleUrls: ['./db-assets.component.css']
})

export class DbAssetsComponent extends BaseComponent implements OnInit {
  clrCds = environment.clrCds;
  lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllMats: any[] = []; lstMats: any; slctPrdId: any; productId:any; asetType: any[] = [];
  cceFltrFrm = this.formBuilder.group({ }); astTypLst: any[] = []; dropdownSettings = {singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
  unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true}; dfltStrTgsStk: any[] = []; selectedTypes: any[] = []; mntrType: any = ''; frstVst = true;
  shwAstType = false; smIndType = ''; mapType= '1'; rngClrCode = this.clrCds.green; lstCCEDnt: any[] = []; usrLvl = ''; usrStateId = 0; usrDistId = 0;
  isCcpShow = false; isAP = false; lstSteData: any[] = []; lstDstrctData: any[] = []; slctdState = ''; lstIND: any[] = [];
  lstT1: any[] = []; lstT2: any[] = []; lstT3: any[] = [];  isHideIND = false; dstName = ''; noRcrdsTxt = environment.TblNoRcrds; astStatus = ''; usrLvlDtls: any;
  mntrgType = ''; cceMngmntType = ''; facltyCtgry = ''; asstTypeNme: any[] = []; faciltyName: any[] = []; mntrTypeName = 'All'; ttlAstTpeLst: any[] = []; stName =''; dsplyMap: boolean = false;
  dfltAstType = ''; dfltMntrngAStType: any[] = []; dflastTypLst: any[] = [];

  lstAP = [{
    id: 'IN.AP.AN', value: '82', name: 'Ananthapor', displayValue: 'Ananthapor,82',showlabel: 1 }, { id: 'IN.AP.CH', value: '89', 
    name: 'Chittoor' }, { id: 'IN.AP.CU', name: 'YSR District, Kadapa (Cuddaph)', value: '12', showlabel: '1'}, { id: 'IN.AP.EG', value: '15', 
    name: 'East Godavari' }, { id: 'IN.AP.VZ', value: '15', name: 'Vizianagaram' }, { id: 'IN.AP.VS', value: '55', showlabel: '1', 
    name: 'Visakhapatnam' }, { id: 'IN.AP.NE', value: '79', name: 'Nellore' }, { id: 'IN.AP.KU', value: '45', name: 'Kurnool', showlabel: '1' },
    { id: 'IN.AP.GU', value: '42', showlabel: '1', name: 'Guntur' }, { id: 'IN.AP.KR', value: '25', name: 'Krishna' }, { id: 'IN.AP.SR', 
    value: '18', name: 'Srikakulam' }, { id: 'IN.AP.PR', value: '54', name: 'Prakasam', displayValue: 'Prakasam,20', showlabel: '1' }, { 
    id: 'IN.AP.WG', value: '2', name: 'West Godavari' }];

  constructor(public glblSvc: GlobalService, private formBuilder: FormBuilder) {
    super(glblSvc);    
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility); }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.ttlAstTpeLst = s.astTypLst, s.astTypLst.filter((e: any) => { if (e.id != 1) { this.astTypLst.push(e) } } ); }),  
      this.glblSvc.sbjctCnfgData.subscribe(s => { this.dfltAstType = s.cnfgDBData?.asset_dashboard?.default_monitoring_type, this.dfltMntrngAStType = s.cnfgDBData?.asset_dashboard?.default_asset_types});
      this.cceFltrFrm = this.formBuilder.group({ asetTypeId: [''],astTpe:[''], stckFacCatId: ['']})
  }

  ngOnInit(): void {
    this.usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
    this.usrLvl = this.usrLvlDtls?.userLevel ?? '';
    if (this.usrLvl == 'State')
      this.usrStateId = this.usrLvlDtls?.stateId ?? 0, this.usrDistId = 0;
    if (this.usrLvl == 'District')
      this.usrStateId = this.usrLvlDtls?.stateId ?? 0, this.usrDistId = this.usrLvlDtls?.districtId ?? 0;
      
      if(this.dfltAstType !=''){
        this.mntrType = this.dfltAstType; this.mntrTypeName = this.mntrgType = this.dfltAstType =='2' ? 'Monitored Assets' : this.dfltAstType =='1' ? 'Monitoring Assets': 'All';
        this.cceFltrFrm.get('astTpe')?.setValue(this.dfltAstType);
        if(this.dfltAstType =='2'){
          // if(this.dfltMntrngAStType.length>0)
          // this.shwAstType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1 && this.dfltMntrngAStType.includes(e.id)) { this.selectedTypes.push(e), this.asetType.push(e.id), this.asstTypeNme.push(e.name) } });
          // else
          // this.shwAstType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1) { this.selectedTypes.push(e), this.asetType.push(e.id), this.asstTypeNme.push(e.name) } }); 
          this.shwAstType = true;
          this.astTypLst.forEach(e => {if(this.dfltMntrngAStType.includes(e.id)){ this.asetType.push(e.id), this.asstTypeNme.push(e.name),  this.selectedTypes.push(e)}})
          
        }
      } 

    var fsnOptns: any = FusionCharts.options; Promise.all([fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false })]),
    //this.ttlAstTpeLst.filter(e => { this.asstTypeNme.push(e.name) }),
     this.lstFacTags.filter(e => { this.dfltStrTgsStk.push({ id: e.id, name: 
      e.name }) }), this.LoadDonutChrt();
  }

  chngProduct(event: any) {
    for (let s = 0; s < this.lstMats.length; s++) {
      if (this.lstMats[s].productName == event.value)
        this.slctPrdId = this.lstMats[s].productId;
    }
  }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'asetTypeId') {
      if (evntType == 'os')
        this.asetType.push(item.id), this.asstTypeNme.push(item.name);
      else if (evntType == 'osa')
        this.asetType = [], this.asstTypeNme = [], this.astTypLst.filter(e => this.asetType.push(e.id)), this.astTypLst.filter(e => this.asstTypeNme.push(e.name));
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id), this.asstTypeNme = this.asstTypeNme.filter(s => s != item.name);
      else
        this.asetType = [], this.asstTypeNme = [];
    }
  }

  onMntrTypeChng(evnt: any) {
    this.mntrType = evnt.target.value, this.mntrTypeName = evnt.target.options[evnt.target.options.selectedIndex].text, this.asstTypeNme = [], this.asetType= [], 
    this.selectedTypes = [];
    if (this.mntrType == 2){
      if(this.dfltMntrngAStType.length>0)
      this.shwAstType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1 && this.dfltMntrngAStType.includes(e.id)) { this.selectedTypes.push(e), this.asetType.push(e.id), this.asstTypeNme.push(e.name) } });
      else
      this.shwAstType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1) { this.selectedTypes.push(e), this.asetType.push(e.id), this.asstTypeNme.push(e.name) } }); 
    }   
    else if (this.mntrType == 1)
      this.shwAstType = false,  this.asetType.push(1), this.asstTypeNme.push('Temperature Logger');
    else 
      this.shwAstType = false, this.ttlAstTpeLst.filter(e => { this.asstTypeNme.push(e.name) });
  }

  LoadDonutChrt() {
    let payLoad: any = {}, fcltyId: any[] = []; this.dsplyMap = false; this.lstT1 = []; this.lstT2 = []; this.lstT3 = []; this.lstIND = []; this.lstSteData = []; this.lstDstrctData = [];
    this.faciltyName = [], this.dfltStrTgsStk.filter(e => { this.faciltyName.push(e.name) });
    this.mntrgType = this.mntrTypeName, this.cceMngmntType = this.asstTypeNme.toString(), this.facltyCtgry = this.faciltyName.toString(), 
    this.dfltStrTgsStk.filter(e => { fcltyId.push(e.id) }); payLoad.assetType = this.asetType, payLoad.facilityTag = fcltyId;
   
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbCCEDonut, payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (this.result.status == 'OK') {   
              this.lstCCEDnt = this.result?.data?.indicatorValues != '' ? this.result?.data?.indicatorValues : [];
              let smData = this.lstCCEDnt.filter(f => f.displayValue.includes('Working - '))[0];
              this.LoadCCEDonut(this.lstCCEDnt), this.smIndType = smData?.displayValue, this.onClk(smData);
            }
            else
             this.glblSvc.onTstr('i', this.result.message);
          }
          else
           this.glblSvc.onTstr('i', this.result.message);
        }
      })
  }

  onClk(el: any) {
    if (+el?.value != 0) {
      let typeNme = el.displayValue.split('-'), type = typeNme[0].trim(); this.frstVst = true;
      this.astStatus = 'Status : ' + type , this.smIndType = type, this.rngClrCode = (type == 'Working' ? this.clrCds.green : (type == 'Under Repair' ?
        this.clrCds.red : (type == 'Beyond Repair' ? this.clrCds.orange : (type == 'Condemned' ? this.clrCds.grey : (type == 'StandBy' ?
        this.clrCds.yellow : this.clrCds.navyBlue))))), this.isHideIND = false; document.getElementById('Map-tab')?.click(), 
        this.mapType = ((this.slctDmnDetails.countryId != null && this.slctDmnDetails.stateId == null) ? '1' : ((this.slctDmnDetails.stateId != null && this.slctDmnDetails.districtId == null) ? '2' : '3')), this.getIndMap();
    }
  }

  onClkIndState(item: any) {
    localStorage.removeItem('state'); this.frstVst = false;
    if (item.isEnabled) {
      if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && item.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && item.value > 0) {
        let shrtLbl: string = this.lstFsnIndSNs.filter(f => f.id === item.id)[0]?.shortLabel;
        this.stName = item.stateName;
        localStorage.setItem('state', JSON.stringify({
          id: item.locationId, label: item.name, originalId: item.id, shortLabel: shrtLbl,
          value: item.value
        })), document.getElementById('Map-tab')?.click();
      }
    }
  }

  onClkIndDstrct(item: any) {
    localStorage.removeItem('district'); this.frstVst = false;
    if (item.isEnabled) {
      if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && item.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && item.value > 0) {
        this.dstName = item.name,
          localStorage.setItem('district', JSON.stringify({
            id: item.locationId, label: item.name, originalId: item.id,
            value: item.value, districtId: item.districtId, sId: item.stateId
          })), document.getElementById('Map-tab')?.hidden, this.isCcpShow = true;
      }
    }
  }

  onClkState() {
    let stData = JSON.parse(<string>localStorage.getItem('state')); this.frstVst = false;
    if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && stData.value >= 0) || ((+this.chsnDmnId != this.usrLgnDtls.pranthId) && stData.value > 0)) {
      this.isCcpShow = false; this.stName = stData?.label;
      let eMap = ((stData.label == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (stData.label == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (stData.label == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (stData.label == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      this.isAP = stData.label == 'Andhra Pradesh', this.lstSteData = this.isAP ? this.lstAP : [], this.mapType = '2';
      if (eMap == ' ')
        this.slctdState = `maps/${stData.label}`.replace(' ', '').toLowerCase(); // this.LoadStateMap(`maps/${stData.label}`.replace(' ', '').toLowerCase(), this.lstSteData);
      else
        this.slctdState = `maps/${eMap}`; // this.LoadStateMap(`maps/${eMap}`, this.lstSteData);
      if (this.lstIND.filter(f => f.id === stData.originalId)[0]?.isEnabled) {
        $('#lblState').html(stData.label);
        this.getIndMap()
      }
      else
        this.isHideIND = false;
    } 
  }
  onClkDstrct() {
    let stData = JSON.parse(<string>localStorage.getItem('district')); this.frstVst = false;
    if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && stData?.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && stData?.value > 0) {
      this.dstName = stData.label, this.mapType = '3', this.getIndMap();
    }
  }

  getIndMap() {
    let payLoad: any = {}, fcltyId: any[] = [];
    this.dfltStrTgsStk.filter(e => {fcltyId.push(e.id)});
    payLoad.assetType = this.asetType, payLoad.facilityTag = fcltyId, payLoad.email = this.usrLgnDtls.email, 
    payLoad.mapType = this.mapType, payLoad.type = this.smIndType, payLoad.stateId = this.usrStateId, payLoad.districtId = this.usrDistId, payLoad.pranthHierarchy = this.slctDmnDetails;
   
      if (this.mapType == '2' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('state'))?.originalId;

    if (this.mapType == '3' && !this.frstVst)
      payLoad.districtId = JSON.parse(<any>localStorage.getItem('district')).districtId,
      payLoad.stateId = JSON.parse(<any>localStorage.getItem('district')).sId,
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('district')).originalId;

    if (this.mapType == '2' && this.frstVst) {
      let stsLst: any[] = this.mstrDta.statesLst, slctdStateName = '';
      stsLst.filter(e => { if (e.id == this.slctDmnDetails.stateId) { slctdStateName = e.name} });
      let eMap = ((this.usrLvlDtls?.name == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (this.usrLvlDtls?.name == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (this.usrLvlDtls?.name == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (this.usrLvlDtls?.name == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      if (eMap == ' ')
        this.slctdState = `maps/${slctdStateName}`.replace(' ', '').toLowerCase(); 
      else
        this.slctdState = `maps/${eMap}`;
      this.mapType == '2', payLoad.mapType = '2', payLoad.locationId = this.usrLvlDtls?.id, this.stName = slctdStateName, $('#lblState').html(slctdStateName);
    }
    if (this.mapType == '3' && this.frstVst) {
      let dstLst: any[] = this.mstrDta.dstLst, slctdDstName = '';
      dstLst.filter(e => { if (e.id == this.slctDmnDetails.districtId) { slctdDstName = e.name} });
      this.mapType == '3', payLoad.mapType = '3', payLoad.locationId = this.usrLvlDtls?.id, this.dstName = slctdDstName;   
    }       

      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbCCEMap, payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.dsplyMap = true;
            if (this.result?.data) {
              if (this.result?.data?.map.length > 0) {              
                if (this.mapType == '1')
                  this.lstIND = this.result?.data, this.lstT1 = this.lstIND.length > 0 ? this.lstIND.filter(f => f.value != null) : [],                    
                    this.LoadIndMap(this.lstIND);
                else if (this.mapType == '2')
                  this.lstSteData = this.result?.data, this.lstT2 = this.lstSteData.length > 0 ? this.result?.data : [],
                    this.LoadStateMap(this.slctdState, this.lstSteData);
                else {
                  this.isCcpShow = true; 
                  setTimeout(() => { document.getElementById('Map-1-tab')?.click(); }, 1000);                  
                  this.lstDstrctData = this.result?.data, this.lstT3 = this.lstDstrctData.length > 0 ? this.result?.data : [];
                }
                   
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  LoadStateMap(state: string, steData: any[] = [], clrCods: string[] = this.rngClrCode, divId: string = 'fc-map-state-ind') {
    this.isHideIND = true, FusionCharts.ready(function () {
      var chart = new FusionCharts({
        type: state, renderAt: divId, width: '500', height: '500', dataFormat: 'json', dataSource: {
          chart: { // "subcaption": " 2021", "showBorder": "0", "tooltext": "{value} {br}{label}", "labelsepchar": " ", "plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            caption: '', numbersuffix: '%', showCanvasBorder: '0', includevalueinlabels: '1', includeNameInLabels: '1', useSNameInLabels: '1',
            canvasBorderThickness: '1', enableSmartLabels: '1', showMarkerToolTip: '0', showToolTip: '1', entityFillHoverColor: '#ffffff',
            nullEntityColor: clrCods[2], nullEntityAlpha: '100', hoverOnNull: '1', theme: 'fusioncharts/latest/maps/fusioncharts', // nullClr
            exportEnabled: '1', exportTargetWindow: '_self', exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV'
            legendPosition: 'bottom', showLegend: '1', showLabels: '1', showValues: '1'
          },
          colorrange: {
            color: [{ minvalue: '0', maxvalue: '20', code: clrCods[0], displayValue: '< 20' }, {
              minvalue: '20', maxvalue: '40',
              code: clrCods[1], displayValue: '20-40'
            }, { minvalue: '40', maxvalue: '60', code: clrCods[2], displayValue: '40-60' }, {
              minvalue: '60', maxvalue: '80', code: clrCods[3], displayValue: '60-80'
            }, {
              minvalue: '80', maxvalue: '100', code: clrCods[4],
              displayValue: '80 - 100'
            }]
          }, data: steData
        }, events: {
          entityRollover: function (evt: any, data: any) { },
          entityRollout: function (evt: any, data: any) { }, entityClick: (evt: any, data: any) => { localStorage.setItem('district', JSON.stringify(data)), document.getElementById('btnDistrict')?.click(); }
        }
      }).render();
    });
  }

  LoadIndMap(indLst: any, clrCods: string[] = this.rngClrCode, divId: string = 'fc-Ind') {
    FusionCharts.ready(() => {
      var salesMap = new FusionCharts({
        type: 'maps/india', renderAt: divId, width: '600', height: '700', dataFormat: 'json',
        dataSource: {
          chart: {
            caption: "",            
            numbersuffix: '%', showCanvasBorder: '0', includevalueinlabels: '1', includeNameInLabels: '1', useSNameInLabels: '1', showToolTip: '1',
            canvasBorderThickness: '1', enableSmartLabels: '1', showMarkerToolTip: '0', entityFillHoverColor: '#ffffff', nullEntityColor: clrCods[4],     //color
            nullEntityAlpha: '100', hoverOnNull: '1', theme: 'fusioncharts/latest/maps/fusioncharts', legendPosition: 'bottom', showLegend: '1',
            showLabels: '1', showValues: '1', exportEnabled: '1', exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            exportTargetWindow: '_self', exportFileName: 'Inventry'
          },
          colorrange: {
            color: [{ minvalue: '0', maxvalue: '20', code: clrCods[0], displayValue: '< 20' }, {
              minvalue: '20', maxvalue: '40',
              code: clrCods[1], displayValue: '20-40'
            }, { minvalue: '40', maxvalue: '60', code: clrCods[2], displayValue: '40-60' }, {
              minvalue: '60', maxvalue: '80', code: clrCods[3], displayValue: '60-80'
            }, {
              minvalue: '80', maxvalue: '100', code: clrCods[4],
              displayValue: '80 - 100'
            }]
          }, data: indLst,
        },
        events: {
          entityRollover: function (evt: any, data: any) { }, entityRollout: function (evt: any, data: any) { },
          entityClick: (evt: any, data: any) => { localStorage.setItem('state', JSON.stringify(data)), document.getElementById('btnState')?.click(); },
        }
      }).render();
    });
  }

  LoadCCEDonut(resultDta: any) {
    var that = this, fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.bindDonut(resultDta, this.asstTypeNme, this.faciltyName);
    
    $("#chart-container").bind('fusionchartsdataplotclick', (event: any, data: any) => {
      if(!data.isSliced)
        localStorage.setItem('dntSlctdDta', JSON.stringify(data)), document.getElementById('btnDonut')?.click();
      else
        event.preventDefault();
    })
  }

  bindDonut(resultDta: any, captionText: any, subCaptionTxt: any) {
    FusionCharts.ready(function () {
      var revenueChart = new FusionCharts({
        type: 'doughnut2d', renderAt: 'chart-container', width: '750', height: '500', dataFormat: 'json', dataSource: {
          chart: {
            startingAngle: "90", decimals: "0", centerLabelFontSize: "20", theme: "fusion", showPercentValues: "1", enableSlicing: '0',  
            enableSmartLabels: "1", showLegend: "1", showLabels: "1", showValues: "1", enableMultiSlicing: "0", exportEnabled: "1",
            exportFormats: environment.fcExprtOptns, exportTargetWindow: "_self", exportFileName: "CCE Management", caption: 'CCE Management Status', subCaption: 
            'CCE Management Type :'  + captionText.toString() + ' <br> Facility Type : ' + subCaptionTxt.toString() + ' <br> Status: All',
            plotToolText: '$label'
          }, data: resultDta
        }
      }).render();
    })
  }

  onClkDnt() {
    let slctdDntData =  JSON.parse(<string>localStorage.getItem('dntSlctdDta'));
    document.getElementById('Map-tab')?.click(), this.isHideIND = false, this.smIndType = slctdDntData?.displayValue, this.onClk(slctdDntData);
  }

  exprtExl(indData: any){
    let payLoad: any = {}, fcltyId: any[] = [];
    this.dfltStrTgsStk.filter(e => {fcltyId.push(e.id)});
    payLoad.assetType = this.asetType, payLoad.facilityTag = fcltyId, payLoad.mapType = this.mapType, payLoad.districtId = this.usrDistId, 
    payLoad.email = this.usrLgnDtls.email, payLoad.stateId = this.usrStateId, payLoad.type= this.smIndType, payLoad.pranthHierarchy = this.slctDmnDetails;
     if (this.mapType == '2' && !this.frstVst) {
       if (localStorage.getItem("state") === null) 
          payLoad.locationId = this.usrLvlDtls?.id;
       else 
        payLoad.locationId = JSON.parse(<any>localStorage.getItem('state'))?.originalId;
     }
     
   if (this.mapType == '3' && !this.frstVst)
     payLoad.locationId = JSON.parse(<any>localStorage.getItem('district'))?.originalId,
     payLoad.districtId = JSON.parse(<any>localStorage.getItem('district'))?.districtId,
     payLoad.stateId = JSON.parse(<any>localStorage.getItem('district'))?.sId;

     if (this.usrLvl == "State" && this.frstVst) {
      this.mapType == '2', payLoad.mapType = '2', payLoad.locationId = this.usrLvlDtls?.id, this.stName = this.usrLvlDtls?.name;
    }
    if (this.usrLvl == "District" && this.frstVst)
      this.mapType == '3', payLoad.mapType = '3', payLoad.locationId = this.usrLvlDtls?.id, this.dstName = this.usrLvlDtls?.name;  



   this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbCCEExprt, payLoad, 2, true, 3).then(() => {
     if (this.result) {
       if (this.result.status == 'OK')
         this.glblSvc.onTstr('s', this.result.message);
       else
         this.glblSvc.onTstr('w', this.result.message);
     }
   });
  }

}
