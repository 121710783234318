import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-discards',
  templateUrl: './discards.component.html',
  styleUrls: ['./discards.component.css']
})

export class DiscardsComponent extends BaseComponent implements OnInit {

  isMatBdge = 'badge'; isFacBdge = 'badge'; lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllMats: any[] = []; lstFcltys: any[] = [];
  slctdMat: any[] = []; slctdMatName: any[] = []; lstSlctdMatName: any = []; isSbmtd = false; lstSlctdFacTags: any[] = []; stsLst: any[] = [];
  lstSlctdMatTags: any[] = []; lstSlctdStores: any[] = []; lstStrs: any[] = []; lstFacIds: any[]= []; slctdFac: any[] = []; isLocOptn = 'state';
  slctdFacId: any[] = []; blkLst: any[] = []; dstLst: any[] = []; lstTxnTyps: any[] = []; shwStDrpdwn = false; slctdStateId: any;  pLSD = '';
  slctdDistrictId: any; slctdBlockId: any; drpVal = '1'; lstMAT: any[] = []; lstFAC: any[] = []; lstGrid: any[] = []; isMnthChng = false;
  pstT = 'Monthly'; dpSDVal: any = new Date(); dpEDVal: any = new Date(); plED = ''; isDayChng = false; txtFD = ''; txtED = ''; tab = 'o';
  pstType = 'monthly'; maxToDate: Date = new Date(); crntDate: Date = new Date(); fD2 = ''; eD2 = ''; lstDataDDL: any[] = []; lstX: any[] = [];
  lstY: any[] = [];  ttlItms: any; dscrdForm: FormGroup = this.fb.group({}); usrLvl = ''; lstTxn: any[] = []; pageEntrsD = '';
  txnType: any; frmDate: any = ''; tooDate: any = ''; slctrsnTYpe: any =''; slctTxnTypes: any =''; ttlDayItms: any; crntDPage = 1;
  noRcrdTxt = environment.TblNoRcrds; ttlCount = 0; lstMatsTag: any; lstMatsName: any; lstFacsTag: any; lstFacsName: any; 
  discardRsnsLst: any[] = []; txnTypeId = ''; reasonId = ''; mtrcVal = null; isDtCng:string= ''; resn = null; lstGridDate : any[]= [];
  frmDates: any = ''; tooDates: any = ''; dt2days = new Date(); crntPage = 1; pageEntrs = ''; steRes: string = ''; dstRes: string = '';
  pageSize = environment.tblPageSize; totalItems: any; yrMin : any; yBDate : any; lblTextOfDate:any = 'Max 12 months will be accepted';
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
  unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; ddlStngs = { singleSelection: false,
    idField: 'storeId', textField: 'storeName', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
    allowSearchFilter: true }; isShow: boolean = false; isRprtsHlpShw = environment.isRprtsHlpShw; steResDly: string = ''; dstResDly: string = '';
    expDrpn: any = '1'; expDrpnDly: any = '1';  isWekChng = false; ttlCnt: any[] = []; ttlCntDly: any[] = [];
    showExportOption:boolean=true;
  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == 
      environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility)}),
      this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst,
        this.discardRsnsLst = s.rsnsLst.filter((f: any) => f.reasonType == "Transaction Discard"),
        this.lstTxnTyps = s.txnTypeLst, this.dstLst = s.dstLst, this.lstAllMats = s.allMats; });
  }

  ngOnInit(): void {
    this.dscrdForm = this.fb.group({
      isMatBdge: ['badge'], isFacBdge: ['badge'], productTags: [[], Validators.required], reasonId: [''], productIds: [[], Validators.required],
      storeTags: [[], Validators.required], storeIds: [[], Validators.required], isLocOptn: ['state'], pstT: ['Monthly'], state: [null],
      block: ['null'], district: [null], dpSDVal: ['', Validators.required], dpEDVal: ['', Validators.required], fromDate: [''], toDate: [''],
      userName: [''], txnTypeId: [1] }); localStorage.setItem('isPgLd', '0');
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.dscrdForm.get('country')?.setValue(cntryId);
    }
    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
  }

  get fc(){ return this.dscrdForm.controls };
  get fgrv() { return this.dscrdForm.getRawValue(); };

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dscrdForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.dscrdForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.dscrdForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.dscrdForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.dscrdForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.dscrdForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dscrdForm.get('state')?.setValue('null'), this.dscrdForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.dscrdForm.get('fromDate')?.setValue(''), this.dscrdForm.get('toDate')?.setValue(''), this.dscrdForm.get('dpSDVal')?.setValue(''),
          this.dscrdForm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.dscrdForm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.dscrdForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.dscrdForm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.dscrdForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.dscrdForm.get('fromDate')?.setValue(''), this.dscrdForm.get('toDate')?.setValue(''), this.dscrdForm.get('dpSDVal')?.setValue(''),
          this.dscrdForm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.dscrdForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.dscrdForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.dscrdForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.dscrdForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.dscrdForm.get('fromDate')?.setValue(''), this.dscrdForm.get('toDate')?.setValue(''), this.dscrdForm.get('dpSDVal')?.setValue(''),
          this.dscrdForm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.dscrdForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.dscrdForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.dscrdForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.dscrdForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onTabClk(tabType: string) {
    this.tab = tabType, this.lstGrid = [], this.crntPage=1, this.crntDPage = 1, this.isSbmtd = true, this.GenRprt();
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter((a: { name: any; }) => a.name == evnt.target.value).length > 0;
  }
  
  onClkRdbs(ctrl: string, val: any, ctrlId: string) {
    this.dscrdForm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if(slctdId=="null"){
      this.showExportOption= true
    }
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [],  this.showExportOption= true, this.blkLst = [], this.dscrdForm.get('district')?.setValue(null),
          this.dscrdForm.get('block')?.setValue(null);
      else
        did = slctdId, this.showExportOption= slctdId=="null"?true:false, this.blkLst = [],this.dscrdForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.dscrdForm.get('district')?.enable(),
                this.dscrdForm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.dscrdForm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.dscrdForm.get('district')?.setValue('null'), this.dscrdForm.get('block')?.setValue('null'),
          this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('block')?.disable();
      else
        this.dscrdForm.get('block')?.setValue('null'), this.dscrdForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dscrdForm.get('state')?.enable(), this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dscrdForm.get('state')?.disable(), this.dscrdForm.get('district')?.enable(), this.dscrdForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.dscrdForm.get('state')?.disable(), this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.dscrdForm.get('state')?.disable(), this.dscrdForm.get('district')?.disable(), this.dscrdForm.get('block')?.disable();
  }

  onChngPstT() {
    this.dscrdForm.get('dpSDVal')?.setValue(''); this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' : (this.pstT=='Daily' ? 
    'Max 31 days will be accepted' :'Max 42 days will be accepted');
    this.pstType = this.pstT.toLowerCase(), this.isMnthChng = this.pstT == 'Monthly', this.isWekChng = this.pstT == 'Weekly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='', this.dpEDVal='';
  }

  GenRprt(type: string = 'g'){
    let formCtrls = this.dscrdForm.getRawValue();
    this.discardRsnsLst.filter((e => {if(e.reasonId == this.dscrdForm?.value.reasonId) { this.slctrsnTYpe = e.reasonName} }));
   if ((this.fc.productTags.value != '' || this.fc.productIds.value != '') && (this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != '') {
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.dscrdForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.dscrdForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
        this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        else {
          if (this.dpEDVal  &&  this.pstT == 'Monthly'){
            if(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)){
              this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
            }
            else {
              let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
             let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]),
             dtdts = dts.split('...');
             this.dscrdForm.get('toDate')?.setValue(dtdts[1]);
            }
          }
          else{
            this.dscrdForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
          }
        }
      let payLoad: any = this.dscrdForm.getRawValue(), fT: any[] = [], mT: any[] = [], mN: any[] = [], sN: any[] = [],
       lstMatsTags: any[] = [], lstMatsNames: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = [];  this.isDtCng = this.pstT;
      //  this.frmDate = payLoad.fromDate, this.tooDate = payLoad.toDate,
      this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) : 
        (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.tooDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
        (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
       this.lstSlctdMatTags?.forEach((e: any) => {lstMatsTags.push(e.name) }); this.lstMatsTag = lstMatsTags; 
      this.lstSlctdMatName?.forEach((e: any) => { lstMatsNames.push(e.name) }),this.lstMatsName = lstMatsNames;
      this.lstSlctdFacTags?.forEach((e: any) => {lstFacsTags.push(e.name) }),this.lstFacsTag = lstFacsTags;
      this.lstSlctdStores?.forEach((e: any) => {lstFacsNames.push(e.storeName) }),this.lstFacsName = lstFacsNames;
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district ==  'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.productTags = payLoad.productTags != null ? this.lstSlctdMatTags?.
        forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT; payLoad.productIds = payLoad.productIds != null ?
        this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN; payLoad.storeTags = 
      payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.storeIds =
      payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN; payLoad.userName =
        this.usrLgnDtls.userName, payLoad.typeId = (payLoad.txnTypeId != '' && payLoad.txnTypeId != null && payLoad.txnTypeId != undefined) ? +payLoad.txnTypeId : null, 
        payLoad.reasonId = (payLoad.reasonId != '' && payLoad.reasonId != null && payLoad.reasonId != undefined) ? +payLoad.reasonId : 0;
      ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isMatBdge', 'isFacBdge', 'isLocOptn', 'txnTypeId'].forEach(e => {delete payLoad[e]})
      if(type == 'g')
        this.isShow = false, this.onGen(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '') : '', payLoad.metricsId = payLoad.typeId,
        this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onGen(payLoad: any) {
    this.lstGrid = [], this.lstGridDate = [], this.lstX = [], this.lstY = [], this.lstMAT = [], this.lstFAC = [], this.ttlCount = 0;
    let tc: any[] = [], crntPgIndx = this.crntPage - 1; this.ttlCnt = [], this.ttlCntDly = [];
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrd, environment.apiMthds.dscrdStckRprt.
        replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).
        replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'm' ? '2' : '3')}`), payLoad, 4).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                if (this.result?.data) {
                  if (this.tab == 'o') {
                    this.lstGrid = this.result?.data?.discardsCount ?? []; this.lstTxn = this.result?.data[''] ?? [];
                    this.lstTxn.forEach(a => { tc.push(a.count) });
                    if (this.lstTxn.length > 0)
                      this.ttlCount = tc.reduce((x, value) => { x += value; return x });
                  } else  {
                    let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                    if (data) {
                      this.ttlItms = this.result.data?.totalListSize ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms), 
                      Object.keys(data.discardResult).forEach((s: any, i: number) => {
                        let sName = `${s}`.split('||')[0];
                        if (i == 0)
                          data.discardResult[s].forEach((m: any) => { hdrs.push(m.date) });
                        result.push({ kId: null, kName: sName, matData: data.discardResult[s], hdrs: hdrs, total: ttl });
                      });
                      data.dateAndCount.forEach((s: any) => this.ttlCnt.push(s.count));
                      this.lstGrid = result, this.steRes = this.lstGrid[0]?.matData[0]?.state, this.dstRes = this.lstGrid[0]?.matData[0].district;
                    }
                    else if (data == null)
                      this.glblSvc.onTstr('i', this.result.message);
                  }
                  }
                  else
                  this.glblSvc.onTstr('w', this.result.message);
              }
              else
                this.glblSvc.onTstr('i', this.result.message);
            }
          });
  }

  onCncl(){
    this.lblTextOfDate = 'Max 12 months will be accepted';
    this.lstSlctdMatName = [...[]], this.lstSlctdMatTags = [...[]], this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]],
    this.slctdMat = [], this.slctdMatName = [], this.slctdFac = [], this.slctdFacId = [], this.drpVal = '1',this.resn = null;
    this.dscrdForm.get('isMatBdge')?.setValue('badge'), this.dscrdForm.get('isFacBdge')?.setValue('badge'), 
    this.dscrdForm.get('productTags')?.setValue([]), this.dscrdForm.get('productIds')?.setValue([]), this.steRes = '', this.dstRes = '',
    this.dscrdForm.get('storeTags')?.setValue([]), this.dscrdForm.get('storeIds')?.setValue([]), this.steResDly = '', this.dstResDly = '',
    this.dscrdForm.get('txnTypeId')?.setValue('1'), this.dscrdForm.get('pstT')?.setValue('Monthly'), this.expDrpn = '1', this.expDrpnDly = '1',
    this.dscrdForm.get('dpEDVal')?.setValue(''), this.dscrdForm.get('dpSDVal')?.setValue(''), this.dpEDVal ='', this.dpSDVal='', 
    this.dscrdForm.get('reasonId')?.setValue(null), this.dscrdForm.get('fromDate')?.setValue(''), this.dscrdForm.get('toDate')?.setValue('');
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500), this.ttlCnt = [], this.ttlCntDly = [];    
  }

  onClkRdb(ctrl: string) {
    if (ctrl == 'productTags')
    this.fc?.productIds.setValue([]),
      this.fc?.productId?.clearValidators(), this.fc?.productTag?.setValidators([Validators.required]), this.lstSlctdMatName = [];
    else if(ctrl == 'productIds')
    this.fc?.productTags.setValue([]),
      this.fc?.productTag?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]),
    this.fc?.productTag?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
    else if(ctrl == 'storeTags')
    this.fc?.storeIds.setValue([]),
    this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]), this.lstSlctdStores = [...[]];
    else 
    this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), 
    this.fc?.storeIds?.setValidators([Validators.required]),
    this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(),
    this.lstSlctdFacTags = [...[]];
  }
  
  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else if (ctrl == 'm') {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      } else if (ctrl == 'n') {
        if (this.lstSlctdMatName.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
      } else {
        if (this.lstSlctdStores.filter((f: { storeId: any; }) => f.storeId === item.storeId).length == 0)
          this.lstSlctdStores.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'n')
        this.lstSlctdMatName = this.lstSlctdMatName.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstMatTags;
      else if (ctrl == 'n')
        this.lstSlctdMatName = this.lstAllMats;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'm')
        this.lstSlctdMatTags = [];
      else if (ctrl == 'n')
        this.lstSlctdMatName = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  // LoadStrName() {
  //     let frmCtrls = this.dscrdForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, 
  //     districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district };
  //     this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]], this.FetchData(environment.apiCalls.post,
  //         environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data)
  //             this.lstFacIds = this.result.data != null ? this.result.data : [];
  //           else
  //             this.glblSvc.onTstr('W', this.result.message);
  //         }
  //       }
  //     });
  // }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrd, environment.apiMthds.dscrdStkRprtExprt.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' :
        (this.tab == 'm' ? '2' :'3')}`).replace('eee', this.usrLgnDtls?.email), payLoad, 4).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  GetDailyData(dtt: any, type: string='g') {
    if ((this.pstType == 'monthly' || this.pstType == 'weekly') && dtt != 'Total Count') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt !='') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if( this.pstType == 'monthly'){
          dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd);
         let mn = sm.split("-"); let mnth = this.mnths.filter(m => m.name == mn[0]);
        let v = this.lstMnths.find(month => month.val == mnth[0].val);
        let fromDateDay = mn[1] + '-' + v?.val + '-01'; let toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if(dtEND < toDateDay){
          maxDate = dtEND;
        }else{
          maxDate = toDateDay;
        }
      }
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length - 1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.dscrdForm.getRawValue(), fT: any[] = [], mT: any[] = [], mN: any[] = [], sN: any[] = [];
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.tooDates = this.pstType == 'weekly' ?
      maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.fromDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(frmDte, 
        this.dtF.ymd): frmDte , payLoad.toDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.productTags = payLoad.productTags != null ? this.lstSlctdMatTags?.
        forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT; payLoad.productIds = payLoad.productIds != null ?
        this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN; payLoad.storeTags = payLoad.storeTags != null ?
        this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds != null ?
        this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN; payLoad.userName = this.usrLgnDtls.userName,
      payLoad.typeId = (payLoad.txnTypeId != '' && payLoad.txnTypeId != null && payLoad.txnTypeId != undefined) ? +payLoad.txnTypeId : null,
      payLoad.reasonId = (payLoad.reasonId != '' && payLoad.reasonId != null && payLoad.reasonId != undefined) ? +payLoad.reasonId : 0;
        ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isMatBdge', 'isFacBdge', 'isLocOptn', 'txnTypeId'].forEach(e => {delete payLoad[e]});
        if(type=='g')
          this.isShow = true, this.onGenDaysData(payLoad);
        else
          payLoad.metricsId = payLoad.typeId, this.tab == 'f' ? (payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '') : '',
          this.onDaysEE(payLoad);
    }
  }

  onGenDaysData(payLoad: any){
    let crntPgIndx = this.crntDPage - 1; this.lstGridDate = [], this.ttlCntDly = [];
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrd, environment.apiMthds.dscrdStckRprt.
      replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).replace('ddd', 'D').
      replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'm' ? '2' : '3')}`), payLoad, 4).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            if(this.tab == 'o')
            this.lstGridDate = this.result?.data?.discardsCount ?? [];
            else  {
              let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
              if (data) {
                this.ttlDayItms = this.result.data?.totalListSize ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms),
                  Object.keys(data.discardResult).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0];
                    if (i == 0)
                      data.discardResult[s].forEach((m: any) => { hdrs.push(m.date) });
                    result.push({ kId: null, kName: sName, matData: data.discardResult[s], hdrs: hdrs, total: ttl });
                  });
                  data.dateAndCount != null ? data.dateAndCount.forEach((s: any) => this.ttlCntDly.push(s.count)) : null;
                  this.lstGridDate = result, this.steResDly = this.lstGridDate[0]?.matData[0]?.state, this.dstResDly = this.lstGridDate[0]?.matData[0].district;
              }
              else if (data == null)
                this.glblSvc.onTstr('i', this.result.message);
              else
                this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGridDate), this.glblSvc.sbjctTblDataCnt.next(data?.count ?? 0);
            }
            // this.lstGridDate = this.result?.data?.discardsCount ?? [];
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
  }

  onDaysEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrd, environment.apiMthds.dscrdStkRprtExprt.
      replace('ddd', `${'d'}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'm' ? '2' :'3')}`).replace('eee', this.usrLgnDtls?.email),
        payLoad, 4).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }
}

