import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-up-time-locations',
  templateUrl: './up-time-locations.component.html',
  styleUrls: ['./up-time-locations.component.css']
})
export class UpTimeLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
