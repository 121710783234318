import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FusionCharts from 'fusioncharts';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

declare let $: any;

@Component({
  selector: 'evin-e-stock-report',
  templateUrl: './e-stock-report.component.html',
  styleUrls: ['./e-stock-report.component.css']
})

export class EStockReportComponent extends BaseComponent implements OnInit {

  invStkRprtFrm = this.fb.group({ storeId: [null], state: [null],
    expireBefore: [''], abnormalityType: [''], district: [null], productId: [null], duration: [null], includeStoreBadge: [null], block: [null],
    includeProductBadge: [null], excludeStoreBadge: [null], country: [null], batchEnb: [''], pranthId: [this.chsnDmnId] });
  invStkRprtMatFrm = this.fb.group({ storeIdT: [''], productIdT: [''], facility: [''], material: [''], dvntType: [''], expireBefore: [''],
    batchEnb: [''], state: [''], district: [''], block: [''], stockOut: [''], includeCat: [''], excludeCat: [''] }); isUI = 'sr'; isFld = '';
  lstFacs: any[] = []; lstMats: any[] = []; lstAllMats: any[] = []; lstMatsAll: any[] = []; matsLST: any[] = []; stsLst: any[] = [];
  dstLst: any[] = []; blkLst: any[] = []; slctdFac: any[] = []; slctdMat: any[] = []; lstShowFacTags: any[] = []; lstShowMatTags: any[] = [];
  lstSlctdFacTags: any[] = []; lstSlctdMatTags: any[] = []; lstFacTags: any[] = []; lstMatTags: any[] = []; slctdIncldFac: any[] = [];
  slctdIncldProd: any[] = []; slctdExcldFac: any[] = []; lstGrid1: any[] = []; lstGrid2: any[] = [];
  noRcrdsTxt = environment.TblNoRcrds; usrLvl = ''; dtToDay = new Date(); ddlStngs = { singleSelection: false, idField: 'id', textField: 'name',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; isMC = false; isExprt = false; crntPage = 1;
  shwAPrds = true; isAS = false; shwBlks = false; shwDst = false; isFacAll = false; isMatAll = false; stkRprtMatView = false; strView = false;
  expireView = false; isHide = true; isMatAPICald = true; pageEntrs = ''; slctdStrName = ''; facName = ''; slctdStoreId: any; kioskId: any;
  pageSize = environment.tblPageSize; totalItems: any; lstGrid: any; slctdStrId: any; slctdStr: any; slctPrdId: any; matName: any;

  constructor(public glblSvc: GlobalService,
    public router: Router,
    public ngbMdl: NgbModal,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacs = s; }), this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f =>
      f.badgeTypeId == environment.tagTypes.product), this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility); });
  }

  ngOnInit(): void {
    // this.fcDoughNutModal('doughnut2d', 'fcModal'), this.LoadMatsAll(), this.onFormChanges(),
    this.glblSvc.invTable.subscribe(s => this.lstGrid = s), this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst,
      this.lstMats = s.allMats, this.lstMatsAll = s.allMats, this.lstAllMats = s.allMats }), this.glblSvc.sbjctKskId.subscribe(sId => {
      this.kioskId = sId, this.invStkRprtMatFrm.get('storeIdT')?.setValue(sId);
        if (this.kioskId)
          this.isFld = 'f' //, this.LoadMatsbyStoreId(this.kioskId);
        else
          this.router.navigate([environment.lndgPathClnt]);
      }), this.glblSvc.sbjctKskName.subscribe(sNm => this.facName = sNm);
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.invStkRprtFrm.get('country')?.setValue(cntryId);
      if (this.usrLvl == 'State')
        this.invStkRprtFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null) //, this.onLocChng(usrLvlDtls?.stateId ?? null, 'state', true);
      if (this.usrLvl == 'District') {
        this.invStkRprtFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), Promise.all([
          // this.onLocChng(usrLvlDtls?.stateId ?? null, 'state', true), this.onLocChng(usrLvlDtls?.districtId ?? null, 'district', true)
        ]).then(() => {
            this.invStkRprtFrm.get('district')?.setValue(usrLvlDtls?.districtId ?? null) //, this.LoadStores();
        });
      }
      if (this.usrLvl == 'Block')
        this.invStkRprtFrm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), this.invStkRprtFrm.get('district')?.
          setValue(usrLvlDtls?.districtId ?? null), this.invStkRprtFrm.get('block')?.setValue(usrLvlDtls?.blockId ?? null);
    }
    // if (this.usrLvl != 'District')
    //   this.LoadStores();
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef, this.isFld = dtVal ? 'd' : '';
  }

  setPage(pagNo: number, type: any): void {
    if (type == 'str')
      this.crntPage = pagNo //, this.LoadStores();
    else if (type == 'mat') {
      if (this.invStkRprtFrm.get('storeId')?.value != '' && this.invStkRprtFrm.get('storeId')?.value != null) {
        let slctdStrId = this.lstFacs.filter(f => f.storeName == this.invStkRprtFrm.get('storeId')?.value)[0]?.storeId ?? null;
        if (slctdStrId != null)
          this.crntPage = pagNo, this.isFld = 'f' //, this.LoadMatsbyStoreId(slctdStrId);
      }
    }
    else if (type == 'mstStr')
      this.crntPage = pagNo //, this.LoadGrid1();
    else if (type == 'expires')
      this.crntPage = pagNo //, this.LoadGrid2();
  }

  onStoreClk(kskName: string, kskId: number) {
    if (kskName != '')
      this.glblSvc.sbjctKskName.next(kskName), this.glblSvc.sbjctKskId.next(kskId), this.slctdStrName = kskName, this.slctdStoreId = kskId,
        this.stkRprtMatView = true, this.slctdStrId = kskId, this.invStkRprtFrm.get('storeId')?.setValue(kskName),
        localStorage.setItem('clkdStrName', this.slctdStrName), localStorage.setItem('clkdStoreId', this.slctdStoreId);
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdMat = [...this.lstShowMatTags];
  }

  onEE() {
    if (this.lstShowMatTags.length == 0) {
      this.glblSvc.onTstr('w', 'Please choose Material Category');
      if (!this.isAS)
        $('#sr1AS')?.click();
      if (!this.isMC)
        $('#sr1MC')?.click();
    }
    else {
      let lstMC: any[] = [], fT: any[] = [], payLoad: any = {}, ddlVal = $('#ee1').val(), frmCtrls = this.invStkRprtFrm.getRawValue();
      this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), this.lstShowFacTags.forEach(e => { fT.push(e.id); }), payLoad = { // .name
        productId: 0, pranthId: +this.chsnDmnId, userName: this.usrLgnDtls.userName, email: this.usrLgnDtls.email,
        userId: +this.usrLgnDtls.userId, includeAllProductBadge: lstMC, abnormalityType: +frmCtrls.abnormalityType ?? 0,
        duration: frmCtrls.duration ?? '', country: null, state: frmCtrls.state, district: frmCtrls.district, block: frmCtrls.block,
        includeStoreBadge: fT.length != 0 ? fT : null };
      if (ddlVal == 'nb') {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtstckrpt, payLoad).then(() => {
          this.isExprt = false;
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
      else {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtWBstckrpt, payLoad).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
    }
  }

  onEE2() {
    if (this.lstShowMatTags.length == 0) {
      this.glblSvc.onTstr('w', 'Please choose Material Category');
      if (!this.isAS)
        $('#sr1AS')?.click();
      if (!this.isMC)
        $('#sr1MC')?.click();
    }
    else {
      let lstMC: any[] = [], payLoad: any = {}, ddlVal = $('#ee2').val();
        this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), payLoad = { rstoreId: 0,	type: 0, productId: 0, // .name
          abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? 0, includeAllProductBadge: lstMC,
          duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
          storeId: this.slctdStrId, userName: this.usrLgnDtls.userName, email: this.usrLgnDtls.email };
      if (ddlVal == 'nb') {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtStckrpt1fac, payLoad).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
      else {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtStckRpt1WBfac, payLoad).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
    }
  }

  LoadData() {
    this.strView = false, this.expireView = false;
    if (this.lstGrid.length != 1) {
      if (this.invStkRprtFrm.get('productId')?.value)
        this.strView = true, this.expireView = false //, this.LoadGrid1();
      else if (this.invStkRprtFrm.get('expireBefore')?.value)
        this.expireView = true, this.strView = false //, this.LoadGrid2();
      // else if (!this.expireView && !this.strView && !this.stkRprtMatView)
      //   this.LoadStores();
      else if (this.stkRprtMatView) {
        if (this.invStkRprtFrm.get('storeId')?.value != '' && this.invStkRprtFrm.get('storeId')?.value != null)
          this.isFld = 'f' //, this.LoadMatsbyStoreId(+this.slctdStrId);
        // else
        //   this.onFilter();
      }
    }
    else {
      if (this.invStkRprtFrm.get('productId')?.value)
        this.strView = true, this.expireView = false, this.stkRprtMatView = false //, this.LoadGrid1();
      else if (this.invStkRprtFrm.get('expireBefore')?.value)
        this.expireView = true, this.strView = false, this.stkRprtMatView = false //, this.LoadGrid2();
      else
        this.stkRprtMatView = true //, this.onFilter();
    }
  }

  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => { // if (result == 'done') //   return; // your code
    }, (r) => { console.log(r) });
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.shwBlks = false, this.shwDst = true,
          this.invStkRprtFrm.get('district')?.setValue(null), this.invStkRprtFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.shwBlks = true, this.invStkRprtFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.invStkRprtFrm.get('district')?.enable(),
                this.invStkRprtFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.invStkRprtFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.invStkRprtFrm.get('district')?.setValue('null'), this.invStkRprtFrm.get('block')?.setValue('null'), this.shwDst = false,
          this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
      else
        this.invStkRprtFrm.get('block')?.setValue('null'), this.shwBlks = false, this.invStkRprtFrm.get('block')?.disable();
    }
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFacTags.length === this.lstSlctdFacTags.length;
    else
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMatTags.length === this.lstSlctdMatTags.length;
  }

  onReset() {
    this.invStkRprtFrm.patchValue({ productId: null, duration: null, abnormalityType: '', storeId: null, batchEnb: '' }), this.isMC = false,
      this.slctdIncldFac = [], this.slctdIncldProd = [], this.slctdExcldFac = [], this.lstShowFacTags = [...[]], this.lstShowMatTags = [...[]],
      this.isFacAll = false, this.isMatAll = false, this.strView = false, this.expireView = false, this.stkRprtMatView = false, this.isFld = '',
      this.onResetTags('m'), this.onResetTags('f'), $('#cdEB')?.click();
    if (this.usrLvl == 'National')
      this.invStkRprtFrm.get('state')?.setValue(null), this.invStkRprtFrm.get('district')?.setValue(null),
        this.invStkRprtFrm.get('block')?.setValue(null), this.invStkRprtFrm.get('state')?.enable(),
        this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.usrLvl == 'State')
      this.invStkRprtFrm.get('district')?.setValue(null), this.invStkRprtFrm.get('block')?.setValue(null),
        this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.enable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.usrLvl == 'District')
      this.invStkRprtFrm.get('block')?.setValue(null), this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(),
        this.invStkRprtFrm.get('block')?.enable();
    if (this.usrLvl == 'Block')
      this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
    // this.LoadStores();
  }

  clearBSDate(ctrl: string, type: any) {
    if (type == 'first')
      this.invStkRprtFrm.get(ctrl)?.setValue('');
    if (type == 'second')
      this.invStkRprtMatFrm.get(ctrl)?.setValue('');
  }

  onAS() {
    if (this.usrLvl == 'National')
      this.invStkRprtFrm.get('state')?.enable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.usrLvl == 'State')
      this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.enable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.usrLvl == 'District')
       this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.enable();
    if (this.usrLvl == 'Block')
      this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
  }

/*
  fcDoughNutModal(chrtType: string, divId: string) {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json', dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "1",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",
            "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
      // if (divId == 'fcpie2D')
      //   setTimeout(() => { fc.dispose(), $('#shwBtn').click(); }, 5000);
    });
  }

  onFormChanges() {
    this.invStkRprtFrm.get('storeId')?.valueChanges.subscribe(val => {
      if (val != null && val != '') {
        if (this.lstFacs.filter(a => a.storeName == val).length > 0)
          this.invStkRprtFrm.get('productId')?.setValue(null), this.shwAPrds = false;
      }
      else
        this.invStkRprtFrm.get('productId')?.setValue(null), this.shwAPrds = true;
    });
  }

  changeFn(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if (evnt == '' || evnt == undefined)
      this.shwAPrds = true, this.slctdStrId = null, this.invStkRprtFrm.get('storeId')?.setValue(null);
    else {
      if (this.lstFacs.filter(a => a.storeName == evnt).length != 0) {
        this.shwAPrds = false, this.slctdStr = evnt;
        for (let s = 0; s < this.lstFacs.length; s++) {
          if (this.lstFacs[s].storeName == evnt)
            this.slctdStrId = this.lstFacs[s].storeId;
        }; this.isFld = 'f', this.LoadMatsbyStoreId(this.slctdStrId);
      }
      else
        this.shwAPrds = true, this.slctdStrId = null, this.invStkRprtFrm.get('storeId')?.setValue(null);
    }    
  }

  LoadMatsbyStoreId(sId: number) {
    if (sId) {
      if (this.isMatAPICald) {
        this.isMatAPICald = !this.isMatAPICald, this.lstMats = [], this.lstAllMats = [], this.FetchData(environment.apiCalls.get,
            environment.apiPaths.store, environment.apiMthds.invMats.replace('sss', `${sId}`).replace(environment.noPgLen, '').
            replace('rrr', ''), 0).then(() => {
          if (this.result) {
            this.isUI = 'sm';
            if (this.result.status == 'OK') {
              if (this.result.data) {
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                  this.totalItems), this.result.data?.productsDTOs.forEach((e: any) => { Object.assign(e, { isB: false, isM: false, isH: false,
                  isAS: false, isIT: false, lstB: [], lstM: [], lstH: [] }); }), this.lstAllMats = this.result.data?.productsDTOs != null ?
                  this.result.data?.productsDTOs : [], this.lstMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [],
                  this.onFilter(), this.matsLST = this.lstMats;
              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          }
          setTimeout(() => { this.glblSvc.sbjctSpnr.next(false), this.isMatAPICald = true; }, 1000);
        });
      }
    }
  }

  onFilter() {
    let frmVals = this.invStkRprtFrm.getRawValue(), beVal = (frmVals.batchEnb == 'wb' ? true : (frmVals.batchEnb == 'nb' ? false : null)), data: any[] = [];
    if (beVal != null || (frmVals.productId != '' && frmVals.productId != null) || frmVals.abnormalityType != '') {
      if (beVal != null)
        data = this.lstAllMats.filter(f => f.batchManagement == beVal);
      if (frmVals.productId != '' && frmVals.productId != null && frmVals.productId != undefined)
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.productId == +this.slctPrdId);
      if (frmVals.abnormalityType != '')
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.abnormalityType == +frmVals.abnormalityType);
      this.lstMats = data, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.lstMats.length);
    }
    else
      this.lstMats = this.lstAllMats, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.lstMats.length);
  }

  chngProduct(event: any, type: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if (evnt == '' || evnt == undefined)
      this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
    else {
      if (type == 'byStr') {
        if (this.lstAllMats.filter(a => a.productName == evnt).length != 0)
          for (let s = 0; s < this.lstAllMats.length; s++) {
            if (this.lstAllMats[s].productName == event.value)
              this.slctPrdId = this.lstAllMats[s]?.productId;
          }
        else 
          this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
      }
      else {
        if (this.lstMatsAll.filter(a => a.name == evnt).length != 0)
          for (let s = 0; s < this.lstMatsAll.length; s++) {
            if (this.lstMatsAll[s].name == event.value)
              this.slctPrdId = this.lstMatsAll[s]?.id;
          }
        else 
          this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
      }
      this.isUI = 'sm';
    }
  }

  LoadMatsAll() {
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getAll, null, 2, true, 1).
        then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstMatsAll = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadStores() {
    let crntPgIndx = this.crntPage - 1, payLoad = this.invStkRprtFrm.getRawValue(), fT: any[] = [], mT: any[] = [];
    if (payLoad.storeId && (payLoad.productId == null || payLoad.productId == ''))
      this.glblSvc.sbjctKskName.next(payLoad.storeId), this.glblSvc.sbjctKskId.next(this.slctdStrId);
    else {
      if (payLoad.storeId && payLoad.productId)
        payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null;
      this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // e.name - e.name
        payLoad.expireBefore = (payLoad.expireBefore != null && payLoad.expireBefore != '') ?
          this.glblSvc.setDtFrmt(payLoad.expireBefore, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.pranthId = this.lgnUsrData.pranthId,
        payLoad.abnormalityType = (payLoad.abnormalityType != '' && payLoad.abnormalityType != null) ? +payLoad.abnormalityType : null,
        payLoad.state = payLoad.state == 'null' ? null : payLoad.state, payLoad.district = payLoad.district == 'null' ? null : payLoad.district,
        payLoad.block = payLoad.block == 'null' ? null : payLoad.block, payLoad.includeStoreBadge = fT.length != 0 ? fT : null,
        delete payLoad.batchEnb, this.lstGrid = [], payLoad.includeProductBadge = mT.length != 0 ? mT : null,
        payLoad.excludeStoreBadge = (payLoad.excludeStoreBadge?.length != 0 && this.slctdExcldFac.length != 0) ? this.slctdExcldFac : null,
        setTimeout(() => { this.glblSvc.sbjctSpnr.next(true); }, 200), this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
          environment.apiMthds.invntrySV.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
            if (data) {
              this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems),
                Object.keys(data.inventoryDetailMap).forEach((s: any, i: number) => {
                  if (i == 0)
                    data.inventoryDetailMap[s].forEach((m: any) => { hdrs.push(m.productName), ttl.push(data.totalMap[m.productName]); });
                  let sId = data.inventoryDetailMap[s][0].storeId, sName = s, adrs = data.inventoryDetailMap[s][0], block = adrs.block != null ?
                    `${adrs.block}, ` : '', district = adrs.district != null ? `${adrs.district}, ` : '', state = adrs.state != null ? adrs.state : '',
                    city = adrs.city != null ? `${adrs.city}, ` : '', fnl = block == '' ? city : block;
                  result.push({ kId: sId, kName: sName, kAdrs: fnl + district + state, matData: data.inventoryDetailMap[s], ccp: '', hdrs: hdrs, total: ttl });
                });
            }
            else if (data == null)
              this.glblSvc.onTstr('i', this.result.message);
            else
              this.glblSvc.onTstr('e', this.result.message);
            this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid),
              this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0), this.isHide = result.length == 0;
            if (this.lstGrid.length == 1)
              this.stkRprtMatView = true, this.slctdStrId = this.lstGrid[0].kId, this.onStoreClk(this.lstGrid[0].kName, this.slctdStrId),
                this.invStkRprtFrm.get('storeId')?.setValue(this.lstGrid[0].kName), this.isFld = 'f', this.LoadMatsbyStoreId(this.slctdStrId);
            else
              this.stkRprtMatView = false, this.isUI = 'sr', this.invStkRprtFrm.get('storeId')?.setValue(null);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  LoadGrid1() {
    if (this.slctPrdId) {
      this.lstGrid1 = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.invntry, environment.apiMthds.getStrsbyPrdct.
          replace('ppp', this.slctPrdId).replace('rrr', this.lgnUsrData.pranthId).replace('pageNo', `${this.crntPage - 1}`).
          replace('pageSize', `${this.pageSize}`), null).then(() => { let res = this.result;
        if (this.result) {
          if (this.result.data) {
            if (this.lstGrid.length != 1)
              this.totalItems = this.result.data?.totalRecordCount ?? 0, this.lstGrid1 = this.result?.data?.stockViewProductsDTO ?? [],
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.lstGrid1 = this.result?.data?.stockViewProductsDTO?.filter((a: any) => a.storeId == this.lstGrid[0].kId),
                this.lstGrid1 = this.lstGrid1.length > 0 ? this.lstGrid1 : [], this.totalItems = this.lstGrid1.length,
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

  LoadGrid2() {
    if (this.invStkRprtFrm.getRawValue().expireBefore) {
      let expireDate = this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd);
      this.lstGrid2 = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.invntry, environment.apiMthds.getPrdctsbyExpDt.
          replace('ddd', expireDate).replace('rrr', this.lgnUsrData.pranthId).replace('pageNo', `${this.crntPage - 1}`).
          replace('pageSize', `${this.pageSize}`), null).then(() => { let res = this.result;
        if (this.result) {
          if (this.result.data) {
            if (this.lstGrid.length != 1)
              this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.lstGrid2 = this.result?.data?.fetchProductsListBasedOnExpire ?? [],
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.lstGrid2 = this.result?.data?.fetchProductsListBasedOnExpire?.filter((a: any) => a.store_id == this.lstGrid[0].kId),
                this.lstGrid2 = this.lstGrid2.length > 0 ? this.lstGrid2 : [], this.totalItems = this.lstGrid2.length,
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }
*/

}