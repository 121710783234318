<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-xl-end">
            <small>As of 21/9/20 12:00 AM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="col=xl-12 ">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row pt-2">
    <div class="col-xl-12 text-center">
        <h6 class="mb-0">Asset capacity (litres)</h6>
        <small>From: Feb 2021 To: Apr 2021 Monitoring type: Monitored </small>
    </div>
</div>
<div class="row pt-2">
    <div class="col-xl-8 offset-xl-2 mt-2 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto text-center">Material</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>