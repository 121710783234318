<form [formGroup]="assetslstfrm" autocomplete="off">
    <div class="card p-0 border-0">
        <div class="card-body pt-4">
            <div class="row p-0 mt-2">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="row mb-2">
                                <div class="row">
                                    <div class="form-group col-xl-3">
                                        <label class="col-form-label">Facility Tag</label>
                                        <div>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                [disabled]="(fgr.storeId!=''&&fgr.storeId!=null) ||
                                                    (fgr.strTxtName!=''&&fgr.strTxtName!=null)"
                                                [data]="lstFacTags" formControlName="storeTags"
                                                (onSelect)="onMultiSlct('s', 'f', $event)"
                                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                [(ngModel)]="slctdFac">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3 mt-1" *ngIf="slctDmnDetails?.pranthLevel != 'National' && (assetslstfrm?.get('strTxtName')?.value =='')">
                                        <label for="textBox">Facility Name</label>
                                            <ng-template #cstmStore let-model="item">
                                                <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                                <span class="fs-12">{{model.location}}</span>
                                            </ng-template>
                                            <input name="store" type="text" placeholder="Filter by Facility" id="Store" formControlName="storeId"
                                                [typeahead]="lstFacIds" [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmStore"
                                                typeaheadOptionField="storeName" [adaptivePosition]="true" class="form-control form-control-sm"
                                                (typeaheadOnSelect)="changeFn($event, 'slct')" (change)="changeFn($event, 'chng')"
                                                [typeaheadOptionsLimit]="500" [(ngModel)]="fcltyNm" />
                                    </div>
                                    <div class="form-group col-md-3 mt-1" *ngIf="isDbCCE && assetslstfrm?.get('strTxtName')?.value !=''">
                                        <label for="textBox">Facility Name</label>
                                        <input type="text" class="form-control form-control-sm" formControlName="strTxtName" 
                                            [attr.disabled] = 'true'>
                                    </div>
                                    <div class="form-group col-xl-3 mt-1">
                                        <label for="textBox">Serial number</label>
                                        <input type="text" placeholder="Serial number" formControlName="serialNumber"
                                            class="form-control form-control-sm" id="" (change)="onChngSNO()"
                                            onkeypress="return event.charCode != 32" maxlength="30">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Type</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"
                                            formControlName="asetTypeId" (onSelect)="onChng('asetTypeId', $event, 'os')"
                                            (onSelectAll)="onChng('asetTypeId', $event, 'osa')"
                                            (onDeSelect)="onChng('asetTypeId', $event, 'ds')"
                                            (onDeSelectAll)="onChng('asetTypeId', $event, 'dsa')"
                                            [(ngModel)]="selectedTypes">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="row">
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Relationships</label>
                                        <select class="form-select form-select-sm" formControlName="relationships"
                                            (change)="onChngReln($event)">
                                            <option value="">All assets</option>
                                            <option value="1">Assets with relationships</option>
                                            <option value="2">Assets without relationships</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Working status</label>
                                        <select class="form-select form-select-sm" formControlName="approvalStatus">
                                            <option value="">All</option>
                                            <option *ngFor="let os of astStatusList" [value]="os.id">{{os.name}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-xl-3" *ngIf="shwAlrmDrpdwn">
                                        <label for="textBox">Alarms</label>
                                        <select class="form-select form-select-sm" formControlName="alarms"
                                            (change)="selectedAlaram()">
                                            <option value="0">All</option>
                                            <option value="1">Device alarms</option>
                                            <option value="2">Unknown</option>
                                            <option value="3">Normal items</option>
                                            <option value="4">Temperature alarms</option>
                                            <option value="5">No data</option>

                                        </select>
                                    </div>
                                    <div class="form-group col-xl-3" *ngIf="dratn && false">
                                        <label for="textBox">Duration</label>
                                        <select class="form-select form-select-sm" formControlName="duration">
                                            <option value="">Now</option>
                                            <option value="7">7 Days</option>
                                            <option value="30">30 Days</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-xl-3" *ngIf="dratn">
                                        <label for="textBox">Type</label>
                                        <select class="form-select form-select-sm" formControlName="deviceAlarmId" 
                                        (change)="onChngDrnType($event)">
                                            <option value="99">All</option>
                                            <option value="0">Sensor alarm</option>
                                            <option value="1">Battery alarm</option>                                           
                                            <option value="2">Device error - Nexleaf </option>
                                            <option value="3">Device error - Relyon </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-xl-3 mt-3" *ngIf="errCdeDrn">
                                        <label for="textBox">Error Type</label>
                                        <select class="form-select form-select-sm" *ngIf="errStsDvcId == '2'" formControlName="errorCode">
                                            <option value="99-All">All</option>
                                            <option value="1-On board sensor fail TS">1: On board sensor fail TS</option>
                                            <option value="2-External flash fail TS">2: External flash fail TS</option>                                           
                                            <option value="3-OnBoard sensor fail BLE">3: OnBoard sensor fail BLE</option>
                                            <option value="4-Not able to read bat">4: Not able to read bat</option>
                                            <option value="5-External flash fail BLE">5: External flash fail BLE</option>
                                            <option value="6-UART is not working">6: UART is not working</option>
                                            <option value="8-NA">8: NA</option>
                                            <option value="9-PDP activation fail">9: PDP activation fail</option>
                                            <option value="10-SIM not inserted">10: SIM not inserted</option>
                                            <option value="12-CELL poor network">12: CELL poor network</option>
                                            <option value="15-SIM registration failed">15: SIM registration failed</option>
                                            <option value="16-HTTPS timeout">16: HTTPS timeout</option>
                                            <option value="17-DNS error">17: DNS error</option>
                                        </select>

                                        <select class="form-select form-select-sm" *ngIf="errStsDvcId == '3'" formControlName="errorCode">
                                            <option value="99-All">All</option>
                                            <option value="0-BOOT ERR">0: BOOT ERR</option>
                                            <option value="1-WIFI ERR">1: WIFI ERR</option>
                                            <option value="2-GPRS ERR">2: GPRS ERR</option>
                                            <option value="3-SMS ERR">3: SMS ERR</option>
                                            <option value="4-SYS ERR">4: SYS ERR</option>
                                            <option value="5-L.Opened">5: L.Opened</option>
                                            <option value="6-L.Closed">6: L.Closed</option>
                                            <option value="7-UNKNOWN ERR">7: UNKNOWN ERR</option>
                                        </select>
                                    </div>


                                    <div class="form-group col-xl-3" *ngIf="tempDratn">
                                        <label for="textBox">Duration</label>
                                        <select class="form-select form-select-sm" formControlName="timeRange">
                                            <option value="1">Now</option>
                                            <option value="2">6 hours</option>
                                            <option value="3">12 hours</option>
                                            <option value="4">24 hours</option>
                                            <option value="5">>= 48 hours</option>
                                        </select>
                                    </div>

                                    <div class="form-group col-xl-3 mt-3" *ngIf="dratn">
                                        <label for="textBox"><b>From</b></label>
                                        <div class="input-group">
                                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                                onkeypress='return event.charCode == 8' (bsValueChange)="onChngDT($event, 'sD')" 
                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                formControlName="fromDate" [(ngModel)]="frmDateF" placeholder="From" [maxDate]="dt2day"
                                                readonly> <!-- [dateCustomClasses]="cstmDt"  -->
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                    [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                                </button>
                                                <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                    (click)="dpFD.hide();clearBSDate('fromDate')"> <i class="fa fa-times"></i>
                                                </button> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-3 mt-3" *ngIf="dratn">
                                        <label for="textBox"><b>To</b></label>
                                        <div class="input-group">
                                            <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                                (bsValueChange)="onChngDT($event, 'eD')" (bsValue)="ctDte" [(ngModel)]="toDate"
                                                class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                                bsDatepicker [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                onkeypress='return event.charCode == 8' formControlName="toDateF" readonly
                                                [minDate]="mnDate" [maxDate]="endDate"> <!-- [dateCustomClasses]="cstmDt2" -->
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                    [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                                </button>
                                                <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                    (click)="dpED.hide();clearBSDate('toDate')"> <i class="fa fa-times"></i>
                                                </button> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-xl-3 mt-3">
                                        <button class="btn btn-sm btn-primary mt-4" data-toggle="collapse" href="#box1" aria-expanded="false"
                                            aria-controls="box1" >Advance Search</button>
                                    </div>
                                    <div id="box1" class="collapse">
                                        <div class="form-row row mb-2">
                                            <div class="form-group col-xl-3 mt-3">
                                                <strong for="textBox">State</strong>
                                                <select formControlName="state" class="form-select form-select-sm"
                                                    (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                                    <option value="null">All</option>
                                                    <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-xl-3 mt-3">
                                                <strong for="textBox">District</strong>
                                                <select formControlName="district" class="form-select form-select-sm"
                                                    (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                                    <option value="null">All</option>
                                                    <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-xl-3 mt-3">
                                                <strong for="textBox">Taluk/Block</strong>
                                                <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                                    <option value="null">All</option>
                                                    <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-xl-3 mt-3">
                                                <strong for="textBox">Logger Mobile number</strong>
                                                <input type="text" placeholder="Mobile number" formControlName="mobileNumber"
                                                    class="form-control form-control-sm" id="mobileNumber"
                                                    [ngClass]="{ 'is-invalid': isFltr && fc.mobileNumber.errors }"
                                                    onkeypress="return event.charCode != 32 || (event.charCode >= 48 &&
                                                        event.charCode <= 57)" maxlength="13" minlength="10">
                                                <p class="small pb-0 mb-0" style="color: #4a90cf !important;">
                                                    <b>Kindly do not enter +91 (Country Code) to Mobile number</b>
                                                </p>
                                                <div class="invalid-feedback" *ngIf="isFltr && fc.mobileNumber.errors">
                                                    <div *ngIf="fc.mobileNumber.errors.minlength || fc.mobileNumber.errors.maxlength">
                                                        Mobile number should be min. of 10 and max. of 13 digits only
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-3 mt-3 form-group">
                                                <a data-toggle="modal" data-target="#mdlRD"><u>Logger Error Code List</u></a>
                                            </div>
                                            <div class="col-xl-3 mt-3 form-group">
                                                <a (click) = "firmWareDwn()" title="Download"><u>Logger Firmware Report</u></a>
                                            </div>
                                            <div class="form-group col-xl-3 mt-3" *ngIf="enbleField">
                                                <strong for="textBox">Manufacturer</strong>
                                                <select id="manufacturer" class="form-select form-select-sm"
                                                (change)="onChng('manufacturer', $event,  'mf')"  formControlName="manufacturer"
                                                    [attr.disabled]="(fc.asetTypeId.value == '' || fc.asetTypeId.value == null) ? true : null">
                                                    <option value="">-Select-</option>
                                                    <option *ngFor="let item of manufacturersLst" value="{{item.id}}">{{item.name}}</option>
                                                </select>
                                                <!-- (change)="onNestedSlct($event, 'manufacturer')" -->
                                            </div>
                                            <div class="form-group col-xl-3 mt-3" *ngIf="enbleField">
                                                <strong for="textBox">Asset model</strong>
                                                <select id="assetModelId" class="form-select form-select-sm" formControlName="assetModelId"
                                                (change)="onChng('astModel', $event,  'ast')"
                                                    [attr.disabled]="(fc.asetTypeId.value == '' || fc.asetTypeId.value == null ||
                                                    fc.manufacturer.value == '' || fc.manufacturer.value == null) ? true : null">
                                                    <option value="">-Select-</option>
                                                    <option *ngFor="let item of modelsLst" value="{{item.id}}">{{item.modelName}}</option>
                                                </select>
                                            </div>
                                            <!-- (change)="onNestedSlct($event, 'astModel')" -->

                                            <div class="form-group col-xl-3 mt-3" *ngIf="macEnabled">
                                                <strong for="textBox">Mac Address</strong>
                                                <input type="text" placeholder="Mac Address" formControlName="macAddress"
                                                    class="form-control form-control-sm text-uppercase" id="macAddress"
                                                   >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12" *ngIf="cceMngmntLst">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-light me-2 mb-2"
                                            (click)="crntPage=1;isFltr=false;onReset()" aria-expanded="false">Reset</button>
                                        <button class="btn btn-sm btn-primary mb-2"
                                            (click)="crntPage=1;isFltr=true;LoadAssets()">Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row" *ngIf="asstsList?.length!=0">
                <div class="col-xl-12 mb-2">
                    <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="isFltr=true;exportAstLst()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row" *ngIf="asstsList?.length!=0">
                        <div class="col-xl-7 text-start">{{pageEntrs}}</div>
                        <div class="col-xl-5">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div> <br />
                    <div class="table_spacing table-responsive table-row-spacing">
                        <div class="table_spacing table-responsive table-row-spacing">
                            <table class="table table-sm table_spacing table-small-font p-0" style="min-width: 1100px;">
                                <thead>
                                    <tr>
                                        <th>Item No.</th>
                                        <th>Asset</th>
                                        <th>Type</th>
                                        <th>Working status</th>
                                        <th class="text-center" style="width: 30%">Status</th>
                                        <th>Mapped serial number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let a of asstsList | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index">
                                        <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                        <td (click)="onClick(a.assetId)">
                                            <a>{{a.serialNumber}} - {{a.modelName}} ({{a.assetVendorName}})</a> <br>
                                            <span style="font-size: 11px;">
                                                {{a.storeName}}, {{a.city}}, {{a.blockName}}, {{a.districtName}},
                                                {{a.stateName}}, {{a.country}}
                                            </span>
                                        </td>
                                        <!-- <td>{{a.serialNumber}} - {{a.modelName}}
                                            ({{a.assetVendorName}})</td> -->
                                        <td>{{a.assetTypeName}}</td>
                                        <td>{{a.assetStatus}}</td>
                                        <td class="text-center">
                                            <div *ngIf="a.assetTypeName=='Temperature Logger'">
                                                <div *ngIf="a.deviceReadyStatus=='Device Ready'">
                                                    <i class="fa fa-check-circle-o tickGreen" aria-hidden="true"></i>
                                                </div>
                                                <div *ngIf="a.deviceReadyStatus !='Device Ready'">
                                                    <i class="fa fa-times-circle tickRed" aria-hidden="true"></i>
                                                </div>      
                                                <div *ngIf="a.configMessage != null">
                                                    ({{a.configMessage}})
                                                </div>                                         
                                            </div>                                            
                                            <div *ngIf="a.assetTypeName!='Temperature Logger'">
                                                <div class="d-flex bd-highlight flex-wrap">                                                    
                                                    <div *ngFor="let sm of a.sensorMonitoringPoints; let j=index">
                                                        <div *ngFor="let z of a.temperatureLogDataList; let k= index">                                                           
                                                            <div *ngIf="z.tempHigh != null &&  z.tempLow != null">                      
                                                                <div *ngIf='a.temperatureLogDataList != null && a.sensorMonitoringPoints != null'>
                                                                    <div *ngIf='z.sensor == sm.sensor'
                                                                    [ngClass]="{'normal': (z.temperature < z.tempHigh) && (z.temperature > z.tempLow), 'highTemp': z.temperature > z.tempHigh,
                                                                    'lowTemp': z.temperature < z.tempLow, 'noClr': z.isUnknown == true}">
                                                                        <div class="p-2 m-1 bd-highlight rounded box-outline-success tab_info_box"
                                                                            title="{{z.lastAlarmDate}}">
                                                                            <div class="float-start">
                                                                                {{sm.monitoring_point}}:
                                                                                <span>{{sm.sensor}}</span>
                                                                            </div>
                                                                            <div class="float-end font-wt">
                                                                                <span>{{z.temperature}}&deg;C</span>
                                                                            </div>
                                                                            <div class="float-end text-end">
                                                                                <br />{{z.lastUpdated | date:
                                                                                "dd/MM/yyyy
                                                                                hh:mm:ss aa"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>                                                   
                                                    <div *ngIf="a.configMessage != null">
                                                        <br>
                                                        ({{a.configMessage}} for mapped logger)
                                                    </div> 
                                                </div>
                                            </div>
                                        </td>
                                        <td (click)="onClick(a.mappedAssetId)">{{a.mappedAssetSerialNumber}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="asstsList.length == 0">
                                    <tr>
                                        <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> <br />
                    <div class="row" *ngIf="asstsList?.length!=0">
                        <div class="col-xl-6 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Error code Model -->
<div class="modal" id="mdlRD">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Logger Error Code List</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="table_spacing table-responsive mt-3">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <th>S.No.</th>
                            <th>Error Code</th>
                            <th>Error</th>
                            <th>Source Module</th>
                            <th>Display Source</th>
                            <th>Description</th>
                            <!-- <th>Source Module</th> -->
                            <th>Action</th>
                        </thead>
                        <tbody *ngIf="errLst.length != 0">
                            <tr *ngFor="let x of errLst; let i=index">
                                <td class="text-center">{{i+1}}</td>
                                <td class="text-center">{{x?.errorCode}}</td>
                                <td>{{x?.displayString}}</td>
                                <td>{{x?.sourceModule}}</td>
                                <td>{{x?.displaySource}}</td>
                                <td>{{x?.description}}</td>
                                <!-- <td>{{x?.sourceModule}}</td> -->
                                <td>{{x?.action}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="errLst.length == 0">
                            <tr>
                                <td class="text-center" colspan="6">{{noRcrdsTxt}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer card-footer" style="padding: 6px !important;">
                <button type="button" class="btn btn-sm btn-light" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
