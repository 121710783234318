<form [formGroup]="ordrCreateForm" autocomplete="off">
    <div class="card">
        <div class="card-body p-2">
            <div class="">
                <div class="">
                    <div class="row">
                        <div class="col-xl-12" class="row">
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Select Manufacturer<sup class="text-danger">*</sup></label>
                                <ng-template #cstmStore let-model="item">
                                    <span class="font-weight-bold">{{model.producerName}}</span> <br />
                                </ng-template>
                                <input name="store" type="text" placeholder="Select Manufacturer" class="form-control form-control-sm"
                                    formControlName="producerId" [typeahead]="lstPrducr" [typeaheadScrollable]="true"
                                    [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="producerName"
                                    (typeaheadOnSelect)="onChngPrducr($event, 'slct')" (change)="onChngPrducr($event, 'chng')"
                                    [adaptivePosition]="true" [attr.disabled]="usrStrLen==1?true:null" 
                                    [typeaheadOptionsLimit]="500" [ngClass]="{'is-invalid': isSbmtd && fc.producerId.errors}" />
                                
                                <div *ngIf="isSbmtd && fc.producerId.errors" class="invalid-feedback">
                                    <div *ngIf="fc.producerId.errors?.required">Manufacturer is required</div>
                                </div>
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Select Product<sup class="text-danger">*</sup></label>
                                <ng-template #cstmProduct let-model="item">
                                    <span class="font-weight-bold">{{model.productName}}</span> <br />
                                </ng-template>
                                <input name="store" type="text" placeholder="Type Product Name" id="Product"
                                    formControlName="productId" [typeahead]="lstAllMats" [(ngModel)]="matName"
                                    [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                    typeaheadOptionField="productName" [adaptivePosition]="true"
                                    class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                    (typeaheadOnSelect)="chngProduct($event, 'slct')" (change)="chngProduct($event, 'chng')"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.productId.errors}">

                                <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                                    <div *ngIf="fc.productId.errors?.required">Product is required</div>
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox">Supply Period<sup class="text-danger">*</sup></label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        (bsValueChange)="onChngDT($event)" 
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode:'month', 
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' formControlName="supplyPeriodFrom"
                                        [(ngModel)]="frmDate" placeholder="From" [minDate]="dt2day" readonly 
                                        [ngClass]="{'is-invalid': isSbmtd && fc.supplyPeriodFrom.errors}">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                            [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpFD.hide();clearBSDate('supplyPeriodFrom')"> <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.supplyPeriodFrom.errors" class="invalid-feedback">
                                        <div *ngIf="fc.supplyPeriodFrom.errors?.required">Supply period start date is required</div>
                                    </div> 
                                </div>
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox"></label>
                                <div class="input-group mt-2">
                                    <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                        [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)" (bsValue)="ctDte"
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode:'month', 
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' formControlName="supplyPeriodEnd" [(ngModel)]="toDateF"
                                        bsDatepicker [minDate]="frmDate" readonly [ngClass]="{'is-invalid': isSbmtd && fc.supplyPeriodEnd.errors}">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpED.hide();clearBSDate('supplyPeriodEnd')"> <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.supplyPeriodEnd.errors" class="invalid-feedback">
                                        <div *ngIf="fc.supplyPeriodEnd.errors?.required">Supply period end date is required</div>
                                    </div> 
                                </div>
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Order Quantity<sup class="text-danger">*</sup></label>
                                <input type="text" class="form-control form-control-sm" placeholder="Enter Quantity" maxlength="16"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="quantity" 
                                    [ngClass]="{'is-invalid': isSbmtd && fc.quantity.errors}">
                                    <span class="text-secondary">(Total Requirement for Supply Period {{rcmdQntity}})</span>

                                    <div *ngIf="isSbmtd && fc.quantity.errors" class="invalid-feedback">
                                        <div *ngIf="fc.quantity.errors?.required">Quantity is required</div>
                                    </div> 
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label class="col-form-label">Supply Order No #</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Supply Indent No #"
                                    formControlName="orderReferenceNo" />
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Comments</label>
                                <textarea class="form-control form-control-sm taRS" formControlName="comments" placeholder="Comments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card shadow">
        <div class="card-body p-2">
            <div class="float-end mt-2">
                <button type="button" class="btn btn-sm btn-light" (click)="isSbmtd=false;onReset();">Reset</button>&nbsp;
                <button type="button" class="btn btn-sm btn-primary" (click)="isSbmtd=true;onSave()">Submit</button>
            </div>
        </div>
    </div>
</form>