<div class="card">
    <div class="card-body p-2">
        <div class="row">
            <div class="card card-body p-2 border-0">
                <form class="form theme-form">
                    <div class="row">
                        <div class="col-xl-3 form-group col-sm-12">
                            <label for="BadgeName">Material Category:</label>
                            <select class="form-select" autocomplete="off">
                                <option value="">All</option>
                                <option [value]="it.name" *ngFor="let it of lstMatTags">{{it.name}}</option>
                            </select>
                        </div>
                        <div class="col-xl-3 form-group col-sm-12">
                            <label for="textBox">Material</label>
                            <ng-template #cstmProduct let-model="item">
                                <span class="font-weight-bold">{{model.name}}</span> <br />
                            </ng-template>
                            <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                [(ngModel)]="productId" [typeahead]="lstAllMats" [typeaheadScrollable]="true"
                                [typeaheadItemTemplate]="cstmProduct" typeaheadOptionField="name"
                                [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                (typeaheadOnSelect)="chngProduct($event)" autocomplete="off"
                                [ngModelOptions]="{standalone: true}" [typeaheadOptionsLimit]="500">
                        </div>
                        <div class="col-xl-3 form-group col-sm-12">
                            <label for="tagName">Facility Category:</label>
                            <select class="form-select">
                                <option value="">All</option>
                                <option [value]="it.name" *ngFor="let it of lstFacTags">{{it.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 text-end mt-4">      
                            <button class="btn btn-sm btn-light mt-2">Reset</button> &nbsp; 
                            <button class="btn btn-primary btn-sm mt-2">Filter</button>
                         </div>
                    </div>
                 </form>
            </div>
        </div>
    </div>
</div>
<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-header p-3 text-center">
                <h5 class="mb-0">Likely to stock out</h5>
                <p class="mb-0 small">Within the next 7 days.</p>
            </div>
            <div class="card-body p-3  border-0 row">
                <div class="col-xl-7 col-sm-12 col-xs-12 p-1">
                    <div class="float-end">
                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                            <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab"
                                    data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                    aria-selected="true"><i class="fa fa-globe me-0" aria-hidden="true"></i></a></li>
                            <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                                    data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                                    aria-selected="false"><i class="fa fa-bar-chart me-0" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </div>
                    <h5 class="mb-4 text-center">By location </h5>
                    <div class="tab-content " id="top-tabContent">
                        <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                            <div class="row">
                                <div class="col-xl-12 centered-div">
                                    <div id="fc-Ind"></div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="float-end">
                                        <div class="dropdown-basic p-0">
                                            <div class="dropdown p-0">
                                                <a class="dropbtn pl-0 pr-0 text-secondary"
                                                    style="padding:5px !important;">
                                                    <span class="f-20"><i class="fa fa-bars"
                                                            aria-hidden="true"></i></span></a>
                                                <div class="dropdown-content card border-0"
                                                    style="top:22px !important; right: 0; left: unset;">
                                                    <a class="small pt-1 pb-1" type="button">Export as
                                                        PNG</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as
                                                        JPG</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as
                                                        PDF</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as
                                                        SVG</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 centered-div">
                                    <!-- <img class="img-fluid" src="./assets/img/Screenshot 2021-04-27-103959.jpg" alt=""> -->
                                </div>
                            </div>
                            <div class="text-center mb-2 mt-2">
                                <span class="box_indicators border mt-3 mb-3 p-2 f-10">
                                    <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.4);"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>0-10%</span></span>
                                    <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.5)"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>10-25%</span></span>
                                    <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.6)"><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>25-50%</span></span>
                                    <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.7) "><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>50-90%</span></span>
                                    <span class="f-12"><span style="color: rgb(115 102 255 / 0.9) "><i
                                                class="fa fa-square me-2" aria-hidden="true"></i></span>
                                        <span>90-100%</span></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-sm-12 col-xs-12 p-1">
                    <div class="card-body pt-0">
                        <h5 class="mb-4 text-center">By material</h5>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="float-end">
                                    <div class="dropdown-basic p-0 me-1">
                                        <div class="dropdown p-0">
                                            <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                                <span class="f-20"><i class="fa fa-bars"
                                                        aria-hidden="true"></i></span></a>
                                            <div class="dropdown-content card border-0"
                                                style="top:22px !important; right: 0; left: unset;">
                                                <a class="small pt-1 pb-1" type="button">Export as
                                                    PNG</a>
                                                <a class="small pt-1 pb-1" type="button">Export as
                                                    JPG</a>
                                                <a class="small pt-1 pb-1" type="button">Export as
                                                    PDF</a>
                                                <a class="small pt-1 pb-1" type="button">Export as
                                                    SVG</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 text-center">
                                <!-- <img class="img-fluid" src="./assets/img/Screenshot 2021-04-27-103959.jpg" alt=""> -->
                            </div>
                        </div>
                        <div class="clearfix"></div>
                        <div class="text-center mb-2 mt-2">
                            <span class="box_indicators border mt-3 mb-3 p-2 f-10">
                                <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.4);"><i
                                            class="fa fa-square me-2" aria-hidden="true"></i></span>
                                    <span>0-10%</span></span>
                                <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.5)"><i
                                            class="fa fa-square me-2" aria-hidden="true"></i></span>
                                    <span>10-25%</span></span>
                                <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.6)"><i
                                            class="fa fa-square me-2" aria-hidden="true"></i></span>
                                    <span>25-50%</span></span>
                                <span class="me-2 f-12"><span style="color: rgb(115 102 255 / 0.7) "><i
                                            class="fa fa-square me-2" aria-hidden="true"></i></span>
                                    <span>50-90%</span></span>
                                <span class="f-12"><span style="color: rgb(115 102 255 / 0.9) "><i
                                            class="fa fa-square me-2" aria-hidden="true"></i></span>
                                    <span>90-100%</span></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tooltip" display="none" style="position: absolute; display: none;"></div>
        </div>
    </div>
</div>