import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-cstm-rprts',
  templateUrl: './cstm-rprts.component.html',
  styleUrls: ['./cstm-rprts.component.css']
})
export class CstmRprtsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
