<form [formGroup]="stupCCEMOdelForm" autocomplete="off">
    <div class="row p-0 mt-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="row mt-3">
                            <div class="col-xl-4 text-center"><label>Select CCE Type: <span class="text-danger">*</span></label></div>
                            <div class="col-xl-3"> 
                                <select id="asetTypeId" class="form-select form-select-sm"  formControlName="asetTypeId" [(ngModel)]="slctAstTyp"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.asetTypeId.errors }" (change)="LoadManufacturers($event, 'c')">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of astTypLst" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.asetTypeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.asetTypeId.errors.required">CCE Type is required</div>
                            </div>
                        </div>
                        </div>
                        <div class="row mt-3" *ngIf="manftrsType.length>0 || slctAstTyp.length !=0">
                            <div class="col-xl-4 text-center"><label>Select Manufacturer: <span class="text-danger">*</span></label></div>
                            <div class="col-xl-3"> 
                                <select id="manuType" class="form-select form-select-sm"  formControlName="manftrType"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.manftrType.errors }" [(ngModel)]="slctMnftr">
                                <option value="">-Select-</option>
                                <option *ngFor="let item of manftrsType" value="{{item.id}}">{{item.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.manftrType.errors" class="invalid-feedback">
                                <div *ngIf="fc.manftrType.errors.required">Manufacturer is required</div>
                            </div>
                        </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-xl-4 text-center"><label>Enter Model Number: <span class="text-danger">*</span></label></div>
                            <div class="col-xl-3">
                                <input type="text" placeholder="Model no"  [ngClass]="{ 'is-invalid': isSbmtd && fc.modelNo.errors }"
                                    class="form-control form-control-sm" id="name" formControlName="modelNo">
                                <div *ngIf="isSbmtd && fc.modelNo.errors" class="invalid-feedback">
                                    <div *ngIf="fc.modelNo.errors.required">Model Number is required</div>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-xl-4 "></div>
                            <div class="col-xl-8">
                                <button class="btn btn-sm btn-primary float-end m-1" (click)="crntPage=1;isSbmtd=true;saveVndr()">Save</button>
                                <button class="btn btn-sm btn-light float-end m-1" (click)="crntPage=1;isSbmtd=false;onResetDropdwns()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-5">
            <div class="row mt-5">
                <div class="form-group col-xl-3">
                    <input type="text" placeholder="Filter by  Model Name"
                        class="form-control form-control-sm" id="name" formControlName="SrchName" [(ngModel)]="srchMdlNme">
                </div>
                <div class="form-group col-xl-3"></div>
                <div class="col-xl-6">
                    <div class="float-xl-end pt_2">
                        <button class="btn btn-sm btn-light me-2" (click)="isSbmtd=false;crntPage=1;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary" (click)="crntPage=1;LoadCCEModelList()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-5" />
    <div class="row" *ngIf="manufacturersLst?.length!=0">
        <div class="col-xl-12 mb-2">
            <div class="row">
                <div class="col-xl-4">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="manufacturersLst?.length!=0">
                <!-- <div class="col-xl-12 mb-2">
                    <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'astMdl')" id="astMdl"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                    <thead>
                        <tr>
                            <!-- <th></th> -->
                            <th class="text-center">Item No.</th>
                            <th>CCE Type</th>
                            <th>Manufacturer Name</th>
                            <th>Model name</th>
                            <th>Last updated</th>
                            <!-- <th>Shortcuts</th> -->
                            <th ></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of manufacturersLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems, id: 'astMdl'} ; let index = index">
                            <!-- <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td> -->
                            <td class="text-center">{{(pageSize*(crntPage-1))+index+1}}</td>
                            <td>{{item.assetTypeName}}</td>
                            <td>{{item.assetvendorname}}</td>
                            <td>{{item.modelName}}</td>
                            <td>
                                {{item.lastUpdated | date: "yy/MM/dd hh:mm a"}}
                            </td>
                            <!-- <td><a data-toggle="tooltip" data-placement="top" title="Materials">
                                <i class="fa fa-file-o" aria-hidden="true"></i></a>
                            </td> -->
                            <!-- <td   ><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td> -->
                            <td class="text-center">
                                <span>
                                    <a  (click)="onMfgEdit(item.assetModelId)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                </span>&nbsp;
                                <span>
                                    <a  (click)="onRemove(item.assetModelId)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="manufacturersLst.length == 0">
                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="manufacturersLst?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'astMdl')" id="astMdl"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</form>