<form [formGroup]="facViewFrm" autocomplete="off">
    <div class="row p-0 mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="FacilityName">Facility</label>
                            <ng-template #cstmStore let-model="item">
                                <span class="font-weight-bold">{{model.storeName}}</span><br />
                                <span class="fs-12">{{model.location}}</span>
                            </ng-template>
                            <input name="store" type="text" [typeahead]="lstFacs" placeholder="Filter by Facility"
                                [typeaheadItemTemplate]="cstmStore" [adaptivePosition]="true" typeaheadOptionField="storeName" 
                                class="form-control form-control-sm" [typeaheadScrollable]="true" formControlName="ctrl"
                                [attr.disabled]="lstFacs.length==1?true:null" [typeaheadOptionsLimit]="2500"
                                (typeaheadOnSelect)="onChngFclty($event)" (change)="onChngFclty($event)" />
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="tagName">Facility Category:</label>
                            <select class="form-select form-select-sm" formControlName="tagName">
                                <option value="">All</option>
                                <option [value]="it.id" *ngFor="let it of lstFacTags">{{it.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12" *ngIf="fcltyLst">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1; onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1; LoadFacilities()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div>
        <div class="col-xl-12 mb-2">
            <div class="row" *ngIf="lstAllFacs?.length!=0">
                <div class="col-xl-4 mt-4">
                    <button class="btn btn-sm btn-success mt-2" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
                <div class="col-xl-4" *ngIf="isDelSa!='1' && facIds.length!=0">
                    <label>Move store to other domain</label>
                    <ng-multiselect-dropdown [settings]="dropdownSettings"
                        [data]="lstDmns" formControlName="targetDomain"                                                 
                        (onSelect)="onMultiSlct('s', 't', $event)"
                        (onSelectAll)="onMultiSlct('a', 't', $event)"
                        (onDeSelect)="onMultiSlct('r', 't', $event)"
                        (onDeSelectAll)="onMultiSlct('d', 't', $event)"
                        [(ngModel)]="noDmns">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-xl-4 float-end mt-4" *ngIf="isDelSa!='1' && facIds.length!=0 && noDmns.length!=0">
                    <button type="button" class="ms-2 mt-2 btn btn-sm btn-primary" (click)="onTrgtDmn()">Move</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="lstAllFacs?.length!=0">
                <div class="col-xl-4 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-4 text-center">
                    <!-- <span style="display: inline-flex;">
                        <button type="button" class="btn btn-sm btn-primary">Add/Remove Materials</button>
                    </span>
                    <span style="display: inline-flex;">
                        <button type="button" class="btn btn-sm btn-primary">Remove Stores</button>
                    </span>
                    <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                        <li class=""><a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo"
                                data-bs-toggle="pill" href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                aria-selected="true"><i class="fa fa-table" aria-hidden="true"></i> Table view</a></li>
                        <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false"><i
                                    class="fa fa-desktop" aria-hidden="true"></i> Map</a>
                        </li>
                        <li class=""><a class="nav-link btn btn-sm" id="pills-network-tabinfo" data-bs-toggle="pill"
                                href="#pills-network" role="tab" aria-controls="pills-network" aria-selected="false"> <i
                                    class="fa fa-signal" aria-hidden="true"></i>Network</a></li>
                    </ul> -->
                        <button type="button" class="ms-2 btn btn-sm btn-primary" (click)="onRetrvData()" *ngIf="isDelSa=='1' && facIds.length!=0">
                            <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i> Add </button>
                </div>
                <div class="col-xl-4">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="tab-content" id="top-tabContent1">
                <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                    aria-labelledby="pills-tabview-tabinfo">
                    <div class="table_spacing table-responsive table-row-spacing mt-3">
                        <table class="table table-sm table_spacing table-small-font p-0" style="min-width: 1100px;">
                            <thead>
                                <tr>
                                    <th *ngIf="lstAllFacs?.length!=0 && usrRoleName=='SuperAdmin'"> <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                        (click)="onSlctAll($event)"></th>
                                    <th scope="col">Item No.</th>
                                    <th scope="col">Facility name</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">Last updated</th>
                                    <th scope="col" *ngIf="isDelSa !='1'">Shortcuts</th>
                                    <th [hidden]="isUserLvl=='District'" *ngIf="isDelSa !='1'"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of lstAllFacs | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems }; let i = index">
                                    <td class="text-center" scope="row" *ngIf="lstAllFacs?.length!=0&& usrRoleName == 'SuperAdmin'"><input class="form-check-input" type="checkbox" id="flexCheckDefault12"
                                        value="" aria-label="..." (change)= "isSelected($event, item)" [checked]="item.flag"></td>
                                    <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                    <td *ngIf="fcltydtl" (click)="onfcltyDetails(item.storeId, 's', item.pranthId)"><a>{{item.storeName}}</a></td>
                                    <td>{{item.location}}</td>
                                    <td>
                                        <div class="row">
                                            <div class="col-sm-2"></div>
                                            <div class="col-sm-10">
                                                <span class="badge bg-secondary">{{item.tag}}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><a>{{item.updatedBy}}</a><br><small>{{item.lastupdated | date: "yy/MM/dd hh:mm a"}}</small></td>
                                    <td *ngIf="isDelSa !='1'">
                                        <a *ngIf="fcltydtl" title="Stock Management" class="me-2" (click)="onfcltyDetails(item.storeId, 's', item.pranthId)"><i class="fa fa-industry"
                                                aria-hidden="true"></i></a>
                                        <a *ngIf="fcltyDtlViewIndents" title="Indents" class="me-2" (click)="onfcltyDetails(item.storeId, 'i', item.pranthId)"><i
                                                class="fa fa-file-text" aria-hidden="true"></i></a>
                                        <a *ngIf="fcltyDtlViewTransctn" title="Transactions" class="me-2" (click)="onfcltyDetails(item.storeId, 't', item.pranthId)"><i class="fa fa-money"
                                                aria-hidden="true"></i></a>
                                       <a *ngIf="fcltyDtlViewMtrl" title="Materials" class="me-2" (click)="onfcltyDetails(item.storeId, 'm', item.pranthId)"><i class="fa fa-sort"
                                                aria-hidden="true"></i></a>
                                        <a *ngIf="fcltyDtlViewRltnshp" title="Relationship" class="me-2" (click)="onfcltyDetails(item.storeId, 'r', item.pranthId)"><i class="fa fa-link"
                                                aria-hidden="true"></i></a>
                                    </td>
                                    <td class="text-center" [hidden]="isUserLvl=='District'" *ngIf="isDelSa !='1'">
                                        <a *ngIf="fcltyEdt" (click)="onStrEdit(item.storeId);"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                        <a *ngIf="fcltyDlt" (click)="onStrDel(item.storeId)"><i class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="lstAllFacs.length == 0">
                                    <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstAllFacs?.length!=0">
                        <div class="col-xl-4 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-4">
                            <!-- <span style="display: inline-block;"> <button type="button"
                                    class="ms-2 btn btn-sm btn-primary">
                                    <i class="fa fa-paper-plane" aria-hidden="true"></i> Send message</button>
                            </span>
                            <span style="display: inline-block;"> <button type="button"
                                    class="ms-2 btn btn-sm btn-light">
                                    <i class="fa fa-trash" aria-hidden="true"></i> Remove</button>
                            </span> -->
                        </div>
                        <div class="col-xl-4">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                    <div class="card border-0">
                        <div class="card-body p-0">
                            <div class="float-end mb-2"><span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span></div>
                            <div class="clearfix"></div>
                            <div id="map-container-google-1" class="z-depth-1-half map-container" style="width: 100%; height: 500px">
                                <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
                            </div>
                            <div class="clearfix"></div>
                            <div class="float-end mt-2"><span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span></div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="pills-network" role="tabpanel" aria-labelledby="pills-network-tabinfo">
                    <div class="card p-0 mt-3">
                        <div class="card-header p-2">
                            <div class="h6 mb-0">Network View</div>
                        </div>
                        <div class="card-body p-2">
                            <div class="text-center">Network data not available</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>