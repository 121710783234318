import { NullTemplateVisitor } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-transporters-view',
  templateUrl: './transporters-view.component.html',
  styleUrls: ['./transporters-view.component.css']
})

export class TransportersViewComponent extends BaseComponent implements OnInit {

  noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; isSbmtd = false;
  totalItems: any; transportersLst: any[] = []; trnsporterForm = this.formBuilder.group({ contact_no: [null, [Validators.minLength(10), 
    Validators.maxLength(13)]], carrierName: [null] }); prmsns: any = null; trnsprtrsList: boolean = true; trnsprtrDtl: boolean = true;
    trnsprtrEdt: boolean = true;

  constructor(public glblSvc: GlobalService
    , public router: Router
    , private formBuilder: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_st_tp_li')
        this.trnsprtrsList = e?.action;
      if (e?.menuCode == 'mm_st_tp_td')
        this.trnsprtrDtl = e?.action;
      if (e?.menuCode == 'mm_st_tp_td_ed')
        this.trnsprtrEdt = e?.action;
    });
    if (this.trnsprtrsList)
      this.LoadTransporters();
  }

  get fc() { return this.trnsporterForm.controls; }


  onTrnsDetails(trnsprtrId: any) {
    if (this.trnsprtrDtl)
      this.glblSvc.sbjctTrnsId.next(trnsprtrId), this.router.navigate(['home/setup-transporters-details']);
  }

  onTrnsEdit(trnsId: any) {
    this.glblSvc.sbjctTrnsId.next(trnsId), this.router.navigate(['home/setup-transporters-edit']);
  }

  LoadTransporters() {
    let payLoad: any = {},
    crntPgIndx = this.crntPage - 1, carrierName = this.trnsporterForm.get('carrierName')?.value != null ? 
      this.trnsporterForm.get('carrierName')?.value : null, contact_no = (this.trnsporterForm.get('contact_no')?.value != null || 
      this.trnsporterForm.get('contact_no')?.value != '') ? this.trnsporterForm.get('contact_no')?.value : null; this.transportersLst = [];
      payLoad.carrierName = carrierName, payLoad.contactNo = contact_no;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.carrier, environment.apiMthds.getTransportersByFilter.
        replace('pageNo', `${crntPgIndx}`). replace('pageSize', `${this.pageSize}`), payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.transportersLst = this.result.data?.carrierRecords != null ? this.result.data?.carrierRecords : [],
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadTransporters();
  }

  onReset() {
    this.trnsporterForm.reset(), this.LoadTransporters();
  }

  onRemove(trnsId: any) {
    if (confirm("Are you sure you want to delete the transporters.?")) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.carrier, environment.apiMthds.delById + trnsId, null, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Records deleted")
              this.glblSvc.onTstr('s', this.result.message), this.LoadTransporters();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onEE() {
    let payLoad = this.trnsporterForm.getRawValue(), carrierName = this.trnsporterForm.get('carrierName')?.value != null ? 
      this.trnsporterForm.get('carrierName')?.value : null, contact_no = (this.trnsporterForm.get('contact_no')?.value != null || 
      this.trnsporterForm.get('contact_no')?.value != '') ? this.trnsporterForm.get('contact_no')?.value : null; payLoad.carrierName = 
      carrierName, payLoad.contactNo = contact_no, delete payLoad.contact_no;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.carrier, environment.apiMthds.trnspterExpt.
        replace('eee',this.usrLgnDtls.email), payLoad, 7).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}