import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-notifications-general',
  templateUrl: './cnfg-notifications-general.component.html',
  styleUrls: ['./cnfg-notifications-general.component.css']
})

export class CnfgNotificationsGeneralComponent extends BaseComponent implements OnInit {

  cnfgNotGenData: any; lngs: any[] = []; usrPrmsn: any = null; cnfgntfnPrmsn = true;

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.LoadData();
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_nf_ac') ? (this.cnfgntfnPrmsn = e.action) : '') });
    }
  }

  onLngChng(evnt: any) {
    let event = evnt?.target?.value ?? '';
    if (event != '') {
      this.cnfgNotGenData.configJson.general['system-wide_notifications'].language_preference.forEach((lng: any) => {
        let key = Object.keys(lng)[0];
        lng[key].is_selected = false;
        if (Object.keys(lng)[0] == event)
          lng[key].is_selected = true;
      })
    }
  }

  LoadData() {
    this.glblSvc.sbjctSpnr.next(true), this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration,
        environment.apiMthds.getCnfgDatabyType + environment.cnfgTypes.notification, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.cnfgNotGenData = this.result.data;
              this.cnfgNotGenData.configJson.general['system-wide_notifications'].language_preference.forEach((e: any) => {
                this.lngs.push({ lang: Object.keys(e)[0], slctd: e[Object.keys(e)[0]].is_selected });
              });
            }
          }
        }
        this.glblSvc.sbjctSpnr.next(false);
      })
  }

  onSave() {
    let payLoad = this.cnfgNotGenData;
    ['createdOn', 'isDeleted', 'id', 'createdBy', 'updatedOn', 'versionNo'].forEach(e => { delete payLoad[e] });
    payLoad.updatedBy = +this.usrLgnDtls?.userId
    this.glblSvc.sbjctSpnr.next(true), this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration,
        environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
          this.glblSvc.onTstr('s', this.result.message), this.LoadData();
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
      this.glblSvc.sbjctSpnr.next(false);
    });
  }

}