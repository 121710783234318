<div class="card p-0 ">
    <div class="card-header shadow-none p-2 border-0">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="top-Bulletin-tab" data-bs-toggle="tab"
                    href="#top-Bulletin" role="tab" aria-controls="top-Bulletin" aria-selected="true">Bulletin
                    boards</a></li>
            <li class="nav-item"><a class="nav-link" id="create-Bulletin-top-tab" data-bs-toggle="tab"
                    href="#top-create-Bulletin" role="tab" aria-controls="top-create-Bulletin"
                    aria-selected="false">Create Bulletin boards</a></li>
            <li class="nav-item"><a class="nav-link" id="Configure-top-tab" data-bs-toggle="tab" href="#top-Configure"
                    role="tab" aria-controls="top-Configure" aria-selected="false">Configure</a></li>
        </ul>
    </div>
    <div class="card-body shadow-none p-2  ">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="top-Bulletin" role="tabpanel" aria-labelledby="top-Bulletin-tab">
                <evin-cnfg-bulletin-board-view></evin-cnfg-bulletin-board-view>
            </div>
            <div class="tab-pane fade" id="top-create-Bulletin" role="tabpanel"
                aria-labelledby="create-Bulletin-top-tab">
                <evin-cnfg-create-bulletin-board></evin-cnfg-create-bulletin-board>
            </div>
            <div class="tab-pane fade" id="top-Configure" role="tabpanel" aria-labelledby="Configure-top-tab">
                <evin-cnfg-bulletin-board-configure></evin-cnfg-bulletin-board-configure>
            </div>
        </div>
    </div>
</div>
<div class="modal " id="modal_1">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title mb-0">Dashboards</h6>
            </div>
            <div class="modal-body card-body">
            </div>
            <div class="modal-footer card-footer">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>