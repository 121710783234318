<form [formGroup]="rlsCrtFrm" autocomplete="off">
    <div class="card">
        <div class="card-body p-2">
            <div class="">
                <div class="">
                    <div class="row">
                        <div class="col-xl-12" class="row">                           
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='1'">
                                <label for="inputEmail4" class="col-form-label">
                                    Issuing Facility <span class="text-danger"><sup>*</sup></span>
                                </label>
                                <ng-template #cstmIsuStore let-model="item">
                                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                    <span class="fs-12">{{model.location}}</span>
                                </ng-template>
                                <input name="store" type="text" placeholder="Issuing Facility"
                                    formControlName="kioskid" [typeahead]="lstGMDSStrs" id="issStore"
                                    [typeaheadItemTemplate]="cstmIsuStore" typeaheadOptionField="storeName"
                                    [adaptivePosition]="true" class="form-control form-control-sm" #IssStore
                                    (typeaheadOnSelect)="changeFn($event, ordrType, 'main', 'slct')"
                                    (change)="changeFn($event, ordrType, 'main', 'chng')" [typeaheadOptionsLimit]="500"
                                    [typeaheadScrollable]="true" [attr.disabled]="usrStrLen==1?true:null">
                            </div>
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='2'">
                                <label for="inputEmail4" class="col-form-label">Receiving Facility <span class="text-danger"><sup>*</sup></span></label>
                                <ng-template #cstmIsuStore let-model="item">
                                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                    <span class="fs-12">{{model.location}}</span>
                                </ng-template>
                                <input name="store" type="text" placeholder="Receiving Facility"
                                    formControlName="vkioskid" [typeahead]="lstLinkedStrs" id="recStore"
                                    [typeaheadItemTemplate]="cstmIsuStore" typeaheadOptionField="storeName"
                                    [adaptivePosition]="true" class="form-control form-control-sm" #RecStore
                                    [typeaheadScrollable]="true" (typeaheadOnSelect)="onChngRecFclty($event, 'main', 'slct')"
                                    (change)="onChngRecFclty($event, 'main', 'chng')" [typeaheadOptionsLimit]="500" >
                            </div>
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='2'">
                                <label for="inputEmail4" class="col-form-label">Issuing Facility <span class="text-danger"><sup>*</sup></span></label>
                                <ng-template #cstmIsuStore let-model="item">
                                    <span class="font-weight-bold">{{model.mapped_store_name}}</span> <br />
                                    <span class="fs-12">{{model.mapped_store_location}}</span>
                                </ng-template>
                                <input name="store" type="text" placeholder="Issuing Facility"
                                    formControlName="kioskid" [typeahead]="lstLinkedStrs" id="issStore"
                                    [typeaheadItemTemplate]="cstmIsuStore" typeaheadOptionField="mapped_store_name"
                                    [adaptivePosition]="true" class="form-control form-control-sm"
                                    [typeaheadScrollable]="true" (typeaheadOnSelect)="changeFn($event, ordrType, 'linked', 'slct')"
                                    (change)="changeFn($event, ordrType, 'linked', 'chng')" [typeaheadOptionsLimit]="500">
                            </div>
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='1'">
                                <label for="inputEmail4" class="col-form-label">Receiving Facility <span class="text-danger"><sup>*</sup></span></label>
                                <ng-template #cstmRecStore let-model="item">
                                    <span class="font-weight-bold">{{model.mapped_store_name}}</span> <br />
                                    <span class="fs-12">{{model.mapped_store_location}}</span>
                                </ng-template>
                                <input name="store" type="text" placeholder="Receiving Facility"
                                    formControlName="vkioskid" [typeahead]="lstLinkedStrs" id="recStore"
                                    [typeaheadItemTemplate]="cstmRecStore" typeaheadOptionField="mapped_store_name"
                                    [adaptivePosition]="true" class="form-control form-control-sm"
                                    [typeaheadScrollable]="true" (typeaheadOnSelect)="onChngRecFclty($event, 'linked', 'slct')"
                                    (change)="onChngRecFclty($event, 'linked', 'chng')" [typeaheadOptionsLimit]="500" >
                            </div>
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='2'">
                                <label for="inputEmail4" class="col-form-label">Receipt reference #</label>
                                <input type="text" class="form-control form-control-sm"
                                    placeholder="Receipt reference" formControlName="transferReferenceNo" />
                            </div>
                            <div class="col-sm-3  mb-3" *ngIf="ordrType=='1'">
                                <label class="col-form-label">Release reference #</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Release reference #"
                                    formControlName="orderReferenceNo" />
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail4" class="col-form-label">Indent Category</label>
                                <select class="form-select form-select-sm" formControlName="badgeId">
                                    <option value="null">-Select-</option>
                                    <option *ngFor="let ot of lstTags" [value]="ot.id">{{ot.name}}</option>
                                </select>
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Expected date of arrival</label>
                                    <div class="input-group mb-3">
                                        <input [dateCustomClasses]="cstmDt" autocomplete="off" onpaste="return false"
                                            bsDatepicker class="form-control form-control-sm optinbg bsDP-bg"
                                            (bsValueChange)="onChngDT($event)" placeholder="Expected date of arrival"
                                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', 
                                                showWeekNumbers: false, isAnimated: true, adaptivePosition: true }" 
                                            #dpDOA="bsDatepicker" formControlName="arrivalDate"  [minDate]="dt2Day"
                                            onkeypress='return event.charCode == 8' type="text" readonly>
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpDOA.toggle()"
                                                [attr.aria-expanded]="dpDOA.isOpen">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpDOA.hide();clearBSDate('arrivalDate')">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                            </div>
                            <div class="col-sm-3  mb-3">
                                <label for="inputEmail3" class="col-form-label">Comments</label>
                                <textarea class="form-control form-control-sm taRS" formControlName="comments" placeholder="Comments"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card shadow">
        <div class="card-header p-2"><b>Choose products</b></div>
        <div class="card-body p-2">
            <div class="table-responsive table_spacing">
                <table class="table table-striped table-sm table-bordered table1" formArrayName="productBookings">
                    <thead>
                        <tr>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Product</th>
                            <th class="text-center">Available stock <br><small>{{str}}</small></th>
                            <th class="text-center">Available stock <br><small>{{GetMatrls().controls.length>0?str2:''}}</small></th>
                            <th class="text-center w-40">Quantity</th>
                            <th class="text-center">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of GetMatrls().controls;let tabIndx=index;" [formGroupName]="tabIndx">
                        <tr>
                            <td class="text-center">{{tabIndx+1}}</td>
                            <td class="text-center">{{item?.get('name')?.value}}</td>
                            <td class="text-center" [ngStyle]="{ 'backgroundColor': item?.get('stockIndicator')?.value != null ? item?.get('stockIndicator')?.value.backgroundColor : null,
                            'color': item?.get('stockIndicator')?.value != null ? '#fff' : null }">
                                {{item?.get('ts')?.value}}<br />
                                <small>In transit: {{item?.get('intransitStock')?.value}}</small><br />
                                <small title="(min, max)">({{item?.get('invMin')?.value}}, {{item?.get('invMax')?.value}})</small>
                            </td>
                            <td class="text-center">
                                {{item?.get('rcvngStoreAvlStock')?.value}}<br />
                                <small>In transit: {{item?.get('rcvngStoreIntransitStock')?.value}}</small><br />
                                <small title="(min, max)">({{item?.get('rcvngStoreMinStock')?.value}}, {{item?.get('rcvngStoreMaxStock')?.value}})</small>
                            </td>
                            <td class="text-center">
                                <div class="row">
                                    <div class="col-xl-3"></div>
                                    <div class="col-xl-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control form-control-sm text-center" formControlName="quantity"
                                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' (keyup)="onEditQty(item)"
                                                [ngClass]="{ 'is-invalid': isSbmtd && item?.get('quantity')?.errors }">
                                            <div *ngIf="isSbmtd && item?.get('quantity')?.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="item?.get('quantity')?.errors?.required">Please fill Quantity</div>
                                                <div *ngIf="item?.get('quantity')?.errors?.min">Minimum Quantity is 1</div>
                                                <div *ngIf="item?.get('quantity')?.errors?.pattern">Quantity should be multiple of 10</div>
                                                <div *ngIf="item?.get('quantity')?.errors?.minlength">Min Length is 1</div>
                                                <div *ngIf="item?.get('quantity')?.errors?.maxlength">Max Length is 10</div>
                                            </div> <br />
                                            <div *ngIf="item?.get('recommendedQuantity')?.value != item?.get('quantity')?.value">
                                                <span class="txt-red">Recommended: {{item?.get('recommendedQuantity')?.value}}</span><br />
                                                <label>Reason<span class="text-danger" *ngIf="isRsnMndtry && (item?.get('recommendedQuantity')?.value != item?.get('quantity')?.value)"><sup>*</sup></span></label>
                                                <select class="form-select form-select-sm" formControlName="reason"
                                                    [ngClass]="{ 'is-invalid': isSbmtd && item?.get('reason')?.errors }">
                                                    <option value="">Select Reason</option>                                                    
                                                    <option [value]="rs.reasonId" *ngFor="let rs of rsnsLst">{{rs.reasonName}}</option>
                                                </select>
                                                <div *ngIf="isSbmtd && item?.get('reason')?.errors" class="invalid-feedback">
                                                    <div *ngIf="isSbmtd && item?.get('reason')?.errors?.required">Please choose Reason</div>
                                                </div> <br />
                                                <textarea *ngIf="item?.get('reason')?.value=='13'"
                                                    class="form-control form-control-name taRS"
                                                    [ngClass]="{ 'is-invalid': isSbmtd && (item?.get('reason')?.value == '13') &&
                                                        (item?.get('others')?.value == '') }"
                                                    placeholder="Enter Reason" formControlName="others"></textarea>
                                                <div *ngIf="isSbmtd && (item?.get('reason')?.value == '13') &&
                                                    (item?.get('others')?.value == '')" class="invalid-feedback">
                                                    <div>Please fill Reason</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-center">
                                <a (click)="onDelMtrls(tabIndx, item?.get('name')?.value)">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="lstMats.length==0">
                        <tr>
                            <td colspan="8" class="text-left" style="padding-left: 5% !important;">{{facSlctTxt}}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-striped table-sm table-bordered table-design" [hidden]="!issFacId">
                    <tbody>
                        <tr>
                            <td></td>
                            <td colspan="3">
                                <ng-template #matItem let-model="item">
                                    <span class="font-weight-bold">{{model.productName}}</span>
                                </ng-template>
                                <input name="mat" autocomplete="off" placeholder="Type Material name to choose"
                                    type="text" [typeahead]="lstMats" [typeaheadItemTemplate]="matItem"
                                    typeaheadOptionField="productName" [typeaheadScrollable]="true"
                                    class="form-control form-control-sm w-50" [attr.disabled]="recFacId==''?true:null"
                                    (typeaheadOnSelect)="slctdMat($event)" id="srchMtrl"
                                    formControlName="srchMtrl" [adaptivePosition]="true" [typeaheadOptionsLimit]="500">
                            </td>
                            <td colspan="3"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="float-end mt-2" *ngIf="GetMatrls().controls.length!=0 && issFacId">
                <button type="button" class="btn btn-sm btn-light" (click)="onClrData();onReset();">Reset</button>&nbsp;
                <button type="button" class="btn btn-sm btn-primary" (click)="isSbmtd=true;onCreateRlsOrdr(ordrType)">Submit</button>
            </div>
        </div>
    </div>
</form>