<form [formGroup]="bulkUploadForm" autocomplete="off">
    <div class="card mt-3">
        <div class="card-body p-4">
            <div class="row">
                <div class="col-xl-6">
                    <!-- <div><span><i class="fa fa-upload"
                            aria-hidden="true"></i></span>&nbsp;&nbsp;<strong>Last
                        upload:</strong>&nbsp;&nbsp;<a>users_GMSD_Mgr.csv</a></div>
                <p class="small ms-4">on 14/7/16 1:53 PM by <a>Devdutt</a></p> -->
                </div>
                <div class="col-xl-6">
                    <span class="me-3"  *ngIf = "uploadStatusResult"><strong>Status:</strong>&nbsp;&nbsp;{{uploadStatus}}</span>
                    <span class="me-3" *ngIf = "uploadResult">
                        <a (click)="onDwnld()" style="color:#5d5757"><strong>Success:</strong>&nbsp;&nbsp; <span
                            class="text-success">{{successCount}}</span></a></span>
                    <span class="me-3" *ngIf = "uploadResult">
                        <a (click)="onDwnld()" style="color:#5d5757"><strong>Errors:</strong>&nbsp;&nbsp; <span class="text-danger">{{errorCount}} </span></a>
                        <!-- <a data-bs-toggle="modal" data-bs-target="#modal_error"></a> -->
                    </span>
                    <!-- <span class="me-3"><i class="fa fa-refresh" aria-hidden="true"></i></span> -->
                </div>
            </div>
        </div>
    </div>
<div class="card border-0">
    <div class="card-body p-0">
        <div>Select CSV file for {{uploadName}} </div>
        <div class="row">
            <div class="btn-group mt-2 col-xl-9 p-2">
                <input class="form-control form-control-sm" type="file" name="uploadedFile" aria-label="file example" formControlName="uploadedFile"
                  accept=".csv, text/csv"
                  (change)="onFileSelect($event)"  required="">
            </div>
            <div class="btn-group mt-2 col-xl-3 p-2">
                <button type="button" class="btn btn-sm btn-primary me-2" (click) = "uploadFile()">upload</button>
                <button type="reset" class="btn btn-sm btn-light" (click)="onReset()">Reset</button>
            </div>
            <hr />
            <h6><span class="border-bottom">Tips for filling data file:</span></h6>
            <ul>
                <li>Review the instructions in the data file header to ensure correctness</li>
                <li>Star (*) implies mandatory fields</li>
                <li>Values having commas (,) should be quoted (") [NOT required if entering in Excel]
                </li>
            </ul>
            <hr />
            <h6><span class="border-bottom">Instructions</span></h6>
            <div>Create, modify or delete items in bulk by uploading a file containing relevant data in
                Comma-Separated Value (CSV) format (one item per line). You can download a formatted CSV
                spreadsheet below, review instructions in the header, fill it up with data (one item per
                line), save it as a CSV file, and upload the CSV file.</div>
            <div class="mt-2">On completion of upload, you can review the status of your upload on this
                page. Bulk import of data will take time, so refresh the status using the 'refresh' link
                after giving sufficient time. Subsequently, verify that the data has been created
                correctly, as required.</div>
            <a href="{{smplDwnldFle}}" target="_blank" class="btn btn-sm btn-success col-xl-4 col-sm-6 m-3"><i class="fa fa-download"
                    aria-hidden="true"></i>&nbsp;&nbsp;Download data file format for {{uploadName}}</a>
        </div>
    </div>
</div>
</form>