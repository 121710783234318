<div>
    <router-outlet></router-outlet>
    <div class="row mt-1">
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Inventory </h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-pie-chart"></i>
                                                Pie chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-bar-chart"></i>
                                                Bar chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-line-chart"></i>
                                                Line Charts </a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-circle-thin"></i>
                                                Doughnut Charts </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown ">
                                        <a class="dropbtn  pl-1 pr-1 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-file"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-file-pdf-o"></i>
                                                Export PDF</a>
                                            <a [routerLink]="" class="small pt-1 pb-1">
                                                <i class="fa fa fa-file-excel-o"></i>
                                                Export Excel</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-file-word-o"></i>
                                                Export Word</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample12"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i
                                                class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Material category(s):</span> RI Vaccines,
                                <span class="font-weight-bold">Period:</span> 0 day(s)
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample12">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <label class="col-form-label">Include category(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="">
                                                    <label class="col-form-label">Exclude category(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block ">
                    <canvas id="myBarGraph"></canvas>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Temperature</h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-pie-chart"></i>
                                                Pie Chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-bar-chart"></i>
                                                Bar Chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-line-chart"></i>
                                                Line Charts</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-circle-thin"></i>
                                                Doughnut Charts </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown ">
                                        <a class="dropbtn  pl-1 pr-1 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-file"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-file-pdf-o"></i>
                                                Export PDF</a>
                                            <a [routerLink]="" class="small pt-1 pb-1">
                                                <i class="fa fa fa-file-excel-o"></i>
                                                Export Excel</a>
                                            <a [routerLink]="" class="small pt-1 pb-1"><i class="fa fa-file-word-o"></i>
                                                Export Word</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample13"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Exclude Temparature status:</span>Unknown
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample13">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <label class="col-form-label">Include category(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="">
                                                    <label class="col-form-label">Exclude category(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body apex-chart">
                    <div id="donutchart"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-header pb-2 pt-2 pl-4 pr-4">
                    <h5 class="mb-0">Inventory </h5>
                    <p class="mb-0">Stock: Normal, Material category(s): RI Vaccines, Period: 0
                        day(s)</p>
                </div>

                <div class="card-body p-2 mt-3 border-0 row">
                    <div class="col-xl-8 col-sm-12 col-xs-12 p-1">
                        <div class="float-end">
                            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                                <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab"
                                        data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                        aria-selected="true"><i class="fa fa-desktop me-0" aria-hidden="true"></i></a>
                                </li>
                                <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                                        data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                                        aria-selected="false"><i class="fa fa-bar-chart me-0"
                                            aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                        <h5 class="mb-4 text-center">By location </h5>

                        <div class="tab-content " id="top-tabContent">
                            <!-- tab1 start -->
                            <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                                <div class="row">
                                    <div class="col-xl-12 centered-div">
                                        <div id="fc-Ind"></div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="float-end">
                                            <div class="dropdown-basic p-0">
                                                <div class="dropdown p-0">
                                                    <a class="dropbtn pl-0 pr-0 text-secondary"
                                                        style="padding:5px !important;">
                                                        <span class="f-20"><i class="fa fa-bars"
                                                                aria-hidden="true"></i></span></a>
                                                    <div class="dropdown-content card border-0"
                                                        style="top:22px !important; right: 0; left: unset;">
                                                        <a class="small pt-1 pb-1" type="button">Export as PNG</a>
                                                        <a class="small pt-1 pb-1" type="button">Export as JPG</a>
                                                        <a class="small pt-1 pb-1" type="button">Export as PDF</a>
                                                        <a class="small pt-1 pb-1" type="button">Export as SVG</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12 ">
                                        <!-- <img class="img-fluid" src="./assets/img/Screenshot 2021-04-27-103959.jpg" alt=""> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-12 col-xs-12 p-1">
                        <div class="card-body pt-0">
                            <h5 class="mb-4 text-center">By material</h5>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="float-end">
                                        <div class="dropdown-basic p-0 me-1">
                                            <div class="dropdown p-0">
                                                <a class="dropbtn pl-0 pr-0 text-secondary"
                                                    style="padding:5px !important;">
                                                    <span class="f-20"><i class="fa fa-bars"
                                                            aria-hidden="true"></i></span></a>
                                                <div class="dropdown-content card border-0"
                                                    style="top:22px !important; right: 0; left: unset;">
                                                    <a class="small pt-1 pb-1" type="button">Export as PNG</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as JPG</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as PDF</a>
                                                    <a class="small pt-1 pb-1" type="button">Export as SVG</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="float-xl-12"></div>
                            <div id="basic-bar"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>