<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="form-group col-xl-3">
        <label for="inputState-43" class="form-label">Activity</label>
        <select id="inputState-43" class="form-select">
            <option value="">Number of logins</option>
            <option>Number of transactions</option>
            <option>Number of Indents</option>
        </select>
    </div>
    <div class="form-group col-xl-3">
        <label for="inputState-43" class="form-label">Metric</label>
        <select id="inputState-43" class="form-select">
            <option value="">All</option>
            <option>Issues/Net utilization</option>
            <option>Receipts</option>
            <option>Stock Counts</option>
            <option>Discards</option>
            <option>Incoming returns</option>
            <option>Outgoing returns</option>
        </select>
    </div>
    <div class="col-xl-6 text-end">
        <div class="float-end">
            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab" data-bs-toggle="tab"
                        href="#Map" role="tab" aria-controls="Map" aria-selected="true"><i class="fa fa-bar-chart me-0"
                            aria-hidden="true"></i></a></li>
                <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab" data-bs-toggle="tab"
                        href="#Map-1" role="tab" aria-controls="Map-1" aria-selected="false"><i class="fa fa-table me-0"
                            aria-hidden="true"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="tab-content" id="top-tabContent">
        <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="card p-0 mb-0">
                        <div class="card-body p-2">
                            <div class="row mt-3 mb-3">
                                <div class="col-xl-12">
                                    <div class="text-center">
                                        <h6 class="mb-0">Number of logins</h6>
                                        <small>From: Mar 2021 To: May 2021 User tag: DIO</small>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-8 offset-xl-2  centered-div">
                                    <div class="text-center">
                                        <div id="fcbarInv" class="text-center"></div>
                                    </div>
                                </div>
                                <div class="centered-div mt-3 text-center">
                                    <a>Download as CSV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane fade " id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
            <div class="row mt-4">
                <div class="col-xl-12">
                    <div class="text-center">
                        <h6 class="mb-0">Number of transactions - All</h6>
                        <small>From: Jun 2020 To: May 2021 User: A A Bayad</small>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-8 offset-xl-2 table-responsive table_spacing">
                    <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
                        <thead>
                            <tr>
                                <th class="col-auto">Date</th>
                                <th class="col-auto">Issues/Net utilization </th>
                                <th class="col-auto text-center">Receipts</th>
                                <th class="col-auto text-center">Stock Counts</th>
                                <th class="col-auto text-center">Discards</th>
                                <th class="col-auto text-center">Incoming returns</th>
                                <th class="col-auto text-center">Outgoing returns</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>May 2021</div>
                                </td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Apr 2021</div>
                                </td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Mar 2021</div>
                                </td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Feb 2021</div>
                                </td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>Jan 2021</div>
                                </td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="centered-div mt-3"><a>Download as CSV</a></div>
            </div>
        </div>
    </div>
</div>