<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-xl-12">
                <div class="row ">
                    <div class="col=xl-12 ">
                        <div class="float-xl-end">
                            <small>As of 19/9/20 12:00 PM</small>
                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="form-group col-xl-3">
                        <label for="inputState-2" class="form-label">Metric</label>
                        <select id="inputState-2" class="form-select">
                            <option value="">% of time power is availble</option>
                            <option>Number of powercuts</option>
                            <option>Average duration in this state per asset (in days)</option>
                        </select>
                    </div>
                    <div class="col-xl-9 text-end">
                        <!-- <div class="float-end">
                            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
                        </div> -->
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-xl-12">
                        <div class="text-center">
                            <h6 class="mb-0">% of time power is available</h6>
                            <small>From: Feb 2021 To: Apr 2021 Monitoring type: Monitoring </small>
                        </div>
                    </div>
                </div>
                <div class="row mt-2 mb-3">
                    <div class="col-xl-8 offset-xl-2 table-responsive table_spacing">
                        <table class="table table-striped table-sm table-bordered border-primary f-14">
                            <thead>
                                <tr>
                                    <th class="col-auto">Item No.</th>
                                    <th class="col-auto">Facility</th>
                                    <th class="col-auto text-center">Feb 2021</th>
                                    <th class="col-auto text-center">Mar 2021</th>
                                    <th class="col-auto text-center">Apr 2021</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>
                                        <div><a [routerLink]="">1100 Quarters UPHC ABC</a></div>
                                        <small>yousufguda, Hyderabad, Telangana</small>
                                    </td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                    <td class="text-center">0</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>