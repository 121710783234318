import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-actiual-data-of-transaction',
  templateUrl: './actiual-data-of-transaction.component.html',
  styleUrls: ['./actiual-data-of-transaction.component.css']
})

export class ActiualDataOfTransactionComponent extends BaseComponent implements OnInit {

  actulDateOfTxnFrm: FormGroup = this.fb.group({}); lstGrid: any[] = []; lstTxnTyps: any[] = []; txnType: any; dt2day = new Date();
  frmDate: any; isMatBdge = 'badge'; slctdMat: any[] = []; slctdMatName: any[] = []; lstMatTags: any[] = []; lstAllMats: any[] = [];
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; lstSlctdMatNames: any[] = []; lstSlctdMatTags: any[] = []; isSbmtd = false; slctdFac: any[] = [];
  locId: any = ''; lstGrid12: any[] = []; usrLvl = ''; isLevl = false; isDstLvlExprt: any = false; lstGrid1: any[] = []; lstDTs: any[] = [];
  lvl: any = ''; cntryId: any = ''; stateId: any = ''; distId: any=''; pageEntrs = ''; crntPage = 1; isExprt = false;
  pageSize = environment.tblPageSize; totalItems: any; isCcpLvl: any = false; isDstLvl: any = false; isCcpLvlExprt: any = false;
  isUsrLvlClck: boolean =false; lstSlctdFacTags: any[] = []; lstFacTags: any[] = []; isBredCrumNatnl: any = ''; isBredCrumState: any = '';
  isBredCrumDist: any = ''; isStateId: any; locName: any = ''; isBackToCountry :boolean = false; isBackToState :boolean = true; lstTtl: any[] = [];
  stsLst: any[] = []; dstLst: any[] = []; dfltTransctnTpe: any = ''; dfltStrTags ='';

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product), 
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility) }),
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.lstTxnTyps = s.txnTypeLst });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.dstLst = s.dstLst });
    this.glblSvc.sbjctCnfgData.subscribe(s => { this.dfltTransctnTpe = s.cnfgDBData?.date_of_actual_transaction?.default_transaction_type_filter, 
      this.dfltStrTags = s.cnfgDBData?.date_of_actual_transaction?.default_store_tag_filter});
  }

  ngOnInit(): void {
    this.txnType = this.dfltTransctnTpe == 0 ? 'null': this.dfltTransctnTpe, this.actulDateOfTxnFrm = this.fb.group({ productId: [[], Validators.required], transType: [''],
      todate: ['', Validators.required], isMatBdge: ['badge'], productBadgeId: [[], Validators.required], storeBadgeId: [[], Validators.required] });
    if (this.usrLgnDtls) {
      this.cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null;      
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '';
    }
    // if(this.dfltStrTags.length> 0){
    //    let lstMTR = this.lstFacTags.filter(a => a.id == this.dfltStrTags)[0];
    // this.slctdFac.push(lstMTR); this.lstSlctdFacTags.push(lstMTR);
    // }
    if(this.dfltStrTags.length>0){ let lstFclty: any[]=[];
      this.lstFacTags.filter(a => { if ( this.dfltStrTags.includes(a.id)) { lstFclty.push(a) }})
      this.actulDateOfTxnFrm.get('storeBadgeId')?.setValue(lstFclty);
      lstFclty.forEach(e => {this.lstSlctdFacTags.push({id: e.id, name: e.name}), 
     this.slctdFac?.push(e)});
    }

    // this.actulDateOfTxnFrm.get('transType')?.setValue([this.dfltTransctnTpe]); this.txnType = this.dfltTransctnTpe;
    if(this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) 
      this.isBredCrumNatnl = this.locName = this.usrLgnDtls.country, this.isBackToCountry = true;
      if (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null)
        this.stateId = this.slctDmnDetails?.stateId ?? null, this.isBredCrumNatnl = this.locName = this.stsLst.filter((e: any) =>e.id == this.slctDmnDetails?.stateId)[0]?.name, this.isBackToCountry = true;
      if (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null)
        this.distId = this.slctDmnDetails?.districtId ?? null, this.isBredCrumDist = this.locName = this.dstLst.filter((e: any) =>e.id == this.slctDmnDetails?.districtId)[0]?.name, this.isBackToCountry = true;
    this.isLevl = (this.usrLvl == 'National' || this.usrLvl == 'State');  //this.usrLvl == 'District'
  }

  get fc() { return this.actulDateOfTxnFrm.controls; }

  clearBSDate(ctrl: string) {
    this.actulDateOfTxnFrm.get(ctrl)?.setValue('');
  }

  onClkRdb(ctrl: string) {
    this.fc?.productBadgeId.setValue([]), this.fc?.productId.setValue([]); this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
    if (ctrl == 'productBadgeId')
      this.fc?.productId?.clearValidators(), this.fc?.productBadgeId?.setValidators([Validators.required]), this.lstSlctdMatNames = [];
    else
      this.fc?.productBadgeId?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]),
    this.fc?.productBadgeId?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'm') {
        if (this.lstSlctdMatNames.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      } else if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f:any) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'm')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter(f => f.id != item.id);
      else if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: any) => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'm')
        this.lstSlctdMatNames = this.lstAllMats;
      else if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'm')
        this.lstSlctdMatNames = [];
      else if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  LoadGrid(type:string) {
    if (this.isBredCrumState !=''){ $('#isAct').addClass('active') };
    if (((this.isBackToCountry == true && this.isBackToState == true) || type == 'p') ) {
      let crntPgIndx = this.crntPage - 1, payLoad = this.actulDateOfTxnFrm.getRawValue(), mT: any[] = [], mN: any[] = [], fT: any[] = [];
      if ((this.fc.productId.value != '' || this.fc.productBadgeId.value != '') && this.fc.todate.value != '' && this.lstSlctdFacTags.length > 0) {
        delete payLoad.isMatBdge, payLoad.productBadgeId = payLoad.productBadgeId != [] && payLoad.productBadgeId != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productBadgeId = mT;payLoad.productId =
        payLoad.productId != [] && payLoad.productId != null ? this.lstSlctdMatNames?.forEach((e: any) => { mN.push(e.id) }) : [],
        payLoad.productId = mN; payLoad.storeBadgeId = payLoad.storeBadgeId.length > 0 && payLoad.storeBadgeId != null ? 
        this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeBadgeId = fT;
        payLoad.transType = (payLoad.transType != '' && payLoad.transType != null &&
        payLoad.transType != undefined) ? +payLoad.transType : null; payLoad.todate = payLoad.todate != '' &&
        payLoad.todate != null ? this.glblSvc.setDtFrmt(payLoad.todate, this.dtF.ymd) + this.tmF.noTM : '';
        payLoad.countryId = this.slctDmnDetails?.countryId, payLoad.stateId = this.slctDmnDetails?.stateId, payLoad.districtId = this.slctDmnDetails?.districtId,
        payLoad.pranthHierarchy = this.slctDmnDetails;
        if (this.locId == '')
            ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
              this.slctDmnDetails?.districtId == null) ? (payLoad.locationId = this.slctDmnDetails?.countryId, payLoad.level = 'n') :
              ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) ?
                (payLoad.locationId = this.slctDmnDetails?.stateId, payLoad.level = 's') : (payLoad.locationId = this.slctDmnDetails?.districtId, payLoad.level = 'd'))),
                this.isUsrLvlClck = false,
           this.locName = (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) ? 
           this.usrLgnDtls.country : this.chsnDmnName, this.isBackToCountry = false, this.isBackToState = true,
           (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) ? 
           (this.isBredCrumNatnl = this.usrLgnDtls.country) : ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null &&
            this.slctDmnDetails?.districtId == null) ? (this.isBredCrumNatnl = this.chsnDmnName) : this.isBredCrumDist = this.chsnDmnName);
        else if ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) && this.locId != '')
          payLoad.locationId = this.locId, payLoad.level = 'd', this.isBredCrumDist = this.locName, this.isUsrLvlClck = true;
        else if (this.isCcpLvl == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null) && this.locId != '')
          payLoad.locationId = this.locId, payLoad.level = 'd', this.isDstLvl = true, this.isDstLvlExprt =false, this.isCcpLvlExprt = true,
          this.isBredCrumDist = this.locName, this.isUsrLvlClck = true, this.isBackToState = true;
        else {
          if (type == 'a' || type == 'p')
            this.isStateId = payLoad.locationId = this.locId, payLoad.level = 's', this.isDstLvlExprt =true, this.isCcpLvlExprt = false, this.isDstLvl = false,
              this.isBredCrumState = this.locName, this.isUsrLvlClck = false, this.isBackToState = false, this.isBackToCountry = true, this.isCcpLvl = true;
          else
            payLoad.locationId = this.isStateId, payLoad.level = 's', this.isDstLvlExprt = true, this.isCcpLvlExprt = false, this.isBredCrumState, this.isDstLvl = false,
              this.isCcpLvl = true, this.isUsrLvlClck = false, this.isBackToState = false, this.isBackToCountry = true;
        }
          //payLoad.locationId = this.locId, payLoad.level = 's',  this.isDstLvlExprt =true, this.isCcpLvlExprt = false, this.isUsrLvlClck = false;
        this.lstGrid = [], this.lstDTs = [], this.lstTtl = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.
            actulDataOfTrns.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 5).then(() => {
          if (this.result) {
            if (this.result?.data) {
              let apiRslt: any[] = this.result?.data?.avtiviyData ?? [], ttlRslt: any[] = this.result?.data?.totalValue?.Total ?? [];
              this.totalItems = this.result.data?.totalCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                this.totalItems);
              if (apiRslt.length > 0)
                apiRslt[0].data.forEach((e: any) => { this.lstDTs.push(Object.keys(e)[0]); });
              apiRslt.forEach((e, i) => { let vals: any[] = [], ttVals: any[] = [], vI = 3, clrObj = 0;// res: any[] = [0, 1, 2], ttt: any[] = [3, 4, 5];
                e.data.forEach((d: any, i: number) => { d[this.lstDTs[i]].forEach((dt: any, iV: number) => { if (iV < vI) vals.push(dt);
                  if (iV == vI) clrObj = clrObj + 1; if (iV >= vI) ttVals.push({'values':dt, 'clrType': clrObj}); });  }), this.lstGrid.push({ id: e.id, badgeName: e.badgeName, name: 
                    e.name, lstVals: vals, lstTTTs: ttVals}); });
                    let ttlprs: any[] = [], ttlVals: any[] = [], vI = 3;

              if (this.isBredCrumDist !='' || this.isCcpLvl == true || (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && 
                this.slctDmnDetails?.districtId == null) || (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null)) {
                ttlRslt.forEach((e, i) => {
                  e[this.lstDTs[i]].forEach((dt: any, iV: number) => {
                    if (iV < vI) ttlprs.push(dt);
                    if (iV >= vI) ttlVals.push(dt);
                  });
                });
                this.lstTtl.push({ lstVals: ttlprs, lstTTTs: ttlVals });
              }

            }
            else if (this.result?.data == null)
              this.glblSvc.onTstr('i', this.result.message);
              }
            });
      } else
        this.glblSvc.onTstr('w', 'Please choose Mandatory fields');
    }
    else {
      if (this.isBredCrumState != '')
        this.isBredCrumDist == '' ? this.isCcpLvl = true : this.isCcpLvl = false;
      if (this.isBredCrumState == '' && this.isBredCrumDist == '')
        this.isCcpLvl = false;
    }
  }

  onLocDetails(name: string, id: any, e: any) {
    if (this.isUsrLvlClck == false) {
      this.isBackToCountry = true, this.isBackToState = true,// this.isBredCrumState == ''? this.isBackToState = true: this.isBackToState = false ,
      this.isBredCrumDist == ''? this.isBackToState = true :this.isBackToState = false;
      if (name != '' && this.isLevl == true && this.isUsrLvlClck == false) {
        this.crntPage=1, this.locName = name, this.locId = id, this.LoadGrid('a'), this.isCcpLvl = true, this.isDstLvlExprt = true;
        if (this.isExprt == true)
          this.locId = id, this.onEE(), this.isExprt = false;
      }
    } else  
    e.preventDefault();
  }

  onReset() {
    this.lstSlctdMatNames = [], this.lstSlctdMatTags = [], this.txnType = null, this.locId = '',this.txnType = this.dfltTransctnTpe == 0? 'null': this.dfltTransctnTpe,
    this.lstSlctdFacTags = [...[]], this.slctdFac = [], this.isBackToState = true; this.isBackToState = true;
    this.actulDateOfTxnFrm.get('isMatBdge')?.setValue('badge'), this.actulDateOfTxnFrm.get('productBadgeId')?.setValue([]),
    this.actulDateOfTxnFrm.get('productId')?.setValue([]), this.actulDateOfTxnFrm.get('todate')?.setValue(''),
    this.actulDateOfTxnFrm.get('storeBadgeId')?.setValue([]); // this.actulDateOfTxnFrm.get('transType')?.setValue(null);
    if(this.dfltStrTags.length>0){ let lstFclty: any[]=[];
      this.lstFacTags.filter(a => { if ( this.dfltStrTags.includes(a.id)) { lstFclty.push(a) }})
      this.actulDateOfTxnFrm.get('storeBadgeId')?.setValue(lstFclty);
      lstFclty.forEach(e => {this.lstSlctdFacTags.push({id: e.id, name: e.name}), 
     this.slctdFac?.push(e)});
    }

  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, (this.isBredCrumDist=='' && this.isCcpLvl == true)? this.isCcpLvl = false : this.isCcpLvl = true, this.LoadGrid('p');
  }

  onEE() {
    let payLoad = this.actulDateOfTxnFrm.getRawValue(), mT: any[] = [], mN: any[] = [], fT: any[] = []; //this.isExprt = true;
    delete payLoad.isMatBdge, payLoad.productBadgeId = payLoad.productBadgeId != [] && payLoad.productBadgeId != null ?
    this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productBadgeId = mT;payLoad.productId =
    payLoad.productId != [] && payLoad.productId != null ? this.lstSlctdMatNames?.forEach((e: any) => { mN.push(e.id) }) : [],
    payLoad.productId = mN; payLoad.storeBadgeId = payLoad.storeBadgeId.length > 0 && payLoad.storeBadgeId != null ? 
    this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeBadgeId = fT; payLoad.transType = 
    (payLoad.transType != '' && payLoad.transType != null && payLoad.transType != undefined) ? +payLoad.transType : null; payLoad.todate = 
    payLoad.todate != '' && payLoad.todate != null ? this.glblSvc.setDtFrmt(payLoad.todate, this.dtF.ymd) + this.tmF.noTM : '';
    payLoad.countryId = this.slctDmnDetails?.countryId, payLoad.stateId = this.slctDmnDetails?.stateId, payLoad.districtId =
      this.slctDmnDetails?.districtId, payLoad.pranthHierarchy = this.slctDmnDetails;
    if (this.locId == '')
      ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
        this.slctDmnDetails?.districtId == null) ? (payLoad.locationId = this.cntryId, payLoad.level = 'n') :
        ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) ?
          (payLoad.locationId = this.stateId, payLoad.level = 's') : (payLoad.locationId = this.distId, payLoad.level = 'd')));
    else if ((this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null) && this.locId != '')
      payLoad.locationId = this.locId, payLoad.level = 'd';
    else if (this.isCcpLvlExprt == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) && this.locId != '')
      payLoad.locationId = this.locId, payLoad.level = 'd', this.isDstLvlExprt = true;
    else if (this.isDstLvlExprt == true && (this.slctDmnDetails?.countryId != null && this.slctDmnDetails?.stateId == null &&
      this.slctDmnDetails?.districtId == null) && this.locId != '')
      payLoad.locationId = this.locId, payLoad.level = 's', this.isDstLvlExprt = true;
    else
      payLoad.locationId = this.locId, payLoad.level = 's';
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.actulDataOfTrnsExpt, payLoad, 5).then(() => {
      if (this.result) {
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      }
    })
  }

}