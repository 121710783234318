<div class="table-responsive">
    <table class="table table-striped table-bordered mt-5" id="basic">
        <thead>
            <tr>
                <th class="text-center">Sl. No.</th>
                <th class="text-center" rowspan="2">Domain</th>
                <th class="text-center" rowspan="2">Users</th>
                <th class="text-center" colspan="3">Facilities</th>
                <th class="text-center" colspan="3">Monitored assets</th>
                <th class="text-center" rowspan="2">Monitoring assets</th>
            </tr>
            <tr>
                <th class="text-center">Facilities</th>
                <th class="text-center">Live
                    <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                        title="Facilities with at least one transaction since creation">
                        <!-- title="Eshwar&#013;Dev" -->
                        <i class="fa fa-info-circle" id="popoverOption"
                            data-placement="top" rel="popover">
                        </i>
                    </sup>
                </th>
                <th class="text-center">Active
                    <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                        title="Facilities with at least one transaction in the last 30 days">
                        <i class="fa fa-info-circle" id="popoverOption"
                            data-placement="top" rel="popover">
                        </i>
                    </sup>
                </th>
                <th class="text-center">Assets</th>
                <th class="text-center">Live
                    <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                        title="Eligible monitored assets that have received at least one temperature data point since creation">
                        <i class="fa fa-info-circle" id="popoverOption"
                            data-placement="top" rel="popover">
                        </i>
                    </sup>
                </th>
                <th class="text-center">Active
                    <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                        title="Eligible monitored assets with at least one temperature data point in the last 30 days">
                        <i class="fa fa-info-circle" id="popoverOption"
                            data-placement="top" rel="popover">
                        </i>
                    </sup>
                </th>
            </tr>
        </thead>
        <tr>
            <td></td>
            <td>TOT National</td>
            <td class="text-center">21961</td>
            <td class="text-center"><span [popover]="myPopover1" [popoverOnHover]="true">21161</span></td>
            <td class="text-center"><span [popover]="myPopover2"
                [popoverOnHover]="true">14309</span><br /><small>68%</small></td>
            <td class="text-center"><span [popover]="myPopover3"
                [popoverOnHover]="true">1149</span><br /><small>5%</small></td>
            <td class="text-center"><span [popover]="myPopover4" [popoverOnHover]="true">1944</span></td>
            <td class="text-center"><span [popover]="myPopover5" [popoverOnHover]="true">1</span><br /><small>0%</small>
            </td>
            <td class="text-center"><span [popover]="myPopover" [popoverOnHover]="true">0</span><br /><small>0%</small></td>
            <td class="text-center">359</td>
        </tr>
        <tr data-node-id="1" *ngFor="let a of lstAllactivities; let i = index">
            <td>{{i+1}}</td>
            <td><a>{{a.pranth_name}}</a></td>
            <td class="text-center">{{a.user_count}}</td>
            <td class="text-center">{{a.store_count}}</td>
            <td class="text-center">{{a.live_store}}<br /><small>{{a.live_store_percentage ? a.live_store_percentage + ' %' : ''}}</small></td>
            <td class="text-center">{{a.active_store}}<br /><small>{{a.active_store_percentage ? a.active_store_percentage + ' %' : ''}}</small></td>
            <td class="text-center">{{a.monitored_asset}}</td>
            <td class="text-center">{{a.live_asset}}<br /><small>{{a.live_asset_percentage ? a.live_asset_percentage + ' %' : ''}}</small></td>
            <td class="text-center">{{a.active_asset}}<br /><small>{{a.active_asset_percentage ? a.active_asset_percentage + ' %' : ''}}</small></td>
            <td class="text-center">{{a.monitoring_asset}}</td>
        </tr>
    </table>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover placement="left top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Monitored assets - Active</th>
                </tr>
            </thead>
            <tr>
                <td>Walk-in freezer</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>Deep freezer</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>Walk-in cooler</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>ILR</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover1 placement="right top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Facilities</th>
                </tr>
            </thead>
            <tr>
                <td>DVS</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>SVS</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>CVS</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>CCP</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover2 placement="right top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Facility - live</th>
                </tr>
            </thead>
            <tr>
                <td>DVS</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>SVS</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>CVS</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>CCP</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover3 placement="right top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Facility - Active</th>
                </tr>
            </thead>
            <tr>
                <td>DVS</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>SVS</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>CVS</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>CCP</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover4 placement="right top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Monitored assets</th>
                </tr>
            </thead>
            <tr>
                <td>Walk-in freezer</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>Deep freezer</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>Walk-in cooler</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>ILR</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>
<div class="container text-center mb-4">
    <popover-content #myPopover5 placement="right top" [animation]="true" [closeOnClickOutside]="false"
        [closeOnMouseOutside]="false">
        <table class="table table-striped table-bordered">
            <thead>
                <tr>
                    <th class="text-center" rowspan="2">Monitored assets - live</th>
                </tr>
            </thead>
            <tr>
                <td>Walk-in freezer</td>
                <td class="text-center">597
                </td>
                <td class="text-center">2%</td>
            </tr>
            <tr>
                <td>Deep freezer</td>
                <td class="text-center">32</td>
                <td class="text-center">0%</td>
            </tr>
            <tr>
                <td>Walk-in cooler</td>
                <td class="text-center">25737</td>
                <td class="text-center">93%</td>
            </tr>
            <tr>
                <td>ILR</td>
                <td class="text-center">123</td>
                <td class="text-center">0%</td>
            </tr>
        </table>
    </popover-content>
</div>