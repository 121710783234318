import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { OrdrVarComponent } from 'src/app/Components/evin/Pop-ups/ordr-var/ordr-var.component';
import { OrdrParComponent } from 'src/app/Components/evin/Pop-ups/ordr-par/ordr-par.component';

@Component({
  selector: 'evin-order-fulfill',
  templateUrl: './order-fulfill.component.html',
  styleUrls: ['./order-fulfill.component.css']
})

export class OrderFulfillComponent extends BaseComponent implements OnInit {

  ordrStatus: any[] = []; lstMats: any[] = []; arDate: any = new Date(); fulfillFrm: FormGroup = new FormGroup({});
  orderId: any; orderStatus: any; ordrSts = ''; rcvdRsn = ''; isShowRsnOthrs = false; rsnsLst: any[] = []; dt2Day = new Date();
  rsnMand = false; isFulfill = false; paramTabName: any; isReqLL = '0'; isSbmtd = false; fileName: any = ''; fileFormat = '';

  constructor(public glblSvc: GlobalService,
    private ngbMdl: NgbModal
    , private router: Router
    , public dtPipe: DatePipe
    , private formBuilder: FormBuilder, private route: ActivatedRoute) {
    super(glblSvc);
    this.fulfillFrm = this.formBuilder.group({ arDate: [this.arDate], comment: [''] }),
      this.glblSvc.sbjctodrStst.subscribe((s: any) => this.ordrStatus = s), this.glblSvc.sbjctMstrData.subscribe(s => {
        this.rsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.fulfill_indent); });
    this.rsnMand = this.rsnsLst?.filter(a => a.isReasonMandatory == true).length > 0; 
  }

  ngOnInit(): void {
    ['isIndntAPICald', 'isRelsAPICald', 'isTrnsfrAPICald'].forEach(e => { localStorage.removeItem(e); }),
    this.route.queryParams.subscribe(params => { this.paramTabName = params.type; }),
    this.glblSvc.sbjctOrdId.subscribe((s: any) => { this.orderId = s;
      if (s > 0)
        this.LoadOrderStatus(this.orderId), this.LoadProductsByOrderId(this.orderId);
      else
        this.routingToLocation('list', this.paramTabName);        
    });
    setTimeout(() => {
      console.log('x=',this.ordrStatus);
    }, 1000);
  }

  routingToLocation(tabPath: any, typePath: any){
    if (this.paramTabName == 'i')
      this.glblSvc.sbjctTabsPage.next('indnt-list'),
      this.router.navigate(['home/ord-ord'], { queryParams: { tab: tabPath, type: typePath } });
    else if(this.paramTabName == 'r')
      this.glblSvc.sbjctTabsPage.next('rels-list'),
      this.router.navigate(['home/ord-rls'], { queryParams: { tab: tabPath, type: typePath } });
    else if(this.paramTabName == 't')
      this.glblSvc.sbjctTabsPage.next('trsf-list'),
      this.router.navigate(['home/ord-transfers'], { queryParams: { tab: tabPath, type: typePath } });
    else  
      this.router.navigate(['home/ord-invalid'], { queryParams: { tab: tabPath, type: typePath } });
  }
  
  clearBSDate(ctrl: string) {
    this.fulfillFrm.get(ctrl)?.setValue('');
  }

  LoadOrderStatus(ordrId: any) {
    if (ordrId) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getBookingsById.replace('ooo', ordrId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.orderStatus = this.result.data != null ? this.result.data[0] : [], this.ordrSts = this.result.data != null ? this.orderStatus.status : '';
        }
      });
    }
  }

  LoadProductsByOrderId(ordrId: any) {
    if (ordrId) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getProductsByBookingId.replace('ooo', ordrId), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.result.data.forEach((e: any) => { Object.assign(e, { lstB: [], quantity: e.ordered_stock, isRsn: false, isRsnMand: this.rsnMand, reasonId: null, reasonName:''}); }),
                this.lstMats = this.result.data != null ? this.result.data : [], this.lstMats?.filter((a, index) => this.LoadBatches(a, index));
          }
        }
      });
    }
  }
  onChng(evnt: any, indx: any) {

    this.lstMats[indx]['quantity'] = evnt?.target?.value, this.lstMats[indx]['isRsn'] = this.lstMats[indx]['shipped_stock'] != evnt?.target.value;
   
  }

  onChngRsn(evnt: any, indx: any){
    this.lstMats[indx]['reason'] = evnt?.target?.value
  }
  onChngRsnBath(evnt: any, indx: any, bIndex: any){
    this.lstMats[indx]['lstB'][bIndex]['reason'] = evnt?.target?.value;
  }

  onChngRcvd(evnt: any, indx: any, bIndex: any) {
    let aa = this.lstMats[indx].handling_unit; 
    // if (evnt?.target?.value % aa !=0)
      this.lstMats[indx]['lstB'][bIndex]['quantity'] = evnt?.target?.value, this.lstMats[indx]['lstB'][bIndex]['isRsn'] = this.lstMats[indx]['lstB'][bIndex]['shipped_stock'] != evnt?.target.value;
    // else 
      // alert('not');
  }

  onSlctBatchRR(evnt: any, mIndx: number){
    this.rcvdRsn = evnt?.target?.value; this.lstMats[mIndx].reason ='';
    if (this.rcvdRsn != '')
    this.lstMats[mIndx].isRsnMand = `${this.rsnsLst?.filter(f => f.reasonId === +this.rcvdRsn)[0].reasonName}`.toLowerCase().includes('other'),
    this.lstMats[mIndx].reasonId = evnt?.target?.value,
    this.lstMats[mIndx].reasonName =this.rsnsLst.find(e => e.reasonId == +evnt?.target?.value).reasonName;
  else
    this.lstMats[mIndx].isRsnMand = false;
  }


  onSlctRR(evnt: any, mIndx: number, indx: number) {
    this.rcvdRsn = evnt?.target?.value; this.lstMats[mIndx].lstB[indx].reason ='';
      if (this.rcvdRsn != '')
       this.lstMats[mIndx].lstB[indx].isRsnMand = `${this.rsnsLst?.filter(f => f.reasonId === +this.rcvdRsn)[0].reasonName}`.toLowerCase().includes('other'),
       this.lstMats[mIndx].lstB[indx].reasonId = evnt?.target?.value,
       this.lstMats[mIndx].lstB[indx].reasonName = this.rsnsLst.find(e => e.reasonId == +evnt?.target?.value).reasonName;
      else
        this.lstMats[mIndx].lstB[indx].isRsnMand = false;
  }

  LoadBatches(matData: any, indx: any) {
    if (matData.is_batch_enabled) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.bookings, environment.apiMthds.getBatMatByBookingId.
          replace('ooo', matData.bookingitemid).replace('mmm', matData.product_id), null).then(() => { let res = this.result;
        if (this.result) {
          if (this.result.status == 'OK')
            this.result.data?.forEach((e: any) => { Object.assign(e, { isRsn: false, isRsnMand: this.rsnMand, reasonId: null, reasonName:'' }); }),
              this.lstMats[indx]['lstB'] = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

  onChkLL() {
    let btchdsbld = false, btchenbld = false, ttlQnty = 0, cntQnty = 0, nullRsnCnt = 0;
    this.lstMats?.find( (a: any, index: number)=>  { let data = this.lstMats[index]['lstB'];
    if (a.is_batch_enabled) {
      data.find((e: any) => {
        // btchenbld = ((e.quantity %a.handling_unit) != 0 && a.handling_unit !=null) || (e.quantity *1 )==0 || ((e.quantity !=a.ordered_stock ) &&
        //   e.reasonId == null) ||  (e.isRsn && (e.reasonId == null || e.reasonId =='')) || ((e.isRsnMand) && e?.reason=='' || e?.reason?.length > 15)
        btchenbld = ((e.quantity %a.handling_unit) != 0 && a.handling_unit !=null) || (e.quantity *1 )==0 || (e.isRsn && (e.reasonId == null ||
          e.reasonId =='')) || ((e.isRsnMand) && e?.reason=='' || e?.reason?.length > 15),
          nullRsnCnt = e.reasonId == null ? nullRsnCnt++ : nullRsnCnt;
      });
    }
    else {
      if(((a.quantity %  a.handling_unit) != 0 && a.handling_unit !=null) || (a.quantity *1 )==0 || ((a.quantity !=a.ordered_stock) &&
        a.reasonId == null)|| (a.quantity !=a.ordered_stock ) && (a.reasonId ==null || a.reasonId=='' || ((a.isRsnMand) &&
        a?.reason=='' || a?.reason?.length > 15)))
      btchdsbld = true;
    }
    if (a) {
      a.lstB.forEach((b: any) => { ttlQnty += b.quantity, cntQnty++; });
      a.lstB.length != 0 ? btchenbld = (ttlQnty != a.quantity) && (cntQnty == nullRsnCnt) : btchenbld = false;
    }
  })

  if(btchdsbld || btchenbld){
    this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
  }
  else{
    if (this.isReqLL == '1') {
      this.GetLatLon(), setTimeout(() => {
        if (this.usrLat != undefined && this.usrLon != undefined)
          this.fulFilShip();
        else {
          setTimeout(() => {
            if (this.usrLat == undefined || this.usrLon == undefined)
              this.glblSvc.onTstr('i', 'Kindly provide Location access to Fullfill Indent..!');  
          }, 2000);
        }
      }, 500);
    }
    else
      this.fulFilShip();
   }
  }

  fulFilShip() {
        /* let status = false, nonBstatus = false;
    this.lstMats?.filter((a: any, index: number) => { let data = this.lstMats[index]['lstB'], alctBtchTtlVal = 0;
      if (a.is_batch_enabled) {
        data.forEach((e: any) => { alctBtchTtlVal += e.quantity ? +e.quantity : 0; })
      if (alctBtchTtlVal > +a.quantity)
        status = true;
      }
      else {
        if (+a.quantity > +a.ordered_stock) {
          nonBstatus = true;
        }
      }
    });
    if (status || nonBstatus)
      this.glblSvc.onTstr('i', 'Quantity cannot exceed Shipped value');
    else { */
      let batches: any[] = [], mats: any[] = [], arDate = this.dtPipe.transform((this.fulfillFrm.get('arDate')?.value), 'yyyy-MM-dd HH:mm:ss');
      if (this.lstMats.length > 0)
        this.lstMats?.filter((a: any) => {
          if (a.is_batch_enabled) {
            a.lstB?.filter((b: any) => { batches.push({ expiryDate: this.dtPipe.transform((b.batch_expiry_date), 'yyyy-MM-dd HH:mm:ss'),
              quantity: +b.quantity, batchNo: b.batch_no, shippedQuantity: b.shipped_stock != null ? b.shipped_stock : 0,
              productId: a.product_id, reasonId: (b.reasonId != null && b.reasonId != 0 && b.reasonId != '0') ? +b.reasonId : null,
              reasonName: +b.reasonId == 16 ? b.reason: b.reasonName, fulfillQuantity: b.fulfilled_stock != null ?
              b.fulfilled_stock : 0,  manufacturedDate: (b.batch_mf_date != null && b.batch_mf_date != '') ?
                this.glblSvc.setDtFrmt(new Date(b.batch_mf_date), this.dtF.ymd) + this.tmF.zeroTM : b.batch_mf_date,
              producerId: b.manufacture_id }); })
          }
          else {
            mats.push({ productId: a.product_id, quantity: a.quantity != null ? +a.quantity : 0,
              reasonId: (a.reasonId != null && a.reasonId != 0 && a.reasonId != '0') ? +a.reasonId : null,
              reasonName:+a.reasonId == 16 ? a.reason: a.reasonName, fulfillQuantity: a.fulfilled_stock != null ? a.fulfilled_stock : 0, shippedQuantity: a.shipped_stock != null ?
              a.shipped_stock : 0 });
          }
        });
      let payLoad = { bookingId: this.orderStatus.bookingId, cargoNo: this.orderStatus.bookingId + "-1",
        bookingFulfilDate: arDate, comments: this.fulfillFrm.get('comment')?.value, fulfillProducts: mats,
        issuingStoreId: this.orderStatus.issuingStoreId, receievingStoreId: this.orderStatus.receivingStoreId,
        dateOfActualReciet: arDate, pranthId: this.chsnDmnId, sourceType: '2', userId: this.usrLgnDtls.userId, fulfillProductBatch: batches,
        latitude: (this.usrLat != '' && this.usrLat != null && this.usrLat != undefined) ? +this.usrLat : null,
        longitude: (this.usrLon != '' && this.usrLon != null && this.usrLon != undefined) ? +this.usrLon : null }
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cargo, environment.apiMthds.fulfilBooking, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.message == 'booking fullfilment done for this booking id ' + `${this.orderStatus.bookingId}`)
            this.glblSvc.onTstr('s', this.result.message), localStorage.setItem('ordrFulfillPL', JSON.stringify(payLoad)),
            this.routingToLocation('list', this.paramTabName); // setTimeout(() => { window.location.reload(); }, 1000);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    // }
  }

  cancel() {
    this.routingToLocation('sts', this.paramTabName);
  }

  openModal(c:string, i: any) {
    if (c == 'v') {
      const modalRef =  this.ngbMdl.open(OrdrVarComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
        modalRef.componentInstance.cargoId = i;
    } else {
      const modalRef =  this.ngbMdl.open(OrdrParComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
        modalRef.componentInstance.cargoId = i;
    }
  }
  
  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.fileName = event.target.files[0].name;
      const file = event.target.files[0]; 
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        // this.fulfillFrm.patchValue({ fileName: '' })
        this.fileName = ''
      }
      else {
        // this.fulfillFrm.patchValue({ fileFormat: file });
        this.fileFormat = file;
      }
    }
  }

  hasExtension(exts: any) {
    // var fileFormat = this.fulfillFrm.value.fileName;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(this.fileName);
  }

  uploadFile(itm: any) {
    if (this.fileName == '')
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
    else if(this.hasExtension(['.pdf'])) {
      // this.uploadStatusResult = true,  this.uploadStatus = 'In Progress';
      const formData = new FormData();
      // formData.append('file', this.fulfillFrm.get('fileFormat')?.value);
      formData.append('file', this.fileFormat);
      this.onFormData(environment.apiPaths.exprt, environment.apiMthds.relsFulFillUpld.replace('iii', itm.cargo_item_id ?? null).
        replace('fff', itm.is_batch_enabled ? '64' : '80'), formData, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message);
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
    } else {
      this.glblSvc.onTstr('w', 'Upload only PDF files'), this.fulfillFrm.reset();
    }   
  }

}