<body style="background-color: #ffffff !important;">
    <div class="row scroll">
        <div class="col-xl-7 col-md-7" style="background-image: url(assets/img/login_bg.jpg); background-size:cover">
            <div class="bg-img-cover bg-center"
                style="width: 100%; height: 100vh;">
            </div>
        </div>
        <div class="col-xl-5 col-md-5 col-sm-12 p-0 bg_img_cover_clr">
            <div class="login-card" style="align-items: baseline !important;">
                <div>
                    <div>
                        <a class="logo mb-1" onclick="window.location.reload()">
                            <img class="img-fluid" src="assets/img/evin-login-logo.png" alt="looginpage">
                            <!-- <h6>ELECTRONIC VACCINE INTELLIGENCE NETWORK</h6> -->
                        </a>
                    </div>
                    <div class="login-main shadow">
                        <form class="theme-form" [formGroup]="lgnFrm" (ngSubmit)="onSignIn()"> <!-- onValdCaptcha() -->
                            <h4>Sign in to Account</h4>
                            <div class="form-group">
                                <label class="col-form-label">Username</label>
                                <input id="lgnEmail" class="form-control formtext" type="email"
                                    [ngClass]="{ 'is-invalid': isLogd && fc.user_id.errors }"
                                    [ngStyle]="{ 'border-color': isLogd && fc.user_id.errors ? '#d8072c' : '#efefef' }"
                                    placeholder="" formControlName="user_id">
                                <!-- <div *ngIf="isLogd && fc.user_id.errors" class="invalid-feedback">
                                    <div *ngIf="fc.user_id.errors.required">Please fill Username</div>
                                </div> -->
                            </div>
                            <div class="form-group">
                                <label class="col-form-label">Password</label>
                                <input id="lgnPswd" class="form-control formtext" type="password" formControlName="password"
                                    [ngStyle]="{ 'border-color': isLogd && fc.password.errors ? '#d8072c' : '#efefef' }"
                                    [ngClass]="{ 'is-invalid': isLogd && fc.password.errors }" placeholder="">
                                <div class="show-hide">
                                    <span [ngClass]="isShow ? 'hide' : 'show'" (click)="onShwHde()"></span>
                                </div>
                                <!-- <div *ngIf="isLogd && fc.password.errors" class="invalid-feedback">
                                    <div *ngIf="fc.password.errors.required">Please fill Password</div>
                                </div> -->
                            </div>
                            <!-- <div class="form-group" *ngIf="capImg != undefined">
                                <div class="row">
                                    <div class="col-xl-8">
                                        <img [src]="capImg" class="eCaptcha">
                                    </div>
                                    <div class="col-xl-4 mt-2" (click)="onGenCaptcha(true)">
                                        <i class="fa fa-refresh toggle-data" aria-hidden="true">
                                            &nbsp;<label class="col-form-label toggle-data">Refresh</label>
                                        </i>
                                    </div>
                                </div>                                
                            </div>
                            <div class="form-group" *ngIf="capImg != undefined">
                                <input id="lgnCptch" class="form-control formtext" type="text" formControlName="cptch"
                                    [ngStyle]="{ 'border-color': isLogd && fc.cptch.errors ? '#d8072c' : '#efefef' }"
                                    [ngClass]="{ 'is-invalid': isLogd && fc.cptch.errors }" placeholder="Enter Captcha"
                                    autocomplete="off">
                                <div *ngIf="isLogd && fc.cptch.errors" class="invalid-feedback">
                                    <div *ngIf="fc.cptch.errors.required">Please fill Captcha</div>
                                </div>
                            </div> -->
                            <div class="form-group mb-0">
                                <div class="checkbox p-0 float-start">
                                    <input id="checkbox1" type="checkbox" [checked]="isRmbrChk"
                                        (change)="isRmbrChk=!isRmbrChk">
                                    <label class="text-muted" for="checkbox1">Remember Me</label>
                                </div>
                                <div class="float-end mt-2">
                                    <a (click)="onClkFP()" class="text-end">Forgot Password</a>
                                </div>
                                <div class="clearfix"></div>
                                <button class="btn btn-primary btn-block float-end" type="submit">Sign In</button>
                                <div class="clearfix"></div>
                            </div>
                        </form>
                    </div>
                    <p class="small text-center mt-3 text-muted">© 2021 ELECTRONIC VACCINE INTELLIGENCE NETWORK</p>
                </div>
            </div>
        </div>
    </div>
</body>