import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

FusionCharts.addDep(Charts), FusionCharts.addDep(FusionTheme);

@Component({
  selector: 'evin-db-activity-overview',
  templateUrl: './db-activity-overview.component.html',
  styleUrls: ['./db-activity-overview.component.css']
})

export class DbActivityOverviewComponent extends BaseComponent implements OnInit {

  lstMats: any; slctPrdId: any; dt2day = new Date(); frmDate: any; dayPicker = true; mnthPicker = false; dtDDMM: any = ''; dayMnth: any = 'd';
  dbActvtyForm: FormGroup = this.fb.group({}); lstGrid: any[] = []; noRcrdTxt = environment.TblNoRcrds; isMatBdge = 'badge'; isSbmtd = false;
  slctdMat: any[] = []; slctdMatName: any[] = []; lstMatTags: any[] = []; lstAllMats: any[] = []; lstFacTags: any[] = []; slctdTab = '';
  lstSlctdMatTags: any[] = []; slctdFac: any[] = []; stsLst: any[] = []; ddlStngs = { singleSelection: false, idField: 'id', textField: 'name',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; lstSlctdFacTags: any[] = []; usrLvl = '';
  @ViewChild('dayClick', { static: false }) dayClk: any; dstLst: any[] = []; blkLst: any[] = []; lstSlctMatNms: any[] = [];
  lstChrt: any[] = []; isChrtEnable = false; typeSlctd = 'd'; metric: any; dfltMtrls: any[] = []; dfltFclty : any[] = [];

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.stsLst = s.statesLst; }), this.glblSvc.sbjctBdgsList.
      subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility), this.lstMatTags = s.filter(f =>
      f.badgeTypeId == environment.tagTypes.product); });
      this.glblSvc.sbjctCnfgData.subscribe(s => { this.dfltFclty = s.cnfgDBData?.activity_dashboard?.default_act_facility_tag_filter, 
        this.dfltMtrls = s.cnfgDBData?.activity_dashboard?.default_act_material_tag_filter});
  }

  ngOnInit(): void {
    this.dbActvtyForm = this.fb.group({ productId: [[], Validators.required], productBadgeId: [[], Validators.required], isMatBdge: ['badge'],
      date: [this.dt2day, Validators.required], blockId: ['null'], districtId: ['null'], stateId: ['null'], facilityCategories: [[],
      Validators.required] }), this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.dbActvtyForm.get('country')?.setValue(cntryId);
    }
    if(this.dfltMtrls.length>0){ let lstMTR: any[]=[];
    this.lstMatTags.filter(a => { if ( this.dfltMtrls.includes(a.id)) { lstMTR.push(a) }})
    this.dbActvtyForm.get('productBadgeId')?.setValue(lstMTR);
    lstMTR.forEach(e => {this.lstSlctdMatTags.push({id: e.id, name: e.name}), 
   this.slctdMat?.push(e)});
  }
  if(this.dfltFclty.length>0){ let lstfcty: any[]=[];
    this.lstFacTags.filter(a => { if ( this.dfltFclty.includes(a.id)) { lstfcty.push(a) }})
    this.dbActvtyForm.get('facilityCategories')?.setValue(lstfcty);
    lstfcty.forEach(e => {this.lstSlctdFacTags.push({id: e.id, name: e.name}), 
   this.slctdFac?.push(e)});
  }


    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
  }

  get fc() { return this.dbActvtyForm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dbActvtyForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.dbActvtyForm.get('districtId')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.dbActvtyForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('districtId')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.dbActvtyForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('stateId')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.dbActvtyForm.get('districtId')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.dbActvtyForm.get('blockId')?.setValue(blockID ?? null),
              setTimeout(() => { this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dbActvtyForm.get('stateId')?.setValue('null'), this.dbActvtyForm.get('districtId')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onTypeSlct(type: any) {
    this.dayMnth = type;
    if (type == 'd')
      this.dayPicker = true, this.mnthPicker = false;
    else
      this.dayPicker = false, this.mnthPicker = true;
  }

  clearBSDate(ctrl: string) {
    this.dbActvtyForm.get(ctrl)?.setValue('');
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.dbActvtyForm.get('districtId')?.setValue(null),
          this.dbActvtyForm.get('blockId')?.setValue('null');
      else
        did = slctdId, this.blkLst = [],this.dbActvtyForm.get('blockId')?.setValue('null');
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.dbActvtyForm.get('districtId')?.enable(),
                this.dbActvtyForm.get('blockId')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.dbActvtyForm.get('blockId')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.dbActvtyForm.get('districtId')?.setValue('null'), this.dbActvtyForm.get('blockId')?.setValue('null'),
          this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('blockId')?.disable();
      else
        this.dbActvtyForm.get('blockId')?.setValue('null'), this.dbActvtyForm.get('blockId')?.disable();
    }
  }

  GetActChart() {
    document.getElementById('Map-tab')?.click();
    let payLoad = this.dbActvtyForm.getRawValue(), mT: any[] = [], mN: any[] = [], dd: any, lstFacsTags: any[] = [];
    if ((this.fc.productId.value != '' || this.fc.productBadgeId.value != '') && this.fc.facilityCategories.value != '' && this.lstChrt.length == 0 && this.fc.date.value != '') {
      payLoad.type = this.dayMnth, payLoad.productBadgeId = payLoad.productBadgeId != [] && payLoad.productBadgeId != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productBadgeId = mT, delete payLoad.isMatBdge,
        this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.id) }), payLoad.productId = payLoad.productId != [] &&
        payLoad.productId != null ? this.lstSlctMatNms?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productId = mN,
        payLoad.facilityCategories = lstFacsTags, payLoad.stateId = payLoad.stateId != 'null' ? +payLoad.stateId : 0,
        payLoad.districtId = payLoad.districtId != 'null' ? +payLoad.districtId : 0, payLoad.blockId = payLoad.blockId != 'null' ? +payLoad.blockId : 0;
      if (this.dayMnth == 'd')
        payLoad.date = payLoad.date != '' && payLoad.date != null ? this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd) + this.tmF.noTM : '';
      else
        dd = this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd), this.dtDDMM = this.GetMonthDates(+dd.split('-')[1], +dd.split('-')[0]),
          payLoad.date = this.dtDDMM.split('...')[1];
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.getActTabDtls, payLoad, 5).then(() => {
        let res = this.result; this.lstChrt = [];
        if (this.result) {
          if (this.result?.data) {
            this.lstChrt = this.result?.data ?? [];
            if (this.lstChrt.length > 0)
              this.setChrtData();
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
    else if (this.lstChrt.length > 0)
      this.setChrtData();
  }

  setChrtData() {
    let lstLbls: any[] = [], lstVals: any[] = []; this.isChrtEnable = true;
    this.lstChrt.forEach((e: any) => {
      lstLbls.push({ label: this.dayMnth == 'd' ? this.glblSvc.setDtFrmt(e.date, this.dtF.dmy) : e.month }),
        lstVals.push({ value: this.slctdTab == 'f' ? e.activestores : (this.slctdTab == 'u' ? e.activeusers : e.transactions) });
      this.metric = this.slctdTab == 'f' ? 1 : this.slctdTab == 'u' ? 2 : 3;
    }); this.typeSlctd = this.dayMnth;
    if (lstLbls.length > 0 && lstVals.length > 0)
      this.plotChrt(lstLbls, lstVals);
  }

  plotChrt(lblData: any[], chrtData: any[]) {
     
    var fsnOptns: any = FusionCharts.options, xAxsLbl = this.dayMnth == 'd' ? 'Dates' : 'Months', yAxsLbl = this.slctdTab == 'f' ? 'No.of Active Facilities': this.slctdTab =='u' ? 'No.of Active Users' : 'No.of Transactions';
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function() {
      var myChart = new FusionCharts({ type: 'mscolumn2d', renderAt: 'chrt', width: '100%', height: '300', dataFormat: 'json',
        dataSource: { chart: { caption: '', subcaption: '', xaxisname: xAxsLbl, yaxisname: yAxsLbl, formatnumberscale: '', theme: 'fusion',
        exportEnabled: '1', exportTargetWindow: '_self', exportFormats: environment.fcExprtOptns, exportFileName: "Dashboard Activity",
        drawcrossline: '1', plottooltext: '<b>$dataValue</b> on <b>$seriesName</b> <b>$label</b>' }, categories: [{ category: lblData }],
        dataset: [{ seriesname: '', data: chrtData }] } }).render();
    });
  }

  LoadActivity() {
    this.isChrtEnable = false;
    let payLoad = this.dbActvtyForm.getRawValue(), mT: any[] = [], mN: any[] = [], dd: any, lstFacsTags: any[] = [];
    if ((this.fc.productId.value != '' || this.fc.productBadgeId.value != '') && this.fc.facilityCategories.value != '') {
      payLoad.type = this.dayMnth, payLoad.productBadgeId = payLoad.productBadgeId != [] && payLoad.productBadgeId != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productBadgeId = mT, delete payLoad.isMatBdge,
        this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.id) }), payLoad.productId = payLoad.productId != [] &&
        payLoad.productId != null ? this.lstSlctMatNms?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productId = mN,
        payLoad.facilityCategories = lstFacsTags, payLoad.stateId = payLoad.stateId != 'null' ? +payLoad.stateId : 0,
        payLoad.districtId = payLoad.districtId != 'null' ? +payLoad.districtId : 0, payLoad.blockId = payLoad.blockId != 'null' ?
        +payLoad.blockId : 0;
      if (this.dayMnth == 'd')
        payLoad.date = payLoad.date != '' && payLoad.date != null ? this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd) + this.tmF.noTM : '';
      else
        dd = this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd), this.dtDDMM = this.GetMonthDates(+dd.split('-')[1], +dd.split('-')[0]),
          payLoad.date = this.dtDDMM.split('...')[1];
      this.slctdTab = '', this.lstChrt = [], this.lstGrid = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard,
          environment.apiMthds.actvtyDbOvw, payLoad, 5).then(() => { let res = this.result;
        if (this.result) {
          if (this.result?.data)
            this.lstGrid = this.result?.data ?? [];
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
    else
      this.glblSvc.onTstr('w', 'Please choose Mandatory fields');
  }

  onReset() {
    let el: HTMLElement = this.dayClk.nativeElement;
    this.lstSlctMatNms = [], this.lstSlctdMatTags = [], this.slctdMat = [], this.lstSlctdFacTags= [], this.dayMnth = 'd', this.dbActvtyForm.get('isMatBdge')?.setValue('badge'),
      this.dbActvtyForm.get('productBadgeId')?.setValue([]), this.dbActvtyForm.get('productId')?.setValue([]), this.onTypeSlct('d'),
      this.dbActvtyForm.get('date')?.setValue(this.dt2day), this.slctdFac = [], this.dstLst = [], el.click(), this.dtDDMM = '',
      this.slctdTab = '', this.lstChrt = []; this.metric ='';
      if(this.dfltMtrls.length>0){ let lstMTR: any[]=[];
        this.lstMatTags.filter(a => { if ( this.dfltMtrls.includes(a.id)) { lstMTR.push(a) }})
        this.dbActvtyForm.get('productBadgeId')?.setValue(lstMTR);
        lstMTR.forEach(e => {this.lstSlctdMatTags.push({id: e.id, name: e.name}), 
       this.slctdMat?.push(e)});
      } 
      if(this.dfltFclty.length>0){ let lstfcty: any[]=[];
        this.lstFacTags.filter(a => { if ( this.dfltFclty.includes(a.id)) { lstfcty.push(a) }})
        this.dbActvtyForm.get('facilityCategories')?.setValue(lstfcty);
        lstfcty.forEach(e => {this.lstSlctdFacTags.push({id: e.id, name: e.name}), 
       this.slctdFac?.push(e)});
      } this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
  }

  onClkRdb(ctrl: string) {
    this.fc?.productBadgeId.setValue([]), this.fc?.productId.setValue([]), this.lstSlctMatNms = [], this.lstSlctdMatTags = [];
    if (ctrl == 'productBadgeId')
      this.fc?.productId?.clearValidators(), this.fc?.productBadgeId?.setValidators([Validators.required]), this.lstSlctMatNms = [];
    else
      this.fc?.productBadgeId?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]);
    this.fc?.productBadgeId?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onMultiSlctFT(type: string, item: any) {
    if (type == 's') {
      if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
        this.lstSlctdFacTags.push(item);
    }
    else if (type == 'r')
      this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
    else if (type == 'a')
      this.lstSlctdFacTags = this.lstFacTags;
    else
      this.lstSlctdFacTags = [];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctMatNms.filter(f => f.id === item.id).length == 0)
          this.lstSlctMatNms.push(item);
      }
      else {
        (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctMatNms = this.lstSlctMatNms.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctMatNms = this.lstAllMats;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctMatNms = [];
      else
        this.lstSlctdMatTags = [];
    }
  }
  exprtExl() {
    // this.isChrtEnable = false;
    let payLoad = this.dbActvtyForm.getRawValue(), mT: any[] = [], mN: any[] = [], dd: any, lstFacsTags: any[] = [];
    // if ((this.fc.productId.value != '' || this.fc.productBadgeId.value != '') && this.fc.facilityCategories.value != '') {
      payLoad.type = this.dayMnth, payLoad.productBadgeId = payLoad.productBadgeId != [] && payLoad.productBadgeId != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productBadgeId = mT, delete payLoad.isMatBdge,
        this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.id) }), payLoad.productId = payLoad.productId != [] &&
        payLoad.productId != null ? this.lstSlctMatNms?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productId = mN,
        payLoad.facilityCategories = lstFacsTags, payLoad.stateId = payLoad.stateId != 'null' ? +payLoad.stateId : 0,
        payLoad.districtId = payLoad.districtId != 'null' ? +payLoad.districtId : 0, payLoad.blockId = payLoad.blockId != 'null' ?
        +payLoad.blockId : 0;
      if (this.dayMnth == 'd')
        payLoad.date = payLoad.date != '' && payLoad.date != null ? this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd) + this.tmF.noTM : '';
      else
        dd = this.glblSvc.setDtFrmt(payLoad.date, this.dtF.ymd), this.dtDDMM = this.GetMonthDates(+dd.split('-')[1], +dd.split('-')[0]),
          payLoad.date = this.dtDDMM.split('...')[1];  payLoad.metric = this.metric; payLoad.email = this.usrLgnDtls.email;
          this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard,
          environment.apiMthds.dbActvtyExprt, payLoad, 5).then(() => {
            if (this.result) {
              if (this.result.status == 'OK')
                this.glblSvc.onTstr('s', this.result.message);
              else
                this.glblSvc.onTstr('w', this.result.message);
            }
      });
 
  }


  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dbActvtyForm.get('stateId')?.enable(), this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dbActvtyForm.get('stateId')?.disable(), this.dbActvtyForm.get('districtId')?.enable(), this.dbActvtyForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.dbActvtyForm.get('stateId')?.disable(), this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.dbActvtyForm.get('stateId')?.disable(), this.dbActvtyForm.get('districtId')?.disable(), this.dbActvtyForm.get('blockId')?.disable();
  }

}