<form>
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="card shadow-sm p-0 mt-0 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Push Notifications</div>
                </div>
                <div class="card-body p-2">
                    <small>Specify the push notifications to be enabled or disabled for each event</small>
                    <div class="row mt-3">
                        <div class="form-group col-xl-12 mb-2">
                            <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                                notifications</label><br />
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                <label class="form-check-label" for="flexCheckChecked">
                                    Enable notification for supply performance events
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Good Supply Performance</h6>
                </div>
                <div class="card-body p-2">
                    <small>Specify the thresholds for events where number of supply days is greater than
                        expected value in a given time period</small>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>% availability of materials at a Facility (>= %)</th>
                                                <th>% Facilities with specified material availability (>= %)</th>
                                                <th>Number of supply days (<= days)</th>
                                                <th>Over a period of (months)</th>
                                                <th>Include materials with tags</th>
                                                <th>Exclude materials with tags</th>
                                                <th>Include Facilities with tags</th>
                                                <th>Exclude Facilities with tags</th>
                                                <th>Users (tags) responsible for maintaining good performance</th>
                                                <th *ngIf="cnfgEvntSmPrmsn"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>90</td>
                                                <td>90</td>
                                                <td>1</td>
                                                <td>3</td>
                                                <td>RI Vaccines</td>
                                                <td></td>
                                                <td>CCP</td>
                                                <td>3</td>
                                                <td>DVSM</td>
                                                <td *ngIf="cnfgEvntSmPrmsn"><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow">
                        <div class="row mt-3">
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">% availability of materials at a facility
                                    >=</label>
                                <input type="text" placeholder="" value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">% facilities with specified material availability
                                    >=</label>
                                <input type="text" placeholder="" value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">% of materials with no stock outs or excess stock
                                    >=</label>
                                <input type="text" placeholder="" value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Number of supply days &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="" value="" class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Over a period of</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="" value="" class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include material with categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude material with categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include facilities with categories
                                </label>
                                <input type="text" placeholder="" value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facilities with categories
                                </label>
                                <input type="text" placeholder="" value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Users (categories) responsible for maintaining good performance
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow=true">Cancel</button>
                        </div>
                    </div>

                </div>

            </div>
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>
            </div>
        </div>
    </div>
</form>