<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link" id="top-dmns-tab" data-bs-toggle="tab" href="#top-dmns" role="tab"
                aria-controls="top-dmns" aria-selected="true" (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">
                Domains</a></li>
            <!-- <li class="nav-item"><a class="nav-link" id="dtls-dmns-top-tab" data-bs-toggle="tab" href="#top-dtls-dmn" role="tab"
                aria-controls="top-profile" aria-selected="false" (click)="onTabSelection('dtls')" [ngClass]="paramTab === 'dtls' ? 'active' : ''">
                Domain Summary</a></li> -->
            <li class="nav-item"><a class="nav-link" id="create-dmns-top-tab" data-bs-toggle="tab" href="#top-create-dmns" role="tab"
                aria-controls="top-profile" aria-selected="false" (click)="onTabSelection('new')" [ngClass]="paramTab === 'new' ? 'active' : ''">
                Create Domains</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="top-dmns" role="tabpanel" aria-labelledby="top-dmns-tab" [ngClass]="paramTab === 'list' ? 'show active' : '' ">
               <evin-domain-list></evin-domain-list>
            </div>
            <!-- <div class="tab-pane fade" id="top-create-dmns" role="tabpanel" aria-labelledby="create-dmns-top-tab" [ngClass]="paramTab === 'dtls' ? 'show active' : '' ">
                <evin-domain-details></evin-domain-details>
            </div> -->
            <div class="tab-pane fade" id="top-create-dmns" role="tabpanel" aria-labelledby="create-dmns-top-tab" [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-domain-create></evin-domain-create>
            </div>
        </div>
    </div>
</div>