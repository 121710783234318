<evin-report-filter></evin-report-filter>
<div class="card p-0 border-0">
    <div class="card-header p-2 border-0">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Power-availability-tab" data-bs-toggle="tab"
                    href="#Power-availability" role="tab" aria-controls="Power-availability"
                    aria-selected="true">Overview</a></li>
            <li class="nav-item"><a class="nav-link" id="Power-availability-2-tab" data-bs-toggle="tab"
                    href="#Power-availability-2" role="tab" aria-controls="Power-availability-2"
                    aria-selected="false">By locations</a></li>
        </ul>
    </div>
    <div class="card-body p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="Power-availability" role="tabpanel"
                aria-labelledby="Power-availability-tab">
                <evin-sr-overview></evin-sr-overview>
            </div>
            <div class="tab-pane fade" id="Power-availability-2" role="tabpanel"
                aria-labelledby="Power-availability-2-tab">
                <evin-sr-locations></evin-sr-locations>
            </div>
        </div>
    </div>
</div>