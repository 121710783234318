import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-discrepancies',
  templateUrl: './discrepancies.component.html',
  styleUrls: ['./discrepancies.component.css']
})

export class DiscrepanciesComponent extends BaseComponent implements OnInit {

  lstFacs: any[] = []; lstMats: any[] = []; cargoId: any; lstAllMats: any[] = []; slctdStrId: any; slctPrdId: any; slctdFac: any[] = []; 
  slctdMat: any[] = []; lstShowFacTags: any[] = [];  lstSlctdFacTags: any[] = []; lstSlctdMatTags: any[] = [];
  lstFacTags: any[] = []; lstMatTags: any[] = []; isFacAll = false; isMatAll = false; isExprt = false; isMatBdge = 'badge';
  lstAllMats1: any[] = []; shwAPrds = true; lstPrnts: any[] = []; orderStatusList : any[]= []; ordLst : any[]=[]; slctdFcltyId : any[] = []; 
  lstGrid: any[] = []; initFac: any[] = []; initMat: any[] = []; dt2day = new Date(); frmDate = this.wkBkDt; pageEntrs = ''; crntPage = 1; 
  pageSize = environment.tblPageSize; totalItems: any; isPgLd = true; inddscpfrm = this.formBuilder.group({ storeId: [''], productId: [],
   productTags: [], isMatBdge: ['badge'],
  fromDate: [''], toDate: [this.dt2day], storeBadgeId: [''], discrepancyStatusId:[''], isReleasIndent : true, bookingId: '' });
  dropdownSettings = {  singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; noRcrdTxt = environment.TblNoRcrds; lstAllMaterials: any[] = []; lstSlctdMatName: any[] = [];
  slctdMatName: any[] = []; slctdMatrl: any[] = []; isSbmtd = false; //lstShowMatTags: any[] = [];

  constructor(public glblSvc: GlobalService, public formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => {this.lstFacs = s; });
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.lstFacTags = s.filter(f => f.badgeTypeId ==
        environment.tagTypes.facility);
    }), this.glblSvc.sbjctStrList.subscribe(s => { this.lstPrnts = s; });
    this.glblSvc.sbjctMstrData.subscribe(s => {this.lstAllMaterials = s.allMats,
      this.orderStatusList = s.stsLst?.filter((f: any) => f.statusType === 'Order')
      this.ordLst = this.orderStatusList?.filter(e => (e.name == 'Fulfilled' || e.name == 'Confirmed'));
      this.lstMnths.filter(e => (e.name == ''))
    })
  }

  ngOnInit(): void {
    this.lstMatTags.forEach(e => {this.initMat.push(e.id)}); this.lstFacTags.forEach(e => {this.initFac.push(e.id)});
    this.onClkRdb('productTags'); this.matSlct(); this.LoadDscrpances();
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef, this.isPgLd = false;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  clearBSDate(ctrl: string) {
    this.inddscpfrm.get(ctrl)?.setValue('');
  }

  changeFn(event: any, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == "" || evnt == undefined)
      this.shwAPrds = true, this.slctdStrId = null;
    else
      if (this.lstPrnts?.filter(a => a.storeName == evnt).length != 0)
        this.slctdStrId = this.lstPrnts.filter(a => a.storeName == evnt)[0]?.storeId, this.shwAPrds = false;
      else
        this.slctdStrId = null, this.shwAPrds = true, this.inddscpfrm.get('storeId')?.setValue(null);
    this.inddscpfrm.get('productId')?.setValue(''), this.inddscpfrm.get('productTags')?.setValue(''), this.slctPrdId = null, this.LoadMatsbyStoreId(this.slctdStrId);
  }

  changeFn2(event: any, type: any, evntType: string) {
    let evnt = (evntType == 'chng' ? event.target.value : event.value);
    if (evnt == "" || evnt == undefined)
      this.slctPrdId = null, this.inddscpfrm.get('productId')?.setValue(null);
    else {
      if (type == 'byStr')
        if (this.lstMats.filter(a => a.productName == evnt).length != 0)
          this.slctPrdId = this.lstMats.filter(a => a.productName == evnt)[0]?.productId;
        else
          this.slctPrdId = null, this.inddscpfrm.get('productId')?.setValue(null);
      else
        if (this.lstAllMats1.filter(a => a.name == evnt).length != 0)
          this.slctPrdId = this.lstAllMats1.filter(a => a.name == evnt)[0]?.id;
        else
          this.slctPrdId = null, this.inddscpfrm.get('productId')?.setValue(null);
    }
  }

  loadAllMats() {
    this.lstAllMats1 = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats1 = this.result.data != null ? this.result.data : []; // this.lstMats = this.lstAllMats;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadMatsbyStoreId(sId: number) {
    if(sId){
    this.lstMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMatbyStoreId.
      replace('sss', `${sId}`).replace('ttt', `${this.chsnDmnId}`), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
  }

  chngProduct(event: any) {
    for (let s = 0; s < this.lstMats.length; s++) {
      if (this.lstMats[s].productName == event.value)
        this.slctPrdId = this.lstMats[s].productId;
    }
  }

  onMultiSlct(type: string, ctrl: string, item: any) {    
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else if(ctrl =='m'){
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
      else
        if (this.lstSlctdMatName.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
        else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = this.lstSlctdMatName.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
        else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstMatTags;
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = this.lstAllMaterials;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
        else if (ctrl == 'm')
        this.lstSlctdMatTags = [];
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = [];
    }
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    // else
    //   this.slctdMat = [...this.lstShowMatTags];
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFacTags.length === this.lstSlctdFacTags.length;
    // else
    //   this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMatTags.length === this.lstSlctdMatTags.length;
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    // else
    //   this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

  matSlct(){
    this.inddscpfrm.get('productTags')?.setValue(''), this.slctdMatrl=[];
    this.lstMatTags.forEach(e => {this.lstSlctdMatTags.push({id: e.id, name: e.name}), this.slctdMatrl?.push(e)});
  }

  onReset() {
    this.inddscpfrm.reset(); this.inddscpfrm.get('discrepancyStatusId')?.setValue(''); //this.lstShowMatTags = [...[]],
    this.lstSlctdFacTags = [...[]], this.lstShowFacTags = [...[]],this.lstSlctdMatName = [...[]],
    this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false, this.isPgLd = true, this.slctdMatName = [], this.slctdMatrl = [],
    this.inddscpfrm.get('isReleasIndent')?.setValue(true), this.inddscpfrm.get('productId')?.setValue(null), this.slctPrdId = null,
    this.inddscpfrm.get('isMatBdge')?.setValue('badge'),
    this.inddscpfrm.get('storeId')?.setValue(null), this.inddscpfrm.get('bookingId')?.setValue(null), this.slctdStrId = null,
    this.inddscpfrm.get('fromDate')?.setValue(this.wkBkDt), this.inddscpfrm.get('toDate')?.setValue(this.dt2day), this.matSlct(), 
    setTimeout(() => { this.LoadDscrpances(); }, 1000);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadDscrpances();
  }

  get fc() { return this.inddscpfrm.controls; }

  onClkRdb(ctrl: string) {
    if (ctrl == 'productTags')
    this.fc?.productId.setValue([]), this.fc?.productId?.clearValidators(), this.fc?.productTag?.setValidators([Validators.required]),
    this.lstSlctdMatName = [];
    else if(ctrl == 'productId')
    this.fc?.productTags.setValue([]), this.fc?.productTag?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]),
    this.fc?.productTag?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  LoadDscrpances() {
    this.lstGrid = [];
    let crntPgIndx = this.crntPage - 1, payLoad = this.inddscpfrm.getRawValue(), fT: any[] = [], dl: any[] = [], mT: any[] = [], matrlTgs: any[] = [], mtrlName: any[] = [];
    this.lstShowFacTags.forEach(e => { fT.push(e.id); }), //this.lstShowMatTags.forEach(e => { mT.push(e.id); }),
      payLoad.issuingStoreId = this.slctdStrId != '' ? this.slctdStrId : null, localStorage.setItem('isIndntAPICald', '1'), // payLoad.status = payLoad.status != '' ? +payLoad.status : null, 
      payLoad.storeBadgeId = fT.length != 0 ? fT : this.initFac, //payLoad.productBadgeId = mT.length != 0 ? mT : this.initMat, payLoad.productId = this.slctPrdId ?? null,
       payLoad.bookingId = (payLoad.bookingId != '' && payLoad.bookingId != null) ? +payLoad.bookingId : null,
        payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.lastTM : null;
        payLoad.productBadgeId = payLoad.productTags != [] && payLoad.productTags != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { matrlTgs.push(e.id) }) : [], payLoad.productBadgeId = matrlTgs;
        payLoad.productId = payLoad.productId != [] && payLoad.productId != null ?
        this.lstSlctdMatName?.forEach((e: any) => { mtrlName.push(e.id) }) : [], payLoad.productId = mtrlName;
    if (this.isPgLd)
      payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
    delete payLoad.storeId; delete payLoad.isMatBdge; delete payLoad.productTags;
    if (payLoad.discrepancyStatusId == "")
      this.ordLst.forEach(e => { dl.push(e.id) });
    else
      dl.push(+payLoad.discrepancyStatusId);
    payLoad.discrepancyStatusId = dl.length != 0 ? dl : null,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dscrpncs, environment.apiMthds.dscrpncsLst.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
        payLoad).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result?.data)
                this.lstGrid = this.result?.data?.discrepancyBookingFilterDTOs ?? [], this.totalItems = this.result.data?.totalRecordsCount ?? 0,
                  this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
              else if (this.result?.data == null)
                this.glblSvc.onTstr('i', this.result.message);
            } else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    setTimeout(() => { localStorage.removeItem('isIndntAPICald'); }, 500);
  }

  onEE () {
    let payLoad = this.inddscpfrm.getRawValue(), fT: any[] = [], dl: any[] = [], matrlTgs: any[] = [], mtrlName: any[] = [];
    this.lstShowFacTags.forEach(e => { fT.push(e.id); }), //this.lstShowMatTags.forEach(e => { mT.push(e.id); }),
      payLoad.issuingStoreId = this.slctdStrId != '' ? this.slctdStrId : null, localStorage.setItem('isIndntAPICald', '1'), // payLoad.status = payLoad.status != '' ? +payLoad.status : null, 
      payLoad.storeBadgeId = fT.length != 0 ? fT : this.initFac, //payLoad.productBadgeId = mT.length != 0 ? mT : this.initMat, payLoad.productId = this.slctPrdId ?? null,
      payLoad.bookingId = (payLoad.bookingId != '' && payLoad.bookingId != null) ? +payLoad.bookingId : null,
      payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.lastTM : null;
      payLoad.productBadgeId = payLoad.productTags != [] && payLoad.productTags != null ?
      this.lstSlctdMatTags?.forEach((e: any) => { matrlTgs.push(e.id) }) : [], payLoad.productBadgeId = matrlTgs;
      payLoad.productId = payLoad.productId != [] && payLoad.productId != null ?
      this.lstSlctdMatName?.forEach((e: any) => { mtrlName.push(e.id) }) : [], payLoad.productId = mtrlName;
    if (this.isPgLd)
      payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null;
    delete payLoad.storeId; delete payLoad.isMatBdge; delete payLoad.productTags;
    if (payLoad.discrepancyStatusId == "")
      this.ordLst.forEach(e => { dl.push(e.id) });
    else
      dl.push(+payLoad.discrepancyStatusId);
    payLoad.discrepancyStatusId = dl.length != 0 ? dl : null, payLoad.email = this.usrLgnDtls.email, this.FetchData(environment.apiCalls.post,
        environment.apiPaths.dscrpncs, environment.apiMthds.IndentDscrpncsExpt.replace('eml', this.usrLgnDtls.email), payLoad, 11).then(() => { // this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}