import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-facility-view',
  templateUrl: './facility-view.component.html',
  styleUrls: ['./facility-view.component.css']
})

export class FacilityViewComponent extends BaseComponent implements OnInit {

  lstFacTags: any[] = []; lstFacs: any[] = []; lstAllFacs: any[] = []; noRcrdsTxt = environment.TblNoRcrds; crntPage = 1; pageEntrs = '';
  totalItems: any; pageSize = environment.tblPageSize; facViewFrm = this.fb.group({ tagName: [''], FacilityName: [''], ctrl: [''], targetDomain:[null] });
  isDelSa: any; selectedStoreId: any = 0; usrLvlDtls: any = null; isUserLvl: any = null;
  isBetaURL = environment.isBetaURL; prmsns: any = null; fcltyLst: boolean = true; fcltyEdt: boolean = true; fcltyDlt: boolean = true; fcltydtl: boolean = true;
  fcltyDtlViewIndents : boolean = true; fcltyDtlEdtMtrl: boolean = true; fcltyDtlViewRltnshp : boolean = true; fcltyDtlAddRltnshp: boolean = true;
  fcltyDtlViewMtrl: boolean = true; fcltyDtlAddMtrl: boolean = true; fcltyDtlDltMtrl: boolean = true; fcltyDtlViewPrfl: boolean = true; fcltyDtlViewTransctn: boolean = true;
  slctdFacltyLst: any[] = []; facIds: any[] = []; dropdownSettings = {singleSelection: true, idField: 'pranthid', textField: 'pranthname', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true}; lstDmns: any[] = []; noDmns: any[] = []; lstSlctdDmn: any[] = []; usrRoleName = this.usrLgnDtls?.roleName;

  constructor(public glblSvc: GlobalService,
    private router: Router,
    private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility); }),
      this.glblSvc.sbjctStrList.subscribe(s => {
        this.lstFacs = s;
        if (this.lstFacs.length == 1)
          this.facViewFrm.get('ctrl')?.setValue(this.lstFacs[0].storeId), this.facViewFrm.get('FacilityName')?.setValue(this.lstFacs[0].storeId);
      }), localStorage.removeItem('suFcltyId');
  }

  ngOnInit(): void {
      if (this.usrLgnDtls) {
        this.usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
          this.isUserLvl = this.usrLvlDtls?.userLevel;
      }
      this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
      this.prmsns.filter((e: any) =>
      {
      if(e?.menuCode =='mm_st_fc_li')
       this.fcltyLst = e?.action;
      if(e?.menuCode =='mm_st_fc_ed')
       this.fcltyEdt = e?.action;
       if(e?.menuCode =='mm_st_fc_dt')
       this.fcltyDlt = e?.action;
      if(e?.menuCode =='mm_st_fc_fd_vsm')
       this.fcltydtl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_id')
       this.fcltyDtlViewIndents = e?.action;
      if(e?.menuCode =='mm_st_fc_fd_em')
       this.fcltyDtlEdtMtrl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_vm')
       this.fcltyDtlViewMtrl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_am')
       this.fcltyDtlAddMtrl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_dm')
       this.fcltyDtlDltMtrl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_pf')
       this.fcltyDtlViewPrfl = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_tx')
       this.fcltyDtlViewTransctn = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_vr')
       this.fcltyDtlViewRltnshp = e?.action;
       if(e?.menuCode =='mm_st_fc_fd_ar')
       this.fcltyDtlAddRltnshp = e?.action;
    });
    this.isDelSa = localStorage.getItem('isClkdSA'); setTimeout(() => { this.onStors(); if(this.fcltyLst) this.LoadFacilities(), this.LoadDomains(); }, 1000);
  }
  // localStorage.getItem('slctdDmnId')
  onStors() {
    if (this.isDelSa == '1')
      this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getAllStrs.replace('false', 'true')+localStorage.getItem('slctdDmnId'), null, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.lstFacs = this.result.data != null ? this.result.data : [];
              if (this.lstFacs.length == 1)
                this.facViewFrm.get('ctrl')?.setValue(this.lstFacs[0].storeId), this.facViewFrm.get('FacilityName')?.setValue(this.lstFacs[0].storeId);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    else
      this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacs = s;
        if (this.lstFacs.length == 1)
          this.facViewFrm.get('ctrl')?.setValue(this.lstFacs[0].storeId), this.facViewFrm.get('FacilityName')?.setValue(this.lstFacs[0].storeId);
      });
  }

  isSelected($event: any, item: any) {
    if (this.slctdFacltyLst.filter(f => f.id == item.storeId).length == 0)
      this.slctdFacltyLst.push({ id: item.storeId, email: item.email });
    else
      this.slctdFacltyLst = this.slctdFacltyLst.filter(f => f.id != item.storeId);
    if ($event.target.checked) {
      item.flag = true, this.facIds.push(item.storeId);
    }
    else
      item.flag = false, this.facIds.filter((a: any, index: any) => { if (a == item.storeId) this.facIds.splice(index, 1); })
  }

  onSlctAll($event: any) {
    this.slctdFacltyLst = [], this.facIds = [];
    if ($event.target.checked)
      this.lstAllFacs.filter(a => { a.flag = true, this.slctdFacltyLst.push({ id: a.storeId, email: a.email }), this.facIds.push(a.storeId); })
    else
      this.lstAllFacs.filter(a => { a.flag = false });
  }

  onChngFclty(evnt: any) {
    this.facViewFrm.get('FacilityName')?.setValue('');
    if (evnt?.value) {
      if (this.lstFacs.filter(f => f.storeId == evnt?.value).length > 0) this.facViewFrm.get('FacilityName')?.setValue(evnt?.value);
    }
    // [this.str, this.selectedStoreId] = [event.value, ''];
    for (let s = 0; s < this.lstFacs.length; s++) {
      if (this.lstFacs[s].storeName == evnt.value)
        this.selectedStoreId = this.lstFacs[s].storeId;
    }
  }

  onMatDetails(id: any) {
    this.router.navigate(['home/setup-matrls-details'])
  }

  onMatEdit() {
    this.router.navigate(['home/setup-matrls-edit'])
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadFacilities();
  }

  LoadFacilities() {
    let crntPgIndx = this.crntPage - 1, frmCtrls = this.facViewFrm.getRawValue(), badgeId = frmCtrls?.tagName == '' ? 0 : frmCtrls?.tagName;
    this.lstAllFacs = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getAllStrByFilter.
      replace('ppp', `${this.chsnDmnId}`).replace('sss', this.selectedStoreId).replace('ssss', `${this.isDelSa == '1'}`).
      replace('bbb', badgeId).replace('mpdStrddddd', '0').replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAllFacs = this.result.data?.storeFiltersDTOs != null ? this.result.data?.storeFiltersDTOs : [], this.totalItems =
                this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }


  onfcltyDetails(storeId: any, activeTab: any, prnthId: any) {
    if (this.isDelSa !='1') {
      this.glblSvc.sbjctStrId.next(storeId), localStorage.setItem('suFcltyId', storeId), localStorage.setItem('suFcltyPrnthId', prnthId);
      if (activeTab == 's')
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 's', type: 'list' } });
      else if (activeTab == 'm')
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'm', type: 'list' } });
      else if (activeTab == 't')
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 't', type: 'list' } });
      else if (activeTab == 'i')
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'i', type: 'list' } });
      else if (activeTab == 'r')
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 'r', type: 'list' } });
      else
        this.router.navigate(['home/setup-fclty-smry'], { queryParams: { tab: 's', type: 'list' } });
    } 
  }

  onReset() {
    this.selectedStoreId = 0, this.noDmns = [], this.lstSlctdDmn = [...[]];
    this.facViewFrm.get('tagName')?.setValue(''), this.facViewFrm.get('FacilityName')?.setValue(''), this.facViewFrm.get('ctrl')?.setValue(''), this.LoadFacilities();
  }

  onStrEdit(strId: any) {
    this.glblSvc.sbjctFacStrId.next(strId), this.router.navigate(['home/setup-fclty'], { queryParams: { tab: 'new' } });
  }

  onStrDel(strId: any) {
    if (confirm('Are you sure want to delete store ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.store, environment.apiMthds.delById + strId, null, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Records deleted")
              this.glblSvc.onTstr('s', this.result.message), this.onReset();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onRetrvData() {
    let payLoad: any= {};
      if (confirm("Are you sure you want to Retrieve the Facility.?")) {
        payLoad.storeId = this.facIds, payLoad.userId = this.usrLgnDtls?.userId;
        this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.retrveStrs.replace('sss', 'true'), 
          payLoad, 7).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.message === "Store retrieved successfully")
                this.glblSvc.onTstr('s', this.result.message), this.LoadFacilities();
              else 
                this.glblSvc.onTstr('w', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      }
  }

  onTrgtDmn() {
    let payLoad: any= this.facViewFrm.getRawValue(); payLoad.storeId = this.facIds, payLoad.targetDomain = payLoad.targetDomain[0]?.pranthid;
    ['FacilityName', 'ctrl', 'tagName'].forEach(e=>{delete payLoad[e]})
    this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.mveStre, 
      payLoad, 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.status === 'OK')
            this.glblSvc.onTstr('s', this.result.message), this.LoadFacilities();
          else 
            this.glblSvc.onTstr('w', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onEE(){
    let payLoad  = this.facViewFrm.getRawValue(),
     badgeId = payLoad?.tagName == '' ? 0: payLoad?.tagName;
     this.FetchData(environment.apiCalls.post, environment.apiPaths.store, environment.apiMthds.exprtFaclty.
      replace('ppp', `${this.chsnDmnId}`).replace('sss', this.selectedStoreId).replace('ssss', `${this.isDelSa == '1'}`).
        replace('bbb', badgeId).replace('eee',this.usrLgnDtls?.email).replace('nnn', this.usrLgnDtls?.userName), null, 7).then(() => {
          if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  LoadDomains() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.ftchChldPrnth,
        null, 7).then(() => { let res = this.result;
      if (this.result)
        this.lstDmns = this.result?.data ?? [];
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 't') {
        if (this.lstSlctdDmn.filter((f: { id: any; }) => f.id === item.pranthid).length == 0)
          this.lstSlctdDmn.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 't')
        this.lstSlctdDmn = this.lstSlctdDmn.filter((f: { id: any; }) => f.id != item.pranthid);
    }
    else if (type == 'a') {
      if (ctrl == 't')
        this.lstSlctdDmn = this.lstDmns;
    }
    else {
      if (ctrl == 't')
        this.lstSlctdDmn = [];
    }
  }
}