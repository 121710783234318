<form [formGroup]="invStkRprtFrm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0 mb-2">
                <div class="card-body p-4 border-0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">Facility</label>
                                    <ng-template #cstmStore let-model="item">
                                        <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Facility" [typeahead]="lstFacs"
                                        formControlName="storeId" [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                        [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                        [adaptivePosition]="true" class="form-control form-control-sm" id="Store" />
                                        <!-- (typeaheadOnSelect)="changeFn($event, 'slct')" (change)="changeFn($event, 'chng')"
                                            [attr.disabled]="((isFld!='' && isFld!='f') || lstGrid.length==1) ? true : null" -->
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-mtrl="item">
                                        <span class="font-weight-bold">{{mtrl.name}}</span> <br />
                                    </ng-template>
                                    <input name="product" type="text" placeholder="Filter by Material" id="Product"
                                        [typeahead]="lstAllMats" [(ngModel)]="matName" class="form-control form-control-sm"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct" [adaptivePosition]="true"
                                        formControlName="productId" typeaheadOptionField="name" [typeaheadOptionsLimit]="500" />
                                        <!-- (change)="chngProduct($event, 'byStr', 'chng')" 
                                            [attr.disabled]="(isFld!='' && isFld!='m') ? true : null"
                                            (typeaheadOnSelect)="chngProduct($event, 'byStr', 'slct')" -->
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Deviant type</label>
                                    <select class="form-select form-select-sm" formControlName="abnormalityType" #AbnrmlType>
                                        <option value="">-Select-</option>
                                        <option value="200">Zero Stock...</option>
                                        <option value="201">&lt; Min</option>
                                        <option value="202">&gt; Max</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3" *ngIf="AbnrmlType.value!=''">
                                    <label for="textBox">Deviant Duration</label>
                                    <input name="duration" type="text" placeholder="Enter Deviant Duration"
                                        id="duration" class="form-control form-control-sm" formControlName="duration"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">Expires before</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" placeholder="Filter by Expires before"
                                        bsDatepicker class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                        onkeypress='return event.charCode == 8' onpaste="return false" [dateCustomClasses]="cstmDt"
                                        (bsValueChange)="onChngDT($event)" [minDate]="dtToDay" formControlName="expireBefore"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }" readonly
                                        [attr.disabled]="(isFld!='' && isFld!='d') ? true : null">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen" [attr.disabled]="(isFld!='' && isFld!='d') ? true : null">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpED.hide();clearBSDate('expireBefore', 'first')" id="cdEB"
                                            [attr.disabled]="(isFld!='' && isFld!='d') ? true : null">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Batch enabled</label>
                                    <select class="form-select form-select-sm" formControlName="batchEnb">
                                        <option value="">ALL</option>
                                        <option value="wb">Batch enabled</option>
                                        <option value="nb">Batch not enabled</option>
                                    </select>
                                </div>
                            <div class="form-group col-md-3 pt_2">
                                <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" id="sr1AS"
                                    aria-expanded="false" aria-controls="box1" (click)="isAS=!isAS;onAS();">Advance Search</button>
                            </div>
                        </div>
                        <div id="box1" class="collapse">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">State</label>
                                    <select formControlName="state" class="form-select form-select-sm"
                                        (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">District</label>
                                    <select formControlName="district" class="form-select form-select-sm"
                                        (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Taluk/Block</label>
                                    <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5">
                                    <span class="pl-xl-3">Facility Category:
                                        <span *ngIf="isFacAll">ALL</span>
                                        <span *ngIf="!isFacAll && lstShowFacTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                                {{fc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown [settings]="ddlStngs"
                                                                        [data]="lstFacTags" formControlName="includeStoreBadge"                                                                        
                                                                        (onSelect)="onMultiSlct('s', 'f', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                                        [(ngModel)]="slctdFac">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onResetTags('f')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2" role="button"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onTagsSave('f')">Save</button>
                                                                <button class="btn btn-sm btn-light" (click)="onCncl('f')"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <span class="pl-xl-3">Material Category:
                                        <span *ngIf="isMatAll">ALL</span>
                                        <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                                {{mc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample2"
                                        id="sr1MC" (click)="isMC=!isMC">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">                                         
                                                                    <ng-multiselect-dropdown
                                                                        [settings]="ddlStngs" [(ngModel)]="slctdMat"
                                                                        [data]="lstMatTags" formControlName="includeProductBadge"
                                                                        (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                        [ngClass]="{ 'is-invalid': isExprt && lstShowMatTags.length==0 }">
                                                                    </ng-multiselect-dropdown>
                                                                    <div *ngIf="isExprt && lstShowMatTags.length==0" class="invalid-feedback">
                                                                        Please choose Material Category
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2"
                                                                    (click)="isMC=false;onResetTags('m')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2"
                                                                    href="#multiCollapseExample2" role="button"
                                                                    (click)="isMC=false;onTagsSave('m')">Save</button>
                                                                <button class="btn btn-sm btn-light" (click)="isMC=false;onCncl('m')"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="isExprt=false;crntPage=1;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="isExprt=false;LoadData()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <div class="modal-content">
                <div class="modal-header ws">
                    <h5 class="modal-title" id="exampleModalLabel">Achnera CHC</h5>
                    <button type="button" class="close btn btn-sm  btn-light" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="fcModal"></div>
                </div>
            </div>
        </div>
    </div>
</form>