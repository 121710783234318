import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { BaseComponent } from '../../base.component';

@Component({
  selector: 'evin-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends BaseComponent implements OnInit {

  lstGrd: any[] = []; noRcrdTxt = environment.TblNoRcrds; usrDetails: any[] = []; data: any; lstRoles: any[] = [];
  editRole: boolean = false; dstLst: any[] = []; editTmzn: boolean = false; tmZoneLst: any[] = []; permison: any = 'permison';
  editpermsn: boolean = false; usrTagList: any[] = []; editTags: boolean = false; usrTag: any[] = []; dropdownSettings = {};
  lstSlctdTags: any[] = []; editFName: boolean = false; gndr = ''; editGender: boolean = false; editMobile: boolean = false;
  editAltMobile: boolean = false; editEmail: boolean = false; editMail: boolean = false; cntryLst: any[] = []; LngLst: any[] = [];
  editCountry: boolean = false; stsLst: any[] = []; dstrtLst: any[] = []; blkLst: any[] = []; editState: boolean = false; isSbmtd = false;
  editLang: boolean = false; maxdate = new Date(); editDob: boolean = false; userBadges: any[] = []; rolesLst: any[] = []; cnfgVal: any = null;
  usrLvl=''; prnthId: any; editLName: boolean = false; prmsns: any = null; viewPrfl: boolean = true; prflEdt: boolean = true;
  isPrflHdn: boolean = false;

  usrprfForm = this.fb.group({
    address: [''], alt_number: ['', [Validators.minLength(10), Validators.maxLength(13)]], block: [''],
    city_id: [''], country_id: [''], district: [''], dob: [''],
    email: ['',  Validators.email], first_name: [''],
    language_id: [''], last_name: [''], mobile: ['', [Validators.minLength(10), Validators.maxLength(13)]],
    mobile_brand: [''], mobile_model: [''], time_zone_id: [''],
    permissions: [''], pin: [''], role_id: [''],
    gender: [null], sim: [''], state_id: [''], browser_details: [null],
    user_badge: [''],
    remote_login: ['Default as Domain'], msp: [''],
    updated_by: [''], created_by: [null]
  });
  formBuilder: any;
  usrId: any;
  slctdBadge: any;
  router: any;

  constructor(public glblSvc: GlobalService, private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.tmZoneLst = s.tmZoneLst, this.cntryLst = s.cntriesLst, this.LngLst = s.LngLst }),
      this.glblSvc.sbjctMstrData.subscribe(s => {
        this.cntryLst = s.cntriesLst, this.LngLst = s.LngLst,
          this.rolesLst = s.rlsLst.filter((f: any) => f.moduleName == 'General'), this.dstLst = s.dstLst;

      })
    this.glblSvc.sbjctBdgsList.
      subscribe((a: any) => { this.userBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.user) })
  }

  ngOnInit(): void {    
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Capabilities : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='cap_mi_ep')
     this.prflEdt = e?.action;
     if(e?.menuCode =='cap_mi_vp')
     this.viewPrfl = e?.action;
  });
    if(this.usrLgnDtls){
     let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '';
      if(this.usrLvl !='National')
      this.usrprfForm.get('state_id')?.disable();
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?. modulePermissionRoles[1] : null;

    }
    this.isPrflHdn = this.cnfgVal?.permissionRoles[16]?.configuredValue?.value ?? false;
    this.dropdowns(); this.getUserById(); this.getUsrFsltyDtls(); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
  }

  getUsrFsltyDtls() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
      environment.apiMthds.getStrsbyUserId.replace('uuu', this.usrLgnDtls?.userId).replace('ppp', `${this.chsnDmnId}`), 0, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data)
              this.lstGrd = this.result?.data;
            // else
            //   this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
  }

  get fc() { return this.usrprfForm.controls; }

  getUserById() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrbyId.replace('uuu', this.usrLgnDtls?.userId), null, 7).
    then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.usrDetails = this.result.data != null ? this.result.data : []; this.data = this.usrDetails[0]; this.usrPrflPatchValues();
        }
      }
    });
  }

  usrPrflPatchValues(){
    this.usrprfForm.patchValue({
      alt_number: this.usrDetails[0]?.alt_number, id: this.usrDetails[0]?.id,
      role_id: this.usrDetails[0]?.role_id ?? '', permissions: (this.usrDetails[0]?.permissions == '' || this.usrDetails[0]?.permissions == null)
      ? 'default' : this.usrDetails[0]?.permissions, first_name: this.usrDetails[0]?.first_name,
      token_expiry_indays: this.usrDetails[0].token_expiry_indays, last_name: this.usrDetails[0]?.last_name,
      gender: this.usrDetails[0]?.gender ?? '', dob: this.usrDetails[0]?.dob != null ? (new Date(this.usrDetails[0]?.dob)) : '', mobile: this.usrDetails[0]?.mobile,
      email: this.usrDetails[0]?.email, country_id: +this.usrDetails[0]?.country_id, state_id: +this.usrDetails[0]?.state_id,
      language_id: this.usrDetails[0]?.language_id ?? '', user_badge: this.usrDetails[0].badgeids, sim: this.usrDetails[0]?.sim,
      district: +this.usrDetails[0]?.district_id, msp: this.usrDetails[0]?.msp, browser_details: this.usrDetails[0].browser_details,
      time_zone_id: this.usrDetails[0]?.time_zone_id, mobile_app_version: this.usrDetails[0]?.mobile_app_version,
      mobile_brand: this.usrDetails[0]?.mobile_brand, mobile_model: this.usrDetails[0]?.mobile_model, pin: this.usrDetails[0].pin,
      ip_address: this.usrDetails[0].ip_address, created_by: this.usrDetails[0].created_by, created_on: this.usrDetails[0].created_on,
      created_user: this.usrDetails[0].created_user, last_login_time: this.usrDetails[0].last_login_time,
      last_reconnected_time: this.usrDetails[0].last_reconnected_time, last_remote_login_time: this.usrDetails[0].last_remote_login_time,
      updated_by: this.usrDetails[0].updated_by, updated_on: this.usrDetails[0].updated_on, updated_user: this.usrDetails[0].updated_user,
      city_id: this.usrDetails[0].city_id, address: this.usrDetails[0].address, block: +this.usrDetails[0]?.block_id
      // district : this.dstLst.filter(e =>{if(e.districtid == this.usrDetails[0]?.district_id)})
    });
    this.prnthId = this.usrDetails[0]?.pranth_id;
    let tagss = { target: { value: this.usrDetails[0]?.badgeids} }
    this.onItemSelect(tagss);
  }
  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }
  getUserBadges(badge: string){
    let badgeId :any =[];
    badgeId= badge.split(',');
    if(badgeId.length>1)
     return '';
     else
     return badge;
  }

  clearCntrlValue(c: string) {
    if(c=='dob')
      this.usrprfForm.get(c)?.setValue(this.data?.dob != null ? (new Date(this.data?.dob)) : '');
    else
    this.usrprfForm.get(c)?.setValue(this.data?.[c]);
    // if(c=='user_badge'){
    // this.usrprfForm.get(c)?.setValue(this.getUserBadges(this.data?.badgeids));
    // }
    // else
    // this.usrprfForm.get(c)?.setValue(this.data?.[c]);

  }

  dropdowns() {
    this.dropdownSettings = {
      singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  onNestedSlct($event: any, type: any, isId: boolean = false) {
    let cid = '', sid = '', did = '';
    if (type == 'country')
      cid = isId ? $event : $event.target.value;
    else if (type == 'state')
      sid = isId ? $event : $event.target.value;
    else
      did = isId ? $event : $event.target.value;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.getStates.
      replace('ccc', cid) : (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) : environment.apiMthds.getBlocks.
        replace('ddd', did))), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'country')
                this.stsLst = this.result.data != null ? this.result.data : [];
              else if (type == 'state')
                this.dstrtLst = this.result.data != null ? this.result.data : [];
              else
                this.blkLst = this.result.data != null ? this.result.data : [];
            }
          }
        });
  }

  onItemSelect($event: any) {
    this.slctdBadge = [], this.slctdBadge.push({ badgeId: +$event.target.value });
  }

  updateUser() {
    this.checkFormValidations(this.usrprfForm);
    if (this.usrprfForm.invalid)
      this.editMobile=true, this.editAltMobile =true, this.editEmail= true,
      this.glblSvc.onTstr('w', 'Kindly fill/check Correct Format');
    else {
      let payLoad = this.usrprfForm.getRawValue();
      payLoad.id = this.usrLgnDtls?.userId,
        payLoad.block_id = (payLoad.block != null && payLoad.block != '') ? +payLoad.block : null, payLoad.first_name = payLoad.first_name,
        payLoad.country_id = (payLoad.country_id != null && payLoad.country_id != '') ? +payLoad.country_id : null,
        payLoad.district_id = (payLoad.district != null && payLoad.district != '') ? +payLoad.district : null,
        payLoad.state_id = (payLoad.state_id != null && payLoad.state_id != '') ? +payLoad.state_id : null, payLoad.user_id = this.data?.username,
        payLoad.sim = (payLoad.sim != null && payLoad.sim != '') ? +payLoad.sim : null, payLoad.user_badge = this.slctdBadge,
        payLoad.dob = (payLoad.dob != null && payLoad.dob != '') ? this.glblSvc.setDtFrmt(payLoad.dob, this.dtF.yyy) + this.tmF.noTM : null,
        payLoad.role_id = (payLoad.role_id != null && payLoad.role_id != '') ? +payLoad.role_id : null, payLoad.login_preferences = 1,
        payLoad.language_id = (payLoad.language_id != null && payLoad.language_id != '') ? +payLoad.language_id : '',
        payLoad.time_zone_id = (payLoad.time_zone_id != null && payLoad.time_zone_id != '') ? +payLoad.time_zone_id : null,
        payLoad.token_expiry_indays = (payLoad.token_expiry_indays != null && payLoad.token_expiry_indays != '') ? +payLoad.token_expiry_indays : null,
        payLoad.created_by = this.usrLgnDtls.userId, payLoad.updated_by = payLoad.created_by, payLoad.last_name = payLoad.last_name,
        payLoad.pranth_id = this.prnthId, ['username', 'state', 'district', 'block', 'role'].forEach(e => { delete payLoad[e] }); //delete payLoad.cpwd,payLoad.last_logged_on = null,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message);
            this.getUserById();
          }
          else
            this.glblSvc.onTstr('e', this.result.message);

            this.editRole = false, this.editTags = false, this.editpermsn = false, this.editFName = false, this.editGender = false, this.editDob = false,
            this.editMobile = false, this.editAltMobile = false, this.editMail = false, this.editCountry = false, this.editState = false, this.editLang = false,
            this.editTmzn = false; this.editLName = false;
        }
      });
    }
  }

  clearBSDate(ctrl: string) {
    this.usrprfForm.get(ctrl)?.setValue('');
  }

  onCancel() {
      this.isSbmtd = false, this.editLName =  false,
      this.editRole = false, this.editTags = false, this.editpermsn = false, this.editFName = false, this.editGender = false, this.editDob = false,
      this.editMobile = false, this.editAltMobile = false, this.editMail = false, this.editCountry = false, this.editState = false, this.editLang = false,
      this.editTmzn = false;  this.usrPrflPatchValues();
  }
}
