import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-sickness-rate',
  templateUrl: './sickness-rate.component.html',
  styleUrls: ['./sickness-rate.component.css']
})
export class SicknessRateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
