<form [formGroup]="trnsporterForm" autocomplete="off">
    <div class="row p-0 mt-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="carrierName">Transporter</label>
                            <input type="text" placeholder="Filter by Transporter" class="form-control form-control-sm"
                                id="carrierName" formControlName="carrierName">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="contact_no">Mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="contact_no"
                                    formControlName="contact_no" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    id="contact_no" formControlName="contact_no"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.contact_no.errors }" minlength="10" maxlength="13">
                                <div *ngIf="isSbmtd && fc.contact_no.errors" class="invalid-feedback">
                                    <div *ngIf="fc.contact_no.errors.minlength || fc.contact_no.errors.maxlength">
                                        Mobile number should be min. of 10 and max. of 13 digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-xl-end pt_2" *ngIf="trnsprtrsList">
                                <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;isSbmtd=false;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary" (click)="crntPage=1;isSbmtd=true;LoadTransporters()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="transportersLst?.length!=0">
                <div class="col-xl-12 mb-2">
                    <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <!-- <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-sm table_spacing table-small-font p-0 table-bordered"> -->
            <div class="table_spacing table-responsive mt-0">
                <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                    <thead>
                        <tr>
                            <th class="text-center">Item No.</th>
                            <th class="text-center">Name</th>
                            <th class="text-center">Phone number</th>
                            <th class="text-center">Type</th>
                            <th class="text-center">Last updated</th>
                            <th *ngIf="trnsprtrEdt"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of transportersLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let indx = index">
                            <td class="text-center">{{(pageSize*(crntPage-1))+indx+1}}</td>
                            <td class="text-center" (click)="onTrnsDetails(item.id)"><a>{{item.carrier_name}}</a></td>
                            <td class="text-center">{{item.contact_no}}</td>
                            <td class="text-center">{{item.carrier_type}}</td>
                            <td class="text-center"><span><a>{{item.updatedby}}</a></span>
                                <p class="small">{{item.updated_on | date: "dd/MM/yy hh:mm a"}}</p>
                            </td>
                            <!-- <td *ngIf="trnsprtrEdt" (click)="onTrnsEdit()"><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td> -->
                            <td class="text-center">
                                <span>
                                    <a (click)="onTrnsEdit(item.id)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                </span>&nbsp;
                                <span>
                                    <a (click)="onRemove(item.id)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="transportersLst?.length == 0">
                        <tr>
                            <td colspan="6" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="transportersLst?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6" *ngIf="transportersLst?.length!=0">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>