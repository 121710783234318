<div class="card p-2 border-0">
    <div class="card-header shadow-sm p-2">
        <h6 class="mb-0">Stock adjustments configuration</h6>
    </div>
    <div class="card-body p-0 mt-0 mb-0">
        <form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card p-2 mt-2 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">General</h6>
                        </div>
                        <div class="card-body p-0">
                            <div class="small">Specify the general configuration for stock adjustments</div>
                            <div class="row mt-3">
                                <div class="form-group col-xl-12 mb-2">
                                    <label for="textBox" class="f-w-500 mb-2 me-2">Stock adjustments
                                        capability</label><br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Enable stock adjustments
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-sm-3 mt-1">
                                    <label class="textBox mb-0" for="flexCheckDefault">
                                        Include materials with categories <span class="text-danger">*</span>
                                    </label>
                                    <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                        <optgroup label="">
                                            <option value="AL">CAMPAIGN</option>
                                            <option value="WY">COVID vaccine</option>
                                            <option value="WY">HIV</option>
                                            <option value="WY">Malaria</option>
                                            <option value="WY">OPEN Vials</option>
                                            <option value="WY">RI vaccine</option>
                                            <option value="WY">Syringes</option>
                                            <option value="WY">TB</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-sm-12 mt-1">
                                <label class="textBox mt-3" for="flexCheckDefault">
                                    Include facilities with categories <span class="text-danger">*</span>
                                </label>
                                <div class="small">Specify the combinations of facility categories among which stock
                                    adjustments can occur</div>
                                <div class="table-responsive mt-4">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center">Item No.</th>
                                                <th scope="col" class="text-center">Facility categories</th>
                                                <th scope="col" class="text-center"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">1</td>
                                                <td><input type="text" placeholder="" class="form-control" id=""></td>
                                                <td><i class="fa fa-trash" aria-hidden="true"></i></td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="4"><button type="button" class="btn btn-sm btn-primary"><i
                                                            class="fa fa-plus" aria-hidden="true"></i></button></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="form-group col-sm-6 mt-3">
                                <label class="textBox mb-0" for="flexCheckDefault">
                                    Distance between source and destination for geofencing <span
                                        class="text-danger">*</span>
                                </label>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input type="text"
                                            placeholder="Distance between source and destination for geofencing"
                                            value="" class="form-control" id="">
                                    </div>
                                    <div class="col-sm-2">
                                        km(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-2 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Destination triggers</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify the configuration for destination triggers
                            </div>
                            <div class="form-group col-xl-12 mt-3 mb-2">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Triggers to enable</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Stock out duration exceeds the threshold
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2">
                        <div class="card-header p-2 ">
                            <h6 class="mb-0">Source triggers</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify the configurations for source triggers
                            </div>
                            <div class="form-group col-xl-12 mb-2 mt-3">
                                <label for="textBox" class="f-w-500 mb-2 me-2">Triggers to enable</label><br />
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Stock expiring before it can be consumed
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                    <label class="form-check-label" for="flexCheckChecked">
                                        Stock &gt; max for more than specified number of days
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Cost parameters</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify the configurations for cost parameters
                            </div>
                            <div class="row mt-3">
                                <div class="form-group col-sm-6">
                                    <label class="textBox mb-0" for="flexCheckDefault">
                                        Transportation cost
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <input type="text" placeholder="Transportation cost" value=""
                                                class="form-control" id="">
                                        </div>
                                        <div class="col-sm-2">
                                            km(s)
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-sm-6">
                                    <label class="textBox mb-0" for="flexCheckDefault">
                                        Handling charges
                                    </label>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <input type="text" placeholder=" Handling charges" value=""
                                                class="form-control" id="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-2">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Benefit parameters</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">
                                Specify the configurations for benefit parameters
                            </div>
                            <div class="form-group col-sm-6 mt-3">
                                <label class="textBox mb-0" for="flexCheckDefault">
                                    Inventory holding cost (as % of cost price)
                                </label>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <input type="text" placeholder="Inventory holding cost" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-sm-2">
                                        %
                                    </div>
                                </div>
                                <div class="small mt-1">Savings from transferring excess stock</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>