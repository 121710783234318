import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.css']
})

export class UserActivityComponent extends BaseComponent implements OnInit {

  isUserBdge = 'badge'; lstUsrs: any[] = []; lstUsrTags: any[] = []; userBadges: any[] = []; pstT = 'Monthly'; isMnthChng = false; tab = 'u';
  isDayChng = false; txtFD = ''; txtED = ''; dpSDVal: any = new Date(); dpEDVal: any = new Date(); maxToDate: Date = new Date();
  crntDate: Date = new Date(); pLSD = ''; plED = ''; maxWED = this.GetReqDate4mCrntWeek(1); isMatric = false; lstSlctduserIds: any[] = [];
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true }; dd2Stngs = { singleSelection: false, idField: 'userid', textField: 'username',
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; slctdUsr: any[] = []; lstAllUsrs: any[] = [];
  lstSlctdUsrTags: any[] = []; lstSlctdMetrics: any[] = []; slctduserId: any[] = [];  lstFAC: any[] = []; isSbmtd = false; lstMtrc: any[] = [];
  usrActForm: FormGroup = this.fb.group({}); slctdMtrc: any[] = []; lstLOC: any[] = []; lstTxnTyps: any[] = []; orderStatusList: any[] = [];
  pageEntrs = ''; crntPage = 1; lstGrid: any[] = []; act: any; selectedEvent: any; frmDate: any; toDate: any; frmDates: any; toDates: any;
  ordLst: any[] = []; actvity: any; pstType = 'monthly'; fD2 = ''; eD2 = ''; lstDataDDL: any[] = []; lstX: any[] = []; lstY: any[] = [];
  lstMAT: any[] = []; lstMetrc:any; drpVal = '1'; isDtCng:string= ''; lstUsrsTag:any; lstUsrsName:any; isPgL:boolean =false;
  noRcrdTxt = environment.TblNoRcrds; isDTBL:boolean = true; yBDate : any; mBDate : any; monSplit: any; lstDX : any[] = []; lstDY : any[] = [];
  lstGridDate : any[] =[]; dt2days = new Date(); lgnUsrData = this.usrLgnDtls; lst = [{ label: 'Normal', value: '86.94',
    'isSliced': '1' }, { label: 'Zero Stock', value: '1', }, { label: 'Min', value: '5', }, { label: 'Max', value: '7.06' }]; yrMin : any; ttlItms: any;
  lblTextOfDate:any = 'Max 12 months will be accepted'; isShwDayTable: boolean=  false; isRprtsHlpShw = environment.isRprtsHlpShw;
  slctdMetrc: any[] = []; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; userlgndtls: any = null;  steRes: string = '';
  dstRes: string = ''; steResDly: string = ''; dstResDly: string = ''; pageSize = environment.tblPageSize; ttlDayItms: any; crntDPage = 1;
  pageEntrsD = ''; isWekChng = false; expDrpn: any = '1'; expDrpnDly: any = '1'; ttlCount: any[] = []; ttlCountDly: any[] = [];
  trkVal: any[] = []; lstTrkTyps: any[] = []; slctTrkType: any =''; lstTrkTypss: any[] = []; lstSlctdTrk: any[] = [];

  constructor(public glblSvc: GlobalService, private formbuilder: FormBuilder, private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.userBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.user)}),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstTxnTyps = s.txnTypeLst }); this.glblSvc.sbjctMstrData.subscribe(s => {
      this.orderStatusList = s.stsLst?.filter((f: any) => f.statusType === 'Order')
      this.ordLst = this.orderStatusList?.filter(e => (e.name == 'Fulfilled' || e.name == 'Shipped' || e.name == 'Confirmed'));
      this.lstMnths.filter(e => (e.name == '')) });
  }

  ngOnInit(): void {
    if (this.usrLgnDtls)
      this.glblSvc.sbjctSpnr.next(true), Promise.all([this.DoughNutChart(this.lst, 'column2d', 'fcbarInv')]).then(() => {
      this.glblSvc.sbjctSpnr.next(false); }), 
      this.usrActForm = this.formbuilder.group({ isUserBdge: ['badge'], pstT: ['Monthly'], userBadgeId: [[], Validators.required], userId: [[],
      Validators.required], usrMatric: [[]], dpSDVal: ['', Validators.required], dpEDVal: ['', Validators.required], userLoginActivity: ['1'],
      fromDate: [''], toDate: [''], block: [null], district: [null], state: [null],  trackingObjectId: [[]] }),
      this.UsrNamesLst(); localStorage.setItem('isPgLd', '0');
      // this.glblSvc.sbjctSpnr.next(true), Promise.all([, setTimeout(() => { this.FacList()}, 1000)]).then(() => { setTimeout(() => {
      //   this.glblSvc.sbjctSpnr.next(true)}, 1000) });
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null;
        this.usrActForm.get('country')?.setValue(cntryId);
    } this.dmnLocation(), this.LoadTrkTyp(); setTimeout(() => { this.onAS(); }, 1000);
  }

  FacList(){
    this.lstMtrc.forEach(e => { this.lstSlctdMetrics.push({ id: e.id, name: e.name }), this.slctdMetrc.push(e) }); // , this.facCatgry.push(e.name) 
  }

  get fc() { return this.usrActForm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.usrActForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.usrActForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.usrActForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.usrActForm.get('district')?.disable(), this.usrActForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.usrActForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.usrActForm.get('district')?.disable(), this.usrActForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.usrActForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.usrActForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.usrActForm.get('district')?.disable(), this.usrActForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.usrActForm.get('state')?.setValue('null'), this.usrActForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }
  
  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.usrActForm.get('district')?.setValue(null),
          this.usrActForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.usrActForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.usrActForm.get('district')?.enable(),
                this.usrActForm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.usrActForm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.usrActForm.get('district')?.setValue('null'), this.usrActForm.get('block')?.setValue('null'),
          this.usrActForm.get('district')?.disable(), this.usrActForm.get('block')?.disable();
      else
        this.usrActForm.get('block')?.setValue('null'), this.usrActForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.usrActForm.get('state')?.enable(), this.usrActForm.get('district')?.disable(), this.usrActForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.usrActForm.get('state')?.disable(), this.usrActForm.get('district')?.enable(), this.usrActForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.usrActForm.get('state')?.disable(), this.usrActForm.get('district')?.disable(), this.usrActForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.usrActForm.get('state')?.disable(), this.usrActForm.get('district')?.disable(), this.usrActForm.get('block')?.disable();
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.usrActForm.get('fromDate')?.setValue(''), this.usrActForm.get('toDate')?.setValue(''), this.usrActForm.get('dpSDVal')?.setValue(''),
          this.usrActForm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.usrActForm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.usrActForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.usrActForm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.usrActForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.usrActForm.get('fromDate')?.setValue(''), this.usrActForm.get('toDate')?.setValue(''), this.usrActForm.get('dpSDVal')?.setValue(''),
          this.usrActForm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.usrActForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.usrActForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);
            this.usrActForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.usrActForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.usrActForm.get('fromDate')?.setValue(''), this.usrActForm.get('toDate')?.setValue(''), this.usrActForm.get('dpSDVal')?.setValue(''),
          this.usrActForm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.usrActForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.usrActForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.usrActForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.usrActForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }
  
  onChngPstT() {
    this.usrActForm.get('dpSDVal')?.setValue(''); this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' :
      (this.pstT=='Daily' ? 'Max 31 days will be accepted' :'Max 42 days will be accepted'), this.pstType = this.pstT.toLowerCase(),
      this.isMnthChng = this.pstT == 'Monthly', this.isWekChng = this.pstT == 'Weekly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='',
      this.dpEDVal='';
  }

  chngActvty(event: any) {
    this.selectedEvent = event.value; this.lstSlctdMetrics = [...[]], this.lstMtrc = [...[]], this.slctdMtrc = [...[]];
    if (event.value == 1)
      this.isMatric = false;
    else if (event.value == 2)
      this.isMatric = true, this.lstTxnTyps.forEach(e => { this.lstSlctdMetrics.push({ id: e.id, name: e.name }), this.slctdMtrc.push(e) }),
        setTimeout(() => { this.lstMtrc = [...this.lstTxnTyps] }, 1000);
    else
      this.isMatric = true, this.ordLst.forEach(e => { this.lstSlctdMetrics.push({ id: e.id, name: e.name }), this.slctdMtrc.push(e) }),
        setTimeout(() => { this.lstMtrc = [...this.ordLst] }, 1000);
  }

  onTabClk(tabType: string) {
    this.tab = tabType; this.lstGrid = []; this.GenRprt();
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctduserIds.filter((f: { userid: any; }) => f.userid === item.userid).length == 0)
          this.lstSlctduserIds.push(item);
      } else if (ctrl == 'eT') {
          if (this.lstSlctdTrk.filter((f: { id: any; }) => f.id === item.id).length == 0)
            this.lstSlctdTrk.push(item);
      } else if (ctrl == 'n') {
        if (this.lstSlctdUsrTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdUsrTags.push(item);
      }
      else {
        if (this.lstSlctdMetrics.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMetrics.push(item), this.lstSlctdMetrics.length == 1 ? this.loadTrk(this.lstSlctdMetrics[0]?.id) : 
            (this.lstTrkTypss = [], this.lstSlctdTrk = [], this.trkVal = [])   //, this.GenRprt();
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctduserIds = this.lstSlctduserIds.filter((f: { userid: any; }) => f.userid != item.userid);
      else if (ctrl == 'eT')
        this.lstSlctdTrk = this.lstSlctdTrk.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'n')
        this.lstSlctdUsrTags = this.lstSlctdUsrTags.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdMetrics = this.lstSlctdMetrics.filter((f: { id: any; }) => f.id != item.id), this.lstTrkTypss = [], this.lstSlctdTrk = [],
        this.trkVal = [], this.loadTrk(this.lstSlctdMetrics[0]?.id ?? ''); //, this.GenRprt();
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctduserIds = this.lstAllUsrs;
      else if (ctrl == 'eT')
        this.lstSlctdTrk = this.lstTrkTypss;
      else if (ctrl == 'n')
        this.lstSlctdUsrTags = this.userBadges;
      else
        this.lstSlctdMetrics = this.lstMtrc, this.lstTrkTypss = [], this.lstSlctdTrk = [], this.trkVal = []; //, this.GenRprt();
    }
    else {
      if (ctrl == 'f')
        this.lstSlctduserIds = [];
      else if (ctrl == 'eT')
        this.lstSlctdTrk = [];
      else if (ctrl == 'n')
        this.lstSlctdUsrTags = [];
      else
        this.lstSlctdMetrics = [], this.lstTrkTypss = [], this.lstSlctdTrk = [], this.trkVal = [];//, this.GenRprt();
    }
  }

  onClkRdb(ctrl: string) {
    this.fc?.userBadgeId.setValue([]), this.fc?.userId.setValue([]); this.lstSlctduserIds = []; this.lstSlctdUsrTags = [];
    if (ctrl == 'userBadgeId')
      this.fc?.userId?.clearValidators(), this.fc?.userBadgeId?.setValidators([Validators.required]), this.lstSlctduserIds = [];
    else
      this.fc?.userBadgeId?.clearValidators(), this.fc?.userId?.setValidators([Validators.required]);
    this.fc?.userBadgeId?.updateValueAndValidity(), this.fc?.userId?.updateValueAndValidity(), this.lstSlctdUsrTags = [];
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  UsrNamesLst() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.usrActivity, environment.apiMthds.usrLst, null, 4).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllUsrs = this.result.data != null ? this.result.data : []
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadTrkTyp() {
    this.lstTrkTyps = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrTrkTyp, environment.apiMthds.getAll,
      null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstTrkTyps = this.result?.data ?? [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }
  
  loadTrk(mtricId: any) {
    if( mtricId == '1' || mtricId == '2') {
      let x = [1, 2, 3], y = [3, 4, 5];
     this.lstTrkTypss = []; mtricId == '1' ? this.lstTrkTyps.filter(e=>{ if(x.includes(e.id)){this.lstTrkTypss.push(e)}}) :
       (mtricId == '2' ? this.lstTrkTyps.filter(e=>{ if(y.includes(e.id)){this.lstTrkTypss.push(e)}}) : '');
    } else {
     this.lstTrkTypss = [], this.lstSlctdTrk = [], this.trkVal = [];
    }
  }

  GenRprt(type: string = 'g') {
    let formCtrls = this.usrActForm.getRawValue(); this.lstGridDate = [];
    if ((this.fc.userBadgeId.value != '' || this.fc.userId.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != '') {
        this.actvity = (this.usrActForm.controls.userLoginActivity.value == 1 ? 'Number of logins' :
        (this.usrActForm.controls.userLoginActivity.value == 2 ? 'Number of transactions': ''));
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.usrActForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.usrActForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
        this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal  &&  this.pstT == 'Monthly'){
          if(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)){
            this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
          }
          else {
            let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
           let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]),
           dtdts = dts.split('...');
           this.usrActForm.get('toDate')?.setValue(dtdts[1]);
          }
        }
        else{
          this.usrActForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        }
      }
      let payLoad: any = this.usrActForm.getRawValue(), mT: any[] = [], uT: any[] = [], uN: any[] = [], lstMetrics: any[] = [], eT: any[] = [],
      lstUsrsTags: any[] = [], lstUsrsNames: any[] = []; this.isDtCng = this.pstT;
      this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) : 
        (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.toDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
        (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
      payLoad.userActivityFromDate = payLoad.fromDate, payLoad.userActivityToDate = payLoad.toDate,
        this.lstSlctdMetrics?.forEach((e: any) => {lstMetrics.push(e.name) }); this.lstMetrc = lstMetrics;
        this.lstSlctduserIds?.forEach((e: any) => { lstUsrsNames.push(e.username) }),this.lstUsrsName = lstUsrsNames;
        this.lstSlctdUsrTags?.forEach((e: any) => {lstUsrsTags.push(e.name) }),this.lstUsrsTag = lstUsrsTags;
        payLoad.userId = payLoad.userId != null ? this.lstSlctduserIds?.forEach((e: any) => { uN.push(e.userid) }) : [], 
        payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
        payLoad.userId = uN; payLoad.userBadgeId = payLoad.userBadgeId != null ? this.lstSlctdUsrTags?.forEach((e: any) => { uT.push(e.id) }) : [],
        payLoad.userBadgeId = uT; payLoad.userLoginActivity = payLoad.userLoginActivity != '' && payLoad.userLoginActivity != null ? + 
        payLoad.userLoginActivity : '1',  payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.trackingObjectId = 
        (payLoad.trackingObjectId != '' && payLoad.trackingObjectId != null && payLoad.trackingObjectId != undefined) && 
        (this.lstSlctdMetrics[0]?.id == '1' || this.lstSlctdMetrics[0]?.id == '2') ? this.lstSlctdTrk?.forEach((e: any) => { eT.push(e.id) }) : 
          [], payLoad.trackingObjectId = eT, ['isUserBdge', 'pstT', 'usrMatric', 'dpSDVal', 'dpEDVal', 'fromDate', 'toDate', 'block', 'state',
          'district'].forEach(e => { delete payLoad[e] }); this.lstSlctdMetrics?.forEach((e: any) => { mT.push(e.id) });
      if (this.selectedEvent == 1)
        payLoad.indentStatusId = [], payLoad.txnTypeId = [];
      else if (this.selectedEvent == 2)
        payLoad.indentStatusId = [], payLoad.txnTypeId = mT;
      else
        payLoad.txnTypeId = [], payLoad.indentStatusId = mT;
      if  (type == 'g')
       this.isShwDayTable = false, this.onGen(payLoad);
      else
        payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '', this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onCncl() {
    this.chngActvty({ value: '1' }), this.lstMtrc = [...[]], this.slctdMtrc = [...[]], this.lblTextOfDate = 'Max 12 months will be accepted',
    this.usrActForm.get('userId')?.setValue([]), this.usrActForm.get('userBadgeId')?.setValue([]), this.drpVal = '1'; this.ttlCount = [];
    this.usrActForm.get('isUsrBdge')?.setValue('badge'), this.usrActForm.get('pstT')?.setValue('Monthly'), this.lstSlctdMetrics = [...[]],
    this.lstSlctduserIds = [...[]], this.lstSlctdUsrTags = [...[]], this.slctdUsr = [...[]], this.slctduserId = [...[]], this.expDrpn = '1',
    this.usrActForm.get('userLoginActivity')?.setValue('1'); this.dpEDVal ='', this.dpSDVal='',  this.isShwDayTable =false, this.trkVal = [],
    this.usrActForm.get('dpSDVal')?.setValue(''), this.usrActForm.get('dpEDVal')?.setValue(''),this.usrActForm.get('fromDate')?.setValue(''),
    this.usrActForm.get('toDate')?.setValue(''), this.dmnLocation(), setTimeout(() => { this.onAS()}, 500), this.expDrpnDly = '1';
    this.ttlCountDly = [], this.usrActForm.get('trackingObjectId')?.setValue([]);
  }

  onGen(payLoad: any) {
    this.lstGrid = [], this.lstX = [], this.lstY = [], this.lstMAT = [], this.lstFAC = [], this.lstLOC = [], this.ttlCount = [],
      this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity, environment.apiMthds.usrActivityReport.
        replace('ttt', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`), payLoad, 4).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.ttlItms = this.result.data?.totalListSize ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms), 
                  Object.keys(data.groupingByName).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0];
                    if (i == 0)
                      data.groupingByName[s].forEach((m: any) => { hdrs.push(m.rdate) });
                      // this.steRes = data.groupingByName[s][0]?.state, this.dstRes = data.groupingByName[s][0].district;
                    result.push({ kId: null, kName: sName, matData: data.groupingByName[s], hdrs: hdrs });
                  });
                  data.dateAndCount.forEach((s: any) => this.ttlCount.push(s.count));
                  this.lstGrid = result, this.steRes = this.lstGrid[0]?.matData[0]?.stateName, this.dstRes = this.lstGrid[0]?.matData[0].districtName;
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
  }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity, environment.apiMthds.usrActivityReportExprt.
        replace('ttt', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('eee',`${this.usrLgnDtls.email}`).
        replace('nnn',`${this.usrLgnDtls.userName}`).replace('iii',`${this.usrLgnDtls.userId}`), payLoad, 4).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  GetDailyData(dtt: any, type: string='g') {
    if (this.pstType == 'monthly' || this.pstType == 'weekly') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt !='') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if (this.pstType == 'monthly') {
        dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd);
        let mn = sm.split("-"), mnth = this.mnths.filter(m => m.name == mn[0]), v = this.lstMnths.find(month => month.val == mnth[0].val),
        fromDateDay = mn[1] + '-' + v?.val + '-01', toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if (dtEND < toDateDay)
          maxDate = dtEND;
        else
          maxDate = toDateDay;
      }
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length-1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.usrActForm.getRawValue(), mT: any[] = [], uT: any[] = [], uN: any[] = [], eT: any[] = [],
        lstMetrics: any[] = [], lstUsrsTags: any[] = [], lstUsrsNames: any[] = [];
      this.lstSlctdMetrics?.forEach((e: any) => { lstMetrics.push(e.name) }); this.lstMetrc = lstMetrics;
      this.lstSlctduserIds?.forEach((e: any) => { lstUsrsNames.push(e.username) }), this.lstUsrsName = lstUsrsNames;
      this.lstSlctdUsrTags?.forEach((e: any) => { lstUsrsTags.push(e.name) }), this.lstUsrsTag = lstUsrsTags; 
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.toDates =
      this.pstType == 'weekly' ? maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.userActivityFromDate = this.pstType == 'weekly' ? 
      this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy): frmDte, payLoad.userActivityToDate = this.pstType == 'weekly' ?
      this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate, payLoad.userId =  payLoad.userId != null ? this.lstSlctduserIds?.forEach((e: any) => 
        { uN.push(e.userid) }) : [], payLoad.userId = uN; payLoad.userBadgeId = payLoad.userBadgeId != null ? this.lstSlctdUsrTags?.forEach((e: any) => { uT.push(e.id) }) : [],
      payLoad.userBadgeId = uT; payLoad.userLoginActivity = payLoad.userLoginActivity != '' && payLoad.userLoginActivity != null ? +
      payLoad.userLoginActivity : '1', payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : 
        +payLoad.district, payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.trackingObjectId = 
        (payLoad.trackingObjectId != '' && payLoad.trackingObjectId != null && payLoad.trackingObjectId != undefined) && (this.lstSlctdMetrics[0]?.id || 
          this.lstSlctdMetrics[0]?.id == '2') ? this.lstSlctdTrk?.forEach((e: any) => { eT.push(e.id) }) : [], payLoad.trackingObjectId = eT,
          ['isUserBdge', 'pstT', 'usrMatric', 'dpSDVal', 'dpEDVal','fromDate', 'toDate', 'block', 'state', 'district'].forEach(e => { delete payLoad[e] });
          this.lstSlctdMetrics?.forEach((e: any) => { mT.push(e.id) });
      if (this.selectedEvent == 1)
        payLoad.indentStatusId = [], payLoad.txnTypeId = [];
      else if (this.selectedEvent == 2)
        payLoad.indentStatusId = [], payLoad.txnTypeId = mT;
      else
        payLoad.txnTypeId = [], payLoad.indentStatusId = mT;
        if(type=='g')
          this.isShwDayTable = true, this.onGenDaysData(payLoad);
        else
          payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '', this.onDaysEE(payLoad);
    }
  }

onGenDaysData(payLoad: any){
  this.lstGridDate = []; this.ttlCountDly = [];
  this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity, environment.apiMthds.usrActivityReport.
    replace('ttt', 'd'), payLoad, 4).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
            if (data) {
              this.ttlDayItms = this.result.data?.totalListSize ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms), 
              Object.keys(data.groupingByName).forEach((s: any, i: number) => {
                let sName = `${s}`.split('||')[0];
                if (i == 0)
                  data.groupingByName[s].forEach((m: any) => { hdrs.push(m.rdate) });
                result.push({ kId: null, kName: sName, matData: data.groupingByName[s], hdrs: hdrs, total: ttl });
              });
              data.dateAndCount.forEach((s: any) => this.ttlCountDly.push(s.count));
              this.lstGridDate = result, this.steResDly = this.lstGridDate[0]?.matData[0]?.stateName, this.dstResDly = this.lstGridDate[0]?.matData[0].districtName;
            }
            else if (data == null || data == "")
              this.glblSvc.onTstr('i', this.result.message);
          }
            // this.lstGridDate = this.result?.data != null ? this.result?.data : [];
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
}

onDaysEE(payLoad: any) {
  this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity, environment.apiMthds.usrActivityReportExprt.
    replace('ttt', 'd').replace('eee',`${this.usrLgnDtls.email}`).
    replace('nnn',`${this.usrLgnDtls.userName}`).replace('iii',`${this.usrLgnDtls.userId}`), payLoad, 4).then(() => {
    if (this.result)
      this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
  });
}

}