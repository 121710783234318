import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';


var $ = require('jquery'), FusionCharts = require('fusioncharts'), Charts = require('fusioncharts/fusioncharts.charts'),
  FusionTheme = require('fusioncharts/themes/fusioncharts.theme.fusion'), jQueryFusionCharts = require('jquery-fusioncharts');
Charts(FusionCharts), FusionTheme(FusionCharts);// jQueryFusionCharts(FusionCharts);

@Component({
  selector: 'evin-ovrw-dashboard-new',
  templateUrl: './ovrw-dashboard-new.component.html',
  styleUrls: ['./ovrw-dashboard-new.component.css']
})
export class OvrwDashboardNewComponent extends BaseComponent implements OnInit {

  lstUsrTags: any[] = []; lstFacTags: any[] = []; frstVst = true; mtrlDivHt = '0'; msdStngs = {
    singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
    unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
  }; isHideIND = false; isStkMatBdge = 'badge'; isActvMatBdge = 'badge'; indTxt: any = '';
  dfltStrTgsAct: any[] = []; slctdDefauFacTag: any[] = []; lstAllMats: any[] = []; slctdStkMat: any[] = []; noRcrdsTxt = environment.TblNoRcrds;
  lstMatTags: any[] = []; lstMatTags1: any[] = []; lstSlctdMatNames: any[] = []; lstSlctdMatTags: any[] = []; slctdActvtyMatName: any[] = [];
  slctdStkMatName: any[] = []; slctdActvtyMat: any[] = []; frmDate: any = ''; toDate: any = ''; slctdMat: any[] = []; slctdMatName: any[] = []; usrLvlDtls: any;
  overwFrm: FormGroup = this.fb.group({}); mapClrCode = 'g'; nullClrCode = '#e5dede'; ldrImg = `${window.origin}/assets/img/ldr.gif`; lstDmyIND: any[] = [{
    id: '001', value: '82', showlabel: '1',
    name: 'Andaman and Nicobar Islands'
  }, { id: '002', value: '24', showlabel: '1', name: 'Andhra Pradesh' }, {
    id: '003', value: '55',
    showlabel: '1', name: 'Arunachal Pradesh'
  }, { id: '004', value: '14', showlabel: '1', name: 'Assam' }, {
    id: '005', value: '29',
    showlabel: '1', name: 'Bihar'
  }, { id: '006', value: '42', showlabel: '1', name: 'Chandigarh' }, {
    id: '007', value: '15', showlabel: '1',
    name: 'Chhattisgarh'
  }, { id: '008', value: '25', showlabel: '1', name: 'Dadra and Nagar Haveli' }, {
    id: '009', value: '15', showlabel: '1',
    name: 'Daman and Diu'
  }, { id: '010', value: '99', showlabel: '1', name: 'Delhi' }, { id: '011', value: '62', showlabel: '1', name: 'Goa' },
  { id: '012', value: '3', showlabel: '1', name: 'Gujarat' }, { id: '013', value: '98', showlabel: '1', name: 'Haryana' }, {
    id: '014',
    value: '65', showlabel: '1', name: 'Himachal Pradesh'
  }, { id: '015', value: '7', showlabel: '1', name: 'Jammu and Kashmir' }, {
    id: '016',
    value: '41', showlabel: '1', name: 'Jharkhand'
  }, { id: '017', value: '48', showlabel: '1', name: 'Karnataka' }, {
    id: '018', value: '9',
    showlabel: '1', name: 'Kerala'
  }, { id: '019', value: '24', showlabel: '1', name: 'Lakshadweep' }, {
    id: '020', value: '18', showlabel: '1',
    name: 'Madhya Pradesh'
  }, { id: '021', value: '58', showlabel: '1', name: 'Maharashtra' }, {
    id: '022', value: '30', showlabel: '1',
    name: 'Manipur'
  }, { id: '023', value: '32', showlabel: '1', name: 'Meghalaya' }, { id: '024', value: '36', showlabel: '1', name: 'Mizoram' },
  { id: '025', value: '35', showlabel: '1', name: 'Nagaland' }, { id: '026', value: '49', showlabel: '1', name: 'Odisha' }, {
    id: '027',
    value: '24', showlabel: '1', name: 'Puducherry'
  }, { id: '028', value: '47', showlabel: '1', name: 'Punjab' }, {
    id: '029', value: '51',
    showlabel: '1', name: 'Rajasthan'
  }, { id: '030', value: '45', showlabel: '1', name: 'Sikkim' }, {
    id: '031', value: '16', showlabel: '1',
    name: 'Tamil Nadu'
  }, { id: '032', value: '79', showlabel: '1', name: 'Tripura' }, {
    id: '033', value: '1', showlabel: '1',
    name: 'Uttar Pradesh'
  }, { id: '034', value: '2', showlabel: '1', name: 'Uttarakhand' }, {
    id: '035', value: '75', showlabel: '1',
    name: 'West Bengal'
  }, { id: '036', value: '35', showlabel: '1', name: 'Telangana' }]; isAP = false; lstSteData: any[] = []; lstDstrctData: any[] = []; isCcpShow = false;
  /* [{ id: '001', value: '82', showlabel: '1', name: 'Andaman' }, { id: '002', value: '24', showlabel: '1' }, { id: '003', value: '56',
    showlabel: '1' }, { id: '004', value: '14', showlabel: '1' }, { id: '005', value: '29', showlabel: '1' }, { id: '006', value: '42',
    showlabel: '1' }, { id: '007', value: '15', showlabel: '1' }, { id: '008', value: '25', showlabel: '1' }, { id: '009', value: '15',
    showlabel: '1' }, { id: '010', value: '99', showlabel: '1' }, { id: '011', value: '62', showlabel: '1' }, { id: '012', value: '3',
    showlabel: '1' }, { id: '013', value: '98', showlabel: '1' }, { id: '014', value: '65', showlabel: '1' }, { id: '015', value: '7',
    showlabel: '1' }, { id: '016', value: '41', showlabel: '1' }, { id: '017', value: '48', showlabel: '1' }, { id: '018', value: '9',
    showlabel: '1' }, { id: '019', value: '24', showlabel: '1' }, { id: '020', value: '18', showlabel: '1' }, { id: '021', value: '58',
    showlabel: '1' }, { id: '022', value: '30', showlabel: '1' }, { id: '023', value: '32', showlabel: '1' }, { id: '024', value: '36',
    showlabel: '1' }, { id: '025', value: '35', showlabel: '1' }, { id: '026', value: '49', showlabel: '1' }, { id: '027', value: '24',
    showlabel: '1' }, { id: '028', value: '47', showlabel: '1' }, { id: '029', value: '51', showlabel: '1' }, { id: '030', value: '45',
    showlabel: '1' }, { id: '031', value: '16', showlabel: '1' }, { id: '032', value: '79', showlabel: '1' }, { id: '033', value: '1',
    showlabel: '1' }, { id: '034', value: '2', showlabel: '1' }, { id: '035', value: '75', showlabel: '1' }, { id: '036', value: '35',
    showlabel: '1' }]; */ /* [{ id: '001', value: '82', showLabel: '1', displayValue: 'Andaman and Nicobar Islands, 82%' },
{ id: '002', value: '24', showLabel: '1', displayValue: 'Andhra Pradesh, 24%' }, { id: '003', value: '56', showLabel: '1', displayValue: 'Arunachal Pradesh, 56%' },
{ id: '004', value: '14', showLabel: '1', displayValue: 'Assam, 14%' }, { id: '005', value: '29', showLabel: '1', displayValue: 'Bihar, 29%' },
{ id: '006', value: '42', showLabel: '1', displayValue: 'Chandigarh, 42%' }, { id: '007', value: '15', showLabel: '1', displayValue: 'Chhattisgarh, 15%' },
{ id: '008', value: '25', showLabel: '1', displayValue: 'Dadra and Nagar Haveli, 25%' }, { id: '009', value: '15', showLabel: '1', displayValue: 'Daman and Diu, 15%' },
{ id: '010', value: '99', showLabel: '1', displayValue: 'Delhi, 99%' }, { id: '011', value: '62', showLabel: '1', displayValue: 'Goa,6220' },
{ id: '012', value: '3', showLabel: '1', displayValue: 'Gujarat, 3%' }, { id: '013', value: '98', showLabel: '1', displayValue: 'Haryana, 98%' },
{ id: '014', value: '65', showLabel: '1', displayValue: 'Himachal Pradesh, 65%' }, { id: '015', value: '7', showLabel: '1', displayValue: 'Jammu and Kashmir, 7%' },
{ id: '016', value: '41', showLabel: '1', displayValue: 'Jharkhand, 41%' }, { id: '017', value: '48', showLabel: '1', displayValue: 'Karnataka, 48%' },
{ id: '018', value: '9', showLabel: '1', displayValue: 'Kerala, 9%' }, { id: '019', value: '24', showLabel: '1', displayValue: 'Lakshadweep, 24%' },
{ id: '020', value: '18', showLabel: '1', displayValue: 'Madhya Pradesh, 18%' }, { id: '021', value: '58', showLabel: '1', displayValue: 'Maharashtra, 58%' },
{ id: '022', value: '30', showLabel: '1', displayValue: 'Manipur, 30%' }, { id: '023', value: '32', showLabel: '1', displayValue: 'Meghalaya, 32%' },
{ id: '024', value: '36', showLabel: '1', displayValue: 'Mizoram, 36%' }, { id: '025', value: '35', showLabel: '1', displayValue: 'Nagaland, 35%' },
{ id: '026', value: '49', showLabel: '1', displayValue: 'Odisha, 49%' }, { id: '027', value: '24', showLabel: '1', displayValue: 'Puducherry, 24%' },
{ id: '028', value: '47', showLabel: '1', displayValue: 'Punjab, 47%' }, { id: '029', value: '51', showLabel: '1', displayValue: 'Rajasthan, 51%' },
{ id: '030', value: '45', showLabel: '1', displayValue: 'Sikkim, 45%' }, { id: '031', value: '16', showLabel: '1', displayValue: 'Tamil Nadu, 16%' },
{ id: '032', value: '79', showLabel: '1', displayValue: 'Tripura, 79%' }, { id: '033', value: '1', showLabel: '1', displayValue: 'Uttar Pradesh, 1%' },
{ id: '034', value: '2', showLabel: '1', displayValue: 'Uttarakhand, 2%' }, { id: '035', value: '75', showLabel: '1', displayValue: 'West Bengal, 75%' },
{ id: '036', value: '35', showLabel: '1', displayValue: 'Telangana, 35%' }]; */
  fcBarData = [{ label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' },
  { label: 'RVV', value: '42' }, { label: 'DPT', value: '81' }, { label: 'TT', value: '72' }, { label: 'fIPV', value: '35' },
  { label: 'BCG', value: '91' }, { label: 'BoPV', value: '51' }, { label: 'COVAXIN', value: '68' }, { label: 'JE', value: '24' },
  { label: 'JEEV', value: '61' }, { label: 'MMR', value: '49' }, { label: 'ROTASIL', value: '90' }, { label: 'PCV', value: '10' }];
  fcDn: any[] = [{ label: 'Normal', value: '86.94', isSliced: '1' }, { label: 'Max', value: '1' }, { label: 'Min', value: '5' },
  { label: 'Zero Stock', value: '7.06' }];
  clrCds = environment.clrCds;
  smPB: any[] = [{ label: 'Normal', value: '86.94', isSliced: '1' }, { label: 'Max', value: '1' }, { label: 'Min', value: '5' },
  { label: 'Zero Stock', value: '7.06' }]; tmpPB: any[] = [{ label: 'Normal', value: '44' }, { label: 'Low', value: '22' },
  { label: 'High', 'value': '23' }, { label: 'Unknown', value: '11' }]; actPB: any[] = [{ label: 'Active', value: '67.43' },
  { label: 'InActive', value: '32.57' }]; lstAP = [{
    id: 'IN.AP.AN', value: '82', name: 'Ananthapor', displayValue: 'Ananthapor,82',
    showlabel: 1
  }, { id: 'IN.AP.CH', value: '89', name: 'Chittoor' }, {
    id: 'IN.AP.CU', name: 'YSR District, Kadapa (Cuddaph)', value: '12',
    showlabel: '1'
  }, { id: 'IN.AP.EG', value: '15', name: 'East Godavari' }, { id: 'IN.AP.VZ', value: '15', name: 'Vizianagaram' },
  { id: 'IN.AP.VS', value: '55', showlabel: '1', name: 'Visakhapatnam' }, { id: 'IN.AP.NE', value: '79', name: 'Nellore' }, {
    id: 'IN.AP.KU',
    value: '45', name: 'Kurnool', showlabel: '1'
  }, { id: 'IN.AP.GU', value: '42', showlabel: '1', name: 'Guntur' }, {
    id: 'IN.AP.KR',
    value: '25', name: 'Krishna'
  }, { id: 'IN.AP.SR', value: '18', name: 'Srikakulam' }, {
    id: 'IN.AP.PR', value: '54', name: 'Prakasam',
    displayValue: 'Prakasam,20', showlabel: '1'
  }, { id: 'IN.AP.WG', value: '2', name: 'West Godavari' }]; rngClrCode = this.clrCds.green;
  lstIndSM: any[] = []; lstIndACT: any[] = []; lstIndTMP: any[] = []; lstTxnTyps: any[] = []; lstMapSM: any[] = []; lstMapACT: any[] = [];
  lstMapTMP: any[] = []; lstIND: any[] = []; lstI1: any[] = []; lstI2: any[] = []; chsnInvFT = ''; chsnInvMTN = ''; chsnActFT = ''; chsnActMTN = '';
  chsnPrd = 'Now'; chsnTxnType = ''; slctdState = ''; rngIndctr = 's'; smIndType = ''; actIndType = 'a'; mapType = '1'; dfltStrTgsStk: any[] = [];
  usrLvl = ''; usrStateId = 0; usrDistId = 0; lstT1: any[] = []; lstT2: any[] = []; lstT3: any[] = []; dfltStrTgsTemp: any[] = []; tempIndType = 't';
 stateMtrls: any[] = []; dstrctMtrls: any[] = []; ccpMtrls: any[] = []; dstName = ''; chsnTempFT = '';
  astTypLst: any[] = []; astTypeMntrd: any[] = []; asetType: any[] = []; selectedTypes: any[] = []; chsnTempAst = ''; clrLabel = ''; stName = '';
  dropdownSettings = {
    singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
    unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
  }; tempCCP: boolean = false; ccpClr = ''; isSbmtd: boolean = false; nodata: boolean = false; stkIndcSpnr = false; actIndcSpnr = false; 
  tmpIndcSpnr = false; mapIndcSpnr = false;  dbStkPrmsn = true; dbActPrmsn = true; dbTmpPrmsn = true; usrPrmsn: any = null;

  constructor(public glblSvc: GlobalService,
    private cdRef: ChangeDetectorRef,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.lstTxnTyps = s.txnTypeLst; }), this.glblSvc.sbjctBdgsList.
      subscribe(s => {
        this.lstMatTags1 = s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.lstFacTags = s.filter(f =>
          f.badgeTypeId == environment.tagTypes.facility);
      }),
      this.glblSvc.sbjctMstrData.subscribe(s => {
        this.astTypLst = s.astTypLst, this.astTypLst = this.astTypLst.filter(f => f.deviceTypeId == 2)
      });
    this.overwFrm = this.fb.group({
      stckFacCatId: [''], isStkMatBdge: [''],
      stkMtrlBdge: [''], stkMtrlNme: [''], actvtyFacCatID: [''], isActvMatBdge: [''], actvtyMaterialBadge: [''], actvtyMaterialName: [''], isUnknown:[false],
      actvtyExpBfre: [''], actvtyRatePeriod: ['7'], actvtyTxnType: ['0'], activityType: ['1'], tempFacCatId: [''], tempStatus: ['', Validators.required]
    })
  }

  get fc() { return this.overwFrm.controls; }

  ngOnInit(): void {
    var fsnOptns: any = FusionCharts.options, slctd: any[] = [], matTag: any[] = [], data: any[] = [];
    this.usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
    let mtRIV = this.lstMatTags1.filter(f => f.name == 'RI Vaccines')[0], lstMTR: any[] = [];
    if (mtRIV)
      lstMTR.push(mtRIV), this.overwFrm.get('stkMtrlBdge')?.setValue(lstMTR), this.lstSlctdMatTags.push(mtRIV), this.slctdStkMat.push(mtRIV),
        this.overwFrm.get('actvtyMaterialBadge')?.setValue(lstMTR), this.slctdActvtyMat.push(mtRIV);
    this.usrLvl = this.usrLvlDtls?.userLevel ?? '';
    if (this.usrLvl == 'State')
      this.usrStateId = this.usrLvlDtls?.stateId ?? 0, this.usrDistId = 0;
    if (this.usrLvl == 'District')
      this.usrStateId = this.usrLvlDtls?.stateId ?? 0, this.usrDistId = this.usrLvlDtls?.districtId ?? 0;

    this.astTypLst.forEach((e, i) => { this.selectedTypes.push({ id: e.id, name: e.name }), this.chsnTempAst += `${i == 0 ? '' : ', '}${e.name}` });
    this.overwFrm.get('tempStatus')?.setValue(this.selectedTypes);
    // this.glblSvc.sbjctSpnr.next(true), Promise.all([fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.GetIndctrINV(true),
    //   this.GetIndctrACT(true)]).then(() => { this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId ==
    //   environment.tagTypes.product), this.lstUsrTags = s.filter(f => f.badgeTypeId == environment.tagTypes.user); }); });
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.LoadBothIndVals();
    if (data != undefined && data != null && data.length != 0) {
      data.forEach((s: any) => {
        if (this.lstFacTags.filter(f => f.id == s).length > 0)
          matTag = this.lstFacTags.filter(f => f.id == s)[0], slctd.push(matTag != undefined ? matTag : {});
      }), this.slctdDefauFacTag = data, this.dfltStrTgsStk = slctd;
    }
    this.lstFacTags.forEach((e, i) => { this.dfltStrTgsAct.push({ id: e.id, name: e.name }), this.chsnActFT += `${i == 0 ? '' : ', '}${e.name}` });
    this.lstFacTags.forEach((e, i) => { this.dfltStrTgsStk.push({ id: e.id, name: e.name }), this.chsnInvFT += `${i == 0 ? '' : ', '}${e.name}` });
    this.lstFacTags.forEach((e, i) => { this.dfltStrTgsTemp.push({ id: e.id, name: e.name }), this.chsnTempFT += `${i == 0 ? '' : ', '}${e.name}` });

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let dbLst: any[] = this.usrPrmsn?.Dashboard;
      dbLst.filter(e => {
        ((e.menuCode == 'mm_db_ov_tp') ? (this.dbTmpPrmsn = e.action) : ((e.menuCode == 'mm_db_ov_sm') ? (this.dbStkPrmsn = e.action) :
          ((e.menuCode == 'mm_db_ov_ac') ? (this.dbActPrmsn = e.action) : '')));
      });
    }
  }

  LoadBothIndVals() {
    let rawValues = this.overwFrm.getRawValue(), rawStkBdge: any[] = rawValues.stckFacCatId != '' ? rawValues.stckFacCatId : [], actMtrlBdgeID: any[] = [],
      rawMtrlBdge: any[] = rawValues.stkMtrlBdge != '' ? rawValues.stkMtrlBdge : [], rawMtrlStkNames: any[] = rawValues.stkMtrlNme != '' ?
        rawValues.stkMtrlNme : [], strBdges: any[] = [], tempfacltyTag: any[] = [], stkMtrlIDs: any[] = [], stkMtrlBdgeID: any[] = [], rawMtrlActvtyNames: any[] =
        rawValues.actvtyMaterialName != '' ? rawValues.actvtyMaterialName : [], rawStoreActvtyID: any[] = rawValues.actvtyFacCatID != '' ?
          rawValues.actvtyFacCatID : [], rawMtrlactvtyBdge: any[] = rawValues.actvtyMaterialBadge != '' ? rawValues.actvtyMaterialBadge : [],
      rawStatus: any[] = rawValues.tempStatus != '' ? rawValues.tempStatus : [],
      rawTempFacId: any[] = rawValues.tempFacCatId != '' ?
        rawValues.tempFacCatId : [], plSM: any = {}, plACT: any = {}, plTemp: any = {}; this.lstIndSM = [], this.lstIndACT = [], this.lstIndTMP = [],
          this.chsnInvFT = '', this.chsnInvMTN = '', this.chsnActFT = '', this.chsnTempFT = '', this.chsnActMTN = '';
    this.chsnTempAst = '';
    rawStkBdge.forEach((e, i) => { strBdges.push(e.id), this.chsnInvFT += `${i == 0 ? '' : ', '}${e.name}`; }),
      rawMtrlBdge.forEach((e, i) => {
        stkMtrlBdgeID.push(e.id), this.chsnInvMTN += `${i == 0 ? '' : ', '}${e.name}`;
      }), rawMtrlStkNames.forEach((e, i) => {
        stkMtrlIDs.push(e.id),
          this.chsnInvMTN += `${i == 0 ? '' : ', '}${e.name}`;
      }), plSM = { productBadges: stkMtrlBdgeID, productIds: stkMtrlIDs, storeBadges: strBdges },
      rawStoreActvtyID.forEach((e, i) => { strBdges.push(e.id), this.chsnActFT += `${i == 0 ? '' : ', '}${e.name}`; }), rawMtrlactvtyBdge.forEach(
        (e, i) => { actMtrlBdgeID.push(e.id), this.chsnActMTN += `${i == 0 ? '' : ', '}${e.name}`; }), rawMtrlActvtyNames.forEach((e, i) => {
          stkMtrlIDs.push(e.id), this.chsnActMTN += `${i == 0 ? '' : ', '}${e.name}`
        }), this.chsnTxnType = rawValues.actvtyTxnType == '0' ? 'ALL' :
          this.lstTxnTyps.filter(f => f.id == parseInt(rawValues.actvtyTxnType))[0].name;
    switch (rawValues.actvtyRatePeriod) {
      case '7':
        this.chsnPrd = '7 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '15':
        this.chsnPrd = '15 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '30':
        this.chsnPrd = '30 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (29 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '3m':
        this.chsnPrd = '3 Months', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (89 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      default:
        this.chsnPrd = 'Now', this.frmDate = this.dateConverterTo(new Date()), this.toDate = this.dateConverterTo(new Date());
    }
    plACT = {
      fromDate: this.frmDate, locationId: '', productIds: stkMtrlIDs, productTags: actMtrlBdgeID, storeTags: strBdges,
      toDate: this.toDate, txnTypeId: parseInt(rawValues.actvtyTxnType), stateId: this.usrStateId, districtId: this.usrDistId
    };
    rawTempFacId.forEach((e, i) => { tempfacltyTag.push(e.id), this.chsnTempFT += `${i == 0 ? '' : ', '}${e.name}`; });
    let tempcheck: any[] = [] , unknown =rawValues.isUnknown; rawStatus.forEach((e, i) => { tempcheck.push(e.id), this.chsnTempAst += `${i == 0 ? '' : ', '}${e.name}`; });

    plTemp = { facilityTag: tempfacltyTag, assetType: tempcheck }
    const invData = this.glblSvc.postAPI(environment.apiPaths.dashboard, environment.apiMthds.dbINVIndctr, plSM, 5),
      actData = this.glblSvc.postAPI(environment.apiPaths.dashboard, environment.apiMthds.dbACTIndctr + '1', plACT, 5),
      tempData = this.glblSvc.postAPI(environment.apiPaths.dashboard, environment.apiMthds.dbTempIndctr.replace('nnn', unknown), plTemp, 2, 3);
     
     //stk
    if (this.dbStkPrmsn == true) {
      this.stkIndcSpnr = true;
      forkJoin([invData]).subscribe({
        next: (res: any) => {
          res.forEach((list: any, indx: number) => {
            if (list.status === 'OK') {
              const indsLst: any = {
                '0': () => this.lstIndSM = list?.data?.indicatorValues ?? []
              }
              indsLst[indx]();
            }
          });
        },
        error: err => { console.error('something went wrong, kindly refresh', err); },
        complete: () => {
          setTimeout(() => {
            ['sm'].forEach(e => { this.onAnmtPB(e); }), setTimeout(() => {
              let smData =
                this.lstIndSM.filter(f => f.isSliced == true)[0]; this.smIndType = smData?.label, this.stkIndcSpnr = false, this.onClk(smData);
            }, 1000);
          }, 1000);
        },
      });
    }

    //act
    if (this.dbActPrmsn == true) {
      this.actIndcSpnr = true;
      forkJoin([actData]).subscribe({
        next: (res: any) => {
          res.forEach((list: any, indx: number) => {
            if (list.status === 'OK') {
              const indsLst: any = {
                '0': () => this.lstIndACT = list?.data?.indicatorValues ?? [],
              }
              indsLst[indx]();
            }
          });
        },
        error: err => { console.error('something went wrong, kindly refresh', err); },
        complete: () => {
          setTimeout(() => {
            ['act'].forEach(e => { this.onAnmtPB(e); }), setTimeout(() => {
              let smData =
                this.lstIndSM.filter(f => f.isSliced == true)[0]; this.smIndType = smData?.label, this.actIndcSpnr = false,
                  this.onClk(smData);
            }, 1000);
          }, 1000);
        },
      });
    }

    //temp
    if (this.dbTmpPrmsn == true) {
      this.tmpIndcSpnr = true;
      forkJoin([tempData]).subscribe({
        next: (res: any) => {
          res.forEach((list: any, indx: number) => {
            if (list.status === 'OK') {
              const indsLst: any = {
                '0': () => this.lstIndTMP = list?.data?.indicatorValues ?? [],
              }
              indsLst[indx]();
            }
          });
        },
        error: err => { console.error('something went wrong, kindly refresh', err); },
        complete: () => {
          setTimeout(() => {
            ['tmp'].forEach(e => { this.onAnmtPB(e); }), setTimeout(() => {
              let smData =
                this.lstIndSM.filter(f => f.isSliced == true)[0]; this.smIndType = smData?.label, this.tmpIndcSpnr = false,
                  this.onClk(smData);
            }, 1000);
          }, 1000);
        },
      });
    }
  }

  GetIndctrINV(isPL: boolean = false) {
    this.lstT1 = [], this.lstT2 = [], this.lstT3 = [], this.isCcpShow = false;
    let rawValues = this.overwFrm.getRawValue(), rawStkBdge: any[] = rawValues.stckFacCatId != '' ? rawValues.stckFacCatId : [],
      rawMtrlBdge: any[] = rawValues.stkMtrlBdge != '' ? rawValues.stkMtrlBdge : [], rawMtrlStkNames: any[] = rawValues.stkMtrlNme != '' ?
        rawValues.stkMtrlNme : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], stkMtrlBdgeID: any[] = [], payLoad: any = {};
    rawMtrlBdge = [...new Set(rawMtrlBdge)], this.chsnInvFT = '', this.chsnInvMTN = '', rawStkBdge.forEach((e, i) => { strBdges.push(e.id), this.chsnInvFT += `${i == 0 ? '' : ', '}${e.name}`; }),
      rawMtrlBdge.forEach((e, i) => { stkMtrlBdgeID.push(e.id), this.chsnInvMTN += `${i == 0 ? '' : ', '}${e.name}`; }), rawMtrlStkNames.forEach(
        (e, i) => { stkMtrlIDs.push(e.id), this.chsnInvMTN += `${i == 0 ? '' : ', '}${e.name}`; }), payLoad = {
          productBadges: stkMtrlBdgeID, productIds:
            stkMtrlIDs, storeBadges: strBdges
        }, this.lstIndSM = [], this.overwFrm.get('stkMtrlBdge')?.setValue(rawMtrlBdge);
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard,
      environment.apiMthds.dbINVIndctr, payLoad, 5).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (isPL)
                this.lstIndSM = this.result?.data?.indicatorValues ?? [];
              // this.lstI1 = this.result?.data?.indicatorValues ?? [], this.lstIndSM = this.lstI1, this.lstIndACT = this.lstI2, setTimeout(() => {
              //   ['sm', 'act', 'tmp'].forEach(e => { this.onAnmtPB(e); }), setTimeout(() => { let smData = this.lstIndSM.filter(f => f.isSliced ==
              //   true)[0]; this.glblSvc.sbjctSpnr.next(false), this.onClk(smData), this.smIndType = smData?.label; }, 1000); }, 500);
              else
                this.lstIndSM = this.result?.data?.indicatorValues ?? [], setTimeout(() => {
                  this.onAnmtPB('sm'), setTimeout(() => {
                    let sD = this.lstIndSM.filter(f => f.isSliced == true)[0];
                    this.glblSvc.sbjctSpnr.next(false), this.onClk(sD), this.smIndType = sD?.label;
                  }, 1000);
                }, 1000);
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  GetIndctrACT(isPL: boolean = false) {
    this.lstT1 = [], this.lstT2 = [], this.lstT3 = [], this.isCcpShow = false;
    let rawValues = this.overwFrm.getRawValue(), rawMtrlActvtyNames: any[] = rawValues.actvtyMaterialName != '' ? rawValues.actvtyMaterialName : [],
      rawStoreActvtyID: any[] = rawValues.actvtyFacCatID != '' ? rawValues.actvtyFacCatID : [], rawMtrlactvtyBdge: any[] = rawValues.actvtyMaterialBadge != '' ?
        rawValues.actvtyMaterialBadge : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], actMtrlBdgeID: any[] = [], payLoad: any = {};
    rawMtrlactvtyBdge = [...new Set(rawMtrlactvtyBdge)], this.chsnActFT = '', this.chsnActMTN = '', rawStoreActvtyID.forEach((e, i) => {
      strBdges.push(e.id), this.chsnActFT += `${i == 0 ? '' : ', '}${e.name}`;
    }), rawMtrlactvtyBdge.forEach((e, i) => {
      actMtrlBdgeID.push(e.id),
        this.chsnActMTN += `${i == 0 ? '' : ', '}${e.name}`;
    }), rawMtrlActvtyNames.forEach((e, i) => {
      stkMtrlIDs.push(e.id), this.chsnActMTN +=
        `${i == 0 ? '' : ', '}${e.name}`
    }), this.chsnTxnType = rawValues.actvtyTxnType == '0' ? 'ALL' : this.lstTxnTyps.filter(f => f.id ==
      parseInt(rawValues.actvtyTxnType))[0].name, this.overwFrm.get('actvtyMaterialBadge')?.setValue(rawMtrlactvtyBdge);
    switch (rawValues.actvtyRatePeriod) {
      case '7':
        this.chsnPrd = '7 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '15':
        this.chsnPrd = '15 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '30':
        this.chsnPrd = '30 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (29 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '3m':
        this.chsnPrd = '3 Months', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (89 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      default:
        this.chsnPrd = 'Now', this.frmDate = this.dateConverterTo(new Date()), this.toDate = this.dateConverterTo(new Date());
    }
    payLoad = {
      fromDate: this.frmDate, locationId: '', productIds: stkMtrlIDs, productTags: actMtrlBdgeID, storeTags: strBdges,
      toDate: this.toDate, txnTypeId: parseInt(rawValues.actvtyTxnType), stateId: this.usrStateId, districtId: this.usrDistId
    }, this.lstIndACT = [],
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbACTIndctr + '1', payLoad, 5).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (isPL)
                this.lstI2 = this.result?.data?.indicatorValues ?? [];
              else
                this.lstIndACT = this.result?.data?.indicatorValues ?? [], setTimeout(() => {
                  this.onAnmtPB('act'), setTimeout(() => {
                    let aD = this.lstIndACT.filter(f => f.isSliced == true)[0]; this.onClk(aD, 'act'), this.actIndType = '1';
                  }, 1000);
                }, 1000);
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  GetIndctrTemp(isPL: boolean = false) {
    if (this.fc.tempStatus.value != '') {
      document.getElementById('hideFilter')?.click(); 
      this.lstT1 = [], this.lstT2 = [], this.lstT3 = [], this.chsnTempFT = ''; this.chsnTempAst = ''; let tempfacltyTag: any[] = [], slctdstatus: any[] = [],
        rawValues = this.overwFrm.getRawValue(), rawTempFacId: any[] = rawValues.tempFacCatId != '' ? rawValues.tempFacCatId : [],
        rawStatus: any[] = rawValues.tempStatus != '' ? rawValues.tempStatus : []; let unknown = rawValues.isUnknown;  rawTempFacId.forEach((e, i) => {
          tempfacltyTag.push(e.id),
          this.chsnTempFT += `${i == 0 ? '' : ', '}${e.name}`;
        }); rawStatus.forEach((e, i) => {
          slctdstatus.push(e.id), this.chsnTempAst += `${i == 0 ?
            '' : ', '}${e.name}`;
        }); let payLoad = { facilityTag: tempfacltyTag, assetType: slctdstatus }; this.lstIndTMP = [];
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbTempIndctr.replace('nnn', unknown), payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              this.isCcpShow = false
              if (isPL)
                this.lstIndTMP = this.result?.data?.indicatorValues ?? [];
              else
                this.lstIndTMP = this.result?.data?.indicatorValues ?? [], setTimeout(() => {
                  this.onAnmtPB('tmp'), setTimeout(() => {
                    let tD = this.lstIndTMP.filter(f => f.isSliced == true)[0]; this.onClk(tD, 'tmp'), this.tempIndType = tD?.label;
                  }, 1000);
                }, 1000);
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
    }
    else
      this.chsnTempAst = '', this.glblSvc.onTstr('i', "please select CCE Type");
  }

  GetInvMap() {
    this.isCcpShow = false; this.tempCCP = false, this.mapIndcSpnr = true;
    let rawValues = this.overwFrm.getRawValue(), rawStkBdge: any[] = rawValues.stckFacCatId != '' ? rawValues.stckFacCatId : [],
      rawMtrlBdge: any[] = rawValues.stkMtrlBdge != '' ? rawValues.stkMtrlBdge : [], rawMtrlStkNames: any[] = rawValues.stkMtrlNme != '' ?
        rawValues.stkMtrlNme : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], stkMtrlBdgeID: any[] = [], payLoad: any = {};
    rawStkBdge.forEach(e => { strBdges.push(e.id) }), rawMtrlBdge.forEach(e => { stkMtrlBdgeID.push(e.id) }), rawMtrlStkNames.forEach(e => { stkMtrlIDs.push(e.id); }), payLoad = {
      locationId: '', mapType: this.mapType, productBadges: stkMtrlBdgeID, productIds: stkMtrlIDs,
      storeBadges: strBdges, type: this.smIndType, stateId: this.usrStateId, districtId: this.usrDistId
    };

    if (this.mapType == '2' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('state'))?.originalId;

    if (this.mapType == '3' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('district'))?.originalId;

    if (this.usrLvl == "State" && this.frstVst) {
      let eMap = ((this.usrLvlDtls?.name == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (this.usrLvlDtls?.name == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (this.usrLvlDtls?.name == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (this.usrLvlDtls?.name == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      if (eMap == ' ')
        this.slctdState = `maps/${this.usrLvlDtls?.name}`.replace(' ', '').toLowerCase(); 
      else
        this.slctdState = `maps/${eMap}`;
        this.mapType == '2', payLoad.mapType = '2', payLoad.locationId = this.usrLvlDtls?.id, this.stName = this.usrLvlDtls?.name, $('#lblState').html(this.usrLvlDtls?.name);
    }    
    if (this.usrLvl == "District" && this.frstVst)
      this.mapType == '3', payLoad.mapType = '3', payLoad.locationId = this.usrLvlDtls?.id, this.dstName = this.usrLvlDtls?.name;

    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbINVMap, payLoad, 5, false).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data) {
            if (this.result?.data?.map.length > 0) {
              // let mtrls: any[] = this.result?.data?.materials ?? [];
              if (this.mapType == '1')
                this.lstIND = this.result?.data?.map, this.lstT1 = this.lstIND.length > 0 ? this.lstIND.filter(f => f.value != null) : [],
                  this.stateMtrls = this.result?.data?.materials ?? [],
                  this.LoadINDIA(this.lstIND), this.LoadBarS(this.stateMtrls, `${this.stateMtrls.length * 40}`), this.mapIndcSpnr = false;
              else if (this.mapType == '2')
                this.isHideIND = true, this.lstSteData = this.result?.data?.map, this.lstT2 = this.lstSteData.length > 0 ? this.result?.data?.map : [],
                  this.dstrctMtrls = this.result?.data?.materials ?? [],
                  this.LoadStateMap(this.slctdState, this.lstSteData), this.LoadBarS(this.dstrctMtrls, `${this.dstrctMtrls.length * 40}`), this.mapIndcSpnr = false;
              else
                this.isCcpShow = true, document.getElementById('Map-1-tab')?.click(),
                  this.lstDstrctData = this.result?.data?.map, this.lstT3 = this.lstDstrctData.length > 0 ? this.result?.data?.map : [],
                  this.ccpMtrls = this.result?.data?.materials ?? [],
                  this.LoadBarS(this.ccpMtrls, `${this.ccpMtrls.length * 40}`), this.mapIndcSpnr = false;
            }
          }
          else
            this.glblSvc.onTstr('w', this.result.message), this.mapIndcSpnr = false;
        }
        else
          this.glblSvc.onTstr('i', this.result.message), this.mapIndcSpnr = false;
      }
    });
  }

  GetActMap() {
    this.isCcpShow = false; this.tempCCP = false, this.mapIndcSpnr = true;
    let rawValues = this.overwFrm.getRawValue(), rawMtrlActvtyNames: any[] = rawValues.actvtyMaterialName != '' ? rawValues.actvtyMaterialName : [],
      rawStoreActvtyID: any[] = rawValues.actvtyFacCatID != '' ? rawValues.actvtyFacCatID : [], rawMtrlactvtyBdge: any[] = rawValues.actvtyMaterialBadge != '' ?
        rawValues.actvtyMaterialBadge : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], actMtrlBdgeID: any[] = [], payLoad: any = {};
    this.chsnActFT = '', this.chsnActMTN = '', rawStoreActvtyID.forEach((e, i) => {
      strBdges.push(e.id), this.chsnActFT +=
        `${i == 0 ? '' : ', '}${e.name}`;
    }), rawMtrlactvtyBdge.forEach((e, i) => {
      actMtrlBdgeID.push(e.id), this.chsnActMTN +=
        `${i == 0 ? '' : ', '}${e.name}`;
    }), rawMtrlActvtyNames.forEach((e, i) => {
      stkMtrlIDs.push(e.id), this.chsnActMTN +=
        `${i == 0 ? '' : ', '}${e.name}`
    }), this.chsnTxnType = rawValues.actvtyTxnType == '0' ? 'ALL' : this.lstTxnTyps.filter(f =>
      f.id == parseInt(rawValues.actvtyTxnType))[0]?.name;
    switch (rawValues.actvtyRatePeriod) {
      case '7':
        this.chsnPrd = '7 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '15':
        this.chsnPrd = '15 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '30':
        this.chsnPrd = '30 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (29 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      case '3m':
        this.chsnPrd = '3 Months', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (89 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
        break;
      default:
        this.chsnPrd = 'Now', this.frmDate = this.dateConverterTo(new Date()), this.toDate = this.dateConverterTo(new Date());
    }
    payLoad = {
      fromDate: this.frmDate, locationId: '', productIds: stkMtrlIDs, productTags: actMtrlBdgeID, storeTags: strBdges,
      toDate: this.toDate, txnTypeId: parseInt(rawValues.actvtyTxnType), stateId: this.usrStateId, districtId: this.usrDistId
    };
    // if (this.mapType == '2')
    //   payLoad.locationId = JSON.parse(<any>localStorage.getItem('state')).originalId;
    // if (this.mapType == '3')
    //   this.isCcpShow = true,
    //     payLoad.districtId = JSON.parse(<any>localStorage.getItem('district')).districtId,
    //     payLoad.stateId = JSON.parse(<any>localStorage.getItem('district')).sId,
    //     payLoad.locationId = JSON.parse(<any>localStorage.getItem('district')).originalId;

    if (this.mapType == '2' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('state'))?.originalId;
  
    if (this.mapType == '3' && !this.frstVst)      
      payLoad.districtId = JSON.parse(<any>localStorage.getItem('district')).districtId,
      payLoad.stateId = JSON.parse(<any>localStorage.getItem('district')).sId,
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('district'))?.originalId;
  
    if (this.usrLvl == "State" && this.frstVst) {
      let eMap = ((this.usrLvlDtls?.name == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (this.usrLvlDtls?.name == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (this.usrLvlDtls?.name == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (this.usrLvlDtls?.name == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      if (eMap == ' ')
        this.slctdState = `maps/${this.usrLvlDtls?.name}`.replace(' ', '').toLowerCase(); 
      else
        this.slctdState = `maps/${eMap}`;
      this.mapType == '2', payLoad.mapType = '2', payLoad.locationId = this.usrLvlDtls?.id, this.stName = this.usrLvlDtls?.name, $('#lblState').html(this.usrLvlDtls?.name);
    }    
    if (this.usrLvl == "District" && this.frstVst)
      this.mapType == '3', payLoad.mapType = '3', payLoad.locationId = this.usrLvlDtls?.id, this.dstName = this.usrLvlDtls?.name;
    
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbACTMap.replace('mmm', this.mapType) +
      this.actIndType, payLoad, 5, false).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (this.result?.data?.mapDetails.length > 0) {
                if (this.mapType == '1')
                  this.lstIND = this.result?.data?.mapDetails, this.lstT1 = this.lstIND.length > 0 ? this.lstIND.filter(f => f.value != null) : [],
                    this.LoadINDIA(this.lstIND), this.mapIndcSpnr = false;
                else if (this.mapType == '2')
                  this.isHideIND = true, this.lstSteData = this.result?.data?.mapDetails, this.lstT2 = this.lstSteData.length > 0 ? this.result?.data?.mapDetails : [],
                    this.LoadStateMap(this.slctdState, this.lstSteData), this.mapIndcSpnr = false;
                else
                  this.isCcpShow = true, this.isHideIND = true, document.getElementById('Map-1-tab')?.click(),
                    this.lstDstrctData = this.result?.data?.mapDetails, this.lstT3 = this.lstDstrctData.length > 0 ? this.result?.data?.mapDetails : [], this.mapIndcSpnr = false;
                // this.LoadStateMap(this.slctdState, this.lstSteData);
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message), this.mapIndcSpnr = false;
          }
          else
            this.glblSvc.onTstr('i', this.result.message), this.mapIndcSpnr = false;
        }
      });
  }

  GetTempMap() {
    this.isCcpShow = false; this.tempCCP = false, this.mapIndcSpnr = true;
    let rawValues = this.overwFrm.getRawValue(), unkonwn = rawValues.isUnknown,  rawTempFacId: any[] = rawValues.tempFacCatId != '' ? rawValues.tempFacCatId : [],
      tempFclty: any[] = [], payLoad: any = {}, rawStatus: any[] = rawValues.tempStatus != '' ? rawValues.tempStatus : [], slctdstatus: any[] = [];
    rawTempFacId.forEach(e => { tempFclty.push(e.id) }), rawStatus.forEach(e => { slctdstatus.push(e.id) }); payLoad = {
      locationId: '', mapType: this.mapType, facilityTag: tempFclty, type: this.tempIndType, stateId: this.usrStateId, assetType: slctdstatus, districtId: this.usrDistId
    };

    if (this.mapType == '2' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('state'))?.originalId;

    if (this.mapType == '3' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('district'))?.originalId;

    if (this.usrLvl == "State" && this.frstVst) {
      let eMap = ((this.usrLvlDtls?.name == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (this.usrLvlDtls?.name == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (this.usrLvlDtls?.name == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (this.usrLvlDtls?.name == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      if (eMap == ' ')
        this.slctdState = `maps/${this.usrLvlDtls?.name}`.replace(' ', '').toLowerCase(); 
      else
        this.slctdState = `maps/${eMap}`;
      this.mapType == '2', payLoad.mapType = '2', payLoad.locationId = this.usrLvlDtls?.id, this.stName = this.usrLvlDtls?.name, $('#lblState').html(this.usrLvlDtls?.name);
    }
    if (this.usrLvl == "District" && this.frstVst)
      this.mapType == '3', payLoad.mapType = '3', payLoad.locationId = this.usrLvlDtls?.id, this.dstName = this.usrLvlDtls?.name;

    
    this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbTempMap.replace('nnn', unkonwn), payLoad, 2, false, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data) {
            if (this.result?.data.length > 0) {
              if (this.mapType == '1') {
                let tempStateLst = this.result?.data; this.lstIND = tempStateLst.length > 0 ? tempStateLst.filter((s: any) => s.id != null) : []; this.lstT1 = this.lstIND.length > 0 ? this.lstIND.filter(f => f.value != null) : [];
                this.LoadINDIA(this.lstIND), this.mapIndcSpnr = false;
              }
              else if (this.mapType == '2') {
                this.isHideIND = true; let tempDstrct = this.result?.data; this.lstSteData = tempDstrct.length > 0 ? tempDstrct.filter((s: any) => s.id != null) : []; this.lstT2 = this.lstSteData.length > 0 ? this.lstSteData.filter(f => f.id != null) : [];
                this.LoadStateMap(this.slctdState, this.lstSteData), this.mapIndcSpnr = false;
              }
              else {
                this.isCcpShow = true, this.tempCCP = true, document.getElementById('Map-1-tab')?.click();
                let ccpLst = this.result?.data; this.lstDstrctData = ccpLst.length > 0 ? ccpLst.filter((s: any) => s.id != null) : []; this.lstT3 = this.lstDstrctData.length > 0 ? this.lstDstrctData.filter(f => f.id != null) : [], this.mapIndcSpnr = false;
              }
            }
          }
          else
            this.glblSvc.onTstr('w', this.result.message), this.mapIndcSpnr = false;
        }
        else
          this.glblSvc.onTstr('i', this.result.message), this.mapIndcSpnr = false;
      }
    });
  }

  loadMtrls(loadMtrls: string) {
    document.getElementById('Map-tab')?.click();
    if (loadMtrls == 'lsm')
      this.mapType = '1', this.LoadBarS(this.stateMtrls, `${this.stateMtrls.length * 40}`);
    // this.mtrlDivHt = this.stateMtrls.length > 16 ? '100%' : `${this.stateMtrls.length * 6}%`;
    // this.LoadBarS(this.stateMtrls, this.stateMtrls.length > 16 && this.usrLvl == 'State' ? '100%' : (this.stateMtrls.length > 16) && (this.usrLvl == 'District') ? '100%' : (this.stateMtrls.length > 16) ? '100%': `${this.stateMtrls.length * 6}%`);
    else if (loadMtrls == 'ldm')
      this.mapType = '2', this.LoadBarS(this.dstrctMtrls, `${this.dstrctMtrls.length * 40}`);
  }

  onAnmtPB(sfx: string = 'pb') {
    let els: any[] = [], els1: any[] = [], els2: any[] = [];
    this.lstIndSM.forEach(e => { els.push(document.getElementById(`sm${e.label}`)); }),
      this.lstIndACT.forEach(e => { els1.push(document.getElementById(`act${e.label}`)); }), els2 = [document.getElementById(`${sfx}Normal`),
      document.getElementById(`${sfx}Low`), document.getElementById(`${sfx}High`), document.getElementById(`${sfx}Unknown`)];
    if (sfx === 'sm')
      this.lstIndSM.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (els[indx] != null) {
              if (iW > fV)
                (<any>els[indx]).style.width = `${mV}%`, clearInterval(elI);
              else
                iW += 20, (<any>els[indx]).style.width = iW + "%";
            }
          }
        }
      });
    else if (sfx === 'act')
      this.lstIndACT.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (els1[indx] != null) {
              if (iW > fV)
                (<any>els1[indx]).style.width = `${mV}%`, clearInterval(elI);
              else
                iW += 20, (<any>els1[indx]).style.width = iW + "%";
            }
          }
        }
      });
    else
      this.lstIndTMP.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (els2[indx] != null) {
              if (iW > fV)
                (<any>els2[indx]).style.width = `${mV}%`, clearInterval(elI);
              else
                iW += 20, (<any>els2[indx]).style.width = iW + "%";
            }
          }
        }
      });
  }

  onAnmtPBOld(sfx: string = 'pb') {
    let els = [document.getElementById(`${sfx}Normal`), document.getElementById(`${sfx}Max`), document.getElementById(`${sfx}Min`),
    document.getElementById(`${sfx}Zero Stock`)], els1 = [document.getElementById(`${sfx}Active`), document.getElementById(`${sfx}InActive`)],
      els2 = [document.getElementById(`${sfx}Normal`), document.getElementById(`${sfx}Low`), document.getElementById(`${sfx}High`),
      document.getElementById(`${sfx}Unknown`)];
    /* let iV = 0, iW = 0, mV = this.fcDn[0].value, fV = +`${mV}`.split('.')[0];
      if (iV == 0) {
        var elI = setInterval(f1);
        function f1() {
          if (iW == fV)
            (<any>els[0]).style.width = `${mV}%`, clearInterval(elI), iV = 0;
          else
            iW += 1, (<any>els[0]).style.width = iW + "%";
        }
      } */ // [el1, el2, el3, el4].forEach((e: any) => { if (e) e.classList.remove('active'); });
    if (sfx === 'sm')
      this.smPB.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (iW > fV)
              (<any>els[indx]).style.width = `${mV}%`, clearInterval(elI);
            else
              iW += 20, (<any>els[indx]).style.width = iW + "%";
          }
        }
      });
    else if (sfx === 'act')
      this.actPB.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (iW > fV)
              (<any>els1[indx]).style.width = `${mV}%`, clearInterval(elI);
            else
              iW += 20, (<any>els1[indx]).style.width = iW + "%";
          }
        }
      });
    else
      this.tmpPB.forEach((e: any, indx: number) => {
        let mV = e.value;
        for (let i = 0; i < mV; i++) {
          let iW = 0, fV = +`${mV}`.split('.')[0];
          var elI = setInterval(f1);
          function f1() {
            if (iW > fV)
              (<any>els2[indx]).style.width = `${mV}%`, clearInterval(elI);
            else
              iW += 20, (<any>els2[indx]).style.width = iW + "%";
          }
        }
      });
  }

  onClk(el: any, sfx: string = 'sm') {
    this.nodata = false; this.frstVst = true;    
      if (+el?.value == 0 || el?.value == undefined) {
        this.nodata = true;
      } else {
        let type = el?.label, els: any[] = [], els1: any[] = [], els2: any[] = [];
        if (sfx == 'sm')
          this.smIndType = el?.label, this.rngClrCode = (type == 'Normal' ? this.clrCds.green : (type == '> Max' ? this.clrCds.skyBlue :
            (type == '< Min' ? this.clrCds.yellow : this.clrCds.red)));
        else if (sfx == 'tmp')
          this.tempIndType = el?.label, this.rngClrCode = (type == 'Normal' ? this.clrCds.green : (type == 'Low' ? this.clrCds.skyBlue : (type == 'High' ? this.clrCds.red : this.clrCds.grey)));
        else
          this.rngClrCode = (type == 'Active' ? this.clrCds.green : this.clrCds.red), this.actIndType = type == 'Active' ? '1' : '2';
        this.lstIndSM.forEach(e => { els.push(document.getElementById(`sm${e.label}`)); }), this.lstIndACT.forEach(e => {
          els1.push(document.
            getElementById(`act${e.label}`));
        }), this.lstIndTMP.forEach(e => { els2.push(document.getElementById(`tmp${e.label}`)); }),

          [els, els1, els2].forEach(e => { if (e) e.forEach(f => { if (f) (<any>f).classList.remove('active'); }); }),
          (<any>document.getElementById(sfx + el.label)).classList.add('active'), document.getElementById('Map-tab')?.click();
        if (sfx == 'sm')
          this.rngIndctr = 's', this.mapType = (this.usrLvl == "National" ? '1' : (this.usrLvl == "State" ? '2' : '3')), this.isHideIND = false, this.GetInvMap(); // this.LoadINDIA(this.lstDmyIND), this.LoadBarS();
        if (sfx == 'act')
          this.rngIndctr = 'a', this.mapType = (this.usrLvl == "National" ? '1' : (this.usrLvl == "State" ? '2' : '3')), this.isHideIND = false, this.GetActMap();
        if (sfx == 'tmp')
          this.rngIndctr = 't', this.mapType = (this.usrLvl == "National" ? '1' : (this.usrLvl == "State" ? '2' : '3')), this.isHideIND = false, this.GetTempMap();
        this.clrLabel = el.label; this.ccpClr = el.color;
      }
  }

  onClkOld(type: string, sfx: string) {
    let el11 = document.getElementById(`smNormal`), el12 = document.getElementById(`smMax`), el13 = document.getElementById(`smMin`),
      el14 = document.getElementById(`smZero Stock`), el1 = (type == 'Normal' ? el11 : (type == 'Max' ? el12 : (type == 'Min' ? el13 : el14))),
      el21 = document.getElementById(`actActive`), el22 = document.getElementById(`actInActive`), el2 = type == 'Active' ? el21 : el22,
      el31 = document.getElementById(`tmpNormal`), el32 = document.getElementById(`tmpLow`), el33 = document.getElementById(`tmpHigh`),
      el34 = document.getElementById(`tmpUnknown`), el3 = (type == 'Normal' ? el31 : (type == 'Low' ? el32 : (type == 'High' ? el33 : el34))),
      el1C = (type == 'Normal' ? this.clrCds.green : (type == 'Max' ? this.clrCds.skyBlue : (type == 'Min' ? this.clrCds.yellow : this.clrCds.red))),
      el3C = (type == 'Normal' ? this.clrCds.green : (type == 'Low' ? this.clrCds.navyBlue : (type == 'High' ? this.clrCds.yellow : this.clrCds.grey))),
      el2C = (type == 'Active' ? this.clrCds.green : this.clrCds.red);
    [el11, el12, el13, el14, el21, el22, el31, el32, el33, el34].forEach((e: any) => { if (e) e.classList.remove('active'); }),
      this.rngClrCode = sfx == 'sm' ? el1C : (sfx == 'act' ? el2C : el3C), this.isHideIND = false, document.getElementById('Map-tab')?.click(),
      (<any>(sfx == 'sm' ? el1 : (sfx == 'act' ? el2 : el3))).classList.add('active'), this.LoadINDIA(), this.LoadBarS(this.fcBarData, '100%');
  }

  onClkIndState(item: any) {
    localStorage.removeItem('state'), this.mapType = '2', this.frstVst = false;
    if (item.isEnabled) {
      if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && item.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && item.value > 0) {
        this.stName = item.stateName;
        let shrtLbl: string = this.lstFsnIndSNs.filter(f => f.id === item.id)[0]?.shortLabel;
        localStorage.setItem('state', JSON.stringify({
          id: item.locationId, label: item.name, originalId: item.id, shortLabel: shrtLbl,
          value: item.value
        })), document.getElementById('Map-tab')?.click();
      }
    }
  }
  onClkIndDstrct(item: any) {
    localStorage.removeItem('district'), this.mapType = '3', this.frstVst = false;
    if (item.isEnabled) {
      if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && item.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && item.value > 0) {
        this.dstName = item.name,
          localStorage.setItem('district', JSON.stringify({
            id: item.locationId, label: item.name, originalId: item.id,
            value: item.value, districtId: item.districtId, sId: item.stateId
          })), document.getElementById('Map-tab')?.hidden, this.isCcpShow = true;
      }
    }
  }

  onClkState() {
    let stData = JSON.parse(<string>localStorage.getItem('state'));
    if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && stData.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && stData.value > 0) {
      this.isCcpShow = false; this.stName = stData?.label;
      let eMap = ((stData.label == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (stData.label == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (stData.label == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (stData.label == 'Daman and Diu' ? 'damananddiu' : ' ')))));
      this.isAP = stData.label == 'Andhra Pradesh', this.lstSteData = this.isAP ? this.lstAP : [], this.mapType = '2', this.frstVst = false;
      if (eMap == ' ')
        this.slctdState = `maps/${stData.label}`.replace(' ', '').toLowerCase(); 
      else
        this.slctdState = `maps/${eMap}`; // this.LoadStateMap(`maps/${eMap}`, this.lstSteData);
      if (this.lstIND.filter(f => f.id === stData.originalId)[0]?.isEnabled) {
        $('#lblState').html(stData.label);
        if (this.rngIndctr == 's')
          this.GetInvMap();
        if (this.rngIndctr == 'a')
          this.GetActMap();
        if (this.rngIndctr == 't')
          this.GetTempMap();
      }
      else
        this.isHideIND = false;
    }
  }
  onClkDstrct() {
    this.frstVst = false;
    let stData = JSON.parse(<string>localStorage.getItem('district')); this.dstName = stData.label;
    if (((+this.chsnDmnId == this.usrLgnDtls.pranthId) && stData.value >= 0) || (+this.chsnDmnId != this.usrLgnDtls.pranthId) && stData.value > 0) {
      this.mapType = '3';
      if (this.rngIndctr == 's')
        this.GetInvMap();
      if (this.rngIndctr == 'a')
        this.GetActMap();
      if (this.rngIndctr == 't')
        this.GetTempMap();
    }
  }

  LoadBar(chrtData: any[] = this.fcBarData, clrCode: string[] = this.rngClrCode, divId: string = 'fc-bar-horz') {
    let chartConfig: any = {}, pltClrs: string[] = [''];
    pltClrs = [], chrtData.forEach(e => {
      let clrCde = (+e.value < 20 ? clrCode[0] : ((+e.value > 20 && +e.value < 40) ? clrCode[1] :
        ((+e.value > 40 && +e.value < 60) ? clrCode[2] : ((+e.value > 60 && +e.value < 80) ? clrCode[3] : clrCode[4])))); pltClrs.push(clrCde);
    }),
      chartConfig = {
        type: 'bar2d', renderAt: divId, width: '100%', height: '1500', dataFormat: 'json', dataSource: {
          chart: {
            caption: '', // height: '100%',
            subCaption: '', xAxisName: '', yAxisName: '', showValues: '1', paletteColors: pltClrs, numbersuffix: '%', showYAxisValues: '0',
            theme: 'fusion', enableMultiSlicing: '0', interactiveLegend: '0', exportEnabled: '1', exportTargetWindow: '_self', maxBarHeight: '100', // plotBorderDashGap: '5', // plotSpacePercent: '0',
            exportFormats: environment.fcExprtOptns
          }, data: chrtData
        }
      };
    FusionCharts.ready(function () { var fusioncharts = new FusionCharts(chartConfig); fusioncharts.render(); });
  }

  LoadBarS(chrtData: any[] = this.fcBarData, hght: string, clrCode: string[] = this.rngClrCode, divId: string = 'fc-bar-horz') {
    let chartConfig: any = {}, pltClrs: string[] = [''];
    pltClrs = [], chrtData.forEach(e => {
      let clrCde = (+e.value < 20 ? clrCode[0] : ((+e.value > 20 && +e.value < 40) ? clrCode[1] :
        ((+e.value > 40 && +e.value < 60) ? clrCode[2] : ((+e.value > 60 && +e.value < 80) ? clrCode[3] : clrCode[4]))));
      Object.assign(e, { color: clrCde }), pltClrs.push(clrCde);
    }),
      chartConfig = {
        type: 'scrollbar2d', renderAt: divId, width: '100%', height: hght, dataFormat: 'json', dataSource: {
          chart: {
            caption: '',
            subCaption: '', xAxisName: '', yAxisName: '', showValues: '1', paletteColors: pltClrs, numbersuffix: '%', showYAxisValues: '0',
            theme: 'fusion', enableMultiSlicing: '0', interactiveLegend: '0', exportEnabled: '1', exportTargetWindow: '_self', maxBarHeight: '20', // plotBorderDashGap: '5', // plotSpacePercent: '0',
            exportFormats: environment.fcExprtOptns, yAxisMinValue: '0', yAxisMaxValue: '100'
          }, categories: [{ category: chrtData }],
          dataset: [{ data: chrtData }]
        }
      };
    FusionCharts.ready(function () { var fusioncharts = new FusionCharts(chartConfig); fusioncharts.render(); });
  }

  LoadINDIA(indLst: any[] = this.lstIND, clrCods: string[] = this.rngClrCode, divId: string = 'fc-map-ind') {
    this.indTxt = 'INDIA';
    FusionCharts.ready(() => {
      var salesMap = new FusionCharts({
        type: 'maps/india', renderAt: divId, width: '600', height: '700', dataFormat: 'json',
        dataSource: {
          chart: {
            caption: "",
            // subcaption: " 2021", plotToolText: "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue", labelsepchar: " ",
            // showBorder: "0", plotToolText: "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue", tooltext: "{value} {br}",
            numbersuffix: '%', showCanvasBorder: '0', includevalueinlabels: '1', includeNameInLabels: '1', useSNameInLabels: '1', showToolTip: '1',
            canvasBorderThickness: '1', enableSmartLabels: '1', showMarkerToolTip: '0', entityFillHoverColor: '#ffffff', nullEntityColor: clrCods[4],     //color
            nullEntityAlpha: '100', hoverOnNull: '1', theme: 'fusioncharts/latest/maps/fusioncharts', legendPosition: 'bottom', showLegend: '1',
            showLabels: '1', showValues: '1', exportEnabled: '1', exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            exportTargetWindow: '_self', exportFileName: 'Inventry'
          },
          colorrange: {
            color: [{ minvalue: '0', maxvalue: '20', code: clrCods[0], displayValue: '< 20' }, {
              minvalue: '20', maxvalue: '40',
              code: clrCods[1], displayValue: '20-40'
            }, { minvalue: '40', maxvalue: '60', code: clrCods[2], displayValue: '40-60' }, {
              minvalue: '60', maxvalue: '80', code: clrCods[3], displayValue: '60-80'
            }, {
              minvalue: '80', maxvalue: '100', code: clrCods[4],
              displayValue: '80 - 100'
            }]
          }, data: indLst,
        },
        events: {
          entityRollover: function (evt: any, data: any) { }, entityRollout: function (evt: any, data: any) { },
          entityClick: (evt: any, data: any) => { localStorage.setItem('state', JSON.stringify(data)), document.getElementById('btnState')?.click(); },
        }
      }).render();
    });
  }

  LoadStateMap(state: string, steData: any[] = [], clrCods: string[] = this.rngClrCode, divId: string = 'fc-map-state-ind') {
    this.isHideIND = true, FusionCharts.ready(function () {
      var chart = new FusionCharts({
        type: state, renderAt: divId, width: '500', height: '500', dataFormat: 'json', dataSource: {
          chart: { // "subcaption": " 2021", "showBorder": "0", "tooltext": "{value} {br}{label}", "labelsepchar": " ", "plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            caption: '', numbersuffix: '%', showCanvasBorder: '0', includevalueinlabels: '1', includeNameInLabels: '1', useSNameInLabels: '1',
            canvasBorderThickness: '1', enableSmartLabels: '1', showMarkerToolTip: '0', showToolTip: '1', entityFillHoverColor: '#ffffff',
            nullEntityColor: clrCods[2], nullEntityAlpha: '100', hoverOnNull: '1', theme: 'fusioncharts/latest/maps/fusioncharts', // nullClr
            exportEnabled: '1', exportTargetWindow: '_self', exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV'
            legendPosition: 'bottom', showLegend: '1', showLabels: '1', showValues: '1'
          },
          colorrange: {
            color: [{ minvalue: '0', maxvalue: '20', code: clrCods[0], displayValue: '< 20' }, {
              minvalue: '20', maxvalue: '40',
              code: clrCods[1], displayValue: '20-40'
            }, { minvalue: '40', maxvalue: '60', code: clrCods[2], displayValue: '40-60' }, {
              minvalue: '60', maxvalue: '80', code: clrCods[3], displayValue: '60-80'
            }, {
              minvalue: '80', maxvalue: '100', code: clrCods[4],
              displayValue: '80 - 100'
            }]
          }, data: steData
        }, events: {
          entityRollover: function (evt: any, data: any) { },
          entityRollout: function (evt: any, data: any) { }, entityClick: (evt: any, data: any) => { localStorage.setItem('district', JSON.stringify(data)), document.getElementById('btnDistrict')?.click(); }
        }
      }).render();
    });
  }

  // LoadDistrictMap(distrcit: string, dsctrcData: any[] = [], clrCods: string[] = this.rngClrCode, divId: string = 'fc-map-state-ind') {
  //   this.isHideIND = true, FusionCharts.ready(function () {
  //     var chart = new FusionCharts({ type: distrcit, renderAt: divId, width: '500', height: '500', dataFormat: 'json', dataSource: {
  //     chart: { // "subcaption": " 2021", "showBorder": "0", "tooltext": "{value} {br}{label}", "labelsepchar": " ", "plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
  //       caption: '', numbersuffix: '%', showCanvasBorder: '0', includevalueinlabels: '1', includeNameInLabels: '1', useSNameInLabels: '1',
  //       canvasBorderThickness: '1', enableSmartLabels: '1', showMarkerToolTip: '0', showToolTip: '1', entityFillHoverColor: '#ffffff',
  //       nullEntityColor: clrCods[2], nullEntityAlpha: '100', hoverOnNull: '1', theme: 'fusioncharts/latest/maps/fusioncharts', // nullClr
  //       exportEnabled: '1', exportTargetWindow: '_self', exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV'
  //       legendPosition: 'bottom', showLegend: '1', showLabels: '1', showValues: '1' },
  //     colorrange: { color: [{ minvalue: '0', maxvalue: '20', code: clrCods[0], displayValue: '< 20' }, { minvalue: '20', maxvalue: '40',
  //       code: clrCods[1], displayValue: '20-40' }, { minvalue: '40', maxvalue: '60', code: clrCods[2], displayValue: '40-60' }, {
  //       minvalue: '60', maxvalue: '80', code: clrCods[3], displayValue: '60-80' }, { minvalue: '80', maxvalue: '100', code: clrCods[4],
  //       displayValue: '80 - 100' }] }, data: dsctrcData }, events: { entityRollover: function (evt: any, data: any) { },
  //       entityRollout: function (evt: any, data: any) { }, entityClick: (evt: any, data: any) => { console.log('CCP Click', data); }}
  //     }).render();
  //   });
  // }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdMatNames.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstAllMats;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdMatNames = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  clearBSDate(ctrl: string) {
    this.overwFrm.get(ctrl)?.setValue('');
  }

  onClkRdb(ctrl: string) {
    this.fc?.stkMtrlBdge.setValue([]), this.fc?.stkMtrlNme.setValue([]), this.fc?.actvtyMaterialBadge.setValue([]),
      this.fc?.actvtyMaterialName.setValue([]); this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
    // this.fc?.materialBadge.setValue([]), this.fc?.material.setValue([]); this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
    // if (ctrl == 'g')
    //   this.fc?.material?.clearValidators(), this.fc?.materialBadge?.setValidators([Validators.required]), this.lstSlctdMatNames = []; 
    // else
    //   this.fc?.materialBadge?.clearValidators(), this.fc?.material?.setValidators([Validators.required]);
    //     this.fc?.materialBadge?.updateValueAndValidity(), this.fc?.material?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  cnclFrm(cntrl: any) {
    if (cntrl == 'stk') {
      this.overwFrm.get('stckFacCatId')?.setValue(''), this.overwFrm.get('stkMtrlNme')?.setValue(''), this.overwFrm.get('stkMtrlBdge')?.setValue(''),
        this.chsnInvFT = '', this.chsnInvMTN = '', this.lstSlctdMatTags = [], this.dfltStrTgsStk = [],
        this.lstFacTags.forEach(e => { this.dfltStrTgsStk.push({ id: e.id, name: e.name }) }), this.slctdStkMat = [];
      this.overwFrm.get('stckFacCatId')?.setValue(this.dfltStrTgsStk);
      let mtRIV = this.lstMatTags1.filter(f => f.name == 'RI Vaccines')[0], lstMTR: any[] = [];
      if (mtRIV)
        lstMTR.push(mtRIV), this.overwFrm.get('stkMtrlBdge')?.setValue(lstMTR), this.lstSlctdMatTags.push(mtRIV), this.slctdStkMat.push(mtRIV);
      this.GetIndctrINV(); // this.overwFrm.get('stckFacCatId')?.setValue(''),
    }
    else if (cntrl == 'act') {
      this.overwFrm.get('actvtyFacCatID')?.setValue(''), this.overwFrm.get('actvtyMaterialBadge')?.setValue(''), this.overwFrm.
        get('actvtyMaterialName')?.setValue(''), this.overwFrm.get('actvtyTxnType')?.setValue('0'), this.overwFrm.get('actvtyRatePeriod')?.
          setValue('7'), this.chsnActFT = '', this.chsnActMTN = '', this.chsnPrd = '', this.chsnTxnType = '', this.dfltStrTgsAct = [],
        this.lstFacTags.forEach(e => { this.dfltStrTgsAct.push({ id: e.id, name: e.name }) }), this.slctdActvtyMat = [], this.lstSlctdMatTags = [];
      this.overwFrm.get('actvtyFacCatID')?.setValue(this.dfltStrTgsAct);
      let mtRIV = this.lstMatTags1.filter(f => f.name == 'RI Vaccines')[0], lstMTR: any[] = [];
      if (mtRIV)
        lstMTR.push(mtRIV), this.lstSlctdMatTags.push(mtRIV), this.overwFrm.get('actvtyMaterialBadge')?.setValue(lstMTR), this.slctdActvtyMat.push(mtRIV);
      this.GetIndctrACT();
    }
    else {
      this.overwFrm.get('tempFacCatId')?.setValue(''), this.overwFrm.get('tempStatus')?.setValue(''), this.chsnTempFT = '', this.chsnTempAst = '', this.dfltStrTgsTemp = []; this.selectedTypes = [];
      this.lstFacTags.forEach(e => { this.dfltStrTgsTemp.push({ id: e.id, name: e.name }) }); this.overwFrm.get('tempFacCatId')?.setValue(this.dfltStrTgsTemp); this.overwFrm.get('isUnknown')?.setValue(false);
      this.astTypLst.forEach((e, i) => { this.selectedTypes.push({ id: e.id, name: e.name }), this.chsnTempAst += `${i == 0 ? '' : ', '}${e.name}` });
      this.overwFrm.get('tempStatus')?.setValue(this.selectedTypes); this.GetIndctrTemp();
    }
  }

  onChkFltr(type: string) {
    let cO: any[] = [];
    if (type == 's') {
      if (this.chsnInvFT != '')
        cO = [], this.chsnInvFT.split(', ').forEach(e => {
          if (this.lstFacTags.filter(f => f.name === e).length > 0)
            cO.push(this.lstFacTags.filter(f => f.name === e)[0]);
        }), this.dfltStrTgsStk = [...cO];
      else
        this.dfltStrTgsStk = [...[]];
      if (this.isStkMatBdge == 'badge') {
        if (this.chsnInvMTN != '') {
          cO = [], this.chsnInvMTN.split(', ').forEach(e => {
            if (this.lstMatTags1.filter(f => f.name === e).length > 0)
              cO.push(this.lstMatTags1.filter(f => f.name === e)[0]);
          }), this.slctdStkMat = [...cO];
        }
        else
          this.slctdStkMat = [...[]];
      }
      if (this.isStkMatBdge == 'name') {
        if (this.chsnInvMTN != '') {
          cO = [], this.chsnInvMTN.split(', ').forEach(e => {
            if (this.lstAllMats.filter(f => f.name === e).length > 0)
              cO.push(this.lstAllMats.filter(f => f.name === e)[0]);
          }), this.slctdStkMatName = [...cO];
        }
        else
          this.slctdStkMatName = [...[]];
      }
    }
    else {
      if (this.chsnActFT != '')
        cO = [], this.chsnActFT.split(', ').forEach(e => {
          if (this.lstFacTags.filter(f => f.name === e).length > 0)
            cO.push(this.lstFacTags.filter(f => f.name === e)[0]);
        }), this.dfltStrTgsAct = [...cO];
      else
        this.dfltStrTgsAct = [...[]];
      if (this.isActvMatBdge == 'badge') {
        if (this.chsnActMTN != '') {
          cO = [], this.chsnActMTN.split(', ').forEach(e => {
            if (this.lstMatTags1.filter(f => f.name === e).length > 0)
              cO.push(this.lstMatTags1.filter(f => f.name === e)[0]);
          }), this.slctdActvtyMat = [...cO];
        }
        else
          this.slctdActvtyMat = [...[]];
      }
      if (this.isActvMatBdge == 'name') {
        if (this.chsnActMTN != '') {
          cO = [], this.chsnActMTN.split(', ').forEach(e => {
            if (this.lstAllMats.filter(f => f.name === e).length > 0)
              cO.push(this.lstAllMats.filter(f => f.name === e)[0]);
          }), this.slctdActvtyMatName = [...cO];
        }
        else
          this.slctdActvtyMatName = [...[]];
      }
    }
  }

  exprtExl(cntrl: any) {
    if (this.rngIndctr == 's') {
      let rawValues = this.overwFrm.getRawValue(), rawStkBdge: any[] = rawValues.stckFacCatId != '' ? rawValues.stckFacCatId : [],
        rawMtrlBdge: any[] = rawValues.stkMtrlBdge != '' ? rawValues.stkMtrlBdge : [], rawMtrlStkNames: any[] = rawValues.stkMtrlNme != '' ?
          rawValues.stkMtrlNme : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], stkMtrlBdgeID: any[] = [], payLoad: any = {};
      rawStkBdge.forEach(e => { strBdges.push(e.id) }), rawMtrlBdge.forEach(e => { stkMtrlBdgeID.push(e.id) }), rawMtrlStkNames.forEach(e => { stkMtrlIDs.push(e.id); }), payLoad = {
        locationId: '', mapType: this.mapType, productBadges: stkMtrlBdgeID, productIds: stkMtrlIDs,
        storeBadges: strBdges, type: this.smIndType, email: this.usrLgnDtls.email
      };
     
      if (this.mapType == '2' && !this.frstVst)
        payLoad.locationId = JSON.parse(<any>localStorage.getItem('state')).originalId;
      if (this.mapType == '3' && !this.frstVst)
        payLoad.locationId = JSON.parse(<any>localStorage.getItem('district')).originalId;
      
      if (this.usrLvl == "State" && this.frstVst)
         payLoad.locationId = this.usrLvlDtls?.id;
      if (this.usrLvl == "District" && this.frstVst)
        payLoad.locationId = this.usrLvlDtls?.id;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbInvExprt, payLoad, 5).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message);
          }
          else {
            this.glblSvc.onTstr('w', this.result.message);
          }
        }
      });
    }
    else if (this.rngIndctr == 'a') {
      let rawValues = this.overwFrm.getRawValue(), rawMtrlActvtyNames: any[] = rawValues.actvtyMaterialName != '' ? rawValues.actvtyMaterialName : [],
        rawStoreActvtyID: any[] = rawValues.actvtyFacCatID != '' ? rawValues.actvtyFacCatID : [], rawMtrlactvtyBdge: any[] = rawValues.actvtyMaterialBadge != '' ?
          rawValues.actvtyMaterialBadge : [], strBdges: any[] = [], stkMtrlIDs: any[] = [], actMtrlBdgeID: any[] = [], payLoad: any = {};
      rawStoreActvtyID.forEach((e, i) => { strBdges.push(e.id) }), rawMtrlactvtyBdge.forEach((e, i) => { actMtrlBdgeID.push(e.id) }),
      rawMtrlActvtyNames.forEach((e, i) => {
       stkMtrlIDs.push(e.id) }), this.chsnTxnType = rawValues.actvtyTxnType == '0' ? 'ALL' : this.lstTxnTyps.filter(f =>
        f.id == parseInt(rawValues.actvtyTxnType))[0]?.name;
      switch (rawValues.actvtyRatePeriod) {
        case '7':
          this.chsnPrd = '7 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
          break;
        case '15':
          this.chsnPrd = '15 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (14 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
          break;
        case '30':
          this.chsnPrd = '30 Days', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (29 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
          break;
        case '3m':
          this.chsnPrd = '3 Months', this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (89 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
          break;
        default:
          this.chsnPrd = 'Now', this.frmDate = this.dateConverterTo(new Date()), this.toDate = this.dateConverterTo(new Date());
      }
      payLoad = {
        fromDate: this.frmDate, locationId: '', productIds: stkMtrlIDs, productTags: actMtrlBdgeID, storeTags: strBdges,
        toDate: this.toDate, txnTypeId: parseInt(rawValues.actvtyTxnType), stateId: this.usrStateId, districtId: this.usrDistId, email: this.usrLgnDtls.email
      };
      if (this.mapType == '2' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('state')).originalId;
    if (this.mapType == '3' && !this.frstVst)
      payLoad.locationId = JSON.parse(<any>localStorage.getItem('district')).originalId;
    
    if (this.usrLvl == "State" && this.frstVst)
       payLoad.locationId = this.usrLvlDtls?.id;
    if (this.usrLvl == "District" && this.frstVst)
      payLoad.locationId = this.usrLvlDtls?.id;

      // payLoad.stateId = JSON.parse(<any>localStorage.getItem('district')).sId;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbActExprt.replace('mmm', this.mapType) +
        this.actIndType, payLoad, 5).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              this.glblSvc.onTstr('s', this.result.message);
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
        });
    }
    else {
      let rawValues = this.overwFrm.getRawValue(), rawTempFacId: any[] = rawValues.tempFacCatId != '' ? rawValues.tempFacCatId : [], unknown = rawValues.isUnknown,
        tempFclty: any[] = [], payLoad: any = {}, rawStatus: any[] = rawValues.tempStatus != '' ? rawValues.tempStatus : [], slctdstatus: any[] = [];
      rawTempFacId.forEach(e => { tempFclty.push(e.id) }), rawStatus.forEach(e => { slctdstatus.push(e.id) });
      payLoad = {
        locationId: '', mapType: this.mapType, facilityTag: tempFclty, type: this.tempIndType, stateId: this.usrStateId, assetType: slctdstatus,
        districtId: this.usrDistId, email: this.usrLgnDtls.email
      };
      if (this.mapType == '2' && !this.frstVst)
        payLoad.locationId = JSON.parse(<any>localStorage.getItem('state')).originalId;
      if (this.mapType == '3' && !this.frstVst)
        payLoad.locationId = JSON.parse(<any>localStorage.getItem('district')).originalId;
      
      if (this.usrLvl == "State" && this.frstVst)
         payLoad.locationId = this.usrLvlDtls?.id;
      if (this.usrLvl == "District" && this.frstVst)
        payLoad.locationId = this.usrLvlDtls?.id;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbTmpExprt.replace('nnn', unknown), payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message);
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
    }
  }
}