import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-stock-deviant',
  templateUrl: './stock-deviant.component.html',
  styleUrls: ['./stock-deviant.component.css']
})

export class StockDeviantComponent extends BaseComponent implements OnInit {

  pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; slctdInclFac: any[] = []; slctdInclPro: any[] = [];
  hideproducts: any = []; lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllAS: any[] = []; noRcrdsTxt = environment.TblNoRcrds; 
  isMatBdge = 'badge'; lstAS: any[] = []; lstProducts: any; lstSlctdMatName: any = []; lstAllMats: any[] = []; slctdMatName: any[] = [];
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', 
  itemsShowLimit: 3, allowSearchFilter: true }; slctMatId: any = null;
  invStkDvntFrm = this.formBuilder.group({ country: [null], district: [null], storeId: [null], expireBefore: [null], abnormalityType: ['200'],
  includeProductBadge: [null], includeStoreBadge: [null], block: [null], productId: [null], includeAllProductBadge: [[]], isMatBdge: ['badge'],
  productIds: [[]], includeALLStoreBadge: [[]] }); dfltMtrlTag ='';

  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility); }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats });
      this.glblSvc.sbjctCnfgData.subscribe(s => { this.dfltMtrlTag = s.cnfgDBData?.abnormal_stock_views?.default_material_tag_filter});
  }

  ngOnInit(): void {
    this.onClkRdb('productTags');
       if(this.dfltMtrlTag !=''){
       this.slctMatId = this.dfltMtrlTag;  this.slctdInclPro.push(this.dfltMtrlTag);
      } this.LoadGrid();
  }

  get fc() { return this.invStkDvntFrm.controls; }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadGrid();
  }

  LoadGrid() {
    let crntPgIndx = this.crntPage - 1, mN: any[] = [], delKeys = ['includeProductBadge', 'includeStoreBadge'], mT: any [] = [],
    payLoad = this.invStkDvntFrm.getRawValue();
    payLoad.pranthId = this.chsnDmnId, payLoad.includeAllProductBadge = [], payLoad.includeALLStoreBadge = [], payLoad.includeStoreBadge =
      (payLoad.includeStoreBadge?.length != 0 && this.slctdInclFac.length != 0) ? payLoad.includeALLStoreBadge.push(+this.slctdInclFac) : null,
      payLoad.includeProductBadge = (payLoad.includeProductBadge?.length != 0 && this.slctdInclPro.length != 0) ? payLoad.includeAllProductBadge.
      push(+this.slctdInclPro) : null, payLoad.abnormalityType = payLoad.abnormalityType != null ? +payLoad.abnormalityType : 200;
        mT = (payLoad.includeProductBadge != null ? payLoad.includeALLStoreBadge : null), payLoad.includeProductBadge = mT;
    if (payLoad.includeStoreBadge == null) this.lstFacTags.forEach((e: any) => { payLoad.includeALLStoreBadge.push(e.id); }); // .name
    // if (payLoad.includeProductBadge == null) this.lstMatTags.forEach((e: any) => { payLoad.includeAllProductBadge.push(e.id); }); // .name
    delKeys.forEach(e => { delete payLoad[e]; }), this.lstAS = [], payLoad.productIds = payLoad.productIds != [] && payLoad.productIds != null ?
    this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
        environment.apiMthds.abnrmlStkList.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad).then(() => {
      if (this.result) {
        if (this.result.data) {
          this.totalItems = this.result.data.totalRecordsCount, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          if (this.result.status == 'OK') {
            if (this.result.data == null)
              this.glblSvc.onTstr('i', this.result.message);
            else {
              let data = this.result.data;
              data?.abnormalInventoryDetails.forEach((e: any) => { Object.assign(e, { lstPrdcts: [], isShow: false }); }),
                this.lstAllAS = data?.abnormalInventoryDetails, this.lstAS = data?.abnormalInventoryDetails;
            }
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }
  
  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
        if (this.lstSlctdMatName.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
    }
    else if (type == 'r') {
          this.lstSlctdMatName = this.lstSlctdMatName.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
          this.lstSlctdMatName = this.lstAllMats;
    }
    else {
          this.lstSlctdMatName = [];
    }
  }

  onClkRdb(ctrl: string) {
    this.invStkDvntFrm.get('includeProductBadge')?.setValue(null), this.invStkDvntFrm.get('productIds')?.setValue([]);
    if (ctrl == 'includeProductBadge')
      this.fc?.productIds.setValue([]), this.lstSlctdMatName = [...[]];
    else
      this.slctMatId = null, this.invStkDvntFrm.get('includeProductBadge')?.setValue(null), this.slctdInclFac = [],
      this.invStkDvntFrm.get('includeAllProductBadge')?.setValue([]), this.slctdInclPro = [];
  }

  onReset() {
    this.invStkDvntFrm.reset(), this.slctdInclFac = [], this.slctdInclPro = [],  this.invStkDvntFrm.get('productIds')?.setValue([]),
    this.invStkDvntFrm.get('isMatBdge')?.setValue('badge'), this.slctdMatName = [], this.lstSlctdMatName = [...[]],
    this.dfltMtrlTag !='' ? this.slctMatId = this.dfltMtrlTag : null, this.slctdInclPro.push(this.dfltMtrlTag), this.LoadGrid(), setTimeout(() => {
      this.invStkDvntFrm.get('abnormalityType')?.setValue('200'); }, 100);
  }

  selected(type: any, evnt: any) {
    if (type == 'inf') {
      this.slctdInclFac = []
      if (evnt.target.value != 'null')
        this.slctdInclFac.push(evnt.target.value);
    }
    if (type == 'inp') {
      this.slctdInclPro = [];
      if (evnt.target.value != 'null')
        this.slctdInclPro.push(evnt.target.value);
    }
  }

  loadProducts(storeId: any, productId: any, index: any) {
    if (this.lstAS[index].isShow) {
      let payLoad = { abnormalityType: this.invStkDvntFrm.get('abnormalityType')?.value ? +this.invStkDvntFrm.get('abnormalityType')?.value :
        200, productId: productId, storeId: storeId };
      this.lstAS[index].lstPrdcts = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.productstockdeviant,
          environment.apiMthds.getstockdeviantproduct, payLoad, 0, true).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAS[index].lstPrdcts = this.result.data ? this.result.data : [];
          }
          this.hideproducts[index] = !this.hideproducts[index];
        }
      });
    }
  }

  onEE() {
    let payLoad = this.invStkDvntFrm.getRawValue(), delKeys = ['includeProductBadge', 'includeStoreBadge'], mN: any[] = [], mT: any[] = [];
    payLoad.pranthId = this.chsnDmnId, payLoad.includeAllProductBadge = [], payLoad.includeStoreBadge =
      (payLoad.includeStoreBadge?.length != 0 && this.slctdInclFac.length != 0) ? payLoad.includeALLStoreBadge.push(+this.slctdInclFac) : null,
      payLoad.includeProductBadge = (payLoad.includeProductBadge?.length != 0 && this.slctdInclPro.length != 0) ?
      payLoad.includeAllProductBadge.push(+this.slctdInclPro) : null, payLoad.includeALLStoreBadge = [],
      payLoad.abnormalityType = payLoad.abnormalityType != null ? +payLoad.abnormalityType : 200;
      mT = (payLoad.includeProductBadge != null ? payLoad.includeALLStoreBadge : null), payLoad.includeProductBadge = mT;
    if (payLoad.includeStoreBadge == null) this.lstFacTags.forEach((e: any) => { payLoad.includeALLStoreBadge.push(e.id); }); // .name
   // if (payLoad.includeProductBadge == null) this.lstMatTags.forEach((e: any) => { payLoad.includeAllProductBadge.push(e.id); }); // .name
    delKeys.forEach(e => { delete payLoad[e]; }),  payLoad.productIds = payLoad.productIds != [] && payLoad.productIds != null ?
    this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
        environment.apiMthds.exprtstckdvt.replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).
        replace('nnn', this.usrLgnDtls.userName).replace('eml', this.usrLgnDtls.email), payLoad, 11).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}