<div class="row ">
    <div class="col-xl-12 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row mt-4">
    <div class="col-xl-12">
        <div class="text-center">
            <h6 class="mb-0">Total demand</h6>
            <small>From: Feb 2021 To: Apr 2021 Material tag: RI Vaccines Facility: Sagar RVS Indent tag:
                Routine </small>
        </div>
    </div>
</div>
<div class="row mt-2 mb-3">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto">Receiving Facility</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">OM Sagar DiVS 1</a></div>
                        <small>Sagar, Madhya Pradesh</small>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">OM Sagar DiVS 1</a></div>
                        <small>Sagar, Madhya Pradesh</small>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">OM Sagar DiVS 1</a></div>
                        <small>Sagar, Madhya Pradesh</small>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">OM Sagar DiVS 1</a></div>
                        <small>Sagar, Madhya Pradesh</small>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>