<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="mmPrchLst">
                <a class="nav-link" id="orders-tab" data-bs-toggle="tab" href="#orders" role="tab"
                    aria-controls="orders" aria-selected="true" (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' ? 'active' : ''">Purchase Indents
                </a>
            </li>
            <li class="nav-item" *ngIf="mmCrtPrch">
                <a class="nav-link" id="creat-order-tab" data-bs-toggle="tab" href="#creat-order" role="tab"
                    aria-controls="creat-order" aria-selected="false" (click)="onTabSelection('new')"
                    [ngClass]="paramTab === 'new' ? 'active' : ''" *ngIf="isCnsgnStr == 'National'">Create Purchase Indent
                </a>
            </li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="orders-list" role="tabpanel" aria-labelledby="orders-tab" *ngIf="mmPrchLst"
                [ngClass]="paramTab === 'list' ? 'show active' : ''">
                <evin-prchs-indent-list></evin-prchs-indent-list>
            </div>           
            <div class="tab-pane fade" id="creat-order" role="tabpanel" aria-labelledby="creat-order-tab"
                [ngClass]="paramTab === 'new' ? 'show active' : ''" *ngIf="isCnsgnStr == 'National' && 'mmCrtPrch'">
                <evin-create-prchs-indent></evin-create-prchs-indent>
            </div>
        </div>
    </div>
</div>