<div class="card mb-0 mt-3 p-0">
    <div class="card-header p-2 ps-3 pe-3">
        <h6 class="mb-0">Handling units information</h6>
    </div>
    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
        <form>
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify handling unit details</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Name" value="" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Description</label>
                            <input type="text" placeholder="Description" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-4 mt-3">
                            <label for="textBox">Contents <span class="text-danger">*</span></label>
                            <div class="row">
                                <div class="col-xl-7">
                                    <input type="text" placeholder="Contents" value=""
                                        class="form-control form-control-sm" id="">
                                </div>
                                <div class="col-xl-5">
                                    <input type="text" placeholder="Quantity" value="" class="form-control 
                                    form-control-sm" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary me-2 mb-2">Save</button>
                        <button class="btn btn-sm btn-light mb-2">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>