<form [formGroup]="cnfgGnrlFrm" autocomplete="off">
    <div class="card p-3 pb-0 pt-2 border-0">
        <div class="card-header p-3 pb-1 pt-2">
            <div class="h6 float-start">General configuration</div>
        </div>
        <div class="card-body p-0 mt-2 mb-0">
            <div class="row mt-2">
                <div class="col-sm-12" formGroupName="localeDefaults">
                    <div class="card border shadow-none  p-0 h-100 mt-0 mb-0">
                        <div class="card-header p-2 fw-bold">Location Information</div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row mb-2 mt-3">
                                        <div class="form-group col-xl-4 mb-3">
                                            <label>Country</label>
                                            <select class="form-select form-select-sm" formControlName="country_id" [attr.disabled]="true">
                                                <option *ngFor="let s of lstCnts" [value]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label>State</label>
                                            <select class="form-select form-select-sm" formControlName="state" [attr.disabled]="true">
                                                <option *ngFor="let s of lstSts" [value]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label>District</label>
                                            <select class="form-select form-select-sm" formControlName="disrict_id" [attr.disabled]="true">
                                                <option *ngFor="let s of lstDsts" [value]="s.id">{{s.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label>Timezone</label>
                                            <select class="form-select form-select-sm" formControlName="time_zone_id" [attr.disabled]="true">
                                                <option *ngFor="let tz of lstTmZns" value="{{tz.id}}">{{tz.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4 mb-3">
                                            <label>Domain Level</label>
                                            <!-- <input type="text" class="form-control form-control-sm" placeholder="Domain Level"
                                                formControlName="domain_level" [attr.disabled]="true"> -->
                                            <select class="form-select form-select-sm" formControlName="domain_level" [attr.disabled]="true">
                                                <option *ngFor="let dl of lstDLs" value="{{dl}}">{{dl}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-0">
                <div class="col-sm-12">
                    <div class="card border shadow-none p-0 mt-3 mb-3">
                        <div class="card-header p-2 fw-bold">Support settings</div>
                        <div class="card-body p-2">
                            <div class="small">
                                Support contact information can be configured per role. There can be a different support
                                person or number for operators, managers or administrators. Support information can be
                                either specified by selecting a user (in the User column), or by explicitly entering a name,
                                phone number or email. Selecting an existing user will automatically default the support
                                contact information to the user's name, phone number and email.
                            </div>
                            <div class="mt-2 small">
                                <strong>NOTE</strong>:
                                Phone number is mandatory. If you do not select an existing user, then phone number has
                                to be entered. Name and email are optional.
                            </div>
                            <div class="table-responsive mt-3" formArrayName="support_contacts">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" *ngIf="isLcl">Id</th>
                                            <th scope="col">Role</th>
                                            <th scope="col" class="text-center">User</th>
                                            <th scope="col" class="text-center">Name</th>
                                            <th scope="col" class="text-center">Phone number</th>
                                            <th scope="col" class="text-center">Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of GetSprtCntcts().controls;let tabIndx=index;" [formGroupName]="tabIndx">
                                            <td *ngIf="isLcl">{{item?.get('role_id')?.value}}</td>
                                            <td>{{item?.get('roleName')?.value}}</td>
                                            <td>
                                                <ng-template #cstmUser let-model="item">
                                                    <span class="font-weight-bold">{{model.user_id}}</span>
                                                </ng-template>
                                                <input name="user" type="text" placeholder="Type to get suggestions.."
                                                    formControlName="userName" [typeahead]="lstUsers[tabIndx]"
                                                    class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                                    [typeaheadItemTemplate]="cstmUser" typeaheadOptionField="user_id"
                                                    [adaptivePosition]="true" [typeaheadScrollable]="true"
                                                    (change)="onSlctUser($event, 'sc', tabIndx, 'c', lstUsers[tabIndx])"
                                                    (typeaheadOnSelect)="onSlctUser($event, 'sc', tabIndx, 's', lstUsers[tabIndx])" />
                                            </td>
                                            <td>
                                                <input type="text" placeholder="Enter name" formControlName="name"
                                                    class="form-control form-control-sm">
                                            </td>
                                            <td>
                                                <input type="text" placeholder="Enter Phone number" maxlength="13"
                                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                    class="form-control form-control-sm" formControlName="phone_number">
                                            </td>
                                            <td>
                                                <input type="email" placeholder="Enter Email Id" formControlName="email_id"
                                                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                    [ngClass]="{ 'is-invalid': isSave && item?.get('email_id')?.errors }"
                                                    class="form-control form-control-sm">
                                                <div class="invalid-feedback" *ngIf="isSave && item?.get('email_id')?.errors">
                                                    Invalid Email <!-- <div *ngIf="item?.get('email_id')?.errors?.pattern">Invalid Email</div> -->
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card border shadow-none p-0 mt-2">
                        <div class="card-header  p-2 fw-bold">Administrative contacts</div>
                        <div class="card-body p-2">
                            <div class="small">
                                Administrative contacts are typically administrators or supervisors in the domain and will
                                appear wherever others have to contact them (e.g. in the monitoring application). Two types
                                of administrative contacts can be configured - primary and secondary. This will provide
                                options for users to contact either the primary or secondary contacts. Administrative contact
                                information can be specified by selecting a user (in the User column). Selecting a user will
                                automatically fill the Administrative contact details such as name, phone number, and email.
                            </div>
                            <div class="table-responsive mt-4" formArrayName="admin_contacts">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Category</th>
                                            <th scope="col" class="text-center">User</th>
                                            <th scope="col" class="text-center">Name</th>
                                            <th scope="col" class="text-center">Phone number</th>
                                            <th scope="col" class="text-center">Email Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of GetAdmnCntcts().controls;let admIndx=index;" [formGroupName]="admIndx">
                                            <td>{{item?.get('admin_type')?.value}}</td>
                                            <td>
                                                <ng-template #cstmUser let-model="item">
                                                    <span class="font-weight-bold">{{model.user_id}}</span>
                                                </ng-template>
                                                <input name="user" type="text" placeholder="Type to get suggestions.."
                                                    formControlName="userName" [typeahead]="admIndx == 0 ? lstUsrAdmn1 : lstUsrAdmn2"
                                                    class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                                    [typeaheadItemTemplate]="cstmUser" typeaheadOptionField="user_id"
                                                    [adaptivePosition]="true" [typeaheadScrollable]="true"
                                                    (change)="onSlctUser($event, 'ac', admIndx, 'c', (admIndx == 0 ? lstUsrAdmn1 : lstUsrAdmn2))"
                                                    (typeaheadOnSelect)="onSlctUser($event, 'ac', admIndx, 's', (admIndx == 0 ? lstUsrAdmn1 : lstUsrAdmn2))" />
                                            </td>
                                            <td>{{item?.get('name')?.value}}</td>
                                            <td>{{item?.get('phone_number')?.value}}</td>
                                            <td>{{item?.get('email_id')?.value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary" *ngIf="gnrlPrmsn" (click)="isSave=true;onSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>