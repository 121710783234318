<div class="card mb-0 border-0">
    <div class="card-header p-2">
        <div>
            <h6 class="mb-0">Bulletin board access log</h6>
        </div>
    </div>
    <div class="card-body p-2">
        <div class="row">
            <div class="col-xl-12 mb-2">
                <!-- <span style="display: inline-block;" class="me-2">
                    <label tyle="display: inline-block;">Filter</label>
                </span>
                <span style="display: inline-block;">
                    <select class="form-select">
                        <option>5</option>
                        <option>10</option>
                    </select>
                </span> -->
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-sm table-striped table-small-font p-0 table-bordered">
                <thead>
                    <tr>
                        <th>Item No.</th>
                        <th>Date</th>
                        <th>IP Address</th>
                        <th>User-Agent</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Wed Apr 14 12:30:19 UTC 2021</td>
                        <td>27.6.89.156</td>
                        <td>Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like
                            Gecko) Chrome/89.0.4389.114 Safari/537.36</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Wed Apr 14 12:30:19 UTC 2021</td>
                        <td>27.6.89.156</td>
                        <td>Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like
                            Gecko) Chrome/89.0.4389.114 Safari/537.36</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row ps-0 pe-0 pt-3 pb-0">
            <div class="col-lg-6 col-xxl-6 pb-0">
            </div>
            <div class="col-lg-6 col-xxl-6 pb-0">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination justify-content-xl-end  pagination-primary ">
                        <li class="page-item"><a class="page-link" aria-label="Previous" data-bs-original-title=""
                                title=""><span aria-hidden="true">«</span><span class="sr-only">Previous</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a>
                        </li>
                        <li class="page-item"><a class="page-link" aria-label="Next" data-bs-original-title=""
                                title=""><span aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
    <div class="text-end pb-3">
        <button type="button" class="btn btn-sm btn-primary">Save</button>
    </div>
</div>
<div class="tab-pane fade" id="top-Sent-Notif" role="tabpanel" aria-labelledby="Sent-Notif-top-tab">
    <div class="card mb-3 rounded-0">
        <div class="card-header border-bottom-0 p-2">
            <h6 class="mb-0">Bulletin board</h6>
        </div>
    </div>
    <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
        <div class="card-header shadow-sm p-2">
            <h6 class="mb-0">Token expiry</h6>
        </div>
        <div class="card-body p-2 mt-0 mb-0">
            <div class="row">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <form>
                        <div class="row mb-2">
                            <div class="form-group col-xl-12">
                                <label for="textBox">Authentication token expiry <span
                                        class="text-danger">*</span></label>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" placeholder="Authentication token expiry" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-sm-4">
                                        day(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="text-end pb-3">
        <button type="button" class="btn btn-sm btn-primary">Save</button>
    </div>
</div>