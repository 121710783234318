<div class="card p-0 border-0">
    <div class="card-body p-3">
        <div class="row p-0 mt-2">
            <form [formGroup]="actvtyRateFrm">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <div style="float: right;" *ngIf="isRprtsHlpShw">
                                <a href="btn btn-sm btn-link" [routerLink]="['/act-rate/help']" target="_blank"><u>Help</u></a> 
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <strong class="col-form-label">Material By<span class="text-danger"><sup>*</sup></span></strong>
                                    <div class="form-check form-check-inline mt-2 p-l-25">
                                        <input class="form-check-input" type="radio" id="rbMat1" name="isMatBdge" value="badge"
                                            [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" formControlName="isMatBdge"
                                            (click)="onClkRdb('productTags')">
                                        <label class="form-check-label" for="mat">Tag</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="rbMat2" name="isMatBdge" value="name"
                                            [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" formControlName="isMatBdge"
                                            (click)="onClkRdb('productIds')">
                                        <label class="form-check-label" for="mat">Name</label>
                                    </div>
                                    <div *ngIf="isMatBdge=='badge'">
                                        <ng-multiselect-dropdown
                                            [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                            [data]="lstMatTags" formControlName="productTags"
                                            (onSelect)="onMultiSlct('s', 'm', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productTags.errors }">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="isSbmtd && fc.productTags.errors" class="invalid-feedback">
                                            <div *ngIf="fc.productTags.errors?.required">Material tag is required</div>
                                        </div>
                                    </div>
                                    <div *ngIf="isMatBdge=='name'">
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                            [data]="lstAllMats" formControlName="productIds"                                                                        
                                            (onSelect)="onMultiSlct('s', 'n', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.productIds.errors }"
                                            [(ngModel)]="slctdMatName">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="isSbmtd && fc.productIds.errors" class="invalid-feedback">
                                            <div *ngIf="fc.productIds.errors?.required">Material name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 pt-1">
                                    <strong class="col-form-label">Facility Tag</strong><span class="text-danger"><sup>*</sup></span>&nbsp;&nbsp;
                                    <div>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings"
                                            [data]="lstFacTags" formControlName="storeTags"
                                            [ngClass]="{ 'is-invalid': isSbmtd && fc.storeTags.errors }"                                                                        
                                            (onSelect)="onMultiSlct('s', 'f', $event)"
                                            (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                            (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                            [(ngModel)]="slctdFac">
                                        </ng-multiselect-dropdown>
                                        <div *ngIf="isSbmtd && fc.storeTags.errors" class="invalid-feedback">
                                            <div *ngIf="fc.storeTags.errors?.required">Facility is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 pt-3">
                                    <strong for="textBox">From</strong>
                                    <div class="input-group">
                                        <!-- [dateCustomClasses]="cstmDt"  -->
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDT($event)"
                                            onkeypress='return event.charCode == 8' placeholder="From" readonly
                                            formControlName="fromDate" [(ngModel)]="frmDate" [maxDate]="dt2day">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                [attr.aria-expanded]="dpFD.isOpen">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpFD.hide();clearBSDate('fromDate')">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>                                  
                                </div>
                                <div class="form-group col-md-3 pt-1">
                                    <strong class="col-form-label">To</strong>
                                    <div class="input-group">
                                        <!-- [dateCustomClasses]="cstmDt"  -->
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker"
                                            readonly [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDtT($event)" onkeypress='return event.charCode == 8'
                                            formControlName="toDate" placeholder="To" [minDate]="frmDate" [maxDate]="dt2day">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                                [attr.aria-expanded]="dpTD.isOpen">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpTD.hide();clearBSDate('toDate')">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3 mt-3">
                                    <strong for="textBox">State</strong>
                                    <select formControlName="state" class="form-select form-select-sm"
                                        (change)="dstLst=[];blkLst=[];lstGrid=[];onLocChng($event, 'state')" #locState>
                                        <option value="null">All</option>
                                        <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3 mt-3">
                                    <strong for="textBox">District</strong>
                                    <select formControlName="district" class="form-select form-select-sm"
                                        (change)="blkLst=[];lstGrid=[];onLocChng($event, 'district')" #locDist>
                                        <option value="null">All</option>
                                        <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3 mt-3">
                                    <strong for="textBox">Taluk/Block</strong>
                                    <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                        <option value="null">All</option>
                                        <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3 pt-1">
                                    <strong class="col-form-label">Transaction Type</strong>
                                    <div>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings"
                                            [data]="lstTxnTyps" formControlName="txnTypeIds"                                                                     
                                            (onSelect)="onMultiSlct('s', 't', $event)"
                                            (onSelectAll)="onMultiSlct('a', 't', $event)"
                                            (onDeSelect)="onMultiSlct('r', 't', $event)"
                                            (onDeSelectAll)="onMultiSlct('d', 't', $event)"
                                            [(ngModel)]="slctdTxn">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;isSbmtd=false;isExprt=false;lstGrid=[];facTagName='';onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;isSbmtd=true;isExprt=false;lstGrid=[];facTagName='';LoadReport()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <hr />
        <div class="row mt-3">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <div class="row" *ngIf="lstGrid?.length!=0">
                    <div class="col-xl-12 mb-2">
                        <div class="row">
                            <div class="col-xl-4">
                                <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                    <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                </button>
                            </div>
                            <div class="col-xl-8">
                                <select class="form-select form-select-sm" id="ee1" hidden>
                                    <option value="nb">Export without batch</option>
                                    <option value="wb">Export with batch</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6" >
                        <div *ngIf="lstGrid?.length!=0" class="mt-2">{{pageEntrs}}</div>
                    </div>
                    <div class="col-xl-6" >
                        <div class="float-end" *ngIf="lstGrid?.length!=0">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-striped table-sm table-bordered border-primary">
                        <thead>
                            <tr class="text-center">
                                <th class="text-center">Item.No.</th>
                                <th>State</th>
                                <!-- <th *ngIf="(actvtyRateFrm?.state.value !=0 || actvtyRateFrm.state.value !=null)">District</th> -->
                                <th *ngIf="actvtyRateFrm?.get('state')?.value!=null && actvtyRateFrm?.get('state')?.value!='null'
                                    && lstGrid?.length!=0">District</th>
                                <th *ngIf="actvtyRateFrm?.get('district')?.value!=null && 
                                actvtyRateFrm?.get('district')?.value!='null'&& lstGrid?.length!=0">Facility</th>
                                <th *ngIf="actvtyRateFrm?.get('district')?.value==null || 
                                actvtyRateFrm?.get('district')?.value=='null'">Total {{facTagName}}</th>
                                <th class="text-center" *ngIf="actvtyRateFrm?.get('district')?.value==null || 
                                actvtyRateFrm?.get('district')?.value=='null'">Total {{facTagName}} performed transaction on eVIN</th>
                                <th>Activity Rate(%)</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="lstGrid?.length!=0">
                            <tr *ngFor="let ar of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems };let i=index">
                                <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                <td class="text-center">{{ar.stateName}}</td>
                                <td class="text-center" *ngIf="actvtyRateFrm?.get('state')?.value!=null && actvtyRateFrm?.get('state')?.value!='null'
                                    && lstGrid?.length!=0">{{ar.districtName}}</td>
                                    <td class="text-center" *ngIf="actvtyRateFrm?.get('district')?.value!=null && 
                                    actvtyRateFrm?.get('district')?.value!='null'&& lstGrid?.length!=0">{{ar.storeName}}</td>
                                <td class="text-center" *ngIf="actvtyRateFrm?.get('district')?.value==null || 
                                actvtyRateFrm?.get('district')?.value=='null'">{{ar.totalStores}}</td>
                                <td class="text-center" *ngIf="actvtyRateFrm?.get('district')?.value==null || 
                                actvtyRateFrm?.get('district')?.value=='null'">{{ar.activeStores}}</td>
                                <td class="text-center">{{ar.percentage | number:'.0-2'}}</td>
                            </tr>
                            <tr class="text-center" *ngIf="actvtyRateFrm?.get('district')?.value==null || 
                            actvtyRateFrm?.get('district')?.value=='null'">
                                <td></td>
                                <td><Strong>Total</Strong></td>
                                <td *ngIf="actvtyRateFrm?.get('state')?.value!=null && actvtyRateFrm?.get('state')?.value!='null'
                                    && lstGrid?.length!=0"></td>
                                <td *ngIf="actvtyRateFrm?.get('district')?.value!=null && 
                                    actvtyRateFrm?.get('district')?.value!='null'&& lstGrid?.length!=0"></td>
                                <td><strong>{{ttlCcp}}</strong></td>
                                <td><strong>{{actStore}}</strong></td>
                                <td><strong>{{perc | number:'.0-2'}}</strong></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="lstGrid?.length==0">
                            <tr>
                                <td colspan="5" class="text-center">{{noRcrdTxt}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pb-3 pt-3" >
                    <div class="row" *ngIf="lstGrid?.length!=0">
                        <div class="col-xl-6 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>