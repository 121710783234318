import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as FusionCharts from 'fusioncharts';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.css']
})

export class ConsumptionComponent extends BaseComponent implements OnInit {

  isMatBdge = 'badge'; isFacBdge = 'badge'; prdct = ''; fclty = ''; isLocOptn = 'state'; state = ''; dist = ''; block = ''; pct = 'Monthly';
  lstMatTags: any[] = []; lstFacTags: any[] = []; lstAllMats: any[] = []; lstFcltys: any[] = []; stsLst: any[] = []; blkLst: any[] = [];
  dstLst: any[] = []; shwStDrpdwn = false; maxToDate: Date = new Date(); maxWED = this.GetReqDate4mCrntWeek(1); lstDataDDL: any[] = [];
  lstOV: any[] = []; lstMAT: any[] = []; lstFAC: any[] = []; lstLOC: any[] = []; lstX: any[] = []; lstY: any[] = []; dpSD = ''; dpED = '';
  tab = 'o'; pcType = ''; fD = ''; eD = ''; fD2 = ''; eD2 = ''; noRcrdsTxt = environment.TblNoRcrds; mtrcVal = ''; dtsDDL = '';
  xLbl = '(Click on a month bar/line to see daily trends in that month)'; lstOTs = ['material', 'store', 'location']; crntDate = new Date();
  dpSDVal: Date = new Date(); dpEDVal: Date = new Date(); pLSD = ''; plED = ''; slctdStrId: any; slctdPrdId: any; lstDtsDDL: any[] = [];
  slctdDistrictId: any; slctdBlockId: any; isMnthChng = false; isWeekChng = false; isDayChng = false; txtFD = ''; txtED = ''; slctdStateId: any;
  cnsmptnForm = this.fb.group({ blockId: [null], city: [''], consumptionFromDate: [''], consumptionToDate: [''], receivingStoreId: [null],
    consumptionTypeId: [''], districtId: [null], productBadgeId: [''], productId: [null], stateId: [null],
    storeBadgeId: [''], storeId: [null], isMatBdge: ['badge'], isFacBdge: ['badge'], isLocOptn: ['state'], pct: ['Monthly'], dpSDVal: [''],
    dpEDVal: [''], mtrcVal: ['All issues/net utilization'] }); ddlCnt = 0;

  lstXL = [{ "label": "eJan-2021" }, { "label": "eFeb-2021" }, { "label": "eMar-2021" }, { "label": "eApr-2021" }, { "label": "eMay-2021" }, { "label": "eJun-2021" }, { "label": "Jul-2021" }, { "label": "Aug-2021" }, { "label": "Sep-2021" }, { "label": "Oct-2021" }, { "label": "Nov-2021" }, { "label": "Dec-2021" }];
  lstXI = [{ "value": "15000" }, { "value": "16000" }, { "value": "17000" }, { "value": "18000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" }, { "value": "20000" }, { "value": "21000" }, { "value": "22000" }, { "value": "23000" }];
  lstXN = [{ "value": "16000" }, { "value": "20000" }, { "value": "18000" }, { "value": "19000" }, { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" }, { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" }];

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.lstFacTags =
      s.filter(f => f.badgeTypeId == environment.tagTypes.facility) }), this.glblSvc.sbjctStrList.subscribe(s => { this.lstFcltys = s; }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst, this.lstAllMats = s.allMats; });
  }

  ngOnInit(): void {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.onSetDRs(), this.cnsmptnForm.get('consumptionFromDate')?.
      setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd_) + '-01'), this.cnsmptnForm.get('consumptionToDate')?.
      setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ym_) + this.lstMnths[(new Date()).getMonth()].days);
  }

  onSetDRs() {
    let dt = new Date(), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
    this.dpSDVal = dt2, this.cnsmptnForm.get('dpSDVal')?.setValue(this.dpSDVal),
      this.cnsmptnForm.get('dpEDVal')?.setValue(new Date()), setTimeout(() => {
        localStorage.setItem('isPgLd', '0'), this.pLSD = this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd),
          this.plED = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd);
      }, 1000);
  }

  onChngDD(evnt: any, ctrl: string) {
    let val = evnt ? evnt.target.value : null;
    if (ctrl)
      this.cnsmptnForm.get(ctrl)?.setValue(val);
  }

  onChngTAH(type: any, event: any) {
    if (type == 'state' && event.value)
      this.stsLst.filter(a => { if (a.name == event.value) this.shwStDrpdwn = true; });
    if (type == 'store' && event.value) {
      if (this.lstFcltys.filter(f => f.storeName === event.value).length > 0)
        this.slctdStrId = this.lstFcltys.filter(f => f.storeName === event.value)[0].storeId;
      else
        this.cnsmptnForm.get('storeId')?.setValue(null);
    }
    if (type == 'product' && event.value) {
      if (this.lstAllMats.filter(f => f.name === event.value).length > 0)
        this.slctdPrdId = this.lstAllMats.filter(f => f.name === event.value)[0].id;
      else
        this.cnsmptnForm.get('productId')?.setValue(null);
    }
    if (type == 'state' && event.value) {
      if (this.stsLst.filter(f => f.name === event.value).length > 0)
        this.slctdStateId = this.stsLst.filter(f => f.name === event.value)[0].id;
      else
        this.cnsmptnForm.get('stateId')?.setValue(null);
    }
    if (type == 'district' && event.value) {
      if (this.dstLst.filter(f => f.name === event.value).length > 0)
        this.slctdDistrictId = this.dstLst.filter(f => f.name === event.value)[0].id;
      else
        this.cnsmptnForm.get('districtId')?.setValue(null);
    }
    if (type == 'block' && event.value) {
      if (this.blkLst.filter(f => f.name === event.value).length > 0)
        this.slctdBlockId = this.blkLst.filter(f => f.name === event.value)[0].id;
      else
        this.cnsmptnForm.get('blockId')?.setValue(null);
    }
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter(a => a.name == evnt.target.value).length > 0;
  }

  LoadOtherTabsData(payLoad: any, type: string) {
    if (payLoad) {
      let dtStart = new Date(), dtEnd = this.pcType == 'weekly' ? this.GetReqDate4mCrntWeek(1) : new Date(), dtEnd2 = this.pcType == 'weekly' ?
        this.GetReqDate4mCrntWeek(1) : new Date(), dtTo = new Date(payLoad.consumptionToDate), dtFrmt = this.pcType == 'monthly' ? this.dtF.MY : this.dtF.DMY_;
      if (this.pcType == 'monthly')
        dtStart.setMonth(dtEnd2.getMonth() - 2);
      else
        dtStart.setDate(dtEnd2.getDate() - (this.pcType == 'weekly' ? 21 : 6));
      if (this.pcType == 'weekly')
        dtStart.setMonth(dtStart.getMonth() - 1);
      payLoad.consumptionFromDate = this.glblSvc.setDtFrmt(dtStart, this.dtF.ymd), payLoad.consumptionToDate = this.glblSvc.setDtFrmt(dtEnd, this.dtF.ymd),
        this.fD2 = this.glblSvc.setDtFrmt(dtStart, dtFrmt), this.eD2 = this.glblSvc.setDtFrmt(dtEnd, dtFrmt);
      this.FetchData(environment.apiCalls.post, environment.apiPaths.rprtCnsmptn, environment.apiMthds.getCnsmptnRprtOTs.
        replace('pct', this.pcType).replace('type', type), payLoad, 1).then(() => {
          if (this.result) {
            if (this.result.status === 'OK') {
              if (this.result.data) {
                if (type == 'material')
                  this.lstMAT = this.result.data;
                if (type == 'store')
                  this.lstFAC = this.result.data;
                if (type == 'location')
                  this.lstLOC = this.result.data;
              }
            }
          }
        })
    }
  }

  onTabClk(tabType: string) {
    this.tab = tabType;
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pct == 'Monthly') {
        if (this.isMnthChng) {
          let dt = new Date(), dt1 = new Date();
          dt.setMonth(dt.getMonth() - 11), this.dpSDVal = dt, this.dpEDVal = dt1;
        }
        else {
          let dtDiff = ctrl == 'sd' ? this.CheckMonthDiff(new Date(dtVal), this.dpEDVal) : this.CheckMonthDiff(this.dpSDVal, new Date(dtVal));
          if (dtDiff > 12) {
            if (ctrl == 'sd') {
              let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() - 1));
              this.dpEDVal = dt2, this.cnsmptnForm.get('dpEDVal')?.setValue(this.dpEDVal);
            }
            else {
              let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
              this.dpSDVal = dt2, this.cnsmptnForm.get('dpSDVal')?.setValue(this.dpSDVal);
            }
            this.glblSvc.onTstr('i', 'From and To periods should be in a range of 12 months. The dates has been adjusted to fit within the range.');
          }
        }
      }
      if (this.pct == 'Weekly') {
        if (this.isWeekChng) {
          let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 99));
          this.dpSDVal = dt1, this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.isWeekChng = false;
        }
        else {
          let dtDiff = ctrl == 'sd' ? this.CheckWeeksDiff(new Date(dtVal), this.dpEDVal) : this.CheckWeeksDiff(this.dpSDVal, new Date(dtVal)),
            dtDiff2 = +this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF._ymd) - +this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF._ymd);
          setTimeout(() => {
            if (dtDiff > 15 || dtDiff2 < 0) {
              localStorage.setItem('dtWDs', this.glblSvc.setDtFrmt(ctrl == 'sd' ? this.dpSDVal : this.dpEDVal, this.dtF.ymd));
              let dt = new Date(<string>localStorage.getItem('dtWDs')), dt2 = new Date(<string>localStorage.getItem('dtWDs'));
              if (ctrl == 'sd') {
                let dtEND = +this.glblSvc.setDtFrmt(new Date(dt2.setDate(dt2.getDate() + 98)), this.dtF._ymd) - +this.glblSvc.setDtFrmt(new Date(), this.dtF._ymd);
                this.dpEDVal = dtEND > 0 ? this.GetReqDate4mCrntWeek(1) : new Date(dt.setDate(dt.getDate() + 98));
              }
              else
                this.dpSDVal = new Date(dt.setDate(dt.getDate() - 98));
              this.glblSvc.onTstr('i', 'From and To periods should be in a range of 15 weeks. The dates has been adjusted to fit within the range.'),
                localStorage.removeItem('dtWDs');
            }
          }, 500);
        }
      }
      if (this.pct == 'Daily') {
        if (this.isDayChng) {
          let dt2Day = new Date(), dt1 = (new Date(dt2Day.setMonth(dt2Day.getMonth() - 1)));
          this.dpSDVal = new Date(dt1.setDate(dt1.getDate() + 1)), this.dpEDVal = new Date(), this.isDayChng = false;
        }
        else {
          let dtDiff = ctrl == 'sd' ? this.CheckDaysDiff(new Date(dtVal), this.dpEDVal) : this.CheckDaysDiff(this.dpSDVal, new Date(dtVal)),
            dtDiff2 = +this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF._ymd) - +this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF._ymd);
          setTimeout(() => {
            if (dtDiff > 31 || dtDiff2 < 0) {
              localStorage.setItem('dtDDs', this.glblSvc.setDtFrmt(ctrl == 'sd' ? this.dpSDVal : this.dpEDVal, this.dtF.ymd));
              let dt = new Date(<string>localStorage.getItem('dtDDs')), dt2 = new Date(<string>localStorage.getItem('dtDDs'));
              if (ctrl == 'sd') {
                let dtEND = +this.glblSvc.setDtFrmt(new Date(dt2.setDate(dt2.getDate() + 30)), this.dtF._ymd) - +this.glblSvc.setDtFrmt(new Date(), this.dtF._ymd);
                this.dpEDVal = dtEND > 0 ? new Date() : new Date(dt.setDate(dt.getDate() + 30));
              }
              else
                this.dpSDVal = new Date(dt.setDate(dt.getDate() - 30));
              this.glblSvc.onTstr('i', 'From and To periods should be in a range of 31 days. The dates has been adjusted to fit within the range.'),
                localStorage.removeItem('dtDDs');
            }
          }, 500);
        }
      }
    }
    let dtFrmt = this.pct == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onClkRdb(ctrl: string, val: any, ctrlId: string) {
    this.cnsmptnForm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
    if (ctrl == 'stateId')
      this.cnsmptnForm.get('city')?.setValue(val), this.cnsmptnForm.get('blockId')?.setValue(val), this.cnsmptnForm.get('districtId')?.setValue(val),
        $('#cityName').val(''), $('#blockName').val(''), $().val('#distrcitName');
    if (ctrl == 'blockId')
      this.cnsmptnForm.get('city')?.setValue(val), this.cnsmptnForm.get('stateId')?.setValue(val), this.cnsmptnForm.get('districtId')?.setValue(val),
        $('#cityName').val(''), $('#stateName').val(''), $().val('#distrcitName');
    if (ctrl == 'districtId')
      this.cnsmptnForm.get('city')?.setValue(val), this.cnsmptnForm.get('blockId')?.setValue(val), this.cnsmptnForm.get('stateId')?.setValue(val),
        $('#cityName').val(''), $('#blockName').val(''), $().val('#stateName');
    if (ctrl == 'city')
      this.cnsmptnForm.get('stateId')?.setValue(val), this.cnsmptnForm.get('blockId')?.setValue(val), this.cnsmptnForm.get('districtId')?.setValue(val),
        $('#stateName').val(''), $('#blockName').val(''), $().val('#distrcitName');
  }

  onReset() {
    this.cnsmptnForm.reset(); this.cnsmptnForm.get('isMatBdge')?.setValue('badge'), this.cnsmptnForm.get('isFacBdge')?.setValue('badge'),
      this.cnsmptnForm.get('productBadgeId')?.setValue(''), this.cnsmptnForm.get('storeBadgeId')?.setValue(''),
      this.cnsmptnForm.get('dpEDVal')?.setValue(this.dpEDVal), this.cnsmptnForm.get('dpSDVal')?.setValue(this.dpSDVal),
      this.cnsmptnForm.get('isLocOptn')?.setValue('state'), this.cnsmptnForm.get('pct')?.setValue('Monthly'),
      this.cnsmptnForm.get('mtrcVal')?.setValue('All issues/net utilization');
  }

  onChngPct() {
    this.isMnthChng = this.pct == 'Monthly', this.isWeekChng = this.pct == 'Weekly', this.isDayChng = this.pct == 'Daily';
  }

  GenRprt(type: string = 'g') {
    if (this.cnsmptnForm.get('storeId')?.value || this.cnsmptnForm.get('storeBadgeId')?.value) {
      let formCtrls = this.cnsmptnForm.getRawValue(); this.pcType = formCtrls.pct.toLowerCase(); this.fD = this.txtFD, this.eD = this.txtED;
      if (formCtrls.consumptionFromDate == null || formCtrls.consumptionFromDate == '' || formCtrls.consumptionFromDate == undefined)
        this.cnsmptnForm.get('consumptionFromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.cnsmptnForm.get('consumptionFromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.consumptionToDate == null || formCtrls.consumptionToDate == '' || formCtrls.consumptionToDate == undefined)
        this.cnsmptnForm.get('consumptionToDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal)
          this.cnsmptnForm.get('consumptionToDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      }
      let payLoad = this.cnsmptnForm.getRawValue();
        ['pct', 'isLocOptn', 'isMatBdge', 'isFacBdge', 'dpEDVal', 'dpSDVal', 'mtrcVal'].forEach(e => { delete payLoad[e]; }),
        payLoad.blockId = (payLoad.blockId != '' && payLoad.blockId != null && payLoad.blockId != undefined) ? this.slctdBlockId : 0,
        payLoad.districtId = (payLoad.districtId != '' && payLoad.districtId != null && payLoad.districtId != undefined) ? this.slctdDistrictId : 0,
        payLoad.productId = (payLoad.productId != '' && payLoad.productId != null && payLoad.productId != undefined) ? this.slctdPrdId : 0,
        payLoad.stateId = (payLoad.stateId != '' && payLoad.stateId != null && payLoad.stateId != undefined) ? this.slctdStateId : 0,
        payLoad.storeId = (payLoad.storeId != '' && payLoad.storeId != null && payLoad.storeId != undefined) ? this.slctdStrId : 0,
        payLoad.storeBadgeId = (payLoad.storeBadgeId != '' && payLoad.storeBadgeId != null && payLoad.storeBadgeId != undefined) ? +payLoad.storeBadgeId : 0,
        payLoad.productBadgeId = (payLoad.productBadgeId != '' && payLoad.productBadgeId != null && payLoad.productBadgeId != undefined) ? +payLoad.productBadgeId : 0,
        payLoad.consumptionTypeId = (payLoad.consumptionTypeId != '' && payLoad.consumptionTypeId != null && payLoad.consumptionTypeId != undefined) ? +payLoad.consumptionTypeId : 0;
      if (type == 'g')
        this.lstOV = [], this.lstX = [], this.lstY = [], this.dtsDDL = '', this.onGen(payLoad);
      else if (type == 'ddl' && this.dtsDDL != '')
        payLoad.consumptionFromDate = this.dtsDDL.split('...')[0], payLoad.consumptionToDate = this.dtsDDL.split('...')[1], this.GetDailyData(payLoad);
      else
        this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please choose Facility Tag/ Facility Name');
  }

  onGen(payLoad: any) {
    this.lstX = [], this.lstY = [], this.lstDtsDDL = [], this.lstMAT = [], this.lstFAC = [], this.lstLOC = [],
      this.FetchData(environment.apiCalls.post, environment.apiPaths.rprtCnsmptn, environment.apiMthds.getCnsmptnRprt.
        replace('pct', this.pcType), payLoad, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstOV = this.result?.data != null ? this.result?.data : [], this.lstOV.forEach((e, i) => {
              if (this.pcType === 'monthly')
                this.lstX.push({ label: this.GetMonthYear(e.report_date, true) }), this.lstY.push({ value: e.consumed });
              else
                this.lstX.push({ label: this.glblSvc.setDtFrmt(new Date(e.report_date), this.dtF.DMY_) }), this.lstY.push({ value: e.consumed });
            });
            if (this.lstX.length > 0 && this.lstY.length > 0)
                this.BindOVChart(this.mtrcVal + ' (All)', 'Quantity', this.lstX, this.lstY, 'fc-ov');
            this.lstMAT = [], this.lstFAC = [], this.lstLOC = [], this.lstOTs.forEach(e => { this.LoadOtherTabsData(payLoad, e); });
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  BindOVChart(xXName: string, yXName: string, lstLbls: any[], lstData: any[], divId: string, type: any = 'mscombi2d', srsName = 'Issues') {
    var typePCT = this.pct;
    FusionCharts.ready(function () {
      var salesAnlysisChart = new FusionCharts({
        type: type,
        renderAt: divId,
        width: '800',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "subCaption": "",
            "xAxisname": typePCT != 'Daily' ? '(Click on any above bar/line to see respective daily data)' : '',
            "yAxisName": yXName, // xXName,
            "numberPrefix": "",
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "usePlotGradientColor": "0",
            "showAlternateHGridColor": "0",
            "showCanvasBorder": '0',
            "showBorder": "0",
            "showPlotBorder": "0",
            // "numberSuffix": "M",
            "bgColor": "#FFFFFF",
            "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            "theme": "fusion"
          },
          "categories": [{
            "category": lstLbls
          }],
          "dataset": [{
            "seriesName": srsName,
            "showValues": "1",
            "data": lstData
          }],
        },
        events: {
          dataPlotClick: (evnt: any) => {
            if (typePCT !== 'Daily')
              localStorage.setItem('ddlCnsmptnRprt', JSON.stringify(evnt.data.categoryLabel)), document.getElementById('btnDailyDDL')?.click();
          }
        }
      }).render();
    });
  }

  GetDailyData(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.rprtCnsmptn, environment.apiMthds.getCnsmptnRprt.
        replace('pct', 'daily'), payLoad, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            let res = this.result?.data != null ? this.result?.data : [], xLst: any[] = [], yLst: any[] = [];
            res.forEach((e: any, i: any) => {
              xLst.push({ label: this.glblSvc.setDtFrmt(new Date(e.report_date), this.dtF.DMY_) }), yLst.push({ value: e.consumed });
            });
          if (this.lstX.length > 0 && this.lstY.length > 0)
            this.BindChart(this.mtrcVal + ' (All)', '', xLst, yLst, 'fc-ddl-dly');
          }
        }
      }
    });
  }

  onEE(payLoad: any) {
    let tabSlct = this.tab == 'm' ? 'material' : (this.tab == 'f' ? 'facility' : (this.tab == 'l' ? 'location' : ''));
    if (tabSlct != '') {
      Object.assign(payLoad, { email: this.usrLgnDtls.email, username: this.usrLgnDtls.userName });
      this.FetchData(environment.apiCalls.post, environment.apiPaths.rprtCnsmptn, environment.apiMthds.expCnsmptnRprt.
        replace('rrr', this.pcType.toLowerCase()).replace('ttt', tabSlct), payLoad, 1).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
    }
  }

  onSetMDC(fsType: string = 'mscombi2d', divPrntId: string, divChldId: string) {
    FusionCharts.ready(function () {
      const dtSrce = {
        "chart": {
          "caption": "",
          "subCaption": "",
          "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
          "yAxisName": "All issues/net utilization (All)",
          "numberPrefix": "",
          "divlineColor": "#999999",
          "divLineIsDashed": "1",
          "divLineDashLen": "1",
          "divLineGapLen": "1",
          "toolTipColor": "#ffffff",
          "toolTipBorderThickness": "0",
          "toolTipBgColor": "#000000",
          "toolTipBgAlpha": "80",
          "toolTipBorderRadius": "2",
          "toolTipPadding": "5",
          "usePlotGradientColor": "0",
          "showAlternateHGridColor": "0",
          "showCanvasBorder": '0',
          "showBorder": "0",
          "showPlotBorder": "0",
          // "numberSuffix": "M",
          "bgColor": "#FFFFFF",
          "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
          "exportEnabled": "1",
          "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
          "exportTargetWindow": "_self",
          "exportFileName": "bar",
          "theme": "fusion"
        },
        "categories": [{
          "category": [] // fcMulti1
        }],
        "dataset": [{
          "seriesName": "Issues",
          "showValues": "1",

          "data": [] // fcMulti7
          //  [  {  "value": "16000",  "link": "newchart-json-bar1"},{  "value": "20000",  "link": "newchart-json-bar2"},{  "value": "18000"},
          // {  "value": "19000"},{  "value": "15000"},{  "value": "21000"},{  "value": "16000"},{  "value": "20000"},
          // {  "value": "17000"},{  "value": "25000"},{  "value": "19000"},{  "value": "23000"}
          // ]
        },
        {
          "seriesName": "Returns",
          "renderAs": "mscombi2d",
          "data": [
          ]
        },
        {
          "seriesName": "Net Consumption",
          "renderAs": "line",
          "data": [] // fcLine2
          //  [{ "value": "15000" }, { "value": "16000" }, { "value": "17000" },
          // { "value": "18000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" },
          // { "value": "19000" }, { "value": "20000" }, { "value": "21000" }, { "value": "22000" }, { "value": "23000" }
          // ]
        }
        ],
        //linked
        linkeddata: [
          {
            id: "bar1",
            linkedchart: {
              "chart": {
                "caption": "",
                "subCaption": "",
                "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
                "yAxisName": "All issues/net utilization (All)",
                "numberPrefix": "",
                "divlineColor": "#999999",
                "divLineIsDashed": "1",
                "divLineDashLen": "1",
                "divLineGapLen": "1",
                "toolTipColor": "#ffffff",
                "toolTipBorderThickness": "0",
                "toolTipBgColor": "#000000",
                "toolTipBgAlpha": "80",
                "toolTipBorderRadius": "2",
                "toolTipPadding": "5",
                "usePlotGradientColor": "0",
                "showAlternateHGridColor": "0",
                "showCanvasBorder": '0',
                "showBorder": "0",
                "showPlotBorder": "0",
                // "numberSuffix": "M",
                "bgColor": "#FFFFFF",
                "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
                "exportEnabled": "1",
                "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
                "exportTargetWindow": "_self",
                "exportFileName": "bar",
                "theme": "fusion"
              },
              "categories": [{
                "category": [] // fcMulti1
              }],
              "dataset": [{
                "seriesName": "Issues",
                "showValues": "1",
                "data": [] // fcMulti8
                // [{ "value": "16000" }, { "value": "20000" }, { "value": "18000" }, { "value": "19000" },
                // { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" },
                // { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" }
                // ]
              },
              {
                "seriesName": "Returns",
                "renderAs": "mscombi2d",
                "data": [
                ]
              },
              {
                "seriesName": "Net Consumption",
                "renderAs": "line",
                "data": [] // fcLine2
              }
              ]
            }
          },
          {
            id: "bar2",
            linkedchart: {
              "chart": {
                "caption": "",
                "subCaption": "",
                "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
                "yAxisName": "All issues/net utilization (All)",
                "numberPrefix": "",
                "divlineColor": "#999999",
                "divLineIsDashed": "1",
                "divLineDashLen": "1",
                "divLineGapLen": "1",
                "toolTipColor": "#ffffff",
                "toolTipBorderThickness": "0",
                "toolTipBgColor": "#000000",
                "toolTipBgAlpha": "80",
                "toolTipBorderRadius": "2",
                "toolTipPadding": "5",
                "usePlotGradientColor": "0",
                "showAlternateHGridColor": "0",
                "showCanvasBorder": '0',
                "showBorder": "0",
                "showPlotBorder": "0",
                // "numberSuffix": "M",
                "bgColor": "#FFFFFF",
                "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
                "exportEnabled": "1",
                "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
                "exportTargetWindow": "_self",
                "exportFileName": "bar",
                "theme": "fusion"
              },
              "categories": [{
                "category": [] // fcMulti1
              }],
              "dataset": [{
                "seriesName": "Issues",
                "showValues": "1",
                "data": [] // fcMulti8
              },
              {
                "seriesName": "Returns",
                "renderAs": "mscombi2d",
                "data": [
                ]
              },
              {
                "seriesName": "Net Consumption",
                "renderAs": "line",
                "data": [] // fcLine2
              }
              ]
            }
          }
        ]
      };
      var myChart = new FusionCharts({ type: fsType, renderAt: divPrntId, width: environment.fsnChrtProps.width,
        height: environment.fsnChrtProps.height, dataFormat: environment.fsnChrtProps.format, dataSource: dtSrce }).render();
      myChart.configureLink({ type: fsType, width: environment.fsnChrtProps.width, height: environment.fsnChrtProps.height,
        id: "linked-chart", renderAt: divChldId, overlayButton: { show: false, message: "Close", bgColor: "#FFFFFF",
          borderColor: "#6957DA", font: "Source Sans Pro", fontColor: "#6957DA", fontSize: "15", bold: "0" }}, 0);
    });
  }

  onClkDDL() {
    let plotVal: any = JSON.parse(<string>localStorage.getItem('ddlCnsmptnRprt')), mnthNo = 0;
    this.dtsDDL = '';
    if (this.pct == 'Monthly')
      plotVal = plotVal?.split(' '), mnthNo = +this.lstMnths.filter(f => f.name.substring(0,3) === plotVal[0])[0].val, this. dtsDDL = this.GetMonthDates(mnthNo, plotVal[1]);
    else
      this.dtsDDL = this.GetWeekDates(new Date(plotVal));
    if (this.dtsDDL != '')
      this.GenRprt('ddl');
  }
  
  onCncl() {
    this.cnsmptnForm.reset(), this.onSetDRs(), 
    this.cnsmptnForm.get('blockId')?.setValue(null), this.cnsmptnForm.get('city')?.setValue(''),
    this.cnsmptnForm.get('districtId')?.setValue(null), this.cnsmptnForm.get('productId')?.setValue(null), 
    this.cnsmptnForm.get('stateId')?.setValue(null), this.cnsmptnForm.get('storeId')?.setValue(null),
    this.cnsmptnForm.get('consumptionTypeId')?.setValue(''), this.cnsmptnForm.get('storeBadgeId')?.setValue(''),     
    this.cnsmptnForm.get('isFacBdge')?.setValue('badge'), this.cnsmptnForm.get('isLocOptn')?.setValue('state'), 
    this.cnsmptnForm.get('pct')?.setValue('Monthly'), this.cnsmptnForm.get('mtrcVal')?.setValue('All issues/net utilization'), 
    this.cnsmptnForm.get('productBadgeId')?.setValue(''), this.cnsmptnForm.get('isMatBdge')?.setValue('badge'),
    this.cnsmptnForm.get('consumptionFromDate')?.setValue(''), this.cnsmptnForm.get('consumptionToDate')?.setValue(''),
    this.cnsmptnForm.get('receivingStoreId')?.setValue(null), this.lstX = [], this.lstY = [], this.lstDtsDDL = [], this.lstMAT = [], 
    this.lstFAC = [], this.lstLOC = [], this.lstOV = [];
  }

}