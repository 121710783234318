import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-create-transporters',
  templateUrl: './create-transporters.component.html',
  styleUrls: ['./create-transporters.component.css']
})

export class CreateTransportersComponent extends BaseComponent implements OnInit {

  isSbmtd = false; cntryLst: any[] = []; stsLst: any[] = []; dstLst: any[] = []; blkLst: any[] = []; trnsprterCrtForm =
    this.formBuilder.group({ address: [null], block: [null], carrierName: [null, Validators.required], city: [null], carrierType: ['owner'],
    contactNo: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]], country: [null, Validators.required],
      description: [null], district: [null], state: [null, Validators.required], pincode: [null], vehicleDetails: [null]
    }); usrLvl = ''; cntryId: any = ''; carrType = 'owner';

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc); this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst; });
  }

  ngOnInit(): void {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      this.cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData'))?.cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null;
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.trnsprterCrtForm.get('country')?.setValue(this.cntryId), this.trnsprterCrtForm.get('country')?.disable();
    } 
    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
  }
  
  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.trnsprterCrtForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.trnsprterCrtForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.trnsprterCrtForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.trnsprterCrtForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.trnsprterCrtForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.trnsprterCrtForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.trnsprterCrtForm.get('state')?.setValue('null'), this.trnsprterCrtForm.get('district')?.setValue('null');
    }
  }

  // stateId, districtId, blockId

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.trnsprterCrtForm.get('district')?.setValue(null),
          this.trnsprterCrtForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.trnsprterCrtForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.trnsprterCrtForm.get('district')?.enable(),
                this.trnsprterCrtForm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.trnsprterCrtForm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.trnsprterCrtForm.get('district')?.setValue('null'), this.trnsprterCrtForm.get('block')?.setValue('null'),
          this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('block')?.disable();
      else
        this.trnsprterCrtForm.get('block')?.setValue('null'), this.trnsprterCrtForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.trnsprterCrtForm.get('state')?.enable(), this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.trnsprterCrtForm.get('state')?.disable(), this.trnsprterCrtForm.get('district')?.enable(), this.trnsprterCrtForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.trnsprterCrtForm.get('state')?.disable(), this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.trnsprterCrtForm.get('state')?.disable(), this.trnsprterCrtForm.get('district')?.disable(), this.trnsprterCrtForm.get('block')?.disable();
  }

  // onNestedSlct($event: any, type: any) {
  //   let cid = '', sid = '', did = '';
  //   if (type == 'country')
  //     cid = $event.target.value;
  //   else if (type == 'state')
  //     sid = $event.target.value;
  //   else
  //     did = $event.target.value;
  //   this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.getStates.
  //       replace('ccc', cid) : (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) : environment.apiMthds.getBlocks.
  //       replace('ddd', did))), null, 2, true, 1).then(() => {
  //     if (this.result) {
  //       if (this.result.data) {
  //         if (type == 'country')
  //           this.stsLst = this.result.data != null ? this.result.data : [];
  //         else if (type == 'state')
  //           this.dstLst = this.result.data != null ? this.result.data : [];
  //         else
  //           this.blkLst = this.result.data != null ? this.result.data : [];
  //       }
  //     }
  //   });
  // }

  get fc() { return this.trnsprterCrtForm.controls; }

  onCreateTransporter() {
    this.checkFormValidations(this.trnsprterCrtForm);
    if (this.trnsprterCrtForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.trnsprterCrtForm.getRawValue();
      payLoad.block = (payLoad.block != null && payLoad.block != '') ? +payLoad.block : null, payLoad.country = +payLoad.country,
      payLoad.state = payLoad.state != null ? +payLoad.state : null, payLoad.district = (payLoad.district != null && 
      payLoad.district != '') ? +payLoad.district : null, payLoad.updatedBy = this.usrLgnDtls.userId, payLoad.createdBy = payLoad.updatedBy,
      // payLoad.createdOn = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, payLoad.updatedOn = payLoad.createdOn,
      payLoad.carrierType = payLoad.carrierType != '' && payLoad.carrierType != null ? payLoad.carrierType : null,
      payLoad.address = payLoad.address != '' && payLoad.address != null ? payLoad.address : null,
      payLoad.city = payLoad.city != '' && payLoad.city != null ? payLoad.city : null,
      payLoad.contactNo = payLoad.contactNo != '' && payLoad.contactNo != null ? payLoad.contactNo : null,
      payLoad.description = payLoad.description != '' && payLoad.description != null ? payLoad.description : null,
      payLoad.pincode = payLoad.pincode != '' && payLoad.pincode != null ? payLoad.pincode : null,
      payLoad.vehicleDetails = payLoad.vehicleDetails != '' && payLoad.vehicleDetails != null ? payLoad.vehicleDetails : null,
      payLoad.carrierName = payLoad.carrierName != '' && payLoad.carrierName != null ? payLoad.carrierName : null,

      this.FetchData(environment.apiCalls.post, environment.apiPaths.carrier, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            localStorage.setItem('trnsprtrCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message),
              this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onCancel() {
    this.trnsprterCrtForm.reset(), this.isSbmtd = false, this.trnsprterCrtForm.get('country')?.setValue(this.cntryId), this.carrType = 'owner'
    this.trnsprterCrtForm.get('carrierType')?.setValue('owner'),
    this.trnsprterCrtForm.get('country')?.disable(), this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
    this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } });
  }

}