import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.css']
})

export class AssetsListComponent extends BaseComponent implements OnInit {
  dropdownSettings = {singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
  unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true}; asetType: any[] = []; selectedTypes: any[] = []; tempDratn = false;
  asstsList: any[] = []; lstFacIds: any[] = []; astTypLst: any[] = []; astTypeMntrg: any[] = []; astTypeMntrd: any[] = []; slctdFcltyId: any;
  pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; astStatusList: any[] = []; isShowAstdtls = true;
  noRcrdsTxt = environment.TblNoRcrds; dbFacDtls = ''; assetslstfrm = this.fb.group({ mobileNumber: [null, [Validators.minLength(10), Validators.maxLength(13)]],
    asetTypeId: [''], alarms: ['0'], relationships: [''], serialNumber: [''], timeRange: ['1'], state: [null], district: [null], block: [null],
    approvalStatus: [8], storeId: [''], monitoringPoint: [''], statusId: [''], assetId: [''], assetTypes: [''], duration: [''], strName: [''], macAddress:[''],
    manufacturer: [''], assetModelId: [''], storeTags: [''], strTxtName: [''], deviceAlarmId: ['99'], errorCode: [null], errorMsg: [null],
    fromDate: [''], toDateF: [''],
  });
  frmDate: any = ''; toDate: any = ''; dratn: boolean = false; shwAlrmDrpdwn = true; isFltr = false; lstSlctdFacTags: any[] = [];
   usrLvl = ''; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; prmsns: any = null; cceMngmntLst: boolean = true; cceMngmnDtl: boolean = true;
   strId=0; enbleField = false; modelsLst: any[] = [];  manufacturersLst: any[] = []; macEnabled = false; lstSlctdStores: any[] = [];
   lstFacsTags: any[] = []; lstFacTags: any[] = []; lstFacsTag: any; lstFacsName: any; slctdFacId: any[] = []; slctdFac: any[] = [];
   ddlStngs = { singleSelection: true, idField: 'storeId', textField: 'storeName', selectAllText: 'ALL', unSelectAllText: 'ALL',
   itemsShowLimit: 3, allowSearchFilter: true }; tmpFclty : any= ''; isDbCCE = false; slctdStrId: any; fcltyNm =''; errCdeDrn: boolean = false;
   errStsDvcId: any = ''; errLst: any[] = []; frmDateF: any = new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000));
   dt2day = new Date(); ctDte: any = new Date(); endDate: any = new Date(); mnDate: any = new Date(); frDt: any; tDt: any;

   constructor(public glblSvc: GlobalService
    , public fb: FormBuilder
    , private router: Router, private route: ActivatedRoute) {
      
    super(glblSvc); this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility)});
      if(this.lstFacTags?.length >0){
        this.lstFacTags.find(e => {if(e.name.includes('GMSD')){
        this.tmpFclty = e;
        }});
        if(this.tmpFclty ==''){
          this.lstFacTags.find(e => {if(e.name.includes('SVS')){
            this.tmpFclty = e;
            }});
        }
        if(this.tmpFclty ==''){
          this.lstFacTags.find(e => {if(e.name.includes('DVS')){
            this.tmpFclty = e;
            }});
        }
        this.loadTags();

      }
     
    glblSvc.sbjctCceFacDB.subscribe(s => {
      if (s != '' && this.dbFacDtls == '')
        this.isDbCCE = true, this.dbFacDtls = s, 
        this.assetslstfrm.get('storeId')?.setValue(s.split('...')[0]), this.assetslstfrm.get('strTxtName')?.setValue(s.split('...')[1]), 
        this.slctdFcltyId = s.split('...')[0], this.assetslstfrm.get('relationships')?.setValue('1'),
        this.assetslstfrm.get('storeTags')?.setValue(''),this.slctdFac = [...[]], this.lstSlctdFacTags = [...[]], this.ngOnInit();
      if (s == '')
        this.isDbCCE = false, this.dbFacDtls = s, 
        this.assetslstfrm.get('storeId')?.setValue(''), this.assetslstfrm.get('strTxtName')?.setValue(''), 
        this.assetslstfrm.get('relationships')?.setValue('');
    });
  }

  get fgr() { return this.assetslstfrm.getRawValue(); }

  ngOnInit(): void {
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astStatusList = s.stsLst.filter((f: any) => f.statusType === 'Asset')
    }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astTypLst = s.astTypLst, this.astTypeMntrd = this.astTypLst.filter(f => f.deviceTypeId == 2),
        this.astTypeMntrg = this.astTypLst.filter(f => f.deviceTypeId == 1);
    });

    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.CCE_Management : null;
    this.prmsns.find((e: any) =>
    {
      if(e?.menuCode =='mm_cm_li')
      this.cceMngmntLst = e?.action;
      if(e?.menuCode =='mm_cm_va_ad')
      this.cceMngmnDtl = e?.action;
    });
    this.selectedTypes = this.astTypLst.filter(e => e.id != 1), this.selectedTypes.filter(s => this.asetType.push(s.id)),
    this.select2ShowHide(), this.onLocation();
    // if(this.cceMngmntLst)
    // this.LoadAssets();
    this.glblSvc.sbjctAsstStrId.subscribe((s: any) => {
      this.strId = s;
      if (this.strId > 0 && this.cceMngmntLst) {
        let str = this.lstFacIds?.find(e => e.storeId == this.strId)
        this.assetslstfrm.get('strName')?.setValue(str.storeName), this.assetslstfrm.get('storeId')?.setValue(str.storeId);
        this.assetslstfrm.get('strName')?.disable(), this.slctdFcltyId = this.strId;
        this.LoadAssets();
      }
      else if (this.cceMngmntLst) {
        this.LoadAssets();
      }
    })
  setTimeout(() => { this.assetslstfrm?.get('strTxtName')?.value !='' ? (this.assetslstfrm.get('storeTags')?.setValue(''),
    this.assetslstfrm.get('storeTags')?.disable(), this.slctdFac = [...[]], this.lstSlctdFacTags = [...[]] ): '';}, 300);
    this.getErrCdeLst();
  }

  changeFn(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    this.assetslstfrm?.get('storeId')?.value !='' ? (this.assetslstfrm.get('storeTags')?.setValue('')): this.loadTags();
    if (evnt == '' || evnt == undefined)
      this.assetslstfrm.get('storeId')?.setValue(null);
    else {
      this.assetslstfrm.get('storeTags')?.setValue(''), this.assetslstfrm.get('storeTags')?.disable();
      if (this.lstFacIds.filter(a => a.storeName == evnt).length != 0) {
        for (let s = 0; s < this.lstFacIds.length; s++) {
          if (this.lstFacIds[s].storeName == evnt)
            this.slctdStrId = this.lstFacIds[s].storeId;
        };
      }
      else
        this.slctdStrId = null, this.assetslstfrm.get('storeId')?.setValue(null);
    }
  }

  loadTags() {
    if (this.tmpFclty != '') {
      let facTag: any[] = [];
      facTag.push({ id: this.tmpFclty?.id, name: this.tmpFclty?.name });
      // this.slctdFac =[];
      this.lstSlctdFacTags.push({id: this.tmpFclty?.id, name: this.tmpFclty?.name});
      this.assetslstfrm.get('storeTags')?.setValue(this.tmpFclty?.id);
      setTimeout(() => {
        this.slctdFac = [...facTag];
      }, 300);

    }
  }
  

  onLocation() {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.assetslstfrm.get('country')?.setValue(cntryId);
       this.dmnLocation();
    }
    setTimeout(() => { this.onAS(); }, 1000);
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.assetslstfrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.assetslstfrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
          
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.assetslstfrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.assetslstfrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 
          'district', true)]).then(() => {
            this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
            this.assetslstfrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.assetslstfrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => {  this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });        
      }
        
    }
  }


  // onChngFclty(event: any, evntType: string) {
  //   let evnt = evntType == 'chng' ? event.target.value : event.value;
  //   if (evnt == '' || evnt == undefined)
  //     this.slctdFcltyId = null, this.assetslstfrm.get('storeId')?.setValue(''), this.assetslstfrm.get('strName')?.setValue('');
  //   else
  //     if (this.lstFacIds.filter(a => a.storeName == evnt).length != 0)
  //       for (let s = 0; s < this.lstFacIds.length; s++) {
  //         if (this.lstFacIds[s].storeName == evnt)
  //           this.slctdFcltyId = this.lstFacIds[s].storeId;
  //       }
  //     else
  //       this.slctdFcltyId = null, this.assetslstfrm.get('storeId')?.setValue(''), this.assetslstfrm.get('strName')?.setValue('');
  // }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else{
         if (this.lstSlctdStores.filter((f: { storeId: any; }) => f.storeId === item.id).length == 0)
            this.lstSlctdStores.push(item);
        }
      
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
     
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  get fc() { return this.assetslstfrm.controls; }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'asetTypeId') {
      if (evntType == 'os')
        this.asetType.push(item.id)
      else if (evntType == 'osa')
        this.asetType = [], this.astTypLst.filter(e => this.asetType.push(e.id))
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id)
      else
        this.asetType = [];

      if(this.asetType.length == 1){
        this.enbleField = true; this.macEnabled = false;
        // if (type == 'manufacturer') {
          this.assetslstfrm.get('assetModelId')?.setValue('')
          let slctdVendId = item.id;
          if (slctdVendId != '')
            this.LoadManufacturers( this.asetType[0]);
          else
            this.assetslstfrm.get('assetModelId')?.setValue('')
        // }
      }
      else{
        this.enbleField = false;
        this.assetslstfrm.get('manufacturer')?.setValue(''), this.assetslstfrm.get('assetModelId')?.setValue('');
      }
    }
    if (type == 'manufacturer') {
      this.macEnabled = false;
      this.assetslstfrm.get('assetModelId')?.setValue(''),
      this.assetslstfrm.get('macAddress')?.setValue('');
      let slctdVendId = item?.target?.value;
      if (slctdVendId != '')
        this.LoadModels(slctdVendId, this.asetType[0]);
      else
        this.assetslstfrm.get('assetModelId')?.setValue('')
    }
    if (type == 'astModel') {
      let astmdl = item?.target?.value;
      this.assetslstfrm.get('macAddress')?.setValue('');
      // this.macEnabled = this.enbleField && astmdl == 459 ? true : false;
      if (this.assetslstfrm.controls.asetTypeId.value[0].id == 1 && this.assetslstfrm.get('manufacturer')?.value == 58 && astmdl == 459) {
        this.macEnabled =true;
      } else {
        this.macEnabled =false;

      }
    }
  }

  LoadManufacturers(slctdAsetId: any) {
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getByasetTypId.
      replace('asetId', slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manufacturersLst = this.result.data != null ? this.result.data : [];
          if (this.manufacturersLst.length == 1){
            this.assetslstfrm.get('manufacturer')?.setValue(this.manufacturersLst[0].id);
               let slctdVendId = this.manufacturersLst[0].id; this.LoadModels(slctdVendId, slctdAsetId);
          }
          else {
            // if (!this.editFlag)
              this.assetslstfrm.get('manufacturer')?.setValue(''), this.assetslstfrm.get('assetModelId')?.setValue('');
          }
        }
      })
  }

  LoadModels(slctdVendId: any, slctdAsetId: any) {
    this.modelsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getByVendorId.
      replace('vendId', slctdVendId).replace('rrrr', slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.modelsLst = this.result.data != null ? this.result.data : [];
          if (this.modelsLst.length == 1)
            this.assetslstfrm.get('assetModelId')?.setValue(this.modelsLst[0].id);
        }
      })
  }

  onChngSNO() {
    let snVal = this.assetslstfrm.get('serialNumber')?.value;
    this.assetslstfrm.get('serialNumber')?.setValue(`${snVal}`.trim());
  }

  selectedAlaram() {
    this.errCdeDrn = false, this.assetslstfrm.get('deviceAlarmId')?.setValue('99'), this.assetslstfrm.get('errorCode')?.setValue(null), 
    this.assetslstfrm.get('errorMsg')?.setValue(null);
    if (this.assetslstfrm.get('alarms')?.value == "1")
      this.tempDratn = false, this.dratn = true;
    else if(this.assetslstfrm.get('alarms')?.value == "4")
      this.dratn = false, this.tempDratn = true, this.assetslstfrm.get('fromDate')?.setValue(''), this.assetslstfrm.get('toDateF')?.setValue('');
    else
      this.assetslstfrm.get('duration')?.setValue(''), this.assetslstfrm.get('deviceAlarmId')?.setValue('99'), this.dratn = false, 
      this.tempDratn = false, this.assetslstfrm.get('fromDate')?.setValue(this.dt2Day), this.assetslstfrm.get('toDateF')?.setValue(this.frmDateF);
  }

  LoadAssets() {
    let lstAstTypes: any = [],  fT: any[] = [], sN: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = [];
    this.asetType.filter(e => lstAstTypes.push(e));
    let payload = this.assetslstfrm.getRawValue();

    if (this.fc.mobileNumber.errors) {
      this.glblSvc.onTstr('w', 'Mobile number should be min. of 10 and max. of 13 digits only');
      return;
    }

    if (payload.alarms == "4" && this.asetType.length != 0) {
      if(!lstAstTypes.includes(1))
        lstAstTypes.push(1);
    } else {
      if (!this.asetType.includes(1)) {
        const index = lstAstTypes.indexOf(1);
        if (index > -1) {
          lstAstTypes.splice(index, 1);
        }
      }
    }
    this.lstSlctdFacTags?.forEach((e: any) => {lstFacsTags.push(e.name) }),this.lstFacsTag = lstFacsTags;
    this.lstSlctdStores?.forEach((e: any) => {lstFacsNames.push(e.storeName) }),this.lstFacsName = lstFacsNames;
    payload.stateId = payload.state == 'null' ? null : +payload.state, payload.districtId = payload.district == 'null' ? null : +payload.district,
    payload.blockId = null, payload.asetTypeId = payload.asetTypeId != '' ? +payload.asetTypeId : null, payload.macAddress = (payload.macAddress != '' && payload.macAddress != null) ?  payload.macAddress?.toUpperCase() : null,
      payload.storeId = payload.strName != '' ? this.slctdFcltyId : null, payload.monitoringPoint = null, payload.assetId = null,
      ['state', 'district', 'block', 'strName'].forEach(e=>{delete payload[e]}), payload.statusId = payload.approvalStatus != '' ? payload.approvalStatus : null,
      payload.assetTypes = lstAstTypes, payload.mobileNumber = payload.mobileNumber != '' ? payload.mobileNumber : null;
    // if (payload.alarms == "1") {
    //   switch (payload.duration) {
    //     case '':
    //       this.frmDate = this.dateConverterTo(new Date()), this.toDate = this.dateConverterTo(new Date());
    //       break;
    //     case '7':
    //       this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
    //       break;
    //     case '30':
    //       this.frmDate = this.dateConverterTo(new Date(new Date().getTime() - (29 * 24 * 60 * 60 * 1000))), this.toDate = this.dateConverterTo(new Date());
    //       break;
    //     default:
    //       this.frmDate = null, this.toDate = null;
    //   }
    // }
    // else {
    //   this.frmDate = null, this.toDate = null;
    // }

    if(payload.alarms != '4')
        payload.timeRange = null;

    if (payload.alarms == "1") 
      payload.fromDate = payload.fromDate != '' ? this.dateConverterTo(payload.fromDate) : null; 
    else 
      payload.fromDate = null, payload.toDate = null;

    payload.toDate = payload.toDateF != '' ? this.dateConverterTo(payload.toDateF) : null;
    payload.manufacturer = payload.manufacturer != '' ? payload.manufacturer : null, payload.assetModel = payload.assetModelId != '' ? payload.assetModelId: null,
    payload.storeTags = payload.storeTags.length != 0 && payload.storeTags != null ?
      this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payload.storeTags = fT;
    delete payload.duration, delete payload.assetModelId, delete payload.toDateF;
    if(payload.manufacturer)
    payload.manufacturer = this.manufacturersLst.find(a => a.id== payload.manufacturer)?.name ?? null;
    if(payload.assetModel)
    payload.assetModel = this.modelsLst.find(e => e.id == payload.assetModel )?.modelName ?? null;
    // payload.storeId =  this.slctdStrId ?? null;
    if (this.assetslstfrm?.get('strTxtName')?.value !='')
      payload.storeId = this.dbFacDtls.split('...')[0];
    else 
      payload.storeId =  (this.assetslstfrm?.get('storeTags')?.value =='' || 
        this.assetslstfrm?.get('storeTags')?.value ==null ) ? this.slctdStrId : null;

        payload.errorMsg = payload.errorCode != null ? payload.errorCode?.split('-')[1]: null, 
        payload.errorCode = payload.errorCode != null ? payload.errorCode?.split('-')[0]: null;  

    this.asstsList = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.getassetdetailsbyfilter.
      replace('pageNo', (this.crntPage - 1).toString()).replace('pageSize', this.pageSize.toString()), payload, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (this.result.status == 'OK') {
              this.asstsList = this.result?.data?.assetsList != null ? this.result?.data?.assetsList : [],
                this.totalItems = this.result.data?.totalCount ?? 0,
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            }
            else{
              if (this.router.url.includes('asst-asts')) {
                this.glblSvc.onTstr('i', this.result.message);
              }
            }
          }
          else{
            if (this.router.url.includes('asst-asts')) {
              this.glblSvc.onTstr('i', this.result.message);
            }
          }
        }
      })
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadAssets();
  }

  onChngReln(event: any) {
    if (event.target.value == '2')
      this.shwAlrmDrpdwn = false;
    else
      this.shwAlrmDrpdwn = true;
  }

  onClick(assetId: any) {
    if(this.cceMngmnDtl){
    if (this.router.url.includes('asst-asts')) {
        if (assetId > 0)
          this.glblSvc.sbjctAssetId.next(assetId), this.router.navigate(['home/asst-ast-dtls']);
    }
    }
  }

  onReset() {
    this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.slctdFacId= [], this.slctdFac = [...[]]; 
      this.assetslstfrm.reset(); this.enbleField = false, this.macEnabled = false, this.slctdStrId = '';
    this.asetType = [], this.assetslstfrm.get('asetTypeId')?.setValue(''), this.assetslstfrm.get('relationships')?.setValue(''),
      this.assetslstfrm.get('approvalStatus')?.setValue(''), this.assetslstfrm.get('alarms')?.setValue('0'), this.slctdFcltyId = null,
      this.dratn = false, this.tempDratn = false, this.assetslstfrm.get('storeTags')?.setValue(''), this.assetslstfrm.get('storeId')?.setValue([]);
      setTimeout(() =>{
         this.onLocation(), this.onAS();
         if (this.strId > 0 && this.cceMngmntLst) {
          let str = this.lstFacIds?.find(e => e.storeId == this.strId)
          this.assetslstfrm.get('strName')?.setValue(str.storeName), this.assetslstfrm.get('storeId')?.setValue(str.storeId);
          this.assetslstfrm.get('strName')?.disable(), this.slctdFcltyId = this.strId;
          this.LoadAssets();
        }
        else if (this.cceMngmntLst) {
          this.loadTags(), this.LoadAssets();
        }
      }, 500)
      
  }

  // onClkRdb(ctrl: string) {
  //   if(ctrl == 'storeTags')
  //   this.fc?.storeIds.setValue([]), this.fc?.storeIds?.clearValidators(),
  //   this.lstSlctdStores = [...[]];
  //   else 
  //   this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(),
  //   this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = [...[]];
  // }

  exportAstLst() {
    if (this.fc.mobileNumber.errors) {
      this.glblSvc.onTstr('w', 'Mobile number should be min. of 10 and max. of 13 digits only');
      return;
    }
    let payload = this.assetslstfrm.getRawValue(), fT: any[] = [], sN: any[] = []; payload.manufacturer = payload.manufacturer != '' ? payload.manufacturer : null, payload.assetModel = payload.assetModelId != '' ? payload.assetModelId: null,
    payload.asetTypeId = payload.asetTypeId != '' ? +payload.asetTypeId : null, payload.macAddress = (payload.macAddress != '' && payload.macAddress != null) ?  payload.macAddress?.toUpperCase() : null,
    payload.storeId = payload.strName != '' ? this.slctdFcltyId : null, payload.monitoringPoint = null,
      payload.statusId = payload.approvalStatus != '' ? payload.approvalStatus : null, payload.assetId = null, 
      payload.assetTypes = this.asetType; payload.stateId = payload.state == 'null' ? null : +payload.state, 
      payload.districtId = payload.district == 'null' ? null : +payload.district, payload.blockId = null,
      payload.storeTags = payload.storeTags.length != 0 && payload.storeTags != null ?
      this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payload.storeTags = fT;
      ['state', 'district', 'block', 'strName', 'assetModelId'].forEach(e=>{delete payload[e]}); payload.mobileNumber = payload.mobileNumber != '' ? 
        payload.mobileNumber : null;
        if(payload.manufacturer)
        payload.manufacturer = this.manufacturersLst.find(a => a.id== payload.manufacturer)?.name ?? null;
        if(payload.assetModel)
        payload.assetModel = this.modelsLst.find(e => e.id == payload.assetModel )?.modelName ?? null;
        if (this.assetslstfrm?.get('strTxtName')?.value !='') 
          payload.storeId = this.dbFacDtls.split('...')[0];
        else 
          payload.storeId =  (this.assetslstfrm?.get('storeTags')?.value =='' || 
            this.assetslstfrm?.get('storeTags')?.value ==null ) ? this.slctdStrId : null;
            
            payload.errorMsg = payload.errorCode != null ? payload.errorCode?.split('-')[1]: null, 
            payload.errorCode = payload.errorCode != null ? payload.errorCode?.split('-')[0]: null;

    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.exprtAstLst, payload, 3, true, 3).then(() => {
      if (this.result) {
        if (this.result.returnCode == 1 && this.result.status == "OK")
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    })
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.assetslstfrm.get('district')?.setValue(null),
          this.assetslstfrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.assetslstfrm.get('block')?.setValue(null);

      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.assetslstfrm.get('district')?.enable(),
                this.assetslstfrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.assetslstfrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.assetslstfrm.get('district')?.setValue('null'), this.assetslstfrm.get('block')?.setValue('null'),
          this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('block')?.disable();
      else
        this.assetslstfrm.get('block')?.setValue('null'), this.assetslstfrm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
    this.assetslstfrm.get('state')?.enable(), this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('block')?.disable();
  if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
    this.assetslstfrm.get('state')?.disable(), this.assetslstfrm.get('district')?.enable(), this.assetslstfrm.get('block')?.disable();
  if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
     this.assetslstfrm.get('state')?.disable(), this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('block')?.enable();
  if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
    this.assetslstfrm.get('state')?.disable(), this.assetslstfrm.get('district')?.disable(), this.assetslstfrm.get('block')?.disable();
  }

  onChngDrnType(evnt: any) {    
    if (evnt.target.value == '2' || evnt.target.value == '3') {
      this.errCdeDrn = true, this.errStsDvcId = evnt.target.value;
      if (this.errStsDvcId == '2')
        this.assetslstfrm.get('errorCode')?.setValue('99-All');
      else 
        this.assetslstfrm.get('errorCode')?.setValue('99-All');
    }      
    else 
      this.errCdeDrn = false, this.errStsDvcId = '', this.assetslstfrm.get('errorCode')?.setValue(null), this.assetslstfrm.get('errorMsg')?.setValue(null); 
    this.assetslstfrm.get('deviceAlarmId')?.setValue(evnt.target.value);
  }

  getErrCdeLst() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.asstsErr, environment.apiMthds.asstsErrLst.replace('kkk', ''), null, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.returnCode == 1 && this.result.status == "OK")
          this.errLst = this.result.data;
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    })
  }


  onChngDT(dtVal: any, dd: string) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
    if (dd == 'sD' && dtVal != null) {
      this.mnDate = this.frmDateF;
      let dt = new Date(this.frmDateF), dt1 = new Date(dt.setDate(dt.getDate() + 30));
      //  dt1: any = this.dateConverterTo(new Date(dt.getTime() + (89 * 24 * 60 * 60 * 1000)));

      if (Date.parse(this.glblSvc.setDtFrmt(this.dt2day, this.dtF.ymd)) > (Date.parse(this.glblSvc.setDtFrmt(dt1, this.dtF.ymd)))) {
        this.endDate = this.toDate = dt1;
        setTimeout(() => { this.assetslstfrm.get('toDateF')?.setValue(this.toDate); }, 500);
        dtVal = null;
      }
      else {
        this.endDate = this.dt2day;
        setTimeout(() => {
          dtVal = null; this.toDate = this.dt2day;
          this.assetslstfrm.get('toDateF')?.setValue(new Date());
        }, 500);
      }
    }
  }

  clearBSDate(ctrl: string) {
    this.assetslstfrm.get(ctrl)?.setValue('');
  }

  firmWareDwn(){
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.getFirmWareReport.replace('nnn', this.usrLgnDtls.email), null, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.returnCode == 1 && this.result.status == "OK")
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    })
  }

}