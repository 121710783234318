import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-abnormal-stock-locations',
  templateUrl: './abnormal-stock-locations.component.html',
  styleUrls: ['./abnormal-stock-locations.component.css']
})
export class AbnormalStockLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
