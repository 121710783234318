<div class="modal-header">
    <button type="button" class="close btn btn-light float-end" (click)="actMdl.close()">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body">
    <div class="card border mt-3">
        <div class="card-body p-2">
            <div class="container pt-4 pb-4">
                <p>This report is to be filled in by authorized staff, ratified by the Store Manager or the EPI Manager, and forwarded to the 
                    MoHFW, New Delhi within two days of vaccine arrival. Use one report for each vaccine in the shipment. </p>
                <h5 class="text-center mb-3">{{lstVcine?.consigneeName}}</h5>
                <h5 class="text-center mb-3">Vaccine Arrival Report</h5>
                <div class="table-responsive  custom_table">
                    <div class="row">
                        <div class="col-xl-6">
                            <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;">
                                <tr>
                                    <td colspan="5" width="50%"><b>COUNTRY :</b> </td>
                                    <td class="text-center"><span>India</span> </td>
                                </tr>
                                <tr>
                                    <td colspan="5" width="50%"><b>REPORT No.</b></td>
                                    <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="refNo"></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-xl-6 mt-4 pt-2">
                            <table class="table table-bordered mt-4" width="100%" style="border-color: 5px solid #bebebe !important;">
                                <tr>
                                    <td colspan="5" width="50%"><span class="fw-bold"><b>Date of Report:</b></span></td>
                                    <td><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;">
                        <tr>
                            <td colspan="5" width="50%"><b>Place, date and time of inspection</b></td>
                            <td><b>Name of cold store, date and time vaccines entered into cold store</b> </td>
                        </tr>
                        <tr>
                            <td colspan="5" width="50%">
                                <div class="row">
                                    <div class="col-xl-5 mt-2">
                                        <input type="text" class="form-control form-control-sm" placeholder="Place" [(ngModel)]="plce">
                                    </div>
                                    <div class="col-xl-4 mt-2">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                            [(ngModel)]="inspDte" [maxDate]="dt2day">
                                    </div>
                                    <div class="col-xl-3 mt-2">
                                        <input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="tme">
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-xl-6 mt-2">{{lstVcine?.storeLocation}}</div>
                                    <div class="col-xl-3 mt-2">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                            [(ngModel)]="toDate" [maxDate]="dt2day">
                                    </div>
                                    <div class="col-xl-3 mt-2"><input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="strTme"></div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART I - ADVANCE NOTICE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="15%"><b>MAIN DOCUMENTS</b></th>
                                <th width="12%"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div></th>
                                <th width="15%"><b>Pre-advice</b></th>
                                <th width="15%"><b>Copy Airway Bill (AWB)</b></th>
                                <th width="15%"><b>Copy of Packing List</b></th>
                                <th width="13%"><b>Copy of Invoice</b></th>
                                <th width="15%"><b>Copy of Release Certificate</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td><b>Date received by Consignee</b></td>
                            <td class="text-center">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="dteRcvd" [maxDate]="dt2day">
                            </td>
                            <td colspan="5"></td>
                        </tr>
                        <tr>
                            <td><b>Shipping Notification</b></td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpDate"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="preAdvce"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn1"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn2"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn3"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn4"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%"><b>List other documents (If requested)</b></td>
                            <td colspan="6"><input type="text" class="form-control form-control-sm" [(ngModel)]="lstOthDcm"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II - FLIGHT ARRIVAL DETAILS</h6>
                <div class="table-responsive custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th rowspan="2"><b>AWB Number</b></th>
                                <th rowspan="2"><b>Airport of destination</b></th>
                                <th rowspan="2"><b>Flight No</b></th>
                                <th colspan="2" class="text-center"><b>ETA as per notification</b></th>
                                <th colspan="2" class="text-center"><b>Actual time of arrival</b></th>
                            </tr>
                            <tr>
                                <th><b>Date</b></th>
                                <th><b>Time</b></th>
                                <th><b>Date</b></th>
                                <th><b>Time</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><input type="text" class="form-control form-control-sm" [(ngModel)]="awbNum"></td>
                                <td><input type="text" class="form-control form-control-sm" [(ngModel)]="arprtDsntn"></td>
                                <td><input type="text" class="form-control form-control-sm" [(ngModel)]="fltNo"></td>
                                <td>
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                        [(ngModel)]="dteEAPN" [maxDate]="dt2day">
                                </td>
                                <td><input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="tmeEAPN"></td>
                                <td>
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                        [(ngModel)]="dteATA" [maxDate]="dt2day">
                                </td>
                                <td><input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="tmeATA"></td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-center"><b>NAME OF CLEARING AGENT:</b></td>
                                <td colspan="2" class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="clrAgnt"></td>
                                <td colspan="2" class="text-center"><b>ON BEHALF OF:</b></td>
                                <td colspan="2" class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="onBEHALF"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART III - DETAILS OF VACCINE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th colspan="2"><b>Supply order No.</b></th>
                                <th><b>Consignee</b></th>
                                <th colspan="2"><b>Vaccine Description (Type and doses/vial)</b></th>
                                <th colspan="2"><b>Manufacturer</b></th>
                                <th><b>Country</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td class="text-center" colspan="2">{{lstVcine?.supplyOrderNo}}</td>
                            <td class="text-center">{{lstVcine?.consigneeName}}</td>
                            <td class="text-center" colspan="2">{{lstVcine?.productName}}</td>
                            <td class="text-center" colspan="2">{{lstVcine?.manufacturerName}}</td>
                            <td class="text-center">{{lstVcine?.country}}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="text-center"><b>Vaccine</b></td>
                            <td colspan="4" class="text-center"><b>Diluent/Droppers</b></td>
                        </tr>
                        <tr>
                            <th class="text-center"><b>Batch Number</b></th>
                            <th class="text-center"><b>Number of boxes</b></th>
                            <th class="text-center"><b>Number of Vials</b></th>
                            <th class="text-center"><b>Expiry Date</b></th>
                            <th class="text-center"><b>Batch Number</b></th>
                            <th class="text-center"><b>Number of boxes</b></th>
                            <th class="text-center"><b>Number of units</b></th>
                            <th class="text-center"><b>Expiry Date</b></th>
                        </tr>
                        <tr *ngFor="let i of lstBtch">
                            <td class="text-center">{{i.batchNo}}</td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="i.noOfBoxsVcn"></td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="i.noOfVials"></td>
                            <td class="text-center">{{i.expDate | date: 'dd-MM-yyyy'}}</td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="i.dilutBtchNo"></td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="i.dilutNoOfBoxs"></td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="i.dilutNoOfUnits"></td>
                            <!-- <td><input type="text" class="form-control form-control-sm" [(ngModel)]="i.dilutexpDte"></td> -->
                            <td>
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="i.dilutexpDte" [minDate]="dt2day">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5">&nbsp;</td>
                            <td class="text-center"><b>Yes</b></td>
                            <td class="text-center"><b>No</b></td>
                            <td width="20%"><strong>Comments</strong></td>
                        </tr>
                        <tr>
                            <td class="text-center" colspan="5"><b>Was quantity received as per shipping notification?</b></td>
                            <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault" name="qntyRcvd" value="Yes" [(ngModel)]="qntyRcvd"></td>
                            <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault1" name="qntyRcvd" value="No" [(ngModel)]="qntyRcvd"></td>
                            <td class="text-center" width="20%"><input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="coment"></td>
                        </tr>
                        <tr>
                            <td class="text-center" colspan="5"><b>If not, were details of short-shipment provided prior to vaccine arrival?</b></td>
                            <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault2" name="vcneArvl" value="Yes" [(ngModel)]="vcneArvl"></td>
                            <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault3" name="vcneArvl" value="No" [(ngModel)]="vcneArvl"></td>
                            <td class="text-center" width="20%"><input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="coment1"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART IV - DOCUMENTS ACCOMPANYING THE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="20%" class="text-center"><b>Invoice</b></th>
                                <th width="20%" class="text-center"><b>Packing List</b></th>
                                <th width="20%" class="text-center"><b>Release Certificate</b></th>
                                <th width="20%" class="text-center"><b>Vaccine Arrival Report</b></th>
                                <th width="20%" class="text-center"><b>Other</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment1">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment2">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment3">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment4">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="othr"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART V - STATUS OF SHIPPING INDICATORS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <tr>
                            <td colspan="2">
                                <b>Total number of boxes inspected</b>
                            </td>
                            <td colspan="9"><input type="text" class="form-control form-control-sm" [(ngModel)]="boxInspctd"></td>
                        </tr>
                        <tr>
                            <td colspan="2"><b>Coolant type</b></td>
                            <td colspan="9">&nbsp;
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="Dry Ice"
                                        name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="dryIce" [attr.disabled]="noColnt !='' ? true : null">
                                    <label class="form-check-label" for="inlineRadio1">Dry Ice</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="Ice Pack"
                                        name="inlineRadioOptions" id="inlineRadio2" [(ngModel)]="icePack" [attr.disabled]="noColnt !='' ? true : null">
                                    <label class="form-check-label" for="inlineRadio2">Ice Pack</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox" value="No Coolant"
                                        name="inlineRadioOptions" id="inlineRadio3" [(ngModel)]="noColnt" [attr.disabled]="dryIce !='' || icePack !='' ? true : null">
                                    <label class="form-check-label" for="inlineRadio3">No
                                        Coolant</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"><b>Temperature Monitors Present</b></td>
                            <td colspan="8">&nbsp;
                                <div class="form-check form-check-inline">
                                    <input  class="form-check-input" type="checkbox" value="VVM"
                                        name="inlineRadioOptions1" [(ngModel)]="vmm">
                                    <label class="form-check-label" for="inlineRadio1">VVM</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input  class="form-check-input" type="checkbox" value="Cold Chain Card"
                                        name="inlineRadioOptions1" [(ngModel)]="cldCain">
                                    <label class="form-check-label" for="inlineRadio2">Cold Chain
                                        Card</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input  class="form-check-input" type="checkbox" value="Electronic device"
                                        name="inlineRadioOptions1" [(ngModel)]="elctrcDevce">
                                    <label class="form-check-label" for="inlineRadio3">Electronic
                                        device</label>
                                </div>
                            </td>
                            <td>
                                <div class="row g-3 align-items-center">
                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label fw-bold"><b>Type</b></label>
                                    </div>
                                    <div class="col-auto">
                                        <input type="text" id="inputPassword6" class="form-control"
                                            aria-describedby="passwordHelpInline" [(ngModel)]="typ">
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="11"><b>PROVIDE BELOW DETAILS OF STATUS <span
                                    style="text-decoration: underline;">ONLY</span> WHEN PROBLEMS ARE
                                OBSERVED</b>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="11" class="text-center"><b>(In addition fill in ALARM REPORTING FORM if there are any ALARMS in
                                electronic devices):</b>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2"><b>Box No</b> .</td>
                            <td rowspan="2"><b>Batch No.</b></td>
                            <td colspan="4" class="text-center"><b>Alarm in electronic device</b> </td>
                            <td colspan="4" class="text-center"><b>Cold Chain monitor</b> </td>
                            <td rowspan="2" class="text-center"><b>Date/time of inspection</b></td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>>=45&deg;C</b></td>
                            <td class="text-center"><b>>=30&deg;C</b></td>
                            <td class="text-center"><b>>=10&deg;C</b></td>
                            <td class="text-center"><b>&lt;=0.5&deg;C</b></td>
                            <td class="text-center"><b>A</b></td>
                            <td class="text-center"><b>B</b></td>
                            <td class="text-center"><b>C</b></td>
                            <td class="text-center"><b>b</b></td>
                        </tr>
                        <tbody *ngIf="lstBtch.length >0">
                            <tr *ngFor="let x of lstBtch">
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="x.boxNo"></td>
                                <td class="text-center">{{x?.batchNo}}</td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="x.tmp45"></td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="x.tmp30"></td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="x.tmp10"></td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="x.tmp5"></td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">
                                    <div class="row">
                                        <div class="col-xl-12 mt-2">
                                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                                [(ngModel)]="x.almDte" [maxDate]="dt2day">
                                        </div>
                                        <div class="col-xl-12 mt-2">
                                            <input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="x.almTme">
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART VI - GENERAL CONDITIONS OF SHIPMENT</h6>
                <div class="table-responsive  custom_table w-100">
                    <table class="table table-bordered">
                        <tr>
                            <td width="50%"><b>What was the condition of boxes on arrival?</b></td>
                            <td colspan="10" class="text-center">
                                <input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="boxArvl">
                            </td>
                        </tr>
                        <tr>
                            <td><b>Were necessary labels attached to shipping boxes?</b>
                            </td>
                            <td colspan="10" class="text-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineCheckbox1"
                                        value="Yes" [(ngModel)]="shipBox" name="shpngBox">
                                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineCheckbox2"
                                        value="No" [(ngModel)]="shipBox" name="shpngBox">
                                    <label class="form-check-label" for="inlineCheckbox2">No</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Other comments including description of alarms in
                                electronic devices:</b></td>
                            <td colspan="10"><input type="text" class="form-control form-control-sm" [(ngModel)]="elctrncDvc"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART VII - NAME AND SIGNATURE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Authorised Inspection Supervisor</b></td>
                            <td class="text-center"><b>Date</b></td>
                            <td class="text-center"><b>Store Manager/SEPIO</b></td>
                            <td class="text-center"><b>Date</b></td>
                        </tr>
                        <tr>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="suprvsior"></td>
                            <td class="text-center">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="insptnDte" [maxDate]="dt2day">
                            </td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="sepio"></td>
                            <td class="text-center">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="strMngrDte" [maxDate]="dt2day">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="float-center text-center">
                                    <b>For Procurement Agency office use only</b> <br />
                                </div>
                                <div class="float-start mt-4">
                                    Date received by the office ______________________________
                                </div>
                                <div class="float-end mt-4">
                                    Contact person________________________________
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="container pt-4 pb-4" id="tblExl" hidden>
                <p>This report is to be filled in by authorized staff, ratified by the Store Manager or the EPI Manager, and forwarded to the 
                    MoHFW, New Delhi within two days of vaccine arrival. Use one report for each vaccine in the shipment.</p>
                <h5 class="text-center mb-3 mt-2">{{lstVcine?.consigneeName}}</h5>
                <h5 class="text-center mb-3">Vaccine Arrival Report</h5>
                <div class="table-responsive  custom_table">
                    <!-- <div class="row">
                        <div class="col-xl-6"> -->
                            <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;" border="1">
                                <tr width="50%">
                                    <td colspan="5"><b>COUNTRY :</b></td>
                                    <td class="text-center"><span>India</span> </td>
                                </tr>
                                <tr>
                                    <td colspan="5" width="25%"><b>REPORT No.</b></td>
                                    <td class="text-center" width="25%">{{refNo}}</td>
                                    <td colspan="5" width="25%"><b class="fw-bold">Date of Report :</b></td>
                                    <td width="25%"><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table>
                        <!-- </div>
                        <div class="col-xl-6 mt-4 pt-2"> -->
                            <!-- <table class="table table-bordered mt-4" width="50%" style="border-color: 5px solid #bebebe !important;" border="1">
                                <tr>
                                    <td colspan="5" width="50%"><span class="fw-bold">Date of Report :</span></td>
                                    <td><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table> -->
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1" width="100%" style="border-color: 5px solid #bebebe !important;">
                        <tr>
                            <td colspan="5" width="50%"><b>Place, date and time of inspection</b></td>
                            <td><b>Name of cold store, date and time vaccines entered into cold store</b> </td>
                        </tr>
                        <tr>
                            <td colspan="5" width="50%">
                                <div class="row">
                                    <span>{{plce}}</span>&nbsp;<span>{{inspDte | date: 'dd-MM-yyyy'}}</span>&nbsp;<span>{{tme}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-xl-12">{{lstVcine?.storeLocation}}</div>
                                    <div class="col-xl-6 mt-2"> <span>{{toDate | date: 'dd-MM-yyyy'}}</span>&nbsp;<span>{{strTme}}</span></div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART I - ADVANCE NOTICE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th width="15%"><b>MAIN DOCUMENTS</b></th>
                                <th width="12%"><div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div></th>
                                <th width="15%"><b>Pre-advice</b></th>
                                <th width="15%"><b>Copy Airway Bill (AWB)</b></th>
                                <th width="15%"><b>Copy of Packing List</b></th>
                                <th width="13%"><b>Copy of Invoice</b></th>
                                <th width="15%"><b>Copy of Release Certificate</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td><b>Date received by Consignee</b></td>
                            <td class="text-center">{{dteRcvd | date: 'dd-MM-yyyy'}}</td>
                            <td colspan="5"></td>
                        </tr>
                        <tr>
                            <td><b>Shipping Notification</b></td>
                            <td class="text-center">{{shpDate}}</td>
                            <td class="text-center">{{preAdvce}}</td>
                            <td class="text-center">{{shpngNtfcn1}}</td>
                            <td class="text-center">{{shpngNtfcn2}}</td>
                            <td class="text-center">{{shpngNtfcn3}}</td>
                            <td class="text-center">{{shpngNtfcn4}}</td>
                        </tr>
                        <tr>
                            <td width="30%"><b>List other documents (If requested)</b></td>
                            <td colspan="6">{{lstOthDcm}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II - FLIGHT ARRIVAL DETAILS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th rowspan="2">AWB Number</th>
                                <th rowspan="2">Airport of destination</th>
                                <th rowspan="2">Flight No</th>
                                <th colspan="2" class="text-center">ETA as per notification</th>
                                <th colspan="2" class="text-center">Actual time of arrival</th>
                            </tr>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Date</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">{{awbNum}}</td>
                                <td class="text-center">{{arprtDsntn}}</td>
                                <td class="text-center">{{fltNo}}</td>
                                <td class="text-center">{{dteEAPN | date: 'dd-MM-yyyy'}}</td>
                                <td class="text-center">{{tmeEAPN}}</td>
                                <td class="text-center">{{dteATA | date: 'dd-MM-yyyy'}}</td>
                                <td class="text-center">{{tmeATA}}</td>
                            </tr>
                            <tr>
                                <td class="text-center" colspan="2"><b>NAME OF CLEARING AGENT:</b></td>
                                <td class="text-center" colspan="2">{{clrAgnt}}</td>
                                <td class="text-center" colspan="2"><b>ON BEHALF OF:</b></td>
                                <td class="text-center" colspan="2">{{onBEHALF}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART III - DETAILS OF VACCINE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th colspan="2">Supply order No.</th>
                                <th>Consignee</th>
                                <th colspan="2">Vaccine Description (Type and doses/vial)</th>
                                <th colspan="2">Manufacturer</th>
                                <th>Country</th>
                            </tr>
                        </thead>
                        <tr>
                            <td class="text-center" colspan="2">{{lstVcine?.supplyOrderNo}}</td>
                            <td class="text-center">{{lstVcine?.consigneeName}}</td>
                            <td class="text-center" colspan="2">{{lstVcine?.productName}}</td>
                            <td class="text-center" colspan="2">{{lstVcine?.manufacturerName}}</td>
                            <td class="text-center">{{lstVcine?.country}}</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="text-center"><b>Vaccine</b></td>
                            <td colspan="4" class="text-center"><b>Diluent/Droppers</b></td>
                        </tr>
                        <tr>
                            <th class="text-center"><b>Batch Number</b></th>
                            <th class="text-center"><b>Number of boxes</b></th>
                            <th class="text-center"><b>Number of Vials</b></th>
                            <th class="text-center"><b>Expiry Date</b></th>
                            <th class="text-center"><b>Batch Number</b></th>
                            <th class="text-center"><b>Number of boxes</b></th>
                            <th class="text-center"><b>Number of units</b></th>
                            <th class="text-center"><b>Expiry Date</b></th>
                        </tr>
                        <tr *ngFor="let i of lstBtch">
                            <td class="text-center">{{i.batchNo}}</td>
                            <td class="text-center">{{i?.noOfBoxsVcn}}</td>
                            <td class="text-center">{{i.noOfVials}}</td>
                            <td class="text-center">{{i.expDate | date: 'dd-MM-yyyy'}}</td>
                            <td class="text-center">{{i.dilutBtchNo}}</td>
                            <td class="text-center">{{i.dilutNoOfBoxs}}</td>
                            <td class="text-center">{{i.dilutNoOfUnits}}</td>
                            <td class="text-center">{{i.dilutexpDte | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="5">&nbsp;</td>
                            <td class="text-center"><b>Yes</b></td>
                            <td class="text-center"><b>No</b></td>
                            <td width="20%"><b>Comments</b></td>
                        </tr>
                        <tr>
                            <td colspan="5"><b>Was quantity received as per shipping notification?</b></td>
                            <td class="text-center">{{qntyRcvd == 'Yes' ? qntyRcvd : ''}}</td>
                            <td class="text-center">{{qntyRcvd == 'No' ? qntyRcvd : ''}}</td>
                            <td width="20%">{{coment}}</td>
                        </tr>
                        <tr>
                            <td colspan="5"><b>If not, were details of short-shipment provided prior to
                                vaccine arrival?</b></td>
                            <td class="text-center">{{vcneArvl == 'Yes' ? vcneArvl : ''}}</td>
                            <td class="text-center">{{vcneArvl == 'No' ? vcneArvl : ''}}</td>
                            <td width="20%">{{coment1}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART IV - DOCUMENTS ACCOMPANYING THE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th width="20%">Invoice</th>
                                <th width="20%">Packing List</th>
                                <th width="20%">Release Certificate</th>
                                <th width="20%">Vaccine Arrival Report</th>
                                <th width="20%">Other</th>
                            </tr>
                        </thead>
                        <tr>
                            <td class="text-center">{{shipment1}}</td>
                            <td class="text-center">{{shipment2}}</td>
                            <td class="text-center">{{shipment3}}</td>
                            <td class="text-center">{{shipment4}}</td>
                            <td class="text-center">{{othr}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART V - STATUS OF SHIPPING INDICATORS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <tr>
                            <td class="text-center" colspan="2"><b>Total number of boxes inspected</b></td>
                            <td colspan="9">{{boxInspctd}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center"><b>Coolant type</b></td>
                            <td colspan="9">
                                <span *ngIf="dryIce != ''">Dry Ice</span><br><span *ngIf="icePack !=''">Ice Pack</span><br><span *ngIf="noColnt !=''">No Coolant</span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="text-center"><b>Temperature Monitors Present</b></td>
                            <td colspan="8">
                               <span *ngIf="vmm != ''">VVM</span> <br><span *ngIf="cldCain !=''">Cold Chain Card</span><br><span *ngIf="elctrcDevce !=''">Electronic device</span>
                            </td>
                            <td>
                                <div class="row g-3 align-items-center">
                                    <div class="col-auto">
                                        <label for="inputPassword6" class="col-form-label fw-bold"><b>Type</b></label>
                                    </div>
                                    <div class="col-auto">{{typ}}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="11"><b>
                                PROVIDE BELOW DETAILS OF STATUS <span
                                    style="text-decoration: underline;">ONLY</span> WHEN PROBLEMS ARE
                                OBSERVED</b>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="11" class="text-center"><b>
                                (In addition fill in ALARM REPORTING FORM if there are any ALARMS in
                                electronic devices):</b>
                            </td>
                        </tr>
                        <tr>
                            <td rowspan="2"><b>Box No</b> .</td>
                            <td rowspan="2"><b>Batch No.</b></td>
                            <td colspan="4" class="text-center"><b>Alarm in electronic device</b> </td>
                            <td colspan="4" class="text-center"><b>Cold Chain monitor</b> </td>
                            <td rowspan="2" class="text-center"><b>Date/time of inspection</b></td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>>=45&deg;C</b></td>
                            <td class="text-center"><b>>=30&deg;C</b></td>
                            <td class="text-center"><b>>=10&deg;C</b></td>
                            <td class="text-center"><b>&lt;=0.5&deg;C</b></td>
                            <td class="text-center"><b>A</b></td>
                            <td class="text-center"><b>B</b></td>
                            <td class="text-center"><b>C</b></td>
                            <td class="text-center"><b>b</b></td>
                        </tr>
                        <tbody>
                            <tr *ngFor="let x of lstBtch">
                                <td class="text-center">{{x.boxNo}}</td>
                                <td class="text-center">{{x.batchNo}}</td>
                                <td class="text-center">{{x.tmp45}}</td>
                                <td class="text-center">{{x.tmp30}}</td>
                                <td class="text-center">{{x.tmp10}}</td>
                                <td class="text-center">{{x.tmp5}}</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center">&nbsp;</td>
                                <td class="text-center"><span>{{x.almDte | date: 'dd-MM-yyyy'}}</span><br><span>{{x.almTme}}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART VI - GENERAL CONDITIONS OF SHIPMENT</h6>
                <div class="table-responsive  custom_table w-100">
                    <table class="table table-bordered" border="1">
                        <tr>
                            <td width="50%"><b>What was the condition of boxes on
                                arrival?</b></td>
                            <td colspan="10" class="text-center">{{boxArvl}}</td>
                        </tr>
                        <tr>
                            <td><b>Were necessary labels attached to shipping boxes?</b>
                            </td>
                            <td colspan="10" class="text-center">{{shipBox}}</td>
                        </tr>
                        <tr>
                            <td><b>Other comments including description of alarms in
                                electronic devices:</b></td>
                            <td colspan="10" class="text-center">{{elctrncDvc}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART VII - NAME AND SIGNATURE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Authorised Inspection Supervisor</b></td>
                            <td class="text-center"><b>Date</b></td>
                            <td class="text-center"><b>Store Manager/SEPIO</b></td>
                            <td class="text-center"><b>Date</b></td>
                        </tr>
                        <tr>
                            <td class="text-center">{{suprvsior}}</td>
                            <td class="text-center">{{insptnDte | date: 'dd-MM-yyyy'}}</td>
                            <td class="text-center">{{sepio}}</td>
                            <td class="text-center">{{strMngrDte | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="float-center text-center">
                                    <b>For Procurement Agency office use only</b> <br />
                                </div>
                                <div class="float-start mt-4">
                                    Date received by the office ______________________________
                                </div><br/>
                                <div class="float-end mt-4">
                                    Contact person________________________________
                                </div><br />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer card-footer" style="padding: 6px !important;">
    <button type="button" class="btn btn-sm btn-light" (click)="actMdl.close()">Cancel</button>
    <button class="btn btn-primary btn-sm" (click)="onExportExcel('tblExl');actMdl.close()">Print</button>
</div>