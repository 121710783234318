<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="facility-groups-tab" data-bs-toggle="tab"
                    href="#facility-groups" role="tab" aria-controls="facility-groups" aria-selected="true">Facility
                    groups</a></li>
            <li class="nav-item"><a class="nav-link" id="Create-facility-groups-top-tab" data-bs-toggle="tab"
                    href="#top-Create-facility-groups" role="tab" aria-controls="Create-facility-groups"
                    aria-selected="false">Create facility group</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="facility-groups" role="tabpanel"
                aria-labelledby="facility-groups-tab">
                <evin-facility-group-view></evin-facility-group-view>
            </div>
            <div class="tab-pane fade" id="top-Create-facility-groups" role="tabpanel"
                aria-labelledby="Create-facility-groups-top-tab">
                <evin-facility-group-create></evin-facility-group-create>
            </div>
        </div>
    </div>
</div>