<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0 mb-2">
            <div class="card-body p-2 border-0">
                <form [formGroup]="invStkAdjFrm">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Facility</label>
                                    <input type="text" placeholder="Filter by Facility" class="form-control"
                                        id="Facility" formControlName="facility">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <input type="text" placeholder="Filter by Material" class="form-control"
                                        id="Material" formControlName="material">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Deviant type</label>
                                    <select id="inputState" class="form-select" formControlName="dvntType">
                                        <option value="">All</option>
                                        <option>Likely to be out of stock</option>
                                        <option>Stock expiring before it can be consumed</option>
                                        <option>Excess stock for long duration</option>
                                    </select>
                                </div>
                            </div>
                            <!-- </form> -->
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm-4 row mb-3">
                            <label for="inputEmail4" class="col-sm-6 col-form-label">Storage category:</label>
                            <div class="col-sm-6">
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="strgCat">
                                    <option value="">All</option>
                                    <option value="1">CCP</option>
                                    <option value="2">DIVS</option>
                                    <option value="3">DVS</option>
                                    <option value="4">GMSD</option>
                                    <option value="5">Manufacturer</option>
                                    <option value="6">RVS</option>
                                    <option value="7">SVS</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-4 row mb-3">
                            <label for="inputEmail4" class="col-sm-6 col-form-label">Material category:</label>
                            <div class="col-sm-6">
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="matCat">
                                    <option value="">All</option>
                                    <option value="1">CAMPAIGN</option>
                                    <option value="2">HIV</option>
                                    <option value="3">Malaria</option>
                                    <option value="4">OPEN Vials</option>
                                    <option value="5">RI Vaccins</option>
                                    <option value="6">Syringers</option>
                                    <option value="7">TB</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2">Filter</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-body p-2 border-0">
                <div class="row">
                    <div class="col-xl-12 text-center">No recommendations available</div>
                </div>
            </div>
        </div>
    </div>
</div>