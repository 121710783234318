import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-dmn-actvty-new',
  templateUrl: './dmn-actvty-new.component.html',
  styleUrls: ['./dmn-actvty-new.component.css']
})
export class DmnActvtyNewComponent extends BaseComponent implements OnInit {

  lstAllactivities: any[] = []; noRcrds = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; dmnId: any = ''; usrLvl = ''; dmnName: any = '';
  pageSize = environment.tblPageSize; cntryId: any = ''; brdcrmData: any = []; isExprt: any = false;
  slctState: any = ''; slctDistrict: any = ''; slctStateId: any = ''; slctDistrictId: any = ''; slctdmnId: any; slctDistrictResn: any = '';
   slctDistrictResnId: any = ''; totalItems: any; isClckDmn: any = false; prvsPrnthId: any; hvrResult: any[]= []; dmnType : any=''; ttl : any = {};
   isRprtsHlpShw = environment.isRprtsHlpShw;

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '';  this.dmnName = this.usrLgnDtls.pranthName;
      if(this.slctDmnDetails?.pranthName =='India')
       this.isClckDmn = true, this.brdcrmData.push({brdName:this.usrLgnDtls?.pranthName, brdId:this.usrLgnDtls?.pranthId});

    this.dmnId = this.slctDmnDetails?.pranthId; this.exp_collapse();
    // this.exp_collapse(), this.getallactivities(); // this.brdcrmData[0].brdId
    this.glblSvc.sbjctSpnr.next(true), Promise.all([setTimeout(() => { this.getallactivities(this.dmnId)}, 1000)]).then(() => { setTimeout(() => { this.glblSvc.sbjctSpnr.next(true)}, 1000) });
  }

  exp_collapse() {
    $('#basic').simpleTreeTable({ expander: $('#expander'), collapser: $('#collapser'), store: 'session', storeKey: 'simple-tree-table-basic' });
    $('#open1').on('click', function () { $('#basic').data('simple-tree-table').openByID("1"); });
    $('#close1').on('click', function () { $('#basic').data('simple-tree-table').closeByID("1"); });
    $('#basic').simpleTreeTable({ expandable: true, initialState: 'Expand' }, true);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo; this.getallactivities(this.dmnId);
  }

  getallactivities(prnthId: any) {
    this.brdcrmData.forEach((e: any) => { e.brdId == prnthId }); this.dmnId = prnthId; Object.keys(this.ttl).forEach(k => delete this.ttl[k]);
    let crntPgIndx = this.crntPage - 1; this.lstAllactivities = []; let payLoad = { blockId: 0, districtId: 0, stateId: 0 }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity.replace('v1/', ''), prnthId != '' ?
      environment.apiMthds.getdomainactivitydetails.replace('ppp', this.dmnId).replace('ttt', (this.usrLvl == 'National' ? 'n' :
        (this.usrLvl == 'State' ? 's' : 'd'))).replace('ccc', this.isClckDmn).replace('pageNo',
          `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`) : environment.apiMthds.getdomainactivitydtls
            .replace('ttt', (this.usrLvl == 'National' ? 'n' : (this.usrLvl == 'State' ? 's' : 'd'))).replace('ccc', this.isClckDmn).replace('pageNo',
              `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 4).
      then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstAllactivities = this.result?.data?.domainActivityDetails ?? [],
                this.ttl = this.result.data?.totalCount,
                this.totalItems = this.result.data?.totalCount?.count ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                  this.totalItems);
            // this.pageEntrs = this.result.data?.totalCount?.count ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
            else this.glblSvc.onTstr('e', this.result.message);
          }

        }
      });
  }

  dmnClick(dmn: any) {
    if (dmn != '' && dmn.flag) {
      this.isClckDmn = true;
      // this.prvsPrnthId = this.dmnId;
      let obj = this.brdcrmData.find((e: any) => e.brdId == dmn.domainId); obj != 'undefined' ? this.brdcrmData.push({ brdName: dmn.domain, brdId: dmn.domainId }) : this.brdcrmData;
      // this.brdcrmData.filter((e : any)=> e.brdId == dmn.domainId) ? this.brdcrmData : this.brdcrmData.push({brdName:dmn.domain, brdId:dmn.domainId});
      this.dmnId = dmn.domainId, this.getallactivities(this.dmnId);
    }
  }
  onClkBrdcrumb(id: any) {
    let i = this.brdcrmData.findIndex((e: any) => e.brdId == id);
    //  for(var x =i; x<=this.brdcrmData.length; x++)
    let l = this.brdcrmData.length;
    if (this.brdcrmData[l - 1].brdId != id) {
      this.brdcrmData.splice(i + 1)
      this.getallactivities(id);
    }
  }

  loadastStatusForm() {
    this.getallactivities(this.slctDmnDetails?.pranthId), this.brdcrmData = [];
  }

  onEE(){
    this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity,
      environment.apiMthds.exprtDmntActvty.replace('ppp', this.dmnId).replace('ttt', (this.usrLvl == 'National' ? 'n' :
      (this.usrLvl == 'State' ? 's' : 'd'))).replace('ccc', this.isClckDmn).replace('eee',this.usrLgnDtls.email), null, 4).
      then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
          }
        }
      });
  }
  
  popOverTable(type: string, dmn: string,  obj: any){
    this.dmnType =dmn;
    this.hvrResult =[];
    let payLoad ={blockId: 0, districtId :0,stateId: 0 }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.usrActivity.replace('v1/', ''),
      environment.apiMthds.getPopOverDataDmnActvty.replace('ppp', obj.domainId).replace('ttt', (this.usrLvl == 'National' ?  type :
      (this.usrLvl == 'State' ? type: type))), payLoad, 4).
      then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              if(dmn =='dfl')
             this.hvrResult = this.result.data.liveList;
             if(dmn =='dfa')
             this.hvrResult = this.result.data.storeDto;
             if(dmn =='dml')
             this.hvrResult = this.result.data.liveAssetDto;
             if(dmn =='dma')
             this.hvrResult = this.result.data.activeAssetDto;
            }
          }
        }
      });
  }
}