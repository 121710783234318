import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-db-activity',
  templateUrl: './db-activity.component.html',
  styleUrls: ['./db-activity.component.css']
})

export class DbActivityComponent extends BaseComponent implements OnInit {
  dbActPrmsn = true; dbActTxnPrmsn = true; usrPrmsn: any = null; dsbleActlDtOfTransctnTab: boolean = false;
  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
    this.glblSvc.sbjctCnfgData.subscribe(s => { this.dsbleActlDtOfTransctnTab = s.cnfgDBData?.date_of_actual_transaction?.disable_date_of_actual_transaction});
  }

  ngOnInit(): void {
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let dbLst: any[] = this.usrPrmsn?.Dashboard;
      dbLst.filter(e => {
        ((e.menuCode == 'mm_db_ac_ov') ? (this.dbActPrmsn = e.action) : ((e.menuCode == 'mm_db_ac_at') ? (this.dbActTxnPrmsn = e.action) : ''));
      });
    }
  }

}
