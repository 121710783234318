import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-manufacturers-view',
  templateUrl: './manufacturers-view.component.html',
  styleUrls: ['./manufacturers-view.component.css']
})

export class ManufacturersViewComponent extends BaseComponent implements OnInit {

  noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize;  totalItems: any; 
  manufacturersLst: any[] = []; producerBadges: any[] = []; stupManufViewForm = this.formBuilder.group({ producerName: [''] });
  prmsns: any = null; mnfrLst: boolean = true; mnfrEdt: boolean = true;

  constructor(public glblSvc: GlobalService
    , private router:Router
    , private formBuilder: FormBuilder) { 
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe((a: any) => {
      this.producerBadges = a.filter((f : any) => f.badgeTypeId == environment.tagTypes.manufacturer)
    });
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_mf_li')
     this.mnfrLst = e?.action;
    if(e?.menuCode =='mm_st_mf_ed')
     this.mnfrEdt = e?.action;
  });
  if(this.mnfrLst)
    this.loadManufacturers();
  }

  onMfgDetails(ManufacturerId: any){
    this.glblSvc.sbjctManfacId.next(ManufacturerId), this.router.navigate(['home/setup-mfrs-details']);
  }

  onMfgEdit(id: any){
    this.glblSvc.sbjctManEdit.next(id),
    this.router.navigate(['home/setup-mfrs-edit']);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.loadManufacturers();
  }

  loadManufacturers() {
    let crntPgIndx = this.crntPage - 1, 
    // tagName = this.stupManufViewForm.get('tagName')?.value != null ? this.stupManufViewForm.get('tagName')?.value : '',
    producerName = this.stupManufViewForm.get('producerName')?.value != null ? this.stupManufViewForm.get('producerName')?.value : '';
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.producer, environment.apiMthds.getManufacbyFilter.
      replace('ppp', producerName).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.manufacturersLst = this.result.data?.producerDTO != null ? this.result.data?.producerDTO : [],
              this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onRemove(id: any) {
    if (confirm("Are you sure want to delete ?")) {
    this.FetchData(environment.apiCalls.del, environment.apiPaths.producer, environment.apiMthds.delById+`${id}`, null,  0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') 
          this.glblSvc.onTstr('s', this.result.message), this.loadManufacturers();
      else
      this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }
  }

  onEE() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.producer, environment.apiMthds.exprtManftr.
      replace('eee',this.usrLgnDtls.email), null, 0, true, 0).then(() => {
    if (this.result)
      this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
  });
}

  onReset() {
    this.stupManufViewForm.get('producerName')?.setValue(''), this.loadManufacturers();
  }

}