<div class="modal-header">
    <button type="button" class="close btn btn-light float-end" (click)="actMdl.close()">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body">
    <div class="card border mt-3">
        <div class="card-body p-2">
            <div class="container pt-4 pb-4">
                <p>This report is to be filled in by authorized staff, rectified by the Store Manager or the EPI Manager, and forwarded to the MoHFW, New Delhi within Two days of Product arrival.</p>
                <p>
                    Definition of Product: All in kind supplies from the Govt. of India under the Universal Immunization Program barring
                    vaccine and diluents, for which Vaccine Arrival Report(VAR) are being submitted separately. Use one report for each product in the shipment.
                </p>
                <h5 class="text-center mb-3">{{lstVcine?.consigneeName}}</h5>
                <h5 class="text-center mb-3">Product Arrival Report</h5>
                <div class="table-responsive  custom_table">
                    <div class="row">
                        <div class="col-xl-6">
                            <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;">
                                <tr>
                                    <td colspan="5" width="50%"><b>COUNTRY :</b> </td>
                                    <td class="text-center"><span>India</span> </td>
                                </tr>
                                <tr>
                                    <td colspan="5" width="50%"><b>REPORT No.</b></td>
                                    <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="refNo"></td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-xl-6 mt-4 pt-2">
                            <table class="table table-bordered mt-4" width="100%" style="border-color: 5px solid #bebebe !important;">
                                <tr>
                                    <td colspan="5" width="50%"><span class="fw-bold"><b>Date of Report :</b></span></td>
                                    <td><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;">
                        <tr>
                            <td colspan="5" width="50%"><b>Place, date and time of inspection</b></td>
                            <td><b>Name of cold store, date and time vaccines entered into cold store</b> </td>
                        </tr>
                        <tr>
                            <td colspan="5" width="50%">
                                <div class="row">
                                    <div class="col-xl-5 mt-2">
                                        <input type="text" class="form-control form-control-sm" placeholder="Place" [(ngModel)]="plce">
                                    </div>
                                    <div class="col-xl-4 mt-2">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                            [(ngModel)]="inspDte" [maxDate]="dt2day">
                                    </div>
                                    <div class="col-xl-3 mt-2">
                                        <input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="tme">
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-xl-6 mt-2">{{lstVcine?.storeLocation}}</div>
                                    <div class="col-xl-3 mt-2">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                            [(ngModel)]="toDate" [maxDate]="dt2day">
                                    </div>
                                    <div class="col-xl-3 mt-2"><input type="time" class="form-control form-control-sm" placeholder="Time" [(ngModel)]="strTme"></div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART I - ADVANCE NOTICE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th><b>Date received by Mail/Fax</b></th>
                                <th>
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                        [(ngModel)]="shpDate" [maxDate]="dt2day"></th>
                                <th><b>Pre-advice</b></th>
                                <th><b>Copy Airway Bill (AWB)</b></th>
                                <th><b>Copy of Packing List</b></th>
                                <th><b>Copy of Invoice</b></th>
                            </tr>
                        </thead>
                        <!-- <tr>
                            <td>Pre-advice</td>
                            <td><input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="preAdvceDte" [maxDate]="dt2day"></td>
                            <td colspan="4"></td>
                        </tr> -->
                        <tr>
                            <td><b>Shipping Notification</b></td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn5"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn4"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn1"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn2"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm" [(ngModel)]="shpngNtfcn3"
                                    aria-label=".form-select-sm example">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td width="30%"><b>List other documents (If Requested)</b></td>
                            <td colspan="5"><input type="text" class="form-control form-control-sm" [(ngModel)]="lstOthDcm"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II A - Arrival Details</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center"><b>Supply order No.</b></th>
                                <th class="text-center"><b>Consignee</b></th>
                                <th class="text-center"><b>Vaccine Description (Type and doses/vial)</b></th>
                                <th class="text-center"><b>Manufacturer</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td>{{lstVcine?.supplyOrderNo}}</td>
                            <td>{{lstVcine?.consigneeName}}</td>
                            <td>{{lstVcine?.productName}}</td>
                            <td>{{lstVcine?.manufacturerName}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II B- ARRIVAL DETAILS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th colspan="5">Product Details</th>
                            </tr>
                            <tr>
                                <th class="text-center"><b>Lot Number</b></th>
                                <th class="text-center"><b>Number of boxes</b></th>
                                <th class="text-center"><b>Item Quantity</b></th>
                                <th class="text-center"><b>Manufacturing Date</b></th>
                                <th class="text-center"><b>Expiry Date</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of arvilDtls; let i=index">
                                <td>{{x.lotNmb}}</td>
                                <td>{{x.nmbOfBox}}</td>
                                <td>{{x.itmQty}}</td>
                                <td>{{x.mnftrDte | date: 'dd-MM-yyyy'}}</td>
                                <td>{{x.expiryDte | date: 'dd-MM-yyyy'}}</td>
                                <td></td>
                                <!-- <td class="text-center"><a (click)="onActn('d', i)"><i class="fa fa-trash" aria-hidden="true"></i></a></td> -->
                            </tr>
                            <tr>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="lotNmb"></td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="nmbOfBox"></td>
                                <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="itmQty"></td>
                                <td class="text-center">
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                        [(ngModel)]="mnftrDte" [maxDate]="dt2day">
                                </td>
                                <td class="text-center">
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                        [(ngModel)]="expiryDte" [minDate]="dt2day">
                                </td>
                                <td><div>
                                    <button type="button" class="btn btn-sm btn-success" (click)="onActn('a')">
                                            <i class="fa fa-plus" aria-hidden="true"></i></button></div></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART II C- ARRIVAL DETAILS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th colspan="5">&nbsp;</th>
                                <th class="text-center"><b>Yes</b></th>
                                <th class="text-center"><b>No</b></th>
                                <th width="20%"><strong>Comments</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="5"><b>Was quantity received as per shipping notification?</b></td>
                                <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault" name="qntyRcvd" value="Yes" [(ngModel)]="qntyRcvd"></td>
                                <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault1" name="qntyRcvd" value="No" [(ngModel)]="qntyRcvd"></td>
                                <td width="20%"><input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="coment"></td>
                            </tr>
                            <tr>
                                <td colspan="5"><b>If not, were details of short-shipment provided prior to vaccine arrival?</b></td>
                                <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault2" name="vcneArvl" value="Yes" [(ngModel)]="vcneArvl"></td>
                                <td class="text-center"><input class="form-check-input" type="radio" id="flexCheckDefault3" name="vcneArvl" value="No" [(ngModel)]="vcneArvl"></td>
                                <td width="20%"><input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="coment1"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART III - DOCUMENTS ACCOMPANYING THE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th width="20%"><b>Invoice</b></th>
                                <th width="20%"><b>Packing List</b></th>
                                <th width="20%"><b>Release Certificate</b></th>
                                <th width="20%"><b>Product Arrival Report</b></th>
                                <th width="20%"><b>Other</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment1">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment2">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment3">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center">
                                <select class="form-select form-select-sm"
                                    aria-label=".form-select-sm example" [(ngModel)]="shipment4">
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="othr"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART IV - GENERAL CONDITIONS OF SHIPMENT</h6>
                <div class="table-responsive  custom_table w-100">
                    <table class="table table-bordered">
                        <tr>
                            <td width="50%" class="text-center"><b>What was the condition of boxes on arrival?</b></td>
                            <td colspan="10" class="text-center">
                                <input type="text" placeholder="Comments" class="form-control form-control-sm" [(ngModel)]="boxArvl">
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Were necessary labels attached to shipping boxes?</b>
                            </td>
                            <td colspan="10" class="text-center">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineCheckbox1"
                                        value="Yes" [(ngModel)]="shipBox" name="shpngBox">
                                    <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" id="inlineCheckbox2"
                                        value="No" [(ngModel)]="shipBox" name="shpngBox">
                                    <label class="form-check-label" for="inlineCheckbox2">No</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Other comments including description of alarms in
                                electronic devices:</b></td>
                            <td colspan="10"><input type="text" class="form-control form-control-sm" [(ngModel)]="elctrncDvc"></td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART V - NAME AND SIGNATURE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered">
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Authorised Inspection Supervisor</b></td>
                            <td class="text-center"><b>Date</b></td>
                            <td class="text-center"><b>Store Manager/SEPIO</b></td>
                            <td class="text-center"><b>Date</b></td>
                        </tr>
                        <tr>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="suprvsior"></td>
                            <td class="text-center">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="insptnDte" [maxDate]="dt2day">
                            </td>
                            <td class="text-center"><input type="text" class="form-control form-control-sm" [(ngModel)]="sepio"></td>
                            <td class="text-center">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    (bsValueChange)="onChngDT($event)" onkeypress='return event.charCode == 8' placeholder="Date" readonly
                                    [(ngModel)]="strMngrDte" [maxDate]="dt2day">
                            </td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="float-center text-center">
                                    <b>For Procurement Agency office use only</b> <br />
                                </div>
                                <div class="float-start mt-4">
                                    Date received by the office ______________________________
                                </div>
                                <div class="float-end mt-4">
                                    Contact person________________________________
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="container pt-4 pb-4" id="tblExl" hidden>
                <p>This report is to be filled in by authorized staff, rectified by the Store Manager or the EPI Manager, and forwarded to the MoHFW, New Delhi within Two days of Product arrival.</p>
                <p>
                    Definition of Product: All in kind supplies from the Govt. of India under the Universal Immunization Program barring
                    vaccine and diluents, for which Vaccine Arrival Report(VAR) are being submitted separately. Use one report for each product in the shipment.
                </p>
                <h5 class="text-center mb-3">{{lstVcine?.consigneeName}}</h5>
                <h5 class="text-center mb-3">Product Arrival Report</h5>
                <div class="table-responsive  custom_table">
                    <!-- <div class="row">
                        <div class="col-xl-6"> -->
                            <table class="table table-bordered" width="100%" style="border-color: 5px solid #bebebe !important;" border="1">
                                <tr width="50%">
                                    <td colspan="5"><b>COUNTRY :</b></td>
                                    <td><span class="fw-bold">India</span> </td>
                                </tr>
                                <tr>
                                    <td colspan="5" width="25%"><b>REPORT No.</b></td>
                                    <td width="25%">{{refNo}}</td>
                                    <td colspan="5" width="25%"><span class="fw-bold"><b>Date of Report :</b></span></td>
                                    <td width="25%"><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table>
                        <!-- </div>
                        <div class="col-xl-6 mt-4 pt-2"> -->
                            <!-- <table class="table table-bordered mt-4" width="50%" style="border-color: 5px solid #bebebe !important;" border="1">
                                <tr>
                                    <td colspan="5" width="50%"><span class="fw-bold">Date of Report :</span></td>
                                    <td><span>{{toDay | date: 'dd-MM-yyyy hh:mm:ss'}}</span> </td>
                                </tr>
                            </table> -->
                        <!-- </div> -->
                    <!-- </div> -->
                </div>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1" width="100%" style="border-color: 5px solid #bebebe !important;">
                        <tr>
                            <td colspan="5" width="50%"><b>Place, date and time of inspection</b></td>
                            <td><span><b>Name of cold store, date and time vaccines entered into cold store</b></span></td>
                        </tr>
                        <tr>
                            <td colspan="5" width="50%">
                                <div class="row">
                                    <span>{{plce}}</span>&nbsp;<span>{{inspDte | date: 'dd-MM-yyyy'}}</span>&nbsp;<span>{{tme}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-xl-12">{{lstVcine?.storeLocation}}</div>
                                    <div class="col-xl-6 mt-2"> <span>{{toDate | date: 'dd-MM-yyyy'}}</span>&nbsp;<span>{{strTme}}</span></div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART I - ADVANCE NOTICE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th><b>Date received by Mail/Fax</b></th>
                                <th class="text-center">{{shpDate | date: 'dd-MM-yyyy'}}</th>
                                <th><b>Pre-advice</b></th>
                                <th><b>Copy Airway Bill (AWB)</b></th>
                                <th><b>Copy of Packing List</b></th>
                                <th><b>Copy of Invoice</b></th>
                            </tr>
                        </thead>
                        <!-- <tr>
                            <td>Pre-advice</td>
                            <td>{{preAdvceDte | date: 'dd-MM-yyyy'}}</td>
                            <td colspan="4"></td>
                        </tr> -->
                        <tr>
                            <td><b>Shipping Notification</b></td>
                            <td class="text-center">{{shpngNtfcn5}}</td>
                            <td class="text-center">{{shpngNtfcn4}}</td>
                            <td class="text-center">{{shpngNtfcn1}}</td>
                            <td class="text-center">{{shpngNtfcn2}}</td>
                            <td class="text-center">{{shpngNtfcn3}}</td>
                        </tr>
                        <tr>
                            <td width="30%"><b>List other documents (If Requested)</b></td>
                            <td colspan="5">{{lstOthDcm}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II A - Arrival Details</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th class="text-center"><b>Supply order No.</b></th>
                                <th class="text-center"><b>Consignee</b></th>
                                <th class="text-center"><b>Vaccine Description (Type and doses/vial)</b></th>
                                <th class="text-center"><b>Manufacturer</b></th>
                            </tr>
                        </thead>
                        <tr>
                            <td>{{lstVcine?.supplyOrderNo}}</td>
                            <td>{{lstVcine?.consigneeName}}</td>
                            <td>{{lstVcine?.productName}}</td>
                            <td>{{lstVcine?.manufacturerName}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART II B- ARRIVAL DETAILS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th colspan="5">Product Details</th>
                            </tr>
                            <tr>
                                <th class="text-center"><b>Lot Number</b></th>
                                <th class="text-center"><b>Number of boxes</b></th>
                                <th class="text-center"><b>Item Quantity</b></th>
                                <th class="text-center"><b>Manufacturing Date</b></th>
                                <th class="text-center"><b>Expiry Date</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let x of arvilDtls; let i=index">
                                <td>{{x.lotNmb}}</td>
                                <td>{{x.nmbOfBox}}</td>
                                <td>{{x.itmQty}}</td>
                                <td>{{x.mnftrDte | date: 'dd-MM-yyyy'}}</td>
                                <td>{{x.expiryDte | date: 'dd-MM-yyyy'}}</td>
                                <!-- <td class="text-center"><a (click)="onActn('d', i)"><i class="fa fa-trash" aria-hidden="true"></i></a></td> -->
                            </tr>
                            <!-- <tr>
                                <td class="text-center">{{lotNmb}}</td>
                                <td class="text-center">{{nmbOfBox}}</td>
                                <td class="text-center">{{itmQty}}</td>
                                <td class="text-center">{{mnftrDte | date: 'dd-MM-yyyy'}}</td>
                                <td class="text-center">{{expiryDte | date: 'dd-MM-yyyy'}}</td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART II C- ARRIVAL DETAILS</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th colspan="5">&nbsp;</th>
                                <th class="text-center"><b>Yes</b></th>
                                <th class="text-center"><b>No</b></th>
                                <th width="20%"><b>Comments</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colspan="5"><b>Was quantity received as per shipping notification?</b></td>
                                <td class="text-center">{{qntyRcvd == 'Yes' ? qntyRcvd : ''}}</td>
                                <td class="text-center">{{qntyRcvd == 'No' ? qntyRcvd : ''}}</td>
                                <td width="20%">{{coment}}</td>
                            </tr>
                            <tr>
                                <td colspan="5"><b>If not, were details of short-shipment provided prior to
                                    vaccine arrival?</b></td>
                                <td class="text-center">{{vcneArvl == 'Yes' ? vcneArvl : ''}}</td>
                                <td class="text-center">{{vcneArvl == 'No' ? vcneArvl : ''}}</td>
                                <td width="20%">{{coment1}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART III - DOCUMENTS ACCOMPANYING THE SHIPMENT</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <thead>
                            <tr>
                                <th width="20%"><b>Invoice</b></th>
                                <th width="20%"><b>Packing List</b></th>
                                <th width="20%"><b>Release Certificate</b></th>
                                <th width="20%"><b>Product Arrival Report</b></th>
                                <th width="20%"><b>Other</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{shipment1}}</td>
                                <td>{{shipment2}}</td>
                                <td>{{shipment3}}</td>
                                <td>{{shipment4}}</td>
                                <td>{{othr}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 class="mt-4">PART IV - GENERAL CONDITIONS OF SHIPMENT</h6>
                <div class="table-responsive  custom_table w-100">
                    <table class="table table-bordered" border="1">
                        <tr>
                            <td width="50%" class="text-center"><b>What was the condition of boxes on
                                arrival?</b></td>
                            <td colspan="10" class="text-center">{{boxArvl}}</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Were necessary labels attached to shipping boxes?</b>
                            </td>
                            <td colspan="10" class="text-center">{{shipBox}}</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Other comments including description of alarms in
                                electronic devices:</b></td>
                            <td colspan="10">{{elctrncDvc}}</td>
                        </tr>
                    </table>
                </div>
                <h6 class="mt-4">PART V - NAME AND SIGNATURE</h6>
                <div class="table-responsive  custom_table">
                    <table class="table table-bordered" border="1">
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td class="text-center"><b>Authorised Inspection Supervisor</b></td>
                            <td class="text-center"><b>Date</b></td>
                            <td class="text-center"><b>Store Manager/SEPIO</b></td>
                            <td class="text-center"><b>Date</b></td>
                        </tr>
                        <tr>
                            <td class="text-center">{{suprvsior}}</td>
                            <td class="text-center">{{insptnDte | date: 'dd-MM-yyyy'}}</td>
                            <td class="text-center">{{sepio}}</td>
                            <td class="text-center">{{strMngrDte | date: 'dd-MM-yyyy'}}</td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <div class="float-center text-center">
                                    <b>For Procurement Agency office use only</b> <br />
                                </div>
                                <div class="float-start mt-4">
                                    Date received by the office ______________________________
                                </div><br />
                                <div class="float-end mt-4">
                                    Contact person________________________________
                                </div><br />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer card-footer" style="padding: 6px !important;">
    <button type="button" class="btn btn-sm btn-light" (click)="actMdl.close()">Cancel</button>
    <button class="btn btn-primary btn-sm" (click)="onExportExcel('tblExl');actMdl.close()">Print</button>
</div>