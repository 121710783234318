import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-bulletin-board',
  templateUrl: './bulletin-board.component.html',
  styleUrls: ['./bulletin-board.component.css']
})
export class BulletinBoardComponent  extends BaseComponent implements OnInit {

  lgnUsrData: any;
  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
    
  }

  ngOnInit(): void {
  }

}
