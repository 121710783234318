import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-puc',
  templateUrl: './puc.component.html',
  styleUrls: ['./puc.component.css']
})

export class PucComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}