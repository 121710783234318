import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cce-model',
  templateUrl: './cce-model.component.html',
  styleUrls: ['./cce-model.component.css']
})
export class CceModelComponent extends BaseComponent implements OnInit {

  astTypLst: any[] = []; mnfrEdit: any = {}; mnftrId: any = ''; srchMdlNme: any = ''
  noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize;  totalItems: any; 
  manufacturersLst: any[] = []; producerBadges: any[] = []; stupCCEMOdelForm = this.formBuilder.group({ asetTypeId: ['', Validators.required],
  manftrType: ['', Validators.required], modelNo: ['', Validators.required], SrchName:[''] });
  prmsns: any = null; mnfrLst: boolean = true; slctdId =''; manftrsType: any[] = []; isSbmtd = false; slctAstTyp: any = ''; slctMnftr: any = '';

  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.astTypLst = s.astTypLst;
    });
    // this.glblSvc.sbjctCCEMdl.subscribe(s => {this.LoadManufacturers()})
   }

  ngOnInit(): void {
    this.LoadCCEModelList(), this.mnftrId = '';
  }

  LoadManufacturers(e: any, c:string) {
    let evnt: any = (c == 'c' ? (+e.target.value ?? '') : e);
    if (this.slctAstTyp !='' && this.slctAstTyp !=null ) {
      this.manftrsType = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getVndrAstTyp.
        replace('aaa', evnt), null, 2, true, 1).then(() => {
         if (this.result) {
           if (this.result.data)
             this.manftrsType = this.result.data != null ? this.result.data : [], this.slctMnftr = '';
             if (this.mnftrId != '')
              setTimeout(() => { this.slctMnftr = this.manftrsType.filter(e => e.id == +this.mnftrId).length !=0 ? this.mnftrId : ''; }, 500);
         }
       })
    }
  }

  get fc() { return this.stupCCEMOdelForm.controls; }

  LoadCCEModelList() {
    let crntPgIndx = this.crntPage - 1, 
    Vname = this.stupCCEMOdelForm.get('SrchName')?.value != null ? this.stupCCEMOdelForm.get('SrchName')?.value : ''; this.mnftrId = '',
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getMdlLst
      .replace('nnn', Vname).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
       null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manufacturersLst = this.result?.data?.assetModelDTO ?? [], this.totalItems = this.result?.data?.totalRecordCount ?? 0,
              this.pageEntrs = this.result.data?.totalRecordCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
        }
      })
  }

  // onNestedSlct($event: any, type: any) {
  //   if (type == 'asetType') {
  //     let b = $event.target.value;
  //     this.stupCCEMOdelForm.get('asetTypeId')?.setValue([+b]);
  //   }
  //   if (type == 'manuType') {
  //     let m = $event.target.value;
  //     this.stupCCEMOdelForm.get('manftrType')?.setValue([+m]);

  //   }
  // }

  saveVndr() {
    this.checkFormValidations(this.stupCCEMOdelForm);
    if (this.stupCCEMOdelForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payload = this.stupCCEMOdelForm.getRawValue(); payload.id = this.slctdId ?? 0;
      payload.modelName = payload.modelNo, payload.assetTypeId = payload.asetTypeId, payload.assetVendorId = payload.manftrType;
   
      delete payload?.SrchName, delete payload?.modelNo, delete payload?.asetTypeId, delete payload?.manftrType ; 
      this.FetchData(environment.apiCalls.post, environment.apiPaths.asetMdl, environment.apiMthds.saveRupdate, payload, 2, true, 1).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
           this.onReset();this.LoadCCEModelList();
      });
    }
  }

  onReset() {
    this.isSbmtd = false; //this.stupCCEMOdelForm.reset('');
    this.stupCCEMOdelForm.get('asetTypeId')?.setValue(''); this.stupCCEMOdelForm.get('manftrType')?.setValue(''), this.slctAstTyp = '',
    this.slctMnftr = '', this.stupCCEMOdelForm.get('modelNo')?.setValue(''); this.stupCCEMOdelForm.get('SrchName')?.setValue('');
    this.LoadCCEModelList();
  }

  onMfgEdit(id: any){
    this.mnftrId = '',
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getById.replace('uuu', id), null, 2, true, 1).then(() => {
      if (this.result){
        this.mnfrEdit = this.result.data; this.slctdId = id, this.mnftrId = this.mnfrEdit?.assetVendorId ?? '', this.LoadManufacturers(this.mnfrEdit?.assetTypeId, 'e'), 
        this.stupCCEMOdelForm.patchValue({ asetTypeId: this.mnfrEdit?.assetTypeId ?? null, modelNo: this.mnfrEdit?.modelName ?? null,
          // manftrType:  this.mnfrEdit?.assetVendorId ?? ''
        });
      }
    });
  }

  setPage(pagNo: number, type:any): void {
    if(type =='astMdl')
    this.crntPage = pagNo, this.LoadCCEModelList();
  }

  onRemove(id: any) {
    if (confirm("Are you sure want to delete ?")) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.asetMdl, environment.apiMthds.delMat.replace('rrrrr', id), null,  2, true, 1).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') 
            this.glblSvc.onTstr('s', this.result.message),
            this.onReset(), this.LoadCCEModelList();
        else
        this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onResetDropdwns() {
    this.isSbmtd = false; //this.stupCCEMOdelForm.reset(''); 
    this.stupCCEMOdelForm.get('asetTypeId')?.setValue('');
    this.stupCCEMOdelForm.get('manftrType')?.setValue('');
    this.stupCCEMOdelForm.get('modelNo')?.setValue(''); this.stupCCEMOdelForm.get('SrchName')?.setValue('');
  }

  onEE() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.mdl, environment.apiMthds.exprtMdl.
      replace('eee',this.usrLgnDtls.email).replace('mmm', this.srchMdlNme ?? ''), null, 7, true, 0).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }
}
