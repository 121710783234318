<div class="card" [formGroup]="meFrm">
    <div class="card-header px-3 pb-1 pt-2">
        <h3 class="h6 float-start">My Exports</h3>
    </div>
    <div class="card-body p-2">
        <div class="row mb-3">
            <div class="col-xl-3">
                <label>User ID</label>
                <input type="text" placeholder="User ID" class="form-control"
                    [attr.disabled]="lgnUsrDtls.roleId != 3 ? true : null"
                    minlength="3" maxlength="10" formControlName="userId">
            </div>
            <div class="col-xl-3" *ngIf="false">
                <label>Type</label>
                <select class="form-select" formControlName="reportName">
                    <option value="">All</option>
                    <option [value]="ft.name" *ngFor="let ft of lstFTs">{{ft.name}}</option>
                </select>
            </div>
            <div class="col-xl-2">
                <label>From</label>
                <div class="input-group">
                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                        class="form-control optinbg bsDP-bg" #dpFD="bsDatepicker" readonly
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                        onkeypress='return event.charCode == 8' formControlName="fromDate"
                        placeholder="From" [maxDate]="dt2Day" (bsValueChange)="onChngDT($event)">
                    <div class="input-group-append">
                        <button class="btn btn-outline-bsdp p-2" type="button"
                            (click)="dpFD.toggle()" [attr.aria-expanded]="dpFD.isOpen">
                            <i class="fa fa-calendar"></i>
                        </button>
                        <!-- <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                            (click)="dpFD.hide();clearBSDate('fromDate')">
                            <i class="fa fa-times"></i>
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-2">
                <label>To</label>
                <div class="input-group">
                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                        class="form-control optinbg bsDP-bg" #dpTD="bsDatepicker" readonly
                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                        onkeypress='return event.charCode == 8' formControlName="toDate"
                        placeholder="To" [maxDate]="dt2Day" [minDate]="minFD">
                    <div class="input-group-append">
                        <button class="btn btn-outline-bsdp p-2" type="button"
                            (click)="dpTD.toggle()" [attr.aria-expanded]="dpTD.isOpen">
                            <i class="fa fa-calendar"></i>
                        </button>
                        <!-- <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                            (click)="dpTD.hide();clearBSDate('toDate')">
                            <i class="fa fa-times"></i>
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-2 mt-4">
                <button class="btn btn-sm btn-light" (click)="onRst()">Reset</button>&nbsp;&nbsp;
                <button class="btn btn-sm btn-primary" (click)="LoadExports()">Filter</button>
            </div>
        </div>
        <!-- <div class="my-2">
            <input type="checkbox"> Show all exports in this domain
        </div> -->
        <div class="row ps-0 pe-0 pt-3 pb-1" *ngIf="exportsList?.length!=0">
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-striped table-sm table-bordered" id="table_detail">
            <thead class="text-center">
                <tr>
                    <th>S.No.</th>
                    <th>User ID</th>
                    <th>Type</th>
                    <th>Exported On</th>
                    <th>Status</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody class="table_data">
                <tr *ngFor="let item of exportsList | search:searchText:filterMetadata |
                paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};
                let i=index">
                    <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                    <td>{{item.userId}}</td>
                    <td>{{item.reportName}}</td>
                    <td>{{item.exportTime | date: "dd-MM-yyyy hh:mm:ss"}}</td>
                    <td>Completed <br>
                        <span>{{item.createdOn | date: "dd-MM-yyyy hh:mm:ss"}}</span>
                    </td>
                    <td class="text-center">
                        <!-- <a href="{{item.filePath}}" download target="_blank">Download</a> -->
                        <a class="btn btn-link" (click)="onDwnld(item)">Download</a>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="exportsList?.length==0 || count==0">
                <tr>
                    <td colspan="6" class="text-center">
                        {{noRcrdsTxt}}
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="exportsList?.length!=0">
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>