import { Component, OnInit } from '@angular/core';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
declare let anychart: any;

FusionCharts.addDep(Charts);
FusionCharts.addDep(FusionTheme);

@Component({
  selector: 'evin-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})

export class OverviewComponent extends BaseComponent implements OnInit {

  invChart = 'Pie'; tmpChart = 'Bar';
  arrayList: any = [
    { Name: 'chart1', value: 12345 },
    { Name: 'chart2', value: 123456 },
    { Name: 'chart3', value: 123 },
    { Name: 'chart4', value: 1234567 }
  ];

  fcMulti1: any = [{ "label": "Jan-2021 " }, { "label": "Feb-2021 " }, { "label": "Mar -2021" },
  { "label": "Apr-2021 " }, { "label": "May-2021" }, { "label": "Jun -2021" }, { "label": "Jul-2021 " }, { "label": "Aug2021 " },
  { "label": "Sep -2021" }, { "label": "Oct-2021 " }, { "label": "Nov-2021 " }, { "label": "Dec -2021" }
  ];

  fcMulti7: any = [{ "value": "16000", "link": "newchart-json-bar1" }, { "value": "20000", "link": "newchart-json-bar2" }, { "value": "18000" },
  { "value": "19000" }, { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" },
  { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" }
  ];

  fcMulti8: any = [{ "value": "16000" }, { "value": "20000" }, { "value": "18000" }, { "value": "19000" },
  { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" },
  { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" }
  ];

  fcLine2: any = [{ "value": "15000" }, { "value": "16000" }, { "value": "17000" },
  { "value": "18000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" },
  { "value": "19000" }, { "value": "20000" }, { "value": "21000" }, { "value": "22000" }, { "value": "23000" }
  ];

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.glblSvc.sbjctSpnr.next(true), Promise.all([this.acMapIND(), this.fcDoughNut('doughnut2d', 'fcdnInv'),
    this.fcBar(), this.fcDoughNut('pie2d', 'fcpieInv'), this.fcDoughNut('column2d', 'fcbarInv'), this.fcDoughNut('line', 'fclineInv'),
    this.fcDoughNut('doughnut2d', 'fcdnTmp'), this.fcDoughNut('pie2d', 'fcpieTmp'), this.fcDoughNut('line', 'fclineTmp'),
    this.fcDoughNut('column2d', 'fcbarTmp'), this.fcIndia(), this.fcMBar(), this.fcMultichart(), this.fcDrillChart()]).
      then(() => { this.glblSvc.sbjctSpnr.next(false); }), $(document).ready(function () { $('.anychart-credits').hide(); });
  }

  // #region anychart Map
  acMapIND() {
    anychart.onDocumentReady(function () {
      var map = anychart.map(); // create map
      var scale = anychart.scales.ordinalColor([{ less: 10 }, { from: 10, to: 25 }, { from: 25, to: 50 }, { from: 50, to: 90 }, { greater: 90 }]);
      var series = map.choropleth(getData());
      scale.colors(['#edf9ed', '#8ed98e', '#4ec44e', '#2f8e2f', '#256e25']); // '#dcdbd6'
      series.stroke(null);
      series.colorScale(scale);
      series.hovered().fill('#00a1ff');
      map.legend().enabled(true);
      map.legend().position("bottom");
      map.legend().itemsSourceMode('categories');
      series.labels(true); //state labels
      const labels = series.labels();
      labels.fontColor('black');
      labels.fontSize("10px");
      labels.fontWeight('bold');
      labels.offsetY(-10);
      map.overlapMode(false);
      map.geoData(anychart.maps['india']);
      map.container('ac-map');
      // map.background().fill({
      //   keys: ["#262932"],
      // });
      map.draw();
    });
    function getData() {
      var data = [{ "id": "IN.AN", "value": 20 },
      { "id": "IN.AP", "value": 22.2 },
      { "id": "IN.AR", "value": 25.6 },
      { "id": "IN.AS", "value": 30.8 },
      { "id": "IN.BR", "value": 45 },
      { "id": "IN.CH", "value": 78 },
      { "id": "IN.CT", "value": 79.6 },
      { "id": "IN.DN", "value": 89 },
      { "id": "IN.DD", "value": 99 },
      { "id": "IN.DL", "value": 94 },
      { "id": "IN.GA", "value": 10 },
      { "id": "IN.GJ", "value": 81 },
      { "id": "IN.HR", "value": 12 },
      { "id": "IN.HP", "value": 76 },
      { "id": "IN.JH", "value": 75.6 },
      { "id": "IN.KA", "value": 15 },
      { "id": "IN.KL", "value": 75 },
      { "id": "IN.LD", "value": 99 },
      { "id": "IN.MP", "value": 18 },
      { "id": "IN.MH", "value": 19 },
      { "id": "IN.MNL", "value": 20 },
      { "id": "IN.ML", "value": 21 },
      { "id": "IN.MZ", "value": 74 },
      { "id": "IN.NL", "value": 23 },
      { "id": "IN.OR", "value": 54 },
      { "id": "IN.PY", "value": 25 },
      { "id": "IN.PB", "value": 60 },
      { "id": "IN.RJ", "value": 27 },
      { "id": "IN.SK", "value": 64 },
      { "id": "IN.TN", "value": 29 },
      { "id": "IN.TR", "value": 30 },
      { "id": "IN.UP", "value": 31 },
      { "id": "IN.UT", "value": 32 },
      { "id": "IN.WB", "value": 33 },
      { "id": "IN.TG", "value": 34 },
      { "id": "IN.JK", "value": 35 },
      { "id": "IN.LA", "value": 99 }]
      return data;
    }
  }
  //#endregion

  // color code : #262932
  //#region FusionCharts
  fcDoughNut(chrtType: string, divId: string) {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "0",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",
            // "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
      // if (divId == 'fcpie2D')
      //   setTimeout(() => { fc.dispose(), $('#shwBtn').click(); }, 5000);
    });
  }

  fcBar() {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var salesChart = new FusionCharts({
        type: 'bar2d',
        renderAt: 'fcBarMat',
        width: '450',
        height: '350',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "Monthly Revenue",
            "subCaption": "Last year",
            "xAxisName": "Month",
            "yAxisName": "Amount (In USD)",
            "numberPrefix": "",
            "theme": "fusion",
            "paletteColors": ['FF5904', 'FF0000'],
            "canvasBorderColor": 'cccccc',
            "borderColor": "#262932",
            "usePlotGradientColor": "0",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            // "bgColor": "red",
            //"canvasbgColor": "red", // background of chart
            // "canvasbgAlpha": "10",
            // "canvasBorderThickness": "1",
            // "showAlternateHGridColor": "0",
            // "legendShadow":'1'=
          },
          "data": [{
            "label": "Jan",
            "value": "420000",
          }, {
            "label": "Feb",
            "value": "810000"
          }, {
            "label": "Mar",
            "value": "720000"
          }, {
            "label": "Apr",
            "value": "550000"
          }, {
            "label": "May",
            "value": "910000"
          }, {
            "label": "Jun",
            "value": "510000"
          }, {
            "label": "Jul",
            "value": "680000"
          }, {
            "label": "Aug",
            "value": "620000"
          }, {
            "label": "Sep",
            "value": "610000"
          }, {
            "label": "Oct",
            "value": "490000"
          }, {
            "label": "Nov",
            "value": "900000"
          }, {
            "label": "Dec",
            "value": "730000"
          }]
        }
      }).render();
    });
  }

  fcIndia() {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      // chart instance
      var chart = new FusionCharts({
        type: "maps/india",
        renderAt: "chart-map",
        width: "1000",
        height: "500",
        dataFormat: "json",
        dataSource: {
          "chart": {
            "caption": "India Map",
            // "subcaption": " 2021",
            "numbersuffix": "%",
            "includevalueinlabels": "1",
            "labelsepchar": ": ",
            "entityFillHoverColor": "#228B22",
            "theme": "fusioncharts/latest/maps/fusioncharts"
          },
          // "colorrange": {
          //   "minvalue": "0",
          //   "startlabel": "Low",
          //   "endlabel": "High",
          //   "code": "#228B22",
          //   "gradient": "1",
          //   "color": [{
          //     "maxvalue": "25",
          //     "code": "#FFDD44",
          //     "displayValue": "Median"
          //   }, {
          //     "maxvalue": "100",
          //     "code": "#6baa01"
          //   }]
          // },
          "data": [
            { "id": "AN", "value": "0.82", "showLabel": "1" },
            { "id": "AP", "value": "2.04", "showLabel": "1" },
            { "id": "AR", "value": "1.78", "showLabel": "1" },
            { "id": "AS", "value": "0.40", "showLabel": "1" },
            { "id": "BI", "value": "2.58", "showLabel": "1" },
            { "id": "CH", "value": "1.30", "showLabel": "1" },
          ]
        }
      }).render();
    });
  }

  fcMBar() {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var myChart = new FusionCharts({
        type: "stackedcolumn2d",
        renderAt: "multi-bar",
        width: "100%",
        height: "100%",
        dataFormat: "json",
        dataSource: {
          "chart": {
            "caption": "Multi Bar Graph",
            "subcaption": " ",
            "numbersuffix": " ",
            "showsum": "1",
            "plottooltext": "$label produces <b>$dataValue</b> of energy from $seriesName",
            "theme": "fusion",
            "drawcrossline": "1",
            // "borderColor": "#262932",
            "usePlotGradientColor": "0",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            "showBorder": "1",
            "bgColor": "#FFFFFF",
            "bgAlpha": "50",
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",            "canvasBorderColor": '',
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "canvasbgColor": "#ffffff",
            "canvasbgAlpha": "100",
            "canvasBgRatio": "100",
            "canvasBgAngle": "0",
            "stroke-opacity": "0"
          },
          categories: [
            {
              category: [
                {
                  label: "Andhra Pradesh"
                },
                {
                  label: "Telangana"
                },
                {
                  label: "Delhi"
                },
                {
                  label: "Mumbai"
                },
                {
                  label: "Kerala"
                },
                {
                  label: "Assam"
                }
              ]
            }
          ],
          dataset: [
            {
              seriesname: "Issues/Net Utilization",
              data: [
                {
                  value: "400"
                },
                {
                  value: "830"
                },
                {
                  value: "500"
                },
                {
                  value: "420"
                },
                {
                  value: "790"
                },
                {
                  value: "380"
                }
              ]
            },
            {
              seriesname: "Receipts",
              data: [
                {
                  value: "350"
                },
                {
                  value: "620"
                },
                {
                  value: "410"
                },
                {
                  value: "370"
                },
                {
                  value: "720"
                },
                {
                  value: "310"
                }
              ]
            },
            {
              seriesname: "Stock Counts",
              data: [
                {
                  value: "210"
                },
                {
                  value: "400"
                },
                {
                  value: "450"
                },
                {
                  value: "180"
                },
                {
                  value: "570"
                },
                {
                  value: "270"
                }
              ]
            },
            {
              seriesname: "Discards",
              data: [
                {
                  value: "180"
                },
                {
                  value: "330"
                },
                {
                  value: "230"
                },
                {
                  value: "160"
                },
                {
                  value: "440"
                },
                {
                  value: "350"
                }
              ]
            },
            {
              seriesname: "Transfers",
              data: [
                {
                  value: "60"
                },
                {
                  value: "200"
                },
                {
                  value: "200"
                },
                {
                  value: "50"
                },
                {
                  value: "230"
                },
                {
                  value: "150"
                }
              ]
            }
          ]
        }
      }).render();
    });
  }

  fcMultichart() {
    FusionCharts.ready(function () {
      var salesAnlysisChart = new FusionCharts({
        type: 'mscombi2d',
        renderAt: 'fc-multi',
        width: '800',
        height: '500',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "subCaption": "",
            "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
            "yAxisName": "All issues/net utilization (All)",
            "numberPrefix": "",
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipColor": "#ffffff",
            "toolTipBorderThickness": "0",
            "toolTipBgColor": "#000000",
            "toolTipBgAlpha": "80",
            "toolTipBorderRadius": "2",
            "toolTipPadding": "5",
            "usePlotGradientColor": "0",
            "showAlternateHGridColor": "0",
            "showCanvasBorder": '0',
            "showBorder": "0",
            "showPlotBorder": "0",
            // "numberSuffix": "M",
            "bgColor": "#FFFFFF",
            "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "bar",
            "theme": "fusion"
          },
          "categories": [{
            "category": [{
              "label": "Jan-2021"
            },
            {
              "label": "Feb-2021"
            },
            {
              "label": "Mar-2021"
            },
            {
              "label": "Apr-2021"
            },
            {
              "label": "May-2021"
            },
            {
              "label": "Jun-2021"
            },
            {
              "label": "Jul-2021"
            },
            {
              "label": "Aug-2021"
            },
            {
              "label": "Sep-2021"
            },
            {
              "label": "Oct-2021"
            },
            {
              "label": "Nov-2021"
            },
            {
              "label": "Dec-2021"
            }
            ]
          }],
          "dataset": [{
            "seriesName": "Issues",
            "showValues": "1",
            "data": [{
              "value": "16000"
            },
            {
              "value": "20000"
            },
            {
              "value": "18000"
            },
            {
              "value": "19000"
            },
            {
              "value": "15000"
            },
            {
              "value": "21000"
            },
            {
              "value": "16000"
            },
            {
              "value": "20000"
            },
            {
              "value": "17000"
            },
            {
              "value": "25000"
            },
            {
              "value": "19000"
            },
            {
              "value": "23000"
            }
            ]
          },
          {
            "seriesName": "Returns",
            "renderAs": "mscombi2d",
            "data": [
            ]
          },
          {
            "seriesName": "Net Consumption",
            "renderAs": "line",
            "data": [{
              "value": "15000"
            },
            {
              "value": "16000"
            },
            {
              "value": "17000"
            },
            {
              "value": "18000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "19000"
            },
            {
              "value": "20000"
            },
            {
              "value": "21000"
            },
            {
              "value": "22000"
            },
            {
              "value": "23000"
            }
            ]
          }
          ]
        }
      }).render();
    });

  }

  fcDrillChart() {
    let fcMulti1 = this.fcMulti1, fcMulti7 = this.fcMulti7, fcLine2 = this.fcLine2, fcMulti8 = this.fcMulti8;
    FusionCharts.ready(function () {
      var myChart = new FusionCharts({
        type: "mscombi2d",
        renderAt: "Click-chart",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource
      }).render();
      myChart.configureLink(
        {
          type: "mscombi2d",
          width: "100%",
          height: "500",
          id: "linked-chart",
          renderAt: "Click-chart1",
          overlayButton: {
            show: false,
            message: "Close",
            bgColor: "#FFFFFF",
            borderColor: "#6957DA",
            font: "Source Sans Pro",
            fontColor: "#6957DA",
            fontSize: "15",
            bold: "0"
          }
        },
        0
      );
    });
    // Main Chart
    const dataSource = {
      "chart": {
        "caption": "",
        "subCaption": "",
        "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
        "yAxisName": "All issues/net utilization (All)",
        "numberPrefix": "",
        "divlineColor": "#999999",
        "divLineIsDashed": "1",
        "divLineDashLen": "1",
        "divLineGapLen": "1",
        "toolTipColor": "#ffffff",
        "toolTipBorderThickness": "0",
        "toolTipBgColor": "#000000",
        "toolTipBgAlpha": "80",
        "toolTipBorderRadius": "2",
        "toolTipPadding": "5",
        "usePlotGradientColor": "0",
        "showAlternateHGridColor": "0",
        "showCanvasBorder": '0',
        "showBorder": "0",
        "showPlotBorder": "0",
        // "numberSuffix": "M",
        "bgColor": "#FFFFFF",
        "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
        "exportEnabled": "1",
        "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
        "exportTargetWindow": "_self",
        "exportFileName": "bar",
        "theme": "fusion"
      },
      "categories": [{
        "category": fcMulti1
      }],
      "dataset": [{
        "seriesName": "Issues",
        "showValues": "1",

        "data": fcMulti7
        //  [  {  "value": "16000",  "link": "newchart-json-bar1"},{  "value": "20000",  "link": "newchart-json-bar2"},{  "value": "18000"},
        // {  "value": "19000"},{  "value": "15000"},{  "value": "21000"},{  "value": "16000"},{  "value": "20000"},
        // {  "value": "17000"},{  "value": "25000"},{  "value": "19000"},{  "value": "23000"}
        // ]
      },
      {
        "seriesName": "Returns",
        "renderAs": "mscombi2d",
        "data": [
        ]
      },
      {
        "seriesName": "Net Consumption",
        "renderAs": "line",
        "data": fcLine2
        //  [{ "value": "15000" }, { "value": "16000" }, { "value": "17000" },
        // { "value": "18000" }, { "value": "19000" }, { "value": "19000" }, { "value": "19000" },
        // { "value": "19000" }, { "value": "20000" }, { "value": "21000" }, { "value": "22000" }, { "value": "23000" }
        // ]
      }
      ],
      //linked
      linkeddata: [
        {
          id: "bar1",
          linkedchart: {
            "chart": {
              "caption": "",
              "subCaption": "",
              "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
              "yAxisName": "All issues/net utilization (All)",
              "numberPrefix": "",
              "divlineColor": "#999999",
              "divLineIsDashed": "1",
              "divLineDashLen": "1",
              "divLineGapLen": "1",
              "toolTipColor": "#ffffff",
              "toolTipBorderThickness": "0",
              "toolTipBgColor": "#000000",
              "toolTipBgAlpha": "80",
              "toolTipBorderRadius": "2",
              "toolTipPadding": "5",
              "usePlotGradientColor": "0",
              "showAlternateHGridColor": "0",
              "showCanvasBorder": '0',
              "showBorder": "0",
              "showPlotBorder": "0",
              // "numberSuffix": "M",
              "bgColor": "#FFFFFF",
              "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
              "exportEnabled": "1",
              "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
              "exportTargetWindow": "_self",
              "exportFileName": "bar",
              "theme": "fusion"
            },
            "categories": [{
              "category": fcMulti1
            }],
            "dataset": [{
              "seriesName": "Issues",
              "showValues": "1",
              "data": fcMulti8
              // [{ "value": "16000" }, { "value": "20000" }, { "value": "18000" }, { "value": "19000" },
              // { "value": "15000" }, { "value": "21000" }, { "value": "16000" }, { "value": "20000" },
              // { "value": "17000" }, { "value": "25000" }, { "value": "19000" }, { "value": "23000" }
              // ]
            },
            {
              "seriesName": "Returns",
              "renderAs": "mscombi2d",
              "data": [
              ]
            },
            {
              "seriesName": "Net Consumption",
              "renderAs": "line",
              "data": fcLine2
            }
            ]
          }
        },
        {
          id: "bar2",
          linkedchart: {
            "chart": {
              "caption": "",
              "subCaption": "",
              "xAxisname": "(Click on a month bar/line to see  daily trends in that mounth)",
              "yAxisName": "All issues/net utilization (All)",
              "numberPrefix": "",
              "divlineColor": "#999999",
              "divLineIsDashed": "1",
              "divLineDashLen": "1",
              "divLineGapLen": "1",
              "toolTipColor": "#ffffff",
              "toolTipBorderThickness": "0",
              "toolTipBgColor": "#000000",
              "toolTipBgAlpha": "80",
              "toolTipBorderRadius": "2",
              "toolTipPadding": "5",
              "usePlotGradientColor": "0",
              "showAlternateHGridColor": "0",
              "showCanvasBorder": '0',
              "showBorder": "0",
              "showPlotBorder": "0",
              // "numberSuffix": "M",
              "bgColor": "#FFFFFF",
              "paletteColors": ['#7EC8E3', '#77dd77', '#FFA500'],
              "exportEnabled": "1",
              "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
              "exportTargetWindow": "_self",
              "exportFileName": "bar",
              "theme": "fusion"
            },
            "categories": [{
              "category": fcMulti1
            }],
            "dataset": [{
              "seriesName": "Issues",
              "showValues": "1",
              "data": fcMulti8
            },
            {
              "seriesName": "Returns",
              "renderAs": "mscombi2d",
              "data": [
              ]
            },
            {
              "seriesName": "Net Consumption",
              "renderAs": "line",
              "data": fcLine2
            }
            ]
          }
        }
      ]
    };
  }
  //#endregion

  ExportCSV() {
    this.downloadFile(this.arrayList, 'overview');
  }

  downloadFile(data: any, filename = 'data') {
    //debugger;
    let csvData = this.ConvertToCSV(data, Object.keys(this.arrayList[0]));
    //console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any, headerList: any) {
    //debugger;
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';


    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

}