<div class="card p-3 pb-0 pt-2">
    <div class="card-header shadow-none p-2 pb-1 pt-2">
        <div class="h6 float-start">Bulletin board</div>
    </div>
    <div class="card p-0 pb-0 mt-3">
        <div class="card-header shadow-none p-2 pb-1 pt-2">
            <h6 class="mb-0">Token expiry</h6>
        </div>
        <div class="card-body p-2 mt-0 mb-0">
            <form>
                <div class="row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row mb-2">
                            <div class="form-group col-xl-12">
                                <label for="textBox">Authentication token expiry <span
                                        class="text-danger">*</span></label>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" placeholder="Authentication token expiry" value="" class="form-control" id="">
                                    </div>
                                    <div class="col-sm-4">
                                        day(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="text-end pb-3">
        <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgBltnPrmsn">Save</button>
    </div>
</div>