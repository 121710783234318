<form>
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="card shadow-sm p-0 mt-0 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Push Notifications</div>
                </div>
                <div class="card-body p-2">
                    <small>Specify the push notifications to be enabled or disabled for each event</small>
                    <div class="form-group col-xl-12 mt-3 mb-2">
                        <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                            notifications</label><br />
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for asset heating events
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for asset freezing events
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for asset performance events
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow-sm p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Heating</div>
                </div>
                <div class="card-body p-2">
                    <div class="small">
                        Specify the thresholds for heating events where cumulative heat exposure is greater
                        than expected duration in given time period. Multiple event summaries can be
                        configured by using the parameters. For eg. Cumulative Heat exposure greater than 24
                        hours within 2 days
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>Cumulative heat exposure (>= hours)</th>
                                                <th>Duration (<= days)</th>
                                                <th>Include Facility tags</th>
                                                <th>Exclude Facility tags</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>48</td>
                                                <td>4</td>
                                                <td>CCP,DVS,CVS,BVS</td>
                                                <td></td>
                                                <td><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Cumulative heat exposure >=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Cumulative heat exposure" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> hour(s)</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Duration &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Duration" value="" class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include facility with categories
                                </label>
                                <input type="text" placeholder="Include facility with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facility with categories
                                </label>
                                <input type="text" placeholder="Exclude facility with categories" value=""
                                    class="form-control" id="">
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>&nbsp;
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow=true">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow-sm p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Freezing</div>
                </div>
                <div class="card-body p-2">
                    <div class="small">
                        Specify the thresholds for freezing events where cumulative cold exposure is greater
                        than expected duration in given time period. Multiple event summaries can be
                        configured by using the parameters. For eg. Cumulative cold exposure greater than 6
                        hours within 1 day
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow1">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>Cumulative freezing exposure (>= hours)</th>
                                                <th>Duration (<= days)</th>
                                                <th>Include Facility tags</th>
                                                <th>Exclude Facility tags</th>
                                                <th *ngIf="cnfgEvntSmPrmsn"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>12</td>
                                                <td>3</td>
                                                <td>DVS,CCP,CVS,BVS</td>
                                                <td></td>
                                                <td *ngIf="cnfgEvntSmPrmsn"><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow1=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow1">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Cumulative freezing exposure >=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Cumulative freezing exposure" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> hour(s)</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Duration &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Duration" value="" class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include facility with categories
                                </label>
                                <input type="text" placeholder="Include facility with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facility with categories
                                </label>
                                <input type="text" placeholder="Exclude facility with categories" value=""
                                    class="form-control" id="">
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>&nbsp;
                            <button type="button" class="btn btn-sm btn-danger"
                                (click)="isShow1=true" *ngIf="cnfgEvntSmPrmsn">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Good Asset Performance By Facility</h6>
                </div>
                <div class="card-body p-2">
                    <small>Specify the thresholds for events where temperature is within range for
                        identified assets in given time period</small>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow2">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>% of time temperature is within range (>= %)</th>
                                                <th>Over a period of (months)</th>
                                                <th>Include asset types</th>
                                                <th>Exclude asset types</th>
                                                <th>Include Facilities with tags</th>
                                                <th>Exclude Facilities with tags</th>
                                                <th>Users (tags) responsible for maintaining good performance</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>96</td>
                                                <td>3</td>
                                                <td>ILR,Deep freezer,Walk-in cooler,Walk-in freezer</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow2=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow2">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">% of time temperature is within range >=</label>
                                <input type="text" placeholder="% of time temperature is within range" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include asset types
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">ILR</option>
                                        <option value="WY">Deep Freezer</option>
                                        <option value="WY">Walk-in cooler</option>
                                        <option value="WY">Walk-in Freezer</option>
                                        <option value="WY">Radiant warmer</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude users with categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">ILR</option>
                                        <option value="WY">Deep Freezer</option>
                                        <option value="WY">Walk-in cooler</option>
                                        <option value="WY">Walk-in Freezer</option>
                                        <option value="WY">Radiant warmer</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include facilities with categories
                                </label>
                                <input type="text" placeholder="Include facilities with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facilities with categories
                                </label>
                                <input type="text" placeholder="Exclude facilities with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Users (categories) responsible for maintaining good performance
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow2=true">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>
            </div>
        </div>
    </div>
</form>