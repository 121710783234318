import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { items } from 'fusioncharts';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-prchs-indent-status',
  templateUrl: './prchs-indent-status.component.html',
  styleUrls: ['./prchs-indent-status.component.css']
})
export class PrchsIndentStatusComponent extends BaseComponent implements OnInit {

  ordData: any; ordSmrDtls: any; ordrId: any; ordrStsForm: FormGroup; isSbmtd: boolean = false; qntutyLst: any[] = []; cnsgnBkngDtls: any[] = [];
  noRcrdsTxt = environment.TblNoRcrds; isEdit: boolean = false; edtIndntId: any = ''; dt2day = new Date(); frmDate = ''; dt2mx = new Date(); 
  indntDtls: any; isCnsgnStr = this.usrLgnDtls?.producerId; ttlQty = 0;


  constructor(public glblSvc: GlobalService, public fb: FormBuilder, public router: Router) { super(glblSvc); 
    this.glblSvc.sbjctOrdCnsgnee.subscribe(s => { this.ordData = s[0].ordDtls, this.ordSmrDtls = s[0].ordSmry, this.ordrId = s[0].ordId,
       this.cnsgnBkngDtls = s[0].cnsgnDtls });
    this.ordrStsForm = this.fb.group({ initiatedQty: ['', Validators.required], arrival_date: ['', Validators.required]}); 
     
    let a: any = this.ordData[0]?.supply_period_end, b = a.split("-"), c = this.GetMonthDates(+b[1], +b[0]);
    this.dt2mx = new Date(c.split('...')[1]);
    this.dt2day = new Date(this.ordData[0]?.supply_period_from);
  }

  ngOnInit(): void {
    this.getIndntsDtls(), this.getQntityLst();
  }

  get fc() {
    return this.ordrStsForm.controls;
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  getIndntsDtls() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsgnSupply, environment.apiMthds.getIndntDtlsSpl 
      + this.ordSmrDtls?.supplyid, null, 12).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.returnCode == 1) 
          this.indntDtls = this.result?.data[0];
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  onSubmit() {
    if (this.ordrStsForm.invalid) 
      this.glblSvc.onTstr('w', 'Kindly enter the supply quantity');
    else {
      let payLoad = this.ordrStsForm.getRawValue(); payLoad.producerConsigneeSupplyId = this.indntDtls?.consigneSupplyId, 
      payLoad.consigneeNo =  this.indntDtls?.supplyOrderNo, payLoad.arrivalDate = this.dateConverterTo(payLoad.arrival_date) + this.tmF.zeroTM;
      delete payLoad.arrival_date;

      if(this.isEdit)
        payLoad.id = this.edtIndntId;
      else 
        payLoad.id = 0;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.cnsnBkng, environment.apiMthds.add, payLoad, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message), this.isSbmtd = false, this.ordrStsForm.reset(), this.isEdit = false, this.edtIndntId = '',
            this.getQntityLst();
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }

  }

  onOrdDtls(data: any) {
    localStorage.setItem('sbjctOrdDtls', JSON.stringify(data)), this.glblSvc.sbjctOrdDtls.next(data), this.router.navigate(['home/prchs-ord-dtls'])
  }

  getQntityLst() {
    let tq :any[] = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsnBkng, environment.apiMthds.getCnsgnQntityLst + 
      this.ordSmrDtls?.supplyid, null, 12).then((res) => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.qntutyLst = this.result.data;
          this.qntutyLst.forEach(a => { tq.push(a.quantity) });
          if (this.qntutyLst.length > 0)
              this.ttlQty = tq.reduce((x, value) => { x += value; return x });
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  onQuanEdt(data: any) {
    this.isEdit = true, this.edtIndntId = data?.indentid, this.ordrStsForm.patchValue({ initiatedQty: data.quantity, arrival_date: new Date(data?.arrival_date)});
  }

  clearBSDate(ctrl: string) {
    this.ordrStsForm.get(ctrl)?.setValue('');
  }

  onQuantityDel(id: any) {
    if (confirm('Are you sure want to delete the supply quantity ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.cnsnBkng, environment.apiMthds.delQntityInd + id, null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message), this.getQntityLst();
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onCancelEdit() {
    this.isEdit = false, this.ordrStsForm.get('initiatedQty')?.setValue(''), this.ordrStsForm.get('arrival_date')?.setValue('');
  }

}
