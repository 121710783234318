<form [formGroup]="ordrStsForm" autocomplete="off">
    <div class="card">
        <div class="card-body pb-0">
            <h4>Supply Indent</h4>
            <br>
            <div class="row p-0 mt-2">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="p-2 pt-0 row">
                        <div class="col-md-4">
                            <div class="card" style="background-color: #d5ddee;">
                                <div class="card-body">
                                    <span>eVIN Consignment No. :<b>{{this.ordSmrDtls?.purchase_no}}</b></span>&nbsp;
                                    <div class="small mt-3">
                                        <div>Product Name: {{ordData[0]?.productName}}</div>
                                        <div>Consignee Name: {{ordSmrDtls?.storename}}</div>
                                        <div>Consignment Quantity: {{ordSmrDtls?.quantity}}</div>
                                        <div>Supply Period: {{ordData[0]?.supplyPeriodFrom | date: 'MM-yyyy' }} to
                                            {{ordData[0]?.supplyPeriodEnd | date: 'MM-yyyy' }}</div>
                                    </div>
                                    <div class="small">Created on {{ordSmrDtls?.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{ordSmrDtls?.createdby}} - {{ordSmrDtls?.createduserid}}</a>
                                    </div>
                                    <div class="small">Last updated on {{ordSmrDtls?.updatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{ordSmrDtls?.updatedby}} - {{ordSmrDtls?.updateduserid}}</a>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <div class="card-body">
                                    <span>eVIN Indent Id:<b>{{ordrId}}</b></span>&nbsp;
                                    <div class="small">                                
                                        <div>Manufacturer Name: {{ordData[0]?.producerName}}</div>                                
                                        <div>Supply Period: {{ordData[0]?.supplyPeriodFrom | date: 'MM-yyyy' }} to
                                            {{ordData[0]?.supplyPeriodEnd | date: 'MM-yyyy' }}</div>
                                        <div class="mt-0">Supply Order No #: {{ordData[0]?.reference}}</div>
                                    </div>
                                    <div class="small">Created on {{ordData[0]?.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{ordData[0]?.createdName}}</a>
                                    </div>
                                    <div class="small">Last updated on {{ordData[0]?.updatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{ordData[0]?.updatedName}}</a>
                                    </div>
                                </div>
                            </div>                            
                        </div>                       
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <h4>Consignee Period: </h4>
                <div class="col-md-12 mt-3" *ngIf="isCnsgnStr == 'National'">
                    <div class="row">
                        <div class="form-group col-md-3">
                            <label for="textBox">Supply Period<sup class="text-danger">*</sup></label>
                            <div class="input-group">
                                <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                    (bsValueChange)="onChngDT($event)" 
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode:'month', 
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="supplyPeriodFrom"
                                    [(ngModel)]="frmDate" placeholder="From" [minDate]="dt2day" [maxDate]="toMaxDt" 
                                    [ngClass]="{'is-invalid': isSbmtd && fc.supplyPeriodFrom.errors}" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                        [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpFD.hide();clearBSDate('supplyPeriodFrom')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>

                                <div *ngIf="isSbmtd && fc.supplyPeriodFrom.errors" class="invalid-feedback">
                                    <div *ngIf="fc.supplyPeriodFrom.errors?.required">Supply period start date is required</div>
                                </div> 
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="textBox"></label>
                            <div class="input-group mt-2">
                                <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                    [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)" (bsValue)="ctDte"
                                    class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                    [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode:'month', 
                                        containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                    onkeypress='return event.charCode == 8' formControlName="supplyPeriodEnd"
                                    bsDatepicker [minDate]="frmDate" [maxDate]="toMaxDt"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.supplyPeriodEnd.errors}" readonly>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                        [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                        (click)="dpED.hide();clearBSDate('supplyPeriodEnd')"> <i class="fa fa-times"></i>
                                    </button>
                                </div>
                                <div *ngIf="isSbmtd && fc.supplyPeriodEnd.errors" class="invalid-feedback">
                                    <div *ngIf="fc.supplyPeriodEnd.errors?.required">Supply period end date is required</div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Enter Quantity<sup class="text-danger">*</sup></label>
                                <input type="text" class="form-control form-control-sm" placeholder="Enter Quantity"
                                    maxlength="16" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    formControlName="quantity"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.quantity.errors}">
                                    <span class="text-secondary">(Total Requirement for Supply Period {{rcmdQntity}})</span>
                                <div *ngIf="isSbmtd && fc.quantity.errors" class="invalid-feedback">
                                    <div *ngIf="fc.quantity.errors?.required">Supply Quantity is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group mt-1">
                                <button class="btn btn-light mt-4" *ngIf="isEdit" (click)="onCancelEdit()">Cancel Edit</button>&nbsp;
                                <button class="btn btn-sm mt-4 btn-primary"
                                    (click)="isSbmtd=true; onSubmit()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3">
                    <p><b>Consignee Supply List : </b></p>
                    <div class="table_spacing table-responsive table-row-spacing">
                        <table class="table table-striped table-sm table-bordered" id="table_detail">
                            <thead>
                                <tr>
                                    <th class="text-center">S.No</th>
                                    <th class="text-center">Consignee Supply ID</th>
                                    <th class="text-center">Quantity</th>  
                                    <th class="text-center">Supply Period From</th>
                                    <th class="text-center">Supply Period End</th>                                  
                                    <th>Created On</th>                                    
                                    <th>Created By</th>
                                    <th class="text-center" *ngIf="isCnsgnStr == 'National'">Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="qntutyLst.length != 0">
                                <!--(click)="openModal(x)"-->
                                <tr *ngFor="let x of qntutyLst; let i=index">
                                    <td class="text-center">{{i+1}}</td>
                                    <td class="text-center" (click)="onOrdDtls(x)" style="cursor: pointer;">{{x?.supply_order_no}}</td>
                                    <td class="text-center">{{x?.quantity}}</td>
                                    <td class="text-center">{{x?.supply_period_from | date: 'MM-yyyy'}}</td>
                                    <td class="text-center">{{x?.supply_period_end | date: 'MM-yyyy'}}</td>
                                    <td>{{x?.created_on | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                                    <td>{{x?.createdby}} <br> {{x?.createduserid}}</td>
                                    <td class="text-center" *ngIf="isCnsgnStr == 'National'">
                                        <!-- <div *ngIf="!isActn"> -->
                                            <a (click)="onQuanEdt(x);"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                            <a (click)="onQuantityDel(x?.supplyid)"><i
                                                class="fa fa-trash"></i></a>
                                        <!-- </div> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-center"><b>Total Quantity</b></td>
                                    <td class="text-center"><b>{{ttlQty}}</b></td>
                                    <td colspan="5"></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="qntutyLst.length == 0">
                                <tr>
                                    <td colspan="10">{{noRcrdsTxt}}</td>
                                </tr>
                            </tbody>
                        </table>    
                    </div>
                </div>
                <div class="row" *ngIf="isFreze">
                    <div class="col-xl-12" *ngIf="!isActn">
                        <button class="btn btn-sm mt-4 btn-primary float-end"><a data-toggle="modal" data-target="#mdlRD">Freeze Supply Cycle</a></button>
                    </div>
                </div>
                <div class="modal" id="mdlRD">
                    <div class="modal-dialog modal-sm">
                        <div class="modal-content">
                            <!-- <div class="modal-header">
                                <h6 class="modal-title">Logger Error Code List</h6>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div> -->
                            <div class="modal-body">
                                <div>Once Freezed changes cannot be done. Do you wish to continue?</div>
                            </div>
                            <div class="modal-footer card-footer" style="padding: 6px !important;">
                                <button type="button" class="btn btn-sm btn-light close" data-dismiss="modal" aria-label="Close">Close</button>
                                <button type="button" class="btn btn-sm btn-primary" data-dismiss="modal" aria-label="Close" (click)="onCnfmSuply()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>