import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-facility-group-view',
  templateUrl: './facility-group-view.component.html',
  styleUrls: ['./facility-group-view.component.css']
})
export class FacilityGroupViewComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }
  
  ngOnInit(): void {
  }

}
