import { Component, OnInit } from '@angular/core';
import * as FusionCharts from 'fusioncharts';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-ts-overview',
  templateUrl: './ts-overview.component.html',
  styleUrls: ['./ts-overview.component.css']
})

export class TsOverviewComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }
  ngOnInit(): void {
    this.glblSvc.sbjctSpnr.next(true), Promise.all([this.fcDoughNut('pie2d', 'fcpieInv'), this.fcDoughNut('column2d', 'fcbarInv') 
   ]).then(() => { this.glblSvc.sbjctSpnr.next(false); })
  }

  fcDoughNut(chrtType: string, divId: string) {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({
      key: environment.fsnLicKey,
      creditLabel: false
    });
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
           // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "1",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",            
            // "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
      // if (divId == 'fcpie2D')
      //   setTimeout(() => { fc.dispose(), $('#shwBtn').click(); }, 5000);
    });
  }

}
