export enum ApiCalls {
    get = 'Get',
    post = 'Post',
    put = 'Put',
    del = 'Delete',
    patch = 'Patch',
}

export enum DateFrmts {
    dmy = 'dd-MM-yyyy',
    dmy2 = 'dd/MM/yyyy',
    dym = 'dd-yyyy-MM',
    mdy = 'MM-dd-yyyy',
    myd = 'MM-yyyy-dd',
    ymd = 'yyyy-MM-dd',
    ydm = 'yyyy-dd-MM',
    _ymd = 'yyyyMMdd',
    ym_ = 'yyyy-MM-',
    ymd_ = 'yyyy-MM',
    MY = 'MMM yyyy',
    MY2 = 'MMM-yyyy',
    DMY_ = 'MMM dd, yyyy',
    _DMY = 'dd-MMM-yyyy',
    yyy = 'yyyy',
}

export enum TimeFrmts {
    hm = 'hh:mm',
    hms = 'hh:mm:ss',
    _hms = 'hhmmss',
    hma = 'hh:mm aa',
    hmsa = 'hh:mm:ss aa',
    noTM = '',
    zeroTM = ' 00:00:00',
    lastTM = ' 23:59:59',
}