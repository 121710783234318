import { NgModule } from '@angular/core';

import { LeafletDirective, LeafletLayerDirective, LeafletLayersDirective, LeafletLayersControlDirective, LeafletBaseLayersDirective } from 'src/app/OSM_LeafLet/eLeaflet/src/public-api';

@NgModule({
  exports: [
		LeafletDirective,
		LeafletLayerDirective,
		LeafletLayersDirective,
		LeafletLayersControlDirective,
		LeafletBaseLayersDirective
	],
	declarations: [
		LeafletDirective,
		LeafletLayerDirective,
		LeafletLayersDirective,
		LeafletLayersControlDirective,
		LeafletBaseLayersDirective
	]
})

export class LeafletModule { }