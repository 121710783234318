<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="mnfrLst"><a class="nav-link" id="Manufacturer-tab" data-bs-toggle="tab"
                    href="#Manufacturer" role="tab" aria-controls="Manufacturer" aria-selected="true"
                    (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">Manufacturers</a>
            </li>
            <li class="nav-item" *ngIf="cratMnfr"><a class="nav-link" id="Create-Manufacturer-top-tab" data-bs-toggle="tab"
                    href="#top-Create-Manufacturer" role="tab" aria-controls="Create-Manufacturer"
                    aria-selected="false" (click)="onTabSelection('new')" [ngClass]="paramTab === 'new' ? 'active' : ''">Create manufacturer</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="Manufacturer" role="tabpanel" aria-labelledby="Manufacturer-tab"
             [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-manufacturers-view></evin-manufacturers-view>
            </div>
            <div class="tab-pane fade" id="top-Create-Manufacturer" role="tabpanel" aria-labelledby="Create-Manufacturer-top-tab"
             [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-create-manufacturers></evin-create-manufacturers>
            </div>
        </div>
    </div>
</div>