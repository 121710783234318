<div class="row mt-2">
    <div class="col-sm-12">
        <form>
            <div class="card shadow-sm p-0 mt-0 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Push Notifications</div>
                </div>
                <div class="card-body p-2">
                    <small>Specify the push notifications to be enabled or disabled for each event</small>
                    <div class="form-group col-xl-12 mt-3 mb-2">
                        <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                            notifications</label><br />
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for stock out events
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for stock expiry events
                            </label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                            <label class="form-check-label" for="flexCheckChecked">
                                Enable notification for inventory performance events
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow-sm p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Stock Outs</div>
                </div>
                <div class="card-body p-2">
                    <div class="small">
                        Specify the thresholds for summarization of stock out events
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>Cumulative freezing exposure (>= hours)</th>
                                                <th>Duration (<= days)</th>
                                                <th>Include Facility tags</th>
                                                <th>Exclude Facility tags</th>
                                                <th *ngIf="cnfgEvntSmPrmsn"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>12</td>
                                                <td>3</td>
                                                <td>DVS,CCP,CVS,BVS</td>
                                                <td></td>
                                                <td *ngIf="cnfgEvntSmPrmsn"><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Stock out duration &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-9">
                                        <input type="text" placeholder="Stock out duration" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-xl-3">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include material categories
                                </label>
                                <input type="text" placeholder="Include material categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facility with categories
                                </label>
                                <input type="text" placeholder="Exclude facility with categories" value=""
                                    class="form-control" id="">
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow=true">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card shadow-sm p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Stock Expiry</div>
                </div>
                <div class="card-body p-2">
                    <div class="small">
                        Specify the thresholds for stock expiry events where days of available stocks are
                        expiring by a given duration. Multiple event summaries can be configured using the
                        parameters. For eg. 15 days of stock expiring in 90 days or 30 days of stock
                        expiring in 60 days
                    </div>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow1">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>Days of available stock (>= days)</th>
                                                <th>Stock expiry duration (<= days)</th>
                                                <th>Include material tags</th>
                                                <th>Exclude material tags</th>
                                                <th>Include Facility tags</th>
                                                <th>Exclude Facility tags</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>15</td>
                                                <td>60</td>
                                                <td>RI Vaccines</td>
                                                <td></td>
                                                <td>CCP,DVS,CVS,BVS</td>
                                                <td></td>
                                                <td><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow1=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow1">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Days of available stock >=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Days of available stock" value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Stock expiry duration &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Stock expiry duration " value=""
                                            class="form-control" id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include facility categories
                                </label>
                                <input type="text" placeholder="Include facility categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude facility categories
                                </label>
                                <input type="text" placeholder=" Exclude facility categories" value=""
                                    class="form-control" id="">
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow1=true">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Good Inventory Performance By Facility</h6>
                </div>
                <div class="card-body p-2">
                    <small>Specify the thresholds for events where there are no stock outs or excess stock
                        of specified percentage category of materials</small>
                    <div class="row mt-3">
                        <div class="col-sm-12">
                            <div [hidden]="!isShow2">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0">
                                        <thead>
                                            <tr>
                                                <th>SI.No.</th>
                                                <th>% of time materials are in stock (>= %)</th>
                                                <th>% of time there is no excess stock (>= %)</th>
                                                <th>% of materials with no stock outs or excess stock (>= %)</th>
                                                <th>Over a period of (months)</th>
                                                <th>Include materials with tags</th>
                                                <th>Exclude materials with tags</th>
                                                <th>Include Facilities with tags</th>
                                                <th>Exclude Facilities with tags</th>
                                                <th>Users (tags) responsible for maintaining good performance</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>100</td>
                                                <td>85</td>
                                                <td>100</td>
                                                <td>3</td>
                                                <td>RI Vaccines</td>
                                                <td></td>
                                                <td>CCP</td>
                                                <td></td>
                                                <td>CCH</td>
                                                <td><a><i class="fa fa-trash"
                                                            aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                                class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-footer text-end p-3">
                                    <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn"
                                        (click)="isShow2=false">Add</button>&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="isShow2">
                        <div class="row mt-3">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">% of time materials are in stock >=</label>
                                <input type="text" placeholder="% of time materials are in stock" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">% of time there is no excess stock >=</label>
                                <input type="text" placeholder="% of time there is no excess stock " value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">% of materials with no stock outs or excess stock
                                    >=</label>
                                <input type="text" placeholder="% of materials with no stock outs or excess stock"
                                    value="" class="form-control" id="">
                            </div>
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Over a period of</label>
                                <div class="row">
                                    <div class="col-xl-8">
                                        <input type="text" placeholder="Over a period of" value="" class="form-control"
                                            id="">
                                    </div>
                                    <div class="col-xl-4">
                                        <p class="small"> months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-4 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude material categories
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="form-group col-sm-3 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Include Facilities with categories
                                </label>
                                <input type="text" placeholder="Include Facilities with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-3 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Exclude Facilities with categories
                                </label>
                                <input type="text" placeholder="Exclude Facilities with categories" value=""
                                    class="form-control" id="">
                            </div>
                            <div class="form-group col-sm-6 mt-2">
                                <label class="textBox" for="flexCheckDefault">
                                    Users (categories) responsible for maintaining good performance
                                </label>
                                <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                                    <optgroup label="">
                                        <option value="AL">CCH</option>
                                        <option value="WY">COVID vaccine</option>
                                        <option value="WY">CCT(R)</option>
                                        <option value="WY">CCO (S)</option>
                                        <option value="WY">CMO</option>
                                        <option value="WY">CMO (GMOSD)</option>
                                        <option value="WY">DC (IMM)</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div class="card-footer text-end p-3">
                                <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                                <button type="button" class="btn btn-sm btn-danger"  *ngIf="cnfgEvntSmPrmsn" (click)="isShow2=true">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>
            </div>
        </form>
    </div>
</div>