<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="form-group col-xl-4">
        <select id="inputState-43" class="form-select">
            <option selected>All receipts</option>
            <option>Direct receipts (excluding transfer Indents)</option>
            <option>Receipts via transfer Indents</option>
            
        </select>
    </div>
    <div class="col-xl-8 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div> 
<div class="row mt-4">
    <div class="col-xl-12">
        <div class="text-center">
            <h6 class="mb-0">All receipts</h6>
            <small>From: Feb 2021   To: Apr 2021   Material tag: RI Vaccines   Facility: Falka Bazar CD </small>
        </div>
    </div>
</div>
<div class="row mt-2 mb-3">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto">Material</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                         <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                         <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                         <div><a [routerLink]="">BCG (dose)</a></div>  
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                         <div><a [routerLink]="">BCG (dose)</a></div> 
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>