import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-orders',
  templateUrl: './cnfg-orders.component.html',
  styleUrls: ['./cnfg-orders.component.css']
})

export class CnfgOrdersComponent extends BaseComponent implements OnInit {

  lstMatTags: any[] = []; lstFacTags: any[] = []; dropdownSettings = {}; slctdFac: any[] = []; isIL1 = false; isIL2 = false; isIL3 = false;
  slctdProd: any[] = []; lstPranths: any[] = []; selectedStoreId: any; str = ''; override: any; lstOrds: any[] = []; configid: any; PFTxt = '';
  ovrOrdRec: any[] = []; chngOrdQnty: any[] = []; PartFulfl: any[] = []; CanOrd: any[] = []; lstTblData: any; OORTxt = ''; COQTxt = '';
  noRcrdTxt = environment.TblNoRcrds; oorRsn = false; coqRsn = false; pfRsn = false; psRsn = false; caoRsn = false; indntDataExpt = false;
  lstHrs: any[] = []; userBadges: any[] = []; usrBdges: any[] = []; owners: any[] = []; userLst: any[] = []; defaultUsrTags: any[] = [];
  defaultUsers: any[] = []; slctdDefauFacTag: any[] = []; usrDrpdwn = { singleSelection: false, idField: 'id', textField: 'userName',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; txnRsnCnt = 0; userlgndtls: any = null;
  logoFile: any; invcTmplt: any; shipTmplt: any; logoName = 'LLL'; invcName = 'III'; shipName = 'SSS'; matTags: any[] = []; strTags: any[] = [];
  isChk = false; CAOTxt = ''; cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData')); usrPrmsn: any = null; cnfgOrdPrmsn = true;
  tempTblLst: any[] = [];

  cnfgOrdFrm = this.fb.group({
    configJson: this.fb.group({
      general: this.fb.group({
        receipt_order_generation: this.fb.group({
          generate_indent_receipt_when_ordertransactions_are_received: new FormControl(false),
          just_capture_demand_do_not_generate_indent_receipt: new FormControl(false),
        }),
        automatic_posting_of_issue_or_receipts: this.fb.group({ automatically_post_issues_receipts_on_fulfilling_order: new FormControl({value: true, disabled: true}) }),
        transporter: this.fb.group({
          transporter_specification_order_mandatory_before_shipping: new FormControl(false),
          appendtransporter_detailsto_order_status_message_on_shipping: new FormControl(false)
        }), allow_empty_orders: new FormControl(false),
        mark_indent_as_confirmed: this.fb.group({ allow_marking_indent_as_confirmed_during_order_creation: new FormControl(false), }),
        disable_auto_allocation: this.fb.group({ make_auto_allocation_disable_on_marking: new FormControl(false), }),
        allocate_stocks_on_confirmation: this.fb.group({ allow_allocating_stocks_on_marking_order_status_as_confirmed: new FormControl(false) }),
        auto_assign_material_status: this.fb.group({ automatically_assign_the_material_status_to_items_when_order_is_allocated: new FormControl(false) }),
        payment_options: new FormControl(''), package_sizes: new FormControl(''), issuing_store_default: new FormControl(''),
        order_price: this.fb.group({ disable_order_pricing: new FormControl(false) }),
        transfer_release_orders: this.fb.group({ rename_transfer_orders_as_release_orders: new FormControl(false) })
      }),
      mandatory_order_fields: this.fb.group({
        before_shipping_an_order: this.fb.group({
          make_issue_reference_num_mandatory_before_shipping: new FormControl(false),
          make_estimated_date_of_arrival_mandatory_before_shipping: new FormControl(false)
        }), reference_num: this.fb.group({
          make_receipt_reference_num_mandatory_at_the_time_of_indent_creation_or_editing: new FormControl(false),
          make_transfer_release_reference_num_mandatory_at_the_time_of_transfer_release_order_creation_or_editing: new FormControl(false)
        })
      }),
      orders_export: this.fb.group({
        orders_export_schedule: this.fb.group({
          enable_orders_export: new FormControl(false),
          enabled_orders_data_export: this.fb.group({
            everyday_hours: [''],
            group_of_user_tags: [[]],
            group_of_users: [[]]
          })
        })
      }),
      order_reasons: this.fb.group({
        reasons_for_overriding_order_recommendation: this.fb.group({
          value: new FormControl(''),
          mandatory: new FormControl(false)
        }), reasons_for_changing_order_quantity: this.fb.group({
          value: new FormControl(''),
          mandatory: new FormControl(false)
        }), reasons_for_partial_shipments: this.fb.group({
          value: new FormControl(''),
          mandatory: new FormControl(false)
        }), reasons_for_partial_fulfillment: this.fb.group({
          value: new FormControl(''),
          mandatory: new FormControl(false)
        }), reasons_for_cancelling_an_order: this.fb.group({
          value: new FormControl(''),
          mandatory: new FormControl(false)
        }),
      }), demand_board: this.fb.group({
        demand_board_visibility: this.fb.group({
          demand_board_is_private: new FormControl(false), demand_board_is_public: new FormControl(false),
        }), banner: new FormControl(''),
        header: new FormControl(''), footer: new FormControl('')
      }), automate_indent_receipt_creation: this.fb.group({
        enable_indent_receipt_automation: new FormControl(false), store_tags: new FormControl(''), material_tags: new FormControl(''),
        triggers_for_indent_receipt_creation: this.fb.group({
          create_order_when_item_reaches_min_stock_level: new FormControl(false),
          create_order_when_item_is_likely_to_stock_out: new FormControl(false)
        })
      }), invoice: this.fb.group({
        logo: new FormControl(''), invoice_template: new FormControl(''), shipment_template: new FormControl('')
      }), transport: this.fb.group({
        order_status_change: this.fb.group({
          automatically_mark_an_order_as_shipped_on_pick_up_of_a_consignment: new FormControl(false),
          automatically_mark_an_order_as_fulfilled_on_delivery_of_a_consignment: new FormControl(false)
        }),
        delivery_request_permission: this.fb.group({ disable_the_request_for_delivery_by_a_receiving_store: new FormControl(false) })
      }),
    }),
    configTypeId: +environment.cnfgTypes.orders, updatedBy: this.usrLgnDtls?.userId,
  });

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstPranths = s; }), this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.userBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.user),
      this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility); });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.LoadUsers(), this.StngsMultiDD(), this.loadData(),
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_id_ac') ? (this.cnfgOrdPrmsn = e.action) : '') });
    }
  }

  loadData() {
    setTimeout(() => { this.LoadCnfgOrdr(environment.cnfgTypes.orders) }, 1000),
    this.glblSvc.sbjctMstrData.subscribe(s => { this.ovrOrdRec = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.new_indent),
      this.chngOrdQnty = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.update_indent),
      this.PartFulfl = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.fulfill_indent),
      this.CanOrd = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.cancel_indent); });
  this.setRsnData({ ovrOrdRec: this.ovrOrdRec, chngOrdQnty: this.chngOrdQnty, PartFulfl: this.PartFulfl, CanOrd: this.CanOrd });
  }

  LoadUsers() {
    this.userLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.str, environment.apiMthds.getUsrsByPrnth.
        replace('pranthid', `${this.chsnDmnId}`), null).then(() => {
      if (this.result) {
        if (this.result.data)
          this.userLst = this.result.data != null ? this.result.data : [];
      }
    })
  }

  setRsnData(data: any) {
    this.lstTblData = { ovrOrdRec: data?.ovrOrdRec ?? [], chngOrdQnty: data?.chngOrdQnty ?? [], PartFulfl: data?.PartFulfl ?? [],
      CanOrd: data?.CanOrd ?? [] },
    this.oorRsn = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_overriding_order_recommendation?.mandatory ?? false, // this.oorRsn = this.lstTblData.ovrOrdRec.length == 0 ? false : this.lstTblData.ovrOrdRec[0].isReasonMandatory;
    this.coqRsn = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_changing_order_quantity?.mandatory ?? false, // this.coqRsn = this.lstTblData.chngOrdQnty.length == 0 ? false : this.lstTblData.chngOrdQnty[0].isReasonMandatory;
    this.pfRsn = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_partial_fulfillment?.mandatory ?? false, // this.pfRsn = this.lstTblData.PartFulfl.length == 0 ? false : this.lstTblData.PartFulfl[0].isReasonMandatory;
    this.caoRsn = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_cancelling_an_order?.mandatory ?? false; // this.caoRsn = this.lstTblData.CanOrd.length == 0 ? false : this.lstTblData.CanOrd[0].isReasonMandatory;
  }

  onSaveData(ctrl: string) {
    let type = (ctrl == 'OOR' ? environment.cnfgTrnsRsnType.new_indent : (ctrl == 'COQ' ? environment.cnfgTrnsRsnType.update_indent :
      (ctrl == 'PF' ? environment.cnfgTrnsRsnType.fulfill_indent : environment.cnfgTrnsRsnType.cancel_indent))),
      actT = (ctrl == 'OOR' ? 'ovrOrdRec' : (ctrl == 'COQ' ? 'chngOrdQnty' :
        (ctrl == 'PF' ? 'PartFulfl' : 'CanOrd'))), payLoad: any[] = [], reasons: any[] = [];
    // if (this.txnRsnCnt != 0) {
      this.tempTblLst.filter((f: any) => {
        reasons.push({
          id: f.reasonId ? f.reasonId : null,
          isDefault: false,
          name: f.reasonName,
          reasonType: type,
          isReasonMandatory: this.lstTblData[actT][0].isReasonMandatory,
          updatedBy: this.usrLgnDtls?.userId,
        })
      }), payLoad = reasons;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.rsnType, environment.apiMthds.svRupdate, payLoad, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message), this.txnRsnCnt = 0;
            let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
            if (allMstrs.rsnsLst)
              this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
                if (s)
                  allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                    this.glblSvc.sbjctMstrData.next(allMstrs), this.loadData();
            });
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    // }
  }

  onChkRsn(ctrl: string, IsMandtry: Boolean) {
    let actT = (ctrl == 'OOR' ? 'ovrOrdRec' : (ctrl == 'COQ' ? 'chngOrdQnty' : (ctrl == 'PF' ? 'PartFulfl' : 'CanOrd')));
    this.lstTblData[actT].forEach((e: any) => { e.isReasonMandatory = IsMandtry; }); // this.lstTblData[actT].filter((a: any) => { a.isReasonMandatory = IsMandtry; });
  }

  onAddDel(ctrl: string, rowVal: any, actType: string, isMndt: Boolean = false) {
    let type = (ctrl == 'OOR' ? environment.cnfgTrnsRsnType.new_indent : (ctrl == 'COQ' ? environment.cnfgTrnsRsnType.update_indent : (ctrl == 'PF' ? 
    environment.cnfgTrnsRsnType.fulfill_indent : environment.cnfgTrnsRsnType.cancel_indent))), actT = (ctrl == 'OOR' ? 'ovrOrdRec' : (ctrl == 'COQ' ? 'chngOrdQnty' : (ctrl == 'PF' ? 'PartFulfl' : 'CanOrd')));
    if (actType == 'a' && rowVal !='')
      if (this.lstTblData[actT].filter((e: any) => e.reasonName == rowVal).length == 0)
      this.tempTblLst = [...this.lstTblData[actT]],
      this.tempTblLst.push({reasonName: rowVal.trim(), reasonType: type, isReasonMandatory: isMndt}),
      this.onSaveData(ctrl);
        // this.lstTblData[actT].push({ reasonName: rowVal, reasonType: type, isReasonMandatory: isMndt }), this.txnRsnCnt++;
      else
        this.glblSvc.onTstr('w', 'Tag is already exists');
    else {
      if (rowVal.reasonId) {
        if (confirm('Are you sure, you want to delete.?')) {
          this.FetchData(environment.apiCalls.del, environment.apiPaths.rsnType, environment.apiMthds.delById + rowVal.reasonId, null,
              2, true, 1).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                // this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.reasonName != rowVal.reasonName);
                this.glblSvc.onTstr('s', this.result.message);
                let allMstrs = JSON.parse(<string>localStorage.getItem('lgnMstrsData'));
                if (allMstrs.rsnsLst) {
                  this.glblSvc.getAPI(environment.apiPaths.rsnType, environment.apiMthds.get_all, 2, 1).subscribe(s => {
                    if (s)
                      allMstrs.rsnsLst = s?.data ?? [], localStorage.setItem('lgnMstrsData', JSON.stringify(allMstrs)),
                        this.glblSvc.sbjctMstrData.next(allMstrs), this.loadData();
                  });
                }
              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
        }
      }
      else
        this.lstTblData[actT] = this.lstTblData[actT].filter((f: any) => f.reasonName != rowVal.reasonName);
      this.txnRsnCnt--;
    }
  }

  changeFn(event: any) {
    [this.str, this.selectedStoreId] = [event.value, ''];
    for (let s = 0; s < this.lstPranths.length; s++) {
      if (this.lstPranths[s].storeName == event.value)
        this.selectedStoreId = this.lstPranths[s].storeId;
    }
  }

  StngsMultiDD() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
      unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
  }

  onItemSelect(type: any, item: any) {
    if (type == 'inf')
      this.slctdFac.push(item.name);
    if (type == 'inp')
      this.slctdProd.push(item.name);
  }

  onSelectAll(type: any, items: any) {
    if (type == 'inf')
      this.slctdFac.push(items.name);
    if (type == 'inp')
      this.slctdProd.push(items.name);
  }

  onSave() {
    this.lstOrds;
    let payLoad = this.cnfgOrdFrm.getRawValue(), utLst: any[] = [], usrLst: any[] = [];
    payLoad.configJson.orders_export.orders_export_schedule.enabled_orders_data_export.group_of_user_tags?.forEach((e: any) => {
      utLst.push(e.id) }),  payLoad.configJson.orders_export.orders_export_schedule.enabled_orders_data_export.group_of_users?.
      forEach((e: any) => { usrLst.push(e.id) }),
      payLoad.configJson.orders_export.orders_export_schedule.enabled_orders_data_export.group_of_user_tags = utLst,
      payLoad.configJson.orders_export.orders_export_schedule.enabled_orders_data_export.group_of_users = usrLst,
      payLoad.configJson.order_reasons.reasons_for_overriding_order_recommendation.mandatory = this.oorRsn,
      payLoad.configJson.order_reasons.reasons_for_changing_order_quantity.mandatory = this.coqRsn,
      payLoad.configJson.order_reasons.reasons_for_partial_fulfillment.mandatory = this.pfRsn,
      payLoad.configJson.order_reasons.reasons_for_cancelling_an_order.mandatory = this.caoRsn,
      payLoad.configJson.order_reasons.reasons_for_partial_shipments.mandatory = this.psRsn,
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.configid = this.result.data.configTypeId, this.glblSvc.onTstr('s', this.result.message), this.LoadCnfgOrdr(this.configid);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

  LoadCnfgOrdr(configid: any) {
    let allMstrs = JSON.parse(<string>localStorage.getItem('lgnCnfgData'));
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType + configid, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            if (allMstrs?.cnfgOdrData)
                allMstrs.cnfgOdrData = this.result.data?.configJson ?? {}, localStorage.setItem('lgnCnfgData', JSON.stringify(allMstrs)),
                  this.glblSvc.sbjctCnfgData.next(allMstrs);
            let apiRspns = this.result.data;
            ['createdBy', 'createdOn', 'isDeleted', 'updatedOn', 'versionNo', 'id'].forEach(e => { delete apiRspns[e]; }),
              this.cnfgOrdFrm.patchValue(apiRspns), setTimeout(() => {
                this.matTags = [...apiRspns.configJson.automate_indent_receipt_creation.material_tags],
                this.strTags = [...apiRspns.configJson.automate_indent_receipt_creation.store_tags];
            }, 200);
            if (apiRspns.configJson.orders_export.orders_export_schedule.enable_orders_export) {
              this.indntDataExpt = true, this.cnfgOrdFrm.get('configJson')?.get('orders_export')?.get('orders_export_schedule')?.get('enable_orders_export')?.
                get('enabled_orders_data_export')?.get('everyday_hours')?.setValue(apiRspns.configJson?.orders_export?.orders_export_schedule?.
                enable_orders_export?.enabled_orders_data_export?.everyday_hours?.toString())
            }
            else
              this.indntDataExpt = false;
            
            let slctd2: any[] = [], matTag2 = [], data1: any[] = [];
            data1 = apiRspns.configJson?.orders_export?.orders_export_schedule?.enabled_orders_data_export?.group_of_user_tags;
            if (data1 != undefined && data1 != null && data1.length != 0) {
              data1?.forEach((s: any) => {                  
                if (this.userBadges.filter(f => f.id == s).length > 0) 
                  matTag2 = this.userBadges.filter(f => f.id == s)[0], slctd2.push(matTag2 != undefined ? matTag2 : {}); 
              }); 
              this.usrBdges = data1, this.defaultUsrTags = slctd2;
            } 

            let slctd3: any[] = [], matTag3 = [], data2: any[] = [];
            data2 = apiRspns.configJson?.orders_export?.orders_export_schedule?.enabled_orders_data_export?.group_of_users;
            if (data2 != undefined && data2 != null && data2.length != 0) {
              data2?.forEach((s: any) => { 
                if (this.userLst.filter((f: any) => f.id == s).length > 0) 
                  matTag3 = this.userLst.filter((f: any) => f.id == s)[0], slctd3.push(matTag3 != undefined ? matTag3 : {});
              });
              this.owners = data2, this.defaultUsers = slctd3; 
            }
            apiRspns.configJson.orders_export.orders_export_schedule.enabled_orders_data_export.everyday_hours = this.lstHrs;
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onChng(item: any, type: string, evntType: any) {
    if (type == 'default_store_tag') {
      if (evntType == 'os')
        this.slctdDefauFacTag.push(item.id)
      else if (evntType == 'osa')
        this.slctdDefauFacTag = [], item.filter((a: any) => this.slctdDefauFacTag.push(a.id));
      else if (evntType == 'ds')
        this.slctdDefauFacTag = this.slctdDefauFacTag.filter(s => s != item.id)
      else
        this.slctdDefauFacTag = [];
    }
    if (type == 'owner') {
      if (evntType == 'os')
        this.owners.push(item.id)
      else if (evntType == 'osa')
        this.owners = [], this.owners = item.filter((a: any) => this.owners.push(a.id));
      else if (evntType == 'ds')
        this.owners = this.owners.filter(s => s != item.id)
      else
        this.owners = [];
    }
    if (type == 'usrBdge') {
      if (evntType == 'os')
        this.usrBdges.push(item.id)
      else if (evntType == 'osa')
        this.usrBdges = [], this.usrBdges = item.filter((a: any) => this.usrBdges.push(a.id));
      else if (evntType == 'ds')
        this.usrBdges = this.usrBdges.filter(s => s != item.id)
      else
        this.usrBdges = [];
    }
  }

  onAddHrs($event: any) {
    let evnt = $event.target.value.trim(), itms: any[] = [], lstAr: any[] = [];
    evnt.split(',').filter((item: any) => { itms.push(item.trim()) });
    lstAr = itms.filter(b => { return b != null && b != '' }), this.lstHrs = lstAr;
    this.cnfgOrdFrm.get('configJson')?.get('orders_export')?.get('enable_orders_export')?.get('enabled_orders_data_export')?.
      get('everyday_hours')?.setValue(lstAr.toString());
  }

  onSlctFile(evnt: any, type: string) {
    if (evnt) {
      if (evnt.target.files.length > 0) {
        const fileList = evnt.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
        if (fileList.files[0]) {
          if (type == 'l')
            this.logoFile = fileList.files[0];
          else if (type == 'i')
            this.invcTmplt = fileList.files[0];
          else
            this.shipTmplt = fileList.files[0];
        }
      }
    }
  }

  onUpload(type: string) {
    if (this.logoFile || this.invcTmplt || this.shipTmplt) {
      let frmData: FormData = new FormData(), usrData = this.usrLgnDtls;
      frmData.append('file', (type == 'l' ? this.logoFile : (type == 'i' ? this.invcTmplt : this.shipTmplt))),
        this.onFormData('/' + environment.apiPaths.file, environment.apiMthds.upldFile.
          replace('Logo', 'DomainPhotos').replace('dtdt', (new Date()).getTime().toString()) + usrData.userId, frmData).then(() => {
            if (this.result) {
              if (this.result.data) {
                // let dmnPics: any[] = this.cnfgGnrlFrm.get('domain_photos')?.value, picURL: string = this.result.data?.fileDownloadUrl ?? '';
                // if (picURL)
                //   dmnPics.push(picURL), this.lstPics.push(picURL), $('#cncl')?.click(), this.crntFile = null, this.onSave();
              }
            }
          });
    }
  }

}