<form [formGroup]="dbOrdrForm" autocomplete="off">
  <div class="card p-0">
    <div class="card-header p-3">
      <div class="row">
        <div class="col-xl-2 offset-xl-6 text-end">
          <ul class="pull-right nav nav-pills nav-primary mb-3" id="pills-clrtabinfo" role="tablist">
            <li class="nav-item"><a class="nav-link btn btn-sm border active" data-bs-toggle="tab" role="tab"
                aria-controls="Map" aria-selected="true" (click)="onTypeSlct('d')" #dayClick>Day</a></li>
            <li class="nav-item"><a class="nav-link btn btn-sm border" data-bs-toggle="tab" role="tab"
                aria-controls="Map" aria-selected="false" (click)="onTypeSlct('m')">Month</a>
            </li>
          </ul>
        </div>
        <div class="col-xl-2">
          <div *ngIf="dayPicker">
            <div class="input-group">
              <input type="text" autocomplete="off" onpaste="return false" bsDatepicker [(bsValue)]="bsDate"
                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker" [bsConfig]="{ isAnimated: true,  dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                showWeekNumbers: false, adaptivePosition: true }" (bsValueChange)="onChngDT($event)"
                placeholder="Select Date" [maxDate]="dt2day" readonly id="datePickValue" name="dop" #dop
                formControlName="date">
              <!-- onkeypress='return event.charCode == 8' [(ngModel)]="frmDate"  [dateCustomClasses]="cstmDt" -->
              <div class="input-group-append">
                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                  [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                </button>
                <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                  (click)="dpFD.hide();clearBSDate('slctdDate')"> <i class="fa fa-times"></i>
                </button> -->
              </div>
            </div>
          </div>
          <div *ngIf="mnthPicker">
            <div class="input-group">
              <input type="text" autocomplete="off" onpaste="return false" bsDatepicker [(bsValue)]="bsDate"
                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker" [dateCustomClasses]="cstmDt"
                [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                          containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                (bsValueChange)="onChngDT($event)" placeholder="Select Month" [maxDate]="dt2day" id="datePickValue"
                name="dop" #dop formControlName="date" readonly>
              <!-- onkeypress='return event.charCode == 8'  [(ngModel)]="frmDate" -->
              <div class="input-group-append">
                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                  [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                </button>
                <!-- <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                  (click)="dpFD.hide();clearBSDate('slctdDate')"> <i class="fa fa-times"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2 text-end">
          <button class="btn btn-sm btn-light" (click)="onReset()">Reset</button> &nbsp;
          <button class="btn btn-sm btn-primary" (click)="onFltrSubmt()">Filter</button><br /> <br />
        </div>
        <div class="form-group col-xl-7">
          <div class="form-group col-xl-3">
            <button class="btn btn-sm btn-primary" data-toggle="collapse" href="#box1" aria-expanded="false"
              aria-controls="box1">Advance Search</button>
          </div>
          <div id="box1" class="collapse">
            <div class="form-row row mb-2">
              <div class="form-group col-xl-4 mt-3">
                <strong for="textBox">State</strong>
                <select formControlName="stateId" class="form-select form-select-sm"
                  (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState>
                  <option value="null">All</option>
                  <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                </select>
              </div>
              <div class="form-group col-xl-4 mt-3">
                <strong for="textBox">District</strong>
                <select formControlName="districtId" class="form-select form-select-sm"
                  (change)="blkLst=[];onLocChng($event, 'district')" #locDist>
                  <option value="null">All</option>
                  <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                </select>
              </div>
              <div class="form-group col-xl-4 mt-3">
                <strong for="textBox">Taluk/Block</strong>
                <select formControlName="blockId" class="form-select form-select-sm" #locBlck>
                  <option value="null">All</option>
                  <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-xl-5 text-end">
          <a [innerHTML]="slctdType == 'd' ? dayTxt : mnthTxt"></a>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div>
        <div>
          <div class="row m-2">
            <div class="card mb-0 p-0">
              <div class="card-header p-3">
                <div class="h6 mb-0">Indents</div>
              </div>
              <div *ngIf="resOrdrs?.length != 0">
                <div class="card-body p-3  tab-card">
                  <div class="col-md-12">
                    <div class="row">
                      <div (click)="slctdTab='i'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 'i' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.orders}} &nbsp;</span></div>
                        <span class="labelOrder">Indents</span>
                      </div>

                      <div (click)="slctdTab='p'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 'p' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.pending}}</span><span
                          class="ms-3 text-warning">{{resOrdrs[0]?.pending_perc}}%</span></div>
                        <span class="labelOrder">Pending Indents</span>
                      </div>

                      <div (click)="slctdTab='c'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 'c' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.confirmed}}</span><span
                          class="ms-3 text-warning">{{resOrdrs[0]?.confirmed_perc}}%</span></div>
                        <span class="labelOrder">Confirmed Indents</span>
                      </div>

                      <div (click)="slctdTab='d'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 'd' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.readyForDispatch}}</span><span
                          class="ms-3 text-warning">{{resOrdrs[0]?.ready_for_dispatch_perc}}%</span></div>
                        <span class="labelOrder">Ready for Dispatch Indents</span>
                      </div>
                    </div>
                    <div class="row my-3">
                      <div (click)="slctdTab='s'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 's' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.shipped_orders}}</span> <span
                            class="ms-3 text-warning">{{resOrdrs[0]?.shipped_orders_perc}}%</span>
                        </div><span class="labelOrder"> Shipped Indents</span>
                      </div>

                      <div (click)="slctdTab='f'; setChrtData()" class="col-md-3 text-center pntngDiv"
                        [ngClass]="slctdTab == 'f' ? 'actDiv' : ''">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.fulfilled_orders}}</span> <span
                            class="ms-3 text-warning">{{resOrdrs[0]?.fulfilled_orders_perc}}%</span>
                        </div>
                        <span class="labelOrder">Fulfilled Indents</span>
                      </div>

                      <div class="col-md-3 text-center">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.order_processing_time}} &nbsp;</span>
                        </div><span class="labelOrder">Indent Processing Time</span> <br>
                        <span class="lgndTxt">(Creation to Shipment)</span>
                      </div>

                      <div class="col-md-3 text-center">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.deliveryLeadTime}} &nbsp;</span>
                        </div><span class="labelOrder">Delivery Lead Time</span> <br>
                        <span class="lgndTxt">(Shipment to Fulfillment)</span>
                      </div>                      
                    </div>
                    <div class="row  my-3">
                      <div class="col-md-3 text-center">
                        <div><span class="f-big mb-2">{{resOrdrs[0]?.order_response_time}} &nbsp;</span>
                        </div><span class="labelOrder">Indent Response Time</span> <br>
                        <span class="lgndTxt">(Creation to Fulfillment)</span>
                      </div>
                    </div>
                    <div class="col-md-12" *ngIf="isChrtShw">
                      <div class="float-end mb-3" *ngIf="lstChrt.length != 0">
                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                          <li class="nav-item" title="Map View"><a class="nav-link btn btn-sm border active"
                              id="Map-tab" data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                              aria-selected="true"><i class="fa fa-bar-chart me-0" aria-hidden="true"></i></a>
                          </li>
                          <li class="nav-item" title="List View"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                              data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                              aria-selected="false"><i class="fa fa-list me-0" aria-hidden="true"></i></a></li>
                        </ul>
                      </div>
                      <div class="tab-content mt-3" id="top-tabContent">
                        <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                          <div class="card-body text-center">
                            <div id="chrt"></div>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">

                          <div class="table_spacing table-responsive table-row-spacing mt-4 text-center ">
                            <div class="w-50 mx-auto">
                              <div class="text-right float-end" *ngIf="lstChrt?.length!=0">
                                <button class="btn btn-sm btn-success mb-3" (click)="exprtExl()"><i
                                    class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                              </div>

                              <table class="table table-striped table-sm table-bordered">
                                <thead>
                                  <tr>
                                    <th>S.No.</th>
                                    <th>{{typeSlctd == 'd' ? 'Date' : 'Month'}}</th>
                                    <th>Count</th>
                                  </tr>
                                </thead>
                                <tbody *ngIf="lstChrt.length != 0">
                                  <tr *ngFor="let order of lstChrt; let i=index">
                                    <td>{{i+1}}</td>
                                    <td>{{ typeSlctd == 'd' ? (order.date | date: 'dd-MM-YYYY') : order.date}}</td>
                                    <td>{{ slctdTab == 'i' ? order?.orders : (slctdTab == 'f' ? order?.fulfilled_orders : (slctdTab == 'p' ? 
                                      order?.pending : (slctdTab == 'c' ? order?.confirmed : (slctdTab == 'd' ? order?.readyForDispatch : 
                                      order?.shipped_orders ))))}}</td>
                                  </tr>
                                </tbody>
                                <tbody *ngIf="lstChrt.length == 0">
                                  <tr>
                                    <td colspan="3">{{noRcrdsTxt}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center" *ngIf="resOrdrs?.length == 0">
                {{noRcrdsTxt}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>