import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})

export class TransactionsComponent extends BaseComponent implements OnInit {

  paramTab: any; isShowCTNS = true; cnfgInvData: any; txnUploadFlag = false; userlgndtls : any = null; mmTxnLst: boolean = true;
  mmCrtTxn: boolean = true; cnfgVal: any = null; isTxnLstHdn = false; isTxnCrtHdn = false;

  constructor(public glblSvc: GlobalService,
    private route: ActivatedRoute,
    private router: Router) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
  }

  ngOnInit(): void {     
    if (this.usrLgnDtls.permissions != null) {
      let crtTrnsPrmsns = this.usrLgnDtls.permissions.filter((pm: any) => pm.parentModuleId === 1)[0]?.modulePermissionRoles.
        filter((mm: any) => mm.moduleId === 1)[0]?.permissionRoles.filter((pr: any) => pr.permissionId === 9)[0]?.configuredValue;
      this.isShowCTNS = crtTrnsPrmsns != null ? !crtTrnsPrmsns.value : true;
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Stock_Management.filter((e: any) => { if (e.menuCode == 'mm_sm_tx_li') this.mmTxnLst = e?.action;
      if (e.menuCode == 'mm_sm_tx_ct') this.mmCrtTxn = e.action;}) : null;
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null;
      this.isTxnLstHdn = this.cnfgVal?.permissionRoles[2]?.configuredValue?.value ?? false,
      this.isTxnCrtHdn = this.cnfgVal?.permissionRoles[4]?.configuredValue?.value ?? false;
    }
    if(this.mmTxnLst && !this.isTxnLstHdn)
    this.onTabSelection('list'), setTimeout(() => { document.getElementById('top-trans-tab')?.click(); }, 500);
    else if(this.mmCrtTxn && !this.isTxnCrtHdn)
    this.onTabSelection('new'), setTimeout(() => { document.getElementById('create-trans-top-tab')?.click(); }, 500);
    // else 
    // setTimeout(() => { document.getElementById('bulkup-top-tab')?.click(); }, 500);
    this.glblSvc.sbjctCnfgData.subscribe(a => this.cnfgInvData = a.cnfgInvData),
      this.txnUploadFlag = this.cnfgInvData?.manual_transaction_upload?.enable_manual_upload_of_stock_management_metadata_along_with_transactions;
  }

  onTabSelection(tabName: string) {
    this.updateQueryParams({ tab: tabName }), this.glblSvc.sbjctTabsPage.next(`trans-${tabName}`);
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}