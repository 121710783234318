<form [formGroup]="dmnFrm" autocomplete="off">
    <div>
        <div class="card p-3 border-0">
            <div class="card-header p-0">
                <h6 class="mb-0 text-center">{{dmnDtls?.pranthName}}</h6>
            </div>
            <div class="card-body p-0">
                <div class="text-end small mt-2">
                    Created on {{dmnDtls?.createdOn}} by {{dmnDtls?.createdBy}}, Last updated on
                    {{dmnDtls?.updatedOn}} by {{dmnDtls?.updatedBy}}
                </div>
                <div class="card p-3 mb-0 mt-3">
                    <div class="card-header p-0 pb-2">
                        <h6 class="mb-0">Domain information</h6>
                    </div>
                    <div class="card-body p-0">
                        <div class="row mt-3">
                            <div class="col-xl-6 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-4">Name:</div>
                                    <div class="col-sm-8">
                                        <div class="input_edit">{{dmnDtls?.pranthName}} <span class="icon_edit"><i
                                                    class="fa fa-pencil" aria-hidden="true"
                                                    (click)="onShwEdt()"></i></span>
                                        </div>
                                        <div class="input-group" *ngIf="isShwEdt">
                                            <input type="text" placeholder="Domain Name" value="{{dmnDtls?.pranthName}}"
                                                class="form-control form-control-sm" id="dmnName">
                                            <div class="input-group-append">
                                                <button type="button" class="btn btn-sm btn-light"
                                                    (click)="updtePrnthNme()"><i class="fa fa-check"
                                                        aria-hidden="true"></i></button>
                                                <button type="button" class="btn btn-sm btn-light"
                                                    (click)="onCnclNme()"><i class="fa fa-times"
                                                        aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">Description:</div>
                                    <div class="col-sm-8">{{dmnDtls?.pranthDescription}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">Owner:</div>
                                    <div class="col-sm-8">{{dmnDtls?.owner}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">is active?:</div>
                                    <div class="col-sm-8">{{dmnDtls?.isActive}}</div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-sm-12">
                                <div>Permissions</div>
                                <div class="table-responsive table_spacing">
                                    <table class="table table-striped table-bordered border-primary f-14">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="col-auto text-center">View</th>
                                                <th class="col-auto">Add</th>
                                                <th class="col-auto">Edit</th>
                                                <th class="col-auto">Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngFor="let prmsn of dmnDtls?.permissions">
                                            <tr>
                                                <td colspan="5" class="text-center">{{prmsn.frameType}}</td>
                                            </tr>
                                            <tr *ngFor="let pp of prmsn?.permissionPranth">
                                                <td>{{pp.permissionType}}</td>
                                                <td class="text-center" *ngFor="let pn of pp?.permissionCodes">
                                                    <div class="form-check form-check-inline" *ngIf="pn.id != 180">
                                                        <input class="form-check-input" type="checkbox" id="{{pn.id}}"
                                                            value="{{pn.id}}" [checked]="pn.isChecked"
                                                            [disabled]="btnFlag == true ? false : pn.isEnable"
                                                            (click)="onChngePrmsn('main', $event, pn.id)">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row mt-3" *ngIf="isEdtPrmsnBtn">
                                    <div class="col-sm-12">
                                        <button type="button" class="btn btn-sm btn-primary float-end"
                                            (click)="editPrmns()">Edit Permissions</button>
                                    </div>
                                </div>
                                <div class="row mt-3" *ngIf="isUpdateBtn">
                                    <div class="col-sm-12">
                                        <div class="float-end">
                                            <button type="button" class="btn btn-sm btn-primary me-2"
                                                (click)="onUpdate('main')">Update</button>
                                            <button type="button" class="btn btn-sm btn-light"
                                                (click)="onCncl()">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="lstDmns.length != 0">
                    <div class="col-xl-6 col-sm-12">
                        <div class="card p-3 mb-0 mt-3">
                            <div class="row mt-2">
                                <div class="col-sm-12 col-xl-12">
                                    <button type="button" class="btn btn-sm btn-primary float-end" data-toggle="modal"
                                        data-target="#myModal" (click)="getUnmpdPrnths()">Add</button>
                                </div>
                            </div>
                            <div class="card-header p-0 pb-2">
                                <h6 class="mb-0" (click)="LoadMappedPranths(dmnDtls)" style="cursor: pointer;">
                                    {{dmnDtls?.pranthName}}</h6>
                            </div>
                            <div class="card-body p-0">
                                <div class="row mt-3">
                                    <div class="col-sm-12 col-xl-12">
                                        <div id="accordian" class="menu-su-domain-container">
                                            <ul class="menu">
                                                <li class="menu-item-has-children" *ngFor="let dl1 of lstDmns">
                                                    <a class="span_div">
                                                        <span class="span_div1"
                                                            (click)="LoadMappedPranths(dl1)">{{dl1.pranthName}}</span>
                                                    </a>
                                                    <ul *ngIf="dl1?.Pranth?.length!=0">
                                                        <li *ngFor="let dl2 of dl1?.Pranth">
                                                            <a class="span_div">
                                                                <span class="span_div1"
                                                                    (click)="LoadMappedPranths(dl2)">{{dl2.pranthName}}</span>
                                                            </a>
                                                            <ul *ngIf="dl2?.Pranth?.length!=0">
                                                                <li *ngFor="let dl3 of dl2?.Pranth">
                                                                    <a class="span_div">
                                                                        <span class="span_div1"
                                                                            (click)="LoadMappedPranths(dl3)">{{dl3.pranthName}}</span>
                                                                    </a>
                                                                    <ul *ngIf="dl3?.Pranth?.length!=0">
                                                                        <li *ngFor="let dl4 of dl3?.Pranth">
                                                                            <a class="span_div">
                                                                                <span class="span_div1"
                                                                                    (click)="LoadMappedPranths(dl4)">{{dl4.pranthName}}</span>
                                                                            </a>
                                                                            <ul *ngIf="dl4?.Pranth?.length!=0">
                                                                                <li *ngFor="let dl5 of dl4?.Pranth">
                                                                                    <a class="span_div">
                                                                                        <span class="span_div1"
                                                                                            (click)="LoadMappedPranths(dl5)">{{dl5.pranthName}}</span>
                                                                                    </a>
                                                                                    <ul *ngIf="dl5?.Pranth?.length!=0">
                                                                                        <li
                                                                                            *ngFor="let dl6 of dl5?.Pranth">
                                                                                            <a class="span_div">
                                                                                                <span class="span_div1"
                                                                                                    (click)="LoadMappedPranths(dl6)">{{dl6.pranthName}}</span>
                                                                                            </a>
                                                                                            <ul
                                                                                                *ngIf="dl6?.Pranth?.length!=0">
                                                                                                <li
                                                                                                    *ngFor="let dl7 of dl6?.Pranth">
                                                                                                    <a class="span_div">
                                                                                                        <span
                                                                                                            class="span_div1"
                                                                                                            (click)="LoadMappedPranths(dl7)">{{dl7.pranthName}}</span>
                                                                                                    </a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-sm-12">
                        <div class="card p-3 mb-0 mt-3">
                            <div class="card-header p-0 pb-2">
                                <h6 class="mb-0">Set permissions to child domains of {{slctdDmnNme}}</h6>
                            </div>
                            <div class="card-body p-0" *ngIf="lstMappedPrnths.length != 0">
                                <div class="row mt-3">
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Specify child domains</label>
                                        <ng-multiselect-dropdown [settings]="msdStngs" [data]="lstMappedPrnths" formControlName="mppdPrnth"
                                            (onSelect)="onChng($event, 'os')" (onSelectAll)="onChng($event, 'osa')"
                                            (onDeSelect)="onChng($event, 'ds')" (onDeSelectAll)="onChng($event, 'dsa')">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-group col-xl-6 mt-3">
                                        <label for="textBox">Copy options</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                ([ngModel])="mtrlsFprnt" (change)="onChngChld('mtrls', $event)">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Copy materials from {{slctdDmnNme}}
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                ([ngModel])="cnfgFprnt" (change)="onChngChld('cnfg', $event)">
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Copy configuration from {{slctdDmnNme}}
                                            </label>
                                        </div>
                                        <!-- <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" ([ngModel])="prntFprnt"
                                            (change)="onChngChld('prnt', $event)">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Copy configuration from parents to all sub-domains
                                        </label>
                                    </div> -->
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div>Permissions</div>
                                    <div class="mt-2">Master Data</div>
                                    <div class="table-responsive table_spacing">
                                        <table class="table table-striped table-bordered border-primary f-14">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th class="col-auto text-center">View</th>
                                                    <th class="col-auto">Add</th>
                                                    <th class="col-auto">Edit</th>
                                                    <th class="col-auto">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor="let prmsn of chldDmns">
                                                <tr>
                                                    <td colspan="5" class="text-center">{{prmsn.frameType}}</td>
                                                </tr>
                                                <tr *ngFor="let pp of prmsn?.permissionPranth">
                                                    <td>{{pp.permissionType}}</td>
                                                    <td class="text-center" *ngFor="let pn of pp?.permissionCodes">
                                                        <div class="form-check form-check-inline" *ngIf="pn.id != 180">
                                                            <input class="form-check-input" type="checkbox"
                                                                id="{{pn.id}}" value="{{pn.id}}"
                                                                (click)="onChngePrmsn('chld', $event, pn.id)">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-sm-12">
                                        <div class="float-end">
                                            <button type="button" class="btn btn-sm btn-warning me-2"
                                                (click)="onUpdate('subChld')">Update to all childs</button>
                                            <button type="button" class="btn btn-sm btn-primary me-2"
                                                (click)="onUpdate('chld')">Update</button>
                                            <button type="button" class="btn btn-sm btn-light">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body p-0" *ngIf="lstMappedPrnths.length == 0">
                                <p>There are no child domains for {{slctdDmnNme}}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="lstDmns.length == 0">
                    <div class="card mt-3">
                        <div class="card-body">
                            <h6>There are no child domains</h6>
                            <div class="row mt-2">
                                <div class="col-sm-5 col-xl-5">
                                    <button type="button" class="btn btn-sm btn-primary float-end" data-toggle="modal"
                                        data-target="#myModal" (click)="getUnmpdPrnths()">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Domain Linking -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Link domains</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="table_spacing table-responsive">
                        <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                            <thead>
                                <tr>
                                    <th class="text-center"></th>
                                    <!-- <input type="checkbox" (change)="slctAll($event)" [attr.checked]="slctChckd"> -->
                                    <th class="text-center">Item No.</th>
                                    <th>Domain Name</th>
                                    <th>Description</th>
                                    <th>Domain Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let dd of unMappedPrnthsLst; let i=index">
                                    <td class="text-center"><input type="checkbox"
                                            (change)="slctPrnth(dd.pranthId, $event)"></td>
                                    <td class="text-center">{{i+1}}</td>
                                    <td>{{dd.pranthName}}</td>
                                    <td>{{dd.description}}</td>
                                    <td>{{dd.pranthLevel}}</td>
                                </tr>
                            </tbody>
                            <tbody [hidden]="unMappedPrnthsLst?.length !=0">
                                <tr>
                                    <td class="text-center" colspan="5">{{noRcrdTxt}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Cancel</button> &nbsp;
                    &nbsp;
                    <button type="button" class="btn btn-primary btn-sm" *ngIf="unMappedPrnthsLst?.length !=0"
                        (click)="onLnkPrnths()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</form>