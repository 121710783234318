<form [formGroup]="configAssetsForm" autocomplete="off">
    <div class="card p-3 pb-0 pt-2 border-0">
        <div class="card-header p-3 pb-1 pt-2">
            <div class="h6 float-start">
                CCE Management Configuration
            </div>
            <!-- <div class="small float-end">
                Last updated on 25/1/19 11:26 AM by
                <a href="">Charan Malemarpuram</a>
            </div> -->
        </div>
        <div class="card-body p-2">

            <div class="card p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <h6 class="mb-0">Asset manufacturer</h6>
                </div>
                <div class="card-body p-2 mt-2 mb-0">
                    <ul class="nav nav-tabs mb-3" id="icon-tab" role="tablist">
                        <li *ngFor="let item of astTypLst" class="nav-item">
                            <a class="nav-link" id="{{item.name}}-tab" data-bs-toggle="tab" href="#{{item.name}}"
                                role="tab" aria-controls="ast-man" aria-selected="false" data-bs-original-title=""
                                title="" (click)="onTabSelection(item.name)" 
                                [ngClass]="activeTab == item.name ? 'active' : ''">{{item.name}}</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="icon-tabContent">
                        <div class="tab-pane fade" id="ast-man" role="tabpanel" aria-labelledby="ast-man-tab"
                            [ngClass]="activeTab == 'Temperature Logger' ? 'show active' : '' ">
                            <div>Please select manufacturer, model and default sensor for
                                <strong>{{activeTab}}</strong>
                            </div>
                            <div class="table-responsive table_spacing mt-3">
                                <table class="table table-striped table-bordered border-primary f-14">
                                    <thead>
                                        <tr>
                                            <th class="col-auto text-center">
                                                <div class="form-check form-check-inline mb-0 pb-0"
                                                    style="min-height: auto;">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="flexCheckDefault" (change)="selectAll($event)">
                                                </div>
                                            </th>
                                            <th class="col-auto">Manufacturer name <span class="text-danger">*</span>
                                            </th>
                                            <th class="col-auto">Asset model <span class="text-danger">*</span></th>
                                            <th class="col-auto">Default sensor <span class="text-danger">*</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let it of copyModals; let i = index;">
                                            <td class="text-center">
                                                <div class="form-check form-check-inline">
                                                    
                                                        <div *ngIf = "it.vendorIsActive == true" >
                                                            <input type="checkbox" class="form-check-input"
                                                             id="flexCheckDefault_{{i}}_vendorName"
                                                             (change)= "checkBoxActive($event, i, 1)" checked>
                                                        </div>
                                                        <div *ngIf = "it.vendorIsActive != true" >
                                                            <input type="checkbox" class="form-check-input"
                                                             id="flexCheckDefault_{{i}}_vendorName"
                                                             (change)= "checkBoxActive($event, i, 1)">
                                                        </div>
                                                       
                                                </div>
                                            </td>
                                            <td>{{it.vendorName}} </td>   
                                             <td>
                                                <div class="row">
                                                    <div class="col-xl-2">
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="checkbox"
                                                                value="it.modelIsActive"
                                                                id="flexCheckDefault_{{i}}_modelName"
                                                                [(ngModel)]="it.modelIsActive"
                                                                formControlName="modelFlag"
                                                                [attr.disabled]="!it.vendorIsActive ? true : null" 
                                                                (change)= "checkBoxActive($event, i, 2)">
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-10">
                                                        {{it.modelName}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td >
                                                <div class="form-group">
                                                    <select class="form-select form-select-sm" (change)= "onDefaultSensorChange($event, i)" 
                                                        [attr.disabled]="(!it.vendorIsActive || !it.modelIsActive) ? true : null">
                                                        <option value="" selected>-Select-</option>
                                                        <option *ngFor="let item of it.assetModelSensorMappings" value="{{item.sensor}}"
                                                            [selected]="item.sensor == it.defaultCensor">
                                                            {{item.sensor}}</option>
                                                    </select>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="ast-man1" role="tabpanel" aria-labelledby="ast-man1-tab"
                            [ngClass]="(activeTab == 'ILR' || activeTab == 'Deep Freezer' || activeTab =='Walk-in cooler' || 
                            activeTab == 'Walk-in Freezer' || activeTab == 'Radiant Warmer') ? 'show active' : ''">
                            <div>Please select manufacturer and default monitoring point for
                                <strong>{{activeTab}}</strong>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-12">
                                    <label for="textBox" class="f-w-500">Manufacturers <span
                                            class="text-danger">*</span></label><br />

                                    <div class="form-check form-check-inline"
                                        *ngFor="let d of asetMdlLst; let i = index;">
                                        <input type="checkbox" name="checkbox_{{i}}" id="check_{{i}}"
                                            class="form-check-input" (change)="d.isChkd=!d.isChkd" [checked]="d.isChkd">
                                        <label for="check_{{i}}"> {{ d.vendorName }}</label>
                                    </div>
                                    <div class="form-check-label" *ngIf="asetMdlLst.length == 0">
                                        <label>{{noRcrdsTxt}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-3 mt-3">
                                <label for="textBox">Default monitoring point <span class="text-danger">*</span></label>
                                <select id="inputState-42" class="form-select form-select-sm"
                                    formControlName="defaultMonitoringPoint"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.defaultMonitoringPoint.errors}">
                                    <option value="null">-Select-</option>
                                    <option *ngFor="let item of manufCLst" value="{{item.monitoringPoint}}">
                                        {{item.monitoringPoint}}
                                    </option>
                                </select>
                                <!-- <div *ngIf="isSbmtd && fc.defaultMonitoringPoint.errors" class="invalid-feedback">
                                    <div *ngIf="fc.defaultMonitoringPoint.errors.required">Select Default monitoring
                                        point</div>
                                </div> -->
                            </div>
                        </div>
                        <br>
                        <div class="text-right w-100 float-right">
                            <button class="btn btn-sm btn-primary" style="float: right;" (click)="saveManufaturere(activeTab)" *ngIf="cnfgCCEPrmsn">Save</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <h6 class="mb-0">Enable asset management</h6>
                </div>
                <div class="card-body p-2 mt-0 mb-0">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row mb-2">
                                <div class="form-group col-xl-12 mt-3">
                                    <label for="textBox " class="me-2 fw-bold">Enable asset management</label><br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                            formControlName="disableAssetManagement">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            No Asset management
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox"
                                            formControlName="enableAssetManagement" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable asset management only
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox"
                                            formControlName="enableAssetManagementLogistics" id="flexCheckDefault">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable asset management with logistics
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <h6 class="mb-0">Namespace configuration.</h6>
                </div>
                <div class="card-body p-2 mt-0 mb-0">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row mb-2">
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Namespace configuration.</label>
                                    <input type="text" placeholder="Enter NameSpace configuration"
                                        class="form-control form-control-sm" id="nameSpace" formControlName="nameSpace">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card p-0 mt-3 mb-0" *ngIf="activeTab == 'Temperature Logger'">
                <div class="card-header p-2">
                    <h6 class="mb-0">Temperature logger configuration</h6>
                </div>
                <div class="card-body p-2 mt-0 mb-0">
                    <div class="row">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row mb-2">
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Communication channel <span
                                            class="text-danger">*</span></label>
                                    <select id="communicationChannelId" class="form-select form-select-sm"
                                        formControlName="communicationChannelId"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.communicationChannelId.errors }">
                                        <option value="" selected>-Select-</option>
                                        <option [value]="cc.id" *ngFor="let cc of comuChnlLst">{{cc.name}}</option>
                                    </select>
                                    <div *ngIf="isSbmtd && fc.communicationChannelId.errors" class="invalid-feedback">
                                        <div *ngIf="fc.communicationChannelId.errors.required">Communication channel is
                                            required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Temperature push URL <span class="text-danger">*</span></label>
                                    <input type="text" placeholder="Temperature push URL"
                                        class="form-control form-control-sm" id="temperaturePushUrl"
                                        formControlName="temperaturePushUrl"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.temperaturePushUrl.errors }">
                                    <div *ngIf="isSbmtd && fc.temperaturePushUrl.errors" class="invalid-feedback">
                                        <div *ngIf="fc.temperaturePushUrl.errors.required">Temperature push URL is
                                            required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Configuration pull URL</label>
                                    <input type="text" placeholder="Configuration pull URL"
                                        class="form-control form-control-sm" id="configurationPullUrl"
                                        formControlName="configurationPullUrl">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Alarm push URL <span class="text-danger">*</span></label>
                                    <input type="text" placeholder="Alarm push URL" class="form-control form-control-sm"
                                        id="alarmPushUrl" formControlName="alarmPushUrl"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.alarmPushUrl.errors}">
                                    <div *ngIf="isSbmtd && fc.alarmPushUrl.errors" class="invalid-feedback">
                                        <div *ngIf="fc.alarmPushUrl.errors.required">Alarm push URL is required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Stats push URL</label>
                                    <input type="text" placeholder="Stats push URL" class="form-control form-control-sm"
                                        id="statsPushUrl" formControlName="statsPushUrl">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Device ready status push URL</label>
                                    <input type="text" placeholder="Device ready status push URL"
                                        class="form-control form-control-sm" id="deviceReadyStatusPushUrl"
                                        formControlName="deviceReadyStatusPushUrl">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">SMS gateway phone number <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                        </div>
                                        <input type="text" placeholder="SMS gateway phone number" class="form-control form-control-sm" id="smsGatewayPhoneNumber" 
                                            formControlName="smsGatewayPhoneNumber" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                            maxlength="13" minlength="10" [ngClass]="{'is-invalid': isSbmtd && fc.smsGatewayPhoneNumber.errors}">
                                            <div *ngIf="isSbmtd && fc.smsGatewayPhoneNumber.errors" class="invalid-feedback">
                                                <div *ngIf="fc.smsGatewayPhoneNumber.errors.required">SMS gateway phone number
                                                    is required</div>                                    
                                                <div *ngIf="fc.smsGatewayPhoneNumber.errors.minlength || fc.smsGatewayPhoneNumber.errors.maxlength">
                                                    SMS gateway phone number should be min. of 10 and max. of 13 digits only
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">SMS gateway keyword</label>
                                    <input type="text" placeholder="SMS gateway keyword"
                                        class="form-control form-control-sm" id="smsGatewayKeyword"
                                        formControlName="smsGatewayKeyword">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Sender Id</label>
                                    <input type="text" placeholder="Sender Id" class="form-control form-control-sm"
                                        id="smsGatewaySenderId" formControlName="smsGatewaySenderId">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Sampling interval (in minutes) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Sampling interval (in minutes)"
                                        class="form-control form-control-sm" id="samplingInterval"
                                        formControlName="samplingInterval"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.samplingInterval.errors}">
                                    <div *ngIf="isSbmtd && fc.samplingInterval.errors" class="invalid-feedback">
                                        <div *ngIf="fc.samplingInterval.errors.required">Sampling interval (in minutes)
                                            is required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Push interval (in minutes) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Push interval (in minutes)"
                                        class="form-control form-control-sm" id="pushInterval"
                                        formControlName="pushInterval"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.pushInterval.errors}">
                                    <div *ngIf="isSbmtd && fc.pushInterval.errors" class="invalid-feedback">
                                        <div *ngIf="fc.pushInterval.errors.required">Push interval (in minutes) is
                                            required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-12 mt-3">
                                    <label for="textBox">Phones to send SMS notifications </label>
                                    <input type="text" placeholder="Phones to send SMS notifications"
                                        class="form-control form-control-sm" id="phoneNumbersToSendSmsNotification"
                                        formControlName="phoneNumbersToSendSmsNotification">
                                    <p class="small pb-0 mb-0">(Phones to send SMS notifications directly)</p>
                                    <p class="small pb-0 mb-0">(User phone numbers (comma-separated)
                                        (+&lt;Country-Code&gt;&lt;Mobile phone&gt;; +919844360948 [India],
                                        +15404569089
                                        [USA]))</p>
                                </div>
                                <div class="form-group col-xl-12 mt-3">
                                    <label for="textBox " class="me-2 fw-bold">Notifications</label><br />
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                            formControlName="temperaturePushNotification">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable raw temperature push
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                            formControlName="temperatureIncursionExcursionPushNotification">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable temperature incursion/excursion push
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                            formControlName="deviceAlarmPushNotification">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable device alarm push
                                        </label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                                            formControlName="statsPushNotification">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            Enable stats push
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Power outage alarm duration(in minutes)</label>
                                    <input type="text" placeholder="Power oucategorye alarm duration(in minutes)"
                                        class="form-control form-control-sm" id="powerOutageAlarmDuration"
                                        formControlName="powerOutageAlarmDuration">
                                </div>
                                <div class="form-group col-xl-6 mt-3">
                                    <label for="textBox">Low battery alarm threshold limits</label>
                                    <input type="text" placeholder="Low battery alarm threshold limits"
                                        class="form-control form-control-sm" id="lowBatteryAlarmThresholdLimit"
                                        formControlName="lowBatteryAlarmThresholdLimit">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">High alarm configuration</div>
                                    <div class="small">High temperature alarm limits.</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Temperature (in celsius) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Temperature (in celsius)"
                                        class="form-control form-control-sm" id="highAlarmTemperature"
                                        formControlName="highAlarmTemperature"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.highAlarmTemperature.errors }">
                                    <div *ngIf="isSbmtd && fc.highAlarmTemperature.errors" class="invalid-feedback">
                                        <div *ngIf="fc.highAlarmTemperature.errors.required">Temperature (in celsius) is
                                            required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Duration (in minutes) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Duration (in minutes)"
                                        class="form-control form-control-sm" id="highAlarmTemperatureDuration"
                                        formControlName="highAlarmTemperatureDuration"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.highAlarmTemperatureDuration.errors }">
                                    <div *ngIf="isSbmtd && fc.highAlarmTemperatureDuration.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="fc.highAlarmTemperatureDuration.errors.required">Duration (in
                                            minutes) is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">Low alarm configuration</div>
                                    <div class="small">Low temperature alarm limits.</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Temperature (in celsius) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Temperature (in celsius)"
                                        class="form-control form-control-sm" id="lowAlarmTemperature"
                                        formControlName="lowAlarmTemperature"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.lowAlarmTemperature.errors }">
                                    <div *ngIf="isSbmtd && fc.lowAlarmTemperature.errors" class="invalid-feedback">
                                        <div *ngIf="fc.lowAlarmTemperature.errors.required">Temperature (in celsius) is
                                            required</div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Duration (in minutes) <span
                                            class="text-danger">*</span></label>
                                    <input type="text" placeholder="Duration (in minutes)"
                                        class="form-control form-control-sm" id="lowAlarmTemperatureDuration"
                                        formControlName="lowAlarmTemperatureDuration"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.lowAlarmTemperatureDuration.errors }">
                                    <div *ngIf="isSbmtd && fc.lowAlarmTemperatureDuration.errors"
                                        class="invalid-feedback">
                                        <div *ngIf="fc.lowAlarmTemperatureDuration.errors.required">Duration (in
                                            minutes) is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">High warning configuration</div>
                                    <div class="small">High temperature warning limits.</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Temperature (in celsius)</label>
                                    <input type="text" placeholder="Temperature (in celsius)"
                                        class="form-control form-control-sm" id="highWarningTemperature"
                                        formControlName="highWarningTemperature">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Duration (in minutes)</label>
                                    <input type="text" placeholder="Duration (in minutes)"
                                        class="form-control form-control-sm" id="highWarningTemperatureDuration"
                                        formControlName="highWarningTemperatureDuration">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">Low warning configuration</div>
                                    <div class="small">Low temperature warning limits.</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Temperature (in celsius)</label>
                                    <input type="text" placeholder="Temperature (in celsius)"
                                        class="form-control form-control-sm" id="lowWarningTemperature"
                                        formControlName="lowWarningTemperature">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Duration (in minutes)</label>
                                    <input type="text" placeholder="Duration (in minutes)"
                                        class="form-control form-control-sm" id="lowWarningTemperatureDuration"
                                        formControlName="lowWarningTemperatureDuration">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">Alarm frequency notification</div>
                                    <div class="small">Alarm frequency limits to send notifications.</div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Duration (in minutes)</label>
                                    <input type="text" placeholder="Duration (in minutes)"
                                        class="form-control form-control-sm" id="alarmFrequencyNotificationDuration"
                                        formControlName="alarmFrequencyNotificationDuration">
                                </div>
                                <div class="form-group col-xl-6">
                                    <label for="textBox">Number of times</label>
                                    <input type="text" placeholder="Number of times"
                                        class="form-control form-control-sm" id="alarmFrequencyNotificationNumber"
                                        formControlName="alarmFrequencyNotificationNumber">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="fw-bold">Sensor specific configurations</div>
                                    <div class="small">Please specify sensor specific configurations.</div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                                    <li class="nav-item"
                                        *ngFor="let item of GetDefaultConfgs()?.controls;let liIndx=index;">
                                        <a [ngClass]="liIndx==0?'nav-link active':'nav-link'"
                                            id="{{item?.get('sensorId')?.value}}-tab" data-bs-toggle="tab"
                                            href="#{{item?.get('sensorId')?.value}}" role="tab" title=""
                                            [attr.aria-controls]="item?.get('sensorId')?.value" aria-selected="true"
                                            data-bs-original-title=""
                                            (click)="tab=item?.get('sensorId')?.value">{{item?.get('sensorId')?.value}}
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content" [id]="'icon-tabContent'+tabIndx"
                                    *ngFor="let item of GetDefaultConfgs()?.controls;let tabIndx=index;"
                                    formArrayName="sensorDefaultConfigurationList">
                                    <div *ngIf="item?.get('sensorId')?.value == tab">
                                        <div class="tab-pane fade active show" id="{{item?.get('sensorId')?.value}}"
                                            role="tabpanel" [attr.aria-labelledby]="item?.get('sensorId')?.value+'-tab'"
                                            [formGroupName]="tabIndx">
                                            <div class="p-2">
                                                <div class="form-group col-xl-3 mt-3">
                                                    <label for="textBox">Use default configuration</label>
                                                    <input class="form-check-input" type="checkbox"
                                                        id="useDefaultConfiguration"
                                                        formControlName="useDefaultConfiguration">
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Sampling interval (in minutes)</label>
                                                        <input type="text" placeholder="Sampling interval (in minutes)"
                                                            class="form-control form-control-sm" id="samplingInterval"
                                                            formControlName="samplingInterval"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Push interval (in minutes)</label>
                                                        <input type="text" placeholder="Sampling interval (in minutes)"
                                                            class="form-control form-control-sm" id="pushInterval"
                                                            formControlName="pushInterval"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                                <div class="form-group col-xl-12 mt-3">
                                                    <label for="textBox "
                                                        class="me-2 fw-bold">Notifications</label><br />
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="temperaturePushNotification"
                                                            formControlName="temperaturePushNotification"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                        <label class="form-check-label"
                                                            for="temperaturePushNotification">
                                                            Enable raw temperature push
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="temperatureIncursionExcursionPushNotification"
                                                            formControlName="temperatureIncursionExcursionPushNotification"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                        <label class="form-check-label"
                                                            for="temperatureIncursionExcursionPushNotification">
                                                            Enable temperature incursion/excursion push
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="deviceAlarmPushNotification"
                                                            formControlName="deviceAlarmPushNotification"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Enable device alarm push
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="checkbox"
                                                            id="statsPushNotification"
                                                            formControlName="statsPushNotification"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            Enable stats push
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-xl-12">
                                                        <div class="fw-bold">High alarm configuration</div>
                                                        <div class="small">High temperature alarm limits.</div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Temperature (in celsius)</label>
                                                        <input type="text" placeholder="Temperature (in celsius)"
                                                            class="form-control form-control-sm"
                                                            id="highAlarmTemperature"
                                                            formControlName="highAlarmTemperature"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Duration (in minutes)</label>
                                                        <input type="text" placeholder="Duration (in minutes)"
                                                            class="form-control form-control-sm"
                                                            id="highAlarmTemperatureDuration"
                                                            formControlName="highAlarmTemperatureDuration"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-xl-12">
                                                        <div class="fw-bold">Low alarm configuration</div>
                                                        <div class="small">Low temperature alarm limits.</div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Temperature (in celsius)</label>
                                                        <input type="text" placeholder="Temperature (in celsius)"
                                                            class="form-control form-control-sm"
                                                            id="lowAlarmTemperature"
                                                            formControlName="lowAlarmTemperature"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Duration (in minutes)</label>
                                                        <input type="text" placeholder="Duration (in minutes)"
                                                            class="form-control form-control-sm"
                                                            id="lowAlarmTemperatureDuration"
                                                            formControlName="lowAlarmTemperatureDuration"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-xl-12">
                                                        <div class="fw-bold">High warning configuration</div>
                                                        <div class="small">High temperature warning limits.</div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Temperature (in celsius)</label>
                                                        <input type="text" placeholder="Temperature (in celsius)"
                                                            class="form-control form-control-sm"
                                                            id="highWarningTemperature"
                                                            formControlName="highWarningTemperature"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Duration (in minutes)</label>
                                                        <input type="text" placeholder="Duration (in minutes)"
                                                            class="form-control form-control-sm"
                                                            id="highWarningTemperatureDuration"
                                                            formControlName="highWarningTemperatureDuration"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-xl-12">
                                                        <div class="fw-bold">Low warning configuration</div>
                                                        <div class="small">Low temperature warning limits.</div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Temperature (in celsius)</label>
                                                        <input type="text" placeholder="Temperature (in celsius)"
                                                            class="form-control form-control-sm"
                                                            id="lowWarningTemperature"
                                                            formControlName="lowWarningTemperature"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Duration (in minutes)</label>
                                                        <input type="text" placeholder="Duration (in minutes)"
                                                            class="form-control form-control-sm"
                                                            id="lowWarningTemperatureDuration"
                                                            formControlName="lowWarningTemperatureDuration"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                                <div class="row mt-3">
                                                    <div class="col-xl-12">
                                                        <div class="fw-bold">Alarm frequency notification</div>
                                                        <div class="small">Alarm frequency limits to send
                                                            notifications.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Duration (in minutes)</label>
                                                        <input type="text" placeholder="Duration (in minutes)"
                                                            class="form-control form-control-sm"
                                                            id="alarmFrequencyNotificationDuration"
                                                            formControlName="alarmFrequencyNotificationDuration"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Number of times</label>
                                                        <input type="text" placeholder="Number of times"
                                                            class="form-control form-control-sm"
                                                            id="alarmFrequencyNotificationNumber"
                                                            formControlName="alarmFrequencyNotificationNumber"
                                                            [attr.disabled]="item?.get('useDefaultConfiguration')?.value == true ? true : null">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-end pt-3 pb-3">
                <button type="button" class="btn btn-sm btn-primary"
                    (click)="isSbmtd=true;onSaveConfigAssets()" *ngIf="cnfgCCEPrmsn">Save</button>
            </div>
        </div>
    </div>
</form>