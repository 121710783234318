<form [formGroup]="setupMatsEditForm">
  <div class="card mb-0 p-0">
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Material information</h6>
    </div>
    <div class="card-body p-3 pt-2 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <span class="small m-0">Please specify basic Material information</span>
          <div class="row mb-2">
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Material name <span class="text-danger">*</span></label>
              <input type="text" placeholder="Material name" formControlName="productName" 
              [ngClass]="{ 'is-invalid': isSbmtd && fc.productName.errors}" value="" class="form-control form-control-sm" id="">
              <div *ngIf="isSbmtd && fc.productName.errors" class="invalid-feedback">
                <div *ngIf="fc.productName.errors.required">Material name is required</div>
            </div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Short name</label>
              <input type="text" placeholder="Short name" value="" formControlName="short_name" class="form-control form-control-sm" maxlength="6" id="">
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Description</label>
              <input type="text" placeholder="Description" value="" formControlName="description" class="form-control form-control-sm" id="">
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="BadgeName">Material Category<span class="text-danger">*</span></label>
              <select class="form-select form-select-sm" formControlName="badge" [(ngModel)]="badge_id"
              [ngClass]="{ 'is-invalid': isSbmtd && fc.badge.errors }">
                  <option value="">Please Select Category</option>
                  <option [value]="it.name" *ngFor="let it of lstMatTags">{{it.name}}</option>
              </select>
              <div *ngIf="isSbmtd && fc.badge.errors" class="invalid-feedback">
                <div *ngIf="fc.badge.errors?.required">Material category is required</div>
            </div>
          </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Batch management</label>
              <div><input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" formControlName="is_batch_enabled"
                  aria-label="...">&nbsp;&nbsp; <span class="small"> Enable batch management</span></div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Seasonal</label>
              <div><input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" formControlName="is_seasonal" aria-label="..."></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-0 mt-3 p-0" hidden>
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Pricing information</h6>
    </div>
    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <span class="small m-0">Please specify the pricing information such as MSRP, Retailer's price and
            Currency</span>
          <div class="row mb-2">
            <div class="form-group col-xl-4 mt-3">
              <label for="textBox">MSRP</label>
              <input type="text" value=""  placeholder="Manufacturer-specified retail price"
                class="form-control form-control-sm" id="">
            </div>
            <div class="form-group col-xl-4 mt-3">
              <label for="textBox">Retailer's price</label>
              <input type="text" value="" placeholder="Retailer's price"  class="form-control form-control-sm" id="">
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Currency</label>
              <select id="inputState" class="form-select form-select-sm" >
                <option value="">Select Currency</option>
                <option>Indian Rupees (INR)</option>
                <option>Congo/Kinshasa Francs (CDF)</option>
                <option>Haitian Gourde (HTG)</option>
                <option>Great Britain Pounds (GBP)</option>
                <option>Japan Yen (JPY)</option>
                <option>Tanzania Shillings (TZS)</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card mb-0 mt-3 p-0">
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Temperature</h6>
    </div>
    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <p class="small mt-3">Enable monitoring of temperature for this material</p>
          <div class="row mb-2">
            <div class="form-group col-xl-4 mt-3">
              <label for="textBox">Temperature sensitive</label>
              <div><input class="form-check-input" type="checkbox"  formControlName ='is_temperature_sensitive' id="checkboxNoLabel" value=""
                  aria-label="...">&nbsp;&nbsp;Is material temperature sensitive?</div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Specify a valid temperature range for this material</label>
              <div class="row">
                <div class="col-xl-6">Min.:&nbsp;&nbsp;&nbsp;<input type="text" formControlName="minimum_temperature" placeholder="Min &deg;C"
                    class="form-control form-control-sm" id="" onkeypress='return ((event.charCode >=48 && event.charCode <=57) || 
                    event.charCode == 45 || event.charCode == 46)'></div>
                <div class="col-xl-6">Max.:&nbsp;&nbsp;&nbsp;<input type="text" formControlName="maximum_temperature" placeholder="Max &deg;C"
                    class="form-control form-control-sm" id="" onkeypress='return ((event.charCode >=48 && event.charCode <=57) ||
                    event.charCode == 45 || event.charCode == 46)'></div>
              </div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="handling_unit">Material handling unit</label>
              <input class="form-control form-control-sm" type="text" id="handling_unit" aria-label="..."
                [ngClass]="{ 'is-invalid': isSbmtd && fc.handling_unit.errors }" maxlength="3"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                formControlName="handling_unit" placeholder="Material handling unit">
             <div *ngIf="isSbmtd && fc.handling_unit.errors" class="invalid-feedback">
                <div *ngIf="fc.handling_unit.errors.min || fc.handling_unit.errors.max">
                  Material handling unit should accepts between 1 to 100
              </div>  
              </div> <!--  <div *ngIf="fc.handling_unit.errors.required">Material handling unit is required</div> onkeypress='return event.charCode >= 48 && event.charCode <= 57' -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-xl-12  rt-2">
        <div class="float-xl-end">
          <button class="btn btn-sm btn-primary me-2 mb-2" (click)="updateMaterial();isSbmtd = true">Save</button>
          <button class="btn btn-sm btn-light mb-2" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</form>