import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-prchs-indnt-fulfill-mdl',
  templateUrl: './prchs-indnt-fulfill-mdl.component.html',
  styleUrls: ['./prchs-indnt-fulfill-mdl.component.css']
})
export class PrchsIndntFulfillMdlComponent extends BaseComponent implements OnInit {

  shpngNtfcn1 = ''; shpngNtfcn2 = ''; shpngNtfcn3 = ''; shpngNtfcn4 = ''; lstVcine: any = {}; toDay: any = new Date(); boxArvl: any = '';
  coment: any = ''; coment1: any = ''; shipment1 = ''; shipment2 = ''; shipment3 = ''; shipment4 = ''; orderId: any; shipBox: any;
  colntType = ''; tempMntrPrsnt = ''; qntyRcvd = ''; vcneArvl = ''; typ = ''; qntyRcvd1 = ''; vcneArvl1 = ''; lstOthDcm: any = '';
  noOfBoxsVcn: any = ''; noOfVials: any = ''; btchNo: any = ''; noOfBoxsDrpers: any; noOfUnits: any; expDte: any; boxInspctd: any = '';
  elctrncDvc: any = ''; suprvsior: any = ''; steVcneStr: any = ''; sepio: any = ''; lstBtch: any[] = []; noColnt:  any = ''; icePack: any = '';
  dryIce: any = ''; vmm: any = ''; cldCain: any = ''; elctrcDevce: any = ''; othr: any = ''; frmDate: any = ''; dt2day = new Date();
  ordCnsnee: any = localStorage.getItem('sbjctOrdCnsgnee'); ordDtls: any = localStorage.getItem('sbjctOrdDtls'); toDate: any = ''; tme: any = '';
  refNo: any = ''; strTme: any = ''; dteRcvd: any = ''; shpDate: any = ''; clrAgnt: any = ''; onBEHALF: any = ''; insptnDte: any = '';
  strMngrDte: any = ''; plce: any = ''; inspDte: any = ''; awbNum: any = ''; arprtDsntn: any = ''; fltNo: any = ''; dteEAPN = ''; tmeEAPN = '';
  // tmp45: any = ''; tmp30: any = ''; tmp10: any = ''; tmp5: any = ''; almDte: any = ''; almTme: any = '';
  tmeATA = ''; dteATA = ''; preAdvce: any = '';

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , public actMdl: NgbActiveModal) {
      super(glblSvc);
      this.glblSvc.sbjctOrdId.subscribe((s: any) => { this.orderId = s; });
    }

  ngOnInit(): void {
    this.LoadVcine();
  }

  LoadVcine() {
    let orderId: any = JSON.parse(this.ordDtls); this.lstBtch = [];
    this.lstVcine = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsnBkng, environment.apiMthds.getDtlsByCnsneId.
      replace('ccc', orderId?.indentid), null, 12).then(() => {
      if (this.result) {
        if (this.result.data)
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstVcine = this.result?.data ?? {};
            this.lstVcine?.batchDetails.forEach((e: any) => {
              this.lstBtch.push({ batchNo: e.batchNo, expDate: e.expDate, noOfBoxsVcn: '', noOfVials: '', dilutBtchNo: '', dilutNoOfBoxs: '',
              dilutNoOfUnits: '', dilutexpDte: '', tmp45: '', tmp30: '', tmp10: '', tmp5: '', almDte: '', almTme: '', boxNo: '' })
            });
          }
          else if (this.result.data == null || this.result.returnCode == 0)
            this.glblSvc.onTstr('i', this.result.message);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    })
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }
  // onExportExcel(tblId: string) {
  //   let data: any = document.getElementById(tblId), uri = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;filename=XDK_Export;base64,',
  //     template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
  //     base64 = function (s: any) { return window.btoa(unescape(encodeURIComponent(s))) },
  //     format = function (s: any, c: any) { return s.replace(/{(\w+)}/g, function (m: any, p: any) { return c[p]; }) }

  //   let ctx = { worksheet: 'Testing', table: data.innerHTML }, blob = this.b64toBlob(base64(format(template, ctx)), "application/vnd.ms-excel");
  //   let blobUrl = URL.createObjectURL(blob), link = document.createElement("a");

  //   link.download = `eTest`;
  //   link.href = blobUrl;
  //   link.click();
  // }

  // b64toBlob(b64Data: any, contentType: any, sliceSize = 0) {
  //   contentType = contentType || '', sliceSize = sliceSize || 512;
  //   var byteCharacters = atob(b64Data), byteArrays = [];
  //   for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     var slice = byteCharacters.slice(offset, offset + sliceSize), byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++)
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     var byteArray = new Uint8Array(byteNumbers);
  //     byteArrays.push(byteArray);
  //   }
  //   return new Blob(byteArrays, { type: contentType });
  // }

  onExportExcel(tblId: string){
    var x: any = document.getElementById(tblId)?.innerHTML
    var link = document.createElement('a');
    link.setAttribute('download', 'var.doc');
    link.setAttribute('href', 'data:' + 'text/doc' + ';charset=utf-8,' + encodeURIComponent(x));
    link.click(); 
  }
}
