<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="mmIdTfLst && !isRelseLstHdn"><a class="nav-link " id="trsf-tab" data-bs-toggle="tab" href="#orders" role="tab"
                    aria-controls="orders" aria-selected="true" (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' || paramTab === 'sts' ? 'active' : '' ">Transfers</a></li>
            <li class="nav-item" *ngIf="mmCrtTfId && !isRelseCrtHdn"><a class="nav-link" id="create-trsf-tab" data-bs-toggle="tab" href="#creat-order"
                    role="tab" aria-controls="creat-order" aria-selected="false" (click)="onTabSelection('new')"
                    [ngClass]="paramTab === 'new' ? 'active' : ''">Create Transfer Indent</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="trsf-tab" *ngIf="mmIdTfLst && !isRelseLstHdn"
                [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-transfers-list></evin-transfers-list>
            </div>
            <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="trsf-tab"
                [ngClass]="paramTab === 'sts' ? 'show active' : '' ">
                <evin-orders-status></evin-orders-status>
            </div>
            <div class="tab-pane fade" id="order-fulfill" role="tabpanel" aria-labelledby="orders-tab3"
                [ngClass]="paramTab === 'fulfill' ? 'show active' : ''">
                <evin-order-fulfill></evin-order-fulfill>
            </div>
            <div class="tab-pane fade" id="creat-order" role="tabpanel" aria-labelledby="create-trsf-tab" *ngIf="mmCrtTfId && !isRelseCrtHdn"
            [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-transfers-create></evin-transfers-create>
            </div>
        </div>
    </div>
</div>