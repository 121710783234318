<evin-report-filter></evin-report-filter>
<div class="card p-0 border-0">
    <div class="card-header border-0 p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Transaction-count-tab" data-bs-toggle="tab"
                    href="#Transaction-count" role="tab" aria-controls="Transaction-count"
                    aria-selected="true">Overview</a></li>
            <li class="nav-item"><a class="nav-link" id="Transaction-count-1-tab" data-bs-toggle="tab"
                    href="#Transaction-count-1" role="tab" aria-controls="Transaction-count-1" aria-selected="false">By
                    materials</a></li>
            <li class="nav-item"><a class="nav-link" id="Transaction-count-2-tab" data-bs-toggle="tab"
                    href="#Transaction-count-2" role="tab" aria-controls="#Transaction-count-2" aria-selected="false">By
                    facilities</a></li>
            <li class="nav-item"><a class="nav-link" id="Transaction-count-3-tab" data-bs-toggle="tab"
                    href="#Transaction-count-3" role="tab" aria-controls="#Transaction-count-3" aria-selected="false">By
                    locations</a></li>
        </ul>
    </div>
    <div class="card-body p-2">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="Transaction-count" role="tabpanel"
                aria-labelledby="Transaction-count-tab">
               <evin-rplnsh-rspn-time-overview></evin-rplnsh-rspn-time-overview>
            </div>
            <div class="tab-pane fade" id="Transaction-count-1" role="tabpanel"
                aria-labelledby="Transaction-count-1-tab">
                <evin-rplnsh-rspn-time-materials></evin-rplnsh-rspn-time-materials>
            </div>
            <div class="tab-pane fade" id="Transaction-count-2" role="tabpanel"
                aria-labelledby="Transaction-count-2-tab">
                <evin-rplnsh-rspn-time-facilitys></evin-rplnsh-rspn-time-facilitys>
            </div>
            <div class="tab-pane fade" id="Transaction-count-3" role="tabpanel"
                aria-labelledby="Transaction-count-3-tab">
               <evin-rplnsh-rspn-time-locations></evin-rplnsh-rspn-time-locations>
            </div>
        </div>
    </div>
</div>