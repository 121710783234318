<form [formGroup]="usrDtlsfrm" autocomplete="off">
    <div class="card p-3 pt-2 border-0">
        <!--div class="card-header shadow-sm p-3 pb-1 pt-2">
        <div class="d-flex">
            <div class="p-2 w-100 text-center h6">{{usrDetails[0]?.fname}} {{usrDetails[0]?.lname}}</div>
            <div class="p-2 flex-shrink-1"><i class="fa fa-external-link"></i></div>
        </div>
    </div-->
        <div class="row">
            <div class="col-sm-8">
                <div class="card p-0 mb-3 mt-3">
                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                        <h6>Identification</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4">Role:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.role}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Username:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.username}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Tags:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.badges}}<span class="badge bg-primary"></span></div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Domain name:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.pranthname}}<span class="badge bg-primary"></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Last login:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.last_login_time | date: "dd-MM-yyyy hh:mm:ss"}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Last reconnected:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.last_reconnected_time | date: "dd-MM-yyyy hh:mm:ss"}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-3">
                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                        <h6>Personal details</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4">Full name:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.full_name}}</div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-3">
                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                        <h6>Contact information</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4">Mobile phone:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.mobile}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Email:</div>
                            <div class="col-sm-8"><a>{{usrDetails[0]?.email}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-3">
                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                        <h6>Location</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4">Country:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.country}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">State:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.state}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">District:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.district}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Language:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.language}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Timezone:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.time_zone}}</div>
                        </div>
                    </div>
                </div>
                <div class="card p-0 mb-3">
                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                        <h6>Device details</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                            <div class="col-sm-4">Device/browser details:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.browser_details}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">IP Address:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.ip_address}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Application version:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.mobile_app_version}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">Remote login:</div>
                            <div class="col-sm-8">{{usrDetails[0]?.last_remote_login_time | date: "dd-MM-yyyy
                                hh:mm:ss"}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="small mt-3">
                    <p class="mb-0">Created on {{usrDetails[0]?.created_on | date: "dd-MM-yyyy hh:mm:ss"}} by
                        <a>{{usrDetails[0]?.created_user}}</a>
                    </p>
                    <p class="mb-0">Last updated on {{usrDetails[0]?.updated_on | date: "dd-MM-yyyy hh:mm:ss"}} by
                        <a>{{usrDetails[0]?.updated_user}}</a>
                    </p>
                </div>
                <!--hr class="mb-2 mt-0 pt-0" />
            <div class="card h-25 mt-3">
                <div class="card-body text-center p-3">
                    No Image Available
                </div>
            </div-->
            </div>
        </div>
        <div class="card mb-0 border-0">
            <div class="card-body p-2">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="Items-tab" data-bs-toggle="tab" href="#facility"
                            role="tab" aria-controls="Items" aria-selected="true">Facility</a></li>
                    <li class="nav-item"><a class="nav-link" id="Items-tab" data-bs-toggle="tab" href="#domain"
                            role="tab" aria-controls="Items" aria-selected="true" *ngIf="isDmntabShw">Domain</a></li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="facility" role="tabpanel" aria-labelledby="Items-tab">
                        <div class="card mb-0">
                            <div class="card-body p-3 mb-0">
                                <div class="col-xl-12">
                                    <ul class="float-end nav nav-pills nav-primary " id="pills-clrtabinfo"
                                        role="tablist">
                                        <li class=""><a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo"
                                                data-bs-toggle="pill" href="#pills-tabview" role="tab"
                                                aria-controls="pills-tabview" aria-selected="true"><i
                                                    class="fa fa-table me-0" aria-hidden="true"></i> List view</a></li>
                                        <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo"
                                                data-bs-toggle="pill" href="#pills-map" role="tab"
                                                aria-controls="pills-map" aria-selected="false"><i
                                                    class="fa fa-desktop me-0" aria-hidden="true"></i>
                                                Map view</a></li>
                                    </ul>
                                </div>
                                <div class="clearfix"></div>
                                <div class="tab-content" id="pills-clrtabContentinfo">
                                    <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                                        aria-labelledby="pills-tabview-tabinfo">
                                        <div class="table_spacing table-responsive mt-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                style="min-width: 1100px;">
                                                <thead>
                                                    <tr>
                                                        <!--th scope="col" style="width: 5%;"></th-->
                                                        <th scope="col">Item No.</th>
                                                        <th scope="col">Facility name</th>
                                                        <th scope="col">Location</th>
                                                        <!--th scope="col">Move</th>
                                                    <th scope="col">Reorder</th-->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let str of strsLst">
                                                        <!--td><a><i class="fa fa-external-link"
                                                                aria-hidden="true"></i></a></td-->
                                                        <td class="text-center">1</td>
                                                        <td><a>{{ str.store_name }}</a></td>
                                                        <td>{{ str.location }}</td>
                                                        <!--td><i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                        &nbsp;&nbsp;<i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                    </td>
                                                    <td class="text-center">1</td-->
                                                    </tr>
                                                    <!--tr>
                                                    <td><a><i class="fa fa-external-link"
                                                                aria-hidden="true"></i></a></td>
                                                    <td class="text-center">2</td>
                                                    <td><a>Test Nandurbar Somawal PHC</a></td>
                                                    <td>Nandurbar, Maharashtra</td>
                                                    <td><i class="fa fa fa-arrow-up"
                                                            aria-hidden="true"></i>&nbsp;&nbsp;<i
                                                            class="fa fa-arrow-down" aria-hidden="true"></i></td>
                                                    <td class="text-center">2</td>
                                                </tr-->
                                                </tbody>
                                                <tbody>
                                                    <tr *ngIf="strsLst?.length == 0">
                                                        <td colspan="3" class="text-center">{{ noRcrdsTxt }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="float-end mt-3" *ngIf="false">
                                                <button type="button" class="btn btn-sm btn-primary">Save route</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="pills-map" role="tabpanel"
                                        aria-labelledby="pills-map-tabinfo">
                                        <div class="card border mt-4 mb-0">
                                            <div class="card-body p-4">
                                                No Data Available
                                            </div>
                                        </div>
                                        <div class="float-end mt-3" *ngIf="false">
                                            <button type="button" class="btn btn-sm btn-primary">Save route</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane fade" id="domain" role="tabpanel" aria-labelledby="Items-tab" *ngIf="isDmntabShw">
                        <div class="card mb-0">
                            <div class="card-body p-3 mb-0">
                                <div class="col-md-12 col-xl-12">
                                    <div class="row">
                                        <div class="col-md-6 col-xl-6">
                                            <h6>Access to domains</h6>
                                            
                                            <div class="card p-0 border-0 mt-3" *ngIf="lstDmns?.length!=0">
                                                <div class="card-header p-2">
                                                    <!-- <h6 *ngIf="slctDmnNme != ''" class="mb-0">Domains of <b>{{slctDmnNme | uppercase}}</b></h6> -->
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="col-xl-12 col-sm-12 mt-4">
                                                        <div id="accordian" class="menu-su-domain-container">
                                                            <ul class="menu">
                                                                <li class="menu-item-has-children" *ngFor="let dl1 of lstDmns">
                                                                    <a class="span_div" [ngClass]="{'bg-danger' : (dl1.isDeleted == true)}">
                                                                        <span class="span_div1">{{dl1.pranthName}}</span>
                                                                        <span class="icon_div">                                                                    
                                                                            <i class="fa fa-trash" aria-hidden="true" *ngIf="dl1.isDeleted != true" (click)="onDlnkDmn(dl1.pranthId)"></i>
                                                                            <i class="fa fa-plus" aria-hidden="true" *ngIf="dl1.isDeleted == true" (click)="onAddDmn('s', dl1.pranthId)"></i>
                                                                        </span>
                                                                    </a>
                                                                    <ul *ngIf="dl1?.Pranth?.length!=0">
                                                                        <li *ngFor="let dl2 of dl1?.Pranth">
                                                                            <a class="span_div" [ngClass]="{'bg-danger' : (dl2.isDeleted == true)}">
                                                                                <span class="span_div1">{{dl2.pranthName}}</span>
                                                                                <span class="icon_div">
                                                                                    <i class="fa fa-trash" aria-hidden="true" *ngIf="dl2.isDeleted != true" (click)="onDlnkDmn(dl2.pranthId)"></i>
                                                                                    <i class="fa fa-plus" aria-hidden="true" *ngIf="dl2.isDeleted == true" (click)="onAddDmn('s', dl2.pranthId)"></i>
                                                                                </span>
                                                                            </a>
                                                                            <ul *ngIf="dl2?.Pranth?.length!=0">
                                                                                <li *ngFor="let dl3 of dl2?.Pranth">
                                                                                    <a class="span_div" [ngClass]="{'bg-danger' : (dl3.isDeleted == true)}">
                                                                                        <span class="span_div1">{{dl3.pranthName}}</span>
                                                                                        <span class="icon_div">
                                                                                            <i class="fa fa-trash" aria-hidden="true" *ngIf="dl3.isDeleted != true" (click)="onDlnkDmn(dl3.pranthId)"></i>
                                                                                            <i class="fa fa-plus" aria-hidden="true" *ngIf="dl3.isDeleted == true" (click)="onAddDmn('s', dl3.pranthId)"></i>
                                                                                        </span>
                                                                                    </a>
                                                                                    <ul *ngIf="dl3?.Pranth?.length!=0">
                                                                                        <li *ngFor="let dl4 of dl3?.Pranth">
                                                                                            <a class="span_div" [ngClass]="{'bg-danger' : (dl4.isDeleted == true)}">
                                                                                                <span class="span_div1">{{dl4.pranthName}}</span>
                                                                                                <span class="icon_div">
                                                                                                    <i class="fa fa-trash" aria-hidden="true" *ngIf="dl4.isDeleted != true" (click)="onDlnkDmn(dl4.pranthId)"></i>
                                                                                                    <i class="fa fa-plus" aria-hidden="true" *ngIf="dl4.isDeleted == true" (click)="onAddDmn('s', dl4.pranthId)"></i>
                                                                                                </span>
                                                                                            </a>
                                                                                            <ul *ngIf="dl4?.Pranth?.length!=0">
                                                                                                <li *ngFor="let dl5 of dl4?.Pranth">
                                                                                                    <a class="span_div" [ngClass]="{'bg-danger' : (dl5.isDeleted == true)}">
                                                                                                        <span class="span_div1">{{dl5.pranthName}}</span>
                                                                                                        <span class="icon_div">
                                                                                                            <i class="fa fa-trash" aria-hidden="true" *ngIf="dl5.isDeleted != true" (click)="onDlnkDmn(dl5.pranthId)"></i>
                                                                                                            <i class="fa fa-plus" aria-hidden="true" *ngIf="dl5.isDeleted == true" (click)="onAddDmn('s', dl5.pranthId)"></i>
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <ul *ngIf="dl5?.Pranth?.length!=0">
                                                                                                        <li *ngFor="let dl6 of dl5?.Pranth">
                                                                                                            <a class="span_div" [ngClass]="{'bg-danger' : (dl6.isDeleted == true)}">
                                                                                                                <span class="span_div1">{{dl6.pranthName}}</span>
                                                                                                                <span class="icon_div">
                                                                                                                    <i class="fa fa-trash" aria-hidden="true" *ngIf="dl6.isDeleted != true" (click)="onDlnkDmn(dl6.pranthId)"></i>
                                                                                                                    <i class="fa fa-plus" aria-hidden="true" *ngIf="dl6.isDeleted == true" (click)="onAddDmn('s', dl6.pranthId)"></i>
                                                                                                                </span>
                                                                                                            </a>
                                                                                                            <ul *ngIf="dl6?.Pranth?.length!=0">
                                                                                                                <li *ngFor="let dl7 of dl6?.Pranth">
                                                                                                                    <a class="span_div" [ngClass]="{'bg-danger' : (dl7.isDeleted == true)}">
                                                                                                                        <span class="span_div1">{{dl7.pranthName}}</span>
                                                                                                                        <span class="icon_div">
                                                                                                                            <i class="fa fa-trash" aria-hidden="true" *ngIf="dl7.isDeleted != true" (click)="onDlnkDmn(dl7.pranthId)"></i>
                                                                                                                            <i class="fa fa-plus" aria-hidden="true" *ngIf="dl7.isDeleted == true" (click)="onAddDmn('s', dl7.pranthId)"></i>
                                                                                                                        </span>
                                                                                                                    </a>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-xl-6">
                                            <h6>Access to domains</h6>

                                            <div class="form-group mt-3">
                                                <label>Domain(s) to add</label>
                                                <div class="row">
                                                    <div class="w-75 float-left">
                                                        <ng-template #cstmStore let-model="item">
                                                            <span class="font-weight-bold">{{model.pranthName}}</span>
                                                            <br />
                                                            <span class="fs-12">{{model.pranthLevel}}</span>
                                                        </ng-template>
                                                        <input name="store" type="text" placeholder="Filter by Domain"
                                                            class="form-control form-control-sm" formControlName="dmnID"
                                                            [typeahead]="lstPrnths" [typeaheadScrollable]="true"
                                                            [typeaheadOptionsLimit]="500" (typeaheadOnDeSelect)="onChngDmn($event, 'd')"
                                                            [typeaheadItemTemplate]="cstmStore"
                                                            typeaheadOptionField="pranthName" id="Store"
                                                            (typeaheadOnSelect)="onChngDmn($event, 's')" [adaptivePosition]="true" />
                                                    </div>
                                                    <div class="w-25 float-left">
                                                        <button
                                                            class="btn btn-sm btn-primary float-start" (click)="onAddDmn('m', '')">Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>