<div class="card mb-3 border-0" [formGroup]="sntNtfcnForm">
    <div class="card-body p-2">
        <div class="row">
            <div class="col-xl-12">
                <div class="row row mb-2">
                    <div class="form-group col-md-3">
                        <label for="textBox">From</label>
                        <div class="input-group">
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker readonly
                                class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker" formControlName="fromDate"
                                placeholder="" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' [maxDate]="dt" [(bsValue)]="ctDte" (bsValueChange)="onChngDt($event, 'fd')">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                    [attr.aria-expanded]="dpFD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpFD.hide();clearBSDate('fromDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="textBox">To</label>
                        <div class="input-group">
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker readonly
                            (bsValueChange)="onChngDt($event, 'td')" (bsValue)="dt" ([ngModel])="defToDate"
                                class="form-control form-control-sm optinbg bsDP-bg" #dpTD="bsDatepicker" formControlName="tooDate"
                                placeholder="To" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                onkeypress='return event.charCode == 8' [minDate]="toMin" [maxDate]="dt">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpTD.toggle()"
                                    [attr.aria-expanded]="dpTD.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpTD.hide();clearBSDate('tooDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-4"></div>
                    <div class="form-group col-md-2">
                        <div class="text-end pt_2">
                            <button class="btn btn-sm btn-light me-2 mb-2" (click)="OnCncl()" *ngIf="cnfgntfnPrmsn">Reset</button>
                            <button class="btn btn-sm btn-primary me-2 mb-2" type="submit" (click)="LoadGrid()" *ngIf="cnfgntfnPrmsn"
                                [disabled]="sntNtfcnForm.invalid">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-xl-4 mb-2">
                        <!-- <span style="display: inline-block;" class="me-2">
                        <label tyle="display: inline-block;">Filter</label>
                    </span>
                    <span style="display: inline-block;">
                        <select class="form-select form-select-sm">
                            <option>5</option>
                            <option>10</option>
                        </select>
                    </span> -->
                    </div>
                    <div class="col-xl-4 mb-2" *ngIf="lstGrid?.length!=0">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-4" *ngIf="lstGrid?.length!=0">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">Item No.</th>
                                <th class="text-center">Sent to</th>
                                <th class="text-center">Type</th>
                                <th class="text-center">Message</th>
                                <th class="text-center">Status</th>
                                <th class="text-center">Time</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="lstGrid.length != 0">
                            <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i = index">
                                <td class="text-center">{{i+1}}</td>
                                <td>{{item.firstName + ' ' + item.lastName + ' (+91 ' + item.mobile + ')'}}</td>
                                <td>{{item.messageType}}</td>
                                <td>{{item.messageData}}</td>
                                <td>{{item.statusMessage}}</td>
                                <td>{{item.createdOn | date:"dd-MM-yyyy"}}</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="lstGrid.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">{{noRcrdsTxt}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row ps-0 pe-0 pt-3 pb-3">
                    <div class="col-lg-4 col-xxl-6 pb-3">
                    </div>
                    <div class="col-xl-4 mb-2" *ngIf="lstGrid?.length!=0">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-4" *ngIf="lstGrid?.length!=0">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>