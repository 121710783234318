import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-activity-rate',
  templateUrl: './activity-rate.component.html',
  styleUrls: ['./activity-rate.component.css']
})

export class ActivityRateComponent extends BaseComponent implements OnInit {

  isSbmtd = false; lstAllMats: any[] = []; lstMatTags: any[] = []; lstFacTags: any[] = []; isMatBdge = 'badge'; slctdPrdId: any[] = [];
  dt2day = new Date(); frmDate = this.wkBkDt; lstGrid: any[] = []; usrLvl = ''; lstTxnTyps: any[] = [];
  actvtyRateFrm: FormGroup = this.fb.group({}); noRcrdTxt = environment.TblNoRcrds; isExprt = false; crntPage = 1; pageEntrs = '';
  pageSize = environment.tblPageSize; totalItems: any; facTagName = ''; isPgLd = false; slctdMatName: any[] = []; lstSlctdMatName: any = [];
  slctdFac: any[] = []; dropdownSettings = {singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true}; slctdMat: any[] = []; lstSlctdFacTags: any[] = []; lstShowFacTags: any[] = [];
  lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; actStore = 0; ttlCcp = 0; percntge = 0; perc = 0; stsLst: any[] = []; 
  dstLst: any[] = []; blkLst: any[] = []; slctdTxn: any[] = []; lstSlctdTxn: any[] = [];  isRprtsHlpShw = environment.isRprtsHlpShw;

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility)
    }), this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.stsLst = s.statesLst, this.blkLst = s.blockLst, 
      this.dstLst = s.dstLst, this.lstTxnTyps = s.txnTypeLst });
  }

  ngOnInit(): void {
    this.actvtyRateFrm = this.fb.group({
      fromDate: [''], toDate: [this.dt2day], isMatBdge: ['badge'], storeTags: [[], Validators.required], block: [null],
      productIds: [[], Validators.required], productTags: [[], Validators.required], state: [null], district: [null], txnTypeIds:[] }),
      this.onClkRdb('productTags'); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.actvtyRateFrm.get('country')?.setValue(cntryId);
    }
    this.lstTxnTyps.forEach(e => { this.lstSlctdTxn.push({ id: e.id, name: e.name }), this.slctdTxn.push(e) });
    this.dmnLocation(), setTimeout(() => { this.onAS(), this.isPgLd = false; }, 1000);
  }

  get fc() { return this.actvtyRateFrm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.actvtyRateFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.actvtyRateFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
          
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.actvtyRateFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.actvtyRateFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 
          'district', true)]).then(() => {
            this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
            this.actvtyRateFrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.actvtyRateFrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => {  this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });        
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.actvtyRateFrm.get('state')?.setValue('null'), this.actvtyRateFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  LoadReport() {
    this.checkFormValidations(this.actvtyRateFrm), this.isSbmtd = true, this.ttlCcp = 0, this.actStore = 0, this.perc = 0;
    let fT: any[] = [], mT: any[] = [], mN: any[] = [], as: any[] = [], tc: any[] = [], per: any[] = [], txC: any[] = [], payLoad: any = this.actvtyRateFrm.getRawValue();
    if ((this.fc.productIds?.value != 0 || this.fc.productTags?.value.length != 0) && this.fc.storeTags.value != '') {
      payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district, delete payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, delete payLoad.block,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, delete payLoad.state, delete payLoad.isMatBdge,
        payLoad.storeTags = payLoad.storeTags != [] && payLoad.storeTags != null ?
          this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
      payLoad.productTags = payLoad.productTags != [] && payLoad.productTags != null ?
        this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT;
      payLoad.productIds = payLoad.productIds != [] && payLoad.productIds != null ?
        this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN;
      payLoad.txnTypeIds = payLoad.txnTypeIds != [] && payLoad.txnTypeIds != null ?
        this.lstSlctdTxn?.forEach((e: any) => { txC.push(e.id) }) : [], payLoad.txnTypeIds = txC;
      payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : '';
      if (this.isPgLd)
        payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
      else
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null
      this.FetchData(environment.apiCalls.post, environment.apiPaths.activity, environment.apiMthds.actvtyRprt.
        replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), payLoad, 4).then(() => { let res = this.result;
          this.lstGrid = [], this.facTagName = (<string>localStorage.getItem('facTag'));
          if (this.result) {
            if (this.result?.data) {
              this.lstGrid = this.result?.data?.txnActivityList ?? [], this.totalItems = this.result?.data?.count ?? this.lstGrid.length,
                this.pageEntrs = this.result.data?.count ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems,
                this.lstGrid.forEach(a => { as.push(a.activeStores), tc.push(a.totalStores), per.push(a.percentage) });
                if (this.lstGrid.length > 0) {
                  this.actStore = as.reduce((x, value) => { x += value; return x }), this.ttlCcp = tc.reduce((x, value) => { x += value; return x }),
                  this.percntge =((this.actStore)/(this.ttlCcp))*100; this.perc = +(this.percntge);
                  // this.percntge = per.reduce((x, value) => { x += value; return x }), this.perc = +((this.percntge) / per.length);
                }
            }
            else
              this.glblSvc.onTstr('i', res.message);
          }
          else
            this.glblSvc.onTstr('e', res.message);
        });
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onClkRdb(ctrl: string) {
    if (ctrl == 'productTags')
    this.fc?.productIds.setValue([]), this.fc?.productId?.clearValidators(), this.fc?.productTag?.setValidators([Validators.required]),
    this.lstSlctdMatName = [];
    else if(ctrl == 'productIds')
    this.fc?.productTags.setValue([]), this.fc?.productTag?.clearValidators(), this.fc?.productId?.setValidators([Validators.required]),
    this.fc?.productTag?.updateValueAndValidity(), this.fc?.productId?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  clearBSDate(ctrl: string) {
    this.actvtyRateFrm.get(ctrl)?.setValue('');
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.actvtyRateFrm.get('district')?.setValue(null),
          this.actvtyRateFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.actvtyRateFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.actvtyRateFrm.get('district')?.enable(),
                this.actvtyRateFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.actvtyRateFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.actvtyRateFrm.get('district')?.setValue('null'), this.actvtyRateFrm.get('block')?.setValue('null'),
          this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('block')?.disable();
      else
        this.actvtyRateFrm.get('block')?.setValue('null'), this.actvtyRateFrm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.actvtyRateFrm.get('state')?.enable(), this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.actvtyRateFrm.get('state')?.disable(), this.actvtyRateFrm.get('district')?.enable(), this.actvtyRateFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.actvtyRateFrm.get('state')?.disable(), this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.actvtyRateFrm.get('state')?.disable(), this.actvtyRateFrm.get('district')?.disable(), this.actvtyRateFrm.get('block')?.disable();
  }

  onReset() {
    this.lstSlctdTxn = [...[]]; this.slctdTxn =[];
    this.lstTxnTyps.forEach(e => { this.lstSlctdTxn.push({ id: e.id, name: e.name }), this.slctdTxn.push(e) });
    this.lstSlctdMatName = [...[]]; this.lstSlctdFacTags = [...[]]; this.lstSlctdMatTags = [...[]]; this.slctdMat = []; this.slctdMatName = [];
    this.actvtyRateFrm.get('fromDate')?.setValue(this.wkBkDt); this.actvtyRateFrm.get('toDate')?.setValue(this.dt2day),
    this.actvtyRateFrm.get('isMatBdge')?.setValue('badge'), this.actvtyRateFrm.get('storeTags')?.setValue([]);
      this.actvtyRateFrm.get('productTags')?.setValue([]), this.actvtyRateFrm.get('productIds')?.setValue([]),
      this.actvtyRateFrm.get('txnTypeIds')?.setValue(this.slctdTxn), this.dmnLocation(), setTimeout(() => { this.onAS()}, 500); 
  }

  onEE() {
    let fT: any[] = [], mT: any[] = [], mN: any[] = [], tC: any[] = []; let payLoad: any = this.actvtyRateFrm.getRawValue();
    payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district, delete payLoad.district, payLoad.blockId = 
    payLoad.block == 'null' ? 0 : +payLoad.block, delete payLoad.block, payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, 
    delete payLoad.state, delete payLoad.isMatBdge, payLoad.storeTags = payLoad.storeTags != [] && payLoad.storeTags != null ?
        this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
    payLoad.productTags = payLoad.productTags != [] && payLoad.productTags != null ?
      this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.productTags = mT;
    payLoad.productIds = payLoad.productIds != [] && payLoad.productIds != null ?
      this.lstSlctdMatName?.forEach((e: any) => { mN.push(e.id) }) : [], payLoad.productIds = mN;
    payLoad.txnTypeIds = payLoad.txnTypeIds != [] && payLoad.txnTypeIds != null ?
      this.lstSlctdTxn?.forEach((e: any) => { tC.push(e.id) }) : [], payLoad.txnTypeIds = tC;
    payLoad.toDate = payLoad.toDate != '' && payLoad.toDate != null ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : ''
    if (this.isPgLd)
      payLoad.fromDate = this.glblSvc.setDtFrmt(this.wkBkDt, this.dtF.ymd) + this.tmF.zeroTM;
    else
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null
    payLoad.userName = this.usrLgnDtls.userName, payLoad.email = this.usrLgnDtls.email
    this.FetchData(environment.apiCalls.post, environment.apiPaths.activity, environment.apiMthds.actvtyRprtExprt, payLoad, 4).then(() => {
      this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadReport();
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      } else if (ctrl == 't') {
        if (this.lstSlctdTxn.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdTxn.push(item);
      } else if (ctrl == 'm') {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      } else
        if (this.lstSlctdMatName.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatName.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 't')
        this.lstSlctdTxn = this.lstSlctdTxn.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = this.lstSlctdMatName.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 't')
        this.lstSlctdTxn = this.lstTxnTyps;
      else if (ctrl == 'm')
        this.lstSlctdMatTags = this.lstMatTags;
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = this.lstAllMats;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 't')
        this.lstSlctdTxn = [];
      else if (ctrl == 'm')
        this.lstSlctdMatTags = [];
      else
        if (ctrl == 'n')
          this.lstSlctdMatName = [];
    }
  }

  // dropdowns() {
  //   this.dropdownSettings = {
  //     singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  //     itemsShowLimit: 3, allowSearchFilter: true
  //   };
  // }

}