<div class="card p-0 border-0">
    <div class="card-body pt-4">
        <div class="row p-0 mt-2">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <form [formGroup]="chngPswdFrm">
                    <div class="row form-group mb-2">
                        <div class="col-xl-3">
                            <label for="textBox">Username</label>
                        </div>
                        <div class="col-xl-3">
                            <input type="text" placeholder="Username" class="form-control" formControlName="userName" readonly>
                        </div>
                    </div>
                    <div class="row form-group mb-2">
                        <div class="col-xl-3">
                            <label for="textBox">Send OTP to..?</label>
                        </div>
                        <div class="col-xl-3">
                            <div class="form-check form-check-inline mt-2">
                                <input class="form-check-input" type="radio" id="rbSE1" name="otp" value="mobile" formControlName="otp">
                                <label class="form-check-label" for="otp">SMS</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" id="rbSE2" name="otp" value="email" formControlName="otp">
                                <label class="form-check-label" for="otp">Email</label>
                            </div>
                        </div>
                        <div class="col-xl-3" *ngIf="!isShow">
                            <button class="btn btn-primary btn-sm float-start mt-1 ms-2" type="button" (click)="onSend()">Send</button>
                        </div>
                    </div>
                    <div *ngIf="isShow">
                        <div class="row form-group mb-2 mt-3">
                            <div class="col-xl-3">
                                <label for="textBox">Enter OTP</label>
                            </div>
                            <div class="col-xl-3">
                                <div class="row">
                                    <div class="col-xl-4">
                                        <input type="text" placeholder="" class="form-control" value="" maxlength="2" id="otp1" autocomplete="off"
                                            [(ngModel)]="o1" [ngClass]="{ 'is-invalid': isSave && o1.length != 2 }" (keyup)="onOTP(1)"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="dmy">
                                    </div>
                                    <div class="col-xl-4">
                                        <input type="text" placeholder="" class="form-control" value="" maxlength="2" id="otp2" autocomplete="off"
                                            [(ngModel)]="o2" [ngClass]="{ 'is-invalid': isSave && o1.length != 2 }" (keyup)="onOTP(2)"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="dmy">
                                    </div>
                                    <div class="col-xl-4">
                                        <input type="text" placeholder="" class="form-control" value="" maxlength="2" id="otp3" autocomplete="off"
                                            [(ngModel)]="o3" [ngClass]="{ 'is-invalid': isSave && o1.length != 2 }" (keyup)="onOTP(3)"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' formControlName="dmy">
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 mt-2">
                                <span class="reset-password-link">
                                    If don't receive OTP? <a class="btn-link text-danger" (click)="onResend()">Resend</a>
                                </span>
                            </div>
                        </div>
                        <div class="row form-group mb-2">
                            <div class="col-xl-3">
                                <label for="textBox">Old Password</label>
                            </div>
                            <div class="col-xl-3">
                                <input type="password" placeholder="Old Password" class="form-control" id="oldPswd" formControlName="oldPassword"
                                    [ngClass]="{ 'is-invalid': isSave && fc.oldPassword.errors }" autocomplete="new-password">
                                <div class="show-hide" style="top: 20px !important; right: 40px !important;">
                                    <span [ngClass]="isSHde ? 'hide' : 'show'" (click)="onSHde()"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group mb-2">
                            <div class="col-xl-3">
                                <label for="textBox">New Password</label>
                            </div>
                            <div class="col-xl-3">
                                <input type="password" placeholder="New Password" class="form-control" id="chngNewPswd"
                                    formControlName="newPassword"
                                    [ngClass]="{ 'is-invalid': isSave && (fc.newPassword.errors || !isValidPswd) }">
                                <div class="show-hide" style="top: 20px !important; right: 40px !important;">
                                    <span [ngClass]="isShwHde ? 'hide' : 'show'" (click)="onShwHde()"></span>
                                </div>
                                <div *ngIf="isSave && fc.newPassword.errors" class="invalid-feedback">
                                    <div *ngIf="fc.newPassword.errors.minlength || fc.newPassword.errors.maxlength">Min.
                                        & Max. should be 7 & 18 char.s only and no spaces will be allowed</div>
                                    <div *ngIf="fc.newPassword.errors.pattern">Enter atleast one number & one alphabet
                                        (in Upper or Lower)</div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group mb-2">
                            <div class="col-xl-3">
                                <label for="textBox">Confirm New Password</label>
                            </div>
                            <div class="col-xl-3">
                                <input type="password" placeholder="Confirm New Password" class="form-control"
                                    formControlName="cnfmPswd" [ngClass]="{ 'is-invalid': isSave && fc.cnfmPswd.errors }"
                                    onkeypress='return ((event.charCode == 32)) ||
                                    (event.charCode >= 64 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                                    (event.charCode >= 48 && event.charCode <= 57)'>
                                <div *ngIf="isSave && fc.cnfmPswd.errors" class="invalid-feedback">
                                    <div *ngIf="fc.cnfmPswd.errors.mustMatch">Confirm New Password must match New Password</div>
                                </div>
                            </div>
                            <div class="col-xl-3"></div>
                            <div class="col-xl-3">
                                <button class="btn btn-primary btn-sm float-end mt-1 ms-2" type="submit" (click)="isSave=true;onSave()">Verify & Submit</button>
                                <button class="btn btn-light btn-sm float-end mt-1" type="button" (click)="isShow=false">Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>