<div *ngIf="pageType=='List'">
    <div class="row">
        <div class="col-sm-3">
            <div class="input-group">
                <input type="text" placeholder="Filter by Domain Name" class="form-control" [(ngModel)]="slctDmnNme">
                <div class="input-group-append">
                    <button type="button" title="" (click)="LoadTreeDomains()" class="btn btn-outline-bsdp p-2">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-sm-9 pt-2">
            <button type="button" class="btn btn-sm btn-light float-end" (click)="dfltStrTgsStk=[];crntPage=1; lstDmns=[]; onReset()">Reset</button>
        </div>
    </div>
    <hr />
    <div class="col-xl-12">
        <div class="row" *ngIf="lstDmns?.length!=0">
            <div class="col-xl-12 mb-2">
                <div class="row">
                    <div class="col-xl-12 float-start">
                        <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                            <i class="fa fa-file-excel-o"></i>&nbsp;Export
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 text-start">
                {{pageEntrs}}
            </div>
            <div class="col-xl-6">
                <div class="float-end">
                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div class="table_spacing table-responsive">
        <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
            <thead>
                <tr>
                    <th class="text-center">Item No.</th>
                    <th>Domain Name</th>
                    <th>Description</th>
                    <th>Domain Level</th>
                    <th class="text-center">Switch Domain</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let dd of lstDmns | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index">
                    <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                    <td>
                        <a [routerLink]="" [queryParams]="{tab:'list'}" queryParamsHandling="merge"
                            (click)="onDmnClk(dd); pageType='Details'">{{dd.pranthName}}</a>
                    </td>
                    <td>{{dd.description}}</td>
                    <td>{{dd.pranthLevel}}</td>
                    <td class="text-center">
                        <a (click)="onTreDmnClk(dd)"><i class="fa fa-exchange" aria-hidden="true"></i></a> <!-- *ngIf="dd.mappingExists == true"  -->
                    </td>
                </tr>
            </tbody>
            <tbody [hidden]="lstDmns?.length !=0">
                <tr>
                    <td class="text-center" colspan="5">{{noRcrdTxt}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row mt-3" *ngIf="lstDmns.length!=0">
        <div class="col-xl-6 text-start">
            {{pageEntrs}}
        </div>
        <div class="col-xl-6">
            <div class="float-end">
                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<div *ngIf="pageType!='List'">
    <div class="row p-0" *ngIf="pageType!='List'">
        <h6 class="mb-0 text-center">
            <button type="button" class="btn btn-sm btn-light float-end" (click)="pageType='List'">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </h6>
    </div>
    <div class="row">
        <evin-domain-details></evin-domain-details>
    </div>
</div>