<div class="modal-header">
    <h4 class="modal-title">Cancel</h4>
    <button type="button" class="btn btn-light btn-sm close" aria-label="Close" (click)="actMdl.close('done')">
        <span class="f-16" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form class="theme-form" [formGroup]="cnclForm">
        <div class="row">
            <div class="col-xl-12">
                <div class="row p-2">
                    <div class="col-sm-4 text-xl-right text-sm-right text-xs-left">Next Status</div>
                    <div class="col-sm-8">Cancelled</div>
                </div>
                <div class="row p-2">
                    <div class="col-sm-4 text-xl-right text-sm-right text-xs-left">
                        <label class="col-form-label">Reason<span class="text-danger" *ngIf="isRsnMand">*</span></label>
                    </div>
                    <div class="col-sm-8">
                        <select class="form-select form-select-sm mt-2" formControlName="reason" #cnclRsn
                            [ngClass]="{ 'is-invalid': isCncl && fc.reason.errors }" (change)="isOthr=false;onChngRsn()">
                            <option value="">-Select-</option>
                            <option [value]="rs.reasonName" *ngFor="let rs of lstCnclRsns">{{rs.reasonName}}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="isCncl && fc.reason.errors">
                            <div *ngIf="fc.reason.errors.required">Please choose Reason</div>
                        </div> <br />
                        <textarea class="form-control form-control-name taRS" placeholder="Enter Other Reason"
                            [ngClass]="{ 'is-invalid': isCncl && fc.others.errors }" formControlName="others" *ngIf="isOthr">
                        </textarea>
                        <div class="invalid-feedback" *ngIf="isCncl && fc.others.errors">
                            <div *ngIf="fc.others.errors.required">Please Enter Other Reason</div>
                        </div>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-sm-4 text-xl-right text-sm-right text-xs-left mt-2">Comments</div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <textarea class="form-control taRS" formControlName="comments" placeholder="Comments"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-sm-4 text-xl-right text-sm-right text-xs-left mt-2">Capture Lat & Long</div>
                    <div class="col-sm-8">
                        <div class="form-group">
                            <input class="form-check-input" type="radio" name="isReqLL" value="1" (click)="isReqLL='1'"
                                [checked]="isReqLL=='1'">&nbsp;&nbsp;<label class="form-check-label">Yes</label>&nbsp;&nbsp;
                            <input class="form-check-input" type="radio" name="isReqLL" value="0" (click)="isReqLL='0'"
                                [checked]="isReqLL=='0'">&nbsp;&nbsp;<label class="form-check-label">No</label><br />
                            <!-- <div class="highTemp font-wt" *ngIf="isReqLL == '1'">Kindly provide Location access to Cancel Indent..!</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary me-2" (click)="onChkLL()">Submit</button>
    <button type="button" class="btn btn-sm btn-light" (click)="actMdl.close('done')">Cancel</button>
</div>