import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-discards-overview',
  templateUrl: './discards-overview.component.html',
  styleUrls: ['./discards-overview.component.css']
})
export class DiscardsOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
