<div class="card p-0 border-0">
    <div class="card-body p-4 pt-3">
        <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="cceList"><a class="nav-link" id="top-trans-tab" data-bs-toggle="tab" href="#top-trans"
                    role="tab" aria-controls="top-trans" aria-selected="true" (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">Assets</a></li>
            <li class="nav-item" *ngIf="cratBulkUpld"><a class="nav-link" id="create-trans-top-tab" data-bs-toggle="tab"
                    href="#top-create-trans" role="tab" aria-controls="top-profile" aria-selected="false" (click)="onTabSelection('new')" [ngClass]="paramTab === 'new' ? 'active' : ''">
                    {{assetHead}}</a></li>
            <li class="nav-item" *ngIf="cratBulkUpld"><a class="nav-link" id="bulkup-top-tab" data-bs-toggle="tab" href="#top-bulkup"
                    role="tab" aria-controls="top-bulkup" aria-selected="false" (click)="onTabSelection('upld')" [ngClass]="paramTab === 'upld' ? 'active' : ''">Bulk upload</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="top-trans" role="tabpanel" aria-labelledby="top-trans-tab" 
             [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-assets-view></evin-assets-view>
            </div>         
            <div class="tab-pane fade" id="top-create-trans" role="tabpanel" aria-labelledby="create-trans-top-tab"
            [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-assets-create></evin-assets-create>
            </div>
            <div class="tab-pane fade" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab" 
             [ngClass]="paramTab === 'upld' ? 'show active' : '' ">
                <evin-bulk-upload></evin-bulk-upload>
            </div>
        </div>
    </div>
</div>
