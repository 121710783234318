<form [formGroup]="stupManufViewForm" autocomplete="off">
    <div class="row p-0 mt-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="producerName">Manufacturer</label>
                            <input type="text" placeholder="Filter by Manufacturer Name"
                                class="form-control form-control-sm" id="producerName" formControlName="producerName">
                        </div>
                        <div class="form-group col-xl-3 " *ngIf="false">
                            <label for="tagName">Manufacturer Category:</label>
                            <select id="tagName" class="form-select form-select-sm" formControlName="tagName">
                                <option value="">All</option>
                                <option *ngFor="let item of producerBadges" [value]="item.name">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="col-xl-9" *ngIf="mnfrLst">
                            <div class="float-xl-end pt_2">
                                <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary" (click)="crntPage=1;loadManufacturers()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0" />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="manufacturersLst?.length!=0">
                <div class="col-xl-12 mb-2">
                    <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                    <thead>
                        <tr>
                            <!-- <th></th> -->
                            <th class="text-center">Item No.</th>
                            <th>Manufacturer category</th>
                            <th>Contact Name</th>
                            <th>Mobile Number</th>
                            <th>Last updated</th>
                            <!-- <th>Shortcuts</th> -->
                            <th *ngIf="mnfrEdt"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of manufacturersLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems} ; let index = index">
                            <!-- <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td> -->
                            <td class="text-center">{{(pageSize*(crntPage-1))+index+1}}</td>
                            <td (click)="onMfgDetails(item.producerId)"><a>{{item.producerName}}</a></td>
                            <td>{{item?.contactName}}</td>
                            <td>{{item?.mobileNumber}}</td>
                            <td><span><a>{{item.updatedBy}}</a></span>
                                <p class="small">{{item.lastupdated | date: "yy/MM/dd hh:mm a"}}</p>
                            </td>
                            <!-- <td><a data-toggle="tooltip" data-placement="top" title="Materials">
                                <i class="fa fa-file-o" aria-hidden="true"></i></a>
                            </td> -->
                            <!-- <td   ><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td> -->
                            <td class="text-center">
                                <span>
                                    <a *ngIf="mnfrEdt" (click)="onMfgEdit(item.producerId)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                </span>&nbsp;
                                <span>
                                    <a  (click)="onRemove(item.producerId)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="manufacturersLst.length == 0">
                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="manufacturersLst?.length!=0">
                <div class="col-xl-6 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-6">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>