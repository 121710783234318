<body style="background-color: #ffffff !important;">
  <div class="row scroll">
    <div class="col-xl-7 col-md-7">
      <img class="bg-img-cover bg-center" src="assets/img/login_bg.jpg" alt="Evin login page" style="width: 100%; height: 100vh;">
    </div>
    <div class="col-xl-5 col-md-5 col-sm-12 p-0 bg_img_cover_clr">
      <div class="login-card" style="align-items: baseline !important;">
        <div>
          <div>
            <a class="logo mb-1">
              <img class="img-fluid" src="assets/img/evin-login-logo.png" alt="looginpage">
            </a>
          </div>
          <div class="login-main shadow">
            <form [formGroup]="frgtPswdFrm">
              <h4>Forgot Password</h4>
              <div class="form-group">
                <label class="col-form-label">Username</label>
                <input class="form-control form-control-sm mb-1" type="text" value="" placeholder="Username"
                  [ngClass]="{ 'is-invalid': isSend && fc.userName.errors }" formControlName="userName" id="user_id">
                <!-- <div *ngIf="isSend && fc.userName.errors" class="invalid-feedback">
                  <div *ngIf="fc.userName.errors.required">Please fill Username</div>
                </div>
                <div *ngIf="isSend && fc.sendOTPTo.errors" class="invalid-feedback col-md-12">
                  <div *ngIf="fc.sendOTPTo.errors.required">Please choose Send OTP To</div>
                </div> -->
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <label class="col-form-label">Send OTP to..?</label>&nbsp;&nbsp;
                    <div class="form-check form-check-inline mt-2">
                      <input class="form-check-input" type="radio" id="rbSE1" name="sendOTPTo" value="mobile"
                        [ngClass]="{ 'is-invalid': isSend && fc.sendOTPTo.errors }" formControlName="sendOTPTo">
                      <label class="form-check-label" for="rbSE1">SMS</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="rbSE2" name="sendOTPTo" value="email"
                        [ngClass]="{ 'is-invalid': isSend && fc.sendOTPTo.errors }" formControlName="sendOTPTo">
                      <label class="form-check-label" for="rbSE2">Email</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button class="btn btn-primary btn-sm float-end mt-1 ms-2" type="submit" (click)="onSend()">Send</button>
                    <button class="btn btn-light btn-sm float-end mt-1" type="button" [routerLink]="['']">Back</button>
                  </div>
                </div>
                <div class="row">
                  <div *ngIf="isSend && fc.sendOTPTo.errors" class="invalid-feedback col-md-12">
                    <div *ngIf="fc.sendOTPTo.errors.required">Please choose Send OTP To</div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <p class="small text-center mt-3 text-muted">© 2021 ELECTRONIC VACCINE INTELLIGENCE NETWORK</p>
        </div>
      </div>
    </div>
  </div>
</body>