import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-stock-report-expires-before',
  templateUrl: './stock-report-expires-before.component.html',
  styleUrls: ['./stock-report-expires-before.component.css']
})

export class StockReportExpiresBeforeComponent extends BaseComponent implements OnInit {

  lstGrid: any[] = []; noRcrdsTxt = environment.TblNoRcrds; pageSize = environment.tblPageSize; totalItems: any; crntPage = 1; pageEntrs = '';

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.LoadGrid();
  }

  LoadGrid() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.invntry, environment.apiMthds.getPrdctsbyExpDt.replace('ddd', '2021-06-17').
        replace('rrr', `${this.chsnDmnId}`).replace('pageNo', `${this.crntPage-1}`).
        replace('pageSize', `${this.pageSize}`), null).then(() => { let res = this.result;
      if (this.result) {
        if (this.result.data) {
          this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.lstGrid = this.result?.data?.fetchProductsListBasedOnExpire ?? [],
            this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadGrid();
  }

}