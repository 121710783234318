import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-rspns-tm-rpar-overview',
  templateUrl: './rspns-tm-rpar-overview.component.html',
  styleUrls: ['./rspns-tm-rpar-overview.component.css']
})
export class RspnsTmRparOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
