import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-approvals',
  templateUrl: './cnfg-approvals.component.html',
  styleUrls: ['./cnfg-approvals.component.css']
})

export class CnfgApprovalsComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
  }

}
