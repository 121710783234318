<div class="card p-0">
    <div class="card-header p-2">
        <h6 class="mb-0">System-wide notifications</h6>
    </div>
    <div class="card-body p-2">
        <div class="small">
            Select the language in which system-wide notifications are to be sent to users in this domain.
        </div>
        <div class="row mt-3">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <form>
                    <div class="form-group col-xl-3">
                        <label for="textBox">Language preference</label>
                        <select id="inputState" class="form-select form-select-sm" (change)="onLngChng($event)">
                            <option *ngFor="let it of lngs" [value]="it.lang" [selected]="it.slctd">{{it.lang}}</option>
                        </select>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgntfnPrmsn" (click)="onSave()">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>