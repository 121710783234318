import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-assets-view',
  templateUrl: './assets-view.component.html',
  styleUrls: ['./assets-view.component.css']
})

export class AssetsViewComponent extends BaseComponent implements OnInit {
  
  assetsLst: any[] = []; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; astTypLst: any[] = [];
  noRcrdsTxt = environment.TblNoRcrds; setupAssetsViewForm = this.formBuilder.group({ serialNumber: [], asetType: [''], manufacturer:[''], assetModelId:[''] }); usrPrmsn: any;
  asetType: any[] = []; dropdownSettings = {}; paramTab: any; editableAssetInfo = JSON.parse(<string>(localStorage.getItem('editAssetInfo')));
  slctdAstLst: any[] = []; astIds: any[] = []; isDelSa: any; manufacturersLst: any[] = []; modelsLst: any[] = []; enbleField = false;
   
  //dmn permsns
  prmsns: any = null; cratBulkUpld: boolean = true; cceList: boolean = true; cceEdit : boolean = true; cceDlt: boolean = true;
   ccedtlAddRlnshp: boolean = true; cceAsstdDtlDltRlntsp: boolean = true; cceAsstDtlUpdate: boolean = true; cceMngmnDtl: boolean = true;
   ccePrmsns: any= null; 



  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.astTypLst = s.astTypLst });
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });    
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.ccePrmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.CCE_Management : null;
    this.ccePrmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_cm_va_ad')
        this.cceMngmnDtl = e?.action;
    });
    this.prmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_st_cm_li')
        this.cceList = e?.action;
      if (e?.menuCode == 'mm_st_cm_ed')
        this.cceEdit = e?.action;
      if (e?.menuCode == 'mm_st_cm_dt')
        this.cceDlt = e?.action;
      if (e?.menuCode == 'mm_st_cm_ad_ar')
        this.ccedtlAddRlnshp = e?.action;
      if (e?.menuCode == 'mm_st_cm_ad_dr')
        this.cceAsstdDtlDltRlntsp = e?.action;
      if (e?.menuCode == 'mm_st_cm_ad_up')
        this.cceAsstDtlUpdate = e?.action;
    });
    this.dropdowns(); this.isDelSa = localStorage.getItem('isClkdSA');
    setTimeout(() => { if (this.cceList) this.LoadAssets(); }, 1000);
    
  }

  isSelected($event: any, item: any) {
    if (this.slctdAstLst.filter(f => f.id == item.assetId).length == 0)
      this.slctdAstLst.push({ id: item.assetId, email: item.email });
    else
      this.slctdAstLst = this.slctdAstLst.filter(f => f.id != item.assetId);
    if ($event.target.checked) {
      item.flag = true, this.astIds.push(item.assetId);
    }
    else
      item.flag = false, this.astIds.filter((a: any, index: any) => { if (a == item.assetId) this.astIds.splice(index, 1); })
  }

  onSlctAll($event: any) {
    this.slctdAstLst = [], this.astIds = [];
    if ($event.target.checked)
      this.assetsLst.filter(a => { a.flag = true, this.slctdAstLst.push({ id: a.assetId, email: a.email }), this.astIds.push(a.assetId); })
    else
      this.assetsLst.filter(a => { a.flag = false });
  }

  LoadManufacturers(slctdAsetId: any) {
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getByasetTypId.
      replace('asetId', slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manufacturersLst = this.result.data != null ? this.result.data : [];
          if (this.manufacturersLst.length == 1){
            this.setupAssetsViewForm.get('manufacturer')?.setValue(this.manufacturersLst[0].id);
               let slctdVendId = this.manufacturersLst[0].id; this.LoadModels(slctdVendId, slctdAsetId);
          }
          else {
            // if (!this.editFlag)
              this.setupAssetsViewForm.get('manufacturer')?.setValue(''), this.setupAssetsViewForm.get('assetModelId')?.setValue('');
          }
        }
      })
  }

  get fc() { return this.setupAssetsViewForm.controls; }

  LoadModels(slctdVendId: any, slctdAsetId: any) {
    this.modelsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getByVendorId.
      replace('vendId', slctdVendId).replace('rrrr', slctdAsetId), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data)
            this.modelsLst = this.result.data != null ? this.result.data : [];
          if (this.modelsLst.length == 1)
            this.setupAssetsViewForm.get('assetModelId')?.setValue(this.modelsLst[0].id);
        }
      })
  }

  onChngSNO() {
    let snVal = this.setupAssetsViewForm.get('serialNumber')?.value;
    this.setupAssetsViewForm.get('serialNumber')?.setValue(`${snVal}`.trim());
  }

  LoadAssets() {
    let payLoad = this.setupAssetsViewForm.getRawValue(), manuftr: any[]=[], astTpe: any[] = [];
    manuftr.push(+payLoad.manufacturer); astTpe.push(+payLoad.assetModelId);
    payLoad.assetTypes = this.asetType.length != 0 ? this.asetType : null, payLoad.manufacturerId = payLoad.manufacturer != '' ? manuftr : null,
    payLoad.assetModelId = payLoad.assetModelId != '' ? astTpe: null;
    delete payLoad.manufacturer;
      // let payLoad = { serialNumber: this.setupAssetsViewForm.get('serialNumber')?.value, assetTypes: this.asetType.length != 0 ? this.asetType : null };
    // if (payLoad.manufacturer)
    // payLoad.manufacturer = this.manufacturersLst.find(a => a.id == payLoad.manufacturer)?.name ?? null;
    // if (payLoad.assetModelId)
    // payLoad.assetModelId = this.modelsLst.find(e => e.id == payLoad.assetModelId)?.modelName ?? null;

    this.assetsLst = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.getAsetTypeList.
      replace('sss', `${this.isDelSa == '1'}`).replace('pageNo', `${this.crntPage - 1}`).
      replace('pageSize', `${this.pageSize}`), payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.assetsLst = (this.result.data?.assetsList != null || this.result.data != null) ? this.result.data?.assetsList : [], this.totalItems =
              this.result.data?.totalCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        }
      });
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
      unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
  }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'asetType') {
      if (evntType == 'os')
        this.asetType.push(item.id)
      else if (evntType == 'osa')
        this.asetType = [], this.astTypLst.forEach((e: any) => { this.asetType.push(e.id); });
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id)
      else
        this.asetType = [];

        if(this.asetType.length == 1){
          this.enbleField = true; //this.macEnabled =false;
          // if (type == 'manufacturer') {
            this.setupAssetsViewForm.get('assetModelId')?.setValue('')
            let slctdVendId = item.id;
            if (slctdVendId != '')
              this.LoadManufacturers( this.asetType[0]);
            else
              this.setupAssetsViewForm.get('assetModelId')?.setValue('')
          // }
        }
        else{
          this.enbleField = false;
          this.setupAssetsViewForm.get('manufacturer')?.setValue(''), this.setupAssetsViewForm.get('assetModelId')?.setValue('');
        }
    }
    if (type == 'manufacturer') {
     // this.macEnabled =false;
      this.setupAssetsViewForm.get('assetModelId')?.setValue('');
      let slctdVendId = item?.target?.value;
      if (slctdVendId != '')
        this.LoadModels(slctdVendId, this.asetType[0]);
      else
        this.setupAssetsViewForm.get('assetModelId')?.setValue('')
    }
    if (type == 'astModel') {
      let astmdl = item?.target?.value;
    
    }
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadAssets();
  }

  onClick(assetId: any) {
    if (this.isDelSa !='1' && this.cceMngmnDtl && assetId > 0) {
      // if(this.cceMngmnDtl){
      //   if (assetId > 0)
          this.glblSvc.sbjctAssetId.next(assetId), this.router.navigate(['home/asst-ast-dtls']);
    //     }
     }
  }
  onReset() {
    this.enbleField = false, this.setupAssetsViewForm.reset(), this.setupAssetsViewForm.get('asetType')?.setValue(''), this.asetType = [], this.LoadAssets();
  }

  //Delete Asset
  deleteAsset(assetInfo: any) {
    if (confirm('Are you sure, you want to delete ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.asset, environment.apiMthds.deleteAsset.replace('ddd', assetInfo.assetId), null, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status === 'OK') {
            if (this.result.message === 'Records deleted') {
              this.glblSvc.onTstr('s', this.result.message);
              this.LoadAssets();
            } else {
              this.glblSvc.onTstr('w', this.result.message);
            }
          } else
            this.glblSvc.onTstr('w', this.result.message);
        }
      })
    }
  }

  onRetrvData() {
    let payLoad: any= {};
      if (confirm("Are you sure you want to Retrieve the Asset.?")) {
        payLoad.assetIds = this.astIds, payLoad.userId = this.usrLgnDtls?.userId;
        this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.retrveAsts.replace('sss', 'true'), 
        payLoad, 2, true, 3).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.message === "Assets retreived successfully")
                this.glblSvc.onTstr('s', this.result.message), this.LoadAssets();
              else 
                this.glblSvc.onTstr('w', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      }
  }

  //Edit Asset
  editAsset(assetInfo: any) {  
    if (assetInfo)
      this.glblSvc.editAssetInfo.next(assetInfo), this.router.navigate(['home/setup-assets'], { queryParams: { tab: 'new' } });
}

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

  onEE() {
    let payLoad = this.setupAssetsViewForm.getRawValue(),
     manuftr : any = +payLoad.manufacturer, astTpe: any = +payLoad.assetModelId,
    assetTypes: any = this.asetType.length != 0 ? this.asetType : ''; let manufacturerId: any = payLoad.manufacturer != '' ? manuftr : '',
    assetModelId: any = payLoad.assetModelId != '' ? astTpe: '';
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.exprtAsst.
      replace('eee',this.usrLgnDtls.email).replace('mmm', manufacturerId).replace('aaa', assetModelId).replace('ttt', assetTypes), null, 6, true, 2).then(() => {
    if (this.result)
      this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
  });
}

}