<div class="card">
    <div class="row p-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12 p-2 pt-0">
            <div class="float-start">
                <h5 class="mb-0">Fulfil the shipment: {{orderStatus?.consgineeNo}}</h5>
            </div>
            <div class="clearfix"></div>
            <hr class="mt-1 mb-1" />
            <div class="small">Please review and confirm the received quantities before fulfilling the Indent. If the
                received quantities are different from the shipped quantities indicated in the Indent, edit the
                corresponding received quantities before fulfilling the Indent.</div>
            <div class="row mt-3">
                <div class="col-xl-6 mb-3">
                    <div class="card shadow-sm h-100">
                        <div class="card-header p-2">Manufacturer Details</div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <a>{{orderStatus?.producerName}}</a>
                                    <div>{{orderStatus?.producerLocation}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mb-3">
                    <div class="card mb-4 shadow-sm h-100">
                        <div class="card-header p-2">Consignee Facility</div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-sm-12">
                                    <a>{{orderStatus?.storeName}}</a>
                                    <div>{{orderStatus?.storeLocation}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card border mt-3">
                <div class="card-header p-2">
                    <h6 class="modal-title">Item</h6>
                </div>
                <div class="card-body p-2">
                    <div class="table-responsive table_spacing">
                        <table class="table table-striped table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center">Item no.</th>
                                    <th scope="col">Material</th>
                                    <th scope="col" class="text-center">Ordered</th>
                                    <th scope="col" class="text-center">Shipped</th>
                                    <th scope="col" class="text-center">Fulfilled</th>
                                    <th class="text-center">Received</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="orderStatus?.batchEnabled == false">
                                <tr>
                                    <td>1</td>
                                    <td><a>{{orderStatus?.productName}}</a></td>
                                    <td class="text-center">{{orderStatus?.orderedQuantity}}</td>
                                    <td class="text-center">{{orderStatus?.shippedQuantity}}</td>
                                    <td class="text-center">{{orderStatus?.shippedQuantity}}</td>
                                    <td class="text-center">
                                        <input type="text"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                            [(ngModel)]="flseBtchQntuty" [ngClass]="{ 'is-invalid': ( flseBtchQntuty ==0 && flseBtchQntuty == ''  )||
                                            (flseBtchQntuty *1 )==0}" class="form-control form-control-sm w-100"
                                            [value]="orderStatus?.shippedQuantity" style="border: 1px solid #ddd">
                                        <div *ngIf="(flseBtchQntuty *1 )==0 " class="text-danger">
                                            Quantity should be atleast 1
                                        </div>
                                        <div *ngIf="flseBtchQntuty != orderStatus?.shippedQuantity">
                                            <select class="form-select form-select-sm mt-2 w-40"
                                                (change)="isNoBtchRsnChng($event)"
                                                [ngClass]="{ 'is-invalid': (flseBtchQntuty != orderStatus?.shippedQuantity ) && isNBtchRsn == '' }">
                                                <option value="">Select Reason</option>
                                                <option [value]="rs.reasonId" *ngFor="let rs of rsnsLst">
                                                    {{rs.reasonName}}
                                                </option>
                                            </select>
                                            <div *ngIf="(flseBtchQntuty !=orderStatus?.shippedQuantity) && isNBtchRsn == ''"
                                                class="text-danger w-40">
                                                <div>Please choose Reason</div>
                                            </div>
                                            <textarea class="form-control form-control-name taRS mt-2 w-40"
                                                *ngIf="isNBtchRsn == '16'" maxlength="15" placeholder="Enter Reason"
                                                [(ngModel)]="isNbtchOthrRsn"></textarea>
                                            <div *ngIf="isNBtchRsn == '16' && isNbtchOthrRsn?.length == 0"
                                                class="text-danger mt-2 w-40">
                                                <div>Please Enter reason</div>
                                            </div>                                           
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="orderStatus?.batchEnabled == true">
                                <tr *ngFor="let x of btchLst; let i = index">
                                    <td>{{i+1}}</td>
                                    <td><a>{{orderStatus?.productName}}</a></td>
                                    <td class="text-center">{{x?.batchQty}}</td>
                                    <td class="text-center">{{x?.batchQty}}</td>
                                    <td class="text-center">{{x?.batchQty}}</td>
                                    <td>
                                        <input type="text"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                            (keyup)="onChngRcvd($event, i)" [ngClass]="{ 'is-invalid': ( x?.RCquantity ==0 && x?.RCquantity == ''  )||
                                        (x?.RCquantity *1 )==0}" class="form-control form-control-sm w-100"
                                            [value]="x?.RCquantity" style="border: 1px solid #ddd">
                                        <div *ngIf="(x?.RCquantity *1 )==0 " class="text-danger">
                                            Quantity should be atleast 1
                                        </div>

                                        <div *ngIf="x?.RCquantity != x?.batchQty">
                                            <select class="form-select form-select-sm mt-2 w-40"
                                                (change)="onChngRsn($event, i)"
                                                [ngClass]="{ 'is-invalid': (x?.RCquantity != x?.batchQty ) && x?.reasonId == null }">
                                                <option value="">Select Reason</option>
                                                <option [value]="rs.reasonId" *ngFor="let rs of rsnsLst">
                                                    {{rs.reasonName}}
                                                </option>
                                            </select>
                                            <div *ngIf="(x?.RCquantity !=x?.batchQty) && x?.reasonId == null"
                                                class="text-danger w-40">
                                                <div>Please choose Reason</div>
                                            </div>
                                            <textarea class="form-control form-control-name taRS mt-2 w-40"
                                                *ngIf="x?.reasonId == '16'" maxlength="15" placeholder="Enter Reason"
                                                [value]="x?.reasonName" (keyup)="onChngRsnBath($event, i)"></textarea>
                                            <div *ngIf="x?.reasonId == '16' && x?.reasonName.length == 0"
                                                class="text-danger mt-2 w-40">
                                                <div>Please Enter reason</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <form [formGroup]="fulfillFrm">
                <div class="card border mt-3">
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-row row mb-2">
                                    <div class="col-xl-3 mb-2">
                                        <label for="inputEmail4" class="col-form-label">Date of actual receipt
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="input-group mb-3">
                                            <input type="text" autocomplete="off" onpaste="return false"
                                                class="form-control form-control-sm optinbg bsDP-bg" readonly
                                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', 
                                                showWeekNumbers: false, isAnimated: true, adaptivePosition: true }"
                                                #dpDAR="bsDatepicker" formControlName="arDate" bsDatepicker
                                                onkeypress='return event.charCode == 8' [maxDate]="dt2Day">
                                            <div class="input-group-append">
                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                    (click)="dpDAR.toggle()" [attr.aria-expanded]="dpDAR.isOpen"><i
                                                        class="fa fa-calendar"></i>
                                                </button>
                                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                    title="Clear Date" (click)="dpDAR.hide();clearBSDate('arDate')"><i
                                                        class="fa fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 mb-3 mt-4">
                                        <label>Capture Lat & Long</label>&nbsp;&nbsp;
                                        <input class="form-check-input" type="radio" name="isReqLL" value="1"
                                            (click)="isReqLL='1'" [checked]="isReqLL=='1'">&nbsp;&nbsp;<label
                                            class="form-check-label">Yes</label>&nbsp;&nbsp;
                                        <input class="form-check-input" type="radio" name="isReqLL" value="0"
                                            (click)="isReqLL='0'" [checked]="isReqLL=='0'">&nbsp;&nbsp;<label
                                            class="form-check-label">No</label><br />
                                    </div>
                                    <div class="col-xl-2 mb-2">
                                        <label for="inputEmail3" class="col-form-label">Comment</label>
                                        <textarea class="form-control form-control-sm taRS"
                                            formControlName="comment"></textarea>
                                    </div>
                                    <div class="col-xl-3 mt-5 float-end" *ngIf="orderStatus?.batchEnabled == true">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            (click)="openModal('v')">Download VAR</button>
                                    </div>
                                    <div class="col-xl-3 mt-5 float-end" *ngIf="orderStatus?.batchEnabled == false">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            (click)="openModal('p')">Download PAR</button>
                                    </div>
                                    <div class="col-xl-3">
                                        <div class="form-group">
                                            <label>{{orderStatus?.batchEnabled == true ? 'Vaccine' : 'Product'}} arrival report</label>
                                            <input type="file" class="form-control form-control-sm" formControlName="fileName"
                                                accept="pdf" (change)="onFileSelect($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="float-end">
                    <button type="button" class="btn btn-sm btn-primary me-2"
                        (click)="isFulfill=true;onChkLL()">Fulfill</button>
                    <button type="button" class="btn btn-sm btn-light"
                        (click)="isFulfill=false;cancel()">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>