import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-stock-trends-facilitys',
  templateUrl: './stock-trends-facilitys.component.html',
  styleUrls: ['./stock-trends-facilitys.component.css']
})
export class StockTrendsFacilitysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
