import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'evin-transfers-list',
  templateUrl: './transfers-list.component.html',
  styleUrls: ['./transfers-list.component.css']
})
export class TransfersListComponent extends BaseComponent implements OnInit {

  crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; pageEntrs = ''; slctdFcltyId: any; ordersList: any[] = []; count: any;
  isFacAll = false; isOrdAll = false; lstShowFacTags: any[] = []; dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; slctdFac: any[] = []; lstSlctdFacTags: any[] = [];
  lstShowOrdTags:any [] = []; lstSlctdOrdTags: any[] = []; slctdOrd: any[] = []; noRcrdsTxt = environment.TblNoRcrds; lstStrs: any[] = [];
  lstFacTags: any[] = []; orderlstfrm = this.fb.group({ expireBefore: [''], approvalStatus: [null], fromDate: [''], issueReferenceId: [''],
    issuingStoreName: [''], receiptReferenceId: [''], status: [''], toDate: [''], recevingStoreName: [''], bookingId: [''], searchText: [],
    storeBadgeId: [null], storeId: [''], bookingBadgeId: [null], indentTypeId: [4], isStkMatBdge: [''], stkMtrlBdge: [''], stkMtrlNme: ['']}); ttlItems: any;  frmDate: any; 
   searchText: any; orderStatusList: any[] = []; lstTags: any[] = []; filterMetadata = { count: 0}; dt2day = new Date(); isAPICald = true;
   isStkMatBdge = 'badge'; slctdStkMat: any[] = []; lstSlctdMatTags: any[] = []; userlgndtls : any = null; mmIdDtls: boolean = true;
   lstSlctdMatNames: any[] = []; lstAllMats: any[] = []; lstMatTags1 : any[] = [];

  constructor(public glblSvc: GlobalService,
    private router: Router,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstStrs = s, this.usrStrLen = s.length; if (this.usrStrLen == 1)
      this.onChngFclty(this.lstStrs[0].storeName, 'str1'), this.slctdFcltyId = this.lstStrs[0].storeId; }),
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags1 = s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.lstFacTags = s?.filter(f => f.badgeTypeId == environment.tagTypes.facility);
        this.lstTags = s?.filter(f => f.badgeTypeId == environment.tagTypes.order) }),
        this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.orderStatusList = s.stsLst.filter((f: any) => f.statusType === 'Order') }),
    this.glblSvc.sbjctTabsPage.subscribe(s => { this.ordersList = []; if (s == 'trsf-list') this.LoadOrders(); });
  }

  ngOnInit(): void {
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_ids_dt') this.mmIdDtls = e.action;}) : null;
    }
    this.LoadOrders();
  }

  get fc() { return this.orderlstfrm.controls; }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any){
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  search() {
    this.count = this.searchText == '' ? this.ttlItems : this.filterMetadata.count, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
  }

  onClkRdb(ctrl: string) {
    this.fc?.stkMtrlBdge.setValue([]), this.fc?.stkMtrlNme.setValue([]),
     this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdMatNames.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstAllMats;
      else
        this.lstSlctdMatTags = this.lstMatTags1;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdMatNames = [];
      else
        this.lstSlctdMatTags = [];
    }
  }
  
  clearBSDate(ctrl: string) {
    this.orderlstfrm.get(ctrl)?.setValue('');
  }

  LoadOrders() {
    if (localStorage.getItem('isTrnsfrAPICald') == null) { // if (this.isAPICald) {
      let crntPgIndx = this.crntPage - 1, payLoad = this.orderlstfrm.getRawValue(),rawMtrlStkNames: any[] = payLoad.stkMtrlNme != '' ?
      payLoad.stkMtrlNme : [], rawMtrlBdge: any[] = payLoad.stkMtrlBdge != '' ? payLoad.stkMtrlBdge : [], stkMtrlBdgeID: any[] = [], stkMtrlIDs: any[] = [];
      rawMtrlBdge.forEach((e) => {stkMtrlBdgeID.push(e.id);}); rawMtrlStkNames.forEach((e) => { stkMtrlIDs.push(e.id)});
      payLoad.storeId = this.slctdFcltyId != '' ? +this.slctdFcltyId : null,  localStorage.setItem('isTrnsfrAPICald', '1'), // payLoad.status = payLoad.status != '' ? +payLoad.status : null, 
        payLoad.storeBadgeId = (payLoad.storeBadgeId != '' && payLoad.storeBadgeId != null) ? +payLoad.storeBadgeId : null,
        payLoad.bookingBadgeId = (payLoad.bookingBadgeId != '' && payLoad.bookingBadgeId != null) ? +payLoad.bookingBadgeId : null,
        payLoad.bookingId = (payLoad.bookingId != '' &&  payLoad.bookingId != null) ? +payLoad.bookingId : null,
        payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.pranthId = this.chsnDmnId, payLoad.productBadgeIds = stkMtrlBdgeID; payLoad.productIds = stkMtrlIDs; 
        ['stkMtrlBdge', 'stkMtrlNme', 'isStkMatBdge', 'searchText'].forEach(e=>{delete payLoad[e]});
        this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.getAllBookings.
          replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad).then(() => {
        if (this.result) {
          if (this.result.data)
            this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.ttlItems = this.totalItems,
              this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.ordersList = this.result.data?.bookingFilterDTOs;
            else if (this.result.data == null)
              this.glblSvc.onTstr('i', this.result.message);
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
        setTimeout(() => { localStorage.removeItem('isTrnsfrAPICald'); }, 500);
      });
    } else{
      this.LoadOrders(); 
    }
  }

  onChngFclty(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if  (evnt == '' || evnt == undefined)
      this.slctdFcltyId = '', this.orderlstfrm.get('storeId')?.setValue(''), this.orderlstfrm.get('recevingStoreName')?.setValue('');
    else {
      if (this.lstStrs?.filter(a => a.storeName == evnt).length != 0) {
        for (let s = 0; s < this.lstStrs.length; s++) {
          if (this.lstStrs[s].storeName == evnt) {
            this.slctdFcltyId = this.lstStrs[s].storeId, this.orderlstfrm.get('recevingStoreName')?.setValue(evnt);
            if (evntType === 'str1')
              this.orderlstfrm.get('storeId')?.setValue(evnt);
          }
        }
      }
      else
        this.slctdFcltyId = '', this.orderlstfrm.get('storeId')?.setValue(''), this.orderlstfrm.get('recevingStoreName')?.setValue('');
    }
  }

  onOrdIdClk(orderId: number) {
    if (orderId > 0 && this.mmIdDtls)
      this.glblSvc.sbjctOrdId.next(orderId), localStorage.setItem('ordrId', orderId.toString()), this.router.navigate(['home/ord-transfers'], { queryParams: { tab: 'sts', type:'t' } });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadOrders();
  }

  onReset() {
    this.slctdFcltyId = '', this.orderlstfrm.reset(), this.orderlstfrm.get('status')?.setValue(''), this.orderlstfrm.get('indentTypeId')?.setValue(4);
    this.slctdStkMat = []; this.lstSlctdMatTags = [];
    this.orderlstfrm.get('stkMtrlBdge')?.setValue([]);  this.orderlstfrm.get('stkMtrlNme')?.setValue([]); this.orderlstfrm.get('isStkMatBdge')?.setValue('badge');
    if (this.lstStrs.length == 1)
      this.slctdFcltyId = this.lstStrs[0].storeId, this.orderlstfrm.get('recevingStoreName')?.setValue(this.lstStrs[0].storeName),
        this.orderlstfrm.get('storeId')?.setValue(this.lstStrs[0].storeName);
    this.LoadOrders(), this.lstShowFacTags = [], this.lstShowOrdTags = [], this.slctdOrd = [...[]], this.slctdFac = [...[]];
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFacTags.length === this.lstSlctdFacTags.length;
    else
      this.lstShowOrdTags = [...this.lstSlctdOrdTags], this.isOrdAll = this.lstTags.length === this.lstSlctdOrdTags.length;
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdOrd = [...this.lstShowOrdTags];
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowOrdTags = [...[]], this.lstSlctdOrdTags = [...[]], this.slctdOrd = [...[]], this.isOrdAll = false;
  }

  onEE() {
    let payLoad = this.orderlstfrm.getRawValue(), rawMtrlStkNames: any[] = payLoad.stkMtrlNme != '' ?
    payLoad.stkMtrlNme : [], rawMtrlBdge: any[] = payLoad.stkMtrlBdge != '' ? payLoad.stkMtrlBdge : [], stkMtrlBdgeID: any[] = [], stkMtrlIDs: any[] = [];
    rawMtrlBdge.forEach((e) => {stkMtrlBdgeID.push(e.id);}); rawMtrlStkNames.forEach((e) => { stkMtrlIDs.push(e.id)});
    payLoad.storeId = this.slctdFcltyId != null && this.slctdFcltyId != undefined && this.slctdFcltyId != '' ? +this.slctdFcltyId : null,
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.isBatchEnabled=false,
      payLoad.pranthId = this.chsnDmnId, payLoad.productBadgeIds = stkMtrlBdgeID; payLoad.productIds = stkMtrlIDs; 
      ['stkMtrlBdge', 'stkMtrlNme', 'isStkMatBdge'].forEach(e=>{delete payLoad[e]});
       this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.
        exprtIndnts.replace('iii', this.usrLgnDtls.userId). replace('nnn', this.usrLgnDtls.userName).replace('eml', this.usrLgnDtls.email).
        replace('rrr', `${this.chsnDmnId}`), payLoad, 11).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}