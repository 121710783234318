import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-transporters-details',
  templateUrl: './transporters-details.component.html',
  styleUrls: ['./transporters-details.component.css']
})

export class TransportersDetailsComponent  extends BaseComponent implements OnInit {

  trnspterId: any; trnspterDetails: any = {};

  constructor(public glblSvc: GlobalService, private router: Router) { 
    super(glblSvc);
  }
  ngOnInit(): void {
    this.glblSvc.sbjctTrnsId.subscribe(s => { this.trnspterId = s;
      if (this.trnspterId > 0)
        this.getTrnspterById(this.trnspterId)
      else
        this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } })
    })
  }

  getTrnspterById(trnspterId: any) {
    let x: any;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.carrier, environment.apiMthds.trnspterById + trnspterId, null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.trnspterDetails = this.result.data != null ? this.result.data : [];
        }
      }
    });
  }

}
