import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-event-summary-supply',
  templateUrl: './cnfg-event-summary-supply.component.html',
  styleUrls: ['./cnfg-event-summary-supply.component.css']
})

export class CnfgEventSummarySupplyComponent extends BaseComponent implements OnInit {

  isShow=true; usrPrmsn: any = null; cnfgEvntSmPrmsn = true;
  
  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.select2ShowHide();

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_es_ac') ? (this.cnfgEvntSmPrmsn = e.action) : '') });
    }
  }

}