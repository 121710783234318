import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

@Component({
  selector: 'evin-stock-report-material',
  templateUrl: './stock-report-material.component.html',
  styleUrls: ['./stock-report-material.component.css']
})

export class StockReportMaterialComponent extends BaseComponent implements OnInit {
  lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; isMatAll = false; slctdMat: any[] = [];
  lstMats: any[] = []; lstAllMats: any[] = []; kioskId: any; noRcrdsTxt = environment.TblNoRcrds; lstPrnts: any[] = []; lstMatTags: any[] = [];
  facName = ''; invStkRprtMatFrm = this.formBuilder.group({
    storeIdT: [''], productIdT: [''], facility: [''], material: [''], dvntType: [''],
    expireBefore: [''], batchEnb: [''], state: [''], district: [''], block: [''], stockOut: [''], productBadge:[''] //includeCat: [''], excludeCat: ['']
  });
  pageSize = environment.tblPageSize; totalItems: any; slctdStrId: any; slctdPrdctId: any;
  crntPage = 1; pageEntrs = ''; selectedItems = []; dropdownSettings = {};

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private ngbMdl: NgbModal
    , private router: Router) {
    super(glblSvc); this.glblSvc.sbjctStrList.subscribe(s => { this.lstPrnts = s; }),
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.glblSvc.sbjctKskId.subscribe(sId => {
      this.kioskId = sId, this.invStkRprtMatFrm.get('storeIdT')?.setValue(sId);
      if (this.kioskId)
        this.LoadMatsbyStoreId(this.kioskId)
      else
        this.router.navigate(['home/stk-rprt']);
    }), this.glblSvc.sbjctKskName.subscribe(sNm => this.facName = sNm), this.dropdowns();
  }

  onMultiSlct(type: string,item: any) {
    if (type == 's') {
      if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
    }
    else if (type == 'r') {
      this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      this.lstSlctdMatTags = [];
    }
  }

  dropdowns() {
    this.dropdownSettings = {
      singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  changeFn(evnt: any) {
    for (let s = 0; s < this.lstPrnts.length; s++) {
      if (this.lstPrnts[s].storeName == evnt.value)
        this.slctdStrId = this.lstPrnts[s].storeId;
    }
    this.LoadMatsbyStoreId(this.slctdStrId);
  }

  onChngMtrl(evnt: any) {
    for (let s = 0; s < this.lstAllMats.length; s++) {
      if (this.lstAllMats[s].productName == evnt.value)
        this.slctdPrdctId = this.lstAllMats[s].productId;
    }
  }

  clearBSDate(ctrl: string) {
    this.invStkRprtMatFrm.get(ctrl)?.setValue('');
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadMatsbyStoreId(this.kioskId);
  }

  LoadMatsbyStoreId(sId: number) {
    let crntPgIndx = this.crntPage - 1;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMats.replace('sss', `${sId}`).
        replace('rrr', '').replace('pageNo', crntPgIndx.toString()).replace('pageSize', this.pageSize.toString()), 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems),
              this.result.data?.productsDTOs.forEach((e: any) => { Object.assign(e, { isB: false, isM: false, isH: false, isAS: false, isIT: false, lstB: [], lstM: [], lstH: [] }); }),
              this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onFilter() {
    let frmVals = this.invStkRprtMatFrm.getRawValue(), beVal = (frmVals.batchEnb == 'wb' ? true : (frmVals.batchEnb == 'nb' ? false : null)),
      data: any[] = [];
    if (beVal != null || frmVals.productIdT != '' || frmVals.dvntType != '') {
      if (beVal != null)
        data = this.lstAllMats.filter(f => f.batchManagement == beVal);
      if (frmVals.productIdT != '')
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.productId == +this.slctdPrdctId);
      if (frmVals.dvntType != '')
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.abnormalityType == +frmVals.dvntType);
      this.lstMats = data;
    }
    else
      this.lstMats = this.lstAllMats;
  }

  GetNestedData(type: string, indx: number, id: any = '') {
    this.FetchData(environment.apiCalls.get, (type == 'b' ? environment.apiPaths.store : environment.apiPaths.product),
        (type == 'b' ? environment.apiMthds.getBatchbyMatId.replace('mmm', id).replace('kkk', `${this.kioskId}`) :
        (type == 'h' ? environment.apiMthds.getMatHstry.replace('kkk', `${this.kioskId}`).replace('mmm', id) :
        environment.apiMthds.getMatsMinMax.replace('kkk', `${this.kioskId}`).replace('mmm', id))), null, (type == 'b' ? 0 : 2), true,
        (type == 'b' ? 0 : 1)).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstMats[indx][((type == 'b') ? 'lstB' : ((type == 'm') ? 'lstM' : 'lstH'))] = this.result.data != null ? this.result.data : [];
      }
    });
  }

  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => {
      // if (result == 'done')
      //   return; // your code
    }, (r) => { console.log(r) });
  }

  onCncl() {
      this.slctdMat = [...this.lstShowMatTags];
  }

  onTagsSave() {
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMatTags.length === this.lstSlctdMatTags.length;
  }

  onResetTags() {
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }
}