import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
@Component({
  selector: 'evin-prchs-indent',
  templateUrl: './prchs-indent.component.html',
  styleUrls: ['./prchs-indent.component.css']
})
export class PrchsIndentComponent extends BaseComponent implements OnInit {

  paramTab: any; isShowCOD = true; userIndent : any; userlgndtls : any = null; mmPrchLst: boolean = true; mmCrtPrch: boolean = true;
  cnfgVal: any = null; isPrcsLstHdn = false; isPrcsCrtHdn = false; isCnsgnStr = this.usrLgnDtls?.userLevelDetails[0]?.userLevel;
  

  constructor(private route: ActivatedRoute,
    private router: Router,
    public glblSvc: GlobalService) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
  }

  ngOnInit(): void {      
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_pi_li') this.mmPrchLst = e?.action;
      if (e.menuCode == 'mm_id_pi_cpi') this.mmCrtPrch = e?.action;}) : null;
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null;
      // this.isPrcsLstHdn = this.cnfgVal?.permissionRoles[11]?.configuredValue?.value ?? false,
      // this.isPrcsCrtHdn = this.cnfgVal?.permissionRoles[9]?.configuredValue?.value ?? false;
    }
    if (!this.mmPrchLst && this.mmCrtPrch)
      this.onTabSelection('new'), setTimeout(() => { document.getElementById('creat-order-tab')?.click(); }, 1000);
  }

  onTabSelection(tabName: string) {
    this.updateQueryParams({ tab: tabName });
    // if(tabName == 'list')
    //   window.location.reload();
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}
