<div class="card p-0 border-0">
    <div class="card-body p-2">
        <tabset #mngeTbs>
            <tab heading="Domains">
                <evin-domain></evin-domain>
            </tab>
            <tab heading="Data" *ngIf="false">
                <evin-manage-data></evin-manage-data>
            </tab>
            <tab heading="System Configuration">
                <evin-system-config></evin-system-config>
            </tab>
        </tabset>
    </div>
</div>