<form>
    <div class="card p-0 border-0">
        <div class="card-header p-2">
            <div class="float-start">
                <h6 class="mb-0">Custom reports configuration</h6>
            </div>
            <div class="float-end">
                <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgCstRprtPrmsn">Add template</button>
            </div>
        </div>
        <div class="card-body p-2 pb-0">
            <div class="row">
                <div class="col-xl-12">
                    <div>There are no uploaded Custom report templates for this domain. Please click on the Add Template
                        button to upload a template.</div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card p-0 mt-3">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Report template details</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="form-row row">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">File name <span class="text-danger">*</span></label>
                                            <input type="file" placeholder="Choose File" class="form-control"
                                                id="Facility">
                                            <small>Upload <strong>.xls .xlsx</strong> or <strong>.xlsm</strong> files
                                                only</small>
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Report template name <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" placeholder="Template name" class="form-control"
                                                id="Material">
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <label for="textBox">Description</label>
                                            <input type="text" placeholder="Description" class="form-control"
                                                id="Description">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Sheet data</h6>
                        </div>
                        <div class="card-body p-1">
                            <div class="p-2">
                                <small>Enter the sheet data, such as name of the sheet in the report template to which
                                    the
                                    data should be pasted. Data should be entered for at least one of the
                                    sheets.</small>
                                <div>
                                    <small class="text-danger">NOTE: Maximum of 60,000 rows can be exported per sheet in
                                        excel file. Please choose the data duration accordingly.</small>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Stock Management</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Stock Management sheet name" class="form-control"
                                                id="Sheet name">
                                        </div>
                                        <div class="form-group col-xl-12 mt-3">
                                            <label for="textBox" class="f-w-500 mb-2 me-2">Batch</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckChecked">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Include batch details
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2 rounded-0">
                                    <h6 class="mb-0">Users</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Users sheet name" class="form-control"
                                                id="Sheet name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Facilities</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Facilities sheet name" class="form-control"
                                                id="Sheet name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Materials</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Materials sheet name" class="form-control"
                                                id="Sheet name">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Indents</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Indents sheet name" class="form-control"
                                                id="Sheet name">
                                        </div>
                                        <div class="form-group col-xl-12 mt-3">
                                            <div class="f-w-500 mb-2 me-2">Data duration</div><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckChecked">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Same as report generation frequency
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-xl-2"><input type="text" placeholder="0 days"
                                                class="form-control  " id="Sheet name" disabled></div>
                                        <small>(Upto 180 days of data can be exported.)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Transactions</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-4">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Transactions sheet name"
                                                class="form-control" id="Sheet name">
                                        </div>
                                        <div class="form-group col-xl-12 mt-3">
                                            <div class="f-w-500 mb-2 me-2">Data duration</div><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckChecked">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Same as report generation frequency
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-xl-2"><input type="text" placeholder="0 days"
                                                class="form-control  " id="Sheet name" disabled></div>
                                        <small>(Upto 180 days of data can be exported.)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Transactions counts</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Transactions counts sheet name"
                                                class="form-control" id="Sheet name">
                                        </div>
                                        <div class="form-check col-xl-3 ">
                                            <label for="textBox"> Aggregate by</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value=""> Select</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="form-check col-xl-3  pt-4 mt-1 ">
                                            <select class="form-select" aria-label="Default select example">
                                                <option value=""> Select </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-12 pt-2">
                                            <div class="f-w-500 mb-2 me-2">Data duration</div><br />
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault" checked>
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Same as report generation frequency
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-xl-2"><input type="text" placeholder="days"
                                                class="form-control  " id="Sheet name" disabled></div>
                                        <small>(Upto 180 days of data can be exported.)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Stock Management trends</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Stock Management trends sheet name"
                                                class="form-control" id="Sheet name">
                                        </div>
                                        <div class="form-check col-xl-3 ">
                                            <label for="textBox"> Aggregate by</label>
                                            <select class="form-select" aria-label="Default select example">
                                                <option value="">Select </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="form-check col-xl-3  pt-4 mt-1 ">
                                            <select class="form-select" aria-label="Default select example">
                                                <option value=""> Select </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-12 pt-2">
                                            <div class="f-w-500 mb-2 me-2">Data duration</div><br />
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckDefault" checked>
                                                <label class="form-check-label" for="flexCheckDefault">
                                                    Same as report generation frequency
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="col-xl-2"><input type="text" placeholder="0 days"
                                                class="form-control  " id="Sheet name" disabled></div>
                                        <small>(Upto 180 days of data can be exported.)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-1 mb-2 border-0 rounded-0 shadow-sm">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Historical Stock Management snapshot</h6>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox"> Sheet name</label>
                                            <input type="text" placeholder="Historical Stock Management snapshot sheet name"
                                                class="form-control" id="Sheet name">
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <label for="textBox"> Snap shot time</label>
                                            <input type="text" placeholder="Snap shot time" class="form-control"
                                                id="Sheet name">
                                        </div>
                                        <div class="form-group col-xl-3 pt_2">
                                            <small> (day(s) before report generation)</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Report generation and email</h6>
                        </div>
                        <div class="card-body p-3">
                            <small>Enter the frequency at which the custom report should be generated and
                                emailed</small>
                        </div>
                        <div class="card p-0 mt-3 mb-0">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Report generation schedule</h6>
                            </div>
                            <div class="card-body p-3 pb-0">
                                <div class="mt-3">
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="inlineCheckbox1"
                                            value="option1">
                                        <label class="form-check-label" for="inlineCheckbox1">Daily</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="inlineCheckbox2"
                                            value="option2">
                                        <label class="form-check-label" for="inlineCheckbox2">Weekly</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" id="inlineCheckbox3"
                                            value="option3">
                                        <label class="form-check-label" for="inlineCheckbox3">Monthly</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Email to</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="form-row row mb-2">
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Managers</label>
                                        <input type="text" class="form-control" id="Managers" placeholder="Managers">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox">Administrators</label>
                                        <input type="text" class="form-control" id="Administrators"
                                            placeholder="Administrators">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox"> Email ids</label>
                                        <input type="text" placeholder="Email" class="form-control" id="Email ids">
                                    </div>
                                    <div class="form-group col-xl-3">
                                        <label for="textBox"> User</label>
                                        <input type="text" placeholder=" User" class="form-control" id="Sheet name">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-end pb-3">
                        <button type="button" class="btn btn-sm btn-primary mr-2" *ngIf="cnfgCstRprtPrmsn">Save</button>
                        <button type="button" class="btn btn-sm btn-light" *ngIf="cnfgCstRprtPrmsn">Cancle</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>