import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare var $: any;
declare let LoadChart1: any
declare let LoadChart2: any;
var FusionCharts = require('fusioncharts');

@Component({
  selector: 'evin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent extends BaseComponent implements OnInit {

  lstMatTags: any[] = []; selectedItems = []; dropdownSettings = {};

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); });
  }

  ngOnInit(): void {
    var fsnOptns: any = FusionCharts.options; Promise.all([fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false })]),
    LoadChart1('myBarGraph'), LoadChart2('#donutchart'), this.select2ShowHide(); this.dropdowns(); this.LoadIndMap('fc-Ind');
  }

  dropdowns() {  
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
  }

  onSelectAll(items: any) {
    console.log(items);
  }

  onToolTip(isShow: boolean, evnt: any = null, txt: string = '') {
    let tooltip = (<HTMLElement>document.getElementById("tooltip"));
    if (tooltip) {
      if (isShow) {
        tooltip.innerHTML = txt;
        tooltip.style.display = "block";
        tooltip.style.left = evnt.pageX + 10 + 'px';
        tooltip.style.top = evnt.pageY + 10 + 'px';
      }
      else
        tooltip.style.display = "none";
    }
    else {
      if (isShow)
        this.glblSvc.onTstr('d', 'Mouse overed');
      else
        this.glblSvc.onTstr('d', 'Mouse out');
    }
  }

  LoadIndMap(divId: any) {
    FusionCharts.ready(function () {
      var chart = new FusionCharts({
        type: 'maps/india',
        renderAt: divId,
        width: "600",
        height: "600",
        dataFormat: "json",
        dataSource: {
          "chart": {
            "caption": '',
            // "subcaption": " 2021",
            // "showBorder": "0",
            //"tooltext": "{value} {br}{label}",
            // "labelsepchar": " ",
            //"plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            "numbersuffix": "%",
            "showCanvasBorder": '0',
            "includevalueinlabels": "1",
            "includeNameInLabels": '1',
            'useSNameInLabels': '1',
            "canvasBorderThickness": "1",
            "enableSmartLabels": "1",
            "showMarkerToolTip": '0',
            "showToolTip": "1",
            "entityFillHoverColor": "#f0b74d",
            "nullEntityColor": '#1aaf5d',     //color
            "nullEntityAlpha": "100",
            "hoverOnNull": "1 ",
            "theme": "fusioncharts/latest/maps/fusioncharts",
            "exportEnabled": "1",
            "exportTargetWindow": "_self",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportFileName": "CCE Management",
            'legendPosition': "bottom",
            'showLegend': '1',
            "showLabels": '1',
            "showValues": '1',
          },
          "colorrange": {
            "color":  [{
              "minvalue": "0",
              "maxvalue": "20",
              "code": '#c9c1d4',// "#AACFAE",
              "displayValue": "< 20"
            },
            {
              "minvalue": "20",
              "maxvalue": "40",
              "code": '#c3ace3', //"#8ed98e",
              "displayValue": "20-40"
            }, {
              "minvalue": "40",
              "maxvalue": "60",
              "code": '#b696e3', //"#4ec44e",
              "displayValue": "40-60"
            }, {
              "minvalue": "60",
              "maxvalue": "80",
              "code": '#aa77f2',  //"#2f8e2f",
              "displayValue": "60-80"
            }, {
              "minvalue": "80",
              "maxvalue": "100",
              "code":'#9a5fed',  //"#256e25",
              "displayValue": "> 100"
            }]
          },
          data: [{ "id": "001", "value": "82", 'showLabel': '1' }, { "id": "002", "value": "24", 'displayValue': 'Andhra Predesh, 24%' },
            { "id": "003", "value": "56" }, { "id": "004", "value": "14" }, { "id": "005", "value": "29" }, { "id": "006", "value": "42" },
            { "id": "007", "value": "15" }, { "id": "008", "value": "25" }, { "id": "009", "value": "15" }, { "id": "010", "value": "99" },
            { "id": "011", "value": "62", 'displayValue': 'Goa,20' }, { "id": "012", "value": "3" }, { "id": "013", "value": "98" },
            { "id": "014", "value": "65" }, { "id": "015", "value": "7" }, { "id": "016", "value": "41" }, { "id": "017", "value": "48" },
            { "id": "018", "value": "9" }, { "id": "019", "value": "24" }, { "id": "020", "value": "18" }, { "id": "021", "value": "58" },
            { "id": "022", "value": "30" }, { "id": "023", "value": "32" }, { "id": "024", "value": "36" }, { "id": "025", "value": "35" },
            { "id": "026", "value": "49" }, { "id": "027", "value": "24" }, { "id": "028", "value": "47" }, { "id": "029", "value": "51" },
            { "id": "030", "value": "45" }, { "id": "031", "value": "16" }, { "id": "036", "value": "35" }, { "id": "032", "value": "79" },
            { "id": "033", "value": "1" }, { "id": "034", "value": "2" }, { "id": "035", "value": "75" }],
        },
      }).render();
    });
  }

}