import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-handling-units-view',
  templateUrl: './handling-units-view.component.html',
  styleUrls: ['./handling-units-view.component.css']
})
export class HandlingUnitsViewComponent  extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService, private router: Router) { 
    super(glblSvc);
  }

  ngOnInit(): void {
  }

  onHandlingClk(){
    this.router.navigate(['home/setup-hand-units-details']);
  }

  onHandlingEdit(){
    this.router.navigate(['home/setup-hand-units-edit']);
  }
}
