import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-create-material',
  templateUrl: './create-material.component.html',
  styleUrls: ['./create-material.component.css']
})

export class CreateMaterialComponent extends BaseComponent implements OnInit {

  matCreateForm: FormGroup = this.formBuilder.group({ description: [''], is_batch_enabled: [false], is_seasonal: [false],
    is_temperature_sensitive: [false], maximum_temperature: [''], minimum_temperature: [''], name: ['', Validators.required],
    product_badge_ids: ['', Validators.required], short_name: [''], handling_unit: ['', [Validators.min(1), Validators.max(100)]]
  }); isSbmtd = false; dropdownSettings = {}; slctdIncldFac: any[] = []; lstMatTags: any[] = [];

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product) });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.dropdowns(); 
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL',
      unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
  }

  get fc() { return this.matCreateForm.controls; }

  // onItemSelect(item: any) {
  //   this.slctdIncldFac.push(item.id)
  // }

  // onSelectAll(items: any) {
  //   items.filter((a: any) => this.slctdIncldFac.push(a.id));
  // }

  onChng(type: string, item: any, evntType: any) {
    if (type == 'prdBdge') {
      if (evntType == 'os')
        this.slctdIncldFac.push(item.id)
      else if (evntType == 'osa')
        this.slctdIncldFac = [], item.filter((a: any) => this.slctdIncldFac.push(a.id));
      else if (evntType == 'ds')
        this.slctdIncldFac = this.slctdIncldFac.filter(s => s != item.id)
      else
        this.slctdIncldFac = [];
    }
  }

  cancel() {
    this.matCreateForm.reset(), this.isSbmtd = false, this.router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } });
  }

  saveMaterilas() {
    this.checkFormValidations(this.matCreateForm);
    if (this.matCreateForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.matCreateForm.getRawValue(), tags = [];
      if (payLoad.product_badge_ids != '')
        tags.push(+payLoad.product_badge_ids);
      payLoad.maximum_temperature = (payLoad.maximum_temperature != null && payLoad.maximum_temperature != '') ? +payLoad.maximum_temperature : null,
        payLoad.minimum_temperature = (payLoad.minimum_temperature != null && payLoad.minimum_temperature != '') ? +payLoad.minimum_temperature : null,
        payLoad.handling_unit = (payLoad.handling_unit != null && payLoad.handling_unit != '') ? +payLoad.handling_unit : null,
        payLoad.user_id = this.usrLgnDtls.userId,
        payLoad.product_badge_ids = payLoad.product_badge_ids != '' ? tags : null //this.slctdIncldFac,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.product, environment.apiMthds.creatProduct, payLoad, 7, true).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            localStorage.setItem('matrlCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message),
              this.router.navigate(['home/setup-matrls'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

}