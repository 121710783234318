<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="form-group col-xl-3">
        <label for="inputState-43" class="form-label">Metric</label>
        <select id="inputState-43" class="form-select">
            <option selected>Total discrepancy</option>
            <option>Average discrepancy quantity</option>
            <option>Item-wise % discrepancy in quantity</option>
            <option>% of items with discrepancy</option>
        </select>
    </div>
    <div class="form-group col-xl-3">
        <label for="inputState-44" class="form-label">Discrepancy type</label>
        <select id="inputState-44" class="form-select">
            <option selected>Indent</option>
            <option> Shipping</option>
            <option>Fulfilment</option>
        </select>
    </div>
    <div class="form-group col-xl-3">
        <label for="inputState-45" class="form-label">Reason</label>
        <select id="inputState-45" class="form-select">
            <option selected>All</option>
        </select>
    </div>
    <div class="col-xl-3 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row mt-4">
    <div class="col-xl-12">
        <div class="text-center">
            <h6 class="mb-0">Total discrepancy quantity - Ordering (All)</h6>
            <small>From: Feb 2021 To: Apr 2021 Material tag: RI Vaccines Facility: Sabakheda PHC Indent
                type: Receipt </small>
        </div>
    </div>
</div>
<div class="row mt-2 mb-3">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto">Material</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        <div><a [routerLink]="">BCG (dose)</a></div>
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>