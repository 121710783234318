<form [formGroup]="ntfcBltnForm" autocomplete="off">
    <div class="card mb-0 border-0 rounded-0" formGroupName="configJson">
        <div class="card-header p-2">
            <div class="float-start">
                <h6 class="mb-0">Bulletin board</h6>
            </div>
            <!-- <div class="float-end">
                <button type="button" class="btn btn-sm btn-light">Go to Bulletin board
                </button>
            </div> -->
        </div>
        <div class="card mb-3 p-0 pb-0 mt-2 pt-2" formGroupName="enable_bulletin_board">
            <div class="card-header shadow-sm p-2">
                <h6 class="mb-0">Enable Bulletin board</h6>
            </div>
            <div class="card-body p-2 mt-0 mb-0">
                <div class="row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="small">Specify the bulletin board configuration options</div>
                        <div class="row mb-2 mt-3">
                            <div class="form-group col-xl-12">
                                <div class="f-w-500"><label for="textBox">Enable</label></div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="enable" id="inlineRadio1"
                                        value="Disabled" formControlName="enable">
                                    <label class="form-check-label" for="inlineRadio1">Disabled</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="enable" id="inlineRadio2"
                                        value="Only users in my domain can see" formControlName="enable">
                                    <label class="form-check-label" for="inlineRadio2">Only users in my
                                        domain can see</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="enable" id="inlineRadio3"
                                        value="Everyone can see" formControlName="enable">
                                    <label class="form-check-label" for="inlineRadio3">Everyone can
                                        see</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 p-0 pb-0 mt-2 pt-2" formGroupName="data_options">
            <div class="card-header shadow-sm p-2">
                <h6 class="mb-0">Data options</h6>
            </div>
            <div class="card-body p-2 mt-0 mb-0">
                <div class="row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row mb-2">
                            <div class="form-group col-xl-3">
                                <label for="textBox">Duration of data</label>
                                <div class="row">
                                    <div class="col-sm-8">
                                        <input type="text" placeholder="Duration of data" class="form-control form-control-sm"
                                            id="duration_of_data" formControlName="duration_of_data"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                    </div>
                                    <div class="col-sm-4">
                                        day(s)
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6">
                                <label for="textBox">Maximum number of items on board</label>
                                <div class="row">
                                    <div class="col-sm-8">
                                        <input type="text" placeholder="Maximum number of items on board"
                                            class="form-control form-control-sm" id="maximum_number_of_items_on_board"
                                            formControlName="maximum_number_of_items_on_board"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                    </div>
                                    <div class="col-sm-4">
                                        <small>(cannot exceed 100)</small>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="textBox">Refresh Duration</label>
                                <div class="row">
                                    <div class="col-sm-7">
                                        <input type="text" placeholder="Refresh Duration" class="form-control form-control-sm"
                                            id="refresh_duration" formControlName="refresh_duration"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                    </div>
                                    <div class="col-sm-5">
                                        seconds
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3 p-0 pb-0 mt-2 pt-2" formGroupName="display_options">
            <div class="card-header shadow-sm p-2">
                <h6 class="mb-0">Display options</h6>
            </div>
            <div class="card-body p-2 mt-0 mb-0">
                <div class="row">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row mb-2">
                            <div class="form-group col-xl-12">
                                <label for="textBox">Scroll interval</label>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" placeholder="Scroll interval" class="form-control form-control-sm"
                                            id="scroll_interval" formControlName="scroll_interval"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                    </div>
                                    <div class="col-sm-4">ms</div>
                                </div>
                            </div>
                            <div class="form-group col-xl-12">
                                <div class="form-group col-xl-12 mt-3 mb-2">
                                    <label for="textBox" class="f-w-500 mb-2 me-2">Inventory
                                        visibility</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox" id="show_slide_navigator"
                                            formControlName="show_slide_navigator">
                                        <label class="form-check-label" for="flexCheckChecked">
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end pb-3">
            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgntfnPrmsn" (click)="onSave()">Save</button>
            <!-- <button type="button" class="btn btn-sm btn-light">Post to board</button> -->
        </div>
    </div>
</form>