<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="fcltyLst"><a class="nav-link" id="Facilities-tab" data-bs-toggle="tab" href="#Facilities"
                    role="tab" aria-controls="User" aria-selected="true" (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">Facility</a></li>
            <li class="nav-item" *ngIf="cratFcltyBulk"><a class="nav-link" id="Create-Facility-top-tab" data-bs-toggle="tab"
                    href="#top-Create-Facility" role="tab" aria-controls="Create-Facility" aria-selected="false" (click)="onTabSelection('new')"
                     [ngClass]="paramTab === 'new' ? 'active' : ''">{{facltyHead}}
                    </a></li>
            <li class="nav-item" *ngIf="cratFcltyBulk"><a class="nav-link" id="bulkup-top-tab" data-bs-toggle="tab" href="#top-bulkup"
                    role="tab" aria-controls="top-bulkup" aria-selected="false" (click)="onTabSelection('upld')" [ngClass]="paramTab === 'upld' ? 'active' : ''">Bulk upload</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="Facilities" role="tabpanel" aria-labelledby="Facilities-tab"
             [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-facility-view></evin-facility-view>
            </div>
            <div class="tab-pane fade" id="top-Create-Facility" role="tabpanel" aria-labelledby="Create-Facility-top-tab" 
             [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-facility-create></evin-facility-create>
            </div>
            <div class="tab-pane fade" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab" 
             [ngClass]="paramTab === 'upld' ? 'show active' : '' ">
                <evin-bulk-upload></evin-bulk-upload>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="modal_error">
    <div class="modal-dialog modal-xl modal -dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body">
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-sm table_spacing table-small-font p-0" style="min-width: 1100px;">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Character offset</th>
                                <th>Operation</th>
                                <th>Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>1334</td>
                                <td>add</td>
                                <td>
                                    <div class="card p-0">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2 text-danger">
                                                    1
                                                </div>
                                                <div class="col-xl-11 col-sm-10 text-danger">
                                                    MSRP: Price 'no' is invalid. It should be a valid number between 0
                                                    and 1 billion, rounded to two decimal places maximum.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2">
                                                    in
                                                </div>
                                                <div class="col-xl-11 col-sm-10">
                                                    a,0.1 ml syringe (pieces),,,,yes,Syringes,no,no,,,INR,,,no,no,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>