import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.css']
})
export class ShipmentsComponent extends BaseComponent implements OnInit {

   paramTab: any;

  constructor(public route: Router, public glblSvc: GlobalService, private Actroute: ActivatedRoute,) {
      super(glblSvc);
    this.Actroute.queryParams.subscribe(params => {
      this.paramTab = params.tab;
    });
  }

  ngOnInit(): void {
  }

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
    if(tabName == 'list')
      window.location.reload();
  }

  updateQueryParams(queryParams: any) {
    this.route.navigate([], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }
}
