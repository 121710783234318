<div class="card p-3 pb-0 pt-2 border-0">
    <div class="card-header shadow-sm p-3 pb-1 pt-2">
        <div class="h6">Add notification</div>
    </div>
    <div class="card-body p-0 mt-1 mb-0">
        <form>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card p-0 mt-0 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">Fulfilment due (Remind days before = 4)</h6>
                        </div>
                        <div class="card-body p-2">
                            <div>Specify the categories to exclude while notifying the event.</div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="mb-2 mt-3">
                                        <div class="form-group mb-2">
                                            <label for="textBox" class="mb-0">Facility categories</label>
                                            <select class="js-example-basic-hide-search" multiple="multiple">
                                                <option value="">All</option>
                                                <option>Operator</option>
                                                <option>Manager</option>
                                                <option>administrator</option>
                                            </select>
                                        </div>
                                        <div class="form-group mb-2">
                                            <label for="textBox" class="mb-0">Indent categories</label>
                                            <select class="js-example-basic-hide-search" multiple="multiple">
                                                <option>Critical</option>
                                                <option>Routine</option>
                                                <option>administrator</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="mb-2">Specify who should be notified of this event, at what frequency
                                        and
                                        method (email/SMS). In case of SMS message, also specify the message template
                                        with
                                        variables embedded in the message, as needed.</div>
                                    <div class="mb-0 mt-2">
                                        Whom to notify, at what Frequency?
                                    </div>
                                    <div class="table-responsive mt-2">
                                        <table class="table table-striped table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Whom to notify</th>
                                                    <th>Frequency</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault">
                                                        </div>
                                                    </td>
                                                    <td>Receiving facilities / Owners</td>
                                                    <td>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio1"
                                                                value="option1">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">Immediately
                                                                (SMS)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio2"
                                                                value="option2">
                                                            <label class="form-check-label" for="inlineRadio2">Daily
                                                                (Email)
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option3">
                                                            <label class="form-check-label" for="inlineRadio3">Weekly
                                                                (Email)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option4">
                                                            <label class="form-check-label" for="inlineRadio4">Monthly
                                                                (Email)</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault">
                                                        </div>
                                                    </td>
                                                    <td>Issuing facilities</td>
                                                    <td>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio1"
                                                                value="option1">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">Immediately
                                                                (SMS)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio2"
                                                                value="option2">
                                                            <label class="form-check-label" for="inlineRadio2">Daily
                                                                (Email)
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option3">
                                                            <label class="form-check-label" for="inlineRadio3">Weekly
                                                                (Email)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option4">
                                                            <label class="form-check-label" for="inlineRadio4">Monthly
                                                                (Email)</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="flexCheckDefault">
                                                        </div>
                                                    </td>
                                                    <td>Administrators</td>
                                                    <td>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio1"
                                                                value="option1">
                                                            <label class="form-check-label"
                                                                for="inlineRadio1">Immediately
                                                                (SMS)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio2"
                                                                value="option2">
                                                            <label class="form-check-label" for="inlineRadio2">Daily
                                                                (Email)
                                                            </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option3">
                                                            <label class="form-check-label" for="inlineRadio3">Weekly
                                                                (Email)</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input class="form-check-input" type="radio"
                                                                name="inlineRadioOptions" id="inlineRadio3"
                                                                value="option4">
                                                            <label class="form-check-label" for="inlineRadio4">Monthly
                                                                (Email)</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="form-check form-check-inline mt-3" *ngIf="false">
                                        <input class="form-check-input" type="checkbox" name="inlineRadioOptions"
                                            id="inlineRadio3" value="option4">
                                        <label class="form-check-label" for="inlineRadio4">Post on Bulletin
                                            board</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <label class="form-label" for="">Message template (of message to be sent or
                                        posted)</label>
                                    <textarea class="form-control" id="" placeholder="Add comment"></textarea>
                                </div>
                                <div class="col-xl-12 text-end mt-3">
                                    <button type="button" class="btn btn-sm btn-primary me-2">Save</button>
                                    <button type="button" class="btn btn-sm btn-light">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-0 mt-3 mb-0">
                        <div class="card-header  p-2">
                            <h6 class="mb-0 ">Variables that can be used in notification messages:</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="table-responsive mt-4">
                                        <table class="table table-striped table-sm table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td>Created on</td>
                                                    <td>%creationTime%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Receiving facility</td>
                                                    <td>%customer%</td>
                                                    <td>25</td>
                                                </tr>
                                                <tr>
                                                    <td>Receiving facility city/village</td>
                                                    <td>%customerCity%</td>
                                                    <td>15</td>
                                                </tr>
                                                <tr>
                                                    <td>Required by date</td>
                                                    <td>%requiredByDate%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Estimated date of arrival</td>
                                                    <td>%estimatedDateOfArrival%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Comment</td>
                                                    <td>%comment%</td>
                                                    <td>100</td>
                                                </tr>
                                                <tr>
                                                    <td>Commented on</td>
                                                    <td>%commentedOn%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Commented by</td>
                                                    <td>%commentedBy%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Material names (only)</td>
                                                    <td>%materials%</td>
                                                    <td>(variable)</td>
                                                </tr>
                                                <tr>
                                                    <td>Material names with quantity and reasons (if any)</td>
                                                    <td>%materialsWithMetadata%</td>
                                                    <td>(variable)</td>
                                                </tr>
                                                <tr>
                                                    <td>Number of items</td>
                                                    <td>%numberOfItems%</td>
                                                    <td>6</td>
                                                </tr>
                                                <tr>
                                                    <td>Indent Id</td>
                                                    <td>%orderId%</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>Payment</td>
                                                    <td>%payment%</td>
                                                    <td>10</td>
                                                </tr>
                                                <tr>
                                                    <td>Status</td>
                                                    <td>%orderStatus%</td>
                                                    <td>7</td>
                                                </tr>
                                                <tr>
                                                    <td>Status change time</td>
                                                    <td>%statusChangeTime%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>Issuing facility</td>
                                                    <td>%vendor%</td>
                                                    <td>25</td>
                                                </tr>
                                                <tr>
                                                    <td>Issuing facility city/village</td>
                                                    <td>%vendorCity%</td>
                                                    <td>15</td>
                                                </tr>
                                                <tr>
                                                    <td>Updated on</td>
                                                    <td>%updationTime%</td>
                                                    <td>20</td>
                                                </tr>
                                                <tr>
                                                    <td>User</td>
                                                    <td>%user%</td>
                                                    <td>25</td>
                                                </tr>
                                                <tr>
                                                    <td>Username</td>
                                                    <td>%userId%</td>
                                                    <td>25</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>