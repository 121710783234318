import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-demand-materials',
  templateUrl: './demand-materials.component.html',
  styleUrls: ['./demand-materials.component.css']
})
export class DemandMaterialsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
