import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.css']
})

export class UsersViewComponent extends BaseComponent implements OnInit {

  noRcrdsTxt = environment.TblNoRcrds; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems = 0; usersLst: any[] = [];
  userBadges: any[] = []; rolesLst: any[] = []; frmDate: any = ''; userViewForm = this.formBuilder.group({
    f_name: [''], from_last_login_date: [''],
    is_Deleted: ['false'], mobile_app_version: [''], mobile_phone_number: ['', [Validators.minLength(10), Validators.maxLength(13)]], role: [''], to_last_login_date: [''], message: [''], user_id: [''],
    user_badge: [''], user_never_login: [false]
  }); slctdUsersLst: any[] = []; isslctd = true; messages: string = ''; isSelct = true;
  actvty: any = 'false'; usrIds: any[] = []; isSbmtd = false; usrLvlDtls: any = null; isUserLvl: any = null; usrLst: boolean = true;
  isBetaURL = environment.isBetaURL; prmsns: any = null; usrDtl: boolean = true; usrEdt: boolean = true; usrDlt: boolean = true;
  isDelSa: any;

  constructor(public glblSvc: GlobalService
    , private router: Router, private formBuilder: FormBuilder, private modalService: NgbModal) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe((a: any) => { this.userBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.user) }),
      this.glblSvc.sbjctMstrData.subscribe(a => { this.rolesLst = a.rlsLst.filter((f: any) => f.moduleName == 'General'); });
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) => {
      if (e?.menuCode == 'mm_st_us_li')
        this.usrLst = e?.action;
      if (e?.menuCode == 'mm_st_us_ud')
        this.usrDtl = e?.action;
      if (e?.menuCode == 'mm_st_us_ed')
        this.usrEdt = e?.action;
      if (e?.menuCode == 'mm_st_us_dt')
        this.usrDlt = e?.action;
    });

    this.usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] :
      {}, this.isUserLvl = this.usrLvlDtls?.userLevel; this.isDelSa = localStorage.getItem('isClkdSA');
    setTimeout(() => {
      if (this.usrLst)
        this.loadUsers();
    }, 1000);

  }

  get fc() { return this.userViewForm.controls; }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDt(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  loadUsers() {
    let crntPgIndx = this.crntPage - 1, payLoad = this.userViewForm.getRawValue();
    payLoad.from_last_login_date = payLoad.from_last_login_date ? this.glblSvc.setDtFrmt(payLoad.from_last_login_date, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.to_last_login_date = payLoad.to_last_login_date ? this.glblSvc.setDtFrmt(payLoad.to_last_login_date, this.dtF.ymd) + this.tmF.lastTM : null,
      payLoad.pranth_id = this.chsnDmnId,
      payLoad.f_name = payLoad.f_name != '' ? payLoad.f_name : null, payLoad.user_badge = payLoad.user_badge != '' ? payLoad.user_badge : null,
      payLoad.user_id = payLoad.user_id != '' ? payLoad.user_id : null, payLoad.role = payLoad.role != '' ? payLoad.role : null,
      payLoad.is_Deleted = (payLoad.is_Deleted != null && payLoad.is_Deleted != '') ? payLoad.is_Deleted : null, payLoad.mobile_app_version =
      payLoad.mobile_app_version != '' ? payLoad.mobile_app_version : null, payLoad.mobile_phone_number = payLoad.mobile_phone_number != '' ?
        payLoad.mobile_phone_number : null, delete payLoad.message; this.usersLst = [], payLoad.user_never_login = payLoad.user_never_login,
          this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.getusersByFilter.
            replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).replace('sss', `${this.isDelSa == '1'}`), payLoad, 7, true).then(() => {
              if (this.result) {
                if (this.result.status == 'OK') {
                  if (this.result.data)
                    this.result.data?.result.forEach((e: any) => { Object.assign(e, { flag: false }) }),
                      this.usersLst = this.result.data?.result != null ? this.result.data?.result : [],
                      this.totalItems = this.result.data?.totalRecordCount ?? 0,
                      this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
                  else
                    this.glblSvc.onTstr('e', this.result.message);
                }
              }
            });
  }

  clearBSDate(ctrl: string) {
    this.userViewForm.get(ctrl)?.setValue('');
  }

  onUserClk(userItem: any) {
    if (this.usrDtl && this.isDelSa !='1') {
      this.glblSvc.sbjctUsrId.next(userItem.id), localStorage.setItem('usrDtls', JSON.stringify(userItem)),
        this.router.navigate(['home/setup-users-details']);
    }
  }

  onUserEdit(userId: any) {
    if (this.usrEdt)
      this.glblSvc.sbjctUsrId.next(userId), this.router.navigate(['home/setup-users-edit']);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.loadUsers();
  }

  onReset() {
    this.userViewForm.reset(), this.userViewForm.get('user_badge')?.setValue(''), this.actvty = 'false',
      this.userViewForm.get('role')?.setValue(''), this.loadUsers(); this.userViewForm.get('f_name')?.setValue(''),
        this.userViewForm.get('from_last_login_date')?.setValue(''), this.userViewForm.get('mobile_app_version')?.setValue(''),
        this.userViewForm.get('is_Deleted')?.setValue(''), this.userViewForm.get('mobile_phone_number')?.setValue(''),
        this.userViewForm.get('to_last_login_date')?.setValue(''), this.userViewForm.get('message')?.setValue(''),
        this.userViewForm.get('user_id')?.setValue(''), this.userViewForm.get('user_never_login')?.setValue(false);
  }

  onRemove(userId: any) {
      if (confirm("Are you sure you want to delete the user ?")) {
        this.FetchData(environment.apiCalls.del, environment.apiPaths.users, environment.apiMthds.delById + userId,
          null, 7).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                if (this.result.message === "Records deleted")
                  this.glblSvc.onTstr('s', this.result.message), this.loadUsers();
                else
                  this.glblSvc.onTstr('w', this.result.message);
              }
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
    }
  }

  onRetrvData() {
    let payLoad: any= {};
      if (confirm("Are you sure you want to Retrieve the user.?")) {
        payLoad.userIds = this.usrIds, payLoad.userId = this.usrLgnDtls?.userId;
        this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.retrveUsrs.replace('sss', 'true'), 
          payLoad, 7).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.message === "Users retrieved successfully")
                this.glblSvc.onTstr('s', this.result.message), this.loadUsers();
              else 
                this.glblSvc.onTstr('w', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
      }
  }

  onEE() {
    let payLoad = this.userViewForm.getRawValue();
    payLoad.from_last_login_date = payLoad.from_last_login_date ? this.glblSvc.setDtFrmt(payLoad.from_last_login_date, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.to_last_login_date = payLoad.to_last_login_date ? this.glblSvc.setDtFrmt(payLoad.to_last_login_date, this.dtF.ymd) + this.tmF.lastTM : null,
      payLoad.pranth_id = this.chsnDmnId,
      payLoad.f_name = payLoad.f_name != '' ? payLoad.f_name : null, payLoad.user_badge = payLoad.user_badge != '' ? payLoad.user_badge : null,
      payLoad.user_id = payLoad.user_id != '' ? payLoad.user_id : null, payLoad.role = payLoad.role != '' ? payLoad.role : null,
      payLoad.is_Deleted = (payLoad.is_Deleted != null && payLoad.is_Deleted != '') ? payLoad.is_Deleted : null, payLoad.mobile_app_version =
      payLoad.mobile_app_version != '' ? payLoad.mobile_app_version : null, payLoad.mobile_phone_number = payLoad.mobile_phone_number != '' ?
        payLoad.mobile_phone_number : null, delete payLoad.message; payLoad.user_never_login = payLoad.user_never_login;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.expUsers.replace('sss', `${this.isDelSa == '1'}`), payLoad, 7, true).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  // isSelected($event: any, item: any) {
  //   if (this.slctdUsersLst.filter(f => f.id == item.id).length == 0)
  //     this.slctdUsersLst.push({ id: item.id, email: item.email });
  //   else
  //     this.slctdUsersLst = this.slctdUsersLst.filter(f => f.id != item.id);
  // }

  isSelected($event: any, item: any) {
    if (this.slctdUsersLst.filter(f => f.id == item.id).length == 0)
      this.slctdUsersLst.push({ id: item.id});
    else
      this.slctdUsersLst = this.slctdUsersLst.filter(f => f.id != item.id);
    if ($event.target.checked) {
      item.flag = true, this.usrIds.push(item.id);
    }
    else
      item.flag = false, this.usrIds.filter((a: any, index: any) => { if (a == item.id) this.usrIds.splice(index, 1); })
  }

  onSlctAll($event: any) {
    this.slctdUsersLst = [], this.usrIds = [];
    if ($event.target.checked)
      this.usersLst.filter(a => { a.flag = true, this.slctdUsersLst.push({ id: a.id }), this.usrIds.push(a.id); })
    else
      this.usersLst.filter(a => { a.flag = false });
  }

  sendMail() {
    let payLoad: any, emlsLst: any[] = [];
    this.messages = this.userViewForm.get('message')?.value, this.slctdUsersLst.forEach(e => { emlsLst.push(e.email); }),
      payLoad = { content: this.messages, email: { subject: '', toMails: emlsLst } };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.notifications, environment.apiMthds.sendEmail, payLoad, 3, true, 4).then(() => {
      if (this.result) {
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        if (this.result.status == 'OK')
          this.userViewForm.get('message')?.setValue(''), this.slctdUsersLst = [];
      }
    })
  }

}