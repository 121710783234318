<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row">
    <div class="form-group col-xl-3">
        <select id="inputState" class="form-select">
            <option value="">All Transaction Type</option>
            <option>Issues/Net Utilization</option>
            <option>Receipts</option>
            <option>Stock Counts</option>
            <option>Discards</option>
            <option>Transfers</option>
            <option>Incoming Returns</option>
            <option>Outgoing Returns</option>
        </select>
    </div>
    <div class="form-group col-xl-3">
        <select id="inputState" class="form-select">
            <option value="">Number of Transaction</option>
            <option>Average no. of Transactions per Facility</option>
            <option>% Facilities With Transactions</option>
        </select>
    </div>
    <div class="col-xl-6 text-end">
        <div class="float-end">
            <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab" data-bs-toggle="tab"
                        href="#Map" role="tab" aria-controls="Map" aria-selected="true"><i class="fa fa-bar-chart me-0"
                            aria-hidden="true"></i></a>
                </li>
                <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab" data-bs-toggle="tab"
                        href="#Map-1" role="tab" aria-controls="Map-1" aria-selected="false"><i class="fa fa-table me-0"
                            aria-hidden="true"></i></a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="tab-content" id="top-tabContent">
    <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
        <div class="row mt-3">
            <div class="col-xl-6">
                <div class="card h-100">
                    <div class="card-body p3">
                        <div class="row">
                            <div class="col-xl-12 centered-div mt-3">
                                <div id="fcpieInv" class="text-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6  ">
                <div class="card h-100 mb-0">
                    <div class="card-body p-3">
                        <div class="table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered border-primary f-14">
                                <thead>
                                    <tr>
                                        <th class="col-lg-8">Type</th>
                                        <th class="col-lg-4 text-center">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Issues/Net utilization</td>
                                        <td class="text-center">54,410</td>
                                    </tr>
                                    <tr>
                                        <td>Receipts</td>
                                        <td class="text-center">30,518</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Counts</td>
                                        <td class="text-center">82,762</td>
                                    </tr>
                                    <tr>
                                        <td>Discards</td>
                                        <td class="text-center">14,104</td>
                                    </tr>
                                    <tr>
                                        <td>Transfers</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Incoming returns</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Outgoing returns</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td class="text-center">181,794</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-12">
                <div class="card p-0 mb-0">
                    <div class="card-body p-3">
                        <div class="row mt-3 mb-3">
                            <div class="col-xl-12">
                                <div class="text-center">
                                    <h6 class="mb-0">All transaction types - Number of transactions</h6>
                                    <small>From: Mar 2021 To: May 2021</small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-8 offset-xl-2  centered-div">
                                <div class="text-center">
                                    <div id="fcbarInv" class="text-center"></div>
                                </div>
                            </div>
                            <div class="centered-div mt-3"><a>Download as CSV</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab-pane fade " id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
        <div class="row mt-3">
            <div class="col-xl-6">
                <div class="card h-100">
                    <div class="card-body p-2 m-2">
                        <div class="row">
                            <div class="col-xl-12 centered-div mt-3">
                                <div id="fcpieInv" class="text-center"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 ">
                <div class="card h-100">
                    <div class="card-body p-2 m-2">
                        <div class="table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered border-primary f-14">
                                <thead>
                                    <tr>
                                        <th class="col-lg-8">Type</th>
                                        <th class="col-lg-4 text-center">Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Issues/Net utilization</td>
                                        <td class="text-center">54,410</td>
                                    </tr>
                                    <tr>
                                        <td>Receipts</td>
                                        <td class="text-center">30,518</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Counts</td>
                                        <td class="text-center">82,762</td>
                                    </tr>
                                    <tr>
                                        <td>Discards</td>
                                        <td class="text-center">14,104</td>
                                    </tr>
                                    <tr>
                                        <td>Transfers</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Incoming returns</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Outgoing returns</td>
                                        <td class="text-center">0</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td class="text-center">181,794</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card m-3">
            <div class="card-body p-2">
                <div class="row mt-3">
                    <div class="col-xl-6 offset-xl-3 text-center">
                        <h6 class="mb-0">All transaction types - Number of transactions</h6>
                        <small>From: May 2020 To: Apr 2021</small>
                    </div>
                </div>
                <div class="col-xl-10 col-sm-12 offset-xl-1 mt-2 mb-3 table-responsive table_spacing">
                    <table class="table table-striped table-sm table-bordered border-primary f-14"
                        style="min-width: 300;">
                        <thead>
                            <tr>
                                <th class="col-auto">Date</th>
                                <th class="col-auto text-center">Issues/Net utilization</th>
                                <th class="col-auto text-center">Receipts</th>
                                <th class="col-auto text-center">Stock Counts</th>
                                <th class="col-auto text-center">Discards</th>
                                <th class="col-auto text-center">Transfers</th>
                                <th class="col-auto text-center">Incoming returns</th>
                                <th class="col-auto text-center">Outgoing returns</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Apr 2021</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Mar 2021</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Feb 2021</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Jan 2021</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                                <td class="text-center">0</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="centered-div mt-3"><a>Download as CSV</a></div>
                </div>
            </div>
        </div>
    </div>
</div>