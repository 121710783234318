import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent extends BaseComponent implements OnInit {

  chngPswdFrm = this.fb.group({ userName: new FormControl('', Validators.required), otp: new FormControl('mobile', Validators.required),
    newPassword: new FormControl('', [Validators.required, Validators.pattern(environment.patterns.alphaNum), Validators.minLength(7),
      Validators.maxLength(18)]), oldPassword: new FormControl('', Validators.required), dmy: new FormControl(''),
    cnfmPswd: new FormControl('', Validators.required) }, { validators: this.MustMatch('newPassword','cnfmPswd')});
    isShwHde = false; isSHde = false; isValidPswd = true; isSbmtd = false; isShow = false; isSave = false; o1 = ''; o2 =''; o3 = '';

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    private router: Router) {
    super(glblSvc);
  }

  get fc() { return this.chngPswdFrm.controls; }

  ngOnInit(): void {
    this.chngPswdFrm.get('userName')?.setValue(this.usrLgnDtls.userName);
  }

  onOTP(i: number) {
    if (i == 1 && this.o1.length == 2)
      this.setElmntFocus('otp2');
    else if (i == 2 && this.o2.length == 2)
      this.setElmntFocus('otp3');
    else if (i == 3 && this.o3.length == 2)
      this.setElmntFocus('oldPswd');
    else
      return;
  }

  onSend() {
    let payLoad = { userName: this.chngPswdFrm.get('userName')?.value, sendOTPTo: this.chngPswdFrm.get('otp')?.value };
    this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.api + environment.apiPaths.users,
      environment.apiMthds.resetPswd + 'change', payLoad).subscribe((s: any) => { this.glblSvc.sbjctSpnr.next(false);
      if (s) {
        if (s.status == 'OK')
          this.isShow = true, this.glblSvc.onTstr('s', s.message), setTimeout(() => { this.setElmntFocus('otp1'); }, 200);
        else
          this.glblSvc.onTstr('e', s.message);
      }
    });
  }

  onResend() {
    let payLoad = { userName: this.chngPswdFrm.get('userName')?.value, resendOTPTo: this.chngPswdFrm.get('otp')?.value };
    this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.api + environment.apiPaths.users,
      environment.apiMthds.resndPswd + 'change', payLoad).subscribe((s: any) => { this.glblSvc.sbjctSpnr.next(false);
      if (s) {
        if (s.status == 'OK')
          this.glblSvc.onTstr('s', s.message);
        else
          this.glblSvc.onTstr('e', s.message);
      }
    });
  }

  onShwHde() {
    if (this.chngPswdFrm.get('newPassword')?.value != '')
      this.isShwHde = !this.isShwHde, this.ShowHidePwd('chngNewPswd', this.isShwHde);
  }

  onSHde() {
    if (this.chngPswdFrm.get('oldPassword')?.value != '')
      this.isSHde = !this.isSHde, this.ShowHidePwd('oldPswd', this.isSHde);
  }

  onSave() {
    if (this.chngPswdFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else if (this.chngPswdFrm.get('newPassword')?.value != this.chngPswdFrm.get('cnfmPswd')?.value)
      this.glblSvc.onTstr('w', 'New Password & Confirm New Password should be same');
    else {
      let payLoad = this.chngPswdFrm.getRawValue();
      payLoad.newPassword = btoa(payLoad.newPassword), payLoad.oldPassword = btoa(payLoad.oldPassword) + this.GenUnqText(),
        payLoad.otp = +(this.o1 + this.o2 + this.o3), delete payLoad.cnfmPswd, delete payLoad.dmy;
      this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.api + environment.apiPaths.users,
        environment.apiMthds.vldtPswd + 'change', payLoad).subscribe((s: any) => { this.glblSvc.sbjctSpnr.next(false);
        if (s) {
          if (s.status == 'OK')
            this.glblSvc.onTstr('s', s.message), setTimeout(() => { this.router.navigate([ environment.lndgPathGnrl]); }, 200);
          else
            this.glblSvc.onTstr('e', s.message);
        }
      });
    }
  }

}