import { Component, OnInit } from '@angular/core';
import { FormBuilder, ɵInternalFormsSharedModule } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-cnfg-event-notifications',
  templateUrl: './cnfg-event-notifications.component.html',
  styleUrls: ['./cnfg-event-notifications.component.css']
})

export class CnfgEventNotificationsComponent extends BaseComponent implements OnInit {

  lstFreq = [{ name: 'Receiving facilitys / Owners', isChk: false, rbVal: '' }, { name: 'Issuing facilities', isChk: false, rbVal: '' },
  { name: 'Administrators', isChk: false, rbVal: '' }, { name: 'Asset Owners / Maintainers', isChk: false, rbVal: '' },
  { name: 'Creator', isChk: false, rbVal: '' }, { name: 'Users', isChk: false, rbVal: '' }]; isSave = false; tabType = 'o'; // o, c, i, s, a
  whmNtfy: any[] = [];
  // Orders
  ordrEvnt = ''; oeDays = ''; oeSts = ''; lstOrdTags: any[] = []; lstFacTags: any[] = []; lstUsrTags: any[] = []; lstUsrs: any[] = [];
  isOrdAdd = false; msdStngs = {}; rsnMsdStngs = {};
  // Set Up
  suEvnt = ''; suDays = ''; suPtrn = ''; isSUAdd = false;
  // Assets
  asstType = 'Monitored Asset'; mndEvnt = ''; mndMins = ''; astSts = ''; mngEvnt = ''; mngMins = ''; isAstAdd = false; isMdAdd = false;
  isMgAdd = false; shpmntEvnt = ''; invntryEvnt = '';
  isAdd1 = false; iePrcnt = ''; ieSts = ''; ieSds = ''; isAdd2 = false; lstMatTags: any[] = []; ieSct = ''; ieSes = ''; ieSrc = '';
  evntNtfcnLst: any[] = []; shipmentsLst: any[] = []; ordersLst: any[] = []; invntryLst: any[] = []; setupLst: any[] = [];
  assetsLst: any[] = []; moniteredLst: any[] = []; monitoringLst: any[] = []; ordrEvntName = ''; orderStatusList: any[] = [];
  StrTags: any[] = []; ordrTags: any[] = []; tabTypeId = '5'; usrNames: any[] = []; usrTags: any[] = []; apiRspns: any[] = [];
  isEvnt = false; slctdFacTags: any; slctdordrTags: any; slctdUsrBadges: any; slctdUsrNames: any; InvntryData: any[] = [];
  invntryEvntName = ''; slctdMatTags: any; matTags: any[] = []; suEvntName = ''; setUpData: any[] = []; isSUSave = false;
  mndEvntName = ''; mngEvntName = ''; isAstSave = false; asetsData: any[] = []; rsnsLst: any[] = []; rsnsLst1: any[] = [];
  rsnsLst2: any[] = []; shpmntData: any[] = []; shpmntEvntName = ''; shpStatus = ''; usrPrmsn: any = null; cnfgntfnPrmsn = true; astTypLst: any[] = [];
  asetType: any[] = []; selectedTypes: any[] = []; ordStatus: any[] = []; slctdOrdStatus: any = ''; invStsLst: any[] = []; invDscrdStsLst: any[] = [];
  invStkCntSts: any[] = []; invStkIn: any[] = []; invStkTrnsfr: any[] = []; slctdInvRsn: any[] = []; slctdInvRsn21: any[] = []; slctdInvIsuSts: any[] = [];
  slctdInvInSts: any[] = []; slctdInvTrnsSts: any[] = []; slctdInvDscrdSts: any[] = []; slctdInvEdtSts: any[] = []; slctdInvStsLst: any[] = [];
  slctdInvRsnLst: any[] = []; rsnsLst3: any[] = []; slctdInvRsnIs: any[] = []; ttlRsnsLst: any[] = []; admnTgsLst: any[] = []; admUsrTags: any[] = []; 
  slctdAdmUsrBdges: any[] = []; isEdit = false; isSupFacShw = true;
 
  dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, 
  allowSearchFilter: true };

  cnfgEvntNotfcForm = this.formBuilder.group({
    bookingBadge: [''],
    timePeriod: [''],
    id: [null],
    notificationEventId: [''],
    postOnBulletinBoard: [false],
    postOnBulletinBoardBadge: [[]],
    statusId: [''],
    storeBadge: [''],
    updatedBy: [+this.usrLgnDtls?.userId],
    whomToNotify: [[]], // this.formBuilder.array([])
    usrTags: [''], //added extra
    AdminUsrTags: [''],
    usrNames: [''], //added extra
    productBadge: [''], //added extra for invrntory tab
    reasonId: ['']
  })

  constructor(public glblSvc: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.evntNtfcnLst = s.evntNtfcnLst,
      this.astTypLst = s.stsLst.filter((f: any) => f.statusType === 'Asset'),
      this.invStsLst = s.stsLst.filter((f: any) => f.statusType === 'Issue'),
      this.invDscrdStsLst = s.stsLst.filter((f: any) => f.statusType === 'Discard'),
      this.invStkCntSts = s.stsLst.filter((f: any) => f.statusType === 'Stock Count'),
      this.invStkIn = s.stsLst.filter((f: any) => f.statusType === 'Receipt'),
      this.invStkTrnsfr = s.stsLst.filter((f: any) => f.statusType === 'Transfer'),
      this.rsnsLst = s.rsnsLst.filter((f: any) => f.reasonType == "Transaction Discard"),
      this.rsnsLst1 = s.rsnsLst.filter((f: any) => f.reasonType == "Transaction Stock Count"),
      this.rsnsLst2 = s.rsnsLst.filter((f: any) => f.reasonType == "Transaction Transfer"),
      this.rsnsLst3 = s.rsnsLst.filter((f: any) => f.reasonType == "Issue"),
      this.ttlRsnsLst = s.rsnsLst
    })
  }
  setTabsFreq(type: any, data: any) {
    let result: any[] = [], ipData: any[] = data, opData: any[] = [];
    if (type == 'ind')
       ipData.filter(e => { if (e.notificationEventType == 'Notification-Event-Orders') { opData.push(e.whomToNotify) } });
    else if (type == 'ship')
      ipData.filter(e => { if (e.notificationEventType == 'Notification-Event-Shipments') { opData.push(e.whomToNotify) } });
    else if (type == 'inv')
      ipData.filter(e => { if (e.notificationEventType == 'Notification-Event-Inventory') { opData.push(e.whomToNotify) } });
    else if (type == 'stup')
      ipData.filter(e => { if (e.notificationEventType == 'Notification-Event-Setup') { opData.push(e.whomToNotify) } });
    else if (type == 'asts')
      ipData.filter(e => { if (e.notificationEventType == 'Notification-Event-Assets') { opData.push(e.whomToNotify) } });
    else 
      opData = [];
    
       opData.filter(e => { 
         let keys = Object.keys(e);
         for (let i = 0; i < keys.length; i++) {
           result.push({
             'name': keys[i], 'isChk': false, isShown: e[keys[i]],  rbVal: '', otherTags: keys[i] == 'Users' ? { users: [], userTags: [] } : {}
           });
         };
          
        })
      this.cnfgEvntNotfcForm.get('whomToNotify')?.setValue(result);
  }

  ngOnInit(): void {    
    this.msdStngs = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true }, this.rsnMsdStngs = { singleSelection: false, idField: 'reasonId', textField: 'reasonName', 
      selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }, 
      this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstOrdTags = s.filter(f =>
        f.badgeTypeId == environment.tagTypes.order), this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
        this.lstUsrTags = s.filter(f => f.badgeTypeId == environment.tagTypes.user),
        this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product);
    }), this.glblSvc.sbjctMstrData.subscribe(s => { this.orderStatusList = s.stsLst.filter((f: any) => f.statusType === 'Order') }), this.GetUsers();
    this.evntNtfcnLst.forEach(e => {
      this.getEvntsData(e);
      if (e.notificationEventType.toLowerCase().includes('assets'))
        this.getAsetTypeEvnts(e.id, this.asstType)
      if (e.notificationEventType.toLowerCase().includes('orders'))
        this.onTabClick('o', '5');

    })

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_nf_ac') ? (this.cnfgntfnPrmsn = e.action) : '') });
    }
    this.getWhmNtfy();
  }

  onTabClick(tabType: any, tabId: any) {
    this.tabTypeId = tabId, this.tabType = tabType, this.loadData(this.tabTypeId), this.isEdit = false, this.cnfgEvntNotfcForm.get('notificationEventId')?.setValue(''),
     this.cnfgEvntNotfcForm.get('notificationEventId')?.enable(), this.cnfgEvntNotfcForm.get('reasonId')?.enable(),
     this.cnfgEvntNotfcForm.get('statusId')?.enable(), this.cnfgEvntNotfcForm.reset();
  }

  getEvntsData(modTyp: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.ntfcnEvent, environment.apiMthds.getByModule.
      replace('modtypId', `${modTyp.id}`).replace('&assetEventType=asetType', ''), null).then(() => {
        if (this.result) {
          if (this.result.status == "OK") {
            if (modTyp.notificationEventType.toLowerCase().includes('shipments'))
              this.shipmentsLst = this.result.data != null ? this.result.data : []
            if (modTyp.notificationEventType.toLowerCase().includes('inventory'))
              this.invntryLst = this.result.data != null ? this.result.data : [];
            if (modTyp.notificationEventType.toLowerCase().includes('setup'))
              this.setupLst = this.result.data != null ? this.result.data : []
            if (modTyp.notificationEventType.toLowerCase().includes('assets'))
              this.assetsLst = this.result.data != null ? this.result.data : []
            if (modTyp.notificationEventType.toLowerCase().includes('orders'))
              this.ordersLst = this.result.data != null ? this.result.data : []
          }
        }
      });
  }

  onChng(type: any, item: any, evntType: any, data: any) {
    if (type == 'strBadge')
      if (evntType == 'os')
        this.StrTags.push(item.id)
      else if (evntType == 'osa')
      this.StrTags = [], item.filter((a: any) => this.StrTags.push(a.id));
      else if (evntType == 'ds')
        this.StrTags = this.StrTags.filter(s => s != item.id)
      else
        this.StrTags = [];
    if (type == 'ordrBadge')
      if (evntType == 'os')
        this.ordrTags.push(item.id)
      else if (evntType == 'osa')
       this.ordrTags = [], item.filter((a: any) => this.ordrTags.push(a.id));
      else if (evntType == 'ds')
        this.ordrTags = this.ordrTags.filter(s => s != item.id)
      else
        this.ordrTags = [];
    if (type == 'usrTag')
      if (evntType == 'os')
        this.usrTags.push(item.id)
      else if (evntType == 'osa')
      this.usrTags = [], item.filter((a: any) => this.usrTags.push(a.id));
      else if (evntType == 'ds')
        this.usrTags = this.usrTags.filter(s => s != item.id)
      else
        this.usrTags = [];

      if (type == 'admusrTag')
        if (evntType == 'os')
          this.admUsrTags.push(item.id)
        else if (evntType == 'osa')
        this.admUsrTags = [], item.filter((a: any) => this.admUsrTags.push(a.id));
        else if (evntType == 'ds')
          this.admUsrTags = this.admUsrTags.filter(s => s != item.id)
        else
          this.admUsrTags = [];

    if (type == 'usrName')
      if (evntType == 'os')
        this.usrNames.push(item.id)
      else if (evntType == 'osa')
      this.usrNames = [], item.filter((a: any) => this.usrNames.push(a.id));
      else if (evntType == 'ds')
        this.usrNames = this.usrNames.filter(s => s != item.id)
      else
        this.usrNames = [];
    if (type == 'matBadge')
      if (evntType == 'os')
        this.matTags.push(item.id)
      else if (evntType == 'osa')
      this.matTags = [], item.filter((a: any) => this.matTags.push(a.id));
      else if (evntType == 'ds')
        this.matTags = this.StrTags.filter(s => s != item.id)
      else
        this.matTags = [];
  }

  getAsetTypeEvnts(modTyp: any, asstType: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.ntfcnEvent, environment.apiMthds.getByModule.
      replace('modtypId', `${modTyp}`).replace('asetType', asstType), null).then(() => {
        if (this.result) {
          if (this.result.status == "OK") {
            if (asstType == 'Monitored Asset')
              this.moniteredLst = this.result.data != null ? this.result.data : [], this.asstType=='Monitored Asset';
            if (asstType == 'Monitoring Asset')
              this.monitoringLst = this.result.data != null ? this.result.data : [], this.asstType=='Monitoring Asset';
          }
        }
      })
  }

  GetUsers() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrByPrnth + this.chsnDmnId, null, 7).then(() => {
      if (this.result) {
        if (this.result.status == "OK") {
          if (this.result.data)
            this.lstUsrs = this.result.data;
        }
      }
    });

    //Admin Tags
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrTgsByPrth + this.chsnDmnId, null, 7).then(() => {
        if (this.result) {
          if (this.result.status == "OK") {
            if (this.result.data)
              this.admnTgsLst = this.result.data, console.log(this.admnTgsLst);
          }
        }
      })
  }

  getWhmNtfy() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.evntNotType, environment.apiMthds.getAll, null).then(() => {
      if (this.result) {
        if (this.result.status == "OK") {
          if (this.result.data)
            this.whmNtfy = this.result.data, this.setTabsFreq('ind', this.result.data);
        }
      }
    });
  }

  onChngOEs(evnt: any, type: string) {
    if (!this.isEdit) {
      if (type == 'e')
        $('#oeDays').val(''), $('#oeSts').val(''), this.oeDays = '', this.oeSts = '', this.ordrEvnt = evnt?.target?.value ?? '',
          $('#iePrcnt').val(''), $('#ieSts').val(''), $('#ieSds').val(''), $('#ieSct').val(''),
          $('#ieSes').val(''), this.iePrcnt = '', this.ieSts = '', this.ieSds = '', this.ieSct = '',
          this.ieSes = '', $('#ieSrc').val(''), this.ieSrc = '', this.slctdOrdStatus = '', this.ordStatus = [], this.asetType = [],
          this.ordrEvntName = this.ordersLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
      else if (type == 'd')
        this.oeDays = evnt?.target?.value ?? '', this.iePrcnt = evnt?.target?.value ?? '';
      else if (type == 'st')
        this.ieSct = evnt?.target?.value ?? '', this.ieSes = evnt?.target?.value ?? '';
      else
        this.oeSts = evnt?.target?.value ?? '', this.ieSds = evnt?.target?.value ?? '', this.ieSts = evnt?.target?.value,
          this.ieSrc = evnt?.target?.value;
    }
  }

  addNotification(addType: string) {
    if (addType == 'ordADd')
      if (this.apiRspns.some(a => (a.eventName == this.ordrEvntName && this.ordStatus.length == 0) || (a.eventName == this.ordrEvntName && this.ordStatus.includes(a.statusId)))) 
        this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.ordrEvntName + '. Please edit it or choose another event.')
      else
        this.isOrdAdd = !this.isOrdAdd;
          // this.cnfgEvntNotfcForm.reset(), 
          this.slctdordrTags = [], this.slctdFacTags = [], this.slctdUsrBadges = [], this.slctdUsrNames = [], this.setTabsFreq('ind', this.whmNtfy);;
    // this.cnfgEvntNotfcForm.get('notificationEventId')?.setValue(''),
    // this.setTabsFreq(), this.cnfgEvntNotfcForm.get('statusId')?.setValue('');
    if (addType == 'shipAdd')
      if (this.shpmntData.some(a => a.eventName == this.shpmntEvntName))
        this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.shpmntEvntName + '. Please edit it or choose another event.')
      else
        this.isAdd1 = !this.isAdd1,
          // this.cnfgEvntNotfcForm.reset(), 
          this.slctdMatTags = [], this.slctdFacTags = [],
          this.slctdUsrBadges = [], this.slctdUsrNames = [], this.setTabsFreq('ship', this.whmNtfy);
    if (addType == 'invAdd')
      if (this.InvntryData.some(a => a.eventName == this.invntryEvntName && this.slctdInvRsnLst.length == 0) || this.InvntryData.some(a => a.eventName == this.invntryEvntName && this.slctdInvRsnLst.includes(a.reasonId)))
        this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.invntryEvntName + '. Please edit it or choose another event.')
      else
        this.isAdd2 = !this.isAdd2, this.slctdMatTags = [], this.slctdFacTags = [], this.slctdUsrBadges = [], this.slctdUsrNames = [],
          this.setTabsFreq('inv', this.whmNtfy);
    if (addType == 'stupAdd')
      if (this.setUpData.some(a => a.eventName == this.suEvntName))
        this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.suEvntName + '. Please edit it or choose another event.')
      else
        this.isSUAdd = !this.isSUAdd, this.slctdFacTags = [], this.slctdUsrBadges = [], this.slctdUsrNames = [], this.setTabsFreq('stup', this.whmNtfy);
    if (addType == 'asetAdd')
      if (this.asstType == 'Monitored Asset') {
        if (this.asetsData.some(a => a.eventName == this.mndEvntName && this.asetType.length == 0) || this.asetsData.some(a => a.eventName == this.mndEvntName && this.asetType.includes(a.statusId)))
          this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.mndEvntName + '. Please edit it or choose another event.')
        else
          this.isAstAdd = !this.isAstAdd, this.slctdMatTags = [], this.slctdFacTags = [], this.slctdUsrBadges = [], this.slctdUsrNames = [],
            this.setTabsFreq('asts', this.whmNtfy);
      }
      else {
        if (this.asetsData.some(a => a.eventName == this.mngEvntName) && (this.asetsData.some(a => a.timePeriod == this.mngMins) ||
          this.asetsData.some(a => a.statusId == this.astSts)))
          this.glblSvc.onTstr('w', 'A specification already exists for event ' + this.mngEvntName + '. Please edit it or choose another event.')
        else
          this.isAstAdd = !this.isAstAdd, this.slctdMatTags = [], this.slctdFacTags = [], this.slctdUsrBadges = [], this.slctdUsrNames = [];
            this.setTabsFreq('asts', this.whmNtfy);
      }
  }

  onChngSUs(evnt: any, type: string) {
    if (type == 'e') {
      $('#suEvnt').val(''), $('#suPtrn').val(''), this.suDays = '', this.suPtrn = '', this.suEvnt = evnt?.target?.value ?? '',
        this.suEvntName = this.setupLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
        if (this.suEvnt == '32' || this.suEvnt == '33' ||  this.suEvnt == '34' || this.suEvnt == '36' || this.suEvnt == '37' || this.suEvnt == '38')
         this.isSupFacShw = false;
        else 
          this.isSupFacShw = true;
      }
    else if (type == 'd')
      this.suDays = evnt?.target?.value ?? '';
    else
      this.suPtrn = evnt?.target?.value ?? '';
  }

  onChngATs(evnt: any, type: string) {
    if (type == 't')
      this.asstType = evnt?.target?.value ?? '', this.mndEvnt = '', this.mngEvnt = '', this.asetType = [], this.selectedTypes = [], 
      this.getAsetTypeEvnts('4', this.asstType);
    else if (type == 'e') {
      if (this.asstType == 'Monitored Asset')
        $('#mndMins').val(''), $('#astSts').val(''), this.mndMins = '', this.astSts = '', this.mndEvnt = evnt?.target?.value ?? '',
        this.asetType = [], this.selectedTypes = [], this.mndEvntName = this.moniteredLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
      else
        $('#mngMins').val(''), $('#astSts').val(''), this.mngMins = '', this.astSts = '', this.mngEvnt = evnt?.target?.value ?? '',
          this.mngEvntName = this.monitoringLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
    }
    else if (type == 'm') {
      if (this.asstType == 'Monitored Asset')
        this.mndMins = evnt?.target?.value ?? '';
      else
        this.mngMins = evnt?.target?.value ?? '';
    }
    else
      this.astSts = evnt?.target?.value ?? '';
  }

  onChngSEs(evnt: any, type: string) {
    if (type == 'e')
      this.shpmntEvnt = evnt?.target?.value ?? '', this.oeSts = '', $('#oeSts').val(''),
        this.shpmntEvntName = this.shipmentsLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
    else
      this.oeSts = evnt?.target?.value ?? '',
        this.shpStatus = this.orderStatusList.filter(a => a.id == evnt?.target?.value)[0].name;
  }

  onChngIEs(evnt: any, type: string) {
    if (type == 'e')
      this.invntryEvnt = evnt?.target?.value ?? '', $('#iePrcnt').val(''),
        $('#ieSts').val(''), $('#ieSds').val(''), $('#ieSct').val(''), $('#ieSes').val(''), this.iePrcnt = '', this.ieSts = '',
        this.ieSds = '', this.ieSct = '', this.ieSes = '', $('#ieSrc').val(''), this.ieSrc = '', this.oeDays = '', this.slctdInvStsLst = [],
        this.slctdInvRsnLst = [], $('#oeDays').val(''), this.invntryEvntName = this.invntryLst.filter(a => a.id == evnt?.target?.value)[0].eventName;
    else if (type == 'd')
      this.oeDays = evnt?.target?.value ?? '', this.iePrcnt = evnt?.target?.value ?? '';
    else if (type == 'st')
      this.ieSct = evnt?.target?.value ?? '', this.ieSes = evnt?.target?.value ?? '';
    else
      this.ieSds = evnt?.target?.value ?? '', this.ieSts = evnt?.target?.value,
        this.ieSrc = evnt?.target?.value;
  }

  cancel() {
    this.ordrEvnt = '', this.oeSts = ''; this.iePrcnt = ''; this.ieSts = ''; this.ieSds = ''; this.ieSct = ''; this.ieSes = ''; this.StrTags = [],
      this.ordrTags = [], this.ieSrc = '', this.shpmntEvnt = '', this.invntryEvnt = '', this.isEvnt = false, this.selectedTypes = [], this.slctdOrdStatus = '',
      this.slctdInvRsn21 = [], this.slctdInvRsnIs = [], this.slctdInvRsn = [],
      this.matTags = [], this.slctdInvStsLst = [], this.slctdInvRsnLst = [], this.slctdMatTags = [], this.slctdFacTags = [], this.getAsetTypeEvnts('4', 'Monitored Asset')
    this.ordStatus = [], this.cnfgEvntNotfcForm.get('statusId')?.setValue('');
  }

  onSave() {
    let payLoad = this.cnfgEvntNotfcForm.getRawValue(), cnt = 0, admnCnt = 0, usrCnt = 0;
    payLoad.whomToNotify.forEach((e: any) => { if (e.isChk && e.rbVal == '') cnt++; });
    payLoad.whomToNotify.filter((a: any) => {
      if (a.name == 'Users')
        a.otherTags.userTags = this.usrTags,
          a.otherTags.users = this.usrNames;
      if (a.name == 'Administrators')
        a.otherTags.userTags = this.admUsrTags;
    });
    payLoad.whomToNotify.forEach((e: any) => { if (e.isChk && e.name == 'Administrators' && this.admUsrTags.length == 0) admnCnt++; });
    payLoad.whomToNotify.forEach((e: any) => { if ((e.isChk && e.name == 'Users') && (this.usrTags.length == 0 && this.usrNames.length == 0)) usrCnt++; });
    if (cnt > 0)
      this.glblSvc.onTstr('w', 'Frequency not specified for selected user types.');
    else if (admnCnt > 0)
      this.glblSvc.onTstr('w', 'Please select Administrator tags');
    else if (usrCnt > 0)
      this.glblSvc.onTstr('w', 'Please select user tags / Individual users');
    else {      
      payLoad.bookingBadge = this.ordrTags, payLoad.storeBadge = this.StrTags, payLoad.timePeriod = +payLoad.timePeriod,
        payLoad.notificationEventId = +payLoad.notificationEventId, payLoad.statusIds = [], payLoad.productBadge = this.matTags, 
        delete payLoad.matBadge, payLoad.reasonIds = [];
        if (this.tabTypeId == '5')
           payLoad.statusIds = this.ordStatus.length != 0 ? this.ordStatus : [];
          else if (this.tabTypeId == '4')
            payLoad.statusIds = this.asetType.length != 0 ? this.asetType : [];
          else if (this.tabTypeId == '2')
            //payLoad.statusIds = this.slctdInvStsLst.length != 0 ? this.slctdInvStsLst : [], 
            payLoad.reasonIds = this.slctdInvRsnLst.length != 0 ? this.slctdInvRsnLst : []; 
       delete payLoad.usrTags, delete payLoad.usrNames, delete payLoad.statusId, delete payLoad.reasonId;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.ntfcnEvntDetails, environment.apiMthds.saveRupdate, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && (this.result.message == 'Record saved successfully' || this.result.message == 'Record updated successfully'))
            this.glblSvc.onTstr('s', this.result.message), setTimeout(() => { window.location.reload() }, 1000);                  
          else
            this.glblSvc.onTstr('e', this.result.message), setTimeout(() => { window.location.reload() }, 1000);
        }
      });
    }
  }

  loadData(tabTypeId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.ntfcnEvntDetails, environment.apiMthds.getNtfcnEvntType.
        replace('tabType', tabTypeId), null).then(() => {
      if (this.result) {
        if (this.result.data) {
          this.result.data.forEach((e: any) => { Object.assign(e, { eventName: '', statusName: '', reasonName: '' }); });
          if (tabTypeId == '5')
            this.result.data.forEach((a: any, i: number) => {
              this.result.data[i]['eventName'] = this.ordersLst.filter(b => b.id == a.notificationEventId)[0]?.eventName, 
              this.result.data[i]['statusName'] = this.orderStatusList.filter(b => b.id == a.statusId)[0]?.name;
            });
          if (tabTypeId == '1')
            this.result.data.forEach((a: any, i: number) => {
              this.result.data[i]['eventName'] = this.shipmentsLst.filter(b => b.id == a.notificationEventId)[0]?.eventName,
                this.result.data[i]['whomToNotify'] = this.result.data[i]['whomToNotify'] != {} ? this.result.data[i]['whomToNotify'] : [];
            }), this.shpmntData = this.result.data != null ? this.result.data : [];
          if (tabTypeId == '2')
            this.result.data.forEach((a: any, i: number) => {
              this.result.data[i]['eventName'] = this.invntryLst.filter(b => b.id == a.notificationEventId)[0]?.eventName,
              this.result.data[i]['reasonName'] = this.ttlRsnsLst.filter(b => b.reasonId == a.reasonId)[0]?.reasonName
            }), this.InvntryData = this.result.data != null ? this.result.data : [];
          if (tabTypeId == '3')
            this.result.data.forEach((a: any, i: number) => {
              this.result.data[i]['eventName'] = this.setupLst.filter(b => b.id == a.notificationEventId)[0]?.eventName;
            }), this.setUpData = this.result.data != null ? this.result.data : [];
          if (tabTypeId == '4')
            this.result.data.forEach((a: any, i: number) => {
              this.result.data[i]['eventName'] = this.assetsLst.filter(b => b.id == a.notificationEventId)[0]?.eventName,
              this.result.data[i]['statusName'] = this.astTypLst.filter(b => b.id == a.statusId)[0]?.name;
            }), this.asetsData = this.result.data != null ? this.result.data : [];
          this.apiRspns = this.result.data
        }
      }
    })
  }

  onChngAsst(type: string, item: any, evntType: any) {
    if (type == 'asetTypeId') {
      if (evntType == 'os')
        this.asetType.push(item.id)
      else if (evntType == 'osa')
        this.asetType = [], this.astTypLst.filter(e => this.asetType.push(e.id))
      else if (evntType == 'ds')
        this.asetType = this.asetType.filter(s => s != item.id)
      else
        this.asetType = [];
    }
  }

  onChngSts(type: string, item: any, evntType: any) {
    if (type == 'ord') {
      if (evntType == 'os')
        this.ordStatus.push(item.id)
      else if (evntType == 'osa')
        this.ordStatus = [], this.orderStatusList.filter(e => this.ordStatus.push(e.id))
      else if (evntType == 'ds')
        this.ordStatus = this.ordStatus.filter(e => e != item.id)
      else
        this.ordStatus = [];
    }
  }

  edit(item: any) { console.log(item);
    this.cnfgEvntNotfcForm.get('notificationEventId')?.disable(), this.cnfgEvntNotfcForm.get('reasonId')?.disable(),
    this.cnfgEvntNotfcForm.get('statusId')?.disable(),
     this.ordrEvnt = item.notificationEventId, this.slctdOrdStatus = [{'id': item.statusId, 'name': item.statusName}], 
    this.ordStatus.push(item.statusId);
    if (this.tabTypeId == '4') {
      this.getAsetTypeEvnts('4', 'Monitored Asset'), this.getAsetTypeEvnts('4', 'Monitoring Asset');        
    }   
    this.slctdInvRsnLst.push(item.reasonId), this.slctdInvRsn.push({'reasonId': item?.reasonId, 'reasonName': item?.reasonName}), 
    this.selectedTypes = [{'id': item.statusId, 'name': item.statusName}], this.mngEvnt = item.notificationEventId, this.suEvnt = item.notificationEventId,
    this.ordrEvntName = item.eventName, this.oeDays = item.timePeriod, this.invntryEvnt =item.notificationEventId, this.invntryEvntName = item.eventName,
    this.suDays = item.timePeriod, this.suPtrn = item.timePeriod, this.asetType.push(item.statusId), this.mndEvnt = item.notificationEventId, this.mndMins = item.timePeriod, 
    this.mngMins = item.timePeriod;
    if (this.tabTypeId == '3') {
      if (this.suEvnt == '32' || this.suEvnt == '33' ||  this.suEvnt == '34' || this.suEvnt == '36' || this.suEvnt == '37' || this.suEvnt == '38')
        this.isSupFacShw = false;
      else 
        this.isSupFacShw = true;
    }
    let ordTag: any[] = [], slctd: any[] = [], strTag: any[] = [], slctdStrs: any[] = [], usersObj: any, userTag: any[] = [], admnObj: any,
      slctdUsrs: any[] = [], slctdUsrTag: any[] = [], usrName: any[] = [], whm: any[] = [], admnTag: any[] = [], slctdAdmnTag: any[] = [],
      matTag: any[] = [], slctdMts: any[] = [];
      this.moniteredLst.filter(e => { if (e.eventName == item.eventName) { setTimeout(() => { $("#mntrAsst").val('Monitored Asset'), this.asstType = 'Monitored Asset';
      }, 1000);  } }),
      this.monitoringLst.filter(e => { if (e.eventName == item.eventName) { $("#mntrAsst").val('Monitoring Asset'), this.asstType = 'Monitoring Asset'; } })    
    this.cnfgEvntNotfcForm.patchValue(item);
    item.bookingBadge?.forEach((e: any) => {
      ordTag = this.lstOrdTags.filter(f => f.id == e)[0], slctd.push(ordTag)
    }); this.slctdordrTags = [...slctd]; this.ordrTags = item.bookingBadge ?? [];
    item.storeBadge?.forEach((e: any) => {
      strTag = this.lstFacTags.filter(f => f.id == e)[0], slctdStrs.push(strTag)
    }); this.slctdFacTags = [...slctdStrs]; this.StrTags = item.storeBadge ?? [];

    item.productBadge?.forEach((e: any) => {
      matTag = this.lstMatTags.filter(f => f.id == e)[0], slctdMts.push(matTag)
    }); this.slctdMatTags = [...slctdMts]; this.matTags = item.storeBadge ?? [];

    this.slctdMatTags = slctdMts ?? []; 
    usersObj = item.whomToNotify.filter((p: any) => p.name == 'Users' && p.isChk)[0],
      usersObj?.otherTags.userTags.forEach((ut: any) => {
        userTag = this.lstUsrTags.filter(f => f.id == ut)[0], slctdUsrTag.push(userTag)
      }); this.slctdUsrBadges = [...slctdUsrTag]; this.usrTags = usersObj?.otherTags.userTags ?? [];
    usersObj?.otherTags.users.forEach((ut: any) => {
      usrName = this.lstUsrs.filter(f => f.id == ut)[0], slctdUsrs.push(usrName)
    }); this.slctdUsrNames = [...slctdUsrs]; this.usrNames = usersObj?.otherTags.users ?? [];
    admnObj = item.whomToNotify.filter((p: any) => p.name == 'Administrators' && p.isChk)[0],
    admnObj?.otherTags.userTags.forEach((ut: any) => {
      admnTag = this.admnTgsLst.filter(f => f.id == ut)[0], slctdAdmnTag.push(admnTag)
    }); this.slctdAdmUsrBdges = [...slctdAdmnTag]; this.admUsrTags = admnObj?.otherTags.userTags ?? [];
  }

  onCnclEdt() {
    this.cnfgEvntNotfcForm.get('notificationEventId')?.enable(), this.cnfgEvntNotfcForm.get('reasonId')?.enable(),
    this.cnfgEvntNotfcForm.get('statusId')?.enable(), this.ordrEvnt = '', this.ordrEvntName = '', this.oeDays = '', 
    this.isEdit = false, this.cnfgEvntNotfcForm.reset(), this.getWhmNtfy();
  }

  onDelNtfcn(id: any) {
    if (confirm('Are you sure, you want to delete the notification ?')) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.ntfcnEvntDetails, environment.apiMthds.delById + id, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message), this.loadData(this.tabTypeId);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onChngInvSts(item: any, evntType: any, type: any, stkType: any) {
    // if (type == 'sts') {
    //   if (evntType == 'os')
    //     this.slctdInvStsLst.push(item.id)
    //   else if (evntType == 'osa') {
    //     this.slctdInvStsLst = []; 
    //     if (stkType == 'i')
    //       this.invStsLst.filter(e => this.slctdInvStsLst.push(e.id));
    //     else if (stkType == 'in')
    //       this.invStkIn.filter(e => this.slctdInvStsLst.push(e.id));
    //     else if (stkType == 't')
    //       this.invStkTrnsfr.filter(e => this.slctdInvStsLst.push(e.id));
    //     else if (stkType == 'd')
    //       this.invDscrdStsLst.filter(e => this.slctdInvStsLst.push(e.id));
    //     else if (stkType == 'e')
    //       this.invStkCntSts.filter(e => this.slctdInvStsLst.push(e.id));
    //     else
    //       this.slctdInvStsLst = [];
    //   }        
    //   else if (evntType == 'ds')
    //     this.slctdInvStsLst = this.slctdInvStsLst.filter(e => e != item.id)
    //   else
    //     this.slctdInvStsLst = [];
    // }

    if (type == 'rsn') {
      if (evntType == 'os')
        this.slctdInvRsnLst.push(item.reasonId)
      else if (evntType == 'osa') {
        this.slctdInvRsnLst = [];
      if (stkType == 'e')
        this.rsnsLst1.filter(e => this.slctdInvRsnLst.push(e.reasonId));
      else if (stkType == 'd')
        this.rsnsLst.filter(e => this.slctdInvRsnLst.push(e.reasonId));
      else if (stkType == 'i')
        this.rsnsLst3.filter(e => this.slctdInvRsnLst.push(e.reasonId));
      else 
        this.slctdInvRsnLst = [];
      }
      else if (evntType == 'ds')
        this.slctdInvRsnLst = this.slctdInvStsLst.filter(e => e != item.reasonId)
      else
        this.slctdInvRsnLst = [];
    }
  }
}