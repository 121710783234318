import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-dashboard',
  templateUrl: './cnfg-dashboard.component.html',
  styleUrls: ['./cnfg-dashboard.component.css']
})

export class CnfgDashboardComponent extends BaseComponent implements OnInit {

  lstMatTags: any[] = []; dropdownSettings = {}; lstFacTags: any[] = []; lstTxnTyps: any[] = []; userBadges: any[] = []; slctdTSED: any[] = [];
  astTypLst: any[] = []; DfltMatTags: any[] = []; exStrTags: any[] = []; tmpStates: any[] = []; assetTyps: any[] = []; slctdEST: any[] = [];
  usrTags: any[] = []; apiRspns: any; default_monitoring_type = ''; default_monitoring_typeName = 'All';  slctdAVUT: any[] = []; slctdDAT: any[] = []; slctdDMT: any[] = [];
  usrPrmsn: any = null; cnfgDbPrmsn = true; mntredType = false; slctdActEST: any[] = []; slctdActDMT: any[] = []; DfltActMatTags: any[] = []; slctdActFclTag: any[] = [];
  fcltyCtgrys: any[] = []; asstTypeNme: any[] = []; ttlAstTpeLst: any[] = [];
  slctdFcltyLST: any[] = [];

  cnfgDshbFrm = this.formBuilder.group({
    configJson: this.formBuilder.group({
      general: this.formBuilder.group({
        default_material_tag_filter: [[]],
        exclude_store_tags: [[]],
        dashboard_visibility: [false]
      }),
      temperature: this.formBuilder.group({
        temperature_states_to_exclude_by_default: [[]]
      }),
      overview_dashboard: this.formBuilder.group({
        default_period_for_activity: ['']
      }),
      activity_dashboard: this.formBuilder.group({
        show_activity_panel: [false],
        show_revenue_panel: [false],
        show_orders_panel: [false],
        show_inventory_panel: [false],
        default_act_material_tag_filter: [[]],
        default_act_facility_tag_filter: [[]],
      }),
      date_of_actual_transaction: this.formBuilder.group({
        disable_date_of_actual_transaction: [false],
        default_transaction_type_filter: [''],
        default_store_tag_filter: [[]],
      }),
      activity_views: this.formBuilder.group({
        hide_activity_views_for_users_with_tags: [[]]
      }),
      abnormal_stock_views: this.formBuilder.group({
        default_material_tag_filter: [[]]
      }),
      asset_dashboard: this.formBuilder.group({
        default_monitoring_type: [''],
        default_asset_types: [[]],
      })
    }),
    configTypeId: [+environment.cnfgTypes.dashboard], updatedBy: [+this.usrLgnDtls?.userId]
  })

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
        this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
        this.userBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.user);
    }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstTxnTyps = s.txnTypeLst, this.ttlAstTpeLst = s.astTypLst,  s.astTypLst.filter((e: any) => { if (e.id != 1) { this.astTypLst.push(e) } }) });
  }

  ngOnInit(): void {
    this.select2ShowHide(); this.dropdowns(), this.LoadData();

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_cm_ac') ? (this.cnfgDbPrmsn = e.action) : '') });
    }
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true };
  }
 
  // chngMntrgType() {
  //   if(this.default_monitoring_type=='Monitored assets')
  //     this.mntredType= true;
  //   else
  //   this.mntredType= false;
  // }

  chngMntrgType(evnt: any) {
    this.default_monitoring_type = evnt.target.value, this.default_monitoring_typeName = evnt.target.options[evnt.target.options.selectedIndex].text, this.asstTypeNme = [], this.assetTyps= [], 
    this.slctdDAT = [];
    if (this.default_monitoring_type == '2'){
      this.mntredType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1) { this.slctdDAT.push(e), this.assetTyps.push(e.id), this.asstTypeNme.push(e.name) } });
    }   
    else if (this.default_monitoring_type == '1')
      this.mntredType = false,  this.assetTyps.push(1), this.asstTypeNme.push('Temperature Logger');
    else 
      this.mntredType = false, this.ttlAstTpeLst.filter(e => { this.asstTypeNme.push(e.name) });
  }


  onChng(type: string, item: any, evntType: any) {
    if (type == 'matTagEx') {
      if (evntType == 'os')
        this.DfltMatTags.push(item.id)
      else if (evntType == 'osa')
        this.DfltMatTags = [],item.filter((a: any) => this.DfltMatTags.push(a.id));
      else if (evntType == 'ds')
        this.DfltMatTags = this.DfltMatTags.filter(s => s != item.id)
      else
        this.DfltMatTags = [];
    }
    if (type == 'matActTagEx') {
      if (evntType == 'os')
        this.DfltActMatTags.push(item.id)
      else if (evntType == 'osa')
        this.DfltActMatTags = [],item.filter((a: any) => this.DfltActMatTags.push(a.id));
      else if (evntType == 'ds')
        this.DfltActMatTags = this.DfltActMatTags.filter(s => s != item.id)
      else
        this.DfltActMatTags = [];
    }
    if (type == 'facBadge') {
      if (evntType == 'os')
        this.exStrTags.push(item.id)
      else if (evntType == 'osa')
        this.exStrTags = [], item.filter((a: any) => this.exStrTags.push(a.id));
      else if (evntType == 'ds')
        this.exStrTags = this.exStrTags.filter(s => s != item.id)
      else
        this.exStrTags = [];
    }
    if (type == 'facActBadge') {
      if (evntType == 'os')
        this.slctdActFclTag.push(item.id)
      else if (evntType == 'osa')
        this.slctdActFclTag = [], item.filter((a: any) => this.slctdActFclTag.push(a.id));
      else if (evntType == 'ds')
        this.slctdActFclTag = this.slctdActFclTag.filter(s => s != item.id)
      else
        this.slctdActFclTag = [];
    }
    if (type == 'tmpStates') {
      if (evntType == 'os')
        this.tmpStates.push(item.id)
      else if (evntType == 'osa')
        this.tmpStates = [], item.filter((a: any) => this.tmpStates.push(a.id));
      else if (evntType == 'ds')
        this.tmpStates = this.tmpStates.filter(s => s != item.id)
      else
        this.tmpStates = [];
    }
    if (type == 'userBadge') {
      if (evntType == 'os')
        this.usrTags.push(item.id)
      else if (evntType == 'osa')
        this.usrTags = [], item.filter((a: any) => this.usrTags.push(a.id));
      else if (evntType == 'ds')
        this.usrTags = this.usrTags.filter(s => s != item.id)
      else
        this.usrTags = [];
    }
    if (type == 'assetType') {
      if (evntType == 'os')
        this.assetTyps.push(item.id);
      else if (evntType == 'osa')
        this.assetTyps = [],  item.filter((a: any) => this.assetTyps.push(a.id));
      else if (evntType == 'ds')
        this.assetTyps = this.assetTyps.filter(s => s != item.id);
      else
        this.assetTyps = [];
    }
    if (type == 'facltyBadge') {
      if (evntType == 'os')
        this.fcltyCtgrys.push(item.id)
      else if (evntType == 'osa')
        this.fcltyCtgrys = [],item.filter((a: any) => this.fcltyCtgrys.push(a.id));
      else if (evntType == 'ds')
        this.fcltyCtgrys = this.fcltyCtgrys.filter(s => s != item.id)
      else
        this.fcltyCtgrys = [];
    }
  }

  LoadData() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
      environment.cnfgTypes.dashboard, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.apiRspns = this.result.data;
              this.cnfgDshbFrm.patchValue(this.apiRspns);
              let slctd: any[] = [], matTag = [], slctd1: any[] = [], matTag1 = [], slctd2: any[] = [], matTag2 = [], slctd3: any[] = [], faclty6: any[] = [],
                matTag3 = [], slctd4: any[] = [], matTag4 = [], slctd5: any[] = [], matTag5 = [], fcltyTag = [], faclty5 : any[] = [], facltyTags= [];
              this.apiRspns.configJson.activity_views.hide_activity_views_for_users_with_tags.forEach((e: any) => {
                matTag = this.userBadges?.filter(f => f.id == e)[0], slctd.push(matTag)
              }); this.usrTags = this.apiRspns.configJson.activity_views.hide_activity_views_for_users_with_tags; this.slctdAVUT = slctd;
              // this.cnfgDshbFrm.get('configJson')?.get('activity_views')?.get('hide_activity_views_for_users_with_tags')?.setValue(slctd);

              this.apiRspns.configJson.general.default_material_tag_filter.forEach((e: any) => {
                matTag2 = this.lstMatTags?.filter(f => f.id == e)[0], slctd2.push(matTag2)
              }); this.DfltMatTags = this.apiRspns.configJson.general.default_material_tag_filter; this.slctdDMT = slctd2;
              // this.cnfgDshbFrm.get('configJson')?.get('general')?.get('default_material_tag_filter')?.setValue(slctd2);

              this.apiRspns.configJson.general.exclude_store_tags.forEach((e: any) => {
                matTag3 = this.lstFacTags?.filter(f => f.id == e)[0], slctd3.push(matTag3)
              }); this.exStrTags = this.apiRspns.configJson.general.exclude_store_tags; this.slctdEST = slctd3;
              // this.cnfgDshbFrm.get('configJson')?.get('general')?.get('exclude_store_tags')?.setValue(slctd3);

              this.apiRspns.configJson.temperature.temperature_states_to_exclude_by_default.forEach((e: any) => {
                matTag4 = this.lstTempStatus?.filter(f => f.id == e)[0], slctd4.push(matTag4)
              }); this.tmpStates = this.apiRspns.configJson.temperature.temperature_states_to_exclude_by_default; this.slctdTSED = slctd4;
              // this.cnfgDshbFrm.get('configJson')?.get('temperature')?.get('temperature_states_to_exclude_by_default')?.setValue(slctd4);

              this.apiRspns.configJson.activity_dashboard.default_act_facility_tag_filter.forEach((e: any) => {
                fcltyTag = this.lstFacTags?.filter(f => f.id == e)[0], faclty5.push(fcltyTag)
              }); this.slctdActFclTag = this.apiRspns.configJson.activity_dashboard.default_act_facility_tag_filter; this.slctdActEST = faclty5;

              this.apiRspns.configJson.date_of_actual_transaction.default_store_tag_filter.forEach((e: any) => {
                facltyTags = this.lstFacTags?.filter(f => f.id == e)[0], faclty6.push(facltyTags)
              }); this.fcltyCtgrys = this.apiRspns.configJson.date_of_actual_transaction.default_store_tag_filter; this.slctdFcltyLST = faclty6;

              this.apiRspns.configJson.activity_dashboard.default_act_material_tag_filter.forEach((e: any) => {
                matTag5 = this.lstMatTags?.filter(f => f.id == e)[0], slctd5.push(matTag5)
              }); this.DfltActMatTags = this.apiRspns.configJson.activity_dashboard.default_act_material_tag_filter; this.slctdActDMT = slctd5;

              if(this.apiRspns.configJson.asset_dashboard.default_monitoring_type == '2'){
                this.astTypLst = [];
                this.mntredType = true, this.ttlAstTpeLst.filter((e: any) => { if (e.id != 1) { this.astTypLst.push(e) } });
              }

              this.apiRspns.configJson.asset_dashboard.default_asset_types.forEach((e: any) => {
                matTag1 = this.astTypLst?.filter(f => f.id == e)[0], slctd1.push(matTag1)
              }); this.assetTyps = this.apiRspns.configJson.asset_dashboard.default_asset_types; this.slctdDAT = slctd1;
              // this.cnfgDshbFrm.get('configJson')?.get('asset_dashboard')?.get('default_asset_types')?.setValue(slctd1);
            }
          }
        }
      });
  }

  onSave() {
    let payLoad = this.cnfgDshbFrm.getRawValue();
    payLoad.configJson.general.default_material_tag_filter = this.DfltMatTags,
    payLoad.configJson.activity_dashboard.default_act_material_tag_filter = this.DfltActMatTags,
    payLoad.configJson.activity_dashboard.default_act_facility_tag_filter = this.slctdActFclTag,
    payLoad.configJson.date_of_actual_transaction.default_store_tag_filter = this.fcltyCtgrys,
      payLoad.configJson.general.exclude_store_tags = this.exStrTags,
      payLoad.configJson.temperature.temperature_states_to_exclude_by_default = this.tmpStates,
      payLoad.configJson.activity_views.hide_activity_views_for_users_with_tags = this.usrTags,
      payLoad.configJson.asset_dashboard.default_asset_types = this.assetTyps,
      payLoad.configJson.abnormal_stock_views.default_material_tag_filter = payLoad.configJson.abnormal_stock_views.
        default_material_tag_filter != '' ? +payLoad.configJson.abnormal_stock_views.default_material_tag_filter : null,
     // payLoad.configJson.date_of_actual_transaction.default_store_tag_filter = payLoad.configJson.date_of_actual_transaction.
       // default_store_tag_filter != '' ? +payLoad.configJson.date_of_actual_transaction.default_store_tag_filter : null,
      payLoad.configJson.date_of_actual_transaction.default_transaction_type_filter = payLoad.configJson.date_of_actual_transaction.
        default_transaction_type_filter != '' ? +payLoad.configJson.date_of_actual_transaction.default_transaction_type_filter : '',
      payLoad.configJson.overview_dashboard.default_period_for_activity = payLoad.configJson.overview_dashboard.
        default_period_for_activity != '' ? +payLoad.configJson.overview_dashboard.default_period_for_activity : null;
      // payLoad.configJson.activity_dashboard.default_act_material_tag_filter = payLoad.configJson.activity_dashboard.default_act_material_tag_filter != '' ?
      // +payLoad.configJson.activity_dashboard.
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message), this.LoadData();
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

}