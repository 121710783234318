<div class="row p-0 mt-2">
    <form>
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card p-0">
                        <div class="card-header p-2">
                            <h6>Domain Category</h6>
                        </div>
                        <div class="card-body p-2">
                            <small>Specify Category to identify this domain level</small>
                            <div class="row pt-3">
                                <div class="col-xl-12">
                                    <input type="text" placeholder="" value="" class="form-control" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                        <div class="card-header p-2">
                            <h6>Navigation Hierarchy</h6>
                        </div>
                        <div class="card-body p-2">
                            <small>Specify domain categories by which event summaries can be
                                navigated/drilled down in monitoring app</small>
                            <div class="row pt-3">
                                <div class="col-xl-12">
                                    <input type="text" placeholder="" value="" class="form-control" id="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-end p-3">
            <button type="button" class="btn btn-sm btn-primary">Save</button>
        </div>
    </form>
</div>