import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $:any;
@Component({
  selector: 'evin-domain-activity',
  templateUrl: './domain-activity.component.html',
  styleUrls: ['./domain-activity.component.css']
})
export class DomainActivityComponent extends BaseComponent implements OnInit {
  lstAllactivities:any;
  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.exp_collapse();this.getallactivities();
  }
  exp_collapse() {
    $('#basic').simpleTreeTable({
      expander: $('#expander'),
      collapser: $('#collapser'),
      store: 'session',
      storeKey: 'simple-tree-table-basic'
    });
    $('#open1').on('click', function () {
      $('#basic').data('simple-tree-table').openByID("1");
    });
    $('#close1').on('click', function () {
      $('#basic').data('simple-tree-table').closeByID("1");
    });
    $('#basic').simpleTreeTable({ expandable: true, initialState: 'Expand' }, true);
  }

  getallactivities() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.reportactivity, environment.apiMthds.getdomainactivitydetails, null, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllactivities = this.result.data;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }
}
