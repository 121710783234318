<div class="row p-3 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12 p-2 pt-0">
        <div class="float-start">
            <h5 class="mb-0">Fulfil the shipment: {{orderStatus?.bookingId}}-1</h5>
        </div>
        <div class="clearfix"></div>
        <hr class="mt-1 mb-1" />
        <div class="small">Please review and confirm the received quantities before fulfilling the Indent. If the
            received quantities are different from the shipped quantities indicated in the Indent, edit the
            corresponding received quantities before fulfilling the Indent.</div>
        <div class="row mt-3">
            <div class="col-xl-6 mb-3">
                <div class="card shadow-sm h-100">
                    <div class="card-header p-2">Issuing facility</div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <a>{{orderStatus?.issuingStoreName}}</a>
                                <div>{{orderStatus?.issuingStoreLocation}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 mb-3">
                <div class="card mb-4 shadow-sm h-100">
                    <div class="card-header p-2">Receiving facility</div>
                    <div class="card-body p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <a>{{orderStatus?.receivingStoreName}}</a>
                                <div>{{orderStatus?.receivingStoreLocation}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card border mt-3">
            <div class="card-header p-2">
                <h6 class="modal-title">Item</h6>
            </div>
            <div class="card-body p-2">
                <div class="table-responsive table_spacing">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">Item no.</th>
                                <th scope="col">Material</th>
                                <th scope="col" class="text-center">Ordered</th>
                                <th scope="col" class="text-center" *ngIf="ordrSts!='Shipped'">Shipped</th>
                                <th scope="col" class="text-center" *ngIf="ordrSts!='Shipped'">Fulfilled</th>
                                <th scope="col" class="text-center">Received</th>
                                <th *ngIf="orderStatus?.batchEnabled"></th>
                                <th *ngIf="orderStatus?.orderTypeId == 3">VAR/PAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of lstMats; let mi = index">
                                <td class="text-center">{{mi+1}}</td>
                                <td><a>{{item.product_name}}</a></td>
                                <td class="text-center">{{item.ordered_stock}}</td>
                                <td class="text-center" *ngIf="ordrSts!='Shipped'">{{item.shipped_stock}}</td>
                                <td class="text-center" *ngIf="ordrSts!='Shipped'">{{item.fulfilled_stock}}</td>
                                <td class="text-center" *ngIf="!item.is_batch_enabled">
                                    <!-- {{item.quantity}} -->
                                    <input type="text" (keyup)="onChng($event, mi)"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                        [ngClass]="{ 'is-invalid': ((item.quantity %item.handling_unit )!=0 && item.handling_unit !=null  )||
                                        (item.quantity *1 )==0}"
                                        class="form-control form-control-sm w-40" [value]="item.quantity">
                                        <div *ngIf="(item.quantity *1 )==0 " class="text-danger w-40">
                                            Quantity should be atleast 1
                                        </div>
                                        <div *ngIf="(item.quantity %item.handling_unit )!=0 && item.handling_unit !=null" class="text-danger w-40">
                                            Quantity should be multiple of {{item.handling_unit}}
                                        </div>
                                    <div *ngIf="item.isRsn">
                                        <select class="form-select form-select-sm mt-2 w-40" 
                                        [ngClass]="{ 'is-invalid': (item.quantity !=item.ordered_stock ) && (item.reasonId ==null || item.reasonId=='')}"
                                            (change)="onSlctBatchRR($event, mi)">
                                            <option value="">Select Reason</option>
                                            <option [value]="rs.reasonId" *ngFor="let rs of rsnsLst">{{rs.reasonName}}
                                            </option>
                                        </select>
                                        <div *ngIf="(item.quantity !=item.ordered_stock) && (item.reasonId == null || item.reasonId=='')"
                                        class="text-danger w-40">
                                            <div>Please choose Reason</div>
                                        </div>
                                        <textarea class="form-control form-control-name taRS mt-2 w-40"
                                        [ngClass]="{ 'is-invalid': (item.isRsnMand) && item.reason=='' || item.reason?.length>15 }" (keyup)="onChngRsn($event, mi)"
                                            *ngIf="item.isRsnMand" [value]="item.reason" placeholder="Enter Reason"></textarea>
                                            <div *ngIf="item.reason?.length>15"
                                                class="text-danger mt-2 w-40">
                                                    <div>Please Enter not More then 15 charecters</div>
                                                </div>
                                        <!-- rcvdRsn=='16' -->
                                    </div>
                                </td>
                                <td class="text-center" *ngIf="item.is_batch_enabled">
                                    <div class="table-responsive table_spacing mt-3">
                                        <table class="table table-striped table-sm table-bordered">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Batch</th>
                                                    <th class="text-center">Manufactured date</th>
                                                    <th class="text-center">Expiry date</th>
                                                    <th class="text-center">Shipped</th>
                                                    <th class="text-center w-31">Received</th>
                                                    <th class="text-center"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let bat of item.lstB; let mb= index">
                                                    <td class="text-center">{{bat.batch_no}}</td>
                                                    <td class="text-center">{{bat.batch_mf_date | date:
                                                        "dd-MM-yyyy"}}</td>
                                                    <td class="text-center">{{bat.batch_expiry_date | date:
                                                        "dd-MM-yyyy"}}</td>
                                                    <td class="text-center">{{bat.shipped_stock}}</td>
                                                    <td class="text-center">
                                                        <input type="text" (keyup)="onChngRcvd($event, mi, mb)"
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                            [ngClass]="{ 'is-invalid': ((bat.quantity %item.handling_unit )!=0 && item.handling_unit !=null) }"
                                                            class="form-control form-control-sm" [value]="bat.quantity">
                                                            <div *ngIf="(bat.quantity *1 )==0 " class="text-danger">
                                                                Quantity should be atleast 1
                                                            </div>
                                                            <div *ngIf="(bat.quantity %item.handling_unit )!=0 && item.handling_unit !=null" class="text-danger">
                                                                    Quantity should be multiple of {{item.handling_unit}}
                                                            </div>
                                                            <!-- [ngClass]="{ 'is-invalid':bat.isRsnMand }" -->
                                                        <div *ngIf="bat.isRsn">
                                                            <select class="form-select form-select-sm mt-2"
                                                                (change)="onSlctRR($event, mi, mb)"
                                                                [ngClass]="{ 'is-invalid': bat.isRsn && (bat.reasonId == null || bat.reasonId =='') }"
                                                                >
                                                                <option value="">Select Reason</option>
                                                                <option [value]="rs.reasonId"
                                                                    *ngFor="let rs of rsnsLst">{{rs.reasonName}}
                                                                </option>
                                                            </select>
                                                            <div *ngIf="bat.isRsn && (bat.reasonId==null || bat.reasonId =='')"
                                                            class="text-danger">
                                                                <div>Please choose Reason</div>
                                                            </div>
                                                            <textarea class="form-control form-control-name taRS mt-2"
                                                            [ngClass]="{ 'is-invalid': (bat.isRsnMand) && bat.reason==''|| bat.reason?.length>15 }" 
                                                                *ngIf="bat.isRsnMand" [value]="bat.reason" (keyup)="onChngRsnBath($event, mi, mb)"
                                                                placeholder="Enter Reason"></textarea>
                                                                <div *ngIf="bat.reason?.length>15"
                                                                class="text-danger w-40">
                                                                    <div>Please Enter not More than 15 Characters</div>
                                                                </div>
                                                            <!-- rcvdRsn=='16' -->
                                                        </div>
                                                        <!-- {{bat.quantity}} -->
                                                    </td>
                                                    <td hidden>
                                                        <a data-bs-toggle="modal" hidden
                                                            data-bs-target="#edit_modal_popup">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                                <td *ngIf="orderStatus?.orderTypeId == 3">
                                    <div class="row">
                                        <div class="col-xl-8 mt-2">
                                            <div class="form-group">
                                                <input type="file" class="form-control form-control-sm" accept="pdf" (change)="onFileSelect($event)">
                                            </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <button class="btn btn-sm btn-primary mt-2" (click)="uploadFile(item)">Upload VAR/PAR</button>
                                        </div>
                                        <div class="col-xl-6 mt-2 float-end" *ngIf="item.is_batch_enabled">
                                            <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                                (click)="openModal('v', item?.cargo_item_id)">Download VAR</button>
                                        </div>
                                        <div class="col-xl-6 mt-2 float-end" *ngIf="!item.is_batch_enabled">
                                            <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                                (click)="openModal('p', item?.cargo_item_id)">Download PAR</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <form [formGroup]="fulfillFrm">
            <div class="card border mt-3">
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row mb-2">
                                <div class="col-sm-3 mb-2">
                                    <label for="inputEmail4" class="col-form-label">Date of actual receipt
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="input-group mb-3">
                                        <input type="text" autocomplete="off" onpaste="return false"
                                            class="form-control form-control-sm optinbg bsDP-bg" readonly [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', 
                                                showWeekNumbers: false, isAnimated: true, adaptivePosition: true }"
                                            #dpDAR="bsDatepicker" formControlName="arDate" bsDatepicker
                                            onkeypress='return event.charCode == 8' [maxDate]="dt2Day">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                (click)="dpDAR.toggle()" [attr.aria-expanded]="dpDAR.isOpen"><i
                                                    class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button"
                                                title="Clear Date" (click)="dpDAR.hide();clearBSDate('arDate')"><i
                                                    class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6 mb-3 mt-4">
                                    <label>Capture Lat & Long</label>&nbsp;&nbsp;
                                    <input class="form-check-input" type="radio" name="isReqLL" value="1" (click)="isReqLL='1'"
                                        [checked]="isReqLL=='1'">&nbsp;&nbsp;<label class="form-check-label">Yes</label>&nbsp;&nbsp;
                                    <input class="form-check-input" type="radio" name="isReqLL" value="0" (click)="isReqLL='0'"
                                        [checked]="isReqLL=='0'">&nbsp;&nbsp;<label class="form-check-label">No</label><br />
                                    <!-- <div class="highTemp font-wt" *ngIf="isReqLL == '1'">Kindly provide Location access to Fullfill Indent..!</div> -->
                                </div>
                                <div class="col-xl-3 mb-2">
                                    <label for="inputEmail3" class="col-form-label">Comment</label>
                                    <textarea class="form-control form-control-sm taRS"
                                        formControlName="comment"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="float-end">
                <button type="button" class="btn btn-sm btn-primary me-2"
                    (click)="isFulfill=true;onChkLL()">Fulfill</button>
                <button type="button" class="btn btn-sm btn-light" (click)="isFulfill=false;cancel()">Cancel</button>
            </div>
        </form>
    </div>
</div>
<div class="modal fade" id="edit_modal_popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Batches of BCG (dose)</h6>
                <button type="button" class="btn btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table-responsive table_spacing mt-3">
                    <table class="table table-striped table-sm table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">Batch ID <span class="text-danger">*</span></th>
                                <th class="text-center">Manufactured date (dd/mm/yyyy)</th>
                                <th class="text-center">Manufacturer <span class="text-danger">*</span></th>
                                <th class="text-center">Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">KK123</td>
                                <td class="text-center">08/02/2021</td>
                                <td class="text-center">Biological. E Ltd</td>
                                <td class="text-center">03/05/2022</td>
                                <td>
                                    <div class="form-row mb-2">
                                        <div class="col-sm-3 mb-2">
                                            <input type="text" class="form-control form-control-sm" placeholder="" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>
                                    <button type="button" class="btn btn-sm btn-primary">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-sm btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div>