import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-rspns-tm-rpar-locations',
  templateUrl: './rspns-tm-rpar-locations.component.html',
  styleUrls: ['./rspns-tm-rpar-locations.component.css']
})
export class RspnsTmRparLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
