import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-report-filter',
  templateUrl: './report-filter.component.html',
  styleUrls: ['./report-filter.component.css']
})

export class ReportFilterComponent extends BaseComponent implements OnInit {

  prdctyType = 'Monthly'; pLSD = ''; plED = ''; isWeekChng = true; orderBadges: any[] = []; lstOrdTyps: any[] = []; shwStDrpdwn = false;
  crntDate: Date = new Date(); dpSDVal: Date = new Date(); dpEDVal: Date = new Date(); maxToDate: Date = new Date(); isDayChng = true;
  lstMatTags: any[] = []; lstFacTags: any[] = []; cntryLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = [];
  state: any; distrcit: any; block: any; producerBadges: any[] = []; userBadges: any[] = []; lstFcltys: any[] = []; material: any;
  lstAllMats: any[] = []; crntPage = 1; pageSize = environment.tblPageSize; facility: any; path = ''; txtFD = ''; txtED = ''; tab = '';
  // this.GetReqDate4mCrntWeek(1);
  rprtFltrForm = this.fb.group({ blockId: [null], city: [''], consumptionFromDate: [''], consumptionToDate: [''], consumptionType: [''],
    districtId: [null], productBadge: [''], productId: [null], receivingStoreId: [null], stateId: [null], storeBadge: [''], storeId: [null] });

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
        this.lstFacTags = s.filter(f => f.badgeTypeId == environment.tagTypes.facility),
        this.producerBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.manufacturer),
        this.userBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.user),
        this.orderBadges = s.filter((f: any) => f.badgeTypeId == environment.tagTypes.manufacturer); }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst,
        this.lstOrdTyps = s.ordTypeLst; }), this.glblSvc.sbjctStrList.subscribe(s => { this.lstFcltys = s; }),
      this.glblSvc.sbjctRprtFltr.subscribe(s => { this.path = s; });
  }

  ngOnInit(): void {
    localStorage.setItem('isPgLd', '1'), this.onSetDRs(), this.LoadMats(), this.rprtFltrForm.get('consumptionFromDate')?.
      setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd_) + '-01'), this.rprtFltrForm.get('consumptionToDate')?.
      setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ym_) + this.lstMnths[(new Date()).getMonth()].days),
      this.glblSvc.sbjctRprtTabOptns.subscribe(s => this.tab = s), this.rprtFltrForm.reset(), localStorage.removeItem('rptFltrPL');
  }

  onChngDD(evnt: any, ctrl: string) {
    let val = evnt ? evnt.target.value : null;
    if (ctrl)
      this.rprtFltrForm.get(ctrl)?.setValue(val);
  }

  onClkTbs(ctrl: string, val: any, ctrlId: string) {
    this.rprtFltrForm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
  }

  onChngTAH(type: any, event: any) {
    if (type == 'state' && event.value)
      this.stsLst.filter(a => { if (a.name == event.value) this.shwStDrpdwn = true; });
    if (type == 'store' && event.value) {
      if (this.lstFcltys.filter(f => f.storeName === event.value).length > 0)
        this.rprtFltrForm.get('storeId')?.setValue(this.lstFcltys.filter(f => f.storeName === event.value)[0].storeId);
      else
        this.rprtFltrForm.get('storeId')?.setValue(null);
    }
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter(a => a.name == evnt.target.value).length > 0;
  }

  LoadMats() {
    let crntPgIndx = this.crntPage - 1;
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getMatsByFilter.
        replace('ppp', `${this.chsnDmnId}`).replace('prdName', '').
        replace('bdgeName', '').replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productFilterModel != null ? this.result.data?.productFilterModel : []
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onSetDRs() {
    let dt = new Date(), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
    this.dpSDVal = dt2, setTimeout(() => { localStorage.setItem('isPgLd', '0'), this.pLSD = this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd),
        this.plED = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd); }, 1000);
  }

  onChngPCT(evnt: any = null) {
    $('#prdCtyClpse')?.click(), this.prdctyType = this.lstPrdCty.filter(f => f.val == evnt.target.value)[0].name;
    if (this.prdctyType == 'Weekly')
      this.isWeekChng = true;
    if (this.prdctyType == 'Daily')
      this.isDayChng = true;
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.prdctyType == 'Monthly') {
        let dtDiff = ctrl == 'sd' ? this.CheckMonthDiff(new Date(dtVal), this.dpEDVal) : this.CheckMonthDiff(this.dpSDVal, new Date(dtVal));
        if (dtDiff > 12) {
          if (ctrl == 'sd') {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() - 1));
            this.dpEDVal = dt2;
          }
          else {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
            this.dpSDVal = dt2;
          }
          this.glblSvc.onTstr('i', 'From and To periods should be in a range of 12 months. To date has been adjusted to fit within the range.');
        }
      }
      if (this.prdctyType == 'Weekly') {
        if (this.isWeekChng) {
          let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 99));
          this.dpSDVal = dt1, this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.isWeekChng = false;
        }
        /* let dtDiff = ctrl == 'sd' ? this.CheckWeeksDiff(new Date(dtVal), this.dpEDVal) : this.CheckWeeksDiff(this.dpSDVal, new Date(dtVal));
        if (dtDiff > 15 && !this.isWeekChng) {
          if (ctrl == 'sd') {
            this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.maxToDate = this.GetReqDate4mCrntWeek(1);
            if (dtDiff == 49) {
              let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 99));
              this.dpSDVal = dt1;
              console.log('dt1', dt1, this.dpSDVal)
            }
          }
          else {
            if (dtWkFnl != this.dpSDVal) {
              let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 105));
              this.dpSDVal = dt1;
              console.log('yes', dt1), setTimeout(() => { this.isWeekChng = !this.isWeekChng; }, 1000);
            }
          }
          console.log(dtDiff, this.GetReqDate4mCrntWeek(1)) // this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.maxToDate = this.GetReqDate4mCrntWeek(1)
          if (dtDiff != 49)
            this.glblSvc.onTstr('i', 'From and To periods should be in a range of 15 weeks. To date has been adjusted to fit within the range.');
        } */
      }
      if (this.prdctyType == 'Daily') {
        if (this.isDayChng) {
          let dt2Day = new Date(), dt1 = (new Date(dt2Day.setMonth(dt2Day.getMonth() - 1)));
          // [this.dpSDVal, this.dpEDVal] = [(new Date((dt2Day).setDate(dt2Day.getMonth() - 1))), (new Date())];
          this.dpSDVal = new Date(dt1.setDate(dt1.getDate() + 1)), this.dpEDVal = new Date(), this.isDayChng = false;
        }
        /* let dtDiff = ctrl == 'sd' ? this.CheckDaysDiff(new Date(dtVal), this.dpEDVal) : this.CheckDaysDiff(this.dpSDVal, new Date(dtVal));
        console.log(dtDiff)
        if (dtDiff > 31) {
          if (ctrl == 'sd') {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() - 1));
            this.dpEDVal = dt2;
          }
          else {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
            this.dpSDVal = dt2;
          }
          this.glblSvc.onTstr('i', 'From and To periods should be in a range of 31 days. To date has been adjusted to fit within the range.');
        } */
      }
    }
    let dtFrmt = this.prdctyType == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt); 
  }

  onChngDTs(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      $('#prdCtyClpse')?.click();
      let dtWk = this.GetReqDate4mCrntWeek(2), dtWkFnl = new Date(dtWk.setDate(dtWk.getDate() - 99));
      if (this.prdctyType == 'Monthly') {
        let dtDiff = ctrl == 'sd' ? this.CheckMonthDiff(new Date(dtVal), this.dpEDVal) : this.CheckMonthDiff(this.dpSDVal, new Date(dtVal));
        console.log(dtDiff)
        if (dtDiff > 12) {
          if (ctrl == 'sd') {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() - 1));
            this.dpEDVal = dt2;
          }
          else {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
            this.dpSDVal = dt2;
          }
          this.glblSvc.onTstr('i', 'From and To periods should be in a range of 12 months. To date has been adjusted to fit within the range.');
        }
      }
      if (this.prdctyType == 'Weekly') {
        let dtDiff = ctrl == 'sd' ? this.CheckWeeksDiff(new Date(dtVal), this.dpEDVal) : this.CheckWeeksDiff(this.dpSDVal, new Date(dtVal));
        if (dtDiff > 15 && !this.isWeekChng) {
          if (ctrl == 'sd') {
            this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.maxToDate = this.GetReqDate4mCrntWeek(1);
            if (dtDiff == 49) {
              let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 99));
              this.dpSDVal = dt1;
              console.log('dt1', dt1, this.dpSDVal)
            }
          }
          else {
            if (dtWkFnl != this.dpSDVal) {
              let dt = this.GetReqDate4mCrntWeek(2), dt1 = new Date(dt.setDate(dt.getDate() - 105));
              this.dpSDVal = dt1;
              console.log('yes', dt1), setTimeout(() => { this.isWeekChng = !this.isWeekChng; }, 1000);
            }
          }
          console.log(dtDiff, this.GetReqDate4mCrntWeek(1)) // this.dpEDVal = this.GetReqDate4mCrntWeek(1), this.maxToDate = this.GetReqDate4mCrntWeek(1)
          if (dtDiff != 49)
            this.glblSvc.onTstr('i', 'From and To periods should be in a range of 15 weeks. To date has been adjusted to fit within the range.');
        }
      }
      if (this.prdctyType == 'Daily') {
        if (!this.isDayChng) {
          let dt2Day = new Date();
          // [this.dpSDVal, this.dpEDVal] = [(new Date((dt2Day).setDate(dt2Day.getMonth() - 1))), (new Date())];
          this.dpSDVal = (new Date(dt2Day.setMonth(dt2Day.getMonth() - 1))), this.dpEDVal = new Date(), this.isDayChng = true;
        }
        /* let dtDiff = ctrl == 'sd' ? this.CheckDaysDiff(new Date(dtVal), this.dpEDVal) : this.CheckDaysDiff(this.dpSDVal, new Date(dtVal));
        console.log(dtDiff)
        if (dtDiff > 31) {
          if (ctrl == 'sd') {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() - 1));
            this.dpEDVal = dt2;
          }
          else {
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() - 1)), dt2 = new Date(dt1.setMonth(dt1.getMonth() + 1));
            this.dpSDVal = dt2;
          }
          this.glblSvc.onTstr('i', 'From and To periods should be in a range of 31 days. To date has been adjusted to fit within the range.');
        } */
      }
    }
  }

  onGenRprt() {
    if (this.rprtFltrForm.get('storeId')?.value || this.rprtFltrForm.get('storeBadge')?.value) {
      let formCtrls = this.rprtFltrForm.getRawValue();
      if (formCtrls.consumptionFromDate == null || formCtrls.consumptionFromDate == '' || formCtrls.consumptionFromDate == undefined)
        this.rprtFltrForm.get('consumptionFromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.rprtFltrForm.get('consumptionFromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.consumptionToDate == null || formCtrls.consumptionToDate == '' || formCtrls.consumptionToDate == undefined)
        this.rprtFltrForm.get('consumptionToDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal)
          this.rprtFltrForm.get('consumptionToDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      }
      let payLoad = this.rprtFltrForm.getRawValue();
      payLoad.pct = this.prdctyType.toLowerCase(), payLoad.fdTXT = this.txtFD, payLoad.edTXT = this.txtED,
        localStorage.setItem('rptFltrPL', JSON.stringify(payLoad)), $('#prdCtyClpse')?.click(), this.glblSvc.sbjctRprtFltr.next(this.path);
    }
    else
      this.glblSvc.onTstr('w', 'Please choose Store Tag/Store Name')
  }

}