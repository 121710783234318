<div class="card">
    <div class="card-body p-2">
        <div class="row ">
            <div class="col-xl-5 offset-xl-4 col-xs-12 text-center">
                <div class="float-end">
                    <div class="dropdown-basic">
                        <div class="dropdown">
                            <label class="mb-0 f-20" data-bs-toggle="collapse" href="#multiCollapseExample12"
                                role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><i
                                    class="fa fa-filter"></i>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 text-center">
                <h5 class="mb-0">CCE Management Status <i class="fa fa-info-circle" aria-hidden="false"
                        data-bs-toggle="tooltip" data-bs-placement="top"
                        title="% of inventory items that are likely to stock out across all Facilities in this jurisdiction. An inventory item is a material at a Facility."></i>
                </h5>
                <div class=" mt-0 mb-1 "><strong>Asset Type:</strong> {{mntrgType}}, <strong>CCE Management type:</strong> {{cceMngmntType}},
                     <strong>Facility Category: </strong> {{facltyCtgry}}, <strong>Status:</strong> All</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="collapse multi-collapse" id="multiCollapseExample12">
                    <div class="card border-0">
                        <div class="card-body p-4">
                            <form class="form theme-form" [formGroup]="cceFltrFrm" autocomplete="off">
                                <div class="row">

                                    <div class="col-xl-3 col-sm-12">
                                        <label for="mntrType">Asset Type:</label>
                                        <select class="form-select" ([ngModel])="mntrType"
                                            (change)="onMntrTypeChng($event)" formControlName="astTpe">
                                            <option value="">All</option>
                                            <option value="1">Monitoring Assets</option>
                                            <option value="2">Monitored Assets</option>
                                        </select>
                                    </div>

                                    <div class="col-xl-3 col-sm-12" *ngIf="shwAstType">
                                        <label for="asetTypeId">Monitored Asset Type:</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"
                                            formControlName="asetTypeId" (onSelect)="onChng('asetTypeId', $event, 'os')"
                                            (onSelectAll)="onChng('asetTypeId', $event, 'osa')"
                                            (onDeSelect)="onChng('asetTypeId', $event, 'ds')"
                                            (onDeSelectAll)="onChng('asetTypeId', $event, 'dsa')"
                                            [(ngModel)]="selectedTypes">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="col-xl-3 col-sm-12">
                                        <label for="stckFacCatId">Facility Category:</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings"
                                            [(ngModel)]="dfltStrTgsStk" [data]="lstFacTags" placeholder=""
                                            formControlName="stckFacCatId">
                                        </ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="row pt-2">
                                    <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                        <div class="float-end">
                                            <button class="btn btn-sm btn-primary" type="submit"
                                                (click)="LoadDonutChrt()">Filter</button> &nbsp; 
                                            <button class="btn btn-sm btn-light" data-bs-toggle="collapse"
                                                href="#multiCollapseExample12" role="button" aria-expanded="false"
                                                aria-controls="multiCollapseExample1 mb-2">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-xl-12 mt-5 text-center">
            <div id="chart-container"></div>
            <button id="btnDonut" (click)="onClkDnt()" hidden></button>
        </div>

        <div *ngIf="dsplyMap" class="col-xl-8 mt-5 mx-auto">
            <div class="text-center">
                <div class="float-end">                    
                    <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                        <li class="nav-item" title="Map View"><a class="nav-link btn btn-sm border active" id="Map-tab"
                                [hidden]="isCcpShow" data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map"
                                aria-selected="true"><i class="fa fa-globe me-0" aria-hidden="true"></i></a>
                        </li>
                        <li class="nav-item" title="List View"><a class="nav-link btn btn-sm border" id="Map-1-tab"
                                data-bs-toggle="tab" href="#Map-1" role="tab" aria-controls="Map-1"
                                aria-selected="false"><i class="fa fa-list me-0" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
                
                <div class="clearfix"></div>
                <h5 class="mb-4 float-start">{{astStatus}}</h5>
                <div class="clearfix"></div>
                <div class="tab-content mt-3" id="top-tabContent">
                    <div class="tab-pane fade show active" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                        <div class="row centered-div">
                            <div [hidden]="isHideIND">
                                <h3><label style="text-align: center;">INDIA</label></h3>
                                <div id="fc-Ind" style="padding-left: 2% !important;"></div>
                            </div>
                            <button *ngIf="!isHideIND" id="btnState" (click)="onClkState()"
                                hidden></button>
                            <div [hidden]="!isHideIND">
                                <h3><label id="lblState" style="text-align: center;"></label></h3>
                                <div id="fc-map-state-ind"></div><br>
                                <button (click)="onClkDstrct()" id="btnDistrict" hidden></button>

                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                    <h3 *ngIf="isHideIND && !isCcpShow">{{stName}}</h3>
                    <h3 *ngIf="isCcpShow">{{dstName}}</h3>
                        <div class="row">
                            <div class="float-end">
                                <button class="btn btn-sm btn-success mb-3 mt-3 float-end" (click)="exprtExl(isHideIND)"
                                *ngIf = "lstT1.length != 0 || lstT2.length != 0 || lstT3.length != 0"><i
                                        class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                            </div>
                            <div class="table_spacing table-responsive table-row-spacing">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Item No.</th>
                                            <th class="text-center" *ngIf="!isCcpShow">{{isHideIND ? 'District' :
                                                'State'}}</th>
                                            <th class="text-center" *ngIf="isCcpShow">State</th>
                                            <th class="text-center" *ngIf="isCcpShow">District</th>
                                            <th class="text-center" *ngIf="isCcpShow">Facility Name</th>
                                            <th class="text-center" *ngIf="isCcpShow">Facility Category</th>
                                            <th class="text-center" *ngIf="!isCcpShow">Value</th>
                                            <th class="text-center" *ngIf="isCcpShow">Total</th>
                                            <th class="text-center" *ngIf="isCcpShow">Count</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngFor="let item of lstT1;let i=index" [hidden]="isHideIND || isCcpShow ">
                                        <tr *ngIf="item.value != null">
                                            <td class="text-center">{{i+1}}</td>
                                            <td class="text-center pntr" (click)="onClkIndState(item);onClkState();">
                                                {{item.name}}</td>
                                            <td class="text-center">{{item.value != null ? item.value + '%' : ''}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngFor="let item of lstT2;let i=index" [hidden]="!isHideIND || isCcpShow">
                                        <tr *ngIf="item.value != null">
                                            <td class="text-center">{{i+1}}</td>
                                            <td class="text-center pntr" (click)="onClkIndDstrct(item);onClkDstrct();">
                                                {{item.name}}</td>
                                            <td class="text-center">{{item.value != null ? item.value + '%' : ''}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngFor="let item of lstT3;let i=index" [hidden]="!isCcpShow">
                                        <tr>
                                            <td class="text-center">{{i+1}}</td>
                                            <td class="text-center">{{item.stateName}}</td>
                                            <td class="text-center">{{item.districtName}}</td>
                                            <td class="text-center">{{item.storeName}}</td>
                                            <td class="text-center">{{item.storeCategory}}</td>
                                            <!-- <td class="text-center">{{item.value != null ? item.value + '%' : ''}}</td> -->
                                            <td class="text-center">{{item.totalCount}}</td>
                                            <td class="text-center">{{item.count}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstT1.length==0" [hidden]="isHideIND || isCcpShow">
                                        <tr>
                                            <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstT2.length==0" [hidden]="!isHideIND || isCcpShow">
                                        <tr>
                                            <td colspan="3" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="lstT3.length==0" [hidden]="!isCcpShow">
                                        <tr>
                                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" style="margin-top: 30px;">
                <div class="text-right">
                    <div *ngIf = "mapType == '2' && slctDmnDetails.stateId == null">
                        <button *ngIf="isHideIND && !isCcpShow" class="btn btn-sm btn-light" (click)="isHideIND=false; mapType='1'">Back</button>
                    </div>
                    <div *ngIf = "mapType == '3' && slctDmnDetails.districtId == null">
                        <button *ngIf="isCcpShow" class="btn btn-sm btn-light" (click)="isCcpShow=false; mapType='2'">Back</button>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>