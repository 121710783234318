import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.css']
})

export class MaterialsComponent extends BaseComponent implements OnInit {

  paramTab: any; prmsns: any = null; mtrlLst: boolean = true; cratMtrlBulk: boolean = true; mtrlDtl : boolean = true; mtrlEdt: boolean = true;
  mtrlDlt: boolean = true;

  constructor(public glblSvc: GlobalService,
    private route: ActivatedRoute,
    private router: Router) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
  }
  
  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_ma_li')
     this.mtrlLst = e?.action;
    if(e?.menuCode =='mm_st_ma_cm-bu')
     this.cratMtrlBulk = e?.action;
  });
  }

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}