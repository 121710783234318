import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent extends BaseComponent implements OnInit {

  frgtPswdFrm = this.fb.group({ userName: ['', Validators.required], sendOTPTo: ['', Validators.required] }); isSend = false;

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    private router: Router) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.setElmntFocus('user_id'), this.frgtPswdFrm.get('userName')?.setValue('');
  }

  get fc() { return this.frgtPswdFrm.controls; }

  onChngOTP(evnt: any) {
    this.frgtPswdFrm.get('sendOTPTo')?.setValue(evnt.target.value);
  }

  onSend() {
    this.isSend = true, this.checkFormValidations(this.frgtPswdFrm);
    if (this.frgtPswdFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill required fields');
    else {
      let fpPL = this.frgtPswdFrm.getRawValue();
      localStorage.setItem('fpPL', JSON.stringify(fpPL)), this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.api +
        environment.apiPaths.users, environment.apiMthds.resetPswd + 'forgot', fpPL).subscribe((s: any) => { this.glblSvc.sbjctSpnr.next(false);
          if (s) {
            if (s.status == 'OK')
              this.glblSvc.onTstr('s', s.message), this.router.navigate(['rest-pswd']);
            else
              this.glblSvc.onTstr('e', s.message);
          }
      });
    }
  }

}