import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-transporters-edit',
  templateUrl: './transporters-edit.component.html',
  styleUrls: ['./transporters-edit.component.css']
})

export class TransportersEditComponent  extends BaseComponent implements OnInit {

  isSbmtd = false; cntryLst: any[] = []; stsLst: any[] = []; dstLst: any[] = []; blkLst: any[] = []; trnsprterEditForm =
  this.formBuilder.group({ address: [null], block: [null], carrierName: [null, Validators.required], city: [null], carrierType: ['owner'],
  contactNo: [null, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]], country: [null, Validators.required],
  description: [null], district: [null], state: [null, Validators.required], pincode: [null], vehicleDetails: [null] }); usrLvl = '';
  cntryId: any = ''; trnspterId: any; trnspterDetails: any = {}; pranthId:any; carrType = '';

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) { 
    super(glblSvc); this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst; });
  }

  ngOnInit(): void {
    this.glblSvc.sbjctTrnsId.subscribe(s => { this.trnspterId = s;
      if (this.trnspterId > 0)
        this.getTrnspterById(this.trnspterId)
      else
        this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } })
    }),
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
  }

  getTrnspterById(trnspterId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.carrier, environment.apiMthds.trnspterById + trnspterId, null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.trnspterDetails = this.result.data != null ? this.result.data : [];
          this.trnsprterEditForm.patchValue({ address: this.trnspterDetails?.address, carrierName: this.trnspterDetails?.carrierName,
            city: this.trnspterDetails?.city ?? null, contactNo: this.trnspterDetails?.contactNo, description:
            this.trnspterDetails?.description, pincode: this.trnspterDetails?.pinCode,
            vehicleDetails: this.trnspterDetails?.vehicleDetails ?? null, updatedBy: this.trnspterDetails?.updatedBy,
            createdBy: this.trnspterDetails?.createdBy, state: +this.trnspterDetails?.stateId, country: +this.trnspterDetails?.country ?? '431',
            district: +this.trnspterDetails?.districtId, block: +this.trnspterDetails?.blockId, carrierType: this.trnspterDetails?.carrierType,
          }), this.carrType = this.trnspterDetails?.carrierType ?? '';
          if (this.usrLgnDtls) {
            let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData'))?.cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
              usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
            this.usrLvl = usrLvlDtls?.userLevel ?? '', this.trnsprterEditForm.get('country')?.setValue(cntryId);
            if (this.trnspterDetails) {
              if (this.trnspterDetails?.stateId != null && this.trnspterDetails?.districtId == null && this.trnspterDetails?.blockId == null)
                this.trnsprterEditForm.get('state')?.setValue(+this.trnspterDetails?.stateId ?? 'null'), this.trnsprterEditForm.get('district')?.setValue('null'),
                  this.onLocChng(+this.trnspterDetails?.stateId ?? null, 'state', true);
              if (this.trnspterDetails?.stateId != null && this.trnspterDetails?.districtId != null && this.trnspterDetails?.blockId == null) {
                this.trnsprterEditForm.get('state')?.setValue(+this.trnspterDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.trnspterDetails?.stateId ?? null,
                  'state', true), this.onLocChng(+this.trnspterDetails?.districtId ?? null, 'district', true)]).then(() => {
                    this.trnsprterEditForm.get('district')?.setValue(+this.trnspterDetails?.districtId ?? 'null'),
                      setTimeout(() => { this.trnsprterEditForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
                  });
              }
              if (this.trnspterDetails?.stateId != null && this.trnspterDetails?.districtId != null && this.trnspterDetails?.blockId != null) {
                let blockID = +this.trnspterDetails?.blockId;
                Promise.all([this.onLocChng(+this.trnspterDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.trnspterDetails?.districtId ?? null,
                  'district', true)]).then(() => {
                    this.trnsprterEditForm.get('state')?.setValue(this.trnspterDetails?.stateId ?? null),
                      this.trnsprterEditForm.get('district')?.setValue(this.trnspterDetails?.districtId ?? 'null'), this.trnsprterEditForm.get('block')?.setValue(blockID ?? null),
                      setTimeout(() => { this.trnsprterEditForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
                  });
              }
              if (this.trnspterDetails?.stateId == null && this.trnspterDetails?.districtId == null && this.trnspterDetails?.blockId == null)
                this.trnsprterEditForm.get('state')?.setValue('null'), this.trnsprterEditForm.get('district')?.setValue('null'),
                this.trnsprterEditForm.get('block')?.setValue('null');
            }
          }
          setTimeout(() => { this.onAS() }, 1000);
        }
      }
    });
  }

  optChange(e: any) {

  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.trnsprterEditForm.get('district')?.setValue(null),
          this.trnsprterEditForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.trnsprterEditForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.trnsprterEditForm.get('district')?.enable(),
                  this.trnsprterEditForm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.trnsprterEditForm.get('block')?.enable();
                // console.log('block list', this.blkLst);
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.trnsprterEditForm.get('district')?.setValue(null), this.trnsprterEditForm.get('block')?.setValue('null'),
          this.trnsprterEditForm.get('district')?.disable(), this.trnsprterEditForm.get('block')?.disable();
      else
        this.trnsprterEditForm.get('block')?.setValue('null'), this.trnsprterEditForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.trnsprterEditForm.get('state')?.enable(), this.trnsprterEditForm.get('district')?.enable(), this.trnsprterEditForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.trnsprterEditForm.get('state')?.disable(), this.trnsprterEditForm.get('district')?.enable(), this.trnsprterEditForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.trnsprterEditForm.get('state')?.disable(), this.trnsprterEditForm.get('district')?.disable(), this.trnsprterEditForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.trnsprterEditForm.get('state')?.disable(), this.trnsprterEditForm.get('district')?.disable(), this.trnsprterEditForm.get('block')?.disable();
  }

  get fc() { return this.trnsprterEditForm.controls; }

  onUpdateTransporter() {
    this.checkFormValidations(this.trnsprterEditForm);
    if (this.trnsprterEditForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.trnsprterEditForm.getRawValue(); payLoad.id = this.trnspterId,
      payLoad.block = (payLoad.block != null && payLoad.block != '') ? +payLoad.block : null, payLoad.country = +payLoad.country,
      payLoad.state = payLoad.state != null ? +payLoad.state : null, payLoad.district = (payLoad.district != null && 
      payLoad.district != '') ? +payLoad.district : null, payLoad.updatedBy = this.usrLgnDtls.userId, payLoad.createdBy = payLoad.updatedBy,
      // payLoad.createdOn = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, payLoad.updatedOn = payLoad.createdOn,
      payLoad.carrierType = payLoad.carrierType != '' && payLoad.carrierType != null ? payLoad.carrierType : null,
      payLoad.address = payLoad.address != '' && payLoad.address != null ? payLoad.address : null,
      payLoad.city = payLoad.city != '' && payLoad.city != null ? payLoad.city : null,
      payLoad.contactNo = payLoad.contactNo != '' && payLoad.contactNo != null ? payLoad.contactNo : null,
      payLoad.description = payLoad.description != '' && payLoad.description != null ? payLoad.description : null,
      payLoad.pincode = payLoad.pincode != '' && payLoad.pincode != null ? payLoad.pincode : null,
      payLoad.vehicleDetails = payLoad.vehicleDetails != '' && payLoad.vehicleDetails != null ? payLoad.vehicleDetails : null,
      payLoad.carrierName = payLoad.carrierName != '' && payLoad.carrierName != null ? payLoad.carrierName : null,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.carrier, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            localStorage.setItem('trnsprtrCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message),
              this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onCancel() {
    this.trnsprterEditForm.reset(), this.isSbmtd = false, this.trnsprterEditForm.get('country')?.setValue(this.cntryId),
    this.trnsprterEditForm.get('country')?.disable(), 
    this.router.navigate(['home/setup-transporters'], { queryParams: { tab: 'list' } });
  }

}
