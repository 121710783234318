<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0 mb-2">
            <div class="card-body p-2 border-0">
                <form>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">Facility</label>
                                    <input name="store" type="text" class="form-control" placeholder="Filter by Facilities" />
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <input name="store" type="text" class="form-control"
                                        placeholder="Filter by Material" id="material">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">AbnormalityType</label>
                                    <select class="form-select">
                                        <option value="">-Select-</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-12 pt_2 text-end">
                                <button class="btn btn-primary" data-toggle="collapse" href="#box1"
                                    aria-expanded="false" aria-controls="box1">Advance Search</button>
                            </div>
                        </div>
                        <div id="box1" class="collapse">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">Expires before</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" autocomplete="off"
                                            placeholder="Filter by Expires before">
                                        <!-- <div class="input-group-append">
                                            <button class="btn btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                [attr.aria-expanded]="dpED.isOpen">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpED.hide();clearBSDate('expireBefore')">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Batch</label>
                                    <input name="batch" type="text" class="form-control" placeholder="Filter by Batch"
                                        id="batch" />
                                </div>
                            </div>
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">State</label>
                                    <select  class="form-control">
                                        <option value="null">-Select-</option>
                                        <option></option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">District</label>
                                    <select  class="form-control">
                                        <option value="null">-Select-</option>
                                        <option></option>
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Taluk/Block</label>
                                    <select  class="form-control">
                                        <option value="null">-Select-</option>
                                        <option></option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5">
                                    <span class="pl-xl-3">Facility tags : <span
                                            class="badge badge-pink mb-0">GMSD</span></span>&nbsp;<span
                                        class="badge badge-pink mb-0">SVS</span>&nbsp;<span
                                        class="badge badge-pink mb-0">RVS</span>
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExamplef1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExamplef1"><i
                                            class="fa fa-filter"></i></span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExamplef1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <label class="col-form-label">Include Category(s)</label>
                                                                <div >
                                                                    <select class="form-control">
                                                                        <option value="">All</option>
                                                                    </select>
                                                                    <!-- <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstFacTags"
                                                                        formControlName="includeStoreBadge"
                                                                        (onSelect)="onItemSelect('inf', $event)"
                                                                        (onSelectAll)="onSelectAll('inf', $event)">
                                                                    </ng-multiselect-dropdown> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="">
                                                                <label class="col-form-label">Exclude Category(s)</label>
                                                                <div >
                                                                    <select class="form-control">
                                                                        <option value="">All</option>
                                                                    </select>
                                                                    <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                [data]="lstFacTags" formControlName="excludeStoreBadge"
                                                                (onSelect)="onItemSelect('exf', $event)"
                                                                (onSelectAll)="onSelectAll('exf', $event)">
                                                                </ng-multiselect-dropdown> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    type="button">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    type="submit">Save</button>
                                                                <button class="btn btn-sm btn-light" role="button"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1"
                                                                    href="#multiCollapseExamplef1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2">Filter</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-body border-0 pb-2">
                <div class="row">
                    <div class="col-xl-4">
                        <!-- <button class="btn btn-primary">Export</button> -->
                    </div>
                    <div class="col-xl-8"></div>
                    <hr class="mt-1">
                </div>
                <div class="row">
                    <div class="col-xl-4">
                        <!-- <span style="display: inline-block;">
                            <label class="me-2">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                        <!-- <span style="display: inline-block;">
                            <button type="button" class="btn btn-sm btn-primary ms-2" data-bs-toggle="modal"
                                data-bs-target="#modal_export1"> <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                                Export
                            </button>
                        </span> -->
                    </div>
                    <div class="col-xl-4">
                        <!-- <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                            <li class="">
                                <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                    href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                    aria-selected="true"><i class="fa fa-table me-0" aria-hidden="true"></i>
                                    List view</a>
                            </li>
                            <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                    href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                    <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                            </li>
                        </ul> -->
                    </div>
                    <div class="col-xl-2 col-lg-2 mb-2 text-end" *ngIf="lstGrid.length!=0">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-2" *ngIf="lstGrid.length!=0">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="pills-clrtabContentinfo">
                    <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                        aria-labelledby="pills-tabview-tabinfo">
                        <div class="table_spacing table-responsive mt-3">
                            <table class="table table-striped table-sm table-bordered table1">
                                <thead>
                                    <tr>
                                        <th>Item No.</th>
                                        <th>Facility</th>
                                        <th>Total stock</th>
                                        <th></th>
                                        <th class="text-center"> Allocated</th>
                                        <th class="text-center">Available</th>
                                        <th>In-transit </th>
                                        <th>Min.</th>
                                        <th>Max.</th>
                                        <th>Last updated</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGrid?.length!=0">
                                    <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems};let i = index"
                                        [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                            'color': item.stockIndicator != null ? '#fff' : null }">
                                        <td>{{i+1}}</td>
                                        <td>{{item.storeName}}</td>
                                        <td>{{item.totalStock}}</td>
                                        <td>
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }"
                                                title="Batch Details" class="expand-row toggle-data" *ngIf="item.batchEnabled">
                                                <i class="fa fa-list-alt"></i>
                                            </a>
                                        </td>
                                        <td>{{item.allocatedStock}}</td>
                                        <!-- <td title="Allocated Stock Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')" class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.allocatedStock}}&nbsp;<i class="fa fa-caret-up"></i>
                                            </a>
                                        </td> -->
                                        <td>{{item.availableStock}}</td>
                                        <td>{{item.intransitStock}}</td>
                                        <!-- <td title="In-transit Stock Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')" class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.intransitStock}}&nbsp;<i class="fa fa-caret-up"></i>
                                            </a>
                                        </td> -->
                                        <td title="Min-Max Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')" class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.minStock}}&nbsp;<i class="fa fa-caret-up"></i>
                                            </a>
                                        </td>
                                        <td title="Min-Max Details">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')" class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.maxStock}}&nbsp;<i class="fa fa-caret-up"></i>
                                            </a>
                                        </td>
                                        <td>{{item.updatedOn | date: "dd-MM-yyyy hh:mm aa"}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="lstGrid.length==0">
                                    <tr>
                                        <td colspan="10" class="text-center">
                                            {{noRcrdsTxt}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class=" pb-3 pt-3">
                            <div class="row">
                                <div class="col-lg-8 col-xxl-8">
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                        class="clrCde p-1 me-2">Zero Stock</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                        class="clrCde p-1 me-2">Min</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                        class="clrCde p-1 me-2">Max</span>
                                </div>
                                <div class="col-xl-2 mb-2 text-end" *ngIf="lstGrid.length!=0">
                                    {{pageEntrs}}
                                </div>
                                <div class="col-lg-2 col-xxl-4" *ngIf="lstGrid.length!=0">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="text-end mt-2 col-xl-12">
                        <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>