import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-temp-excursions',
  templateUrl: './temp-excursions.component.html',
  styleUrls: ['./temp-excursions.component.css']
})

export class TempExcursionsComponent extends BaseComponent implements OnInit {

  isTypeBdge = 'badge'; isFacBdge = 'badge'; lstMatTags: any[] = []; lstFacTags: any[] = []; lstFcltys: any[] = []; lstSlctdFacTags: any[] = [];
  isSbmtd = false; lstStrs: any[] = []; isManfterBdge = 'badge'; lstDts: any[] = []; lstDDts: any[] = []; lstGridDate: any[] = [];
  lstSlctdMatTags: any[] = []; lstSlctdStores: any[] = []; lstFacIds: any[] = []; slctdFac: any[] = []; slctdFacId: any[] = []; tab = 'o';
  stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; lstTxnTyps: any[] = []; shwStDrpdwn = false; metrcVal: any = '0'; plED = '';
  slctdStateId: any; slctdDistrictId: any; slctdBlockId: any; lstMAT: any[] = []; lstFAC: any[] = []; lstGrid: any[] = []; pLSD = '';
  isDtCng: string = ''; pstT = 'Monthly'; dpSDVal: any = new Date(); mandatryDate: any =(new Date()).getFullYear();
  dpEDVal: any = new Date(); isMnthChng = false; isDayChng = false; txtFD = ''; txtED = ''; pstType = 'monthly'; lstDataDDL: any[] = [];
  maxToDate: Date = new Date(); usrLvl = ''; crntDate: Date = new Date(); lstX: any[] = []; lstY: any[] = []; lstTxn: any[] = []; mBDate: any;
  tempExcusonForm: FormGroup = this.fb.group({}); frmDate: any = ''; tooDate: any = ''; frmDates: any = ''; tooDates: any = ''; lstFacsTag: any;
  noRcrdTxt = environment.TblNoRcrds; ttlCount = 0; yBDate: any; lstFacsName: any; lstDX: any[] = []; lstDY: any[] = []; astTypLst: any[] = [];
  dt2days = new Date(); manfter: any; astType: any = '2'; manfterLst: any[] = []; modelsLst: any[] = []; isManfterName: any = null; 
  isModel: any = null;  montrngType: any = '0'; excsonType: any = '0'; dropdownSettings = { singleSelection: false,
    idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; ddlStngs = {
    singleSelection: false, idField: 'storeId', textField: 'storeName', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3,
    allowSearchFilter: true }; crntPage = 1; pageEntrs = ''; pageSize = environment.tblPageSize; totalItems: any; yrMin : any; ttlItms: any;
    lblTextOfDate:any = 'Max 12 months will be accepted'; ttlDayItms: any; crntDPage = 1; pageEntrsD = ''; isShwDayTable: boolean=  false;
    isRprtsHlpShw = environment.isRprtsHlpShw; steRes: string = ''; dstRes: string = ''; steResDly: string = ''; dstResDly: string = '';
    expDrpn: any = '1'; expDrpnDly: any = '1'; isWekChng = false; ttlCnt: any[] = []; ttlCntDly: any[] = []; durtn: any = 0; lstCcpAll: any[] = [];
    isFclty: any[] = []; lstSlctdFclty: any[] = [];showExportOption:boolean=true;

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility) }),
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacIds = s; }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst,
      this.lstTxnTyps = s.txnTypeLst, this.astTypLst = s.astTypLst });
  }

  ngOnInit(): void {
    this.tempExcusonForm = this.fb.group({ isFacBdge: ['badge'], storeTags: [[], Validators.required], storeIds: [[], Validators.required],
      isManfterBdge: ['badge'], pstT: ['Monthly'], block: ['null'], district: [null], state: [null], dpSDVal: ['', Validators.required],
      dpEDVal: ['', Validators.required], excursionType: [], metricId: [], fromDate: [''], toDate: [''], isTypeBdge: ['badge'], modelId: [null],
      manufacturerId: [null], assetType: [], monitoringType: [], excursionCriteria: [], facilityIds: [[]] }), this.LoadManufacturers(),
      this.LoadModels(),this.LoadCCPs(), localStorage.setItem('isPgLd', '0');
      //manufacturerYear: ['', [Validators.required, Validators.min(environment.yomSY), Validators.max((new Date()). getFullYear())]] 
        
        this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.tempExcusonForm.get('country')?.setValue(cntryId);
    } this.dmnLocation(); setTimeout(() => { this.onAS(); }, 1000);
  }

  get fc() { return this.tempExcusonForm.controls };
  get fgrv() { return this.tempExcusonForm.getRawValue(); };

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.tempExcusonForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.tempExcusonForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.tempExcusonForm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.tempExcusonForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.tempExcusonForm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.tempExcusonForm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => { this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.tempExcusonForm.get('state')?.setValue('null'), this.tempExcusonForm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onChngDT(dtVal: any, ctrl: string) {
    if (localStorage.getItem('isPgLd') == '0') {
      if (this.pstT == 'Monthly') {
        if (this.isMnthChng) {
          dtVal ='', this.dpEDVal ='',  
          this.tempExcusonForm.get('fromDate')?.setValue(''), this.tempExcusonForm.get('toDate')?.setValue(''), this.tempExcusonForm.get('dpSDVal')?.setValue(''),
          this.tempExcusonForm.get('dpEDVal')?.setValue('');
          this.isMnthChng = false;
        }
        else{
          if(ctrl == 'sd' && dtVal !=null){
            this.tempExcusonForm.get('dpSDVal')?.setValue(dtVal);
            let dt = new Date(dtVal), dt1 = new Date(dt.setFullYear(dt.getFullYear() + 1)); this.yBDate = new Date(dt1.setMonth(dt1.getMonth() -1));
            this.dpSDVal  = dtVal;  this.yrMin = dtVal;
           this.tempExcusonForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.MY));
           let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(this.yBDate, this.dtF.ymd));
           if (exDate < toDayDate )
           this.yBDate;
           else
             this.yBDate = new Date(this.crntDate);
             
            this.tempExcusonForm.get('dpEDVal')?.setValue('');
          }
          else if(ctrl == 'ed' && dtVal !=null){
            this.dpEDVal = dtVal;
           this.tempExcusonForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
           this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.MY));
          }
        }
      }
      if (this.pstT == 'Weekly') {
        if (this.isWekChng) {
          dtVal='', this.dpEDVal = '', this.dpSDVal= '';
          this.tempExcusonForm.get('fromDate')?.setValue(''), this.tempExcusonForm.get('toDate')?.setValue(''), this.tempExcusonForm.get('dpSDVal')?.setValue(''),
          this.tempExcusonForm.get('dpEDVal')?.setValue('');
          this.isWekChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal = '';
          this.tempExcusonForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.tempExcusonForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 41));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.tempExcusonForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.tempExcusonForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));

         }
        }
      }
      if (this.pstT == 'Daily') {
        if (this.isDayChng) {
          dtVal='', this.dpEDVal ='', this.dpSDVal='';
          this.tempExcusonForm.get('fromDate')?.setValue(''), this.tempExcusonForm.get('toDate')?.setValue(''), this.tempExcusonForm.get('dpSDVal')?.setValue(''),
          this.tempExcusonForm.get('dpEDVal')?.setValue('');
          this.isDayChng = false;
        }
        else {
         if(ctrl == 'sd' && dtVal !=null){
          this.dpEDVal ='';
          this.tempExcusonForm.get('dpSDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
          let edt = new Date(this.tempExcusonForm.get('dpSDVal')?.value), md = new Date(edt.setDate(edt.getDate() + 30));
          let toDayDate = Date.parse( this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd)); let exDate = Date.parse(this.glblSvc.setDtFrmt(md, this.dtF.ymd));
          if (exDate < toDayDate )
          this.maxToDate = new Date(md);
          else
            this.maxToDate = new Date(this.crntDate);

            this.tempExcusonForm.get('dpEDVal')?.setValue('');
         }
         else if(ctrl == 'ed' && dtVal !=null){
           this.tempExcusonForm.get('dpEDVal')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd)),
           this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(dtVal, this.dtF.ymd));
         }
        }
      }
    }
    let dtFrmt = this.pstT == 'Monthly' ? this.dtF.MY : this.dtF.DMY_;
    this.txtFD = this.glblSvc.setDtFrmt(this.dpSDVal, dtFrmt), this.txtED = this.glblSvc.setDtFrmt(this.dpEDVal, dtFrmt);
  }

  onTabClk(tabType: string) {
    this.tab = tabType, this.lstGrid = [], this.lstGridDate = [], this.crntPage=1, this.crntDPage = 1, this.GenRprt(); this.isSbmtd = true;
  }

  LoadCCPs() {
    let frmCtrls = this.tempExcusonForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state,  
    districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district ?? 0, blockId: (frmCtrls.block == null || frmCtrls.block == 'null') ? 0 : 
      +frmCtrls.block}; this.lstCcpAll = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.store, 
          environment.apiMthds.strByLocns, payLoad, 0, true, 2).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstCcpAll = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  setPage(pagNo: number, type: any): void {
    if ( type == 'dm') 
      this.crntPage = pagNo, this.GenRprt();
    if ( type == 'dd')
      this.crntDPage = pagNo, this.GetDailyData('', 'g');
  }

  onChngState(evnt: any) {
    if (evnt)
      this.shwStDrpdwn = this.stsLst.filter((a: { name: any; }) => a.name == evnt.target.value).length > 0;
  }

  onClkRdbs(ctrl: string, val: any, ctrlId: string) {
    this.tempExcusonForm.get(ctrl)?.setValue(val), setTimeout(() => { $(`#${ctrlId}`).val(''); }, 200);
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if(slctdId=="null"){
      this.showExportOption= true
    }
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [],this.showExportOption= true, this.blkLst = [], this.tempExcusonForm.get('district')?.setValue(null),
          this.tempExcusonForm.get('block')?.setValue(null);
      else
        did = slctdId, this.showExportOption= slctdId=="null"?true:false, this.blkLst = [], this.tempExcusonForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.tempExcusonForm.get('district')?.enable(),
                  this.tempExcusonForm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.tempExcusonForm.get('block')?.enable();
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.tempExcusonForm.get('district')?.setValue('null'), this.tempExcusonForm.get('block')?.setValue('null'),
          this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('block')?.disable();
      else
        this.tempExcusonForm.get('block')?.setValue('null'), this.tempExcusonForm.get('block')?.disable();
    }
    type == 'district' ? this.LoadCCPs() : '';
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.tempExcusonForm.get('state')?.enable(), this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.tempExcusonForm.get('state')?.disable(), this.tempExcusonForm.get('district')?.enable(), this.tempExcusonForm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.tempExcusonForm.get('state')?.disable(), this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.tempExcusonForm.get('state')?.disable(), this.tempExcusonForm.get('district')?.disable(), this.tempExcusonForm.get('block')?.disable();
  }

  onChngPstT() {
    this.lblTextOfDate = this.pstT=='Monthly' ? 'Max 12 months will be accepted' : (this.pstT=='Daily' ? 
    'Max 31 days will be accepted': 'Max 42 days will be accepted');
    this.tempExcusonForm.get('dpSDVal')?.setValue(''); this.pstType = this.pstT.toLowerCase(), this.isMnthChng = this.pstT == 'Monthly',
    this.isWekChng = this.pstT == 'Weekly', this.isDayChng = this.pstT == 'Daily', this.dpSDVal='', this.dpEDVal='';
  }

  onExcrsnChng() {
    this.excsonType == '0' ? (this.durtn = null, this.tempExcusonForm?.get('excursionCriteria')?.setValue(this.durtn)) : 
      (this.durtn = '0', this.tempExcusonForm?.get('excursionCriteria')?.setValue(this.durtn));
    this.metrcVal = '0', this.tempExcusonForm?.get('metricId')?.setValue(this.metrcVal);
  }

  GenRprt(type: string = 'g') {
    this.checkFormValidations(this.tempExcusonForm); let formCtrls = this.tempExcusonForm.getRawValue();
    if ((this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.dpSDVal.value != '' && this.fc.dpEDVal.value != '') {
      if (formCtrls.fromDate == null || formCtrls.fromDate == '' || formCtrls.fromDate == undefined)
        this.tempExcusonForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      else {
        if (this.dpSDVal)
          this.tempExcusonForm.get('fromDate')?.setValue(this.glblSvc.setDtFrmt(this.dpSDVal, this.dtF.ymd));
      }
      if (formCtrls.toDate == null || formCtrls.toDate == '' || formCtrls.toDate == undefined)
        this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
      else {
        if (this.dpEDVal && this.pstT == 'Monthly') {
          if (this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd_) == this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd_)) {
            this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.crntDate, this.dtF.ymd));
          }
          else {
            let dt = this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd), slctDte: any = dt.split('-');
            let dts = this.GetMonthDates(+slctDte[1], +slctDte[0]), dtdts = dts.split('...');
            this.tempExcusonForm.get('toDate')?.setValue(dtdts[1]);
          }
        } else {
          this.tempExcusonForm.get('toDate')?.setValue(this.glblSvc.setDtFrmt(this.dpEDVal, this.dtF.ymd));
        }
      }
      let payLoad: any = this.tempExcusonForm.getRawValue(), fT: any[] = [], sN: any[] = [], fTs: any[] = [], lstFacsTags: any[] = [], lstFacsNames: any[] = [];
      this.isDtCng = this.pstT; this.frmDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt(new Date(payLoad.fromDate), this.dtF.MY)) : 
      (this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.dmy))), this.tooDate = (this.pstT == 'Monthly' ? (this.glblSvc.setDtFrmt
      (new Date(payLoad.toDate), this.dtF.MY)) : (this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.dmy))),
      this.lstSlctdFacTags?.forEach((e: any) => { lstFacsTags.push(e.name) }), this.lstFacsTag = lstFacsTags;
      this.lstSlctdStores?.forEach((e: any) => { lstFacsNames.push(e.storeName) }), this.lstFacsName = lstFacsNames;
      payLoad.fromDate = payLoad.fromDate !='' ? payLoad.fromDate : null, payLoad.toDate = payLoad.toDate !='' ? payLoad.toDate : null,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.metricId = (payLoad.metricId != '' && payLoad.metricId != null && payLoad.metricId != undefined) ? +payLoad.metricId : null,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.manufacturerId = (payLoad.manufacturerId != '' &&
      payLoad.manufacturerId != null && payLoad.manufacturerId != undefined) ? +payLoad.manufacturerId : null, payLoad.facilityIds =
        (payLoad.facilityIds != '' && payLoad.facilityIds != null && payLoad.facilityIds != undefined && this.tab == 'f' &&
        (payLoad.district != 'null' || payLoad.block != 'null')) ? this.lstSlctdFclty?.forEach((e: any) => { fTs.push(e.storeId) }) : [], 
        payLoad.facilityIds = fTs, payLoad.excursionCriteria = (payLoad.excursionCriteria != '' && payLoad.excursionCriteria != null &&
        payLoad.excursionCriteria != undefined) ? +payLoad.excursionCriteria : null, payLoad.modelId = (payLoad.modelId != '' && 
        payLoad.modelId != null && payLoad.modelId != undefined) ? +payLoad.modelId : null, payLoad.assetType =
        (payLoad.assetType != '' && payLoad.assetType != null && payLoad.assetType != undefined) ? +payLoad.assetType : null,
      payLoad.monitoringType = (payLoad.monitoringType != '' && payLoad.monitoringType != null && payLoad.monitoringType != undefined) ? +payLoad.monitoringType : null,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.excursionType = 
        (payLoad.excursionType != '' && payLoad.excursionType != null && payLoad.excursionType != undefined) ? +payLoad.excursionType : null,
      payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [],
      payLoad.storeIds = sN, payLoad.email =this.usrLgnDtls?.email, ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isTypeBdge',
        'isFacBdge', 'isManfterBdge'].forEach(e => { delete payLoad[e] }); //, this.tab == 'f' ? payLoad.exportLevel = this.expDrpn : '';
      if (type == 'g')
        this.isShwDayTable = false, this.onGen(payLoad);
     else
      this.tab == 'f' ? (payLoad.exportLevel = this.expDrpn !='' ? this.expDrpn : '') : '', this.onEE(payLoad);
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onGen(payLoad: any) {
    this.lstGrid = [], this.ttlCnt = [], this.ttlCntDly = [], this.lstX = [], this.lstY = [], this.lstMAT = [], this.lstFAC = [], this.ttlCount = 0; 
    let result: any[] = [], cnt: any[] = [], crntPgIndx = this.crntPage - 1;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astTmpExcuson.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`),
      payLoad, 2, true, 5).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result?.data) {
              if (this.tab == 'o')
                this.lstGrid = this.result?.data.tempExcursions ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDts = [];
              else {
                let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.ttlItms = this.result.data?.size ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.ttlItms),
                  Object.keys(data.storetempExcursions).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0];
                    if (i == 0)
                      data.storetempExcursions[s].forEach((m: any) => { hdrs.push(m.date) });
                    result.push({ kId: null, kName: sName, matData: data.storetempExcursions[s], hdrs: hdrs, total: ttl });
                  });
                  data.dateAndCount?.forEach((s: any) => this.ttlCnt.push(s.count));
                  this.lstGrid = result, this.steRes = this.lstGrid[0]?.matData[0]?.stateName, this.dstRes = this.lstGrid[0]?.matData[0].districtName;
                }
                else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
              }
            }
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
      });
  }

  onCncl() {
    this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.slctdFac = [], this.slctdFacId = [], this.montrngType = '0', 
    this.astType = '2', this.isManfterName = null, this.isModel = null; this.tempExcusonForm.get('isTypeBdge')?.setValue('badge'),
    this.dpEDVal ='', this.dpSDVal='', this.lblTextOfDate = 'Max 12 months will be accepted', this.isShwDayTable =false, this.lstSlctdFclty = [...[]],
    this.tempExcusonForm.get('dpSDVal')?.setValue(''), this.tempExcusonForm.get('dpEDVal')?.setValue(''), this.expDrpn = '1'; 
    this.tempExcusonForm.get('fromDate')?.setValue(''), this.tempExcusonForm.get('toDate')?.setValue(''), this.expDrpnDly = '1';
    this.tempExcusonForm.get('isFacBdge')?.setValue('badge'), this.tempExcusonForm.get('isManfterBdge')?.setValue('badge'),
    this.tempExcusonForm.get('storeTags')?.setValue([]), this.metrcVal = '0', this.steRes = '', this.dstRes = '', this.steResDly = '',
    this.tempExcusonForm.get('storeIds')?.setValue([]), this.tempExcusonForm.get('monitoringType')?.setValue(['0']), this.dstResDly = '',
    this.tempExcusonForm.get('assetType')?.setValue(['2']), this.tempExcusonForm.get('manufacturerId')?.setValue(null), this.excsonType = '0',
    this.tempExcusonForm.get('modelId')?.setValue(null), this.tempExcusonForm.get('pstT')?.setValue('Monthly'), this.isFclty = [],
    this.tempExcusonForm.get('excursionType')?.setValue(['0']), this.tempExcusonForm.get('metricId')?.setValue(['0']), this.lstCcpAll =[];
    this.tempExcusonForm?.get('excursionCriteria')?.setValue('0'), this.durtn = '0', this.tempExcusonForm.get('facilityIds')?.setValue([]),
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500), this.ttlCnt = [], this.ttlCntDly = [];
  }

  onClkRdb(ctrl: string) {
    if (ctrl == 'monitoringType')
      this.fc?.assetType.setValue([]), this.astType = '2', this.fc?.assetType?.clearValidators(); //this.fc?.monitoringType?.setValidators([Validators.required]);
    else if (ctrl == 'assetType')
      this.fc?.monitoringType.setValue([]), this.montrngType = '0', this.fc?.monitoringType?.clearValidators(), //this.fc?.assetType?.setValidators([Validators.required]),
        this.fc?.monitoringType?.updateValueAndValidity(), this.fc?.assetType?.updateValueAndValidity();
    else if (ctrl == 'manufacturerId')
      this.fc?.modelId.setValue(null), this.isModel = null, this.fc?.modelId?.clearValidators(); //this.fc?.manufacturerId?.setValidators([Validators.required]);
    else if (ctrl == 'modelId')
      this.fc?.manufacturerId.setValue(null), this.isManfterName = null, this.fc?.manufacturerId?.clearValidators(),  // this.fc?.modelId?.setValidators([Validators.required]),
        this.fc?.manufacturerId?.updateValueAndValidity(), this.fc?.modelId?.updateValueAndValidity();
    else if (ctrl == 'storeTags')
      this.fc?.storeIds.setValue([]),
        this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]), this.lstSlctdStores = [...[]];
    else
      this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), this.fc?.storeIds?.setValidators([Validators.required]),
        this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = [...[]];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      } else if (ctrl == 'fc') {
        if (this.lstSlctdFclty.filter((f: { storeId: any; }) => f.storeId === item.storeId).length == 0)
          this.lstSlctdFclty.push(item);
      } else {
        if (this.lstSlctdStores.filter((f: { storeIds: any; }) => f.storeIds === item.storeId).length == 0)
      this.lstSlctdStores.push(item);
      }
        
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 'fc')
        this.lstSlctdFclty = this.lstSlctdFclty.filter(f => f.storeId != item.storeId);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeId != item.storeId);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 'fc')
        this.lstSlctdFclty = this.lstCcpAll;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 'fc')
        this.lstSlctdFclty = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  // LoadStrName() {
  //   let frmCtrls = this.tempExcusonForm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, districtId:
  //       frmCtrls.district == 'null' ? 0 : +frmCtrls.district }; this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]],
  //     this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
  //       if (this.result) {
  //         if (this.result.status == 'OK') {
  //           if (this.result.data)
  //             this.lstFacIds = this.result.data != null ? this.result.data : [];
  //           else
  //             this.glblSvc.onTstr('W', this.result.message);
  //         }
  //       }
  //     });
  // }

  LoadManufacturers() {
    this.manfterLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getAll,
      null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.manfterLst = this.result.data != null ? this.result.data : [];
        }
      })
  }

  LoadModels() {
    this.modelsLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetMdl, environment.apiMthds.getAll,
      null).then(() => {
        if (this.result) {
          if (this.result.data)
            this.modelsLst = this.result.data != null ? this.result.data : [];
        }
      })
  }

  onEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astTmpExcusonExprt.
      replace('ddd', `${this.pstT == 'Monthly' ? 'm' : (this.pstT =='Daily' ? 'd' : 'w')}`).replace('ttt', `${this.tab == 'o' ? '1' :
        (this.tab == 'f' ? '2' :'3')}`), payLoad, 2, true, 5).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  GetDailyData(dtt: any, type: string='g') {
    if ((this.pstType == 'monthly' || this.pstType == 'weekly') && dtt != 'Total Count') {
      let dtEND: any, maxDate: any, sm: any, frmDte: any = '';
      if (dtt !='') {
        sm = dtt, this.glblSvc.sbjctExpt.next(dtt);
      } else {
        this.glblSvc.sbjctExpt.subscribe(s => { sm = s });
      }
      if (this.pstType == 'monthly') {
        dtEND = this.glblSvc.setDtFrmt(this.dt2days, this.dtF.ymd); let mn = sm.split("-");
        let mnth = this.mnths.filter(m => m.name == mn[0]), v = this.lstMnths.find(month => month.val == mnth[0].val),
        fromDateDay = mn[1] + '-' + v?.val + '-01', toDateDay = mn[1] + '-' + v?.val + '-' + v?.days; frmDte = fromDateDay;
        if (dtEND < toDateDay){
          maxDate = dtEND;
        }else{
          maxDate = toDateDay;
        } 
      }
      if (this.pstType == 'weekly') {
        frmDte = '', maxDate = '';
        let dt = sm.split('('), frTo: any = dt[1], frToDly: any; frTo = frTo.substring(0, frTo.length-1), frToDly = frTo.split(' '),
          frmDte = frToDly[0], maxDate = frToDly[2];
      }
      let payLoad: any = this.tempExcusonForm.getRawValue(), fT: any[] = [], sN: any[] = [], fTs: any[] = [], result: any[] = [],
       hdrs: any[] = [], cnt: any[] = [];
      this.frmDates = this.pstType == 'weekly' ? frmDte: this.glblSvc.setDtFrmt(frmDte, this.dtF.dmy), this.tooDates = this.pstType == 'weekly' ?
        maxDate: this.glblSvc.setDtFrmt(maxDate, this.dtF.dmy), payLoad.fromDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(frmDte, 
          this.dtF.dmy): frmDte , payLoad.toDate = this.pstType == 'weekly' ? this.glblSvc.setDtFrmt(maxDate, this.dtF.ymd) : maxDate,
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
      payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, payLoad.manufacturerId = (payLoad.manufacturerId != '' &&
      payLoad.manufacturerId != null && payLoad.manufacturerId != undefined) ? +payLoad.manufacturerId : null, payLoad.facilityIds =
      (payLoad.facilityIds != '' && payLoad.facilityIds != null && payLoad.facilityIds != undefined && this.tab == 'f' &&
      (payLoad.district != 'null' || payLoad.block != 'null')) ? this.lstSlctdFclty?.forEach((e: any) => { fTs.push(e.storeId) }) : [], 
      payLoad.facilityIds = fTs, payLoad.excursionCriteria = (payLoad.excursionCriteria != '' && payLoad.excursionCriteria != null &&
      payLoad.excursionCriteria != undefined) ? +payLoad.excursionCriteria : null, payLoad.modelId = (payLoad.modelId != '' &&
      payLoad.modelId != null && payLoad.modelId != undefined) ? +payLoad.modelId : null, payLoad.assetType = (payLoad.assetType != '' && 
      payLoad.assetType != null && payLoad.assetType != undefined) ? +payLoad.assetType : null, payLoad.excursionType = (payLoad.excursionType != '' &&
      payLoad.excursionType != null && payLoad.excursionType != undefined) ? +payLoad.excursionType : null, payLoad.monitoringType =
      (payLoad.monitoringType != '' && payLoad.monitoringType != null && payLoad.monitoringType != undefined) ? +payLoad.monitoringType : null,
      payLoad.storeTags = payLoad.storeTags != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT;
      payLoad.storeIds = payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeId) }) : [], payLoad.storeIds = sN,
      payLoad.email =this.usrLgnDtls?.email, ['pstT', 'dpSDVal', 'dpEDVal', 'state', 'district', 'block', 'isTypeBdge', 'isFacBdge',
      'isManfterBdge'].forEach(e => { delete payLoad[e] });
      if(type=='g')
        this.isShwDayTable = true, this.onGenDaysData(payLoad);
      else
        this.tab == 'f' ? (payLoad.exportLevel = this.expDrpnDly !='' ? this.expDrpnDly : '') : '', this.onDaysEE(payLoad);
    }
  }

  onGenDaysData(payLoad: any) {
    this.lstGridDate = [], this.ttlCntDly = []; let crntPgIndx = this.crntDPage - 1;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astTmpExcuson.replace('ddd', 'd').
    replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' : '3')}`).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 2, true, 5).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result?.data) {
            if (this.tab == 'o')
              this.lstGridDate = this.result?.data.tempExcursions ?? [], this.lstTxn = this.result?.data?.txnCountDTo ?? [], this.lstDDts = [];
            else {
              let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
              if (data) {
                this.ttlDayItms = this.result.data?.size ?? 0, this.pageEntrsD = this.GetPageSizeTxt(this.crntDPage, this.pageSize, this.ttlDayItms),
                  Object.keys(data.storetempExcursions).forEach((s: any, i: number) => {
                    let sName = `${s}`.split('||')[0];
                    if (i == 0)
                      data.storetempExcursions[s].forEach((m: any) => { hdrs.push(m.date) });
                    result.push({ kId: null, kName: sName, matData: data.storetempExcursions[s], hdrs: hdrs, total: ttl });
                  });
                  data.dateAndCount?.forEach((s: any) => this.ttlCntDly.push(s.count));
                  this.lstGridDate = result, this.steResDly = this.lstGridDate[0]?.matData[0]?.stateName, this.dstResDly = this.lstGridDate[0]?.matData[0].districtName;
              }
              else if (data == null)
                this.glblSvc.onTstr('i', this.result.message);
              else
                this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGridDate), this.glblSvc.sbjctTblDataCnt.next(data?.count ?? 0);
            }
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
    });
  }

  onDaysEE(payLoad: any) {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astTmpExcusonExprt.
      replace('ddd', `${'d'}`).replace('ttt', `${this.tab == 'o' ? '1' : (this.tab == 'f' ? '2' :'3')}`), payLoad, 2, true, 5).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }
}