<div class="row p-0 mt-3 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-xl-3">
                <button class="btn btn-sm btn-light me-2 mb-2">Remove group(s)</button>
            </div>
        </div>
    </div>
</div>
<hr class="mt-0 mb-0" />
<div class="row mt-3">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-xl-12 mb-2">
                <!-- <span style="display: inline-block;" class="me-2">
                    <label tyle="display: inline-block;">Filter</label>
                </span>
                <span style="display: inline-block;">
                    <select class="form-select form-select-sm">
                        <option>5</option>
                        <option>10</option>
                    </select>
                </span> -->
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                <thead>
                    <tr>
                        <th><input type="checkbox" id="" value="" /></th>
                        <th class="text-center">Item No.</th>
                        <th>Group Name</th>
                        <th>Group Owner</th>
                        <th>Last updated on</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><input type="checkbox" id="" value="" /></td>
                        <td class="text-center">1</td>
                        <td><a>BCG Guindy</a></td>
                        <td>manufmgr</td>
                        <td>5/4/21 4:14 PM</td>
                        <td><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row ps-0 pe-0 pt-3 pb-3">
            <div class="col-lg-6 col-xxl-6 pb-3">
            </div>
            <div class="col-lg-6 col-xxl-6 pb-3">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination justify-content-xl-end  pagination-primary ">
                        <li class="page-item"><a class="page-link" aria-label="Previous" data-bs-original-title=""
                                title=""><span aria-hidden="true">«</span><span class="sr-only">Previous</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a>
                        </li>
                        <li class="page-item"><a class="page-link" aria-label="Next" data-bs-original-title=""
                                title=""><span aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>