<form [formGroup]="facilityCreateForm" autocomplete="off">
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Facility information</h6>
        </div>
        <div class="card-body p-4 pt-3 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Facility Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Facility Name" class="form-control form-control-sm"
                                formControlName="name" [ngClass]="{ 'is-invalid': isSbmtd && fc.name.errors }"
                                (change)="dupMsg='';onChngFN($event)" id="name">
                            <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                                <div *ngIf="fc.name.errors.required">Facility name is required</div>
                            </div>
                            <div class="highTemp" *ngIf="dupMsg!=''">{{dupMsg}}</div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">                            
                            <!-- (onSelect)="onItemSelect('user', $event)"
                            (onSelectAll)="onSelectAll('user', $event)" -->
                            <label for="textBox">Users <span class="text-danger">*</span></label>
                            <ng-multiselect-dropdown [settings]="userDropdown"
                                [data]="userLst" placeholder="Select Users"
                                formControlName="store_users" [(ngModel)]="selectedOwners"
                                (onSelect)="onChng('user', $event, 'os')"
                                (onSelectAll)="onChng('user', $event, 'osa')"
                                (onDeSelect)="onChng('user', $event, 'ds')"
                                (onDeSelectAll)="onChng('user', $event, 'dsa')"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.store_users.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.store_users.errors" class="invalid-feedback">
                                <div *ngIf="fc.store_users.errors.required">Users is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Tags <span class="text-danger">*</span></label>
                            <select id="inputState" class="form-select form-select-sm" formControlName="store_badges"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.store_badges.errors }" [(ngModel)]="lst_Tag">
                                <option value="">Select</option>
                                <option *ngFor="let tags of lstFacTags" [value]="tags.id">{{tags.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.store_badges.errors" class="invalid-feedback">
                                <div *ngIf="fc.store_badges.errors.required">Tags is required</div>
                            </div>
                            <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                placeholder="Tags" formControlName="store_badges"
                                (onSelect)="onChng('badge', $event, 'os')"
                                (onSelectAll)="onChng('badge', $event, 'osa')"
                                (onDeSelect)="onChng('badge', $event, 'ds')"
                                (onDeSelectAll)="onChng('badge', $event, 'dsa')">
                            </ng-multiselect-dropdown> -->
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Custom ID</label>
                            <input type="text" placeholder="Custom ID" class="form-control form-control-sm" id="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Location</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify location information for the Facility</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="country_id">Country <span class="text-danger">*</span></label>
                            <select id="country_id" class="form-select form-select-sm" formControlName="country_id"
                                (change)="onNestedSlct($event, 'country')"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.country_id.errors }">
                                <option value="">All</option>
                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.country_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.country_id.errors.required">Country is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="state_id">State <span class="text-danger">*</span></label>
                            <select id="state_id" class="form-select form-select-sm" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.state_id.errors }"
                                (change)="onNestedSlct($event, 'state')">
                                <option value="null">All</option>
                                <option *ngFor="let s of stsLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.state_id.errors" class="invalid-feedback">
                                <div *ngIf="fc.state_id.errors.required">State is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="district_id">District</label>
                            <select id="district_id" class="form-select form-select-sm" formControlName="district_id"
                                (change)="onNestedSlct($event, 'district')">
                                <option value="null">All</option>
                                <option *ngFor="let s of dstrtLst" [value]="s.districtid">{{s.districtname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="block_id">Taluk/Block</label>
                            <select id="block_id" class="form-select form-select-sm" formControlName="block_id">
                                <option value="null">All</option>
                                <option *ngFor="let s of blkLst" [value]="s.blockid">{{s.blockname}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="city">Village/City <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Village/City" class="form-control form-control-sm" id="city"
                                formControlName="city" [ngClass]="{ 'is-invalid': isSbmtd && fc.city.errors }">
                            <div *ngIf="isSbmtd && fc.city.errors" class="invalid-feedback">
                                <div *ngIf="fc.city.errors.required">Village/City is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="pin">Zip/PIN code</label>
                            <input type="text" placeholder="Zip/PIN code" class="form-control form-control-sm" id="pin"
                                formControlName="pin" onkeypress='return (event.charCode >=48 && event.charCode <=57)'
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.pin.errors }" maxlength='6' minlength="6">
                            <div *ngIf="isSbmtd && fc.pin.errors" class="invalid-feedback">
                                <div *ngIf="fc.pin.errors.minlength || fc.pin.errors.maxlength">Min. & Max. should be 6 char.s only</div>
                            </div>    
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="address">Street address</label>
                            <input type="text" placeholder="Street address" class="form-control form-control-sm" id="address"
                                formControlName="address" >
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Latitude</label>
                            <input type="text" placeholder="Latitude" formControlName="latitude" class="form-control form-control-sm" id=""
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                            (event.charCode >=48 && event.charCode <=57) || event.charCode == 46)' >
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Longitude</label>
                            <input type="text" placeholder="Longitude" formControlName="longitude" class="form-control form-control-sm" id=""
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                            (event.charCode >=48 && event.charCode <=57) || event.charCode == 46)' >
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-primary" (click)="getGeoCds()">Get geocodes</button>
                <hr class="m-0" />
                <p class="mb-0">Please specify Country, State, District and Village/City name to get the Geocodes.</p>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0" *ngIf="false">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Pricing information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <span class="small m-0">Please specify the pricing information such as MSRP, Retailer's
                        price and Currency</span>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">MSRP</label>
                            <input type="text" placeholder="Manufacturer-specified retail price" class="form-control form-control-sm"
                                id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Retailer's price</label>
                            <input type="text" placeholder="Retailer's price" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Currency</label>
                            <select id="inputState" class="form-select form-select-sm" disabled>
                                <option selected>Indian Rupees (INR)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0" *ngIf="false">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Temperature</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small">Enable monitoring of temperature for this material</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Temperature sensitive</label>
                            <div><input class="form-check-input" type="checkbox" id="checkboxNoLabel" value=""
                                    aria-label="...">&nbsp;&nbsp;Is material temperature
                                sensitive?</div>
                        </div>
                        <div class="form-group col-xl-9">
                            <label for="textBox">Specify a valid temperature range for this
                                material</label>
                            <div class="row">
                                <div class="col-xl-6">Min.:&nbsp;&nbsp;&nbsp;<input type="text" placeholder="Min &deg;C"
                                        class="form-control form-control-sm" id=""></div>
                                <div class="col-xl-6">Max.:&nbsp;&nbsp;&nbsp;<input type="text" placeholder="Max &deg;C"
                                        class="form-control form-control-sm" id=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0" *ngIf="false">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Financial details </h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small">Please specify the financial details such as currency and tax</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Currency</label>
                            <select id="inputState" class="form-select form-select-sm" disabled>
                                <option value="WY" selected>Indian Rupee (INR)</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Tax (%)</label>
                            <input type="text" placeholder="Tax (%)" value="0" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Tax ID</label>
                            <input type="text" placeholder="Tax ID" value="0" class="form-control form-control-sm" id="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Inventory policy</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small">Please specify the policy for inventory management</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-4">
                            <label for="textBox">Inventory policy</label>
                            <select id="inputState" class="form-select form-select-sm">
                                <option value="">User specified replenishment - s,X</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Batch management</label>
                            <div class="form-check mt-2">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                <label class="form-check-label" for="flexCheckChecked">
                                    Disable batch management
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12">
            <div class="float-xl-end">
                <button class="btn btn-sm btn-primary me-2 mb-2"
                    (click)="isSbmtd = true; saveFacilities()">Save</button>
                <button class="btn btn-sm btn-light mb-2" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</form>