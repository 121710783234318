<form class="row" [formGroup]="assetDetailsForm" autocomplete="off">
    <div class="card border-0">
        <div class="card-body">
            <div class="row">
                <div class="col-xl-12 col-xs-12 mb-2">
                    <div class="row">
                        <div class="col-xl-12 col-xs-12">
                            <div class="float-start">
                                <div class="h6 me-2">{{assetsData[0]?.serialNumber}} - {{assetsData[0]?.modelName}}
                                    ({{assetsData[0]?.assetVendorName}})</div>
                                <p>{{assetsData[0]?.storeName}}, {{assetsData[0]?.city}}, {{assetsData[0]?.districtName}}, {{assetsData[0]?.stateName}},
                                    {{assetsData[0]?.country}}</p>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-0" />
                    <div class="row">
                        <div class="col-xl-12 col-xs-12">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="thermometer-half">
                                        <div class="ps-3 d-flex bd-highlight flex-wrap">
                                            <div *ngFor = "let a of assetsData">
                                            <div *ngIf="a.assetTypeName=='Temperature Logger'">
                                                <div *ngIf="a.deviceReadyStatus=='Device Ready'">
                                                    <i class="fa fa-check-circle-o tickGreen" aria-hidden="true"></i>
                                                </div>
                                                <div *ngIf="a.deviceReadyStatus !='Device Ready'">
                                                    <i class="fa fa-times-circle tickRed" aria-hidden="true"></i>
                                                </div>      
                                                <div *ngIf="a.configMessage != null">
                                                    ({{a.configMessage}})
                                                </div> 
                                            </div>
                                            <div>
                                                <div class="d-flex bd-highlight flex-wrap">
                                                    <div *ngFor="let sm of a.sensorMonitoringPoints; let j=index">
                                                        <div *ngFor="let z of a.temperatureLogDataList; let k= index">
                                                            <div *ngIf='a.temperatureLogDataList != null && a.sensorMonitoringPoints != null'>
                                                                <div *ngIf='z.sensor == sm.sensor'
                                                                [ngClass]="{'normal': (z.temperature < z.tempHigh) && (z.temperature > z.tempLow), 'highTemp': z.temperature > z.tempHigh,
                                                                'lowTemp': z.temperature < z.tempLow, 'noClr': z.isUnknown == true}">
                                                                    <div class="p-2 m-1 bd-highlight rounded box-outline-success tab_info_box">
                                                                        <div class="float-start">
                                                                            {{sm.monitoring_point}}: <span>{{sm.sensor}}</span>
                                                                        </div>
                                                                        <div class="float-end font-wt">
                                                                            <span>{{z.temperature}}&deg;C</span>
                                                                        </div>
                                                                        <div class="float-end text-end">
                                                                            <br />{{z.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="a.configMessage != null">
                                                        <br>
                                                        ({{a.configMessage}} for mapped logger)
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>                                           
                                            <div class="p2" *ngIf="tempLogLst.length == 0"> {{noRcrdsTxt}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <div class="fa-text-width">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">
                                            {{assetsData[0]?.assetTypeName}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <!-- <div class="col-sm-3">
                                    <div class="fa-status_icon">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">
                                            {{assetsData[0]?.assetStatus}}</div>
                                    </div>
                                </div> -->
                                <div class="col-sm-3">
                                    <div class="fa-status_icon" *ngIf="isChngAstHdn">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">
                                            {{assetsData[0]?.assetStatus}}</div>
                                    </div>
                                    <div class="fa-status_icon" *ngIf="!isChngAstHdn">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">
                                            <select class="form-select form-select-sm" formControlName="assetworkingStatus">
                                                <option value="">Select Status</option>
                                                <option *ngFor="let status of astStatsLst" value="{{status.id}}">{{status.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2" *ngIf="!isChngAstHdn">
                                    <div *ngIf="cceAsstDtlUpdate">
                                        <button class="btn btn-primary btn-sm" type="button" (click) = "updateAstStatus()">Update</button>                              
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row mt-2" *ngIf="isShowstatus">
                                <div class="col-sm-12">
                                    <div class="fa-user_times">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">                                            
                                            <p>{{assetsData[0]?.updatedOn | date:"dd/MM/yyyy hh:mm aa"}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <div class="fa-user_icon">
                                        <div class="ps-4 text_indent" style="font-weight: normal;">
                                            <span class="badge bg-primary"
                                                *ngFor="let user of usrDataLst[0]?.user_list">
                                                <a class="text-white">{{user.name}} [{{user.mobile}}]</a>
                                            </span>
                                            <span *ngIf="usrDataLst.length == 0">{{noRcrdsTxt}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mt4 mb-0" />
                    <div class="mt-1"><small>Created on {{assetsData[0]?.createdOn | date:"dd/MM/yyyy hh:mm aa"}} by
                            <a>{{assetsData[0]?.createdByUser}} ({{assetsData[0]?.createdUserId}})</a>, Last updated
                            on {{assetsData[0]?.updatedOn | date:"dd/MM/yyyy hh:mm aa"}} by
                            <a>{{assetsData[0]?.updatedByUser}} ({{assetsData[0]?.updatedUserId}})</a></small></div>
                </div>
                <!-- <div class="col-xl-4 col-xs-12 mb-2">
                    <div class="card text-center text-secondary h-100">
                        <div class="card-body">
                            Geocodes not defined
                        </div>({{lgnUsrData?.userName}})
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="card p-0 border-0">
        <div class="card-body p-3 pt-0 pb-0">
            <ul class="nav nav-tabs border-tab mt-3 mb-3" id="inner_assets_tab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="summary-tab" data-bs-toggle="tab" href="#summary" role="tab"
                        aria-controls="summary" aria-selected="true" #summry>Summary</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="information-tab" data-bs-toggle="tab" href="#information" role="tab"
                        aria-controls="information" aria-selected="false">Information</a>
                </li>
                <li class="nav-item" role="presentation" *ngIf="assetsData[0]?.assetTypeName == 'Temperature Logger'">
                    <a class="nav-link" id="configuration-tab" data-bs-toggle="tab" href="#configuration" role="tab"
                        aria-controls="Configuration" aria-selected="false">Configuration</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="relationships-tab" data-bs-toggle="tab" href="#relationships" role="tab"
                        aria-controls="relationships" aria-selected="false">Relationships</a>
                </li>
                <!-- <li class="nav-item" role="presentation">
                    <a class="nav-link" id="statistics-tab" data-bs-toggle="tab" href="#statistics" role="tab"
                        aria-controls="statistics" aria-selected="false">Statistics</a>
                </li> -->
            </ul>
            <div class="tab-content" id="inner_assets_tab">
                <div class="tab-pane fade show active" id="summary" role="tabpanel" aria-labelledby="summary-tab">
                    <div class="card border-0 p-0">
                        <div class="card-body border-0 p-2 pt-0">
                            <div class="row mt-0">
                                <div class="col-sm-12">
                                    <div class="float-start">
                                        <button type="button" class="btn btn-primary btn-sm" (click)="exportSummaryPoints()">Export</button>
                                    </div>
                                    <div class="float-end">
                                        <table class="table table-borderless">
                                            <tr>
                                                <td class="border" *ngFor="let sensorP of snsrMntrPnts; let i = index;"
                                                    [class.sensorActive]="selectedIndex === i"
                                                    (click)="setIndex(i, sensorP, 'fc-line22')">
                                                    {{sensorP.monitoring_point}} {{sensorP.sensor}}
                                                </td>
                                            </tr>
                                        </table>                                       
                                    </div>                                   
                                </div>
                            </div>
                            <div class="card p-0">
                                <div class="card-header p-2">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="row m-0">
                                                <div class="col-md-3">
                                                    <div class="float-start pt-1">
                                                        <h6>Temperature <span *ngIf="macAd != ''"> - {{macAd}}</span></h6>
                                                    </div>
                                                </div>
                                                <div class="offset-md-6 col-md-3">
                                                    <div class="float-end">
                                                        <div class="input-group">
                                                            <input type="text" autocomplete="off"
                                                                onpaste="return false" bsDatepicker
                                                                class="form-control form-control-sm optinbg bsDP-bg"
                                                                #dpFD="bsDatepicker" [dateCustomClasses]="cstmDt"
                                                                (bsValueChange)="onChngDT($event, 'fc-line22')"
                                                                [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                                    containerClass: 'theme-default', showWeekNumbers: false,
                                                                    adaptivePosition: true }" onkeypress='return event.charCode == 8'
                                                                placeholder="Select Date" [maxDate]="dt2day"
                                                                [(ngModel)]="frmDate" id="datePickValue" name="dop" #dop>
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-sm btn-outline-bsdp p-2"
                                                                    type="button" (click)="dpFD.toggle()"
                                                                    [attr.aria-expanded]="dpFD.isOpen"> <i
                                                                        class="fa fa-calendar"></i>
                                                                </button>
                                                                <button
                                                                    class="btn btn-sm btn-outline-bsdp p-2"
                                                                    type="button" title="Clear Date"
                                                                    (click)="dpFD.hide();clearBSDate('frmDate', 'fc-line22'); dop.value = ''">
                                                                    <i class="fa fa-times"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h6 class="text-center">Select a portion of the chart to zoom in </h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <div class="card-body p-4 pt-2 mt-1 mb-1">
                                    <div class="row">                                               
                                        <div class="col-xs-12 col-sm-12 col-xl-12">
                                            <div class="mt-2">
                                                <div id="fc-line22"></div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-xl-12">
                                            <button type="button" class="btn btn-primary btn-sm" (click)="expttPwrAvlblty()">Export</button>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 col-xl-12">
                                            <div class="mt-2">
                                                <h6>Power Availability</h6>
                                                <div id="fc-line23"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <div class="card p-0">
                                        <div class="card-header p-2">
                                            <h6>Recent activity</h6>
                                        </div>
                                        <div class="card-body p-4 pt-2 mt-1 mb-1">
                                            <div class="table-responsive mt-2 rcntTble">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Monitoring point</th>
                                                            <th scope="col">Alarm</th>
                                                            <th scope="col"><i class="far fa-clock"></i> Time</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf= "recentActivityList.length != 0">
                                                        <tr *ngFor = "let activity of recentActivityList">
                                                            <td>{{activity.monitoring_point}} {{activity.sensor_name}}</td>
                                                            <td>{{activity.alarm_message}}</td>
                                                            <td>{{activity.time}}</td>
                                                        </tr>                                                       
                                                    </tbody>
                                                    <tbody *ngIf= "recentActivityList.length == 0">
                                                        <tr>
                                                            <td colspan="3">
                                                                <div>No data available.</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="clear: both;"></div>
                <div class="tab-pane fade" id="information" role="tabpanel" aria-labelledby="information-tab">
                    <div class="card p-0">
                        <div class="card-header p-2">
                            <h6>Asset Profile</h6>
                        </div>
                        <div class="card-body p-4 pt-2 mt-1 mb-1">
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Asset type</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.assetTypeName}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Serial number</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.serialNumber}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Manufacturer</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.assetVendorName}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Asset model</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.modelName}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Mobile Number</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.mobileNumber}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Year of manufacture</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.yearOfManufacture}}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3 text-secondary">Asset capacity</div>
                                <div class="col-sm-3">{{this.assetsData[0]?.capacity}}</div>
                            </div>
                            <div *ngIf="this.assetsData[0]?.assetModelId == 459">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sensor A MAC</div>
                                    <div class="col-sm-3">{{this.assetsData[0]?.sensorAMAC}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sensor B MAC</div>
                                    <div class="col-sm-3">{{this.assetsData[0]?.sensorBMAC}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sensor C MAC</div>
                                    <div class="col-sm-3">{{this.assetsData[0]?.sensorCMAC}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sensor D MAC</div>
                                    <div class="col-sm-3">{{this.assetsData[0]?.sensorDMAC}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sensor E MAC</div>
                                    <div class="col-sm-3">{{this.assetsData[0]?.sensorEMAC}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                    <div class="text-end"
                        *ngIf="assetsData[0]?.assetConfiguration == null || assetsData[0]?.assetConfiguration == ''">
                        <span class="text-danger">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </span> Configuration not pushed
                    </div>
                    <span *ngIf="assetsData[0]?.assetConfiguration != null && assetsData[0]?.assetConfiguration != ''">
                        <div class="card p-0 mb-0 mt-3">
                            <div class="card-header p-2">
                                <h6>High alarm configuration</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Temperature (in celsius):</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.highAlarmTemperature}}&deg;C</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Duration (in minutes)</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.highAlarmTemperatureDuration}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3 mb-0">
                            <div class="card-header p-2">
                                <h6>Low alarm configuration</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Temperature (in celsius):</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.lowAlarmTemperature}}&deg;C</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Duration (in minutes)</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.lowAlarmTemperatureDuration}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3 mb-0">
                            <div class="card-header p-2">
                                <h6>High warning configuration</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Temperature (in celsius):</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.highWarningTemperature}}&deg;C</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Duration (in minutes)</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.highWarningTemperatureDuration}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3 mb-0">
                            <div class="card-header p-2">
                                <h6>Low warning configuration</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Temperature (in celsius):</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.lowWarningTemperature}}&deg;C</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Duration (in minutes)</div>
                                    <div class="col-sm-3">
                                        {{assetsData[0]?.assetConfiguration?.lowWarningTemperatureDuration}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3 mb-0">
                            <div class="card-header p-2">
                                <h6>Communication configuration</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Communication channel:</div>
                                    <div class="col-sm-3">{{comChannel}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Temperature push URL:</div>
                                    <div class="col-sm-9">
                                        <a>{{assetsData[0]?.assetConfiguration?.temperaturePushUrl}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Configuration pull URL:</div>
                                    <div class="col-sm-9">
                                        <a>{{assetsData[0]?.assetConfiguration?.configurationPullUrl}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Alarm push URL:</div>
                                    <div class="col-sm-9"><a>{{assetsData[0]?.assetConfiguration?.alarmPushUrl}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Stats push URL:</div>
                                    <div class="col-sm-9"><a>{{assetsData[0]?.assetConfiguration?.statsPushUrl}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Device ready status push URL:</div>
                                    <div class="col-sm-9">
                                        <a>{{assetsData[0]?.assetConfiguration?.deviceReadyStatusPushUrl}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">SMS gateway phone number:</div>
                                    <div class="col-sm-9"><a
                                            href="tel:+918466997755">{{assetsData[0]?.assetConfiguration?.smsGatewayPhoneNumber}}</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">SMS gateway keyword:</div>
                                    <div class="col-sm-9">{{assetsData[0]?.assetConfiguration?.smsGatewayKeyword}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sender Id:</div>
                                    <div class="col-sm-3">{{assetsData[0]?.assetConfiguration?.sensorId}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Sampling interval (in minutes):</div>
                                    <div class="col-sm-3">{{assetsData[0]?.assetConfiguration?.samplingInterval}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Push interval (in minutes):</div>
                                    <div class="col-sm-3">{{assetsData[0]?.assetConfiguration?.pushInterval}}</div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Phones to send SMS notifications:</div>
                                    <div class="col-sm-3">
                                        <!-- <a href="tel:+917579167860">+917579167860</a>,  -->
                                        <a
                                            href="tel:+917065054011">{{assetsData[0]?.assetConfiguration?.phoneNumbersToSendSmsNotification}}</a>,
                                        <!-- <a href="tel:+918448589061">{{assetsData[0]?.assetConfiguration?.sensorId}}</a> -->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-3 text-secondary">Notifications:</div>
                                    <div class="col-sm-9">
                                        <span class="badge badge-primary" style="max-height: 1.5rem;">
                                            <span class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    value="{{assetsData[0]?.assetConfiguration?.temperaturePushNotification}}"
                                                    id="flexCheckDefault"
                                                    [checked]="assetsData[0].assetConfiguration.temperaturePushNotification">
                                                <label class="form-check-label mt-1" for="flexCheckDefault">
                                                    Raw temperature
                                                </label>
                                            </span>
                                        </span>
                                        <span class="badge badge-primary" style="max-height: 1.5rem;">
                                            <span class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    value="{{assetsData[0]?.assetConfiguration?.temperatureIncursionExcursionPushNotification}}"
                                                    id="flexCheckDefault"
                                                    [checked]="assetsData[0].assetConfiguration.temperatureIncursionExcursionPushNotification">
                                                <label class="form-check-label mt-1" for="flexCheckDefault">
                                                    Incursion/Excursion
                                                </label>
                                            </span>
                                        </span>
                                        <span class="badge badge-primary" style="max-height: 1.5rem;">
                                            <span class="form-check">
                                                <input class="form-check-input" type="checkbox"
                                                    value="{{assetsData[0]?.assetConfiguration?.deviceAlarmPushNotification}}"
                                                    id="flexCheckDefault"
                                                    [checked]="assetsData[0].assetConfiguration.deviceAlarmPushNotification">
                                                <label class="form-check-label mt-1" for="flexCheckDefault">
                                                    Device alarm
                                                </label>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Power outage alarm duration(in minutes)
                                        </div>
                                        <div class="col-sm-3">
                                            {{assetsData[0]?.assetConfiguration?.powerOutageAlarmDuration}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Low battery alarm threshold limits</div>
                                        <div class="col-sm-3">
                                            {{assetsData[0]?.assetConfiguration?.lowBatteryAlarmThresholdLimit}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-3 mb-0">
                                <div class="card-header p-2">
                                    <h6>Alarm frequency notification</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Duration (in minutes):</div>
                                        <div class="col-sm-3">
                                            {{assetsData[0]?.assetConfiguration?.alarmFrequencyNotificationDuration}}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Number of times:</div>
                                        <div class="col-sm-3">
                                            {{assetsData[0]?.assetConfiguration?.alarmFrequencyNotificationNumber}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-3 mb-0">
                                <div class="card-header p-2">
                                    <h6>Locale configuration</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Timezone:</div>
                                        <div class="col-sm-3">{{timeZone}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Country:</div>
                                        <div class="col-sm-3">{{country}}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-secondary">Language:</div>
                                        <div class="col-sm-3">{{language}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="card p-0 mt-3 mb-0">
                                <div class="card-header p-2">
                                    <h6>Sensor specific configuration</h6>
                                </div>
                                <div class="card-body p-2">
                                    <ul class="nav nav-tabs" id="icon-tab" role="tablist">
                                        <li class="nav-item" *ngFor="let item of sensorData;let liIndx=index;">
                                            <a class="nav-link" [ngClass]="liIndx==0?'nav-link active':'nav-link'"
                                                id="{{item.sensorId}}-tab" data-bs-toggle="tab"
                                                href="#{{item.sensorId}}" role="tab" title=""
                                                [attr.aria-controls]="item.sensorId" aria-selected="true"
                                                data-bs-original-title="" (click)="tab=item.sensorId">{{item.sensorId}}
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" [id]="'icon-tabContent'+index"
                                        *ngFor="let item of sensorData;let index=index;">
                                        <div *ngIf="item.sensorId == tab">
                                            <div class="tab-pane fade active show" id="{{item.sensorId}}"
                                                role="tabpanel" [attr.aria-labelledby]="item.sensorId+'-tab'">
                                                <div class="card p-0 mb-0 mt-3">
                                                    <div class="card-header p-2">
                                                        <h6>High Alarm configuration</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Temperature (in
                                                                celsius):</div>
                                                            <div class="col-sm-3">{{item.highAlarmTemperature}}&deg;C
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Duration :</div>
                                                            <div class="col-sm-3">{{item.highAlarmTemperatureDuration}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mt-3 mb-0">
                                                    <div class="card-header p-2">
                                                        <h6>Low Alarm configuration</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Temperature (in
                                                                celsius):</div>
                                                            <div class="col-sm-3">{{item.lowAlarmTemperature}}&deg;C
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Duration :</div>
                                                            <div class="col-sm-3">{{item.lowAlarmTemperatureDuration}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mt-3 mb-0">
                                                    <div class="card-header p-2">
                                                        <h6>High warning configuration</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Temperature (in
                                                                celsius):</div>
                                                            <div class="col-sm-3">{{item.highWarningTemperature}}&deg;C
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Duration :</div>
                                                            <div class="col-sm-3">
                                                                {{item.highWarningTemperatureDuration}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mt-3 mb-0">
                                                    <div class="card-header p-2">
                                                        <h6>Low warning configuration</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Temperature (in celsius):</div>
                                                            <div class="col-sm-3">{{item.lowWarningTemperature}}&deg;C</div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Duration :</div>
                                                            <div class="col-sm-3">{{item.lowWarningTemperatureDuration}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mt-3 mb-0">
                                                    <div class="card-header p-2">
                                                        <h6>Communication configuration</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Sampling interval (in
                                                                minutes):
                                                            </div>
                                                            <div class="col-sm-3">{{item.samplingInterval}}</div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-3 text-secondary">Push interval (in
                                                                minutes):
                                                            </div>
                                                            <div class="col-sm-3">{{item.pushInterval}}</div>
                                                        </div>
                                                        <!-- <div class="row">
                                                    <div class="col-sm-3 text-secondary">Notifications:</div>
                                                    <div class="col-sm-3">{{item.samplingInterval}}</div>
                                                </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="tab-pane fade" id="relationships" role="tabpanel" aria-labelledby="relationships-tab" *ngIf="assetsData[0]?.assetTypeName == 'Temperature Logger'">
                    <div class="card p-2">
                        <div class="card-body border-0 p-2">
                            <div class="form-group row">
                                <label for="srNumber"
                                    class="col-sm-3 col-form-label text-xl-right text-sm-right text-xs-left">
                                    Related asset</label>
                                <div class="col-sm-6" *ngIf = "tempMappedSerialNo != ''">                                    
                                    <p>{{tempMappedSerialNo?.serialNumber}}</p>                                   
                                </div>
                                <div class="text-center" *ngIf = "tempMappedSerialNo == ''">
                                    No relationships found
                                </div>                               
                            </div>
                            <div class="form-group row">
                                <label for="staticEmail"
                                    class="col-sm-3 col-form-label text-xl-right text-sm-right text-xs-left">Use
                                    default
                                    configuration</label>
                                <div class="col-sm-6">
                                    <input type="checkbox" class="mt-2" name="checkbox" value="" checked disabled>
                                </div>
                            </div>    
                            <div class="form-group row"  *ngIf = "tempMappedSerialNo != ''">
                                <h6 class="mt-3">Mapped Asset Details : </h6>
                                <table class="table table-striped table-borderless w-50">
                                    <tr>
                                        <td>Asset Type</td>
                                        <td>:</td>
                                        <td>{{tempMappedSerialNo?.assetType}}</td>
                                    </tr>
                                    <tr>
                                        <td>Model Name</td>
                                        <td>:</td>
                                        <td>{{tempMappedSerialNo?.modelName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Manufacturer Name</td>
                                        <td>:</td>
                                        <td>{{tempMappedSerialNo?.vendorName}}</td>
                                    </tr>                                       
                                </table>
                            </div>
                            <div class="float-end mt-2">                                
                                <button class="btn btn-sm btn-light" (click)="cnclRelationMap()">Back</button>
                            </div>                        
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="relationships" role="tabpanel" aria-labelledby="relationships-tab" *ngIf="assetsData[0]?.assetTypeName != 'Temperature Logger'">
                    <div class="card p-2">
                        <div class="card-body border-0 p-2">
                            <div class="form-group row">
                                <label for="srNumber"
                                    class="col-sm-3 col-form-label text-xl-right text-sm-right text-xs-left">
                                    Related asset</label>
                                <div class="col-sm-6">
                                    <!--<input type="text" placeholder="Serial number" class="form-control form-control-sm">-->
                                    <ng-template #srNo let-model="item">
                                        <span class="font-weight-bold">{{model.serialNumber}}</span> <br />
                                    </ng-template>
                                    <input name="srNumber" type="text" [typeahead]="srNoLst" id="srNumber"
                                        formControlName="srNumber" [typeaheadScrollable]="true" [typeaheadOptionsLimit]="500"
                                        [typeaheadItemTemplate]="srNo" typeaheadOptionField="serialNumber"
                                        [adaptivePosition]="true" (typeaheadOnSelect)="onSlct($event)"
                                        class="form-control" [typeaheadScrollable]="true"  onkeypress="return event.charCode != 32" 
                                        (keydown)="onSlct($event)" (change)= "onChngSNO()">
                                </div>
                                <div class="col-sm-3" *ngIf="cceAsstdDtlDltRlntsp">
                                    <button class="btn btn-sm btn-primary" [attr.disabled]="!isMappedAsset ? true : null"
                                    (click)="delRelationMap()">Delete</button>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="staticEmail"
                                    class="col-sm-3 col-form-label text-xl-right text-sm-right text-xs-left">
                                    Use default configuration</label>
                                <div class="col-sm-6">
                                    <input type="checkbox" class="mt-2" name="checkbox" value="" checked disabled>
                                </div>
                            </div>
                            <div class="form-group row mt-3" *ngIf="isMappedAsset ? true : false">
                                <h6>Mapped Asset Details : </h6>
                                <table class="table table-striped table-borderless w-50">
                                    <tr>
                                        <td>Asset Type</td>
                                        <td>:</td>
                                        <td>{{assetsData[0]?.mappedAssetType}}</td>
                                    </tr>
                                    <tr>
                                        <td>Model Name</td>
                                        <td>:</td>
                                        <td>{{assetsData[0]?.mappedModelName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Manufacturer Name</td>
                                        <td>:</td>
                                        <td>{{assetsData[0]?.mappedVendorName}}</td>
                                    </tr>
                                    <tr>
                                        <td>Mobile Number</td>
                                        <td>:</td>
                                        <td>{{assetsData[0]?.mappedMobileNumber}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="float-end mt-2">
                                <button class="btn btn-sm btn-primary" [attr.disabled]="!showField ? true : null"
                                    (click)="saveRelationMap()" type="button" *ngIf="showSveBtn && ccedtlAddRlnshp">Save</button>&nbsp;
                                <button class="btn btn-sm btn-light" (click)="cnclRelationMap()">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="tab-pane fade" id="statistics" role="tabpanel" aria-labelledby="relationships-tab">
                    <div class="card p-2">
                        <div class="card-body border-0 p-2">
                            <div class="row p-0">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="text-center">No Data Available</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</form>