<form [formGroup]="userViewForm" autocomplete="off">
    <div class="row p-0 mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="f_name">First name</label>
                            <input type="text" placeholder="First name" class="form-control form-control-sm" id="f_name"
                                formControlName="f_name">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="mobile_phone_number">Mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="mobile_phone_number"
                                    formControlName="mobile_phone_number" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                    id="mobile_phone_number" formControlName="mobile_phone_number"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.mobile_phone_number.errors }" minlength="10" maxlength="13">
                                <div *ngIf="isSbmtd && fc.mobile_phone_number.errors" class="invalid-feedback">
                                    <div *ngIf="fc.mobile_phone_number.errors.minlength || fc.mobile_phone_number.errors.maxlength">
                                        Mobile number should be min. of 10 and max. of 13 digits only
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="role">Role</label>
                            <select id="role" class="form-select form-select-sm" formControlName="role">
                                <option value="">All</option>
                                <option *ngFor="let rl of rolesLst" [value]="rl.name">{{rl.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="user_id">Username</label>
                            <input type="text" placeholder="Username" class="form-control form-control-sm" id="user_id"
                                formControlName="user_id">
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="isDelSa !='1'">
                        <div class="form-group col-xl-12">
                            <button class="btn btn-sm btn-primary float-start" data-toggle="collapse"
                                href="#multiCollapseExample-adv" role="button" aria-expanded="false"
                                aria-controls="multiCollapseExample-adv">Advance Search</button>
                        </div>
                    </div>
                    <div class="collapse multi-collapse" id="multiCollapseExample-adv">
                        <div class="row mb-2">
                            <div class="form-group col-xl-3" *ngIf="false">
                                <label for="textBox">Active</label>
                                <select id="inputState" class="form-select form-select-sm"
                                    formControlName="is_Deleted" [(ngModel)]="actvty">
                                    <option value="">All</option>
                                    <option value="false">Active</option>
                                    <option value="true">Disabled</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="mobile_app_version">Mobile app. version</label>
                                <input type="text" placeholder="Mobile app. version"
                                    class="form-control form-control-sm" id="mobile_app_version"
                                    formControlName="mobile_app_version">
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="user_badge">User tag:</label>
                                <select class="form-select form-select-sm" formControlName="user_badge">
                                    <option value="">All</option>
                                    <option [value]="it.name" *ngFor="let it of userBadges">{{it.name}}</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="textBox">Last login</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker" placeholder="From"
                                        [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8'
                                        formControlName="from_last_login_date" readonly [(ngModel)]="frmDate">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                            [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpFD.hide();clearBSDate('from_last_login_date')"> <i
                                                class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-3 mt-2">
                                <label for="textBox"></label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                        class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker" readonly
                                        [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDt($event)"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                    containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }" onkeypress='return event.charCode == 8'
                                        formControlName="to_last_login_date" bsDatepicker [minDate]="frmDate">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpED.hide();clearBSDate('to_last_login_date')"> <i
                                                class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        <!-- <div class="row mb-2"> -->
                            <div class="form-group col-xl-3 pt-4 pb-4">
                                <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" formControlName="user_never_login"
                                    aria-label="...">&nbsp; <span class="small">Show users who never logged in</span>
                            </div>
                        <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="float-xl-end" *ngIf="usrLst">
                        <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;isSbmtd=false;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;isSbmtd=true;loadUsers()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class="row" *ngIf="usersLst?.length!=0">
        <div class="col-xl-12 mb-2">
            <div class="row">
                <div class="col-xl-4">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row ps-0 pe-0" *ngIf="usersLst?.length!=0">
                <div class="col-xl-4 text-start">
                    {{pageEntrs}}
                </div>
                <!-- <div class="col-xl-3 text-center"> -->
                    <!--span style="display: inline-block;"> <button type="button" class="ms-2 btn btn-sm btn-primary"> <i
                                class="fa fa-paper-plane" aria-hidden="true"></i> Send message</button>
                    </span-->
                    <!-- <span style="display: inline-block;"> <button type="button" class="ms-2 btn btn-sm btn-primary" 
                        data-bs-toggle="modal" data-bs-target="#messageModal" [disabled]="usrIds.length==0">
                        <i class="fa fa-paper-plane" aria-hidden="true" ></i> Send E-mail </button>
                    </span> -->
                <!-- </div> -->
                <div class="col-xl-4 text-center">
                    <button type="button" class="ms-2 btn btn-sm btn-primary"  (click)="onRetrvData()" *ngIf="isDelSa=='1' && usrIds.length!=0">
                        <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i> Add </button>
                    <!-- <a (click)="onRemove('', 'a')" *ngIf="isDelSa=='1' && usrIds.length!=0">
                        <i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>
                    </a> -->
                </div>
                <div class="col-lg-4">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive mt-0">
                <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                    <thead>
                        <tr>
                            <!-- <th class="text-center"><input class="form-check-input" type="checkbox" id="checkboxNoLabel"
                                    value="" aria-label="..."></th> -->
                            <th *ngIf="usersLst?.length!=0 && isDelSa =='1'"> <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                (click)="onSlctAll($event)"></th>
                            <th class="text-center">Item No.</th>
                            <th>User name</th>
                            <th>Full name</th>
                            <th>Role</th>
                            <th>Category</th>
                            <th>Mapped Store</th>
                            <th>Location</th>
                            <th>Phone</th>
                            <th>Last login</th>
                            <th></th>
                            <th>Last mobile access</th>
                            <th [hidden]="isUserLvl=='District'" *ngIf="isDelSa !='1'"></th>
                            <!-- <th class="text-center" *ngIf="isDelSa =='1'"></th> -->
                            <!-- <th colspan="2" [hidden]="!((userlgndtls?.edit || userlgndtls == null ) && (userlgndtls?.delete || userlgndtls== null))"></th> -->
                            <!-- <th [hidden]="!"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of usersLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let index = index">
                            <!--td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td-->
                            <td class="text-center" *ngIf="isDelSa =='1'" scope="row"><input class="form-check-input" type="checkbox" id="flexCheckDefault12"
                                value="" aria-label="..." (change)= "isSelected($event, item)" [checked]="item.flag"></td>
                                <!-- <td scope="row"><input class="form-check-input" type="checkbox"
                                    id="flexCheckDefault11" (click)="onSlct($event, item)"
                                    [checked]="item.flag"></td> -->
                            <td class="text-center">{{(pageSize*(crntPage-1))+index+1}}</td>
                            <td (click)="onUserClk(item)"><a>{{item.username}}</a></td>
                            <td>{{item.full_name}}</td>
                            <td>{{item.role}}</td>
                            <td><span class="badge bg-secondary">{{item.badges}}</span></td>
                            <td>{{item.mappedstore}}</td>
                            <td>{{item.location}}</td>
                            <td>{{item.mobile}}</td>
                            <td>{{item.last_login_time | date: "dd-MM-yy hh:mm:ss"}}</td> <!-- "yy/MM/dd hh:mm a" -->
                            <td><i class="fa fa-laptop" aria-hidden="true"></i></td>
                            <td>{{item.logged_from_mobile}}</td>
                            <!-- <td (click)="onUserEdit(item.id)"><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                            <td (click)="onRemove(item.id)"><a><i class="fa fa-trash" aria-hidden="true"></i></a></td> -->
                            <td class="text-center" [hidden]="isUserLvl=='District'" *ngIf="isDelSa !='1'">
                                <span>
                                    <a *ngIf="item.is_deleted == false && usrEdt" (click)="onUserEdit(item.id)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                </span>&nbsp;
                                <span>
                                    <a *ngIf="item.is_deleted == false && usrDlt" (click)="onRemove(item.id)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                </span>
                            </td>
                            <!-- <td class="text-center" *ngIf="isDelSa =='1'"><a (click)="onRemove(item.id, 'a')"><i class="fa fa-plus-circle" aria-hidden="true"></i></a></td> -->
                            <!-- <td [hidden]="!(userlgndtls?.edit || userlgndtls== null)"><a *ngIf="item.is_deleted == false" (click)="onUserEdit(item.id)"><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                            <td [hidden]="!(userlgndtls?.delete || userlgndtls== null)"><a *ngIf="item.is_deleted == false" (click)="onRemove(item.id)"><i class="fa fa-trash" aria-hidden="true"></i></a></td> -->
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="usersLst.length == 0">
                            <td colspan="13" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="usersLst?.length!=0">
                <div class="col-xl-4 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-4 text-center">
                    <!-- <span></span><span></span> -->
                    <!--span style="display: inline-block;"> <button type="button" class="ms-2 btn btn-sm btn-primary"> <i
                                class="fa fa-paper-plane" aria-hidden="true"></i> Send message</button>
                    </span-->
                    <!-- <span style="display: inline-block;"> <button type="button" class="ms-2 btn btn-sm btn-primary" 
                        data-bs-toggle="modal" data-bs-target="#messageModal" [disabled]="(slctdUsersLst.length >= 1) ? !isSelct : isSelct"> <i
                        class="fa fa-paper-plane" aria-hidden="true" ></i> Send E-mail </button>
                    </span> -->
                    <!--span style="display: inline-block;"> <button type="button" class="ms-2 btn btn-sm btn-light"> <i
                                class="fa fa-trash" aria-hidden="true"></i> Remove</button>
                    </span-->
                </div>
                <div class="col-lg-4">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="messageModal" tabindex="-1" aria-labelledby="messageModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!--div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div-->
            <div class="modal-body">
                <label for="message">Please enter the message</label>
                <textarea placeholder="Enter message" class="form-control form-control-sm" id="message"
                    formControlName="message" rows="4" cols="50"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm btn-light mb-2" data-bs-dismiss="modal">Close</button>
              <button type="button" class="ms-2 btn btn-sm btn-primary" (click) = "sendMail()" data-bs-dismiss="modal">Send Mail</button>
            </div>
          </div>
        </div>
    </div>
</form>