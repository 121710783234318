<div class="card rounded-0 p-0 mb-0">
    <div class="card-header p-3">
        <div class="row ps-2 pe-2">
            <div class="col-xl-12">
                <div style="float: right;" *ngIf="isRprtsHlpShw">
                    <a href="btn btn-sm btn-link" [routerLink]="['/dmn-actvty/help']" target="_blank"><u>Help</u></a> 
                </div>
            </div>
            <div class="col-xl-2 col-sm-center col-sm-6">
                <div class="h5 mb-0" style="display: inline;">
                    Activity
                </div>
            </div>
            <div class="col-xl-8 col-sm-12">
                <div class="text-center h5 mb-0"> Domain Activity
                    <!-- <span class="text-secondary">
                        <a [routerLink]="" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                            <i class="fa fa-question-circle f-20" aria-hidden="true"></i>
                        </a>
                    </span> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="col-xl-12">
        <nav aria-label="breadcrumb" class="float-end">
            <ol class="breadcrumb">
                <li class="breadcrumb-item" (click)="slctState = '';slctDistrict = '';getallactivities()"><a>{{dmnName}}</a></li>
                <li class="breadcrumb-item" *ngIf="slctState!=''" (click)="slctDistrict = '';getallactivities()"><a>{{slctState}}</a></li>
                <li class="breadcrumb-item" *ngIf="slctDistrictResn!=''"><a>{{slctDistrictResn}}</a></li>
                <li class="breadcrumb-item" *ngIf="slctDistrict!=''"><a>{{slctDistrict}}</a></li>
            </ol>
        </nav>
    </div> -->
    <div class="card-body p-2 m-2">
        <div class="row">
            <div class="col-xl-3" >
                <button class="btn btn-sm btn-primary" *ngIf="brdcrmData?.length>=1 && slctDmnDetails?.pranthName !='India'" style="float: left;"  (click)="isClckDmn='false';loadastStatusForm()">Home</button>
            </div>
            <div class="col-xl-9">
                <nav aria-label="breadcrumb" class="float-end">
                    <ol class="breadcrumb" *ngIf="brdcrmData.length!=0">
                        <li class="breadcrumb-item active" *ngFor="let item of brdcrmData;" (click)="onClkBrdcrumb(item.brdId)"><a>{{item.brdName}}</a></li>
                    </ol>
                </nav>
            </div>
            
            <div class="col-xl-12">
                <div class="row" *ngIf="lstAllactivities?.length!=0">
                    <div class="col-xl-12 mb-2">
                        <div class="row">
                            <div class="col-xl-12 float-start">
                                <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                    <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6">
                        <div *ngIf="lstAllactivities?.length!=0" class="mt-2">{{pageEntrs}}</div>
                    </div>
                    <div class="col-xl-6">
                        <div class="float-end" *ngIf="lstAllactivities?.length!=0">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-12">
                <div class="table-responsive table_spacing">
                    <table class="table table-striped table-sm table-bordered border-primary f-14">
                        <thead>
                            <tr>
                                <th class="text-center" rowspan="2">Sl.No.</th>
                                <th class="text-center" rowspan="2">Domain</th>
                                <th class="text-center" rowspan="2">Users</th>
                                <th class="text-center" colspan="2">Facilities</th>
                                <th class="text-center" colspan="3">Monitored assets</th>
                                <th class="text-center" rowspan="2">Monitoring assets</th>
                            </tr>
                            <tr>
                                <!-- <th class="text-center">Facilities</th> -->
                                <th class="text-center">Live</th>
                                <th class="text-center">Active</th>
                                <th class="text-center">Assets</th>
                                <th class="text-center">Live</th>
                                <th class="text-center">Active</th>
                                <!-- <th></th> -->
                            </tr>
                        </thead>
                        <tbody *ngIf="lstAllactivities?.length!=0">
                            <tr data-node-id="1" *ngFor="let a of lstAllactivities | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems };let i=index">
                                <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                <td><a  (click)="dmnClick(a)">{{a.domain}}</a></td>
                                <td class="text-center">{{a.users}}</td>
                                <!-- <td class="text-center">{{a.totalStore}}</td> -->
                                <td class="text-center">{{a.liveStores}}  <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                                    title="Facilities with at least one transaction since creation">
                                    <i class="fa fa-info-circle" *ngIf="a.liveStores>0"  (click)="popOverTable('s', 'dfl', a)" data-bs-toggle="modal" data-bs-target="#myModal">
                                    </i>
                                </sup><br /><small>{{a.liveStorePercentage ? a.liveStorePercentage + ' %' : ''}}
                                   
                                </small></td>
                                <td class="text-center toggle-data">{{a.activeStores}} <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                                    title="Facilities with at least one transaction in the last 30 days">
                                    <i class="fa fa-info-circle" *ngIf="a.activeStores>0"
                                       (click)="popOverTable('s', 'dfa', a)"   data-bs-toggle="modal" data-bs-target="#myModal">
                                    </i>
                                </sup><br /><small>{{a.activeStorePercentage ? a.activeStorePercentage + ' %' : ''}}  </small></td>
                                <td class="text-center">{{a.monitoredAssets}}</td>
                                <td class="text-center toggle-data">{{a.liveAssets}}  <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                                    title="Eligible Monitored assets that have received at least one temperature data point since creation">
                                    <i class="fa fa-info-circle" *ngIf="a.liveAssets>0"  (click)="popOverTable('a', 'dml', a)" data-bs-toggle="modal" data-bs-target="#myModal">
                                    </i>
                                </sup><br /><small>{{a.liveAssetPercentage ? a.liveAssetPercentage + ' %' : ''}}</small></td>
                                <td class="text-center toggle-data">{{a.activeAssets}}    <sup data-toggle="tooltip" data-placement="left" class="toggle-data"
                                    title="Eligible Monitored assets with at least one temperature data point in the last 7 days">
                                    <i class="fa fa-info-circle" *ngIf="a.activeAssets>0" (click)="popOverTable('a','dma', a)" data-bs-toggle="modal" data-bs-target="#myModal">
                                    </i>
                                </sup><br /><small>{{a.activeAssetPercentage ? a.activeAssetPercentage + ' %' : ''}}</small></td>
                                <td class="text-center">{{a.monitoringAssets}}</td> 
                            </tr>
                            <tr *ngIf="ttl!={} && lstAllactivities.length > 1">
                                <td></td>
                                <td class="text-center"><strong>Total</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalUsers}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalLiveStores}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalActiveStores}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalMonitoredAssets}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalLiveAssets}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalActiveAssets}}</strong></td>
                                <td class="text-center"><strong>{{ttl?.totalMonitoringAssets}}</strong></td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="lstAllactivities?.length==0">
                            <tr>
                                <td colspan="10" class="text-center">{{noRcrds}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="pb-3 pt-3">
                    <div class="row" *ngIf="lstAllactivities?.length!=0">
                        <div class="col-xl-6 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="myModal" >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th *ngIf="dmnType=='dfl'" class="text-center" rowspan="2">Facility - live</th>
                                <th *ngIf="dmnType=='dfa'"class="text-center" rowspan="3">Facility - Active</th>
                                <th *ngIf="dmnType=='dml'"class="text-center" rowspan="3">Monitored assets - live</th>
                                <th *ngIf="dmnType=='dma'"class="text-center" rowspan="3">Monitored assets - Active</th>
                                <!-- <th class="text-center">Live</th>
                                <th class="text-center">Active</th> -->
                            </tr>
                        </thead>
                        <tr *ngFor ='let h of hvrResult'>
                            <td class="text-center" *ngIf="dmnType=='dfl' || dmnType=='dfa'">{{h.storeBadge}}</td>
                            <td class="text-center" *ngIf="dmnType=='dml'">{{h.assetType}}</td>
                            <td class="text-center" *ngIf="dmnType=='dma'">{{h.activeAssetType}}</td>
                            <td class="text-center" *ngIf="dmnType=='dfl' || dmnType=='dfa'">{{h.storeCount}}</td>
                            <td class="text-center" *ngIf="dmnType=='dma'">{{h.activeCount}}</td>
                            <td class="text-center" *ngIf="dmnType=='dml'">{{h.assetCount}}</td>
                            <td class="text-center" >{{h.percentage}}</td>
                        </tr>
                       
                    </table>
                </div>
              </div>
            </div>
          </div>
    </div>   
</div>