import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

var $ = require('jquery');
var FusionCharts = require('fusioncharts');
var Charts = require('fusioncharts/fusioncharts.charts');
var FusionTheme = require('fusioncharts/themes/fusioncharts.theme.fusion');
var jQueryFusionCharts = require('jquery-fusioncharts');

@Component({
  selector: 'evin-dashboard-overview',
  templateUrl: './dashboard-overview.component.html',
  styleUrls: ['./dashboard-overview.component.css']
})

export class DashboardOverviewComponent extends BaseComponent implements OnInit {

  invChart = 'Donut'; tmpChart = 'Pie'; loc = 'IND'; lstMatTags: any[] = []; msdStngs = { singleSelection: false, idField: 'id',
    textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
  colorState: any; colorState1: any; colorState2: any; colorState3: any; colorState4: any; colorState5: any; isHideIND = false;
  clrCode0: any = ['#1aaf5d', '#d9534f', '#fad42e', '#00c0ef']; clrCode1: any = ['#d1efde', '#f7dcdb', '#fef6d5', '#ccf2fb'];
  clrCode2: any = ['#a3dfbe', '#efbab8', '#fdedab', '#99e5f8']; clrCode3: any = ['#75cf9d', '#e89795', '#fce581', '#66d9f5'];
  clrCode4: any = ['#47bf7d', '#e07572', '#fbdc57', '#32ccf2']; clrCode5: any = ['#1aaf5d', '#d9534f', '#fad42e', '#00c0ef'];
  fcBarHrzntl = [{"label": "Jan", "value": "42", }, { "label": "Feb", "value": "81" }, { "label": "Mar", "value": "72" },
    { "label": "Apr", "value": "35" }, { "label": "May", "value": "91"}, { "label": "Jun", "value": "51" }, { "label": "Jul", "value": "68" },
    { "label": "Aug", "value": "24" }, { "label": "Sep", "value": "61" }, { "label": "Oct", "value": "49" }, { "label": "Nov", "value": "90" },
    { "label": "Dec", "value": "10" }]; fcDn: any = [{ "label": "Normal", "value": "86.94", "isSliced": "1" },
    { "label": "Zero Stock", "value": "1", }, { "label": "Min", "value": "5", }, { "label": "Max", "value": "7.06" }];

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
    var fsnOptns: any = FusionCharts.options;
    this.glblSvc.sbjctSpnr.next(true), Promise.all([fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false }), this.LoadDnClkFC(),
      this.LoadNewFCs('bar2d', 'fc-bar-horz', '400', this.fcBarHrzntl), this.LoadNewFCs('pie2d', 'fc-pieT'),
      this.LoadNewFCs('line', 'fc-lineI'), this.LoadNewFCs('column2d', 'fc-VbarI'), this.LoadNewFCs('pie2d', 'fc-pieI'),
      this.LoadNewFCs('doughnut2d', 'fc-dnT'), this.LoadNewFCs('line', 'fc-lineT'), this.LoadNewFCs('column2d', 'fc-VbarT'),
      this.colorState = this.clrCode0[0], this.colorState1 = this.clrCode1[0], this.colorState2 = this.clrCode2[0],
      this.colorState3 = this.clrCode3[0], this.colorState4 = this.clrCode4[0], this.colorState5 = this.clrCode5[0],
      $('#fc-dnI').html(this.isHideIND = false, this.LoadMapIND(this.clrCode0[0], this.clrCode1[0], this.clrCode2[0], this.clrCode3[0],
      this.clrCode4[0], this.clrCode5[0]))]).then(() => { this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags =
        s.filter(f => f.badgeTypeId == environment.tagTypes.product); }), this.glblSvc.sbjctSpnr.next(false); });
  }

  LoadNewFCs(fcType: string, divId: string, hght: string = '300', data: any[] = this.fcDn, cI = 0) {
    let chartConfig: any = {}, pltClrs: string[] = [''];
    if (fcType == 'bar2d') {
      pltClrs = [], data.forEach(e => { let clrCde = (+e.value < 20 ? this.clrCode1[cI] : ((+e.value > 20 && +e.value < 40) ? this.clrCode2[cI] :
        ((+e.value > 40 && +e.value < 60) ? this.clrCode3[cI] : ((+e.value > 60 && +e.value < 80) ? this.clrCode1[cI] : this.clrCode5[cI]))));
        pltClrs.push(clrCde); });
    }
    chartConfig = { type: fcType, renderAt: divId, width: '100%', height: hght,  dataFormat: 'json', dataSource: {
      chart: {
        caption: '', // "Monthly Stock",
        subCaption: '', // "Last year",
        xAxisName: '', // "Month",
        yAxisName: '', // "Stock Value",
        theme: 'fusion',
        paletteColors: fcType == 'bar2d' ? pltClrs.toString() : '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
        showValues: '1',
        exportEnabled: '1',
        exportTargetWindow: '_self',
        exportFormats: environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
        enableMultiSlicing: '0',
        interactiveLegend: '0',
      }, data: data
    }};
    FusionCharts.ready(function () { var fusioncharts = new FusionCharts(chartConfig); fusioncharts.render(); });
  }

  LoadMapIND(coloreCode: string, cod1: string, cod2: string, cod3: string, cod4: string, cod5: string) {
    FusionCharts.ready(() => {
      var salesMap = new FusionCharts({
        type: 'maps/india',
        renderAt: 'fc-map-ind',
        width: '600',
        height: '700',
        dataFormat: 'json',
        dataSource: {
          chart: {
            "caption": "", // India Map
            // "subcaption": " 2021",
            // 'plotToolText': "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            // "showBorder": "0",
            // "plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            // "labelsepchar": " ",
            // "tooltext": "{value} {br}",
            "numbersuffix": "%",
            "showCanvasBorder": '0',
            "includevalueinlabels": "1",
            "includeNameInLabels": '1',
            'useSNameInLabels': '1',
            "canvasBorderThickness": "1",
            "enableSmartLabels": "1",
            "showMarkerToolTip": '0',
            "showToolTip": "1",
            "entityFillHoverColor": "#ffc766",
            "nullEntityColor": coloreCode,     //color
            "nullEntityAlpha": "100",
            "hoverOnNull": "1 ",
            "theme": "fusioncharts/latest/maps/fusioncharts",
            'legendPosition': "bottom",
            'showLegend': '1',
            "showLabels": '1',
            "showValues": '1',
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
          },
          colorrange: {
            "color": [{
              "minvalue": "0",
              "maxvalue": "20",
              "code": cod1,// "#AACFAE",
              "displayValue": "< 20"
            },
            {
              "minvalue": "20",
              "maxvalue": "40",
              "code": cod2, //"#8ed98e",
              "displayValue": "20-40"
            }, {
              "minvalue": "40",
              "maxvalue": "60",
              "code": cod3, //"#4ec44e",
              "displayValue": "40-60"
            }, {
              "minvalue": "60",
              "maxvalue": "80",
              "code": cod4,  //"#2f8e2f",
              "displayValue": "60-80"
            }, {
              "minvalue": "80",
              "maxvalue": "100",
              "code": cod5,  //"#256e25",
              "displayValue": "> 100"
            }]
          },
          data: [{ "id": "001", "value": "82", 'showLabel': '1' }, { "id": "002", "value": "24", 'displayValue': 'Andhra Predesh, 24%' },
            { "id": "003", "value": "56" }, { "id": "004", "value": "14" }, { "id": "005", "value": "29" }, { "id": "006", "value": "42" },
            { "id": "007", "value": "15" }, { "id": "008", "value": "25" }, { "id": "009", "value": "15" }, { "id": "010", "value": "99" },
            { "id": "011", "value": "62", 'displayValue': 'Goa,20' }, { "id": "012", "value": "3" }, { "id": "013", "value": "98" },
            { "id": "014", "value": "65" }, { "id": "015", "value": "7" }, { "id": "016", "value": "41" }, { "id": "017", "value": "48" },
            { "id": "018", "value": "9" }, { "id": "019", "value": "24" }, { "id": "020", "value": "18" }, { "id": "021", "value": "58" },
            { "id": "022", "value": "30" }, { "id": "023", "value": "32" }, { "id": "024", "value": "36" }, { "id": "025", "value": "35" },
            { "id": "026", "value": "49" }, { "id": "027", "value": "24" }, { "id": "028", "value": "47" }, { "id": "029", "value": "51" },
            { "id": "030", "value": "45" }, { "id": "031", "value": "16" }, { "id": "036", "value": "35" }, { "id": "032", "value": "79" },
            { "id": "033", "value": "1" }, { "id": "034", "value": "2" }, { "id": "035", "value": "75" }],
        },
        events: {
          entityRollover: function (evt: any, data: any) { },
          entityRollout: function (evt: any, data: any) { },
          entityClick: (evt: any, data: any) => { localStorage.setItem('state', JSON.stringify(data)), document.getElementById('btnState')?.click(); },
        }
      }).render();
    });
  }

  LoadDnClkFC() {
    let cod = this.clrCode0, fcDn = this.fcDn
    Charts(FusionCharts);
    FusionTheme(FusionCharts);
    jQueryFusionCharts(FusionCharts);
    $('document').ready(() => {
      $('#fc-dnI').insertFusionCharts({
        type: 'doughnut2d',
        width: '430',
        height: '350',
        dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": cod,
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "0",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "interactiveLegend": "0",
            "theme": "fusion",
            "usePlotGradientColor": "0",
            // "canvasbgColor": "red", // background of chart
            // "bgColor": "red",
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": fcDn
        }
      });
      $('#fc-dnI').bind('fusionchartsdataplotclick', (event: any, data: any) => {
        this.isHideIND = false; // this.glblSvc.onTstr('s', data.categoryLabel + data.displayValue);
        if (data.categoryLabel == 'Normal') {
          $('#fc-dnI').html(this.LoadMapIND(this.clrCode0[0], this.clrCode1[0], this.clrCode2[0], this.clrCode3[0], this.clrCode4[0],
            this.clrCode5[0]), this.LoadNewFCs('bar2d', 'fc-bar-horz', '350', this.fcBarHrzntl, 0)), this.colorState = this.clrCode0[0],
            this.colorState1 = this.clrCode1[0], this.colorState2 = this.clrCode2[0], this.colorState3 = this.clrCode3[0],
            this.colorState4 = this.clrCode4[0], this.colorState5 = this.clrCode5[0];
        }
        else if (data.categoryLabel == 'Zero Stock') {
          $('#fc-dnI').html(this.LoadMapIND(this.clrCode0[1], this.clrCode1[1], this.clrCode2[1], this.clrCode3[1], this.clrCode4[1],
            this.clrCode5[1]), this.LoadNewFCs('bar2d', 'fc-bar-horz', '350', this.fcBarHrzntl, 1)), this.colorState = this.clrCode0[1],
            this.colorState1 = this.clrCode1[1], this.colorState2 = this.clrCode2[1], this.colorState3 = this.clrCode3[1],
            this.colorState4 = this.clrCode4[1], this.colorState5 = this.clrCode5[1];
        }
        else if (data.categoryLabel == 'Min') {
          $('#fc-dnI').html(this.LoadMapIND(this.clrCode0[2], this.clrCode1[2], this.clrCode2[2], this.clrCode3[2], this.clrCode4[2],
            this.clrCode5[2]), this.LoadNewFCs('bar2d', 'fc-bar-horz', '350', this.fcBarHrzntl, 2)), this.colorState = this.clrCode0[2],
            this.colorState1 = this.clrCode1[2], this.colorState2 = this.clrCode2[2], this.colorState3 = this.clrCode3[2],
            this.colorState4 = this.clrCode4[2], this.colorState5 = this.clrCode5[2];
        }
        else if (data.categoryLabel == 'Max') {
          $('#fc-dnI').html(this.LoadMapIND(this.clrCode0[3], this.clrCode1[3], this.clrCode2[3], this.clrCode3[3], this.clrCode4[3],
            this.clrCode5[3]), this.LoadNewFCs('bar2d', 'fc-bar-horz', '350', this.fcBarHrzntl, 3)), this.colorState = this.clrCode0[3],
            this.colorState1 = this.clrCode1[3], this.colorState2 = this.clrCode2[3], this.colorState3 = this.clrCode3[3],
            this.colorState4 = this.clrCode4[3], this.colorState5 = this.clrCode5[3];
        }
      });
    });
  }

  onClkState() {
    let stData = JSON.parse(<string>localStorage.getItem('state')),
      eMap = ((stData.label == 'Andaman and Nicobar Islands' ? 'andamanandnicobar' :
        (stData.label == 'Dadra and Nagar Haveli' ? 'dadraandnagarhaveli' : (stData.label == 'Jammu and Kashmir' ? 'jammuandkashmir' :
          (stData.label == 'Daman and Diu' ? 'damananddiu' : ' ')))))
    if (eMap == ' ')
      this.LoadStateMap(`maps/${stData.label}`.replace(' ', '').toLowerCase()), $('#lblState').html(stData.label);
    else
      this.LoadStateMap(`maps/${eMap}`);
    $('#lblState').html(stData.label);
  }

  LoadStateMap(state: string, divId: string = 'fc-map-state-ind') {
    let colorState = this.colorState, colorState1 = this.colorState1, colorState2 = this.colorState2, colorState3 = this.colorState3,
      colorState4 = this.colorState4, colorState5 = this.colorState5;
    this.isHideIND = true, FusionCharts.ready(function () {
      var chart = new FusionCharts({
        type: state,//`maps/${state}`,
        renderAt: divId, // "apmap", // container where chart will render
        width: "500",
        height: "500",
        dataFormat: "json",
        dataSource: {
          "chart": {
            "caption": '',
            // "subcaption": " 2021",
            // "showBorder": "0",
            //"tooltext": "{value} {br}{label}",
            // "labelsepchar": " ",
            //"plotToolText": "Store location: $label <br> Sales (YTD): $dataValue <br> $displayValue",
            "numbersuffix": "%",
            "showCanvasBorder": '0',
            "includevalueinlabels": "1",
            "includeNameInLabels": '1',
            'useSNameInLabels': '1',
            "canvasBorderThickness": "1",
            "enableSmartLabels": "1",
            "showMarkerToolTip": '0',
            "showToolTip": "1",
            "entityFillHoverColor": "#f0b74d",
            "nullEntityColor": colorState,     //color
            "nullEntityAlpha": "100",
            "hoverOnNull": "1 ",
            "theme": "fusioncharts/latest/maps/fusioncharts",
            "exportEnabled": "1",
            "exportTargetWindow": "_self",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            'legendPosition': "bottom",
            'showLegend': '1',
            "showLabels": '1',
            "showValues": '1',
          },
          "colorrange": {
            "color": [{
              "minvalue": "0",
              "maxvalue": "20",
              "code": colorState1,// "#AACFAE",
              "displayValue": "< 20"
            },
            {
              "minvalue": "20",
              "maxvalue": "40",
              "code": colorState2, //"#8ed98e",
              "displayValue": "20-40"
            }, {
              "minvalue": "40",
              "maxvalue": "60",
              "code": colorState3, //"#4ec44e",
              "displayValue": "40-60"
            }, {
              "minvalue": "60",
              "maxvalue": "80",
              "code": colorState4,  //"#2f8e2f",
              "displayValue": "60-80"
            }, {
              "minvalue": "80",
              "maxvalue": "100",
              "code": colorState5,  //"#256e25",
              "displayValue": "> 100"
            }]
          },
          "data": [ { "id": "IN.AP.AN", "value": "82", 'displayValue': 'Ananthapor,20', 'showLabel': 1 }, { "id": "IN.AP.CH", "value": "89" },
            { "id": "IN.AP.CU", 'label': 'chithoor', "value": "12", ' showlabel': '1' }, { "id": "IN.AP.EG", "value": "15", },
            { "id": "IN.AP.GU", "value": "42", ' showlabel': '1' }, { "id": "IN.AP.KR", "value": "25", },
            { "id": "IN.AP.PR", "value": "54", 'displayValue': 'Prakasam,20', 'showlabel': '1' }, { "id": "IN.AP.SR", "value": "18", },
            { "id": "IN.AP.VS", "value": "55", ' showlabel': '1' }, { "id": "IN.AP.VZ", "value": "15", },
            { "id": "IN.AP.KU", "value": "45", ' showlabel': '1' }, { "id": "IN.AP.NE", "value": "79", },
            { "id": "IN.AP.WG", "value": "2", }]
        },
      }).render();
    });
  }

}