import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-order-discrepancies-overview',
  templateUrl: './order-discrepancies-overview.component.html',
  styleUrls: ['./order-discrepancies-overview.component.css']
})
export class OrderDiscrepanciesOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
