import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-notifications-bulletin-board',
  templateUrl: './cnfg-notifications-bulletin-board.component.html',
  styleUrls: ['./cnfg-notifications-bulletin-board.component.css']
})
export class CnfgNotificationsBulletinBoardComponent extends BaseComponent implements OnInit {

  apiRspns: any; usrPrmsn: any = null; cnfgntfnPrmsn = true;

  ntfcBltnForm = this.formBuilder.group({
    configJson: this.formBuilder.group({
      enable_bulletin_board: this.formBuilder.group({
        enable: ['']
      }),
      data_options: this.formBuilder.group({
        duration_of_data: [''],
        maximum_number_of_items_on_board: [''],
        refresh_duration: ['']
      }),
      display_options: this.formBuilder.group({
        scroll_interval: [''],
        show_slide_navigator: [false]
      })
    }),
    configTypeId: [+environment.cnfgTypes.ntfcnBltnBrd], updatedBy: [+this.usrLgnDtls?.userId]
  })

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.LoadData();

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_nf_ac') ? (this.cnfgntfnPrmsn = e.action) : '') });
    }
  }

  LoadData() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
      environment.cnfgTypes.ntfcnBltnBrd, null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.apiRspns = this.result.data;
              this.ntfcBltnForm.patchValue(this.apiRspns);
            }
          }
        }
      })
  }

  onSave() {
    let payLoad = this.ntfcBltnForm.getRawValue();
    payLoad.configJson.data_options.duration_of_data = payLoad.configJson.data_options.duration_of_data != '' ? +payLoad.configJson.data_options.duration_of_data : null,
      payLoad.configJson.data_options.maximum_number_of_items_on_board = payLoad.configJson.data_options.maximum_number_of_items_on_board != '' ? +payLoad.configJson.data_options.maximum_number_of_items_on_board : null,
      payLoad.configJson.data_options.refresh_duration = payLoad.configJson.data_options.refresh_duration != '' ? +payLoad.configJson.data_options.refresh_duration : null,
      payLoad.configJson.display_options.scroll_interval = payLoad.configJson.display_options.scroll_interval != '' ? +payLoad.configJson.display_options.scroll_interval : null
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'Record updated successfully')
          this.glblSvc.onTstr('s', this.result.message), this.LoadData();
        // setTimeout(() => { window.location.reload(); }, 1000);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

}
