<form [formGroup]="usrActForm" autocomplete="off">
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-0 p-3">
            <div class="col-xl-12">
                <div style="float: right;" *ngIf="isRprtsHlpShw">
                    <a href="btn btn-sm btn-link" [routerLink]="['/user-activity/help']" target="_blank"><u>Help</u></a> 
                </div>
            </div>
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-xl-3">
                        <strong class="col-form-label">User By</strong><span class="text-danger"><sup>*</sup></span>
                        <div class="form-check form-check-inline mt-2 ">
                            <input class="form-check-input" type="radio" name="isUserBdge" value="badge" (click)="onClkRdb('userBadgeId')"
                                [(ngModel)]="isUserBdge" [checked]="isUserBdge=='badge'" formControlName="isUserBdge">
                            <label class="form-check-label" for="mat">Tag</label>
                        </div>
                        <div class="form-check form-check-inline" *ngIf="!ChkNtnlUsrByPrnthId()">
                            <input class="form-check-input" type="radio" name="isUserBdge" value="name" (click)="onClkRdb('userId')"
                                [(ngModel)]="isUserBdge" [checked]="isUserBdge=='name'" formControlName="isUserBdge">
                            <label class="form-check-label" for="mat">Name</label>
                        </div>
                        <div *ngIf="isUserBdge=='badge'">
                            <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdUsr" [data]="userBadges"
                                formControlName="userBadgeId" (onSelect)="onMultiSlct('s', 'n', $event)"
                                (onSelectAll)="onMultiSlct('a', 'n', $event)" (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.userBadgeId.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.userBadgeId.errors" class="invalid-feedback">
                                <div *ngIf="fc.userBadgeId.errors?.required">User tag is required</div>
                            </div>
                        </div>
                        <div *ngIf="isUserBdge=='name'">
                            <ng-multiselect-dropdown [settings]="dd2Stngs" [data]="lstAllUsrs" [(ngModel)]="slctduserId"
                                formControlName="userId" (onSelect)="onMultiSlct('s', 'f', $event)"
                                (onSelectAll)="onMultiSlct('a', 'f', $event)" (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)" [ngClass]="{ 'is-invalid': isSbmtd && fc.userId.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.userId.errors" class="invalid-feedback">
                                <div *ngIf="fc.userId.errors?.required">User name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-xl-7">
                        <div class="row">
                            <div class="col-xl-4 small form-group">
                                <strong class="col-form-label">From<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='Monthly'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpSDVal" #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                        (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate" 
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='Daily' || pstT == 'Weekly'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                        #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                         (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate"
                                         [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-1 text-center mt-1 p-0" style="margin-top: 2.3rem !important;"><label>-</label></div> 
                            <div class="col-xl-4 small">
                                 <strong class="col-form-label">To<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='Monthly'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpEDVal" #dpED="bsDatepicker" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" readonly
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY',
                                            minMode: 'month', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="yBDate" (bsValueChange)="onChngDT($event, 'ed')" [minDate]="yrMin"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='Daily' || pstT=='Weekly'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                         #dpED="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="maxToDate" [minDate]="dpSDVal" (bsValueChange)="onChngDT($event, 'ed')"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-3 small select2-drpdwn" style="margin-top: 2.3rem !important;">
                                <select class="form-select form-select-sm" [(ngModel)]="pstT" formControlName="pstT"
                                (change)="isSbmtd = false;lstGrid = [];lstGridDate = [];onChngPstT()">
                                <option *ngFor="let pc of lstPrdCtys" [value]="pc.name">{{pc.name}}</option>
                            </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 float-xl-end text-left"><strong>{{lblTextOfDate}}</strong></div>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 mt-3">
                        <strong for="textBox">State</strong>
                        <select formControlName="state" class="form-select form-select-sm"
                            (change)="dstLst=[];blkLst=[];lstGrid=[];lstGridDate=[];onLocChng($event, 'state')" #locState>
                            <option value="null">All</option>
                            <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-3 mt-3">
                        <strong for="textBox">District</strong>
                        <select formControlName="district" class="form-select form-select-sm"
                            (change)="blkLst=[];lstGrid=[];lstGridDate=[];onLocChng($event, 'district')" #locDist>
                            <option value="null">All</option>
                            <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-3 mt-3">
                        <div>
                            <strong for="textBox">Taluk/Block</strong>
                            <select formControlName="block" class="form-select form-select-sm" #locBlck>
                                <option value="null">All</option>
                                <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-xl-3 mt-4" style="margin-top: 2.3rem !important;">
                        <div class="float-xl-end">
                            <button class="btn btn-sm btn-light me-2" type="button" (click)="onCncl();isSbmtd = false;lstGrid =[]">Reset</button>
                            <button class="btn btn-sm btn-primary" type="button" (click)="GenRprt();isSbmtd = true;">Get report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-2">
            <div class="row ">
                <div class="form-group col-xl-3 ms-2">
                    <strong for="inputState-43" class="form-label">Activity</strong>
                    <select id="inputState-43" class="form-select" formControlName="userLoginActivity"
                        (change)="chngActvty($event.target)" [(ngModel)]="drpVal">
                        <option value="1">Number of logins</option>
                        <option value="2">Number of transactions</option>
                        <!-- <option value="3">Number of Indents</option> -->
                    </select>
                </div>
                <div class="form-group col-xl-3">
                  <div *ngIf= isMatric>
                    <strong for="inputState-43" class="form-label">Metric</strong>
                        <ng-multiselect-dropdown [settings]="dropdownSettings"
                            [data]="lstMtrc" formControlName = "usrMatric"                                                                       
                            (onSelect)="onMultiSlct('s', 't', $event)"
                            (onSelectAll)="onMultiSlct('a', 't', $event)"
                            (onDeSelect)="onMultiSlct('r', 't', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 't', $event)"
                            [(ngModel)]="slctdMtrc">
                        </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="form-group col-xl-3" *ngIf="(slctdMtrc[0]?.id == '1' || slctdMtrc[0]?.id == '2') && slctdMtrc.length == 1">
                    <label>Exclude Tracking Object</label>
                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstTrkTypss" [(ngModel)]="trkVal" 
                        formControlName="trackingObjectId" (onSelect)="onMultiSlct('s', 'eT', $event)"  
                        (onSelectAll)="onMultiSlct('a', 'eT', $event)" (onDeSelect)="onMultiSlct('r', 'eT', $event)"
                        (onDeSelectAll)="onMultiSlct('d', 'eT', $event)">
                    </ng-multiselect-dropdown>
                </div>
                <div class="col-xl-6 text-end" hidden>
                    <div class="float-end">
                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                            <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab" data-bs-toggle="tab"
                                    href="#Map" role="tab" aria-controls="Map" aria-selected="true"><i class="fa fa-bar-chart me-0"
                                        aria-hidden="true"></i></a></li>
                            <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab" data-bs-toggle="tab"
                                    href="#Map-1" role="tab" aria-controls="Map-1" aria-selected="false"><i class="fa fa-table me-0"
                                        aria-hidden="true"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade " id="Map" role="tabpanel" aria-labelledby="Map-tab">
                        <div class="row mt-3">
                            <div class="col-xl-12">
                                <div class="card p-0 mb-0">
                                    <div class="card-body p-2">
                                        <div class="row mt-3 mb-3">
                                            <div class="col-xl-12">
                                                <div class="text-center">
                                                    <strong class="mb-0">{{actvity}}</strong> &nbsp;
                                                    <!-- <strong *ngIf ="lstSlctdMetrics.length == 0 ">- All</strong> -->
                                                    <small *ngIf ="lstSlctdMetrics.length != 0 "> <b>-</b>{{lstMetrc}} </small> <br>
                                                    <div *ngIf ="frmDate != '' && toDate != ''">
                                                        <small><strong>From:</strong> {{frmDate}} <strong>To:</strong> {{toDate}} &nbsp;</small>
                                                    </div>
                                                    <span *ngIf="isUserBdge=='badge'"><small><strong>user Tag:</strong> {{lstUsrsTag}} </small></span>   
                                                   <span *ngIf="isUserBdge=='name'"><small><strong>user:</strong>{{lstUsrsName}} </small></span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-8 offset-xl-2  centered-div">
                                                <div class="text-center">
                                                    <div id="fcbarInv" class="text-center"></div>
                                                </div>
                                            </div>
                                            <div class="centered-div mt-3 text-center">
                                                <a>Download as CSV</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade show active" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                        <div *ngIf="!isShwDayTable">
                            <div class="row mt-4">
                                <div class="col-xl-12">
                                    <div class="col-xl-12" *ngIf="lstGrid?.length!=0">
                                        <div class="text-center" >
                                            <strong class="mb-0">{{actvity}}</strong>&nbsp;
                                            <!-- <strong *ngIf ="lstSlctdMetrics.length == 0 && actvity != 'Number of logins' ">- All</strong> -->
                                            <small *ngIf ="lstSlctdMetrics.length != 0 "><b>-</b>&nbsp;{{lstMetrc}} </small> <br>
                                            <small><strong>From:</strong> {{frmDate}} <strong>To:</strong> {{toDate}} &nbsp;</small>
                                            <span *ngIf="isUserBdge=='badge'"><small><strong>user Tag:</strong> {{lstUsrsTag}} </small></span>   
                                           <span *ngIf="isUserBdge=='name'"><small><strong>user:</strong>{{lstUsrsName}} </small></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row" *ngIf="lstGrid?.length!=0">
                                        <div class="col-xl-12 mb-2">
                                            <div class="row m-1">
                                                <div class="col-xl-2">
                                                    <button class="btn btn-sm btn-success" (click)="GenRprt('e')">
                                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                                    </button>
                                                </div>
                                                <div class="col-xl-3">
                                                    <select class="form-select form-select-sm" [(ngModel)]="expDrpn" [ngModelOptions]="{standalone: true}">
                                                        <option value="1">Same as table</option>
                                                        <option value="2">User wise</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div *ngIf="lstGrid?.length!=0" class="mt-2">{{pageEntrs}}</div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dm')" id="pcFclitys"></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-wrap" *ngIf="lstGrid.length!=0">
                                        <table class="text-center table-small-font main-table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <th style="width: 10%">Item No.</th>
                                                                <th style="width: 30%" class="text-center">State</th>
                                                                <th style="width: 25%" class="text-center" *ngIf="usrActForm?.get('state')?.value!=null && usrActForm?.get('state')?.value!='null'">District</th>
                                                                <th style="width: 35%" class="text-center" *ngIf="usrActForm?.get('district')?.value!=null && usrActForm?.get('district')?.value!='null'">User</th>
                                                            </tr>
                                                        </table>
                                                    </th>
                                                    <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-2 pntr" (click)="crntDPage=1;GetDailyData(hdr, 'g')">{{(isDtCng == 'Monthly' || isDtCng == 'Weekly') ? (hdr) : (hdr == 'Total Count' ? hdr: (hdr | date:'dd-MM-yyyy'))}}</th>                                            
                                                </tr>
                                                <tr>
                                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <th style="width: 10%">Total</th>
                                                            </tr>
                                                        </table>
                                                    </th>                                            
                                                    <th *ngFor="let ttl of ttlCount" class="tbl-hdr-clr p-2 pntr">{{ttl}}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="lstGrid.length!=0">
                                                <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms, id: 'pcFclitys' }; let i=index">
                                                    <td colspan="5" class="freeze">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <td style="width: 10%" class="text-left">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                                <td style="width: 30%" class="text-center" *ngIf="usrActForm?.get('state')?.value!=null && usrActForm?.get('state')?.value!='null'">{{steRes}}</td>
                                                                <td style="width: 25%" class="text-center" *ngIf="usrActForm?.get('district')?.value!=null && usrActForm?.get('district')?.value!='null'">{{dstRes}}</td>
                                                                <td style="width: 35%" class="text-center f-14">{{item.kName}}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td *ngFor="let col of item.matData" >{{col.noOfCount}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-3" *ngIf="lstGrid?.length==0">
                                        <div class="text-center col-xl-12" *ngIf="lstGrid?.length==0">{{noRcrdTxt}} </div>
                                    </div>
                                    <div class="pb-3 pt-3">
                                        <div class="row" *ngIf="lstGrid?.length!=0">
                                            <div class="col-xl-6 text-start">
                                                {{pageEntrs}}
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="float-end">
                                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dm')" id="pcFclitys"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="lstGridDate?.length!=0 && (isDtCng == 'Monthly' || isDtCng == 'Weekly') && isShwDayTable;">
                            <div class="row mt-4">
                                <div class="col-xl-12">
                                    <div class="col-xl-12">
                                        <div class="text-center">
                                            <strong class="mb-0">{{actvity}}</strong>&nbsp;
                                            <small *ngIf ="lstSlctdMetrics.length != 0 "><b>-</b>&nbsp;{{lstMetrc}} </small> <br>
                                            <small ><strong>From:</strong> {{frmDates}} <strong>To:</strong> {{toDates}} &nbsp;</small>
                                            <span *ngIf="isUserBdge=='badge'"><small><strong>user Tag:</strong> {{lstUsrsTag}} </small></span>   
                                            <span *ngIf="isUserBdge=='name'"><small><strong>user:</strong>{{lstUsrsName}} </small></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-sm-12 col-xs-12" *ngIf="lstGridDate?.length!=0">
                                    <div class="row m-1">
                                        <div class="col-xl-2">
                                            <button class="btn btn-sm btn-success" (click)="GetDailyData('','e')">
                                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                            </button>
                                        </div>
                                        <div class="col-xl-3">
                                            <select class="form-select form-select-sm" [(ngModel)]="expDrpnDly" [ngModelOptions]="{standalone: true}">
                                                <option value="1">Same as table</option>
                                                <option value="2">User wise</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-7 float-end">
                                            <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable = false">
                                              Back
                                            </button>
                                        </div>
                                        <div class="col-xl-6">
                                            <div *ngIf="lstGridDate?.length!=0" class="mt-2">{{pageEntrsD}}</div>
                                        </div>
                                        <div class="col-xl-6">
                                            <div class="float-end" *ngIf="lstGridDate?.length!=0">
                                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dd')" id="pcFclitysDay"></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-wrap" *ngIf="lstGridDate.length!=0">
                                        <table class="text-center table-small-font main-table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <th style="width: 10%">Item No.</th>
                                                                <th style="width: 30%" class="text-left">State</th>
                                                                <th style="width: 25%" class="text-left" *ngIf="usrActForm?.get('state')?.value!=null && usrActForm?.get('state')?.value!='null'">District</th>
                                                                <th style="width: 35%" class="text-left" *ngIf="usrActForm?.get('district')?.value!=null && usrActForm?.get('district')?.value!='null'">User</th>
                                                            </tr>
                                                        </table>
                                                    </th>                                            
                                                    <th *ngFor="let hdr of lstGridDate[0]?.hdrs" class="tbl-hdr-clr p-2">{{hdr == 'Total Count' ? hdr: (hdr | date:'dd-MM-yyyy')}}</th>
                                                </tr>
                                                <tr>
                                                    <th colspan="5" class="freeze tbl-hdr-clr">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <th style="width: 10%">Total</th>
                                                            </tr>
                                                        </table>
                                                    </th>                                            
                                                    <th *ngFor="let ttl of ttlCountDly" class="tbl-hdr-clr p-2 pntr">{{ttl}}</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="lstGridDate.length!=0">
                                                <tr *ngFor="let item of lstGridDate | paginate: { itemsPerPage: pageSize, currentPage: crntDPage, totalItems: ttlDayItms, id: 'pcFclitysDay' }; let i=index">
                                                    <td colspan="5" class="freeze">
                                                        <table class="w-300p break-word-td">
                                                            <tr>
                                                                <td style="width: 10%" class="text-left">{{(pageSize*(crntDPage-1))+i+1}}</td>
                                                                <td style="width: 30%" *ngIf="usrActForm?.get('state')?.value!=null && usrActForm?.get('state')?.value!='null'">{{steResDly}}</td>
                                                                <td style="width: 25%" *ngIf="usrActForm?.get('district')?.value!=null && usrActForm?.get('district')?.value!='null'">{{dstResDly}}</td>
                                                                <td style="width: 35%" class="text-left f-14">{{item.kName}}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td *ngFor="let col of item.matData" >{{col.noOfCount}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pb-3 pt-3">
                                        <div class="row" *ngIf="lstGridDate?.length!=0">
                                            <div class="col-xl-6 text-start">
                                                {{pageEntrsD}}
                                            </div>
                                            <div class="col-xl-6">
                                                <div class="float-end">
                                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'dd')" id="pcFclitysDay"></pagination-controls>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="lstGridDate?.length ==0 && (isDtCng == 'Monthly' || isDtCng == 'Weekly') && isShwDayTable">
                            <div class="row">
                                <div class="col-xl-12">
                                    <button class="btn btn-sm btn-primary float-end" (click)="isShwDayTable=false;">Back</button>
                                </div>
                                <div class="text-center col-xl-12">{{noRcrdTxt}} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>