<div class="row p-0 mt-3 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <form>
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Handling units</label>
                            <input type="text" placeholder="Handling units" class="form-control form-control-sm" id="">
                        </div>
                        <div class="col-xl-9">
                            <div class="float-xl-end pt_2">
                                <button class="btn btn-sm btn-light me-2">Reset</button>
                                <button class="btn btn-sm btn-primary">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<hr />
<div class="row mt-3">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="col-xl-12 mb-2">
                <span style="display: inline-block;" class="me-2">
                    <label tyle="display: inline-block;">Filter</label>
                </span>
                <span style="display: inline-block;">
                    <!-- <label tyle="display: inline-block;">Filter</label>  -->
                    <select class="form-select form-select-sm">
                        <option>5</option>
                        <option>10</option>
                    </select>
                </span>
                <span style="display: inline-block;">
                    <label tyle="display: inline-block;" class="ms-3">Search</label>
                </span>
                <span style="display: inline-block;" class="ms-2">
                    <input type="text" class="form-control form-control-sm ms-3" />
                </span>
            </div>
        </div>
        <div class="table_spacing table-responsive table-row-spacing">
            <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-center">Item No.</th>
                        <th>Handling units</th>
                        <th>Description</th>
                        <th>Contents</th>
                        <th>Last updated</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td class="text-center">1</td>
                        <td (click)=onHandlingClk()><a>BCG diluent vial(s)</a></td>
                        <td>ssferd</td>
                        <td><a>BCG diluent (dose)</a>: 10</td>
                        <td><span><a>Abhimanyu Saxena</a></span>
                            <p class="small">9/3/21 8:17 PM</p>
                        </td>
                        <td (click)=onHandlingEdit()><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td class="text-center">2</td>
                        <td><a>BCG (dose)</a></td>
                        <td></td>
                        <td><a>BCG diluent (dose)</a>: 10</td>
                        <td><span><a>Abhimanyu Saxena</a></span>
                            <p class="small">9/3/21 8:17 PM</p>
                        </td>
                        <td><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td class="text-center">3</td>
                        <td><a>BCG ABC</a></td>
                        <td>bcg</td>
                        <td><a>BCG diluent (dose)</a>: 10</td>
                        <td><span><a>Abhimanyu Saxena</a></span>
                            <p class="small">9/3/21 8:17 PM</p>
                        </td>
                        <td><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                    </tr>
                    <tr>
                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                        <td class="text-center">4</td>
                        <td><a>BCG diluent (dose)</a></td>
                        <td>COVISHIELD (dose)</td>
                        <td><a>BCG diluent (dose)</a>: 10</td>
                        <td><span><a>Abhimanyu Saxena</a></span>
                            <p class="small">9/3/21 8:17 PM</p>
                        </td>
                        <td><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row ps-0 pe-0 pt-3 pb-3">
            <div class="col-lg-6 col-xxl-6 pb-3">
            </div>
            <div class="col-lg-6 col-xxl-6 pb-3">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination justify-content-xl-end  pagination-primary ">
                        <li class="page-item"><a class="page-link" aria-label="Previous"
                                data-bs-original-title="" title=""><span aria-hidden="true">«</span><span
                                    class="sr-only">Previous</span></a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a>
                        </li>
                        <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a>
                        </li>
                        <li class="page-item"><a class="page-link" aria-label="Next" data-bs-original-title=""
                                title=""><span aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>