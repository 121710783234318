import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-setup-inventory',
  templateUrl: './setup-inventory.component.html',
  styleUrls: ['./setup-inventory.component.css']
})

export class SetupInventoryComponent extends BaseComponent implements OnInit {

  bulkUploadForm: FormGroup; uploadStatus= ''; successCount = ''; errorCount = ''; downloadLink = ''; uploadResult = false; uploadStatusResult = false;
  smTmpltUrl = environment.webURL + environment.upldFileTmplts.inventory;
  prmsns: any = null; stckMngmntBlkUpld: boolean = true; isBDShow = false; updateBatchDtl: boolean =true;

  constructor(public glblSvc: GlobalService, private formBuilder: FormBuilder) { 
    super(glblSvc);
    this.bulkUploadForm = this.formBuilder.group({uploadedFile: ['', Validators.required], fileName: ''}),
    this.slctDmnDetails = JSON.parse(<string>localStorage.getItem('slctdDmnDtls')),
    this.isBDShow = (this.usrLgnDtls?.roleId == 3 || this.usrLgnDtls?.roleId == 6) && (this.slctDmnDetails?.pranthLevel == 'National' || this.slctDmnDetails?.pranthLevel == 'State' );

  }

  ngOnInit(): void { 
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_sm_bu')
     this.stckMngmntBlkUpld = e?.action;
     if(e?.menuCode =='mm_st_sm_udom_udoe')
     this.updateBatchDtl = e?.action;
  });
  }

  onFileSelect(event: any) {
    if (event.target.files.length > 0) {     
      const file = event.target.files[0];
      console.log(file);
      this.bulkUploadForm.patchValue({fileName: file});      
    }
  }

  hasExtension(exts: any) {
    var fileName = this.bulkUploadForm.value.uploadedFile;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  uploadFile(){
    console.log(this.bulkUploadForm.get('fileName')?.value);
    if( this.bulkUploadForm.get('fileName')?.value == ''){
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
    } else if(this.hasExtension(['.csv'])){      
      this.uploadStatusResult = true;
      this.uploadStatus = 'In Progress';
      const formData = new FormData();
      formData.append('file', this.bulkUploadForm.get('fileName')?.value);
      this.onFormData(environment.apiPaths.invntry, environment.apiMthds.invntryBlkSave, formData, 0, true, 0).then(() => {
        if (this.result) {
          if(this.result.returnCode === 1 && this.result.message === "Success")
            this.uploadResult = true,
            this.uploadStatus = 'Done', this.successCount = this.result.data.successCount + ' success', this.errorCount = this.result.data.errorCount + ' error(s)',
            this.downloadLink = this.result.data.downloadUrl,
            this.glblSvc.onTstr('s', this.result.message), this.bulkUploadForm.reset();     
          else
            this.uploadStatusResult = false, this.uploadResult = false,
            this.uploadStatus = 'Failed', this.successCount = '', this.errorCount = '',            
            this.glblSvc.onTstr('e', this.result.message), this.bulkUploadForm.reset();
        }
      })
    } else{
      this.glblSvc.onTstr('w', 'Upload only CSV files'), this.bulkUploadForm.reset();
    }   
  }

  onDwnld() {
    if (this.downloadLink)
      this.OpenFileInNewTab(this.downloadLink);
  }

}