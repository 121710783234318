<div class="card p-0">
    <div class="card-header border-0 p-3">
        <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Order-tab" data-bs-toggle="tab" href="#Order" role="tab"
                    aria-controls="Order" aria-selected="true">Overview</a></li>
            <li class="nav-item"><a class="nav-link" id="Create-order-tab" data-bs-toggle="tab" href="#Create-order"
                    role="tab" aria-controls="Create-order" aria-selected="false" hidden>Stock out prediction</a></li>
        </ul>
    </div>
    <div class="card-body border-0 p-3 pt-0">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active pb-2" id="Order" role="tabpanel" aria-labelledby="Order-tab">
                <evin-db-inv-overview></evin-db-inv-overview>
            </div>
            <div class="tab-pane fade pb-2" id="Create-order" role="tabpanel" aria-labelledby="Create-order-tab" hidden>
                <evin-db-inv-stock-out-prediction></evin-db-inv-stock-out-prediction>
            </div>
        </div>
    </div>
</div>