<form [formGroup]="matCreateForm" autocomplete="off">
  <div class="card mb-0 p-0">
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Material information</h6>
    </div>
    <div class="card-body p-4 pt-3 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <span class="small m-0">Please specify basic Material information</span>
          <div class="row mb-2">
            <div class="form-group col-xl-3 mt-3">
              <label for="name">Material name <span class="text-danger">*</span></label>
              <input type="text" placeholder="Material name" class="form-control form-control-sm" id="name"
                formControlName="name" name="name" [ngClass]="{ 'is-invalid': isSbmtd && fc.name.errors }">
              <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                <div *ngIf="fc.name.errors.required">Material name is required</div>
              </div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Short name</label>
              <input type="text" placeholder="Short name" class="form-control form-control-sm" id="short_name"
                formControlName="short_name" maxlength="6">
              <p class="small">enter a short name up to 6-characters, if needed, to minimize data sent to the mobile via
                SMS</p>
            </div>
            <!-- <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Custom ID</label>
              <input type="text" placeholder="Custom ID" class="form-control form-control-sm" id="">
            </div> -->
            <div class="form-group col-xl-3 mt-3">
              <label for="description">Description</label>
              <input type="text" placeholder="Description" class="form-control form-control-sm" name="description"
                id="description" formControlName="description">
            </div>
            <!-- <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Additonal information</label>
              <textarea id="" placeholder="Additonal information" class="form-control taRS"></textarea>
              <input class="form-check-input" type="checkbox" id="checkboxNoLabel"
                aria-label="...">&nbsp;&nbsp; <span class="small">Show on mobile phone (400 characters allowed)</span>
            </div> -->
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Material Category<span class="text-danger">*</span></label>
              <select class="form-select form-select-sm" formControlName="product_badge_ids" 
              [ngClass]="{ 'is-invalid': isSbmtd && fc.product_badge_ids.errors }">
                <option value="">Select</option>
                <option *ngFor="let tags of lstMatTags" [value]="tags.id">{{tags.name}}</option>
              </select>              
              <div *ngIf="isSbmtd && fc.product_badge_ids.errors" class="invalid-feedback">
                <div *ngIf="fc.product_badge_ids.errors?.required">Material category is required</div>
              </div>
              <!-- <ng-multiselect-dropdown [settings]="dropdownSettings"
                [data]="lstMatTags" placeholder="Categories"
                formControlName="product_badge_ids"
                (onSelect)="onChng('prdBdge', $event, 'os')"
                (onSelectAll)="onChng('prdBdge', $event, 'osa')"
                (onDeSelect)="onChng('prdBdge', $event, 'ds')"
                (onDeSelectAll)="onChng('prdBdge', $event, 'dsa')">
              </ng-multiselect-dropdown> -->
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Batch management</label>
              <div><input class="form-check-input" type="checkbox" id="is_batch_enabled" aria-label="..."
                  formControlName="is_batch_enabled">&nbsp;&nbsp;
                <span class="small"> Enable batch management</span>
              </div>
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Seasonal</label>
              <div><input class="form-check-input" type="checkbox" id="is_seasonal" aria-label="..."
                  formControlName="is_seasonal"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="card mb-0 mt-3 p-0">
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Pricing information</h6>
    </div>
    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <span class="small m-0">Please specify the pricing information such as MSRP, Retailer's price and
            Currency</span>
          <div class="row mb-2">
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">MSRP</label>
              <input type="text" placeholder="Manufacturer-specified retail price" class="form-control form-control-sm"
                id="">
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Retailer's price</label>
              <input type="text" placeholder="Retailer's price" class="form-control form-control-sm" id="">
            </div>
            <div class="form-group col-xl-3 mt-3">
              <label for="textBox">Currency</label>
              <select class="form-select form-select-sm" disabled>
               
                <option selected>Indian Rupees (INR)</option>
               
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="card mb-0 mt-3 p-0">
    <div class="card-header p-2 ps-3 pe-3">
      <h6 class="mb-0">Temperature</h6>
    </div>
    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
      <div class="row ">
        <div class="col-xl-12">
          <p class="small">Enable monitoring of temperature for this material</p>
          <div class="row mb-2">
            <div class="form-group col-xl-3">
              <label for="textBox">Temperature sensitive</label>
              <div>
                <input class="form-check-input" type="checkbox" id="is_temperature_sensitive" aria-label="..."
                  formControlName="is_temperature_sensitive">&nbsp;&nbsp;
                Is material temperature sensitive?
              </div>
            </div>
            <div class="form-group col-xl-3">
              <label for="textBox">Specify a valid temperature range for this material</label>
              <div class="row">
                <div class="col-xl-6">Min.:&nbsp;&nbsp;&nbsp;<input type="text" placeholder="Min &deg;C"
                    class="form-control form-control-sm" id="minimum_temperature" formControlName="minimum_temperature"
                    onkeypress='return ((event.charCode >=48 && event.charCode <=57) || event.charCode == 45 || event.charCode == 46)'>
                </div>
                <div class="col-xl-6">Max.:&nbsp;&nbsp;&nbsp;<input type="text" placeholder="Max &deg;C"
                    class="form-control form-control-sm" id="maximum_temperature" formControlName="maximum_temperature"
                    onkeypress='return ((event.charCode >=48 && event.charCode <=57) || event.charCode == 45 || event.charCode == 46)'>
                </div>
              </div>
            </div>
            <div class="form-group col-xl-3">
              <label for="handling_unit">Material handling unit</label>
              <input class="form-control form-control-sm" type="text" id="handling_unit" aria-label="..."               
                [ngClass]="{ 'is-invalid': isSbmtd && fc.handling_unit.errors }" maxlength="3"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                formControlName="handling_unit" placeholder="Material handling unit">
              <div *ngIf="isSbmtd && fc.handling_unit.errors" class="invalid-feedback">
                <div *ngIf="fc.handling_unit.errors.min || fc.handling_unit.errors.max">
                  Material handling unit should accepts between 1 to 100
              </div>  
              </div> <!-- <div *ngIf="fc.handling_unit.errors.required">Material handling unit is required</div> onkeypress='return event.charCode >= 48 && event.charCode <= 57' -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-xl-12">
      <div class="float-xl-end">
        <button class="btn btn-sm btn-primary me-2 mb-2" (click)="isSbmtd = true; saveMaterilas()">Save</button>
        <button class="btn btn-sm btn-light mb-2" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</form>