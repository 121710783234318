<form [formGroup]="cnfgeventSumFrm">
    <div class="row mt-2">
        <div class="col-sm-12">
            <div class="card shadow-sm p-0 mt-0 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Push Notifications</div>
                </div>
                <div class="card-body p-2">
                    <small>Specify the push notifications to be enabled or disabled for each event</small>
                    <div class="row mt-3">
                        <div class="form-group col-xl-12 mb-2">
                            <label for="textBox" class="f-w-500 mb-2 me-2">Enable push
                                notifications</label><br />
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                <label class="form-check-label" for="flexCheckChecked">
                                    Enable notification for no login events
                                </label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                <label class="form-check-label" for="flexCheckChecked">
                                    Enable notification for good data entry performance events
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>

            </div>
            <div class="card shadow-sm p-0 mt-3 mb-0">
                <div class="card-header p-2">
                    <div class="h6 mb-0">Login</div>
                </div>
                <div class="card-body p-2">
                    <div class="small">
                        Specify the thresholds for no user activity events where duration of no login by
                        user category(s) is greater than expected time period.
                    </div>
                    <div [hidden]="!isShow">
                        <div class="table_spacing table-responsive table-row-spacing">
                            <table class="table table-sm table_spacing table-small-font p-0">
                                <thead>
                                    <tr>
                                        <th>SI.No.</th>
                                        <th>Duration not logged in (>= days)</th>
                                        <th>Include users with tags</th>
                                        <th>Exclude users with tags</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>15</td>
                                        <td>DIO</td>
                                        <td></td>
                                        <td><a><i class="fa fa-trash" aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                        class="fa fa-pencil" aria-hidden="true"></i></a></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary"
                                (click)="isShow=false" *ngIf="cnfgEvntSmPrmsn">Add</button>&nbsp;
                        </div>
                    </div>
                    <div [hidden]="isShow">
                        <div class="row">
                            <div class="form-group col-xl-4 mt-2">
                                <label for="textBox" class="">Duration not logged in >=</label>
                                <div class="row">
                                    <div class="row">
                                        <div class="col-xl-9">
                                            <input type="text" placeholder="Duration not logged in" value=""
                                                class="form-control" id="">
                                        </div>
                                        <div class="col-xl-3">
                                            <p class="small"> days</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <span class="pl-xl-3">Users Category:
                                    <span *ngIf="isUsrAll">ALL</span>
                                    <span *ngIf="!isUsrAll && lstShowUsrTags.length != 0">
                                        <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowUsrTags">
                                            {{mc.name}}
                                        </span>
                                    </span>
                                </span>&nbsp;
                                <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                    role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                    <i class="fa fa-filter"></i>
                                </span>
                                <div class="row">
                                    <div class="col">
                                        <div class="collapse multi-collapse" id="multiCollapseExample2">
                                            <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                <div class="row">
                                                    <div class="col-xl-6 col-sm-12">
                                                        <div class="row">
                                                            <div class="">                                         
                                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [data]="userBadges"
                                                                    formControlName="productBadge"
                                                                    (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                    (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                    (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                    (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                    [(ngModel)]="slctdUsr">
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-sm-12">
                                                        <div class="float-end">
                                                            <button class="btn btn-sm btn-outline-light me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2"
                                                                (click)="onResetTags()" *ngIf="cnfgEvntSmPrmsn">Reset</button>
                                                            <button class="btn btn-sm btn-primary me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2"
                                                                href="#multiCollapseExample2" role="button"
                                                                (click)="onTagsSave()" *ngIf="cnfgEvntSmPrmsn">Save</button>
                                                            <button class="btn btn-sm btn-light" (click)="onCncl()" *ngIf="cnfgEvntSmPrmsn"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>&nbsp;
                            <button type="button" class="btn btn-sm btn-danger" (click)="isShow=true" *ngIf="cnfgEvntSmPrmsn">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mb-3 p-0 pb-0 mt-2 pt-2">
                <div class="card-header p-2">
                    <h6>Good Data Entry Performance By Facility</h6>
                </div>
                <div class="card-body p-2">
                    <small>Specify the thresholds for events where data entry at a facility is within
                        expected time period over specified duration</small>

                    <div [hidden]="!isShow1">
                        <div class="table_spacing table-responsive table-row-spacing">
                            <table class="table table-sm table_spacing table-small-font p-0">
                                <thead>
                                    <tr>
                                        <th>SI.No.</th>
                                        <th>Data entry within (<= days)</th>
                                        <th>Over a period of (months)</th>
                                        <th>Include Facilities with tags</th>
                                        <th>Exclude Facilities with tags</th>
                                        <th>Users (tags) responsible for maintaining good performance</th>
                                        <th *ngIf="cnfgEvntSmPrmsn"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>3</td>
                                        <td>CCP</td>
                                        <td></td>
                                        <td>CCH</td>
                                        <td *ngIf="cnfgEvntSmPrmsn"><a><i class="fa fa-trash" aria-hidden="true"></i></a>&nbsp;<span><a><i
                                                        class="fa fa-pencil" aria-hidden="true"></i></a></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary"
                                (click)="isShow1=false" *ngIf="cnfgEvntSmPrmsn">Add</button>&nbsp;
                        </div>
                    </div>
                    <div [hidden]="isShow1">
                        <div class="row mt-3">
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Data entry within &lt;=</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Data entry within" value="" class="form-control"
                                            id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> days</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-6 mt-2">
                                <label for="textBox" class="">Over a period of</label>
                                <div class="row">
                                    <div class="col-xl-10">
                                        <input type="text" placeholder="Over a period of" value="" class="form-control"
                                            id="">
                                    </div>
                                    <div class="col-xl-2">
                                        <p class="small"> months</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <span class="pl-xl-3">Users Category:
                                    <span *ngIf="isUsrAll">ALL</span>
                                    <span *ngIf="!isUsrAll && lstShowUsrTags.length != 0">
                                        <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowUsrTags">
                                            {{mc.name}}
                                        </span>
                                    </span>
                                </span>&nbsp;
                                <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                    role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                    <i class="fa fa-filter"></i>
                                </span>
                                <div class="row">
                                    <div class="col">
                                        <div class="collapse multi-collapse" id="multiCollapseExample2">
                                            <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                <div class="row">
                                                    <div class="col-xl-6 col-sm-12">
                                                        <div class="row">
                                                            <div class="">                                         
                                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [data]="userBadges"
                                                                    formControlName="productBadge"
                                                                    (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                    (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                    (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                    (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                    [(ngModel)]="slctdUsr">
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-sm-12">
                                                        <div class="float-end">
                                                            <button class="btn btn-sm btn-outline-light me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2"
                                                                (click)="onResetTags()" *ngIf="cnfgEvntSmPrmsn">Reset</button>
                                                            <button class="btn btn-sm btn-primary me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2"
                                                                href="#multiCollapseExample2" role="button"
                                                                (click)="onTagsSave()" *ngIf="cnfgEvntSmPrmsn">Save</button>
                                                            <button class="btn btn-sm btn-light" (click)="onCncl()"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2" *ngIf="cnfgEvntSmPrmsn">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-end p-3">
                            <button type="button" class="btn btn-sm btn-primary me-2" *ngIf="cnfgEvntSmPrmsn">Save</button>
                            <button type="button" class="btn btn-sm btn-danger" *ngIf="cnfgEvntSmPrmsn" (click)="isShow1=true">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="text-end p-3">
                <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgEvntSmPrmsn">Save</button>
            </div>
        </div>
    </div>
</form>