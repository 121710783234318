import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

import { FormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
@Component({
  selector: 'evin-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})

export class UsersEditComponent extends BaseComponent implements OnInit {

  usrId: any; isDup = false; dupMsg = ''; isSbmtd = false; userBadges: any[] = []; usrDetails: any[] = []; isDistMndtry = false;
  maxdate = new Date(); slctdBadge: any[] = []; blkLst: any[] = []; stsLst: any[] = []; gndr = ''; usrRoleId: any = '';
  usrEditForm = this.formBuilder.group({ address: [''], alt_number: ['', [Validators.minLength(10), Validators.maxLength(13)]], block: [''],
    city_id: [''], country_id: ['', Validators.required], district: [''], dob: [''],
    email: ['', [Validators.required, Validators.email]], first_name: ['', Validators.required], mobile_brand: [''], mobile_model: [''],
    language_id: [''], last_name: [''], mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
    permissions: [''], pin: ['', [Validators.minLength(6), Validators.maxLength(6)]], role_id: ['', Validators.required],
    gender: [null, Validators.required], sim: ['', [Validators.maxLength(50)]], state: ['', Validators.required], time_zone_id: ['', Validators.required],
    token_expiry_indays: ['', [Validators.minLength(1), Validators.maxLength(999)]], browser_details: [null],
    username: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]], user_badge: [null, Validators.required],
    remote_login: ['Default as Domain'], msp: ['', [Validators.maxLength(50)]], //cpwd: ['', Validators.required], 
    updated_by: [''], created_by: [null] }); //{ validator: this.MustMatch('dpassword', 'cpwd') }); 
  rolesLst: any[] = []; LngLst: any[] = []; tmZoneLst: any[] = []; cntryLst: any[] = []; usrLvl = ''; dstLst: any[] = []; pranthId:any;

  constructor(public glblSvc: GlobalService, private router: Router, private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.tmZoneLst = s.tmZoneLst, this.cntryLst = s.cntriesLst, this.LngLst = s.LngLst,
      this.rolesLst = s.rlsLst.filter((f: any) => f.moduleName == 'General'), this.dstLst = s.dstLst }), this.glblSvc.sbjctBdgsList.
      subscribe((a: any) => { this.userBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.user) }), this.maxdate.
      setFullYear(this.maxdate.getFullYear() - 18);
  }

  ngOnInit(): void {
    this.glblSvc.sbjctUsrId.subscribe(s => { this.usrId = s;
      if (this.usrId > 0)
        this.getUserById(this.usrId)
      else
        this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } })
    }), this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
  }

  onItemSelect($event: any) {
    this.slctdBadge = [], this.slctdBadge.push({ badgeId: +$event.target.value });
  }

  get fc() { return this.usrEditForm.controls; }

  onRoleChng(evnt: any, type: string = 'e') {
    let roleId: string = type == 'e' ? evnt : evnt?.target?.value;
    if (roleId != '') {
      let roleName: string = this.rolesLst.filter(f => f.id == roleId)[0]?.name;
      if (this.usrLgnDtls.roleName == 'Administrator' && (roleName == 'Manager' || roleName == 'Operator'))
        this.usrEditForm.get('district')?.setValidators([Validators.required]), this.isDistMndtry = true;
      else
        this.usrEditForm.get('district')?.setValidators(null), this.isDistMndtry = false;
    }
    else
      this.usrEditForm.get('district')?.setValidators(null), this.isDistMndtry = false;
    this.usrEditForm.get('district')?.updateValueAndValidity();
  }

  CheckDuplicateUN() {
    this.isDup = false, this.dupMsg = '', this.FetchData(environment.apiCalls.get, environment.apiPaths.users,
      environment.apiMthds.finduserbyusername.replace('username', this.usrEditForm.get('user_id')?.value), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === 'No record found')
              this.isDup = false, this.dupMsg = '', this.usrEditForm.get('user_id')?.setValidators(null);
            else
              this.isDup = true, this.dupMsg = this.result.message, this.usrEditForm.get('user_id')?.setValidators(Validators.required);
            this.usrEditForm.get('user_id')?.updateValueAndValidity();
          }
        }
      });
  }

  getUserById(usrId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getUsrbyId.replace('uuu', usrId), null, 7).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.usrDetails = this.result.data != null ? this.result.data : [];
          this.usrEditForm.patchValue({ alt_number: this.usrDetails[0]?.alt_number, id: this.usrDetails[0]?.id, username: this.usrDetails[0]?.username,
            role_id: this.usrDetails[0]?.role_id ?? '', permissions: (this.usrDetails[0]?.permissions == '' || this.usrDetails[0]?.permissions == null) ? 'default' : this.usrDetails[0]?.permissions, first_name: this.usrDetails[0]?.first_name,
            token_expiry_indays: this.usrDetails[0].token_expiry_indays, last_name: this.usrDetails[0]?.last_name,
            gender: this.usrDetails[0]?.gender ?? '', dob: this.usrDetails[0]?.dob != null ? (new Date(this.usrDetails[0]?.dob)): '', mobile: this.usrDetails[0]?.mobile,
            email: this.usrDetails[0]?.email, country_id: +this.usrDetails[0]?.country_id, state: +this.usrDetails[0]?.state_id,
            language_id: this.usrDetails[0]?.language_id ?? '', user_badge: this.usrDetails[0]?.badgeids, sim: this.usrDetails[0]?.sim,
            district: +this.usrDetails[0]?.district_id, msp: this.usrDetails[0]?.msp, browser_details: this.usrDetails[0].browser_details,
            time_zone_id: this.usrDetails[0]?.time_zone_id, mobile_app_version: this.usrDetails[0]?.mobile_app_version,
            mobile_brand: this.usrDetails[0]?.mobile_brand, mobile_model: this.usrDetails[0]?.mobile_model, pin: this.usrDetails[0].pin,
            ip_address: this.usrDetails[0].ip_address, created_by: this.usrDetails[0].created_by, created_on: this.usrDetails[0].created_on,
            created_user: this.usrDetails[0].created_user, last_login_time: this.usrDetails[0].last_login_time,
            last_reconnected_time: this.usrDetails[0].last_reconnected_time, last_remote_login_time: this.usrDetails[0].last_remote_login_time,
            updated_by: this.usrDetails[0].updated_by, updated_on: this.usrDetails[0].updated_on, updated_user: this.usrDetails[0].updated_user,
            city_id: this.usrDetails[0].city_id, address: this.usrDetails[0].address, block: +this.usrDetails[0]?.block_id,
            
            // block : this.dstLst.filter(e =>{if(e.districtid == this.usrDetails[0]?.block_id)})
          });
          this.pranthId = this.usrDetails[0]?.pranth_id, this.usrRoleId = this.usrDetails[0]?.role_id;
          let tagss = { target: { value: this.usrDetails[0]?.badgeids } }
          this.onItemSelect(tagss);
          if (this.usrLgnDtls) {
            let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
              usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
            this.usrLvl = usrLvlDtls?.userLevel ?? '', this.usrEditForm.get('country')?.setValue(cntryId);
            if (this.usrLvl == 'National') {
              if (this.usrDetails[0]?.state_id != null && this.usrDetails[0]?.district_id == null) {
                this.usrEditForm.get('state')?.setValue(this.usrDetails[0]?.state_id ?? 'null'),
                Promise.all([this.onLocChng(this.usrDetails[0]?.state_id ?? null,'state', true)]).then(() => {
                    this.usrEditForm.get('district')?.setValue(usrLvlDtls?.districtId ?? ''),
                      setTimeout(() => { this.usrEditForm.get('district')?.enable() }, 1000) });
              } else if (this.usrDetails[0]?.state_id != null && this.usrDetails[0]?.district_id != null && this.usrDetails[0]?.block_id == null) {
                this.usrEditForm.get('state')?.setValue(this.usrDetails[0]?.state_id ?? 'null'), Promise.all([this.onLocChng(this.usrDetails[0]?.state_id ?? null,
                  'state', true), this.onLocChng(this.usrDetails[0]?.district_id ?? null, 'district', true)]).then(() => {
                    this.usrEditForm.get('district')?.setValue(this.usrDetails[0]?.district_id ?? ''),
                      setTimeout(() => { this.usrEditForm.get('state')?.enable(), this.usrEditForm.get('district')?.enable(),
                      this.usrEditForm.get('block')?.enable() }, 1000);
                  });
              } else
                this.usrEditForm.get('state')?.setValue(this.usrDetails[0]?.state_id ?? 'null'), Promise.all([this.onLocChng(this.usrDetails[0]?.state_id ?? null,
                  'state', true), this.onLocChng(this.usrDetails[0]?.district_id ?? null, 'district', true)]).then(() => {
                  this.usrEditForm.get('district')?.setValue(this.usrDetails[0]?.district_id ?? ''),
                    this.usrEditForm.get('block')?.setValue(this.usrDetails[0]?.block_id ?? 'null')
                  setTimeout(() => {
                    this.usrEditForm.get('state')?.enable(), this.usrEditForm.get('district')?.enable(),
                      this.usrEditForm.get('block')?.enable()
                  }, 1000);
                });
            }
            if (this.usrLvl == 'State')
              this.usrEditForm.get('state')?.setValue(usrLvlDtls?.stateId ?? 'null'), this.usrEditForm.get('district')?.setValue(''),
                this.onLocChng(usrLvlDtls?.stateId ?? null, 'state', true);
            if (this.usrLvl == 'District') {
              this.usrEditForm.get('state')?.setValue(usrLvlDtls?.stateId ?? 'null'), Promise.all([this.onLocChng(usrLvlDtls?.stateId ?? null,
                'state', true), this.onLocChng(usrLvlDtls?.districtId ?? null, 'district', true)]).then(() => {
                  this.usrEditForm.get('district')?.setValue(usrLvlDtls?.districtId ?? ''),
                    setTimeout(() => { this.usrEditForm.get('district')?.disable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
                });
            }
            if (this.usrLvl == 'Block')
              this.usrEditForm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), this.usrEditForm.get('district')?.setValue(usrLvlDtls?.districtId ?? ''),
                this.usrEditForm.get('block')?.setValue(usrLvlDtls?.blockId ?? null);
          }
          setTimeout(() => { this.onAS(), this.onRoleChng(this.usrRoleId) }, 1000);
          // Promise.all([this.onLocChng(this.usrDetails[0]?.state_id, 'state', true), this.onLocChng(this.usrDetails[0]?.state_id, 'district',
          //     true)]).then(() => { this.usrEditForm.get('district')?.setValue(this.usrDetails[0]?.district_id)
          //   , this.usrEditForm.get('block')?.setValue(this.usrDetails[0]?.block_id)
          //   setTimeout(() => {
          //     if (this.usrDetails[0]?.district_id)
          //       this.usrEditForm.get('district')?.enable(); //, this.usrEditForm.get('block')?.enable();
          //     if (this.usrDetails[0]?.block_id)
          //       this.usrEditForm.get('block')?.enable();
          //   }, 2000);
          // });
        }
      }
    });
  }

  clearBSDate(ctrl: string) {
    this.usrEditForm.get(ctrl)?.setValue('');
  }

  updateUser() {
    this.checkFormValidations(this.usrEditForm);
    if (this.usrEditForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.usrEditForm.getRawValue();
      payLoad.id = this.usrId,
        payLoad.block_id = (payLoad.block != null && payLoad.block != '') ? +payLoad.block : null,
        payLoad.country_id = (payLoad.country_id != null && payLoad.country_id != '') ? +payLoad.country_id : null,
        payLoad.district_id = (payLoad.district != null && payLoad.district != '') ? +payLoad.district : null,
        payLoad.state_id = (payLoad.state != null && payLoad.state != '') ? +payLoad.state : null, payLoad.user_id = payLoad.username,
        payLoad.sim = (payLoad.sim != null && payLoad.sim != '') ? +payLoad.sim : null, payLoad.user_badge = this.slctdBadge,
        payLoad.dob = (payLoad.dob != null && payLoad.dob != '') ? this.glblSvc.setDtFrmt(payLoad.dob, this.dtF.yyy) + this.tmF.noTM : null,
        payLoad.role_id = (payLoad.role_id != null && payLoad.role_id != '') ? +payLoad.role_id : null, payLoad.login_preferences = 1,
        payLoad.language_id = (payLoad.language_id != null && payLoad.language_id != '') ? +payLoad.language_id : '',
        payLoad.time_zone_id = (payLoad.time_zone_id != null && payLoad.time_zone_id != '') ? +payLoad.time_zone_id : null,
        payLoad.token_expiry_indays = (payLoad.token_expiry_indays != null && payLoad.token_expiry_indays != '') ? +payLoad.token_expiry_indays : null,
        payLoad.created_by = this.usrLgnDtls.userId, payLoad.updated_by = payLoad.created_by,
        payLoad.pranth_id = this.pranthId, ['username', 'state', 'district', 'block',
          'role'].forEach(e => { delete payLoad[e] }); //delete payLoad.cpwd,payLoad.last_logged_on = null,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message); this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } }),
              localStorage.setItem('usrCreatePL', JSON.stringify(payLoad)), setTimeout(() => { window.location.reload(); }, 1000);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.usrEditForm.get('district')?.setValue(''),
          this.usrEditForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.usrEditForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.usrEditForm.get('district')?.enable(),
                  this.usrEditForm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.usrEditForm.get('block')?.enable();
                // console.log('block list', this.blkLst);
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.usrEditForm.get('district')?.setValue(''), this.usrEditForm.get('block')?.setValue('null'),
          this.usrEditForm.get('district')?.disable(), this.usrEditForm.get('block')?.disable();
      else
        this.usrEditForm.get('block')?.setValue('null'), this.usrEditForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.usrLvl == 'National')
      this.usrEditForm.get('state')?.enable(), this.usrEditForm.get('district')?.disable(), this.usrEditForm.get('block')?.disable();
    if (this.usrLvl == 'State')
      this.usrEditForm.get('state')?.disable(), this.usrEditForm.get('district')?.enable(), this.usrEditForm.get('block')?.disable();
    if (this.usrLvl == 'District') {
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrEditForm.get('state')?.disable(), this.usrEditForm.get('block')?.enable();
      if (usrLvlDtls?.districtId != null)
        this.usrEditForm.get('district')?.disable();
      else
        this.usrEditForm.get('district')?.enable();
    }
    if (this.usrLvl == 'Block')
      this.usrEditForm.get('state')?.disable(), this.usrEditForm.get('district')?.disable(), this.usrEditForm.get('block')?.disable();
  }

  cancel() {
    this.usrEditForm.reset(), this.usrEditForm.get('role_id')?.setValue(''), this.usrEditForm.get('permissions')?.setValue(''),
      this.usrEditForm.get('gender')?.setValue(''), this.usrEditForm.get('country_id')?.setValue(''),
      this.usrEditForm.get('state_id')?.setValue(''), this.usrEditForm.get('district_id')?.setValue(''),
      this.isSbmtd = false, this.usrEditForm.get('block_id')?.setValue(''), this.usrEditForm.get('language_id')?.setValue(''),
      this.usrEditForm.get('time_zone_id')?.setValue(''), this.usrEditForm.get('user_badge')?.setValue(null),
      this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } });
  }

}