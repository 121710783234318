<div class="card p-0 border-0">
  <div class="card-body p-4 pt-2 pb-2">
    <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
      <li class="nav-item" *ngIf="mtrlLst"><a class="nav-link" id="Materials-tab" data-bs-toggle="tab" href="#Materials"
          role="tab" aria-controls="User" aria-selected="true" (click)="onTabSelection('list')" [ngClass]="paramTab === 'list' ? 'active' : ''">Materials</a></li>
      <li class="nav-item" *ngIf="cratMtrlBulk"><a class="nav-link" id="Create-material-top-tab" data-bs-toggle="tab"
          href="#top-Create-material" role="tab" aria-controls="Create-material" aria-selected="false" (click)="onTabSelection('new')" 
          [ngClass]="paramTab === 'new' ? 'active' : ''">Create material</a>
      </li>
      <li class="nav-item" *ngIf="cratMtrlBulk"><a class="nav-link" id="bulkup-top-tab" data-bs-toggle="tab" href="#top-bulkup" role="tab"
          aria-controls="top-bulkup" aria-selected="false" (click)="onTabSelection('upld')" [ngClass]="paramTab === 'upld' ? 'active' : ''">Bulk upload</a></li>
    </ul>
    <div class="tab-content" id="top-tabContent">
      <div class="tab-pane fade" id="Materials" role="tabpanel" aria-labelledby="Materials-tab"
      [ngClass]="paramTab === 'list' ? 'show active' : '' ">
        <evin-materials-view></evin-materials-view>
      </div>
      <div class="tab-pane fade" id="top-Create-material" role="tabpanel" aria-labelledby="Create-material-top-tab"
      [ngClass]="paramTab === 'new' ? 'show active' : '' ">
        <evin-create-material></evin-create-material>
      </div>
      <div class="tab-pane fade" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab" 
       [ngClass]="paramTab === 'upld' ? 'show active' : '' ">
        <evin-bulk-upload></evin-bulk-upload>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="modal_error">
  <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-body">
        <div class="table_spacing table-responsive table-row-spacing">
          <table class="table table-sm table_spacing table-small-font p-0 table-bordered"
            style="min-width: 1100px;">
            <thead>
              <tr>
                <th>#</th>
                <th>Character offset</th>
                <th>Operation</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>1334</td>
                <td>add</td>
                <td>
                  <div class="card p-0">
                    <div class="card-body p-2">
                      <div class="row">
                        <div class="col-xl-1 col-sm-2 text-danger">
                          1
                        </div>
                        <div class="col-xl-11 col-sm-10 text-danger">
                          MSRP: Price 'no' is invalid. It should be a valid number between 0 and 1 billion, rounded to
                          two decimal places maximum.
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xl-1 col-sm-2">
                          in
                        </div>
                        <div class="col-xl-11 col-sm-10">
                          a,0.1 ml syringe (pieces),,,,yes,Syringes,no,no,,,INR,,,no,no,
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>