import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.css']
})

export class ShipmentsListComponent extends BaseComponent implements OnInit {

  shipmentsList: any[] = []; lstStrs: any[] = []; shiplst = this.fb.group({ receivingStoreId: [''], issuingStoreId: [''],
  fromDate: [''], toDate: [''], fromExpectedArrvlDate: [''], toExpectedArrvlDate: [''], transporter: [''], status: [''], 
  searchText: [], isStkMatBdge: [''], stkMtrlBdge: [''], stkMtrlNme: [''] }); crntPage = 1; pageSize = environment.tblPageSize;
  totalItems: any; pageEntrs = ''; lstLinkedStrs: any[] = []; isuStrId: any; recStrId: any; noRcrdsTxt = environment.TblNoRcrds; 
  shipStatusList: any[] = []; carrierLst: any[] = [];  fromEADate: any; count: any;searchText: any;
  filterMetadata = { count: 0 }; ttlItems: any;  toDateF: any = new Date(); frmDate: any = new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000)); 
  dt2day = new Date(); userlgndtls : any = null; isStkMatBdge = 'badge'; slctdStkMat: any[] = []; lstSlctdMatTags: any[] = [];
  lstSlctdMatNames: any[] = []; lstAllMats: any[] = []; lstMatTags1 : any[] = []; dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name',
  selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; mmIdSpDtls: boolean = true;

  constructor(public glblSvc: GlobalService,
    private router: Router,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstStrs = s, this.usrStrLen = s.length;
      if (this.usrStrLen == 1) this.onChngFclty(this.lstStrs[0].storeName, 'str1'); }),
      this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags1 = s.filter(f => f.badgeTypeId == environment.tagTypes.product) }),
      this.glblSvc.sbjctMstrData.subscribe(s => { this.lstAllMats = s.allMats, this.carrierLst = s.carrierLst, this.shipStatusList = s.stsLst.filter((f: any) => f.statusType === 'Cargo') });
  }

  ngOnInit(): void {
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_sh_sd') this.mmIdSpDtls = e.action;}) : null;
    }
    this.LoadShipments();
  }

  get fc() { return this.shiplst.controls; }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any){
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  onClkRdb(ctrl: string) {
    this.fc?.stkMtrlBdge.setValue([]), this.fc?.stkMtrlNme.setValue([]),
     this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdMatNames.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter((f: { id: any; }) => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstAllMats;
      else
        this.lstSlctdMatTags = this.lstMatTags1;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdMatNames = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onChngDtE(dtVal3: any){
    this.cstmDt3 = dtVal3 ? [] : this.cstmDtDef3;
  }

  onChngDtET(dtVal4: any){
    this.cstmDt4 = dtVal4 ? [] : this.cstmDtDef4;
  }

  search() {
    this.count = this.searchText == '' ? this.ttlItems : this.filterMetadata.count,
      this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
  }

  onChngFclty(evnt: any, evntType: string) {
    let event = evntType == 'chng' ? evnt.target.value : (evntType == 'str1' ? evnt : evnt.value);
    if (event == '' || event == undefined)
      this.isuStrId = '', this.recStrId = '', this.shiplst.get('issuingStoreId')?.setValue(''), this.shiplst.get('receivingStoreId')?.setValue('');
    else {
      if (this.lstStrs.filter(a => a.storeName == event).length != 0) {
        for (let s = 0; s < this.lstStrs.length; s++) {
          if (this.lstStrs[s].storeName == event) {
            this.isuStrId = this.lstStrs[s].storeId;
            if (evntType == 'str1')
              this.shiplst.get('issuingStoreId')?.setValue(evnt);
          }
        }
        this.LoadLinkedPranths(this.isuStrId, 'c');
      }
      else
        this.isuStrId = '', this.recStrId = '', this.shiplst.get('issuingStoreId')?.setValue(''), this.shiplst.get('receivingStoreId')?.setValue('');
    }
  }

  onChngFclty2(evnt: any, evntType: string) {    
    let event = evntType == 'chng' ? evnt.target.value : evnt.value;
    if (event == '' || event == undefined)
      this.recStrId = '', this.shiplst.get('receivingStoreId')?.setValue('');
    else {
      if (this.lstLinkedStrs.filter(a => a.mapped_store_name == event).length != 0)
        for (let s = 0; s < this.lstLinkedStrs.length; s++) {
          if (this.lstLinkedStrs[s].mapped_store_name == event)
            this.recStrId = this.lstLinkedStrs[s].mapped_store_id;
        }
      else
        this.recStrId = '', this.shiplst.get('receivingStoreId')?.setValue('');
    }
  }

  LoadLinkedPranths(kioskId: any, ordrType: any) {
    if (kioskId) {
    this.lstLinkedStrs = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
      environment.apiMthds.getLinkedStores.replace('pranthId', kioskId).replace('ordType', ordrType), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.lstLinkedStrs = this.result.data != null ? this.result.data : [],
              this.shiplst.get('receivingStoreId')?.setValue(this.lstLinkedStrs.length == 1 ? this.lstLinkedStrs[0]?.mapped_store_name : '');
          }
        }
      });
    }
  }

  clearBSDate(ctrl: string) {
    this.shiplst.get(ctrl)?.setValue('');
  }

  LoadShipments() {
    let crntPgIndx = this.crntPage - 1, payLoad = this.shiplst.getRawValue(), statusIds: any[] =[], rawMtrlStkNames: any[] = payLoad.stkMtrlNme != '' ?
    payLoad.stkMtrlNme : [], rawMtrlBdge: any[] = payLoad.stkMtrlBdge != '' ? payLoad.stkMtrlBdge : [], stkMtrlBdgeID: any[] = [], stkMtrlIDs: any[] = [];
    rawMtrlBdge.forEach((e) => {stkMtrlBdgeID.push(e.id);}); rawMtrlStkNames.forEach((e) => { stkMtrlIDs.push(e.id)});
      if(this.shiplst.get('status')?.value == ''||this.shiplst.get('status')?.value == null)
       this.shipStatusList.filter(a => statusIds.push(a.id));
      else
        statusIds.push(Number(this.shiplst.get('status')?.value));

    payLoad.issuingStoreId = this.isuStrId != '' ? +this.isuStrId : null,
      payLoad.receivingStoreId = (this.lstLinkedStrs.length == 1 ? this.lstLinkedStrs[0]?.mapped_store_id : (this.recStrId != '' ? +this.recStrId : null)),
      // payLoad.status = payLoad.status != '' ? +payLoad.status : nulls,  payLoad.tracking = payLoad.tracking != '' ? payLoad.tracking : null,
      payLoad.status = statusIds, payLoad.fromDate = this.frmDate ? this.glblSvc.setDtFrmt(this.frmDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.toDate = this.toDateF ? this.glblSvc.setDtFrmt(this.toDateF, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.fromExpectedArrvlDate = payLoad.fromExpectedArrvlDate ? this.glblSvc.setDtFrmt(payLoad.fromExpectedArrvlDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.toExpectedArrvlDate = payLoad.toExpectedArrvlDate ? this.glblSvc.setDtFrmt(payLoad.toExpectedArrvlDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.pranthId = this.chsnDmnId, payLoad.productBadgeIds = stkMtrlBdgeID; payLoad.productIds = stkMtrlIDs;
      ['stkMtrlBdge', 'stkMtrlNme', 'isStkMatBdge', 'searchText'].forEach(e=>{delete payLoad[e]});
    this.shipmentsList = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.cargo, environment.apiMthds.getAllCargos.
        replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad).then(() => {
      if (this.result) {
        if (this.result.data) {
          this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.ttlItems = this.totalItems;
          this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        }
        if (this.result.status == 'OK')
          this.shipmentsList = this.result.data.shipmenByFiltertDTO;
        else if (this.result.status == null)
          this.glblSvc.onTstr('i', this.result.message);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  onShipmentClk(cargoId: any) {
    if (cargoId != '' && this.mmIdSpDtls)
      this.glblSvc.sbjctShipmentId.next(cargoId), this.router.navigate(['home/ord-ship'], { queryParams: { tab: 'sts' } });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadShipments();
  }

  onReset() {
    this.isuStrId = '', this.recStrId = '', this.shiplst.reset(), this.shiplst.get('status')?.setValue(''), this.slctdStkMat = [],
    this.lstSlctdMatTags = [], this.shiplst.get('stkMtrlBdge')?.setValue([]), this.shiplst.get('stkMtrlNme')?.setValue([]),
    this.shiplst.get('isStkMatBdge')?.setValue('badge'); this.toDateF = new Date(), this.frmDate = new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000)); 
    if (this.lstStrs.length == 1) this.onChngFclty(this.lstStrs[0].storeName, 'str1'); 
    this.LoadShipments(); 
  }

  onEE() {
    let payLoad = this.shiplst.getRawValue(),  statusIds: any[] =[],  rawMtrlStkNames: any[] = payLoad.stkMtrlNme != '' ?
    payLoad.stkMtrlNme : [], rawMtrlBdge: any[] = payLoad.stkMtrlBdge != '' ? payLoad.stkMtrlBdge : [], stkMtrlBdgeID: any[] = [], stkMtrlIDs: any[] = [];
    rawMtrlBdge.forEach((e) => {stkMtrlBdgeID.push(e.id);}); rawMtrlStkNames.forEach((e) => { stkMtrlIDs.push(e.id)});
    payLoad.productBadgeIds = stkMtrlBdgeID; payLoad.productIds = stkMtrlIDs; 
      if(this.shiplst.get('status')?.value == ''||this.shiplst.get('status')?.value == null)
       this.shipStatusList.filter(a => {
         if(a.name !="Cancelled")
           statusIds.push(a.id)
       });
      else
        statusIds.push(Number(this.shiplst.get('status')?.value));

      payLoad.pranthId = this.chsnDmnId, payLoad.issuingStoreId = this.isuStrId != '' ? +this.isuStrId : null, payLoad.status = statusIds,
      payLoad.receivingStoreId = (this.lstLinkedStrs.length == 1 ? this.lstLinkedStrs[0]?.mapped_store_id : (this.recStrId != '' ? +this.recStrId : null)),
      payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null,
      ['stkMtrlBdge', 'stkMtrlNme', 'isStkMatBdge'].forEach(e=>{delete payLoad[e]});
      this.FetchData(environment.apiCalls.post, environment.apiPaths.cargo, environment.apiMthds.exprtCargos.
        replace('iii', this.usrLgnDtls.userId). replace('nnn', this.usrLgnDtls.userName).
        replace('eml', this.usrLgnDtls.email).replace('rrr', `${this.chsnDmnId}`), payLoad, 11).then(() => {
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

}