import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.css']
})
export class ReleasesComponent extends BaseComponent implements OnInit {

  paramTab: any; mmIdTfLst: boolean = true; mmCrtTfId: boolean = true; userlgndtls : any = null; cnfgVal: any = null; isRelseLstHdn: boolean = false;
  isRelseCrtHdn: boolean = false;


  constructor(public route: Router
    , private Actroute: ActivatedRoute, public glblSvc: GlobalService,) {
      super(glblSvc);
    this.Actroute.queryParams.subscribe(params => {
      this.paramTab = params.tab;
    });
  }

  ngOnInit(): void { 
    if(this.usrLgnDtls){
      this.userlgndtls = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? 
      this.usrLgnDtls.domainPermissions?.Indents.filter((e: any) => { if (e.menuCode == 'mm_id_tf') this.mmIdTfLst = e?.action;
      if (e.menuCode == 'mm_id_tf_ct') this.mmCrtTfId = e?.action;}) : null;
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null;
      this.isRelseLstHdn = this.cnfgVal?.permissionRoles[10]?.configuredValue?.value ?? false,
      this.isRelseCrtHdn = this.cnfgVal?.permissionRoles[12]?.configuredValue?.value ?? false;
    }
    if (!this.mmIdTfLst && this.mmCrtTfId && !this.isRelseCrtHdn)
      this.onTabSelection('new'), setTimeout(() => { document.getElementById('create-rels-tab')?.click(); }, 500);
    // else if(!this.isRelseLstHdn)
    //   this.onTabSelection('list');
  }

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
    if(tabName == 'list')
      window.location.reload();
  }

  updateQueryParams(queryParams: any) {
    this.route.navigate([], {
      queryParams,
      queryParamsHandling: 'merge'
    });
  }


}
