import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-consumption-locations',
  templateUrl: './consumption-locations.component.html',
  styleUrls: ['./consumption-locations.component.css']
})
export class ConsumptionLocationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
