<div class="card border-0 p-0 pb-0 mt-2 p-2 m-b-5r">
    <div class="card-body mt-0 mb-0 p-2">
        <div class="row">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <!-- <div class="small text-end mb-2">Last updated on 7/5/19 10:28 AM by <a>Devdutt Mishra</a></div> -->
                <div class="small">Specify the kinds and formats of notification messages sent via SMS
                    or email on various events in the system.</div>
                <div class="mt-2 mb-3" *ngIf="false"><strong>Note:</strong> If you want the notifications on the
                    bulletin board, please ensure that the Bulletin board is enabled</div>
                <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
                    <li class="nav-item fw-bold"><a class="nav-link active" id="Orders-tab" data-bs-toggle="tab"
                            href="#Orders" role="tab" aria-controls="Orders" aria-selected="true"
                            (click)="onTabClick('o', '5')">Indents</a>
                    </li>
                    <li class="nav-item fw-bold"><a class="nav-link" id="Shipments-tab" data-bs-toggle="tab"
                            href="#Shipments" role="tab" aria-controls="Shipments" aria-selected="false"
                            (click)="onTabClick('c', '1')" *ngIf="false">Shipments</a>
                    </li>
                    <li class="nav-item fw-bold"><a class="nav-link" id="Inventory-tabs" data-bs-toggle="tab"
                            href="#Inventory" role="tab" aria-controls="Inventory" aria-selected="false"
                            (click)="onTabClick('i', '2')">Inventory</a>
                    </li>
                    <li class="nav-item fw-bold"><a class="nav-link" id="Setup-tab" data-bs-toggle="tab" href="#Setup"
                            role="tab" aria-controls="Setup" aria-selected="false"
                            (click)="onTabClick('s', '3')">Setup</a>
                    </li>
                    <li class="nav-item fw-bold"><a class="nav-link" id="Assets-tab" data-bs-toggle="tab" href="#Assets"
                            role="tab" aria-controls="Assets" aria-selected="false"
                            (click)="onTabClick('a', '4')">Assets</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="Orders" role="tabpanel" aria-labelledby="Orders-tab">
                        <form [formGroup]="cnfgEvntNotfcForm" autocomplete="off">
                            <div class="row mt-3" *ngIf="!isOrdAdd">
                                <div class="col-sm-12">
                                    <div class="small">
                                        Select an event, its associated parameters (if any), and add a
                                        notification configuration. Click on an event to view or edit it
                                    </div>
                                    <div class="row mt-2">
                                        <div class="form-group col-xl-4">
                                            <select class="form-select form-select-sm" (change)="onChngOEs($event, 'e')"
                                                formControlName="notificationEventId">
                                                <option value="">Select Event</option>
                                                <option *ngFor="let evnt of ordersLst" [value]="evnt.id">
                                                    {{evnt.eventName}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <div class="row" *ngIf="ordrEvnt=='1'||ordrEvnt=='2'||
                                            ordrEvnt=='3'">
                                                <label for="textBox" class="col-xl-4 text-end mt-2">
                                                    {{(ordrEvnt=='1'?'before':
                                                    (ordrEvnt=='2'?'since':'for'))}}
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="text" placeholder="days" maxlength="3"
                                                        class="form-control form-control-sm" id="oeDays" formControlName="timePeriod"
                                                        (change)="onChngOEs($event, 'd')"
                                                        (keyup)="onChngOEs($event, 'd')"
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="ordrEvnt=='5'">
                                                <!-- <select class="form-select form-select-sm" (change)="onChngOEs($event, 's')" id="oeSts"
                                                    formControlName="statusId">
                                                    <option value="">Select Status</option>
                                                    <option *ngFor="let os of orderStatusList" [value]="os.id">
                                                        {{os.name}}</option>
                                                </select> -->

                                                <ng-multiselect-dropdown  id="oeSts" [settings]="msdStngs" formControlName="statusId" [data]="orderStatusList"
                                                    [(ngModel)]="slctdOrdStatus" (onSelect)="onChngSts('ord', $event, 'os')" [disabled]="isEdit"
                                                    (onSelectAll)="onChngSts('ord', $event, 'osa')" (onDeSelect)="onChngSts('ord', $event, 'ds')"
                                                    (onDeSelectAll)="onChngSts('ord', $event, 'dsa')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="!isEdit">
                                            <button type="button" (click)="isSave=false;addNotification('ordADd')" *ngIf="cnfgntfnPrmsn"
                                                class="btn btn-sm btn-primary" [disabled]="(((ordrEvnt=='1'||ordrEvnt=='2'||
                                                ordrEvnt=='3') && (oeDays==''))||
                                                (ordrEvnt=='')||(ordrEvnt=='5' && this.ordStatus.length == 0))">
                                                Add Notifcations
                                            </button>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="isEdit">
                                            <button type="button" (click)="isSave=false; isOrdAdd=!isOrdAdd;" *ngIf="cnfgntfnPrmsn"
                                                class="btn btn-sm btn-primary" [disabled]="(((ordrEvnt=='1'||ordrEvnt=='2'||
                                                ordrEvnt=='3') && (oeDays==''))||
                                                (ordrEvnt=='')||(ordrEvnt=='5' && this.ordStatus.length == 0))">
                                                Edit Notifcations
                                            </button>
                                            <button class="btn btn-default" (click)="onCnclEdt()">Cancel</button>
                                        </div>
                                    </div>

                                    <div class="card p-0 mt-3 mb-0" *ngIf="!isEdit">
                                        <div class="card-header p-2">
                                            <h6 class="mb-0">Configured notifications</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                [hidden]="apiRspns.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Events</th>
                                                        <th>Status</th>
                                                        <th>Whom to notify and when?</th>
                                                        <th>Shortcuts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of apiRspns">
                                                        <td>{{item.eventName}}</td>
                                                        <td>{{item.statusName}}</td>
                                                        <td>
                                                            <div *ngFor="let wtn of item.whomToNotify">
                                                                <div>
                                                                    {{wtn.isChk ? wtn.name : ''}} {{wtn.rbVal != '' ?
                                                                    '(' : ''}} {{wtn.rbVal != '' ?
                                                                    wtn.rbVal : ''}} {{wtn.rbVal != '' ? ')' : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> <!-- isOrdAdd=!isOrdAdd; -->
                                                            <a *ngIf="cnfgntfnPrmsn"
                                                                (click)="isEvnt = !isEvnt; isEdit = !isEdit; edit(item)"><i
                                                                    class="fa fa-pencil"
                                                                    aria-hidden="true"></i></a>&nbsp;&nbsp;
                                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="onDelNtfcn(item.id)"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table [hidden]="apiRspns.length != 0">
                                                <tbody>
                                                    <tr>
                                                        <td>No Events Configured</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="isOrdAdd">
                                <div class="col-sm-12">
                                    <div class="card p-3 pb-0 pt-2 border-0">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <div class="h6">{{isEvnt ? 'Edit' : 'Add'}} notification</div>
                                        </div>
                                        <div class="card-body p-0 mt-1 mb-0">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card p-0 mt-0 mb-0">
                                                        <div class="card-header p-2">
                                                            <h6 class="mb-0">{{ordrEvntName}} {{ordrEvnt == '1' ||
                                                                ordrEvnt == '2' ? '(' : ''}}{{ordrEvnt == '1' ||
                                                                ordrEvnt == '2' ? oeDays : ''}}{{ordrEvnt == '1' ||
                                                                ordrEvnt == '2' ? ')' : ''}}</h6>
                                                        </div>
                                                        <div class="card-body p-2">
                                                            <div>Specify the categories to exclude while notifying the
                                                                event.</div>
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <div class="mb-2 mt-3">
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Facility
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        formControlName="storeBadge"
                                                                                        [data]="lstFacTags"
                                                                                        [(ngModel)]="slctdFacTags"
                                                                                        (onSelect)="onChng('strBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('strBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('strBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('strBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Indent
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        formControlName="bookingBadge"
                                                                                        [data]="lstOrdTags"
                                                                                        [(ngModel)]="slctdordrTags"
                                                                                        (onSelect)="onChng('ordrBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('ordrBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('ordrBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('ordrBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-2">Specify who should be notified of
                                                                        this event, at what frequency and method
                                                                        (email/SMS).
                                                                    </div>
                                                                    <div class="mb-0 mt-2">
                                                                        Whom to notify, at what Frequency?
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <table
                                                                            class="table table-striped table-sm table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>Whom to notify</th>
                                                                                    <th>Frequency</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody *ngFor="let fq of cnfgEvntNotfcForm.get('whomToNotify')?.value; let i=index">
                                                                                <tr *ngIf="fq.isShown == true">
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                id="{{fq.name}}+{{i}}"
                                                                                                [checked]="fq.isChk"
                                                                                                (change)="fq.isChk=!fq.isChk;fq.rbVal=fq.isChk?fq.rbVal:''">                                                                                            
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{fq.name}}
                                                                                        <div *ngIf="fq.name=='Users' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of
                                                                                                    users by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdUsrBadges"
                                                                                                    [data]="lstUsrTags"
                                                                                                    formControlName="usrTags"
                                                                                                    (onSelect)="onChng('usrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <label for="textBox"
                                                                                                    class="mb-0">Enter
                                                                                                    Individual
                                                                                                    Users</label>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdUsrNames"
                                                                                                    [data]="lstUsrs"
                                                                                                    formControlName="usrNames"
                                                                                                    (onSelect)="onChng('usrName', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrName', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrName', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrName', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div *ngIf="fq.name=='Administrators' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of Administrators by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdAdmUsrBdges"
                                                                                                    [data]="admnTgsLst"
                                                                                                    formControlName="AdminUsrTags"
                                                                                                    (onSelect)="onChng('admusrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('admusrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('admusrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('admusrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbI'+{{i}}"
                                                                                                (change)="fq.rbVal='Immediately'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Immediately'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">Immediately
                                                                                                (SMS)</label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbD'+{{i}}"
                                                                                                (change)="fq.rbVal='Daily'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Daily'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Daily (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbW'+{{i}}"
                                                                                                (change)="fq.rbVal='Weekly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Weekly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Weekly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbM'+{{i}}"
                                                                                                (change)="fq.rbVal='Monthly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Monthly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Monthly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 text-end mt-3 mb-3 p-r-30">
                                                                <button type="button" *ngIf="cnfgntfnPrmsn"
                                                                    class="btn btn-sm btn-primary me-2"
                                                                    (click)="isSave=true;onSave()">Save</button>
                                                                <button type="button" class="btn btn-sm btn-light" *ngIf="cnfgntfnPrmsn"
                                                                    (click)="isOrdAdd=!isOrdAdd;isSave=false;cancel(); onCnclEdt()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="Shipments" role="tabpanel" aria-labelledby="Shipments-tab">
                        <form [formGroup]="cnfgEvntNotfcForm" autocomplete="off">
                            <div class="row mt-3" *ngIf="!isAdd1">
                                <div class="col-sm-12">
                                    <div class="small">
                                        Select an event, its associated parameters (if any), and add a
                                        notification configuration. Click on an event to view or edit it
                                    </div>
                                    <div class="row mt-2">
                                        <div class="form-group col-xl-4">
                                            <select class="form-select form-select-sm" (change)="onChngSEs($event, 'e')"
                                                formControlName="notificationEventId">
                                                <option value=""> Select Event</option>
                                                <option *ngFor="let evnt of shipmentsLst" [value]="evnt.id">
                                                    {{evnt?.eventName}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-4">
                                            <div class="row" *ngIf="shpmntEvnt=='10'">
                                                <select class="form-select form-select-sm" (change)="onChngSEs($event, 's')" id="oeSts"
                                                    formControlName="statusId" [disabled]="isEdit">
                                                    <option value="">Select Status</option>
                                                    <option *ngFor="let os of orderStatusList" [value]="os.id">
                                                        {{os.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                (click)="addNotification('shipAdd')" *ngIf="cnfgntfnPrmsn"
                                                [disabled]="((shpmntEvnt=='')||(shpmntEvnt=='10' && oeSts==''))">Add
                                                Notifcations</button>
                                        </div>
                                    </div>
                                    <div class="card p-0 mt-3 mb-0">
                                        <div class="card-header p-2">
                                            <h6 class="mb-0">Configured notifications</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                [hidden]="shpmntData.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Events</th>
                                                        <th>Whom to notify and when?</th>
                                                        <th>Shortcuts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of shpmntData">
                                                        <td>{{item.eventName}}</td>
                                                        <td>
                                                            <div *ngFor="let wtn of item.whomToNotify">
                                                                <div>
                                                                    {{wtn.isChk ? wtn.name : ''}} {{wtn.rbVal != '' ?
                                                                    '(' : ''}} {{wtn.rbVal != '' ?
                                                                    wtn.rbVal : ''}} {{wtn.rbVal != '' ? ')' : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a (click)="isEvnt = !isEvnt; isAdd1 = !isAdd1; edit(item)" *ngIf="cnfgntfnPrmsn"><i
                                                                    class="fa fa-pencil"
                                                                    aria-hidden="true"></i></a>&nbsp;&nbsp;
                                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="onDelNtfcn(item.id)"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table [hidden]="shpmntData.length != 0">
                                                <tbody>
                                                    <tr>
                                                        <td>No Events Configured</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="isAdd1">
                                <div class="col-sm-12">
                                    <div class="card p-3 pb-0 pt-2 border-0">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <div class="h6">{{isEvnt ? 'Edit' : 'Add'}} notification</div>
                                        </div>
                                        <div class="card-body p-0 mt-1 mb-0">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card p-0 mt-0 mb-0">
                                                        <div class="card-header p-2">
                                                            <h6 class="mb-0">{{shpmntEvntName}} {{shpmntEvnt == '10'?
                                                                '(':''}}
                                                                {{shpmntEvnt == '10'? shpStatus : ''}} {{shpmntEvnt ==
                                                                '10'? ')':'' }}</h6>
                                                        </div>
                                                        <div class="card-body p-2">
                                                            <div>Specify the categories to exclude while notifying
                                                                the event.
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <div class="mb-2 mt-3">
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Facility
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstFacTags"
                                                                                        formControlName="storeBadge"
                                                                                        (onSelect)="onChng('strBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('strBadge', $event, 'osa', '')"
                                                                                        [(ngModel)]="slctdFacTags"
                                                                                        (onDeSelect)="onChng('strBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('strBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Indent
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstOrdTags"
                                                                                        formControlName="bookingBadge"
                                                                                        [(ngModel)]="slctdordrTags"
                                                                                        (onSelect)="onChng('ordrBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('ordrBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('ordrBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('ordrBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-2">Specify who should be notified
                                                                        of this event, at what frequency and method
                                                                        (email/SMS).
                                                                    </div>
                                                                    <div class="mb-0 mt-2">
                                                                        Whom to notify, at what Frequency?
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <table
                                                                            class="table table-striped table-sm table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>Whom to notify</th>
                                                                                    <th>Frequency</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody *ngFor="let fq of cnfgEvntNotfcForm.get('whomToNotify')?.value; let i=index">
                                                                                <tr *ngIf="fq.isShown == true">
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                id="{{fq.name}}+{{i}}"
                                                                                                [checked]="fq.isChk"
                                                                                                (change)="fq.isChk=!fq.isChk;fq.rbVal=fq.isChk?fq.rbVal:''">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{fq.name}}
                                                                                        <div
                                                                                            *ngIf="fq.name=='Users' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of
                                                                                                    users by
                                                                                                    tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrTags"
                                                                                                    [(ngModel)]="slctdUsrBadges"
                                                                                                    formControlName="usrTags"
                                                                                                    (onSelect)="onChng('usrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <label for="textBox"
                                                                                                    class="mb-0">Enter
                                                                                                    Individual
                                                                                                    Users</label>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdUsrNames"
                                                                                                    [data]="lstUsrs"
                                                                                                    formControlName="usrNames"
                                                                                                    (onSelect)="onChng('usrName', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrName', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrName', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrName', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div *ngIf="fq.name=='Administrators' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of Administrators by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdAdmUsrBdges"
                                                                                                    [data]="admnTgsLst"
                                                                                                    formControlName="AdminUsrTags"
                                                                                                    (onSelect)="onChng('admusrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('admusrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('admusrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('admusrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbI'+{{i}}"
                                                                                                (change)="fq.rbVal='Immediately'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Immediately'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">Immediately
                                                                                                (SMS)</label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbD'+{{i}}"
                                                                                                (change)="fq.rbVal='Daily'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Daily'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Daily (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbW'+{{i}}"
                                                                                                (change)="fq.rbVal='Weekly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Weekly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Weekly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbM'+{{i}}"
                                                                                                (change)="fq.rbVal='Monthly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Monthly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Monthly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 text-end mt-3 mb-3 p-r-30">
                                                                <button type="button" *ngIf="cnfgntfnPrmsn"
                                                                    class="btn btn-sm btn-primary me-2"
                                                                    (click)="isSave=true; onSave()">Save</button>
                                                                <button type="button" class="btn btn-sm btn-light" *ngIf="cnfgntfnPrmsn"
                                                                    (click)="isAdd1=!isAdd1; cancel(); isSave=false">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="Inventory" role="tabpanel" aria-labelledby="Inventory-tab">
                        <form [formGroup]="cnfgEvntNotfcForm" autocomplete="off">
                            <div class="row" *ngIf="!isAdd2">
                                <div class="col-sm-12">
                                    <div class="small">
                                        Select an event, its associated parameters (if any), and add a
                                        notification configuration. Click on an event to view or edit it
                                    </div>
                                    <div class="row mt-2">
                                        <div class="form-group col-xl-3">
                                            <select class="form-select form-select-sm" (change)="onChngIEs($event, 'e')"
                                                formControlName="notificationEventId">
                                                <option value=""> Select Event </option>
                                                <option *ngFor="let ordrEvnt of invntryLst" [value]="ordrEvnt.id">
                                                    {{ordrEvnt?.eventName}}</option>
                                             </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <div class="row" *ngIf="invntryEvnt=='12'||invntryEvnt=='13'">
                                                <label for="textBox" class="col-xl-4 text-end mt-2">
                                                    {{(invntryEvnt=='12'?'expires in':
                                                    (invntryEvnt=='13'?'since':'for'))}}
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="text" placeholder="days" class="form-control form-control-sm"
                                                        id="oeDays" (change)="onChngIEs($event, 'd')"
                                                        (keyup)="onChngIEs($event, 'd')" formControlName="timePeriod"
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="invntryEvnt=='15'">
                                                <label for="textBox" class="col-xl-4 text-end mt-2">
                                                    &gt;
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="text" placeholder="%" class="form-control form-control-sm" id="iePrcnt"
                                                        (change)="onChngIEs($event, 'd')"
                                                        (keyup)="onChngIEs($event, 'd')" formControlName="timePeriod"
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                </div>
                                            </div>
                                            <div *ngIf="invntryEvnt=='16'">
                                                <ng-multiselect-dropdown [settings]="rsnMsdStngs" [data]="rsnsLst1" formControlName="reasonId" [disabled]="isEdit"
                                                    [(ngModel)]="slctdInvRsn" (onSelect)="onChngInvSts($event, 'os', 'rsn', 'e')" (onSelectAll)="onChngInvSts($event, 'osa', 'rsn', 'e')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'rsn', 'e')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'rsn', 'e')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='17'">
                                                 <ng-multiselect-dropdown [settings]="rsnMsdStngs" [data]="rsnsLst3" formControlName="reasonId" [disabled]="isEdit"
                                                    [(ngModel)]="slctdInvRsn" (onSelect)="onChngInvSts($event, 'os', 'rsn', 'i')" (onSelectAll)="onChngInvSts($event, 'osa', 'rsn', 'i')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'rsn', 'i')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'rsn', 'i')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='21'">
                                                 <ng-multiselect-dropdown [settings]="rsnMsdStngs" [data]="rsnsLst" formControlName="reasonId" [disabled]="isEdit"
                                                    [(ngModel)]="slctdInvRsn" (onSelect)="onChngInvSts($event, 'os', 'rsn', 'd')" (onSelectAll)="onChngInvSts($event, 'osa', 'rsn', 'd')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'rsn', 'd')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'rsn', 'd')">
                                                </ng-multiselect-dropdown>
                                            </div>                                           
                                        </div>
                                        <!-- <div class="form-group col-xl-3">
                                            <div *ngIf="invntryEvnt=='17'"> 
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="invStsLst" formControlName="statusId"
                                                    [(ngModel)]="slctdInvIsuSts" (onSelect)="onChngInvSts($event, 'os', 'issue', 'i')" (onSelectAll)="onChngInvSts($event, 'osa', 'sts', 'i')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'issue', 'i')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'issue', 'i')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='19'"> 
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="invStkIn" formControlName="statusId"
                                                    [(ngModel)]="slctdInvInSts" (onSelect)="onChngInvSts($event, 'os', 'sts', 'in')" (onSelectAll)="onChngInvSts($event, 'osa', 'sts', 'in')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'sts', 'in')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'sts', 'in')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='18'">
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="invStkTrnsfr" formControlName="statusId"
                                                    [(ngModel)]="slctdInvTrnsSts" (onSelect)="onChngInvSts($event, 'os', 'sts', 't')" (onSelectAll)="onChngInvSts($event, 'osa', 'sts', 't')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'sts', 't')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'sts', 't')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='21'">
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="invDscrdStsLst" formControlName="statusId"
                                                    [(ngModel)]="slctdInvDscrdSts" (onSelect)="onChngInvSts($event, 'os', 'sts', 'd')" (onSelectAll)="onChngInvSts($event, 'osa', 'sts', 'd')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'sts', 'd')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'sts', 'd')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                            <div *ngIf="invntryEvnt=='16'">
                                                <ng-multiselect-dropdown [settings]="msdStngs" [data]="invStkCntSts" formControlName="statusId"
                                                    [(ngModel)]="slctdInvEdtSts" (onSelect)="onChngInvSts($event, 'os', 'sts', 'e')" (onSelectAll)="onChngInvSts($event, 'osa', 'sts', 'e')" 
                                                    (onDeSelect)="onChngInvSts($event, 'ds', 'sts', 'e')" (onDeSelectAll)="onChngInvSts($event, 'dsa', 'sts', 'e')">
                                                </ng-multiselect-dropdown>
                                            </div>
                                        </div> -->
                                        <div class="form-group col-xl-3" *ngIf="!isEdit">
                                            <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgntfnPrmsn"
                                                (click)="isSave=false;addNotification('invAdd')" [disabled]="((invntryEvnt=='12' && (oeDays=='')) ||  (invntryEvnt=='13' && (oeDays=='')) 
                                                || (invntryEvnt=='15' && (iePrcnt==''))|| (invntryEvnt==''))">
                                                Add Notifcations
                                            </button>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="isEdit">
                                            <button type="button" (click)="isSave=false; isAdd2=!isAdd2;" *ngIf="cnfgntfnPrmsn"
                                                class="btn btn-sm btn-primary" [disabled]="((invntryEvnt=='12' && (oeDays=='')) ||  (invntryEvnt=='13' && (oeDays=='')) 
                                                || (invntryEvnt=='15' && (iePrcnt==''))|| (invntryEvnt==''))">
                                                Edit Notifcations
                                            </button>
                                            <button class="btn btn-default" (click)="onCnclEdt()">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="card p-0 mt-3 mb-0" *ngIf="!isEdit">
                                        <div class="card-header p-2">
                                            <h6 class="mb-0">Configured notifications</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                [hidden]="InvntryData.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Events</th>
                                                        <th>Reason Name</th>
                                                        <th>Whom to notify and when?</th>
                                                        <th>Shortcuts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of InvntryData">
                                                        <td>{{item.eventName}}</td>
                                                        <td>{{item.reasonName}}</td>
                                                        <td>
                                                            <div *ngFor="let wtn of item.whomToNotify">
                                                                <div>
                                                                    {{wtn.isChk ? wtn.name : ''}} {{wtn.rbVal != '' ?
                                                                    '(' :
                                                                    ''}} {{wtn.rbVal != '' ?
                                                                    wtn.rbVal : ''}} {{wtn.rbVal != '' ? ')' : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a (click)="isEvnt = !isEvnt;isEdit = !isEdit;edit(item)" *ngIf="cnfgntfnPrmsn"><i
                                                                    class="fa fa-pencil"
                                                                    aria-hidden="true"></i></a>&nbsp;&nbsp;
                                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="onDelNtfcn(item.id)"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table [hidden]="InvntryData.length != 0">
                                                <tbody>
                                                    <tr>
                                                        <td>No Events Configured</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="isAdd2">
                                <div class="col-sm-12">
                                    <div class="card p-3 pb-0 pt-2 border-0">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <div class="h6">{{isEvnt ? 'Edit' : 'Add'}} notification</div>
                                        </div>
                                        <div class="card-body p-0 mt-1 mb-0">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card p-0 mt-0 mb-0">
                                                        <div class="card-header p-2">
                                                            <h6 class="mb-0">{{invntryEvntName}}
                                                                <!-- {{invntryEvnt == '13' ? '( Days since no activity = ' :
                                                                invntryEvnt == '15' ? '( Stock count threshold [%] = ' :
                                                                invntryEvnt == '16' || invntryEvnt == '21' ||
                                                                invntryEvnt == '17' ? '( Reason = ' :
                                                                invntryEvnt == '12' ? '( Expires in [days] = ' : ''}}
                                                                {{invntryEvnt == '12' ||
                                                                invntryEvnt == '13' || invntryEvnt == '15' ? oeDays :
                                                                invntryEvnt == '15' ? iePrcnt : invntryEvnt == '16' ?
                                                                ieSct :
                                                                invntryEvnt == '17' ? ieSes : invntryEvnt == '21' ?
                                                                ieSds : ''}}
                                                                {{invntryEvnt == '12' || invntryEvnt == '16' ||
                                                                invntryEvnt == '21' ||
                                                                invntryEvnt == '13' || invntryEvnt == '15' ||
                                                                invntryEvnt == '17' ? ' )' :
                                                                ''}} {{invntryEvnt == '16' || invntryEvnt == '17' ||
                                                                invntryEvnt == '18'
                                                                || invntryEvnt == '19' ? '( Status = ' : ''}}
                                                                {{invntryEvnt == '16' || invntryEvnt == '17' ? ieSts :
                                                                invntryEvnt == '18' ||
                                                                invntryEvnt == '19' ? ieSrc : ''}} {{invntryEvnt == '16'
                                                                || invntryEvnt == '18' ||
                                                                invntryEvnt == '19' || invntryEvnt == '17' ? ' )' : ''}} -->
                                                            </h6>
                                                        </div>
                                                        <div class="card-body p-2">
                                                            <div>Specify the categories to exclude while notifying
                                                                the event.</div>
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <div class="mb-2 mt-3">
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Material
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstMatTags"
                                                                                        formControlName="productBadge"
                                                                                        [(ngModel)]="slctdMatTags"
                                                                                        (onSelect)="onChng('matBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('matBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('matBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('matBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Facility
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstFacTags"
                                                                                        formControlName="storeBadge"
                                                                                        [(ngModel)]="slctdFacTags"
                                                                                        (onSelect)="onChng('strBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('strBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('strBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('strBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-2">Specify who should be notified
                                                                        of this event, at what frequency and method
                                                                        (email/SMS).
                                                                    </div>
                                                                    <div class="mb-0 mt-2">
                                                                        Whom to notify, at what Frequency?
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <table
                                                                            class="table table-striped table-sm table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>Whom to notify</th>
                                                                                    <th>Frequency</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody *ngFor="let fq of cnfgEvntNotfcForm.get('whomToNotify')?.value; let i=index">
                                                                                <tr *ngIf="fq.isShown == true">
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                id="{{fq.name}}+{{i}}"
                                                                                                [checked]="fq.isChk"
                                                                                                (change)="fq.isChk=!fq.isChk;fq.rbVal=fq.isChk?fq.rbVal:''">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{fq.name}}
                                                                                        <div
                                                                                            *ngIf="fq.name=='Users' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of
                                                                                                    users by
                                                                                                    tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrTags"
                                                                                                    [(ngModel)]="slctdUsrBadges"
                                                                                                    formControlName="usrTags"
                                                                                                    (onSelect)="onChng('usrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <label for="textBox"
                                                                                                    class="mb-0">Enter
                                                                                                    Individual
                                                                                                    Users</label>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrs"
                                                                                                    [(ngModel)]="slctdUsrNames"
                                                                                                    formControlName="usrNames"
                                                                                                    (onSelect)="onChng('usrName', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrName', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrName', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrName', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div *ngIf="fq.name=='Administrators' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of Administrators by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdAdmUsrBdges"
                                                                                                    [data]="admnTgsLst"
                                                                                                    formControlName="AdminUsrTags"
                                                                                                    (onSelect)="onChng('admusrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('admusrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('admusrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('admusrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbI'+{{i}}"
                                                                                                (change)="fq.rbVal='Immediately'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Immediately'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">Immediately
                                                                                                (SMS)</label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbD'+{{i}}"
                                                                                                (change)="fq.rbVal='Daily'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Daily'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Daily (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbW'+{{i}}"
                                                                                                (change)="fq.rbVal='Weekly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Weekly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Weekly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbM'+{{i}}"
                                                                                                (change)="fq.rbVal='Monthly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Monthly'"
                                                                                                [ngClass]="{ 'is-invalid': isSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Monthly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 text-end mt-3 mb-3 p-r-30">
                                                                <button type="button" *ngIf="cnfgntfnPrmsn"
                                                                    class="btn btn-sm btn-primary me-2"
                                                                    (click)="isSave=true;onSave()">Save</button>
                                                                <button type="button" class="btn btn-sm btn-light" *ngIf="cnfgntfnPrmsn"
                                                                    (click)="isAdd2=!isAdd2;isSave=false;cancel(); onCnclEdt()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="Setup" role="tabpanel" aria-labelledby="Setup-tab">
                        <form [formGroup]="cnfgEvntNotfcForm" autocomplete="off">
                            <div class="row" *ngIf="!isSUAdd">
                                <div class="col-sm-12">
                                    <div class="small">
                                        Select an event, its associated parameters (if any), and add a
                                        notification configuration. Click on an event to view or edit it
                                    </div>
                                    <div class="row mt-2">
                                        <div class="form-group col-xl-4">
                                            <select class="form-select form-select-sm" (change)="onChngSUs($event, 'e')"
                                                formControlName="notificationEventId">
                                                <option value="">Select Event </option>
                                                <option *ngFor="let stpEvnt of setupLst" [value]="stpEvnt.id">
                                                    {{stpEvnt?.eventName}}</option>
                                             </select>
                                        </div>
                                        <div class="form-group col-xl-5">
                                            <div class="row" *ngIf="suEvnt=='35'||suEvnt=='39'">
                                                <label for="textBox" class="col-xl-4 text-end mt-2">since </label>
                                                <div class="col-sm-8">
                                                    <input type="text" placeholder="days" id="suDays"
                                                        class="form-control form-control-sm"
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                        (change)="onChngSUs($event, 'd')"
                                                        (keyup)="onChngSUs($event, 'd')" formControlName="timePeriod">
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="suEvnt=='40'">
                                                <label for="textBox" class="col-xl-4 text-end mt-2">IP starts with
                                                </label>
                                                <div class="col-sm-8">
                                                    <input type="text" placeholder="IP patterns (CSV)" id="suPtrn"
                                                        class="form-control form-control-sm" formControlName="timePeriod"
                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                        (change)="onChngSUs($event, 'p')"
                                                        (keyup)="onChngSUs($event, 'p')">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="!isEdit">
                                            <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgntfnPrmsn"
                                                (click)="isSUSave=false;addNotification('stupAdd')" [disabled]="((suEvnt=='')||((suEvnt=='35'||suEvnt=='39')
                                                &&(suDays==''))||((suEvnt=='40')&&(suPtrn=='')))">
                                                Add Notifcations
                                            </button>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="isEdit">
                                            <button type="button" (click)="isSUSave=false;isSUAdd = !isSUAdd" *ngIf="cnfgntfnPrmsn"
                                                class="btn btn-sm btn-primary" [disabled]="((suEvnt=='')||((suEvnt=='35'||suEvnt=='39')
                                                &&(suDays==''))||((suEvnt=='40')&&(suPtrn=='')))">
                                                Edit Notifcations
                                            </button>
                                            <button class="btn btn-default" (click)="onCnclEdt()">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="card p-0 mt-3 mb-0" *ngIf="!isEdit">
                                        <div class="card-header p-2">
                                            <h6 class="mb-0">Configured notifications</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                [hidden]="setUpData.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Events</th>                                                        
                                                        <th>Whom to notify and when?</th>
                                                        <th>Shortcuts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of setUpData">
                                                        <td>{{item.eventName}}</td>                                                       
                                                        <td>
                                                            <div *ngFor="let wtn of item.whomToNotify">
                                                                <div>
                                                                    {{wtn.isChk ? wtn.name : ''}} {{wtn.rbVal != '' ?
                                                                    '(' : ''}} {{wtn.rbVal != '' ?
                                                                    wtn.rbVal : ''}} {{wtn.rbVal != '' ? ')' : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td> <!-- isSUAdd=!isSUAdd; -->
                                                            <a (click)="isEvnt = !isEvnt; isEdit=!isEdit; edit(item)" *ngIf="cnfgntfnPrmsn"><i
                                                                    class="fa fa-pencil"
                                                                    aria-hidden="true"></i></a>&nbsp;&nbsp;
                                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="onDelNtfcn(item.id)"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table [hidden]="setUpData.length != 0">
                                                <tbody>
                                                    <tr>
                                                        <td>No Events Configured</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="isSUAdd">
                                <div class="col-sm-12">
                                    <div class="card p-3 pb-0 pt-2 border-0">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <div class="h6">Add notification</div>
                                        </div>
                                        <div class="card-body p-0 mt-1 mb-0">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card p-0 mt-0 mb-0">
                                                        <div class="card-header p-2">
                                                            <h6 class="mb-0">{{suEvntName}} {{suEvnt == '35' || suEvnt
                                                                ==
                                                                '39' ? '( Days since no activity = ' :
                                                                suEvnt == '40' ? '( IP Address starts with = ' : ''}}
                                                                {{suEvnt == '35' || suEvnt == '39' ? suDays :
                                                                suEvnt == '40' ? suPtrn : ''}} {{suEvnt == '35' ||
                                                                suEvnt ==
                                                                '39' || suEvnt == '40' ? ' )' : ''}}
                                                            </h6>
                                                        </div>
                                                        <div class="card-body p-2">
                                                            <div>Specify the tags to exclude while notifying the event.
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <div class="mb-2 mt-3">
                                                                        <div class="form-group mb-2" *ngIf="isSupFacShw">
                                                                            <label for="textBox" class="mb-0">Facility
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstFacTags"
                                                                                        formControlName="storeBadge"
                                                                                        [(ngModel)]="slctdFacTags"
                                                                                        (onSelect)="onChng('strBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('strBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('strBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('strBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-2">Specify who should be notified of
                                                                        this
                                                                        event, at what frequency and method (email/SMS).
                                                                    </div>
                                                                    <div class="mb-0 mt-2">
                                                                        Whom to notify, at what Frequency?
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <table
                                                                            class="table table-striped table-sm table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>Whom to notify</th>
                                                                                    <th>Frequency</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody *ngFor="let fq of cnfgEvntNotfcForm.get('whomToNotify')?.value; let i=index">
                                                                                <tr *ngIf="fq.isShown == true">
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                id="{{fq.name}}+{{i}}"
                                                                                                [checked]="fq.isChk"
                                                                                                (change)="fq.isChk=!fq.isChk;fq.rbVal=fq.isChk?fq.rbVal:''">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{fq.name}}
                                                                                        <div
                                                                                            *ngIf="fq.name=='Users' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of
                                                                                                    users by
                                                                                                    tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrTags"
                                                                                                    [(ngModel)]="slctdUsrBadges"
                                                                                                    formControlName="usrTags"
                                                                                                    (onSelect)="onChng('usrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <label for="textBox"
                                                                                                    class="mb-0">Enter
                                                                                                    Individual
                                                                                                    Users</label>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrs"
                                                                                                    [(ngModel)]="slctdUsrNames"
                                                                                                    formControlName="usrNames"
                                                                                                    (onSelect)="onChng('usrName', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrName', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrName', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrName', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div *ngIf="fq.name=='Administrators' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of Administrators by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdAdmUsrBdges"
                                                                                                    [data]="admnTgsLst"
                                                                                                    formControlName="AdminUsrTags"
                                                                                                    (onSelect)="onChng('admusrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('admusrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('admusrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('admusrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbI'+{{i}}"
                                                                                                (change)="fq.rbVal='Immediately'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Immediately'"
                                                                                                [ngClass]="{ 'is-invalid': isSUSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">Immediately
                                                                                                (SMS)</label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbD'+{{i}}"
                                                                                                (change)="fq.rbVal='Daily'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Daily'"
                                                                                                [ngClass]="{ 'is-invalid': isSUSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Daily (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbW'+{{i}}"
                                                                                                (change)="fq.rbVal='Weekly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Weekly'"
                                                                                                [ngClass]="{ 'is-invalid': isSUSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Weekly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbM'+{{i}}"
                                                                                                (change)="fq.rbVal='Monthly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Monthly'"
                                                                                                [ngClass]="{ 'is-invalid': isSUSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Monthly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 text-end mt-3 mb-3 p-r-30">
                                                                <button type="button" *ngIf="cnfgntfnPrmsn"
                                                                    class="btn btn-sm btn-primary me-2"
                                                                    (click)="isSUSave=true;onSave()">Save</button>
                                                                <button type="button" class="btn btn-sm btn-light" *ngIf="cnfgntfnPrmsn"
                                                                    (click)="isSUAdd=!isSUAdd;isSUSave=false;cancel();onCnclEdt();">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="tab-pane fade" id="Assets" role="tabpanel" aria-labelledby="Assets-tab">
                        <form [formGroup]="cnfgEvntNotfcForm" autocomplete="off">
                            <div class="row" *ngIf="!isAstAdd">
                                <div class="col-sm-12">
                                    <div class="small">
                                        Select an event, its associated parameters (if any), and add a
                                        notification configuration. Click on an event to view or edit it
                                    </div>
                                    <div class="row mt-2">
                                        <div class="form-group col-xl-3">
                                            <select class="form-select form-select-sm" (change)="onChngATs($event, 't')" [disabled]="isEdit"
                                            id="mntrAsst">
                                                <!-- <option *ngFor="let astEvnt of assetsLst" [value]="astEvnt.id">{{astEvnt?.eventName}}</option> -->
                                                <option value="Monitored Asset">Monitored asset</option>
                                                <option value="Monitoring Asset">Monitoring asset</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <select class="form-select form-select-sm" *ngIf="asstType=='Monitored Asset'"
                                                (change)="onChngATs($event, 'e')" id="mndEvnt"
                                                formControlName="notificationEventId">
                                                <option value="">Select Event</option>
                                                <option *ngFor="let evnt of moniteredLst" [value]="evnt.id">
                                                    {{evnt.eventName}}</option>                                              
                                            </select>
                                            <select class="form-select form-select-sm" *ngIf="asstType=='Monitoring Asset'"
                                                (change)="onChngATs($event, 'e')" id="mngEvnt"
                                                formControlName="notificationEventId">
                                                <option value="">Select Event</option>
                                                <option *ngFor="let evnt of monitoringLst" [value]="evnt.id">
                                                    {{evnt.eventName}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <div *ngIf="asstType == 'Monitored Asset'">
                                                <div class="row" *ngIf="mndEvnt!='48' && mndEvnt!='54' && mndEvnt!=''">
                                                    <label for="textBox" class="col-xl-4 text-end mt-2">
                                                        {{mndEvnt=='49'?'since':'after'}}
                                                    </label>
                                                    <div class="col-sm-8">
                                                        <input placeholder="{{mndEvnt=='49'?'days':'minutes'}}"
                                                            (change)="onChngATs($event, 'm')"
                                                            formControlName="timePeriod"
                                                            (keyup)="onChngATs($event, 'm')"
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                            type="text" class="form-control form-control-sm" id="mndMins">
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="asstType == 'Monitoring Asset'">
                                                <div class="row" *ngIf="mngEvnt=='55'||mngEvnt=='57'||
                                                mngEvnt=='58'||mngEvnt=='60'||mngEvnt=='62'||
                                                mngEvnt=='64'">
                                                    <label for="textBox" class="col-xl-4 text-end mt-2">after</label>
                                                    <div class="col-sm-8">
                                                        <input type="text" placeholder="minutes" class="form-control form-control-sm"
                                                            id="mngMins" formControlName="timePeriod"
                                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                            (change)="onChngATs($event, 'm')"
                                                            (keyup)="onChngATs($event, 'm')">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="mndEvnt=='54'||mngEvnt=='66'">
                                                <div class="col-sm-8">
                                                    <!-- <select class="form-select form-select-sm" id="astSts"
                                                        (change)="onChngATs($event, 's')" formControlName="statusId">
                                                        <option value="">Select Status</option>
                                                        <option value="Working">Working</option>
                                                        <option value="Under repair">Under repair</option>
                                                        <option value="Beyond repair">Beyond repair</option>
                                                        <option value="Condemned">Condemned</option>
                                                        <option value="Stand by">Stand by</option>
                                                        <option value="Defrosting">Defrosting</option>
                                                    </select> -->
                                                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst" formControlName="statusId"
                                                        (onSelect)="onChngAsst('asetTypeId', $event, 'os')" (onSelectAll)="onChngAsst('asetTypeId', $event, 'osa')"
                                                        (onDeSelect)="onChngAsst('asetTypeId', $event, 'ds')" (onDeSelectAll)="onChngAsst('asetTypeId', $event, 'dsa')"
                                                        [(ngModel)]="selectedTypes" [disabled]="isEdit">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="!isEdit">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                *ngIf="asstType=='Monitored Asset' && cnfgntfnPrmsn" [disabled]="((mndEvnt!='48' && mndEvnt!='54')&&(mndMins=='')||
                                                ((mndEvnt=='54')&&(asetType.length == 0)||(mndEvnt=='')))"
                                                (click)="isAstSave=false;addNotification('asetAdd')">Add Notifcations
                                            </button>
                                            <button type="button" class="btn btn-sm btn-primary"
                                                *ngIf="asstType=='Monitoring Asset' && cnfgntfnPrmsn" [disabled]="((mngEvnt=='55'||mngEvnt=='57'||
                                                mngEvnt=='58'||mngEvnt=='60'||mngEvnt=='62'||
                                                mngEvnt=='64')&&(mngMins=='')||((mngEvnt=='66')&&
                                                (asetType.length == 0)||(mngEvnt=='')))"
                                                (click)="isAstSave=false;addNotification('asetAdd')">Add
                                                Notifcations
                                            </button>
                                        </div>
                                        <div class="form-group col-xl-3" *ngIf="isEdit">
                                            <button type="button" class="btn btn-sm btn-primary"
                                                *ngIf="asstType=='Monitored Asset' && cnfgntfnPrmsn" [disabled]="((mndEvnt!='48' && mndEvnt!='54')&&(mndMins=='')||
                                                ((mndEvnt=='54')&&(asetType.length == 0)||(mndEvnt=='')))"
                                                (click)="isAstSave=false;isAstAdd = !isAstAdd">Edit Notifcations
                                            </button>
                                            <button type="button" class="btn btn-sm btn-primary"
                                                *ngIf="asstType=='Monitoring Asset' && cnfgntfnPrmsn" [disabled]="((mngEvnt=='55'||mngEvnt=='57'||
                                                mngEvnt=='58'||mngEvnt=='60'||mngEvnt=='62'||
                                                mngEvnt=='64')&&(mngMins=='')||((mngEvnt=='66')&&
                                                (asetType.length == 0)||(mngEvnt=='')))"
                                                (click)="isAstSave=false;isAstAdd = !isAstAdd">Edit Notifcations
                                            </button>
                                            <button class="btn btn-default" (click)="onCnclEdt()">Cancel</button>
                                        </div>
                                    </div>
                                    <div class="card p-0 mt-3 mb-0" *ngIf="!isEdit">
                                        <div class="card-header p-2">
                                            <h6 class="mb-0">Configured notifications</h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <table class="table table-striped table-sm table-bordered"
                                                [hidden]="asetsData.length == 0">
                                                <thead>
                                                    <tr>
                                                        <th>Events</th>
                                                        <th>Status</th>
                                                        <th>Whom to notify and when?</th>
                                                        <th>Shortcuts</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of asetsData">
                                                        <td>{{item.eventName}}</td>
                                                        <td>{{item.statusName}}</td>
                                                        <td>
                                                            <div *ngFor="let wtn of item.whomToNotify">
                                                                <div>
                                                                    {{wtn.isChk ? wtn.name : ''}} {{wtn.rbVal != '' ?
                                                                    '(' :
                                                                    ''}} {{wtn.rbVal != '' ?
                                                                    wtn.rbVal : ''}} {{wtn.rbVal != '' ? ')' : ''}}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                (click)="isEvnt = !isEvnt; isEdit = !isEdit; edit(item)"><i
                                                                    class="fa fa-pencil"  *ngIf="cnfgntfnPrmsn"
                                                                    aria-hidden="true"></i></a>&nbsp;&nbsp;
                                                            <a><i class="fa fa-trash" aria-hidden="true" (click)="onDelNtfcn(item.id)"></i></a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <table [hidden]="asetsData.length != 0">
                                                <tbody>
                                                    <tr>
                                                        <td>No Events Configured</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" *ngIf="isAstAdd">
                                <div class="col-sm-12">
                                    <div class="card p-3 pb-0 pt-2 border-0">
                                        <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                            <div class="h6">Add notification</div>
                                        </div>
                                        <div class="card-body p-0 mt-1 mb-0">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card p-0 mt-0 mb-0">
                                                        <div class="card-header p-2">
                                                            <h6 class="mb-0">{{asstType=='Monitored
                                                                Asset'?mndEvntName: mngEvntName}}</h6>
                                                        </div>
                                                        <div class="card-body p-2">
                                                            <div>Specify the tags to exclude while notifying the event.
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xl-12">
                                                                    <div class="mb-2 mt-3">
                                                                        <div class="form-group mb-2">
                                                                            <label for="textBox" class="mb-0">Facility
                                                                                categories</label>
                                                                            <div class="row">
                                                                                <div class="col-xl-4">
                                                                                    <ng-multiselect-dropdown
                                                                                        [settings]="msdStngs"
                                                                                        [data]="lstFacTags"
                                                                                        formControlName="storeBadge"
                                                                                        [(ngModel)]="slctdFacTags"
                                                                                        (onSelect)="onChng('strBadge', $event, 'os', '')"
                                                                                        (onSelectAll)="onChng('strBadge', $event, 'osa', '')"
                                                                                        (onDeSelect)="onChng('strBadge', $event, 'ds', '')"
                                                                                        (onDeSelectAll)="onChng('strBadge', $event, 'dsa', '')">
                                                                                    </ng-multiselect-dropdown>
                                                                                </div>
                                                                                <div class="col-xl-8"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-2">Specify who should be notified of
                                                                        this
                                                                        event, at what frequency and method (email/SMS).
                                                                    </div>
                                                                    <div class="mb-0 mt-2">
                                                                        Whom to notify, at what Frequency?
                                                                    </div>
                                                                    <div class="mt-2">
                                                                        <table
                                                                            class="table table-striped table-sm table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th>Whom to notify</th>
                                                                                    <th>Frequency</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody *ngFor="let fq of cnfgEvntNotfcForm.get('whomToNotify')?.value; let i=index">
                                                                                <tr *ngIf="fq.isShown == true">
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="checkbox"
                                                                                                id="{{fq.name}}+{{i}}"
                                                                                                [checked]="fq.isChk"
                                                                                                (change)="fq.isChk=!fq.isChk;fq.rbVal=fq.isChk?fq.rbVal:''">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{fq.name}}
                                                                                        <div
                                                                                            *ngIf="fq.name=='Users' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of
                                                                                                    users by
                                                                                                    tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrTags"
                                                                                                    [(ngModel)]="slctdUsrBadges"
                                                                                                    formControlName="usrTags"
                                                                                                    (onSelect)="onChng('usrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <label for="textBox"
                                                                                                    class="mb-0">Enter
                                                                                                    Individual
                                                                                                    Users</label>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [data]="lstUsrs"
                                                                                                    [(ngModel)]="slctdUsrNames"
                                                                                                    formControlName="usrNames"
                                                                                                    (onSelect)="onChng('usrName', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('usrName', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('usrName', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('usrName', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div *ngIf="fq.name=='Administrators' && fq.isChk">
                                                                                            <div
                                                                                                class="form-group mb-2">
                                                                                                <sup>Enter group of Administrators by tags</sup>
                                                                                                <ng-multiselect-dropdown
                                                                                                    [settings]="msdStngs"
                                                                                                    [(ngModel)]="slctdAdmUsrBdges"
                                                                                                    [data]="admnTgsLst"
                                                                                                    formControlName="AdminUsrTags"
                                                                                                    (onSelect)="onChng('admusrTag', $event, 'os', fq)"
                                                                                                    (onSelectAll)="onChng('admusrTag', $event, 'osa', fq)"
                                                                                                    (onDeSelect)="onChng('admusrTag', $event, 'ds', '')"
                                                                                                    (onDeSelectAll)="onChng('admusrTag', $event, 'dsa', '')">
                                                                                                </ng-multiselect-dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbI'+{{i}}"
                                                                                                (change)="fq.rbVal='Immediately'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Immediately'"
                                                                                                [ngClass]="{ 'is-invalid': isAstSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">Immediately
                                                                                                (SMS)</label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbD'+{{i}}"
                                                                                                (change)="fq.rbVal='Daily'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Daily'"
                                                                                                [ngClass]="{ 'is-invalid': isAstSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Daily (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbW'+{{i}}"
                                                                                                (change)="fq.rbVal='Weekly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Weekly'"
                                                                                                [ngClass]="{ 'is-invalid': isAstSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Weekly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-check form-check-inline">
                                                                                            <input
                                                                                                class="form-check-input"
                                                                                                type="radio"
                                                                                                [disabled]="!fq.isChk"
                                                                                                id="'rbM'+{{i}}"
                                                                                                (change)="fq.rbVal='Monthly'"
                                                                                                name="{{i}}+{{fq.name}}"
                                                                                                [value]="fq.rbVal"
                                                                                                [checked]="fq.rbVal=='Monthly'"
                                                                                                [ngClass]="{ 'is-invalid': isAstSave && fq.isChk && fq.rbVal=='' }">
                                                                                            <label
                                                                                                class="form-check-label"
                                                                                                for="{{i}}+{{fq.name}}">
                                                                                                Monthly (Email)
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 text-end mt-3 mb-3 p-r-30">
                                                                <button type="button"  *ngIf="cnfgntfnPrmsn"
                                                                    class="btn btn-sm btn-primary me-2"
                                                                    (click)="isAstSave=true;onSave()">Save</button>
                                                                <button type="button" class="btn btn-sm btn-light"  *ngIf="cnfgntfnPrmsn"
                                                                    (click)="isAstAdd=!isAstAdd;isAstSave=false;cancel();onCnclEdt()">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>