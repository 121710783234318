<form>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Facility groups information</h6>
        </div>
        <div class="card-body p-2 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify the Facility groups information</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Group Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Group Name" value=""
                                class="form-control form-control-sm" id="">
                            <p class="text-danger">Facility groups name is required</p>
                        </div>
                        <div class="form-group col-xl-3  mt-3">
                            <label for="textBox">Description</label>
                            <input type="text" placeholder="Description" class="form-control form-control-sm" id="">
                        </div>
                        <div class="form-group col-xl-3  mt-3">
                            <label for="textBox">Group Owner <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Choose Group Owner" class="form-control form-control-sm"
                                id="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Facility</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small">Please select Facility for this group</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Facility</label>
                            <select class="js-example-basic-hide-search" multiple="multiple">
                                <optgroup label="">
                                    <option value="">All</option>
                                    <option value="AL">1100 Quarters UPHC</option>
                                    <option value="WY">1100 Quarters ABC</option>
                                    <option value="WY">Aanchrikhunt APHC</option>
                                    <option value="WY">ABC CCP</option>
                                    <option value="WY">Adawal PHC</option>
                                    <option value="WY">Agar DVS</option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="row mt-3">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-primary me-2 mb-2">Save</button>
                                    <button class="btn btn-sm btn-light mb-2">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>