import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})

export class ResetPasswordComponent extends BaseComponent implements OnInit {

  newPW = ''; rtPW = ''; o1 = ''; o2 =''; o3 = ''; fpPL: any; tmr = 120; isShwHde = false; isSave = false; showReSnd = false;

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder,
    private router: Router) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.setElmntFocus('otp1'), this.showReSnd = false, this.onResetTmr();
    if (localStorage.getItem('fpPL'))
      this.fpPL = JSON.parse(<string>localStorage.getItem('fpPL'));
    else
      this.router.navigate(['']);
  }

  onResetTmr() {
    if (this.tmr > 0)
      setTimeout(() => { this.tmr -= 1, this.onResetTmr(); }, 1000);
    else
      this.showReSnd = true;
  }

  onOTP(i: number) {
    if (i == 1 && this.o1.length == 2)
      this.setElmntFocus('otp2');
    else if (i == 2 && this.o2.length == 2)
      this.setElmntFocus('otp3');
    else if (i == 3 && this.o3.length == 2)
      this.setElmntFocus('rstNewPswd');
    else
      return;
  }

  onResend() {
    if (this.fpPL) {
      let payLoad = { userName: this.fpPL.userName, resendOTPTo: this.fpPL.sendOTPTo };
      this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.api + environment.apiPaths.users,
        environment.apiMthds.resndPswd + 'forgot', payLoad).subscribe((s: any) => { this.glblSvc.sbjctSpnr.next(false);
          if (s) {
            if (s.status == 'OK')
              this.glblSvc.onTstr('s', s.message), this.router.navigate(['rest-pswd']);
            else
              this.glblSvc.onTstr('e', s.message);
          }
      });
    }
  }

  onSave() {
    if (this.o1 == '' || this.o2 == '' || this.o3 == '' || this.newPW == '' || this.rtPW == '')
      this.glblSvc.onTstr('w', 'Please fill all details');
    else if (this.newPW != this.rtPW)
      this.glblSvc.onTstr('w', 'New Password & Retype password should be same');
    else {
      if (this.fpPL) {
        let payLoad = { userName: this.fpPL.userName, newPassword: btoa(this.newPW), oldPassword: '', otp: +(this.o1 + this.o2 + this.o3) };
        this.glblSvc.loginAPI(environment.apiPaths.api + environment.apiPaths.users, environment.apiMthds.vldtPswd + 'forgot', payLoad).
          subscribe((s: any) => { //  + this.GenUnqText()
            if (s) {
              if (s.status == 'OK')
                this.glblSvc.onTstr('s', s.message), setTimeout(() => { this.router.navigate(['']); }, 200);
              else
                this.glblSvc.onTstr('e', s.message);
            }
        });
      }
    }
  }

  onBack() {
    this.router.navigate(['frgt-pswd']);
  }

  onShwHde() {
    // if (this.lgnFrm.get('password')?.value != '')
      this.isShwHde = !this.isShwHde, this.ShowHidePwd('rstNewPswd', this.isShwHde);
  }

}