import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-stock-views',
  templateUrl: './stock-views.component.html',
  styleUrls: ['./stock-views.component.css']
})

export class StockViewsComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void { }

}