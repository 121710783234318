<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="usrLst"><a class="nav-link" id="User-tab" data-bs-toggle="tab" href="#User" role="tab"
                    aria-controls="User" aria-selected="true" (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' || paramTab === 'sts' ? 'active' : ''">User</a></li>
            <li class="nav-item" *ngIf="cratUsrBulk"><a class="nav-link" id="Create-user-top-tab" data-bs-toggle="tab"
                    href="#top-Create-user" role="tab" aria-controls="Create-user" aria-selected="false"
                    (click)="onTabSelection('new')" [ngClass]="paramTab === 'new' ? 'active' : ''">Create user</a>
            </li>
            <li class="nav-item" *ngIf="cratUsrBulk"><a class="nav-link" id="bulkup-top-tab" data-bs-toggle="tab" href="#top-bulkup"
                    role="tab" aria-controls="top-bulkup" aria-selected="false" (click)="onTabSelection('upld')"
                    [ngClass]="paramTab === 'upld' ? 'active' : ''">Bulk upload</a></li>
            <!-- <li class="nav-item"><a class="nav-link" id="Sent-top-tab" data-bs-toggle="tab" href="#top-Sent" role="tab"
                    aria-controls="top-Sent" aria-selected="false" (click)="onTabSelection('sms')"
                    [ngClass]="paramTab === 'sms' ? 'active' : ''">Sent message status</a></li> -->
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="User" role="tabpanel" aria-labelledby="User-tab"
             [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-users-view></evin-users-view>
            </div>
            <div class="tab-pane fade" id="top-Create-user" role="tabpanel" aria-labelledby="Create-user-top-tab"
              [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-create-user></evin-create-user>
            </div>
            <div class="tab-pane fade" id="top-bulkup" role="tabpanel" aria-labelledby="bulkup-top-tab"
             [ngClass]="paramTab === 'upld' ? 'show active' : ''">
                <evin-su-bulk-upload></evin-su-bulk-upload>
            </div>
            <!-- <div class="tab-pane fade" id="top-Sent" role="tabpanel" aria-labelledby="Sent-top-tab"
                [ngClass]="paramTab === 'sms' ? 'show active' : ''">
                <evin-message-status></evin-message-status>
            </div> -->
        </div>
    </div>
</div>
<div class="modal" id="modal_error">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-body">
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-sm table_spacing table-small-font p-0 table-bordered"
                        style="min-width: 1100px;">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Character offset</th>
                                <th>Operation</th>
                                <th>Errors</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>1334</td>
                                <td>add</td>
                                <td>
                                    <div class="card p-0">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2 text-danger">
                                                    1
                                                </div>
                                                <div class="col-xl-11 col-sm-10 text-danger">
                                                    User with ID mgrgmsd1 already exists. Cannot add this user again.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2">
                                                    in
                                                </div>
                                                <div class="col-xl-11 col-sm-10">
                                                    a,mgrgmsd2,123456,123456,ROLE_sm,Krishan Kumar,Ahuja,+91
                                                    989,gmsdkarnal.store111@gil.com,IN,en,Asia/Kolkata,,35,,Haryana,,,Karnal,,,,,,,,,,,,,,,,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>1463</td>
                                <td>add</td>
                                <td>
                                    <div class="card p-0">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2 text-danger">
                                                    1
                                                </div>
                                                <div class="col-xl-11 col-sm-10 text-danger">
                                                    User with ID mgrgmsd1 already exists. Cannot add this user again.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-1 col-sm-2">
                                                    in
                                                </div>
                                                <div class="col-xl-11 col-sm-10">
                                                    a,mgrgmsd2,123456,123456,ROLE_sm,Krishan Kumar,Ahuja,+91
                                                    989,gmsdkarnal.store111@gil.com,IN,en,Asia/Kolkata,,35,,Haryana,,,Karnal,,,,,,,,,,,,,,,,
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>