import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-notifications',
  templateUrl: './cnfg-notifications.component.html',
  styleUrls: ['./cnfg-notifications.component.css']
})

export class CnfgNotificationsComponent extends BaseComponent implements OnInit {

    paramTab: any;
  
  constructor(public glblSvc: GlobalService
    , private router: Router
    , private route: ActivatedRoute ) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
  }

  ngOnInit(): void {

  }  

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}