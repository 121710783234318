<form [formGroup]="mnftrTrnsctnsFrm" autocomplete="off" class="p-2">
    <div class="card p-0 border-0">
        <div class="card-body">
            <div class="row container-fluid mt-2">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-xl-3" *ngIf="(dfltPrdcrID == null || dfltPrdcrID == '')">
                                    <label for="textBox">Manufacturer</label>
                                    <ng-template #cstmManftrs let-model="item">
                                        <span class="font-weight-bold">{{model.producerName}}</span> <br />
                                    </ng-template>
                                    <input name="Manufacturer" type="text" placeholder="Filter by Manufacturer" id="Manufacturer"
                                        formControlName="producerId" [typeahead]="lstManfacturs" [(ngModel)]="mnftrName"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmManftrs"
                                        typeaheadOptionField="producerName" [adaptivePosition]="true"
                                        class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                        (typeaheadOnSelect)="chngMnftrs($event, 'slct')" (change)="chngMnftrs($event, 'chng')">
                                </div>
                                <div class="form-group col-xl-3" *ngIf="(dfltPrdcrID != null || dfltPrdcrID != '')">
                                    <label for="textBox">Manufacturer</label>
                                    <input type="text" [attr.disabled]="true" formControlName="producerId" class="form-control form-control-sm">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.name}}</span> <br />
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstAllMats" [(ngModel)]="matName"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="name" [adaptivePosition]="true"
                                        class="form-control form-control-sm" [typeaheadOptionsLimit]="500"
                                        (typeaheadOnSelect)="chngProduct($event, 'slct')" (change)="chngProduct($event, 'chng')">
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">From</label>
                                    <div class="input-group">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                            (bsValueChange)="onChngDT($event)" [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            onkeypress='return event.charCode == 8' formControlName="fromDate"
                                            [(ngModel)]="frmDate" placeholder="From" [maxDate]="dt2day" readonly>
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpFD.hide();clearBSDate('fromDate')"> <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">To</label>
                                    <div class="input-group">
                                        <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                            [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)" (bsValue)="ctDte"
                                            class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            onkeypress='return event.charCode == 8' formControlName="toDate" [(ngModel)]="toDateF"
                                            bsDatepicker [minDate]="frmDate" [maxDate]="dt2day" readonly>
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpED.hide();clearBSDate('toDate')"> <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-xl-3 mt-2">
                                    <label>Batch ID</label>
                                    <input placeholder="Enter Batch ID" class="form-control form-control-sm text-uppercase"
                                        type="text" formControlName="batchNo" onkeypress='return ((event.charCode >= 65 &&
                                            event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) ||
                                            (event.charCode >= 48 && event.charCode <= 57))'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="crntPage=1;isExprt=false;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;isExprt=false;LoadLst()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="row mt-3 container-fluid">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row" *ngIf="lstGrid?.length!=0">
                        <div class="col-xl-3 mb-2">
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div>
                    <div class="row mb-1 mt-2" *ngIf="lstGrid?.length!=0">
                        <div class="col-xl-6 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div> 
                    <div class="table_spacing table-responsive table-row-spacing">
                        <table class="table table-striped table-sm table-bordered" id="table_detail">
                            <thead>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Manufacturer Name</th>
                                    <th>Material</th>
                                    <th>Operation</th>
                                    <th>Facility</th>
                                    <th>Shipped Quantity</th>
                                    <th>Shipped On</th>
                                    <th>Fulfilled Quantity</th>
                                    <th>Fullfilled On</th>
                                    <th>VAR/PAR Status</th>
                                    <th>Created On</th>
                                    <th>Created By</th>                       
                                </tr>
                            </thead>
                            <tbody class="table_data" *ngIf="lstGrid.length!=0">
                                <tr *ngFor="let m of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems };let i=index">
                                    <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                    <td>{{m.producerName}}</td>
                                    <td>{{m.productName}}</td>
                                    <td>{{m.trackingObjectType}}</td>
                                    <td>{{m.receivingStoreName}}</td>
                                    <td>{{m.shippedQty}}</td>
                                    <td>{{m?.shippedOn | date:'dd-MM-yyyy'}}</td>
                                    <td>{{m.fullFilledQty}}</td>
                                    <td>{{m?.fullfilledOn | date: 'dd-MM-yyyy'}}</td>
                                    <td>{{m.varUploadedStatus}}</td>
                                    <td>{{m.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                                    <td>{{m.createdBy}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstGrid.length == 0">
                                <tr>
                                    <td colspan="9" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstGrid?.length!=0">
                        <div class="col-xl-6 text-start">
                            {{pageEntrs}}
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>