<div> <!-- (dblclick)="onDC()" -->
    <ngx-spinner bdColor="rgba(255,255,255,.5)" type="">
        <div class="pb-ldr">
            <img [src]='ldrImg' />
            <p class="text-center cntntCntr"> <strong>Processing...</strong> </p>
        </div>
    </ngx-spinner>
    <router-outlet></router-outlet>
    <button type="button" id="btnLgnPg" (click)="onLgnRD()" hidden></button>
    <button type="button" id="rprtsRtngMdl" data-bs-toggle="modal" data-bs-target="#rptsMdl" hidden></button>
    <div class="modal fade" id="rptsMdl" tabindex="-1" aria-labelledby="rptsMdlLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="rptsMdlLabel">Reports</h5>
                    <button id="clsRptsMdl" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row ps-3 pe-3">
                        <div class="col-sm-3">
                            <div class="h5">Activity</div>
                            <div><a data-bs-dismiss="modal" [routerLink]="['/home/dmn-actvty']">Domain activity</a></div>
                            <div><a data-bs-dismiss="modal" [routerLink]="['/home/trans-counts']">Transaction counts</a></div>
                            <div><a data-bs-dismiss="modal" [routerLink]="['/home/user-activity']">User activity</a></div>
                        </div>
                        <div class="col-sm-3">
                            <div class="h5">Assets</div>
                            <div><a [routerLink]="">Asset capacity</a></div>
                            <div><a [routerLink]="">Asset status</a></div>
                            <div><a [routerLink]="">Power availability</a></div>
                            <div><a [routerLink]="">Response time to repair</a></div>
                            <div><a [routerLink]="">Sickness rate</a></div>
                            <div><a [routerLink]="">Temperature excursions</a></div>
                            <div><a [routerLink]="">Up time</a></div>
                        </div>
                        <div class="col-sm-3">
                            <div class="h5">Inventory</div>
                            <div><a [routerLink]="">Abnormal stock</a></div>
                            <div><a [routerLink]="">Consumption</a></div>
                            <div><a [routerLink]="">Discards</a></div>
                            <div><a [routerLink]="">Replenishment response time</a></div>
                            <div><a [routerLink]="">Stock availability</a></div>
                            <div><a [routerLink]="">Stock trends</a></div>
                            <div><a [routerLink]="">Supply</a></div>
                            <div><a [routerLink]="">Utilization</a></div>
                        </div>
                        <div class="col-sm-3">
                            <div class="h5">Order</div>
                            <div><a [routerLink]="">Approval request status</a></div>
                            <div><a [routerLink]="">Approval response time</a></div>
                            <div><a [routerLink]="">Demand</a></div>
                            <div><a [routerLink]="">Order discrepancies</a></div>
                            <div><a [routerLink]="">Order response time</a></div>
                            <div><a [routerLink]="">Order status</a></div>
                        </div>
                    </div>
                    <div class="row ps-3 pe-3">
                        <div class="col-sm-3">
                            <div class="h5">Custom reports</div>
                            <div><a [routerLink]="">Custom reports</a></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
</div>