<form [formGroup]="stupCCEManurerForm" autocomplete="off">
    <div class="row p-0 mt-3 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mb-2">
                        <div class="row mt-3">
                            <div class="col-xl-4 text-center"><label>Enter Manufacturer Name: <span
                                        class="text-danger">*</span></label></div>
                            <div class="col-xl-3"> <input type="text" placeholder="Name"
                                    class="form-control form-control-sm" id="name" formControlName="name"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.name.errors }">
                                <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                                    <div *ngIf="fc.name.errors.required">Manufaturer Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-xl-4 text-center"><label>Select CCE Type: <span class="text-danger">*</span></label></div>
                            <div class="col-xl-3"> 
                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                    (onSelect)="onMultiSlct('s', $event)"  (onSelectAll)="onMultiSlct('a', $event)"
                                    (onDeSelect)="onMultiSlct('r', $event)" (onDeSelectAll)="onMultiSlct('d', $event)"
                                    [data]="astTypLst" formControlName="assetTypeIds" [(ngModel)]="slctdAstTyp"
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.assetTypeIds.errors }" >
                                </ng-multiselect-dropdown>
                                <div *ngIf="isSbmtd && fc.assetTypeIds.errors" class="invalid-feedback">
                                    <div *ngIf="fc.assetTypeIds.errors?.required">CCE Type is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-xl-4 text-center"><label>Enter Description:</label></div>
                            <div class="col-xl-3"> <textarea placeholder="Description" class="form-control h-100"
                                    id="name" formControlName="description"></textarea></div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-xl-4 "></div>
                            <div class="col-xl-8">
                                <button class="btn btn-sm btn-primary float-end m-1"
                                    (click)="isSbmtd=true;saveVndr()">Save</button>
                                <button class="btn btn-sm btn-light float-end m-1"
                                    (click)="isSbmtd=false;onReset()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-5">
            <div class="row mt-5">
                <div class="form-group col-xl-3">
                    <input type="text" placeholder="Filter by Manufacturer Name" class="form-control form-control-sm" id="name"
                        formControlName="SrchName" [(ngModel)]="srchNme">
                </div>
                <div class="form-group col-xl-3"></div>
                <div class="col-xl-6">
                    <div class="float-xl-end pt_2">
                        <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;onReset()">Reset</button>
                        <button class="btn btn-sm btn-primary" (click)="crntPage=1;LoadManufacturers()">Filter</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-5" />
    <div class="row" *ngIf="manufacturersLst?.length!=0">
        <div class="col-xl-12 mb-2">
            <div class="row">
                <div class="col-xl-4">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="manufacturersLst?.length!=0">
                <div class="col-xl-12 mb-2">
                    <!-- <div class="row">
                        <div class="col-xl-4">
                            <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                <i class="fa fa-file-excel-o"></i>&nbsp;Export
                            </button>
                        </div>
                    </div> -->
                </div>
                <div class="col-xl-6" >
                    <div *ngIf="manufacturersLst?.length!=0" class="mt-2">{{pageEntrs}}</div>
                </div>
                <div class="col-xl-6">
                    <div class="float-end" *ngIf="manufacturersLst?.length!=0">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'astMnfr')" id="astMnfr"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-sm table_spacing table-small-font p-0 table-bordered">
                    <thead>
                        <tr>
                            <!-- <th></th> -->
                            <th class="text-center">Item No.</th>
                            <th>Manufacturer name</th>
                            <th>CCE Type</th>
                            <th>Description</th>
                            <!-- <th>Last updated</th> -->
                            <!-- <th>Shortcuts</th> -->
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of manufacturersLst | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems, id:'astMnfr'} ; let index = index">
                            <td class="text-center">{{(pageSize*(crntPage-1))+index+1}}</td>
                            <td>{{item.vendorName}}</td>
                            <td>{{item?.assetTypeName}}</td>
                            <td>{{item.description}}</td>
                            <!-- <td>
                                {{item.updatedOn | date: "yy/MM/dd hh:mm a"}}
                            </td> -->

                            <td class="text-center">
                                <span *ngIf="false">
                                    <a (click)="onMfgEdit(item.assetVendorId)"><i class="fa fa-pencil"
                                            aria-hidden="true"></i></a>
                                </span>
                                <span>
                                    <a (click)="onRemove(item.assetVendorId)"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="manufacturersLst.length == 0">
                            <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pb-3 pt-3" >
                <div class="row" *ngIf="manufacturersLst?.length!=0">
                    <div class="col-xl-6 text-start">
                        {{pageEntrs}}
                    </div>
                    <div class="col-xl-6">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event, 'astMnfr')" id="astMnfr"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>