import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-event-summary-activity',
  templateUrl: './cnfg-event-summary-activity.component.html',
  styleUrls: ['./cnfg-event-summary-activity.component.css']
})

export class CnfgEventSummaryActivityComponent extends BaseComponent implements OnInit {

  isShow = true;isShow1 = true; userBadges: any[] = []; isUsrAll = false;  lstShowUsrTags: any[] = []; dropdownSettings = {};
  lstSlctdUsrTag: any[] = []; slctdUsr: any[] = []; usrPrmsn: any = null; cnfgEvntSmPrmsn = true;
  cnfgeventSumFrm = this.formBuilder.group({productBadge:['']});

  constructor(public glblSvc: GlobalService, public formBuilder: FormBuilder) { 
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe((a: any) => {
      this.userBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.user)
    })
  }

  ngOnInit(): void {
    this.select2ShowHide();this.dropdowns();

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_es_ac') ? (this.cnfgEvntSmPrmsn = e.action) : '') });
    }
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true };
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (this.lstSlctdUsrTag.filter(f => f.id === item.id).length == 0)
        this.lstSlctdUsrTag.push(item);
    }
    else if (type == 'r') {
      this.lstSlctdUsrTag = this.lstSlctdUsrTag.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      this.lstSlctdUsrTag = this.userBadges;
    }
    else {
      this.lstSlctdUsrTag = [];
    }
  }

  onCncl() {
      this.slctdUsr = [...this.lstShowUsrTags];
  }

  onTagsSave() {
      this.lstShowUsrTags = [...this.lstSlctdUsrTag], this.isUsrAll = this.userBadges.length === this.lstSlctdUsrTag.length;
  }

  onResetTags() {
      this.lstShowUsrTags = [...[]], this.lstSlctdUsrTag = [...[]], this.slctdUsr = [...[]], this.isUsrAll = false;
  }
}