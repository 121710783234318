<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Handling-units-tab" data-bs-toggle="tab"
                    href="#Handling-units" role="tab" aria-controls="Handling-units" aria-selected="true">Handling
                    units</a></li>
            <li class="nav-item"><a class="nav-link" id="Handling-units-top-tab" data-bs-toggle="tab"
                    href="#top-Handling-units" role="tab" aria-controls="Handling-units" aria-selected="false">Create
                    handling-units</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="Handling-units" role="tabpanel"
                aria-labelledby="Handling-units-tab">
                <evin-handling-units-view></evin-handling-units-view>
            </div>
            <div class="tab-pane fade" id="top-Handling-units" role="tabpanel" aria-labelledby="Handling-units-top-tab">
                <evin-create-handling-units></evin-create-handling-units>
            </div>
        </div>
    </div>
</div>