import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-cnfg-general',
  templateUrl: './cnfg-general.component.html',
  styleUrls: ['./cnfg-general.component.css']
})

export class CnfgGeneralComponent extends BaseComponent implements OnInit {

  lstRoles: any[] = []; lstAdmCatgrs: any[] = [{ id: 1, admin_type: 'Primary' }, { id: 2, admin_type: 'Secondary' }];
  cnfgGnrlFrm = this.fb.group({ localeDefaults: new FormGroup({ currency_id: new FormControl(null), country_id: new FormControl(null),
    disrict_id: new FormControl(null), language_id: new FormControl(null), time_zone_id: new FormControl(null), state: new FormControl(null),
    custom_page_header: new FormControl(null) }), support_contacts: this.fb.array([]), admin_contacts: this.fb.array([]), domain_photos:
    this.fb.array([]), }); cntryLst: any[] = []; stsLst: any[] = []; dstrtLst: any[] = []; tmZoneLst: any[] = []; lstUsers: any[] = [];
  LngLst: any[] = []; crntFile: any; lstPics: any[] = []; usrPrmsn: any = null; gnrlPrmsn = true; // configTypeId = +environment.cnfgTypes.general; updatedBy = this.usrLgnDtls?.userId; 

  
  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.tmZoneLst = s.tmZoneLst, this.LngLst = s.LngLst; });
  }

  ngOnInit(): void {
    this.LoadRoles(), this.LoadUsers(), this.LoadData();

    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_gn_ac') ? (this.gnrlPrmsn = e.action) : '') });
    }
  }

  onLocChng(evntVal: any, type: string, isFtchd = false) {
    let cid = '', sid = '', did = '';
    if (type == 'country')
      cid = isFtchd ? evntVal : evntVal.target.value;
    else (type == 'state')
      sid = isFtchd ? evntVal : evntVal.target.value;
    this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.getStates.
        replace('ccc', cid) : (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) : environment.apiMthds.getBlocks.
        replace('ddd', did))), null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.data) {
          if (type === 'country')
            this.stsLst = this.result.data != null ? this.result.data : [];
          if (type === 'state')
            this.dstrtLst = this.result.data != null ? this.result.data : [];
        }
      }
    });
  }

  LoadData() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.systemConfiguration, environment.apiMthds.getCnfgDatabyType +
        environment.cnfgTypes.general, null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            if (this.result.data?.configJson != null) {
              let cnfgJsn = this.result.data?.configJson;
              Promise.all([ cnfgJsn.localeDefaults.state ? this.onLocChng(cnfgJsn.localeDefaults.state, 'state', true) : null,
                  cnfgJsn.localeDefaults.country_id ? this.onLocChng(cnfgJsn.localeDefaults.country_id, 'country', true) : null, ]).then(() => {
                cnfgJsn.admin_contacts.forEach((e: any, i: number) => { Object.assign(e, { userName: '' });
                  if (e.user_id)
                    e.userName = this.lstUsers.filter(f => f.id === e.user_id)[0]?.userId, this.onSlctUser(e.user_id, 'ac', i, 'b'); }),
                cnfgJsn.support_contacts.forEach((e: any, i: number) => { Object.assign(e, { userName: '' });
                  if (e.user_id)
                    e.userName = this.lstUsers.filter(f => f.id === e.user_id)[0]?.userId, this.onSlctUser(e.user_id, 'sc', i, 'b');
                }), this.cnfgGnrlFrm.patchValue(cnfgJsn), this.lstPics = cnfgJsn.domain_photos;
              });
            }
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  LoadUsers() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.users, environment.apiMthds.getAll, 0).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstUsers = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onSlctUser(evntVal: any, ctrl: string, indx: number, type: string) {
    let usrDtls: any;
    if (type != 'b')
      usrDtls = this.lstUsers.filter(f => f.userId === (type == 's' ? evntVal.value : evntVal.target.value));
    else
      usrDtls = this.lstUsers.filter(f => f.id === evntVal);
    if (ctrl == 'sc') {
      if (usrDtls.length > 0)
        this.GetSprtCntcts().at(indx)?.get('name')?.setValue(usrDtls[0]?.fname + ' ' + usrDtls[0]?.lname),
          this.GetSprtCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls[0]?.mobile),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(usrDtls[0]?.email),
          this.GetSprtCntcts().at(indx)?.get('user_id')?.setValue(usrDtls[0]?.id),
          this.GetSprtCntcts().at(indx)?.get('name')?.disable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.disable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.disable();
      else
        this.GetSprtCntcts().at(indx)?.get('name')?.setValue(''), this.GetSprtCntcts().at(indx)?.get('phone_number')?.setValue(''),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.setValue(''), this.GetSprtCntcts().at(indx)?.get('user_id')?.setValue(null),
          this.GetSprtCntcts().at(indx)?.get('name')?.enable(), this.GetSprtCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetSprtCntcts().at(indx)?.get('email_id')?.enable();
    }
    else {
      if (usrDtls.length > 0)
        this.GetAdmnCntcts().at(indx)?.get('name')?.setValue(usrDtls[0]?.fname + ' ' + usrDtls[0]?.lname),
          this.GetAdmnCntcts().at(indx)?.get('phone_number')?.setValue(usrDtls[0]?.mobile),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.setValue(usrDtls[0]?.email),
          this.GetAdmnCntcts().at(indx)?.get('user_id')?.setValue(usrDtls[0]?.id),
          this.GetAdmnCntcts().at(indx)?.get('name')?.disable(), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.disable(),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.disable();
      else
        this.GetAdmnCntcts().at(indx)?.get('name')?.setValue(''), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.setValue(''),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.setValue(''), this.GetAdmnCntcts().at(indx)?.get('user_id')?.setValue(null),
          this.GetAdmnCntcts().at(indx)?.get('name')?.enable(), this.GetAdmnCntcts().at(indx)?.get('phone_number')?.enable(),
          this.GetAdmnCntcts().at(indx)?.get('email_id')?.enable();
    }
  }

  GetSprtCntcts(): FormArray {
    return this.cnfgGnrlFrm.get('support_contacts') as FormArray;
  }

  GetAdmnCntcts(): FormArray {
    return this.cnfgGnrlFrm.get('admin_contacts') as FormArray;
  }

  LoadRoles() {
    this.lstRoles = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrRole, environment.apiMthds.getAll +
        environment.apiMthds.getbyModName + 'General', null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstRoles = this.result?.data ? this.result?.data : [];
            if (this.lstRoles.length > 0)
              this.SetFAData();
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  SetFAData() {
    this.lstRoles.forEach(e => { this.GetSprtCntcts().push(this.AssignData('support_contacts', e)); }),
      this.lstAdmCatgrs.forEach(e => { this.GetAdmnCntcts().push(this.AssignData('admin_contacts', e)); });
  }

  AssignData(type: string, item: any) {
    if (type == 'support_contacts')
      return this.fb.group({ role_id: item.id, user_id: null, name: '', phone_number: '', email_id: '', roleName: item.name, userName: '' });
    else
      return this.fb.group({ admin_type: item.admin_type, user_id: null, name: '', phone_number: '', email_id: '', userName: '' });
  }

  onSlctFile(evnt: any) {
    if (evnt) {
      if (evnt.target.files.length > 0) {
        const fileList = evnt.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
        if (fileList.files[0])
          this.crntFile = fileList.files[0]; // this.onUpload(fileList.files[0]);
      }
    }
  }

  onUpload() {
    if (this.crntFile) {
      let frmData: FormData = new FormData(), usrData = this.usrLgnDtls;
      frmData.append('file', this.crntFile), this.onFormData('/' + environment.apiPaths.file, environment.apiMthds.upldFile.
          replace('Logo', 'DomainPhotos').replace('dtdt', (new Date()).getTime().toString()) + usrData.userId, frmData).then(() => {
        if (this.result) {
          if (this.result.data) {
            let dmnPics: any[] = this.cnfgGnrlFrm.get('domain_photos')?.value, picURL: string = this.result.data?.fileDownloadUrl ?? '';
            if (picURL)
              dmnPics.push(picURL), this.lstPics.push(picURL), $('#cncl')?.click(), this.crntFile = null, this.onSave();
          }
        }
      });
    }
  }

  onSave() {
    let payLoad = this.cnfgGnrlFrm.getRawValue(), fnlPL: any = null;
    payLoad.localeDefaults.country_id = payLoad.localeDefaults.country_id != null ? +payLoad.localeDefaults.country_id : null,
      payLoad.localeDefaults.currency_id = payLoad.localeDefaults.currency_id != null ? +payLoad.localeDefaults.currency_id : null,
      payLoad.localeDefaults.disrict_id = payLoad.localeDefaults.disrict_id != null ? +payLoad.localeDefaults.disrict_id : null,
      payLoad.localeDefaults.language_id = payLoad.localeDefaults.language_id != null ? +payLoad.localeDefaults.language_id : null,
      payLoad.localeDefaults.time_zone_id = payLoad.localeDefaults.time_zone_id != null ? +payLoad.localeDefaults.time_zone_id : null,
      payLoad.localeDefaults.state = payLoad.localeDefaults.state != null ? +payLoad.localeDefaults.state : null,
      payLoad.support_contacts.forEach((e: any) => { delete e.userName; delete e.roleName; }), payLoad.domain_photos = this.lstPics,
      payLoad.admin_contacts.forEach((e: any) => { delete e.userName; }), fnlPL = { configJson: payLoad,
        configTypeId: +environment.cnfgTypes.general, updatedBy: this.usrLgnDtls?.userId };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.systemConfiguration, environment.apiMthds.saveRupdate, fnlPL).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }

}