import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-domain-list',
  templateUrl: './domain-list.component.html',
  styleUrls: ['./domain-list.component.css']
})

export class DomainListComponent extends BaseComponent implements OnInit {

  msdStngs = { singleSelection: true, idField: 'pranthId', textField: 'pranthName', itemsShowLimit: 3, allowSearchFilter: true };
  pageType = 'List'; dfltStrTgsStk: any[] = []; lstPrnths: any[] = []; lstDmns: any[] = []; slctDmnNme = ''; crntPage = 1;
  pageSize = environment.tblPageSize; totalItems: any; pageEntrs = ''; noRcrdTxt = environment.TblNoRcrds; isExprt = false;

  constructor(public glblSvc: GlobalService,
    private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctPrnthList.subscribe(s => this.lstPrnths = s);
  }

  ngOnInit(): void {
    this.LoadTreeDomains();
  }

  onDtls() {
    this.router.navigate(['home/mng-dmn-dtls']);
  }

  LoadTreeDomains() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.allPrnths.
        replace('pageNo', `${this.crntPage-1}`).replace('pageSize', `${this.pageSize}`).replace('nnn', this.slctDmnNme), null, 7).then(() => {
        let res = this.result;
      if (this.result)
        this.totalItems = this.result.data?.totalCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
          this.totalItems), this.lstDmns = this.result?.data?.pranthDetail ?? [];
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadTreeDomains();
  }
  onTreDmnClk(sltdDtls: any) {
    if (confirm(`Are you sure do you want to navigate ${sltdDtls.pranthName} domain.?`)) {
      localStorage.setItem('slctdDmnId', sltdDtls.pranthId), this.glblSvc.sbjctChsnDmnId.next(sltdDtls.pranthId), localStorage.setItem('isMstrsCld', '1');//, this.glblSvc.onTstr('i', `Domain='${this.dmnId}'`);
        localStorage.setItem('slctdDmnName', sltdDtls.pranthName), this.glblSvc.sbjctChsnDmnName.next(sltdDtls.pranthName);
          window.location.reload();
    } 
  }

  onReset(){
    this.slctDmnNme ='', this.LoadTreeDomains();

  }

  onDmnClk(item: any) {
    localStorage.setItem('slctdMngPrnth', item),  this.glblSvc.sbjctslctdDmntPrnth.next(item);
  }

  onEE() {
    let payLoad: any = {};
    payLoad.email = this.usrLgnDtls.email;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.pranth, environment.apiMthds.exptAllPrnths, payLoad, 7).then(() => {
      this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }
}