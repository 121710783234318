<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="Bulletin-board-top-tab" data-bs-toggle="tab"
                    href="#top-Bulletin-board" role="tab" aria-controls="top-Bulletin-board"
                    aria-selected="false">Bulletin board</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="top-Bulletin-board" role="tabpanel"
                aria-labelledby="Bulletin-board-top-tab">
                <div class="card mb-0 mt-3 p-0">
                    <div class="card-header p-2 ps-3 pe-3">
                        <h6 class="mb-0">Authorize</h6>
                    </div>
                    <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
                        <form>
                            <div class="row ">
                                <div class="col-xl-12">
                                    <div class="row mb-2">
                                        <div class="form-group col-xl-3 mt-3">
                                            <label for="textBox">Authorization key <span
                                                    class="text-danger">*</span></label>
                                            <input type="text" placeholder="Authorization key" value=""
                                                class="form-control form-control-sm" id="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row mt-2">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-primary me-2 mb-2">Authorize</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>