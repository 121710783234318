<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-xl-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="form-group col-xl-3">
        <label for="inputState-1" class="form-label">Asset status</label>
        <select id="inputState-1" class="form-select">
            <option selected>Working</option>
            <option>Under repair</option>
            <option>Beyond repair</option>
        </select>
    </div>
    <div class="form-group col-xl-3">
        <label for="inputState-2" class="form-label">Metric</label>
        <select id="inputState-2" class="form-select">
            <option selected>Number of times assets changed to this state</option>
            <option>Average number of times assets changed to this state</option>
            <option>Average duration in this state per asset (in days)</option>
        </select>
    </div>
    <div class="col-xl-6 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="text-center">
            <h6>Working - Number of times assets changed to this state</h6>
            <small>From: Feb 2021 To: Apr 2021 </small>
        </div>
    </div>
</div>
<div class="row mt-2 mb-3">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto">Asset model</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        23456
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>HF-320</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>280F2</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>ABC</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>ABC120</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>adf</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>