import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-manufactures-details',
  templateUrl: './manufactures-details.component.html',
  styleUrls: ['./manufactures-details.component.css']
})

export class ManufacturesDetailsComponent extends BaseComponent implements OnInit {

  ManFacId: any; manFctrDetails: any = {}; lstMatTags: any[] = []; matBadge: any;  lstSlctdMatNames: any[] = [];
  slctdDMT: any [] = []; dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true };  manufutureDtlFrm = this.fb.group({ matNames:['']});  addTbl: boolean =false; listMtrl: any[] =[];
  listMtrlAdd: any[] = []; slctdMtrlLst: any[] = []; totalItems: any; pageSize = environment.tblPageSize; crntPage = 1; pageEntrs = ''; noRcrdsTxt = environment.TblNoRcrds;
  flag: boolean = true;
  constructor(public glblSvc: GlobalService, private fb: FormBuilder,
     private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product)
    })
    
  }

  ngOnInit(): void {
    this.glblSvc.sbjctManfacId.subscribe((s: any) => {
      this.ManFacId = s;
      if (s > 0)
        this.loadManfacData(this.ManFacId)
      else
        this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } })
    });

   

    this.getList();
  }

  onChng(item: any, type: any) {
    if(type=='s'){
    if (this.lstSlctdMatNames.filter((f: any) => f.id === item.id).length == 0)
       this.lstSlctdMatNames.push(item);
    }
    else if (type == 'r') {
          this.lstSlctdMatNames = this.lstSlctdMatNames.filter((f: { id: any; }) => f.id != item.id);
    }
    else if (type == 'a') {
        this.lstSlctdMatNames = this.lstMatTags;
    }
    else {
        this.lstSlctdMatNames = [];
    }
  }

  getList() {
    let crntPgIndx = this.crntPage - 1, badgeIds: any=[];
    if(this.lstSlctdMatNames?.length > 0)
    this.lstSlctdMatNames.filter(e => {badgeIds.push(e.id)}); 
    else
    badgeIds=[];

    this.FetchData(environment.apiCalls.get, environment.apiPaths.ManufrMtrlList, environment.apiMthds.getAllPrdts.replace('bbb', badgeIds).replace('iii',this.ManFacId ).replace('aaa', `${this.flag}`).replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), null, 0, true, 0).then(() =>{
      if(this.result){
        if(this.result.status =='OK'){
          this.listMtrlAdd = this.result?.data?.producerFilterDetails ?? [], this.totalItems = this.result?.data?.totalRecordCount ?? this.listMtrlAdd.length,
                this.pageEntrs = this.result.data?.totalRecordCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
        }
        else
        this.glblSvc.onTstr('e', this.result.message);
      }
    });

    
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo,  this.getList();// this.searchMtrlList();
  }



  onSlctAll($event: any) {
    this.slctdMtrlLst = [];
    if ($event.target.checked)
      this.listMtrlAdd.filter(a => { a.flag = true, this.slctdMtrlLst.push({ id: a.productId }) });
      // this.listMtrlAdd.filter(a => { a.flag = true, this.slctdMtrlLst.push({ id: a.id, producerId: a.producerId, createdBy: a.createdBy, createdOn: a.createdOn, isDeleted: a.isDeleted, productId: a.productId,  }) })

    else
      this.listMtrlAdd.filter(a => { a.flag = false });
  }

  isSelected($event: any, item: any) {
    if (this.slctdMtrlLst.filter(f => f.id == item.productId).length == 0)
      this.slctdMtrlLst.push({ id: item.productId, });
    else
      this.slctdMtrlLst = this.slctdMtrlLst.filter(f => f.id != item.productId);
  }

 

  saveMtrls() {
    let pIds: any[] = []; this.slctdMtrlLst.forEach(e => {pIds.push(e.id)});
   let payLoad = {
    createdBy:this.usrLgnDtls.userId, createdOn:this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, isDeleted:false, producerId:this.ManFacId, productId: 0, productIds: pIds,
    updatedBy: this.usrLgnDtls.userId, updatedOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM
   }

   this.FetchData(environment.apiCalls.post, environment.apiPaths.ManufrMtrlList, environment.apiMthds.cratPrductprducr, payLoad, 0, true, 2).then(() =>{
     if(this.result){
       if(this.result.status =='OK'){
        this.glblSvc.onTstr('s', this.result.message);
        var element = <HTMLInputElement> document.getElementById("checkboxNoLabel");
        element.checked = false;
        this.listMtrlAdd=[]; this.slctdMtrlLst=[];
         this.getList();// this.searchMtrlList();
       }
       else
       this.glblSvc.onTstr('e', this.result.message);
     }
     
   });

  }

  deleteMtrl(id: any) {
    this.FetchData(environment.apiCalls.del, environment.apiPaths.ManufrMtrlList, environment.apiMthds.dltMnfrId.replace('iii', id).replace('ppp', this.ManFacId), null, 0, true, 2).then(() =>{
      if(this.result){
        if(this.result.status =='OK'){
          console.log("item deleted", this.result.data);
 
        }
      }

    });

  }

  Cancel() {
    this.listMtrlAdd.filter(a => { a.flag = false });
  }

  backToList() {
    this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } })
  }

  loadManfacData(manfacId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.producer, environment.apiMthds.prducrById.replace('iii', manfacId), null, 0, true, 0).then(() =>{
      if(this.result){
        if(this.result.status == 'OK') {
           this.manFctrDetails = this.result.data[0] != null ? this.result.data[0] : [];
        }
      }
    })
  }

}