import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})

export class CreateUserComponent extends BaseComponent implements OnInit {

  isPswdShow = false; usrLvl = ''; isDistMndtry = false;
  cntryLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; dstrtLst: any[] = []; isSbmtd = false; usrName: any; dupMsg = '';
  tmZoneLst: any[] = []; LngLst: any[] = []; userBadges: any[] = []; dropdownSettings = {}; rolesLst: any[] = []; isDup = false;
  slctdBadge: any[] = []; cstmDt: DatepickerDateCustomClasses[] = [{ date: (new Date()), classes: ['bg-success'] }]; isValidPswd = true;
  maxdate = new Date(); permison: any = 'default'; usrCreateForm = this.formBuilder.group({ address: [''], dob: [''], city_id: [''],
    alt_number: ['', [Validators.minLength(10), Validators.maxLength(13)]], block_id: ['null'], country_id: ['', Validators.required],
    dpassword: ['', [Validators.required , Validators.pattern(environment.patterns.alphaNum), Validators.minLength(7), Validators.maxLength(18)]], // , Validators.pattern(environment.patterns.pswd1L1N)
    district_id: ['null'], email: ['', [Validators.required, Validators.email]], first_name: ['', Validators.required], mobile_brand: [''],
    mobile_model: [''], imei: ['', [Validators.maxLength(50)]], language_id: [''], last_name: [''], sim: ['', [Validators.maxLength(50)]],
    mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]], user_badge: ['', Validators.required], sex: ['', Validators.required],
    permissions: [''], pin: ['', [Validators.minLength(6), Validators.maxLength(6)]], role_id: ['', Validators.required], state_id: ['null', Validators.required],
    time_zone_id: ['', Validators.required], token_expiry_indays: ['', [Validators.minLength(1), Validators.maxLength(999)]], //ip_address: [''],
    user_id: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(20)]], remote_login: ['Default as Domain'],
    msp: ['', [Validators.maxLength(50)]], cpwd: ['', Validators.required] }, { validator: this.MustMatch('dpassword', 'cpwd') });
    
  constructor(public glblSvc: GlobalService,
    private formBuilder: FormBuilder,
    private router: Router) {
    super(glblSvc);
    this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } }),
    this.glblSvc.sbjctMstrData.subscribe(s => { this.tmZoneLst = s.tmZoneLst, this.cntryLst = s.cntriesLst, this.LngLst = s.LngLst,
      this.rolesLst = s.rlsLst.filter((f: any) => f.moduleName == 'General'); }), this.glblSvc.sbjctBdgsList.subscribe((a: any) => {
      this.userBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.user); }), this.maxdate.setFullYear(this.maxdate.getFullYear() - 18);
  }

  ngOnInit(): void {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.usrCreateForm.get('country_id')?.setValue(cntryId);
      this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
    }
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.usrCreateForm.get('state_id')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.usrCreateForm.get('district_id')?.setValue('null'),
          this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.usrCreateForm.get('state_id')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onNestedSlct(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('district_id')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.usrCreateForm.get('block_id')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onNestedSlct(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('state_id')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.usrCreateForm.get('district_id')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.usrCreateForm.get('block_id')?.setValue(blockID ?? null),
              setTimeout(() => { this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('block_id')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.usrCreateForm.get('state_id')?.setValue('null'), this.usrCreateForm.get('district_id')?.setValue('null'),
          this.onNestedSlct(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onRoleChng(evnt: any) {
    if (evnt?.target?.value != '') {
      let roleName: string = this.rolesLst.filter(f => f.id == evnt?.target?.value)[0]?.name;
      if (this.usrLgnDtls.roleName == 'Administrator' && (roleName == 'Manager' || roleName == 'Operator'))
        this.usrCreateForm.get('district_id')?.setValidators([Validators.required]), this.isDistMndtry = true;
      else
        this.usrCreateForm.get('district_id')?.setValidators(null), this.isDistMndtry = false;
    }
    else
      this.usrCreateForm.get('district_id')?.setValidators(null), this.isDistMndtry = false;
    this.usrCreateForm.get('district_id')?.updateValueAndValidity();
  }

  onItemSelect($event: any) {
    this.slctdBadge = [], this.slctdBadge.push({ badgeId: +$event.target.value });
  }

  onShwHde() {
    if (this.usrCreateForm.get('dpassword')?.value != '')
      this.isPswdShow = !this.isPswdShow, this.ShowHidePwd('dpassword', this.isPswdShow);
  }

  get fc() { return this.usrCreateForm.controls; }

  onNestedSlct(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')    // block_id       district_id     state_id
        sid = slctdId, this.dstrtLst = [], this.blkLst = [], this.usrCreateForm.get('district_id')?.setValue(null),
          this.usrCreateForm.get('block_id')?.setValue('null');
      else
        did = slctdId, this.blkLst = [],this.usrCreateForm.get('block_id')?.setValue('null');
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstrtLst = this.result.data != null ? this.result.data : [], this.usrCreateForm.get('district_id')?.enable(),
                this.usrCreateForm.get('block_id')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.usrCreateForm.get('block_id')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.usrCreateForm.get('district_id')?.setValue('null'), this.usrCreateForm.get('block_id')?.setValue('null'),
          this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('block_id')?.disable();
      else
        this.usrCreateForm.get('block_id')?.setValue('null'), this.usrCreateForm.get('block_id')?.disable();
    }
  }

  clearBSDate(ctrl: string) {
    this.usrCreateForm.get(ctrl)?.setValue('');
  }

  cancel() {
    this.usrCreateForm.reset(), this.usrCreateForm.get('role_id')?.setValue(''), this.usrCreateForm.get('permissions')?.setValue(''),
      this.usrCreateForm.get('sex')?.setValue(''), this.usrCreateForm.get('country_id')?.setValue(''), this.permison = 'default',
      this.dmnLocation(), setTimeout(() => { this.onAS()}, 500), this.usrCreateForm.get('language_id')?.setValue(''),
      this.usrCreateForm.get('time_zone_id')?.setValue(''), this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } });
  }

  CheckDuplicateUN() {
    if (this.usrCreateForm.get('user_id')?.value != '' && this.usrCreateForm.get('user_id')?.value != null) {
      this.isDup = false, this.dupMsg = '', this.FetchData(environment.apiCalls.get, environment.apiPaths.users,
          environment.apiMthds.finduserbyusername.replace('username', this.usrCreateForm.get('user_id')?.value), null).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === 'No record found')
              this.isDup = false, this.dupMsg = ''; //, this.usrCreateForm.get('user_id')?.setValidators(null);
            else
              this.isDup = true, this.dupMsg = this.result.message; //, this.usrCreateForm.get('user_id')?.setValidators(Validators.required);
            // this.usrCreateForm.get('user_id')?.updateValueAndValidity();
          }
        }
      });
    }
  }

  onChngPswd() {
    let pswd: string = this.usrCreateForm.get('dpassword')?.value;
    if (pswd != '')
      this.isValidPswd = pswd.match(environment.patterns.alphaNum) != null;
    else
      this.isValidPswd = true;
  }

  createUser() {
    this.checkFormValidations(this.usrCreateForm);
    if (this.usrCreateForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      // payLoad.address = payLoad.address != '' && payLoad.address != null ? payLoad.address : null,
      let payLoad = this.usrCreateForm.getRawValue();
      payLoad.address = payLoad.address != '' && payLoad.address != null ? payLoad.address : null,
      payLoad.alt_number = payLoad.alt_number != '' && payLoad.alt_number != null ? payLoad.alt_number : null,
      payLoad.alt_number = payLoad.alt_number != '' && payLoad.alt_number != null ? payLoad.alt_number : null,
        payLoad.block_id = (payLoad.block_id != null && payLoad.block_id != '') ? +payLoad.block_id : null,
        payLoad.country_id = (payLoad.country_id != null && payLoad.country_id != '') ? +payLoad.country_id : null,
        payLoad.district_id = (payLoad.district_id != null && payLoad.district_id != '') ? +payLoad.district_id : null,
        payLoad.city_id = payLoad.city_id != '' && payLoad.city_id != null ? payLoad.city_id : null,
        payLoad.state_id = (payLoad.state_id != null && payLoad.state_id != '') ? +payLoad.state_id : null,
        payLoad.imei = (payLoad.imei != null && payLoad.imei != '') ? +payLoad.imei : null,
        payLoad.sim = (payLoad.sim != null && payLoad.sim != '') ? +payLoad.sim : null,
        payLoad.dpassword = payLoad.dpassword != '' && payLoad.dpassword != null ? payLoad.dpassword : null,
        payLoad.email = payLoad.email != '' && payLoad.email != null ? payLoad.email : null,
        payLoad.first_name = payLoad.first_name != '' && payLoad.first_name != null ? payLoad.first_name : null,
        payLoad.last_name = payLoad.last_name != '' && payLoad.last_name != null ? payLoad.last_name : null,
        payLoad.mobile = (payLoad.mobile != null && payLoad.mobile != '') ? +payLoad.mobile : null,
        payLoad.mobile_brand = payLoad.mobile_brand != '' && payLoad.mobile_brand != null ? payLoad.mobile_brand : null,
        payLoad.mobile_model = payLoad.mobile_model != '' && payLoad.mobile_model != null ? payLoad.mobile_model : null,
        payLoad.permissions = payLoad.permissions != '' && payLoad.permissions != null ? payLoad.permissions : null,
        payLoad.sex = payLoad.sex != '' && payLoad.sex != null ? payLoad.sex : null,
        payLoad.user_id = payLoad.user_id != '' ? payLoad.user_id : null,
        payLoad.msp = payLoad.msp != '' && payLoad.msp != null ? payLoad.msp : null,
        payLoad.pin = (payLoad.pin != null && payLoad.pin != '') ? +payLoad.pin : null,
        payLoad.dob = (payLoad.dob != null && payLoad.dob != '') ? this.glblSvc.setDtFrmt(payLoad.dob, this.dtF.yyy) + this.tmF.noTM : null,
        payLoad.role_id = (payLoad.role_id != null && payLoad.role_id != '') ? +payLoad.role_id : null,
        payLoad.language_id = (payLoad.language_id != null && payLoad.language_id != '') ? +payLoad.language_id : null,
        payLoad.time_zone_id = (payLoad.time_zone_id != null && payLoad.time_zone_id != '') ? +payLoad.time_zone_id : null,
        payLoad.token_expiry_indays = (payLoad.token_expiry_indays != null && payLoad.token_expiry_indays != '') ? +payLoad.token_expiry_indays : null,
        payLoad.created_by = this.usrLgnDtls.userId, payLoad.updated_by = payLoad.created_by,
        payLoad.user_badge = this.slctdBadge, payLoad.last_logged_on = null, delete payLoad.cpwd,
        payLoad.pranth_id = this.chsnDmnId, payLoad.login_preferences = 1,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.users, environment.apiMthds.saveRupdate, payLoad, 7).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message); this.router.navigate(['home/setup-users'], { queryParams: { tab: 'list' } }),
              localStorage.setItem('usrCreatePL', JSON.stringify(payLoad)), setTimeout(() => { window.location.reload(); }, 1000);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.usrCreateForm.get('state_id')?.enable(), this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('block_id')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.usrCreateForm.get('state_id')?.disable(), this.usrCreateForm.get('district_id')?.enable(), this.usrCreateForm.get('block_id')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.usrCreateForm.get('state_id')?.disable(), this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('block_id')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.usrCreateForm.get('state_id')?.disable(), this.usrCreateForm.get('district_id')?.disable(), this.usrCreateForm.get('block_id')?.disable();
  }

}