import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-ccemanufacturers',
  templateUrl: './ccemanufacturers.component.html',
  styleUrls: ['./ccemanufacturers.component.css']
})
export class CcemanufacturersComponent extends BaseComponent implements OnInit{

  paramTab: any; prmsns: any = null; mnfrLst: boolean = true; cratMnfr: boolean = true;


  constructor(public glblSvc: GlobalService
    , private route: ActivatedRoute
    , private router: Router) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; })
  }

  ngOnInit(): void {
  //   this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
  //   this.prmsns.filter((e: any) =>
  //   {
  //   if(e?.menuCode =='mm_st_mf_li')
  //    this.mnfrLst = e?.action;
  //   if(e?.menuCode =='mm_st_ma_ct')
  //    this.cratMnfr = e?.action;
  // });
  if(this.mnfrLst)
  this.onTabSelection('list');
  else if(this.cratMnfr)
  this.onTabSelection('new');
  }

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }
}
