import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-stocks-reports',
  templateUrl: './stocks-reports.component.html',
  styleUrls: ['./stocks-reports.component.css']
})

export class StocksReportsComponent extends BaseComponent implements OnInit {

  usrLvl = ''; pageEntrs = ''; crntPage = 1; matName: any; 
  lstGrid: any[] = []; lstMatTags: any[] = []; stsLst: any[] = []; dstLst: any[] = []; blkLst: any[] = []; isExprt = false; ttlItms: any;
  isMatBdge = 'badge'; pageSize = environment.tblPageSize; noRcrdsTxt = environment.TblNoRcrds; lstAllMats :any[] = []; slctdMat: any[] = [];
  stksRprtsFrm: FormGroup = this.fb.group({}); dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true}; lstSlctdMatNames: any[] = [];
  lstSlctdMatTags: any[] = []; slctdMatName: any[] = []; isSbmtd = false; isCcp = false; lstCcpAll: any[] = []; slctdccp:any[] = [];
  lstSlctdStores: any[] = []; ddlStngs = { singleSelection: false, idField: 'storeid', textField: 'storename', selectAllText: 'ALL',
  unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; isPgLd = true; lstSlctdFacTags: any[] = [];
  lstFacTags: any[] = []; slctdFac: any[] = []; totlBy: any = '1'; ordBy:any = false; isorderBy = false; isTotlBy = false;// lstAllCCPs: any[] = []; 
  isCCpEnble: boolean = false; isRprtsHlpShw = environment.isRprtsHlpShw;

  constructor(public glblSvc: GlobalService,
    private fb: FormBuilder) {
    super(glblSvc); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst,
       this.lstAllMats = s.allMats; });
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product),
      this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility) });
  }
 
  ngOnInit(): void {
    this.stksRprtsFrm = this.fb.group({ country: [null], state: [null], district: [null], block: [null], material: [[], Validators.required], 
      materialBadge: [[], Validators.required], isMatBdge: ['badge'], storeId:[[]], storeBadge: [[], Validators.required], stockMetric: [],
      isDistrict: [] });
    this.glblSvc.invTable.subscribe(s => this.lstGrid = s), this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst }),
      this.lstGrid = [], this.onClkRdb('materialBadge');
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.stksRprtsFrm.get('country')?.setValue(cntryId), this.isCcp = (this.usrLvl == 'State' || this.usrLvl == 'District');
    }
    this.dmnLocation();
      setTimeout(() => {$("#ccpContainer").addClass("disabledbutton"), this.LoadCCPs(), this.onAS(), this.isPgLd = false; }, 1000);
  }

  get fc() { return this.stksRprtsFrm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.stksRprtsFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.stksRprtsFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
          
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.stksRprtsFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.stksRprtsFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 
          'district', true)]).then(() => {
            this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
            this.stksRprtsFrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.stksRprtsFrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => {  this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });        
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.stksRprtsFrm.get('state')?.setValue('null'), this.stksRprtsFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);  
    }
  }
  
  LoadStockReport() { 
    let crntPgIndx = this.crntPage - 1, payLoad = this.stksRprtsFrm.getRawValue(), mT: any[] = [], mN: any[] = [], sI: any[] = [], fT:any[] = [];
    this.isSbmtd = true;
    if((this.fc.material.value != '' || this.fc.materialBadge.value != '') && (this.fc.storeBadge.value != '')){
      payLoad.state = payLoad.state == 'null' ? 0 : +payLoad.state, delete payLoad.isMatBdge, payLoad.materialBadge = payLoad.materialBadge != [] &&
      payLoad.materialBadge != null ? this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.materialBadge = mT; 
      payLoad.material = payLoad.material != [] && payLoad.material != null ? this.lstSlctdMatNames?.forEach((e: any) => { mN.push(e.id) }) : [],
      payLoad.material = mN; payLoad.district = payLoad.district == 'null' ? 0 : +payLoad.district, payLoad.storeBadge = payLoad.storeBadge != [] &&
      payLoad.storeBadge != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeBadge = fT;
      payLoad.country = payLoad.country != null ? +payLoad.country : null, payLoad.storeId = payLoad.storeId != [] &&
      payLoad.storeId != null ? this.lstSlctdStores?.forEach((e: any) => { sI.push(e.storeid) }) : [], payLoad.storeId = sI, payLoad.isDistrict = 
      payLoad.isDistrict != '' && payLoad.isDistrict != null ? payLoad.isDistrict : false, payLoad.stockMetric = payLoad.stockMetric != '' && 
      payLoad.stockMetric != null ? +payLoad.stockMetric : 1; payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, delete payLoad.block,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.report, environment.apiMthds.stksRprts.replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`), payLoad, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.stksRprtsFrm.getRawValue()?.stockMetric =='3'? this.isTotlBy = true:this.isTotlBy = false,
            this.stksRprtsFrm.getRawValue()?.isDistrict == 'true'? this.isorderBy = true:this.isorderBy = false;
            let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
            if (data) {
              this.ttlItms = this.result.data?.totalRecordsCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                this.ttlItms), Object.keys(data.stockReportDetails).forEach((s: any, i: number) => {
                let sName = `${s}`.split('||')[0], adrs = `${s}`.split('||')[1], ccp = this.usrLvl == 'National' || this.usrLvl == 'State' || this.usrLvl == 'District' ? `${s}`.split('||')[3] : '';
                if (i == 0)
                  data.stockReportDetails[s].forEach((m: any) => { hdrs.push(m.productName); ttl.push(data.totalMap[m.productName]); });
                result.push({ kId: null, kName: sName, kAdrs: adrs, ccp: ccp, matData: data.stockReportDetails[s], hdrs: hdrs, total: ttl });
                // console.log(data.stockReportDetails[s]);
              });
            }
            else if (data == null)
              this.glblSvc.onTstr('i', this.result.message);
            else
              this.glblSvc.onTstr('e', this.result.message);
            this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid), this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0);
            if (result.length == 0)
              this.glblSvc.onTstr('i', 'No data found');
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    } else
      this.glblSvc.onTstr('w', 'Please choose Mandatory fields');
  }
  
  onLocChng(evnt: any, type: any, isId: boolean = false) {
    type == 'state' ? this.stksRprtsFrm.get('isDistrict')?.setValue(false) : '';
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.stksRprtsFrm.get('district')?.setValue(null),
          this.stksRprtsFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.stksRprtsFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.stksRprtsFrm.get('district')?.enable(),
                this.stksRprtsFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.stksRprtsFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.stksRprtsFrm.get('district')?.setValue('null'), this.stksRprtsFrm.get('block')?.setValue('null'),
          this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('block')?.disable();
      else
        this.stksRprtsFrm.get('block')?.setValue('null'), this.stksRprtsFrm.get('block')?.disable();
    }
    // if (!this.isPgLd)
      this.LoadCCPs();
  }

  onEE() {
    let payLoad = this.stksRprtsFrm.getRawValue(), mT: any[] = [], mN: any[] = [], sI: any[] = [], fT: any[] = [];
    payLoad.state = payLoad.state == 'null' ? 0 : +payLoad.state, delete payLoad.isMatBdge, payLoad.materialBadge = payLoad.materialBadge != [] &&
    payLoad.materialBadge != null ? this.lstSlctdMatTags?.forEach((e: any) => { mT.push(e.id) }) : [], payLoad.materialBadge = mT; 
    payLoad.material = payLoad.material != [] && payLoad.material != null ? this.lstSlctdMatNames?.forEach((e: any) => { mN.push(e.id) }) : [] ,payLoad.material = mN;
    payLoad.district = payLoad.district == 'null' ? 0 : +payLoad.district, payLoad.country = payLoad.country != null ? +payLoad.country :
      null, payLoad.storeId = payLoad.storeId != [] && payLoad.storeId != null ? this.lstSlctdStores?.forEach((e: any) => {
        sI.push(e.storeid) }) : [], payLoad.storeId = sI, payLoad.storeBadge = payLoad.storeBadge != [] &&
        payLoad.storeBadge != null ? this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeBadge = fT; 
        payLoad.blockId = payLoad.block == 'null' ? 0 : +payLoad.block, delete payLoad.block,
        this.FetchData(environment.apiCalls.post, environment.apiPaths.report,
        environment.apiMthds.stksRprtsExprt + this.usrLgnDtls.email, payLoad, 11).then(() => { this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.stksRprtsFrm.get('state')?.enable(), this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.stksRprtsFrm.get('state')?.disable(), this.stksRprtsFrm.get('district')?.enable(), this.stksRprtsFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.stksRprtsFrm.get('state')?.disable(), this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.stksRprtsFrm.get('state')?.disable(), this.stksRprtsFrm.get('district')?.disable(), this.stksRprtsFrm.get('block')?.disable();
  }

  onReset() {
    this.isSbmtd = false; this.lstSlctdMatNames = []; this.lstSlctdMatTags = []; this.lstSlctdStores = [], this.lstCcpAll = [...[]], this.ordBy = false;
    this.stksRprtsFrm.patchValue({ material: null }), this.onClkRdb('materialBadge'); this.slctdccp = [], this.LoadCCPs(); // this.loadCcpAll();
    this.stksRprtsFrm.get('storeBadge')?.setValue([]), this.lstSlctdFacTags = [], this.stksRprtsFrm.get('isDistrict')?.setValue(false);
    this.stksRprtsFrm.get('stockMetric')?.setValue('1'), this.totlBy = '1'; $("#ccpContainer").addClass("disabledbutton");  this.stksRprtsFrm.get('storeId')?.setValue([]);
    this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadStockReport(); 
  }

  onClkRdb(ctrl: string) {
    this.fc?.materialBadge.setValue([]), this.fc?.material.setValue([]); this.lstSlctdMatNames = []; this.lstSlctdMatTags = [];
    if (ctrl == 'materialBadge')
      this.fc?.material?.clearValidators(), this.fc?.materialBadge?.setValidators([Validators.required]), this.lstSlctdMatNames = []; 
    else
      this.fc?.materialBadge?.clearValidators(), this.fc?.material?.setValidators([Validators.required]);
      this.fc?.materialBadge?.updateValueAndValidity(), this.fc?.material?.updateValueAndValidity(), this.lstSlctdMatTags = [];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if(ctrl == 'c')
      this.lstGrid=[];
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdMatNames.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatNames.push(item);
      }
      else if (ctrl == 'n') {
        (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
        this.lstSlctdMatTags.push(item);
      } else if (ctrl == 'fa') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
        let obj = this.lstSlctdFacTags.find((f: { id: any; }) => f.id == 53);
        obj?.id == 53 ? this.isCCpEnble = true : this.isCCpEnble = false;
        if (this.isCCpEnble) {
          $("#ccpContainer").removeClass("disabledbutton");
        }
      } else
        if (this.lstSlctdStores.filter(f => f.storeid === item.storeid).length == 0)
          this.lstSlctdStores.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstSlctdMatNames.filter(f => f.id != item.id);
      else if (ctrl == 'n')
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
      else if (ctrl == 'fa') {
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
        if(item?.id==53){
          this.lstSlctdStores=[...[]], this.stksRprtsFrm.get('storeId')?.setValue([]),
          this.isCCpEnble = false, $("#ccpContainer").addClass("disabledbutton");
        }
      }
      else
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeid != item.storeid);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdMatNames = this.lstAllMats;
      else if (ctrl == 'n')
        this.lstSlctdMatTags = this.lstMatTags;
      else if (ctrl == 'fa'){
        this.lstSlctdFacTags = this.lstFacTags, this.isCCpEnble=true;
        if (this.isCCpEnble) {
          $("#ccpContainer").removeClass("disabledbutton");
        }
      }
      else
        this.lstSlctdStores = this.lstCcpAll;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdMatNames = [];
      else if (ctrl == 'n')
        this.lstSlctdMatTags = [];
      else if (ctrl == 'fa')
        this.lstSlctdFacTags = [];
        if(item?.id==53){
          this.lstSlctdStores=[...[]], this.stksRprtsFrm.get('storeId')?.setValue([]),
        this.isCCpEnble = false, $("#ccpContainer").addClass("disabledbutton");
        }
      else
        this.lstSlctdStores = [];
    }
  }

  loadCcpAll() {
    if (this.isCcp) {
      let payLoad = this.stksRprtsFrm.getRawValue(); 
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 :
        +payLoad.district, this.slctdccp = [], ['isMatBdge','country','material', 'materialBadge', 'state', 'storeId',
        'district','blockId'].forEach(e => { delete payLoad[e]; }), this.lstCcpAll = [...[]], this.lstSlctdStores = [],
        this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).
          then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstCcpAll = this.result.data != null ? this.result.data : [];
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
  }

  LoadCCPs() {
    if (this.usrLvl == 'State' || this.usrLvl == 'District' || this.usrLvl == 'Block') {
      let frmCtrls = this.stksRprtsFrm.getRawValue(), payLoad = { stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, 
        districtId: frmCtrls.district == 'null' ? 0 : +frmCtrls.district, blockId: frmCtrls.block == 'null' ? 0 : +frmCtrls.block };
      this.slctdccp = [], this.lstCcpAll = [...[]], this.lstSlctdStores = [], this.FetchData(environment.apiCalls.post,
          environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstCcpAll = this.result.data != null ? this.result.data : [];
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
  }

}