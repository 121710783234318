import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cce-manfcturers',
  templateUrl: './cce-manfcturers.component.html',
  styleUrls: ['./cce-manfcturers.component.css']
})
export class CceManfcturersComponent extends BaseComponent implements OnInit{

  noRcrdsTxt = environment.TblNoRcrds; pageEntrs: any = ''; crntPage = 1; pageSize = environment.tblPageSize;  totalItems = 0;
  manufacturersLst: any[] = []; producerBadges: any[] = []; stupCCEManurerForm = this.formBuilder.group({ name: ['', Validators.required],
  description: [''], SrchName: [''], assetTypeIds: [[], Validators.required] }); slctdAstTyp: any[] = [];
  prmsns: any = null; mnfrLst: boolean = true; slctdId =''; isSbmtd = false; astTypLst: any[] = []; lstSlctdAstTyps: any[] = []
  dropdownSettings = {singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  itemsShowLimit: 3, allowSearchFilter: true}; mnfrEdit: any = {}; srchNme: any = '';

  constructor(public glblSvc: GlobalService, private router:Router, private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.astTypLst = s.astTypLst; });
   }

  ngOnInit(): void {
    this.LoadManufacturers(); //, this.dropdown();
  }

  // dropdown() {
  //   this.dropdownSettings = {singleSelection: this.isEdit? true :false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
  //     itemsShowLimit: 3, allowSearchFilter: true};
  // }

  LoadManufacturers() {
    // let crntPgIndx = this.crntPage - 1, 
    let Vname = this.stupCCEManurerForm.get('SrchName')?.value != null ? this.stupCCEManurerForm.get('SrchName')?.value : '';
    //description = this.stupCCEManurerForm.get('description')?.value != null ? this.stupCCEManurerForm.get('description')?.value : '';
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getVndrByName.
      replace('nnn', Vname).replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.manufacturersLst = this.result?.data?.assetVendorDTO ?? [], this.totalItems = this.result?.data?.totalRecordCount ?? this.manufacturersLst.length,
              this.pageEntrs = this.result.data?.totalRecordCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
              // this.stupCCEManurerForm.get('SrchName')?.setValue('');
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onMfgEdit(id: any){
    this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getById.replace('uuu', id), null, 2, true, 1).then(() => {
      if (this.result){
         this.mnfrEdit = this.result.data; this.slctdId = id, this.lstSlctdAstTyps = this.slctdAstTyp = this.astTypLst.filter(e=>e.id == this.mnfrEdit?.assetTypeId),
        this.stupCCEManurerForm.patchValue({ name: this.mnfrEdit?.name ?? '', description: this.mnfrEdit?.description ?? null,
        assetTypeIds:  this.astTypLst.filter(e=>e.id == this.mnfrEdit?.assetTypeId)}); // description
      }
    });
  }

  setPage(pagNo: number, type: any): void {
    if(type == 'astMnfr')
    this.crntPage = pagNo; this.LoadManufacturers();
  }

  searchManuftr() {
    let crntPgIndx = this.crntPage - 1,
      Vname = this.stupCCEManurerForm.get('SrchName')?.value != null ? this.stupCCEManurerForm.get('SrchName')?.value : '';
    this.manufacturersLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetVendor, environment.apiMthds.getVndrByName.
      replace('nnn', Vname), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.manufacturersLst = this.result.data?.producerDTO != null ? this.result.data?.producerDTO : [],
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
                // this.onReset();
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  get fc() { return this.stupCCEManurerForm.controls; }


  onRemove(id: any) {
    if (confirm("Are you sure want to delete ?")) {
    this.FetchData(environment.apiCalls.del, environment.apiPaths.asetVendor, environment.apiMthds.delMat.replace('rrrrr', id), null,  2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') 
          this.glblSvc.onTstr('s', this.result.message), this.LoadManufacturers();
      else
      this.glblSvc.onTstr('e', this.result.message);
      }
    })
  }
  }

  onEE() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.vndr, environment.apiMthds.exprtVndrs.
      replace('eee',this.usrLgnDtls.email).replace('vvv', this.stupCCEManurerForm.get('SrchName')?.value ?? ''), null, 7, true, 0).then(() => {
    if (this.result)
      this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
  });
}

  onReset() {
   this.slctdId='', this.stupCCEManurerForm.reset(), this.lstSlctdAstTyps = [], this.slctdAstTyp = [], this.LoadManufacturers();
   this.isSbmtd= false, this.srchNme = '';
  }

  saveVndr() {
    this.checkFormValidations(this.stupCCEManurerForm);
    if (this.stupCCEManurerForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
    let payload = this.stupCCEManurerForm.getRawValue(), aT: any[] = []; payload.id = this.slctdId ?? 0; payload.assetTypeIds =  
      payload.assetTypeIds != null ? this.lstSlctdAstTyps?.forEach((e: any) => { aT.push(e.id) }) : [], payload.assetTypeIds = aT;
      this.FetchData(environment.apiCalls.post, environment.apiPaths.asetVendor, environment.apiMthds.save, payload, 2, true, 1).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
          this.LoadManufacturers(); this.onReset(); this.glblSvc.sbjctCCEMdl.next('update');
      });
      }
    }

    onMultiSlct(type: string, item: any) {
      if (type == 's') {
        if (this.lstSlctdAstTyps.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdAstTyps.push(item);
      }
      else if (type == 'r') {
        this.lstSlctdAstTyps = this.lstSlctdAstTyps.filter((f: { id: any; }) => f.id != item.id);
      }
      else if (type == 'a') {
        this.lstSlctdAstTyps = this.astTypLst;
      }
      else {
        this.lstSlctdAstTyps = [];
      }
    }
}
