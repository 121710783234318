import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import * as FusionCharts from 'fusioncharts';

@Component({
  selector: 'evin-assets-details',
  templateUrl: './assets-details.component.html',
  styleUrls: ['./assets-details.component.css']
})

export class AssetsDetailsComponent extends BaseComponent implements OnInit {
  @ViewChild ('summry') summry: any;
  assetId: any; assetsData: any[] = []; MntrngPntLst: any[] = []; ConfgData: any[] = []; srNoLst: any[] = []; selectedIndex: any = null;
  assetDetailsForm = this.formBuilder.group({}); tempLogLst: any[] = []; snsrMntrPnts: any[] = []; noRcrdsTxt = environment.TblNoRcrds;
  usrDataLst: any[] = []; showField = true; assetConfiguration: any; tab = 'A'; sensorData: any[] = []; dt2day = new Date();
  tmZoneLst: any[] = []; cntriesLst: any[] = []; LngLst: any[] = []; comuChnlLst: any[] = []; comChannel: any; seletedISTDate: any = '';
  language: any; country: any; timeZone: any; isShowstatus = true; assteMappedID: any; frmDate: any = null; isMappedAsset = false;
  assetSummaryPoints: any[] = []; recentActivityList: any[] = []; astStatsLst: any[] = []; tempMappedSerialNo: any; showSveBtn = true; 
  userlgndtls : any = null; cceList: boolean = true; cceEdit : boolean = true; cceDlt: boolean = true; cnfgVal: any = null;
  ccedtlAddRlnshp: boolean = true; cceAsstdDtlDltRlntsp: boolean = true; cceAsstDtlUpdate: boolean = true; prmsns: any = null;
  isChngAstHdn = false; macAd: any= '';

  constructor(public glblSvc: GlobalService
    , private router: Router
    , private formBuilder: FormBuilder) {
    super(glblSvc);
    this.assetDetailsForm = this.formBuilder.group({ srNumber: [''], fromDate: [], toDate: [''], assetworkingStatus: [''] }), this.glblSvc.sbjctMstrData.subscribe(s => {
      this.tmZoneLst = s.tmZoneLst, this.cntriesLst = s.cntriesLst, this.LngLst = s.LngLst, this.comuChnlLst = s.comuChnlLst,
        this.astStatsLst = s.stsLst.filter((f: any) => f.statusType === 'Asset')
    });
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.find((e: any) =>
    {
     if(e?.menuCode =='mm_st_cm_ad_ar')
     this.ccedtlAddRlnshp = e?.action;
     if(e?.menuCode =='mm_st_cm_ad_dr')
     this.cceAsstdDtlDltRlntsp = e?.action;
     if(e?.menuCode =='mm_st_cm_ad_up')
     this.cceAsstDtlUpdate = e?.action;
  });
  this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
    modulePermissionRoles[1] : null;
  this.isChngAstHdn = this.cnfgVal?.permissionRoles[14]?.configuredValue?.value ?? false,
    this.glblSvc.sbjctAssetId.subscribe((s: any) => {
      this.assetId = s;
      if (s > 0)
        this.LoadAssetDetails();
      else
        this.router.navigate(['home/asst-asts']);
    });
  }

  setIndex(index: number, sensorPoint: any, id: any) {
    if (sensorPoint) {
      if (sensorPoint.sensor == 'A') 
        this.macAd = this.assetsData[0]?.sensorAMAC;
      else  if (sensorPoint.sensor == 'B') 
        this.macAd = this.assetsData[0]?.sensorBMAC;
      else  if (sensorPoint.sensor == 'C') 
        this.macAd = this.assetsData[0]?.sensorCMAC;
      else  if (sensorPoint.sensor == 'D') 
        this.macAd = this.assetsData[0]?.sensorDMAC;
      else  if (sensorPoint.sensor == 'E') 
        this.macAd = this.assetsData[0]?.sensorEMAC;
      else 
        this.macAd = '';
      this.selectedIndex = index, this.assetSummaryChart(this.frmDate, id, sensorPoint.sensor), this.powerAvailabiltyChart(this.frmDate, id);
    }      
  }

  LoadAssetDetails() {
    this.assetsData = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.getAsetDetails.
      replace('assetId', this.assetId), null, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message == 'Records fetched successfully') {
              this.assetsData = this.result.data != null ? this.result.data : [], this.getSerialNoData('', this.assetsData[0]?.pranthId),
                this.tempLogLst = this.assetsData[0]?.temperatureLogDataList != null ? this.assetsData[0]?.temperatureLogDataList : [],
                this.snsrMntrPnts = this.assetsData[0]?.sensorMonitoringPoints != null ? this.assetsData[0]?.sensorMonitoringPoints : [],
                this.usrDataLst = this.assetsData[0]?.userDetailsList != null ? this.assetsData[0]?.userDetailsList : [];
              if (this.assetsData[0]?.statusId != null)
                this.assetDetailsForm.patchValue({
                  assetworkingStatus: this.assetsData[0]?.statusId
                });
              this.setIndex(0, this.snsrMntrPnts[0], 'fc-line22'),
                this.sensorData = this.assetsData[0]?.assetConfiguration?.assetSensorConfigurationList != null ? this.assetsData[0]?.assetConfiguration?.assetSensorConfigurationList : [];
                this.tab = this.sensorData.length != 0 ? this.sensorData[0]?.sensorId: 'A';
              if (this.assetsData[0]?.mappedAssetSerialNumber != null)
                this.assetDetailsForm.patchValue({
                  srNumber: this.assetsData[0]?.mappedAssetSerialNumber != null ? this.assetsData[0]?.mappedAssetSerialNumber : ''
                }), this.assetDetailsForm.get('srNumber')?.disable(), this.isMappedAsset = true, this.showSveBtn = false;
              if (this.assetsData[0]?.assetConfiguration != null && this.assetsData[0]?.assetConfiguration != '') {
                this.comChannel = this.comuChnlLst.filter(a => a.id == this.assetsData[0]?.assetConfiguration?.communicationChannelId)[0]?.name ?? '',
                  this.language = this.LngLst.filter(a => a.id == this.assetsData[0]?.assetConfiguration?.languageId)[0]?.name,
                  this.country = this.cntriesLst.filter(a => a.id == this.assetsData[0]?.assetConfiguration?.countryId)[0]?.name,
                  this.timeZone = this.tmZoneLst.filter(a => a.id == this.assetsData[0]?.assetConfiguration?.timeZoneId)[0]?.name
              }
              if(this.assetsData[0]?.assetTypeId == 1)
                  this.tempRelations(this.assetsData[0]?.serialNumber);
            }
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
  }

  getSerialNoData(serialNumber: any, pranth: any) {
    this.srNoLst = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asset, environment.apiMthds.getAvailTempLog.replace('st', serialNumber)
    .replace('tttttt', pranth), null, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.srNoLst = this.result.data != null ? this.result.data : []
      }
    });
  }

  tempRelations(serialN: any){
    let payLoad: any = serialN;
    this.tempMappedSerialNo = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.tempAstRelatn, payLoad, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.tempMappedSerialNo = this.result.data != null ? this.result.data : '';
        else 
          this.glblSvc.onTstr('e', this.result.message);          
      }
    });
  }

  asetConfg() {
    this.ConfgData = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.asetTypeConfg, environment.apiMthds.getAsetTyp.
      replace('id', '1'), null, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.ConfgData = this.result.data != null ? this.result.data : []
        }
      });
  }

  onSlct($event: any) {
    if ($event.value)
      this.assteMappedID = $event.item.id, this.showField = true;      
    else
      this.assteMappedID = '', this.showField = true;
  }

  onChngSNO(){
    let snVal = this.assetDetailsForm.get('srNumber')?.value;
    this.assetDetailsForm.get('srNumber')?.setValue(`${snVal}`.trim());
  }

  saveRelationMap() {
    let payLoad: any;
    if(this.assetDetailsForm.value.srNumber != ''){
      payLoad = { assetId: this.assetId, mappedAssetId: this.assteMappedID, updatedBy: this.userlgndtls?.userId, mappedSerialNumber: this.assetDetailsForm.value.srNumber };
      if(this.assteMappedID != ''){
        payLoad.mappedSerialNumber = null;
      } else {
        payLoad.mappedAssetId = 0;
      }
      this.FetchData(environment.apiCalls.post, environment.apiPaths.assetMapping, environment.apiMthds.saveRupdate, payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Record saved successfully")
              this.glblSvc.onTstr('s', this.result.message), this.LoadAssetDetails();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    } else {
      this.glblSvc.onTstr('w', 'Select related asset to map relationship');
    } 
   
  }

  delRelationMap() {
    if (confirm("Are you sure want to delete the relationship ?")) {
      this.FetchData(environment.apiCalls.del, environment.apiPaths.assetMapping, environment.apiMthds.delRelationAsset.replace('rrrrr', this.assetId), null, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Records deleted")
              this.assetDetailsForm.patchValue({
                srNumber: ''
              }), this.assetDetailsForm.get('srNumber')?.enable(), this.isMappedAsset = false,
                this.glblSvc.onTstr('s', this.result.message), this.LoadAssetDetails();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  cnclRelationMap(){
    this.showField = true;
    this.assetDetailsForm.patchValue({
      srNumber: ''
    });
    this.assetDetailsForm.get('srNumber')?.enable(); 
    this.isMappedAsset = false;
    let el: HTMLElement = this.summry.nativeElement;
    el.click();
  }

  updateAstStatus() {
    let payLoad: any = {};
    payLoad.assetId = this.assetId, payLoad.statusId = this.assetDetailsForm.value.assetworkingStatus;
    if (payLoad.statusId != '') {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.updateAstStatus, payLoad, 2, true, 3).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.message === "Asset status updated successfully")
              this.glblSvc.onTstr('s', this.result.message), this.LoadAssetDetails();
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
    else
      this.glblSvc.onTstr('w', 'Kindly select the asset status');
  }

  onChngDT(dtVal: any, id: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
    this.seletedISTDate = dtVal;
    this.frmDate = this.dateConverterTo(dtVal), this.setIndex(0, this.snsrMntrPnts[0], id);
  }

  dateConverterTo(dtVal: any) {
    let date = new Date(dtVal), dd: any = date.getDate(), mm: any = date.getMonth() + 1;
    if (mm <= 9)
      mm = '0' + mm;
    if (dd <= 9)
      dd = '0' + dd;
    let yyyy = date.getFullYear(), vaidDate = yyyy + "-" + mm + "-" + dd;
    return vaidDate;
  }

  clearBSDate(ctrl: string, id: any) {
    this.frmDate = null, this.seletedISTDate = '', this.assetDetailsForm.get(ctrl)?.setValue(''), this.setIndex(0, this.snsrMntrPnts[0], id);
  }

  assetSummaryChart(date: any, id: any, mappingSesnor: any) {
    let payLoad: any = {}, categories: any[] = [], dataPoints: any[] = [], trendLines: any[] = [];
    payLoad.assetId = this.assetsData[0].assetId, payLoad.assetSerialNumber = this.assetsData[0].serialNumber,
      payLoad.assetTypeId = this.assetsData[0].assetTypeId, payLoad.sensorName = [mappingSesnor];
    if (this.seletedISTDate == '' || this.seletedISTDate == null)
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.dateConverterTo(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)));
    else
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.frmDate;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.tempChart, payLoad, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.recentActivityList = this.result.data.recentActivity != null ? this.result.data.recentActivity : [];
          if (this.result.data.trendLine != null)
            for (let i = 0; i < this.result.data.trendLine.length; i++)
              trendLines.push({
                parentyaxis: "S", valueonright: "1", startvalue: this.result.data.trendLine[i]?.startValue, displayvalue: this.result.data.trendLine[i]?.displayValue?.toString(), color: this.result.data.trendLine[i]?.colorCode
              });

          let captionT = this.result.data.chartConfig[0].caption != "" ? this.result.data.chartConfig[0].caption : '';
          let subcaptionT = this.result.data.chartConfig[0].subCaption != "" ? this.result.data.chartConfig[0].subCaption : '';
          let pYAxisNameT = this.result.data.chartConfig[0].yaxisName != "" ? this.result.data.chartConfig[0].yaxisName : '';
          let xAxisnameT = this.result.data.chartConfig[0].xaxisname != "" ? this.result.data.chartConfig[0].xaxisname : '';
          let snumbersuffixT = this.result.data.chartConfig[0].snumbersuffix != "" ? this.result.data.chartConfig[0].snumbersuffix : '';
          let numberSuffixT = this.result.data.chartConfig[0].numberSuffix != "" ? this.result.data.chartConfig[0].numberSuffix : '';
          let categoryT: any[] = [];
          let dataT: any[] = [];
          let aa: any = this.result.data.xaxisData.tempDate.split('|');
          let bb: any  =  this.result.data.y1axisData.tempValue.split('|');
          for(let i=0; i< aa.length; i ++){
            categoryT.push({'label': aa[i]})
          }
          for(let j=0; j< bb.length; j ++){
            dataT.push({'value': bb[j], 'color': '#000000'})
          }
         
         
          //let dataT = this.result.data.y1axisData.tempValue;
          let seriesT = this.result.data.y1axisData.y1axisCaption;

          var that = this, fsnOptns: any = FusionCharts.options;
          fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });

          FusionCharts.ready(function () {
            var chartObj = new FusionCharts({
              type: 'zoomline',
              renderAt: id,
              width: '100%',
              height: '390',
              dataFormat: 'json',
              dataSource: {
                "chart": {
                  "caption": captionT,
                  "subcaption": subcaptionT,
                  "pYAxisName": pYAxisNameT,
                  "sYAxisName": "",
                  "xAxisname": xAxisnameT,
                  "snumbersuffix": snumbersuffixT,
                  "numberSuffix": numberSuffixT,
                  // "numVisibleLabels": "12",
                  "compactDataMode": "1",
                  "pixelsPerPoint": "0",
                  "lineThickness": "1",
                  "dataSeparator": "|",
                  // "snumberPrefix": "$",
                  "pYAxisMaxValue": "10",
                  "pYAxisMinValue": "0",
                  "sYAxisMaxValue": "10",
                  "sYAxisMinValue": "0",
                  "exportEnabled": "1",
                  "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
                  "exportTargetWindow": "_self",
                  "exportFileName": "Temperature",
                  "theme": "fusion",
                  "paletteColors": '#1aaf5d,#D9534F',
                  "usePlotGradientColor": "0",
                  "showAlternateHGridColor": "0",
                  "showCanvasBorder": '0',
                  "showBorder": "0",
                  "showPlotBorder": "0",
                  "bgColor": "#FFFFFF",
                  "showLegend": '0',
                  "divlineColor": "#999999",
                  "divLineIsDashed": "1",
                  "divLineDashLen": "1",
                  "divLineGapLen": "2",
                },
                "categories": [{
                  "category": categoryT
                }],
                "dataset": [{
                  "seriesname": seriesT,
                  "data": dataT,
                }
                ],
                "trendlines": [{ line: trendLines }],
              }
            });
            chartObj.render();
          });
        }
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    });


  }

  powerAvailabiltyChart(date: any, id: any) {
    let payLoad: any = {}, categories: any[] = [], dataPoints: any[] = [];
    if (this.seletedISTDate == '' || this.seletedISTDate == null)
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.dateConverterTo(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)));
    else
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.frmDate;
    payLoad.assetId = this.assetsData[0].assetId, payLoad.assetSerialNumber = this.assetsData[0].serialNumber,
    payLoad.assetTypeId = this.assetsData[0].assetTypeId;
  
    this.FetchData(environment.apiCalls.post, environment.apiPaths.asset, environment.apiMthds.powerAvailableLog, payLoad, 2, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          for (let i = 0; i < this.result.data.powerData.length; i++)
            categories.push({ label: this.result.data.powerData[i].powerAvailabilityTime }), dataPoints.push({ value: this.result.data.powerData[i].stat })
          var fsnOptns: any = FusionCharts.options;
          fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });

          if (this.result.data.powerDataDetails.powerAvailabilityTime != null)
            var categoriesData = this.result.data.powerDataDetails.powerAvailabilityTime;
          if (this.result.data.powerDataDetails.stat != null)
            var dataListPoints = this.result.data.powerDataDetails.stat;
          var that = this;
          FusionCharts.ready(function () {
            var chartObj = new FusionCharts({
              type: 'zoomline',
              renderAt: 'fc-line23',
              width: '100%',
              height: '390',
              dataFormat: 'json',
              dataSource: {
                "chart": {
                  caption: "",
                  subCaption: "",
                  yAxisName: '',
                  xAxisName: "Time",
                  "numberSuffix": "",
                  "compactDataMode": "1",
                  "pixelsPerPoint": "0",
                  "lineThickness": "1",
                  "dataSeparator": "|",
                  "exportEnabled": "1",
                  // "numVisibleLabels": "12",
                  "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
                  "exportTargetWindow": "_self",
                  "exportFileName": "Power Availability",
                  "theme": "fusion",
                  "paletteColors": '#1aaf5d,#D9534F',
                  "usePlotGradientColor": "0",
                  "showAlternateHGridColor": "0",
                  "showCanvasBorder": '0',
                  "showBorder": "0",
                  "showPlotBorder": "0",
                  "bgColor": "#FFFFFF",
                  "showLegend": '0',
                  "divlineColor": "#999999",
                  "divLineIsDashed": "1",
                  "divLineDashLen": "1",
                  "divLineGapLen": "2",
                },
                "categories": [{
                  "category": categoriesData,
                }],
                "dataset": [{
                  "seriesname": "Unique Footfall",
                  "data": dataListPoints
                }
                ],
                trendlines: [
                  {
                    line: [
                      {
                        showValue: 0,
                        startvalue: "1",
                        displayvalue: "Available",
                        linethickness: "2",
                        color: "0000FF",
                        valueonright: "1"
                      }
                    ]
                  }
                ]
              }
            });
            chartObj.render();
          });
        }
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    });

  }

  exportSummaryPoints() {
    let monitorPoints: any = []; this.snsrMntrPnts.forEach((e: any) => { monitorPoints.push(e.sensor) });
    let payLoad: any = {};
    payLoad.sensorName = monitorPoints, payLoad.assetId = this.assetId, payLoad.assetSerialNumber = this.assetsData[0].serialNumber,
      payLoad.assetTypeId = this.assetsData[0].assetTypeId;
    if (this.seletedISTDate == '' || this.seletedISTDate == null)
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.dateConverterTo(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)));
    else
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.frmDate;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.tempGrphExprt, payLoad, 3, true, 3).then(() => {
      if (this.result) {
        if (this.result.returnCode == 1 && this.result.status == "OK")
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    })
  }

  expttPwrAvlblty() {
    let monitorPoints: any = []; this.snsrMntrPnts.forEach((e: any) => { monitorPoints.push(e.sensor) });
    let payLoad: any = {};
      // payLoad.sensorName = monitorPoints, 
    payLoad.assetId = this.assetId, payLoad.assetSerialNumber = this.assetsData[0].serialNumber,
      payLoad.assetTypeId = this.assetsData[0].assetTypeId;
    if (this.seletedISTDate == '' || this.seletedISTDate == null)
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.dateConverterTo(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)));
    else
      payLoad.toDate = this.dateConverterTo(new Date()), payLoad.fromDate = this.frmDate;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.pwrAvlbltyDtlsExpt, payLoad, 3, true, 3).then(() => {
      if (this.result) {
        if (this.result.status == "OK")
          this.glblSvc.onTstr('s', this.result.message);
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    })
  }
}