import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
FusionCharts.addDep(Charts), FusionCharts.addDep(FusionTheme);

@Component({
  selector: 'evin-db-orders',
  templateUrl: './db-orders.component.html',
  styleUrls: ['./db-orders.component.css']
})

export class DbOrdersComponent extends BaseComponent implements OnInit {

  dt2day = new Date(); dayPicker = true; mnthPicker = false; slctdType: any = 'd'; resOrdrs: any[] = []; bsDate: any = new Date();
  frmDate: any = new Date(); @ViewChild('dayClick', { static: false }) dayClick: any; noRcrdsTxt = environment.TblNoRcrds;  slctdTab = '';
  dbOrdrForm: FormGroup = this.fb.group({}); stsLst: any[] = []; usrLvl = ''; dstLst: any[] = []; blkLst: any[] = [];
  lstChrt: any[] = []; isChrtShw = false; typeSlctd = 'd'; metric: any=null;

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.dbOrdrForm = this.fb.group({date:[this.dt2day], blockId: ['null'], districtId: ['null'], stateId: ['null'] });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {      
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.dbOrdrForm.get('country')?.setValue(cntryId);
    }
    this.dmnLocation(), setTimeout(() => { this.onAS(), this.onFltrSubmt() }, 1000);
  }

  get fc() { return this.dbOrdrForm.controls; }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dbOrdrForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.dbOrdrForm.get('districtId')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.dbOrdrForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('districtId')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.dbOrdrForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('stateId')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.dbOrdrForm.get('districtId')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.dbOrdrForm.get('blockId')?.setValue(blockID ?? null),
              setTimeout(() => { this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.dbOrdrForm.get('stateId')?.setValue('null'), this.dbOrdrForm.get('districtId')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  onTypeSlct(type: any) {
    this.slctdType = type;
    if (type == 'd')
      this.dayPicker = true, this.mnthPicker = false;
    else
      this.dayPicker = false, this.mnthPicker = true;
  }

  onChngDT(evnt: any) {
    this.frmDate = evnt;
  }

  onFltrSubmt() {
    let payLoad: any = this.dbOrdrForm.getRawValue(), dt: any, chkdDate: any = '', finalDate: any = '', slcDate: any = "";
    this.isChrtShw = false, payLoad.stateId = payLoad.stateId != 'null' ? +payLoad.stateId : 0, payLoad.blockId = payLoad.blockId != 'null' ?
      +payLoad.blockId : 0, payLoad.districtId = payLoad.districtId != 'null' ? +payLoad.districtId : 0;
    if (this.frmDate != null) {
      if (this.slctdType == 'm') {
        dt = this.glblSvc.setDtFrmt(this.frmDate, this.dtF.ymd), chkdDate = dt.split('-');
        finalDate = this.GetMonthDates(+chkdDate[1], +chkdDate[0]); slcDate = finalDate.split('...');
        payLoad.type = this.slctdType, payLoad.date = slcDate[1];
      }
      else
        payLoad.type = this.slctdType, payLoad.date = this.glblSvc.setDtFrmt(this.frmDate, this.dtF.ymd);
    }
    else
      payLoad.type = this.slctdType, payLoad.date = '';
    this.slctdTab = '', this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbIndnts,
        payLoad, 5, true, 2).then(() => {
      if (this.result) {
        this.resOrdrs = [];
        if (this.result.data) {
          if (this.result.status == 'OK')
            this.resOrdrs = this.result.data?.totalResponse != 'null' ? this.result.data?.totalResponse : [],
            this.lstChrt = this.result.data?.dayAndMonthWiseResponse != 'null' ? this.result.data?.dayAndMonthWiseResponse : [];         
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      }
    });
  }

  setChrtData() {
   
    this.isChrtShw = true, document.getElementById('Map-tab')?.click();
    let lstLbls: any[] = [], lstVals: any[] = [];
    this.lstChrt.forEach((e: any) => {
      lstLbls.push({ label: this.slctdType == 'd' ? this.glblSvc.setDtFrmt(e.date, this.dtF.dmy) : e.date }),

      lstVals.push({ value: this.slctdTab == 'i' ? e?.orders : (this.slctdTab == 'f' ? e?.fulfilled_orders : (this.slctdTab == 'p' ? 
      e?.pending : (this.slctdTab == 'c' ? e?.confirmed : (this.slctdTab == 'd' ? e?.readyForDispatch : e?.shipped_orders )))) });

      this.metric = this.slctdTab == 'i' ? 1 : (this.slctdTab == 'p' ? 2 : (this.slctdTab == 'c' ?  3 : (this.slctdTab == 'd' ? 4 : 
        (this.slctdTab == 'f' ? 6 : 5))));

    }); this.typeSlctd = this.slctdType;
    if (lstLbls.length > 0 && lstVals.length > 0)
      this.plotChrt(lstLbls, lstVals);
  }

  plotChrt(lblData: any[], chrtData: any[]) {
    var fsnOptns: any = FusionCharts.options, xAxsLbl = this.slctdType == 'd' ? 'Dates' : 'Months', yAxsLbl = this.slctdTab =='i' ? 'No.of Indents' : this.slctdTab =='s' ? 'No.of Shipped Indents' : this.slctdTab =='c' ?
    'No.of Confirmed Indents' : this.slctdTab =='p' ? 'No.of Pending Indents' : this.slctdTab =='d' ? 'No.of Ready for Dispatch Indents' : 'No.of Fulfilled Indents';
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function() {
      var myChart = new FusionCharts({ type: 'mscolumn2d', renderAt: 'chrt', width: '100%', height: '300', dataFormat: 'json',
        dataSource: { chart: { caption: '', subcaption: '', xaxisname: xAxsLbl, yaxisname: yAxsLbl, formatnumberscale: '', theme: 'fusion',
        exportEnabled: '1', exportTargetWindow: '_self', exportFormats: environment.fcExprtOptns, exportFileName: "Dashboard Indents",
        drawcrossline: '1', plottooltext: '<b>$dataValue</b> on <b>$seriesName</b> in $label' }, categories: [{ category: lblData }],
        dataset: [{ seriesname: '', data: chrtData }] } }).render();
    });
  }

  clearBSDate(ctrl: string) {
    this.bsDate = '', this.frmDate = '';
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.dbOrdrForm.get('districtId')?.setValue('null'),
          this.dbOrdrForm.get('blockId')?.setValue('null');
      else
        did = slctdId, this.blkLst = [],this.dbOrdrForm.get('blockId')?.setValue('null');
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.dbOrdrForm.get('districtId')?.enable(),
                this.dbOrdrForm.get('blockId')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.dbOrdrForm.get('blockId')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.dbOrdrForm.get('districtId')?.setValue('null'), this.dbOrdrForm.get('blockId')?.setValue('null'),
          this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('blockId')?.disable();
      else
        this.dbOrdrForm.get('blockId')?.setValue('null'), this.dbOrdrForm.get('blockId')?.disable();
    }
  }

  onReset() {
    let el: HTMLElement = this.dayClick.nativeElement;
    this.bsDate = new Date(), this.dbOrdrForm.get('date')?.setValue(this.dt2day), this.frmDate = new Date(), this.slctdType = 'd',  this.slctdTab = '', el.click(),
    this.dmnLocation(), setTimeout(() => { this.onAS(), this.onFltrSubmt()}, 500);
  }
  
  exprtExl(){
    // this.isChrtShw = false,
    let payLoad: any = this.dbOrdrForm.getRawValue(), dt: any, chkdDate: any = '', finalDate: any = '', slcDate: any = "";
     payLoad.stateId = payLoad.stateId != 'null' ? +payLoad.stateId : 0, payLoad.blockId = payLoad.blockId != 'null' ?
      +payLoad.blockId : 0, payLoad.districtId = payLoad.districtId != 'null' ? +payLoad.districtId : 0;
    if (this.frmDate != null) {
      if (this.slctdType == 'm') {
        dt = this.glblSvc.setDtFrmt(this.frmDate, this.dtF.ymd), chkdDate = dt.split('-');
        finalDate = this.GetMonthDates(+chkdDate[1], +chkdDate[0]); slcDate = finalDate.split('...');
        payLoad.type = this.slctdType, payLoad.date = slcDate[1];
      }
      else
        payLoad.type = this.slctdType, payLoad.date = this.glblSvc.setDtFrmt(this.frmDate, this.dtF.ymd);
    }
    else
      payLoad.type = this.slctdType, payLoad.date = ''; payLoad.metric = this.metric; payLoad.email = this.usrLgnDtls.email;
     this.FetchData(environment.apiCalls.post, environment.apiPaths.dashboard, environment.apiMthds.dbIndbtExprt,
        payLoad, 5, true, 2).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              this.glblSvc.onTstr('s', this.result.message);
            else
              this.glblSvc.onTstr('w', this.result.message);
          }
    });
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dbOrdrForm.get('stateId')?.enable(), this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.dbOrdrForm.get('stateId')?.disable(), this.dbOrdrForm.get('districtId')?.enable(), this.dbOrdrForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.dbOrdrForm.get('stateId')?.disable(), this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.dbOrdrForm.get('stateId')?.disable(), this.dbOrdrForm.get('districtId')?.disable(), this.dbOrdrForm.get('blockId')?.disable();
  }

}