import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-mnftr-trnsctns',
  templateUrl: './mnftr-trnsctns.component.html',
  styleUrls: ['./mnftr-trnsctns.component.css']
})
export class MnftrTrnsctnsComponent extends BaseComponent implements OnInit {

  frmDate: any = new Date(new Date().getTime() - (6 * 24 * 60 * 60 * 1000)); dt2day = new Date(); toDateF: any = new Date(); lstGrid: any[] = [];
  crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; pageEntrs = ''; ctDte: any = new Date(); slctPrdId: any = null; slctMnfgrId: any = null;
  lstAllMats: any = []; matName: any; lstManfacturs: any[] = []; mnftrName: any; isExprt: boolean = false; noRcrdsTxt = environment.TblNoRcrds;
  mnftrTrnsctnsFrm = this.fb.group({ fromDate: [this.frmDate], toDate: [this.toDateF], producerId: [''], batchNo: [''], productId: [''] });
  dfltPrdcrID: any= null;

  constructor(public glblSvc: GlobalService,
    private router: Router,
    public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {  this.lstAllMats = s.allMats });
    this.dfltPrdcrID = this.usrLgnDtls?.producerId;
    if (this.dfltPrdcrID != null)
        this.mnftrTrnsctnsFrm.get('producerId')?.setValue(this.usrLgnDtls?.producerName);
  }

  ngOnInit(): void {
    this.getAllProducers(), setTimeout(() => { this.LoadLst();}, 500);
  }

  get fc() { return this.mnftrTrnsctnsFrm.controls; }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onChngDtT(dtVal2: any){
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  clearBSDate(ctrl: string) {
    this.mnftrTrnsctnsFrm.get(ctrl)?.setValue('');
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadLst();
  }

  chngProduct(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == '' || evnt == undefined)
      this.slctPrdId = null;
    else {
      if (this.lstAllMats.filter((a: any) => a.name == evnt).length != 0)
        for (let s = 0; s < this.lstAllMats.length; s++) {
          if (this.lstAllMats[s].name == event.value)
            this.slctPrdId = this.lstAllMats[s]?.id;
        }
      else
        this.slctPrdId = null;
    }
  }

  chngMnftrs(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : (evntType == 'str1' ? event : event.value);
    if (evnt == '' || evnt == undefined)
      this.slctMnfgrId = null;
    else {
      if (this.lstManfacturs.filter((a: any) => a.producerName == evnt).length != 0)
        for (let s = 0; s < this.lstManfacturs.length; s++) {
          if (this.lstManfacturs[s].producerName == event.value)
            this.slctMnfgrId = this.lstManfacturs[s]?.producerId;
        }
      else
        this.slctMnfgrId = null;
    }
  }

  getAllProducers() {
    this.lstManfacturs = [];
    this.FetchData(environment.apiCalls.get, environment.apiPaths.producer, environment.apiMthds.getAllProducers, null).then(() => {
      if (this.result) {
        if (this.result.data)
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstManfacturs = this.result.data;
          else if (this.result.data == null || this.result.returnCode == 0)
            this.glblSvc.onTstr('i', this.result.message);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    })
  }
  
  LoadLst() {
    let payLoad = this.mnftrTrnsctnsFrm.getRawValue();
    this.lstGrid = [], payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
    payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.producerId = 
    this.dfltPrdcrID != null ? (this.usrLgnDtls?.producerId ?? null) : (this.slctMnfgrId ?? null), payLoad.productId = this.slctPrdId ?? null,
    payLoad.batchNo = payLoad.batchNo == '' ? null : payLoad.batchNo,
    this.FetchData(environment.apiCalls.post, environment.apiPaths.prducrLst, environment.apiMthds.getPrdcrTxn, payLoad, 12).then(() => {
      if (this.result) {
        if (this.result.data)
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstGrid = this.result.data?.producerTxnDetails ?? [], this.totalItems = this.result?.data?.count ?? this.lstGrid.length,
            this.pageEntrs = this.result.data?.count ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
          else if (this.result.data == null)
            this.glblSvc.onTstr('i', this.result.message);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    })
  }

  onEE() {
    let payLoad = this.mnftrTrnsctnsFrm.getRawValue();
    payLoad.fromDate = payLoad.fromDate ? this.glblSvc.setDtFrmt(payLoad.fromDate, this.dtF.ymd) + this.tmF.zeroTM : null,
    payLoad.toDate = payLoad.toDate ? this.glblSvc.setDtFrmt(payLoad.toDate, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.producerId = 
    this.dfltPrdcrID != null ? (this.usrLgnDtls?.producerId ?? null) : (this.slctMnfgrId ?? null), payLoad.productId = this.slctPrdId ?? null,
    payLoad.batchNo = payLoad.batchNo == '' ? null : payLoad.batchNo,
    this.FetchData(environment.apiCalls.post, environment.apiPaths.prducrLst, environment.apiMthds.exprtPrdcrTxn.replace('eml', 
      this.usrLgnDtls.email), payLoad, 12).then(() => {
      this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    })
  }

  onReset() {
    this.mnftrTrnsctnsFrm.reset(), this.mnftrTrnsctnsFrm.get('fromDate')?.setValue(this.wkBkDt), this.slctMnfgrId = null, this.slctPrdId = null,
    this.mnftrTrnsctnsFrm.get('producerId')?.setValue(''), this.mnftrTrnsctnsFrm.get('productId')?.setValue(''),
    this.mnftrTrnsctnsFrm.get('toDate')?.setValue(this.dt2day), this.frmDate, this.toDateF;
    if (this.dfltPrdcrID != null)
        this.mnftrTrnsctnsFrm.get('producerId')?.setValue(this.usrLgnDtls?.producerName); setTimeout(() => { this.LoadLst() }, 500);
  }
}
