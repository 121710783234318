<form [formGroup]="subdFrm">
    <div class="row mt-3">
        <div class="col-xl-3">
            <label>Material Name<sup class="mndt">*</sup></label>
            <ng-template #cstmProduct let-model="item">
                <span class="font-weight-bold">{{model.name}}</span>
            </ng-template>
            <input type="text" placeholder="Enter Material Name" id="material" [typeaheadItemTemplate]="cstmProduct"
                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                (typeaheadOnSelect)="onTAH($event, 'p', 's')" (change)="onTAH($event, 'p', 'c')"
                [typeahead]="lstMtrls" typeaheadOptionField="name" formControlName="productName" autocomplete="off"
                [attr.disabled]="isDTsShw ? true : null">
        </div>
        <div class="col-xl-3">
            <label>Manufacturer Name<sup class="mndt">*</sup></label>
            <ng-template #cstmMnfr let-model="item">
                <span class="font-weight-bold">{{model.name}}</span>
            </ng-template>
            <input type="text" placeholder="Enter Manufacturer Name" id="manufacturer" [typeaheadItemTemplate]="cstmMnfr"
                [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                (typeaheadOnSelect)="onTAH($event, 'm', 's')" (change)="onTAH($event, 'm', 'c')"
                [typeahead]="lstManfs" typeaheadOptionField="name" formControlName="producerName" autocomplete="off"
                [attr.disabled]="isDTsShw ? true : null">
        </div>
        <div class="col-xl-3">
            <label>Batch ID<sup class="mndt">*</sup></label>
            <input placeholder="Enter Batch ID" class="form-control form-control-sm text-uppercase"
                type="text" formControlName="batchNo" [attr.disabled]="isDTsShw ? true : null"
                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 &&
                    event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'>
        </div>
        <div class="col-xl-3 mt-4">
            <button class="btn btn-sm btn-light" (click)="onRst()">Reset</button>&nbsp;&nbsp;
            <button class="btn btn-sm btn-primary" (click)="isDTsShw=false;onChk();"
                [disabled]="fc.batchNo=='' || fc.producerName=='' || fc.productName =='' || isDTsShw">Check</button>
        </div>
    </div>
    <div class="row mt-3" *ngIf="isDTsShw">
        <div class="col-xl-3">
            <label>Manufactured Date<sup class="mndt">*</sup></label>
            <div class="input-group mb-3">
                <input type="text" autocomplete="off" onpaste="return false"
                    class="form-control form-control-sm optinbg bsDP-bg" [maxDate]="dt2Day"
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default',
                        showWeekNumbers: false, isAnimated: true, adaptivePosition: true }"
                    #dpMD="bsDatepicker" onkeypress='return event.charCode == 8' formControlName="dom"
                    readonly bsDatepicker>
                <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-bsdp p-2 pntr" (click)="dpMD.toggle()"
                        [attr.aria-expanded]="dpMD.isOpen"> <i class="fa fa-calendar f-12"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <label>Expiry Date<sup class="mndt">*</sup></label>
            <div class="input-group mb-3">
                <input type="text" autocomplete="off" onpaste="return false"
                    class="form-control form-control-sm optinbg bsDP-bg" 
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', isAnimated: true,
                        containerClass: 'theme-default', showWeekNumbers: false,
                        adaptivePosition: true }" [minDate]="dt2Day" #dpED="bsDatepicker"
                    onkeypress='return event.charCode == 8' formControlName="doe"
                    bsDatepicker readonly>
                <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-bsdp p-2 pntr" (click)="dpED.toggle()"
                        [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-6 mt-4">
            <div class="float-start">
                <button class="btn btn-sm btn-primary" (click)="onUpdt()" [disabled]="fc.dom=='' || fc.dom==null ||
                    fc.doe=='' || fc.dom==null">Update</button>
            </div>
        </div>
    </div>
</form>