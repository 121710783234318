<form [formGroup]="ordrStatForm" autocomplete="off">
    <div class="card">
        <div class="card-body">
            <h4>Consignee Indent</h4> <br>
            <div class="row p-0 mt-2">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="pt-0">
                        <div class="col-md-4 float-start">
                            <div class="card" style="background-color: #d5ddee;">
                                <div class="card-body">
                                    <span>eVIN Indent Id: <b>{{orderId}}</b></span>&nbsp;
                                    <div class="small mt-2">
                                        <div>Manufacturer Name: {{indntDtls[0]?.producerName}}</div>
                                        <div>Material Name: {{indntDtls[0]?.productName}}</div>
                                        <div>Ordered Quantity: {{indntDtls[0]?.quantity}}</div>
                                        <div>Supply Period: {{indntDtls[0]?.supplyPeriodFrom | date: 'MM-yyyy' }} to
                                            {{indntDtls[0]?.supplyPeriodEnd | date: 'MM-yyyy' }}</div>
                                        <div class="mt-0">Supply Order No #: {{indntDtls[0]?.reference}}</div>
                                    </div>
                                    <div class="small">Created on {{indntDtls[0]?.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls[0]?.createdName}}</a>
                                    </div>
                                    <div class="small">Last updated on {{indntDtls[0]?.updatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                                        <a>{{indntDtls[0]?.updatedName}}</a>
                                    </div>
                                </div>
                            </div>
                         </div>
                    </div>
                </div>
            </div>

            <hr class="mt-1 mb-1" />
            <div class="card border-0 mt-3 shadow">
                <div class="card-header p-2">
                    <div class="card border-0">
                        <div class="card-body p-2">
                            <div class="tab-pane fade show active" id="Items" role="tabpanel"
                                aria-labelledby="Items-tab">
                                <div class="col-md-12" *ngIf="isCnsgnPrmsn == 'National'">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Select Consignment</label>
                                                <select class="form-select form-select-sm"
                                                    formControlName="consignStrId" (change)="getRcmndQnty($event)">
                                                    <option value="" selcted disabled>Select</option>
                                                    <option *ngFor="let x of cnsgnStrLst" [value]="x.storeId">
                                                        {{x.storeName}} - {{x.location}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label>Assign Quantity</label>
                                                <input type="text" class="form-control form-control-sm"  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                    placeholder="Quantity" formControlName="consignQntity">
                                                <span class="text-secondary">(Total Requirement for Supply Period {{rcmdQntity}})</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group mt-3">
                                                <button class="btn btn-light mt-2" *ngIf="isEditCnsgn" (click)="onCancelEdit()">Cancel Edit</button>
                                                <button class="btn btn-primary mt-2" (click)="OnSaveConsgn()">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 mt-3">
                                    <p><b>eVIN Consignee List : </b></p>
                                    <div class="table_spacing table-responsive table-row-spacing">
                                        <table class="table table-striped table-sm table-bordered" id="table_detail">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">S.No</th>
                                                    <th class="text-center">eVIN Consignee No</th>
                                                    <th>Facility Name</th>
                                                    <th class="text-center">Quantity</th>
                                                    <th>Created By</th>
                                                    <th class="text-center" *ngIf="isCnsgnPrmsn == 'National'">Actions</th>
                                                </tr>
                                            </thead>                                           
                                            <tbody *ngIf="cnsgnQnLst.length != 0"> <!--(click)="openModal(x)"-->
                                                <tr *ngFor="let x of cnsgnQnLst; let i=index">
                                                    <td class="text-center">{{i+1}}</td>
                                                    <th class="text-center" (click)="ordSts(x)" style="cursor: pointer;">{{x?.purchase_no}}</th>
                                                    <td (click)="ordSts(x)" style="cursor: pointer;">{{x.storename}}</td>
                                                    <td class="text-center">{{x.quantity}}</td>
                                                    <td>{{x.createdby}} <br> {{x.createduserid}}</td>
                                                    <td class="text-center" *ngIf="isCnsgnPrmsn == 'National'">
                                                        <a (click)="onCnsgnEdt(x);"><i class="fa fa-pencil"></i></a>&nbsp;&nbsp;
                                                        <a (click)="onCnsgnDel(x.bookingconsignmentid)"><i class="fa fa-trash"></i></a>
                                                    </td>
                                                </tr>
                                                <tr *ngIf="cnsgnQnLst.length != 0">
                                                    <td colspan="3" class="text-center"><b>Total Quantity</b></td>
                                                    <td class="text-center"><b>{{ttlQty}}</b></td>
                                                    <td colspan="2"></td>
                                                </tr>
                                            </tbody>
                                            <tbody *ngIf="cnsgnQnLst.length == 0">
                                                <tr>
                                                    <td colspan="5">{{noRcrdsTxt}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>