import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { getFullYear } from 'ngx-bootstrap/chronos';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
@Component({
  selector: 'evin-asset-capacity',
  templateUrl: './asset-capacity.component.html',
  styleUrls: ['./asset-capacity.component.css']
})
export class AssetCapacityComponent extends BaseComponent implements OnInit {

  isFacBdge = 'badge'; lstFacTags: any[] = []; lstSlctdFacTags: any[] = []; lstSlctdAstType: any[] = []; isSbmtd = false;
  lstSlctdStores: any[] = []; lstFacIds: any[] = []; slctdFac: any[] = []; slctdFacId: any[] = []; crntPage = 1; pageEntrs = ''; usrLvl = '';
  stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; totalItems: any; lstGrid: any[] = []; pageSize = environment.tblPageSize;
  astCapctyForm: FormGroup = this.fb.group({}); noRcrdTxt = environment.TblNoRcrds; ttlCount = 0; astTypLst: any[] = []; astType: any = '';
  isDstShw = false; isFcltyShw = false; isExprt =  false;
  isRprtsHlpShw = environment.isRprtsHlpShw;
  dropdownSettings = {
    singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
    itemsShowLimit: 3, allowSearchFilter: true
  }; ddlStngs = {
    singleSelection: false, idField: 'storeid', textField: 'storename',
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true
  }; brdcrmData: any = []; dmnId = '';

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstFacTags = s.filter(f => f.badgeTypeId === environment.tagTypes.facility) }),
      this.glblSvc.sbjctMstrData.subscribe(s => {
        this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst
        this.astTypLst = s.astTypLst.filter((e: any) => { return e.name != 'Temperature Logger' })
      });

  }

  ngOnInit(): void {
    this.astCapctyForm = this.fb.group({
      isFacBdge: ['badge'], storeTags: [[], Validators.required], storeIds: [[], Validators.required], district: [null], state: [null],
      assetType: [[], Validators.required]
    }); this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.astCapctyForm.get('country')?.setValue(cntryId);
      if (this.usrLvl == 'State')
        this.astCapctyForm.get('state')?.setValue(usrLvlDtls?.stateId ?? 'null'), this.astCapctyForm.get('district')?.setValue('null'),
          this.onLocChng(usrLvlDtls?.stateId ?? null, 'state', true);
      if (this.usrLvl == 'District') {
        this.astCapctyForm.get('state')?.setValue(usrLvlDtls?.stateId ?? 'null'), Promise.all([this.onLocChng(usrLvlDtls?.stateId ?? null,
          'state', true), this.onLocChng(usrLvlDtls?.districtId ?? null, 'district', true)]).then(() => {
            this.astCapctyForm.get('district')?.setValue(usrLvlDtls?.districtId ?? 'null'),
              setTimeout(() => { this.astCapctyForm.get('district')?.disable() }, 1000);
          });
      }
      // if (this.usrLvl == 'Block')
      //   this.astCapctyForm.get('state')?.setValue(usrLvlDtls?.stateId ?? null), this.astCapctyForm.get('district')?.setValue(usrLvlDtls?.districtId ?? null),
      //     this.astCapctyForm.get('block')?.setValue(usrLvlDtls?.blockId ?? null);
    }
    setTimeout(() => { this.LoadStrName(), this.onAS(); }, 1000), this.brdcrmData.push({ brdName: this.usrLgnDtls?.pranthName, brdId: this.usrLgnDtls?.pranthId, type: this.slctDmnDetails?.pranthLevel, stID: null });
  }

  get fc() { return this.astCapctyForm.controls };

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.GenRprt();
  }

  onClkBrdcrumb(item: any) {
    console.log(item);
    let i = this.brdcrmData.findIndex((e: any) => e.brdId == item.brdId);
    //  for(var x =i; x<=this.brdcrmData.length; x++)
    let l = this.brdcrmData.length;
    if (this.brdcrmData[l - 1].brdId != item.brdId) {
      this.brdcrmData.splice(i + 1);

      if (item?.type == 'National')
        this.astCapctyForm.get('state')?.setValue(null), this.astCapctyForm.get('district')?.setValue(null), this.GenRprt(); 
      else if (item?.type == 'State')
        this.astCapctyForm.get('state')?.setValue(item?.brdId), this.astCapctyForm.get('district')?.setValue(null), this.GenRprt(); 
      else 
        this.astCapctyForm.get('state')?.setValue(item?.stID), this.astCapctyForm.get('district')?.setValue(item?.brdId), this.GenRprt(); 
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.astCapctyForm.get('district')?.setValue(null),
          this.astCapctyForm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.astCapctyForm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.astCapctyForm.get('district')?.enable(),
                  this.astCapctyForm.get('block')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.astCapctyForm.get('block')?.enable();
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.astCapctyForm.get('district')?.setValue('null'), this.astCapctyForm.get('block')?.setValue('null'),
          this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
      else
        this.astCapctyForm.get('block')?.setValue('null'), this.astCapctyForm.get('block')?.disable();
    }
  }

  onAS() {
    if (this.usrLvl == 'National')
      this.astCapctyForm.get('state')?.enable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
    if (this.usrLvl == 'State')
      this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.enable(), this.astCapctyForm.get('block')?.disable();
    if (this.usrLvl == 'District')
      this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.enable();
    // if (this.usrLvl == 'Block')
    //   this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.disable(), this.astCapctyForm.get('block')?.disable();
  }

  GenRprt() {
    this.checkFormValidations(this.astCapctyForm);
    if ((this.fc.storeTags.value != '' || this.fc.storeIds.value != '') && this.fc.assetType.value != '') {
      let payLoad: any = this.astCapctyForm.getRawValue(), fT: any[] = [], sN: any[] = [], aT: any[] = [];
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
        payLoad.assetType = payLoad.assetType.length != 0 && payLoad.assetType != null ? this.lstSlctdAstType?.forEach((e: any) => { aT.push(e.id) }) : [],
        payLoad.assetType = aT; payLoad.storeTags = payLoad.storeTags.length != 0 && payLoad.storeTags != null ?
          this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds.length != 0 &&
            payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeid) }) : [], payLoad.storeIds = sN, ['state',
              'district', 'isFacBdge'].forEach(e => { delete payLoad[e] });
      this.isDstShw = payLoad.stateId != 0 ? true: false, this.isFcltyShw = payLoad.districtId != 0 ? true : false,
      this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.astCpctyByStr.
        replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), payLoad, 2, true, 5).then(() => {
          let res = this.result;
          this.lstGrid = [];
          if (this.result) {
            if (this.result?.data) {
              this.lstGrid = this.result?.data?.assetCapacity ?? [], this.totalItems = this.result?.data?.totalCount ?? this.lstGrid.length;
              this.pageEntrs = this.result.data?.totalCount ? this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems) : this.totalItems;
            }
            else
              this.glblSvc.onTstr('i', res.message);
          }
          else
            this.glblSvc.onTstr('e', res.message);
        });
    }
    else
      this.glblSvc.onTstr('w', 'Please Choose Mandatory fields');
  }

  onEE() {
    let payLoad: any = this.astCapctyForm.getRawValue(), fT: any[] = [], sN: any[] = [], aT: any[] = [];
      payLoad.stateId = payLoad.state == 'null' ? 0 : +payLoad.state, payLoad.districtId = payLoad.district == 'null' ? 0 : +payLoad.district,
        payLoad.assetType = payLoad.assetType.length != 0 && payLoad.assetType != null ? this.lstSlctdAstType?.forEach((e: any) => { aT.push(e.id) }) : [],
        payLoad.assetType = aT; payLoad.storeTags = payLoad.storeTags.length != 0 && payLoad.storeTags != null ?
          this.lstSlctdFacTags?.forEach((e: any) => { fT.push(e.id) }) : [], payLoad.storeTags = fT; payLoad.storeIds = payLoad.storeIds.length != 0 &&
            payLoad.storeIds != null ? this.lstSlctdStores?.forEach((e: any) => { sN.push(e.storeid) }) : [], payLoad.storeIds = sN, ['state',
              'district', 'isFacBdge'].forEach(e => { delete payLoad[e] });
      this.isDstShw = payLoad.stateId != 0 ? true: false, this.isFcltyShw = payLoad.districtId != 0 ? true : false;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.reports, environment.apiMthds.exptAstCpcty.
      replace('eee', this.usrLgnDtls?.email), payLoad, 2, true, 5).then(() => {
      this.isExprt = false;
      if (this.result)
        this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
    });
  }

  onCncl() {
    this.lstSlctdFacTags = [...[]], this.lstSlctdStores = [...[]], this.slctdFac = [], this.slctdFacId = [], this.lstSlctdAstType = [...[]],
      this.astCapctyForm.get('isFacBdge')?.setValue('badge'), this.astCapctyForm.get('storeTags')?.setValue([]), this.astType = '',
      this.isExprt = false, this.brdcrmData = [],
      this.brdcrmData.push({ brdName: this.usrLgnDtls?.pranthName, brdId: this.usrLgnDtls?.pranthId, type: this.slctDmnDetails?.pranthLevel, stID: null });
    this.astCapctyForm.get('storeIds')?.setValue([]), this.astCapctyForm.get('assetType')?.setValue([]);
    if (this.usrLvl == 'National')
      this.astCapctyForm.get('state')?.setValue(null), this.astCapctyForm.get('district')?.setValue(null)
    this.astCapctyForm.get('state')?.enable(), this.astCapctyForm.get('district')?.disable();
    if (this.usrLvl == 'State')
      this.astCapctyForm.get('district')?.setValue(null), this.astCapctyForm.get('state')?.disable(),
        this.astCapctyForm.get('district')?.enable();
    if (this.usrLvl == 'District')
      this.astCapctyForm.get('state')?.disable(), this.astCapctyForm.get('district')?.disable();
  }

  onClkRdb(ctrl: string) {
    if (ctrl == 'storeTags')
      this.fc?.storeIds.setValue([]),
        this.fc?.storeIds?.clearValidators(), this.fc?.storeTags?.setValidators([Validators.required]), this.lstSlctdStores = [...[]];
    else
      this.fc?.storeTags.setValue([]), this.fc?.storeTags?.clearValidators(), this.fc?.storeIds?.setValidators([Validators.required]),
        this.fc?.storeTags?.updateValueAndValidity(), this.fc?.storeIds?.updateValueAndValidity(), this.lstSlctdFacTags = [...[]];
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else if (ctrl == 't') {
        if (this.lstSlctdAstType.filter((f: { id: any; }) => f.id === item.id).length == 0)
          this.lstSlctdAstType.push(item);
      }
      else
        if (this.lstSlctdStores.filter((f: { storeIds: any; }) => f.storeIds === item.storeid).length == 0)
          this.lstSlctdStores.push(item);
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter((f: { id: any; }) => f.id != item.id);
      else if (ctrl == 't')
        this.lstSlctdAstType = this.lstSlctdAstType.filter(f => f.id != item.id);
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstSlctdStores.filter(f => f.storeid != item.storeid);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else if (ctrl == 't')
        this.lstSlctdAstType = this.astTypLst;
      else if (ctrl == 'c')
        this.lstSlctdStores = this.lstFacIds;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else if (ctrl == 't')
        this.lstSlctdAstType = [];
      else if (ctrl == 'c')
        this.lstSlctdStores = [];
    }
  }

  LoadStrName() {
    let frmCtrls = this.astCapctyForm.getRawValue(), payLoad = {
      stateId: frmCtrls.state == 'null' ? 0 : +frmCtrls.state, districtId:
        frmCtrls.district == 'null' ? 0 : +frmCtrls.district
    }; this.slctdFacId = [], this.lstFacIds = [...[]], this.lstSlctdStores = [...[]],
      this.FetchData(environment.apiCalls.post, environment.apiPaths.ccp, environment.apiMthds.ccpStore, payLoad, 0, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstFacIds = this.result.data != null ? this.result.data : [];
            else
              this.glblSvc.onTstr('W', this.result.message);
          }
        }
      });
  }

  onDmnClk(dmn: any, type: string) {
    if (type == 's') {
      if (dmn?.districtId == null) {
        let obj = this.brdcrmData.find((e: any) => e.brdId == dmn.stateId); obj != 'undefined' ? this.brdcrmData.push({ brdName: dmn.stateName, brdId: dmn.stateId, type: 'State', stID: dmn?.stateId }) : this.brdcrmData;
        this.astCapctyForm.get('state')?.setValue(dmn.stateId ?? 'null'), this.astCapctyForm.get('district')?.setValue('null'), this.GenRprt();
      }
    }
    else {
      if (dmn?.storeId == null) {
        let obj = this.brdcrmData.find((e: any) => e.brdId == dmn.districtId); obj != 'undefined' ? this.brdcrmData.push({ brdName: dmn.districtName, brdId: dmn.districtId, type: 'District', stID:  dmn?.stateId}) : this.brdcrmData;
        this.dmnId = dmn.domainId, this.astCapctyForm.get('state')?.setValue(dmn?.stateId ?? 'null'), this.astCapctyForm.get('district')?.setValue(dmn?.districtId), this.GenRprt();
      }
    }
  }

}
