<form [formGroup]="facltyDtlsFrm" autocomplete="off">
    <div class="row">
        <div class="col-xl-8">
            <div class="card border-0 h-100">
                <div class="card-header p-2">
                    <h6 class="mb-0">{{strDetails?.name}}</h6>
                    <small>{{strDetails?.districtId}}, {{strDetails?.city}}, {{strDetails?.stateId}},
                        {{strDetails?.countryId}}</small>
                    <!-- <div class="float-end"><i class="fa fa-external-link" aria-hidden="true"></i></div> -->
                </div>
                <div class="card-body p-2">
                    <div class="mb-2">
                        <i class="fa fa-category" aria-hidden="true"></i>
                        <a><span class="badge bg-secondary">Sessions site--!</span></a>
                    </div>
                    <div class="mb-2">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <a><span class="badge bg-secondary">Session Site Supervisor [gjabc_siteuser]</span></a>
                    </div>
                    <div class="mb-2">
                        <p class=" text-danger"> <i class="fa fa-info-circle" aria-hidden="true"></i> 9 materials are
                            out of
                            stock</p>
                    </div>
                </div>
                <div class="card-footer p-2 small">
                    Created on {{strDetails?.createdOn | date: "yy/MM/dd hh:mm a"}} by <a>{{strDetails?.createdBy}}</a>,
                    Last updated on {{strDetails?.updatedOn | date: "yy/MM/dd hh:mm a"}} by
                    <a>{{strDetails?.updatedBy}}</a>
                </div>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="card border-0 h-100">
                <div class="card-body carousel_box p-2">
                    <div id="map-container-google-1" class="z-depth-1-half map-container"
                        style="width: 100%; height: 250px">
                        <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen></iframe>
                    </div>
                    <div class="clearfix"></div>
                    <div class="float-end mt-2"><span>23.2098, 77.4286</span></div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3">
        <div class="card-body p-2">
            <div class="row text-center">
                <div class="col-xl-6 pt-3">
                    <h6 class="mb-0">Stock Management</h6>
                    <small class="text-secondary ">Material category(s): RI Vaccines</small>
                    <h5 class=" text-success">100%</h5>
                    <small class="text-secondary">Available</small>
                </div>
                <div class="col-xl-6 pt-3">
                    <h6 class="mb-0">Temperature</h6>
                    <small class="text-secondary ">Exclude temperature state(s): Unknown</small>
                    <h5 class=" text-secondary">No data to display</h5>
                </div>
            </div>
            <div class="collapse multi-collapse" id="multiCollapseExample_details">
                <div class="row">
                    <div class="col-xl-6">
                        <div class="text-center">
                            <!-- <img src="./assets/img/graph.png"> -->
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="text-center">
                            <p>No data to display</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center"><span class="mb-0 text-center mt-2 mb-2" data-bs-toggle="collapse"
                href="#multiCollapseExample_details" role="button" aria-expanded="false"
                aria-controls="multiCollapseExample_details"><i class="fa fa-chevron-down f-24" aria-hidden="true">
                </i></span>
        </div>
    </div>
    <div class="card mb-0 mt-3">
        <div class="card-body p-4 pt-2 pb-2 border-0 row">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup-content-tab" data-bs-toggle="tab"
                            href="#setup-content" role="tab" aria-controls="setup-content" #stk aria-selected="false"
                            [ngClass]="{'active': routerTabTyoe =='s'}" (click)="onTabClck('s')">Stock Management</a>
                    </li>
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup1-content-tab" data-bs-toggle="tab"
                            href="#setup1-content" role="tab" aria-controls="setup1-content" #ind aria-selected="false"
                            [ngClass]="{'active': routerTabTyoe =='i'}" (click)="onTabClck('i')">Indents</a></li>
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup2-content-tab" data-bs-toggle="tab"
                            href="#setup2-content" role="tab" aria-controls="setup2-content" #txn aria-selected="false"
                            [ngClass]="{'active': routerTabTyoe =='t'}" (click)="onTabClck('t')">Transactions</a></li>
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup3-content-tab" data-bs-toggle="tab"
                            href="#setup3-content" role="tab" aria-controls="setup3-content" #mtrl aria-selected="false"
                            [ngClass]="{'active': routerTabTyoe =='m'}" (click)="onTabClck('m')">Materials</a></li>
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup4-content-tab" data-bs-toggle="tab"
                            href="#setup4-content" role="tab" aria-controls="setup4-content" #rlnshp
                            aria-selected="false" [ngClass]="{'active': routerTabTyoe =='r'}"
                            (click)="onTabClck('r')">Relationship</a></li>
                    <!-- <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup5-content-tab" data-bs-toggle="tab"
                            href="#setup5-content" role="tab" aria-controls="setup5-content" aria-selected="false" #stbrd
                            [ngClass]="{'active': routerTabTyoe =='b'}" (click)= "onTabClck('b')">Stock board</a></li> -->
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup6-content-tab" data-bs-toggle="tab"
                            href="#setup6-content" role="tab" aria-controls="setup6-content" #asts aria-selected="false"
                            [ngClass]="{'active': routerTabTyoe =='a'}" (click)="onTabClck('a')">Assets</a></li>
                    <li class="nav-item"><a class="nav-link nav-link1 f-14" id="setup7-content-tab" data-bs-toggle="tab"
                            href="#setup7-content" role="tab" aria-controls="setup7-content" #prfle
                            aria-selected="false" [ngClass]="{'active': routerTabTyoe =='p'}"
                            (click)="onTabClck('p')">Profile</a></li>
                </ul>
            </div>
            <div class="tab-content" id="top-tabContent">
                <div class="tab-pane fade show active" id="setup-content" role="tabpanel"
                    aria-labelledby="setup-content-tab">
                    <div class="row p-0 mt-3">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox">Material Category:</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                [(ngModel)]="slctdMat" [data]="lstMatTags"
                                                formControlName="includeProductBadge"
                                                (onSelect)="onMultiSlct('s', 'm', $event)"
                                                (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                (onDeSelectAll)="onMultiSlct('d', 'm', $event)">
                                            </ng-multiselect-dropdown>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                        <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <!-- <div class="row">
                    <div class="col-xl-6 mb-2">
                        <span style="display: inline-block;" class="me-2">
                            <label tyle="display: inline-block;">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span>
                        <span style="display: inline-block;">
                            <button type="button" class="ms-2 btn btn-sm btn-primary">
                                <i class="fa fa-file-excel-o" aria-hidden="true"></i> Export
                            </button>
                        </span>
                        <span style="display: inline-block;">
                            <label tyle="display: inline-block;" class="ms-3">Search</label>
                        </span>
                        <span style="display: inline-block;" class="ms-3">
                            <input type="text" class="form-control  ms-3" />
                        </span>
                    </div>
                </div> -->
                    <div class="row p-0">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="card border-0">
                                <div class="card-body border-0 pb-2 p-2">
                                    <div class="row">
                                        <div class="col-xl-4 text-start">
                                            <div *ngIf="lstMats1.length!=0">{{stkPageEntrs}}</div>
                                        </div>
                                        <div class="col-xl-4"> </div>
                                        <div class="col-xl-4 text-start">
                                            <div class="float-end" *ngIf="lstMats1.length!=0">
                                                <pagination-controls previousLabel="" nextLabel=""
                                                    (pageChange)="setStkPage($event, 'mat')"></pagination-controls>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                                            aria-labelledby="pills-tabview-tabinfo">
                                            <div class="table_spacing table-responsive mt-3">
                                                <table class="table table-striped table-sm table-bordered table1">
                                                    <thead>
                                                        <tr>
                                                            <th>Item No.</th>
                                                            <th>Material</th>
                                                            <th>Total stock</th>
                                                            <th>&nbsp;</th>
                                                            <th>Allocated</th>
                                                            <th>Available</th>
                                                            <th>In-transit </th>
                                                            <th>Min</th>
                                                            <th>Max</th>
                                                            <th style="width: 30%;">Last updated</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody *ngIf="lstMats1.length!=0">
                                                        <tr *ngFor="let item of lstMats1 | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i = index"
                                                            [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                                        'color': item.stockIndicator != null ? '#000000' : null }">
                                                            <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                                            <td>{{item.productName}}</td>
                                                            <td>{{item.totalStock}}</td>
                                                            <td title="Batch Details" class="toggle-data">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row" *ngIf="item.batchManagement"> <i
                                                                        class="fa fa-list-alt"></i>
                                                                </a>
                                                            </td>
                                                            <td title="Allocated Stock Details" class="toggle-data">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row">{{item.allocatedStock}}
                                                                </a>
                                                            </td>
                                                            <td>{{item.availableStock}}</td>
                                                            <td title="In-transit Stock Details" class="toggle-data">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row">{{item.intransitStock}}
                                                                </a>
                                                            </td>
                                                            <td title="Min-Max Details" class="toggle-data">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row">{{item.invMin}}
                                                                </a>
                                                            </td>
                                                            <td title="Min-Max Details">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row">{{item.invMax}}
                                                                </a>
                                                            </td>
                                                            <td>{{item.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}
                                                            </td>
                                                            <td title="History Details" class="toggle-data">
                                                                <a (click)="openModal(item.storeId, item.productId, item.productName, 'h')"
                                                                    [ngStyle]="{ 'color': item.stockIndicator != null ? '#000000' : null }"
                                                                    class="expand-row"><i class="fa fa-history"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody *ngIf="lstMats1.length==0">
                                                        <tr>
                                                            <td colspan="11" class="text-center">
                                                                {{noRcrdsTxt}}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div class=" pb-3 pt-3">
                                                <div class="row" *ngIf="lstMats1.length!=0">
                                                    <div class="col-xl-6 text-start">
                                                        {{stkPageEntrs}}
                                                    </div>
                                                    <div class="col-xl-6">
                                                        <div class="float-end">
                                                            <pagination-controls previousLabel="" nextLabel=""
                                                                (pageChange)="setStkPage($event, 'mat')">
                                                            </pagination-controls>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 text-start">
                                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(0) }" class="clrCde p-1 me-2">Normal</span>
                                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                                            class="clrCde p-1 me-2">Zero Stock</span>
                                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                                            class="clrCde p-1 me-2">Min</span>
                                                        <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                                            class="clrCde p-1 me-2">Max</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade" id="pills-map2" role="tabpanel"
                                            aria-labelledby="pills-map-tabinfo">
                                            <div class="card border-0">
                                                <div class="card-body p-2">
                                                    <div class="clearfix"></div>
                                                    <div id="map-container-google-1"
                                                        class="z-depth-1-half map-container"
                                                        style="width: 100%; height: 500px">
                                                        <iframe
                                                            src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                                            width="100%" height="100%" frameborder="0" style="border:0"
                                                            allowfullscreen></iframe>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setup1-content" role="tabpanel" aria-labelledby="setup1-content-tab">
                    <div class="border-0 p-2 row">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                        data-bs-toggle="tab" href="#top-contact-11" role="tab"
                                        aria-controls="top-contact-11" aria-selected="false">Indents</a></li>
                                <li class="nav-item"><a class="nav-link nav-link1 f-14" id="contact-top-tab-3"
                                        data-bs-toggle="tab" href="#top-contact-21" role="tab"
                                        aria-controls="top-contact-21" aria-selected="false">Create Indents</a></li>
                                <!-- <li class="nav-item"><a class="nav-link nav-link1 f-14" id="contact-top-tab-4"
                                        data-bs-toggle="tab" href="#top-contact-31" role="tab"
                                        aria-controls="top-contact-31" aria-selected="false">Demand</a></li> -->
                            </ul>
                        </div>
                        <div class="tab-content" id="top-tabContent">
                            <div class="tab-pane fade show active" id="top-contact-11" role="tabpanel"
                                aria-labelledby="contact-top-tab-2">
                                <!-- <evin-orders-list></evin-orders-list> -->
                                <!-- <div class="row p-0 mt-2">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="row mb-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Status</label>
                                                        <select class="form-select form-select-sm">
                                                            <option selected>All</option>
                                                            <option>pending</option>
                                                            <option>confirmed</option>
                                                            <option>ready for dispatch</option>
                                                            <option>backordered</option>
                                                            <option>shipped</option>
                                                            <option>fulfilled</option>
                                                            <option>cancelled</option>
                                                        </select>
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label for="textBox">From</label>
                                                        <input type="text" placeholder="From"
                                                            class="form-control form-control-sm" id="State">
                                                    </div>
                                                    <div class="form-group col-md-3">
                                                        <label for="textBox">To</label>
                                                        <input type="text" placeholder="To"
                                                            class="form-control form-control-sm" id="District-County">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                        <div class="row mb-2">
                                            <div class="form-group col-xl-3">
                                                <label for="textBox">Indent</label>
                                                <input type="text" placeholder="Order number"
                                                    class="form-control form-control-sm" id="">
                                            </div>
                                            <div class="form-group col-xl-3">
                                                <label for="textBox">Issue reference #</label>
                                                <input type="text" placeholder="Issue reference #"
                                                    class="form-control form-control-sm" id="">
                                            </div>
                                            <div class="form-group col-xl-3">
                                                <label for="textBox">Receipt reference #</label>
                                                <input type="text" placeholder="Receipt reference #"
                                                    class="form-control form-control-sm" id="">
                                            </div>
                                            <div class="form-group col-xl-3">
                                                <label for="textBox">Approval status</label>
                                                <select class="form-select form-select-sm">
                                                    <option selected>pending</option>
                                                    <option>Cancelled</option>
                                                    <option>Rejected</option>
                                                    <option>Approved</option>
                                                    <option>Expired</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="float-xl-end">
                                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row mt-3">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-6 mb-2">
                                                <span style="display: inline-block;" class="me-2">
                                                    <label tyle="display: inline-block;">Filter</label>
                                                </span>
                                                <span style="display: inline-block;">
                                                    <select class="form-select form-select-sm">
                                                        <option>5</option>
                                                        <option>10</option>
                                                    </select>
                                                </span>
                                                <span style="display: inline-block;">
                                                    <label tyle="display: inline-block;" class="ms-3">Search</label>
                                                </span>
                                                <span style="display: inline-block;" class="ms-3">
                                                    <input type="text" class="form-control form-control-sm ms-3" />
                                                </span>
                                            </div>
                                        </div>
                                        <div class="table_spacing table-responsive mt-4">
                                            <table class="table table-striped table-sm table-bordered"
                                                id="table_detail">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th class="text-center">Item No.</th>
                                                        <th class="text-center">Indent Id</th>
                                                        <th class="text-center">Items</th>
                                                        <th>Status</th>
                                                        <th>Facility</th>
                                                        <th>Created</th>
                                                        <th>Issuing Facility</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a></td>
                                                        <td class="text-center">1</td>
                                                        <td class="text-center">2375462</td>
                                                        <td class="text-center">1</td>
                                                        <td>Pending</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;">
                                                            <span class="f-14"><i class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">2</td>
                                                        <td class="text-center">2375121</td>
                                                        <td class="text-center">1</td>
                                                        <td>Pending</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">3</td>
                                                        <td class="text-center">2375119</td>
                                                        <td class="text-center">1</td>
                                                        <td>Pending</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">4</td>
                                                        <td class="text-center">2375118</td>
                                                        <td class="text-center">1</td>
                                                        <td>Pending</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">5</td>
                                                        <td class="text-center">2375116</td>
                                                        <td class="text-center">1</td>
                                                        <td>Pending</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">6</td>
                                                        <td class="text-center">2374962</td>
                                                        <td class="text-center">1</td>
                                                        <td>fulfilled</td>
                                                        <td>
                                                            <div class="small"><a hrfe="#">TestCCP001</a></div>
                                                            <div class="small">Khurda, Khurda, Odisha</div>
                                                        </td>
                                                        <td><a>Test name Dash</a>
                                                            <div class="small">28/3/21 10:13 PM</div>
                                                        </td>
                                                        <td><a>TestKhurdaDVS</a>
                                                            <div class="small">KHURDA, Khurda, Odisha</div>
                                                        </td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="tab-pane fade" id="top-contact-21" role="tabpanel"
                                aria-labelledby="contact-top-tab-3">
                                <div class="card mt-0 border-0">
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="card">
                                                    <div class="card-header p-2">
                                                        <div class="h6 mb-0">Indent Details</div>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Type<span
                                                                        class="text-danger">*</span></label>
                                                                <select class="form-select form-select-sm"
                                                                    aria-label="Default select example">
                                                                    <option selected>Indents(receipt)</option>
                                                                    <option value="1">Indents(issue)</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Receiving
                                                                    facility
                                                                    <span class="text-danger">*</span></label>
                                                                <input type="text" id="disabledTextInput"
                                                                    class="form-control form-control-sm"
                                                                    placeholder="1100 Quarters UPHC">
                                                            </div>
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Issuing
                                                                    facility</label>
                                                                <input type="text" id="TextInput"
                                                                    class="form-control form-control-sm"
                                                                    placeholder="Choose Issuing facility">
                                                            </div>
                                                            <div class="form-group col-xl-3">
                                                                <label for="textBox">Receipt reference #</label>
                                                                <input type="text" placeholder="Receipt reference #"
                                                                    class="form-control form-control-sm" id="">
                                                            </div>
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Indent
                                                                    categories</label>
                                                                <input type="text" id="TextInput"
                                                                    class="form-control form-control-sm"
                                                                    placeholder="Select order categories">
                                                            </div>
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Required by
                                                                    date</label>
                                                                <input type="text" id="TextInput"
                                                                    class="form-control form-control-sm"
                                                                    placeholder="Required by date">
                                                            </div>
                                                            <div class="col-xl-3">
                                                                <label for="input" class="col-form-label">Required by
                                                                    date</label>
                                                                <textarea class="form-control"
                                                                    id="exampleFormControlTextarea1"
                                                                    rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-12">
                                            <div class="card">
                                                <div class="card-header p-2">
                                                    <div class="h6 mb-0">Choose materials</div>
                                                </div>
                                                <div class="card-body p-2">
                                                    <div class="table_spacing table-responsive">
                                                        <table class="table table-striped table-sm table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col-">Item NO.</th>
                                                                    <th scope="col">Material</th>
                                                                    <th scope="col">
                                                                        <div>Total stock</div>
                                                                        <small> Quarters UPH ...</small>
                                                                    </th>
                                                                    <th scope="col">Quantity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <th scope="row">1</th>
                                                                    <td><input type="text" id="input12"
                                                                            class="form-control form-control-sm"
                                                                            placeholder="Type Material name to choose">
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="top-contact-31" role="tabpanel"
                                aria-labelledby="contact-top-tab-4">
                                <div class="row p-0 mt-2">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-12">

                                                <div class="row mb-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Material Tag:</label>
                                                        <select id="inputState1" class="form-select form-select-sm">
                                                            <option value="AL">All</option>
                                                            <option value="WY">CAMPAIGN</option>
                                                            <option value="WY">HIV</option>
                                                            <option value="WY">Malaria</option>
                                                            <option value="WY">OPEN Vials</option>
                                                            <option value="WY">RI Vaccines</option>
                                                            <option value="WY">Syringes</option>
                                                            <option value="WY">TB</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-3 mt-2 mb-2">
                                                <div class="dropdown">
                                                    <button class="btn btn-sm btn-primary dropdown-toggle" type="button"
                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        Indent type
                                                    </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a class="dropdown-item">Indents (issue)</a></li>
                                                        <li><a class="dropdown-item">Indents (receipt)</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 mt-3 mb-2">
                                                <input type="checkbox" id="scales" name="scales" checked>
                                                <label class="ms-2" for="scales">Filter by date of actual
                                                    transaction</label>
                                            </div>
                                            <div class="col-xl-3 mt-3 mb-2">
                                                <div class="float-xl-end">
                                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row mt-3">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <!-- <div class="row">
                                        <div class="col-xl-6 mb-2">
                                            <span style="display: inline-block;" class="me-2">
                                                <label tyle="display: inline-block;">Filter</label>
                                            </span>
                                            <span style="display: inline-block;">
                                                <select class="form-select form-select-sm">
                                                    <option>5</option>
                                                    <option>10</option>
                                                </select>
                                            </span>
                                            <span style="display: inline-block;"> <button type="button"
                                                    class="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#modal_export1"> <i class="fa fa-file-excel-o"
                                                        aria-hidden="true"></i> Export</button>
                                            </span>
                                            <span style="display: inline-block;">
                                                <label tyle="display: inline-block;" class="ms-3">Search</label>
                                            </span>
                                            <span style="display: inline-block;" class="ms-3">
                                                <input type="text" class="form-control  ms-3" />
                                            </span>
                                        </div>
                                    </div> -->
                                        <div class="table_spacing table-responsive mt-1">
                                            <table class="table table-striped table-sm table-bordered"
                                                id="table_detail">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Item No.</th>
                                                        <th>Issuing Facility</th>
                                                        <th>Material</th>
                                                        <th class="text-center">Ordered</th>
                                                        <th class="text-center">Allocated</th>
                                                        <th class="text-center">Shipped</th>
                                                        <th class="text-center">Yet to ship</th>
                                                        <th class="text-center">Total stock</th>
                                                        <th class="text-center">Available stock</th>
                                                        <th class="text-center">In-transit stock</th>
                                                        <th class="text-center">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">1
                                                        </td>
                                                        <td><a>BEL</a>
                                                            <div class="small">New Delhi, Capital Territory of Delhi
                                                            </div>
                                                        </td>
                                                        <td><a>bOPV (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"><a class="btn btn-sm btn-primary"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#allocation_note">Clear allocations</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">2
                                                        </td>
                                                        <td><a>BIBCOL</a>
                                                            <div class="small">Srinagar, Srinagar, Jammu and Kashmir
                                                            </div>
                                                        </td>
                                                        <td><a>BCG (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"></td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">3
                                                        </td>
                                                        <td><a>Test GMSD Chennai</a>
                                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                                        </td>
                                                        <td><a>BCG (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"></td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">4
                                                        </td>
                                                        <td><a>Test GMSD Chennai</a>
                                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                                        </td>
                                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"></td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">5
                                                        </td>
                                                        <td><a>Test GMSD Chennai</a>
                                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                                        </td>
                                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"><a class="btn btn-sm btn-primary"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#allocation_note">Clear allocations</a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">6
                                                        </td>
                                                        <td><a>Test GMSD Chennai</a>
                                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                                        </td>
                                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                                        <td class="text-center" data-bs-toggle="modal"
                                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                                        <td class="text-center"><a class="btn btn-sm btn-primary"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#allocation_note">Clear allocations</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setup2-content" role="tabpanel" aria-labelledby="setup2-content-tab">
                    <div class="border-0 mt-2 row">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                        data-bs-toggle="tab" href="#top-contact-41" role="tab"
                                        aria-controls="top-contact-2" aria-selected="false">Transactions</a></li>
                                <li class="nav-item"><a class="nav-link nav-link1 f-14" id="contact-top-tab-3"
                                        data-bs-toggle="tab" href="#top-contact-42" role="tab"
                                        aria-controls="top-contact-3" aria-selected="false">Create Transaction</a></li>
                            </ul>
                        </div>
                        <div class="tab-content" id="top-tabContent">
                            <div class="tab-pane fade show active" id="top-contact-41" role="tabpanel"
                                aria-labelledby="contact-top-tab-2">
                                <div class="row p-0 mt-3">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <div class="row">
                                            <div class="col-xl-12">

                                                <div class="row mb-2">
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Material</label>
                                                        <input type="text" placeholder="Filter by Material"
                                                            class="form-control form-control-sm" id="Material">
                                                    </div>
                                                    <div class="form-group col-xl-3">
                                                        <label for="textBox">Type</label>
                                                        <select class="form-select form-select-sm">
                                                            <option selected>All transactions</option>
                                                            <option>Issues/Net utilization</option>
                                                            <option>Receipts</option>
                                                            <option>Stock Count</option>
                                                            <option>Discards</option>
                                                            <option>Transfers</option>
                                                            <option>Incoming returns</option>
                                                            <option>Outgoing returns</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>
                                            <div class="row mb-2">
                                                <div class="form-group col-md-3">
                                                    <label for="textBox">From</label>
                                                    <input type="text" placeholder="From"
                                                        class="form-control form-control-sm" id="State">
                                                </div>
                                                <div class="form-group col-md-3">
                                                    <label for="textBox">To</label>
                                                    <input type="text" placeholder="To"
                                                        class="form-control form-control-sm" id="District-County">
                                                </div>
                                                <div class="form-group col-xl-3">
                                                    <label for="textBox">Receiving facility</label>
                                                    <input type="text" placeholder="Filter by Receiving facility"
                                                        class="form-control form-control-sm" id="Receiving facility">
                                                </div>
                                                <div class="form-group col-xl-3">
                                                    <label for="textBox">Issuing facility</label>
                                                    <input type="text" placeholder="Filter by Issuing facility"
                                                        class="form-control form-control-sm" id="Issuing facility">
                                                </div>
                                            </div>
                                            <div class="">
                                                <input type="checkbox" id="scales" name="scales" checked>
                                                <label class="ms-2" for="scales">Filter by date of actual
                                                    transaction</label>
                                            </div>
                                            <div class="row mb-2 mt-3">
                                                <div class="form-group col-xl-4">
                                                    <label for="textBox">Material Tag:</label>
                                                    <select id="inputState1" class="form-select form-select-sm">
                                                        <option value="AL">All</option>
                                                        <option value="WY">CAMPAIGN</option>
                                                        <option value="WY">HIV</option>
                                                        <option value="WY">Malaria</option>
                                                        <option value="WY">OPEN Vials</option>
                                                        <option value="WY">RI Vaccines</option>
                                                        <option value="WY">Syringes</option>
                                                        <option value="WY">TB</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-xl-4">
                                                    <label for="textBox">Transaction reason:</label>
                                                    <select id="inputState2" class="form-select form-select-sm">
                                                        <option value="AL">All</option>
                                                        <option value="WY">VVM Usable</option>
                                                        <option value="WY">Wrong entry</option>
                                                        <option value="WY">VVM Unusable</option>
                                                        <option value="WY">Vaccine/Diluent Discarded</option>
                                                        <option value="WY">Expired</option>
                                                        <option value="WY">Frozen</option>
                                                        <option value="WY">First time stock count</option>
                                                        <option value="WY">Broken</option>
                                                        <option value="WY">Lable unreadable</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="float-xl-end">
                                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row mt-4">
                                    <div class="col-xl-12 col-sm-12 col-xs-12">
                                        <!-- <div class="row">
                                        <div class="col-xl-6 mb-2">
                                            <span style="display: inline-block;" class="me-2">
                                                <label tyle="display: inline-block;">Filter</label>
                                            </span>
                                            <span style="display: inline-block;">
                                                <select class="form-select form-select-sm">
                                                    <option>5</option>
                                                    <option>10</option>
                                                </select>
                                            </span>
                                            <span style="display: inline-block;"> <button type="button"
                                                    class="ms-2 btn btn-sm btn-primary" data-bs-toggle="modal"
                                                    data-bs-target="#modal_export1"> <i class="fa fa-file-excel-o"
                                                        aria-hidden="true"></i> Export</button>
                                            </span>
                                            <span style="display: inline-block;">
                                                <label tyle="display: inline-block;" class="ms-3">Search</label>
                                            </span>
                                            <span style="display: inline-block;" class="ms-3">
                                                <input type="text" class="form-control  ms-3" />
                                            </span>
                                        </div>
                                    </div> -->
                                        <div class="table_spacing table-responsive">
                                            <table class="table table-striped table-sm table-bordered"
                                                id="table_detail">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th class="text-center">Item No.</th>
                                                        <th>Material</th>
                                                        <th class="text-center">Opening stock</th>
                                                        <th>Operation</th>
                                                        <th>Quantity</th>
                                                        <th>Closing stock</th>
                                                        <th>Updated on</th>
                                                        <th>Updated by</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">1</td>
                                                        <td><a>bOPV (dose)</a></td>
                                                        <td class="text-center">4,200</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">200</span>
                                                            <span class="float-end"><a data-bs-toggle="modal"
                                                                    data-bs-target="#Tab-row-Backdrop"><i
                                                                        class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">4,000</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Tesh BVSM</a></td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">2</td>
                                                        <td><a>OPEN TT (vial)</a></td>
                                                        <td class="text-center">4,400</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">200</span>
                                                            <span class="float-end"><a data-bs-toggle="modal"
                                                                    data-bs-target="#Tab-row-Backdrop2"><i
                                                                        class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">4,000</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Tesh BVSM</a></td>
                                                        <td style="width: 7%;" class="text-center"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">3</td>
                                                        <td><a>Pentavalent (dose)</a></td>
                                                        <td class="text-center">4,500</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">500</span>
                                                            <span class="float-end"><a data-bs-toggle="modal"
                                                                    data-bs-target="#Tab-row-Backdrop3"><i
                                                                        class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">4,500</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Tesh BVSM</a></td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">4</td>
                                                        <td><a>Pentavalent (dose)</a></td>
                                                        <td class="text-center">4,700</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">100</span>
                                                            <span class="float-end"><a data-bs-toggle="modal"
                                                                    data-bs-target="#Tab-row-Backdrop4"><i
                                                                        class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">4,200</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Tesh BVSM</a></td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">5</td>
                                                        <td><a>bOPV (dose)</a></td>
                                                        <td class="text-center">1</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">200</span>
                                                            <span class="float-end"><a><i class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">0</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Anitha</a></td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><a><i class="fa fa-external-link"
                                                                    aria-hidden="true"></i></a>
                                                        </td>
                                                        <td class="text-center">6</td>
                                                        <td><a>bOPV (dose)</a></td>
                                                        <td class="text-center">4,200</td>
                                                        <td>issue<div class="small"><a hrfe="#">To PB Test CCH131</a>
                                                            </div>
                                                            <div class="small"><a>(Indent shipment: 2337738-1)</a>
                                                            </div>
                                                        </td>
                                                        <td class="text-center"><span class="float-start">500</span>
                                                            <span class="float-end"><a><i class="fa fa-list"
                                                                        aria-hidden="true"></i></a></span>
                                                        </td>
                                                        <td class="text-center">5</td>
                                                        <td>20/3/21 1:27 PM</td>
                                                        <td><a>Anitha</a></td>
                                                        <td style="width: 7%;"><span class="f-14"><i
                                                                    class="fa fa-mobile"
                                                                    aria-hidden="true"></i></span>&nbsp;&nbsp;<span><i
                                                                    class="fa fa-desktop" aria-hidden="true"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="row mt-4">
                                            <span style="display: inline-block;">
                                                <!-- <button type="button" class="ms-2 btn btn-sm btn-primary">
                                                <i class="fa fa-file-excel-o" aria-hidden="true"></i> Export
                                            </button> -->
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="top-contact-42" role="tabpanel"
                                aria-labelledby="contact-top-tab-3">
                                <div class="card mt-3">
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="row ">
                                                    <div class="col-xl-3">
                                                        <label for="input112" class="col-form-label">Facility<span
                                                                class="text-danger">*</span></label>
                                                        <input type="text" id="TextInput112"
                                                            class="form-control form-control-sm"
                                                            placeholder="Choose Issuing facility">
                                                    </div>
                                                    <div class="col-xl-3">
                                                        <label for="input113" class="col-form-label">Transaction
                                                            Type<span class="text-danger">*</span></label>
                                                        <select class="form-select form-select-sm"
                                                            aria-label="Default select example">
                                                            <option selected>--Select Type--</option>
                                                            <option>Issues?Net Utilization</option>
                                                            <option>Receipts</option>
                                                            <option>Stock Count</option>
                                                            <option>Discards</option>
                                                            <option>Transfers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="row pt-2">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-2">
                                        <div class="h6 mb-0">Choose materials</div>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="table_spacing table-responsive">
                                            <table class="table table-striped table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col-">Item NO.</th>
                                                        <th scope="col">Material</th>
                                                        <th scope="col">
                                                            <div>Total stock</div>
                                                            <small>1100 Quarters UPH ...</small>
                                                        </th>
                                                        <th scope="col">
                                                            <div>Available stock</div>
                                                            <small>1100 Quarters UPH ...</small>
                                                        </th>
                                                        <th scope="col">Quantity</th>
                                                        <th scope="col">Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td><small>Select Facility & Transaction type To Choose
                                                                Materials...</small></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="float-xl-end">
                                            <button class="btn btn-sm btn-primary mb-2">Submit</button>
                                            <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setup3-content" role="tabpanel" aria-labelledby="setup3-content-tab">
                    <div [hidden]="!isShowMat">
                        <div class="row mt-3">
                            <div class="form-group col-xl-3">
                                <label for="textBox">Material Tag:</label>
                                <ng-multiselect-dropdown [settings]="dropdownSettings" [(ngModel)]="slctdMat" [data]="lstMatTagsStk"
                                    formControlName="includeProductBadge" (onSelect)="onMultiSlct('s', 'stk', $event)"
                                    (onSelectAll)="onMultiSlct('a', 'stk', $event)" (onDeSelect)="onMultiSlct('r', 'stk', $event)"
                                    (onDeSelectAll)="onMultiSlct('d', 'stk', $event)">
                                </ng-multiselect-dropdown>
                                <!-- <select formControlName="includeProductBadge" (change)="getAllMtrlLst()">
                                    <option *ngFor="let pc of lstMatTagsStk" [value]="pc.id">{{pc.name}}</option>
                                </select> -->
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="row">
                                    <div class="col-xl-4 text-start">
                                        <div *ngIf="mtrlList.length!=0">{{mtrlPageEntrs}}</div>
                                    </div>
                                    <div class="col-xl-4">
                                        <span style="display: inline-block;"><button class="btn btn-sm btn-primary ms-2 me-2" (click)="onAddMat();isShowMat=false"><i
                                                    class="fa fa-plus" aria-hidden="true"></i> Add</button></span>
                                        <span style="display: inline-block;"><button class="btn btn-sm btn-light me-2"><i
                                                    class="fa fa-refresh" aria-hidden="true"></i> Refresh</button></span>
                                    </div>
                                    <div class="col-xl-4 text-start">
                                        <!-- <div class="float-end" *ngIf="lstMats1.length!=0">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setStkPage($event, 'mat')">
                                            </pagination-controls>
                                        </div> -->
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table_spacing table-responsive mt-2">
                            <table class="table table-striped table-sm table-bordered" style="min-width: 1100px;">
                                <thead>
                                    <tr>
                                        <th scope="col">Item No.</th>
                                        <th scope="col">Material</th>
                                        <th scope="col">Min</th>
                                        <th scope="col">Max</th>
                                        <th scope="col">Daily consumption rate</th>
                                        <th scope="col">Temperature Min.</th>
                                        <th scope="col">Temperature Max.</th>
                                        <th scope="col">Updated by</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="mtrlList.length != 0">
                                    <tr *ngFor="let mat of mtrlList| paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems} ; let i = index">
                                        <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                                        <td><a>{{mat.productname}}</a></td>
                                        <td class="text-center">{{mat.minstock}}</td>
                                        <td class="text-center">{{mat.maxstock}}</td>
                                        <td class="text-center">{{mat.dailyconsumptionrate}}</td>
                                        <td class="text-center">{{mat.min}}</td>
                                        <td class="text-center">{{mat.max}}</td>
                                        <td class="text-center">{{mat.updatedBy}}</td>
                                        <td class="text-center" (click)="isShowMat=false;onMatEdit(mat);"><i class="fa fa-edit"></i></td>
                                        <td class="text-center" (click)="onMatDel(mat.icatalogue_id)"><i class="fa fa-trash"></i></td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="mtrlList.length == 0">
                                    <tr class="text-center">
                                        <td colspan="8">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="mtrlList?.length!=0">
                            <div class="col-xl-4 mb-2 text-start">
                                {{mtrlPageEntrs}}
                            </div>
                            <div class="col-xl-4 text-center">
                            </div>
                            <div class="col-lg-4">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2" [hidden]="isShowMat">
                        <div class="row">
                            <div class="form-group col-xl-3">
                                <label>Material<span class="text-danger">*</span></label>
                                <div>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.name}}</span><br />
                                    </ng-template>
                                    <input name="material" type="text" placeholder="Select Material Name"
                                        [typeahead]="lstFreeMtrls" autocomplete="new" [typeaheadScrollable]="true"
                                        formControlName="productId" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="name" [adaptivePosition]="true"
                                        class="form-control form-control-sm" id="prdtName"
                                        (typeaheadOnSelect)="onChngTAH($event)" [typeaheadOptionsLimit]="500" 
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.productId.errors }" />
                                        <div *ngIf="isSbmtd && fc.productId.errors" class="invalid-feedback">
                                            <div *ngIf="fc.productId.errors?.required">Material is required</div>
                                        </div>
                                </div>
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="min">Min<span class="text-danger">*</span></label>
                                <input type="text" class="form-control form-control-sm" id="min" formControlName="minStock"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.minStock.errors }" />
                                    <div *ngIf="isSbmtd && fc.minStock.errors" class="invalid-feedback">
                                        <div *ngIf="fc.minStock.errors?.required">Min stock is required</div>
                                    </div>
                            </div>
                            <div class="form-group col-xl-3">
                                <label for="max">Max<span class="text-danger">*</span></label>
                                <input type="text" id="max" class="form-control form-control-sm" formControlName="maxStock"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    [ngClass]="{ 'is-invalid': isSbmtd && fc.maxStock.errors }" />
                                    <div *ngIf="isSbmtd && fc.maxStock.errors" class="invalid-feedback">
                                        <div *ngIf="fc.maxStock.errors?.required">Max stock is required</div>
                                    </div>
                            </div>
                            <div class="col-xl-3 mt-4">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-primary me-2 mb-2" (click)="saveMat()">Save</button>
                                    <button class="btn btn-sm btn-light mb-2" (click)="isShowMat=true;isSbmtd = true;">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setup4-content" role="tabpanel" aria-labelledby="setup4-content-tab">
                    <div class="row mt-3" [hidden]="!addStatus">
                        <div class="mb-2">
                            <button class="btn btn-sm btn-primary mb-2 me-2" (click)="addStatus=false">
                                <i class="fa fa-plus" aria-hidden="true"></i> Add</button>
                            <button class="btn btn-sm btn-light  mb-2 me-2"><i class="fa fa-trash"
                                    aria-hidden="true"></i>
                                Remove</button>
                        </div>
                        <hr />
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                                <li class="nav-item"><a class="nav-link nav-link1 f-14 active" id="contact-top-tab-2"
                                        data-bs-toggle="tab" href="#top-contact-44" role="tab"
                                        aria-controls="top-contact-2" aria-selected="false">Receiving facilities <span
                                            class="badge bg-secondary">{{RecFaciLst.length}}</span></a>
                                </li>
                                <li class="nav-item"><a class="nav-link nav-link1 f-14" id="contact-top-tab-3"
                                        data-bs-toggle="tab" href="#top-contact-45" role="tab"
                                        aria-controls="top-contact-3" aria-selected="false">Issuing facilities <span
                                            class="badge bg-secondary">{{IssuFaciLst.length}}</span></a>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-content" id="top-tabContent">
                            <div class="tab-pane fade show active" id="top-contact-44" role="tabpanel"
                                aria-labelledby="contact-top-tab-2">
                                <div class="row ">
                                    <div class="col-xl-12">

                                        <div class="row mt-3">
                                            <div class="form-group col-md-4">
                                               
                                            </div>
                                            <div class="form-group col-md-6">
                                                <div class="row">
                                                    <label for="textBox" class="col-xl-3 mt-2">Facility
                                                        category:</label>
                                                    <div class="col-xl-4">
                                                        <select class="form-select form-select-sm">
                                                            <option selected>All</option>
                                                            <option>ABC</option>
                                                            <option>SAS</option>
                                                            <option>ADG</option>
                                                            <option>ADF</option>
                                                            <option>AFC</option>
                                                            <option>AAA</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-2">
                                                <div class="float-xl-end">
                                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <!-- <div class="row mt-4">
                                    <div class="col-xl-12 mb-2">
                                        <span style="display: inline-block;" class="me-2">
                                            <label tyle="display: inline-block;">Filter</label>
                                        </span>
                                        <span style="display: inline-block;">
                                            <select class="form-select form-select-sm">
                                                <option>5</option>
                                                <option>10</option>
                                            </select>
                                        </span>
                                    </div>
                                </div> -->
                                <div class="table_spacing table-responsive">
                                    <table class="table table-striped table-sm table-bordered"
                                        style="min-width: 1100px;">
                                        <thead>
                                            <tr>
                                                <th scope="col">&nbsp;</th>
                                                <th scope="row">
                                                    <input class="form-check-input" type="checkbox" value="">
                                                </th>
                                                <th scope="col">Item No.</th>
                                                <th scope="col">Receiving facility</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Move</th>
                                                <th scope="col">Reorder</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of RecFaciLst; let index=index">
                                                <td scope="row"><i class="fa fa-external-link" aria-hidden="true"></i>
                                                </td>
                                                <td scope="row">
                                                    <input class="form-check-input" type="checkbox" value="">
                                                </td>
                                                <td>{{index+1}}</td>
                                                <td><a>{{item.mappedStoreName}}</a></td>
                                                <td>{{item.storeLocation}}</td>
                                                <td><input type="text" class="form-control form-control-sm" /></td>
                                                <td>
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                </td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngIf="RecFaciLst.length == 0">
                                                <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="float-xl-end">
                                            <button class="btn btn-sm btn-primary mb-2">Save route</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="top-contact-45" role="tabpanel"
                                aria-labelledby="contact-top-tab-3">

                                <div class="row mt-3">
                                    <div class="form-group col-md-4">
                                        <!-- <span style="display: inline-block;">
                                            <label tyle="display: inline-block;" class="ms-3">Search</label>
                                        </span>
                                        <span style="display: inline-block;" class="ms-3">
                                            <input type="text" class="form-control form-control-sm ms-3" />
                                        </span> -->
                                    </div>
                                    <div class="form-group col-md-6">
                                        <div class="row">
                                            <label for="textBox" class="col-xl-3 mt-2">Facility category:</label>
                                            <div class="col-xl-6">
                                                <select class="form-select form-select-sm">
                                                    <option value="">All</option>
                                                    <option *ngFor="let ft of lstFacTags" [value]="ft.id">{{ft.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2">
                                        <div class="float-xl-end">
                                            <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                        </div>
                                    </div>
                                </div>

                                <hr />
                                <!-- <div class="row mt-4">
                                    <div class="col-xl-12 mb-2">
                                        <span style="display: inline-block;" class="me-2">
                                            <label tyle="display: inline-block;">Filter</label>
                                        </span>
                                        <span style="display: inline-block;">
                                          
                                            <select class="form-select form-select-sm">
                                                <option>5</option>
                                                <option>10</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <hr /> -->
                                <div class="table_spacing table-responsive mt-4">
                                    <table class="table table-striped table-sm table-bordered"
                                        style="min-width: 1100px;">
                                        <thead>
                                            <tr>
                                                <th scope="col">&nbsp;</th>
                                                <th scope="row">
                                                    <input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault11">
                                                </th>
                                                <th scope="col">Item No.</th>
                                                <th scope="col">Issue facility</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Move</th>
                                                <th scope="col">Reorder</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of IssuFaciLst; let index=index">
                                                <th scope="row"><i class="fa fa-external-link" aria-hidden="true"></i>
                                                </th>
                                                <td scope="row"><input class="form-check-input" type="checkbox" value=""
                                                        id="flexCheckDefault11"></td>
                                                <td>{{index+1}}</td>
                                                <td><a>{{item.mappedStoreName}}</a></td>
                                                <td>{{item.storeLocation}}</td>
                                                <td><input type="text" class="form-control form-control-sm" /></td>
                                                <td>
                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                                    <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                                </td>
                                                <td>1</td>
                                            </tr>
                                        </tbody>
                                        <tbody>
                                            <tr *ngIf="IssuFaciLst.length == 0">
                                                <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-xl-12">
                                        <div class="float-xl-end">
                                            <button class="btn btn-sm btn-primary mb-2">Save route</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card p-3" [hidden]="addStatus">

                        <div class="card-body p-0">
                            <h6 class="mb-0 border-bottom pt-2 pb-2">Add Relationships To {{strDetails?.name}}</h6>
                            <div class="row p-0 mt-3">
                                <div class="col-xl-12 col-sm-12 col-xs-12">
                                    <div class="row">
                                        <div class="col-xl-12">
                                            <div class="row mb-2">
                                                <div class="form-group col-xl-3">
                                                    <label for="textBox">Relation Type</label>
                                                    <select class="form-select form-select-sm" id="rltnType"
                                                        (change)="onChange($event)">
                                                        <option value="c">Receiving facility</option>
                                                        <option value="v">Issuing facility</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div class="float-xl-end">
                                                        <button class="btn btn-sm btn-primary me-2 mb-2"
                                                            (click)="createStrRelation()">Submit</button>
                                                        <button class="btn btn-sm btn-light mb-2"
                                                            (click)="addStatus=true">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 class="mb-0 border-bottom mt-3 pb-2">Related facility</h6>
                            <div class="row mt-3">
                                <div class="form-group col-md-4 mb-2">
                                    <label style="display: inline-block;">Facility</label>
                                    <input type="text" class="form-control form-control-sm"
                                        placeholder="Filter by facilities" />
                                </div>
                                <div class="form-group col-md-4 mb-2">
                                    <label for="textBox">Facility category:</label>
                                    <select class="form-select form-select-sm col-sm-4">
                                        <option value="">All</option>
                                        <option [value]="it.name" *ngFor="let it of lstFacTags">{{it.name}}</option>
                                    </select>
                                </div>
                                <div class="col-xl-4 pt_2">
                                    <div class="float-end">
                                        <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="text-end">{{pageEntrs}}</div>
                                    <div class="table_spacing table-responsive">
                                        <table class="table table-striped table-sm table-bordered"
                                            style="min-width: 1100px;">
                                            <thead>
                                                <tr>
                                                    <th scope="col">&nbsp;</th>
                                                    <th scope="row"><input class="form-check-input" type="checkbox"
                                                            id="flexCheckDefault11" (click)="onSlctAll($event)"></th>
                                                    <th scope="col">Item No.</th>
                                                    <th scope="col">Facility</th>
                                                    <th scope="col">Location</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of lstAllFacilities; let indx = index">
                                                    <th scope="row"><i class="fa fa-external-link"
                                                            aria-hidden="true"></i>
                                                    </th>
                                                    <td scope="row"><input class="form-check-input" type="checkbox"
                                                            id="flexCheckDefault11" (click)="onSlct($event, item)"
                                                            [checked]="item.flag"></td>
                                                    <td>{{indx+1}}</td>
                                                    <td>{{item.storeName}}</td>
                                                    <td>{{item.location}}</td>
                                                </tr>
                                            </tbody>
                                            <tbody>
                                                <tr *ngIf="lstAllFacilities.length == 0">
                                                    <td colspan="8" class="text-center">{{noRcrdsTxt}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="text-end">{{pageEntrs}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="float-xl-end">
                                        <button class="btn btn-sm btn-primary me-2 mb-2"
                                            (click)="createStrRelation()">Submit</button>
                                        <button class="btn btn-sm btn-light mb-2"
                                            (click)="addStatus=true">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- <div class="tab-pane fade" id="setup5-content" role="tabpanel" aria-labelledby="setup5-content-tab">
                    <div class="row mt-3">
                        <div class="col-xl-12">
                            <div class="float-xl-start">
                                <h6 class="mb-0">Stock board</h6>
                            </div>
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-primary mb-2 me-2">Save route</button>
                                <button class="btn btn-sm btn-light mb-2">Go to Stock board</button>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header p-2">
                            <div class="h6 mb-0">Enable Stock board</div>
                        </div>
                        <div class="card-body p-2">
                            <small class="pt-0 mt-0">Specify the stock board configuration options</small>
                            <div class="row mt-3">
                                <div class="col-xl-12">
                                    <label for="check21" class="form-label f-w-500">Enable</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                            id="flexRadioDefault1" checked>
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Default radio
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Only users in my domain can see
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault"
                                            id="flexRadioDefault1">
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Everyone can see
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header p-2">
                            <div class="h6 mb-0">Data options</div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row mt-3">
                                <div class="col-sm-4">
                                    <label for="input17" class="col-form-label">Number of items in view</label>
                                    <div class="col-xl-6"><input type="text" id="input17"
                                            class="form-control form-control-sm" placeholder="10"></div>
                                </div>
                                <div class="col-sm-4">
                                    <label for="input17" class="col-form-label">Refresh duration</label>
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <input type="text" id="input17" class="form-control form-control-sm"
                                                placeholder="3600">
                                        </div>
                                        <div class="col-xl-4">
                                            <span id="input17help" class="form-text">
                                                milliseconds
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header p-2">
                            <div class="h6 mb-0">Display options</div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row mt-3">
                                <div class="col-sm-6">
                                    <label for="input17" class="col-form-label">Scroll interval</label>
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <input type="text" id="input17" class="form-control form-control-sm"
                                                placeholder="50000">
                                        </div>
                                        <div class="col-xl-4">
                                            <span id="input17help" class="form-text">
                                                milliseconds
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-3">
                        <div class="card-header p-2">
                            <div class="h6 mb-0">Horizontal scroll options</div>
                        </div>
                        <div class="card-body p-2">
                            <div class="row">
                                <div class="col-xl-3">
                                    <label for="textarea">Messages to show</label>
                                    <div class="form-floating">
                                        <textarea class="form-control" placeholder="Leave a comment here"
                                            id="floatingTextarea2" style="height: 100px"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-sm-6">
                                    <label for="input17" class="col-form-label">Scroll interval</label>
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <input type="text" id="input17" class="form-control form-control-sm"
                                                placeholder="10000">
                                        </div>
                                        <div class="col-xl-4">
                                            <span id="input17help" class="form-text">
                                                milliseconds
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="float-end mt-0">
                        <button type="button" class="btn btn-sm btn-primary">Save</button>
                    </div>
                </div> -->
                <div class="tab-pane fade" id="setup6-content" role="tabpanel" aria-labelledby="setup6-content-tab">
                    <div class="row p-0 mt-3">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row">
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Type</label>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="astTypLst"
                                                formControlName="asetTypeId"
                                                (onSelect)="onChng('asetTypeId', $event, 'os')"
                                                (onSelectAll)="onChng('asetTypeId', $event, 'osa')"
                                                (onDeSelect)="onChng('asetTypeId', $event, 'ds')"
                                                (onDeSelectAll)="onChng('asetTypeId', $event, 'dsa')">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Relationship</label>
                                            <select class="form-select form-select-sm" formControlName="relationships">
                                                <option value="">All assets</option>
                                                <option value="1">Assets with relationships</option>
                                                <option value="2">Assets without relationships</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Working status</label>
                                            <select class="form-select form-select-sm" formControlName="approvalStatus">
                                                <option value="">All</option>
                                                <option *ngFor="let os of astStatusList" [value]="os.id">{{os.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Alarms</label>
                                            <select class="form-select form-select-sm" formControlName="alarms">
                                                <option value="0">All</option>
                                                <option value="1">Device alarms</option>
                                                <option value="2">No data</option>
                                                <option value="3">Normal items</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <div class="float-xl-end">
                                            <button class="btn btn-sm btn-light me-2 mb-2"
                                                (click)="crntPage=1;onReset()">Reset</button>
                                            <button class="btn btn-sm btn-primary mb-2"
                                                (click)="crntPage=1;LoadAssets()">Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row mt-3">
                        <div class="col-xl-12 col-sm-12 col-xs-12">
                            <div class="row" *ngIf="asstsList?.length!=0">
                                <div class="col-xl-6 text-start">
                                    &nbsp; &nbsp; {{astPgeEntrs}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel=""
                                            (pageChange)="setPage($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div> <br />

                            <div class="table_spacing table-responsive table-row-spacing">
                                <div class="table_spacing table-responsive table-row-spacing">
                                    <table class="table table-sm table_spacing table-small-font p-0"
                                        style="min-width: 1100px;">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Item No.</th>
                                                <th>Asset</th>
                                                <th>Type</th>
                                                <th>Working status</th>
                                                <th class="text-center" style="width: 50%">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                *ngFor="let a of asstsList | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index">
                                                <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                                <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                                <td (click)="onClick(a.assetId)" *ngIf="isShowAstdtls">
                                                    <a>{{a.serialNumber}} -
                                                        {{a.modelName}}
                                                        ({{a.assetVendorName}})</a>
                                                </td>
                                                <td *ngIf="!isShowAstdtls">{{a.serialNumber}} - {{a.modelName}}
                                                    ({{a.assetVendorName}})</td>
                                                <td>{{a.assetTypeName}}</td>
                                                <td>{{a.assetStatus}}</td>
                                                <td class="text-center">
                                                    <div *ngIf="a.assetTypeName=='Temperature Logger'">
                                                        <div *ngIf="a.deviceReadyStatus=='Device Ready'">
                                                            <i class="fa fa-check-circle-o tickGreen"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        <div *ngIf="a.deviceReadyStatus!='Device Ready'">
                                                            <i class="fa fa-times-circle tickRed"
                                                                aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="a.assetTypeName!='Temperature Logger'">
                                                        <div class="d-flex bd-highlight flex-wrap">
                                                            <div
                                                                *ngFor="let sm of a.sensorMonitoringPoints; let j=index">
                                                                <div
                                                                    *ngFor="let z of a.temperatureLogDataList; let k= index">
                                                                    <div
                                                                        *ngIf='a.temperatureLogDataList != null && a.sensorMonitoringPoints != null'>
                                                                        <div *ngIf='z.sensor == sm.sensor'
                                                                            [ngClass]="{'normal': (z.temperature < z.tempHigh) && (z.temperature > z.tempLow), 'highTemp': z.temperature > z.tempHigh,
                                                                        'lowTemp': z.temperature < z.tempLow, 'noClr': z.tempHigh == null &&  z.tempLow == null}">
                                                                            <div
                                                                                class="p-2 m-1 bd-highlight rounded box-outline-success tab_info_box">
                                                                                <div class="float-start">
                                                                                    {{sm.monitoring_point}}:
                                                                                    <span>{{sm.sensor}}</span>
                                                                                </div>
                                                                                <div class="float-end font-wt">
                                                                                    <span>{{z.temperature}}&deg;C</span>
                                                                                </div>
                                                                                <div class="float-end text-end">
                                                                                    <br />{{z.lastUpdated | date:
                                                                                    "dd/MM/yyyy hh:mm:ss aa"}}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <!-- <tbody *ngIf="asstsList.length == 0">
                                            <tr colspan="7">
                                                <td class="text-center">{{noRcrdsTxt}}</td>
                                            </tr>
                                        </tbody> -->
                                    </table>
                                </div>
                            </div> <br />
                            <div class="row" *ngIf="asstsList?.length!=0">
                                <div class="col-xl-6 text-start">
                                    {{astPgeEntrs}}
                                </div>
                                <div class="col-xl-6">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel=""
                                            (pageChange)="setPage($event)">
                                        </pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setup7-content" role="tabpanel" aria-labelledby="setup7-content-tab">
                    <div class="card p-3 pt-2 border-0">
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="card p-0 mb-3 mt-3">
                                    <div class="card-header p-2">
                                        <div class="h6 mb-0">Location</div>
                                    </div>
                                    <div class="card-body p-2" *ngFor="let x of strPrfle">
                                        <div class="row">
                                            <div class="col-sm-4">Country:</div>
                                            <div class="col-sm-8">{{x.country_name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">State:</div>
                                            <div class="col-sm-8">{{x.state_name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">District:</div>
                                            <div class="col-sm-8">{{x.district_name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Village/City:</div>
                                            <div class="col-sm-8">{{x.city_name}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Zip/Pin code:</div>
                                            <div class="col-sm-8">{{x.pin}}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="card p-0 mb-3">
                                                    <div class="card-header p-2">
                                                        <h6 class="mb-0">Financial details</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-4">Currency:</div>
                                                            <div class="col-sm-8">INR</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mb-3">
                                                    <div class="card-header p-2">
                                                        <h6 class="mb-0">Stock Management policy</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-4">Stock Management policy:</div>
                                                            <div class="col-sm-8">User specified replenishment - s,X</div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-sm-4">Batch management:</div>
                                                            <div class="col-sm-8">Disabled</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card p-0 mb-3">
                                                    <div class="card-header p-2">
                                                        <h6 class="mb-0">Custom ID</h6>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row">
                                                            <div class="col-sm-4">Custom ID:</div>
                                                            <div class="col-sm-8">test9cch1</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="card h-25 mt-3">
                                                    <div class="card-body text-center p-3">
                                                        No Image Available
                                                    </div>
                                                </div>
                                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="setu-content" role="tabpanel" aria-labelledby="conta-top-tab-">
                    <div class="card p-3 pt-2 border-0">
                        <div class="card-header p-2">
                            <div class="d-flex">
                                <div class="p-2 w-100 text-center h6">A B Kuwar</div>
                                <div class="p-2 flex-shrink-1"><i class="fa fa-external-link"></i></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="card p-0 mb-3 mt-3">
                                    <div class="card-header p-2">
                                        <h6 class="mb-0">Identification</h6>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Role:</div>
                                            <div class="col-sm-8">Operator</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Username:</div>
                                            <div class="col-sm-8">testmhnb056</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Categories:</div>
                                            <div class="col-sm-8"><span class="badge bg-primary">CCH</span></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Last login:</div>
                                            <div class="col-sm-8">2/11/18 3:16 PM</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Last reconnected:</div>
                                            <div class="col-sm-8">2/11/18 3:29 PM</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header p-2">
                                        <h6 class="mb-0">Personal details</h6>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">First name:</div>
                                            <div class="col-sm-8">A B Kuwar</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header p-2">
                                        <h6 class="mb-0">Contact information</h6>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Mobile phone:</div>
                                            <div class="col-sm-8">+91 6359876650</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Email:</div>
                                            <div class="col-sm-8"><a href="mg@gmail.com">mg@gmail.com</a></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header p-2">
                                        <h6 class="mb-0">Location</h6>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Country:</div>
                                            <div class="col-sm-8">India</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">State:</div>
                                            <div class="col-sm-8">Maharashtra</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">District:</div>
                                            <div class="col-sm-8">Nandurbar</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Language:</div>
                                            <div class="col-sm-8">English</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Timezone:</div>
                                            <div class="col-sm-8">India Standard Time</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header p-2">
                                        <h6 class="mb-0">Device details</h6>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Device/browser details:</div>
                                            <div class="col-sm-8">okhttp/3.3.1</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">IP Address:</div>
                                            <div class="col-sm-8">42.106.45.50</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Application version:</div>
                                            <div class="col-sm-8">Facilities app [2.0.0]</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Remote login:</div>
                                            <div class="col-sm-8">Use the default for this domain</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="small mt-3">
                                    <p class="mb-0">Created on 30/10/18 11:07 PM by <a>Rakesh Jatav</a></p>
                                    <p class="mb-0">Last updated on 4/3/21 11:42 AM by <a>Abhimanyu Saxena</a></p>
                                </div>
                                <hr class="mb-2 mt-0 pt-0" />
                                <div class="card h-25 mt-3">
                                    <div class="card-body text-center p-3">
                                        No Image Available
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-0 border-0">
                            <div class="card-body p-2">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item"><a class="nav-link active" id="Items-tab" data-bs-toggle="tab"
                                            href="#facility" role="tab" aria-controls="Items"
                                            aria-selected="true">Facility</a></li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Items" role="tabpanel"
                                        aria-labelledby="Items-tab">
                                        <div class="card mb-0">
                                            <div class="card-body pb-3 pt-3 mb-0">
                                                <div class="col-xl-12">
                                                    <ul class="float-end nav nav-pills nav-primary "
                                                        id="pills-clrtabinfo" role="tablist">
                                                        <li class=""><a class="nav-link btn btn-sm active"
                                                                id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                                                href="#pills-tabview" role="tab"
                                                                aria-controls="pills-tabview" aria-selected="true"><i
                                                                    class="fa fa-table me-0" aria-hidden="true"></i>
                                                                List
                                                                view</a></li>
                                                        <li class=""><a class="nav-link btn btn-sm"
                                                                id="pills-map-tabinfo" data-bs-toggle="pill"
                                                                href="#pills-map" role="tab" aria-controls="pills-map"
                                                                aria-selected="false"><i class="fa fa-desktop me-0"
                                                                    aria-hidden="true"></i> Map
                                                                view</a></li>
                                                    </ul>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="tab-content" id="pills-clrtabContentinfo">
                                                    <div class="tab-pane fade show active" id="pills-tabview"
                                                        role="tabpanel" aria-labelledby="pills-tabview-tabinfo">
                                                        <div class="table_spacing table-responsive mt-4">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" style="width: 5%;"></th>
                                                                        <th scope="col">Item No.</th>
                                                                        <th scope="col">Facility name</th>
                                                                        <th scope="col">Location</th>
                                                                        <th scope="col">Move</th>
                                                                        <th scope="col">Reorder</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><a><i class="fa fa-external-link"
                                                                                    aria-hidden="true"></i></a></td>
                                                                        <td class="text-center">1</td>
                                                                        <td><a>BCG (dose)</a></td>
                                                                        <td>Nandurbar, Nandurbar, Nandurbar, Maharashtra
                                                                        </td>
                                                                        <td><i class="fa fa-arrow-up"
                                                                                aria-hidden="true"></i>
                                                                            &nbsp;&nbsp;<i class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i></td>
                                                                        <td class="text-center">1</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><a><i class="fa fa-external-link"
                                                                                    aria-hidden="true"></i></a></td>
                                                                        <td class="text-center">2</td>
                                                                        <td><a>Test Nandurbar Somawal PHC</a></td>
                                                                        <td>Nandurbar, Maharashtra</td>
                                                                        <td><i class="fa fa fa-arrow-up"
                                                                                aria-hidden="true"></i>&nbsp;&nbsp;<i
                                                                                class="fa fa-arrow-down"
                                                                                aria-hidden="true"></i></td>
                                                                        <td class="text-center">2</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div class="float-end mt-3">
                                                                <button type="button"
                                                                    class="btn btn-sm btn-primary">Save
                                                                    route</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-map" role="tabpanel"
                                                        aria-labelledby="pills-map-tabinfo">
                                                        <div class="card border mt-4 mb-0">
                                                            <div class="card-body p-4">
                                                                No Data Available
                                                            </div>
                                                        </div>
                                                        <div class="float-end mt-3">
                                                            <button type="button" class="btn btn-sm btn-primary">Save
                                                                route</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>