<div class="card p-0 border-0">
    <div class="card-body p-2">
        <div class="row">
            <div class="col-xl-12 col-xs-12 mb-2">
                <div class="card p-0 border-0" style="min-height: 300px;">
                    <div class="card-body p-3" *ngFor="let mat of matDetals">
                        <div class="row">
                            <div class="col-xl-12 col-xs-12">
                                <div class="float-start">
                                    <div class="h6 me-2">{{mat.productName}}</div>
                                </div>
                            </div>
                            <div class="card-footer p-3 border-light">
                                <div class="mt-1 p-0"><small>Last updated on {{mat.updatedOn | date:'dd-MM-YYYY'}} by <a>{{mat.updatedBy}}</a></small>
                                </div>
                            </div>
                        </div>
                        <hr class="mt-0" />
                        <div class="row">
                            <div class="col-xl-12 col-xs-12">
                                <div class="row mt-2">
                                    <div class="col-sm-12">
                                        <div class="fa-tag_icon">
                                            <div class="ps-4 text_indent" style="font-weight: normal;">
                                                <span class="badge bg-primary me-2"><a class="text-white">{{mat.badge}}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card p-0 mb-3 mt-3">
                                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                        <h6>Material information</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                            <div class="col-sm-4"><strong>Batch management:</strong></div>
                                            <div class="col-sm-8">{{mat.batchEnabled}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4"><strong>Seasonal:</strong></div>
                                            <div class="col-sm-8">{{mat.isSeasonal}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                        <h6>Temperature</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                            <div class="col-sm-4"><strong>Temperature sensitive:</strong></div>
                                            <!-- <div class="col-sm-8" *ngIf="mat.isTempSensitive=='Yes'">Min.: {{mat.minimumTemperature}} &deg;C Max.: {{mat.maximumTemperature}} &deg;C</div>
                                            <div class="col-sm-8" *ngIf="mat.isTempSensitive!='Yes'">{{mat.isTempSensitive}}</div> -->
                                            <div class="col-sm-8">
                                                {{mat.isTempSensitive=='Yes' ? ('Min. : ' + mat.minimumTemperature + '&deg;C, Max. : ' + mat.maximumTemperature + '&deg;C') : mat.isTempSensitive}}
                                            </div>
                                            <!-- <div class="col-sm-8">Min.: {{mat.minimumTemperature}} &deg;C Max.: {{mat.maximumTemperature}} &deg;C</div> -->
                                        </div>isTempSensitive
                                    </div>
                                </div>
                                <div class="card p-0 mb-3">
                                    <div class="card-header shadow-sm p-3 pb-1 pt-2">
                                        <h6>Handling unit</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                            <div class="col-sm-4"><strong>{{mat.productName}}</strong>: {{mat.handlingUnit}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-xl-12 rt-2">
            <div class="float-xl-end">
                <button class="btn btn-sm btn-light mb-2" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="card p-0 border-0 my-3">
    <div class="card-body p-2">
        <h6>Map Stores to Material</h6>
        <div>
            <!-- <ng-template #strNme let-model="item">
                <span class="font-weight-bold">{{model.name}}</span> <br />
            </ng-template>
            <input name="strName" type="text" [typeahead]="StrLst" id="strName" [typeaheadScrollable]="true"
                [typeaheadOptionsLimit]="500" [typeaheadItemTemplate]="strNme" typeaheadOptionField="name"
                [adaptivePosition]="true" (typeaheadOnSelect)="onSlct($event)" class="form-control"
                [typeaheadScrollable]="true" [(ngModel)]="strName"> -->
            <div class="row">
                <div class="col-xl-4">
                    <div class="input-group">
                        <input type="text" placeholder="Search store name" class="form-control" [(ngModel)]="slctStrName">
                        <div class="input-group-append">
                            <button type="button" title="" (click)="getUnMappedStores()" class="btn btn-outline-bsdp p-2">
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="row ps-0 pe-0 pt-3" *ngIf="lstGrid?.length!=0">
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid?.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                                </pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="table_spacing table-responsive mt-3">
                        <table class="table table-striped table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th *ngIf="lstGrid?.length!=0" class="text-center"> <input class="form-check-input" type="checkbox" id="flexCheckDefault11"
                                        (click)="onSlctAll($event)"></th>
                                    <th>Item No.</th>
                                    <th>Store Details</th>
                                    <!-- <th>Min</th>
                                    <th>Max</th> -->
                                    <!-- <th>&nbsp; </th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="lstGrid.length != 0">
                                <tr
                                    *ngFor="let str of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems };let i=index">
                                    <td class="text-center" scope="row"><input class="form-check-input" type="checkbox" id="flexCheckDefault12"
                                        value="" aria-label="..." (change)= "isSelected($event, str)" [checked]="str.flag"></td>
                                    <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                    <td>{{str.name}} <br> {{str.location}}</td>
                                    <!-- <td><input type="text" class="form-control form-control-sm" placeholder="Min"></td>
                                    <td><input type="text" class="form-control form-control-sm" placeholder="Max"></td> -->
                                    <!-- <td><i class="fa fa-trash" (click)="onRmveStr(str)"></i> </td> -->
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstGrid.length == 0">
                                <td colspan="3">No stores are selected</td>
                            </tbody>
                        </table>
                    </div>
                    <div class="pb-3 pt-3">
                        <div class="row" *ngIf="lstGrid?.length!=0">
                            <div class="col-xl-6 text-start">
                                {{pageEntrs}}
                            </div>
                            <div class="col-xl-6">
                                <div class="float-end">
                                    <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-xl-12 rt-2">
                <div class="float-xl-end">
                    <button class="btn btn-sm btn-light mb-2" (click)="crntPage=1;onCancel()">Cancel</button> &nbsp;
                    <button class="btn btn-sm btn-primary mb-2" (click)="crntPage=1;onSubmit()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</div>