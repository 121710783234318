<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="btn btn-light btn-sm close" aria-label="Close" (click)="actMdl.close('done')">
        <span class="f-16" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <tabset #stkRprtTbs [justified]="true">
        <tab [heading]="isShowAll?'Batch':''" [active]="tab=='b'">
            <div class="mt-2">
                <div class="card-body p-2">
                    <div class="table_spacing table-responsive tblRspnOF">
                        <table class="table table-striped table-sm table-bordered table1 tblCntr">
                            <tbody>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Batch ID</th>
                                    <th>Manufactured date (dd/mm/yyyy)</th>
                                    <th>Manufacturer</th>
                                    <th>Expiry date (dd/mm/yyyy)</th>
                                    <th>Total stock</th>
                                    <th>Allocated</th>
                                    <th>Available</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstB1.length!=0">
                                <tr *ngFor="let bat of lstB1; let i=index">
                                    <td class="">{{i+1}}</td>
                                    <td class="">{{bat.batch_no}}</td>
                                    <td class="">{{bat.manufactured_date | date: "dd/MM/yyyy"}}</td>
                                    <td class="">{{bat.batch_producer_name}}</td>
                                    <td class="">{{bat.expiry_date | date: "dd/MM/yyyy"}}</td>
                                    <td>{{bat.total_stock}}</td>
                                    <td>{{bat.allocated_stock}}</td>
                                    <td>{{bat.available_stock == null ? bat.total_stock : bat.available_stock}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstB1.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h4 class="mt-2">Expired Batches</h4>
                        <table class="table table-striped table-sm table-bordered table1 tblCntr">
                            <tbody>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Batch ID</th>
                                    <th>Manufactured date (dd/mm/yyyy)</th>
                                    <th>Manufacturer</th>
                                    <th>Expiry date (dd/mm/yyyy)</th>
                                    <th>Total stock</th>
                                    <th>Allocated</th>
                                    <th>Available</th>
                                    <th>Actions</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstB2.length!=0">
                                <tr *ngFor="let bat of lstB2; let i=index">
                                    <td class="">{{i+1}}</td>
                                    <td class="">{{bat.batch_no}}</td>
                                    <td class="">{{bat.manufactured_date | date: "dd/MM/yyyy"}}</td>
                                    <td class="">{{bat.batch_producer_name}}</td>
                                    <td class="">{{bat.expiry_date | date: "dd/MM/yyyy"}}</td>
                                    <td>{{bat.total_stock}}</td>
                                    <td>0</td>
                                    <td>{{bat.available_stock == null ? bat.total_stock : bat.available_stock}}</td>
                                    <td class="text-center">
                                        <div *ngIf="!bat.isClkDBR">
                                            <a (click)="bat.isClkDBR=true;bat.actnType='d';makeFldsMand(i, 'd');(i)">
                                                <i class="fa fa-window-close-o highTemp" aria-hidden="true" title="Discard"></i>
                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a (click)="bat.isClkDBR=true;bat.actnType='s';makeFldsMand(i, 'b');(i)">
                                                <i class="fa fa-minus-circle highTemp" aria-hidden="true" title="Batch Reset"></i>
                                            </a>
                                        </div>
                                        <div *ngIf="bat.isClkDBR">
                                            <div class="row p-3" [ngClass]="bat.isStsMand ? 'mb-10' : ''">
                                                <label class="font-wt">{{bat.actnType=='d'?'Discard':'Batch Reset'}}</label>
                                                <select class="form-select form-select-sm" (change)="onChngDD(bat, 'r', $event)"
                                                    [ngClass]="{ 'is-invalid' : bat.isRsnMand && (bat.rsnId == '' || bat.rsnId == null) }">
                                                    <option value="">-Select Reason-</option>
                                                    <option *ngFor="let rsn of (bat.actnType=='d'?lstDR:lstBR)" value="{{rsn.reasonId}}">{{rsn.reasonName}}</option>
                                                </select>
                                            </div>
                                            <div class="row p-3" *ngIf="bat.isStsMand">
                                                <select class="form-select form-select-sm" (change)="onChngDD(bat, 's', $event)"
                                                    [ngClass]="{ 'is-invalid' : bat.isStsMand && (bat.stsId == '' || bat.stsId == null) }">
                                                    <option value="">-Select Status-</option>
                                                    <option *ngFor="let sts of (bat.actnType=='d'?lstDS:lstBS)" value="{{sts.id}}">{{sts.name}}</option>
                                                </select>
                                            </div>
                                            <div class="row mb2">
                                                <div class="float-start">
                                                    <button class="btn btn-sm btn-primary" (click)="onActn(i);">Submit</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <button class="btn btn-sm btn-reset" (click)="bat.isClkDBR=false;bat.actnType='';">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstB2.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="Allocated" [active]="tab=='a'" *ngIf="isShowAll">
            <div class="mt-2">
                <div class="card-body p-2">
                    <div class="table_spacing table-responsive">
                        <table class="table table-striped table-sm table-bordered table1 tblCntr w-96">
                            <tbody>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Receiving Facility</th>
                                    <th>Indent</th>
                                    <th>Ordered</th>
                                    <th>Allocated</th>
                                    <th>Shipped</th>
                                    <th>Yet to ship</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstA.length!=0">
                                <tr *ngFor="let item of lstA; let i = index">
                                    <td>{{i+1}}</td>
                                    <td class="">{{item.receivingStore}}</td>
                                    <td class="">{{item.bookingId}}</td>
                                    <td class="">{{item.orderedStock}}</td>
                                    <td class="">{{item.allocatedStock}}</td>                                    
                                    <td class="">{{item.shippedStock}}</td>
                                    <td class="">{{item.yetToShip}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstA.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="In transit" [active]="tab=='i'" *ngIf="isShowAll">
            <div class="mt-2">
                <div class="card-body p-2">
                    <div class="table_spacing table-responsive">
                        <table class="table table-striped table-sm table-bordered table1 tblCntr w-96">
                            <tbody>
                                <tr>
                                    <th>Item No.</th>
                                    <th>Issuing Facility.</th>
                                    <th>Indent</th>
                                    <th>Ordered</th>
                                    <th>Shipped</th>                                    
                                    <th>Yet to ship</th>
                                    <th>Fulfilled</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstT.length!=0">
                                <tr *ngFor="let iTd of lstT; let index = index">
                                    <td>{{index+1}}</td>
                                    <td class="">{{iTd.issuingstore}}</td>
                                    <td class="">{{iTd.bookingId}}</td>
                                    <td class="">{{iTd.orderedStock}}</td>
                                    <td class="">{{iTd.shippedStock}}</td>
                                    <td class="">{{iTd.yetToShip}}</td>
                                    <td class="">{{iTd.fulfilledStock}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstT.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="Min./Max." [active]="tab=='m'" *ngIf="isShowAll">
            <div class="mt-2">
                <div class="card-body p-2">
                    <div class="table_spacing table-responsive">
                        <table class="table table-striped table-sm table-bordered table1 tblCntr w-96">
                            <tbody>
                                <tr>
                                    <th>Min.</th>
                                    <th>Max.</th>
                                    <th>Consumption rate</th>
                                    <th>Updated on</th>
                                    <th>Updated by</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstM.length!=0">
                                <tr *ngFor="let mm of lstM">
                                    <td class="">{{mm.min}}</td>
                                    <td class="">{{mm.max}}</td>
                                    <td class=""></td>
                                    <td class="">{{mm.updated_on | date: "dd/MM/yyyy hh:mm aa"}}</td>
                                    <td class=""><a [routerLink]="">{{mm.updated_by}}</a></td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstM.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
        <tab heading="History" [active]="tab=='h'" *ngIf="isShowAll">
            <div class="mt-2">
                <div class="card-body p-2">
                    <div class="table_spacing table-responsive">
                        <table class="table table-striped table-sm table-bordered table1 tblCntr w-96">
                            <tbody>
                                <tr>
                                    <th>Material </th>
                                    <th>Facility </th>
                                    <th>From</th>
                                    <th>Until</th>
                                    <th>Duration</th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstH.length!=0">
                                <tr *ngFor="let hst of lstH">
                                    <td class="">{{hst.product_name}}</td>
                                    <td class="">{{hst.store_name}}<br /><span>{{hst.location}}</span></td>
                                    <td class="">{{hst.date_start | date: "dd/MM/yyyy hh:mm aa"}}</td>
                                    <td class="">{{hst.date_end | date: "dd/MM/yyyy hh:mm aa"}}</td>
                                    <td class="">{{hst.days}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="lstH.length==0">
                                <tr>
                                    <td colspan="8" class="text-center">
                                        {{noRcrdsTxt}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </tab>
    </tabset>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-light" (click)="actMdl.close('done')">Close</button>
</div>