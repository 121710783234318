import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-setup-assets',
  templateUrl: './setup-assets.component.html',
  styleUrls: ['./setup-assets.component.css']
})
export class SetupAssetsComponent extends BaseComponent implements OnInit {

  paramTab: any; usrPrmsn: any = null; usrprmsnCreate: boolean = true; prmsns: any = null; cratBulkUpld: boolean = true;
  cceList: boolean = true;

  assetHead = "Create Asset";

  constructor(private route: ActivatedRoute, private router: Router, 
    public glblSvc: GlobalService) {
      super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
    this.glblSvc.editAssetInfo.subscribe(s => { 
      if (Object.keys(s).length != 0) {
        this.assetHead = "Edit Asset";
      } else{
        this.assetHead = "Create Asset";
      }
    })
  }

  ngOnInit(): void {
    //this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'userPermissions').length > 0 ? this.usrLgnDtls.userPermissions : null;
    // if (this.usrPrmsn == 'asset view' || this.usrPrmsn == 'view')
    //   this.usrprmsnCreate = false;
    // else 
    //   this.usrprmsnCreate = true;
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_cm_cm-bu')
     this.cratBulkUpld = e?.action;
     if(e?.menuCode =='mm_st_cm_li')
     this.cceList = e?.action;
  });
  if(this.cceList)
  this.onTabSelection('list');
  else if(this.cratBulkUpld)
  this.onTabSelection('new');
  }

  onTabSelection(tabName: any) {
    if (tabName != 'new'){
      this.glblSvc.editAssetInfo.next('');    
    }      
    this.glblSvc.editAssetInfo.subscribe(s => { 
      if (Object.keys(s).length != 0) {
        this.assetHead = "Edit Asset";
      } else{
        this.assetHead = "Create Asset";
      }
    })
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }
}
