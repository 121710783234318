<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="row ">
            <div class="col=xl-12 ">
                <div class="float-xl-end">
                    <small>As of 19/9/20 12:00 PM</small>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-xl-12 text-end">
                <div class="float-xl-end">
                    <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                        <li class="nav-item"><a class="nav-link btn btn-sm border active" id="Map-tab"
                                data-bs-toggle="tab" href="#Map" role="tab" aria-controls="Map" aria-selected="true"><i
                                    class="fa fa-bar-chart me-0" aria-hidden="true"></i></a></li>
                        <li class="nav-item"><a class="nav-link btn btn-sm border" id="Map-1-tab" data-bs-toggle="tab"
                                href="#Map-1" role="tab" aria-controls="Map-1" aria-selected="false"><i
                                    class="fa fa-table me-0" aria-hidden="true"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active pb-3" id="Map" role="tabpanel" aria-labelledby="Map-tab">
                <div class="row mt-3">
                    <div class="col-xl-12">
                        <div class="card p-0 mb-0">
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="float-xl-end">
                                            <div class="dropdown-basic p-0 me-1">
                                                <div class="dropdown p-0">
                                                    <a class="dropbtn pl-0 pr-0 text-secondary"
                                                        style="padding:5px !important;">
                                                        <span class="f-20"><i class="fa fa-bars"
                                                                aria-hidden="true"></i></span></a>
                                                    <div class="dropdown-content card border-0"
                                                        style="top:22px !important; right: 0; left: unset;">
                                                        <a class="small pt-1 pb-1" type="button">Export
                                                            as PNG</a>
                                                        <a class="small pt-1 pb-1" type="button">Export
                                                            as JPG</a>
                                                        <a class="small pt-1 pb-1" type="button">Export
                                                            as PDF</a>
                                                        <a class="small pt-1 pb-1" type="button">Export
                                                            as SVG</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-8 offset-xl-2 centered-div">
                                        <div class="text-center">
                                            <!-- <img class="text-center img-fluid" src="./assets/img/Screenshot 2021-04-20 115003.jpg" alt="Img"> -->
                                        </div>
                                    </div>
                                    <div class="centered-div mt-3"><a [routerLink]="">Download as CSV</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade pb-3" id="Map-1" role="tabpanel" aria-labelledby="Map-1-tab">
                <div class="row mt-3">
                    <div class="col-xl-6 offset-xl-3 text-center">
                        <p>From: May 2020 To: Apr 2021 </p>
                    </div>
                </div>
                <div class="col-xl-6 col-sm-12 offset-xl-3 mt-3 table-responsive table_spacing">
                    <table class="table table-striped table-sm table-bordered border-primary f-14"
                        style="min-width: 300;">
                        <thead>
                            <tr>
                                <th class="col-auto">Date</th>
                                <th class="col-auto text-center">% assets under repair</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Apr 2021</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Mar 2021</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Feb 2021</td>
                                <td class="text-center">0</td>
                            </tr>
                            <tr>
                                <td>Jan 2021</td>
                                <td class="text-center">0</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="centered-div mt-3"><a [routerLink]="">Download as CSV</a></div>
                </div>
            </div>
        </div>
    </div>
</div>