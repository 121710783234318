import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { ShipCreateMdlComponent } from 'src/app/Components/evin/Pop-ups/ship-create-mdl/ship-create-mdl.component';
import { CnclOrderComponent } from 'src/app/Components/evin/Pop-ups/cncl-order/cncl-order.component';
import { PrchsIndntFulfillMdlComponent } from 'src/app/Components/evin/Orders/prchs-indent/prchs-indnt-fulfill-mdl/prchs-indnt-fulfill-mdl.component';
import { ParMdlComponent } from 'src/app/Components/evin/Orders/prchs-indent/par-mdl/par-mdl.component';

declare let $: any;

@Component({
  selector: 'evin-prchs-indnt-dtls',
  templateUrl: './prchs-indnt-dtls.component.html',
  styleUrls: ['./prchs-indnt-dtls.component.css']
})
export class PrchsIndntDtlsComponent extends BaseComponent implements OnInit {

  OrderHistory: any; noRcrdsTxt = environment.TblNoRcrds; tt = 'e-Demand low'; progressdata: any[] = []; allocateS = false;
  orderId: any; orderStatus: any; prgrsStatus: any[] = []; lstMats: any[] = []; lstShipments: any[] = []; isSb = false; defWdthVal = 25;
  midWdthVal = 12.5; dispatchForm = this.formBuilder.group({ DispatchComment: [] }); shwAllTab = false; userlgndtls: any = null;
  editFlag = false; ordrStatForm: FormGroup = new FormGroup({}); rsnsLst: any[] = []; isSbmtd = false; lstAllMats: any[] = [];
  MatsLst: any[] = []; edflg = true; rdflg = true; bbflg = true; irflg = true; lstTags: any[] = []; oldrsnsLst: any[] = []; paramTabName: any;
  lstAllComments: any[] = []; cmntStat = false; dtToDay = new Date(); isRsnMndtry = false; isoldRsnMndtry = false; isAlctSave = false;
  mmIdDtlsSI: boolean = true; mmIdDtlsEd: boolean = true; mmIdDtlsIh: boolean = true; mmIdDtlsAd: boolean = true; mmIdDtlsRi: boolean = true;
  mmIdDtlsDi: boolean = true; mmIdDtlsFi: boolean = true; mmIdDtlsCi: boolean = true; mmIdDtlsEe: boolean = true; mmIdDtlsAi: boolean = true;
  mmIdDtlsEc: boolean = true; mmIdDtlsEr: boolean = true; mmIdDtlsEi: boolean = true; isMinmax: boolean = false; isAllcteByBatch: boolean = false;
  dt2day = new Date(); frmDate = new Date(); toDateF = ''; ctDte: any = new Date(); isBtchSubmit = false; ttlQntity: any = ''; btchLst: any[]=[];
  isRsnAlw = false; cnfgData: any = JSON.parse(<string>localStorage.getItem('lgnCnfgData')); isCnsgnStr = this.usrLgnDtls?.producerId;
  isFileUpld: boolean = false;

  readyfrDspcthFrm: FormGroup;

  constructor(public glblSvc: GlobalService,
    private ngbMdl: NgbModal,
    public dtPipe: DatePipe,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => {
      this.rsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.new_indent),
      this.oldrsnsLst = s.rsnsLst?.filter((f: any) => f.reasonType == environment.cnfgTrnsRsnType.update_indent)
    }),
      this.ordrStatForm = this.formBuilder.group({ comment: [''] }); this.readyfrDspcthFrm = this.formBuilder.group({ comments: [''] })
    // this.isRsnMndtry = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_overriding_order_recommendation?.mandatory ?? false,
    //   this.isoldRsnMndtry = this.cnfgData?.cnfgOdrData?.order_reasons?.reasons_for_overriding_order_recommendation?.mandatory ?? false;
    if (this.rsnsLst?.filter(a => a.isReasonMandatory == true).length > 0)
      this.isRsnMndtry = true;
    else
      this.isRsnMndtry = false;
    if (this.oldrsnsLst?.filter(a => a.isReasonMandatory == true).length > 0)
      this.isoldRsnMndtry = true;
    else
      this.isoldRsnMndtry = false;
  }

  ngOnInit(): void {
    this.isCnsgnStr = this.isCnsgnStr == null ? '0': this.isCnsgnStr;
    this.route.queryParams.subscribe(params => { this.paramTabName = params.type; }), this.glblSvc.sbjctOrdDtls.subscribe((s: any) => {
      this.orderId = s?.indentid;
      if (Object.keys(s).length > 0)
        this.GetBadges(), this.LoadOrderStatus(this.orderId), this.LoadShipments(this.orderId),
          this.LoadProgressBar(this.orderId), this.loadComments(this.orderId),
          this.ordrStatForm = this.formBuilder.group({
            comment: [''], batchNo: ['', Validators.required], quantity: ['', Validators.required],
            mfgDate: ['', Validators.required], expDate: ['', Validators.required], quantitySM: [''], fileName: ['', Validators.required],
            fileFormat: [''], fileNameVAR: [''], fileFormatVAR: [''] }), this.editFlag = false, this.allocateS = false;
    });
  }

  openModalVAR(c: string) {
    if (c == 'v')
      this.ngbMdl.open(PrchsIndntFulfillMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    else
    this.ngbMdl.open(ParMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
  }

  routingToLocation() {
    this.router.navigate(['home/prchs-ord-sts']);
  }

  onFileSelect(event: any, ctrl: string) {
    if (event.target.files.length > 0 && ctrl == 'wB') {
      const file = event.target.files[0];      
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        this.ordrStatForm.patchValue({ fileName: '' })
      }
      else {
        this.ordrStatForm.patchValue({ fileFormat: file });
      }
    }
    if (event.target.files.length > 0 && ctrl == 'woB') {
      const file = event.target.files[0];      
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        this.ordrStatForm.patchValue({ fileName: '' })
        // this.fleNmeWithOutBtch = ''
      }
      else {
        this.ordrStatForm.patchValue({ fileFormat: file });
        // this.fleNmeWithOutBtchFormt = file;
      }
    }
    
  }

  onFileSelectVar(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];      
      if (file.size > 5242880) {
        alert("File size must be less than 5MB");
        this.ordrStatForm.patchValue({ fileNameVAR: '' })
      }
      else {
        this.ordrStatForm.patchValue({ fileFormatVAR: file });
      }
    }
  }

  hasExtensionVAR(exts: any) {
    var fileFormat = this.ordrStatForm.value.fileNameVAR;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileFormat);
  }

  uploadFile() {
    if (this.ordrStatForm.get('fileNameVAR')?.value == '')
      this.glblSvc.onTstr('w', 'Kindly select a file to upload');
    else if(this.hasExtensionVAR(['.pdf'])) {
      // this.uploadStatusResult = true,  this.uploadStatus = 'In Progress';
      const formData = new FormData();
      formData.append('file', this.ordrStatForm.get('fileFormatVAR')?.value);     
      this.onFormData(environment.apiPaths.prchsShip, environment.apiMthds.fulFillUpld.replace('iii', this.orderId ?? null).
        replace('fff', this.orderStatus?.batchEnabled ? '64' : '80'),//this.orderStatus?.consigneeBookingId
      formData, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1) {
            this.glblSvc.onTstr('s', this.result.message), this.routingToLocation();
          }
          else
            this.glblSvc.onTstr('w', this.result.message);
        }
      });
    } else {
      this.glblSvc.onTstr('w', 'Upload only PDF files'), this.ordrStatForm.reset();
    }   
  }

  onConfrim(orderID: any) {
    let payLoad = { bookingId: orderID, userId: this.usrLgnDtls.userId, comments: null };
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.cnfrmOrder, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.onTstr('s', this.result.message), this.ngOnInit();
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  get fc() {
    return this.ordrStatForm.controls;
  }

  onReopen(orderID: any) {
    if (confirm("Are you sure you want to reopen the indent ?")) {
      let payLoad = { bookingId: orderID, userId: this.usrLgnDtls.userId, comments: null };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.reopenOrder, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.message == 'Please check the Order Status..')
            this.glblSvc.onTstr('w', this.result.message);
          else if (this.result.status == 'OK')
            this.glblSvc.onTstr('s', this.result.message), this.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  GetBadges() {
    this.lstTags = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.prchsAllcte, environment.apiMthds.prchsAlctdBtchsLst + this.orderId,
      null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.lstTags = this.result.data;
        }
      });
  }

  onBtchSave() {
    if (this.fc.quantity?.value ==0 || this.fc.quantity?.value == null || this.fc.quantity?.value == '') {
      this.glblSvc.onTstr('w', 'Quantity must be greater than Zero')
    } else {
      if (this.ordrStatForm.invalid)
        this.glblSvc.onTstr('w', 'KIndly select all the mandatory fields');
      else {
        let a = this.ordrStatForm.getRawValue();
        this.ttlQntity = this.lstTags.length == 0 ? a.quantity : '';
        this.lstTags.filter(b => { this.ttlQntity = +this.ttlQntity + (+b.batchQty) });
        if (+this.ttlQntity > +this.orderStatus?.orderedQuantity)
          this.glblSvc.onTstr('w', 'Choose quantity less than allocated quantity')
        else {
          // this.lstTags.push({ 'id': a.batchNo+'_'+a.quantity , 'batchNo': a.batchNo, 'batchQty': a.quantity, 'expDate': this.dateConverterTo(a.expDate), 'mfgDate': this.dateConverterTo(a.mfgDate) });
          this.btchLst.push({ 'id': a.batchNo+'_'+a.quantity , 'batchNo': a.batchNo, 'quantity': a.quantity, 'expDate': 
          this.dateConverterTo(a.expDate), 'mfgDate': this.dateConverterTo(a.mfgDate) });
          this.allocateOrder(), this.isSbmtd = false, this.ordrStatForm.reset();
        }
      }
    }
  }

  onRmve(item: any) {
    if (confirm('Are you sure want to delete the allocate ?')) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.prchsAllcte, environment.apiMthds.delQntityInd + item.id, null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK' && this.result.returnCode == 1)
            this.glblSvc.onTstr('s', this.result.message), this.GetBadges();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      })
    }
  }

  onChngDtT(dtVal2: any) {
    this.cstmDt2 = dtVal2 ? [] : this.cstmDtDef2;
  }

  orderS() {
    /* // this.progressdata.filter(f => {
    //   if (f.active == true)
    //     $("#progress-bar-container li.active::before").css("background", '#28a745')
    // });
    // for (let i = 0; i < this.progressdata.length; i++) {
    //   if (name == this.progressdata[i].label) {
    let val = ((this.progressdata.filter(f => f.active == true).length - 1) * this.defWdthVal)  //+ this.midWdthVal;
    // $(this).addClass("active").prevAll().addClass("active");
    $(this).nextAll().removeClass("active");
    // $("#line-progress").css("width", "this.orderStatus[i].progress");
    $("#line-progress").css("width", `${val}%`);
    return;
    //   }
    // } */
    if (this.orderStatus?.statusName != 'Cancelled') {
      let val = ((this.progressdata?.filter(f => f.active == true).length - 1) * this.defWdthVal)  //+ this.midWdthVal;
      $(this).nextAll().removeClass("active"), $("#line").css("width", `80%`), $("#line").css("background", `#fff`),
        $("#line-progress").css("width", `${val}%`);
      return;
    }
    else {
      let defWV = (this.progressdata.length == 2 ? 20 : (this.progressdata.length == 3 ? 40 : (this.progressdata.length == 4 ? 60 : 80))),
        val = (this.progressdata.length == 2 ? 40 : (this.progressdata.length == 3 ? 65 : (this.progressdata.length == 4 ? 80 : 90)));
      $(this).nextAll().removeClass("active"), $("#line").css("width", `${defWV}%`), $("#line").css("background", `#ff0000`),
        $("#line-progress").css("width", `${val}%`);
      return;
    }
  }

  updateOrderDetails() {
    let payLoad = this.ordrStatForm.getRawValue();
    payLoad.userId = this.usrLgnDtls.userId, payLoad.bookingId = this.orderId,
      delete payLoad.bookingItems, delete payLoad.srchMtrl,
      payLoad.estimatedDateOfArvl = (payLoad.estimatedDateOfArvl != null && payLoad.estimatedDateOfArvl != '') ? this.glblSvc.setDtFrmt(payLoad.estimatedDateOfArvl, this.dtF.ymd) + this.tmF.zeroTM : '',
      payLoad.requiredByDate = (payLoad.requiredByDate != null && payLoad.requiredByDate != '') ? this.glblSvc.setDtFrmt(payLoad.requiredByDate, this.dtF.ymd) + this.tmF.zeroTM : '',
      this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.updateBookingDetails, payLoad).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.glblSvc.onTstr('s', this.result.message)
            if (this.result.message === 'Order record updated successfully')
              this.LoadOrderStatus(this.orderId),
                this.LoadShipments(this.orderId), this.LoadProgressBar(this.orderId), this.loadComments(this.orderId),
                setTimeout(() => { this.orderS(); }, 1000), this.ordrStatForm.get('estimatedDateOfArvl')?.setValue(''),
                this.ordrStatForm.get('bookingBadge')?.setValue(''), this.ordrStatForm.get('issuseRefNo')?.setValue(''),
                this.ordrStatForm.get('requiredByDate')?.setValue(''), this.edflg = true; this.rdflg = true; this.bbflg = true; this.irflg = true;
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
  }

  clearBSDate(ctrl: string) {
    this.ordrStatForm.get(ctrl)?.setValue('');
  }

  LoadOrderStatus(orderId: any) {
    if (orderId) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.cnsnBkng, environment.apiMthds.prchsOrdDtls + orderId, null, 12).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            this.orderStatus = this.result.data != null ? this.result.data : [], this.prgrsStatus = this.orderStatus?.statusesForBar,
              localStorage.setItem('estimateArvl', this.orderStatus?.estimatedDateOfArrival);
            //this.LoadMatsbyStoreId();
            if (Object.keys(this.orderStatus).length != 0)
              this.updateForm(this.orderStatus)
          }
        }
      });
    }
  }

  updateForm(d: any) {
    let bkngBadg = d?.bookingBadge ? this.lstTags?.filter(a => a.name == d?.bookingBadge)[0]?.id : '';
    this.ordrStatForm.patchValue({
      bookingBadge: bkngBadg, estimatedDateOfArvl: d?.estimatedDateOfArrival ? this.glblSvc.
        setDtFrmt(d?.estimatedDateOfArrival, this.dtF.dmy2) : null, issuseRefNo: d?.reference ? d?.reference : null, requiredByDate:
        d?.requiredByDate ? this.glblSvc.setDtFrmt(d?.requiredByDate, this.dtF.dmy2) : null
    });
  }

  LoadShipments(orderId: any) {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.cargo, environment.apiMthds.getCargobyBookingId.replace('ooo', orderId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.lstShipments = this.result.data != null ? this.result.data?.filter((f: any) => f.cargoId != null) : [];
      }
    });
  }
  LoadProgressBar(orderId: any) {
    this.progressdata = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.bkngTracking, environment.apiMthds.prchsPrgrs + orderId, null, 12).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.progressdata = this.result.data != null ? this.result.data : []
        if (this.progressdata.length != 0)
          setTimeout(() => { this.orderS(); }, 1000);
      }
    });
  }

  openModal() {
    localStorage.setItem('sbjctOrdShipDtls', JSON.stringify(this.orderStatus)), this.glblSvc.sbjctOrdSmry.next(this.orderStatus);
    const modalRef = this.ngbMdl.open(ShipCreateMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.orderStatusData = this.orderStatus;
    modalRef.result.then((result: any) => {
      if (result == 'done')
        this.routingToLocation(); // setTimeout(() => { window.location.reload(); }, 1000);
    }, (r: any) => { console.log(r) });
  }

  LoadHstry() {
    this.FetchData(environment.apiCalls.get, '', environment.apiMthds.getOrderHis.replace('ooo', this.orderId), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.OrderHistory = this.result.data != null ? this.result.data : [];
      }
    });
  }


  allocate() {
    this.allocateS = true;
  }

  allcotaeByBatch() {
    this.isAllcteByBatch = true;
  }

  Oncnclal() {
    this.allocateS = false, this.isAllcteByBatch = false;
  }

  onReadyForDispatch() {
    let payLoad: any = this.readyfrDspcthFrm.getRawValue(); payLoad.consigneeBookingId = this.orderId, payLoad.status = 4;

    this.FetchData(environment.apiCalls.post, environment.apiPaths.prchsAllcte, environment.apiMthds.prchsRdyfrDsptch, payLoad, 12).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.returnCode == 1)
          this.glblSvc.onTstr('s', this.result.message),  window.location.reload();
        else
          this.glblSvc.onTstr('w', this.result.message);
      }
    });
  }

  allocateOrder() {
    let payLoad: any = {}; this.isBtchSubmit = false;
    payLoad.id = 0, payLoad.consigneeBookingId = this.orderId, payLoad.isDeleted = false, payLoad.createdBy = this.usrLgnDtls?.userId;
    const formData = new FormData();
    if (this.orderStatus?.batchEnabled == false) {
      if (this.ordrStatForm.value.quantitySM == '')
        this.glblSvc.onTstr('w', 'Kindly enter the quantity'), this.isBtchSubmit = false, this.ttlQntity = 0;
      else if ( this.hasExtension(['.pdf'])) {
        payLoad.quantity = this.ordrStatForm.value.quantitySM, payLoad.batchProducts = null, this.isBtchSubmit = true, this.ttlQntity = +payLoad.quantity;
          formData.append('file', this.ordrStatForm.get('fileFormat')?.value);
          formData.append('allocateRequest', JSON.stringify(payLoad));
      } else {
        this.glblSvc.onTstr('w', 'Kindly upload pdf formats only');
      }
    }
    else {
      if (this.btchLst.length == 0)
        this.glblSvc.onTstr('w', 'Kindly add the batches'), this.isBtchSubmit = false, this.ttlQntity = '';
      else if ( this.hasExtension(['.pdf'])) {
          let batches: any[] = []; this.ttlQntity = +this.ordrStatForm.value.quantity;
          this.lstTags.filter(a => { this.ttlQntity = +this.ttlQntity + (+a.batchQty)
          }); 
          this.btchLst.filter(a => {batches.push({'batchNo': a.batchNo, 'quantity': a.quantity, 'expDate': a.expDate, 'mfgDate': a.mfgDate})})
           payLoad.quantity = this.ttlQntity, payLoad.batchProducts = batches, this.isBtchSubmit = true;

           formData.append('file', this.ordrStatForm.get('fileFormat')?.value);
           formData.append('allocateRequest', JSON.stringify(payLoad));
         }
      else {
        this.glblSvc.onTstr('w', 'Kindly upload pdf formats only'), this.isBtchSubmit = false, this.ttlQntity = '', this.btchLst = [];
      }
    }

    if (this.isBtchSubmit) {
      if (this.ttlQntity <= this.orderStatus?.orderedQuantity) {
        this.onFormData(environment.apiPaths.prchsAllcte, environment.apiMthds.prchsAllctBking, formData, 12).then(() => {
          if (this.result) {
            if (this.result.status == 'OK' && this.result.returnCode == 1)
              this.glblSvc.onTstr('s', this.result.message), this.isSbmtd = false, this.ordrStatForm.reset(),
              this.btchLst = [], this.LoadOrderStatus(this.orderId), this.LoadProgressBar(this.orderId), this.GetBadges(), this.ngOnInit();
            else
              this.glblSvc.onTstr('w', this.result.message), this.btchLst = [];
          }
        });
      }
      else
        this.glblSvc.onTstr('w', 'Choose quntity should less than allocated quantity'), this.btchLst = [];
    }
    else
      this.btchLst = []; //this.glblSvc.onTstr('w', 'Something went wrong'), 


  }

  OnCnclBtch() {
    this.isAllcteByBatch = false;
  }

  hasExtension(exts: any) {
    var fileFormat = this.ordrStatForm.value.fileName;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileFormat);
  }

  dispatchBooking() {

  }

  onClkFulfill() {    
    let array = [this.lstTags, this.orderStatus]
    this.glblSvc.sbjctodrStst.next(array),
    this.router.navigate(['home/prchs-ord-fulfill']);
  }

  loadComments(orderId: any) {

  }

  saveComments() {
    let payLoad = {
      bookingId: this.orderId, cargoId: null, comments: this.ordrStatForm.get('comment')?.value,
      createdBy: this.usrLgnDtls.userId,
      createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, entryFrom: "Booking"
    }
    this.FetchData(environment.apiCalls.post, environment.apiPaths.booking, environment.apiMthds.saveComments, payLoad).then(() => {
      if (this.result) {
        if (this.result.status == 'OK' && this.result.message == 'message added successfully') {
          this.glblSvc.onTstr('s', this.result.message), this.LoadOrderStatus(this.orderId), this.loadComments(this.orderId),
            this.LoadShipments(this.orderId),
            this.LoadProgressBar(this.orderId), setTimeout(() => { this.orderS(); }, 1000), this.editFlag = false,
            this.allocateS = false, this.ordrStatForm.get('comment')?.setValue(''), this.cmntStat = false;
        }
        else
          this.glblSvc.onTstr('e', this.result.message);
      }
    });
  }

  cancelComment() {
    this.cmntStat = false, this.ordrStatForm.get('comment')?.setValue('');
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  onInvc() {
    this.FetchData(environment.apiCalls.post, environment.apiPaths.bookings, environment.apiMthds.getInvcOrdr + this.orderId, {}).then(() => {
      if (this.result) {
        if (this.result.status == 'OK')
          this.OpenFileInNewTab(this.result?.data?.downloadurlpath);
      }
    });
  }

  onCncl() {
    if (this.orderId) {
      const modalRef = this.ngbMdl.open(CnclOrderComponent, { scrollable: true, size: 'md', backdrop: 'static', animation: true });
      modalRef.componentInstance.bkngId = this.orderId;
      modalRef.componentInstance.page = 'Booking';
      modalRef.result.then((result: any) => {
        if (result == 'done')
          this.routingToLocation(), this.ngOnInit(), this.glblSvc.sbjctTabsPage.next('indnt-list');
      }, (r: any) => { console.log(r) });
    }
  }

}