import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { stat } from 'fs';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-stk-rprt-mdl',
  templateUrl: './stk-rprt-mdl.component.html',
  styleUrls: ['./stk-rprt-mdl.component.css']
})

export class StkRprtMdlComponent extends BaseComponent implements OnInit {

  @Input() title: any; @Input() kioskId: any; @Input() prdctId: any; @Input() tab: string = 'b'; @Input() isShowAll: boolean = true;
  @ViewChild('stkRprtTbs', { static: false }) stkRprtTbs: TabsetComponent | any; noRcrdsTxt = environment.TblNoRcrds; lstTR: any[] = [];
  lstB1: any[] = []; lstB2: any[] = []; lstM: any[] = []; lstH: any[] = []; lstA: any[] = []; lstT: any[] = []; lstMS: any[] = [];
  cnfg = JSON.parse(<any>localStorage.getItem('lgnCnfgData')); lgnUsrData = JSON.parse(<any>localStorage.getItem('lgnUsrDtls'));
  srbFrm: FormGroup = this.fb.group({ batch_products: [[]], country_id: [+this.slctDmnDetails?.countryId], district_id: [this.slctDmnDetails.
    districtId != null ? +this.slctDmnDetails?.districtId : null], latitude: [null], linked_store_id: [null], linked_store_location: [null],
    linked_store_name: [null], longitude: [null], pranth_id: [this.slctDmnDetails?.pranthId ?? null], pranth_name: [this.slctDmnDetails?.
    pranthName ?? ''], products: [[]], source: [2], state_id: [this.slctDmnDetails.stateId != null ? +this.slctDmnDetails?.stateId : null],
    store_badge_id: [null], store_badge_name: [''], store_id: [null], store_location: [''], store_name: [''], txn_actual_date: [''],
    txn_type_id: [null], txn_type_name: [''], user_id: [this.lgnUsrData?.userId], user_name: [this.lgnUsrData?.userName ?? ''] });
  lstDS: any[] = []; stsDM = this.cnfg?.cnfgInvData?.material_status?.discard?.status?.make_this_field_mandatory ?? false;
  lstBS: any[] = []; stsBM = this.cnfg?.cnfgInvData?.material_status?.stock_count?.status?.make_this_field_mandatory ?? false;
  lstDR: any[] = []; rsnDM = this.cnfg?.cnfgInvData?.transaction_reasons?.discards?.make_this_field_mandatory ?? false;
  lstBR: any[] = []; rsnBM = this.cnfg?.cnfgInvData?.transaction_reasons?.stock_count?.make_this_field_mandatory ?? false;

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder,
    public actMdl: NgbActiveModal) {
    super(glblSvc);
    glblSvc.sbjctMstrData.subscribe(s => { this.lstTR = s?.rsnsLst ?? [], this.lstMS = s?.stsLst ?? []; }), this.lstTR.forEach(e => { Object.
      assign(e, { txt: `${e.reasonName}...${e.reasonId}` }); }), this.lstMS.forEach(e => { Object.assign(e, { txt: `${e.name}...${e.id}` }); });
      this.lstDR = this.lstTR.filter((f: any) => f.reasonType == 'Transaction Discard'), this.lstBR = this.lstTR.filter((f: any) =>
      f.reasonType == 'Transaction Stock Count'), this.lstDS = this.lstMS.filter((f: any) => f.statusType == 'Discard'), this.lstBS =
      this.lstMS.filter((f: any) => f.statusType == 'Stock Count');
  }

  ngOnInit(): void {
    if (this.kioskId && this.prdctId)
      this.GetData();
  }

  GetData() {
    [this.lstB1, this.lstM, this.lstH] = [[], [], []], this.glblSvc.sbjctSpnr.next(true);
    if (this.isShowAll)
      Promise.all([this.GetNestedData('b'), this.GetNestedData('m'), this.GetNestedData('h'), this.GetNestedData('t'),
        this.GetNestedData('a')]).then(() => { this.glblSvc.sbjctSpnr.next(false); })
    else
      Promise.all([this.GetNestedData('b')]).then(() => { this.glblSvc.sbjctSpnr.next(false); })
  }

  GetNestedData(type: string) {
    this.FetchData(environment.apiCalls.get, (type == 'b' ? environment.apiPaths.store :
      (type == 't' || type == 'a' ? environment.apiPaths.icatalogue : environment.apiPaths.product)),
      (type == 'b' ? environment.apiMthds.getBatchbyMatId.replace('mmm', this.prdctId).replace('kkk', `${this.kioskId}`) :
      (type == 'h' ? environment.apiMthds.getMatHstry.replace('kkk', `${this.kioskId}`).replace('mmm', this.prdctId) :
      (type == 'a' ? environment.apiMthds.getAllocated.replace('sss', `${this.kioskId}`).replace('ppp', this.prdctId) :
      (type == 't' ? environment.apiMthds.getIntransit.replace('sss', `${this.kioskId}`).replace('ppp', this.prdctId) :
        environment.apiMthds.getMatsMinMax.replace('kkk', `${this.kioskId}`).replace('mmm', this.prdctId))))), null,
        ((type == 'm' || type == 'h') ? 2 : 0), false, ((type == 'm' || type == 'h') ? 1 : 0)).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            if (type == 'b') {
              let b1Lst = this.result.data.filter((f: any) => f.expired == false), b2Lst = this.result.data.filter((f: any) => f.expired == true);
              b2Lst.forEach((e: any) => { Object.assign(e, { isClkDBR: false, actnType: '', isRsnMand: false, isStsMand: false, rsnId: '',
                rsnNme: '', stsId: '', stsNme: '' }); }), this.lstB1 = b1Lst, this.lstB2 = b2Lst;
            }
            else if (type == 'm')
              this.lstM = this.result.data;
            else if (type == 'h')
              this.lstH = this.result.data;
            else if (type == 'a')
              this.lstA = this.result.data;
            else
              this.lstT = this.result.data;
          }
        }
      }
    });
  }

  makeFldsMand(indx: number, ctrl: string) {
    this.lstB2[indx].isRsnMand = ctrl == 'd' ? this.rsnDM : this.rsnBM, this.lstB2[indx].isStsMand = ctrl == 'd' ? this.stsDM : this.stsBM;
  }

  onChngDD(item: any, type: string, evnt: any) {
    if (evnt?.target?.value != '') {
      if (type == 'r') {
        let lst = item.actnType == 'd' ? this.lstDR : this.lstBR, resn = lst.filter(f => f.reasonId == evnt?.target?.value)[0]?.txt ?? '';
        if (resn != '' && resn.includes('...'))
          item.rsnId = `${resn}`.split('...')[1], item.rsnNme = `${resn}`.split('...')[0];
      }
      else {
        let lst = item.actnType == 'd' ? this.lstDS : this.lstBS, stats = lst.filter(f => f.id == evnt?.target?.value)[0]?.txt ?? '';
        if (stats != '' && stats.includes('...'))
          item.stsId = `${stats}`.split('...')[1], item.stsNme = `${stats}`.split('...')[0];
      }
    }
    else {
      if (type == 'r')
        item.rsnId = null, item.rsnNme = '';
      else
        item.stsId = null, item.stsNme = '';
    }
  }

  onActn(indx: number) {
    let payLoad: any = this.srbFrm.getRawValue(), rowItm = this.lstB2[indx], batDtls: any[] = [];
    if ((rowItm.isRsnMand && (rowItm.rsnId == null || rowItm.rsnId == '')) || (rowItm.isStsMand && (rowItm.stsId == null || rowItm.stsId == ''))) {
      this.glblSvc.onTstr('w', 'Please fill mandatory fields');
      return;
    }
    batDtls.push({ batch_id: rowItm?.batch_no ?? null, expiry_date: rowItm?.expiry_date ?? null, is_batch_enabled: true, manufactured_date:
      rowItm?.manufactured_date ?? null, material_status: rowItm.isStsMand ? rowItm?.stsId : null, material_status_name: rowItm.isStsMand ?
      rowItm.stsNme : '', producer_name: rowItm?.batch_producer_name ?? null, product_badge_id: rowItm?.product_badge_id ?? null,
      product_badge_name: rowItm?.product_badge_name ?? null, product_id: rowItm?.product_id ?? null, product_name: rowItm?.product_name ?? null,
      quantity: rowItm?.available_stock ?? null, reason_id: rowItm.isRsnMand ? (rowItm.rsnId != '' ? +rowItm.rsnId : null) : null,
      reason_name: rowItm.isRsnMand ? rowItm.rsnNme : '', producer_id: rowItm?.producer_id ?? null }),
      payLoad.batch_products = batDtls, payLoad.linked_store_id = null, payLoad.linked_store_location = null, payLoad.linked_store_name = null,
      payLoad.store_badge_id = rowItm?.store_badge_id ?? null, payLoad.store_badge_name = rowItm?.store_badge_name ?? null, payLoad.store_id = 
      rowItm?.store_id ?? null, payLoad.store_location = rowItm?.store_location ?? null, payLoad.store_name = rowItm?.store_name ?? null,
      payLoad.txn_type_id = this.lstB2[indx].actnType == 'd' ? 4 : 3, payLoad.txn_actual_date = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd,
      this.tmF.hmsa), payLoad.txn_type_name = this.lstB2[indx].actnType == 'd' ? 'Stock-Discards' : 'Stock-Edit', this.FetchData(environment.
          apiCalls.post, environment.apiPaths.trans2, environment.apiMthds.saveRupdate, payLoad).then(() => { let res = this.result;
        if (this.result) {
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
          if (this.result.status == 'OK')
            this.GetNestedData('b');
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
  }

}