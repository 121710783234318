import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;

@Component({
  selector: 'evin-cnfg-capabilities',
  templateUrl: './cnfg-capabilities.component.html',
  styleUrls: ['./cnfg-capabilities.component.css']
})

export class CnfgCapabilitiesComponent extends BaseComponent implements OnInit {

  lstRoles: any[] = []; lstPrmsns: any[] = []; lstMatTags: any[] = []; lstSlctd: any[] = []; lstChkItems: any[] = [];
  rbVal = 'Optimistic (Geo-Location Is Acquired Once During Login, And Later On Expiry; Has Better Performance)'; ddVal = 'Default';
  usrRoleId: number = 0; savePld: SaveCapabs = new SaveCapabs(); isPgLd: boolean = false; msVal = []; msdStngs = {}; usrPrmsn: any = null; 
  cpblPrmsn = true;

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags = s.filter(f => f.badgeTypeId == environment.tagTypes.product); }),
      this.select2ShowHide(), this.isPgLd = true, this.savePld.result = [], localStorage.removeItem('lstPrmsnsAPI'),
      this.msdStngs = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
        allowSearchFilter: true, enableCheckAll: false }, this.LoadRoles(); // itemsShowLimit: 3, this.glblSvc.sbjctMstrData.subscribe(s => { this.lstRoles = s.rlsLst });
        this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
        if (this.usrPrmsn != null) {
          let cnfgLst: any[] = this.usrPrmsn?.Configurations;
          cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_gn_ac') ? (this.cpblPrmsn = e.action) : '') });
        }
  }

  LoadRoles() {
    this.lstRoles = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrRole, environment.apiMthds.getAll +
        environment.apiMthds.getbyModName + 'General', null, 2, true, 1).then(() => { let res = this.result;
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstRoles = this.result?.data ? this.result?.data : [];
            if (this.lstRoles.length > 0)
            this.lstRoles.push({ id: 0, name: 'ALL' })
            if (this.lstRoles.length > 0)
              this.usrRoleId = this.lstRoles[0]?.id, this.GetPermissionsbyRoleId(this.lstRoles[0]?.id), this.isPgLd = false;
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  GetPermissionsbyRoleId(role: any, isSaved = false) {
    let roleId = this.isPgLd || isSaved ? role : role?.target?.value;
    this.lstPrmsns = [], this.usrRoleId = roleId, this.FetchData(environment.apiCalls.get, environment.apiPaths.rolePrmsn,
        environment.apiMthds.getPrmsnsbyRole + roleId, null, 2, true, 1).then(() => { let res = this.result;
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.lstPrmsns = this.result?.data != null ? this.result?.data : [], localStorage.setItem('lstPrmsnsAPI',
                JSON.stringify(this.lstPrmsns)), this.lstPrmsns.forEach((p: any) => {
              p.modulePermissionRoles.forEach((m: any) => {
                m.permissionRoles.forEach((s: any) => {
                  if (s.inputType == 'dropdown')
                    this.ddVal = s.configuredValue?.value ?? this.ddVal;
                  if (s.inputType == 'radio')
                    this.rbVal = s.configuredValue?.value ?? this.rbVal;
                  if (s.inputType == 'multiselect') {
                    if (s.configuredValue != null) {
                      let slctd: any[] = [], matTag = [];
                      Object.assign(s, { slctdItems: [] })
                      if (s.configuredValue.value != null) {
                        s.configuredValue.value.forEach((e: any) => {
                          if (this.lstMatTags.filter(f => f.id == e).length > 0)
                            matTag = this.lstMatTags.filter(f => f.id == e)[0], slctd.push(matTag);
                        });
                      }
                      s.slctdItems = [...slctd];
                    }
                    else
                      s.configuredValue = { value: [] };
                  }
                });
              });
            });
            if (isSaved) {
              
              if (this.usrLgnDtls.roleId === +roleId)
                this.usrLgnDtls.permissions = this.lstPrmsns, localStorage.setItem('lgnUsrDtls', JSON.stringify(this.usrLgnDtls)), window.location.reload();
            }
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  onChngMS(evntVal: any, pmId: any, mdId: any, prId: any, type: string) {
    let result: any, apiRspns: any = JSON.parse(<string>localStorage.getItem('lstPrmsnsAPI')), item: any = this.lstPrmsns.filter((pm: any) =>
      pm.parentModuleId === pmId)[0]?.modulePermissionRoles.filter((m: any) => m.moduleId === mdId)[0]?.permissionRoles.
      filter((pr: any) => pr.permissionId === prId)[0], itemAPI: any = apiRspns.filter((pm: any) => pm.parentModuleId === pmId)[0]?.
        modulePermissionRoles.filter((m: any) => m.moduleId === mdId)[0]?.permissionRoles.filter((pr: any) => pr.permissionId === prId)[0];
    if (item.configuredValue != null) {
      if (item.configuredValue.value.filter((f: any) => f === evntVal.id).length === 0)
        item.configuredValue.value.push(evntVal.id)
      else
        item.configuredValue.value = item.configuredValue.value.filter((f: any) => f !== evntVal.id);
    }
    else {
      let slctd: any[] = [];
      slctd.push(evntVal.id)
      item.configuredValue = { value: slctd };
    }
    result = item.configuredValue.value.length > 0 ? item.configuredValue.value : null;
  }

  onChng(evntVal: any, pmId: any, mdId: any, prId: any, type: string) {
    let result: any, apiRspns: any = JSON.parse(<string>localStorage.getItem('lstPrmsnsAPI')), item: any = this.lstPrmsns.filter((pm: any) =>
      pm.parentModuleId === pmId)[0]?.modulePermissionRoles.filter((m: any) => m.moduleId === mdId)[0]?.permissionRoles.
      filter((pr: any) => pr.permissionId === prId)[0], itemAPI: any = apiRspns.filter((pm: any) => pm.parentModuleId === pmId)[0]?.
      modulePermissionRoles.filter((m: any) => m.moduleId === mdId)[0]?.permissionRoles.filter((pr: any) => pr.permissionId === prId)[0],
      rbAltId = type == 'rb' ? (prId == 41 ? 42 : 41) : 0, rbItm = rbAltId > 0 ? this.lstPrmsns.filter((pm: any) => pm.parentModuleId ===
        pmId)[0]?.modulePermissionRoles.filter((m: any) => m.moduleId === mdId)[0]?.permissionRoles.filter((pr: any) => pr.permissionId ===
        rbAltId)[0] : null;
    switch (type) {
      case 'cb':  // for check boxes
        if (item.configuredValue != null)
          item.configuredValue.value = evntVal.target.checked;
        else
          item.configuredValue = { value: evntVal.target.checked };
        result = evntVal.target.checked;
        break;
      case 'dd':  // for drop downs
      case 'nb':  // for numeric text boxes
      case 'rb':  // for radio buttons
        if (item.configuredValue != null)
          item.configuredValue.value = type != 'nb' ? evntVal.target.value : (evntVal.target.value ? +evntVal.target.value : null);
        else
          item.configuredValue = { value: type != 'nb' ? evntVal.target.value : (evntVal.target.value ? +evntVal.target.value : null) };
        result = type != 'nb' ? evntVal.target.value : (evntVal.target.value ? +evntVal.target.value : null);
        break;
      case 'ms':  // for multi select
        if (item.configuredValue != null) {
          if (item.configuredValue.value != null) {
            if (item.configuredValue.value?.filter((f: any) => f === evntVal.id).length === 0)
              item.configuredValue.value.push(evntVal.id)
            else
              item.configuredValue.value = item.configuredValue.value.filter((f: any) => f !== evntVal.id);
          }
          else
            item.configuredValue.value = [], item.configuredValue.value.push(evntVal.id);
        }
        else {
          let slctd: any[] = [];
          slctd.push(evntVal.id)
          item.configuredValue = { value: slctd };
        }
        result = item.configuredValue.value.length > 0 ? item.configuredValue.value : null;
        break;
      default:
        break;
    }
    /* if ((item.configuredValue === null || itemAPI.configuredValue === null) && result != null) {
      if (this.savePld.result.filter((f: any) => f.permissionId === prId).length == 0)
        this.savePld.result.push({ configuredValue: { value: result }, permissionId: prId, roleId: this.usrRoleId,
          updatedBy: this.usrLgnDtls?.userId });
      else
        this.savePld.result.filter((f: any) => f.permissionId === prId)[0].configuredValue.value = result;
    }
    else if (result != null && item.configuredValue.value != itemAPI.configuredValue.value &&
        this.savePld.result.filter(f => f.permissionId == prId).length == 0) {
      if (this.savePld.result.filter((f: any) => f.permissionId === prId).length == 0)
        this.savePld.result.push({ configuredValue: { value: result }, permissionId: prId, roleId: this.usrRoleId,
          updatedBy: this.usrLgnDtls?.userId });
      else
        this.savePld.result.filter((f: any) => f.permissionId === prId)[0].configuredValue.value = result;
    } */
    if (((item.configuredValue === null || itemAPI.configuredValue === null) && result != null) ||
      (result != null && item.configuredValue.value != itemAPI.configuredValue.value &&
        this.savePld.result.filter(f => f.permissionId == prId).length == 0)) {
      if (this.savePld.result.filter((f: any) => f.permissionId === prId).length == 0)
        this.savePld.result.push({
          configuredValue: { value: result }, permissionId: prId, roleId: this.usrRoleId,
          updatedBy: this.usrLgnDtls?.userId
        });
      else
        this.savePld.result.filter((f: any) => f.permissionId === prId)[0].configuredValue.value = result;
    }
    else {
      if (result == null && type == 'ms') {
        if (this.savePld.result.filter((f: any) => f.permissionId === prId).length == 0)
          this.savePld.result.push({ configuredValue: { value: null }, permissionId: prId, roleId: this.usrRoleId,
            updatedBy: this.usrLgnDtls?.userId });
        else
          this.savePld.result.filter((f: any) => f.permissionId === prId)[0].configuredValue.value = null;
      }
      else
        this.savePld.result = this.savePld.result.filter(f => f.permissionId !== prId);
    }
    if (rbItm != null && type == 'rb') {
      if (Object.keys(rbItm).length > 0)
        this.savePld.result.push({ configuredValue: null, permissionId: rbAltId, roleId: this.usrRoleId, updatedBy: this.usrLgnDtls?.userId });
    }
  }

  onSave() {
    if (this.savePld.result.length > 0) {
      this.FetchData(environment.apiCalls.post, environment.apiPaths.rolePrmsn, environment.apiMthds.saveRupdate,
          JSON.stringify(this.savePld.result), 2, true, 1).then(() => { let res = this.result
        if (this.result) {
          if (this.result.status == 'OK')
            localStorage.setItem('cnfgCapbltyPL', JSON.stringify(this.savePld.result)), this.glblSvc.onTstr('s', this.result.message), this.isPgLd = false,
              this.savePld.result = [], localStorage.setItem('capRoleId', `${this.usrRoleId}`), this.GetPermissionsbyRoleId(this.usrRoleId, true);
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

}

export class SaveCapabs {
  result: ISaveCapabs[];
  constructor() {
    this.result = [{
      configuredValue: { value: null },
      permissionId: 0,
      roleId: 0,
      updatedBy: 0,
    }]
  }
}

export interface ISaveCapabs {
  configuredValue: any;
  permissionId: number;
  roleId: number;
  updatedBy: number;
}