<form [formGroup]="manfcturerEditForm" *ngIf="manfcturerEditForm" autocomplete="off">
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Manufacturer information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify the Manufacturer information</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="name">Manufacturer Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Manufacturer Name" class="form-control form-control-sm"
                                id="name" formControlName="name"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.name.errors }">
                            <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                                <div *ngIf="fc.name.errors.required">Manufacturer name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="description">Description</label>
                            <input type="text" placeholder="Description" class="form-control form-control-sm"
                                id="description" formControlName="description">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="countryId">Country <span class="text-danger">*</span></label>
                            <select id="countryId" class="form-select form-select-sm" formControlName="countryId"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.countryId.errors }" [attr.disabled]="true">
                                <option value="">-Select-</option>
                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.countryId.errors" class="invalid-feedback">
                                <div *ngIf="fc.countryId.errors.required">Country is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">State <span class="text-danger">*</span></label>
                            <select formControlName="stateId" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.stateId.errors }">
                                <option value="null">All</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.stateId.errors" class="invalid-feedback">
                                <div *ngIf="fc.stateId.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Contact information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify contact information of the manufacturer</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="contactName">Contact Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Contact Name" class="form-control form-control-sm"
                                id="contactName" formControlName="contactName"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.contactName.errors }">
                            <div *ngIf="isSbmtd && fc.contactName.errors" class="invalid-feedback">
                                <div *ngIf="fc.contactName.errors.required">Contact name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="gender">Gender <span class="text-danger">*</span></label>
                            <select id="gender" class="form-select form-select-sm" [ngClass]="{ 'is-invalid': isSbmtd && fc.gender.errors }"
                                formControlName="gender">
                                <option value="">Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.gender.errors" class="invalid-feedback">                                    
                                <div *ngIf="fc.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Mobile number <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm" id="mobile" formControlName="mobile"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="13" minlength="10"
                                    [ngClass]="{'is-invalid': isSbmtd && fc.mobile.errors}">
                                <div *ngIf="isSbmtd && fc.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="fc.mobile.errors.required">Mobile number is required</div>
                                    <div *ngIf="fc.mobile.errors.minlength || fc.mobile.errors.maxlength">
                                        Mobile number should be min. of 10 and max. of 13 digits only
                                    </div>
                                    <div *ngIf="fc.mobile.errors.pattern">Mobile Number starts with 6, 7, 8 and 9 Only</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="alternateContactNo">Alternate mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Alternate mobile number" class="form-control form-control-sm" id="alternateContactNo" 
                                    formControlName="alternateContactNo" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    maxlength="13" minlength="10" [ngClass]="{'is-invalid': isSbmtd && fc.alternateContactNo.errors}">
                                    <div *ngIf="isSbmtd && fc.alternateContactNo.errors" class="invalid-feedback">                                  
                                        <div *ngIf="fc.alternateContactNo.errors.minlength || fc.alternateContactNo.errors.maxlength">
                                            Alternate mobile number should be min. of 10 and max. of 13 digits only
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="email">Email <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Email" class="form-control form-control-sm" id="email"
                                formControlName="email" [ngClass]="{ 'is-invalid': isSbmtd && fc.email.errors }">
                            <div *ngIf="isSbmtd && fc.email.errors" class="invalid-feedback">
                                <div *ngIf="fc.email.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-xl-12">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-primary me-2 mb-2"
                                        (click)="isSbmtd = true; editeManufacturers()">Save</button>
                                    <button class="btn btn-sm btn-light mb-2" (click)="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>