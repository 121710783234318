import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as FusionCharts from 'fusioncharts';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';
import { OsmLeafletComponent } from 'src/app/Components/Global/osm-leaflet/osm-leaflet.component';

declare let $: any, Highcharts: any, echarts: any, LoadAGM: any;

@Component({
  selector: 'evin-stock-report-all',
  templateUrl: './stock-report-all.component.html',
  styleUrls: ['./stock-report-all.component.css']
})

export class StockReportAllComponent extends BaseComponent implements OnInit {

  lstMats: any[] = []; lstAllMats: any[] = []; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; lstGrid: any;
  isShown = false; isHide = true; lstGrids: any[] = []; isFacAll = false; isMatAll = false; lstFacs: any[] = []; slctdStrId: any; slctPrdId: any;
  lstFacTags: any[] = []; dstLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; noRcrdsTxt = environment.TblNoRcrds; dropdownList = [];
  slctdStr: any; slctdExcludProd: any; selectedItems = []; dropdownSettings = {}; slctdIncldFac: any[] = []; slctdExcldFac: any[] = [];
  slctdIncldProd: any[] = []; slctdFac: any[] = []; facName = ''; lstMats1: any[] = []; lstAllMats1: any[] = []; kioskId: any; isMatAPICald = true;
  isAS = false; invStkRprtMatFrm = this.fb.group({ storeIdT: [''], productIdT: [''], facility: [''], material: [''], dvntType: [''],
    expireBefore: [''], batchEnb: [''], state: [''], district: [''], block: [''], stockOut: [''], includeCat: [''], excludeCat: [''] });
  slctdStrId1: any; slctdPrdctId: any; matsLST: any[] = []; lstGrid1: any[] = []; lstGrid2: any[] = []; matName: any; lstPrnts: any[] = [];
  lstMatsAll: any[] = []; stkRprtMatView = false; strView = false; expireView = false; slctdStrName = ''; slctdStoreId: any; shwAPrds = true;
  lstSlctdFacTags: any[] = []; lstShowFacTags: any[] = []; lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; slctdMat: any[] = [];
  lstMatTags: any[] = []; dtToDay = new Date();  isFacDsbl = false; invStkRprtFrm = this.fb.group({ storeId: [null], state: [null], 
    expireBefore: [''], district: [null], productId: [null], block: [null], batchEnb: [''], duration: [null], includeStoreBadge: [null], 
    includeProductBadge: [null], excludeStoreBadge: [null], country: [null], abnormalityType: [''], pranthId: [this.chsnDmnId], batchNo: [null] }); 
  shwDst = false; shwBlks = false; isExprt = false; isMC = false; isFC = false; usrLvl = ''; prdBtchEnbld : any = null; isExptHdn = false
  mapStoreLst: any[] = []; bachNo: any = null; isBtch = false; btShow = false; devntType: any = ''; cnfgVal: any = null; rngPrnth =
    JSON.parse(<any>localStorage.getItem('slctdDmnDtls')).pranthLevel; isShwMapBtn = this.rngPrnth == 'District' || this.rngPrnth == 'Block' || this.rngPrnth == 'State';

  constructor(public glblSvc: GlobalService,
    private router: Router,
    private ngbMdl: NgbModal,
    private fb: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacs = s; }), this.glblSvc.sbjctBdgsList.subscribe(s => {
      this.lstMatTags = s.filter(f => (f.badgeTypeId == environment.tagTypes.product && f.inventoryView == true)), this.lstFacTags =
      s.filter(f => f.badgeTypeId === environment.tagTypes.facility); }), this.glblSvc.sbjctStrList.subscribe(s => { this.lstPrnts = s; }),
      this.glblSvc.tableSource.next([]);
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.freeTable, this.glblSvc.invTable.subscribe(s => this.lstGrid = s), this.fcDoughNutModal('doughnut2d', 'fcModal'),
      this.dropdowns(), this.LoadGrid1(), this.loadMatsAll(), this.onFormChanges(), this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst =
        s.statesLst }), this.glblSvc.sbjctKskId.subscribe(sId => { this.kioskId = sId, this.invStkRprtMatFrm.get('storeIdT')?.setValue(sId);
        if (this.kioskId)
          this.LoadMatsbyStoreId(this.kioskId);
        else
          this.router.navigate(['home/stk-rprt']);
      }), this.glblSvc.sbjctKskName.subscribe(sNm => this.facName = sNm);
    if (this.usrLgnDtls) {
      let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData')).cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
        usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '', this.invStkRprtFrm.get('country')?.setValue(cntryId);
      this.cnfgVal = Object.keys(this.usrLgnDtls).filter(f => f == 'permissions').length > 0 ? this.usrLgnDtls.permissions[0]?.
      modulePermissionRoles[1] : null;
      this.dmnLocation();    
    }
    setTimeout(() => { this.onAS()}, 1000);
    // if (this.usrLvl != 'District')
    this.isExptHdn = this.cnfgVal?.permissionRoles[8]?.configuredValue?.value ?? false
      this.LoadStores();
  }

  loadMaps() {
    LoadAGM();
  }

  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.invStkRprtFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), this.invStkRprtFrm.get('district')?.setValue('null'),
          this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
          
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.invStkRprtFrm.get('state')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('district')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.invStkRprtFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 
          'district', true)]).then(() => {
            this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('state')?.setValue(this.slctDmnDetails?.stateId ?? null),
            this.invStkRprtFrm.get('district')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.invStkRprtFrm.get('block')?.setValue(blockID ?? null),
              setTimeout(() => {  this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });        
      }
      if(this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.invStkRprtFrm.get('state')?.setValue('null'), this.invStkRprtFrm.get('district')?.setValue('null');
      // this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
    }
  }

  LoadOSM(type: string) {
    let payLoad: any = this.invStkRprtFrm.getRawValue(), slctdDmnDtls = JSON.parse(<string>localStorage.getItem('slctdDmnDtls'));
    payLoad.userId = JSON.parse(<any>localStorage.getItem('lgnUsrDtls')).userId, payLoad.abnormalityType = payLoad.abnormalityType == '' ? 0 :
      +payLoad.abnormalityType, payLoad.block = +slctdDmnDtls?.blockId ?? 0, payLoad.country = +slctdDmnDtls?.countryId ?? 0, payLoad.district =
      +slctdDmnDtls?.districtId ?? 0, payLoad.duration = (payLoad.duration == null || payLoad.duration == 'null') ? 0 : +payLoad.duration,
      payLoad.expireBefore = (payLoad.expireBefore == null || payLoad.expireBefore == 'null') ? null : payLoad.expireBefore, payLoad.
      includeProductBadge = [], payLoad.pranthId = (payLoad.pranthId == null || payLoad.pranthId == 'null') ? 0 : +payLoad.pranthId,
      payLoad.productId = (payLoad.productId == null || payLoad.productId == 'null') ? 0 : +payLoad.productId, payLoad.state = +slctdDmnDtls?.
      stateId ?? 0, payLoad.storeId = (payLoad.storeId == null || payLoad.storeId == 'null') ? null : this.slctdStoreId;
    if (payLoad.productId != null && payLoad.productId != '' && payLoad.productId != 0 && payLoad.productId != undefined)
      payLoad.productId = this.slctPrdId;
    ['batchEnb', 'batchNo', 'excludeStoreBadge'].forEach(e => { delete payLoad[e]; }), localStorage.setItem('osmPL', JSON.stringify(payLoad));
    this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.getLatLngSVM, payLoad).then(() => {
      if (this.result) {
        if (this.result?.data) {
          localStorage.setItem('osmLat', this.result.data[0]?.latitude), localStorage.setItem('osmLng', this.result.data[0]?.longitude),
            localStorage.setItem('osmData', JSON.stringify(this.result?.data));
          if (type == 'm') {
            const modalRef = this.ngbMdl.open(OsmLeafletComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
            modalRef.result.then(result => { /* if (result == 'done') //   return; // your code */ }, (r) => { console.log(r) });
          }
          else
            this.openFileNewWndw(window.origin + '/#/lflt');
        }
      }
    });
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.invStkRprtFrm.get('district')?.setValue(null),
          this.invStkRprtFrm.get('block')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.invStkRprtFrm.get('block')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.invStkRprtFrm.get('district')?.enable(),
                this.invStkRprtFrm.get('block')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.invStkRprtFrm.get('block')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.invStkRprtFrm.get('district')?.setValue('null'), this.invStkRprtFrm.get('block')?.setValue('null'),
          this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
      else
        this.invStkRprtFrm.get('block')?.setValue('null'), this.invStkRprtFrm.get('block')?.disable();
    }
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  onFormChanges() {
    this.invStkRprtFrm.get('storeId')?.valueChanges.subscribe(val => {
      if (val != null && val != '') {
        if (this.lstPrnts.filter(a => a.storeName == val).length > 0)
          this.invStkRprtFrm.get('productId')?.setValue(null), this.shwAPrds = false;
      }
      else
        this.invStkRprtFrm.get('productId')?.setValue(null), this.shwAPrds = true;
    });
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
    if ((dtVal != null && this.invStkRprtFrm.get('productId')?.value != null) ||
      (dtVal == null && this.invStkRprtFrm.get('productId')?.value != null))
      this.isFacDsbl = true;
    else if (dtVal != null)
      this.isFacDsbl = true;
    else
      this.isFacDsbl = false;
  }
  
  onBatchChng(event: any){
    if (this.bachNo != null)
      this.invStkRprtFrm.get('abnormalityType')?.setValue(''), this.invStkRprtFrm.get('storeId')?.setValue(null);
    this.isBtch = (event.target.value =='')? false : true;
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true };
  }

  onItemSelect(type: any, item: any) {
    if (type == 'inf')
      this.slctdIncldFac.push(item.name);
    if (type == 'exf')
      this.slctdExcldFac.push(item.name);
    if (type == 'inp')
      this.slctdIncldProd.push(item.name);
    // if (type == 'exp')
    // this.slctdIncldFac.push(item.name);
  }

  onSelectAll(type: any, items: any) {
    if (type == 'inf')
      this.slctdIncldFac.push(items.name);
    if (type == 'exf')
      this.slctdExcldFac.push(items.name);
    if (type == 'inp')
      this.slctdIncldProd.push(items.name);
    // if (type == 'exp')
    // this.slctdIncldFac.push(items.name);
  }

  changeFn(event: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    if (evnt == '' || evnt == undefined)
      this.shwAPrds = true, this.btShow= false, this.slctdStrId = null, this.invStkRprtFrm.get('storeId')?.setValue(null);
    else {
      if (this.lstFacs.filter(a => a.storeName == evnt).length != 0) {
        this.shwAPrds = false, this.slctdStr = evnt, this.btShow= true;
        for (let s = 0; s < this.lstFacs.length; s++) {
          if (this.lstFacs[s].storeName == evnt)
            this.slctdStrId = this.lstFacs[s].storeId, this.onStoreClk(this.lstFacs[s].storeName, this.lstFacs[s].storeId);
        }; // this.LoadMatsbyStoreId(this.slctdStrId);
      }
      else
        this.shwAPrds = true, this.slctdStrId = null, this.btShow= false, this.invStkRprtFrm.get('storeId')?.setValue(null);
    }
  }

  loadMatsAll() {
    this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstMatsAll = this.result.data != null ? this.result.data : [];
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  chngProduct(event: any, type: any, evntType: string) {
    let evnt = evntType == 'chng' ? event.target.value : event.value;
    // if (this.lstGrid.length != 1) {
    if (evnt == '' || evnt == undefined)
      this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
    else {
      if (type == 'byStr') {
        if (this.lstAllMats.filter(a => a.productName == evnt).length != 0)
          for (let s = 0; s < this.lstAllMats.length; s++) {
            if (this.lstAllMats[s].productName == event.value)
              this.slctPrdId = this.lstAllMats[s]?.productId;
              if(this.slctPrdId)
                 this.lstShowMatTags = []; this.onResetTags('m');
          }
        else
          this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
      }
      else {
        if (this.lstMatsAll.filter(a => a.name == evnt).length != 0)
          for (let s = 0; s < this.lstMatsAll.length; s++) {
            if (this.lstMatsAll[s].name == event.value)
              this.slctPrdId = this.lstMatsAll[s]?.id, this.prdBtchEnbld=this.lstMatsAll[s].isBatchEnabled;
                if(this.slctPrdId)
                this.lstShowMatTags = []; this.onResetTags('m');
          }
        else
          this.slctPrdId = null, this.invStkRprtFrm.get('productId')?.setValue(null);
      }
    }
    if((this.slctPrdId != null &&  this.invStkRprtFrm.get('expireBefore')?.value) || (this.slctPrdId != null && this.invStkRprtFrm.get('expireBefore')?.value == null) || this.slctPrdId || this.invStkRprtFrm.get('expireBefore')?.value )
    this.isFacDsbl = true;
    else
    this.isFacDsbl = false;
    // }
    // else
    //   this.slctPrdId = this.lstMatsAll.filter(a => a.name == event.value)[0]?.id;
    // if (evnt == '' || evnt == undefined)
    //   this.invStkRprtFrm.get('productId')?.setValue(null);
    // if (type == 'byStr')
    //   for (let s = 0; s < this.lstAllMats.length; s++) {
    //     if (this.lstAllMats[s].productName == event.value)
    //       this.slctPrdId = this.lstAllMats[s]?.productId;
    //   }
  }

  LoadMatsbyStoreId(sId: number) {
    // if (sId) {
    //   this.lstMats = [], this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store,
    //     // environment.apiMthds.invMatbyStoreId.replace('sss', `${sId}`).replace('ttt', this.lgnUsrData.pranthId), null).then(() => {
    //     environment.apiMthds.invMats.replace('sss', `${sId}`).replace('rrr', '').replace(environment.noPgLen, ''), 0).then(() => {
    //     if (this.result) {
    //       if (this.result.status == 'OK') {
    //         if (this.result.data)
    //           this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
    //         else
    //           this.glblSvc.onTstr('e', this.result.message);
    //       }
    //     }
    //   });
    // }
    if (sId) {
      if (this.isMatAPICald) {
        let crntPgIndx = this.crntPage - 1;
        this.isMatAPICald = !this.isMatAPICald, this.lstMats = []; let mT: any = [];  this.lstShowMatTags.forEach(e => { mT.push(e.id); });
          this.lstAllMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMats.
            replace('sss', `${sId}`).replace('rrr', '').replace('null', mT.length > 0 ? mT :'null').replace('pageNo', `${crntPgIndx}`).
            replace('pageSize', `${this.pageSize}`), 0).then(() => {
          if (this.result) {
            if (this.result.status == 'OK') {
              if (this.result.data)
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize,
                    this.totalItems), this.result.data?.productsDTOs.forEach((e: any) => {
                    Object.assign(e, { isB: false, isM: false, isH: false, isAS: false, isIT: false, lstB: [], lstM: [], lstH: [] });
                  }), this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [],
                  this.lstMats = this.lstAllMats, this.onFilter(), this.matsLST = this.lstMats;
              else
                this.glblSvc.onTstr('e', this.result.message);
            }
          }
          setTimeout(() => { this.isMatAPICald = true; }, 1000);
        });
      }
    }
  }

  loadPrdctsByFilter() {
    if (this.slctPrdId) {
      let lstMC: any[] = [], lstStrs: any[] = [], payLoad: any = {};
      // this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }),
      this.lstShowFacTags.forEach(e => { lstStrs.push(e.id); }), payLoad = {
        type: 0, includeStoreBadge: lstStrs,  // .name
        productId: this.slctPrdId, abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? 0, includeProductBadge: lstMC,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: this.slctdStrId ?? 0, expireBefore: this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd),
        country: null, state: this.invStkRprtFrm.get('state')?.value ?? null, district: this.invStkRprtFrm.get('district')?.value ?? null,
        block: this.invStkRprtFrm.get('block')?.value ?? null
      };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.getStrsbyPrdct, payLoad).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
    }
  }

  setPage(pagNo: number, type: any): void {
    if (type == 'str')
      this.crntPage = pagNo, this.LoadStores();
    else if (type == 'mat') {
      if (this.invStkRprtFrm.get('storeId')?.value != '' && this.invStkRprtFrm.get('storeId')?.value != null) {
        let slctdStrId = this.lstFacs.filter(f => f.storeName == this.invStkRprtFrm.get('storeId')?.value)[0]?.storeId ?? null;
        if (slctdStrId != null)
          this.crntPage = pagNo, this.LoadMatsbyStoreId(slctdStrId);
      }
    }
    else if (type == 'mstStr')
      this.crntPage = pagNo, this.LoadGrid1();
    else if (type == 'expires')
      this.crntPage = pagNo, this.LoadGrid2();
  }

  clearBSDate(ctrl: string, type: any) {
    if (type == 'first')
      this.invStkRprtFrm.get(ctrl)?.setValue('');
    if (type == 'second')
      this.invStkRprtMatFrm.get(ctrl)?.setValue('');
  }

  freeTable() {
    $(".table-multi-columns").freezeTable({ 'columnNum': 4 });
  }

  LoadData() {
    this.strView = false,  this.slctdStrId ? this.stkRprtMatView =true: this.stkRprtMatView= false, this.expireView = false;
    if (this.lstGrid.length != 1) {
      if (this.invStkRprtFrm.get('productId')?.value && this.invStkRprtFrm.get('expireBefore')?.value == '' &&
        this.invStkRprtFrm.get('batchNo')?.value == null )
        this.strView = true, this.expireView = false, this.stkRprtMatView = false, this.LoadGrid1();
      else if ((this.invStkRprtFrm.get('expireBefore')?.value && this.invStkRprtFrm.get('productId')?.value) || 
        (this.invStkRprtFrm.get('batchNo')?.value && this.invStkRprtFrm.get('productId')?.value) ||
        this.invStkRprtFrm.get('expireBefore')?.value || this.invStkRprtFrm.get('batchNo')?.value )
        // && (this.invStkRprtFrm.get('productId')?.value == null || 
        // this.invStkRprtFrm.get('productId')?.value == ''
        this.expireView = true, this.strView = false, this.stkRprtMatView= false, this.LoadGrid2();
      else if (!this.expireView && !this.strView && !this.stkRprtMatView)
        this.LoadStores();
      else if (this.stkRprtMatView) {
        if (this.invStkRprtFrm.get('storeId')?.value != '' && this.invStkRprtFrm.get('storeId')?.value != null)
          this.LoadMatsbyStoreId(+this.slctdStrId), this.expireView = false, this.strView = false;
        else
          this.onFilter();
      }
    }
    else
      if (this.invStkRprtFrm.get('productId')?.value)
        this.strView = true, this.expireView = false, this.stkRprtMatView = false, this.LoadGrid1();
      else if (this.invStkRprtFrm.get('expireBefore')?.value || this.invStkRprtFrm.get('batchNo')?.value)
        // && (this.invStkRprtFrm.get('productId')?.value == null || 
        // this.invStkRprtFrm.get('productId')?.value == ''
        this.expireView = true, this.strView = false, this.stkRprtMatView = false, this.LoadGrid2();
        else if (this.kioskId && this.invStkRprtFrm.get('includeProductBadge')?.value)
        this.LoadMatsbyStoreId(+this.slctdStrId), this.stkRprtMatView= true, this.strView =false, this.expireView= false;
      else
        this.stkRprtMatView = true, this.onFilter();
  }

  LoadStores() {
    let crntPgIndx = this.crntPage - 1, payLoad = this.invStkRprtFrm.getRawValue(), fT: any[] = [], mT: any[] = [];
    if (payLoad.storeId && (payLoad.productId == null || payLoad.productId == ''))
      this.glblSvc.sbjctKskName.next(payLoad.storeId), this.glblSvc.sbjctKskId.next(this.slctdStrId) // , this.router.navigate(['home/stk-rprt-mtrl']);
    else {
      /* if (payLoad.storeId && payLoad.productId)
        payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null; */
      if (payLoad.storeId)
        payLoad.storeId = payLoad.storeId ? this.slctdStrId : null;
      if (/[^a-zA-Z]/.test(payLoad.productId))
        setTimeout(() => { this.invStkRprtFrm.get('productId')?.setValue(this.lstMatsAll.filter(f => f.id == payLoad.productId)[0]?.name), this.isFacDsbl = true; },
          1000), payLoad.productId = this.lstMatsAll.filter(f => f.name == payLoad.productId)[0]?.id ?? this.slctPrdId;
      // payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = (payLoad.productId !='' || payLoad.productId != null)? +this.slctPrdId : null,
      this.lstShowFacTags.forEach(e => { fT.push(e.id); }), this.lstShowMatTags.forEach(e => { mT.push(e.id); }), // e.name - e.name
        payLoad.expireBefore = (payLoad.expireBefore != null && payLoad.expireBefore != '') ?
          this.glblSvc.setDtFrmt(payLoad.expireBefore, this.dtF.ymd) + this.tmF.zeroTM : null, payLoad.pranthId = this.chsnDmnId,
        payLoad.abnormalityType = (payLoad.abnormalityType != '' && payLoad.abnormalityType != null) ? +payLoad.abnormalityType : null,
        // payLoad.includeStoreBadge = (payLoad.includeStoreBadge?.length != 0 && this.slctdIncldFac.length != 0) ? this.slctdIncldFac : null,
        // payLoad.includeProductBadge = (payLoad.includeProductBadge?.length != 0 && this.slctdIncldProd.length != 0) ? this.slctdIncldProd : null,
        payLoad.state = payLoad.state == 'null' ? null : payLoad.state, payLoad.district = payLoad.district == 'null' ? null : payLoad.district,
        payLoad.block = payLoad.block == 'null' ? null : payLoad.block, payLoad.includeStoreBadge = fT.length != 0 ? fT : null,
        delete payLoad.batchEnb, this.lstGrid = [], this.mapStoreLst = [] ,payLoad.includeProductBadge = mT.length != 0 ? mT : null,
        payLoad.excludeStoreBadge = (payLoad.excludeStoreBadge?.length != 0 && this.slctdExcldFac.length != 0) ? this.slctdExcldFac : null,
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.invntrySV.replace('pageNo', `${crntPgIndx}`).
          replace('pageSize', `${this.pageSize}`), payLoad, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [], mapStore: any[] = [];
            if (data) {
              this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems),
                Object.keys(data.inventoryDetailMap).forEach((s: any, i: number) => {
                  if (i == 0)
                    data.inventoryDetailMap[s].forEach((m: any) => { hdrs.push(m.productName), ttl.push(data.totalMap[m.productName]); });
                  let sId = data.inventoryDetailMap[s][0].storeId, sName = s, adrs = data.inventoryDetailMap[s][0], block = adrs.block != null ?
                    `${adrs.block}, ` : '', district = adrs.district != null ? `${adrs.district}, ` : '', state = adrs.state != null ? adrs.state : '',
                    city = adrs.city != null ? `${adrs.city}, ` : '', fnl = block == '' ? city : block, lat = adrs.latitude != null ? `${adrs.latitude}` : '',
                    lon = adrs.longitude != null ? `${adrs.longitude}` : '';
                  result.push({ kId: sId, kName: sName, kAdrs: fnl + district + state, matData: data.inventoryDetailMap[s], ccp: '', hdrs: hdrs, total: ttl });
                  if (adrs.latitude != null && adrs.latitude != '' && adrs.longitude != null && adrs.longitude != '')
                    mapStore.push({ name: sName, strAdrs: fnl + district + state, lat: +lat, lon: +lon }), this.mapStoreLst = mapStore;
                });
            }
            else if (data == null)
              this.glblSvc.onTstr('i', this.result.message);
            else
              this.glblSvc.onTstr('e', this.result.message);
            this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid),
              this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0), this.isHide = result.length == 0;
            if (this.lstGrid.length == 1)
              this.isFacDsbl = true, this.stkRprtMatView = true, this.slctdStrId = this.lstGrid[0].kId, this.onStoreClk(this.lstGrid[0].kName, this.slctdStrId),
                this.invStkRprtFrm.get('storeId')?.setValue(this.lstGrid[0].kName), this.LoadMatsbyStoreId(this.slctdStrId);
            else
              this.isFacDsbl = false, this.stkRprtMatView = false, this.invStkRprtFrm.get('storeId')?.setValue(null);
            // setTimeout(() => { this.ecDoughnut(); }, 100);
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }

  onReset() {
    this.isFacDsbl = false, this.isBtch = false, this.devntType = '', this.btShow = false;
    if (this.isFC)
      document.getElementById('cnclFBtn')?.click();
    if (this.isMC)
      document.getElementById('cnclMBtn')?.click();

    this.invStkRprtFrm.patchValue({ productId: null, duration: null, abnormalityType: '', storeId: null, batchEnb: '' }), this.isMC = false, this.isFC = false, this.bachNo = null,
    this.slctdIncldFac = [], this.slctdIncldProd = [], this.slctdExcldFac = [], this.lstShowFacTags = [...[]], this.lstShowMatTags = [...[]], this.slctdStrId = '';
    this.isFacAll = false, this.isMatAll = false, this.strView = false, this.expireView = false, this.stkRprtMatView = false, this.slctPrdId = null;
    this.lstMats = []; this.lstAllMats = []; this.matsLST = []; this.onResetTags('m'), this.onResetTags('f'), $('#cdEB')?.click(); // this.clearBSDate('expireBefore', 'first');
    this.dmnLocation(), setTimeout(() => {  this.LoadStores(), this.onAS()}, 500);
  }

  onResetDrpdwns(type: any) {
    if (type == 'f')
      this.invStkRprtFrm.get('includeStoreBadge')?.reset(), this.invStkRprtFrm.get('excludeStoreBadge')?.reset()
    // this.invStkRprtFrm.patchValue({includeStoreBadge: [], excludeStoreBadge: []})
    if (type == 'p')
      this.invStkRprtFrm.get('includeProductBadge')?.reset()
    // , this.invStkRprtFrm.get('excludeProductBadge')?.reset()
    //     this.invStkRprtFrm.patchValue({includeProductBadge: []})
  }

  onStoreClk(kskName: string, kskId: number) {
    if (kskName != '')
      this.invStkRprtFrm.get('batchNo')?.setValue(null),
      this.glblSvc.sbjctKskName.next(kskName), this.glblSvc.sbjctKskId.next(kskId), this.slctdStrName = kskName, this.slctdStoreId = kskId,
        // this.router.navigate(['home/stk-rprt-mtrl']), 
        this.stkRprtMatView = true, this.slctdStrId = kskId, this.invStkRprtFrm.get('storeId')?.setValue(kskName),
        localStorage.setItem('clkdStrName', this.slctdStrName), localStorage.setItem('clkdStoreId', this.slctdStoreId);
  }

  fcDoughNut(chrtType: string, divId: string) {
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '120', height: '120', dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "0",
            "paletteColors": '#1aaf5d,#D9534F,#00C0EF,#cccccc',
            "fill-opacity": '0',
            "transform": '0',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "0",
            "showShadow": '0',
            "showPlotBorder": "0",
            "borderColor": "#fff",
            "glow": "0",
            "showCanvasBorder": '0',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "0",
            "enableMultiSlicing": "0",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "0",
            "theme": "fussion",
            "showValue": "0",
            "showValues": '0 ',
            "showLabels": '0 ',
            "animation": "0",
            "showTooltip": "0",
            "bgColor": "red",
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            // "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
    });
  }

  fcDoughNutModal(chrtType: string, divId: string) {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json', dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "1",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",
            "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
      // if (divId == 'fcpie2D')
      //   setTimeout(() => { fc.dispose(), $('#shwBtn').click(); }, 5000);
    });
  }

  ecDoughnut() {
    this.lstGrid.forEach((e: any, i: number) => {
      var myChart = echarts.init(document.getElementById(`ec-dn-${i}`),), option;
      option = {
        series: [
          {
            name: '',
            type: 'pie',
            animationType: 'expansion',
            animationDuration: 0,
            animationDurationUpdate: 0,
            startAngle: 0,
            endAngle: 360,
            selectedMode: 'single',
            avoidLabelOverlap: false,
            color: ['#1aaf5d', '#D9534F', '#FAD42E', '#00C0EF'],
            radius: ['40%', '20%'],
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 89, name: 'Normal' },
              { value: 7, name: 'Zero Stock' },
              { value: 1, name: 'Min' },
              { value: 3, name: 'Max' },
            ]
          }
        ]
      };
      myChart.setOption({})
      if (option && typeof option === 'object')
        myChart.setOption(option);
    });
  }

  changeFn1(evnt: any) {
    for (let s = 0; s < this.lstPrnts.length; s++) {
      if (this.lstPrnts[s].storeName == evnt.value)
        this.slctdStrId1 = this.lstPrnts[s].storeId;
    }
    this.LoadMatsbyStoreId(this.slctdStrId1);
  }

  onChngMtrl(evnt: any) {
    for (let s = 0; s < this.lstAllMats1.length; s++) {
      if (this.lstAllMats1[s].productName == evnt.value)
        this.slctdPrdctId = this.lstAllMats1[s].productId;
    }
  }

  LoadMatsbyStoreId1(sId: number) {
    if (sId) {
      let crntPgIndx = this.crntPage - 1;
      this.lstMats1 = [], this.lstAllMats1 = [],this.lstGrid =[]; this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.
          invMats.replace('sss', `${sId}`).replace('rrr', '').replace('pageNo', `${crntPgIndx}`).replace('pageSize', `${this.pageSize}`).
          replace('ppp', 'null'), 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.totalItems = this.result.data?.totalRecordCount ?? 0,
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems), this.result.data?.productsDTOs.forEach((e: any) => {
                  Object.assign(e, { isB: false, isM: false, isH: false, isAS: false, isIT: false, lstB: [], lstM: [], lstH: [] });
                }), this.lstAllMats1 = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [],
                this.lstMats1 = this.lstAllMats1, this.onFilter(), this.matsLST = this.lstMats1;
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
  }

  onFilter() {
    let frmVals = this.invStkRprtFrm.getRawValue(), beVal = (frmVals.batchEnb == 'wb' ? true : (frmVals.batchEnb == 'nb' ? false : null)), data: any[] = [];
    if (beVal != null || (frmVals.productId != '' && frmVals.productId != null) || frmVals.abnormalityType != '') {
      if (beVal != null)
        data = this.lstAllMats.filter(f => f.batchManagement == beVal);
      // if (frmVals.storeId != '' && frmVals.storeId != null)
      //   data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.storeId == +this.slctdStrId);
      if (frmVals.productId != '' && frmVals.productId != null && frmVals.productId != undefined)
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.productId == +this.slctPrdId);
      if (frmVals.abnormalityType != '')
        data = (data.length > 0 ? data : this.lstAllMats).filter(f => f.abnormalityType == +frmVals.abnormalityType);
      this.lstMats = data //, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.lstMats.length);
    }
    else
      this.lstMats = this.lstAllMats //, this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.lstMats.length);
  }

  GetNestedData(type: string, indx: number, id: any = '') {
    this.FetchData(environment.apiCalls.get, (type == 'b' ? environment.apiPaths.store : environment.apiPaths.product),
      (type == 'b' ? environment.apiMthds.getBatchbyMatId.replace('mmm', id).replace('kkk', `${this.kioskId}`) :
        (type == 'h' ? environment.apiMthds.getMatHstry.replace('kkk', `${this.kioskId}`).replace('mmm', id) :
          environment.apiMthds.getMatsMinMax.replace('kkk', `${this.kioskId}`).replace('mmm', id))), null,
      (type == 'b' ? 0 : 2), true, (type == 'b' ? 0 : 1)).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            this.lstMats[indx][((type == 'b') ? 'lstB' : ((type == 'm') ? 'lstM' : 'lstH'))] = this.result.data != null ? this.result.data : [];
        }
      });
  }

  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => { /* if (result == 'done') //   return; // your code */ }, (r) => { console.log(r) });
  }

  LoadGrid1() {
    if (this.slctPrdId) {
      this.lstGrid1 = []; this.lstGrid2 = []; this.lstGrid = [];
      let lstStrs: any[] = [], payLoad: any = {};
      this.lstShowFacTags.forEach(e => { lstStrs.push(e.id); }), payLoad = {
        productId: this.slctPrdId ?? null, abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? null, includeProductBadge: null, includeStoreBadge: lstStrs,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: this.slctdStrId ?? 0, expireBefore: this.invStkRprtFrm.get('expireBefore')?.value ? (this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd) + this.tmF.zeroTM) : null,
        country: 0, state: +this.invStkRprtFrm.get('state')?.value ?? 0, district: +this.invStkRprtFrm.get('district')?.value ?? 0, block: +this.invStkRprtFrm.get('block')?.value ?? 0
      };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.getStrsbyPrdct.
        replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), payLoad).then(() => {
          let res = this.result;
          if (this.result) {
            if (this.result.data) {
              if (this.lstGrid.length != 1)
                this.totalItems = this.result.data?.totalRecordCount ?? 0, this.lstGrid1 = this.result?.data?.stockViewProductsDTO ?? [],
                  this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
              else
                this.lstGrid1 = this.result?.data?.stockViewProductsDTO?.filter((a: any) => a.storeId == this.lstGrid[0].kId),
                  this.lstGrid1 = this.lstGrid1.length > 0 ? this.lstGrid1 : [], this.totalItems = this.lstGrid1.length,
                  this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            }
            else
              this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', res.message);
        });
    }
  }

  LoadGrid2() {
    if ((this.invStkRprtFrm.get('expireBefore')?.value != null) || (this.invStkRprtFrm.get('batchNo')?.value != null)) {
      let lstMC: any[] = [], lstStrs: any[] = [], payLoad: any = {}; let product = this.lstMatsAll.find(e => this.slctPrdId == e.id);
      let batchNum: any = this.invStkRprtFrm.get('batchNo')?.value ?? null
      this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), this.lstShowFacTags.forEach(e => { lstStrs.push(e.id); }), payLoad = {
        productId: this.slctPrdId ?? 0, // .name
        isBatchEnabled: this.slctPrdId ? product.isBatchEnabled : false, batchNo: batchNum !=null ? (batchNum.toUpperCase()) : null,
        abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? null, includeProductBadge: lstMC ?? null, includeStoreBadge: lstStrs ?? null,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: +this.invStkRprtFrm.get('storeId')?.value ?? null, expireBefore:  this.invStkRprtFrm.get('expireBefore')?.value ? 
          (this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd) + this.tmF.zeroTM) : null,
        country: 0, state: +this.invStkRprtFrm.get('state')?.value ?? 0, district: +this.invStkRprtFrm.get('district')?.value ?? null, block: +this.invStkRprtFrm.get('block')?.value ?? 0
      };
      this.lstGrid2 = [],  
      this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.getPrdctsbyExpDt.replace('pageNo', `${this.crntPage - 1}`).replace('pageSize', `${this.pageSize}`), payLoad).then(() => {
        let res = this.result;
        if (this.result) {
          if (this.result.data) {
            if (this.lstGrid.length != 1)
              this.totalItems = this.result.data?.totalRecordsCount ?? 0, this.lstGrid2 = this.result?.data?.fetchProductsListBasedOnExpire ?? [],
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
            else
              this.lstGrid2 = this.result?.data?.fetchProductsListBasedOnExpire?.filter((a: any) => a.store_id == this.lstGrid[0].kId),
                this.lstGrid2 = this.lstGrid2.length > 0 ? this.lstGrid2 : [], this.totalItems = this.lstGrid2.length,
                this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
          }
          else
            this.glblSvc.onTstr('i', this.result.message);
        }
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

  onMatDetails(id: any) {
    this.router.navigate(['home/setup-matrls-details'])
  }

  onfcltyDetails(storeId: any) {
    this.glblSvc.sbjctStrId.next(storeId), this.router.navigate(['home/setup-fclty-details'])
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFacTags.length === this.lstSlctdFacTags.length;
    else
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMatTags.length === this.lstSlctdMatTags.length;
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdMat = [...this.lstShowMatTags];
  }

  onEE() {
    if (this.slctPrdId == null && this.lstShowMatTags.length == 0) {
      this.glblSvc.onTstr('w', 'Please choose Material Category');
      if (!this.isAS)
        $('#sr1AS')?.click();
      if (!this.isMC)
        $('#sr1MC')?.click();
    }
    else {
      let lstMC: any[] = [], fT: any[] = [], payLoad: any = {}, ddlVal = $('#ee1').val(), frmCtrls = this.invStkRprtFrm.getRawValue();
      this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), this.lstShowFacTags.forEach(e => { fT.push(e.id); }), payLoad = { // .name
        productId: frmCtrls.productId ? this.slctPrdId : null, pranthId: +this.chsnDmnId, userName: this.usrLgnDtls.userName, email: this.usrLgnDtls.email,
        userId: +this.usrLgnDtls.userId, includeAllProductBadge: lstMC, abnormalityType: +frmCtrls.abnormalityType ?? 0,
        duration: frmCtrls.duration ?? '', country: null, state: frmCtrls.state, district: frmCtrls.district, block: frmCtrls.block,
        includeStoreBadge: fT.length != 0 ? fT : null
      };
      if (ddlVal == 'nb') {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtstckrpt, payLoad, 11).then(() => {
          this.isExprt = false;
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
      else {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtWBstckrpt, payLoad, 11).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
    }
  }

  onEE2() {
    if (this.slctPrdId == null && this.lstShowMatTags.length == 0) {
      this.glblSvc.onTstr('w', 'Please choose Material Category');
      if (!this.isAS)
        $('#sr1AS')?.click();
      if (!this.isMC)
        $('#sr1MC')?.click();
    }
    else {
      let lstMC: any[] = [], payLoad: any = {}, ddlVal = $('#ee2').val();
      this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), payLoad = {
        rstoreId: 0, type: 0, productId: 0, // .name
        abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? 0, includeAllProductBadge: lstMC,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: this.slctdStrId, userName: this.usrLgnDtls.userName, email: this.usrLgnDtls.email
      };
      if (ddlVal == 'nb') {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtStckrpt1fac, payLoad, 11).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
      else {
        this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry, environment.apiMthds.exprtStckRpt1WBfac, payLoad, 11).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
    }
  }

  onEE3() {
    if (this.invStkRprtFrm.get('productId')?.value == null)
      this.glblSvc.onTstr('w', 'Please choose product');
    else {
      let lstStrs: any[] = [], payLoad: any = {}, ddlVal = $('#ee3').val();;
      this.lstShowFacTags.forEach(e => { lstStrs.push(e.id); }), payLoad = {
        productId: this.slctPrdId, abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? 0, includeProductBadge: null, includeStoreBadge: lstStrs,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: this.slctdStrId ?? 0, expireBefore: this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd),
        country: null, state: this.invStkRprtFrm.get('state')?.value ?? null, district: this.invStkRprtFrm.get('district')?.value ?? null, block: this.invStkRprtFrm.get('block')?.value ?? null
      };
      if(ddlVal== 'nb'){
      this.FetchData(environment.apiCalls.post, environment.apiPaths.report, environment.apiMthds.exprtStckvewPrdctByFltr.
            replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).
            replace('eml', this.usrLgnDtls.email), payLoad, 11, true, 2).then(() => {
          if (this.result)
            this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
        });
      }
      else{
        this.FetchData(environment.apiCalls.post, environment.apiPaths.report, environment.apiMthds.exprtStckvewPrdctByFltrWB.
              replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).
              replace('eml', this.usrLgnDtls.email), payLoad, 11, true, 2).then(() => {
            if (this.result)
              this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
          });
      }
    }
  }

  onEE4() {
    if (this.invStkRprtFrm.get('expireBefore')?.value == null && this.invStkRprtFrm.get('batchNo')?.value == null)
      this.glblSvc.onTstr('w', 'Please choose Expires before / batchNo');
    else {
      let lstMC: any[] = [], lstStrs: any[] = [], payLoad: any = {}; let product = this.lstMatsAll.find(e => this.slctPrdId == e.id);
      let batchNum: any = this.invStkRprtFrm.get('batchNo')?.value ?? null
      this.lstShowMatTags.forEach(e => { lstMC.push(e.id); }), this.lstShowFacTags.forEach(e => { lstStrs.push(e.id); }), payLoad = {
        productId: this.slctPrdId ?? 0, batchNo: batchNum !=null ? (batchNum.toUpperCase()) : null,
        isBatchEnabled: this.slctPrdId ? product.isBatchEnabled : false,
        abnormalityType: +this.invStkRprtFrm.get('abnormalityType')?.value ?? null, includeProductBadge: lstMC ?? null, includeStoreBadge: lstStrs ?? null,
        duration: +this.invStkRprtFrm.get('duration')?.value ?? 0, pranthId: +this.chsnDmnId, userId: +this.usrLgnDtls.userId,
        storeId: +this.invStkRprtFrm.get('storeId')?.value ?? null, expireBefore:  this.invStkRprtFrm.get('expireBefore')?.value ? 
        (this.glblSvc.setDtFrmt(this.invStkRprtFrm.get('expireBefore')?.value, this.dtF.ymd) + this.tmF.zeroTM) : null,
        country: 0, state: +this.invStkRprtFrm.get('state')?.value ?? 0, district: +this.invStkRprtFrm.get('district')?.value ?? null, block: +this.invStkRprtFrm.get('block')?.value ?? 0
      };
      this.FetchData(environment.apiCalls.post, environment.apiPaths.report, environment.apiMthds.exprtStckExprBefr.
          replace('rrr', `${this.chsnDmnId}`).replace('iii', this.usrLgnDtls.userId).replace('nnn', this.usrLgnDtls.userName).
          replace('eml', this.usrLgnDtls.email), payLoad, 11, true, 2).then(() => {
        if (this.result)
          this.glblSvc.onTstr(this.result.status == 'OK' ? 's' : 'e', this.result.message);
      });
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.invStkRprtFrm.get('state')?.enable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.enable(), this.invStkRprtFrm.get('block')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.invStkRprtFrm.get('state')?.disable(), this.invStkRprtFrm.get('district')?.disable(), this.invStkRprtFrm.get('block')?.disable();
  }

  storeMap(lstMapData: any[] = this.mapStoreLst) {
    Highcharts.mapChart('locMap', {
      chart: { map: 'countries/in/custom/in-all-disputed' }, title: { text: '' }, credits: false, mapNavigation: { enabled: true },
      // exporting: { enabled: true },
      tooltip: { headerFormat: '', pointFormat: '<b>{point.name}, </b><b>{point.strAdrs}</b><br>Lat: {point.lat}, Lon: {point.lon}' },
      series: [{ name: 'Basemap', borderColor: '#A0A0A0', nullColor: 'rgba(200, 200, 200, 0.3)', showInLegend: false },
               { name: 'Separators', type: 'mapline', nullColor: '#707070', showInLegend: false, enableMouseTracking: false },
               { color: Highcharts.getOptions().colors[1], data: lstMapData, type: 'mappoint', name: '' }
              ],
              exporting: {
                accessibility:{
                  enabled:true
                },
                buttons: {
                  exportButton:{
                    enabled: false
                  },
                  printButton:{
                    enabled: false
                  }  
                },
                enabled:true,
                filename:'stock-report',
                type: "application/pdf",
            }
              // menuItemDefinitions: {
              //   downloadPNG: {textKey: 'downloadPNG',onclick: function () {  this.exportChart(); } },
              //   downloadJPEG: { textKey: 'downloadJPEG', onclick: function () { this.exportChart({ type: 'image/jpeg' }); } },
              //   downloadPDF: { textKey: 'downloadPDF',  onclick: function () { this.exportChart({ type: 'application/pdf' }); } }
              // }
    });
  }

}