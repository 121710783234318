import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-create-manufacturers',
  templateUrl: './create-manufacturers.component.html',
  styleUrls: ['./create-manufacturers.component.css']
})

export class CreateManufacturersComponent extends BaseComponent implements OnInit {

  isSbmtd = false; cntryLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; dstLst: any[] = []; producerBadges: any[] = [];
  prdcrBadges: any[] = []; IdentifiersLst: any[] = []; usrLvl = ''; cntryId: any = ''; isValidPswd = true; isPswdShow = false;
  createManufacturersForm = this.formBuilder.group({ name: ['', Validators.required],
    description: [''], countryId: ['431', Validators.required], stateId: [null, Validators.required], contactName: ['', Validators.required], 
    gender: ['', Validators.required], isEnabled:[true], alternateContactNo: ['', [Validators.minLength(10), Validators.maxLength(13)]], 
    mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13), Validators.pattern(environment.patterns.mble)]],
    email: ['', [Validators.required, Validators.email]], password: ['', [Validators.required , Validators.pattern(environment.patterns.alphaNum),
      Validators.minLength(7), Validators.maxLength(18)]], districtId: [null], blockId: [null], cpwd: ['', Validators.required] },
      { validator: this.MustMatch('password', 'cpwd') } );

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; this.IdentifiersLst = s.IdentifiersLst });
    this.glblSvc.sbjctBdgsList.subscribe((a: any) => {
      this.producerBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.manufacturer)
    });
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst; });
  }

  ngOnInit(): void {
    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst });
    if (this.usrLgnDtls) {
      this.cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData'))?.cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null;
      let usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
      this.usrLvl = usrLvlDtls?.userLevel ?? '';
    } 
    this.dmnLocation(), setTimeout(() => { this.onAS(); }, 1000);
  }
  
  onShwHde() {
    if (this.createManufacturersForm.get('password')?.value != '')
      this.isPswdShow = !this.isPswdShow, this.ShowHidePwd('password', this.isPswdShow);
  }
  
  dmnLocation() {
    if (this.slctDmnDetails) {
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.createManufacturersForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'),
        this.createManufacturersForm.get('districtId')?.setValue('null'), this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true);
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null) {
        this.createManufacturersForm.get('stateId')?.setValue(+this.slctDmnDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null,
          'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null, 'district', true)]).then(() => {
            this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('districtId')?.setValue(+this.slctDmnDetails?.districtId ?? 'null'),
              setTimeout(() => { this.createManufacturersForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null) {
        let blockID = +this.slctDmnDetails?.blockId.split(',')[0];
        Promise.all([this.onLocChng(+this.slctDmnDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.slctDmnDetails?.districtId ?? null,
          'district', true)]).then(() => {
            this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('stateId')?.setValue(this.slctDmnDetails?.stateId ?? null),
              this.createManufacturersForm.get('districtId')?.setValue(this.slctDmnDetails?.districtId ?? 'null'), this.createManufacturersForm.get('blockId')?.setValue(blockID ?? null),
              setTimeout(() => { this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
          });
      }
      if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
        this.createManufacturersForm.get('stateId')?.setValue('null'), this.createManufacturersForm.get('districtId')?.setValue('null');
    }
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [],this.createManufacturersForm.get('stateId')?.setValue(sid),
        this.createManufacturersForm.get('districtId')?.setValue(null), this.createManufacturersForm.get('blockId')?.setValue(null);
      else
        did = slctdId, this.blkLst = [],this.createManufacturersForm.get('blockId')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
          replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
        if (this.result) {
          if (this.result.data) {
            if (type == 'state')
              this.dstLst = this.result.data != null ? this.result.data : [], this.createManufacturersForm.get('districtId')?.enable(),
                this.createManufacturersForm.get('blockId')?.disable();
            else
              this.blkLst = this.result.data != null ? this.result.data : [], this.createManufacturersForm.get('blockId')?.enable();
          }
        }
      })
    }
    else {
      if (type == 'state')
        this.createManufacturersForm.get('districtId')?.setValue('null'), this.createManufacturersForm.get('blockId')?.setValue('null'),
          this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('blockId')?.disable();
      else
        this.createManufacturersForm.get('blockId')?.setValue('null'), this.createManufacturersForm.get('blockId')?.disable();
    }
  }

  GetIdentifiers(): FormArray {
    return this.createManufacturersForm.get('producerTm') as FormArray;
  }

  onCreateIdentifiers(): FormGroup {
    return this.formBuilder.group({
      tmTypeId: ['', Validators.required], tmCode: ['', Validators.required]
    });
  }

  onDelIdentifiers(tabIndex: any) {
    this.GetIdentifiers().removeAt(tabIndex)
  }

  onAddIdentifiers() {
    if (this.GetIdentifiers().length == 0)
      this.GetIdentifiers().push(this.onCreateIdentifiers());
    else {
      let mtCnt = 0;
      this.GetIdentifiers().controls.forEach((e: any) => {
        if (e.controls.tmTypeId.value == '' || e.controls.tmCode.value == '') {
          this.glblSvc.onTstr('i', 'Kindly fill all mandatory fields to add a new record'), mtCnt++;
          return;
        }
      });
      if (mtCnt == 0)
        this.GetIdentifiers().push(this.onCreateIdentifiers());
    }
  }

  onKU(fC: string) {
    this.createManufacturersForm.get(fC)?.setValue((<string>this.createManufacturersForm.get(fC)?.value).trimLeft());
  }

  onSlct($event: any) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.prdcrBadges = [{
        badgeId: this.producerBadges.filter(a => a.name == $event.target.value)[0].id,
        createdBy: this.usrLgnDtls.userId,
        createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
        isDeleted: this.producerBadges.filter(a => a.name == $event.target.value)[0].isDeleted, producerId: null,
        updatedBy: this.usrLgnDtls.userId,
        updatedOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
      }]
    }
  }

  // onNestedSlct($event: any, type: any) {
  //   let cid = '', sid = '', did = '';
  //   if (type == 'country')
  //     cid = $event.target.value;
  //   else if (type == 'state')
  //     sid = $event.target.value;
  //   else
  //     did = $event.target.value;
  //   this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'country' ? environment.apiMthds.getStates.replace('ccc', cid) :
  //       (type == 'state' ? environment.apiMthds.getDistricts.replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did))), null,
  //       2, true, 1).then(() => {
  //     if (this.result) {
  //       if (this.result.data) {
  //         if (type == 'country')
  //           this.stsLst = this.result.data != null ? this.result.data : [];
  //         else if (type == 'state')
  //           this.dstrtLst = this.result.data != null ? this.result.data : [];
  //         else
  //           this.blkLst = this.result.data != null ? this.result.data : [];
  //       }
  //     }
  //   });
  // }

  cancel() {
    this.createManufacturersForm.reset(), this.isSbmtd = false, this.createManufacturersForm.get('countryId')?.setValue('431'),
    this.createManufacturersForm.get('gender')?.setValue(''),
      this.createManufacturersForm.get('stateId')?.setValue(''), this.createManufacturersForm.get('districtId')?.setValue(''),
      this.createManufacturersForm.get('blockId')?.setValue(''), this.dmnLocation(), setTimeout(() => { this.onAS()}, 500);
      this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } });
     
  }

  get fc() { return this.createManufacturersForm.controls; }

  createManufacturers() {
    this.checkFormValidations(this.createManufacturersForm);
    if (this.createManufacturersForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.createManufacturersForm.getRawValue();
      payLoad.producerTm = [], payLoad.gender = payLoad.gender != '' && payLoad.gender != null ? 
      payLoad.gender : null, payLoad.countryId = +payLoad.countryId, payLoad.stateId = +payLoad.stateId,
        payLoad.updatedBy = this.usrLgnDtls.userId, payLoad.createdBy = payLoad.updatedBy, payLoad.districtId = null, payLoad.blockId = null,
        payLoad.pranthId = this.chsnDmnId, payLoad.password = payLoad.password != '' && payLoad.password != null ? payLoad.password : null,
        payLoad.createdOn = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, payLoad.updatedOn = payLoad.createdOn,
        delete payLoad.producerBadges, delete payLoad.id, payLoad.producerBadges = []; payLoad.producerBadges = []; delete payLoad.cpwd,
        this.FetchData(environment.apiCalls.post, environment.apiPaths.producer, environment.apiMthds.saveRupdate, payLoad, 0, true, 2).then(() => {
          if (this.result) {
            if (this.result.status == 'OK')
              localStorage.setItem('manfcturCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message),
                this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        });
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.createManufacturersForm.get('stateId')?.enable(), this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.createManufacturersForm.get('stateId')?.disable(), this.createManufacturersForm.get('districtId')?.enable(), this.createManufacturersForm.get('blockId')?.disable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.createManufacturersForm.get('stateId')?.disable(), this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.createManufacturersForm.get('stateId')?.disable(), this.createManufacturersForm.get('districtId')?.disable(), this.createManufacturersForm.get('blockId')?.disable();
  }

}