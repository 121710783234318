<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="col-xl-12 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row mt-4">
    <div class="col-xl-12">
        <div class="text-center">
            <h6>Total demand</h6>
            <small>From: Mar 2021 To: May 2021 Material tag: RI Vaccines Facility tag: SVS </small>
        </div>
    </div>
</div>
<div class="row mt-2">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered table-hover border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Sl.No.</th>
                    <th class="col-auto">City</th>
                    <th class="col-auto">Feb 2021</th>
                    <th class="col-auto">Mar 2021</th>
                    <th class="col-auto">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        Andaman and Nicobar Islands
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        Andhra Pradesh
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>
                        Arunachal Pradesh
                    </td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>