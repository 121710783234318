import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cnfg-options',
  templateUrl: './cnfg-options.component.html',
  styleUrls: ['./cnfg-options.component.css']
})

export class CnfgOptionsComponent extends BaseComponent implements OnInit {

  mdlStngs = { singleSelection: false, idField: 'childId', textField: 'childType', selectAllText: 'ALL', unSelectAllText: 'ALL',
    itemsShowLimit: 3, allowSearchFilter: true }; msdMatStngs = { singleSelection: false, idField: 'productId', textField: 'productName', 
    selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
    msdTypestngs = { singleSelection: false, idField: 'id', textField: 'type', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true };
    msdSubTypeStng = { singleSelection: false, idField: 'subId', textField: 'subType', selectAllText: 'ALL', unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; noDmns: any[] = []; noCTs: any[] = []; usrPrmsn: any = null; cnfgOptPrmsn = true;
  lblSubType = ''; lblChldType = ''; lstTyps: any[] = []; lstSubTyps: any[] = []; lstChldTyps: any[] = []; chsnCTs: any[] = [];
  lstDmns: any[] = []; phCT = ''; msdStngs = { singleSelection: false, idField: 'pranthid', textField: 'pranthname', selectAllText: 'ALL',
  unSelectAllText: 'ALL', itemsShowLimit: 3, allowSearchFilter: true }; pushToAllChilds = '0'; isPush = false; chsnDmns: any[] = [];
  cnfgOptFrm: FormGroup = this.fb.group({ typeId: [''], sub: [''], typeOfChilds: [[]], mappedPranthIds: [[]], pushToAllChilds: ['1'],
    userId: [JSON.parse(<any>localStorage.getItem('lgnUsrDtls')).userId], pranthId: [localStorage.getItem('slctdDmnId')], eg: [''], matMul : [''], 
    matDmn: [''], pshMat: [''], pshStr: ['']});
    lstMats: any[] = []; matMdl: any[] = []; strPush = false; matPushAllChilds = '0'; matPushSlctdDmns = '1'; slctdMats: any[] = []; 
    slctdDmns: any[] = []; isMatPush = false; chsnType: any[] = []; type: any[] =[]; subTypeValue: any[] = []; chsnSubType: any[] = [];

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_pc_ac') ? (this.cnfgOptPrmsn = e.action) : '') });
    }
    this.LoadTypes(), this.LoadDomains(), this.LoadMtrlsNms();
  }

  get FC() { return this.cnfgOptFrm.getRawValue(); }

  LoadTypes() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.mstrSts, environment.apiMthds.getAllPushCnfgTypes, null, 2, true, 1).
        then(() => { let res = this.result;
      if (this.result)
        this.lstTyps = this.result.data;
      else 
        this.glblSvc.onTstr('e', res.message);
    });
  }

  LoadMtrlsNms() {   
    this.lstMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.product, environment.apiMthds.getMatsByFilter.
      replace('ppp', `${this.chsnDmnId}`).replace('prdName', '').replace('bdgeName', '').replace('pageNo', '').replace('pageSize', ''), null, 0).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data)
              this.lstMats = this.result.data?.productFilterModel != null ? this.result.data?.productFilterModel : [];                
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
  }

  LoadDomains() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getChldPrnth + localStorage.getItem('slctdDmnId'),
        null, 7).then(() => { let res = this.result;
      if (this.result)
        this.lstDmns = this.result?.data ?? [];
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  onChngType(evnt: any, ctrl: string) {
    if (ctrl == 'mt') {
      this.lblChldType = '', this.lstChldTyps = [], this.lblSubType = this.lstTyps.filter(f => f.id == evnt)[0]?.type ?? '',
        this.lstSubTyps = this.lstTyps.filter(f => f.id == evnt)[0]?.subList ?? [], this.phCT = '';
    }
    else if (ctrl == 'st')
      this.lblChldType = this.lstTyps.filter(m => m.type == this.lblSubType)[0]?.subList?.filter((s: any) => s.subId == evnt)[0]?.subType ?? '',
       this.lstChldTyps = this.lstTyps.filter(m => m.type == this.lblSubType)[0]?.subList?.filter((s: any) =>
        s.subId == evnt)[0]?.childList ?? [], this.phCT = this.lblChldType == '' ? 'Select' : `Select ${this.lblChldType} Type`;
  }

  onMultiDDL(evnt: any, ctrl: string, type: string) {
    if (type == 's') {
      if (ctrl == 'd') {
        if (this.chsnDmns.filter(f => f == evnt?.pranthid).length == 0)
          this.chsnDmns.push(evnt?.pranthid);
      }
      else if(ctrl == 't') {
        if (this.chsnCTs.filter(f => f == +evnt?.childId).length == 0)
          this.chsnCTs.push(+evnt?.childId);
      }
      else if(ctrl == 'subType') {
        if (this.chsnSubType.filter(f => f == evnt?.subId).length == 0)
          this.chsnSubType.push(evnt?.subId);
      }
      else 
        if(this.chsnType.filter(f => f == +evnt?.id).length == 0)
         this.chsnType.push(evnt?.id);

    }
    else if (type == 'a') {
      if (ctrl == 'd')
        this.chsnDmns = [], this.lstDmns.forEach(e => { this.chsnDmns.push(e?.pranthid); });
      else if(ctrl == 't')
        this.chsnCTs = [], this.lstChldTyps.forEach(e => { this.chsnCTs.push(+e?.childId); });
      else if(ctrl == 'subType')
        this.chsnSubType = [], this.lstSubTyps.forEach(e => { this.chsnSubType.push(e?.subId); }); 
        else 
        this.chsnType = [], this.lstTyps.forEach(e => {this.chsnType.push(e?.id)});
    }
    else if (type == 'd') {
      if (ctrl == 'd')
        this.chsnDmns = this.chsnDmns.filter(f => f != evnt?.pranthid);
      else if(ctrl == 't')
        this.chsnCTs = this.chsnCTs.filter(f => f != +evnt?.childId);
      else if(ctrl == 'subType')
        this.chsnSubType = this.chsnSubType.filter(f => f != evnt?.subId);
      else
        this.chsnType = this.chsnType.filter(f => f != +evnt?.id);
    }
    else {
      if (ctrl == 'd')
        this.chsnDmns = [];
      else if(ctrl == 't')
        this.chsnCTs = [];
      else if(ctrl == 'subType')
        this.chsnSubType = [];
        else
        this.chsnType= [];
    }
    if(ctrl =='type'){
      if(this.chsnType?.length ==1){
        this.onChngType(this.chsnType[0], 'mt');
      }
      else
      this.lblSubType ='', this.lstSubTyps = [], this.subTypeValue = [], this.noCTs = [], this.lstChldTyps =[],this.lblChldType ='', this.phCT = '', this.chsnCTs=[];
    }
    if(ctrl =='subType'){
      if(this.chsnSubType?.length ==1){
        this.onChngType(this.chsnSubType[0], 'st');
      }
      else
      this.noCTs = [], this.lstChldTyps = [], this.lblChldType ='', this.phCT = '', this.chsnCTs =[];
    }
  }

  onPush() {
    let payLoad: any = this.cnfgOptFrm.getRawValue(), subTypes: any=[], typeIds :any = []; this.chsnSubType.forEach(e => subTypes.push(e));
    this.chsnType.forEach(e =>typeIds.push(e));
    if ((payLoad.typeId == '' || payLoad.typeId == null) || ((payLoad.sub == '' || payLoad.sub == null) && this.lstSubTyps.length > 0) ||
        (this.lstChldTyps.length > 0 && this.chsnCTs.length == 0) || (this.lstDmns.length > 0 && this.chsnDmns.length == 0)) {
      this.glblSvc.onTstr('w', 'Kindly fill Mandatory fields');
      return;
    }
    payLoad.typeOfSubs = subTypes ?? null, 
    delete payLoad.sub, delete payLoad.eg, payLoad.pushToAllChilds = payLoad.pushToAllChilds == '1', payLoad.pranthId = +payLoad.pranthId,
      payLoad.typeIds = typeIds ?? null, payLoad.mappedPranthIds = this.chsnDmns, payLoad.typeOfChilds = this.chsnCTs, delete payLoad.matMul,
      delete payLoad.matDmn, delete payLoad.pshMat, delete payLoad.pshStr, delete payLoad.typeId;
    this.FetchData(environment.apiCalls.post, environment.apiPaths.evin, environment.apiMthds.updtCnfgChldDmnsNew, payLoad, 3).then(() => {
      if (this.result) {
        this.glblSvc.onTstr(this.result?.status == 'OK' ? 's' : 'e', this.result?.message);
        if (this.result?.status == 'OK')
          this.isPush = false, this.onRst();
      }
    });
  }

  onRst() {
      this.type =[''], this.chsnType=[], this.chsnSubType= [], this.subTypeValue=[''], this.lblSubType = '', this.lstTyps=[],
       this.lblChldType = '', this.pushToAllChilds = '0', this.lstSubTyps = [], this.lstChldTyps = [], this.chsnCTs = [],
      this.chsnDmns = [], this.noCTs = [], this.noDmns = [], this.phCT = 'Select', this.cnfgOptFrm.get('typeId')?.setValue([]),
      this.cnfgOptFrm.get('sub')?.setValue([]), this.cnfgOptFrm.get('pushToAllChilds')?.setValue('1'); 
      this.LoadTypes(); this.LoadDomains(); 
  }

  onRstMat() {
    this.strPush = false, this.slctdDmns = [], this.slctdMats = [], this.noDmns = [], this.matMdl = [];
  }

  onPushMat() {
    let payLoad: any = {};
    if (this.slctdMats.length == 0 || this.slctdDmns.length == 0)
      this.glblSvc.onTstr('w', 'Kindly fill Mandatory fields');
    else {
      payLoad.childDomains = this.matPushAllChilds == '0' ? false : true, payLoad.isInventory = this.strPush, payLoad.pranthId = this.slctdDmns,
        payLoad.productId = this.slctdMats, payLoad.selectedDomain = this.matPushSlctdDmns == '1' ? true : false;
      console.log(payLoad);

      this.FetchData(environment.apiCalls.post, environment.apiPaths.product, environment.apiMthds.pshMtrl + this.chsnDmnId, payLoad).then(() => {
        if (this.result) {
          this.glblSvc.onTstr(this.result?.status == 'OK' ? 's' : 'e', this.result?.message);
          if (this.result?.status == 'OK')
            this.isMatPush = false, this.onRstMat();
        }
      });
    }
  }

  matPshChng(evnt: any, type: any) {
    if (type=='s' && evnt.target.checked == true) 
      this.matPushAllChilds = '0', this.matPushSlctdDmns = '1';
    else if (type=='a' && evnt.target.checked == true)
      this.matPushAllChilds = '1', this.matPushSlctdDmns = '0';
    else if (type=='str')
      this.strPush = evnt.target.checked;
    else 
      this.strPush = false, this.matPushAllChilds = '0', this.matPushSlctdDmns = '1';
  }


  onMultiMat(item: any, type: string, evntType: any) {
    if (type == 'm') {
      if (evntType == 'os')
        this.slctdMats.push(item.productId)
      else if (evntType == 'osa')
        this.slctdMats = [], this.lstMats.filter(e => this.slctdMats.push(e.productId))
      else if (evntType == 'ds')
        this.slctdMats = this.slctdMats.filter(s => s != item.productId)
      else
        this.slctdMats = [];
    } else {
      if (evntType == 'os')
        this.slctdDmns.push(item.pranthid)
      else if (evntType == 'osa')
        this.slctdDmns = [], this.lstDmns.filter(e => this.slctdDmns.push(e.pranthid))
      else if (evntType == 'ds')
        this.slctdDmns = this.slctdDmns.filter(s => s != item.pranthid)
      else
        this.slctdDmns = [];
    }
  }

}