<div class="modal-header">
    <h4 class="modal-title">Ship</h4>
    <button type="button" class="btn btn-light btn-sm close" aria-label="Close" (click)="packageDetails=[];actMdl.close('close')">
        <span class="f-16" aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="shipCreateForm" autocomplete="off">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="Cancle" role="tabpanel" aria-labelledby="Cancle-tab">
                <div class="form-group col-xl-12">
                    <label for="textBox">Next status: </label>
                    <span class="ms-3">Shipped</span>
                </div>
                <div class="col-sm-6 mb-3 mt-4">
                    <label>Capture Lat & Long</label>&nbsp;&nbsp;
                    <input class="form-check-input" type="radio" name="isReqLL" value="1" (click)="isReqLL='1'"
                        [checked]="isReqLL=='1'">&nbsp;&nbsp;<label class="form-check-label">Yes</label>&nbsp;&nbsp;
                    <input class="form-check-input" type="radio" name="isReqLL" value="0" (click)="isReqLL='0'"
                        [checked]="isReqLL=='0'">&nbsp;&nbsp;<label class="form-check-label">No</label><br />
                    <!-- <div class="highTemp font-wt" *ngIf="isReqLL == '1'">Kindly provide Location access to Create Shippment..!</div> -->
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">Issue reference # <span
                            class="text-danger" *ngIf="issRefFlag">*</span></label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control form-control-sm"
                            formControlName="orderReferenceNo" placeholder="Issue reference #"
                            [attr.disabled]="(orderStatusData.reference != null && orderStatusData.reference != '') ? true : null"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.orderReferenceNo.errors }" />
                        <div *ngIf="isSbmtd && fc.orderReferenceNo.errors" class="invalid-feedback">
                            <div *ngIf="fc.orderReferenceNo.errors.required">Please enter Issue reference #</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="textBox">Is customer pickup: </label>&nbsp;&nbsp;
                    <div class="form-check form-check-inline mt-2">
                        <input class="form-check-input" type="radio" id="rbYN1" name="customerDelivery" [value]="true"
                            formControlName="customerDelivery">
                        <label class="form-check-label" for="customerDelivery">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="rbYN2" name="customerDelivery" [value]="false"
                            formControlName="customerDelivery">
                        <label class="form-check-label" for="customerDelivery">No</label>
                    </div>
                    <!-- <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="Yes"
                            formControlName="customerDelivery">
                        <label class="form-check-label" for="inlineCheckbox1">Yes</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="No"
                            formControlName="customerDelivery">
                        <label class="form-check-label" for="inlineCheckbox2">No</label>
                    </div> -->
                </div>
                <div class="col-sm-12 mb-2">
                    <div class="col-xl-3">
                        <label for="inputEmail3" class="col-form-label">Comment</label>
                        <textarea class="form-control form-control-sm taRS" formControlName="remarks"></textarea>
                    </div>
                </div>
                <hr />
                <h6>Consignment</h6>
                <div *ngIf="(usrLgnDtls.roleId == 7 && usrLgnDtls.roleName == 'Manufacturer') || (orderStatusData?.orderTypeId == 3)">
                    <div class="col-sm-12 mb-2">
                        <div class="row">
                            <div class="col-sm-3">
                                <label for="inputEmail4" class="col-form-label">Package Type<span class="text-danger">*</span></label>
                                <select class="form-select form-select-sm" [(ngModel)]="packageTypeId" [ngModelOptions]="{standalone: true}">
                                    <option value="">Select</option>
                                    <option *ngFor="let i of lstPkgeTyp" value="{{i.id}}">{{i.packageName}}</option>
                                </select>
                            </div>  
                            <div class="col-sm-3 mb-2">
                                <label for="inputEmail4" class="col-form-label">Number of packages</label>
                                <input type="text" class="form-control form-control-sm" placeholder="Number of packages" formControlName="quantity"
                                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                            </div>
                            <div class="col-sm-6 float-end mt-4 pt-2">
                                <button type="button" class="btn btn-sm btn-success" (click)="onActn('a')"><i class="fa fa-plus" aria-hidden="true"></i></button>
                            </div>
                        </div>  
                    </div>
                    <div class="card border-0" *ngIf="packageDetails.length != 0">
                        <div class="card-body">
                            <div class="table_spacing table-responsive mt-3">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Package Type</th>
                                            <th>Number of packages</th>
                                            <th></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="packageDetails.length != 0">
                                        <tr *ngFor="let x of packageDetails; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{x.packageName}}</td>
                                            <td>{{x.quantity}}</td>
                                            <td class="text-center"><a (click)="onActn('d', i)"><i class="fa fa-trash" aria-hidden="true"></i></a></td>
                                        </tr>
                                    </tbody>
                                    <!-- <tbody *ngIf="lstTags.length == 0">
                                        <tr>
                                            <td colspan="2" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody> -->
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="(usrLgnDtls.roleId != 7 && usrLgnDtls.roleName != 'Manufacturer') && (orderStatusData?.orderTypeId != 3)">
                    <div class="col-sm-12 mb-2">
                        <label for="inputEmail4" class="col-form-label">Number of packages</label>
                        <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                            placeholder="Number of packages" formControlName="packageNo" /></div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="inputEmail3" class="col-form-label">Total weight (kg)</label>
                        <div class="col-sm-3"><input type="text" class="form-control form-control-sm"
                                placeholder="Total weight (kg)" formControlName="wtkg" /> </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="inputEmail3" class="col-form-label">Volume (inches)</label>
                        <div class="row">
                            <div class="col-sm-3 me-1">
                                <input type="text" class="form-control form-control-sm" id="" placeholder="Length"
                                    formControlName="length">
                            </div>
                            <div class="col-sm-3 me-1">
                                <input type="text" class="form-control form-control-sm" id="" placeholder="Width"
                                    formControlName="breadth">
                            </div>
                            <div class="col-sm-3 me-1">
                                <input type="text" class="form-control form-control-sm" id="" placeholder="Height"
                                    formControlName="height">
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <label for="inputEmail3" class="col-form-label">Value</label>
                        <div class="col-sm-3"><input type="text" class="form-control form-control-sm" placeholder="Value"
                                formControlName="packageQuantity" />
                        </div>
                    </div>
                </div>
                <hr />
                <h6>Tracking details</h6>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">Tracking #</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control form-control-sm" placeholder="" formControlName="statusId" />
                    </div>
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">
                        Estimated date of arrival <span class="text-danger" *ngIf="arrvDatFlag">*</span>
                    </label>
                    <div class="col-sm-3">
                        <div class="input-group mb-3">
                            <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                [minDate]="dtToDay" class="form-control form-control-sm optinbg bsDP-bg"
                                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', 
                                    showWeekNumbers: false, isAnimated: true, adaptivePosition: true }"
                                #dpEDA="bsDatepicker" formControlName="arrivalDate"
                                onkeypress='return event.charCode == 8' readonly
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.arrivalDate.errors }">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpEDA.toggle()"
                                    [attr.aria-expanded]="dpEDA.isOpen">
                                    <i class="fa fa-calendar"></i>
                                </button>
                                <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                    (click)="dpEDA.hide();clearBSDate('arrivalDate')">
                                    <i class="fa fa-times"></i>
                                </button>
                            </div>
                            <div *ngIf="isSbmtd && fc.arrivalDate.errors" class="invalid-feedback">
                                <div *ngIf="fc.arrivalDate.errors.required">Please select Estimated date of arrival</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-2">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" id="cbEDA" (click)="isChkd=!isChkd"
                            [ngClass]="{ 'is-invalid': isSbmtd && this.shipCreateForm.get('arrivalDate')?.value != '' && !isChkd }">&nbsp;
                            I Confirm that the Estimated date of arrival is correctly filled <span class="text-danger" *ngIf="this.shipCreateForm.get('arrivalDate')?.value != ''">*</span>
                        <div *ngIf="isSbmtd && this.shipCreateForm.get('arrivalDate')?.value != '' && !isChkd" class="invalid-feedback">
                            Please click on the confirmation checkbox
                        </div>
                    </div>
                    <!-- <label for="textBox">I Confirm that the Estimated date of arrival is correctly filled </label> -->
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">Transporter Name<span
                        class="text-danger" *ngIf="trnsPrtrFlg">*</span></label>
                    <div class="col-sm-3">
                        <!-- <input type="text" class="form-control form-control-sm"
                            placeholder="Search/Enter Transporter" formControlName="carrierName" />-->
                        <ng-template #cstmStore let-model="item">
                            <span class="font-weight-bold">{{model.carrierName}}</span> <br />
                            <span class="fs-12">{{model.location}}</span>
                        </ng-template>
                        <input name="store" type="text" placeholder="Search/Enter Transporter" id="Store"
                            formControlName="carrierName" [typeahead]="carrierLst" [typeaheadScrollable]="true"
                            [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="carrierName"
                            [adaptivePosition]="true" class="form-control form-control-sm" [typeaheadScrollable]="true"
                            (typeaheadOnSelect)="sctTransPName($event)" [typeaheadOptionsLimit]="500"
                            [ngClass]="{ 'is-invalid': isSbmtd && fc.carrierName.errors }"/>
                            <!-- (change)="sctTransPName($event, 'chng')" -->
                        <div *ngIf="isSbmtd && fc.carrierName.errors" class="invalid-feedback">
                            <div *ngIf="fc.carrierName.errors.required">Transporter Name is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">Phone number</label>
                    <div class="col-sm-3">
                        <input class="form-control form-control-sm" placeholder="" formControlName="driverPhone"
                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="10"
                            type="text" [ngClass]="{ 'is-invalid': isSbmtd && fc.driverPhone.errors }" />
                        <div *ngIf="isSbmtd && fc.driverPhone.errors" class="invalid-feedback">
                            <div *ngIf="fc.driverPhone.errors.pattern">Please enter valid Phone number</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 mb-2">
                    <label for="inputEmail4" class="col-form-label">Vehicle details</label>
                    <div class="col-sm-3">
                        <input type="text" class="form-control form-control-sm" placeholder="" formControlName="vehicleInfo" />
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary me-2" (click)="isSbmtd=true;onChkLL();">Submit</button>
    <button type="button" class="btn btn-sm btn-light" (click)="packageDetails=[];actMdl.close('close')">Cancel</button>
</div>