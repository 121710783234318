import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-cncl-order',
  templateUrl: './cncl-order.component.html',
  styleUrls: ['./cncl-order.component.css']
})

export class CnclOrderComponent extends BaseComponent implements OnInit {

  @Input() bkngId = ''; @Input() page = ''; isCncl = false; cnclForm = this.fb.group({ bookingId: [''], comments: [''], others: [''],
    reason: [''], sourcePage: [], sourceType: ['2'], userId: [this.usrLgnDtls.userId], latitude: [null], longitude: [null] });
  lstCnclRsns: any[] = []; isOthr = false; isRsnMand = false; isReqLL = '0';

  constructor(public glblSvc: GlobalService,
    public fb: FormBuilder,
    public actMdl: NgbActiveModal) {
    super(glblSvc);
    this.glblSvc.sbjctMstrData.subscribe(s => { this.lstCnclRsns = s.rsnsLst.filter((f: any) => f.reasonType === environment.cnfgTrnsRsnType.cancel_indent),
      this.isRsnMand = this.lstCnclRsns.length > 0 ? this.lstCnclRsns[0].isReasonMandatory : false;
      if (this.isRsnMand)
        this.cnclForm.get('reason')?.setValidators(Validators.required);
      else
        this.cnclForm.get('reason')?.setValidators(null);
      this.cnclForm.get('reason')?.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.cnclForm.get('bookingId')?.setValue(this.bkngId), this.cnclForm.get('sourcePage')?.setValue(this.page);
  }

  get fc() { return this.cnclForm.controls; }

  onChngRsn() {
    let rsnVal: string = this.cnclForm.get('reason')?.value;
    this.cnclForm.get('others')?.setValue('');
    if (rsnVal != '' && rsnVal.toLowerCase().includes('other'))
      this.cnclForm.get('others')?.setValidators(Validators.required), this.isOthr = true;
    else
      this.cnclForm.get('others')?.setValidators(null);
    this.cnclForm.get('others')?.updateValueAndValidity();
  }

  onChkLL() {
    if (this.isReqLL == '1') {
      this.GetLatLon(), setTimeout(() => {
        if (this.usrLat != undefined && this.usrLon != undefined)
          this.onCncl();
        else {
          setTimeout(() => {
            if (this.usrLat == undefined || this.usrLon == undefined)
              this.glblSvc.onTstr('i', 'Kindly provide Location access to Cancel Indent..!');  
          }, 2000);
        }
      }, 500);
    }
    else
      this.onCncl();
  }

  onCncl() {
    this.isCncl = true, this.checkFormValidations(this.cnclForm);
    let mthd = '';
    

    if (this.cnclForm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else {
      if (this.bkngId != '' && this.page != '') {
        let payLoad = this.cnclForm.getRawValue();
        payLoad.latitude = (this.usrLat != '' && this.usrLat != null && this.usrLat != undefined) ? +this.usrLat : null,
          payLoad.longitude = (this.usrLon != '' && this.usrLon != null && this.usrLon != undefined) ? +this.usrLon : null,
          payLoad.reason = payLoad.others == '' ? payLoad.reason : payLoad.others, delete payLoad.others;

          if (window.location.href.includes('prchs-ord-dtls'))
            mthd = environment.apiPaths.prchsShip, payLoad.consigneeBookingId = payLoad.bookingId, delete payLoad.bookingId;
          else 
            mthd = environment.apiPaths.bookings;

          this.FetchData(environment.apiCalls.post, mthd, environment.apiMthds.cnclOrdr, payLoad).then(() => { let res = this.result;
          if (this.result) {
            if (this.result.status === 'OK')
              this.glblSvc.onTstr('s', this.result.message), this.actMdl.close('done');
            else
              this.glblSvc.onTstr('i', this.result.message);
          }
          else
            this.glblSvc.onTstr('e', res.message)
        });
      }
    }
  }

}