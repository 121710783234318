<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link active" id="demand-tab" data-bs-toggle="tab" href="#demand"
                    role="tab" aria-controls="demand" aria-selected="true">Demand</a></li>
            <li class="nav-item"><a class="nav-link" id="Backorder-tab" data-bs-toggle="tab" href="#Backorder"
                    role="tab" aria-controls="Backorder" aria-selected="false">Backorder</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade show active" id="demand" role="tabpanel" aria-labelledby="demand-tab">
                <div class="row p-0 mt-2">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-12">
                                <form>
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox">Facility</label>
                                            <input type="text" placeholder="Filter by Facility" class="form-control"
                                                id="Facility">
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <label for="textBox">Material</label>
                                            <input type="text" placeholder="Filter by Material" class="form-control"
                                                id="Material">
                                        </div>
                                        <!-- Facility Category -->
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Facility Category:</label>
                                            <select id="inputState" class="form-select">
                                                <option value="AL">All</option>
                                                <option value="WY">CCP</option>
                                                <option value="WY">DIVS</option>
                                                <option value="WY">DVS</option>
                                                <option value="WY">GMSD</option>
                                                <option value="WY">Manufacturer</option>
                                                <option value="WY">RVS</option>
                                                <option value="WY">SVS</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <!-- Material Category -->
                                            <label for="textBox">Material Category:</label>
                                            <select id="inputState1" class="form-select">
                                                <option value="AL">All</option>
                                                <option value="WY">CAMPAIGN</option>
                                                <option value="WY">HIV</option>
                                                <option value="WY">Malaria</option>
                                                <option value="WY">OPEN Vials</option>
                                                <option value="WY">RI Vaccines</option>
                                                <option value="WY">Syringes</option>
                                                <option value="WY">TB</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 mt-2 mb-2">
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        Indent type
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a class="dropdown-item">Indents (issue)</a></li>
                                        <li><a class="dropdown-item">Indents (receipt)</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-6 mt-3 mb-2">
                                <input type="checkbox" id="scales" name="scales" checked>
                                <label class="ms-2" for="scales">Filter by date of actual transaction</label>
                            </div>
                            <div class="col-xl-3 mt-3 mb-2">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <!-- demand panel start -->
                <div class="row mt-3">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-6 mb-2">
                                <!-- <span style="display: inline-block;" class="me-2">
                                    <label tyle="display: inline-block;">Filter</label>
                                </span>
                                <span style="display: inline-block;">
                                    <select class="form-select form-select-sm">
                                        <option>5</option>
                                        <option>10</option>
                                    </select>
                                </span> -->
                            </div>
                        </div>
                        <div class="table-responsive table_spacing table-row-spacing">
                            <table class="table table-striped table-sm table-bordered" id="table_detail">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Item No.</th>
                                        <th>Issuing Facility</th>
                                        <th>Material</th>
                                        <th class="text-center">Ordered</th>
                                        <th class="text-center">Allocated</th>
                                        <th class="text-center">Shipped</th>
                                        <th class="text-center">Yet to ship</th>
                                        <th class="text-center">Total stock</th>
                                        <th class="text-center">Available stock</th>
                                        <th class="text-center">In-transit stock</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">1</td>
                                        <td><a>BEL</a>
                                            <div class="small">New Delhi, Capital Territory of Delhi</div>
                                        </td>
                                        <td><a>bOPV (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">2</td>
                                        <td><a>BIBCOL</a>
                                            <div class="small">Srinagar, Srinagar, Jammu and Kashmir</div>
                                        </td>
                                        <td><a>BCG (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">3</td>
                                        <td><a>Test GMSD Chennai</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>BCG (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">4</td>
                                        <td><a>Test GMSD Chennai</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">5</td>
                                        <td><a>Test GMSD Chennai</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">6</td>
                                        <td><a>Test GMSD Chennai</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ps-0 pe-0 pt-3 pb-3">
                            <div class="col-lg-6 col-xxl-6 pb-3">
                            </div>
                            <div class="col-lg-6 col-xxl-6 pb-3">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination justify-content-xl-end  pagination-primary ">
                                        <li class="page-item"><a class="page-link" aria-label="Previous"
                                                data-bs-original-title="" title=""><span
                                                    aria-hidden="true">«</span><span class="sr-only">Previous</span></a>
                                        </li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">1</a></li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">2</a></li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">3</a></li>
                                        <li class="page-item"><a class="page-link" aria-label="Next"
                                                data-bs-original-title="" title=""><span
                                                    aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="Backorder" role="tabpanel" aria-labelledby="Backorder-tab">
                <div class="row p-0 mt-2">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-12">
                                <form>
                                    <div class="form-row row mb-2">
                                        <div class="form-group col-xl-3">
                                            <label for="textBox">Facility</label>
                                            <input type="text" placeholder="Filter by Facility" class="form-control"
                                                id="Facility">
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <label for="textBox">Material</label>
                                            <input type="text" placeholder="Filter by Material" class="form-control"
                                                id="Material">
                                        </div>
                                        <!-- Facility Category -->
                                        <div class="form-group col-md-3">
                                            <label for="textBox">Facility Category:</label>
                                            <select id="inputState" class="form-select">
                                                <option value="AL">All</option>
                                                <option value="WY">CCP</option>
                                                <option value="WY">DIVS</option>
                                                <option value="WY">DVS</option>
                                                <option value="WY">GMSD</option>
                                                <option value="WY">Manufacturer</option>
                                                <option value="WY">RVS</option>
                                                <option value="WY">SVS</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-xl-3">
                                            <!-- Material Category -->
                                            <label for="textBox">Material Category:</label>
                                            <select id="inputState1" class="form-select">
                                                <option value="AL">All</option>
                                                <option value="WY">CAMPAIGN</option>
                                                <option value="WY">HIV</option>
                                                <option value="WY">Malaria</option>
                                                <option value="WY">OPEN Vials</option>
                                                <option value="WY">RI Vaccines</option>
                                                <option value="WY">Syringes</option>
                                                <option value="WY">TB</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-3 mt-2 mb-2">
                                <div class="dropdown">
                                    <button class="btn btn-primary dropdown-toggle" type="button"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        Order type
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a class="dropdown-item">Indents (issue)</a></li>
                                        <li><a class="dropdown-item">Indents (receipt)</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-6 mt-3 mb-2">
                                <input type="checkbox" id="scales" name="scales" checked>
                                <label class="ms-2" for="scales">Filter by date of actual transaction</label>
                            </div>
                            <div class="col-xl-3 mt-3 mb-2">
                                <div class="float-xl-end">
                                    <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                                    <button class="btn btn-sm btn-primary mb-2">Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <!-- demand panel start -->
                <div class="row mt-3">
                    <div class="col-xl-12 col-sm-12 col-xs-12">
                        <div class="row">
                            <div class="col-xl-6 mb-2">
                                <!-- <span style="display: inline-block;" class="me-2">
                                    <label tyle="display: inline-block;">Filter</label>
                                </span>
                                <span style="display: inline-block;">
                                    <select class="form-select form-select-sm">
                                        <option>5</option>
                                        <option>10</option>
                                    </select>
                                </span> -->
                            </div>
                        </div>
                        <div class="table-responsive table-row-spacing">
                            <table class="table table-striped table-sm table-bordered" id="table_detail">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Item No.</th>
                                        <th>Issuing Facility</th>
                                        <th>Material</th>
                                        <th class="text-center">Ordered</th>
                                        <th class="text-center">Allocated</th>
                                        <th class="text-center">Shipped</th>
                                        <th class="text-center">Yet to ship</th>
                                        <th class="text-center">Total stock</th>
                                        <th class="text-center">Available stock</th>
                                        <th class="text-center">In-transit stock</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">1</td>
                                        <td><a>SII</a>
                                            <div class="small">New Delhi, Capital Territory of Delhi</div>
                                        </td>
                                        <td><a>bOPV (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">220600</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5068370</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">2</td>
                                        <td><a>SII</a>
                                            <div class="small">Srinagar, Srinagar, Jammu and Kashmir</div>
                                        </td>
                                        <td><a>BCG (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">3</td>
                                        <td><a>SII</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>BCG (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">1000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">5000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">4</td>
                                        <td><a>Jabalpur RVS</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">11060</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">45000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">5</td>
                                        <td><a>Jabalpur RVS</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                    <tr>
                                        <td><a><i class="fa fa-external-link" aria-hidden="true"></i></a></td>
                                        <td data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">6</td>
                                        <td><a>Jabalpur RVS</a>
                                            <div class="small">Chennai, Chennai, Tamil Nadu</div>
                                        </td>
                                        <td><a>bOPV CAMPAIGN (dose)</a></td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">20000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">100000</td>
                                        <td class="text-center" data-bs-toggle="modal"
                                            data-bs-target="#Tab-row-Backdrop">0</td>
                                        <td class="text-center"><a [routerLink]="" class="btn btn-sm btn-primary"
                                                data-bs-toggle="modal" data-bs-target="#allocation_note">Clear
                                                allocations</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ps-0 pe-0 pt-3 pb-3">
                            <div class="col-lg-6 col-xxl-6 pb-3">
                            </div>
                            <div class="col-lg-6 col-xxl-6 pb-3">
                                <!-- Pagination start -->
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination justify-content-xl-end  pagination-primary ">
                                        <li class="page-item"><a class="page-link" aria-label="Previous"
                                                data-bs-original-title="" title=""><span
                                                    aria-hidden="true">«</span><span class="sr-only">Previous</span></a>
                                        </li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">1</a></li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">2</a></li>
                                        <li class="page-item"><a class="page-link" data-bs-original-title=""
                                                title="">3</a></li>
                                        <li class="page-item"><a class="page-link" aria-label="Next"
                                                data-bs-original-title="" title=""><span
                                                    aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="allocation_note">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Note</h6>
            </div>
            <div class="modal-body">
                Are you sure you want to clear allocations?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary">Ok</button>
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<!-- Table row popup -->
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3 pb-0" id="tab" role="tablist">
                    <li class="nav-item"><a class="nav-link f-14 active" id="Indents-tab" data-bs-toggle="tab"
                            href="#Indents" role="tab" aria-controls="top-Indents" aria-selected="true">
                            <div class="modal-title">Indents (issue)</div>
                        </a></li>
                    <li class="nav-item"><a class="nav-link f-14" id="Releases-tab" data-bs-toggle="tab"
                            href="#Releases" role="tab" aria-controls="top-Releases" aria-selected="false">
                            <div class="modal-title">Releases</div>
                        </a></li>
                </ul>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-1">
                <div class="tab-content mt-3" id="tabContent">
                    <!-- tab 1 content -->
                    <div class="tab-pane fade show active" id="Indents" role="tabpanel" aria-labelledby="Indents-tab">
                        <div class="card mb-2 p-0 border-0 rounded-0">
                            <div class="card-body p-2">
                                <div class="table-responsive table-row-spacing">
                                    <table class="table table-striped table-sm table-bordered" id="table_detail">
                                        <thead>
                                            <tr>
                                                <th>Item No.</th>
                                                <th>Receiving Facility</th>
                                                <th class="text-center">Indent Id</th>
                                                <th class="text-center">Ordered</th>
                                                <th class="text-center">Allocated</th>
                                                <th class="text-center">Yet to allocated</th>
                                                <th class="text-center">Shipped</th>
                                                <th class="text-center">Yet to ship</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">total stock</th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td><a>Test GMSD Mumbai</a>
                                                    <div class="small">Mumbai, Maharashtra</div>
                                                </td>
                                                <td class="text-center"><a>1996383</a></td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">Confirmed</td>
                                                <td class="text-center"
                                                    style="background-color: rgba(81,187,37,0.5) !important; z-index: 1;">
                                                    676330 <div class="small">(100,325)</div>
                                                </td>
                                                <td class="text-center"><a [routerLink]=""
                                                        class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                        data-bs-target="#allocation_content">Allocate</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><a>Test GMSD Mumbai</a>
                                                    <div class="small">Mumbai, Maharashtra</div>
                                                </td>
                                                <td class="text-center"><a>1581210</a></td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">Confirmed</td>
                                                <td class="text-center"
                                                    style="background-color: rgba(81,187,37,0.5) !important; z-index: 1;">
                                                    676330 <div class="small">(100,325)</div>
                                                </td>
                                                <td class="text-center"><a [routerLink]=""
                                                        class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                        data-bs-target="#allocation_content">Allocate</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tab 2 content start-->
                    <div class="tab-pane fade" id="Releases" role="tabpanel" aria-labelledby="Releases-tab">
                        <div class="card mb-2 p-0 border-0 rounded-0">
                            <div class="card-body p-2">
                                <div class="table-responsive table-row-spacing">
                                    <table class="table table-striped table-sm table-bordered" id="table_detail">
                                        <thead>
                                            <tr>
                                                <th>Item No.</th>
                                                <th>Receiving Facility</th>
                                                <th class="text-center">Indent Id</th>
                                                <th class="text-center">Ordered</th>
                                                <th class="text-center">Allocated</th>
                                                <th class="text-center">Yet to allocated</th>
                                                <th class="text-center">Shipped</th>
                                                <th class="text-center">Yet to ship</th>
                                                <th class="text-center">Status</th>
                                                <th class="text-center">total stock</th>
                                                <th class="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td><a>Test GMSD Mumbai</a>
                                                    <div class="small">Mumbai, Maharashtra</div>
                                                </td>
                                                <td class="text-center"><a>1996383</a></td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">300</td>
                                                <td class="text-center">Pending</td>
                                                <td class="text-center"
                                                    style="background-color: rgba(81,187,37,0.5) !important; z-index: 1;">
                                                    676330 <div class="small">(100,325)</div>
                                                </td>
                                                <td class="text-center"><a [routerLink]=""
                                                        class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                        data-bs-target="#allocation_note">allocate</a></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><a>Test GMSD Mumbai</a>
                                                    <div class="small">Mumbai, Maharashtra</div>
                                                </td>
                                                <td class="text-center"><a>1581210</a></td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">0</td>
                                                <td class="text-center">20000</td>
                                                <td class="text-center">Confirmed</td>
                                                <td class="text-center"
                                                    style="background-color: rgba(81,187,37,0.5) !important; z-index: 1;">
                                                    676330 <div class="small">(100,325)</div>
                                                </td>
                                                <td class="text-center"><a [routerLink]=""
                                                        class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                        data-bs-target="#allocation_note">allocate</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- modal footer -->
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 float-start">
                        <span class="label label-warning p-1 me-2"
                            style="background-color: rgba(214, 77, 207, 0.5) !important; z-index: 1;">Zero Stock</span>
                        <span class="label label-primary p-1 me-2"
                            style="background-color: rgba(248, 214, 43, 0.5) !important; z-index: 1;">Min</span>
                        <span class="label label-success p-1"
                            style="background-color: rgba(81,187,37,0.5) !important; z-index: 1;">Max</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Table row popup2 -->
<div class="modal" id="allocation_content">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h6 class="modal-title">Recent zero stock incidents for RVV dropper (piece)</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="table-responsive mt-2">
                            <table class="table table-striped table-sm table-bordered">
                                <thead>
                                    <tr>
                                        <th>Batch</th>
                                        <th>Batch expiry</th>
                                        <th>Available stock</th>
                                        <th>Allocated</th>
                                        <th>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>12354</td>
                                        <td>01/06/2020</td>
                                        <td class="text-center">4478870</td>
                                        <td class="text-center">0</td>
                                        <td class="text-center">0
                                            <span class="col-sm-3"><input type="text" placeholder="" id="Facility"
                                                    class="form-control" title=""></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12354</td>
                                        <td>01/04/2020</td>
                                        <td class="text-center">4478870</td>
                                        <td class="text-center">0</td>
                                        <td class="text-center">0
                                            <span class="col-sm-3"><input type="text" placeholder="" id="Facility"
                                                    class="form-control" title=""></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>12354</td>
                                        <td>01/01/2020</td>
                                        <td class="text-center">4478870</td>
                                        <td class="text-center">0</td>
                                        <td class="text-center">0
                                            <span class="col-sm-3"><input type="text" placeholder="" id="Facility"
                                                    class="form-control" title=""></span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary">Edit</button>
                <button type="button" class="btn btn-sm btn-primary">Save</button>
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>