import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-demand-overview',
  templateUrl: './demand-overview.component.html',
  styleUrls: ['./demand-overview.component.css']
})
export class DemandOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
