import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { mapTo } from 'rxjs/operators';
import { Observable, merge, of, fromEvent } from 'rxjs';
import { ConnectionService } from 'ng-connection-service';

import { GlobalService } from 'src/app/Services/global.service';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  online$: Observable<boolean> | any; isCnctnAvlble: boolean | any; ldrImg = `${window.origin}/assets/img/ldr.gif`; elem;

  constructor(private route: Router,
    private glblSvc: GlobalService,
    private connSvc: ConnectionService,
    @Inject(DOCUMENT) private document: any) {
    this.checkNetwork(), this.online$ = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').
      pipe(mapTo(false))), this.checkNetworkNetworkStatus(), this.elem = document.documentElement;
    /* glblSvc.sbjctSpnr.next(true), setTimeout(() => { glblSvc.sbjctSpnr.next(false); }, 5000);
    setTimeout(() => { glblSvc.onTstr('d', 'jQcode'), $('#btnLgnPg').click(); }, 10000); */
  }

  onLgnRD() {
    this.route.navigateByUrl(''); // window.location.reload();
  }

  clsRprtsRouteMdl(rtePth: string) {
    $('#clsRptsMdl')?.click(), this.route.navigate([rtePth]);
  }

  checkNetwork() {
    this.connSvc.monitor().subscribe(isCnctd => {
      if (isCnctd)
        this.glblSvc.onTstr('s', 'Network is back, please continue..');
      else
        this.glblSvc.onTstr('e', 'No network connection found');
    });
  }

  public checkNetworkNetworkStatus() {
    this.online$.subscribe((val: any) => this.isCnctnAvlble = val);
  }

  onDC() {
    if (localStorage.getItem('isFS') == null)
      localStorage.setItem('isFS', '0');
    if (localStorage.getItem('isFS') == '0')
      this.setFS(), localStorage.setItem('isFS', '1');
    else
      this.resetFS(), localStorage.setItem('isFS', '0');
  }

  setFS() {
    if (this.elem.requestFullscreen)
      this.elem.requestFullscreen();
    else if (this.elem.mozRequestFullScreen) /* Firefox */
      this.elem.mozRequestFullScreen();
    else if (this.elem.webkitRequestFullscreen) /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    else if (this.elem.msRequestFullscreen) /* IE/Edge */
      this.elem.msRequestFullscreen();
  }

  resetFS() {
    if (this.document.exitFullscreen)
      this.document.exitFullscreen();
    else if (this.document.mozCancelFullScreen) /* Firefox */
      this.document.mozCancelFullScreen();
    else if (this.document.webkitExitFullscreen) /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    else if (this.document.msExitFullscreen) /* IE/Edge */
      this.document.msExitFullscreen();
  }

}