import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
declare let $:any
@Component({
  selector: 'evin-db-inventory',
  templateUrl: './db-inventory.component.html',
  styleUrls: ['./db-inventory.component.css']
})

export class DbInventoryComponent extends BaseComponent implements OnInit {

  constructor(public glblSvc: GlobalService) {
    super(glblSvc);
  }

  ngOnInit(): void {
  
  }

}
