<div class="card p-3 border-0">
    <div class="p-0 mt-2 mb-2">
        <div>
            <div class="text-left">Created by {{trnspterDetails?.createdByName}}</div>
        </div>
    </div>
    <hr>
    <div class="mb-2">
        <p class="mb-0">Created on {{trnspterDetails?.createdOn | date: "dd-MM-yyyy HH:mm:ss"}} by <a>{{trnspterDetails?.createdByName}}</a>, 
            Last updated on {{trnspterDetails?.updatedOn | date: "dd-MM-yyyy HH:mm:ss"}} by <a>{{trnspterDetails?.updatedByName}}</a></p>
    </div>
    <div class="row">
        <div class="col-sm-8">
            <div class="card p-0 mb-3">
                <div class="card-header shadow-sm p-3 pb-1 pt-2">
                    <h6>Transporter information</h6>
                </div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-sm-4">Transporter name:</div>
                        <div class="col-sm-8">{{trnspterDetails?.carrierName !=null ? trnspterDetails?.carrierName : ''}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Description:</div>
                        <div class="col-sm-8">{{trnspterDetails?.description}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Mobile phone:</div>
                        <div class="col-sm-8">{{trnspterDetails?.contactNo}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Country:</div>
                        <div class="col-sm-8">{{trnspterDetails?.country}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">State:</div>
                        <div class="col-sm-8">{{trnspterDetails?.state}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">District:</div>
                        <div class="col-sm-8">{{trnspterDetails?.district}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Taluk/Block:</div>
                        <div class="col-sm-8">{{trnspterDetails?.block}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Village/City:</div>
                        <div class="col-sm-8">{{trnspterDetails?.city}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Street address:</div>
                        <div class="col-sm-8">{{trnspterDetails?.address}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Zip/PIN code:</div>
                        <div class="col-sm-8">{{trnspterDetails?.pinCode}}</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Vehicle details:</div>
                        <div class="col-sm-8">{{trnspterDetails?.vehicleDetails}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>