import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css']
})

export class FacilityComponent extends BaseComponent implements OnInit {

  paramTab: any; facltyHead = "Create Facility"; prmsns: any = null; fcltyLst: boolean = true; cratFcltyBulk: boolean = true;

  constructor(public glblSvc: GlobalService
    , private route: ActivatedRoute
    , private router: Router) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
    this.glblSvc.sbjctFacStrId.subscribe(s => {
      if (s != '')
        this.facltyHead = "Edit Facility";
      else
        this.facltyHead = "Create Facility";
    })
  }

  ngOnInit(): void {
    this.glblSvc.sbjctFacStrId.next('');
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_fc_li')
     this.fcltyLst = e?.action;
    if(e?.menuCode =='mm_st_fc_cf-bu')
     this.cratFcltyBulk = e?.action;
  });
  }

  onTabSelection(tabName: any) {
    if (tabName != 'new')
      this.glblSvc.sbjctFacStrId.next('');
    this.glblSvc.sbjctFacStrId.subscribe(s => {
      if (s != '')
        this.facltyHead = "Edit Facility";
      else
        this.facltyHead = "Create Facility";
    }), this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}