import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as FusionCharts from 'fusioncharts';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any;
declare let echarts: any;

@Component({
  selector: 'evin-stock-report-view',
  templateUrl: './stock-report-view.component.html',
  styleUrls: ['./stock-report-view.component.css']
})

export class StockReportViewComponent extends BaseComponent implements OnInit {
  
  lstMats: any[] = []; lstAllMats: any[] = []; pageEntrs = ''; crntPage = 1; pageSize = environment.tblPageSize; totalItems: any; lstGrid: any;
  isShown :boolean = false; isHide: boolean = true; lstGrids: any[] = []; invStkRprtFrm = this.formBuilder.group({ storeId: [null], state: [null],
    expireBefore: [], abnormalityType: [''], district: [null], pranthId: [this.chsnDmnId],
    productId: [null], duration:[null], includeStoreBadge: [null], includeProductBadge: [null], excludeStoreBadge: [null], country: [null],
    block: [null] }); lstFacs: any[] = []; slctdStrId: any; slctPrdId: any; slctdStr: any; lstMatTags: any[] = []; lstFacTags: any[] = [];
  cntryLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; noRcrdsTxt = environment.TblNoRcrds; dropdownList = []; slctdExcludProd: any;
  selectedItems = []; dropdownSettings = {}; slctdIncldFac: any[] = []; slctdExcldFac: any[] = []; slctdIncldProd: any[] = [];
  lstSlctdFacTags: any[] = []; lstShowFacTags: any[] = []; lstSlctdMatTags: any[] = []; lstShowMatTags: any[] = []; slctdMat: any[] = [];
  slctdFac: any[] = []; isFacAll = false; isMatAll = false;

  constructor(public glblSvc: GlobalService
    , private router: Router
    , private formBuilder: FormBuilder) {
    super(glblSvc);
    this.glblSvc.sbjctStrList.subscribe(s => { this.lstFacs = s; }), this.glblSvc.sbjctBdgsList.subscribe(s => { this.lstMatTags =
      s.filter(f => f.badgeTypeId == environment.tagTypes.product), this.lstFacTags = s.filter(f => f.badgeTypeId ==
      environment.tagTypes.facility); }), this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; this.stsLst = s.statesLst,
      this.blkLst = s.blockLst; });
  }

  ngOnInit(): void {
    this.select2ShowHide(), this.freeTable, this.glblSvc.invTable.subscribe(s => this.lstGrid = s), this.LoadStores();
    this.fcDoughNutModal('doughnut2d', 'fcModal'); this.ecDoughnut() // this.fcDoughNut('doughnut2d', 'fctabl'), 
    this.dropdowns();
  }

  onChngDT(dtVal: any) {
    this.cstmDt = dtVal ? [] : this.cstmDtDef;
  }

  dropdowns() {
    this.dropdownSettings = { singleSelection: false, idField: 'id', textField: 'name', selectAllText: 'ALL', unSelectAllText: 'ALL',
      itemsShowLimit: 3, allowSearchFilter: true };
  }

  onMultiSlct(type: string, ctrl: string, item: any) {
    if (type == 's') {
      if (ctrl == 'f') {
        if (this.lstSlctdFacTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdFacTags.push(item);
      }
      else {
        if (this.lstSlctdMatTags.filter(f => f.id === item.id).length == 0)
          this.lstSlctdMatTags.push(item);
      }
    }
    else if (type == 'r') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstSlctdFacTags.filter(f => f.id != item.id);
      else
        this.lstSlctdMatTags = this.lstSlctdMatTags.filter(f => f.id != item.id);
    }
    else if (type == 'a') {
      if (ctrl == 'f')
        this.lstSlctdFacTags = this.lstFacTags;
      else
        this.lstSlctdMatTags = this.lstMatTags;
    }
    else {
      if (ctrl == 'f')
        this.lstSlctdFacTags = [];
      else
        this.lstSlctdMatTags = [];
    }
  }

  changeFn(event: any) {
    this.slctdStr = event.value;
    for (let s = 0; s < this.lstFacs.length; s++) {
      if (this.lstFacs[s].storeName == event.value)
        this.slctdStrId = this.lstFacs[s].storeId;
    }
    this.LoadMatsbyStoreId(this.slctdStrId);
  }

  chngProduct(event: any) {
    for (let s = 0; s < this.lstMats.length; s++) {
      if (this.lstMats[s].productName == event.value)
        this.slctPrdId = this.lstMats[s].productId;
    }
  }

  LoadMatsbyStoreId(sId: number) {
    this.lstMats = [], this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.invMatbyStoreId.
      replace('sss', `${sId}`).replace('ttt', `${this.chsnDmnId}`), null).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data)
            this.lstAllMats = this.result.data?.productsDTOs != null ? this.result.data?.productsDTOs : [], this.lstMats = this.lstAllMats;
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadStores();
  }

  clearBSDate(ctrl: string) {
    this.invStkRprtFrm.get(ctrl)?.setValue('');
  }

  freeTable() {
    $(".table-multi-columns").freezeTable({ 'columnNum': 4 });
  }

  LoadStores() {
    let crntPgIndx = this.crntPage - 1, payLoad = this.invStkRprtFrm.getRawValue();if (payLoad.storeId && (payLoad.productId == null || payLoad.productId == ''))
      this.glblSvc.sbjctKskName.next(payLoad.storeId), this.glblSvc.sbjctKskId.next(this.slctdStrId), this.router.navigate(['home/stk-rprt-mtrl']);
    else {
      if (payLoad.storeId && payLoad.productId)
        payLoad.storeId = payLoad.storeId ? this.slctdStrId : null, payLoad.productId = payLoad.productId ? this.slctPrdId : null;

      payLoad.expireBefore = (payLoad.expireBefore != null && payLoad.expireBefore != '') ?
        this.glblSvc.setDtFrmt(payLoad.expireBefore, this.dtF.ymd) + this.tmF.zeroTM : null,
        payLoad.pranthId = this.chsnDmnId,
        payLoad.abnormalityType = (payLoad.abnormalityType != '' || payLoad.abnormalityType != null) ? +payLoad.abnormalityType : null,
        payLoad.includeStoreBadge = (payLoad.includeStoreBadge?.length != 0 && this.slctdIncldFac.length != 0) ? this.slctdIncldFac : null,
        payLoad.includeProductBadge = (payLoad.includeProductBadge?.length != 0 && this.slctdIncldProd.length != 0) ? this.slctdIncldProd : null,
        payLoad.excludeStoreBadge = (payLoad.excludeStoreBadge?.length != 0 && this.slctdExcldFac.length != 0) ? this.slctdExcldFac : null,
        this.lstGrid = [], this.FetchData(environment.apiCalls.post, environment.apiPaths.invntry,
          environment.apiMthds.invntrySV.replace('pageNo', crntPgIndx.toString()).replace('pageSize', this.pageSize.toString()), payLoad, 0).then(() => {
            if (this.result) {
              if (this.result.status == 'OK') {
                let data = this.result.data, result: any[] = [], hdrs: any[] = [], ttl: any[] = [];
                if (data) {
                  this.totalItems = this.result.data?.totalRecordsCount ?? 0,
                    this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems),
                    Object.keys(data.inventoryDetailMap).forEach((s: any, i: number) => {
                      if (i == 0)
                        data.inventoryDetailMap[s].forEach((m: any) => { hdrs.push(m.productName), ttl.push(data.totalMap[m.productName]); });
                      let sId = data.inventoryDetailMap[s][0].storeId, sName = s, adrs = data.inventoryDetailMap[s][0], block = adrs.block != null ?
                        `${adrs.block}, ` : '', district = adrs.district != null ? `${adrs.district}, ` : '', state = adrs.state != null ? adrs.state : '',
                        city = adrs.city != null ? `${adrs.city}, ` : '', fnl = block == '' ? city : block;
                      result.push({ kId: sId, kName: sName, kAdrs: fnl + district + state, matData: data.inventoryDetailMap[s], hdrs: hdrs, total: ttl });
                    });
                } else if (data == null)
                  this.glblSvc.onTstr('i', this.result.message);
                else
                  this.glblSvc.onTstr('e', this.result.message);
                this.glblSvc.tableSource.next(result.length > 0 ? result : this.lstGrid), this.glblSvc.sbjctTblDataCnt.next(data?.totalRecordsCount ?? 0),
                  this.isHide = result.length == 0;
              } else
                this.glblSvc.onTstr('e', this.result.message);
            }
          });
    }
  }

  onReset() {
    this.invStkRprtFrm.reset(), this.slctdIncldFac = [], this.slctdIncldProd = [], this.slctdExcldFac = [], this.lstShowFacTags = [],
      this.lstShowMatTags = [], this.slctdMat = [...[]], this.slctdFac = [...[]], this.LoadStores(), setTimeout(() => {
      this.invStkRprtFrm.get('abnormalityType')?.setValue(''); }, 100);
  }

  onCncl(ctrl: string) {
    if (ctrl == 'f')
      this.slctdFac = [...this.lstShowFacTags];
    else
      this.slctdMat = [...this.lstShowMatTags];
  }

  onResetDrpdwns(type: any) {
    if (type == 'f')
      this.invStkRprtFrm.get('includeStoreBadge')?.reset(), this.invStkRprtFrm.get('excludeStoreBadge')?.reset()
    // this.invStkRprtFrm.patchValue({includeStoreBadge: [], excludeStoreBadge: []})
    if (type == 'p')
      this.invStkRprtFrm.get('includeProductBadge')?.reset()
    // , this.invStkRprtFrm.get('excludeProductBadge')?.reset()
    //     this.invStkRprtFrm.patchValue({includeProductBadge: []})
  }

  onStoreClk(kskName: string, kskId: number) {
    if (kskName != '')
      this.glblSvc.sbjctKskName.next(kskName), this.glblSvc.sbjctKskId.next(kskId), this.router.navigate(['home/stk-rprt-mtrl']);
  }

  fcDoughNut(chrtType: string, divId: string) {
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '120', height: '120', dataFormat: 'json',
        dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "0",
            "paletteColors": '#1aaf5d,#D9534F,#00C0EF,#cccccc',
            "fill-opacity": '0',
            "transform": '0',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "0",
            "showShadow": '0',
            "showPlotBorder": "0",
            "borderColor": "#fff",
            "glow": "0",
            "showCanvasBorder": '0',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "0",
            "enableMultiSlicing": "0",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "0",
            "theme": "fussion",
            "showValue": "0",
            "showValues": '0 ',
            "showLabels": '0 ',
            "animation": "0",
            "showTooltip": "0",
            "bgColor": "red",
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            // "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
    });
  }

  fcDoughNutModal(chrtType: string, divId: string) {
    var fsnOptns: any = FusionCharts.options;
    fsnOptns.license({ key: environment.fsnLicKey, creditLabel: false });
    FusionCharts.ready(function () {
      var fc = new FusionCharts({
        type: chrtType, renderAt: divId, width: '430', height: '300', dataFormat: 'json', dataSource: {
          "chart": {
            "caption": "",
            "showBorder": "0",
            "bgAlpha": "1",
            "paletteColors": '#1aaf5d,#D9534F,#FAD42E,#00C0EF',
            "fill-opacity": '0',
            "transform": '1',
            "subCaption": "",
            "numberPrefix": "",
            "startingAngle": "0",
            "showPercentValues": "1",
            "showShadow": '1',
            "showPlotBorder": "1",
            "borderColor": "#fff",
            // "bgColor": "red",
            "glow": "0",
            "showLegend": '1',
            "showCanvasBorder": '1',
            "showPercentInTooltip": "0",
            "enableSmartLabels": "1",
            "enableMultiSlicing": "1",
            "decimals": "2",
            "use3DLighting": "0",
            "useDataPlotColorForLabels": "1",
            "exportEnabled": "1",
            "exportFormats": environment.fcExprtOptns, // |SVG=Export as SVG|XLSX=Export as Excel|CSV=Export as CSV"
            "exportTargetWindow": "_self",
            "exportFileName": "Inventry",
            "theme": "candy",
            "usePlotGradientColor": "0",
            "canvasbgColor": "red", // background of chart
            "canvasbgAlpha": "10",
            "canvasBorderThickness": "1",
            "showAlternateHGridColor": "0"
          },
          "data": [{
            "label": "Normal",
            "value": "86.94",
            "isSliced": "1"
          }, {
            "label": "Zero Stock",
            "value": "1",
          }, {
            "label": "Min",
            "value": "5",
          }, {
            "label": "Max",
            "value": "7.06"
          }]
        }
      }).render();
      // if (divId == 'fcpie2D')
      //   setTimeout(() => { fc.dispose(), $('#shwBtn').click(); }, 5000);
    });
  }

  ecDoughnut() {
    this.lstGrid.forEach((e: any, i: number) => {
      var myChart = echarts.init(document.getElementById(`ec-dn-${i}`),), option;
      option = {
        series: [
          {
            name: '',
            type: 'pie',
            animationType: 'expansion',
            animationDuration: 0,
            animationDurationUpdate: 0,
            startAngle: 0,
            endAngle: 360,
            selectedMode: 'single',
            avoidLabelOverlap: false,
            color: ['#1aaf5d','#D9534F','#FAD42E','#00C0EF'],
            radius: ['40%', '20%'],
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 89, name: 'Normal' },
              { value: 7, name: 'Zero Stock' },
              { value: 1, name: 'Min' },
              { value: 3, name: 'Max' },

            ]
          }
        ]
      };
      myChart.setOption({})
      if (option && typeof option === 'object')
        myChart.setOption(option);
    });
  }

  onTagsSave(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...this.lstSlctdFacTags], this.isFacAll = this.lstFacTags.length === this.lstSlctdFacTags.length;
    else
      this.lstShowMatTags = [...this.lstSlctdMatTags], this.isMatAll = this.lstMatTags.length === this.lstSlctdMatTags.length;
  }

  onResetTags(ctrl: string) {
    if (ctrl == 'f')
      this.lstShowFacTags = [...[]], this.lstSlctdFacTags = [...[]], this.slctdFac = [...[]], this.isFacAll = false;
    else
      this.lstShowMatTags = [...[]], this.lstSlctdMatTags = [...[]], this.slctdMat = [...[]], this.isMatAll = false;
  }

}