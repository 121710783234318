import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

declare let $: any, LoadSetupDomains: any;

@Component({
  selector: 'evin-setup-domains',
  templateUrl: './setup-domains.component.html',
  styleUrls: ['./setup-domains.component.css']
})

export class SetupDomainsComponent extends BaseComponent implements OnInit {

  lstDmns: any[] = []; slctDmnNme = ''; lstMappedPrnths: any[] = []; slctdChldPrnths: any[] = [];  mtrlsFprnt = false; cnfgFprnt = false;
  chldDmns: any[] = []; allChldPrmsnBox: any[] = []; allPrmsnBoxes: any[] = []; msdStngs = { idField: 'pranthid', textField: 'pranthname', 
  itemsShowLimit: 3, allowSearchFilter: true }; isChldShw = false; chldDmnNme = ''; prmsns: any = null; dmnActns: boolean = true; prntFprnt = false;
  dmnFrm = this.fb.group({ mppdPrnth: [''] });

  constructor(public glblSvc: GlobalService, public fb: FormBuilder) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_do_ac')
     this.dmnActns = e?.action;
  });
    this.select2ShowHide(), this.LoadDomains(), this.LoadChldDmnDtls();
  }

  LoadDomains() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getHrchryDtls + this.chsnDmnId,
        null).then(() => { let res = this.result;
      if (this.result)
        this.lstDmns = this.result?.data[0]?.Pranth ?? [], this.slctDmnNme = this.result?.data[0]?.pranthName ?? '',
        setTimeout(() => {
          LoadSetupDomains() }, 500);
      else
        this.glblSvc.onTstr('e', res.message)
    });
  }

  onDlnkDmn(pranthID: any) {
    if(this.dmnActns){
    if (confirm("The selected child domain will be removed from current domain. Do you wish to continue?")) {
      this.FetchData(environment.apiCalls.get, environment.apiPaths.prnthHierrchy, environment.apiMthds.deLnkPrnth+pranthID, null, 7).then(() => {
          let res = this.result;
          if (this.result)
            this.glblSvc.onTstr('s', res.message), window.location.reload();
          else
            this.glblSvc.onTstr('e', res.message)
        });
      }
    }
  }

  onTreDmnClk(sltdDtls: any) {
    if(this.dmnActns){
    if (confirm(`Are you sure do you want to navigate ${sltdDtls.pranthName} domain.?`)) {
      localStorage.setItem('slctdDmnId', sltdDtls.pranthId), this.glblSvc.sbjctChsnDmnId.next(sltdDtls.pranthId), localStorage.setItem('isMstrsCld', '1');//, this.glblSvc.onTstr('i', `Domain='${this.dmnId}'`);
        localStorage.setItem('slctdDmnName', sltdDtls.pranthName), this.glblSvc.sbjctChsnDmnName.next(sltdDtls.pranthName);
          window.location.reload();
    }
  }
  }

  LoadMappedPranths(prnth: any) {
    if (this.dmnActns) {
    this.isChldShw = true, this.dmnFrm.reset();
    this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getChldPrnth + prnth.pranthId, null, 7).then(() => {
        let res = this.result;
        if (this.result)
          this.lstMappedPrnths = this.result?.data ?? [], this.chldDmnNme = prnth.pranthName;
        else
          this.glblSvc.onTstr('e', res.message);
      });
    }
  }

  onCncl() { this.isChldShw = false; }

  onChng(item: any, evntType: any) {
    if (evntType == 'os')
      this.slctdChldPrnths.push(item.pranthid)
    else if (evntType == 'osa')
      this.slctdChldPrnths = [], this.lstMappedPrnths.filter(e => this.slctdChldPrnths.push(e.pranthid))
    else if (evntType == 'ds')
      this.slctdChldPrnths = this.slctdChldPrnths.filter(s => s != item.pranthid)
    else
      this.slctdChldPrnths = [];
  }

  onChngChld(type: any, evnt: any) {
    if (type == 'mtrls')
      this.mtrlsFprnt = evnt.target.checked;
    else if (type == 'prnt')
      this.prntFprnt = evnt.target.checked;
    else
      this.cnfgFprnt = evnt.target.checked;
  }

  LoadChldDmnDtls() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.exprt, environment.apiMthds.loadChldDmns, null, 8).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          if (this.result.data) {
            this.chldDmns = this.result.data;
            for (let i = 0; i < this.chldDmns.length; i++) {
              for (let j = 0; j < this.chldDmns[i].permissionPranth.length; j++) {
                for (let k = 0; k < this.chldDmns[i].permissionPranth[j].permissionCodes.length; k++) {
                  this.allChldPrmsnBox.push({ domainPermissionId: this.chldDmns[i].permissionPranth[j].permissionCodes[k].id, isChecked: this.chldDmns[i].permissionPranth[j].permissionCodes[k].isChecked })
                }
              }
            }
          }
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      }
    });
  }

  onChngePrmsn(type: any, evnt: any, id: any) {
    if (type == "main")
      this.allPrmsnBoxes.filter(e => { if (e.domainPermissionId == id) { e.isChecked = evnt.target.checked } });
    else
      this.allChldPrmsnBox.filter(e => { if (e.domainPermissionId == id) { e.isChecked = evnt.target.checked } })
  }


  onUpdate(type: any) {
    if (this.slctdChldPrnths.length != 0) {
      let payLoad: any = {};
      payLoad.copyConfig = this.cnfgFprnt, payLoad.copyMaterial = this.mtrlsFprnt, payLoad.pranthId = this.slctdChldPrnths, 
      payLoad.updatePermission = this.allChldPrmsnBox;

      if (type == 'chld')
        payLoad.copyToChilds = true;
      else 
        payLoad.copyToChilds = false;

      this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.updatePrmsn, payLoad, 8).then(() => {
        if (this.result) {
          if (this.result.status == 'OK') {
            if (this.result.data) {
              this.glblSvc.onTstr('s', this.result.message);
            }
            else
              this.glblSvc.onTstr('e', this.result.message);
          }
        }
      });
    }
    else
        this.glblSvc.onTstr('w', 'Kindly select the domain(s)');
  }
}