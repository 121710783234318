<div class="row ">
    <div class="col=xl-12 ">
        <div class="float-xl-end">
            <small>As of 19/9/20 12:00 PM</small>
        </div>
    </div>
</div>
<div class="row ">
    <div class="form-group col-xl-4">
        <label for="inputState-41" class="form-label">Metric</label>
        <select id="inputState-41" class="form-select">
            <option value="">% availbility (or in-stock %)</option>
            <option>% of inventtory item availble</option>
            <option>% of facilities with a level of availbility</option>
        </select>
    </div>
    <div class="col-xl-8 text-end">
        <!-- <div class="float-end">
            <button type="button" class="btn btn-sm btn-primary mb-2">Export</button>
        </div> -->
    </div>
</div>
<div class="row mt-3">
    <div class="col-xl-12">
        <div class="text-center">
            <div>% availability (or in-stock %)</div>>
            <small>From: Feb 2021 To: Apr 2021 </small>
        </div>
    </div>
</div>
<div class="row mt-2 mb-3">
    <div class="col-xl-6 offset-xl-3 table-responsive table_spacing">
        <table class="table table-striped table-sm table-bordered border-primary f-14">
            <thead>
                <tr>
                    <th class="col-auto">Item No.</th>
                    <th class="col-auto">State</th>
                    <th class="col-auto text-center">Feb 2021</th>
                    <th class="col-auto text-center">Mar 2021</th>
                    <th class="col-auto text-center">Apr 2021</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1</td>
                    <td>
                        Andaman and Nicobar Islands
                    </td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>Andaman and Nicobar Islands</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>