<link type="stylesheet" href="approval.component.css">
<div class="card p-0 border-0">
    <div class="card-body p-2">
        <!-- Filter Start -->
        <div class="row p-0 mt-2">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-xl-12">
                        <form>
                            <div class="form-row row mb-2">
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Facility</label>
                                    <input type="text" placeholder="Filter by Facility" class="form-control"
                                        id="Facility">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Indent Id</label>
                                    <input type="text" placeholder="Indent Id" class="form-control" id="Material">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Request status</label>
                                    <select id="inputState" class="form-select">
                                        <option selected>All</option>
                                        <option>Pending</option>
                                        <option>Cancelled</option>
                                        <option>Rejected</option>
                                        <option>Approved</option>
                                        <option>Expired</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3 mt-5">
                                    <button class="btn btn-sm btn-primary">Advance Search</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="form-row row mb-2">
                    <div class="form-group col-md-3">
                        <label for="textBox">Request status</label>
                        <select id="inputState" class="form-select">
                            <option selected>All</option>
                            <option>Receipt</option>
                            <option>Issue</option>
                            <option>Transfer</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="textBox">Requester</label>
                        <input type="text" placeholder="Requester" class="form-control" id="District-County">
                    </div>
                    <div class="form-group col-md-3">
                        <label for="textBox">Approver</label>
                        <input type="text" placeholder="Approver" class="form-control" id="District-County">
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="float-xl-end">
                            <button class="btn btn-sm btn-light me-2 mb-2">Reset</button>
                            <button class="btn btn-sm btn-primary mb-2">Filter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <!-- Content start -->
        <div class="row mt-3">
            <div class="col-xl-12 col-sm-12 col-xs-12">
                <div class="row">
                    <div class="col-xl-6 mb-2">
                        <!-- <span style="display: inline-block;" class="me-2">
                            <label tyle="display: inline-block;">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                    </div>
                </div>
                <div class="table_spacing table-responsive table-row-spacing">
                    <table class="table table-striped table-sm table-bordered" id="table_detail">
                        <thead>
                            <tr>
                                <th class="text-center">Item No.</th>
                                <th>Indent Id</th>
                                <th class="text-center">Type</th>
                                <th>Receiving facility</th>
                                <th>Issuing facility</th>
                                <th>Request status</th>
                                <th>Requester</th>
                                <th>Approver(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop">
                                <td class="text-center">1</td>
                                <td></td>
                                <td>Issue</td>
                                <td></td>
                                <td></td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>DK Bajpai</td>
                            </tr>
                            <tr data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop2">
                                <td class="text-center">2</td>
                                <td><a>1546973</a></td>
                                <td>Issue</td>
                                <td><a>BD Facility</a>
                                    <div class="small">Indore, Madhya Pradesh</div>
                                </td>
                                <td><a>Jabalpur DVS</a>
                                    <div class="small">Patpara, Kundam, Jabalpur, Madhya Pradesh</div>
                                </td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>DK Bajpai</td>
                            </tr>
                            <tr data-bs-toggle="modal" data-bs-target="#Tab-row-Backdrop3">
                                <td class="text-center">3</td>
                                <td><a>1547673</a></td>
                                <td>Issue</td>
                                <td><a>BD Facility</a>
                                    <div class="small">Indore, Madhya Pradesh</div>
                                </td>
                                <td><a>Jabalpur DVS</a>
                                    <div class="small">Patpara, Kundam, Jabalpur, Madhya Pradesh</div>
                                </td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>DK Bajpai</td>
                            </tr>
                            <tr>
                                <td class="text-center">4</td>
                                <td></td>
                                <td>Issue</td>
                                <td></td>
                                <td></td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>DK Bajpai</td>
                            </tr>
                            <tr>
                                <td class="text-center">5</td>
                                <td></td>
                                <td>Receipt</td>
                                <td><a>BD Facility</a>
                                    <div class="small">Indore, Madhya Pradesh</div>
                                </td>
                                <td><a>Jabalpur DVS</a>
                                    <div class="small">Patpara, Kundam, Jabalpur, Madhya Pradesh</div>
                                </td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>
                                    <div class="small">Sent to:</div><a>DK Bajpai</a>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">6</td>
                                <td><a>156793</a></td>
                                <td>Issue</td>
                                <td></td>
                                <td></td>
                                <td>Approved<div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td><a>Tesh BVSM</a>
                                    <div class="small">11/07/2018 12:34 PM</div>
                                </td>
                                <td>DK Bajpai</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row ps-0 pe-0 pt-3 pb-3">
                    <div class="col-lg-6 col-xxl-6 pb-3">
                    </div>
                    <div class="col-lg-6 col-xxl-6 pb-3">
                        <!-- Pagination start -->
                        <nav aria-label="Page navigation example ">
                            <ul class="pagination justify-content-xl-end  pagination-primary ">
                                <li class="page-item"><a class="page-link" aria-label="Previous"
                                        data-bs-original-title="" title=""><span aria-hidden="true">«</span><span
                                            class="sr-only">Previous</span></a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">1</a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">2</a></li>
                                <li class="page-item"><a class="page-link" data-bs-original-title="" title="">3</a></li>
                                <li class="page-item"><a class="page-link" aria-label="Next" data-bs-original-title=""
                                        title=""><span aria-hidden="true">»</span><span class="sr-only">Next</span></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Table row popup -->
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link f-14 active" id="top-batchtab-tab" data-bs-toggle="tab"
                            href="#top-batchtab" role="tab" aria-controls="top-batchtab" aria-selected="true">
                            <div class="modal-title">Issue approval</div>
                        </a></li>
                </ul>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="tab-content" id="top-tabContent">
                    <div class="tab-pane fade show active" id="top-batchtab" role="tabpanel"
                        aria-labelledby="top-batchtab-tab">
                        <div class="card mb-2 p-0 border-0 rounded-0">
                            <div class="card-body p-2">
                                <div class="mt-2 mb-2"><i class="fa fa-check text-success" aria-hidden="true"></i>
                                    Approved on 01/02/2018 10:13 PM by Jyothi</div>
                                <div class="table-responsive table_spacing">
                                    <table class="table table-striped table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                        aria-hidden="true"></i></td>
                                                <td><a>Srilekha</a>&nbsp;&nbsp;<small>added a message - 6/7/18
                                                        3:46 PM</small>
                                                    <div>Shipping now</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                        aria-hidden="true"></i></td>
                                                <td><a>user</a>&nbsp;&nbsp;<small>added a message - 11/7/12
                                                        3:46 PM</small>
                                                    <div>test test</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-3">
                                    <div class="col-xl-12">
                                        <label class="form-label" for="">Comment</label>
                                        <textarea class="form-control taRS" id="" placeholder="Add comment"></textarea>
                                    </div>
                                </div>
                                <div class="mt-2 mb-2"><button class="float-end btn btn-sm btn-primary">Add
                                        comment</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Table row popup2 -->
<div class="modal" id="Tab-row-Backdrop2">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h6 class="modal-title">Issue approval</h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-3 rounded-0">
                    <div class="card-body p-2 text-warning">
                        There are no comments yet.
                    </div>
                    <div class="mt-3">
                        <div class="col-xl-12">
                            <label class="form-label" for="">Comment</label>
                            <textarea class="form-control taRS" id="" placeholder="Add comment"></textarea>
                        </div>
                    </div>
                    <div class="mt-2 mb-2"><button class="float-end btn btn-sm btn-primary">Add comment</button></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Table row popup3 -->
<div class="modal" id="Tab-row-Backdrop3">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header p-2">
                <h6 class="modal-title"></h6>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="table-responsive table_spacing">
                            <table class="table table-striped table-sm table-bordered">
                                <tbody>
                                    <tr>
                                        <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                aria-hidden="true"></i>
                                        </td>
                                        <td><a>Srilekha</a>&nbsp;&nbsp;<small>added a message - 6/7/18 3:46
                                                PM</small>
                                            <div>Shipping now</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3">
                            <div class="col-xl-12">
                                <label class="form-label" for="">Comment</label>
                                <textarea class="form-control taRS" id="" placeholder="Add comment"></textarea>
                            </div>
                        </div>
                        <div class="mt-2 mb-2"><button class="float-end btn btn-sm btn-primary">Add comment</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>