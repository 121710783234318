<div class="card p-0 border-0">
    <div class="card-body p-4 pt-2 pb-2">
        <ul class="nav nav-tabs border-tab mb-2" id="top-tab" role="tablist">
            <li class="nav-item" *ngIf="trnsprtrsList"><a class="nav-link" id="Transporter-tab" data-bs-toggle="tab" href="#Transporter"
                    role="tab" aria-controls="Transporter" aria-selected="true" (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' ? 'active' : ''">Transporters</a>
            </li>
            <li class="nav-item" *ngIf="createTrnsprtrs"><a class="nav-link" id="Create-Transporters-top-tab" data-bs-toggle="tab"
                    href="#top-Create-Transporters" role="tab" aria-controls="Create-Transporters" aria-selected="false"
                    (click)="onTabSelection('new')" [ngClass]="paramTab === 'new' ? 'active' : ''">Create
                    transporter</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="Transporter" role="tabpanel" aria-labelledby="Transporter-tab"
             [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-transporters-view></evin-transporters-view>
            </div>
            <div class="tab-pane fade" id="top-Create-Transporters" role="tabpanel"  aria-labelledby="Create-Transporters-top-tab" 
             [ngClass]="paramTab === 'new' ? 'show active' : '' ">
                <evin-create-transporters></evin-create-transporters>
            </div>
        </div>
    </div>
</div>