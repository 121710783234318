import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-create-custom-dashboard',
  templateUrl: './cnfg-create-custom-dashboard.component.html',
  styleUrls: ['./cnfg-create-custom-dashboard.component.css']
})
export class CnfgCreateCustomDashboardComponent extends BaseComponent implements OnInit {
  usrPrmsn: any = null; cnfgCstDbPrmsn = true;
  constructor(public glblSvc: GlobalService) { super(glblSvc) }

  ngOnInit(): void {
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_cd_ac') ? (this.cnfgCstDbPrmsn = e.action) : '') });
    }
  }

}
