import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';


@Component({
  selector: 'evin-manufactures-edit',
  templateUrl: './manufactures-edit.component.html',
  styleUrls: ['./manufactures-edit.component.css']
})

export class ManufacturesEditComponent extends BaseComponent implements OnInit {

  isSbmtd = false; cntryLst: any[] = []; stsLst: any[] = []; blkLst: any[] = []; dstrtLst: any[] = []; producerBadges: any[] = [];
  prdcrBadges: any[] = []; IdentifiersLst: any[] = []; id: any = 0; usrLvl = ''; catgry = ''; dstLst: any[] = [];
  manfcturerEditForm: FormGroup = this.formBuilder.group({}); manFctrDetails: any = {}; slctdDmndtls: any;

  constructor(public glblSvc: GlobalService
    , private formBuilder: FormBuilder
    , private router: Router) {
    super(glblSvc);
    this.glblSvc.sbjctManEdit.subscribe(s => { this.id = s; })
    this.glblSvc.sbjctMstrData.subscribe(s => { this.cntryLst = s.cntriesLst; this.IdentifiersLst = s.IdentifiersLst });
    this.glblSvc.sbjctBdgsList.subscribe((a: any) => {
      this.producerBadges = a.filter((f: any) => f.badgeTypeId == environment.tagTypes.manufacturer);
      this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst, this.blkLst = s.blockLst, this.dstLst = s.dstLst; });
    })
  }

  ngOnInit(): void {
    this.manfcturerEditForm = this.formBuilder.group({
      alternateContactNo: ['', [Validators.minLength(10), Validators.maxLength(13)]], blockId: [null], contactName: ['', Validators.required],
      countryId: ['431', Validators.required], description: [''], gender: ['', Validators.required], districtId: [null], email: ['', 
        Validators.required], isDeleted: [false], name: ['', Validators.required], stateId: [null, Validators.required], isEnabled: [true],
      mobile: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13),  Validators.pattern(environment.patterns.mble)]],
    });

    if (this.id>0) {
      this.getManuftrData(this.id);
    } else
    this.router.navigate(['home/setup-mfrs'], {queryParams:{tab: 'list'}});

    this.glblSvc.sbjctMstrData.subscribe(s => { this.stsLst = s.statesLst});

  }

  GetIdentifiers(): FormArray {
    return this.manfcturerEditForm.get('producerTm') as FormArray;
  }

  onCreateIdentifiers(): FormGroup {
    return this.formBuilder.group({
      tmTypeId: ['', Validators.required], tmCode: ['', Validators.required]
    });
  }

  onDelIdentifiers(tabIndex: any) {
    this.GetIdentifiers().removeAt(tabIndex)
  }

  onAddIdentifiers() {
    if (this.GetIdentifiers().length == 0)
      this.GetIdentifiers().push(this.onCreateIdentifiers());
    else {
      let mtCnt = 0;
      this.GetIdentifiers().controls.forEach((e: any) => {
        if (e.controls.tmTypeId.value == '' || e.controls.tmCode.value == '') {
          this.glblSvc.onTstr('i', 'Kindly fill all mandatory fields to add a new record'), mtCnt++;
          return;
        }
      });
      if (mtCnt == 0)
        this.GetIdentifiers().push(this.onCreateIdentifiers());
    }
  }

  getManuftrData(manfurId: any) {
    this.manFctrDetails = {};
    this.FetchData(environment.apiCalls.get, environment.apiPaths.producer, environment.apiMthds.fndById + `${manfurId}`, null, 0, true, 2).then(() => {
      if (this.result) {
        if (this.result.status == 'OK') {
          this.manFctrDetails = this.result.data != null ? this.result.data : [];
          this.manfcturerEditForm.patchValue({
            alternateContactNo: this.manFctrDetails?.alternateContactNo, blockId: null, contactName: this.manFctrDetails?.contactName,
            countryId: this.manFctrDetails?.countryId, description: this.manFctrDetails?.description, districtId: null,
            email: this.manFctrDetails?.email, isDeleted: this.manFctrDetails?.isDeleted, mobile: this.manFctrDetails?.mobile, 
            name: this.manFctrDetails?.name, stateId: this.manFctrDetails?.stateId, isEnabled: this.manFctrDetails?.isEnabled,
            gender: this.manFctrDetails?.gender,
          });
          // this.manfcturerEditForm.get('producerBadges')?.setValue(this.manFctrDetails?.producerBadges[0]?.badgeId);
          let tags = { target: { value: this.manFctrDetails?.producerBadges[0] } }

          
          // this.onNestedSlct(this.manFctrDetails.countryId ?? null, 'country', true)
          if (this.usrLgnDtls) {
            let cntryId = `${JSON.parse(<string>localStorage.getItem('lgnMstrsData'))?.cntriesLst.filter((f: any) => f.name == 'India')[0]?.id}` ?? null,
              usrLvlDtls = Object.keys(this.usrLgnDtls).filter(f => f == 'userLevelDetails').length > 0 ? this.usrLgnDtls.userLevelDetails[0] : {};
            this.usrLvl = usrLvlDtls?.userLevel ?? '', this.manfcturerEditForm.get('countryId')?.setValue(cntryId);
            if (this.manFctrDetails) {
              if (this.manFctrDetails?.stateId != null && this.manFctrDetails?.districtId == null && this.manFctrDetails?.blockId == null)
                this.manfcturerEditForm.get('stateId')?.setValue(+this.manFctrDetails?.stateId ?? 'null'), this.manfcturerEditForm.get('districtId')?.setValue('null'),
                  this.onLocChng(+this.manFctrDetails?.stateId ?? null, 'state', true);
              if (this.manFctrDetails?.stateId != null && this.manFctrDetails?.districtId != null && this.manFctrDetails?.blockId == null) {
                this.manfcturerEditForm.get('stateId')?.setValue(+this.manFctrDetails?.stateId ?? 'null'), Promise.all([this.onLocChng(+this.manFctrDetails?.stateId ?? null,
                  'state', true), this.onLocChng(+this.manFctrDetails?.districtId ?? null, 'district', true)]).then(() => {
                    this.manfcturerEditForm.get('districtId')?.setValue(+this.manFctrDetails?.districtId ?? 'null'),
                      setTimeout(() => { this.manfcturerEditForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
                  });
              }
              if (this.manFctrDetails?.stateId != null && this.manFctrDetails?.districtId != null && this.manFctrDetails?.blockId != null) {
                let blockID = +this.manFctrDetails?.blockId;
                Promise.all([this.onLocChng(+this.manFctrDetails?.stateId ?? null, 'state', true), this.onLocChng(+this.manFctrDetails?.districtId ?? null,
                  'district', true)]).then(() => {
                    this.manfcturerEditForm.get('stateId')?.setValue(this.manFctrDetails?.stateId ?? null),
                      this.manfcturerEditForm.get('districtId')?.setValue(this.manFctrDetails?.districtId ?? 'null'), this.manfcturerEditForm.get('blockId')?.setValue(blockID ?? null),
                      setTimeout(() => { this.manfcturerEditForm.get('blockId')?.enable() }, 1000);  //, this.LoadCnsmptnReport();  , this.loadCcpAll(); 
                  });
              }
              if (this.manFctrDetails?.stateId == null && this.manFctrDetails?.districtId == null && this.manFctrDetails?.blockId == null)
                this.manfcturerEditForm.get('stateId')?.setValue('null'), this.manfcturerEditForm.get('districtId')?.setValue('null'),
                this.manfcturerEditForm.get('blockId')?.setValue('null');
            }
          }
          setTimeout(() => { this.onAS(), this.ctgrydrpdwn(tags) }, 1000);
        }
      }
    })
  }

  onLocChng(evnt: any, type: any, isId: boolean = false) {
    let sid = '', did = '', slctdId = isId ? evnt : evnt.target.value;
    if (slctdId != "null") {
      if (type == 'state')
        sid = slctdId, this.dstLst = [], this.blkLst = [], this.manfcturerEditForm.get('districtId')?.setValue(null),
          this.manfcturerEditForm.get('blockId')?.setValue(null);
      else
        did = slctdId, this.blkLst = [], this.manfcturerEditForm.get('blockId')?.setValue(null);
      this.FetchData(environment.apiCalls.get, environment.apiPaths.states, (type == 'state' ? environment.apiMthds.getDistricts.
        replace('sss', sid) : environment.apiMthds.getBlocks.replace('ddd', did)), null, 2, true, 1).then(() => {
          if (this.result) {
            if (this.result.data) {
              if (type == 'state')
                this.dstLst = this.result.data != null ? this.result.data : [], this.manfcturerEditForm.get('districtId')?.enable(),
                  this.manfcturerEditForm.get('blockId')?.disable();
              else
                this.blkLst = this.result.data != null ? this.result.data : [], this.manfcturerEditForm.get('blockId')?.enable();
                // console.log('block list', this.blkLst);
            }
          }
        })
    }
    else {
      if (type == 'state')
        this.manfcturerEditForm.get('districtId')?.setValue(null), this.manfcturerEditForm.get('blockId')?.setValue('null'),
          this.manfcturerEditForm.get('districtId')?.disable(), this.manfcturerEditForm.get('blockId')?.disable();
      else
        this.manfcturerEditForm.get('blockId')?.setValue('null'), this.manfcturerEditForm.get('blockId')?.disable();
    }
  }

  onAS() {
    if (this.slctDmnDetails?.stateId == null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.manfcturerEditForm.get('stateId')?.enable(), this.manfcturerEditForm.get('districtId')?.enable(), this.manfcturerEditForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId == null && this.slctDmnDetails?.blockId == null)
      this.manfcturerEditForm.get('stateId')?.disable(), this.manfcturerEditForm.get('districtId')?.enable(), this.manfcturerEditForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId == null)
       this.manfcturerEditForm.get('stateId')?.disable(), this.manfcturerEditForm.get('districtId')?.disable(), this.manfcturerEditForm.get('blockId')?.enable();
    if (this.slctDmnDetails?.stateId != null && this.slctDmnDetails?.districtId != null && this.slctDmnDetails?.blockId != null)
      this.manfcturerEditForm.get('stateId')?.disable(), this.manfcturerEditForm.get('districtId')?.disable(), this.manfcturerEditForm.get('blockId')?.disable();
  }

  onSlct($event: any) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.prdcrBadges = [{
        badgeId: this.producerBadges.filter(a => a.id == $event.target.value)[0]?.id,
        createdBy: this.usrLgnDtls.userId,
        createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
        isDeleted: this.producerBadges.filter(a => a.id == $event.target.value)[0]?.isDeleted, producerId: null,
        updatedBy: this.usrLgnDtls.userId,
        updatedOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
      }]
    }
  }

  ctgrydrpdwn($event: any) {
    if ($event.target.value != '' && $event.target.value != null) {
      this.prdcrBadges = [{
        badgeId: this.producerBadges.filter(a => a.id == $event.target.value?.badgeId)[0].id,
        createdBy: this.usrLgnDtls.userId,
        createdOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
        isDeleted: this.producerBadges.filter(a => a.isDeleted == $event.target.value?.isDeleted)[0].isDeleted, producerId: null,
        updatedBy: this.usrLgnDtls.userId,
        updatedOn: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM,
      }]
    }
  }

  cancel() {
    this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } }),
    this.manfcturerEditForm.reset(), this.isSbmtd = false, this.manfcturerEditForm.get('countryId')?.setValue(''),
      this.manfcturerEditForm.get('stateId')?.setValue(''), this.manfcturerEditForm.get('districtId')?.setValue(''),
      this.manfcturerEditForm.get('blockId')?.setValue('');
  }

  get fc() { return this.manfcturerEditForm.controls; }


  editeManufacturers() {
    this.checkFormValidations(this.manfcturerEditForm);
    if (this.manfcturerEditForm.invalid)
      this.glblSvc.onTstr('w', 'Kindly fill/check all mandatory fields');
    else {
      let payLoad = this.manfcturerEditForm.getRawValue();
      payLoad.id = this.id, payLoad.producerTm = [], payLoad.blockId = null, payLoad.districtId = null, payLoad.gender = payLoad.gender != '' && 
      payLoad.gender != null ? payLoad.gender : null, payLoad.countryId = +payLoad.countryId, payLoad.stateId = +payLoad.stateId,
      payLoad.updatedBy = this.usrLgnDtls.userId, payLoad.createdBy = payLoad.updatedBy, payLoad.pranthId = this.chsnDmnId,
      payLoad.createdOn = this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd) + this.tmF.zeroTM, payLoad.updatedOn = payLoad.createdOn,
      payLoad.producerBadges = [];
      this.FetchData(environment.apiCalls.post, environment.apiPaths.producer, environment.apiMthds.saveRupdate, payLoad, 0, true, 2).then(() => {
        if (this.result) {
          if (this.result.status == 'OK')
            localStorage.setItem('manfcturCreatePL', JSON.stringify(payLoad)), this.glblSvc.onTstr('s', this.result.message),
              this.router.navigate(['home/setup-mfrs'], { queryParams: { tab: 'list' } }), setTimeout(() => { window.location.reload(); }, 1000); //, this.cmpntTL.ngOnInit();
          else
            this.glblSvc.onTstr('e', this.result.message);
        }
      });
    }
  }
}
