import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-notifications-access-log',
  templateUrl: './notifications-access-log.component.html',
  styleUrls: ['./notifications-access-log.component.css']
})
export class NotificationsAccessLogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
