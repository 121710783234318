<form>
    <div class="card p-3 pb-0 pt-2">
        <div class="card-header shadow-none p-2 pb-1 pt-2">
            <h6 class="mb-0">Bulletin board</h6>
        </div>
        <div class="card-body p-2 mt-0 mb-0">
            <div class="row">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Name" class="form-control" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Description</label>
                            <input type="text" placeholder="Description" class="form-control" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Display time <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Display time" class="form-control" id="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card p-3 pb-0 pt-2">
        <div class="card-header shadow-none p-2 pb-1 pt-2">
            <div class="float-start">
                <h6 class="mb-0">Dashboards</h6>
            </div>
            <div class="float-end">
                <div class="mb-0"><button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                        data-bs-target="#modal_1" *ngIf="cnfgBltnPrmsn">Add Dashboards</button></div>
            </div>
        </div>
        <div class="card-body p-2 mt-0 mb-0">
            no Dashboards found
        </div>
    </div>
    <div class="text-end pb-3">
        <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgBltnPrmsn">Save</button>
    </div>
</form>