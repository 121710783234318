import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends BaseComponent implements OnInit {

  paramTab: any; prmsns: any = null; usrLst: boolean = true; cratUsrBulk: boolean = true;
  
  constructor(private route: ActivatedRoute, private router: Router, public glblvc: GlobalService) {
    super(glblvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; });
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.usrLst =this.prmsns.find((obj: any) => obj.menuCode =='mm_st_us_li').action;
    this.cratUsrBulk =this.prmsns.find((obj: any) => obj.menuCode =='mm_st_us_cu-bu').action;
  }
  
  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}
