<div class="card p-3 pt-2 border-0">
    <div class="card-header shadow-sm p-3 pb-1 pt-2">
        <div class="d-flex">
            <div class="p-2 w-100 text-center h6">BCG diluent vial(s)</div>
        </div>
    </div>
    <div class="card-body p-0 mt-2 mb-2">
        <div class="float-start">
            <button type="button" class="btn btn-sm btn-primary">Edit</button>
        </div>
        <div class="float-end">
            <div class="small">
                <p class="mb-0">Created on 30/10/18 11:07 PM by <a>Rakesh Jatav</a> &nbsp;&nbsp;&nbsp;Last
                    updated on 4/3/21 11:42 AM by <a>Abhimanyu Saxena</a></p>
            </div>
        </div>
        <hr class="mb-2 mt-0 pt-0" />
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card p-0 mb-3">
                <div class="card-body p-2">
                    <div class="row">
                        <div class="col-sm-4">Handling unit name</div>
                        <div class="col-sm-8">BCG diluent vial(s)</div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Description:</div>
                        <div class="col-sm-8"></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Content:</div>
                        <div class="col-sm-8"><a>BCG diluent (dose)</a></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">Quantity:</div>
                        <div class="col-sm-8">10</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>