<div class="card p-0 border-0">
    <div class="card-header border-0 p-3">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link" id="Order-tab" data-bs-toggle="tab" href="#Order" role="tab"
                    aria-controls="Order" aria-selected="true" *ngIf="dbActPrmsn" [ngClass]="{'active' : (dbActPrmsn == true)}">Overview</a></li>
            <li class="nav-item"><a class="nav-link" id="Create-order-tab" data-bs-toggle="tab" href="#Create-order"
                    role="tab" aria-controls="Create-order" aria-selected="false" *ngIf="dbActTxnPrmsn && !dsbleActlDtOfTransctnTab" 
                    [ngClass]="{'active' : (dbActPrmsn == false && dbActTxnPrmsn == true)}">By actual date of transaction</a>
            </li>
        </ul>
    </div>
    <div class="card-body p-3 pt-0">
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade pb-2" id="Order" role="tabpanel" aria-labelledby="Order-tab" *ngIf="dbActPrmsn"
            [ngClass]="{'show active' : (dbActPrmsn == true)}">
                <evin-db-activity-overview></evin-db-activity-overview>
            </div>
            <div class="tab-pane fade pb-2" id="Create-order" role="tabpanel" aria-labelledby="Create-order-tab" *ngIf="dbActTxnPrmsn && !dsbleActlDtOfTransctnTab" 
            [ngClass]="{'show active' : (dbActPrmsn == false && dbActTxnPrmsn == true)}">
                <evin-actiual-data-of-transaction></evin-actiual-data-of-transaction>
            </div>
        </div>
    </div>
</div>