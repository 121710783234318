<form [formGroup]="cnsmptnForm">
    <div class="card p-0 border-0">
        <div class="row mt-2 mb-2 p-4">
            <div class="col-xl-12">
                <div style="float: right;" *ngIf="isRprtsHlpShw">
                    <a href="btn btn-sm btn-link" [routerLink]="['/consumption/help']" target="_blank"><u>Help</u></a> 
                </div>
            </div>
            <div class="col-xl-12">
                <div class="form-row row mb-2">
                    <div class="form-group col-md-3">
                        <strong class="col-form-label">Material By<span class="text-danger"><sup>*</sup></span></strong>
                        <div class="form-check form-check-inline mt-2 p-l-25">
                            <input class="form-check-input" type="radio" id="rbMat1" name="isMatBdge" value="badge"
                                [(ngModel)]="isMatBdge" [checked]="isMatBdge=='badge'" formControlName="isMatBdge"
                                (click)="onClkRdb('productTags')">
                            <label class="form-check-label" for="mat">Tag</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="rbMat2" name="isMatBdge" value="name"
                                [(ngModel)]="isMatBdge" [checked]="isMatBdge=='name'" formControlName="isMatBdge"
                                (click)="onClkRdb('productIds')">
                            <label class="form-check-label" for="mat">Name</label>
                        </div>
                        <div *ngIf="isMatBdge=='badge'">
                            <ng-multiselect-dropdown
                                [settings]="dropdownSettings" [(ngModel)]="slctdMat"
                                [data]="lstMatTags" formControlName="productTags"
                                (onSelect)="onMultiSlct('s', 'm', $event)"
                                (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.productTags.errors }">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.productTags.errors" class="invalid-feedback">
                                <div *ngIf="fc.productTags.errors?.required">Material tag is required</div>
                            </div>
                        </div>
                        <div *ngIf="isMatBdge=='name'">
                             <ng-multiselect-dropdown [settings]="dropdownSettings"
                                 [data]="lstAllMats" formControlName="productIds"                                                                        
                                 (onSelect)="onMultiSlct('s', 'n', $event)"
                                 (onSelectAll)="onMultiSlct('a', 'n', $event)"
                                 (onDeSelect)="onMultiSlct('r', 'n', $event)"
                                 (onDeSelectAll)="onMultiSlct('d', 'n', $event)"
                                 [ngClass]="{ 'is-invalid': isSbmtd && fc.productIds.errors }"
                                 [(ngModel)]="slctdMatName">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.productIds.errors" class="invalid-feedback">
                                <div *ngIf="fc.productIds.errors?.required">Material name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <strong class="col-form-label">Facility Tag</strong><span class="text-danger"><sup>*</sup></span>&nbsp;&nbsp;
                        <div>
                            <ng-multiselect-dropdown [settings]="dropdownSettings"
                                [data]="lstFacTags" formControlName="storeTags"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.storeTags.errors }"                                                                        
                                (onSelect)="onMultiSlct('s', 'f', $event)"
                                (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                [(ngModel)]="slctdFac">
                            </ng-multiselect-dropdown>
                            <div *ngIf="isSbmtd && fc.storeTags.errors" class="invalid-feedback">
                                <div *ngIf="fc.storeTags.errors?.required">Facility is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3  mt-3">
                        <strong>State</strong>
                        <select formControlName="state" class="form-select form-select-sm"
                            (change)="dstLst=[];blkLst=[];lstGrid=[];onLocChng($event, 'state')" #locState>
                            <option value="null">All</option>
                            <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3  mt-3">
                        <strong>District</strong>
                        <select formControlName="district" class="form-select form-select-sm"
                            (change)="blkLst=[];lstGrid=[];onLocChng($event, 'district')" #locDist>
                            <option value="null">All</option>
                            <option *ngFor="let s of dstLst" [value]="s.districtid">{{s.districtname}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3  mt-3"> <!-- *ngIf="storeT.length !=0" -->
                         <strong>Taluk/Block</strong>
                         <select formControlName="blockId" class="form-select form-select-sm" 
                              (change)="lstGrid=[];LoadCCPs();" #locBlck>
                             <option value="null">All</option>
                             <option *ngFor="let b of blkLst" [value]="b.blockid">{{b.blockname}}</option>
                         </select>
                    </div>
                    <div class="form-group col-md-3 mt-3 pt-1" >
                        <strong class="col-form-label m-0 p-0">Metric</strong>
                        <select class="form-select form-select-sm" [(ngModel)]="pstT" formControlName="metricId"
                            (change)="isSbmtd = false;lstGrid = [];onChngPstT()">
                            <option *ngFor="let pc of lstPrdCtyss" [value]="pc.val">{{pc.name}}</option>
                        </select>
                    </div>
                    <div class="form-group col-xl-6">
                        <div class="row">
                            <div class="col-xl-5 small form-group">
                                <strong class="col-form-label">From<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='2'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpSDVal" #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY', minMode: 'month',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                        (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate" 
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='1' || pstT=='3'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpSDVal"
                                        #dpSD="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="Start Date"
                                         (bsValueChange)="onChngDT($event, 'sd')" [maxDate]="crntDate"
                                         [ngClass]="{ 'is-invalid': isSbmtd && fc.dpSDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpSD.toggle()"
                                            [attr.aria-expanded]="dpSD.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpSDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpSDVal.errors?.required">From date is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-2 text-center mt-1 p-0" style="margin-top: 2.3rem !important;"><label>-</label></div> 
                            <div class="col-xl-5 small">
                                 <strong class="col-form-label">To<span class="text-danger"><sup>*</sup></span></strong>
                                <div class="input-group" *ngIf="pstT=='2'">
                                    <input type="text" autocomplete="off" onpaste="return false"
                                        formControlName="dpEDVal" #dpED="bsDatepicker" bsDatepicker
                                        class="form-control form-control-sm optinbg bsDP-bg" readonly
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'MMM-YYYY',
                                            minMode: 'month', containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="yBDate" (bsValueChange)="onChngDT($event, 'ed')" [minDate]="yrMin"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                                <div class="input-group" *ngIf="pstT=='1' || pstT=='3'">
                                    <input type="text" autocomplete="off" onpaste="return false" [(bsValue)]="dpEDVal"
                                         #dpED="bsDatepicker" bsDatepicker readonly
                                        class="form-control form-control-sm optinbg bsDP-bg"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MMM-YYYY',
                                            displayOneMonthRange: true, containerClass: 'theme-default',
                                            showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' placeholder="End Date"
                                        [maxDate]="maxToDate" [minDate]="dpSDVal" (bsValueChange)="onChngDT($event, 'ed')"
                                        [ngClass]="{ 'is-invalid': isSbmtd && fc.dpEDVal.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-sm btn-outline-bsdp" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                        </button>
                                    </div>
                                    <div *ngIf="isSbmtd && fc.dpEDVal.errors" class="invalid-feedback">
                                        <div *ngIf="fc.dpEDVal.errors?.required">To date is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 float-xl-end text-left"><strong>{{lblTextOfDate}}</strong></div>
                        </div>
                    </div>
                    <div class="form-group col-md-3  mt-3" *ngIf="usrLvl !='National'" id="ccpContainer">
                        <strong for="textBox">CCP</strong>
                        <ng-multiselect-dropdown [settings]="ddlStngs" [data]="lstCcpAll" [(ngModel)]="slctdccp" 
                            formControlName="storeIds" (onSelect)="onMultiSlct('s', 'c', $event)"  
                            (onSelectAll)="onMultiSlct('a', 'c', $event)" (onDeSelect)="onMultiSlct('r', 'c', $event)"
                            (onDeSelectAll)="onMultiSlct('d', 'c', $event)">
                        </ng-multiselect-dropdown>
                    </div>
                    <div class="form-group col-md-3 pt-3" *ngIf="usrLvl == 'National'&& locState.value == 'null'">
                        <strong for="textBox">Order By</strong>
                        <select class="form-select form-select-sm" formControlName="isDistrict" 
                            [(ngModel)]="ordBy">
                        <option value="false">State-wise</option>
                        <option value="true">District-wise</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 pt-3" *ngIf="locState.value != 'null' && locDist.value == 'null'">
                        <strong for="textBox">Order By</strong>
                        <select class="form-select form-select-sm" formControlName="isFacility" 
                            [(ngModel)]="ordFBy" (change)="isSbmtd = false;lstGrid = [];">
                        <option value="false">District-wise</option>
                        <option value="true">Facility-wise</option>
                        </select>
                    </div>
                    <div class="form-group col-md-3" *ngIf="locState.value != 'null'"></div>
                    <div class="form-group offset-xl-9 col-md-3 mt-2">
                        <div class="float-xl-end mb-2">
                            <button class="btn btn-sm btn-light me-2" type="button" (click)="crntPage=1;lstGrid=[];onReset()">Reset</button>
                            <button class="btn btn-sm btn-primary" type="button" (click)="crntPage=1;lstGrid=[];isSbmtd=true;LoadCnsmptnReport()">Get report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>    
    </div>
    <div class="row p-0" >
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-body border-0 pb-2">
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-4 mb-2">
                            <div class="row">
                                <div class="col-xl-4">
                                    <button class="btn btn-sm btn-success" (click)="isExprt=true;onEE()">
                                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="lstGrid.length!=0">
                        <div class="col-xl-6">
                            <div *ngIf="lstGrid.length!=0" class="mt-2">{{pageEntrs}}</div>
                        </div>
                        <div class="col-xl-6">
                            <div class="float-end" *ngIf="lstGrid?.length!=0">
                                <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                    <div class="tab-content" *ngIf="lstGrid.length!=0">
                        <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                            aria-labelledby="pills-tabview-tabinfo">
                            <div class="table-multi-columns table-fill mt-2 mb-2 horizontal-scroll scroll-demo p-0 border-0 table_spacing"
                                style="height: auto !important;" *ngIf="lstGrid.length==0">
                                <!-- <table class="table table-sm text-center table-small-font table-striped table-bordered align-middle"
                                    cellspacing="0"> -->
                                    <table class="text-center table-small-font main-table table-bordered center">
                                    <thead>
                                        <tr>
                                            <th style="width: 20%">Item No.</th>
                                            <th style="width: 60%" class="text-center">Facility</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="2" class="text-center">{{noRcrdsTxt}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="table-wrap" *ngIf="lstGrid.length!=0">
                                <table class="text-center table-small-font main-table table-bordered">
                                    <thead>
                                        <tr>
                                            <th colspan="5" class="freeze tbl-hdr-clr">
                                                <table class="w-450p break-word-td">
                                                    <tr>
                                                        <th style="width: 15%">Item No.</th>
                                                        <th style="width: 25%" class="text-center" *ngIf="usrLvl=='National'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='National' && ((cnsmptnForm?.get('state')?.value!=null && 
                                                            cnsmptnForm?.get('state')?.value!='null' && !isorderBy) || isorderBy ) && lstGrid?.length!=0">District</th>
                                                        <th style="width: 25%" class="text-center" *ngIf="usrLvl=='State'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='State'">District</th>
                                                        <th style="width: 25%" class="text-center" *ngIf="usrLvl=='District'">State</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="usrLvl=='District'">District</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="(((cnsmptnForm?.get('district')?.value!=null && 
                                                            cnsmptnForm?.get('district')?.value!='null') || (cnsmptnForm?.get('isFacility')?.value =='true')) || (cnsmptnForm?.get('storeIds')?.value?.length!=0 &&
                                                            cnsmptnForm?.get('storeIds')?.value != null)) && lstGrid?.length!=0">Facility</th>
                                                        <th style="width: 20%" class="text-center" *ngIf="(((cnsmptnForm?.get('district')?.value!=null && 
                                                            cnsmptnForm?.get('district')?.value!='null') || (cnsmptnForm?.get('isFacility')?.value =='true')) || (cnsmptnForm?.get('storeIds')?.value?.length!=0 &&
                                                            cnsmptnForm?.get('storeIds')?.value != null)) && lstGrid?.length!=0">Facility Tag</th>
                                                        <!-- <th style="width: 5%" title="Temperature" class="toggle-data text-center">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                                <path d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                                            </svg>
                                                        </th> -->
                                                    </tr>
                                                </table>
                                            </th>                                            
                                            <th *ngFor="let hdr of lstGrid[0]?.hdrs" class="tbl-hdr-clr p-2">{{hdr}}</th>
                                        </tr>
                                        <tr>
                                            <th colspan="5" class="freeze tbl-hdr-clr">
                                                <table class="w-300p break-word-td">
                                                    <tr>
                                                        <th style="width: 25%"></th>
                                                        <th style="width: 75%">Total</th>
                                                    </tr>
                                                </table>
                                            </th>                                         
                                            <th *ngFor="let ttl of ttlCount" class="tbl-hdr-clr p-2 pntr">{{ttl}}</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="lstGrid.length!=0">
                                        <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: ttlItms }; let i=index">
                                            <td colspan="5" class="freeze">
                                                <table class="w-450p break-word-td">
                                                    <tr>
                                                        <td style="width: 15%" class="text-left">{{(pageSize*(crntPage-1))+i+1}}</td>
                                                        <td style="width: 25%" class="text-left f-14" *ngIf="usrLvl=='National'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-left f-14" *ngIf="usrLvl=='National' && ((cnsmptnForm?.get('state')?.value!=null && 
                                                            cnsmptnForm?.get('state')?.value!='null' && !isorderBy) || isorderBy ) && lstGrid?.length!=0">{{item.kAdrs}}</td>
                                                        <td style="width: 25%" class="text-left f-14" *ngIf="usrLvl=='State'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-left f-14" *ngIf="usrLvl=='State'">{{item.kAdrs}}</td>
                                                        <td style="width: 25%" class="text-left f-14" *ngIf="usrLvl=='District'">{{item.kName}}</td>
                                                        <td style="width: 20%" class="text-left f-14" *ngIf="usrLvl=='District'">{{item.kAdrs}}</td>
                                                        <td style="width: 20%" class="text-left f-14" *ngIf="(((cnsmptnForm?.get('district')?.value!=null && 
                                                            cnsmptnForm?.get('district')?.value!='null') || (cnsmptnForm?.get('isFacility')?.value =='true')) || (cnsmptnForm?.get('storeIds')?.value?.length!=0 &&
                                                            cnsmptnForm?.get('storeIds')?.value != null)) && lstGrid?.length!=0">{{item.ccp}}</td>
                                                        <td style="width: 20%" class="text-left f-14" *ngIf="(((cnsmptnForm?.get('district')?.value!=null && 
                                                            cnsmptnForm?.get('district')?.value!='null') || (cnsmptnForm?.get('isFacility')?.value =='true')) || (cnsmptnForm?.get('storeIds')?.value?.length!=0 &&
                                                            cnsmptnForm?.get('storeIds')?.value != null)) && lstGrid?.length!=0">{{item.storeBadge}}</td>
                                                        <!-- <td class="text-center" style="width: 5%"></td> -->
                                                    </tr>
                                                </table>
                                            </td>
                                            <td [ngStyle]="{ 'backgroundColor': col.stockIndicator != null ? col.stockIndicator.backgroundColor : null,
                                                'color': col.stockIndicator != null ? '#000000' : null }" *ngFor="let col of item.matData" >
                                                {{col.productCount}}
                                                 <!-- <span *ngIf="col.productCount !=null"></span> <span *ngIf="col.productCount == null">NA</span> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="pb-3 pt-3">
                                <div class="row" *ngIf="lstGrid?.length!=0">
                                    <div class="col-xl-6 text-start">
                                        {{pageEntrs}}
                                    </div>
                                    <div class="col-xl-6">
                                        <div class="float-end">
                                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-map1" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                            <div class="card border-0">
                                <div class="card-body p-2">
                                    <div class="clearfix"></div>
                                    <div id="map-container-google-1" class="z-depth-1-half map-container"
                                        style="width: 100%; height: 500px">
                                        <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                            width="100%" height="100%" frameborder="0" style="border:0"
                                            allowfullscreen></iframe>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>