import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-transporters',
  templateUrl: './transporters.component.html',
  styleUrls: ['./transporters.component.css']
})

export class TransportersComponent extends BaseComponent implements OnInit {

  paramTab: any; prmsns: any = null; trnsprtrsList: boolean = true; createTrnsprtrs: boolean = true;

  
  constructor(public glblSvc: GlobalService
    , private route: ActivatedRoute
    , private router: Router) {
    super(glblSvc);
    this.route.queryParams.subscribe(params => { this.paramTab = params.tab; })     
  }

  ngOnInit(): void {
    this.prmsns = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions?.Setup : null;
    this.prmsns.filter((e: any) =>
    {
    if(e?.menuCode =='mm_st_tp_li')
     this.trnsprtrsList = e?.action;
     if(e?.menuCode =='mm_st_tp_ct')
     this.createTrnsprtrs = e?.action;
  });
  if(this.trnsprtrsList)
    this.onTabSelection('list')
    else if(this.createTrnsprtrs)
    this.onTabSelection('new')
  }

  onTabSelection(tabName: any) {
    this.updateQueryParams({ tab: tabName });
  }

  updateQueryParams(queryParams: any) {
    this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
  }

}