<div class="card p-0 border-0">
    <div class="card-body p-2">
        <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
            <li class="nav-item"><a class="nav-link" id="orders-shipments-tab" data-bs-toggle="tab"
                    href="#orders-shipments" role="tab" aria-controls="orders-shipments" aria-selected="true"
                    (click)="onTabSelection('list')"
                    [ngClass]="paramTab === 'list' || paramTab === 'sts' ? 'active' : '' ">Shipments</a></li>
        </ul>
        <div class="tab-content" id="top-tabContent">
            <div class="tab-pane fade" id="orders-shipments" role="tabpanel" aria-labelledby="orders-shipments-tab"
                [ngClass]="paramTab === 'list' ? 'show active' : '' ">
                <evin-shipments-list></evin-shipments-list>
            </div>
            <div class="tab-pane fade " id="orders-shipments2" role="tabpanel" aria-labelledby="orders-shipments-tab"
                [ngClass]="paramTab === 'sts' ? 'show active' : '' ">
                <evin-shipments-status></evin-shipments-status>
            </div>
        </div>
    </div>
</div>