<div class="card p-0 pb-0 mt-2 pt-2">
    <div class="card-header shadow-sm p-2">
        <h6 class="mb-0">Dashboard</h6>
    </div>
    <div class="card-body p-2 mt-0 mb-0">
        <form>
            <div class="row">
                <div class="col-xl-12 col-sm-12 col-xs-12">
                    <div class="row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="textBox">Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Name" class="form-control" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Description</label>
                            <input type="text" placeholder="Description" class="form-control" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Title</label>
                            <input type="text" placeholder="Title" class="form-control" id="">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="textBox">Sub-title</label>
                            <input type="text" placeholder="Sub-title" class="form-control" id="">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="card h-100 border p-0 pb-0 pt-2">
    <div class="card-header shadow-sm p-2">
        <div class="float-start">
            <h6 class="mb-0">Widgets</h6>
        </div>
        <div class="float-end">
            <div class="mb-0"><button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop" *ngIf="cnfgCstDbPrmsn">Add Widgets</button></div>
        </div>
    </div>
    <div class="card-body p-2 mt-0 mb-0">
        <div class="row">
            <div class="col-sm-12 col-xl-6">
                <div class="card border shadow-sm rounded-0 mt-3">
                    <div class="card-header rounded-0 p-2">
                        <div class="h6 float-start">Asset count</div>
                        <div class="small float-end"><span><i class="fa fa-bars" aria-hidden="true"
                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop1"></i></span>
                            <span class="ms-2"><i class="fa fa-trash" aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <div class="card-body rounded-0 p-2">
                        <p class="text-center p-4 f-24">Widget</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="staticBackdrop">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
             <h6 class="modal-title">Widgets</h6>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" *ngIf="cnfgCstDbPrmsn"></button>
            </div>    
            <div class="modal-body">
                <div class="table-responsive m-2">
                    <table class="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td>
                                    Asset count
                                </td>
                                <td class="text-center"><button  *ngIf="cnfgCstDbPrmsn" type="button" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Asset status donut
                                </td>
                                <td class="text-center"><button  *ngIf="cnfgCstDbPrmsn" type="button" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Asset status map
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Asset top ten locations
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Facility activity bar
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Facility count
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Event map
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Event summaries text
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Event summaries user profile
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Inventory item count
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Inventory status donut
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Inventory status map
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Inventory top ten locations
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Inventory top ten materials
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Immunize report
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Stock availability or abnormality trends
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                            <tr>
                                <td>
                                    Temperature excursion trends
                                </td>
                                <td class="text-center"><button type="button"  *ngIf="cnfgCstDbPrmsn" class="btn btn-sm btn-light"><i class="fa fa-plus" aria-hidden="true"></i></button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal"  *ngIf="cnfgCstDbPrmsn">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="staticBackdrop1">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
            <div class="modal-header">
             <h6 class="modal-title">Widgets</h6>
             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"  *ngIf="cnfgCstDbPrmsn"></button>
            </div>
            <div class="modal-body">                
                <div class="row">
                    <div class="form-group col-sm-4 mt-2">
                        <label class="textBox" for="flexCheckDefault">
                            Asset type
                        </label>
                        <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                            <optgroup label="">
                              <option value="AL">Deep freezer</option>
                              <option value="WY">ILR</option>
                              <option value="WY">Walk-in cooler</option>
                              <option value="WY">Walk-in freezer</option>
                              <option value="WY">Radiant warmer</option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="form-group col-sm-4 mt-2">
                        <label class="textBox" for="flexCheckDefault">
                            Period (since)
                        </label>
                            <select id="inputState" class="form-select">
                                <option selected>Now</option>
                                <option>30 minutes</option>
                                <option>1 hour</option>
                                <option>2 hours</option>
                                <option>3 hours</option>
                                <option>6 hours</option>
                                <option>12 hours</option>
                            </select>
                    </div>
                    <div class="form-group col-sm-4 mt-2">
                        <label class="textBox" for="flexCheckDefault">
                            Exclude temperature state
                        </label>
                        <select class="js-example-basic-hide-search select_sm" multiple="multiple">
                            <optgroup label="">
                              <option value="AL">Low</option>
                              <option value="WY">Normal</option>
                              <option value="WY">High</option>
                              <option value="WY">Unkonwn</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-primary"  *ngIf="cnfgCstDbPrmsn">Save</button>
                <button type="button" class="btn btn-sm btn-light"  *ngIf="cnfgCstDbPrmsn" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>