import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-login-old',
  templateUrl: './login-old.component.html',
  styleUrls: ['./login-old.component.css']
})

export class LoginOldComponent extends BaseComponent implements OnInit {

  isShow = false; isLogd = false; isRmbrChk: boolean = false; capData: any; lsIP: string = ''; lgnUsrId: any; capImg: any;
  lgnFrm = this.formBuilder.group({ user_id: ['', Validators.required], password: ['', Validators.required], // cptch: ['', Validators.required],
    auth_token: environment.lgnDefJWT, sourceType: 2 }); isMigURL = environment.isMigURL;

  constructor(public glblSvc: GlobalService,
    private router: Router,
    private formBuilder: FormBuilder,
    private domSntzr: DomSanitizer) {
    super(glblSvc);
    this.glblSvc.sbjctCptcha.subscribe(s => {
      if (s)
        this.capData = s, this.capImg = this.domSntzr.bypassSecurityTrustResourceUrl('data:image/png;base64,' + this.capData.captchaImg),
          this.lgnFrm.get('cptch')?.setValue(''), this.isLogd = false;
    });
  }

  get fc() { return this.lgnFrm.controls; }

  ngOnInit(): void {
    this.isRmbrChk = false, this.setElmntFocus('lgnEmail');
    if (localStorage.getItem('lgnSsn') != null) {
      let lgnSsnData = JSON.parse(<string>localStorage.getItem('lgnSsn')),
        pswd = lgnSsnData.usrPswd.substring(0, lgnSsnData.usrPswd.length - environment.rndmTxtLen);
      this.lgnFrm.get('user_id')?.setValue(lgnSsnData.usrName), this.lgnFrm.get('password')?.setValue(atob(pswd)), this.isRmbrChk = true;
      setTimeout(() => { localStorage.setItem('lgnSsn', JSON.stringify({ usrName: lgnSsnData.usrName, usrPswd: lgnSsnData.usrPswd })) }, 500);
    }
    localStorage.clear(), this.glblSvc.GetPCsIP(0).subscribe((s: any) => { this.lsIP = s.IPv4; }); //, this.onGenCaptcha(), this.glblSvc.setIntrvlDT(false);
  }

  onGenCaptcha(isRld = false) {
    this.lgnFrm.get('cptch')?.setValue(''), this.FetchData(environment.apiCalls.get, environment.apiPaths.captcha, isRld ?
        environment.apiMthds.reGenCptch + this.capData?.captchaId : environment.apiMthds.genCptch, null, 3).then(() => {
      let resCap = this.result;
      if (this.result) {
        if (this.result.status == 'OK')
          this.glblSvc.sbjctCptcha.next(this.result.data), setTimeout(() => { if (localStorage.getItem('lgnSsn') != null) this.setElmntFocus('lgnCptch'); }, 500);
        else
          this.glblSvc.onTstr('i', this.result.message)
      }
      else
        this.glblSvc.onTstr('e', resCap.message)
    });
  }

  onValdCaptcha() {
    this.isLogd = true, this.checkFormValidations(this.lgnFrm);
    if (this.lgnFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else {
      if (this.capData) {
        this.lgnFrm.get('cptch')?.setValue(`${this.lgnFrm.get('cptch')?.value}`.trim()), this.FetchData(environment.apiCalls.get,
            environment.apiPaths.captcha, environment.apiMthds.valdCptch.replace('capID', this.capData.captchaId) +
            `${this.lgnFrm.get('cptch')?.value}`.trim(), null, 3).then(() => { let resCap = this.result;
          if (this.result) {
            if (this.result.status == 'OK')
              this.onSignIn();
            else
              this.glblSvc.onTstr('i', this.result.message)
          }
          else
            this.glblSvc.onTstr('e', resCap.message)
        });
      }
    }
  }

  onShwHde() {
    if (this.lgnFrm.get('password')?.value != '')
      this.isShow = !this.isShow, this.ShowHidePwd('lgnPswd', this.isShow);
  }

  onSignIn() {
    this.isLogd = true, this.checkFormValidations(this.lgnFrm);
    if (this.lgnFrm.invalid)
      this.glblSvc.onTstr('w', 'Please fill all details');
    else {
      let lgnPL = this.lgnFrm.getRawValue(), realPswd = this.lgnFrm.get('password')?.value;
      lgnPL.password = btoa(lgnPL.password) + this.GenUnqText(); //, delete lgnPL.cptch;
      Object.assign(lgnPL, { user_model: { browser_details: this.GetBrwsrInfo(), ip_address: this.lsIP, logged_from_mobile: false,
        mobile_app_version: null, login_time: this.glblSvc.setDtFrmt(new Date(), this.dtF.ymd, this.tmF.hms), last_reconnected_time: null }});
      this.glblSvc.sbjctSpnr.next(true), this.glblSvc.loginAPI(environment.apiPaths.loginout, environment.apiMthds.login, lgnPL).subscribe((s: any) => {
        this.glblSvc.sbjctSpnr.next(false);
        if (s.data == null) {
          this.glblSvc.onTstr('e', s?.message), this.isLogd; // , this.onGenCaptcha(true)
          return;
        }
        this.lgnUsrId = s.data.userId;
        if (this.isRmbrChk)
          localStorage.setItem('lgnSsn', JSON.stringify({ usrName: lgnPL.user_id, usrPswd: lgnPL.password }));
        else
          localStorage.removeItem('lgnSsn');
        if (s) {
          if (s.message === 'User LoggedIn Successfully' && s.status === 'OK') {
            if (s.data.roleId == 1) {
              this.glblSvc.onTstr('i', 'You are not supposed to use Web portal');
              return;
            }
            let lstAllStrs: any[] = []
            localStorage.setItem('lgnUsrDtls', JSON.stringify(s.data)), this.glblSvc.sbjctJWT.next(s.data.token), // this.glblSvc.setIntrvlDT(true),
              this.FetchData(environment.apiCalls.get, environment.apiPaths.pranth, environment.apiMthds.getAll, null).then(() => {
                if (this.result) {
                  if (this.result.status == 'OK') {
                    localStorage.setItem('lgnUsrPrths', JSON.stringify(this.result.data)), this.glblSvc.sbjctPrnthList.next(this.result.data),
                      this.FetchData(environment.apiCalls.get, environment.apiPaths.store, environment.apiMthds.getAllStrs + s.data.pranthId, null).then(() => {
                        // environment.apiMthds.getStrsbyUserId.replace('uuu', s.data.userId) + s.data.pranthId, null).then(() => {
                      if (this.result) {
                        if (this.result.status == 'OK') {
                          // lstAllStrs = this.result?.data?.storeFiltersDTOs != null ? this.result?.data?.storeFiltersDTOs : [],
                          lstAllStrs = this.result?.data != null ? this.result?.data : [],
                            localStorage.setItem('lgnUsrStrs', JSON.stringify(lstAllStrs)), this.glblSvc.sbjctStrList.next(lstAllStrs),
                            this.FetchData(environment.apiCalls.get, environment.apiPaths.badge, environment.apiMthds.getAll, null, 2, true, 1).then(() => {
                            if (this.result) {
                              if (this.result.status == 'OK') {
                                localStorage.setItem('appBdgs', JSON.stringify(this.result.data)), this.glblSvc.sbjctBdgsList.next(this.result.data),
                                  sessionStorage.clear(), localStorage.setItem('isLgd', '1'), this.router.navigate([this.isMigURL ? environment.lndgPathClnt : environment.lndgPathGnrl]); // 'home/puc' ['home/db-ovrvw'] ['home/ovrvw'];
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
            });
          }
          else
            this.glblSvc.onTstr('e', s.message);
        }
      });
    }
  }

  GetBrwsrInfo() {
    if (navigator.userAgent.includes('Edg/'))
      return `Edge v${navigator.userAgent.split('Edg/')[1]}`; // this.glblSvc.onTstr('d', 'Edge');
    else if (navigator.userAgent.includes('OPR/'))
      return `Opera v${navigator.userAgent.split('OPR/')[1]}`; // this.glblSvc.onTstr('d', 'Opera');
    else if (navigator.userAgent.includes('Chrome/'))
        return `Chrome v${navigator.userAgent.split('Chrome/')[1].split(' ')[0]}`; // this.glblSvc.onTstr('d', 'Chrome');
    else if (navigator.userAgent.includes('Firefox/'))
      return `Firefox v${navigator.userAgent.split('Firefox/')[1]}`; // this.glblSvc.onTstr('d', 'Firefox');
    else if (navigator.userAgent.includes('Safari/'))
      return `Safari ${navigator.userAgent.split('Version/')[1].replace('Safari/','(')})`; // this.glblSvc.onTstr('d', 'Safari');
    else
      return ''; // this.glblSvc.onTstr('d', 'Unknown');
  }

  onClkFP() {
    this.router.navigate(['frgt-pswd']);
  }

}