import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'evin-rplnsh-rspn-time-overview',
  templateUrl: './rplnsh-rspn-time-overview.component.html',
  styleUrls: ['./rplnsh-rspn-time-overview.component.css']
})
export class RplnshRspnTimeOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
