<div class="card p-0">
    <div class="card-header shadow-none p-3">
        <div class="h6 float-start">Indents configuration </div>
        <!-- <div class="small float-end">Last updated on 24/1/18 4:07 PM by <a [routerLink]="">Devdutt Mishra</a></div> -->
    </div>
    <div class="card-body p-2 mb-0">
        <form [formGroup]="cnfgOrdFrm" autocomplete="off">
            <div class="row" formGroupName="configJson">
                <div class="col-sm-12">
                    <div class="card shadow-sm p-0 mt-0 mb-0">
                        <div class="card-header p-2">
                            <h6 class="mb-0">General</h6>
                        </div>
                        <div class="card-body p-2" formGroupName="general">
                            <div class="small">Specify the general configuration for Indents.</div>
                            <div class="row mb-0 mt-3">
                                <div class="form-group col-xl-12 mb-2">
                                    <div class="row">
                                        <div class="col-sm-12" formGroupName="receipt_order_generation">
                                            <label for="textBox" class="f-w-500 mb-2 me-2">Receipt Indent
                                                generation</label><br />
                                            <div class="form-check form-check-inline">
                                                <input id="generate_indent_receipt_when_ordertransactions_are_received" class="form-check-input" type="checkbox"
                                                    formControlName="generate_indent_receipt_when_ordertransactions_are_received">
                                                <label class="form-check-label" for="generate_indent_receipt_when_ordertransactions_are_received">
                                                    Generate a indent (receipt), when Indent transactions are received
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="just_capture_demand_do_not_generate_indent_receipt"
                                                    formControlName="just_capture_demand_do_not_generate_indent_receipt">
                                                <label class="form-check-label" for="just_capture_demand_do_not_generate_indent_receipt">
                                                    Just capture demand, do not generate a indent (receipt)
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-2" formGroupName="automatic_posting_of_issue_or_receipts">
                                            <label for="textBox" class="f-w-500 mb-2 me-2">Automatic posting of issue or
                                                receipt transactions</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="autoPostIssue"
                                                    formControlName="automatically_post_issues_receipts_on_fulfilling_order">
                                                <label class="" for="autoPostIssue">
                                                    Automatically post issues/net utilization on shipping and receipts
                                                    on fulfilling an Indent.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-2" formGroupName="transporter">
                                            <label for="textBox" class="f-w-500 mb-2">Transporter</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="transporter_specification_order_mandatory_before_shipping"
                                                    formControlName="transporter_specification_order_mandatory_before_shipping">
                                                <label class="form-check-label" for="transporter_specification_order_mandatory_before_shipping">
                                                    The transporter specification on an Indent is mandatory before
                                                    shipping it
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline" [hidden]="true">
                                                <input class="form-check-input" type="checkbox" id="appendtransporter_detailsto_order_status_message_on_shipping"
                                                    formControlName="appendtransporter_detailsto_order_status_message_on_shipping">
                                                <label class="form-check-label" for="appendtransporter_detailsto_order_status_message_on_shipping">
                                                    Append transporter details to Indent status message on shipping
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-2" [hidden]="true">
                                            <label for="textBox" class="f-w-500 mb-2">Allow empty Indents?</label><br />
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="allow_empty_orders"
                                                    formControlName="allow_empty_orders">
                                                <label class="form-check-label" for="allow_empty_orders">
                                                    Allow Indents with no items
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 mt-2">
                                            <div class="col-sm-12 mt-2" formGroupName="mark_indent_as_confirmed">
                                                <label for="textBox" class="f-w-500 mb-2">Mark indent (issue) as
                                                    confirmed?</label><br />
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="allow_marking_indent_as_confirmed_during_order_creation"
                                                        formControlName="allow_marking_indent_as_confirmed_during_order_creation">
                                                    <label class="form-check-label" for="allow_marking_indent_as_confirmed_during_order_creation">
                                                        Allow marking indent (issue) as confirmed during Indent creation
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-2" formGroupName="disable_auto_allocation">
                                                <label for="textBox" class="f-w-500 mb-2">Disable auto allocation</label><br />
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox" id="make_auto_allocation_disable_on_marking"
                                                        formControlName="make_auto_allocation_disable_on_marking">
                                                    <label class="form-check-label" for="make_auto_allocation_disable_on_marking">
                                                        Make auto allocation disable on marking
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-2" formGroupName="allocate_stocks_on_confirmation" [hidden]="true">
                                                <label for="textBox" class="f-w-500 mb-2">Allocate stocks on
                                                    confirmation?</label><br />
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="allow_allocating_stocks_on_marking_order_status_as_confirmed"
                                                        formControlName="allow_allocating_stocks_on_marking_order_status_as_confirmed">
                                                    <label class="form-check-label" for="allow_allocating_stocks_on_marking_order_status_as_confirmed">
                                                        Allow allocating stocks on marking Indent status as confirmed
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 mt-2" formGroupName="auto_assign_material_status" [hidden]="true">
                                                <label for="textBox" class="f-w-500 mb-2">Auto-assign material
                                                    status</label><br />
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="automatically_assign_the_material_status_to_items_when_order_is_allocated"
                                                        formControlName="automatically_assign_the_material_status_to_items_when_order_is_allocated">
                                                    <label class="form-check-label" for="automatically_assign_the_material_status_to_items_when_order_is_allocated">
                                                        Automatically assign the material status to items when the Indent
                                                        is
                                                        allocated (manually or automatically)
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-sm-6 mt-2" [hidden]="true">
                                                <label class="textBox mb-0" for="flexCheckDefault">
                                                    Payment options
                                                </label>
                                                <input type="text" placeholder="Payment options" class="form-control"
                                                    id="" formControlName="payment_options">
                                                <small>(Enter comma-separated values of payment options)</small>
                                            </div>
                                            <div class="form-group col-sm-6 mt-2">
                                                <label class="textBox mb-0" for="flexCheckDefault">
                                                    Package sizes
                                                </label>
                                                <input type="text" placeholder="Package sizes" class="form-control"
                                                    id="" formControlName="package_sizes">
                                                <small>(Enter comma-separated values of package sizes)</small>
                                            </div>
                                            <div class="form-group col-sm-12 mt-2" hidden>
                                                <label for="inputEmail4" class="col-form-label">Issuing Facility <span
                                                        class="text-danger"><sup>*</sup></span></label>
                                                <ng-template #cstmIsuStore let-model="item">
                                                    <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                                    <span class="fs-12">{{model.location}}</span>
                                                </ng-template>
                                                <input name="store" type="text" placeholder="Type to get suggetions..."
                                                    id="Store" formControlName="issuing_store_default"
                                                    [typeahead]="lstPranths" [typeaheadItemTemplate]="cstmIsuStore"
                                                    typeaheadOptionField="storeName" [adaptivePosition]="true" [typeaheadOptionsLimit]="500"
                                                    class="form-control" [typeaheadScrollable]="true" #IssStore
                                                    [typeaheadScrollable]="true" (typeaheadOnSelect)="changeFn($event)">
                                                <small>(This issuing facility will become the default issuing facility
                                                    for an Indent where no issuing facility is specified)</small>
                                            </div>
                                        </div>
                                        <div class="row" >
                                            <div class="col-sm-12" hidden>
                                                <p class="fw-bold mt-3">Allow creation of the following types of facilities </p>
                                            </div>
                                            <div class="col-sm-6 mt-2" formGroupName="order_price" hidden>
                                                <label class="textBox f-w-500 mb-2" for="flexCheckDefault">
                                                    Indent price
                                                </label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="disable_order_pricing" formControlName="disable_order_pricing">
                                                    <label class="form-check-label" for="disable_order_pricing">
                                                        Disable Indent pricing
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-sm-6" formGroupName="transfer_release_orders">
                                                <label class="textBox f-w-500 mt-2" for="flexCheckDefault">
                                                    Transfer/Release Indents
                                                </label>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="rename_transfer_orders_as_release_orders"
                                                        formControlName="rename_transfer_orders_as_release_orders">
                                                    <label class="form-check-label" for="rename_transfer_orders_as_release_orders">
                                                        Rename Transfer Indents as Release Indents
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-3 mb-3" formGroupName="mandatory_order_fields">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Mandatory Indent fields</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">
                                    Specify the fields that should be mandatory in an Indent at different scategoryes of
                                    the Indent lifecycle.
                                </div>
                                <div class="row mb-0 mt-3">
                                    <div class="form-group col-xl-12 mb-2" formGroupName="before_shipping_an_order">
                                        <label for="textBox" class="f-w-500 mb-2">Before shipping an Indent</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="make_issue_reference_num_mandatory_before_shipping"
                                                formControlName="make_issue_reference_num_mandatory_before_shipping">
                                            <label class="form-check-label" for="make_issue_reference_num_mandatory_before_shipping">
                                                Make 'Issue reference #' mandatory before shipping
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="make_estimated_date_of_arrival_mandatory_before_shipping"
                                                formControlName="make_estimated_date_of_arrival_mandatory_before_shipping">
                                            <label class="form-check-label" for="make_estimated_date_of_arrival_mandatory_before_shipping">
                                                Make 'Estimated date of arrival' mandatory before shipping
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-12 mt-2" formGroupName="reference_num">
                                        <label for="textBox" class="f-w-500 mb-2">Reference #</label>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="make_receipt_reference_num_mandatory_at_the_time_of_indent_creation_or_editing"
                                                formControlName="make_receipt_reference_num_mandatory_at_the_time_of_indent_creation_or_editing">
                                            <label class="form-check-label" for="make_receipt_reference_num_mandatory_at_the_time_of_indent_creation_or_editing">
                                                Make 'Receipt reference #' mandatory at the time of indent (receipt)
                                                creation or editing
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="make_transfer_release_reference_num_mandatory_at_the_time_of_transfer_release_order_creation_or_editing"
                                                formControlName="make_transfer_release_reference_num_mandatory_at_the_time_of_transfer_release_order_creation_or_editing">
                                            <label class="form-check-label" for="make_transfer_release_reference_num_mandatory_at_the_time_of_transfer_release_order_creation_or_editing">
                                                Make 'Transfer/release reference #' mandatory at the time of
                                                transfer/release Indent creation or editing
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card p-0 mt-3 mb-3" formGroupName="orders_export">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Indents export</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">
                                    Enable periodic export of Indents in spreadsheets, which are emailed to users specified below.
                                </div>
                                <div class="row mb-0 mt-3" formGroupName="orders_export_schedule">
                                    <div class="form-group col-xl-12 mb-2">
                                        <label for="textBox" class="f-w-500 mb-2 me-2">Indents export schedule</label><br />
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="enable_orders_export"
                                                formControlName="enable_orders_export" (change)="indntDataExpt=!indntDataExpt">
                                            <label class="form-check-label" for="enable_orders_export">
                                                Enable Indents export
                                            </label>
                                        </div>
                                        <div class="card border p-0 shadow-sm col-xl-8" *ngIf="indntDataExpt" formGroupName="enabled_orders_data_export">
                                            <div class="card-body p-4">
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-6">
                                                        <div class="f-w-500">Every day at hour(s) (hh:mm)</div>
                                                        <div>
                                                            <input type="text" class="col-xl-3 form-control form-control-sm"
                                                                placeholder="hh:mm" (change)="onAddHrs($event)"
                                                                onkeypress='return ((event.charCode >= 48 && event.charCode <= 57) ||
                                                                event.charCode == 58 || event.charCode == 44)' formControlName="everyday_hours">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-row row mb-2">
                                                    <div class="form-group col-xl-12">
                                                        <div class="f-w-500">To</div>
                                                        <div>
                                                            <div class="f-w-250">Select groups of users by tags</div>
                                                            <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="userBadges"
                                                                (onSelect)="onChng($event, 'usrBdge', 'os')" [(ngModel)]="defaultUsrTags"
                                                                (onSelectAll)="onChng($event, 'usrBdge', 'osa')" (onDeSelect)="onChng($event, 'usrBdge', 'ds')"
                                                                (onDeSelectAll)="onChng($event, 'usrBdge', 'dsa')" formControlName="group_of_user_tags">
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                        <div class="mt-2">
                                                            <div class="f-w-250">Select individual users</div>
                                                            <ng-multiselect-dropdown [settings]="usrDrpdwn" [data]="userLst"
                                                                (onSelect)="onChng($event, 'owner', 'os')" [(ngModel)]="defaultUsers"
                                                                (onSelectAll)="onChng($event, 'owner', 'osa')" (onDeSelect)="onChng($event, 'owner', 'ds')"
                                                                (onDeSelectAll)="onChng($event, 'owner', 'dsa')" formControlName="group_of_users">
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="card p-0 mt-3 mb-3" formGroupName="order_reasons">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Indent reasons</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="row mb-0" hidden>
                                    <div class="form-group col-xl-6 mb-2"
                                        formGroupName="reasons_for_overriding_order_recommendation">
                                        <label for="textBox">Reasons for overriding Indent recommendation</label>
                                        <input class="form-control" type="text" id="flexCheckChecked"
                                            placeholder="Enter comma seperated values" formControlName="value">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="mandatory">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Make this field mandatory
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mb-2"
                                        formGroupName="reasons_for_changing_order_quantity">
                                        <label for="textBox">Reasons for changing Indent quantity</label>
                                        <input class="form-control" type="text" id="flexCheckChecked"
                                            placeholder="Enter comma seperated values" formControlName="value">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="mandatory">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Make this field mandatory
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mb-2" formGroupName="reasons_for_partial_shipments" hidden>
                                        <label for="textBox">Reasons for partial shipments</label>
                                        <input class="form-control" type="text" id="flexCheckChecked"
                                            placeholder="Enter comma seperated values" formControlName="value">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="mandatory">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Make this field mandatory
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mb-2"
                                        formGroupName="reasons_for_partial_fulfillment">
                                        <label for="textBox">Reasons for partial fulfillment</label>
                                        <input class="form-control" type="text" id="flexCheckChecked"
                                            placeholder="Enter comma seperated values" formControlName="value">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="mandatory">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Make this field mandatory
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mb-2"
                                        formGroupName="reasons_for_cancelling_an_order">
                                        <label for="textBox">Reasons for cancelling an Indent</label>
                                        <input class="form-control" type="text" id="flexCheckChecked"
                                            placeholder="Enter comma seperated values" formControlName="value">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="mandatory">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Make this field mandatory
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-0" >
                                    <div class="row mt-3">
                                        <div>
                                            <div class="nav nav-tabs border-tab mb-2" id="v-pills-tab" role="tablist"
                                                aria-orientation="vertical">
                                                <a class="nav-link f-w-500 active" id="top-d-tab1-tab" data-bs-toggle="tab"
                                                    href="#top-d-tab1" role="tab" aria-controls="top-tab1"
                                                    aria-selected="true">Overriding Indent recommendation</a>
                                                <a class="nav-link f-w-500" id="d-tab2-top-tab" data-bs-toggle="tab"
                                                    href="#d-top-tab2" role="tab" aria-controls="top-tab2"
                                                    aria-selected="false">Changing Indent quantity</a>
                                                <a class="nav-link f-w-500" id="d-tab3-top-tab" data-bs-toggle="tab"
                                                    href="#d-top-tab3" role="tab" aria-controls="top-tab3"
                                                    aria-selected="false" hidden>Partial shipments</a>
                                                <a class="nav-link f-w-500" id="d-tab4-top-tab" data-bs-toggle="tab"
                                                    href="#d-top-tab4" role="tab" aria-controls="top-tab4"
                                                    aria-selected="false">Partial fulfillment</a>
                                                <a class="nav-link f-w-500" id="d-tab5-top-tab" data-bs-toggle="tab"
                                                    href="#d-top-tab5" role="tab" aria-controls="top-tab5"
                                                    aria-selected="false">Cancelling an Indent</a>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="tab-content" id="top-tabContent">
                                                <div class="tab-pane fade show active" id="top-d-tab1" role="tabpanel"
                                                    aria-labelledby="top-d-tab1-tab">
                                                    <div class="col-xl-6 mb-2">
                                                        <div class="table-responsive mt-2">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w-24">Item No.</th>
                                                                        <th class="text-center">Reason</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of lstTblData?.ovrOrdRec; let i=index">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td class="text-center">{{item.reasonName}}</td>
                                                                        <td><i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true"
                                                                                (click)="onAddDel('OOR', item, 'd')"></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody [hidden]="lstTblData?.ovrOrdRec?.length!=0">
                                                                    <tr>
                                                                        <td class="text-center" colspan="3">
                                                                            {{noRcrdTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <input type="text" class="form-control form-control-sm w-75"
                                                                                placeholder="Create custom Reason"
                                                                                [(ngModel)]="OORTxt" [ngModelOptions]="{standalone: true}"
                                                                                >
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-sm btn-success"
                                                                            (click)="onAddDel('OOR', OORTxt, 'a', oorRsn);OORTxt='';">Add Reasons</button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <input class="form-check-input" type="checkbox" id="oorCheck"
                                                                                (change)="oorRsn=!oorRsn;onChkRsn('OOR', oorRsn)" [checked]="oorRsn"
                                                                                [attr.disabled]="lstTblData?.ovrOrdRec?.length == 0 ? true : null">&nbsp;&nbsp;
                                                                                <!-- [checked]="lstTblData?.ovrOrdRec[0]?.isReasonMandatory" -->
                                                                            <label class="form-check-label">
                                                                                Make this field mandatory
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                        <!-- <div class="text-end pb-3 pt-3">
                                                          
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="d-top-tab2" role="tabpanel"
                                                    aria-labelledby="d-tab2-top-tab">
                                                    <div class="col-xl-6 mb-2">
                                                        <div class="table-responsive mt-2">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w-24">Item No.</th>
                                                                        <th class="text-center">Reason</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of lstTblData?.chngOrdQnty; let i=index">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td class="text-center">{{item.reasonName}}</td>
                                                                        <td><i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true"
                                                                                (click)="onAddDel('COQ', item, 'd')"></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody [hidden]="lstTblData?.chngOrdQnty?.length!=0">
                                                                    <tr>
                                                                        <td class="text-center" colspan="3">
                                                                            {{noRcrdTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <input type="text" class="form-control form-control-sm w-75"
                                                                                placeholder="Create custom Reason"
                                                                                [(ngModel)]="COQTxt" [ngModelOptions]="{standalone: true}"
                                                                                >
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-sm btn-success"
                                                                            (click)="onAddDel('COQ', COQTxt, 'a', coqRsn);COQTxt='';">Add Reasons</button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <input class="form-check-input" type="checkbox" id="coqCheck"
                                                                                (change)="coqRsn=!coqRsn;onChkRsn('COQ', coqRsn)" [checked]="coqRsn"
                                                                                [attr.disabled]="lstTblData?.chngOrdQnty?.length == 0 ? true : null">&nbsp;&nbsp;
                                                                                <!-- [checked]="lstTblData?.chngOrdQnty[0]?.isReasonMandatory" -->
                                                                            <label class="form-check-label">
                                                                                Make this field mandatory
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                        <!-- <div class="text-end pb-3 pt-3">
                                                          
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="d-top-tab3" role="tabpanel"
                                                    aria-labelledby="d-tab3-top-tab" hidden>
                                                    <div class="col-xl-6 mb-2">
                                                        <div class="table-responsive mt-2">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w-24">Item No.</th>
                                                                        <th class="text-center">Reason</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td class="text-center">1</td>
                                                                        <td class="text-center">ABC</td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <input type="text" class="form-control form-control-sm w-75"
                                                                                placeholder="Create custom Reason">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <input class="form-check-input" type="checkbox"
                                                                                (click)="isChk=!isChk">&nbsp;&nbsp;
                                                                            <label class="form-check-label">
                                                                                Make this field mandatory
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="d-top-tab4" role="tabpanel"
                                                    aria-labelledby="d-tab4-top-tab">
                                                    <div class="col-xl-6 mb-2">
                                                        <div class="table-responsive mt-2">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w-24">Item No.</th>
                                                                        <th class="text-center">Reason</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of lstTblData?.PartFulfl; let i=index">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td class="text-center">{{item.reasonName}}</td>
                                                                        <td><i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true"
                                                                                (click)="onAddDel('PF', item, 'd')"></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody [hidden]="lstTblData?.PartFulfl?.length!=0">
                                                                    <tr>
                                                                        <td class="text-center" colspan="3">
                                                                            {{noRcrdTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <input type="text" class="form-control form-control-sm w-75"
                                                                                placeholder="Create custom Reason"
                                                                                [(ngModel)]="PFTxt" [ngModelOptions]="{standalone: true}"
                                                                                >
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-sm btn-success"
                                                                            (click)="onAddDel('PF', PFTxt, 'a', pfRsn);PFTxt='';">Add Reasons</button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <input class="form-check-input" type="checkbox" id="pfCheck"
                                                                                (change)="pfRsn=!pfRsn;onChkRsn('PF', pfRsn)" [checked]="pfRsn"
                                                                                [attr.disabled]="lstTblData?.PartFulfl?.length == 0 ? true : null">&nbsp;&nbsp;
                                                                                <!-- [checked]="lstTblData?.PartFulfl[0]?.isReasonMandatory" -->
                                                                            <label class="form-check-label">
                                                                                Make this field mandatory
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                        <!-- <div class="text-end pb-3 pt-3">
                                                            
                                                        </div> -->
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="d-top-tab5" role="tabpanel"
                                                    aria-labelledby="d-tab5-top-tab">
                                                    <div class="col-xl-6 mb-2">
                                                        <div class="table-responsive mt-2">
                                                            <table class="table table-striped table-sm table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th class="text-center w-24">Item No.</th>
                                                                        <th class="text-center">Reason</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of lstTblData?.CanOrd; let i=index">
                                                                        <td class="text-center">{{i+1}}</td>
                                                                        <td class="text-center">{{item.reasonName}}</td>
                                                                        <td><i class="fa fa-trash toggle-data"
                                                                                aria-hidden="true"
                                                                                (click)="onAddDel('CAO', item, 'd')"></i>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tbody [hidden]="lstTblData?.CanOrd?.length!=0">
                                                                    <tr>
                                                                        <td class="text-center" colspan="3">
                                                                            {{noRcrdTxt}}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                            <input type="text" class="form-control form-control-sm w-75"
                                                                                placeholder="Create custom Reason"
                                                                                [(ngModel)]="CAOTxt" [ngModelOptions]="{standalone: true}"
                                                                                >
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" class="btn btn-sm btn-success"
                                                                            (click)="onAddDel('CAO', CAOTxt, 'a', caoRsn);CAOTxt='';">Add Reasons</button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="3">
                                                                            <input class="form-check-input" type="checkbox" id="caoCheck"
                                                                                (change)="caoRsn=!caoRsn;onChkRsn('CAO', caoRsn)" [checked]="caoRsn"
                                                                                [attr.disabled]="lstTblData?.CanOrd?.length == 0 ? true : null">&nbsp;&nbsp;
                                                                                <!-- [checked]="lstTblData?.CanOrd[0]?.isReasonMandatory" -->
                                                                            <label class="form-check-label">
                                                                                Make this field mandatory
                                                                            </label>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                        <!-- <div class="text-end pb-3 pt-3">
                                                           
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-12">
                        <div class="card p-0 mt-2" formGroupName="demand_board" [hidden]="true">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Demand board</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">
                                    Specify visibility of Demand Board for this domain (whether private or public)
                                </div>
                                <div class="row">
                                    <div class="form-group col-xl-12 mt-2" formGroupName="demand_board_visibility">
                                        <label for="textBox" class="me-2 f-w-500 mb-2">Demand board
                                            visibility</label><br />
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="demand_board_is_private">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Demand board is private
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="demand_board_is_public">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Demand board is public
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 mt-2">
                                        <label for="textBox" class="mb-0">Banner</label>
                                        <input type="text" placeholder="Banner" class="form-control" id=""
                                            formControlName="banner">
                                        <small>(enter a HTML banner within div categories, if you wish to change the
                                            default)</small>
                                    </div>
                                    <div class="form-group col-xl-6 mt-2">
                                        <label for="textBox" class="mb-0">Header</label>
                                        <input type="text" placeholder="Header" class="form-control" id=""
                                            formControlName="header">
                                        <small>(enter a HTML banner within div categories, if you wish to change the
                                            default)</small>
                                    </div>
                                    <div class="form-group col-xl-6 mt-2">
                                        <label for="textBox" class="mb-0">Footer</label>
                                        <input type="text" placeholder="Footer" class="form-control" id="footer"
                                            formControlName="footer">
                                        <small>(enter a HTML copyright within div categories, if you wish to change the
                                            default)</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-2" formGroupName="automate_indent_receipt_creation" [hidden]="true">
                            <div class="card-header p-2">
                                <h6 class="f-w-500 mb-2">Automate indent (receipt) creation</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">Specify the configuration for automating creation of indents
                                    (receipt)
                                </div>
                                <div class="form-group col-xl-12 mt-3 mb-2">
                                    <label for="textBox" class="f-w-500 mb-2 me-2">Enable indent (receipt)
                                        automation</label>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox"
                                            id="enable_indent_receipt_automation"
                                            formControlName="enable_indent_receipt_automation">
                                        <label class="form-check-label" for="flexCheckChecked">
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-xl-6 mb-2">
                                        <label for="textBox">Facility categories</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstFacTags"
                                            formControlName="store_tags" (onSelect)="onItemSelect('inf', $event)"
                                            (onSelectAll)="onSelectAll('inf', $event)" [(ngModel)]="strTags">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-group col-xl-6 mb-2">
                                        <label for="textBox">Material categories</label>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="lstMatTags"
                                            formControlName="material_tags" (onSelect)="onItemSelect('inp', $event)"
                                            (onSelectAll)="onSelectAll('inp', $event)" [(ngModel)]="matTags">
                                        </ng-multiselect-dropdown>
                                    </div>
                                    <div class="form-group col-xl-12 mt-2" formGroupName="triggers_for_indent_receipt_creation">
                                        <label for="textBox" class="f-w-500 mb-2 me-2">Triggers for indent (receipt)
                                            creation</label><br />
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="create_order_when_item_reaches_min_stock_level">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Create Indent when item reaches min. stock level
                                            </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                formControlName="create_order_when_item_is_likely_to_stock_out">
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Create Indent when item is likely to stock out
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-2" *ngIf="false">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Invoice</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">Specify the templates for Indent invoice and shipment document/voucher.
                                    These templates have to be in JasperReports template format (.jrxml). If you wish to only
                                    change the logo in the default templates then upload jpeg or png logo file (the image
                                    should not exceed a height of 90 pixels and a width of 500 pixels).
                                </div>
                                <div class="row mt-3">
                                    <div class="col-sm-3">
                                        <p class="mb-0">Logo</p>
                                        <p class="mb-0" *ngIf="!isIL1">
                                            <a [routerLink]="" *ngIf="logoName!=''">{{logoName}}</a>&nbsp;&nbsp;
                                            <a (click)="isIL1=!isIL1"  *ngIf="cnfgOrdPrmsn"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                        </p>
                                        <p *ngIf="isIL1">
                                            <input type="file" class="form-control btn btn-sm btn-primary mb-2" #IL1
                                                (change)="onSlctFile($event, 'l')" accept="image/*" />
                                            <button class="btn btn-sm btn-primary" type="button" (click)="IL1.value=''" *ngIf="cnfgOrdPrmsn">Upload</button>&nbsp;&nbsp;
                                            <button class="btn btn-sm btn-light" type="button" (click)="isIL1=!isIL1" id="cncl1" *ngIf="cnfgOrdPrmsn">Cancel</button>
                                        </p>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="mb-0">Invoice template</p>
                                        <p class="mb-0" *ngIf="!isIL2">
                                            <a [routerLink]="">logistimo_invoice.jrxml</a>&nbsp;&nbsp;
                                            <a (click)="isIL2=!isIL2"  *ngIf="cnfgOrdPrmsn"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                        </p>
                                        <p *ngIf="isIL2">
                                            <input type="file" class="form-control btn btn-sm btn-primary mb-2" #IL2
                                                (change)="onSlctFile($event, 'i')" accept="image/*" />
                                            <button class="btn btn-sm btn-primary" type="button" (click)="IL2.value=''"  *ngIf="cnfgOrdPrmsn">Upload</button>&nbsp;&nbsp;
                                            <button class="btn btn-sm btn-light" type="button" (click)="isIL2=!isIL2"  *ngIf="cnfgOrdPrmsn" id="cncl2">Cancel</button>
                                        </p>
                                    </div>
                                    <div class="col-sm-6">
                                        <p class="mb-0">Shipment template</p>
                                        <p class="mb-0" *ngIf="!isIL3">
                                            <a [routerLink]="">logistimo_shipment.jrxml</a>&nbsp;&nbsp;
                                            <a (click)="isIL3=!isIL3" *ngIf="cnfgOrdPrmsn"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                                        </p>
                                        <p *ngIf="isIL3">
                                            <input type="file" class="form-control btn btn-sm btn-primary mb-2" #IL3
                                                (change)="onSlctFile($event, 's')" accept="image/*" />
                                            <button class="btn btn-sm btn-primary" type="button" *ngIf="cnfgOrdPrmsn" (click)="IL3.value=''">Upload</button>&nbsp;&nbsp;
                                            <button class="btn btn-sm btn-light" type="button" *ngIf="cnfgOrdPrmsn" (click)="isIL3=!isIL3" id="cncl3">Cancel</button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card p-0 mt-2" formGroupName="transport" [hidden]="true">
                            <div class="card-header p-2">
                                <h6 class="mb-0">Transport</h6>
                            </div>
                            <div class="card-body p-2">
                                <div class="small">Specify the configuration regarding transportation of Indent</div>
                                <div class="row">
                                    <div class="">
                                        <div class="form-group col-xl-12 mt-2" formGroupName="order_status_change">
                                            <label for="textBox" class="f-w-500 mb-2">Indent status change</label> <br>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                    formControlName="automatically_mark_an_order_as_shipped_on_pick_up_of_a_consignment">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Automatically mark an Indent as 'Shipped' on pick up of a consignment
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                    formControlName="automatically_mark_an_order_as_fulfilled_on_delivery_of_a_consignment">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Automatically mark an Indent as 'Fulfilled' on delivery of a consignment
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-xl-12" formGroupName="delivery_request_permission">
                                            <label for="textBox" class="f-w-500 mb-2">Delivery request permission</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="flexCheckChecked"
                                                    formControlName="disable_the_request_for_delivery_by_a_receiving_store">
                                                <label class="form-check-label" for="flexCheckChecked">
                                                    Disable the request for delivery by a receiving facility
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-end pb-3">
                            <button type="button" class="btn btn-sm btn-primary" *ngIf="cnfgOrdPrmsn" (click)="onSave()">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>