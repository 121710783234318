import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'evin-consumption-facilities',
  templateUrl: './consumption-facilities.component.html',
  styleUrls: ['./consumption-facilities.component.css']
})

export class ConsumptionFacilitiesComponent implements OnInit {

  cnsmptnOvrvwRptForm = new FormGroup({ blockId: new FormControl([0]), city: new FormControl(['']), storeId: new FormControl([0]),
    consumptionFromDate: new FormControl(['']), consumptionToDate: new FormControl(['']), consumptionType: new FormControl(['']),
    districtId: new FormControl([0]), productBadge: new FormControl(['']), productId: new FormControl([0]), stateId: new FormControl([0]),
    receivingStoreId: new FormControl([0]), storeBadge: new FormControl(['']) });

  constructor() { }

  ngOnInit(): void {
  }

}