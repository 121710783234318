<form [formGroup]="ordrStatForm" autocomplete="off">
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12 card">
            <div class="p-2 card-body">
                <div class="float-start">
                    <span class="h4 mr-2">Indent: {{orderStatus?.consgineeNo}}</span>&nbsp;
                    <span *ngIf="orderStatus?.statusName!='Cancelled'" class="badge bg-success">{{orderStatus?.statusName}}</span>
                    <span *ngIf="orderStatus?.statusName=='Cancelled'" class="badge bg-danger">{{orderStatus?.statusName}}</span>
                    <div class="mt-0">Supply Order No #:
                        <!-- <div *ngIf="orderStatus?.statusName!='Cancelled'">
                            <span *ngIf="irflg">
                                <span>{{orderStatus?.reference}}</span>&nbsp;
                                <a *ngIf="orderStatus?.statusName=='Confirmed' && mmIdDtlsEr">
                                    <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Edit" (click)="irflg=!irflg"></i>
                                </a>
                            </span>
                            <span *ngIf="!irflg">
                                <input type="text" class="form-control form-control-sm" formControlName="issuseRefNo">
                                <button type="button" class="btn btn-sm btn-primary mt-1"
                                    (click)="updateOrderDetails()">Save</button>&nbsp;
                                <button type="button" class="btn btn-sm btn-light mt-1"
                                    (click)="irflg=!irflg">Cancel</button>
                            </span>
                        </div> -->
                    </div>
                    <div class="small">Created on {{orderStatus?.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                        <a>{{orderStatus?.createdBy}}</a>
                    </div>
                    <div class="small">Last updated on {{orderStatus?.updatedOn | date: 'dd-MM-yyyy hh:mm:ss'}} by
                        <a>{{orderStatus?.updatedBy}}</a>
                    </div>
                </div>
                <!-- <div class="float-end">
                    <span data-tooltip="Invoice" class="toggle-data" title="Invoice" (click)="onInvc()"
                        *ngIf="orderStatus?.statusName =='Shipped' || orderStatus?.statusName =='Fulfilled'">
                        <i class="fa fa-print" aria-hidden="true"></i></span>&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="orderStatus?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-mobile"></i></span>&nbsp;
                    <span data-tooltip="facility app" class="f-14" *ngIf="orderStatus?.sourceType==1">
                        <i aria-hidden="true" class="fa fa-globe"></i></span>
                    <span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" aria-expanded="false"
                        aria-controls="multiCollapseExample2" *ngIf="orderStatus?.sourceType==2">
                        <span data-tooltip="map view" class="f-14"><i aria-hidden="true" class="fa fa-desktop"></i></span>
                    </span>
                </div> -->
                <div class="clearfix"></div>
                <hr class="mt-1 mb-1" />
                <!-- <span *ngIf="bbflg">
                    <span
                        class="f-12 badge rounded-pill bg-secondary pt-1 pb-1">{{orderStatus?.bookingBadge}}</span>&nbsp;
                    <a *ngIf="orderStatus?.statusName=='Confirmed' && mmIdDtlsEc">
                        <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Edit" (click)="bbflg=!bbflg"></i>
                    </a>
                </span>
                <span *ngIf="!bbflg">
                    <div class="row col-xl-3">
                        <select class="form-select form-select-sm ml-3" formControlName="bookingBadge">
                            <option value="">-Select-</option>
                            <option *ngFor="let ot of lstTags" [value]="ot.id">{{ot.name}}</option>
                        </select>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary mt-1" (click)="updateOrderDetails()">
                        Save
                    </button>&nbsp;
                    <button type="button" class="btn btn-sm btn-light mt-1" (click)="bbflg=!bbflg">
                        Cancel
                    </button>
                </span>
                <div class="mt-1 mb-1" *ngIf="!orderStatus?.bookingBadge && bbflg && orderStatus?.statusName!='Cancelled'">Add Indent tags</div>
                <hr class="mt-1 mb-1" />
                <div class="clearfix"></div>
                <div class="collapse multi-collapse" id="multiCollapseExample2">
                    <div class="card border-0 mt-3 shadow">
                        <div class="card-body p-2">
                            <div id="map-container-google-1" class="z-depth-1-half map-container"
                                style="width: 100%; height: 500px">
                                <iframe
                                    src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    width="100%" height="100%" frameborder="0" style="border:0"
                                    allowfullscreen></iframe>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="card border-0 mt-3 shadow">
                    <div class="card-header p-2">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="float-start pt-1">
                                    <h6>Status </h6>
                                </div>
                                <div class="float-end m-2">
                                    <span class="m-2" title="History" class="toggle-data" *ngIf="mmIdDtlsIh">
                                        <a class="btn btn-sm btn-primary pt-1qz pb-1/" data-bs-toggle="modal"
                                            data-bs-target="#history-status" (click)="LoadHstry()">
                                            <i class="fa fa-history" aria-hidden="true"></i>
                                        </a>
                                    </span>&nbsp;
                                    <span class="dropdown" *ngIf="orderStatus?.statusName!='Cancelled'">
                                        <button class="btn btn-sm btn-primary dropdown-toggle mr-2" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" *ngIf="orderStatus?.statusName!='Fulfilled'">
                                            <i class="fa fa-bars pr-0"></i>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end p-0 btn_drop"
                                            aria-labelledby="dropdownMenuButton1">                                            
                                            <!-- <li *ngIf="mmIdDtlsRi">
                                                <a class="dropdown-item"
                                                    *ngIf="orderStatus?.statusName=='Confirmed'" (click) ="onReopen(orderStatus.bookingId)">Reopen</a>
                                            </li> -->
                                            <li *ngIf="mmIdDtlsDi">
                                                <a class="dropdown-item" data-toggle="modal" data-target="#mdlRD"
                                                    *ngIf="orderStatus?.statusName=='Confirmed' && isCnsgnStr != '0'">
                                                    Ready For Dispatch
                                                </a>
                                            </li>
                                            <li>
                                                <a *ngIf="orderStatus?.statusName=='Confirmed' || orderStatus?.statusName=='Shipped' || orderStatus?.statusName=='Ready For Dispatch'"
                                                    class="dropdown-item" data-dismiss="modal" (click)="onCncl()">Cancel
                                                </a>
                                            </li>
                                        </ul>
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus?.statusName !='Cancelled' && orderStatus?.statusName=='Ready For Dispatch' && isCnsgnStr != '0'">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            (click)="openModal()">Ship</button>
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus?.statusName !='Cancelled' && mmIdDtlsFi">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"                                            
                                            (click)="onClkFulfill()" *ngIf="orderStatus?.statusName=='Shipped' && isCnsgnStr == '0'">Fulfill</button>                                            
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus?.batchEnabled">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"                                            
                                            *ngIf="orderStatus?.statusName=='Fulfilled' && orderStatus?.vareportPath== null" (click)="openModalVAR('v')">Download VAR</button>                                            
                                    </span>
                                    <span class="m-2" *ngIf="orderStatus?.batchEnabled == false">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"                                            
                                            *ngIf="orderStatus?.statusName=='Fulfilled' && orderStatus?.vareportPath== null" (click)="openModalVAR('p')">Download PAR</button>                                            
                                    </span>
                                    <!-- <span class="m-2" *ngIf="orderStatus?.statusName !='Cancelled'">
                                        <button type="button" class="float-right btn btn-sm btn-primary mr-2"
                                            *ngIf="orderStatus?.statusName=='Pending' && paramTabName != 'i'"
                                            (click) = "onConfrim(orderStatus.bookingId)">Confirm</button>
                                    </span>                                    -->
                                </div>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-xl-12">
                                        <div class="process-wrapper">
                                            <div id="progress-bar-container" *ngIf="progressdata.length != 0">
                                                <div>
                                                    <ul>
                                                        <li style="animation-timing-function: ease-in;"
                                                            [ngClass]="item.label == 'Cancelled' ? 'inactive' : 'active'"
                                                            *ngFor="let item of progressdata; let i=index">
                                                            <div [ngStyle]="{ 'color': item.label == 'Cancelled' ? 'red' : '#28a745' }"
                                                                [attr.id]='i' class="step-inner">{{item.label}} </div>
                                                            <div class="status">
                                                                <div>{{item.activityDate != null ? (item.activityDate |
                                                                    date: 'dd-MM-yyyy hh:mm aa') : ''}}</div>
                                                            </div>
                                                            <div class="status">
                                                                <div>{{item.createdBy}}</div>
                                                            </div>
                                                        </li>
                                                    </ul> 
                                                </div>
                                                <div id="line">
                                                    <div id="line-progress"></div>
                                                </div>
                                            </div>
                                            <div class="text-center" *ngIf="progressdata.length == 0">
                                                {{noRcrdsTxt}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="float-end mb-2 mt-2">
                                    Processing time: 0.02 hour(s), Delivery lead time: 0 hours
                                </div> -->
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-xl-6 mb-3">
                                <div class="card shadow-sm h-100">
                                    <div class="card-header p-2 font-wt">Producer Details</div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Producer Name:</div>
                                            <div class="col-sm-8">
                                                <a>{{orderStatus?.producerName}}</a>
                                                <div>{{orderStatus?.producerLocation}}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">Product Name:</div>
                                            <div class="col-sm-8">
                                                <a>{{orderStatus?.productName}}</a>
                                                <div>{{orderStatus?.produtBadgeName}}</div>
                                            </div>
                                        </div>                                       

                                        <div class="row mt-1">
                                            <div class="col-sm-4 ">Estimated date of arrival:</div>
                                            <div class="col-sm-6">                                                
                                                <div>
                                                    {{orderStatus?.arrivalDate | date: 'dd-MM-yyyy hh:mm aa'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 mb-3">
                                <div class="card shadow-sm h-100">
                                    <div class="card-header p-2 font-wt">Receiving facility</div>
                                    <div class="card-body p-2">
                                        <div class="row">
                                            <div class="col-sm-4">Receiving facility:</div>
                                            <div class="col-sm-8">
                                                <a>{{orderStatus?.storeName}}</a>
                                                <div>{{orderStatus?.storeLocation}}</div>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0">
                            <div class="card-body p-2">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item"><a class="nav-link active" id="Items-tab" data-bs-toggle="tab"
                                            href="#Items" role="tab" aria-controls="Items" aria-selected="true">Items </a>
                                    </li>
                                    <!-- <li class="nav-item"><a class="nav-link" id="Shipments-tab" data-bs-toggle="tab"
                                            href="#Shipments" role="tab" aria-controls="Shipments"
                                            aria-selected="true">Shipments
                                            <span
                                                class="badge rounded-pill bg-secondary">{{lstShipments.length}}</span></a>
                                    </li>                                     
                                    <li class="nav-item"><a class="nav-link" id="Comments-tabs" data-bs-toggle="tab"
                                            href="#Comments" role="tab" aria-controls="Comments"
                                            aria-selected="false">Comments
                                            <span
                                                class="badge rounded-pill bg-secondary">{{lstAllComments.length}}</span></a>
                                    </li>-->
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="Items" role="tabpanel"
                                        aria-labelledby="Items-tab">                                        
                                        <button type="button" class="btn btn-sm btn-primary mt-3" (click)="allocate()"
                                            *ngIf="(orderStatus?.statusName=='Confirmed' ||orderStatus?.statusName=='Allocated') && 
                                            (!allocateS) && orderStatus?.statusName!='Cancelled' && isCnsgnStr != '0'">Allocate</button>
                                                
                                        <div class="table_spacing table-responsive mt-3">
                                            <table class="table table-striped table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Material</th>
                                                        <th scope="col" class="text-center">Ordered</th>
                                                        <th scope="col" class="text-center">Allocated</th>
                                                        <th scope="col" class="text-center" *ngIf="orderStatus?.batchEnabled == false">File</th>
                                                        <!-- <th scope="col" class="text-center" *ngIf="allocateS == false">File</th>*ngIf="allocateS == true && orderStatus?.batchEnabled == false" -->
                                                        <th scope="col" class="text-center">Shipped</th>
                                                        <th scope="col" class="text-center">Yet to ship</th>                                                        
                                                        <th scope="col" class="text-center">Fulfilled</th>                                                    
                                                        <th scope="col" class="text-center" *ngIf="orderStatus?.vareportPath != null && orderStatus?.statusName == 'Fulfilled'">File Path</th>                                                    
                                                        <th scope="col" class="text-center" *ngIf="orderStatus?.vareportPath == null && orderStatus?.statusName == 'Fulfilled'">{{orderStatus?.batchEnabled ? 'Upload VAR' :'Upload PAR'}}</th>                                                  
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{orderStatus?.productName}}</td>
                                                        <td class="text-center">{{orderStatus?.orderedQuantity}}</td>
                                                        <td class="text-center">
                                                            <span *ngIf="allocateS == true">
                                                                <span *ngIf="orderStatus?.batchEnabled == false">
                                                                    <input type="text" class="form-control form-control-sm" formControlName="quantitySM"
                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                                                </span>
                                                                <span *ngIf="orderStatus?.batchEnabled == true && isAllcteByBatch == false">
                                                                    <button class="btn btn-success btn-sm" (click)="allcotaeByBatch()">Allocate by Batch</button>
                                                                </span>
                                                                <span *ngIf="orderStatus?.batchEnabled == true && isAllcteByBatch == true">
                                                                    <button class="btn btn-warning btn-sm" (click)="allcotaeByBatch()">Hide Batch</button>
                                                                </span>
                                                            </span>
                                                            <span *ngIf="allocateS == false">
                                                                {{orderStatus?.allocatedQuantity}}
                                                            </span>
                                                        </td>
                                                        <td class="text-center" *ngIf="orderStatus?.batchEnabled == false">
                                                            <span *ngIf="allocateS == true && orderStatus?.batchEnabled == false">
                                                                <input type="file" class="form-control form-control-sm" formControlName="fileName"
                                                                    [ngClass]="{'is-invalid': isSbmtd && fc.fileName.errors}" accept="pdf"
                                                                     (change)="onFileSelect($event, 'woB')">
                                                                <div *ngIf="isSbmtd && fc.fileName.errors" class="invalid-feedback">
                                                                   <div *ngIf="fc.fileName.errors?.required">File is required</div>
                                                                </div>
                                                            </span>
                                                            <span *ngIf="allocateS == false && orderStatus?.fulfiledQuantity==0">
                                                                <a href="{{orderStatus?.cdlkasauliPath}}" target="_blank">{{orderStatus?.cdlkasauliPath?.substring(96)}}</a>
                                                            </span>
                                                            <span *ngIf="allocateS == false && orderStatus?.fulfiledQuantity!=0">
                                                                <a href="{{orderStatus?.cdlkasauliPath}}" target="_blank">{{orderStatus?.cdlkasauliPath?.substring(96)}}</a>
                                                            </span>
                                                        </td>
                                                        <td class="text-center">{{orderStatus?.shippedQuantity}}</td>
                                                        <td class="text-center">{{orderStatus?.statusName != 'Fulfilled' ? orderStatus?.allocatedQuantity : 0}}</td>
                                                        <td class="text-center">{{orderStatus?.fulfiledQuantity}}</td>
                                                        <td class="text-center" *ngIf="orderStatus?.vareportPath != null && orderStatus?.statusName == 'Fulfilled'">
                                                            <a href="{{orderStatus?.vareportPath}}" target="_blank">{{orderStatus?.vareportPath?.substring(96)}}</a></td>
                                                        <td class="text-center" *ngIf="orderStatus?.vareportPath == null && orderStatus?.statusName == 'Fulfilled'">
                                                            <span><input type="file" class="form-control form-control-sm" formControlName="fileNameVAR"
                                                            accept="pdf" (change)="onFileSelectVar($event)"></span>
                                                            <span>
                                                                <button class="btn btn-sm btn-primary mt-2" (click)="uploadFile()">Upload</button>
                                                            </span></td>
                                                    </tr>                                                   
                                                </tbody>                                               
                                            </table>
                                            
                                            <div *ngIf="isAllcteByBatch">
                                                <div class="row">
                                                <div class="col-md-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Batch ID <sup class="text-center">*</sup></label>
                                                                <input type="text" class="form-control form-control-sm" formControlName="batchNo"
                                                                [ngClass]="{'is-invalid': isSbmtd && fc.batchNo.errors}"
                                                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || (event.charCode >= 65 && event.charCode <= 90)'>
                                                                <div *ngIf="isSbmtd && fc.batchNo.errors" class="invalid-feedback">
                                                                    <div *ngIf="fc.batchNo.errors?.required">Batch id is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Quantity <sup class="text-center">*</sup></label>
                                                                <input type="text" class="form-control form-control-sm" formControlName="quantity"
                                                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                                                    [ngClass]="{'is-invalid': isSbmtd && fc.quantity.errors}">
                                                                <div *ngIf="isSbmtd && fc.quantity.errors" class="invalid-feedback">
                                                                    <div *ngIf="fc.quantity.errors?.required">Quantity is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Manufactured Date <sup class="text-center">*</sup></label>
                                                                <div class="input-group">
                                                                    <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                                                        class="form-control form-control-sm optinbg bsDP-bg" #dpFD="bsDatepicker"
                                                                        [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)" 
                                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY', 
                                                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                        onkeypress='return event.charCode == 8' formControlName="mfgDate"
                                                                        [(ngModel)]="frmDate" placeholder="From" [maxDate]="dt2day" readonly 
                                                                        [ngClass]="{'is-invalid': isSbmtd && fc.mfgDate.errors}">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpFD.toggle()"
                                                                            [attr.aria-expanded]="dpFD.isOpen"> <i class="fa fa-calendar"></i>
                                                                        </button>
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                            (click)="dpFD.hide();clearBSDate('mfgDate')"> <i class="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                    
                                                                    <div *ngIf="isSbmtd && fc.mfgDate.errors" class="invalid-feedback">
                                                                        <div *ngIf="fc.mfgDate.errors?.required">Manufactured date is required</div>
                                                                    </div> 
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Expiry Date <sup class="text-center">*</sup></label>
                                                                <div class="input-group">
                                                                    <input type="text" autocomplete="off" onpaste="return false" placeholder="To"
                                                                        [dateCustomClasses]="cstmDt2" (bsValueChange)="onChngDtT($event)" (bsValue)="ctDte"
                                                                        class="form-control form-control-sm optinbg bsDP-bg" #dpED="bsDatepicker"
                                                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',  
                                                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                                                        onkeypress='return event.charCode == 8' formControlName="expDate" [(ngModel)]="toDateF"
                                                                        bsDatepicker [minDate]="dt2day" readonly [ngClass]="{'is-invalid': isSbmtd && fc.expDate.errors}">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                                                            [attr.aria-expanded]="dpED.isOpen"> <i class="fa fa-calendar"></i>
                                                                        </button>
                                                                        <button class="btn btn-sm btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                                            (click)="dpED.hide();clearBSDate('expDate')"> <i class="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div *ngIf="isSbmtd && fc.expDate.errors" class="invalid-feedback">
                                                                        <div *ngIf="fc.expDate.errors?.required">Expiry date is required</div>
                                                                    </div> 
                                                                </div>                                                               
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group">
                                                                <label>Upload File <sup class="text-center">*</sup></label>
                                                                <input type="file" class="form-control form-control-sm" formControlName="fileName"
                                                                    [ngClass]="{'is-invalid': isSbmtd && fc.fileName.errors}" accept="pdf"
                                                                    (change)="onFileSelect($event, 'wB')">
                                                                <div *ngIf="isSbmtd && fc.fileName.errors" class="invalid-feedback">
                                                                    <div *ngIf="fc.fileName.errors?.required">File is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2 mt-4">
                                                            <button class="btn btn-sm btn-primary mt-2" (click)="isSbmtd=true;onBtchSave()">Add</button>&nbsp;
                                                            <button class="btn btn-sm btn-light mt-2" (click)="isSbmtd=false;OnCnclBtch()">Cancel</button>
                                                        </div>  
                                                    </div>
                                                </div>
                                                  
                                                </div>
                                                                                                
                                                <div class="claerfix"></div>
                                                <div class="table_spacing table-responsive mt-3">
                                                    <table class="table table-striped table-sm table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No.</th>
                                                                <th>Batch ID</th>
                                                                <th>Quantity</th>
                                                                <th>Manufacturer</th>
                                                                <th>Manufactured Date</th>
                                                                <th>Expiry Date</th>
                                                                <th>File</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody *ngIf="lstTags.length != 0">
                                                            <tr *ngFor="let x of lstTags; let i=index">
                                                                <td>{{i+1}}</td>
                                                                <td>{{x?.batchNo}}</td>
                                                                <td>{{x?.batchQty}}</td>
                                                                <td>{{orderStatus?.producerName}}</td>
                                                                <td>{{x?.mfgDate | date: 'dd-MM-yyyy hh:mm aa'}}</td>
                                                                <td>{{x?.expDate | date: 'dd-MM-yyyy hh:mm aa'}}</td>
                                                                <td>
                                                                    <a href="{{x?.documentPath}}" target="_blank">{{x?.documentPath?.substring(96)}}</a>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        <i class="fa fa-trash" (click)="onRmve(x)"></i>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody *ngIf="lstTags.length == 0">
                                                            <tr>
                                                                <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                            </div>
                                            <div class="clear-fix"></div>
                                            <div class="float-end mt-4" *ngIf="allocateS && orderStatus?.batchEnabled == false">
                                                <button class="btn btn-sm btn-primary" (click)="allocateOrder()"
                                                    [disabled]="ordrStatForm.get('fileName')?.value=='' || ordrStatForm.get('quantitySM')?.value==''">Save</button>&nbsp;
                                                <button class="btn btn-sm btn-light" (click)="allocateS=false;Oncnclal()">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="tab-pane fade" id="Shipments" role="tabpanel"
                                        aria-labelledby="Shipments-tab">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Shipment Id</th>
                                                        <th scope="col" class="text-center">Status</th>
                                                        <th scope="col" class="text-center">No of items</th>
                                                        <th scope="col" class="text-center">Transporter</th>
                                                        <th scope="col" class="text-center">Tracking #</th>
                                                        <th scope="col" class="text-center">EDA</th>
                                                        <th scope="col" class="text-center">Created</th>
                                                    </tr>
                                                </thead>
                                                <tbody *ngIf="lstShipments.length!=0">
                                                    <tr *ngFor="let ship of lstShipments">
                                                        <td>{{ship.cargoNo}}</td>
                                                        <td>{{ship.cargoStatus}}</td>
                                                        <td>{{ship.noOfItems}}</td>
                                                        <td>{{ship.transporter}}</td>
                                                        <td>{{ship.trackingid}}</td>
                                                        <td>{{ship.estimatedDeliveryDate}}</td>
                                                        <td>{{ship.createdBy}}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstShipments.length==0">
                                                    <tr>
                                                        <td colspan="7" class="text-center">{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> -->
                                    <!-- <div class="tab-pane fade" id="Returns" role="tabpanel"
                                        aria-labelledby="Returns-tab">
                                        <div class="card">
                                            <div class="card-body p-2">
                                                No returns have been created.
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="tab-pane fade" id="Comments" role="tabpanel"
                                        aria-labelledby="Comments-tab">
                                        <div class="table-responsive">
                                            <table
                                                class="table table-sm table-bordered table_spacing table-small-font p-0 mt-3">
                                                <tbody>
                                                    <tr *ngFor="let com of lstAllComments">
                                                        <td class="text-center"><i class="f-24 fa fa-user-circle-o"
                                                                aria-hidden="true"></i></td>
                                                        <td><a>{{com.userId}}</a>&nbsp;&nbsp;<small>added a message -
                                                            {{com.createdOnDate | date: "yy/MM/dd hh:mm a"}}</small>
                                                            <div>{{com.userComments}}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody *ngIf="lstAllComments.length == 0">
                                                    <tr colspan="3" class="text-center">
                                                        <td>{{noRcrdsTxt}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="mt-3" *ngIf="(lstAllComments.length == 0 || cmntStat)">
                                            <div class="col-xl-12">
                                                <label class="form-label" for="comment">Comment</label>
                                                <textarea class="form-control taRS" id="comment"
                                                    formControlName="comment" placeholder="Add comment"></textarea>
                                            </div>
                                            <div class="float-end mt-2">
                                                <button class="btn btn-sm btn-primary"
                                                    (click)="saveComments()">Save</button>&nbsp;
                                                <button class="btn btn-sm btn-light" (click)="cancelComment()">Cancel</button>
                                            </div>
                                        </div>
                                        <div class="mt-2 mb-2" *ngIf="!cmntStat && lstAllComments.length != 0 && mmIdDtlsAd">
                                            <button class="float-end btn btn-sm btn-primary" (click)="cmntStat = true">
                                                Add comment</button>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<div class="modal" id="history-status">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Status history</h6>
            </div>
            <div class="modal-body">
                <span *ngIf="OrderHistory?.length != 0">
                    <div class="card-body p-2" *ngFor="let his of OrderHistory">
                        <i class="fa fa-check-circle"></i>
                        Order {{his.status}} on {{his.time | date: "d/M/yy hh:mm aa"}} by <a>{{his.user_id}}</a>
                    </div>
                </span>
                <span *ngIf="OrderHistory?.length == 0">
                    <div class="card-body p-2 text-center">{{noRcrdsTxt}}</div>
                </span>
            </div>
            <div class="modal-footer card-footer" style="padding: 6px !important;">
                <button type="button" class="btn btn-sm btn-light" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Tab-row-Backdrop">
    <div class="modal-dialog modal-lg modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header p-2">
                <ul class="nav nav-tabs border-tab mb-3" id="top-tab" role="tablist">
                    <li class="nav-item"><a class="nav-link active" id="Shipped-tab" data-bs-toggle="tab"
                            href="#Shipped" role="tab" aria-controls="Shipped" aria-selected="true">Shipped</a>
                    </li>
                    <li class="nav-item"><a class="nav-link" id="Fulfilled-tab" data-bs-toggle="tab" href="#Fulfilled"
                            role="tab" aria-controls="Fulfilled" aria-selected="true">Fulfilled</a></li>
                </ul>
                <button type="button" class="btn btn-sm btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body pt-2">
                <div class="card mb-2 p-0 border-0 rounded-0">
                    <div class="card-body p-2">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="Shipped" role="tabpanel"
                                aria-labelledby="Shipped-tab">
                                <div class="table-responsive table_spacing mt-3">
                                    <table class="table table-striped table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th class="text-center">Batch</th>
                                                <th class="text-center">Expiry date</th>
                                                <th class="text-center">Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="text-center">HUY675</td>
                                                <td class="text-center">30/11/2021</td>
                                                <td class="text-center">100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="Fulfilled" role="tabpanel" aria-labelledby="Fulfilled-tab">
                                <table class="table table-striped table-sm table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center">Batch</th>
                                            <th class="text-center">Expiry date</th>
                                            <th class="text-center">Quantity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">HUY675</td>
                                            <td class="text-center">30/11/2021</td>
                                            <td class="text-center">100</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="mdlRD">
    <div class="modal-dialog">
        <form [formGroup]="readyfrDspcthFrm" autocomplete="off">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">Ready for Dispatch</h6>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Comments</label>
                        <textarea class="form-control from-control-sm" height="250" formControlName="comments"></textarea>
                    </div>
                </div>
                <div class="modal-footer card-footer" style="padding: 6px !important;">
                    <button type="button" class="btn btn-sm btn-light close" data-dismiss="modal" aria-label="Close">Cancel</button>
                    <button class="btn btn-primary btn-sm" (click)="onReadyForDispatch()">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>