import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'evin-setup-update-batch',
  templateUrl: './setup-update-batch.component.html',
  styleUrls: ['./setup-update-batch.component.css']
})

export class SetupUpdateBatchComponent extends BaseComponent implements OnInit {

  lstMtrls: any[] = []; lstManfs: any[] = []; isDTsShw = false; subdFrm: FormGroup = this.fb.group({ batchNo: [''], doe: [''], dom: [''],
    producerId: [''], productId: [''], requestedDate: [''], productName: [''], producerName: [''] });

  constructor(public glblSvc: GlobalService,
      public fb: FormBuilder) {
    super(glblSvc);
    glblSvc.sbjctMstrData.subscribe((r: any) => this.lstMtrls = r?.allMats?.filter((e: any) => e.isBatchEnabled == true) ?? []),
      glblSvc.sbjctManfsLst.subscribe((r: any) => this.lstManfs = r ?? []);
  }

  get fc() { return this.subdFrm.getRawValue(); }

  ngOnInit(): void { }

  onTAH(evnt: any, ctrl: string, type: string) {
    let val = '';
    if (ctrl == 'p') { // Material
      val = type == 'c' ? evnt?.target?.value : evnt?.value;
      if (val != '' && val != null)
        this.subdFrm.get('productId')?.setValue(this.lstMtrls.filter(f => f.name == val)[0]?.id ?? ''),
          this.subdFrm.get('productName')?.setValue(this.lstMtrls.filter(f => f.name == val)[0]?.name ?? '');
      else
        this.subdFrm.get('productId')?.setValue(''), this.subdFrm.get('productName')?.setValue('');
    }
    else {  // Manufacturer
      val = type == 'c' ? evnt?.target?.value : evnt?.value;
      if (val != '' && val != null)
        this.subdFrm.get('producerId')?.setValue(this.lstManfs.filter(f => f.name == val)[0]?.id ?? ''),
          this.subdFrm.get('producerName')?.setValue(this.lstManfs.filter(f => f.name == val)[0]?.name ?? '');
      else
        this.subdFrm.get('producerId')?.setValue(''), this.subdFrm.get('producerName')?.setValue('');
    }
  }

  onChk() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.iCatBtch, environment.apiMthds.getBtchWiseDtls.
       replace('bbb', this.fc.batchNo.toUpperCase()).replace('ppp', this.fc.producerId).replace('mmm', this.fc.productId), null).then(() => {
      this.isDTsShw = this.result?.message == 'Data fetched successfully', this.subdFrm.get('dom')?.setValue(''),
        this.subdFrm.get('doe')?.setValue('');
      if (this.result?.message != 'Data fetched successfully')
        this.glblSvc.onTstr('i', this.result?.message);
    });
  }

  onRst() {
    this.isDTsShw = false, this.subdFrm.reset(), this.subdFrm.get('productName')?.setValue(''), this.subdFrm.get('producerName')?.setValue(''),
      this.subdFrm.get('batchNo')?.setValue('');
  }

  onUpdt() {
    let payLoad: any = this.subdFrm.getRawValue();
    payLoad.requestedDate = this.glblSvc.setDtFrmt(this.dt2Day, this.dtF.ymd, this.tmF.hmsa), payLoad.batchNo = payLoad.batchNo.toUpperCase(),
      payLoad.dom = this.glblSvc.setDtFrmt(payLoad.dom, this.dtF.ymd), payLoad.doe = this.glblSvc.setDtFrmt(payLoad.doe, this.dtF.ymd),
      this.FetchData(environment.apiCalls.post, environment.apiPaths.exprt, environment.apiMthds.domDoe, payLoad, 4).then(() => {
        this.glblSvc.onTstr(this.result?.status == 'OK' ? 's' : 'e', this.result?.message);
        if (this.result?.status == 'OK')
          this.onRst();
    });
  }

}