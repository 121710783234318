<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0 mb-2">
            <div class="card-body p-2 border-0">
                <form [formGroup]="invStkRprtMatFrm" autocomplete="off">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Facility</label>
                                    <input type="text" placeholder="Filter by Facility" class="form-control"
                                        id="storeIdT" [value]="facName" disabled>
                                    <!-- <ng-template #cstmStore let-model="item">
                                        <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Facility" id="Store"
                                        formControlName="storeIdT" [typeahead]="lstPrnts" [typeaheadScrollable]="true"
                                        [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                        [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                        (typeaheadOnSelect)="changeFn($event)" [typeaheadOptionsLimit]="500" /> -->
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.productName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Material"
                                        formControlName="productIdT" [typeahead]="lstAllMats"
                                        (typeaheadOnSelect)="onChngMtrl($event)" id="Product"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="productName" [adaptivePosition]="true"
                                        class="form-control" [typeaheadScrollable]="true" [attr.disabled]="true"
                                        [typeaheadOptionsLimit]="500">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Deviant type</label>
                                    <select id="inputState" class="form-select" formControlName="dvntType">
                                        <option value="">-Select-</option>
                                        <option value="200">Zero Stock...</option>
                                        <option value="201">&lt; Min</option>
                                        <option value="202">&gt; Max</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Expires before</label>
                                    <div class="input-group">
                                        <input type="text" autocomplete="off" onpaste="return false" bsDatepicker
                                            class="form-control optinbg bsDP-bg" #dpED="bsDatepicker" readonly
                                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                                containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                            onkeypress='return event.charCode == 8' formControlName="expireBefore"
                                            placeholder="Expires before">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-bsdp p-2" type="button"
                                                (click)="dpED.toggle()" [attr.aria-expanded]="dpED.isOpen">
                                                <i class="fa fa-calendar"></i>
                                            </button>
                                            <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                                (click)="dpED.hide();clearBSDate('expireBefore')">
                                                <i class="fa fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Batch enabled</label>
                                    <select id="inputState" class="form-select" formControlName="batchEnb">
                                        <option value="">ALL</option>
                                        <option value="wb">Batch enabled</option>
                                        <option value="nb">Batch not enabled</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-row row mb-2 mt-2" hidden>
                            <div class="form-group col-md-3">
                                <label for="textBox">State</label>
                                <input type="text" placeholder="Filter by State" class="form-control" id="State"
                                    formControlName="state">
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox">District</label>
                                <input type="text" placeholder="Filter by District" class="form-control"
                                    id="District-County" formControlName="district">
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox">Taluk/Block</label>
                                <input type="text" placeholder="Filter by Taluk/Block" class="form-control"
                                    id="District-County" formControlName="block">
                            </div>
                            <div class="form-group col-md-3">
                                <label for="textBox">Likely to stock out (days)</label>
                                <input type="text" placeholder="Filter by Likely to stock out (days)"
                                    class="form-control" id="District-County" formControlName="stockOut">
                            </div>
                        </div>
                        <div class="row mb-2 mt-3">
                            <div class="col-xl-6">
                                <span class="pl-xl-6">Material Category:
                                    <span *ngIf="isMatAll">ALL</span>
                                    <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                        <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                            {{mc.name}}
                                        </span>
                                    </span>
                                </span>&nbsp;
                                <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button"
                                    aria-expanded="false" aria-controls="multiCollapseExample2">
                                    <i class="fa fa-filter"></i>
                                </span>
                                <div class="row">
                                    <div class="col">
                                        <div class="collapse multi-collapse" id="multiCollapseExample2">
                                            <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                <div class="row">
                                                    <div class="col-xl-6 col-12">
                                                        <div class="row">
                                                            <div class="">
                                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [data]="lstMatTags"
                                                                    formControlName="productBadge"
                                                                    (onSelect)="onMultiSlct('s', $event)"
                                                                    (onSelectAll)="onMultiSlct('a', $event)"
                                                                    (onDeSelect)="onMultiSlct('r', $event)"
                                                                    (onDeSelectAll)="onMultiSlct('d', $event)"
                                                                    [(ngModel)]="slctdMat">
                                                                </ng-multiselect-dropdown>
                                                                <!-- <ng-multiselect-dropdown
                                                                        [settings]="dropdownSettings"
                                                                        [data]="lstMatTags"
                                                                        formControlName="productbadge"
                                                                        (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                        [(ngModel)]="slctdMat">
                                                                    </ng-multiselect-dropdown> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-6 col-sm-12">
                                                        <div class="float-end">
                                                            <button class="btn btn-sm btn-outline-light me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2"
                                                                (click)="onResetTags()">Reset</button>
                                                            <button class="btn btn-sm btn-primary me-2"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2"
                                                                href="#multiCollapseExample2" role="button"
                                                                (click)="onTagsSave()">Save</button>
                                                            <button class="btn btn-sm btn-light" (click)="onCncl()"
                                                                data-bs-toggle="collapse" aria-expanded="false"
                                                                aria-controls="multiCollapseExample2" role="button"
                                                                href="#multiCollapseExample2">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-xl-6 col-sm-12">
                                                        <div class="">
                                                            <label class="col-form-label">Exclude Category(s)</label>
                                                            <div class="">
                                                                <select class="js-example-basic-hide-search"
                                                                    multiple="multiple" formControlName="excludeCat">
                                                                    <optgroup label="">
                                                                        <option *ngFor="let mt of lstMatTags"
                                                                            [value]="mt.id">{{mt.name}}</option>
                                                                    </optgroup>
                                                                </select>
                                                                <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                    [data]="lstMatTags" formControlName="includeCat"
                                                                    (onSelect)="onItemSelect($event)"
                                                                    (onSelectAll)="onSelectAll($event)">
                                                                </ng-multiselect-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2"
                                    (click)="invStkRprtMatFrm.reset();lstMats=lstAllMats">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="onFilter()">Filter</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-body border-0 pb-2 p-2">
                <div class="row">
                    <div class="col-xl-4">
                        <!-- <span style="display: inline-block;">
                            <label class="me-2">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                        <!-- <span style="display: inline-block;">
                            <button type="button" class="btn btn-sm btn-primary ms-2">
                                <i class="fa fa-file-excel-o" aria-hidden="true"></i>Export
                            </button>
                        </span> -->
                    </div>
                    <div class="col-xl-4">
                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                            <li class="">
                                <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                    href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                    aria-selected="true"><i class="fa fa-table me-0" aria-hidden="true"></i>
                                    List view</a>
                            </li>
                            <li class=""><a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                    href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                    <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-2 col-lg-2 mb-2 text-end" *ngIf="lstMats.length!=0">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-2" *ngIf="lstMats.length!=0">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)">
                            </pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="pills-clrtabContentinfo">
                    <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                        aria-labelledby="pills-tabview-tabinfo">
                        <div class="table_spacing table-responsive mt-3">
                            <table class="table table-striped table-sm table-bordered table1">
                                <thead>
                                    <tr>
                                        <th>Item No.</th>
                                        <th>Material</th>
                                        <th>Total stock</th>
                                        <th>&nbsp;</th>
                                        <th>Allocated</th>
                                        <th>Available</th>
                                        <th>In-transit </th>
                                        <th>Min</th>
                                        <th>Max</th>
                                        <th style="width: 30%;">Last updated</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstMats.length!=0">
                                    <tr *ngFor="let item of lstMats | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i = index"
                                        [ngStyle]="{ 'backgroundColor': item.stockIndicator != null ? item.stockIndicator.backgroundColor : null,
                                            'color': item.stockIndicator != null ? '#fff' : null }">
                                        <!-- 'font-weight': item.stockIndicator != null ? 'bold' : null -->
                                        <td>{{i+1}}</td>
                                        <td>{{item.productName}}</td>
                                        <td>{{item.totalStock}}</td>
                                        <td title="Batch Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'b')"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }"
                                                class="expand-row" *ngIf="item.batchManagement"> <i
                                                    class="fa fa-list-alt"></i>
                                            </a>
                                        </td>
                                        <td title="Allocated Stock Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'a')"
                                                class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.allocatedStock}}&nbsp;<i
                                                    [ngClass]="!item.isAS ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                                            </a>
                                        </td>
                                        <td>{{item.availableStock}}</td>
                                        <td title="In-transit Stock Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'i')"
                                                class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.intransitStock}}&nbsp;<i
                                                    [ngClass]="!item.isIT ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                                            </a>
                                        </td>
                                        <td title="Min-Max Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.invMin}}&nbsp;<i
                                                    [ngClass]="!item.isM ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                                            </a>
                                        </td>
                                        <td title="Min-Max Details">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'm')"
                                                class="expand-row"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }">
                                                {{item.invMax}}&nbsp;<i
                                                    [ngClass]="!item.isM ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
                                            </a>
                                        </td>
                                        <td>{{item.lastUpdated | date: "dd/MM/yyyy hh:mm:ss aa"}}</td>
                                        <td title="History Details" class="toggle-data">
                                            <a (click)="openModal(item.storeId, item.productId, item.productName, 'h')"
                                                [ngStyle]="{ 'color': item.stockIndicator != null ? '#fff' : null }"
                                                class="expand-row">
                                                <i class="fa fa-history"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="lstMats.length==0">
                                    <tr>
                                        <td colspan="11" class="text-center">
                                            {{noRcrdsTxt}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class=" pb-3 pt-3">
                            <div class="row">
                                <div class="col-lg-8 col-xxl-8">
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                        class="clrCde p-1 me-2">Zero Stock</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                        class="clrCde p-1 me-2">Min</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                        class="clrCde p-1 me-2">Max</span>
                                </div>
                                <div class="col-xl-2 mb-2 text-end" *ngIf="lstMats.length!=0">
                                    {{pageEntrs}}
                                </div>
                                <div class="col-lg-2 col-xxl-4" *ngIf="lstMats.length!=0">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel=""
                                            (pageChange)="setPage($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                        <div class="card border-0">
                            <div class="card-body p-2">
                                <div class="float-end mt-2" hidden>
                                    <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                                </div>
                                <div class="clearfix"></div>
                                <div id="map-container-google-1" class="z-depth-1-half map-container"
                                    style="width: 100%; height: 500px">
                                    <iframe
                                        src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                        width="100%" height="100%" frameborder="0" style="border:0"
                                        allowfullscreen></iframe>
                                </div>
                                <div class="clearfix"></div>
                                <div class="float-end mt-2" hidden>
                                    <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>