<form [formGroup]="createManufacturersForm" autocomplete="off">
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Manufacturer information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify the Manufacturer information</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="name">Manufacturer Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Manufacturer Name" class="form-control form-control-sm"
                                id="name" formControlName="name" (keyup)="onKU('name')"
                                onkeypress='return ((event.charCode == 32)) || (event.charCode >= 64 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.name.errors }">
                            <div *ngIf="isSbmtd && fc.name.errors" class="invalid-feedback">
                                <div *ngIf="fc.name.errors.required">Manufacturer name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="description">Description</label>
                            <input type="text" placeholder="Description" class="form-control form-control-sm"
                                id="description" formControlName="description">
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="countryId">Country <span class="text-danger">*</span></label>
                            <select id="countryId" class="form-select form-select-sm" formControlName="countryId"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.countryId.errors }" [attr.disabled]="true">
                                <option value="null">-Select-</option>
                                <option *ngFor="let s of cntryLst" [value]="s.id">{{s.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.countryId.errors" class="invalid-feedback">
                                <div *ngIf="fc.countryId.errors.required">Country is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">State <span class="text-danger">*</span></label>
                            <select formControlName="stateId" class="form-select form-select-sm"
                                (change)="dstLst=[];blkLst=[];onLocChng($event, 'state')" #locState
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.stateId.errors }">
                                <option value="null">All</option>
                                <option *ngFor="let c of stsLst" [value]="c.id">{{c.name}}</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.stateId.errors" class="invalid-feedback">
                                <div *ngIf="fc.stateId.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-0 mt-3 p-0">
        <div class="card-header p-2 ps-3 pe-3">
            <h6 class="mb-0">Contact information</h6>
        </div>
        <div class="card-body p-4 pt-0 pb-2 mt-1 mb-1">
            <div class="row ">
                <div class="col-xl-12">
                    <p class="small m-0">Please specify contact information of the manufacturer</p>
                    <div class="row mb-2">
                        <div class="form-group col-xl-3 mt-3">
                            <label for="contactName">Contact Name <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Contact Name" class="form-control form-control-sm"
                                id="contactName" formControlName="contactName"
                                [ngClass]="{ 'is-invalid': isSbmtd && fc.contactName.errors }">
                            <div *ngIf="isSbmtd && fc.contactName.errors" class="invalid-feedback">
                                <div *ngIf="fc.contactName.errors.required">Contact name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="gender">Gender <span class="text-danger">*</span></label>
                            <select id="gender" class="form-select form-select-sm"
                                formControlName="gender" [ngClass]="{ 'is-invalid': isSbmtd && fc.gender.errors }">
                                <option value="">Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Other</option>
                            </select>
                            <div *ngIf="isSbmtd && fc.gender.errors" class="invalid-feedback">                                    
                                <div *ngIf="fc.gender.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="textBox">Mobile number <span class="text-danger">*</span></label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Mobile number" class="form-control form-control-sm"
                                    id="mobile" formControlName="mobile" maxlength="13" minlength="10"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    [ngClass]="{'is-invalid': isSbmtd && fc.mobile.errors}">
                                <div *ngIf="isSbmtd && fc.mobile.errors" class="invalid-feedback">
                                    <div *ngIf="fc.mobile.errors.required">Mobile number is required</div>
                                    <div *ngIf="fc.mobile.errors.minlength || fc.mobile.errors.maxlength">
                                        Mobile number should be min. of 10 and max. of 13 digits only
                                    </div>
                                    <div *ngIf="fc.mobile.errors.pattern">Mobile Number starts with 6, 7, 8 and 9 Only</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="alternateContactNo">Alternate mobile number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text bg-light text-dark" id="basic-addon1">+91</span>
                                </div>
                                <input type="text" placeholder="Alternate mobile number" class="form-control form-control-sm" id="alternateContactNo" 
                                    formControlName="alternateContactNo" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    maxlength="13" minlength="10" [ngClass]="{'is-invalid': isSbmtd && fc.alternateContactNo.errors}">
                                    <div *ngIf="isSbmtd && fc.alternateContactNo.errors" class="invalid-feedback">                                  
                                        <div *ngIf="fc.alternateContactNo.errors.minlength || fc.alternateContactNo.errors.maxlength">
                                            Alternate mobile number should be min. of 10 and max. of 13 digits only
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="email">Email <span class="text-danger">*</span></label>
                            <input type="text" placeholder="Email" class="form-control form-control-sm" id="email"
                                formControlName="email" [ngClass]="{ 'is-invalid': isSbmtd && fc.email.errors }">
                            <div *ngIf="isSbmtd && fc.email.errors" class="invalid-feedback">
                                <div *ngIf="fc.email.errors.required">Email is required</div>
                                <div *ngIf="fc.email.errors.email">Email should be in format</div>
                            </div>
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="password">Password <span class="text-danger">*</span></label>
                            <!-- (keyup)="onChngPswd()" -->
                            <input type="password" placeholder="Password" class="form-control form-control-sm"
                                id="password" autocomplete="new-password" autocomplete="off" minlength="7"
                                [ngClass]="{ 'is-invalid': isSbmtd && (fc.password.errors || !isValidPswd) }"
                                onkeypress='return ((event.charCode >= 64 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)' maxlength="18" formControlName="password">
                            <div class="show-hide pswdCSS">
                                <span [ngClass]="isPswdShow ? 'hide' : 'show'" (click)="onShwHde()"></span>
                            </div>
                            <div *ngIf="isSbmtd && fc.password.errors" class="invalid-feedback">
                                <div *ngIf="fc.password.errors.required">Password is required</div>
                                <div *ngIf="fc.password.errors.minlength || fc.password.errors.maxlength">Min. & Max. should be 7 & 18 char.s only and no spaces will be allowed</div>
                                <div *ngIf="fc.password.errors.pattern">Enter atleast one number & one alphabet (in Upper or Lower)</div>
                                <!-- <div *ngIf="fc.password.errors.minlength">Minimum length should be 7 char.s</div>
                                <div *ngIf="fc.password.errors.maxlength">Maximum length should be 18 char.s</div> -->
                                <!-- <div *ngIf="fc.password.errors.pattern">should contain atleast one specail character</div> -->
                            </div>
                            <!-- <div *ngIf="isSbmtd && !isValidPswd" class="invalid-feedback">
                                <div>Enter atleast one number & one alphabet (in Upper or Lower)</div>
                            </div> -->
                        </div>
                        <div class="form-group col-xl-3 mt-3">
                            <label for="cpwd">Confirm Password <span class="text-danger">*</span></label>
                            <input type="password" placeholder="Confirm Password" id="cpwd" class="form-control form-control-sm"
                                onkeypress='return ((event.charCode == 32)) || (event.charCode >= 64 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)'
                                    formControlName="cpwd" [ngClass]="{ 'is-invalid': isSbmtd && fc.cpwd.errors }">
                            <div *ngIf="isSbmtd && fc.cpwd.errors" class="invalid-feedback">
                                <div *ngIf="fc.cpwd.errors.required">Confirm Password is required</div>
                                <div *ngIf="fc.cpwd.errors.mustMatch">Confirm Password must match Password</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-xl-12">
                    <div class="float-xl-end">
                        <button class="btn btn-sm btn-primary me-2 mb-2"
                            (click)="isSbmtd = true; createManufacturers()">Save</button>
                        <button class="btn btn-sm btn-light mb-2" (click)="cancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>