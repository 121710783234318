<div>
    <router-outlet></router-outlet> <!-- <button id="shwBtn" type="button" (click)="fcDoughNut('pie2d', 'fcpie2D')">Reload</button> -->
    <div class="row mt-1">
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Inventory </h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Pie';fcDoughNut('pie2d', 'fcpieInv')"><i
                                                    class="fa fa-pie-chart"></i>
                                                Pie chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Bar';fcDoughNut('column2d', 'fcbarInv')"><i
                                                    class="fa fa-bar-chart"></i>
                                                Bar chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Line';fcDoughNut('line', 'fclineInv')"><i
                                                    class="fa fa-line-chart"></i>
                                                Line Charts </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="invChart='Donut';fcDoughNut('doughnut2d', 'fcdnInv')"><i
                                                    class="fa fa-circle-thin"></i>
                                                Doughnut Charts </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample12"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Material tag(s):</span> RI Vaccines,
                                <span class="font-weight-bold">Period:</span> 0 day(s)
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample12">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <label class="col-form-label">Include tag(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="">
                                                    <label class="col-form-label">Exclude tag(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample12" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body chart-block ">
                    <div [hidden]="invChart!='Donut'" id="fcdnInv" class="text-center"></div>
                    <div [hidden]="invChart!='Pie'" id="fcpieInv" class="text-center"></div>
                    <div [hidden]="invChart!='Bar'" id="fcbarInv" class="text-center"></div>
                    <div [hidden]="invChart!='Line'" id="fclineInv" class="text-center"></div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-sm-12 col-xs-12 mb-2">
            <div class="card border-0  shadow h-100">
                <div class="card-header pb-3 pt-3 pl-4 pr-4">
                    <div class="row ">
                        <div class="col-xl-8 col-xs-12">
                            <h5 class="mb-0">Temperature</h5>
                        </div>
                        <div class="col-xl-4 col-xs-12">
                            <div class="d-inline-flex mt-2 float-end">
                                <div class="dropdown-basic p-0 me-1">
                                    <div class="dropdown p-0">
                                        <a class="dropbtn pl-0 pr-0 text-secondary" style="padding:5px !important;">
                                            <span class="f-20"><i class="fa fa-pie-chart"></i></span></a>
                                        <div class="dropdown-content card border-0"
                                            style="top:22px !important; right: 0; left: unset;">
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Pie';fcDoughNut('pie2d', 'fcpieTmp')"><i
                                                    class="fa fa-pie-chart"></i>
                                                Pie chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Bar';fcDoughNut('column2d', 'fcbarTmp')"><i
                                                    class="fa fa-bar-chart"></i>
                                                Bar chart</a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Line';fcDoughNut('line', 'fclineTmp')"><i
                                                    class="fa fa-line-chart"></i>
                                                Line Charts </a>
                                            <a [routerLink]="" class="small pt-1 pb-1" type="button"
                                                (click)="tmpChart='Donut';fcDoughNut('doughnut2d', 'fcdnTmp')"><i
                                                    class="fa fa-circle-thin"></i>
                                                Doughnut Charts </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-basic">
                                    <div class="dropdown">
                                        <label class="mb-0 f-20" data-toggle="collapse" href="#multiCollapseExample13"
                                            role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                            <i class="fa fa-filter"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <span class="text-secondary mt-0 mb-1">
                                <span class="font-weight-bold">Exclude Temparature status:</span>Unknown
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="collapse multi-collapse" id="multiCollapseExample13">
                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                    <form class="form theme-form">
                                        <div class="row">
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="row">
                                                    <label class="col-form-label">Include tag(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-6 col-sm-12">
                                                <div class="">
                                                    <label class="col-form-label">Exclude tag(s)
                                                        <i class="fa fa-info-circle"></i></label>
                                                    <div class="">
                                                        <select class="js-example-basic-hide-search"
                                                            multiple="multiple">
                                                            <optgroup label="Developer">
                                                                <option value="AL">Smith</option>
                                                                <option value="WY">Peter</option>
                                                                <option value="WY">James</option>
                                                                <option value="WY">Hanry Die</option>
                                                                <option value="WY">John Doe</option>
                                                                <option value="WY">Harry Poter</option>
                                                            </optgroup>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-sm-12 col-xs-12 mt-2">
                                                <div class="float-end">
                                                    <button class="btn btn-sm btn-primary" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                    <button class="btn btn-sm btn-light" data-toggle="collapse"
                                                        href="#multiCollapseExample13" role="button"
                                                        aria-expanded="false"
                                                        aria-controls="multiCollapseExample1">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body apex-chart">
                    <div [hidden]="tmpChart!='Donut'" id="fcdnTmp" class="text-center"></div>
                    <div [hidden]="tmpChart!='Pie'" id="fcpieTmp" class="text-center"></div>
                    <div [hidden]="tmpChart!='Bar'" id="fcbarTmp" class="text-center"></div>
                    <div [hidden]="tmpChart!='Line'" id="fclineTmp" class="text-center"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="row p-0 mt-2">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="card border-0">
                <div class="card-header pb-2 pt-2 pl-4 pr-4">
                    <h5 class="mb-0">Inventory </h5>
                    <p class="mb-0">Stock: Normal, Material tag(s): RI Vaccines, Period: 0
                        day(s)</p>
                </div>
                <div class="card-body border-0 row">
                    <div class="col-xl-6 col-sm-12 col-xs-12 p-1">
                        <h5 class="mb-4 text-center">By location </h5>
                        <div id="ac-map" hidden></div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1">
                        <div class="card-body pt-0">
                            <h5 class="mb-4 text-center">By material</h5>
                            <div id="fcBarMat" class="text-center" style="height: 400px"></div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1">
                        <h5 class="mb-4 text-center">By location </h5>
                        <div id="chart-map"></div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1" hidden>
                        <div id="multi-bar" style="height: 500px;"></div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1" hidden>
                        <div id="fc-multi" ></div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1">
                        <div class="row">
                            <div>
                                <div id="Click-chart" class="col-xl-12"></div>
                                <div id="Click-chart1" class="col-xl-12"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-sm-12 col-xs-12 p-1" hidden>
                        <button class="btn btn-primary" (click)="ExportCSV()">Export CSV</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>