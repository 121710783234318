<div class="row p-0 mt-2">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0 mb-2">
            <div class="card-body p-2 border-0">
                <form [formGroup]="invStkRprtFrm" autocomplete="off">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">Facility</label>
                                    <ng-template #cstmStore let-model="item">
                                        <span class="font-weight-bold">{{model.storeName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Facility" id="Store"
                                        formControlName="storeId" [typeahead]="lstFacs" [typeaheadScrollable]="true"
                                        [typeaheadItemTemplate]="cstmStore" typeaheadOptionField="storeName"
                                        [adaptivePosition]="true" class="form-control" [typeaheadScrollable]="true"
                                        (typeaheadOnSelect)="changeFn($event)" readonly [typeaheadOptionsLimit]="500"/>
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Material</label>
                                    <ng-template #cstmProduct let-model="item">
                                        <span class="font-weight-bold">{{model.productName}}</span> <br />
                                        <span class="fs-12">{{model.location}}</span>
                                    </ng-template>
                                    <input name="store" type="text" placeholder="Filter by Material" id="Product"
                                        formControlName="productId" [typeahead]="lstAllMats"
                                        [typeaheadScrollable]="true" [typeaheadItemTemplate]="cstmProduct"
                                        typeaheadOptionField="productName" [adaptivePosition]="true"
                                        class="form-control" [typeaheadScrollable]="true"
                                        (typeaheadOnSelect)="chngProduct($event)" [typeaheadOptionsLimit]="500">
                                </div>
                                <div class="form-group col-xl-3">
                                    <label for="textBox">Deviant type</label>
                                    <select class="form-select" formControlName="abnormalityType" #AbnrmlType>
                                        <option value="">-Select-</option>
                                        <option value="200">Zero Stock...</option>
                                        <option value="201">&lt; Min</option>
                                        <option value="202">&gt; Max</option>
                                    </select>
                                </div>
                                <div class="form-group col-xl-3" *ngIf="AbnrmlType.value!=''">
                                    <label for="textBox">Deviant Duration</label>                                    
                                    <input name="duration" type="text" placeholder="Enter Deviant Duration" id="duration"
                                        class="form-control" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="form-row row mb-2">
                            <div class="form-group col-md-3">
                                <label for="textBox">Expires before</label>
                                <div class="input-group">
                                    <input type="text" autocomplete="off" placeholder="Filter by Expires before"
                                        bsDatepicker class="form-control optinbg bsDP-bg" #dpED="bsDatepicker"
                                        onpaste="return false" [dateCustomClasses]="cstmDt" (bsValueChange)="onChngDT($event)"
                                        [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD/MM/YYYY',
                                            containerClass: 'theme-default', showWeekNumbers: false, adaptivePosition: true }"
                                        onkeypress='return event.charCode == 8' formControlName="expireBefore" readonly>
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-bsdp p-2" type="button" (click)="dpED.toggle()"
                                            [attr.aria-expanded]="dpED.isOpen">
                                            <i class="fa fa-calendar"></i>
                                        </button>
                                        <button class="btn btn-outline-bsdp p-2" type="button" title="Clear Date"
                                            (click)="dpED.hide();clearBSDate('expireBefore')">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-3 pt_2">
                                <button class="btn btn-primary" data-toggle="collapse" href="#box1"
                                    aria-expanded="false" aria-controls="box1">Advance Search</button>
                            </div>
                        </div>
                        <div id="box1" class="collapse">
                            <div class="form-row row mb-2">
                                <div class="form-group col-md-3">
                                    <label for="textBox">State</label>
                                    <!-- <input type="text" placeholder="Filter by State" class="form-control" id="State"
                                        formControlName="state"> -->
                                    <select formControlName="state" class="form-select">
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let c of stsLst" [value]="c.name">{{c.name}}</option>
                                        <!-- id -->
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">District</label>
                                    <!-- <input type="text" placeholder="Filter by District" class="form-control"
                                        id="District-County" formControlName="district"> -->
                                    <select formControlName="country" class="form-select">
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let s of cntryLst" [value]="s.name">{{s.name}}</option>
                                        <!-- id -->
                                    </select>
                                </div>
                                <div class="form-group col-md-3">
                                    <label for="textBox">Taluk/Block</label>
                                    <!-- <input type="text" placeholder="Filter by Taluk/Block" class="form-control"
                                        id="District-County" formControlName="country"> -->
                                    <select formControlName="block" class="form-select">
                                        <option value="null">-Select-</option>
                                        <option *ngFor="let b of blkLst" [value]="b.name">{{b.name}}</option>
                                        <!-- id -->
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-2 mt-3">
                                <div class="col-xl-5">
                                    <span class="pl-xl-3">Facility Category:
                                        <span *ngIf="isFacAll">ALL</span>
                                        <span *ngIf="!isFacAll && lstShowFacTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let fc of lstShowFacTags">
                                                {{fc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample1"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">
                                                                    <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                        [data]="lstFacTags" formControlName="includeStoreBadge"                                                                        
                                                                        (onSelect)="onMultiSlct('s', 'f', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'f', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'f', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'f', $event)"
                                                                        [(ngModel)]="slctdFac">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onResetTags('f')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2" role="button"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1"
                                                                    href="#multiCollapseExample1"
                                                                    (click)="onTagsSave('f')">Save</button>
                                                                <button class="btn btn-sm btn-light" (click)="onCncl('f')"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1" role="button"
                                                                    href="#multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-6">
                                    <span class="pl-xl-3">Material Category:
                                        <span *ngIf="isMatAll">ALL</span>
                                        <span *ngIf="!isMatAll && lstShowMatTags.length != 0">
                                            <span class="badge badge-pink mb-0" *ngFor="let mc of lstShowMatTags">
                                                {{mc.name}}
                                            </span>
                                        </span>
                                    </span>&nbsp;
                                    <span class="mb-0" data-bs-toggle="collapse" href="#multiCollapseExample2"
                                        role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                                        <i class="fa fa-filter"></i>
                                    </span>
                                    <div class="row">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample2">
                                                <div class="card card-body p-0 mb-0 pt-1 pb-2 border-0">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="row">
                                                                <div class="">                                         
                                                                    <ng-multiselect-dropdown [settings]="dropdownSettings"
                                                                        [data]="lstMatTags" formControlName="includeProductBadge"
                                                                        (onSelect)="onMultiSlct('s', 'm', $event)"
                                                                        (onSelectAll)="onMultiSlct('a', 'm', $event)"
                                                                        (onDeSelect)="onMultiSlct('r', 'm', $event)"
                                                                        (onDeSelectAll)="onMultiSlct('d', 'm', $event)"
                                                                        [(ngModel)]="slctdMat">
                                                                    </ng-multiselect-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-sm-12">
                                                            <div class="float-end">
                                                                <button class="btn btn-sm btn-outline-light me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2"
                                                                    (click)="onResetTags('m')">Reset</button>
                                                                <button class="btn btn-sm btn-primary me-2"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2"
                                                                    href="#multiCollapseExample2" role="button"
                                                                    (click)="onTagsSave('m')">Save</button>
                                                                <button class="btn btn-sm btn-light" (click)="onCncl('m')"
                                                                    data-bs-toggle="collapse" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample2" role="button"
                                                                    href="#multiCollapseExample2">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="float-xl-end">
                                <button class="btn btn-sm btn-light me-2 mb-2" (click)="onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary mb-2" (click)="LoadStores()">Filter</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="row p-0">
    <div class="col-xl-12 col-sm-12 col-xs-12">
        <div class="card border-0">
            <div class="card-body border-0 pb-2">
                <div class="row">
                    <div class="col-xl-4">
                        <!-- <span style="display: inline-block;">
                            <label class="me-2">Filter</label>
                        </span>
                        <span style="display: inline-block;">
                            <select class="form-select form-select-sm">
                                <option>5</option>
                                <option>10</option>
                            </select>
                        </span> -->
                    </div>
                    <div class="col-xl-4">
                        <ul class="pull-right nav nav-pills nav-primary " id="pills-clrtabinfo" role="tablist">
                            <li class="">
                                <a class="nav-link btn btn-sm active" id="pills-tabview-tabinfo" data-bs-toggle="pill"
                                    href="#pills-tabview" role="tab" aria-controls="pills-tabview"
                                    aria-selected="true"><i class="fa fa-table me-0" aria-hidden="true"></i> List view
                                </a>
                            </li>
                            <li class="">
                                <a class="nav-link btn btn-sm" id="pills-map-tabinfo" data-bs-toggle="pill"
                                    href="#pills-map" role="tab" aria-controls="pills-map" aria-selected="false">
                                    <i class="fa fa-desktop me-0" aria-hidden="true"></i> Map view
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-2 mb-2 text-end" *ngIf="lstGrid?.length!=0">
                        {{pageEntrs}}
                    </div>
                    <div class="col-lg-2 col-xl-2" *ngIf="lstGrid?.length!=0">
                        <div class="float-end">
                            <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <div class="tab-content" id="pills-clrtabContentinfo">
                    <div class="tab-pane fade show active" id="pills-tabview" role="tabpanel"
                        aria-labelledby="pills-tabview-tabinfo">
                        <div class="table-multi-columns table-fill mt-2 mb-2 horizontal-scroll scroll-demo p-0 border-0"
                            style="height: auto !important;">
                            <table cellspacing="0"
                                class="table table-sm text-center table-small-font table-striped table-bordered align-middle">
                                <thead>
                                    <tr>
                                        <th>Item No.</th>
                                        <th class="w-25">Facility</th>
                                        <th title="Temperature" class="toggle-data">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-thermometer" viewBox="0 0 16 16">
                                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                <path
                                                    d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0zM6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15V2.5z" />
                                            </svg>
                                        </th>
                                        <th *ngFor="let hdr of lstGrid[0]?.hdrs">{{hdr}}</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="lstGrid.length!=0">
                                    <tr>
                                        <td class=""></td>
                                        <td class=""><b>Total</b></td>
                                        <td class=""></td>
                                        <td *ngFor="let ttl of lstGrid[0]?.total"><b>{{ttl}}</b></td>
                                    </tr>
                                    <tr *ngFor="let item of lstGrid | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems}; let i=index">
                                        <td>{{(pageSize*(crntPage-1))+i+1}}</td>
                                        <td (click)="onStoreClk(item.kName, item.kId)" class="text-left">
                                            <p class="f-14">
                                                <a [routerLink]="">{{item.kName}}</a><br />
                                                <sup>{{item.kAdrs}}</sup>
                                            </p>
                                        </td>
                                        <td>
                                            <a [routerLink]="" data-toggle="modal" data-target="#exampleModal">
                                               <div id="ec-dn-{{i}}" style="height: 30px; width: 70px;"></div>
                                            </a>
                                        </td>
                                        <td [ngStyle]="{ 'backgroundColor': col.stockIndicator != null ? col.stockIndicator.backgroundColor : null,
                                                'color': col.stockIndicator != null ? '#fff' : null }" *ngFor="let col of item.matData"
                                            [title]="'Min: '+col.invMin+', Max: '+col.invMax">{{col.currentStock == null ? 'NA' : col.currentStock}} <!-- +'\ncity: '+col.city -->
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="lstGrid.length==0">
                                    <tr>
                                        <td colspan="10" class="text-center">{{noRcrdsTxt}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="pb-3 pt-3">
                            <div class="row" *ngIf="lstGrid?.length!=0">
                                <div class="col-lg-8 col-xxl-8">
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(200) }"
                                        class="clrCde p-1 me-2">Zero Stock</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(201) }"
                                        class="clrCde p-1 me-2">Min</span>
                                    <span [ngStyle]="{ 'backgroundColor': getClrCode(202) }"
                                        class="clrCde p-1 me-2">Max</span>
                                </div>
                                <div class="col-xl-2 mb-2 text-end">
                                    {{pageEntrs}}
                                </div>
                                <div class="col-lg-2 col-xxl-2">
                                    <div class="float-end">
                                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="pills-map" role="tabpanel" aria-labelledby="pills-map-tabinfo">
                        <div class="card border-0">
                            <div class="card-body p-2">
                                <div class="float-end mt-2" hidden>
                                    <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                                </div>
                                <div class="clearfix"></div>
                                <div id="map-container-google-1" class="z-depth-1-half map-container"
                                    style="width: 100%; height: 500px">
                                    <iframe width="100%" height="100%" frameborder="0" style="border:0" allowfullscreen
                                        src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed">
                                    </iframe>
                                </div>
                                <div class="clearfix"></div>
                                <div class="float-end mt-2" hidden>
                                    <span>1-39 of 6 <i class="fa fa-angle-double-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header ws">
                <h5 class="modal-title" id="exampleModalLabel">Achnera CHC</h5>
                <button type="button" class="close btn btn-light" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div id="fcModal"></div>
            </div>
        </div>
    </div>
</div>
<script>
    $(".table-multi-columns").freezeTable({ 'columnNum': 4 });
</script>