<div class="card pb-0">
    <div class="card-header shadow-none p-3">
        <div class="h6 mb-0 float-start">Capabilities configuration</div>
        <!-- <div class="small float-end">Last updated on 31/10/20 4:56 PM by <a [routerLink]="">Devdutt Mishra</a></div> -->
    </div>
    <div class="card-body p-2 mb-0">
        <form>
            <div class="row mt-2">
                <div class="col-sm-12">
                    <div class="card shadow-sm p-0 mt-0 mb-0">
                        <div class="card-header p-2">
                            <h6>Capabilities on web / mobile</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="small">Specify capabilities to be enabled or disabled on the mobile or other client
                                application. You can tailor capabilities specific to a role (by first selecting the role,
                                selecting capabilities and clicking 'Save'). Role-specific capabilities override the generic
                                ones specified across all roles</div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="row mb-2 mt-3">
                                        <div class="form-group col-xl-3 mb-2">
                                            <label for="textBox" class="mb-0">Role</label>
                                            <select id="inputState" class="form-select form-select-sm"
                                                (change)="GetPermissionsbyRoleId($event)">
                                                <option *ngFor="let rle of lstRoles" [value]="rle.id">{{rle.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row-mt-2">
                <div class="col-sm-12">
                    <div class="card shadow-sm mt-1" *ngFor="let up of lstPrmsns">
                        <div class="card-header p-2">
                            <h6>{{up.parentModuleName}}</h6>
                        </div>
                        <div class="card-body p-3">
                            <div class="row mt-2">
                                <div class="col-sm-12">
                                    <div class="row mb-0" *ngFor="let pm of up.modulePermissionRoles; let i=index">
                                        <div class="form-group col-xl-12 mb-2">
                                            <label for="textBox" class="mb-2 fw-bold">{{pm.moduleName}}</label> <br />
                                            <!-- <div class="form-check form-check-inline" *ngIf="pm.moduleId==1">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                                <label class="form-check-label f-w-500" for="inlineCheckbox1">Disable inventory</label>
                                            </div>
                                            <div class="form-check form-check-inline" *ngIf="pm.moduleId==1">
                                                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                                <label class="form-check-label f-w-500" for="inlineCheckbox2">Disable orders</label>
                                            </div> -->
                                            <div class="row pt-2 pb-2">
                                                <div class="col-sm-12">
                                                    <div class="form-check form-check-inline" *ngFor="let pr of pm.permissionRoles"
                                                        [ngStyle]="{ 'width': pr.inputType=='multiselect' ? '100%' : 'auto' }">
                                                        <input class="form-check-input" type="checkbox" id="{{pr.permissionId}}-{{pr.roleId}}"
                                                            [checked]="pr.configuredValue?.value" *ngIf="pr.inputType=='checkbox'"
                                                            (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'cb')">
                                                        <div class="form-check form-check-inline" *ngIf="pr.inputType=='radio'">
                                                            <input class="form-check-input" type="radio" name="exampleRadios"
                                                                [checked]="pr.configuredValue?.value" value="{{pr.permissionLabel}}"
                                                                (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'rb')"
                                                                id="{{pr.permissionId}}-{{pr.permissionLabel}}">&nbsp;&nbsp;{{pr.permissionLabel}}
                                                            <!-- <select class="form-select form-select-sm" [ngModelOptions]="{ standalone: true }" [(ngModel)]="rbVal"
                                                                (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'rb')">
                                                                <option value="Optimistic (Geo-Location Is Acquired Once During Login, And Later On Expiry; Has Better Performance)">
                                                                    Optimistic (Geo-Location Is Acquired Once During Login, And Later On Expiry; Has Better Performance)</option>
                                                                <option value="Strict (Geo-Location Is Acquired Just Before Sending Transaction; Has Better Freshness, At The Cost Of Performance)">
                                                                    Strict (Geo-Location Is Acquired Just Before Sending Transaction; Has Better Freshness, At The Cost Of Performance)</option>
                                                            </select> -->
                                                        </div>
                                                        <label class="form-check-label" for="{{pr.permissionId}}-{{pr.roleId}}"
                                                            *ngIf="pr.inputType!='radio' && pr.permissionLabel!='Authentication Token Expiry'
                                                                && pr.permissionLabel != '' && pr.permissionLabel != null">{{pr.permissionLabel}}
                                                        </label>
                                                        <div class="row col-xl-12" *ngIf="pr.inputType=='multiselect'">
                                                            <ng-multiselect-dropdown [settings]="msdStngs" id="{{pr.permissionId}}-{{pr.roleId}}"
                                                                [(ngModel)]="pr.slctdItems" [ngModelOptions]="{ standalone: true }" [data]="lstMatTags"
                                                                (onSelect)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'ms')"
                                                                (onDeSelect)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'ms')"
                                                                (onSelectAll)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'ms')"
                                                                (onDeSelectAll)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'ms')">
                                                            </ng-multiselect-dropdown>
                                                        </div>
                                                        <div *ngIf="pr.inputType=='numeric'">
                                                            <div *ngIf="pm.moduleName=='Security'">
                                                                <div class="row">
                                                                    <div class="col-sm-12">
                                                                        <div class="input-group">
                                                                            <br />{{pr.permissionLabel}}&nbsp;&nbsp;&nbsp;
                                                                            <div class="input-group-append mt-3">
                                                                                <div class="input-group">
                                                                                    <input class="form-control form-control-sm" type="text"
                                                                                        [value]="pr.configuredValue?.value" maxlength="4"
                                                                                        (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'nb')"
                                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                                                    <div class="input-group-append">&nbsp;&nbsp;day(s)</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="pm.moduleName!='Security'">
                                                                <div class="input-group">
                                                                    <input class="form-control form-control-sm" type="text"
                                                                        [value]="pr.configuredValue?.value" maxlength="4"
                                                                        (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'nb')"
                                                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                                                                    <div class="input-group-append mt-1">&nbsp;&nbsp;day(s)</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group" *ngIf="pr.inputType=='dropdown'">
                                                            <select class="form-select form-select-sm" [ngModelOptions]="{ standalone: true }" [(ngModel)]="ddVal"
                                                                (change)="onChng($event, up.parentModuleId, pm.moduleId, pr.permissionId, 'dd')">
                                                                <option value="Default">Default</option>
                                                                <option value="Sidebar & Landing screen">Sidebar & Landing screen</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr *ngIf="pm.moduleId==1 && i==0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="lstPrmsns.length!=0">
                        <div class="col-xl-2 p-l-30">
                            <button class="btn btn-sm btn-primary me-2" type="button" *ngIf="cpblPrmsn" (click)="onSave()">Save</button><br />
                        </div>
                    </div>
                    <div class="row"><br /></div>
                </div>
            </div>
        </form>
    </div>
</div>