import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';
import { environment } from 'src/environments/environment.prod';
import { StkRprtMdlComponent } from 'src/app/Components/evin/Pop-ups/stk-rprt-mdl/stk-rprt-mdl.component';

@Component({
  selector: 'evin-stock-report-material-store',
  templateUrl: './stock-report-material-store.component.html',
  styleUrls: ['./stock-report-material-store.component.css']
})

export class StockReportMaterialStoreComponent extends BaseComponent implements OnInit {

  lstGrid: any[] = []; noRcrdsTxt = environment.TblNoRcrds; pageSize = environment.tblPageSize; totalItems: any; crntPage = 1; pageEntrs = '';

  constructor(public glblSvc: GlobalService,
    public ngbMdl: NgbModal) {
    super(glblSvc);
  }

  ngOnInit(): void {
    this.LoadGrid();
  }

  LoadGrid() {
    this.FetchData(environment.apiCalls.get, environment.apiPaths.invntry, environment.apiMthds.getStrsbyPrdct.replace('ppp', '1').
        replace('rrr', `${this.chsnDmnId}`).replace('pageNo', `${this.crntPage-1}`).
        replace('pageSize', `${this.pageSize}`), null).then(() => { let res = this.result;
      if (this.result) {
        if (this.result.data) {
          this.totalItems = this.result.data?.totalRecordCount ?? 0, this.lstGrid = this.result?.data?.stockViewProductsDTO ?? [],
            this.pageEntrs = this.GetPageSizeTxt(this.crntPage, this.pageSize, this.totalItems);
        }
        else
          this.glblSvc.onTstr('i', this.result.message);
      }
      else
        this.glblSvc.onTstr('e', res.message);
    });
  }

  setPage(pagNo: number): void {
    this.crntPage = pagNo, this.LoadGrid();
  }

  openModal(strId: any, prdctId: any, prdctName: string, tab: string) {
    const modalRef = this.ngbMdl.open(StkRprtMdlComponent, { scrollable: true, size: 'xl', backdrop: 'static', animation: true });
    modalRef.componentInstance.title = prdctName;
    modalRef.componentInstance.kioskId = strId;
    modalRef.componentInstance.prdctId = prdctId;
    modalRef.componentInstance.tab = tab;
    modalRef.componentInstance.isShowAll = true;
    modalRef.result.then(result => {
      // if (result == 'done')
      //   return; // your code
    }, (r) => { console.log(r) });
  }

}