import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-event-summary',
  templateUrl: './cnfg-event-summary.component.html',
  styleUrls: ['./cnfg-event-summary.component.css']
})

export class CnfgEventSummaryComponent extends BaseComponent implements OnInit {


  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
   
   }

}