<form [formGroup]="setupMatsViewForm" autocomplete="off">
    <div class="row p-0 mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row row mb-2">
                        <div class="form-group col-xl-3">
                            <label for="productName">Material</label>
                            <input type="text" placeholder="Filter by Material" class="form-control form-control-sm"
                                id="productName" formControlName="productName">
                        </div>
                        <div class="form-group col-xl-3">
                            <label for="BadgeName">Material Category:</label>
                            <select class="form-select form-select-sm" formControlName="BadgeName">
                                <option value="">All</option>
                                <option [value]="it.name" *ngFor="let it of lstMatTags">{{it.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-xl-6 pt_2" *ngIf="mtrlLst">
                            <div class="float-end">
                                <button class="btn btn-sm btn-light me-2" (click)="crntPage=1;onReset()">Reset</button>
                                <button class="btn btn-sm btn-primary" (click)="crntPage=1;loadMats()">Filter</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="m-0 mb-0" />
    <div class="row mt-3">
        <div class="col-xl-12 col-sm-12 col-xs-12">
            <div class="row" *ngIf="lstAllMats?.length!=0">
                <div class="col-xl-12 mb-2 text-start">
                    <button class="btn btn-sm btn-success" (click)="onEE()">
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export
                    </button>
                </div>
                <div class="col-xl-4 mb-2">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-4 mb-2 text-center">
                </div>
                <div class="col-lg-4">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="table_spacing table-responsive table-row-spacing">
                <table class="table table-striped table-sm table-bordered horizontal-scroll scroll-demo" id="table_detail">
                    <thead>
                        <tr>
                            <th class="text-center">Item No.</th>
                            <th>Material</th>
                            <th>Description</th>
                            <th>Categories</th>
                            <th>Batch</th>
                            <th>Last updated</th>
                            <!-- <th [hidden]="!(userlgndtls?.edit || userlgndtls == null)"></th>
                            <th [hidden]="!(userlgndtls?.delete || userlgndtls== null)"></th> -->
                            <th [hidden]="(isUserLvl=='District')" class="text-center"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="let item of lstAllMats | paginate: { itemsPerPage: pageSize, currentPage: crntPage, totalItems: totalItems} ; let i = index">
                            <td class="text-center">{{(pageSize*(crntPage-1))+i+1}}</td>
                            <td *ngIf="mtrlDtl" (click)="onMatDetails(item.productId)"><a>{{item.productName}}</a></td> <!-- (click)="onMatDetails(item.id)" -->
                            <td>{{item.description}}</td>
                            <td><span class="badge bg-secondary">{{item.badge}}</span>&nbsp;&nbsp;</td>
                            <td>{{item.batchEnabled == 'Enabled' ? 'YES' : 'NO'}}</td>
                            <td><span><a>{{item.userId}}</a></span>
                                <p class="small">{{item.lastUpdated | date: "dd-MM-yy"}}</p> <!-- "yy/MM/dd hh:mm a" -->
                            </td>
                            <td [hidden]="isUserLvl=='District'" class="text-center">
                                <a (click)="onMatEdit(item.productId)" *ngIf="mtrlEdt">
                                    <i class="fa fa-pencil" aria-hidden="true"></i></a>&nbsp;&nbsp;
                                <a  (click)="onMatDelete(item.productId)" *ngIf="mtrlDlt">
                                    <i class="fa fa-trash" aria-hidden="true"></i></a>
                            </td>
                            <!-- <td [hidden]="!(userlgndtls?.edit || userlgndtls == null)" (click)="onMatEdit(item.productId)"><a><i class="fa fa-pencil" aria-hidden="true"></i></a></td>
                            <td [hidden]="!(userlgndtls?.delete || userlgndtls == null)" (click)="onMatDelete(item.productId)"><i class="fa fa-trash toggle-data" aria-hidden="true"></i></td> -->
                        </tr>
                    </tbody>
                    <tbody>
                        <tr *ngIf="lstAllMats.length == 0">
                            <td colspan="11" class="text-center">{{noRcrdsTxt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row ps-0 pe-0 pt-3 pb-3" *ngIf="lstAllMats?.length!=0">
                <div class="col-xl-4 mb-2 text-start">
                    {{pageEntrs}}
                </div>
                <div class="col-xl-4 text-center">
                </div>
                <div class="col-lg-4">
                    <div class="float-end">
                        <pagination-controls previousLabel="" nextLabel="" (pageChange)="setPage($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>