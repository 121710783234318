<div class="tap-top">
    <i data-feather="chevrons-up"></i>
</div>
<div class="page-wrapper compact-wrapper" id="pageWrapper">
    <div class="page-header close_icon">
        <div class="header-wrapper m-0">
            <div class="header-logo-wrapper col-auto p-0">
                <div class="logo-wrapper">
                    <a>
                        <!-- [routerLink]="['home/ovrvw-db']" onclick="window.location.reload()" -->
                        <img class="img-fluid" src="assets/img/evin_logo.png" alt="">
                    </a>
                </div>
                <div class="toggle-sidebar">
                    <i class="status_toggle middle sidebar-toggle" data-feather="align-center"></i>
                </div>
            </div>
            <div class="left-header w-50 horizontal-wrapper ps-0">
                <form [formGroup]="dmnFrm">
                    <select class="js-example-basic-single" id="dmnDDL" style="visibility: hidden; display: none;">
                        <option *ngFor="let prn of lstPrnths" [value]="prn.pranthId">{{prn.pranthName}}</option>
                    </select>
                    <ng-multiselect-dropdown [settings]="msdStngs" [(ngModel)]="dfltStrTgsStk" [data]="lstPrnths" 
                        (onDeSelect)="onDslctDmn()" formControlName="slctdmn" class="singleselect" (onSelect)="chngDmn()">
                    </ng-multiselect-dropdown>
                </form>
                <button type="button" id="btnDmn" (click)="chngdVal()" hidden></button>
            </div>
            <span class="ms-3 pl-2" *ngIf="!isSupervisorHide">
                <a class="f-22" data-toggle="modal" (click)="LoadTreeDomains()" data-target="#sitemap-modal">
                    <i class="fa fa-sitemap"></i>
                </a>
            </span>
            <div class="nav-right m-auto pull-right right-header me-0">
                <ul class="nav-menus justify-content-xl-center justify-content-sm-end me-0">
                    <li class="cart-nav onhover-dropdown" hidden>
                        <div class="cart-box">Shortcut</div>
                        <ul class="profile-dropdown onhover-show-div vertical-scroll scroll-demo  border-0"
                            style="top:40px !important; height:200px;">
                            <li *ngIf="false"><a>Bulletin Board (old) (post)</a></li>
                            <li><a>Demand Map</a></li>
                            <li><a>Stock Management Map</a></li>
                            <li><a>Stock Management Transactions</a></li>
                            <li><a>Feature Phone (Java)</a></li>
                            <li><a>Smart Phone(Android)</a></li>
                            <li><a>HTML5 Browser</a></li>
                            <li *ngIf="false"><a>Authorize bulletin Board</a></li>
                        </ul>
                    </li>
                    <li class="cart-nav onhover-dropdown" hidden>
                        <div class="cart-box"><i data-feather="help-circle"></i></div>
                    </li>
                    <li class="cart-nav onhover-dropdown" (click)="onSuprAdmn()" *ngIf="lgnUsrRole=='SuperAdmin'">
                        {{isSupAdmn ? 'Back to Dashboard' : 'Retrieve Data'}}
                        <!-- <div class="cart-box"><i data-feather="help-circle"></i></div> -->
                    </li>
                    <li class="cart-nav onhover-dropdown">
                        <div class="cart-box">
                            <a [routerLink]="['mng']" [queryParams]="{tab:'list'}" queryParamsHandling="merge" 
                                *ngIf="lgnUsrRole=='SuperAdmin'">Manage</a>
                            <!-- <a *ngIf="isMigURL && lgnUsrRole=='SuperAdmin'">Manage</a> -->
                        </div>
                    </li>
                    <li class="user_profile profile-nav onhover-dropdown p-0 me-0">
                        <div class="media profile-media">
                            <img class="b-r-10" src="assets/img/profile.png" alt="">
                            <div class="media-body"><span>{{lgnUsrName}}</span>
                                <p class="mb-0 font-roboto">{{lgnUsrRole}} <i class="middle fa fa-angle-down"></i></p>
                            </div>
                        </div>
                        <ul class="profile-dropdown onhover-show-div">
                            <li [routerLink]="['user-profile']" *ngIf="viewPrfl && !(hdnTT?.isPrflHdn)">
                                <a><i data-feather="user"></i><span>Profile</span></a> <!-- [routerLink]="['user-profile']" -->
                            </li>
                            <li>
                                <a [routerLink]="['user-exports']"><i data-feather="file-text"></i><span>My
                                        Exports</span></a>
                            </li>
                            <li *ngIf="isNtnlAdmn">
                                <a [routerLink]="['txn-exports']"><i data-feather="file-text"></i><span>Transaction
                                        Exports</span></a>
                            </li>
                            <li>
                                <a [routerLink]="['chng-pswd']"><i data-feather="lock"></i><span>Change
                                        Password</span></a>
                            </li>
                            <li (click)="onSignOut()">
                                <!-- routerLink="" -->
                                <a><i data-feather="log-out"></i><span>Sign Out</span></a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="page-body-wrapper">
        <div class="sidebar-wrapper close_icon">
            <div class="logo-wrapper pe-5">
                <a data-bs-original-title="" (click)="menuBinding()" title="">
                    <!-- (click)="menuBinding()" [routerLink]="isMigURL?['/home/stk-rprt']:['/home/ovrvw-db']" onclick="window.location.reload()" ['ovrvw-db'] -->
                    <img class="img-fluid for-light" src="assets/img/evin_logo.png" alt="">
                    <img class="img-fluid for-dark" src="assets/img/evin_logo.png" alt="">
                </a>
                <div class="back-btn"><i class="btn btn-sm fa fa-angle-left"></i></div>
                <div class="toggle-sidebar ml-3 mt-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-grid status_toggle middle sidebar-toggle">
                        <rect x="3" y="3" width="7" height="7"></rect>
                        <rect x="14" y="3" width="7" height="7"></rect>
                        <rect x="14" y="14" width="7" height="7"></rect>
                        <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                </div>
            </div>
            <div class="logo-icon-wrapper">
                <a>
                    <img class="img-fluid" src="assets/img/logo-icon.png" alt="">
                </a>
            </div>
            <nav class="sidebar-main">
                <div class="left-arrow disabled" id="left-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="feather feather-arrow-left">
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                    </svg>
                </div>
                <div id="sidebar-menu">
                    <ul class="sidebar-links" id="simple-bar">
                        <li class="back-btn">
                            <a>
                                <img class="img-fluid" src="assets/img/logo-icon.png" alt="">
                            </a>
                            <div class="mobile-back text-end">
                                <span>Back</span><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
                            </div>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmDb">
                            <a class="sidebar-link sidebar-title" id="dbAnchr">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                </svg>
                                <span>Dashboard</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li class="sidebar-submenu-list" *ngIf="isLcl"><a [routerLink]="['/home/db-ovrvw']"
                                        (click)="isTagsHide=false">Overview Old</a></li>
                                <li class="sidebar-submenu-list" *ngIf="isLcl"><a [routerLink]="['/home/ovrvw']"
                                        (click)="isTagsHide=false">Overview Lib.</a></li>
                                <!-- <li class="sidebar-submenu-list"><a id="dbMI" [routerLink]="isMigURL?['/home/puc']:['/home/ovrvw-db']" (click)="isTagsHide=false">Overview</a></li> -->
                                <li class="sidebar-submenu-list" *ngIf="isLcl"><a [routerLink]="['/home/ovrvw-db']"
                                        (click)="isTagsHide=false">Overview Fusion</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmDbOvrw"><a id="dbMI" [routerLink]="['/home/db-ovrw']"
                                        (click)="isTagsHide=false">Overview</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmDbStkMngmnt && mmSm"><a
                                        [routerLink]="['/home/db-inv']">Stock Management</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmDbCce && mmCCE"><a
                                        [routerLink]="['/home/db-assets']">CCE Management</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmDbAct && mmSm"><a
                                        [routerLink]="['/home/db-activity']">Activity</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmDbIndnts && mmIndnts"><a
                                        [routerLink]="['/home/db-orders']">Indents</a></li>
                                <li class="sidebar-submenu-list" *ngIf="isLclDev"><a
                                        [routerLink]="['/home/db-custom-db']">Custom Dashboards</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmIndnts">
                            <a class="sidebar-link sidebar-title">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-airplay">
                                    <path
                                        d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                                    </path>
                                    <polygon points="12 15 17 21 7 21 12 15"></polygon>
                                </svg>
                                <span>Indents</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li class="sidebar-submenu-list" *ngIf="mmIndInd && (!hdnTT?.isOrdLstHdn || !hdnTT?.isOrdCrtHdn)">
                                    <a [routerLink]="['/home/ord-ord']" [queryParams]="{tab:'list', type:'i'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true;">Indents
                                        <!-- onTabs('indnt-list'); -->
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="isTrnsfr && !isLcl && mmIndTrnsfrs && (!hdnTT?.isTrnsLstHdn || !hdnTT?.isTrnsCrtHdn)">
                                    <a [routerLink]="['/home/ord-rls']" [queryParams]="{tab:'list', type:'r'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true">Releases
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="!isTrnsfr && !isLcl && mmIndTrnsfrs && (!hdnTT?.isTrnsLstHdn || !hdnTT?.isTrnsCrtHdn)">
                                    <a [routerLink]="['/home/ord-transfers']" [queryParams]="{tab:'list', type:'t'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true">Transfers
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="isLcl">
                                    <a [routerLink]="['/home/ord-rls']" [queryParams]="{tab:'list', type:'r'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true">Releases
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="isLcl">
                                    <a [routerLink]="['/home/ord-transfers']" [queryParams]="{tab:'list', type:'t'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true">Transfers
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="mmIndShpmnts">
                                    <a [routerLink]="['/home/ord-ship']" [queryParams]="{tab:'list'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true">Shipments
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="mmIndDesc">
                                    <a [routerLink]="['/home/ord-discrep']" (click)="isTagsHide=true">Discrepancies</a>
                                </li>         
                                <li class="sidebar-submenu-list" *ngIf="mmIndPrcs">
                                    <a [routerLink]="['/home/prchs-ord']" [queryParams]="{tab:'list', type:'i'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true;">Purchase Indents                                        
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="mmIndMnf">
                                    <a [routerLink]="['/home/mnftr-trnsctns']" (click)="isTagsHide=true;">Manufacturer Transactions</a>
                                </li>                      
                            </ul>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmSm">
                            <a class="sidebar-link sidebar-title" id="srAnchr">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-layout">
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                    <line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg>
                                <span>Stock Management</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li class="sidebar-submenu-list" *ngIf="mmSmStkrprt && !(hdnTT?.isStkRptHdn)">
                                    <a [routerLink]="['/home/stk-rprt']" (click)="isTagsHide=true" id="srMI">Stock Report</a>
                                </li>                               
                                <li class="sidebar-submenu-list" *ngIf="mmSmStkDvnt">
                                    <a [routerLink]="['/home/stk-dvnt']" (click)="isTagsHide=true">Stock Deviant</a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="mmSmTxn && (!hdnTT?.isTxnCrtHdn || !hdnTT?.isTxnLstHdn)">
                                    <a [routerLink]="['/home/trans']" [queryParams]="{tab:'list'}"
                                        queryParamsHandling="merge" (click)="isTagsHide=true;onTabs('trans-list');">Transactions
                                    </a>
                                </li>
                                <li class="sidebar-submenu-list">
                                    <a [routerLink]="['/home/stk-adjstm']" (click)="isTagsHide=true" *ngIf="false">Stock
                                        Adjustments</a>
                                </li>
                            </ul>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmRprts">
                            <!-- -->
                            <a class="sidebar-link sidebar-title">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text">
                                    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                    <polyline points="14 2 14 8 20 8"></polyline>
                                    <line x1="16" y1="13" x2="8" y2="13"></line>
                                    <line x1="16" y1="17" x2="8" y2="17"></line>
                                    <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                                <span>Reports</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li *ngIf="mmRprtsAct && mmSm">
                                    <a class="submenu-title active" data-bs-original-title="" title="">Activity</a>
                                    <ul class="nav-sub-childmenu submenu-content" style="display: block;">
                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/dmn-actvty']" *ngIf="mmRprtActDmn">Domain activity</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/trans-counts']" *ngIf="mmRprtActTxnCnts"
                                                (click)="onClick('trans-counts')">Transaction counts</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" *ngIf="mmRprtUsrAct"
                                                [routerLink]="['/home/user-activity']"
                                                (click)="onClick('user-activity')">User activity</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" *ngIf="mmRprtActRte"
                                                [routerLink]="['/home/act-rate']" (click)="onClick('act-rate')">Activity rate</a></li>
                                    </ul>
                                </li>
                                <li *ngIf="mmRprtCCE && mmCCE">
                                    <a class="submenu-title active" data-bs-original-title="" title="">
                                        CCE Management<span class="sub-arrow"></span>
                                    </a>
                                    <!-- *ngIf="mmRprtCceCpcty" -->
                                    <ul class="nav-sub-childmenu submenu-content" style="display: block;">
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtCceCpcty" ><a (click)="isTagsHide=true;onClick('rep-asset-capacity')"
                                            [routerLink]="['/home/rep-asset-capacity']">CCE Management capacity</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtCceSts"><a (click)="isTagsHide=true;onClick('rep-asset-status')"
                                            [routerLink]="['/home/rep-asset-status']">CCE Management status</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtCcePwr"><a (click)="isTagsHide=true;onClick('power-availability')"
                                            [routerLink]="['/home/power-availability']">Power availability</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtCceRespTo"><a (click)="isTagsHide=true;onClick('rspns-tm-rpar')"
                                            [routerLink]="['/home/rspns-tm-rpar']">Response time to repair</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="isLclDev && !isMigURL && mmRprtCceSckNss"><a (click)="isTagsHide=true;onClick('sickness-rate')"
                                            [routerLink]="['/home/sickness-rate']">Sickness rate</a></li>
                                        <li class="sidebar-submenu-list"  *ngIf="mmRprtsCceTemp"><a (click)="isTagsHide=true;onClick('temp-excursion')"
                                            [routerLink]="['/home/temp-excursion']">Temperature excursions</a></li>
                                        <li class="sidebar-submenu-list"  *ngIf="mmRprtCceUp"><a (click)="isTagsHide=true;onClick('up-time')"
                                            [routerLink]="['/home/up-time']">Up time</a></li>
                                    </ul>
                                </li>
                                <li *ngIf="isLclDev && mmRprtsCstm">
                                    <a class="submenu-title active" data-bs-original-title="" title="">
                                        Custom reports<span class="sub-arrow"></span>
                                    </a>
                                    <ul class="nav-sub-childmenu submenu-content" style="display: block;">
                                        <li class="sidebar-submenu-list">
                                            <a (click)="isTagsHide=true" [routerLink]="['/home/rep-custom-reports']">Custom reports</a>
                                        </li>
                                    </ul>
                                </li>
                                <li *ngIf="mmRprtStk  && mmSm">
                                    <a class="submenu-title active" data-bs-original-title="" title="">
                                        Stock Management<span class="sub-arrow"></span>
                                    </a>
                                    <ul class="nav-sub-childmenu submenu-content" style="display: block;">
                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/stks-rprts']" *ngIf="mmRprtStkStkReprt"
                                                (click)="onClick('stks-rprts')">Stock report</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/abnormal-stock']" *ngIf="mmRprtsStkAbnrml"
                                                (click)="onClick('abnormal-stock')">Abnormal stock</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/consumption']" *ngIf="mmRprtStkCnsmptn"
                                                (click)="onClick('consumption')">Consumption</a></li>

                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/discards']" *ngIf="mmRprtsStkDisc"
                                                (click)="onClick('discards')">Discards</a></li>                                        
                                        <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" [routerLink]="['/home/rplnsh-rspn-time']" (click)="onClick('rplnsh-rspn-time')">Replenishment response time</a></li> -->
                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/stock-availability']" *ngIf="mmRprtStkAvlbl"
                                                (click)="onClick('stock-availability')">Stock availability</a></li>
                                                <li class="sidebar-submenu-list" *ngIf="mmRprtStkTrnd"><a (click)="isTagsHide=true"
                                                    [routerLink]="['/home/closing-stock']"
                                                    (click)="onClick('closing-stock')">Closing Stock</a></li>     
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtStkTrnd"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/stock-trends']"
                                                (click)="onClick('stock-trends')">Stock Trends</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="enableWastageReport"><a (click)="isTagsHide=true" [routerLink]="['/home/cnsin-wise-cnsumton']"
                                                (click)="onClick('consignee-wise-consumption')">Consignee wise consumption</a></li>

                                        <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/coverage']" *ngIf="!isMigURL"
                                                (click)="onClick('coverage')">Coverage Report</a></li> -->
    
                                        <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/wastage']" 
                                                (click)="onClick('wastage')">Wastage Report</a></li>
                                        <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" [routerLink]="['/home/supply']" (click)="onClick('supply')">Supply</a></li> -->
                                        <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" [routerLink]="['/home/utilization']" (click)="onClick('utilization')">Utilization</a></li> -->
                                    </ul>
                                </li>
                                <li *ngIf="mmRprtInd && mmIndnts">
                                    <a class="submenu-title active" data-bs-original-title="" title="">Indents<span
                                            class="sub-arrow"></span></a>
                                    <ul class="nav-sub-childmenu submenu-content" style="display: block;">
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtIndDesc"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/discrepancies']"
                                                (click)="onClick('discrepancies')">Indent discrepancies</a></li>
                                        <li class="sidebar-submenu-list" *ngIf="mmRprtIndResTme"><a (click)="isTagsHide=true"
                                                [routerLink]="['/home/ord-res-time']"
                                                (click)="onClick('ord-res-time')">Indent response time</a></li>
                                    </ul>
                                </li>
                                <!-- <li>
                                    <a class="submenu-title active" data-bs-original-title=""
                                        [routerLink]="['/home/report-filter']" title="">Report filter<span class="sub-arrow"></span>
                                    </a>
                                </li> -->
                            </ul>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmCCE && !(hdnTT?.isCCEHdn)">
                            <a class="sidebar-link sidebar-submenu-list"  (click)="removeDbStr()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-box">
                                    <path
                                        d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z">
                                    </path>
                                    <polyline points="2.32 6.16 12 11 21.68 6.16"></polyline>
                                    <line x1="12" y1="22.76" x2="12" y2="11"></line>
                                </svg>
                                <span class="level2">CCE Management</span>
                            </a>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmCnfg">
                            <a class="sidebar-link sidebar-title">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-monitor">
                                    <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                                    <line x1="8" y1="21" x2="16" y2="21"></line>
                                    <line x1="12" y1="17" x2="12" y2="21"></line>
                                </svg>
                                <span class="level2">Configuration</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgGen"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/cnfg-gnrl']">General</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgCap"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-cpblty']">Capabilities</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgStk"><a (click)="isTagsHide=true;onInv();"
                                        [routerLink]="['/home/confg-inv']">Stock Management</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgInd"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-ord']">Indents</a></li>
                                <li class="sidebar-submenu-list"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-aprove']" [hidden]="true">Approvals</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgNtfcn"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-nfgs']" [queryParams]="{tab:'evnt'}"
                                        queryParamsHandling="merge">Notifications</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgTag"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-tags']">Tags</a></li>                                
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgCstmRprt"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-cstm-repts']">Custom reports</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgCCE"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-assets']">CCE Management</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgDb"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-db']">Dashboard</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgOpt"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-options']">Push Options</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgEvnt"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-evnt-sum']">Event summary</a></li>
                                <li class="sidebar-submenu-list" *ngIf="false"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-bultn-board']">Bulletin board</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmCnfgCstmDb"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/confg-custm-db']">Custom Dashboards</a></li>                               
                            </ul>
                        </li>
                        <li class="sidebar-list" [hidden]="isSupAdmn" *ngIf="mmStup">
                            <a class="sidebar-link sidebar-title">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                    class="bi bi-gear-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                </svg>
                                <span class="level2">Setup</span>
                            </a>
                            <ul class="sidebar-submenu">
                                <li class="sidebar-submenu-list" *ngIf="mmStupUsr"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-users']"
                                        [queryParams]="{tab:'list'}" queryParamsHandling="merge">Users</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmStupMtrl"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-matrls']"
                                        [queryParams]="{tab:'list'}" queryParamsHandling="merge">Materials</a></li>
                                <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" [routerLink]="['/home/setup-hand-units']">Handling units</a></li> -->
                                <li class="sidebar-submenu-list" *ngIf="mmStupFac"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-fclty']"
                                        [queryParams]="{tab:'list'}" queryParamsHandling="merge">Facility</a></li>
                                <!-- <li class="sidebar-submenu-list"><a (click)="isTagsHide=true" [routerLink]="['/home/setup-fclty-group']">Facility groups</a></li> -->
                                <li class="sidebar-submenu-list" *ngIf="mmStupStk">
                                    <a (click)="isTagsHide=true" [routerLink]="['/home/setup-inv']">Stock Management</a>
                                </li>
                                <li class="sidebar-submenu-list" *ngIf="mmStupDmns">
                                    <a (click)="isTagsHide=true" [routerLink]="['/home/setup-domns']"
                                        *ngIf="lgnUsrRole=='SuperAdmin'">Domains</a></li>
                                <!-- <li class="sidebar-submenu-list" *ngIf="mmStupDmns"><a
                                         *ngIf="isMigURL && lgnUsrRole=='SuperAdmin'">Domains</a></li> -->
                                <li class="sidebar-submenu-list" *ngIf="mmStupCCE"><a (click)="isTagsHide=true"
                                        [routerLink]="['/home/setup-assets']" [queryParams]="{tab:'list'}"
                                        queryParamsHandling="merge">CCE Management</a></li>
                                <li class="sidebar-submenu-list" *ngIf="false"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-bultn-brd']">Bulletin
                                        board</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmStupTrans && lgnUsrRole=='SuperAdmin'"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-transporters']"
                                        [queryParams]="{tab:'list'}" queryParamsHandling="merge">Transporters</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmStupManfc && lgnUsrRole=='SuperAdmin'"><a
                                        (click)="isTagsHide=true" [routerLink]="['/home/setup-mfrs']"
                                        [queryParams]="{tab:'list'}" queryParamsHandling="merge">Manufacturers</a></li>
                                <li class="sidebar-submenu-list" *ngIf="mmStupManfc && lgnUsrRole=='SuperAdmin'"><a
                                    (click)="isTagsHide=true" [routerLink]="['/home/setup-ccemfrs']"
                                    [queryParams]="{tab:'list'}" queryParamsHandling="merge">CCE Manufacturers</a></li>
                            </ul>
                        </li>
                        <li class="sidebar-list" *ngIf="isSupAdmn">
                            <a class="sidebar-link sidebar-submenu-list" (click)="isTagsHide=true" [routerLink]="['/home/setup-users-list']" id="suUsr">
                                <i class="fa fa-users" aria-hidden="true"></i><span class="level2">&nbsp;&nbsp;Users</span>
                            </a>
                        </li>
                        <li class="sidebar-list" *ngIf="isSupAdmn">
                            <a class="sidebar-link sidebar-submenu-list" (click)="isTagsHide=true" [routerLink]="['/home/setup-fclty-list']">
                                <i class="fa fa-hospital-o fa-lg" aria-hidden="true"></i><span class="level2">&nbsp;&nbsp;Facility</span>
                            </a>
                        </li>
                        <li class="sidebar-list" *ngIf="isSupAdmn">
                            <a class="sidebar-link sidebar-submenu-list" (click)="isTagsHide=true"
                                [routerLink]="['/home/setup-assets-list']">
                                <i class="fa fa-delicious fa-lg" aria-hidden="true"></i><span class="level2">&nbsp;&nbsp;CCE Management</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
            </nav>
        </div>
        <div class="page-body p-0">
            <div class="container-fluid pt-1 pr-0 pl-0">
                <main>
                    <div class="container-fluid mt-4">
                        <div class="row mt-2">
                            <div class="col-xl-12">
                                <div class="card border-0 rounded-0 header-box_flex mb-0 mt-2 rounded">
                                    <div class="card-header pb-2 pt-2 ps-3 pe-3 border-0  rounded">
                                        <nav aria-label="breadcrumb" class="breadcrumb_nav float-lg-start">
                                            <!-- <ol class="breadcrumb bg-transparent mb-0 mt-0 pt-0" *ngIf="isTagsHide">
                                                <li class="breadcrumb-item active" aria-current="page">Home</li>
                                            </ol>
                                            <ol class="breadcrumb bg-transparent mb-0 mt-0 pt-0" *ngIf="!isTagsHide">
                                                <li class="breadcrumb-item fw-normal"><a>Home</a></li>
                                                <li class="breadcrumb-item fw-normal"><a>Dashboard</a></li>
                                                <li class="breadcrumb-item active" aria-current="page">Overview</li>
                                            </ol> -->
                                            <breadcrumb #parent>
                                                <ol class="breadcrumb mb-0 font-wt">
                                                    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
                                                        <li *ngIf="!route.terminal" class="breadcrumb-item">
                                                            <a [routerLink]="[route.url]">{{route.displayName}}</a>
                                                        </li>
                                                        <li *ngIf="route.terminal" class="breadcrumb-item active"
                                                            aria-current="page">{{route.displayName}}</li>
                                                    </ng-template>
                                                </ol>
                                            </breadcrumb>
                                        </nav>
                                        <div class="float-lg-end" *ngIf="false"> <!-- [hidden]="isTagsHide"-->
                                            <span class="text-secondary"><span class="font-weight-bold">Material
                                                    tag(s):</span>
                                                RI Vaccines, <span class="font-weight-bold">Period:</span> 0 day(s)
                                                <label class="badge badge-pink mb-0">GMSD</label>
                                                <!-- <label class="badge badge-pink mb-0">Manufacturer</label> -->
                                                <label class="badge badge-pink mb-0">Session Site</label>
                                            </span>&nbsp;
                                            <label class="mb-0"><i class="fa fa-filter"></i></label>
                                            <!-- <label class="mb-0" data-toggle="collapse" href="#multiCollapseExample1"
                                                role="button" aria-expanded="false"
                                                aria-controls="multiCollapseExample1"><i class="fa fa-filter"></i>
                                            </label> -->
                                            <span class="small">&nbsp;&nbsp;As of {{crntDtTm}}</span>&nbsp;
                                            <span class="btn btn-sm p-0"><a class="rounded-circle">
                                                    <i class="fa fa-refresh"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <hr class="mt-0 mb-0">
                                    <div class="row" *ngIf="false">
                                        <div class="col">
                                            <div class="collapse multi-collapse" id="multiCollapseExample1">
                                                <div class="card card-body mb-0 pt-1 pb-2 border-0">
                                                    <form class="form theme-form">
                                                        <div class="row">
                                                            <div class="col-xl-5 col-sm-12">
                                                                <div class="row">
                                                                    <label class="col-sm-5 col-xs-12 col-form-label">
                                                                        Include tag(s) <i class="fa fa-info-circle"></i>
                                                                    </label>
                                                                    <div class="col-sm-7 col-xs-12">
                                                                        <select class="js-example-basic-hide-search"
                                                                            multiple="multiple">
                                                                            <optgroup label="Developer">
                                                                                <option value="AL">Smith</option>
                                                                                <option value="WY">Peter</option>
                                                                                <option value="WY">James</option>
                                                                                <option value="WY">Hanry Die</option>
                                                                                <option value="WY">John Doe</option>
                                                                                <option value="WY">Harry Poter</option>
                                                                            </optgroup>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-5 col-sm-12">
                                                                <div class="row">
                                                                    <label class="col-sm-5 col-xs-12 col-form-label">
                                                                        Exclude tag(s) <i class="fa fa-info-circle"></i>
                                                                    </label>
                                                                    <div class="col-sm-7 col-xs-12">
                                                                        <select class="js-example-basic-hide-search"
                                                                            multiple="multiple">
                                                                            <optgroup label="Developer">
                                                                                <option value="AL">Smith</option>
                                                                                <option value="WY">Peter</option>
                                                                                <option value="WY">James</option>
                                                                                <option value="WY">Hanry Die</option>
                                                                                <option value="WY">John Doe</option>
                                                                                <option value="WY">Harry Poter</option>
                                                                            </optgroup>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-2 col-sm-12 col-xs-12 mt-1">
                                                                <button class="btn btn-sm btn-primary"
                                                                    data-toggle="collapse" href="#multiCollapseExample1"
                                                                    role="button" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1">Filter</button>&nbsp;
                                                                <button class="btn btn-sm btn-light"
                                                                    data-toggle="collapse" href="#multiCollapseExample1"
                                                                    role="button" aria-expanded="false"
                                                                    aria-controls="multiCollapseExample1">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mb-0 card border-0 rounded-0 header-box_flex pt-0">
                                <div class="d-flex mr-4 ml-4 pl-3 pr-3">
                                    <div class="mr-auto"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row p-0 mt-2">
                            <div class="col-xl-12 col-sm-12 col-xs-12">
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-6">
                        <div class="float-xl-start text-white">
                            <a [routerLink]="">&copy; 2021 Dhanush Infotech</a>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="float-xl-end text-white" *ngIf="cnfgMail!='' || cnfgMobl!=''">
                            <a *ngIf="cnfgMail!=''" href="mailto:{{cnfgMail}}"><i class="fa fa-envelope" aria-hidden="true"></i> {{cnfgMail}}</a>&nbsp;&nbsp;
                            <a *ngIf="cnfgMobl!=''" [routerLink]=""><i class="fa fa-mobile" aria-hidden="true"></i> {{cnfgMobl}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>
<div class="modal fade" id="sitemap-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <ol class="breadcrumb m-0" style="background: transparent;">
                    <li *ngIf="slctDmnNme != ''" class="breadcrumb-item">Domains of <b>{{slctDmnNme | uppercase}}</b></li>                   
                </ol>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" id="mdlClseBtn"></button>
            </div>
            <div class="modal-body p-1">
                <div class="container-fluid">
                    <div class="card p-0 border-0 mt-3" *ngIf="lstDmns?.length!=0">
                        <div class="card-body p-2">
                            <div class="text-right float-end">
                                Home Domain Name: <span style="cursor: pointer;" (click)="onTreDmnClk({pranthId: usrLgnDtls.pranthId, pranthName: usrLgnDtls.pranthName})"><b>{{usrLgnDtls.pranthName | uppercase}}</b></span>
                            </div> <br>
                            <div class="col-xl-12 col-sm-12 mt-4">       
                                <div id="accordian" class="menu-su-domain-container">
                                    <ul class="menu">
                                        <li class="menu-item-has-children" *ngFor="let dl1 of lstDmns">
                                            <a class="span_div">
                                                <span class="span_div1">{{dl1.pranthName}}</span>
                                                <span class="icon_div" (click)="onTreDmnClk(dl1)">
                                                    <i class="fa fa-exchange" aria-hidden="true"></i>
                                                </span>
                                            </a>
                                            <ul *ngIf="dl1?.Pranth?.length!=0">
                                                <li *ngFor="let dl2 of dl1?.Pranth">
                                                    <a class="span_div">
                                                        <span class="span_div1">{{dl2.pranthName}}</span>
                                                        <span class="icon_div" (click)="onTreDmnClk(dl2)">
                                                            <i class="fa fa-exchange" aria-hidden="true"></i>
                                                        </span>
                                                    </a>
                                                    <ul *ngIf="dl2?.Pranth?.length!=0">
                                                        <li *ngFor="let dl3 of dl2?.Pranth">
                                                            <a class="span_div">
                                                                <span class="span_div1">{{dl3.pranthName}}</span>
                                                                <span class="icon_div" (click)="onTreDmnClk(dl3)">
                                                                    <i class="fa fa-exchange" aria-hidden="true"></i>
                                                                </span>
                                                            </a>
                                                            <ul *ngIf="dl3?.Pranth?.length!=0">
                                                                <li *ngFor="let dl4 of dl3?.Pranth">
                                                                    <a class="span_div">
                                                                        <span
                                                                            class="span_div1">{{dl4.pranthName}}</span>
                                                                        <span class="icon_div" (click)="onTreDmnClk(dl4)">
                                                                            <i class="fa fa-exchange"
                                                                                aria-hidden="true"></i>
                                                                        </span>
                                                                    </a>
                                                                    <ul *ngIf="dl4?.Pranth?.length!=0">
                                                                        <li *ngFor="let dl5 of dl4?.Pranth">
                                                                            <a class="span_div">
                                                                                <span
                                                                                    class="span_div1">{{dl5.pranthName}}</span>
                                                                                <span class="icon_div" (click)="onTreDmnClk(dl5)">
                                                                                    <i class="fa fa-exchange"
                                                                                        aria-hidden="true"></i>
                                                                                </span>
                                                                            </a>
                                                                            <ul *ngIf="dl5?.Pranth?.length!=0">
                                                                                <li *ngFor="let dl6 of dl5?.Pranth">
                                                                                    <a class="span_div">
                                                                                        <span
                                                                                            class="span_div1">{{dl6.pranthName}}</span>
                                                                                        <span class="icon_div" (click)="onTreDmnClk(dl6)">
                                                                                            <i class="fa fa-exchange"
                                                                                                aria-hidden="true"></i>
                                                                                        </span>
                                                                                    </a>
                                                                                    <ul *ngIf="dl6?.Pranth?.length!=0">
                                                                                        <li
                                                                                            *ngFor="let dl7 of dl6?.Pranth">
                                                                                            <a class="span_div">
                                                                                                <span
                                                                                                    class="span_div1">{{dl7.pranthName}}</span>
                                                                                                <span class="icon_div" (click)="onTreDmnClk(dl7)">
                                                                                                    <i class="fa fa-exchange"
                                                                                                        aria-hidden="true"></i>
                                                                                                </span>
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0">
                        <button type="button" class="btn btn-light btn-sm" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>