import { Component, OnInit } from '@angular/core';

import { BaseComponent } from 'src/app/Components/Global/base.component';
import { GlobalService } from 'src/app/Services/global.service';

@Component({
  selector: 'evin-cnfg-bulletin-board-view',
  templateUrl: './cnfg-bulletin-board-view.component.html',
  styleUrls: ['./cnfg-bulletin-board-view.component.css']
})

export class CnfgBulletinBoardViewComponent  extends BaseComponent implements OnInit {
  cnfgBltnPrmsn = true; usrPrmsn: any = null;
  constructor(public glblSvc: GlobalService) { 
    super(glblSvc);
  }

  ngOnInit(): void {
    this.usrPrmsn = Object.keys(this.usrLgnDtls).filter(f => f == 'domainPermissions').length > 0 ? this.usrLgnDtls.domainPermissions : null;
    if (this.usrPrmsn != null) {
      let cnfgLst: any[] = this.usrPrmsn?.Configurations;
      cnfgLst.filter(e => { ((e.menuCode == 'mm_cf_bb_ac') ? (this.cnfgBltnPrmsn = e.action) : '') });
    }
  }

}
